import React from 'react'
import { AppBar, withStyles } from '@material-ui/core'
import FuseScrollbars from 'core/components/Scrollbars'
import classNames from 'classnames'
import Logo from 'core/components/Logo'
import { FuseNavigation } from 'core/components'

const styles = theme => ({
  content: {
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll'
  },
  navBarBackground: {
    background: 'white',
    marginTop: '1px'
  }
})

const NavbarLayout = ({ classes, className, organizationId }) => {
  return (
    <div className={classNames('flex flex-col overflow-hidden h-full', className)}>
      <AppBar
        color='primary'
        position='static'
        elevation={0}
        className={classNames('flex flex-row items-center flex-shrink h-52 min-h-52 px-12', classes.navBarBackground)}
      >
        <Logo organizationId={organizationId} />
      </AppBar>
      <FuseScrollbars className={classNames(classes.content, 'border-solid border-t-1 border-gray h-full')}>
        <FuseNavigation />
      </FuseScrollbars>
    </div>
  )
}

export default withStyles(styles, { withTheme: true })(NavbarLayout)
