import React, { Component } from 'react'
import classNames from 'classnames'
import { ButtonGroup, withStyles } from '@material-ui/core'
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay'
import ReorderIcon from '@material-ui/icons/Reorder'
import { connect } from 'react-redux'
import * as ShipmentActions from '../store/actions'
import {
  FusePlaceHolder,
  LoadMore,
  FuseIconButton
} from '../../core/components'
import SubToolBarLayout from '../../core/containers/Layout/SubToolBarLayout'
import PartnersSidebar from '../../core/components/PartnersSidebar'
import CSOutboundsSidebar from '../../core/components/CSOutboundsSidebar'
import * as CoreActions from '../../core/store/actions'
import MarkAsReceivedDialog from '../components/MarkAsReceivedDialog'
import ShipmentListSkeleton from '../../core/components/Skeletons/ShipmentListSkeleton'
import ShipmentListFilterSkeleton from '../../core/components/Skeletons/ShipmentListFilterSkeleton'
import ShipmentCard from '../../core/components/shipment-card/ShipmentCard'
import FilterRow from '../../core/components/Filters/shipment-filter/FilterRow'
import { get } from 'lodash'
import {
  setShipmentListFilters,
  getShipmentListFilters,
  startSearching,
  stopSearching
} from '../../core/helpers'
import IOSSwitchComponent from '../../core/components/switches/IOSSwitchComponent';

class Inbound extends Component {
  state = {
    receiveDialog: {
      open: false,
      shipment: {}
    },
    partnerSidebar: {
      open: false,
      actors: {}
    },
    outboundsSidebar: {
      open: false,
      outbounds: [],
      consolidations: []
    },
    customFilter: null,
    status: null,
    search: '',
    sort: null,
    minified: []
  }
  pagination = {
    pageNo: 1
  }
  filters = null
  search = null

  applyCustomFilter = customFilter => {
    this.pagination.pageNo = 1
    setShipmentListFilters(
      get(this.props, 'userInfo._id'),
      get(this.props, 'currentPlant._id'),
      'import',
      customFilter
    )
    this.setState({ customFilter }, this.getAllShipments)
  }

  resetTimer = () => {
    let timer = setTimeout(() => {
      this.getAllShipments(true)
      this.resetTimer()
    }, 30000)
    clearTimeout(this.timer)
    this.timer = timer
  }
  registerTimer = () => {
    document.addEventListener('mousemove', this.resetTimer)
    document.addEventListener('click', this.resetTimer)
    document.addEventListener('keypress', this.resetTimer)
  }
  componentWillUnmount() {
    clearTimeout(this.timer)
    document.removeEventListener('mousemove', this.resetTimer)
    document.removeEventListener('click', this.resetTimer)
    document.removeEventListener('keypress', this.resetTimer)
  }
  componentDidMount() {
    const { userInfo, currentPlant, autoRefreshStatus } = this.props
    const customFilter = getShipmentListFilters(
      get(userInfo, '_id'),
      get(currentPlant, '_id'),
      'import'
    )
    if (customFilter) {
      this.setState({
        customFilter
      }, this.getAllShipments)
    } else {
      this.getAllShipments()
    }
    /* For checking that is the auto refresh or when component is mounted */
    if (autoRefreshStatus) {
      this.registerTimer();
      this.resetTimer();
    }
  }

  onPartnerClick = actors => {
    this.setState({
      partnerSidebar: {
        open: true,
        actors
      }
    })
  }

  onPartnerSidebarClose = () => {
    this.setState({
      partnerSidebar: {
        open: false,
        actors: {}
      }
    })
  }

  onOutboundsClick = async shipment => {
    this.setState({
      outboundsSidebar: {
        open: true,
        outbounds: [],
        consolidations: shipment.consolidations
      }
    })
    const { getOutboundsByShipment } = this.props

    await getOutboundsByShipment(shipment._id)
    const { outboundsByShipment } = this.props
    this.setState({
      outboundsSidebar: {
        open: true,
        outbounds: outboundsByShipment,
        consolidations: shipment.consolidations
      }
    })
  }

  onOutboundsSidebarClose = () => {
    this.setState({
      outboundsSidebar: {
        open: false,
        outbounds: [],
        consolidations: []
      }
    })
  }

  onUploadFiles = (...args) => {
    const { upload } = this.props
    upload(...args)
  }

  onReceiveOpen = shipment => {
    this.setState({
      receiveDialog: {
        open: true,
        shipment
      }
    })
  }

  onReceiveClose = () => {
    this.setState({
      receiveDialog: {
        open: false,
        shipment: {}
      }
    })
  }

  onMarkReceivedSubmit = (...args) => {
    const { consigneeMarkAsReceived } = this.props
    consigneeMarkAsReceived(...args)
  }

  getAllShipments = (isSync = false) => {
    const { status, customFilter, search, sort } = this.state
    let filter = null
    if (customFilter) {
      filter = customFilter.value
      if (filter && filter.groupage && filter.groupage.type === 'none') {
        delete filter.groupage
      }
    }
    this.props.getAllShipments(this.pagination, status, search, sort, filter, isSync)
  }

  onLoadMore = () => {
    this.pagination.pageNo += 1
    this.getAllShipments()
  }

  onFilterChange = filters => {
    this.filters = filters
    this.pagination.pageNo = 1
    this.getAllShipments()
  }

  componentDidUpdate(prevProps) {
    if (this.props.shipments !== prevProps.shipments) {
      let minified = {}
      this.props.shipments.forEach(shipment => {
        minified[shipment._id] = this.state.minified.hasOwnProperty(shipment._id) ? this.state.minified[shipment._id] : true
      })
      this.setState({ minified })
    }
    if (this.props.autoRefreshStatus !== prevProps.autoRefreshStatus) {
      this.setToggleAutoRefresh(this.props.autoRefreshStatus)
    }
  }

  setAllMinifiedState = val => {
    let minified = {}
    this.props.shipments.forEach(shipment => {
      minified[shipment._id] = val
    })
    this.setState({ minified })
  }

  expandAll = () => {
    this.setAllMinifiedState(false)
  }

  collapseAll = () => {
    this.setAllMinifiedState(true)
  }

  handleSearchChange = event => {
    const { value } = event.target
    const { search } = this.state

    this.setState({ search: value },
      () => {
        if (search !== value) {
          stopSearching()
          startSearching(1000, () => {
            this.pagination.pageNo = 1
            this.getAllShipments()
          })
        }
      }
    )
  }

  handleStatusChange = status => {
    this.setState({ status }, this.getAllShipments)
  }
  handleSortChange = sort => {
    this.setState({ sort }, this.getAllShipments)
  }

  /**
  * Function to set AutoRefreshStatus
  * @param status boolean
  */
  setToggleAutoRefresh = status => {
    this.props.setAutoRefreshStatus(status);

    if (status) {
      this.registerTimer();
      this.resetTimer();
    }else{
      if (this.timer) {
        clearTimeout(this.timer)
        document.removeEventListener('mousemove', this.resetTimer)
        document.removeEventListener('click', this.resetTimer)
        document.removeEventListener('keypress', this.resetTimer)
      }
    }
  }

  render() {
    const { loading, shipments, currentPlant, showMore, initialLoading, classes, getShipmentFilterOptions, shipmentsTotal, autoRefreshStatus } = this.props
    const { partnerSidebar, outboundsSidebar, receiveDialog, minified } = this.state
    const atleastOneMinified = shipments.reduce((total, currentValue) => total || minified[currentValue._id], false)
    const allMinified = shipments.reduce((total, currentValue) => total && minified[currentValue._id], true)

    return (
      <React.Fragment>
        <SubToolBarLayout text='Inbound Bookings'>
          <IOSSwitchComponent label={`AutoRefresh`} checked={autoRefreshStatus} handleToggle={this.setToggleAutoRefresh}/>
          <ButtonGroup size='small'
            color='primary'
            aria-label='actions expand'
            className={classes.buttonGroup}>
            <FuseIconButton
              iconComponent={<CalendarViewDayIcon fontSize='large' className='mr-8' />}
              solid={true}
              className={classNames({
                [classes.button]: true,
                [classes.buttonActive]: true
              })}
              variant='outline'
              onClick={this.expandAll}
              disabled={!atleastOneMinified}>Expand All</FuseIconButton>
            <FuseIconButton iconComponent={<ReorderIcon fontSize='large' className='mr-8' />}
              solid={true}
              className={classNames({
                [classes.button]: true,
                [classes.buttonActive]: true
              })}
              variant='outline'
              onClick={this.collapseAll}
              disabled={allMinified}>Collapse All</FuseIconButton>
          </ButtonGroup>
        </SubToolBarLayout>
        <div className='p-20 h-full overflow-auto'>

          {initialLoading &&
            <ShipmentListFilterSkeleton showSearch showFilter />
          }

          {!initialLoading &&
            <FilterRow shipmentsTotal={shipmentsTotal}
              search={this.state.search}
              handleSearchChange={this.handleSearchChange}
              sort={this.state.sort}
              handleSortChange={this.handleSortChange}
              handleStatusChange={this.handleStatusChange}
              shipments={shipments}
              getShipmentFilterOptions={getShipmentFilterOptions}
              applyCustomFilter={this.applyCustomFilter}
              customFilter={this.state.customFilter}
              allowCustomFilter />
          }

          {loading && <ShipmentListSkeleton count={3} />}

          {!loading && shipments.length === 0 && (
            <FusePlaceHolder className='flex flex-col max-h-256'
              icon='play_for_work'
              title='No bookings'
              description={'Looks like there are no inbound shipments at the moment. Please check back later.'} />
          )}

          {(!loading && shipments.length > 0) &&
            <div className={classNames('mt-20 shipment-list')}>
              {shipments.map((shipment, index) => (
                <ShipmentCard
                  section='inbound'
                  key={index}
                  minified={this.state.minified[shipment._id]}
                  setMinified={value => {
                    this.setState({ minified: { ...minified, [shipment._id]: value } })
                  }}
                  shipment={shipment}
                  role={currentPlant.type}
                  onMarkReceive={this.onReceiveOpen}
                  onPartnerSidebarClick={this.onPartnerClick}
                  onOutboundsSidebarClick={this.onOutboundsClick}
                  openDocUploader={() => { }}
                  statusType={'current-status'}
                />
              ))}
              {showMore && <LoadMore onClick={this.onLoadMore} />}
            </div>
          }

          <PartnersSidebar open={partnerSidebar.open}
            closeSideBar={this.onPartnerSidebarClose}
            sideBarTitle={'Partners'}
            actors={partnerSidebar.actors} />

          <CSOutboundsSidebar open={outboundsSidebar.open}
            closeSideBar={this.onOutboundsSidebarClose}
            sideBarTitle={'CS & Outbounds'}
            outboundsList={outboundsSidebar.outbounds}
            consolidations={outboundsSidebar.consolidations}
            currentPlant={currentPlant} />

          <MarkAsReceivedDialog {...receiveDialog}
            handleClose={this.onReceiveClose}
            currentPlant={currentPlant}
            onSubmit={this.onMarkReceivedSubmit}
            onUpload={this.onUploadFiles} />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({
  inbound: { initialLoading, loading, shipments, shipmentsTotal, showMore, outboundsByShipment },
  welcome: { currentPlant },
  auth: { info },
  core
}) => ({
  initialLoading,
  loading,
  shipments,
  shipmentsTotal,
  currentPlant,
  showMore,
  outboundsByShipment,
  userInfo: info,
  autoRefreshStatus: core.autoRefreshStatus,
})

const mapDispatchToProps = dispatch => ({
  getAllShipments: (...args) => dispatch(ShipmentActions.getAllShipments(...args)),
  getOutboundsByShipment: shipmentId => dispatch(ShipmentActions.getOutboundsByShipment(shipmentId)),
  getShipmentFilterOptions: () => dispatch(CoreActions.getShipmentFilterOptions()),
  consigneeMarkAsReceived: (...args) => dispatch(ShipmentActions.consigneeMarkAsReceived(...args)),
  upload: (...args) => dispatch(CoreActions.uploadFile(...args)),
  setAutoRefreshStatus: (status) => dispatch(CoreActions.setAutoRefreshStatus(status)),
})

const styles = {
  button: { textTransform: 'none', fontWeight: 600, fontSize: 12 },
  buttonActive: {},
  buttonInActive: {}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Inbound))
