import React, { useState, useEffect } from 'react'
import { Grid, TextField, CircularProgress } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  LoadingButton
} from '../../core/components'
import ReviewBookingSummary from './ReviewBookingSummary'
import { getShipmentDocumentByType } from '../../core/api'
import { get } from 'lodash'
import fileDownload from 'js-file-download'

const ReviewCustomClearance = props => {
  const { open, shipment, handleClose, handleSubmit, requesting } = props
  const [note, setNote] = useState('')
  const [downloading, setDownloading] = useState(false)

  const downloadCustomClearance = async () => {
    setDownloading(true)
    const { showMessage } = props
    const { ok, data } = await getShipmentDocumentByType(shipment._id, 'ICP')

    if (ok === true) {
      fileDownload(data.data, get(data, 'data.originalName', 'NoName'))
    } else {
      showMessage({
        variant: 'error',
        message: get(data, 'message')
      })
    }
    setDownloading(false)
  }

  useEffect(() => {
    if (!open) {
      setNote('')
    }
  }, [open])

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Review custom clearance draft'
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="booking-wizard">
          <Grid item xs={8} className='booking-forms'>
            <Grid container className="h-full">

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}
                        className="semi-bold text-16">
                        Review Document</Grid>
                      <Grid item xs={12} className="regular fg-gray text-12">
                        You can download the document to review it, then approve or reject.</Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container
                      justify="space-between"
                      alignItems="center"
                      className="border-dashed border-gray border-2 p-6 bg-gray">
                      <Grid item xs={10}>
                        <Grid container
                          alignItems="center"
                          spacing={2}>
                          <Grid item>
                            <img src="/assets/images/icons/document-upload.svg"
                              alt="document"
                              className="h-36 w-auto" />
                          </Grid>
                          <Grid item className="semi-bold">
                            Custom clearance doc (PIB)</Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {downloading &&
                          <CircularProgress size={18} />
                        }

                        {!downloading &&
                          <img src='/assets/images/icons/import-header-green.svg'
                            alt="download"
                            className="h-24 w-auto cursor-pointer"
                            onClick={downloadCustomClearance} />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container
                  alignItems="flex-end"
                  className="pt-40 h-full">
                  <Grid item xs={12}>
                    <Grid container spacing={3}>

                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}
                            className="semi-bold text-16">
                            Add Note</Grid>
                          <Grid item xs={12}>
                            <TextField type="text"
                              name="note"
                              value={note}
                              onChange={e => setNote(e.target.value)}
                              inputProps={{
                                className: 'text-14 regular'
                              }}
                              placeholder="You can add any extra note for this booking"
                              fullWidth />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={6}>
                        <LoadingButton className="btn btn_lg w-full btn_green"
                          disabled={!!requesting}
                          loading={requesting === 'accept'}
                          onClick={() => handleSubmit(shipment, note, 'accept')}>
                          Accept</LoadingButton>
                      </Grid>

                      <Grid item xs={6}>
                        <LoadingButton className="btn btn_lg w-full btn_light_red"
                          disabled={!!requesting}
                          loading={requesting === 'reject'}
                          onClick={() => handleSubmit(shipment, note, 'reject')}>
                          Reject</LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} className="bg-gray">
            <ReviewBookingSummary shipment={shipment} />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default ReviewCustomClearance
