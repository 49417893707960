import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

const ShipmentListSkeleton = ({ count = 3 }) => (
  <Grid container
    alignItems='center'
    spacing={2}>
    {Array(count).fill().map((_, i) => i + 1).map(item => (
      <Grid item xs={12} key={item}
        className='mb-16'>
        <Skeleton height={200} />
      </Grid>
    ))}
  </Grid>
)

export default ShipmentListSkeleton
