import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import * as API from '../../core/api'
import { checkPagination } from '../../core/helpers'
import { get } from 'lodash'

export const getNotices = (isSync = false, pageNo = 1, success = null, fail = null) => async dispatch => {
  if (isSync === false) {
    dispatch({
      type: ActionTypes.GET_ALL_NOTICES,
      payload: [],
      reset: true,
      loading: true
    })
  }

  if (pageNo > 1) dispatch({ type: ActionTypes.GET_LOAD_MORE_NOTICES })

  try {
    const { ok, data } = await API.getPosts({ pageSize: 10, pageNo })

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination({ pageSize: 10, pageNo }, data, pageNo === 1)
    dispatch({
      type: ActionTypes.GET_ALL_NOTICES,
      payload: data,
      loading: false
    })
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getNoticeDetails = (noticeId = null, success = null, fail = null) => async dispatch => {
  if (!noticeId) {
    dispatch({
      type: ActionTypes.GET_NOTICES_DETAILS,
      payload: null
    })
  } else {
    try {
      const { ok, data } = await API.getPostById(noticeId)

      if (!ok) {
        throw new Error(data.message)
      }

      dispatch({
        type: ActionTypes.GET_NOTICES_DETAILS,
        payload: get(data, 'data', {})
      })
      if (success) {
        success()
      }
    } catch (e) {
      dispatch(CoreActions.showMessage(e))
      if (fail) {
        fail()
      }
    }
  }
}

export const openNotice = (noticeId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.openPost(noticeId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const closeNotice = (noticeId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.closePost(noticeId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const addNoticeComment = (noticeId, params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.addPostsComment(noticeId, params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const updateNotice = (noticeId, params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.updatePosts(noticeId, params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}
