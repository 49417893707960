import React from 'react'
import { Grid, withStyles } from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import FCLContainerTimelineRow from './FCLContainerTimelineRow'
import LCLCargoTimelineRow from './LCLCargoTimelineRow'
import { FuseAnimate } from 'core/components'
import { get } from "lodash"
import classNames from 'classnames'

const FooterStrip = ({ classes, shipment, minified, setMinified }) => {
  return (
    <Grid item xs={12} className={classes.footer}>
      {!minified && (
        <FuseAnimate transition='transition.fadeIn' delay={300}>
          <Grid container alignItems='stretch'>
            {shipment.type === 'LCL' && <LCLCargoTimelineRow shipment={shipment} />}
            {shipment.type === 'FCL' &&
              shipment.containers.map((container, index) => (
                <FCLContainerTimelineRow shipment={shipment}
                  container={container}
                  key={index}
                  index={index} />
              ))}
          </Grid>
        </FuseAnimate>
      )}
      <Grid
        container
        alignItems='stretch'
        className={classes.bottomStrip}
        onClick={() => {
          setMinified(!minified)
        }}
      >
        <Grid item xs={6}>
          {shipment.type === 'LCL' && (
            <Grid container justify='flex-start' alignItems='baseline'>
              <span className={classNames(classes.spanLeft, 'medium')}>
                <img src='/assets/images/icons/shipment-card/lcl-mini.svg'
                  alt='FCL'
                  className={classes.icon} />
                LCL booking
              </span>
            </Grid>
          )}
          {shipment.type === 'FCL' && (
            <Grid container justify='flex-start' alignItems='baseline'>
              {shipment.containers.map((container, containerIndex) => {
                const isCancelled = get(container, 'status.current.value') === 'cancelled'

                return (
                  <Grid item key={containerIndex}>
                    <Grid container alignItems="center"
                      className={classNames(
                        classes.spanLeft,
                        'medium',
                        { 'fg-red': isCancelled }
                      )}>
                      {isCancelled &&
                        <img src='/assets/images/icons/shipment-card/fcl-mini_red.svg'
                          alt='FCL'
                          className={classes.icon} />
                      }
                      {!isCancelled &&
                        <img src='/assets/images/icons/shipment-card/fcl-mini.svg'
                          alt='FCL'
                          className={classes.icon} />
                      }
                      <Grid item>{container.type}</Grid>
                      {get(container, 'equipmentNo') &&
                        <React.Fragment>
                          <Grid item><FiberManualRecordIcon className={classes.dot} /></Grid>
                          <Grid item>&nbsp;</Grid>
                          <Grid item className="uppercase">{container.equipmentNo}</Grid>
                        </React.Fragment>
                      }
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Grid>
        <Grid item xs={6}>
          <Grid container justify='flex-end' alignItems='center'>
            {minified && (
              <button
                onClick={() => {
                  setMinified(false)
                }}
                className={classes.closeLink}
              >
                Show Details
                <img src='/assets/images/icons/shipment-card/down.svg' alt='down' />
              </button>
            )}
            {!minified && (
              <button
                onClick={() => {
                  setMinified(true)
                }}
                className={classes.closeLink}
              >
                Hide Details
                <img src='/assets/images/icons/shipment-card/up.svg' alt='down' />
              </button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}
const styles = theme => ({
  root: {
    background: '#FFFFFF'
  },
  footer: {
    backgroundColor: '#FAFAFA'
  },
  dot: { fontSize: 6, marginLeft: 2, marginRight: 2 },
  icon: { marginRight: 5 },
  spanLeft: {
    color: '#7F7F7F',
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'left',
    marginLeft: 10,
    marginTop: 5
  },
  closeLink: {
    color: '#42B4E6',
    fontSize: 12,
    fontWeight: 500,
    marginTop: 3,
    marginRight: 10,
    textAlign: 'right',
    '&>img': {}
  },
  row: {
    backgroundColor: '#FAFAFA',
    borderTop: '.5px solid #ebebeb'
  },
  rowLeft: {
    padding: 20
  },
  bottomStrip: {
    backgroundColor: '#FAFAFA',
    height: 24,
    cursor: 'pointer',
    borderTop: '.5px solid #ebebeb'
  }
})
export default withStyles(styles, { withTheme: true })(FooterStrip)
