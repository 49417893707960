import React from 'react'
import { Typography, Grid, withStyles } from '@material-ui/core'
import FCLContainerTimelineRow from 'core/components/shipment-card/FCLContainerTimelineRow'
import LCLContainerTimelineRow from '../../core/components/shipment-card/LCLCargoTimelineRow'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Containers from './Containers'
import { get } from 'lodash'
import Skeleton from 'react-loading-skeleton'

const ContainerDetails = ({ shipment, onContainerClick, updatePreps, updateShipmentContainers, classes, addContainer, updateSingleContainer }) => {
  return (
    <Grid container alignItems='stretch' className='booking-details bg-white p-20'>
      <Grid item xs={12} className='shipment-details-container-section'>
        <Grid container>
          <Grid item xs={2}>
            <Typography className={classes.sectionTitle}>Container details</Typography>
          </Grid>
          {!get(shipment, 'containers') &&
            <Grid item xs={12} className="my-40">
              <Grid container alignItems="center"
                className="border-8 p-6 border-gray"
                spacing={2}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(rootItem =>
                  <Grid item key={rootItem} xs={1}><Skeleton height={100} /></Grid>
                )}
              </Grid>
            </Grid>
          }
          <Grid item xs={10}>
            <Grid container justify='flex-end' alignItems='center'>
              <Grid item>
                {shipment.type === 'LCL' && (
                  <Grid container justify='flex-start' alignItems='baseline'>
                    <span className={classes.spanLeft}>
                      <img src='/assets/images/icons/shipment-card/lcl-mini.svg' alt='FCL' className={classes.icon} />
                      LCL booking
                    </span>
                  </Grid>
                )}
                {shipment.type === 'FCL' && (
                  <Grid container justify='flex-start' alignItems='baseline'>
                    {shipment.containers.map(container => (
                      <span className={classes.spanLeft} key={container._id}>
                        <img src='/assets/images/icons/shipment-card/fcl-mini.svg' alt='FCL' className={classes.icon} />
                        {container.type}
                        {container.equipmentNo && container.equipmentNo.trim() !== '' && (
                          <React.Fragment>
                            <FiberManualRecordIcon className={classes.dot} />&nbsp;
                            <label className="uppercase">{container.equipmentNo}</label>
                          </React.Fragment>
                        )}
                      </span>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {shipment.type === 'LCL' && (
        <React.Fragment>
          {get(shipment, 'containers', []).length === 0 &&
            <Containers
              updatePreps={updatePreps}
              updateShipmentContainers={updateShipmentContainers}
              shipment={shipment}
              classes={classes}
              onContainerClick={onContainerClick}
              addContainer={addContainer}
              updateSingleContainer={updateSingleContainer}
            />
          }
          {get(shipment, 'containers', []).length > 0 &&
            get(shipment, 'containers', []).map((container, index) => {
              return (
                <LCLContainerTimelineRow
                  shipment={shipment}
                  container={container}
                  key={index}
                  index={index}
                  onContainerClick={onContainerClick}
                  noBackground
                />
              )
            })
          }
        </React.Fragment>

      )}
      {shipment.type === 'FCL' &&
        shipment.containers.map((container, index) => (
          <FCLContainerTimelineRow
            shipment={shipment}
            container={container}
            key={index}
            index={index}
            direction="vertical"
            onContainerClick={onContainerClick}
            noBackground
          />
        ))}
    </Grid>
  )
}


const styles = theme => ({
  dot: { fontSize: 6, marginLeft: 2, marginRight: 2 },
  icon: { marginRight: 5 },
  spanLeft: {
    color: '#7F7F7F',
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'left',
    marginLeft: 10,
    marginTop: 5
  },
  sectionTitle: {
    fontSize: 14,
    color: '#9fa0a4'
  },
  inputStyles: {
    '& > div > input': {
      fontSize: 14
    },
    '& > label': {
      fontSize: 12,
      color: '#8795A1'
    }
  },
  containerTimelineRow: {
    row: {
      backgroundColor: '#FFFFFF',
      borderTop: '.5px solid #ebebeb'
    }
  }
})

export default withStyles(styles)(ContainerDetails)
