import React, { useState, useMemo } from 'react'
import CheckBox from './CheckBox'
import { FormGroup, FormControlLabel } from '@material-ui/core'

/**
 *
 * @param {Array} value   initial selected
 * @param {Array} data    checkbox data { label, value, checked }
 * @param {String} chkClassName   checkbox class config
 */

const CheckBoxGroup = ({ classes, name, value, data, className, chkClassName, onChange, ...other }) => {
  const [selected, setSelected] = useState([])

  useMemo(() => {
    setSelected(value || [])
  }, [value])

  const onClick = e => {
    const currentValue = e.target.value
    if (currentValue) {
      const newSelected = [...selected]
      const currentValueIndex = newSelected.indexOf(currentValue)
      const currentEvent = {
        name,
        value: currentValue
      }

      if (currentValueIndex !== -1) {
        newSelected.splice(currentValueIndex, 1)
        currentEvent.checked = false
      } else {
        newSelected.push(currentValue)
        currentEvent.checked = true
      }

      setSelected(newSelected)
      onChange(newSelected, currentEvent)
    }
  }

  return (
    <FormGroup className={className}>
      {data &&
        data.map((v, i) => (
          <FormControlLabel
            key={i}
            name={name}
            label={<span className={chkClassName}>{v.label}</span>}
            control={<CheckBox checked={selected.indexOf(v.value) !== -1} value={v.value} onClick={onClick} />}
            {...other}
            disabled={other.disabled || v.disabled}
          />
        ))}
    </FormGroup>
  )
}

export default CheckBoxGroup
