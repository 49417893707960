import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  LoadingButton,
} from '../../core/components'
import { get, pick, compact } from "lodash";
import { addGaEvent } from '../../core/helpers'

const RemoveOutboundDialog = props => {
  const {
    open,
    setRemoveOutboundsDialog,
    selectedOutbounds,
    onSubmit,
  } = props
  const [requesting, setRequesting] = useState(false)

  useEffect(() => {
    if (open) {
      setRequesting(false)
      addGaEvent('shipment-removingConsolidation', get(props, 'shipment._id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleSubmit = (closeOutbounds = false) => {
    setRequesting(closeOutbounds ? 'closing-outbounds' : 'reopening-outbounds')
    const { shipment } = props
    let payload = {
      outboundsDelta: {
        add: [],
        remove: compact(selectedOutbounds.map(item => get(item, '_id')))
      }
    }

    if (get(shipment, 'type') === 'FCL') {
      payload = {
        ...payload,
        ...pick(shipment, ['_id', 'type', 'groupageDate', 'transportMethod', 'containers'])
      }
    } else {
      payload = {
        ...payload,
        ...pick(shipment, ['_id', 'type', 'groupageDate', 'transportMethod', 'referenceEvents'])
      }
    }

    if (closeOutbounds) {
      payload.closeOutbounds = true
    }

    onSubmit(payload,
      () => {
        setRequesting(false)
        setRemoveOutboundsDialog(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }
  return (
    <FuseDialog open={open} wMD>
      <FuseDialogHeader text="Remove Outbounds"
        handleClose={() => {
          addGaEvent('shipment-removingConsolidationCancelled', get(props, 'shipment._id'))
          setRemoveOutboundsDialog(false)
        }} />
      <FuseDialogBody>
        <Grid container className="text-14">
          <Grid item xs={12}
            className="bg-white p-20 min-h-full">
            <Grid container className="h-full">

              <Grid item xs={12}
                className="text-16 medium pb-40">
                <label className="block mb-4">
                  You are about to delete {selectedOutbounds.length} outbound(s) from this shipment.</label>
                <label className="block">
                  Do you want to reopen them or close these outbounds?</label>
              </Grid>

              {/* Actions */}
              <Grid item xs={12} className='pt-40'>
                <Grid container className="h-full">
                  <Grid item xs={12}>
                    <Grid container spacing={3}>

                      {/* Reopen outbounds */}
                      <Grid item xs={6}>
                        <LoadingButton className="btn w-full"
                          loading={requesting === 'reopening-outbounds'}
                          disabled={requesting ? true : false}
                          style={{
                            paddingLeft: '30px',
                            paddingRight: '30px',
                          }}
                          onClick={() => handleSubmit()}>
                          Reopen outbounds</LoadingButton>
                        <label className="text-10 fg-gray">Reopened outbounds will go to&nbsp;
                          <span className="semi-bold">Manage Booking</span> page</label>
                      </Grid>
                      {/* End of Reopen outbounds */}

                      {/* Close outbounds */}
                      <Grid item xs={6}>
                        <LoadingButton className="btn w-full btn_delete"
                          loading={requesting === 'closing-outbounds'}
                          disabled={requesting ? true : false}
                          style={{
                            paddingLeft: '30px',
                            paddingRight: '30px',
                          }}
                          onClick={() => { handleSubmit(true) }}>
                          Close outbounds</LoadingButton>
                        <label className="text-10 fg-gray">Closed outbounds will go to&nbsp;
                          <span className="semi-bold">Closed</span> page</label>
                      </Grid>
                      {/* End of Close outbounds */}

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* END of Actions */}
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default RemoveOutboundDialog
