import React, { useState, useEffect } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  FuseTextField,
  FuseButtonToggle, LoadingButton
} from '../../core/components'
import { get, pick } from 'lodash'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import MenuIcon from '@material-ui/icons/Menu';
import { AutoComplete } from 'core/components/Inputs';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

const taskNameInput = {
  fontFamily: 'IBMPlexSans-SemiBold',
  fontSize: '20px'
}
const baseTextSetting = {
  position: 'end',
  className: 'text-20 cursor-pointer',
  color: 'action'
}
const inputStyles = {
  fontFamily: 'IBMPlexSans',
  fontSize: '14px'
}

const priorityOptions = ['high', 'medium', 'low'].map(i => ({ value: i, label: i.toUpperCase() }))
const assigneeTeams = ['shipper', 'dropHub', 'consignee', 'ofp', 'ifp', 'obl', 'ibl', 'obc', 'ibc'].map(i => ({ value: i, label: i.toUpperCase() }))
const assigneeRoles = ['auditor', 'limited', 'admin', 'none'].map(i => ({ value: i, label: i.toUpperCase() }))
const actionTypes = ['manual', 'machine'].map(i => ({ value: i, label: i.toUpperCase() }))
const actionCategories = ['event', 'document', 'booking-action', 'ad-hoc'].map(i => ({ value: i, label: i.toUpperCase() }))
const shipmentModes = ['air', 'ocean', 'CBT', 'rail', 'NA'].map(i => ({
  value: i,
  label: i === 'NA' ? 'OTHER' : i.toUpperCase()
}))
const shipmentTypes = ['FCL', 'LCL', 'NA'].map(i => ({ value: i, label: i.toUpperCase() }))
const specialLabels = ['formd', 'formak', 'forma', 'forme', 'formai'].map(i => ({ value: i, label: i.toUpperCase() }))

const CreateTemplateDialog = ({ getEventDocList, eventDocs, open, handleClose, users, controllerDictionary, onSave, getTemplates }) => {
  const [state, setState] = useState()
  const [isValid, setIsValid] = useState(false)
  const [requesting, setRequesting] = useState(false)
  const [actionCodes, setActionCodes] = useState([])
  const assigneeOptions = users.map(user => ({
    value: get(user, '_id'),
    label: [get(user, 'firstname'), get(user, 'lastname')].filter(Boolean).join(' ') || get(user, 'email')
  }))

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'eventDays' && value <= 0) {
      return
    }

    setState({
      ...state,
      [name]: value
    })
  }

  const handleSelectChange = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
    if (name === 'shipmentMode' && get(state, 'shipmentMode') !== value) {
      getEventDocList({ transportMethod: value })
    }
  }

  const handleSaveTemplate = () => {
    setRequesting(true)
    const payload = {
      ...pick(state, ['name', 'description', 'priority']),
      assignee: {
        role: get(state, 'assigneeRole', []),
        user: get(state, 'assigneeUser', []),
        team: get(state, 'assigneeTeam', []),
      },
      identifier: {
        ...pick(state, ['shipmentMode', 'shipmentType', 'specialLabel'])
      },
      action: {
        type: get(state, 'actionType'),
        category: get(state, 'actionCategory'),
        code: get(state, 'actionCode')
      }
    }

    onSave(payload, () => {
      getTemplates({ pageNo: 1, pageSize: 10 })
      handleClose()
    }, () => setRequesting(false))
  }

  useEffect(() => {
    setIsValid(true)
    if (!get(state, 'priority')) {
      setState({ ...state, priority: 'medium' })
    }
    if (!get(state, 'name') ||
      !get(state, 'description') ||
      !get(state, 'shipmentMode') ||
      get(state, 'shipmentType', []).length === 0 ||
      get(state, 'specialLabel', []).length === 0 ||
      get(state, 'assigneeTeam', []).length === 0 ||
      !get(state, 'actionType') ||
      !get(state, 'actionCategory') ||
      !get(state, 'actionCode')) {
      setIsValid(false)
    }
  }, [state])

  useEffect(() => {
    setActionCodes(controllerDictionary.map(a => ({ value: get(a, 'code'), label: get(a, 'name') })))
  }, [controllerDictionary])

  useEffect(() => {
    const actionCategory = get(state, 'actionCategory')
    if (actionCategory) {
      if (actionCategory === 'event') {
        setActionCodes(get(eventDocs, 'events', []).map(i => ({ value: get(i, 'code'), label: get(i, 'name') })))
      } else if (actionCategory === 'document') {
        setActionCodes(get(eventDocs, 'documents', []).map(i => ({ value: get(i, 'targetEvent.code'), label: get(i, 'targetEvent.name') })))
      } else if (actionCategory === 'booking-action') {
        setActionCodes(controllerDictionary.map(a => ({ value: get(a, 'code'), label: get(a, 'name') })))
      } else {
        setActionCodes([])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDocs, get(state, 'actionCategory')])

  useEffect(() => {
    if (open === false) {
      setState()
      setRequesting(false)
    }
  }, [open])

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text="Create new task template" handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="p-20">
          <Grid item xs={12}>
            <Grid container spacing={2}>

              {/* Task Name */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <AssignmentTurnedInIcon className="text-grey text-36" />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label className="text-14 semi-bold fg-gray require-field">Name and Priority</label>
                      </Grid>
                      <Grid item xs={8}>
                        <FuseTextField name='name'
                          placeholder='Add the task name'
                          icon={{ ...baseTextSetting, name: 'none' }}
                          value={get(state, 'name', '')}
                          inputStyles={taskNameInput}
                          onChange={handleChange}
                          required
                          fullWidth />
                      </Grid>
                      <Grid item xs={4}>
                        <Grid container justify="flex-end">
                          <FuseButtonToggle name='priority'
                            data={priorityOptions}
                            value={get(state, 'priority', 'medium')}
                            buttonClass='text-12 bold'
                            onChange={(value) => handleSelectChange('priority', value)} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <MenuIcon className="text-grey text-36" />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label className="text-14 semi-bold fg-gray require-field">Description</label>
                      </Grid>
                      <Grid item xs={12}>
                        <FuseTextField name='description'
                          icon={{ ...baseTextSetting, name: 'ship' }}
                          value={get(state, 'description', '')}
                          inputStyles={inputStyles}
                          onChange={handleChange}
                          placeholder="Enter description"
                          required
                          fullWidth
                          multiline />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Identifier */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <InfoRoundedIcon className="text-grey text-36" />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label className="text-14 semi-bold fg-gray require-field">Identifiers</label>
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray require-field">Shipment Mode</label>
                        <AutoComplete options={shipmentModes}
                          className="text-14 regular"
                          value={get(state, 'shipmentMode', '')}
                          onChange={(value) => handleSelectChange('shipmentMode', value)} />
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray require-field">Shipment Types</label>
                        <AutoComplete options={shipmentTypes}
                          className="text-14 regular"
                          value={get(state, 'shipmentType', [])}
                          onChange={(value) => handleSelectChange('shipmentType', value)}
                          isMulti />
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray require-field">Special Labels</label>
                        <AutoComplete options={specialLabels}
                          className="text-14 regular"
                          value={get(state, 'specialLabel', [])}
                          onChange={(value) => handleSelectChange('specialLabel', value)}
                          isMulti />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Assignee */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <Grid container alignItems="center">
                      <AccountCircleIcon className="text-grey text-36" />
                    </Grid>
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label className="text-14 semi-bold fg-gray require-field">Assignee</label>
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray">Users</label>
                        <AutoComplete options={assigneeOptions}
                          className="text-14 regular"
                          value={get(state, 'assigneeUser', [])}
                          onChange={(value) => handleSelectChange('assigneeUser', value)}
                          isMulti
                          dynamic />
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray require-field">Teams</label>
                        <AutoComplete options={assigneeTeams}
                          className="text-14 regular"
                          value={get(state, 'assigneeTeam', [])}
                          onChange={(value) => handleSelectChange('assigneeTeam', value)}
                          isMulti />
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray">Roles</label>
                        <AutoComplete options={assigneeRoles}
                          className="text-14 regular"
                          value={get(state, 'assigneeRole', [])}
                          onChange={(value) => handleSelectChange('assigneeRole', value)}
                          isMulti />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Action */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <TouchAppIcon className="text-grey text-36" />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <label className="text-14 semi-bold fg-gray require-field">Action</label>
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray require-field">Type</label>
                        <AutoComplete options={actionTypes}
                          className="text-14 regular"
                          value={get(state, 'actionType', '')}
                          onChange={(value) => handleSelectChange('actionType', value)} />
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray require-field">Category</label>
                        <AutoComplete options={actionCategories}
                          className="text-14 regular"
                          value={get(state, 'actionCategory', '')}
                          onChange={(value) => handleSelectChange('actionCategory', value)} />
                      </Grid>
                      <Grid item xs={4}>
                        <label className="text-12 fg-gray require-field">Code</label>
                        <AutoComplete options={actionCodes}
                          className="text-14 regular"
                          value={get(state, 'actionCode', '')}
                          onChange={(value) => handleSelectChange('actionCode', value)}
                          dynamic
                          canCreate
                          showAllOptions />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} className="pt-40">
            <Grid container alignItems="center" justify="flex-end">
              <Grid item xs={12} sm={6} md={4}>
                <LoadingButton className="btn"
                  disabled={!isValid}
                  loading={requesting}
                  onClick={handleSaveTemplate}
                  fullWidth>save</LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody >
    </FuseDialog >
  )
}

const styles = {}

export default withStyles(styles)(CreateTemplateDialog)
