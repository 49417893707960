import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  SET_CURRENT_PLANT
  API_ATTEMPT
  API_SUCCESS
  API_FAILED
  `
)
