import ActionTypes from './types'
import * as API from '../../core/api'
import * as CoreActions from '../../core/store/actions'
import { checkPagination } from '../../core/helpers'
import { get } from 'lodash'

export const getPreAlertEmails = (params, success = null, fail = null) => async dispatch => {
  const { pageNo, pageSize, status } = params

  if (status === 'processed') {
    if (pageNo > 1) {
      dispatch({ type: ActionTypes.LOADING_MORE_PROCESSED_PRE_ALERT_EMAILS })
    }
  } else {
    if (pageNo > 1) {
      dispatch({ type: ActionTypes.LOADING_MORE_PRE_ALERT_EMAILS })
    }
  }

  try {
    const { ok, data } = await API.getPreAlertEmails(params)

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination({ pageNo, pageSize }, data)
    if (status === 'processed') {
      if (pageNo === 1) {
        dispatch({
          type: ActionTypes.SET_PROCESSED_PRE_ALERT_EMAILS_SUCCESS,
          payload: data
        })
      } else {
        dispatch({
          type: ActionTypes.LOAD_MORE_PROCESSED_PRE_ALERT_EMAILS_SUCCESS,
          payload: data
        })
      }
    } else {
      if (pageNo === 1) {
        dispatch({
          type: ActionTypes.SET_PRE_ALERT_EMAILS_SUCCESS,
          payload: data
        })
      } else {
        dispatch({
          type: ActionTypes.LOAD_MORE_PRE_ALERT_EMAILS_SUCCESS,
          payload: data
        })
      }
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getShipments = (params, success = null, fail = null) => async dispatch => {
  const { pageNo, pageSize, search } = params

  if (pageNo === 1) {
    dispatch({ type: ActionTypes.LOADING_SHIPMENTS })
  } else {
    dispatch({ type: ActionTypes.LOADING_MORE_SHIPMENTS })
  }

  try {
    params.stage = ['in-transit', 'import']
    if (search) {
      params = {
        ...params,
        'multi-search': search
      }
    }
    const { ok, data } = await API.getAllShipmentsWithFilter(params)

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination({ pageNo, pageSize }, data)
    if (pageNo === 1) {
      dispatch({
        type: ActionTypes.SET_SHIPMENTS_SUCCESS,
        payload: data
      })
    } else {
      dispatch({
        type: ActionTypes.LOAD_MORE_SHIPMENTS_SUCCESS,
        payload: data
      })
    }


    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const saveShipment = (params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.saveInboundShipment(params)

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const addToShipment = (shipmentId, params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.addInboundToShipment(shipmentId, params)

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getServiceCardByItineraryId = (itineraryId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getConsigneeServiceCards({ itineraryId })

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success(get(data, 'data', []))
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}
