import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

export default () => {
  return (
    <Grid container className='shipment-events'>
      {[1, 2, 3].map((rootItem => {
        return (
          <Grid item xs={12} key={rootItem}
            className="shipment-events-item big_dots">
            <Grid container
              className='shipment-events-item-container pl-20 pb-28 pt-4'
              alignItems="center">
              <Grid item xs={6}>
                <Grid container alignItems="center">
                  <Grid item xs={4} className="pr-6"><Skeleton /></Grid>
                  <Grid item className="fg-gray">&bull;</Grid>
                  <Grid item xs={2} className="px-6"><Skeleton /></Grid>
                  <Grid item className="fg-gray">&bull;</Grid>
                  <Grid item xs={2} className="pl-6"><Skeleton /></Grid>
                  <Grid item xs={8}><Skeleton /></Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center"
                  justify="flex-end"
                  spacing={2}>
                  <Grid item xs={6}>
                    <Skeleton />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      }))}
    </Grid>
  )
}

