import React, { useState } from 'react'
import {
  Grid,
  Typography,
  withStyles,
  Icon,
  Card,
  Zoom,
  ClickAwayListener,
  Badge
} from '@material-ui/core'
import { get, find } from 'lodash'
import classNames from 'classnames'
import { getStatusHistory, getExceptionTypes } from '../../helpers'
import LoadingButton from '../LoadingButton'
import { InfoToolTip } from '../'
import MenuButton from './MenuButton'
import ForumIcon from '@material-ui/icons/Forum';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Link } from 'react-router-dom'

const getStatusHoverText = (statusType, shipment) => {
  switch (statusType) {
    case 'exception-type':
      /** @TODO Show all exception types in a list */
      return getExceptionTypes(shipment.exceptionMode.details)
    case 'current-status': {
      return getStatusHistory(shipment.status)
    }
    case 'time-delay': {
      /** @TODO
       * if 1-3 days delayed - yellow,
       * if 4 to 7 orange,
       * if 8 + red. ontime is green */
      return ''
    }
    default:
      return ''
  }
}

const RightBlock = props => {
  const {
    shipment,
    tagValue,
    tagClass,
    statusType,
    actionButtonProps,
    onPartnerSidebarClick,
    onOutboundsSidebarClick,
    countPartners,
    classes,
    onDocSidebarClick,
    vesselTrackDialog,
    showMessage
  } = props
  const [showVesselPopup, setShowVesselPopup] = useState(false)

  const prepareTrackerPopup = () => {
    let scheduledVessels = [];
    scheduledVessels.push(get(shipment, 'vesselSchedule.FirstPOL.vessel'))
    get(shipment, 'vesselSchedule.transhipmentPorts', []).forEach(item => {
      const vessel = get(item, 'vessel')
      if (!find(scheduledVessels, vessel)) {
        scheduledVessels.push(vessel)
      }
    })
    if (scheduledVessels.length > 0) {
      if (scheduledVessels.length === 1) {
        vesselTrackDialog(scheduledVessels[0])
      } else {
        setShowVesselPopup(scheduledVessels)
      }
    } else {
      showMessage({
        variant: 'info',
        message: 'Vessel information not available'
      })
      setShowVesselPopup(false)
    }
  }

  return (
    <Grid container alignItems='stretch' className='h-full' spacing={1}>
      {((get(shipment, 'stage.current.value') === 'in-transit' ||
        get(shipment, 'stage.current.value') === 'import') &&
        get(shipment, 'summary.tracking.trackingLink')) &&
        <Grid item>
          <Grid container
            justify='flex-start'
            alignItems='center'
            className='mb-20'>
            <InfoToolTip title={`Track shipment using global tracker`}>
              <Grid item
                onClick={() => window.open(get(shipment, 'summary.tracking.trackingLink', ''), '_blank')}
                className={'shipment-list-item-tag cursor-pointer green-tag'}>
                Track</Grid>
            </InfoToolTip>

            {get(shipment, 'stage.current.value') === 'in-transit' &&
              <Grid item className="px-12">
                <Grid container
                  alignItems="center"
                  className="relative">
                  <InfoToolTip title={`Track vessel information`}>
                    <Icon className="fas fa-satellite fg-green text-16 cursor-pointer"
                      onClick={prepareTrackerPopup} />
                  </InfoToolTip>
                  {showVesselPopup &&
                    <ClickAwayListener onClickAway={() => setShowVesselPopup(false)}>
                      <Zoom direction="down"
                        in={showVesselPopup !== false}>
                        <Card className="border-solid border-1 border-gray shadow-none rounded-4 bg-white"
                          style={{
                            position: 'absolute',
                            top: 25,
                            width: 200,
                            marginLeft: -100,
                            zIndex: 1
                          }}>
                          <Grid container>
                            <Grid item xs={12}
                              className="p-8 border-solid bold border-b-1 border-gray">
                              Select Vessel
                        </Grid>
                            <Grid item xs={12} className="overflow-hidden p-8">
                              <Grid container className="max-h-84 overflow-x-auto"
                                spacing={2}>
                                {showVesselPopup && showVesselPopup
                                  .map((item, itemIndex) => {
                                    return (
                                      <Grid item key={itemIndex} xs={12}
                                        className={itemIndex > 0 && 'border-solid border-t-1 border-gray'}
                                        onClick={() => {
                                          vesselTrackDialog(item)
                                          setShowVesselPopup(false)
                                        }}>
                                        <InfoToolTip title={`${get(item, 'name')} (${get(item, 'IMO')})`}>
                                          <Grid container
                                            spacing={1}
                                            alignItems="center"
                                            className="cursor-pointer">
                                            <Grid item xs={8}>
                                              <Typography className="text-12 medium"
                                                noWrap>
                                                {get(item, 'name')}
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={4} className="text-right">
                                              <Typography className="text-12 medium"
                                                noWrap>
                                                {get(item, 'IMO')}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </InfoToolTip>
                                      </Grid>
                                    )
                                  })
                                }
                              </Grid>
                            </Grid>
                            <Grid item xs={12}
                              className="bg-green text-white text-center cursor-pointer p-8 bold"
                              onClick={() => setShowVesselPopup(false)}>
                              CLOSE</Grid>
                          </Grid>
                        </Card>
                      </Zoom>
                    </ClickAwayListener>
                  }
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      }


      <Grid item>
        <Grid container justify='flex-start' alignItems='center' spacing={2}>
          <Grid item>
            <Link to={`/tasks?shipmentId=${get(shipment, '_id')}`}>
              <Grid container alignItems="center"
                className="bg-transparent-green fg-green px-4 rounded">
                <PlaylistAddCheckIcon className="fg-green text-14 mr-4" />
                <Typography className="fg-green text-10 uppercase bold">tasks</Typography>
              </Grid>
            </Link>
          </Grid>

          {get(shipment, 'spotMode.enabled', false) &&
            <Grid item>
              <Grid container wrap='nowrap'>
                <Grid item>
                  <img src='assets/images/icons/shipments-list/Exception-Orange@3x.svg'
                    alt='Spot' />
                </Grid>
                <Grid item className={classes.spot}>SPOT</Grid>
              </Grid>
            </Grid>
          }

          {get(shipment, 'summary.posts', 0) > 0 &&
            <Grid item>
              <InfoToolTip title={`${get(shipment, 'summary.posts', 0)} Notices`}>
                <Badge badgeContent={get(shipment, 'summary.posts', 0)}
                  color='secondary'
                  classes={{
                    anchorOriginTopRightRectangle: classes.badge
                  }}>
                  <ForumIcon className="text-20 fg-gray" />
                </Badge>
              </InfoToolTip>
            </Grid>
          }
        </Grid>
      </Grid>

      <Grid item xs>
        <Grid container
          justify='flex-end'
          alignItems='center'
          className='mb-20'>
          {tagValue.slice(0, 2).map(tag => {
            return (
              <Grid item
                title={getStatusHoverText(statusType, shipment)}
                className={classNames('shipment-list-item-tag cursor-pointer', tagClass)}
                key={tag}>
                {tag}
              </Grid>
            )
          })}
          {tagValue.length > 2 &&
            <Grid item
              title={getStatusHoverText(statusType, shipment)}
              className={classNames('shipment-list-item-tag cursor-pointer', tagClass)}>
              +{tagValue.length - 2}
            </Grid>
          }
          {get(shipment, 'customClearance.flag.status') &&
            <Grid item className="pl-12">
              <Icon className={`fas fa-flag text-16 fg-${get(shipment, 'customClearance.flag.status')}`} />
            </Grid>
          }
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container alignItems="center">
          <Grid item xs={6} sm={6} md={6}
            className='shipment-list-item-summery__key'>
            Partners</Grid>
          <Grid item xs={6} sm={6} md={6}
            onClick={() => onPartnerSidebarClick(get(shipment, 'actors'))}>
            <Typography className='shipment-list-item-summery__value text-right'>
              {countPartners(shipment)}&nbsp;partners involved</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}
            className='shipment-list-item-summery__key'>
            CS/Outbounds</Grid>
          <Grid item xs={6} sm={6} md={6}
            onClick={() => onOutboundsSidebarClick(shipment)} style={{ textAlign: 'right' }}>
            <Typography className='shipment-list-item-summery__value text-right'>
              {get(shipment, 'consolidations', []).length}&nbsp;CS,&nbsp;
              {get(shipment, 'outbounds', []).length}&nbsp;outbounds</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6} sm={6} md={6}
            className='shipment-list-item-summery__key'>
            Documents</Grid>
          <Grid item xs={6} sm={6} md={6}
            style={{ textAlign: 'right' }}>
            <Typography className='shipment-list-item-summery__value no-underline text-right'>
              {get(shipment, 'summary.actions.docs.me.done', []).length}&nbsp;uploaded,{' '}
              <label onClick={onDocSidebarClick}
                style={{
                  cursor: 'pointer',
                  borderBottom: '1px dashed',
                  borderColor: '#cdced1',
                  paddingBottom: '2px'
                }}>
                {get(shipment, 'summary.actions.docs.me.pending', []).length}&nbsp;pending</label>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container className='shipment-list-item-actions'>
          {!actionButtonProps.isMenuButton &&
            <LoadingButton {...actionButtonProps}>
              {get(actionButtonProps, 'title')}
              {get(actionButtonProps, 'progress') >= 0 &&
                <span className={"progress progress_orange"}
                  style={{
                    width: `${get(actionButtonProps, 'progress', 0)}%`
                  }}></span>
              }
            </LoadingButton>
          }
          {actionButtonProps.isMenuButton &&
            <Grid item xs={12}>
              <MenuButton {...actionButtonProps} />
            </Grid>
          }
          {get(actionButtonProps, 'note') &&
            <InfoToolTip title={get(actionButtonProps, 'notetitle')}>
              <Grid item xs={12}
                className="pt-4 text-center text-12 fg-gray regular">
                {get(actionButtonProps, 'note')}
              </Grid>
            </InfoToolTip>
          }
        </Grid>
      </Grid>
    </Grid >
  )
}

const styles = theme => ({
  spot: {
    color: '#E47F00',
    fontSize: 11,
    fontWeight: 400,
    textAlign: 'left',
    paddingLeft: 2,
    paddingTop: 2
  },
  buttonMenuPaper: {
    right: 0,
    top: 40
  },
  badge: {
    fontSize: 9,
    backgroundColor: '#E47F00',
    height: 16,
    minWidth: 16
  }
})
export default withStyles(styles, { withTheme: true })(RightBlock)
