import React, { useState, useEffect } from 'react'
import {
  withStyles,
  Grid,
  Typography,
  Button
} from '@material-ui/core'
import { connect } from 'react-redux'
import * as Actions from '../store/actions'
import * as ScheduleActions from '../../schedule/store/actions'
import * as CoreActions from '../../core/store/actions'
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout'
import { find, get, set, pick } from 'lodash'
import TaskList from '../components/TaskList'
import CreateTaskDialog from '../components/CreateTaskDialog'
import { AutoComplete } from '../../core/components/Inputs'
import FileUploadDialog from '../components/FileUploadDialog'
import AddEventSidebar from '../components/AddEventSidebar'
import ReviewBookingDialog from '../../schedule/components/ReviewBookingDialog'
import RejectBookingDialog from '../../schedule/components/RejectBookingDialog'
import FPAcceptBookingDialog from '../../schedule/components/FPAcceptBookingDialog'
import ELPAcknowledgeBookingDialog from '../../schedule/components/ELPAcknowledgeBookingDialog'
import MarkAsReceivedDialog from '../components/MarkAsReceivedDialog'
import { FuseCheckBox } from '../../core/components'
import queryString from 'query-string'
import ShipmentSearch from '../components/ShipmentSearch'
import DateTime from 'react-datetime';
import CloseIcon from '@material-ui/icons/Close';
import DismissConfirm from '../components/DismissConfirm'
import AdHocConfirm from '../components/AdHocConfirm'
import ReviewCustomClearance from '../../manage-incoming/components/ReviewCustomClearance'
import ScheduleDelivery from '../../inbound-schedule/components/ScheduleDelivery'
import UpdateShipmentStatus from '../../manage-incoming/components/UpdateShipmentStatus'
import ResolveException from '../../exceptions/components/ResolveException'
import TaskTemplates from '../components/TaskTemplates'
import CreateTemplateDialog from '../components/CreateTemplateDialog'


const sortOptions = [{
  value: 'task.priorityIndex-desc',
  label: 'PRIORITY: High to Low'
}, {
  value: 'task.priorityIndex-asc',
  label: 'PRIORITY: Low to High'
}]

const filterSelectStyle = {
  control: () => ({
    display: 'flex',
    border: '1px solid rgba(159, 160, 164, 0.2)',
    borderRadius: '2px',
    padding: '5px',
    height: '32px'
  }),
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  }),
}

const CountComponent = ({ className, count, label }) => {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Typography className={className}>{count}</Typography>
      </Grid>
      <Grid item>
        <Typography className="uppercase semi-bold fg-gray text-12">{label}</Typography>
      </Grid>
    </Grid>
  )
}

const TasksPage = (props) => {
  const {
    classes,
    incomplete,
    blocked,
    completed,
    getTasks,
    getShipmentDetails,
    getAllShipmentsLight,
    getActionsDictionary,
    getShipmentTasksById,
    getEventsList,
    getPlantUsers,
    createNewTask,
    getShipmentMeta,
    currentPlant,
    shipments,
    resolveFpConflicts,
    limitedServiceCards,
    getLimitedServiceCards,
    vessels,
    getVessels,
    getShipmentEventWriteList,
    addShipmentEvents,
    getEventDocList,
    searchShipments,
    showMessage,
    resolveConflict,
    markAsSpot,
    resolveSpot,
    getPlants,
    templates,
    getTemplates,
    createTemplates,
    location: { search }
  } = props
  const [createTaskDialog, setCreateTaskDialog] = useState(false)
  const [filters, setFilters] = useState(false)
  const [selectedTask, setSelectedTask] = useState(null)
  const [elpNotes, setElpNotes] = useState('')
  const [requesting, setRequesting] = useState(false)
  const [selectedShipment, setSelectedShipment] = useState({})
  const [documentUpload, setDocumentUpload] = useState(false)
  const [addEvent, setAddEvent] = useState(false)
  const [reviewBooking, setReviewBooking] = useState(false)
  const [fpRejectDialog, setFpRejectDialog] = useState(false)
  const [fpAcceptDialog, setFpAcceptDialog] = useState(false)
  const [elpAcceptDialog, setElpAcceptDialog] = useState(false)
  const [markAsReceived, setMarkAsReceived] = useState(false)
  const [incompletePageNo, setIncompletePageNo] = useState(1)
  const [blockedPageNo, setBlockedPageNo] = useState(1)
  const [completePageNo, setCompletePageNo] = useState(1)
  const [dismissConfirmOpen, setDismissConfirmOpen] = useState(false)
  const [adHocConfirmOpen, setAdHocConfirmOpen] = useState(false)
  const [reviewCustomClearance, setReviewCustomClearance] = useState(false)
  const [scheduleDelivery, setScheduleDelivery] = useState(false)
  const [customClearance, setCustomClearance] = useState(false)
  const [resolvingShipment, setResolvingShipment] = useState(null)
  const [taskTemplatesSideBar, setTaskTemplatesSideBar] = useState(false)
  const [createTemplateDialog, setCreateTemplateDialog] = useState(false)
  const pageSize = 10

  const getFilterParams = () => {
    let assignee = undefined
    if (get(filters, 'myTasks') === true) {
      assignee = [get(props, 'userInfo._id')]
    }
    const params = {
      assignee,
      ...pick(filters, ['shipmentId', 'sortBy', 'sortMode', 'taskStartDate', 'taskEndDate'])
    }
    return params
  }

  const loadTasks = (isSync = false) => {
    let pageNo = 1
    setIncompletePageNo(pageNo)
    setBlockedPageNo(pageNo)
    setCompletePageNo(pageNo)
    if (filters === false) {
      getTasks(isSync, { pageNo, pageSize }, { status: 'incomplete' })
      getTasks(isSync, { pageNo, pageSize }, { status: 'blocked' })
      getTasks(isSync, { pageNo, pageSize }, { status: 'completed' })
    } else {
      getTasks(isSync, { pageNo, pageSize }, { status: 'incomplete', ...getFilterParams() })
      getTasks(isSync, { pageNo, pageSize }, { status: 'blocked', ...getFilterParams() })
      getTasks(isSync, { pageNo, pageSize }, { status: 'completed', ...getFilterParams() })
    }
  }

  const loadMoreIncomplete = () => {
    let pageNo = incompletePageNo
    pageNo++
    setIncompletePageNo(pageNo)
    if (filters === false) {
      getTasks(true, { pageNo, pageSize }, { status: 'incomplete' })
    } else {
      getTasks(true, { pageNo, pageSize }, { status: 'incomplete', ...getFilterParams() })
    }
  }

  const loadMoreBlocked = () => {
    let pageNo = blockedPageNo
    pageNo++
    setBlockedPageNo(pageNo)
    if (filters === false) {
      getTasks(true, { pageNo, pageSize }, { status: 'blocked' })
    } else {
      getTasks(true, { pageNo, pageSize }, { status: 'blocked', ...getFilterParams() })
    }
  }

  const loadMoreCompleted = () => {
    let pageNo = completePageNo
    pageNo++
    setCompletePageNo(pageNo)
    if (filters === false) {
      getTasks(true, { pageNo, pageSize }, { status: 'completed' })
    } else {
      getTasks(true, { pageNo, pageSize }, { status: 'completed', ...getFilterParams() })
    }
  }

  const handleTaskAction = (task = null) => {
    const shipmentId = get(selectedTask, 'shipmentId._id', get(task, 'shipmentId._id'))
    const taskCategory = get(selectedTask, 'task.action.category', get(task, 'task.action.category'))
    const taskCode = get(selectedTask, 'task.action.code', get(task, 'task.action.code'))

    switch (taskCategory) {
      case 'event':
        setRequesting(true)
        getShipmentEventWriteList(shipmentId, () => {
          setRequesting(false)
          setAddEvent(true)
        }, () => {
          setRequesting(false)
        })
        break;
      case 'document':
        setRequesting(true)
        getShipmentMeta(shipmentId, () => {
          setRequesting(false)
          setDocumentUpload(true)
        }, () => {
          setRequesting(false)
        })
        break;
      case 'ad-hoc':
        setAdHocConfirmOpen(true)
        break;

      default:
        switch (taskCode) {
          case 'D007':
          case 'D008':
            setResolvingShipment({ ...selectedShipment })
            break;
          case 'D021':
          case 'D024':
            setReviewBooking(true)
            break;
          case 'D027':
            setMarkAsReceived(true)
            break;
          case 'I001':
            setCustomClearance(true)
            break;
          case 'I002':
            setReviewCustomClearance(true)
            break;
          case 'I003':
            setScheduleDelivery(true)
            break;

          default:
            handleMarkComplete()
            break;
        }
        break;
    }
  }

  const handleCreateSubmit = (payload) => {
    setRequesting(true)
    createNewTask(payload, () => {
      setCreateTaskDialog(false)
      setRequesting(false)
      loadTasks(true)
    }, () => {
      setRequesting(false)
    })
  }

  const performMarkComplete = () => {
    setRequesting(true)
    const shipmentId = get(selectedTask, 'shipmentId._id')
    getShipmentDetails(shipmentId, () => {
      setRequesting(false)
      handleTaskAction()
    }, () => {
      setRequesting(false)
    })
  }

  const handleMarkComplete = (callback = null) => {
    const pageNo = 1
    setCompletePageNo(pageNo)
    setSelectedTask(null)
    loadTasks(true)
    if (callback) {
      callback()
    }
  }

  const onRejectSubmit = (payload, shipmentId) => {
    setRequesting(true)
    const { freightPartnerRejectBooking } = props
    freightPartnerRejectBooking(payload, shipmentId,
      () => {
        setRequesting(false)
        setFpRejectDialog(false)
        handleMarkComplete()
      },
      () => {
        setRequesting(false)
      })
  }

  const onFreightPartnerAcceptSubmit = (shippingData, containerScheduleData, bookingData, shipmentContext) => {
    const documentCount = get(bookingData, 'documentUploads', []).length
    let uploadedDocumentCount = 0

    const { freightPartnerAcceptBooking, uploadShipmentFile } = props
    const payload = {
      referenceNos: {
        bookingReferenceNo: get(bookingData, 'bookingDetails.bookingRefNumber', ''),
        carrierBookingNo: get(bookingData, 'bookingDetails.carrierBookingNumber', ''),
        HBLNo: get(bookingData, 'bookingDetails.hblNumber', '').split(','),
        MBLNo: get(bookingData, 'bookingDetails.mblNumber', '').split(',')
      },
      freightOperatorInfo: {
        name: get(shippingData, 'carrierName', ''),
        identifier: {
          type: 'SCAC',
          value: get(shippingData, 'scacCode', '')
        }
      },
      referenceEvents: {
        documentDeadlineDate: get(bookingData, 'siCutOffDate', '')
      },
      vesselSchedule: get(shippingData, 'vesselSchedule', []),
      note: get(bookingData, 'note', ''),
      documentDeadlineDate: get(bookingData, 'siCutOffDate', '')
    }

    if (get(shipmentContext, 'type') === 'FCL') {
      payload.containers = get(containerScheduleData, 'containers', [])
    }

    if (get(shipmentContext, 'type') === 'LCL') {
      payload.referenceEvents = {
        ...payload.referenceEvents,
        cargoDropOffAtCFS: {
          expectedAt: get(bookingData, 'packageSubmissionSchedule.dateTime', ''),
          location: {
            ...get(bookingData, 'packageSubmissionSchedule.packageSubmissionAddress', {}),
            floorUnitNumber: get(bookingData, 'packageSubmissionSchedule.additionalAddress', '')
          }
        }
      }
    }

    if (get(shippingData, 'hasUnderlyingCarrier')) {
      set(payload, 'freightOperatorInfo.name', get(shippingData, 'underlyingCarrierName', ''))
      set(payload, 'freightOperatorInfo.identifier.value', get(shippingData, 'underlyingScacCode', ''))
      set(payload, 'freightOperatorInfo.secondaryIdentifier.type', 'SCAC')
      set(payload, 'freightOperatorInfo.secondaryIdentifier.value', get(shippingData, 'scacCode', ''))
    }

    get(bookingData, 'documentUploads', []).forEach(async doc => {
      setRequesting(true)
      const uploaded = await uploadShipmentFile(shipmentContext._id, doc.type, doc.payload)

      if (uploaded) {
        uploadedDocumentCount++
      }

      if (documentCount === uploadedDocumentCount) {
        freightPartnerAcceptBooking(payload, shipmentContext._id,
          () => {
            setRequesting(false)
            setFpAcceptDialog(false)
            handleMarkComplete()
          },
          () => {
            setRequesting(false)
          }
        )
      } else {
        setRequesting(false)
      }
    })
  }

  const onLogisticsPartnerAcceptSubmit = (acknowledgeData, shipment) => {
    setRequesting(true)
    const { logisticsPartnerAcceptBooking } = props

    var payload = {
      note: elpNotes
    }

    if (get(shipment, 'type') === 'FCL') {
      payload.containers = get(acknowledgeData, 'containers', [])
    }

    if (get(shipment, 'type') === 'LCL') {
      payload.referenceEvents = get(acknowledgeData, 'referenceEvents', {})
    }

    logisticsPartnerAcceptBooking(shipment._id, payload,
      () => {
        setRequesting(false)
        setElpAcceptDialog(false)
        handleMarkComplete()
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const onMarkReceivedSubmit = (...args) => {
    const { consigneeMarkAsReceived } = props
    consigneeMarkAsReceived(...args)
  }

  const onUploadFiles = (...params) => {
    const { upload } = props

    upload(...params)
  }

  const handleAssignUser = (task, userId) => {
    const { assignUser } = props
    const assignee = {
      ...pick(get(task, 'task.assignee', {}), ['role', 'user', 'team', 'plants'])
    }
    set(assignee, 'shipmentId', get(task, 'shipmentId._id'))
    set(assignee, 'user', userId)
    assignUser(task._id, assignee, () => {
      loadTasks(true)
    })
  }

  const handleDismissTask = () => {
    const { dismissTask } = props
    setRequesting(true)
    dismissTask(selectedTask._id, () => {
      setRequesting(false)
      setDismissConfirmOpen(false)
      loadTasks(true)
    }, () => setRequesting(false))
  }

  const handleAdHocComplete = () => {
    const { markTaskComplete } = props
    setRequesting(true)
    markTaskComplete(selectedTask._id, () => {
      setRequesting(false)
      setAdHocConfirmOpen(false)
      loadTasks(true)
    }, () => setRequesting(false))
  }

  const handleReviewCustomSubmit = (shipment, note, action) => {
    const { reviewCustomDraft } = props
    setRequesting(action)
    reviewCustomDraft(shipment._id, note, action, () => {
      setRequesting(false)
      setReviewCustomClearance(false)
      loadTasks(true)
    }, () => setRequesting(false))
  }

  const handleScheduleDeliverySubmit = (shipment, payload) => {
    const { scheduleDelivery } = props
    setRequesting(true)
    scheduleDelivery(shipment._id, payload, () => {
      setRequesting(false)
      setScheduleDelivery(false)
      loadTasks(true)
    }, () => setRequesting(false))
  }

  const handleUpdateStatusSubmit = (shipment, params) => {
    const { updateShipmentStatus } = props
    setRequesting(true)
    updateShipmentStatus(shipment._id, params, () => {
      setRequesting(false)
      setCustomClearance(false)
      loadTasks(true)
    }, () => setRequesting(false))
  }

  const handleLinkPost = (taskId, postId) => {
    const { updateTaskPostLink } = props
    setRequesting(true)
    updateTaskPostLink(taskId, postId, () => {
      setRequesting(false)
      loadTasks(true)
    }, () => setRequesting(false))
  }

  const handleAddDueDate = (task, date) => {
    const { addDueDate } = props
    addDueDate(task._id, { date }, () => {
      loadTasks(true)
    })
  }

  const handlePriorityChange = (task, priority) => {
    const { changePriority } = props
    changePriority(task._id, { priority }, () => {
      loadTasks(true)
    })
  }

  const handleChangeFilter = (value) => {
    setFilters({
      ...filters ? filters : {},
      ...value
    })
  }

  useEffect(() => {
    getAllShipmentsLight()
    getActionsDictionary()
    getEventsList()
    getPlantUsers()

    if (get(currentPlant, 'type') === 'shipper') {
      getPlants()
    }

    if (get(currentPlant, 'type') === 'ofp') {
      getVessels()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (filters !== false) {
      loadTasks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    if (selectedTask) {
      const shipmentId = get(selectedTask, 'shipmentId._id')
      const shipment = find(shipments, { _id: shipmentId })
      const taskCode = get(selectedTask, 'task.action.code')
      if (shipment) {
        setSelectedShipment(shipment)
        if (['D007', 'D008'].includes(taskCode)) {
          setResolvingShipment(shipment)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments])

  useEffect(() => {
    if (search) {
      const parseQuery = queryString.parse(search)
      const { shipmentId } = parseQuery
      if (shipmentId) {
        setFilters({
          ...filters ? filters : {},
          shipmentId
        })
      } else {
        setFilters({})
      }
    } else {
      loadTasks()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    const { tasks } = incomplete
    if (search !== '' && tasks.length > 0) {
      const parseQuery = queryString.parse(search)
      const { shipmentId, taskId, category, code } = parseQuery
      if (shipmentId && taskId) {
        const task = find(tasks, {
          _id: taskId,
          shipmentId: { _id: shipmentId },
          task: { action: { category, code } }
        })
        if (task) {
          setSelectedTask(task)
          setRequesting(true)
          getShipmentDetails(shipmentId, () => {
            setRequesting(false)
            handleTaskAction(task)
          }, () => {
            setRequesting(false)
          })
        } else {
          showMessage({
            variant: 'error',
            message: 'Task not found!'
          })
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, incomplete])

  return (
    <Grid container>
      <Grid item xs={12}>
        <SubToolBarLayout text='Tasks'>
          <Grid container justify="flex-end" spacing={1}>
            <Grid item>
              <Button className="btn btn_sm"
                onClick={() => setCreateTaskDialog(true)}>+&nbsp;add&nbsp;task</Button>
            </Grid>
            <Grid item>
              <Button className="btn btn_sm btn_light-blue"
                onClick={() => setTaskTemplatesSideBar(true)}>Templates</Button>
            </Grid>
          </Grid>
        </SubToolBarLayout>
      </Grid>
      <Grid item xs={12} className="px-20 py-8 bg-white shadow">
        <Grid container spacing={1} justify="space-between" alignItems="center">
          <Grid item xs={12} sm={12} md={4}>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <CountComponent className={classes.blockCount}
                  count={get(blocked, 'total', 0)}
                  label="blocked" />
              </Grid>
              <Grid item>
                <CountComponent className={classes.upcomingCount}
                  count={get(incomplete, 'total', 0)}
                  label="Next Due" />
              </Grid>
              <Grid item>
                <CountComponent className={classes.todayCount}
                  count={get(completed, 'total', 0)}
                  label="Completed" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={2} alignItems="center" justify="flex-end">
              <Grid item xs={4}>
                <ShipmentSearch searchedShipments={get(props, 'searchedShipments')}
                  handleSearchShipments={searchShipments}
                  handleSelectShipment={(shipmentId) => handleChangeFilter({ shipmentId })} />
              </Grid>
              <Grid item xs={2}>
                <AutoComplete value={get(filters, 'sortBy') && `${get(filters, 'sortBy')}-${get(filters, 'sortMode')}`}
                  className="text-12 medium"
                  options={[...sortOptions, get(filters, 'sortBy') && { value: '-', label: 'Clear' }].filter(Boolean)}
                  placeholder="Sort by..."
                  onChange={(value) => {
                    if (value) {
                      value = value.split('-')
                      handleChangeFilter({ sortBy: get(value, '[0]'), sortMode: get(value, '[1]') })
                    }
                  }}
                  customStyles={filterSelectStyle}
                  dynamic />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1}>
                  <Grid item xs={6} className="relative">
                    <DateTime className={classes.filterDateField}
                      value={get(filters, 'taskStartDate', '')}
                      inputProps={{
                        placeholder: 'Due From'
                      }}
                      dateFormat="DD MMM YYYY"
                      timeFormat=""
                      onChange={(date) => {
                        if (new Date(date).toString() === 'Invalid Date') {
                          date = new Date()
                        } else {
                          date = new Date(date)
                        }
                        handleChangeFilter({ taskStartDate: date })

                      }}
                      input
                      closeOnSelect />
                    {get(filters, 'taskStartDate') &&
                      <CloseIcon className={classes.filterDateFieldClear}
                        onClick={() => handleChangeFilter({ taskStartDate: undefined })} />
                    }
                  </Grid>
                  <Grid item xs={6} className="relative">
                    <DateTime className={classes.filterDateField}
                      value={get(filters, 'taskEndDate', '')}
                      inputProps={{
                        placeholder: 'Due To'
                      }}
                      dateFormat="DD MMM YYYY"
                      timeFormat=""
                      onChange={(date) => {
                        if (new Date(date).toString() === 'Invalid Date') {
                          date = new Date()
                        } else {
                          date = new Date(date)
                        }
                        handleChangeFilter({ taskEndDate: date })
                      }}
                      input
                      closeOnSelect />
                    {get(filters, 'taskEndDate') &&
                      <CloseIcon className={classes.filterDateFieldClear}
                        onClick={() => handleChangeFilter({ taskEndDate: undefined })} />
                    }
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className="semi-bold fg-gray">
                <FuseCheckBox checked={get(filters, 'myTasks', false)}
                  disabled={get(blocked, 'loading') || get(incomplete, 'loading') || get(completed, 'loading')}
                  onChange={e => handleChangeFilter({ myTasks: e.target.checked })} />My Tasks
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.listContainer}>
        <Grid container direction="row" spacing={3} wrap="nowrap" className="h-full w-full">
          <Grid item>
            <TaskList title="Blocked"
              shipments={shipments}
              tasks={get(blocked, 'tasks')}
              hasMore={get(blocked, 'hasMore', false)}
              loading={get(blocked, 'loading')}
              loadingMore={get(blocked, 'loadingMore')}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              userInfo={get(props, 'userInfo', {})}
              users={get(props, 'users', [])}
              onAssign={handleAssignUser}
              onDueDate={handleAddDueDate}
              onPriorityChange={handlePriorityChange}
              linkPost={handleLinkPost}
              loadMore={loadMoreBlocked}
              requesting={requesting} />
          </Grid>
          <Grid item>
            <TaskList title="Next due"
              shipments={shipments}
              tasks={get(incomplete, 'tasks')}
              hasMore={get(incomplete, 'hasMore', false)}
              loading={get(incomplete, 'loading')}
              loadingMore={get(incomplete, 'loadingMore')}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              markComplete={performMarkComplete}
              onDismiss={() => setDismissConfirmOpen(true)}
              userInfo={get(props, 'userInfo', {})}
              users={get(props, 'users', [])}
              onAssign={handleAssignUser}
              onDueDate={handleAddDueDate}
              onPriorityChange={handlePriorityChange}
              linkPost={handleLinkPost}
              loadMore={loadMoreIncomplete}
              requesting={requesting} />
          </Grid>
          <Grid item>
            <TaskList title="Completed"
              shipments={shipments}
              tasks={get(completed, 'tasks')}
              hasMore={get(completed, 'hasMore', false)}
              loading={get(completed, 'loading')}
              loadingMore={get(completed, 'loadingMore')}
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
              userInfo={get(props, 'userInfo', {})}
              users={get(props, 'users', [])}
              requesting={requesting}
              loadMore={loadMoreCompleted} />
          </Grid>
        </Grid>
      </Grid>

      <CreateTaskDialog open={createTaskDialog}
        shipments={get(props, 'shipments', [])}
        events={get(props, 'events', [])}
        userInfo={get(props, 'userInfo', {})}
        shipmentTasks={get(props, 'shipmentTasks', {})}
        users={get(props, 'users', [])}
        controllerDictionary={get(props, 'controllerDictionary', {})}
        getShipmentTasks={getShipmentTasksById}
        handleClose={() => setCreateTaskDialog(false)}
        requesting={requesting}
        currentPlant={currentPlant}
        getEventDocList={getEventDocList}
        eventDocs={get(props, 'eventDocs', [])}
        onSave={handleCreateSubmit} />

      <CreateTemplateDialog open={createTemplateDialog}
        users={get(props, 'users', [])}
        controllerDictionary={get(props, 'controllerDictionary', {})}
        handleClose={() => setCreateTemplateDialog(false)}
        getEventDocList={getEventDocList}
        getTemplates={getTemplates}
        eventDocs={get(props, 'eventDocs', [])}
        onSave={createTemplates} />

      <TaskTemplates open={taskTemplatesSideBar}
        handleClose={() => setTaskTemplatesSideBar(false)}
        templates={templates}
        getTemplates={getTemplates}
        onAddNew={() => setCreateTemplateDialog(true)} />

      <FileUploadDialog open={documentUpload}
        shipment={selectedShipment}
        upload={onUploadFiles}
        handleClose={() => {
          setRequesting(false)
          setDocumentUpload(false)
        }}
        currentPlant={currentPlant}
        selectedTask={selectedTask}
        callback={handleMarkComplete} />

      <AddEventSidebar open={addEvent}
        selectedTask={selectedTask}
        shipment={selectedShipment}
        eventWriteList={get(selectedShipment, 'eventWriteList', [])}
        handleClose={() => {
          setAddEvent(false)
          setRequesting(false)
        }}
        onDone={() => handleMarkComplete(() => setAddEvent(false))}
        requesting={requesting}
        getShipmentEventWriteList={getShipmentEventWriteList}
        addShipmentEvents={addShipmentEvents} />

      <ReviewBookingDialog open={reviewBooking}
        shipment={selectedShipment}
        handleClose={() => {
          setRequesting(false)
          setReviewBooking(false)
        }}
        currentPlant={currentPlant}
        onReject={() => {
          setReviewBooking(false)
          setFpRejectDialog(true)
        }}
        onFreightPartnerAccept={() => {
          setReviewBooking(false)
          setFpAcceptDialog(true)
        }}
        onLogisticsPartnerAccept={(shipment, note) => {
          setElpNotes(note)
          setReviewBooking(false)
          setElpAcceptDialog(true)
        }}
        getLimitedServiceCards={getLimitedServiceCards}
        limitedServiceCards={limitedServiceCards}
        resolveFpConflicts={resolveFpConflicts}
        resolveConflictCallback={() => { }} />

      <RejectBookingDialog open={fpRejectDialog}
        shipment={selectedShipment}
        handleClose={() => {
          setRequesting(false)
          setFpRejectDialog(false)
        }}
        currentPlant={currentPlant}
        onSubmit={onRejectSubmit}
        onReviewBooking={(shipment) => {
          setFpRejectDialog(false)
          setReviewBooking(true)
        }}
        requesting={requesting} />

      <FPAcceptBookingDialog open={fpAcceptDialog}
        shipment={selectedShipment}
        handleClose={() => {
          setRequesting(false)
          setFpAcceptDialog(false)
        }}
        vessels={vessels}
        currentPlant={currentPlant}
        onSubmit={onFreightPartnerAcceptSubmit}
        requesting={requesting}
        getVessels={() => { }} />

      <ELPAcknowledgeBookingDialog open={elpAcceptDialog}
        shipment={selectedShipment}
        handleClose={() => {
          setRequesting(false)
          setElpAcceptDialog(false)
        }}
        currentPlant={currentPlant}
        onSubmit={onLogisticsPartnerAcceptSubmit}
        requesting={requesting} />

      <MarkAsReceivedDialog open={markAsReceived}
        shipment={selectedShipment}
        handleClose={() => {
          setRequesting(false)
          setMarkAsReceived(false)
        }}
        currentPlant={currentPlant}
        onSubmit={onMarkReceivedSubmit}
        onUpload={onUploadFiles}
        callback={handleMarkComplete} />

      <DismissConfirm open={dismissConfirmOpen}
        dismissTask={selectedTask}
        handleDismiss={handleDismissTask}
        handleDismissCancel={() => setDismissConfirmOpen(false)}
        requesting={requesting} />

      <AdHocConfirm open={adHocConfirmOpen}
        adHocTask={selectedTask}
        handleConfirm={handleAdHocComplete}
        handleCancel={() => setAdHocConfirmOpen(false)}
        requesting={requesting} />

      <ReviewCustomClearance open={reviewCustomClearance}
        shipment={selectedShipment}
        requesting={requesting}
        handleClose={() => setReviewCustomClearance(false)}
        handleSubmit={handleReviewCustomSubmit}
        showMessage={showMessage} />

      <ScheduleDelivery open={scheduleDelivery}
        shipment={selectedShipment}
        requesting={requesting}
        handleClose={() => setScheduleDelivery(false)}
        handleSubmit={handleScheduleDeliverySubmit} />

      <UpdateShipmentStatus open={customClearance}
        shipment={selectedShipment}
        requesting={requesting}
        handleClose={() => setCustomClearance(false)}
        handleSubmit={handleUpdateStatusSubmit}
        uploadDocument={onUploadFiles} />

      <ResolveException shipment={resolvingShipment}
        clearPropShipment={() => setResolvingShipment(null)}
        plants={get(props, 'plants', [])}
        onResolveConflict={resolveConflict}
        onMarkAsSpot={markAsSpot}
        onResolveSpot={resolveSpot}
        getAllShipments={() => { }} />
    </Grid>
  )
}

const styles = {
  blockCount: {
    backgroundColor: 'rgba(177, 0, 67, 0.1)',
    borderRadius: '2px',
    color: '#B10043',
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: '11px',
    lineHeight: '14px',
    textAlign: 'center',
    padding: '1px 6px'
  },
  overdueCount: {
    backgroundColor: 'rgba(228, 127, 0, 0.1)',
    borderRadius: '2px',
    color: '#E47F00',
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: '11px',
    lineHeight: '14px',
    textAlign: 'center',
    padding: '1px 6px'
  },
  todayCount: {
    backgroundColor: 'rgba(0, 149, 48, 0.1)',
    borderRadius: '2px',
    color: '#009530',
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: '11px',
    lineHeight: '14px',
    textAlign: 'center',
    padding: '1px 6px'
  },
  upcomingCount: {
    backgroundColor: 'rgba(61, 205, 88, 0.1)',
    borderRadius: '2px',
    color: '#3DCD58',
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: '11px',
    lineHeight: '14px',
    textAlign: 'center',
    padding: '1px 6px'
  },
  filterDateField: {
    display: 'flex',
    border: '1px solid rgba(159, 160, 164, 0.2)',
    borderRadius: '2px',
    padding: '5px',
    height: '32px',
    fontSize: '12px',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      right: '5px',
      content: 'url("/assets/images/icons/Date-In-text.svg")',
      display: 'inline-block'
    }
  },
  filterDateFieldClear: {
    position: 'absolute',
    right: '32px',
    bottom: '10px',
    fontSize: '20px',
    color: '#9FA0A4',
    cursor: 'pointer'
  },
  listContainer: {
    height: 'calc(100vh - 152px)',
    overflow: 'auto',
    padding: '20px'
  }
}

const mapStateToProps = ({
  schedule: { limitedServiceCards, vessels },
  welcome: { currentPlant },
  core: { plants },
  auth: { info },
  tasks: {
    incomplete,
    blocked,
    completed,
    shipmentTasks,
    shipments,
    controllerDictionary,
    events,
    users,
    eventDocs,
    searchedShipments,
    templates
  },
}) => ({
  limitedServiceCards,
  vessels,
  currentPlant,
  userInfo: info,
  incomplete,
  blocked,
  completed,
  shipmentTasks,
  shipments,
  controllerDictionary,
  events,
  users,
  eventDocs,
  searchedShipments,
  plants,
  templates
})

const mapDispatchToProps = dispatch => ({
  getTasks: (...args) => dispatch(Actions.getTasks(...args)),
  getShipmentTasksById: (...args) => dispatch(Actions.getShipmentTasksById(...args)),
  getAllShipmentsLight: (...args) => dispatch(Actions.getAllShipmentsLight(...args)),
  getActionsDictionary: (...args) => dispatch(Actions.getActionsDictionary(...args)),
  getEventsList: (...args) => dispatch(Actions.getEventsList(...args)),
  getPlantUsers: (...args) => dispatch(Actions.getPlantUsers(...args)),
  createNewTask: (...args) => dispatch(Actions.createNewTask(...args)),
  markTaskComplete: (...args) => dispatch(Actions.markTaskComplete(...args)),
  getShipmentDetails: (...args) => dispatch(Actions.getShipmentDetails(...args)),
  getShipmentMeta: (...args) => dispatch(Actions.getShipmentMeta(...args)),
  getExpandShipmentTasks: (...args) => dispatch(Actions.getExpandShipmentTasks(...args)),
  getShipmentEventWriteList: (...params) => dispatch(Actions.getShipmentEventWriteList(...params)),
  addShipmentEvents: (...params) => dispatch(Actions.addShipmentEvents(...params)),
  resolveFpConflicts: (...args) => dispatch(Actions.resolveFpConflicts(...args)),
  freightPartnerRejectBooking: (...args) => dispatch(Actions.freightPartnerRejectBooking(...args)),
  freightPartnerAcceptBooking: (...args) => dispatch(Actions.freightPartnerAcceptBooking(...args)),
  logisticsPartnerAcceptBooking: (...args) => dispatch(Actions.logisticsPartnerAcceptBooking(...args)),
  consigneeMarkAsReceived: (...args) => dispatch(Actions.consigneeMarkAsReceived(...args)),
  uploadShipmentFile: (...args) => dispatch(Actions.uploadShipmentFile(...args)),
  assignUser: (...args) => dispatch(Actions.assignUser(...args)),
  addDueDate: (...args) => dispatch(Actions.addDueDate(...args)),
  changePriority: (...args) => dispatch(Actions.changePriority(...args)),
  getEventDocList: (...args) => dispatch(Actions.getEventDocList(...args)),
  searchShipments: (...args) => dispatch(Actions.searchShipments(...args)),
  dismissTask: (...args) => dispatch(Actions.dismissTask(...args)),
  updateTaskPostLink: (...args) => dispatch(Actions.updateTaskPostLink(...args)),
  reviewCustomDraft: (...args) => dispatch(Actions.reviewCustomDraft(...args)),
  scheduleDelivery: (...args) => dispatch(Actions.scheduleDelivery(...args)),
  updateShipmentStatus: (...args) => dispatch(Actions.updateShipmentStatus(...args)),
  resolveConflict: (...args) => dispatch(Actions.resolveConflict(...args)),
  markAsSpot: (...args) => dispatch(Actions.markAsSpot(...args)),
  resolveSpot: (...args) => dispatch(Actions.resolveSpot(...args)),
  getTemplates: (...args) => dispatch(Actions.getTemplates(...args)),
  createTemplates: (...args) => dispatch(Actions.createTemplates(...args)),
  getLimitedServiceCards: (...args) => dispatch(ScheduleActions.getLimitedServiceCards(...args)),
  getVessels: (...args) => dispatch(ScheduleActions.getVessels(...args)),
  upload: (...params) => dispatch(CoreActions.uploadFile(...params)),
  showMessage: (...params) => dispatch(CoreActions.showMessage(...params)),
  getPlants: (...args) => dispatch(CoreActions.getPlants(...args)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TasksPage))
