import React, { useState, useEffect } from 'react'
import { get, pick, filter } from 'lodash'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  FuseTabs
} from '../../core/components'
import { Grid } from '@material-ui/core'
import { useMergeState } from '../../core/helpers'
import DisabledBookingForm from "./DisabledBookingForm";
import BookingForm from "./BookingForm";
import DisabledBookingRouteForm from "./DisabledBookingRouteForm";
import BookingRouteForm from "./BookingRouteForm";
import BookingLaneConflictForm from "./BookingLaneConflictForm";
import BookingLaneSpotForm from "./BookingLaneSpotForm";
import { general } from "../../core/localization";

const tabs = {
  routeInfo: 'Route',
  routeInfoReadOnly: 'Route (Read only)',
  laneInfo: 'Lane',
  conflictingLaneInfo: 'Conflicting Lane',
  bookingInfo: 'Booking',
  bookingInfoReadOnly: 'Booking (Read only)',
}

const ResolveExceptionDialog = (props) => {
  const [requesting, setRequesting] = useState(false)
  const [state, setState] = useMergeState({})
  const {
    open,
    handleClose,
    plants,
    shipment,
    exceptions,
    matchedServiceCards,
    onResolveConflict,
    onMarkAsSpot,
    onResolveSpot,
    getAllShipments
  } = props

  useEffect(() => {
    setState({
      plants,
      shipment,
      exceptions,
      matchedServiceCards,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, exceptions, matchedServiceCards])

  useEffect(() => {
    if (!open) {
      setState({
        currentTab: 0,
        bookingForm: null,
        routeForm: null,
        laneConflictForm: null,
        laneSpotForm: null
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const go = (step) => {
    const currentTab = get(state, 'currentTab', 0) + step

    if (0 <= currentTab && currentTab < 3) {
      setState({ currentTab })
    }
  }

  const onChange = (name, value) => {
    setState({
      [name]: value
    })
  }

  const getSpecialLabels = (goodsTypes) => {
    var specialLabels = []
    if (goodsTypes.includes('FORMD')) {
      specialLabels.push('formd')
    }
    if (goodsTypes.includes('FORMAK')) {
      specialLabels.push('formak')
    }
    if (goodsTypes.includes('FORMA')) {
      specialLabels.push('forma')
    }
    if (goodsTypes.includes('FORME')) {
      specialLabels.push('forme')
    }
    return specialLabels
  }

  const handleResolveConflict = () => {
    setRequesting(true)
    const serviceCardSelection = get(state, 'laneConflictForm.serviceCardSelection', {})
    const payload = {
      serviceCardIds: Object.keys(serviceCardSelection).map(key => {
        return get(serviceCardSelection[key], 'serviceCardId')
      }),
      note: get(state, 'laneConflictForm.note')
    }
    onResolveConflict(payload, shipment.id,
      () => {
        getAllShipments()
        handleClose()
        setRequesting(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const handleMarkAsSpot = (reason) => {
    setRequesting(true)
    const payload = {
      reason
    }
    onMarkAsSpot(payload, shipment.id,
      () => {
        getAllShipments()
        setState({
          currentTab: 0,
          exceptions: {
            ...exceptions,
            suggestNextResolutionProcess: 'XRP003'
          }
        })
        setRequesting(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const handleResolveSpot = () => {
    setRequesting(true)
    const type = get(state, 'bookingForm.type')
    const shipmentMode = get(state, 'routeForm.shipmentMode')
    const shipmentNote = get(state, 'bookingForm.note')
    var derivedShipmentData = {
      ...get(state, 'shipment', {}),
      type,
      goodsType: get(state, 'routeForm.goodsType', []).includes('DG') ? 'DG' : 'normal',
      specialLabels: getSpecialLabels(get(state, 'routeForm.goodsType', [])),
      groupageDate: get(state, 'bookingForm.groupageDate'),
      shipper: get(state, 'routeForm.shipper._id'),
      consignee: get(state, 'routeForm.consignee', []).map(item => item._id),
      shipmentMode,
      obc: get(state, 'laneSpotForm.obc._id'),
      obl: get(state, 'laneSpotForm.obl._id'),
      ofp: get(state, 'laneSpotForm.ofp._id'),
      ibc: get(state, 'laneSpotForm.ibc._id'),
      ibl: get(state, 'laneSpotForm.ibl._id'),
      ifp: get(state, 'laneSpotForm.ifp._id'),
      fpType: get(state, 'laneSpotForm.fpType'),
      incoterm: get(state, 'laneSpotForm.incoterm'),
      originPort: get(state, 'laneSpotForm.originPort'),
      destinationPort: get(state, 'laneSpotForm.destinationPort')
    }

    if (shipmentMode === 'drop') {
      derivedShipmentData.dropHub = get(state, 'routeForm.dropHub._id')
    }

    if (type === 'FCL') {
      derivedShipmentData.containers = get(state, 'bookingForm.containers')
    }

    if (type === 'LCL') {
      derivedShipmentData.referenceEvents = {
        ...get(state, 'shipment.referenceEvents', {}),
        truckInForCargoPickup: {
          ...get(state, 'bookingForm.truckInCargo', {}),
          expectedAt: new Date(get(state, 'bookingForm.truckInCargo.expectedAt')),
          location: {
            ...get(state, 'bookingForm.truckInCargo.location', {}),
            ...pick(get(state, 'bookingForm.truckInCargo', {}), ['floorUnitNumber'])
          }
        }
      }
    }

    if (shipmentNote) {
      derivedShipmentData.note = shipmentNote
    }

    const payload = {
      quoteId: get(state, 'laneSpotForm.quoteId'),
      derivedShipmentData,
      note: shipmentNote
    }


    onResolveSpot(payload, shipment.id,
      () => {
        getAllShipments()
        handleClose()
        setRequesting(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const tabItems = [
    {
      label: tabs.bookingInfo,
      component: BookingForm,
      type: 'XRP003',
      props: {
        data: state,
        existingFormData: get(state, 'bookingForm'),
        go,
        onChange,
        mode: 'edit'
      }
    },
    {
      label: tabs.routeInfo,
      component: BookingRouteForm,
      type: 'XRP003',
      props: {
        data: state,
        existingFormData: get(state, 'routeForm'),
        go,
        onChange,
      }
    },
    {
      label: tabs.laneInfo,
      component: BookingLaneSpotForm,
      type: 'XRP003',
      props: {
        data: state,
        existingFormData: get(state, 'laneSpotForm'),
        go,
        onChange,
        handleResolveSpot,
        requesting
      }
    },
    {
      label: tabs.bookingInfoReadOnly,
      component: DisabledBookingForm,
      type: 'XRP002',
      props: {
        data: state,
        go,
      }
    },
    {
      label: tabs.routeInfoReadOnly,
      component: DisabledBookingRouteForm,
      type: 'XRP002',
      props: {
        data: state,
        go,
      }
    },
    {
      label: tabs.conflictingLaneInfo,
      component: BookingLaneConflictForm,
      type: 'XRP002',
      props: {
        data: state,
        existingFormData: get(state, 'laneConflictForm'),
        go,
        onChange,
        handleResolveConflict,
        handleMarkAsSpot,
        requesting
      }
    },
  ]

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader handleClose={handleClose}
        text={`Resolve Exception (${general.exceptions[get(state, 'exceptions.suggestNextResolutionProcess')]})`}
      />
      <FuseDialogBody>
        <Grid container>
          <Grid item xs={12}>
            <FuseTabs showIndicator
              value={get(state, 'currentTab', 0)}
              data={filter(tabItems, { type: get(state, 'exceptions.suggestNextResolutionProcess') })}
              onChange={tabValue => { }} />
          </Grid>
          <Grid item xs={12}
            className='booking-wizard'>
            {filter(tabItems, { type: get(state, 'exceptions.suggestNextResolutionProcess') })
              .map((tab, index) => {
                if (index === get(state, 'currentTab', 0)) {
                  return <tab.component key={index} {...tab.props} />
                }
                return <React.Fragment key={index} />
              })
            }
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default ResolveExceptionDialog
