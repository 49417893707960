import ActionTypes from './types'
import { uniqBy, get } from 'lodash'

const initialState = {
  outbounds: [],
  airOutbounds: [],
  cbtOutbounds: [],
  railOutbounds: [],
  otherOutbounds: [],
  outboundsTotal: 0,
  airOutboundsTotal: 0,
  cbtOutboundsTotal: 0,
  railOutboundsTotal: 0,
  otherOutboundsTotal: 0,
  initialPrep: {},
  showOutboundsMore: false,
  showAirOutboundsMore: false,
  showCbtOutboundsMore: false,
  showRailOutboundsMore: false,
  showOtherOutboundsMore: false,
  loading: false,
  initialLoading: true,
  outboundsLoading: false,
  airOutboundsLoading: false,
  cbtOutboundsLoading: false,
  railOutboundsLoading: false,
  otherOutboundsLoading: false,
  outboundsLoadingMore: false,
  airOutboundsLoadingMore: false,
  cbtOutboundsLoadingMore: false,
  railOutboundsLoadingMore: false,
  otherOutboundsLoadingMore: false,
  shipmentsLoading: false,
  shipmentsLoadingMore: false,
  manageShipments: [],
  manageShipmentsTotal: 0,
  showShipmentsMore: false,
  airShipmentsLoading: false,
  airShipmentsLoadingMore: false,
  showAirShipmentsMore: false,
  airManageShipments: [],
  airManageShipmentsTotal: 0
}

const coreReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_ATTEMPT:
      return {
        ...state,
        loading: true
      }

    case ActionTypes.API_SUCCESS:
      return {
        ...state,
        loading: false,
        initialLoading: false
      }

    case ActionTypes.API_FAILED:
      return {
        ...state,
        loading: false,
        initialLoading: false,
        error: action.payload
      }

    case ActionTypes.SET_OUTBOUND_LOADING:
      return {
        ...state,
        outboundsLoading: action.value
      }
    case ActionTypes.SET_OUTBOUND_LOADING_MORE:
      return {
        ...state,
        outboundsLoadingMore: action.value
      }
    case ActionTypes.GET_OUTBOUNDS_SUCCESS:
      return {
        ...state,
        outbounds: action.payload.reset ? action.payload.data : [...state.outbounds, ...action.payload.data],
        outboundsTotal: action.payload.total,
        showOutboundsMore: action.payload.showMore,
        loading: !!action.payload.loading,
        initialLoading: !!action.payload.loading
      }

    case ActionTypes.SET_AIR_OUTBOUND_LOADING:
      return {
        ...state,
        airOutboundsLoading: action.value
      }
    case ActionTypes.SET_AIR_OUTBOUND_LOADING_MORE:
      return {
        ...state,
        airOutboundsLoadingMore: action.value
      }
    case ActionTypes.GET_AIR_OUTBOUNDS_SUCCESS:
      return {
        ...state,
        airOutbounds: action.payload.reset ? action.payload.data : [...state.airOutbounds, ...action.payload.data],
        airOutboundsTotal: action.payload.total,
        showAirOutboundsMore: action.payload.showMore,
        loading: !!action.payload.loading,
        initialLoading: !!action.payload.loading
      }

    case ActionTypes.SET_CBT_OUTBOUND_LOADING:
      return {
        ...state,
        cbtOutboundsLoading: action.value
      }
    case ActionTypes.SET_CBT_OUTBOUND_LOADING_MORE:
      return {
        ...state,
        cbtOutboundsLoadingMore: action.value
      }
    case ActionTypes.GET_CBT_OUTBOUNDS_SUCCESS:
      return {
        ...state,
        cbtOutbounds: action.payload.reset ? action.payload.data : [...state.cbtOutbounds, ...action.payload.data],
        cbtOutboundsTotal: action.payload.total,
        showCbtOutboundsMore: action.payload.showMore,
        loading: !!action.payload.loading,
        initialLoading: !!action.payload.loading
      }

    case ActionTypes.SET_RAIL_OUTBOUND_LOADING:
      return {
        ...state,
        railOutboundsLoading: action.value
      }
    case ActionTypes.SET_RAIL_OUTBOUND_LOADING_MORE:
      return {
        ...state,
        railOutboundsLoadingMore: action.value
      }
    case ActionTypes.GET_RAIL_OUTBOUNDS_SUCCESS:
      return {
        ...state,
        railOutbounds: action.payload.reset ? action.payload.data : [...state.railOutbounds, ...action.payload.data],
        railOutboundsTotal: action.payload.total,
        showRailOutboundsMore: action.payload.showMore,
        loading: !!action.payload.loading,
        initialLoading: !!action.payload.loading
      }

    case ActionTypes.SET_OTHER_OUTBOUND_LOADING:
      return {
        ...state,
        otherOutboundsLoading: action.value
      }
    case ActionTypes.SET_OTHER_OUTBOUND_LOADING_MORE:
      return {
        ...state,
        otherOutboundsLoadingMore: action.value
      }
    case ActionTypes.GET_OTHER_OUTBOUNDS_SUCCESS:
      return {
        ...state,
        otherOutbounds: action.payload.reset ? action.payload.data : [...state.otherOutbounds, ...action.payload.data],
        otherOutboundsTotal: action.payload.total,
        showOtherOutboundsMore: action.payload.showMore,
        loading: !!action.payload.loading,
        initialLoading: !!action.payload.loading
      }

    case ActionTypes.GET_INITIAL_PREP_SUCCESS:
      return {
        ...state,
        initialPrep: action.payload,
        loading: false,
        initialLoading: false
      }

    case ActionTypes.SET_MANAGE_SHIPMENTS_LOADING:
      return {
        ...state,
        shipmentsLoading: action.value
      }
    case ActionTypes.SET_MANAGE_SHIPMENTS_LOADING_MORE:
      return {
        ...state,
        shipmentsLoadingMore: action.value
      }
    case ActionTypes.GET_ALL_MANAGE_SHIPMENT_SUCCESS: {
      return {
        ...state,
        manageShipments: action.payload.reset ?
          action.payload.data :
          uniqBy([...state.manageShipments, ...action.payload.data], '_id'),
        showShipmentsMore: action.payload.showMore,
        manageShipmentsTotal: get(action, 'payload.total', 0)
      }
    }

    case ActionTypes.SET_AIR_MANAGE_SHIPMENTS_LOADING:
      return {
        ...state,
        airShipmentsLoading: action.value
      }
    case ActionTypes.SET_AIR_MANAGE_SHIPMENTS_LOADING_MORE:
      return {
        ...state,
        airShipmentsLoadingMore: action.value
      }
    case ActionTypes.GET_AIR_MANAGE_SHIPMENT_SUCCESS: {
      return {
        ...state,
        airManageShipments: action.payload.reset ?
          action.payload.data :
          uniqBy([...state.airManageShipments, ...action.payload.data], '_id'),
        showAirShipmentsMore: action.payload.showMore,
        airManageShipmentsTotal: get(action, 'payload.total', 0)
      }
    }

    default:
      return state
  }
}

export default coreReducer
