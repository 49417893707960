import React from 'react'
import ReactQuill from 'react-quill'
import { getVariablesByType } from '../../helpers'
import { get, find } from 'lodash'

/*
 * Event handler to be attached using Quill toolbar module (see line 73)
 * https://quilljs.com/docs/modules/toolbar/
 */
function insertVariable(value) {
  if (value) {
    const cursorPosition = this.quill.getSelection().index;
    this.quill.insertText(cursorPosition, value);
    this.quill.setSelection(cursorPosition + value.length);
  }
}

/*
 * Editor component with custom toolbar and content containers
 */
class Editor extends React.Component {
  componentDidMount() {
    const insertVariablePickerItems = Array.prototype.slice.call(
      document.querySelectorAll('.ql-insertVariable .ql-picker-item')
    );
    insertVariablePickerItems.forEach(item =>
      item.textContent = get(find(getVariablesByType('body'), { name: item.dataset.value }), 'label')
    );
    document.querySelector('.ql-insertVariable .ql-picker-label')
      .innerHTML = '<span class="pr-28">Insert variables</span>' + document
        .querySelector('.ql-insertVariable .ql-picker-label')
        .innerHTML;
  }

  render() {
    return (
      <ReactQuill modules={Editor.modules}
        theme={"snow"} // pass false to use minimal theme
        {...this.props}
      />
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: {
    container: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'font': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      ['link', 'image'],
      [{ 'insertVariable': getVariablesByType('body').map(item => item.name) }],
      ['blockquote', 'code-block'],
      ['clean']
    ],
    handlers: {
      "insertVariable": insertVariable
    }
  },
  clipboard: {
    matchVisual: false,
  }
};

export default Editor
