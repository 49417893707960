import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

const BookingListSkeleton = () => (
  <Grid container spacing={2}>
    {[1, 2, 3].map(item => {
      return (
        <Grid key={item} item xs={12}>
          <Grid container className="bg-white rounded-3 p-16 pt-32">
            <Grid item xs={12}>
              <Skeleton height={80} />
            </Grid>
          </Grid>
        </Grid>
      )
    })}
  </Grid>
)

export default BookingListSkeleton
