import React, { useEffect, useState } from 'react'
import { Grid, TextField, Chip } from '@material-ui/core'
import { get, find, filter, pick, uniqBy } from 'lodash'
import { useMergeState, shipmentNameBeautifier, addGaEvent } from '../helpers'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions,
  LoadingButton,
  InfoToolTip
} from './'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { general } from "../localization";
import AutoComplete from "./Inputs/AutoComplete";

const ManualNotification = props => {
  const { open, shipment, selectedPlant, usersByPlantIds, onGetUsers, onClose, onSubmit } = props
  const [state, setState] = useMergeState({})
  const [plants, setPlants] = useState([])
  const [loading, setLoading] = useState(false)
  const [plantOptions, setPlantOptions] = useState([])
  const [recipientOptions, setRecipientOptions] = useState([])
  const [recipient, setRecipient] = useState('')
  const [recipientInput, setRecipientInput] = useState('select')
  const chipStyle = {
    fontFamily: 'IBMPlexSans-Medium',
    fontSize: 12,
    borderRadius: 15,
    height: 25,
  }
  const chipInvalidStyle = {
    ...chipStyle,
    backgroundColor: '#B10043',
    color: '#FFFFFF'
  }
  const multiSelectStyle = {
    control: () => ({
      display: 'flex',
      border: 'none',
      borderRadius: '0px',
      borderBottom: '1px solid #949494',
      minHeight: '31px',
      maxHeight: '31px',
      marginTop: '0px'
    }),
    clearIndicator: () => ({
      display: 'none'
    }),
    placeholder: (styles) => ({
      ...styles,
      marginLeft: 0,
      marginRight: 0,
    })
  }

  useEffect(() => {
    setLoading(false)
    setRecipient('')
    setState({
      recipients: [],
      subject: 'Confirmation Reminder',
      messageBody: 'You have a pending / amended booking to review. Please review the booking as soon as possible.'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    const plantsArray = []

    setState({
      subject: shipmentNameBeautifier(get(shipment, 'name', '')) +
        ' | Confirmation Reminder'
    })
    Object.keys(get(shipment, 'actors', {})).forEach(actor => {
      if (!['_id', 'shipper'].includes(actor)) {
        getPlantFromActors(actor).forEach(item => {
          plantsArray.push(item)
        })
      }
    })
    setPlantOptions(plantsArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  useEffect(() => {
    if (selectedPlant) {
      setPlants([
        ...filter(plantOptions, { type: selectedPlant }).map(item => item._id)
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantOptions])

  useEffect(() => {
    if (plants.length === 0) {
      setRecipientOptions([])
    } else {
      onGetUsers(plants)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plants])

  useEffect(() => {
    if (plants.length > 0) {
      setRecipientOptions(filter(usersByPlantIds, item => {
        return find(get(state, 'recipients', []), { email: item.email }) ? false : true
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersByPlantIds, get(state, 'recipients', [])])

  const getPlantFromActors = (actor) => {
    return get(shipment, `actors.${actor}`, []).map(item => ({
      ...pick(item, ['_id', 'name', 'type'])
    }))
  }

  const removeFromArray = (arr, str) => {
    let index = arr.indexOf(str)
    if (index !== -1) {
      return [...arr.slice(0, index), ...arr.slice(index + 1)]
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setState({
      [name]: value
    })
  }

  const handleRecipientSelectChange = (values) => {
    setState({
      recipients: uniqBy([
        ...get(state, 'recipients', []),
        ...values.map(value => ({ email: value }))
      ], 'email')
    })
  }

  const handleRecipientChange = (event) => {
    let { value } = event.target
    value = value.replace(/ +/g, ',')
    value = value.replace(/;+/g, ',')
    value = value.replace(/,+/g, ',')
    if (value[0] === ',') {
      value = value.substring(1)
    }
    setRecipient(value)

    if (value.search(',') >= 0) {
      handleRecipientAdd(event, true, value)
    }
  }

  const handleRecipientAdd = (event, force = false, recipient) => {
    event.stopPropagation()
    const appendKeyCodes = [9, 13, 32]
    const { keyCode } = event

    if (recipient && (force || appendKeyCodes.includes(keyCode))) {
      const recipientArray = recipient.split(',').map(email => {
        if (email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
          return {
            email
          }
        } else {
          return {
            email,
            invalid: true
          }
        }
      })
      setState({
        recipients: uniqBy([
          ...get(state, 'recipients', []),
          ...recipientArray
        ].filter(Boolean), 'email')
      })
      setRecipient('')
    }
  }

  const handleEditorChange = (messageBody) => {
    setState({ messageBody })
  }

  const handleSubmit = () => {
    setLoading(true)
    const payload = { ...state }
    payload.recipients = get(payload, 'recipients', []).map(item => item.email).join(',')
    onSubmit(shipment._id, payload, onClose)
  }

  return (
    <FuseSideBar open={open}>
      <FuseSideBarHeader
        text={`Send Notification to accept`}
        handleClose={() => {
          addGaEvent('shipment-sendingNotificationCancelled', get(shipment, '_id'))
          onClose()
        }}
      />
      <FuseSideBarBody>
        <Grid container>
          <Grid item xs={12} className="p-8 mb-20 bg-gray bold text-16">
            {shipmentNameBeautifier(get(shipment, 'name', ''))}
          </Grid>
          <Grid item xs={12}>
            <Grid container
              className="mb-28">

              {/* Plants */}
              <Grid item xs={12} className="pb-20">
                <label className='text-12 medium require-field'>
                  Select Plants:</label>
                <AutoComplete value={plants}
                  options={plantOptions.map(item => {
                    return {
                      value: item._id,
                      label: `${item.name} [${get(general, `partnerShortForms.${item.type}`)}]`
                    }
                  })}
                  onChange={plants => {
                    setPlants(plants)
                  }}
                  placeholder="Select plants..."
                  customStyles={multiSelectStyle}
                  isMulti
                  hideChip
                />
                <Grid container
                  className="mt-8"
                  spacing={1}>
                  {plants.map((item, itemIndex) => {
                    const selectedPartner = find(plantOptions, { _id: item }) || {}
                    const { name, type } = selectedPartner

                    return (
                      <Grid item key={itemIndex}>
                        <Chip label={`${name} [${get(general, `partnerShortForms.${type}`)}]`}
                          onDelete={() => {
                            setPlants(removeFromArray(plants, item))
                          }}
                          style={chipStyle}
                        />
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
              {/* End of Plants */}

              {/* Recipients */}
              <Grid item xs={12} className="pb-20">
                <Grid container
                  alignItems="center"
                  justify="space-between">
                  <Grid item>
                    <label className='text-12 medium require-field'>
                      Recipients:</label>
                  </Grid>
                  <Grid item className="text-11 semi-bold uppercase">
                    <InfoToolTip title="To select recipients from plant users list">
                      <label className={recipientInput === 'select' ? 'fg-gray' : 'cursor-pointer fg-blue'}
                        onClick={() => recipientInput === 'manual' ? setRecipientInput('select') : {}}>
                        Select</label>
                    </InfoToolTip>
                    <label>&nbsp;&nbsp;</label>
                    <InfoToolTip title="To enter recipients manually">
                      <label className={recipientInput === 'manual' ? 'fg-gray' : 'cursor-pointer fg-blue'}
                        onClick={() => recipientInput === 'select' ? setRecipientInput('manual') : {}}>
                        Manual</label>
                    </InfoToolTip>
                  </Grid>
                </Grid>
                {recipientInput === 'select' &&
                  <AutoComplete value={get(state, 'recipients', [])}
                    options={recipientOptions.map(item => {
                      return {
                        value: item.email,
                        label: item.email
                      }
                    })}
                    onChange={values => handleRecipientSelectChange(values)}
                    placeholder="Select recipients..."
                    customStyles={multiSelectStyle}
                    isMulti
                    hideChip
                  />
                }
                {recipientInput === 'manual' &&
                  <TextField placeholder="Enter recipients"
                    value={recipient}
                    onChange={handleRecipientChange}
                    onKeyDown={e => handleRecipientAdd(e, false, recipient)}
                    onBlur={e => handleRecipientAdd(e, true, recipient)}
                    inputProps={{
                      style: {
                        fontSize: '14px',
                        padding: '7px 0px',
                        fontFamily: 'IBMPlexSans'
                      }
                    }}
                    fullWidth />
                }
                <Grid container
                  className="mt-8"
                  spacing={1}>
                  {get(state, 'recipients', [])
                    .map((item, itemIndex) => {
                      return (
                        <Grid item key={itemIndex}>
                          <Chip label={item.email}
                            onDelete={() => {
                              setState({
                                recipients: removeFromArray(get(state, 'recipients', []), item)
                              })
                            }}
                            style={item.invalid ? chipInvalidStyle : chipStyle}
                          />
                        </Grid>
                      )
                    })
                  }
                  {find(get(state, 'recipients', []), { invalid: true }) &&
                    <Grid item xs={12}
                      className="text-12 fg-red">You have some invalid email addresses in recipient list, please remove them to proceed.
                      </Grid>
                  }
                </Grid>
              </Grid>
              {/* End of Recipients */}

              {/* Subject */}
              <Grid item xs={12} className="pb-20">
                <label className='text-12 medium'>
                  Subject:</label>
                <TextField placeholder="Enter subject (optional)"
                  name="subject"
                  value={get(state, 'subject', '')}
                  onChange={handleInputChange}
                  inputProps={{
                    style: {
                      fontSize: '14px',
                      padding: '7px 0px',
                      fontFamily: 'IBMPlexSans'
                    }
                  }}
                  fullWidth />
              </Grid>
              {/* End of Subject */}

              {/* Message Body */}
              <Grid item xs={12} className="pb-20">
                <label className='text-12 medium'>
                  Message Body:</label>
                <Grid container className="mt-8">
                  <ReactQuill value={get(state, 'messageBody', '')}
                    onChange={handleEditorChange}
                    placeholder="Enter your message here..." />
                </Grid>
              </Grid>
              {/* End of Message Body */}

            </Grid>
          </Grid>
        </Grid>
      </FuseSideBarBody>

      <FuseSideBarActions>
        <LoadingButton className='btn btn_full-space'
          loading={loading}
          disabled={(get(state, 'recipients', []).length === 0 ||
            find(get(state, 'recipients', []), { invalid: true }))}
          onClick={handleSubmit}>
          SEND</LoadingButton>
      </FuseSideBarActions>

    </FuseSideBar>
  )
}

export default ManualNotification
