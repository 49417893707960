import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
// `react-router-redux` is deprecated, so we use `connected-react-router`.
// This provides a Redux middleware which connects to our `react-router` instance.
import { routerMiddleware } from 'connected-react-router'
// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore } from 'redux-persist'
import { createRootReducer } from './core/reducers'
import { setTokenHeader, setContextPlantHeader } from './core/api'

export default function configureStore(history, initialState) {
  // create the composing function for our middlewares
  const composeEnhancers = composeWithDevTools({})

  // We'll create our store with the combined reducers and thunk, and the initial Redux state that
  // we'll be passing from our entry point.
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  )

  const persistor = persistStore(store, undefined, () => {
    const { token } = store.getState().auth
    const { currentPlant } = store.getState().welcome
    if (token) {
      setTokenHeader(token)
    }
    if (currentPlant && currentPlant._id) {
      setContextPlantHeader(currentPlant._id)
    }
  })

  return { store, persistor }
}
