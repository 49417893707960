import React from 'react'
import {
  Grid,
  Button
} from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader
} from '../../core/components'
import { get, find, filter } from 'lodash'
import BookingWizardSummary from '../../manage-booking/components/ProposeBookingSummary'
import { general } from "../../core/localization";

const ReviewExceptionDialog = (props) => {
  const {
    handleClose,
    handleResolve,
    exceptions,
    shipment
  } = props

  return (
    <FuseDialog open={get(props, 'open', false)}>
      <FuseDialogHeader handleClose={handleClose}
        text={`Review Exception (${general.exceptions[get(exceptions, 'suggestNextResolutionProcess')]})`}
      />
      <FuseDialogBody>
        <Grid container className="booking-wizard">
          <Grid item sm={12} md={8}
            className="min-h-full">
            <Grid container className="h-full booking-forms">

              {/* START Conflicting */}
              <Grid item xs={12} className="pb-28">
                <Grid container className={'field-control'}>
                  <Grid item xs={12}>
                    <Grid container justify="space-between"
                      className="pb-20">
                      <label className={'field-label'}>Conflicting lane information</label>
                      <label className={'field-instruction'}>Exception type</label>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {!find(get(exceptions, 'errors', []), { resolutionProcess: 'XRP002' }) &&
                      <label className="medium fg-gray">
                        No conflicting lanes...</label>
                    }
                    {filter(get(exceptions, 'errors', []), { resolutionProcess: 'XRP002' })
                      .map((conflict, conflictIndex) => {
                        return (
                          <Grid container spacing={1}
                            key={conflictIndex}
                            className="py-6">
                            <Grid item xs={4}>
                              <label className="medium">
                                Problem at {get(conflict, 'originProperty')}</label>
                            </Grid>
                            <Grid item xs={8}>
                              <label className="regular fg-gray">
                                {get(conflict, 'message')}
                              </label>
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Grid>
              </Grid>
              {/* END Conflicting */}

              {find(get(exceptions, 'errors', []),
                {
                  resolutionProcess: 'XRP003',
                  originProperty: 'manualInterventionByUser'
                }) &&
                /* START Reason */
                <Grid item xs={12} className="pb-72">
                  <Grid container className={'field-control'}>
                    <Grid item xs={12}>
                      <Grid container className="pb-12">
                        <label className={'field-label'}>Reason to move this booking to spot</label>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <label className="regular fg-gray">
                        {get(find(get(exceptions, 'errors', []),
                          {
                            resolutionProcess: 'XRP003',
                            originProperty: 'manualInterventionByUser'
                          }),
                          'message',
                          'No reason provided...'
                        ) || 'No reason provided...'}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
                /* END Reason */
              }

              {find(get(exceptions, 'errors', []), item =>
                (item.resolutionProcess === 'XRP003' &&
                  item.originProperty !== 'manualInterventionByUser')) &&
                /* Additional Errors */
                <Grid item xs={12} className="pb-72">
                  <Grid container className={'px-20 py-12 mt-20 bg-light-orange'}>
                    <Grid item xs={12}
                      className="pb-12">
                      <label className={'text-16 semi-bold fg-orange'}>
                        Additional Errors</label>
                    </Grid>
                    {filter(get(exceptions, 'errors', []), item =>
                      (item.resolutionProcess === 'XRP003' &&
                        item.originProperty !== 'manualInterventionByUser'))
                      .map((errorItem, errorIndex) => {
                        return (
                          <Grid item xs={12}
                            key={errorIndex}
                            className="pb-4">
                            <label className={'text-10 fg-gray'}>
                              {errorItem.message}
                            </label>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Grid>
                /* END of Additional Errors */
              }

              {/* START Actions */}
              <Grid item xs={12}>
                <Grid container
                  justify="space-between"
                  alignItems="flex-end">
                  <Grid item sm={12} md={6}>
                    <Button className='btn w-full'
                      onClick={handleResolve}>Resolve exception</Button>
                  </Grid>

                  <Grid item sm={12} md={6}>
                    <Grid container justify="flex-end">
                      <Button className={'btn btn_transparent'}
                        onClick={handleClose}>Not now</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* END Actions */}
            </Grid>
          </Grid>
          <Grid item sm={12} md={4}>
            <BookingWizardSummary shipment={shipment} />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog >
  )
}

export default ReviewExceptionDialog
