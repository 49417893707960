import _, { get, find } from 'lodash'
import templateVariables from './json/templateVariables.json'
import fileUploads from '../localization/file-upload-info.json'

export function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min
}

export function getRounded(obj, property, defaultVal, decimals) {
  let val = _.get(obj, property, defaultVal)
  return parseFloat(val).toFixed(decimals)
}

export function preparePartners(serviceCards) {
  var plants = JSON.parse(localStorage.getItem('user')).plants
  var currentPlant = _.find(plants, {
    id: JSON.parse(localStorage.getItem('currentPlantID'))
  })

  const partnerJson = {
    plantId: null,
    organizationId: null,
    plant: null
  }

  /**
   * @todo Update consignee when we settled
   */
  var partners = {
    shipper: {
      ...partnerJson,
      plantId: JSON.parse(localStorage.getItem('currentPlantID')),
      organizationId: JSON.parse(localStorage.getItem('user')).organization,
      plant: currentPlant
    },
    obl: [],
    ibl: [],
    ibc: [],
    obc: [],
    nvocc: {
      ...partnerJson
    },
    consignee: {
      ...partnerJson,
      plantId: JSON.parse(localStorage.getItem('currentPlantID')),
      organizationId: JSON.parse(localStorage.getItem('user')).organization,
      plant: currentPlant
    }
  }

  serviceCards.forEach(serviceCard => {
    partners.nvocc.plantId = serviceCard.nvocc.plantId
    partners.nvocc.organizationId = serviceCard.nvocc.organizationId
    partners.nvocc.plant = serviceCard.nvocc.plantData

    serviceCard.ibc.forEach(ibcItem => {
      if (
        !_.find(partners.ibc, {
          plantId: ibcItem.plantId
        })
      ) {
        partners.ibc.push({
          ...partnerJson,
          plantId: ibcItem.plantId,
          organizationId: ibcItem.organizationId,
          plant: ibcItem.plantData
        })
      }
    })
    serviceCard.ibl.forEach(iblItem => {
      if (
        !_.find(partners.ibl, {
          plantId: iblItem.plantId
        })
      ) {
        partners.ibl.push({
          ...partnerJson,
          plantId: iblItem.plantId,
          organizationId: iblItem.organizationId,
          plant: iblItem.plantData
        })
      }
    })
    serviceCard.obl.forEach(oblItem => {
      if (
        !_.find(partners.obl, {
          plantId: oblItem.plantId
        })
      ) {
        partners.obl.push({
          ...partnerJson,
          plantId: oblItem.plantId,
          organizationId: oblItem.organizationId,
          plant: oblItem.plantData
        })
      }
    })
    serviceCard.obc.forEach(obcItem => {
      if (
        !_.find(partners.obc, {
          plantId: obcItem.plantId
        })
      ) {
        partners.obc.push({
          ...partnerJson,
          plantId: obcItem.plantId,
          organizationId: obcItem.organizationId,
          plant: obcItem.plantData
        })
      }
    })
  })

  return partners
}

export function getPlannedRouteJson() {
  return {
    name: null,
    locode: null,
    address: null,
    city: null,
    country: null
  }
}

export function getEventSource(shipment, entityType = null) {
  const { partners } = shipment
  if (entityType === null || entityType === 'shipper') {
    return entityType
  } else if (Array.isArray(partners[entityType])) {
    return partners[entityType][0].plant.plantName.split('_')[0]
  } else {
    return partners[entityType].plant.plantName.split('_')[0]
  }
}

export function prepareBenchmarks(serviceCards) {
  /**
   * @todo For now selecting first serviceCard's benchmark,
   * Change it to some logical way
   */
  return _.get(serviceCards[0], 'benchmark', null)
}

export function prepareFreightServiceProviderType(serviceCards) {
  /**
   * @todo For now selecting first serviceCard's freightServiceProviderType,
   * Change it to some logical way
   */
  return _.get(serviceCards[0], 'freightServiceProviderType', null)
}

export function copyFirstContainerEvents(containers, index, events, allowUndefined = false) {
  const availableContainerIndexes = []
  const availableContainers = []

  containers.forEach((item, itemIndex) => {
    if (_.get(item, 'status.current.value') !== 'cancelled' &&
      !_.get(item, 'cancelled')) {
      availableContainers.push(item)
      availableContainerIndexes.push(itemIndex.toString())
    }
  })

  events.forEach(event => {
    const firstContainerReferenceEvents = _.get(availableContainers, `[0].referenceEvents.${event}`);
    let value

    if (allowUndefined && firstContainerReferenceEvents === undefined) {
      value = firstContainerReferenceEvents
    } else {
      value = { ...firstContainerReferenceEvents }
    }

    _.set(
      containers,
      `[${Number(availableContainerIndexes[index])}].referenceEvents.${event}`,
      value
    )
  })

  return containers;
}

export function camelCaseSlugify(varName, slug = '_') {
  return varName.replace(/([A-Z])/g, slug + '$1')
}

export function getShipmentDetailsTabs() {
  return [
    "Events",
    "Details",
    "Container",
    "Documents",
    "History",
    "Notices",
    "Pre-Alerts",
    "Goods Received"
  ]
}

export function getToken(length = 128, reoccurringToken = '') {
  const rand = () => Math.random(0).toString(36).substr(2);
  const token = reoccurringToken + rand()
  if (token.length < length) {
    return getToken(length, token)
  }
  return token.substr(0, length)
}

/**
 * Get Email Template variables by type
 * @param {"subject"|"body"} type
 */
export function getVariablesByType(type) {
  return _.filter(templateVariables, item => item.types.includes(type))
}

export function stringToXML(rawXML) {
  var formatted = '';
  var reg = /(>)(<)(\/*)/g;
  rawXML = rawXML.replace(reg, '$1\r\n$2$3');
  var pad = 0;
  _.each(rawXML.split('\r\n'), function (node, index) {
    var indent = 0;
    if (node.match(/.+<\/\w[^>]*>$/)) {
      indent = 0;
    } else if (node.match(/^<\/\w/)) {
      if (pad !== 0) {
        pad -= 1;
      }
    } else if (node.match(/^<\w[^>]*[^/]>.*$/)) {
      indent = 1;
    } else {
      indent = 0;
    }

    var padding = '';
    for (var i = 0; i < pad; i++) {
      padding += '  ';
    }

    formatted += padding + node + '\r\n';
    pad += indent;
  });

  formatted = formatted.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/ /g, '&nbsp;').replace(/\n/g, '<br />');

  return formatted
}

export function getUploadableDocumentOptions() {
  const keys = _.filter(Object.keys(fileUploads), i => i !== 'outbounds')
  return keys.map(i => ({
    value: i,
    label: _.get(fileUploads, i)
  }))
}
export const getShipmentLink = (shipment) => {
  const shipmentLinks = [
    { name: 'scheduled', title: 'Scheduled', url: '/schedule', icon: 'calendar_today' },
    { name: 'in-transit', title: 'In Transit', url: '/in-transit', icon: 'directions_boat' },
    { name: 'import', title: 'Inbound', url: '/inbound', icon: 'play_for_work' },
    { name: 'exceptions', title: 'Exceptions', url: '/exceptions', icon: 'warning_outline' },
    { name: 'completed', title: 'Completed', url: '/completed', icon: 'check_circle' },
    { name: 'cancelled', title: 'Cancelled', url: '/cancelled', icon: 'cancelled' },
  ]

  if (get(shipment, 'exceptionMode.enabled')) {
    return find(shipmentLinks, { name: 'exceptions' })
  } else {
    return find(shipmentLinks, { name: get(shipment, 'stage.current.value', 'scheduled') })
  }
}
