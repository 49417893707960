import React, { Component } from 'react'
import PerfectScrollbar from 'perfect-scrollbar'
import PropTypes from 'prop-types'
import MobileDetect from 'mobile-detect'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

const md = new MobileDetect(window.navigator.userAgent)
const isMobile = md.mobile()

const handlerNameByEvent = {
  'ps-scroll-y': 'onScrollY',
  'ps-scroll-x': 'onScrollX',
  'ps-scroll-up': 'onScrollUp',
  'ps-scroll-down': 'onScrollDown',
  'ps-scroll-left': 'onScrollLeft',
  'ps-scroll-right': 'onScrollRight',
  'ps-y-reach-start': 'onYReachStart',
  'ps-y-reach-end': 'onYReachEnd',
  'ps-x-reach-start': 'onXReachStart',
  'ps-x-reach-end': 'onXReachEnd'
}

Object.freeze(handlerNameByEvent)

class FuseScrollbars extends Component {
  constructor(props) {
    super(props)
    this._handlerByEvent = new Map()
  }

  componentDidUpdate() {
    if (this.props.customScrollbars) {
      setTimeout(() => {
        this.createPs()
      })
    } else {
      setTimeout(() => {
        this.destroyPs()
      })
    }

    this.updatePs()
  }

  componentDidMount() {
    this.createPs()
  }

  componentWillUnmount() {
    this.destroyPs()
  }

  updatePs = () => {
    if (!this._ps) {
      return
    }
    this._ps.update()
  }

  destroyPs = () => {
    if (!this._ps) {
      return
    }
    // unhook up evens
    Object.keys(this._handlerByEvent).forEach((value, key) => {
      this._container.removeEventListener(key, value, false)
    })
    this._handlerByEvent.clear()
    this._ps.destroy()
    this._ps = null
  }

  createPs = () => {
    if (isMobile || !this._container || this._ps) {
      return
    }

    this._ps = new PerfectScrollbar(this._container, this.props.option)

    // hook up events
    Object.keys(handlerNameByEvent).forEach(key => {
      const callback = this.props[handlerNameByEvent[key]]
      if (callback) {
        const handler = () => callback(this._container)
        this._handlerByEvent.set(key, handler)
        this._container.addEventListener(key, handler, false)
      }
    })
  }

  handleRef = ref => {
    this._container = ref
    this.props.containerRef(ref)
  }

  render() {
    const { children, className, customScrollbars, enable, id } = this.props

    return customScrollbars && enable && !isMobile ? (
      <div
        id={id}
        className={className}
        style={{
          position: 'relative',
          overflow: 'hidden'
        }}
        ref={this.handleRef}
      >
        {children}
      </div>
    ) : (
      <div id={id} className={this.props.className} ref={this.handleRef}>
        {this.props.children}
      </div>
    )
  }
}


FuseScrollbars.propTypes = {
  onScrollY: PropTypes.func,
  onScrollX: PropTypes.func,
  onScrollUp: PropTypes.func,
  onScrollDown: PropTypes.func,
  onScrollLeft: PropTypes.func,
  onScrollRight: PropTypes.func,
  onYReachStart: PropTypes.func,
  onYReachEnd: PropTypes.func,
  onXReachStart: PropTypes.func,
  onXReachEnd: PropTypes.func
}

FuseScrollbars.defaultProps = {
  className: '',
  enable: true,
  option: {
    wheelPropagation: true
  },
  containerRef: () => {},
  onScrollY: undefined,
  onScrollX: undefined,
  onScrollUp: undefined,
  onScrollDown: undefined,
  onScrollLeft: undefined,
  onScrollRight: undefined,
  onYReachStart: undefined,
  onYReachEnd: undefined,
  onXReachStart: undefined,
  onXReachEnd: undefined
}

export default FuseScrollbars
