import ActionTypes from './types'
import { fileUploadInfo } from '../localization'
import { get } from 'lodash'

const uploadInitialState = {}

Object.keys(fileUploadInfo).forEach(k => {
  uploadInitialState[k] = {}
})

const initialState = {
  networkDown: false,
  shipmentCounts: {
    scheduled: 0,
    inTransit: 0,
    completed: 0,
    exceptions: 0
  },
  messages: {
    open: false,
    options: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      style: {
        margin: '20px 0px'
      },
      autoHideDuration: 6000
    }
  },
  upload: uploadInitialState,
  filters: {
    originCountries: [],
    destinationCountries: [],
    originPorts: [],
    destinationPorts: [],
    originPlants: [],
    destinationPlants: [],
    transportation: [],
    ofp: [],
    obl: [],
    obc: []
  },
  customFilters: [],
  documents: [],
  notifications: [],
  unreadNotifcationCount: 0,
  badPermInfo: {
    code: 500,
    message: 'Something went wrong'
  },
  redirectBackToUrl: '',
  apiVersion: '',
  userSettings: {
    eventsList: [],
    eventsNotification: [],
    appTheme: '',
    scheduleDefaultMode: '',
    shipmentCardPrimaryId: ''
  },
  plants: [],
  systemSettings: {},
  usersByPlantIds: [],
  authProviders: [],
  systemSearchShipments: {
    loading: true,
    search: '',
    total: 0,
    data: []
  },
  autoRefreshStatus: false,
}

const coreReducer = (state = initialState, action) => {
  // check file upload first
  const uploadTypeInfo = action.type.match(/^UPLOAD_(.+)_FILE$/)

  if (uploadTypeInfo) {
    const type = Object.keys(fileUploadInfo).find(v => v.toUpperCase() === uploadTypeInfo[1])

    return {
      ...state,
      upload: {
        ...state.upload,
        [type]: action.payload
      }
    }
  }

  switch (action.type) {
    case ActionTypes.SET_NETWORK_DOWN:
      return {
        ...state,
        networkDown: action.networkDown
      }
    case ActionTypes.SET_SHIPMENT_COUNTS:
      return {
        ...state,
        shipmentCounts: action.shipmentCounts
      }
    case ActionTypes.SHOW_MESSAGE:
      return {
        ...state,
        messages: {
          open: true,
          options: {
            ...state.messages.options,
            ...action.options
          }
        }
      }

    case ActionTypes.HIDE_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          open: false
        }
      }

    case ActionTypes.LOAD_ORIGIN_COUNTRIES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          originCountries: action.payload
        }
      }
    }

    case ActionTypes.LOAD_DESTINATION_COUNTRIES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          destinationCountries: action.payload
        }
      }
    }
    case ActionTypes.LOAD_ORIGIN_PORTS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          originPorts: action.payload
        }
      }
    }
    case ActionTypes.LOAD_DESTINATION_PORTS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          destinationPorts: action.payload
        }
      }
    }
    case ActionTypes.LOAD_ORIGIN_PLANTS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          originPlants: action.payload
        }
      }
    }
    case ActionTypes.LOAD_DESTINATION_PLANTS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          destinationPlants: action.payload
        }
      }
    }
    case ActionTypes.LOAD_TRANSPORTATION: {
      return {
        ...state,
        filters: {
          ...state.filters,
          transportation: action.payload
        }
      }
    }
    case ActionTypes.LOAD_OFP: {
      let payload = action.payload.map(el => {
        return { name: el.name.name }
      })
      return {
        ...state,
        filters: {
          ...state.filters,
          ofp: payload
        }
      }
    }
    case ActionTypes.LOAD_OBL: {
      let payload = action.payload.map(el => {
        return { name: el.name.name }
      })
      return {
        ...state,
        filters: {
          ...state.filters,
          obl: payload
        }
      }
    }
    case ActionTypes.LOAD_OBC: {
      let payload = action.payload.map(el => {
        return { name: el.name.name }
      })
      return {
        ...state,
        filters: {
          ...state.filters,
          obc: payload
        }
      }
    }
    case ActionTypes.SET_BAD_PERM_INFO: {
      return {
        ...state,
        badPermInfo: action.payload
      }
    }
    case ActionTypes.SET_CUSTOM_FILTERS: {
      return {
        ...state,
        customFilters: action.payload
      }
    }
    case ActionTypes.SET_DOCUMENTS: {
      return {
        ...state,
        documents: action.payload
      }
    }
    case ActionTypes.SET_USER_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload
      }
    }
    case ActionTypes.MARK_USER_NOTIFICATIONS_READ: {
      return {
        ...state,
        notifications: state.notifications.map(item => {
          if (action.payload.includes(item._id)) {
            item.isRead = true
          }
          return item
        })
      }
    }
    case ActionTypes.SET_UNREAD_USER_NOTIFICATIONS: {
      return {
        ...state,
        unreadNotifcationCount: action.payload
      }
    }
    case ActionTypes.HIGH_SECURITY_TOKEN_REDIRECT:
      return {
        ...state,
        redirectBackToUrl: action.redirectBackToUrl
      }
    case ActionTypes.GET_VERSION_SUCCESS:
      return {
        ...state,
        apiVersion: action.payload
      }
    case 'RESET_UPLOAD_LOG': {
      return {
        ...state,
        upload: uploadInitialState
      }
    }
    case ActionTypes.LOAD_USER_SETTINGS_EVENTS_LIST: {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          eventsList: get(action, 'payload', [])
        }
      }
    }
    case 'USER_SETTINGS_EVENTS_NOTIFICATION': {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          eventsNotification: get(action, 'payload', [])
        }
      }
    }
    case 'USER_SETTINGS_APP_THEME': {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          appTheme: get(action, 'payload[0].value', 'light')
        }
      }
    }
    case 'USER_SETTINGS_SCHEDULE_DEFAULT_MODE': {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          scheduleDefaultMode: get(action, 'payload[0].value', '')
        }
      }
    }
    case 'USER_SETTINGS_SHIPMENT_CARD_PRIMARY_ID': {
      return {
        ...state,
        userSettings: {
          ...state.userSettings,
          shipmentCardPrimaryId: get(action, 'payload[0].value', '')
        }
      }
    }
    case ActionTypes.LOAD_PLANTS: {
      return {
        ...state,
        plants: action.payload
      }
    }
    case ActionTypes.LOAD_SYSTEM_SETTINGS: {
      const setting = get(action, 'payload.key') ?
        {
          [get(action, 'payload.key')]: get(action, 'payload.value')
        } : {}
      return {
        ...state,
        systemSettings: {
          ...state.systemSettings,
          ...setting
        }
      }
    }
    case ActionTypes.LOAD_USERS_BY_PLANT_IDS: {
      return {
        ...state,
        usersByPlantIds: action.payload
      }
    }
    case ActionTypes.LOAD_AUTH_PROVIDERS: {
      return {
        ...state,
        authProviders: action.payload
      }
    }
    case ActionTypes.LOADING_SYSTEM_SEARCH_SHIPMENTS: {
      return {
        ...state,
        systemSearchShipments: initialState.systemSearchShipments
      }
    }
    case ActionTypes.LOAD_SYSTEM_SEARCH_SHIPMENTS: {
      return {
        ...state,
        systemSearchShipments: {
          loading: false,
          search: action.search,
          total: action.total,
          data: action.payload
        }
      }
    }
    case ActionTypes.SET_AUTO_REFRESH:
      return {
        ...state,
        autoRefreshStatus: action.status
      }

    default:
      return state
  }
}

export default coreReducer
