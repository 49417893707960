import React from 'react'
import { withStyles, Grid, FormControlLabel } from '@material-ui/core'
import classNames from 'classnames'
import { FuseCheckBox, InfoToolTip } from 'core/components'
import { get } from 'lodash'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import FlightIcon from '@material-ui/icons/Flight';
import TrainIcon from '@material-ui/icons/Train';
import InfoIcon from '@material-ui/icons/Info';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

const OutBoundTableRow = ({ classes, className, data, onChkClick, onOutboundsClick }) => {
  if (!data) {
    return null
  }

  return (
    <div className='import-groups list-container regular'>
      <Grid container alignItems='flex-start'
        className={classNames('booking__item', className)}>
        <Grid item xs={12} className='px-16'>
          <div className='booking__header justify-between text-14'>
            <div className='flex items-center'>
              <FormControlLabel label={
                <span className='bold text-14 text-grey-darkest items-center flex'>
                  {data.code}
                  {get(data, 'outboundTransportMethods', []).includes('CBT') &&
                    <InfoToolTip title="CBT">
                      <LocalShippingIcon className="ml-12 fg-gray text-16" />
                    </InfoToolTip>
                  }
                  {get(data, 'outboundTransportMethods', []).includes('ocean') &&
                    <InfoToolTip title="OCEAN">
                      <DirectionsBoatIcon className="ml-12 fg-gray text-16" />
                    </InfoToolTip>
                  }
                  {get(data, 'outboundTransportMethods', []).includes('air') &&
                    <InfoToolTip title="AIR">
                      <FlightIcon className="ml-12 fg-gray text-16" />
                    </InfoToolTip>
                  }
                  {get(data, 'outboundTransportMethods', []).includes('rail') &&
                    <InfoToolTip title="RAIL">
                      <TrainIcon className="ml-12 fg-gray text-16" />
                    </InfoToolTip>
                  }
                  {get(data, 'outboundTransportMethods', []).includes('NA') &&
                    <InfoToolTip title="OTHER">
                      <InfoIcon className="ml-12 fg-gray text-16" />
                    </InfoToolTip>
                  }
                </span>
              } control={
                <FuseCheckBox checked={!!data.checked}
                  className='p-0 pr-8'
                  onChange={() => onChkClick(data)} />
              } />
            </div>
            <div className='flex justify-end text-14 bold'>
              {get(data, 'outboundSources', []).length > 0 &&
                <span className={classes.shipmentSources}>
                  <InfoToolTip title={<Grid container className="regular">
                    <Grid item xs={12} className="bold mb-8">Sources</Grid>
                    {get(data, 'outboundSources', []).map((i, iIndex) =>
                      <Grid item xs={12} key={iIndex} className="uppercase">{i}</Grid>
                    )}
                  </Grid>}>
                    <DeviceHubIcon className="mr-12 cursor-pointer fg-gray text-16" />
                  </InfoToolTip>
                </span>
              }
              {data.specialLabel && <span className={classes.specialLabel}>{data.specialLabel}</span>}
              {data.goodsType && (
                <span className={classes.goodsType}>{data.goodsType !== 'NORMAL' ? data.goodsType : ''}</span>
              )}
              {data.shipmentMode && <span className={classes.shipmentMode}>{data.shipmentMode}</span>}
            </div>
          </div>
          <Grid container className='flex justify-between text-12 pt-12 pb-8'>
            <Grid item xs={4}>
              <span className='text-grey pr-8'>Weight</span>
              <span className='text-grey-dark'>
                {parseFloat(data.totalWeight).toFixed(3)} {data.weightUnit}
              </span>
            </Grid>
            <Grid item xs={4}>
              <span className='text-grey pr-8'>Volume</span>
              <span className='text-grey-dark'>
                {parseFloat(data.totalVolume).toFixed(3)} {data.volumeUnit}
              </span>
            </Grid>
            <Grid item xs={4} className='flex justify-end'>
              <span
                className='booking__ob_grouped text-grey-dark cursor-pointer'
                onClick={onOutboundsClick}
              >
                {data.outboundCount} Outbound{data.outboundCount > 1 ? 's' : ''}
              </span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  shipmentSources: {
    color: '#9FA0A4',
  },
  shipmentMode: {
    color: '#EFB266',
    paddingLeft: 16
  },
  goodsType: {
    color: '#B10043',
    paddingLeft: 16
  },
  specialLabel: {
    color: '#2784D9',
    paddingLeft: 16
  }
})

export default withStyles(styles)(OutBoundTableRow)
