import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { get, find } from 'lodash'
import { useMergeState, addGaEvent } from '../../core/helpers'
import { FuseSideBar, FuseSideBarHeader, FuseSideBarBody, FuseSideBarActions } from 'core/components'
import Button from '@material-ui/core/Button'
import AutoComplete from '../../core/components/Inputs/AutoComplete'
import Carriers from '../../core/helpers/json/carriers.json'
import Airlines from '../../core/helpers/json/airlines.json'

const INITIAL_STATE = {
  freightOperatorInfo: {
    name: '',
    identifier: {
      type: 'SCAC',
      value: ''
    }
  }
}

const CarrierEditSidebar = ({ open, closeSideBar, carrierInfo, onSubmit, currentPlant, shipment }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const { transportMethod } = shipment

  const getCarrierOptions = () => {
    if (transportMethod === 'air') {
      return Airlines.map(airline => {
        return {
          value: airline.airline,
          label: airline.airline
        }
      })
    } else {
      return Carriers.map(carrier => {
        return {
          value: carrier.name,
          label: carrier.name
        }
      })
    }
  }

  const getScacOptions = () => {
    if (transportMethod === 'air') {
      const iata = get(find(Airlines, { airline: get(state, 'freightOperatorInfo.name') }), 'IATA', '')
      if (iata) {
        return [{
          value: iata,
          label: iata
        }]
      } else {
        return []
      }
    } else {
      return get(find(Carriers, { name: get(state, 'freightOperatorInfo.name') }), 'SCAC', []).map(scac => {
        return {
          value: scac,
          label: scac
        }
      })
    }
  }

  useEffect(() => {
    if (open) {
      addGaEvent('shipment-updatingCarrier')
    }
    setState({
      freightOperatorInfo: {
        name: get(carrierInfo, 'name', ''),
        identifier: {
          type: 'SCAC',
          value: get(carrierInfo, 'identifier.value', '')
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, carrierInfo])

  return (
    <FuseSideBar open={open} onBackDropClick={closeSideBar}>
      <FuseSideBarHeader
        text={transportMethod === 'air' ? "Edit Airline info" : "Edit Carrier info"}
        handleClose={() => {
          addGaEvent('shipment-updatingCarrierCancelled',)
          closeSideBar()
        }}
      />
      <FuseSideBarBody>
        <Grid container>
          <Grid item xs={12}>
            <Grid container
              className="mb-28">
              <Grid item xs={12}
                className="medium text-16 pb-8">{transportMethod === 'air' ? 'Airline name' : 'Carrier name'}</Grid>
              <Grid item xs={12}>
                <AutoComplete value={get(state, 'freightOperatorInfo.name')}
                  options={getCarrierOptions()}

                  placeholder={transportMethod === 'air' ? "Select airline..." : "Select carrier..."}
                  onChange={(value) => setState({
                    freightOperatorInfo: {
                      ...state.freightOperatorInfo,
                      name: value
                    }
                  })}
                  canCreate />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}
                className="medium text-16 pb-8">{transportMethod === 'air' ? 'IATA' : 'SCAC code'}</Grid>
              <Grid item xs={12}>
                <AutoComplete value={get(state, 'freightOperatorInfo.identifier.value')}
                  options={getScacOptions()}
                  placeholder={transportMethod === 'air' ? "Select IATA" : "Select SCAC..."}
                  onChange={(value) => setState({
                    freightOperatorInfo: {
                      ...state.freightOperatorInfo,
                      identifier: {
                        ...state.freightOperatorInfo.identifier,
                        value
                      }
                    }
                  })}
                  canCreate
                  dynamic />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </FuseSideBarBody>
      {
        (get(currentPlant, 'type') === 'ofp' || get(currentPlant, 'type') === 'shipper') &&
        <FuseSideBarActions>
          <Button className='btn btn_full-space' onClick={() => onSubmit(state)}>
            SAVE
        </Button>
        </FuseSideBarActions>
      }

    </FuseSideBar>
  )
}

export default CarrierEditSidebar
