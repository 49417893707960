import React, { useState, useEffect } from 'react'
import { withStyles, Button } from '@material-ui/core'

const NumberCounter = ({ classes, value, onChange }) => {
  const [state, setState] = useState(0)

  useEffect(() => {
    setState(value || 0)
  }, [value])

  const onClick = (delta) => () => {
    const newState = state + delta
    if (newState < 0) {
      return
    }
    setState(newState)
    onChange(newState)
  }

  return (
    <div className='w-128 flex justify-between text-base text-grey-dark'>
      <Button className={classes.root} onClick={onClick(-1)}>&#8722;</Button>
      <span className='text-grey-darkest font-bold'>{state}</span>
      <Button className={classes.root} onClick={onClick(1)}>&#43;</Button>
    </div>
  )
}

const styles = theme => ({
  root: {
    padding: 0,
    width: 24,
    height: 24,
    minWidth: 'auto',
    background: 'transparent',
    border: '1px solid #9ca7c4',
    borderRadius: '100%',

    '& > span': {
      position: 'absolute',
      fontWeight: 'lighter',
      fontSize: 22,
      top: -9,
      color: '#9ca7c4'
    }
  }
})

export default withStyles(styles)(NumberCounter)
