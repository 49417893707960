import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  GET_ALL_SHIPMENT_SUCCESS
  GET_ALL_SHIPMENT_TOTAL_SUCCESS
  GET_OUTBOUNDS_BY_SHIPMENT_SUCCESS
  GET_VESSELS_SUCCESS
  SET_SHIPMENTS_LOADING
  SET_SHIPMENTS_LOADING_MORE
  GET_SHIPMENT_META_SUCCESS
  GET_LIMITED_SERVICE_CARDS_SUCCESS
  `
)
