import React, { useState, useEffect } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { FuseDialog, FuseDialogBody, FuseDialogHeader, LoadingButton } from '../../core/components'
import { getAddressFromLocation } from '../../core/helpers'
import { get, find } from 'lodash'
import TransportTimeline from '../../core/components/Timelines/TransportTimeline'

const CancelAndRestartReasonDialog = (props) => {
  const {
    open,
    handleClose,
    shipment,
    onSubmit,
    requesting
  } = props
  const [reason, setReason] = useState('');
  const originWH = find(get(shipment, 'journey', []), { location: { subType: 'origin', type: 'warehouse' } })
  const destinationWH = find(get(shipment, 'journey', []), { location: { subType: 'destination', type: 'warehouse' } })

  useEffect(() => {
    if (open) {
      setReason('')
    }
  }, [open])

  const transportRoute = [
    {
      place: get(originWH, 'location.name'),
      address: getAddressFromLocation(get(originWH, 'location.address', {}))
    },
    {
      place: get(destinationWH, 'location.name'),
      address: getAddressFromLocation(get(destinationWH, 'location.address', {}))
    }
  ];

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text="Cancel and Restart"
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="text-14">

          {/* Form section */}
          <Grid item xs={8}
            className="bg-white p-20 min-h-full">
            <Grid container className="h-full">
              {/* Reason */}
              <Grid item xs={12} className='pb-40'>
                <Grid container direction='row'>
                  <Grid item xs={12}>
                    <label className="text-16 semi-bold require-field">
                      Reason</label>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name='reason'
                      placeholder="Specify a reason for cancelling this shipment…"
                      className='theme-text-field w-full'
                      value={reason}
                      inputProps={{
                        style: {
                          fontSize: '14px'
                        }
                      }}
                      onChange={event => setReason(event.target.value)} />
                  </Grid>
                </Grid>
              </Grid>
              {/* END of Reason */}

              {/* Actions */}
              <Grid item xs={12} className='pt-40'>
                <Grid container className="h-full" alignItems="flex-end">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={6}>
                        <LoadingButton className="btn btn_light_red w-full"
                          style={{
                            paddingLeft: '30px',
                            paddingRight: '30px',
                          }}
                          disabled={!reason}
                          loading={requesting}
                          onClick={() => onSubmit(reason, true)}>
                          Cancel</LoadingButton>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <LoadingButton className="btn btn_light_orange w-full"
                          style={{
                            paddingLeft: '30px',
                            paddingRight: '30px',
                          }}
                          disabled={!reason}
                          loading={requesting}
                          onClick={() => onSubmit(reason)}>
                          Cancel & Restart</LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* END of Actions */}

          </Grid>
          {/* END of Form section */}

          {/* Summary section */}
          <Grid item xs={4}
            className="bg-gray p-20">
            <Grid container>
              <Grid item xs={12} className="pt-20">
                <Grid container>
                  <Grid item xs={12}
                    className="text-16 pb-12">Transport route</Grid>
                  <TransportTimeline data={transportRoute} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* END of Summary section */}

        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default CancelAndRestartReasonDialog
