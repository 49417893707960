import React, { Component } from 'react'
import classNames from 'classnames'
import { ButtonGroup, withStyles } from '@material-ui/core'
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay'
import ReorderIcon from '@material-ui/icons/Reorder'
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout'
import { connect } from 'react-redux'
import * as ShipmentActions from '../store/actions'
import PartnersSidebar from 'core/components/PartnersSidebar'
import CSOutboundsSidebar from '../../core/components/CSOutboundsSidebar'
import * as CoreActions from '../../core/store/actions'
import { FusePlaceHolder, LoadMore, FuseIconButton, VesselTrackDialog } from '../../core/components'
import ShipmentListSkeleton from '../../core/components/Skeletons/ShipmentListSkeleton'
import ShipmentListFilterSkeleton from '../../core/components/Skeletons/ShipmentListFilterSkeleton'
import ShipmentCard from 'core/components/shipment-card/ShipmentCard'
import FilterRow from 'core/components/Filters/shipment-filter/FilterRow'
import { get } from 'lodash'
import {
  setShipmentListFilters,
  getShipmentListFilters,
  startSearching,
  stopSearching
} from '../../core/helpers'
import ReviewCustomClearance from '../components/ReviewCustomClearance'
import UpdateShipmentStatus from '../components/UpdateShipmentStatus'
import UploadNDA from '../components/UploadNDA'
import IOSSwitchComponent from '../../core/components/switches/IOSSwitchComponent';

class ManageIncoming extends Component {
  state = {
    partnerSidebar: {
      open: false,
      actors: {}
    },
    outboundsSidebar: {
      open: false,
      outbounds: [],
      consolidations: []
    },
    customFilter: null,
    status: undefined,
    search: '',
    sort: null,
    minified: [],
    vesselTrackDialog: false,
    reviewCustomClearanceOpen: false,
    updateShipmentStatusOpen: false,
    updateNdaOpen: false
  }
  pagination = {
    pageNo: 1
  }
  filters = null
  search = null

  applyCustomFilter = customFilter => {
    setShipmentListFilters(
      get(this.props, 'userInfo._id'),
      get(this.props, 'currentPlant._id'),
      'import',
      customFilter
    )
    this.setState({ customFilter }, this.getAllShipments)
  }

  resetTimer = () => {
    let timer = setTimeout(() => {
      this.getAllShipments(true)
      this.resetTimer()
    }, 30000)
    clearTimeout(this.timer)
    this.timer = timer
  }

  registerTimer = () => {
    document.addEventListener('mousemove', this.resetTimer)
    document.addEventListener('click', this.resetTimer)
    document.addEventListener('keypress', this.resetTimer)
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    document.removeEventListener('mousemove', this.resetTimer)
    document.removeEventListener('click', this.resetTimer)
    document.removeEventListener('keypress', this.resetTimer)
  }

  componentDidMount() {
    const { userInfo, currentPlant, autoRefreshStatus } = this.props
    const customFilter = getShipmentListFilters(
      get(userInfo, '_id'),
      get(currentPlant, '_id'),
      'import'
    )
    if (customFilter) {
      this.setState({
        customFilter
      }, this.getAllShipments)
    } else {
      this.getAllShipments()
    }

    /* For checking that is the auto refresh or when component is mounted */
    if (autoRefreshStatus) {
      this.registerTimer();
      this.resetTimer();
    }
  }

  onPartnerClick = actors => {
    this.setState({
      partnerSidebar: {
        open: true,
        actors
      }
    })
  }

  onPartnerSidebarClose = () => {
    this.setState({
      partnerSidebar: {
        open: false,
        actors: {}
      }
    })
  }

  onOutboundsClick = async shipment => {
    this.setState({
      outboundsSidebar: {
        open: true,
        outbounds: [],
        consolidations: shipment.consolidations
      }
    })
    const { getOutboundsByShipment } = this.props

    await getOutboundsByShipment(shipment._id)
    const { outboundsByShipment } = this.props
    this.setState({
      outboundsSidebar: {
        open: true,
        outbounds: outboundsByShipment,
        consolidations: shipment.consolidations
      }
    })
  }

  onOutboundsSidebarClose = () => {
    this.setState({
      outboundsSidebar: {
        open: false,
        outbounds: [],
        consolidations: []
      }
    })
  }

  getAllShipments = (isSync = false) => {
    const { status, customFilter, search, sort } = this.state
    let filter = null
    if (customFilter) {
      filter = customFilter.value
      if (filter && filter.groupage && filter.groupage.type === 'none') {
        delete filter.groupage
      }
    }
    this.props.getAllShipments(this.pagination, status, search, sort, filter, isSync)
  }

  onLoadMore = () => {
    this.pagination.pageNo += 1
    this.getAllShipments()
  }

  onFilterChange = filters => {
    this.filters = filters
    this.pagination.pageNo = 1
    this.getAllShipments()
  }

  componentDidUpdate(prevProps) {
    if (this.props.shipments !== prevProps.shipments) {
      let minified = {}
      this.props.shipments.forEach(shipment => {
        minified[shipment._id] = this.state.minified.hasOwnProperty(shipment._id) ? this.state.minified[shipment._id] : true
      })
      this.setState({ minified })
    }
    if (this.props.autoRefreshStatus !== prevProps.autoRefreshStatus) {
      this.setToggleAutoRefresh(this.props.autoRefreshStatus)
    }
  }

  setAllMinifiedState = val => {
    let minified = {}
    this.props.shipments.forEach(shipment => {
      minified[shipment._id] = val
    })
    this.setState({ minified })
  }

  expandAll = () => {
    this.setAllMinifiedState(false)
  }

  collapseAll = () => {
    this.setAllMinifiedState(true)
  }

  closeDocUploadSidebar = (...params) => {
    this.setState({
      docUploadSidebar: {
        open: false,
        shipment: {}
      }
    })
  }

  onUploadFiles = (...params) => {
    const { upload } = this.props
    upload(...params)
  }

  handleSearchChange = event => {
    const { value } = event.target
    const { search } = this.state

    this.setState({ search: value },
      () => {
        if (search !== value) {
          stopSearching()
          startSearching(1000, () => {
            this.pagination.pageNo = 1
            this.getAllShipments()
          })
        }
      }
    )
  }

  handleStatusChange = status => {
    this.setState({ status }, this.getAllShipments)
  }

  handleSortChange = sort => {
    this.setState({ sort }, this.getAllShipments)
  }

  handleReviewCustomSubmit = (shipment, note, action) => {
    const { reviewCustomClearanceOpen } = this.state
    const { reviewCustomDraft } = this.props
    this.setState({
      reviewCustomClearanceOpen: {
        ...reviewCustomClearanceOpen,
        requesting: action
      }
    })
    reviewCustomDraft(shipment._id, note, action,
      () => {
        this.setState({ reviewCustomClearanceOpen: false })
        this.getAllShipments()
      },
      () => {
        this.setState({
          reviewCustomClearanceOpen: {
            ...reviewCustomClearanceOpen,
            requesting: false
          }
        })
      }
    )
  }

  handleUpdateStatusSubmit = (shipment, params) => {
    const { updateShipmentStatusOpen } = this.state
    const { updateShipmentStatus } = this.props
    this.setState({
      updateShipmentStatusOpen: {
        ...updateShipmentStatusOpen,
        requesting: true
      }
    })
    updateShipmentStatus(shipment._id, params,
      () => {
        this.setState({ updateShipmentStatusOpen: false })
        this.getAllShipments()
      },
      () => {
        this.setState({
          updateShipmentStatusOpen: {
            ...updateShipmentStatusOpen,
            requesting: false
          }
        })
      }
    )
  }

  /**
  * Function to set AutoRefreshStatus
  * @param status boolean
  */
  setToggleAutoRefresh = status => {
    this.props.setAutoRefreshStatus(status);

    if (status) {
      this.registerTimer();
      this.resetTimer();
    } else {
      if (this.timer) {
        clearTimeout(this.timer)
        document.removeEventListener('mousemove', this.resetTimer)
        document.removeEventListener('click', this.resetTimer)
        document.removeEventListener('keypress', this.resetTimer)
      }
    }
  }

  render() {
    const { loading, shipments, currentPlant, showMore, initialLoading, classes, getShipmentFilterOptions, shipmentsTotal, uploadDocument, showMessage, autoRefreshStatus } = this.props
    const { partnerSidebar, outboundsSidebar, minified, vesselTrackDialog, reviewCustomClearanceOpen, updateShipmentStatusOpen, updateNdaOpen } = this.state
    const atleastOneMinified = shipments.reduce((total, currentValue) => total || minified[currentValue._id], false)
    const allMinified = shipments.reduce((total, currentValue) => total && minified[currentValue._id], true)

    return (
      <>
        <SubToolBarLayout text='Manage Incomings'>
          <IOSSwitchComponent label={`AutoRefresh`} checked={autoRefreshStatus} handleToggle={this.setToggleAutoRefresh} />
          <ButtonGroup size='small' color='primary' aria-label='actions expand' className={classes.buttonGroup}>
            <FuseIconButton
              iconComponent={<CalendarViewDayIcon fontSize='large' className='mr-8' />}
              solid={true}
              className={classNames({ [classes.button]: true, [classes.buttonActive]: true })}
              variant='outline'
              onClick={this.expandAll}
              disabled={!atleastOneMinified}
            >
              Expand All
            </FuseIconButton>
            <FuseIconButton
              iconComponent={<ReorderIcon fontSize='large' className='mr-8' />}
              solid={true}
              className={classNames({ [classes.button]: true, [classes.buttonActive]: true })}
              variant='outline'
              onClick={this.collapseAll}
              disabled={allMinified}
            >
              Collapse All
            </FuseIconButton>
          </ButtonGroup>
        </SubToolBarLayout>
        <div className='p-20 h-full overflow-auto'>
          {initialLoading && <ShipmentListFilterSkeleton showSearch showFilter />}

          {!initialLoading && (
            <FilterRow shipmentsTotal={shipmentsTotal}
              search={this.state.search}
              handleSearchChange={this.handleSearchChange}
              sort={this.state.sort}
              handleSortChange={this.handleSortChange}
              handleStatusChange={this.handleStatusChange}
              shipments={shipments}
              getShipmentFilterOptions={getShipmentFilterOptions}
              applyCustomFilter={this.applyCustomFilter}
              customFilter={this.state.customFilter}
              allowCustomFilter
            />
          )}

          {loading && <ShipmentListSkeleton count={3} />}

          {!loading && shipments.length === 0 && (
            <FusePlaceHolder
              className='flex flex-col max-h-256'
              icon='directions_boat'
              title='No bookings'
              description={'Looks like there are no incoming shipments at the moment. Please check back later.'}
            ></FusePlaceHolder>
          )}
          {!loading && shipments.length > 0 && (
            <>
              <div className={classNames('mt-20 shipment-list')}>
                {shipments.map((shipment, index) => (
                  <ShipmentCard section='manageIncoming'
                    key={index}
                    minified={this.state.minified[shipment._id]}
                    setMinified={value => {
                      this.setState({ minified: { ...minified, [shipment._id]: value } })
                    }}
                    shipment={shipment}
                    role={currentPlant.type}
                    onUpdateShipmentStatus={(shipment) => this.setState({
                      updateShipmentStatusOpen: {
                        open: true,
                        shipment
                      }
                    })}
                    onReviewCustomDraft={(shipment) => this.setState({
                      reviewCustomClearanceOpen: {
                        open: true,
                        shipment
                      }
                    })}
                    onPartnerSidebarClick={this.onPartnerClick}
                    onOutboundsSidebarClick={this.onOutboundsClick}
                    openDocUploader={this.openDocUploadSidebar}
                    statusType={'custom-clearance'}
                    vesselTrackDialog={(vessel) => this.setState({ vesselTrackDialog: { vessel } })}
                  />
                ))}
                {showMore && <LoadMore onClick={this.onLoadMore} />}
              </div>
            </>
          )}

          <PartnersSidebar open={partnerSidebar.open}
            closeSideBar={this.onPartnerSidebarClose}
            sideBarTitle={'Partners'}
            actors={partnerSidebar.actors} />

          <CSOutboundsSidebar open={outboundsSidebar.open}
            closeSideBar={this.onOutboundsSidebarClose}
            sideBarTitle={'CS & Outbounds'}
            outboundsList={outboundsSidebar.outbounds}
            consolidations={outboundsSidebar.consolidations}
            currentPlant={currentPlant} />

          <ReviewCustomClearance open={get(reviewCustomClearanceOpen, 'open', false)}
            shipment={get(reviewCustomClearanceOpen, 'shipment', {})}
            requesting={get(reviewCustomClearanceOpen, 'requesting', false)}
            handleClose={() => this.setState({ reviewCustomClearanceOpen: false })}
            handleSubmit={this.handleReviewCustomSubmit}
            showMessage={showMessage} />

          <UpdateShipmentStatus open={get(updateShipmentStatusOpen, 'open', false)}
            shipment={get(updateShipmentStatusOpen, 'shipment', {})}
            requesting={get(updateShipmentStatusOpen, 'requesting', false)}
            handleClose={() => this.setState({ updateShipmentStatusOpen: false })}
            handleSubmit={this.handleUpdateStatusSubmit}
            uploadDocument={uploadDocument} />

          <UploadNDA open={updateNdaOpen}
            shipment={{}}
            handleClose={() => this.setState({ updateNdaOpen: false })} />

          {/* Vessel Track Dialog */}
          <VesselTrackDialog open={get(vesselTrackDialog, 'vessel') !== undefined}
            handleClose={() => this.setState({ vesselTrackDialog: false })}
            vessel={get(vesselTrackDialog, 'vessel', {})} />
          {/* End of Vessel Track Dialog */}
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  incoming: { initialLoading, loading, shipments, shipmentsTotal, showMore, outboundsByShipment },
  welcome: { currentPlant },
  core: { autoRefreshStatus },
  auth: { info }
}) => ({
  initialLoading,
  autoRefreshStatus,
  loading,
  shipments,
  shipmentsTotal,
  currentPlant,
  showMore,
  outboundsByShipment,
  userInfo: info,
})

const mapDispatchToProps = dispatch => ({
  getAllShipments: (...args) => dispatch(ShipmentActions.getAllShipments(...args)),
  getShipmentMeta: (...args) => dispatch(ShipmentActions.getShipmentMeta(...args)),
  getOutboundsByShipment: shipmentId => dispatch(ShipmentActions.getOutboundsByShipment(shipmentId)),
  getShipmentFilterOptions: () => dispatch(CoreActions.getShipmentFilterOptions()),
  uploadDocument: (...args) => dispatch(CoreActions.uploadFile(...args)),
  showMessage: (...args) => dispatch(CoreActions.showMessage(...args)),
  consigneeMarkAsReceived: (...args) => dispatch(ShipmentActions.consigneeMarkAsReceived(...args)),
  updateShipmentStatus: (...args) => dispatch(ShipmentActions.updateShipmentStatus(...args)),
  reviewCustomDraft: (...args) => dispatch(ShipmentActions.reviewCustomDraft(...args)),
  upload: (...args) => dispatch(CoreActions.uploadFile(...args)),
  setAutoRefreshStatus: (status) => dispatch(CoreActions.setAutoRefreshStatus(status)),
})

const styles = {
  button: { textTransform: 'none', fontWeight: 600, fontSize: 12 },
  buttonActive: {},
  buttonInActive: {}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ManageIncoming))
