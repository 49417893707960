export function hasNoEmptyParams(obj, ignores = []) {
  for (let key in obj) {
    if (ignores.includes(key)) {
      continue
    }

    if (obj[key].length === 0) {
      return false
    }
  }
  return true
}
