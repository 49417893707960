import { get, capitalize, pick, filter } from 'lodash'
import ActionTypes from './types'
import UserSettingTypes from './userSettingTypes'
import { fileUploadInfo } from '../localization'
import { camelCaseSlugify } from '../helpers'
import * as API from '../api'
import { concat, orderBy } from 'lodash'
import { addGaEvent } from '../helpers'

export const apiAttempt = () => ({ type: ActionTypes.API_ATTEMPT })
export const apiSuccess = () => ({ type: ActionTypes.API_SUCCESS })
export const apiFailed = payload => ({ type: ActionTypes.API_FAILED, payload })
export const setUserNotifications = payload => ({ type: ActionTypes.SET_USER_NOTIFICATIONS, payload })
export const setUnreadNotificationCount = payload => ({ type: ActionTypes.SET_UNREAD_USER_NOTIFICATIONS, payload })

export const setNetworkDownStatus = (down = false) => ({ type: ActionTypes.SET_NETWORK_DOWN, networkDown: down })
export const setShipmentCounts = shipmentCounts => ({ type: ActionTypes.SET_SHIPMENT_COUNTS, shipmentCounts })
export const hideMessage = () => ({ type: ActionTypes.HIDE_MESSAGE })
export const showMessage = options => {
  const statusCode = get(options, 'status', 0)
  const responseMessage = get(options, 'data.message', get(options, 'message'))
  const otherOptions = pick(options, ['variant', 'noCloseButton', 'rounded'])
  const _options = {
    variant: 'default',
    noCloseButton: false,
    rounded: false,
    message: 'Something went wrong with your request. Try again in a moment',
    ...otherOptions
  }

  if (!options.variant) {
    if (statusCode >= 200 && statusCode < 300) {
      _options.variant = 'success'
    } else if (statusCode >= 300 && statusCode < 500) {
      _options.variant = 'warning'
    } else {
      _options.variant = 'error'
    }
  }

  _options.message = responseMessage || _options.message

  return {
    type: ActionTypes.SHOW_MESSAGE,
    options: _options
  }
}

export const getUserSettingSuccess = (type, payload) => ({
  type,
  payload
})

/* --- Upload --- */
export const uploadActions = {}

Object.keys(fileUploadInfo).forEach(type => {
  uploadActions[`${type}Success`] = payload => ({ type: `UPLOAD_${type.toUpperCase()}_FILE`, payload })
})

export const resetUploadLog = () => ({ type: `RESET_UPLOAD_LOG` })

export const uploadFile = (type, { file, params }, success = null, fail = null, notify = false) => async dispatch => {
  const apiKey = `upload${capitalize(type)}File`
  var actionKey = `${type}Success`

  if (type === 'csv' || type === 'xlsx') {
    actionKey = `outboundsSuccess`
  }

  try {
    dispatch(apiAttempt())

    const { ok, status, data } = await API[apiKey](file, ...(params || []))
    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(apiSuccess())
    dispatch(showMessage({ status, data }))

    const batchId = get(data, 'data.batchJobId')

    if (batchId) {
      dispatch(
        uploadActions[actionKey]({
          [batchId]: {
            status: 'READY',
            data: [],
            totalCount: 0
          }
        })
      )
    }

    if (success) {
      success()
    }
  } catch (error) {
    dispatch(apiFailed(error.message))

    if (notify) {
      dispatch(showMessage(error))
    }

    if (fail) {
      fail()
    }
  }
}

export const getBatchStatus = (batchId, type, success = null, fail = null) => async dispatch => {
  const actionKey = `${type}Success`

  try {
    dispatch(apiAttempt())

    let { ok, data } = await API.getUploadStatus(batchId)
    if (!ok) {
      throw new Error(data.message)
    }

    const uploadStatus = get(data, 'data')
    data = get(uploadStatus, 'data', []).map(v => ({ ...v, type }))

    dispatch(apiSuccess())
    dispatch(
      uploadActions[actionKey]({
        [batchId]: {
          data,
          status: get(uploadStatus, 'status', 'COMPLETED'),
          totalCount: get(uploadStatus, 'totalCount', 0)
        }
      })
    )

    if (success) {
      success()
    }
  } catch (error) {
    dispatch(apiFailed(error.message))

    if (fail) {
      fail()
    }
  }
}
/* --------- */
export const setCustomFilters = payload => ({
  type: ActionTypes.SET_CUSTOM_FILTERS,
  payload
})
export const setDocuments = payload => ({
  type: ActionTypes.SET_DOCUMENTS,
  payload
})
/* --- Filters --- */
export const filterActions = {
  originLoad: payload => ({ type: ActionTypes.LOAD_ORIGIN_COUNTRIES, payload }),
  destinationLoad: payload => ({ type: ActionTypes.LOAD_DESTINATION_COUNTRIES, payload }),
  originPortLoad: payload => ({ type: ActionTypes.LOAD_ORIGIN_PORTS, payload }),
  destinationPortLoad: payload => ({ type: ActionTypes.LOAD_DESTINATION_PORTS, payload }),
  originPlantLoad: payload => ({ type: ActionTypes.LOAD_ORIGIN_PLANTS, payload }),
  destinationPlantLoad: payload => ({ type: ActionTypes.LOAD_DESTINATION_PLANTS, payload }),
  transportationLoad: payload => ({ type: ActionTypes.LOAD_TRANSPORTATION, payload }),
  ofpLoad: payload => ({ type: ActionTypes.LOAD_OFP, payload }),
  oblLoad: payload => ({ type: ActionTypes.LOAD_OBL, payload }),
  obcLoad: payload => ({ type: ActionTypes.LOAD_OBC, payload })
}

export const getShipmentFilterOptions = () => async dispatch => {
  try {
    let { ok, data } = await API.getAllShipmentsFilters()
    const filters = get(data, 'data', {})

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(apiSuccess())
    Object.keys(filters).forEach(key => {
      dispatch(
        filterActions[`${key}Load`](
          filters[key].map(item => {
            return {
              name: item
            }
          })
        )
      )
    })
  } catch (error) {
    console.log(error)
    dispatch(apiFailed(error.message))
  }
}

export const loadCustomFilters = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getUserSettings({
      configType: 'user',
      key: 'scheduleFilter'
    })

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setCustomFilters(data.data))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}
export const loadNotifications = (pageSize = 10, notificationType = 'all', pageNo = 0) => async dispatch => {
  try {
    const { ok, data } = await API.getUserNotifications({ pageSize, notificationType, pageNo })

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setUserNotifications(data.data))
  } catch (e) {
    dispatch(showMessage(e))
  }
}
export const getUnreadNotificationsCount = () => async dispatch => {
  try {
    const { ok, data } = await API.getUserNotifications({ pageSize: 1, notificationType: 'unread', pageNo: 0 })

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setUnreadNotificationCount(data.total))
  } catch (e) {
    dispatch(showMessage(e))
  }
}
export const markNotificationsAsRead = params => async dispatch => {
  try {
    const { ok, data } = await API.markNotificationsAsRead(params)

    if (!ok) {
      throw new Error(data.message)
    }
    dispatch({
      type: ActionTypes.MARK_USER_NOTIFICATIONS_READ,
      payload: get(params, 'ids', [])
    })
  } catch (e) {
    dispatch(showMessage(e))
  }
}
export const loadDocuments = () => async dispatch => {
  try {
    const { ok, data } = await API.loadDocuments()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setDocuments(data.data))
  } catch (e) {
    dispatch(showMessage(e))
  }
}
export const deleteCustomFilter = id => async dispatch => {
  try {
    const { ok, data, status } = await API.deleteCustomFilter('scheduleFilter', id)

    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(
      showMessage({
        status,
        data
      })
    )
    dispatch(loadCustomFilters())
    return data
  } catch (e) {
    dispatch(showMessage(e))
  }
}
export const setCustomFilter = (payload, showStatus, success = null, fail = null) => async dispatch => {
  var value = get(payload, 'value', {})
  Object.keys(value).forEach(valueKey => {
    if (valueKey === 'partners') {
      var partners = get(value, valueKey, {})
      Object.keys(partners).forEach(partnerKey => {
        if (partners[partnerKey].length === 0) {
          delete partners[partnerKey]
        }
      })
      if (Object.keys(partners).length === 0) {
        delete value[valueKey]
      }
    } else {
      if (value[valueKey].length === 0) {
        delete value[valueKey]
      }
    }
  })

  payload.value = value
  try {
    let response
    if (payload._id) {
      response = await API.updateUserSettings(payload._id, payload)
    } else {
      response = await API.setUserSettings(payload)
    }
    const { ok, data, status } = response

    if (!ok) {
      throw new Error(data.message)
    }
    if (showStatus) {
      dispatch(
        showMessage({
          status,
          data
        })
      )
    }

    dispatch(loadCustomFilters(success, fail))
    return data
  } catch (e) {
    dispatch(showMessage(e))
  }
}

/* --------- */
export const getVersionSuccess = payload => ({ type: ActionTypes.GET_VERSION_SUCCESS, payload })
export const getVersion = () => async dispatch => {
  try {
    let { ok, data } = await API.getVersion()
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(getVersionSuccess(data.data.version))
  } catch (error) {
    dispatch(apiFailed(error.message))
  }
}

export const setBadPermInfo = payload => ({ type: ActionTypes.SET_BAD_PERM_INFO, payload })
export const setTokenRedirect = redirectBackToUrl => ({
  type: ActionTypes.HIGH_SECURITY_TOKEN_REDIRECT,
  redirectBackToUrl
})

/* --- User Settings --- */
export const getUserSettings = (key = null, success = null, fail = null) => async dispatch => {
  var params = {
    configType: 'user'
  }

  if (key) {
    params.key = key
  }

  try {
    const { ok, data } = await API.getUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    const userSettings = get(data, 'data', [])

    if (key) {
      dispatch(getUserSettingSuccess(`USER_SETTINGS_${camelCaseSlugify(key).toUpperCase()}`, filter(userSettings, { key })))
    } else {
      Object.keys(UserSettingTypes).forEach(type => {
        dispatch(
          getUserSettingSuccess(`USER_SETTINGS_${camelCaseSlugify(type).toUpperCase()}`, filter(userSettings, { key: type }))
        )
      })
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getEventsListUserSettings = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getEventsListUserSettings()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getUserSettingSuccess(ActionTypes.LOAD_USER_SETTINGS_EVENTS_LIST, get(data, 'data', [])))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const setUpdateUserSettings = (key, params, success = null, fail = null) => async dispatch => {
  params.configType = 'user'

  try {
    const { ok, status, data } = await API.setUpdateUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getUserSettingSuccess(`USER_SETTINGS_${camelCaseSlugify(key).toUpperCase()}`, [get(data, 'data', {})]))
    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const updateUserSettings = (id, params, success = null, fail = null) => async dispatch => {
  params.configType = 'user'

  try {
    const { ok, status, data } = await API.updateUserSettings(id, params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getUserSettings('eventsNotification'))
    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const deleteUserSettings = (id, key, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.deleteUserSettings(id, key)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getUserSettings('eventsNotification'))
    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getAllShipmentsCounts = () => async dispatch => {
  try {
    const { ok, data } = await API.getShipmentCounts()
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(setShipmentCounts(get(data, 'data', {})))
  } catch (e) {
    dispatch(showMessage(e))
  }
}

export const setBulkUserSettings = (key, params, success = null, fail = null) => async dispatch => {
  params = params.map(param => {
    param.configType = 'user'
    return param
  })

  try {
    const { ok, status, data } = await API.setBulkUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getUserSettings('eventsNotification'))
    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}
/* --------- */

/* --- Plants --- */
export const setPlants = payload => ({ type: ActionTypes.LOAD_PLANTS, payload })
export const getPlants = (success = null, fail = null) => async dispatch => {
  var params = {
    populatePaths: ['office']
  }

  try {
    const { ok, data } = await API.getPlants(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setPlants(get(data, 'data', [])))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}
/* --------- */

/* --- System Settings --- */
export const setSystemSettings = payload => ({ type: ActionTypes.LOAD_SYSTEM_SETTINGS, payload })

export const getSettingByType = (type, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getSettingByType(type)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setSystemSettings(get(data, 'data', {})))

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const getSettingById = (key, id = undefined, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getSettingById(key, id)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setSystemSettings({
      key,
      value: get(data, 'data', {})
    }))

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const getSettingAppId = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getSettingAppId()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setSystemSettings({
      key: 'appId',
      value: get(data, 'data', '')
    }))

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const getSettingApiKey = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getSettingApiKey()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(setSystemSettings({
      key: 'apiKey',
      value: get(data, 'data', '')
    }))

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}
/* --------- */

/* --- Users by plantIds --- */
export const getUsersByPlantIds = (plantIds, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.addUserByPlantIds({ plantIds })

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_USERS_BY_PLANT_IDS,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}
/* --------- */

/* --- Users by plantIds --- */
export const getAuthProvidersByAppId = (appId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getAuthProvidersByAppId(appId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_AUTH_PROVIDERS,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}
/* --------- */

/* --- System search shipments --- */
export const getSystemSearchShipments = (search) => async dispatch => {
  addGaEvent('shipment-headerSearchShipments', undefined,search)
  dispatch({ type: ActionTypes.LOADING_SYSTEM_SEARCH_SHIPMENTS })

  if (search) {
    let multiSearch = search
    if ((multiSearch.search(/ \(/i) > -1) && (multiSearch.search(/\)/i) > -1)) {
      multiSearch = multiSearch.replace(/ \(/i, '__')
      multiSearch = multiSearch.replace(/\)/i, '')
    }
    const normals = await API.getAllShipmentsWithFilter({ 'multi-search': multiSearch })
    const exceptions = await API.getAllShipmentsWithFilter({
      'multi-search': multiSearch,
      'exception-mode': 'enabled'
    })
    const total = get(normals, 'data.total', 0) + get(exceptions, 'data.total', 0)
    const payload = orderBy(
      concat(
        get(normals, 'data.data', []),
        get(exceptions, 'data.data', [])
      ),
      'updatedAt',
      'desc'
    )

    dispatch({
      type: ActionTypes.LOAD_SYSTEM_SEARCH_SHIPMENTS,
      search,
      total,
      payload
    })
  }
}
/* --------- */

export const getVesselInfo = (imo, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getVesselInformation(imo);

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success(get(data, 'data'))
    }

    return get(data, 'data')
  } catch (e) {
    dispatch(apiFailed(e.message))

    if (fail) {
      fail()
    }
  }
}

export const copyToClipboard = (copyText, success = null, fail = null) => async dispatch => {
  try {
    const el = document.createElement('textarea');
    el.setAttribute('readonly', '');
    el.style.position = 'fixed';
    el.style.left = '-9999px';
    el.value = copyText;
    document.body.appendChild(el);

    /* Select the text field */
    el.select();
    el.setSelectionRange(0, 99999); /*For mobile devices*/

    document.execCommand('copy');
    document.body.removeChild(el);
    dispatch(showMessage({
      variant: 'success',
      message: 'Copied to clipboard'
    }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

/**
 * Set AutoRefreshStatus
 */
export const setAutoRefreshStatus = (status) => async dispatch => {
  dispatch({
    type: ActionTypes.SET_AUTO_REFRESH,
    status
  })
}
