import React, { useState, useEffect } from 'react'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody,
  LoadingButton
} from '../../core/components'
import {
  withStyles,
  Grid,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'

const ShipmentCardViewDialog = props => {
  const {
    open,
    classes,
    scheduleDefaultMode,
    getUserSettingsByKey,
    onSubmit,
    handleClose
  } = props
  const [shipmentCardView, setShipmentCardView] = useState('')
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    setUpdating(false)
    if (scheduleDefaultMode) {
      setShipmentCardView(scheduleDefaultMode)
    } else {
      setShipmentCardView('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <FuseDialog open={open} wSM>
      <FuseDialogHeader text="Shipment Card View"
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container alignItems="center" className="p-20">
          <Grid item xs={12}>
            <RadioGroup name="scheduleDefaultMode"
              value={shipmentCardView}
              onChange={(e) => {
                setShipmentCardView(e.target.value)
              }}>
              <Grid container
                alignItems="center"
                justify="center"
                spacing={3}>
                <Grid item>
                  <FormControlLabel value="expended"
                    className={classes.radioLabel}
                    control={<Radio />} label="Expended" />
                </Grid>
                <Grid item>
                  <FormControlLabel value="collapsed"
                    className={classes.radioLabel}
                    control={<Radio />} label="Collapsed" />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <Grid container
              spacing={2}
              className="pt-32">
              <Grid item xs={6}>
                <LoadingButton className="btn btn_sm btn_green"
                  loading={updating}
                  onClick={() => {
                    setUpdating(true)
                    onSubmit(
                      'scheduleDefaultMode',
                      {
                        key: 'scheduleDefaultMode',
                        value: shipmentCardView
                      },
                      () => {
                        setUpdating(false)
                        getUserSettingsByKey('scheduleDefaultMode')
                        handleClose()
                      },
                      () => {
                        setUpdating(false)
                      }
                    )
                  }}
                  fullWidth>Confirm</LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <Button className="btn btn_sm btn_light_red"
                  onClick={handleClose}
                  disabled={updating}
                  fullWidth>Close</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

const styles = theme => ({
  radioLabel: {
    color: '#333333',
    fontFamily: 'IBMPlexSans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    textAlign: 'left',
  },
  button: {
    color: '#fff',
    backgroundColor: '#cc1f1a'
  },
  icon: { fontSize: 75 }
})
export default withStyles(styles)(ShipmentCardViewDialog)
