import React, { useState, useEffect } from 'react'
import { FusePlaceHolder, LoadMore } from 'core/components'
import {
  Grid,
  withStyles,
  Icon,
  Tabs,
  Tab,
  Typography,
  Box
} from '@material-ui/core'
import classNames from 'classnames'
import OutBoundTableGroup from './OutBoundTableGroup'
import OutboundListSkeleton from 'core/components/Skeletons/OutboundListSkeleton'
import DateTime from 'react-datetime'
import { indexOf, uniq } from 'lodash'
import { startSearching, stopSearching } from '../../../core/helpers'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import FlightIcon from '@material-ui/icons/Flight';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TrainIcon from '@material-ui/icons/Train';
import InfoIcon from '@material-ui/icons/Info';

const tabs = ['ocean', 'air', 'CBT', 'rail', 'NA']

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component='div'
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={'w-full overflow-hidden'}
    {...other}>
    <Box>{children}</Box>
  </Typography>
)

const PlaceHolderSection = ({
  classes,
  description,
  isLoading,
  outboundLength,
  search,
  shipToParty,
  date,
  csCodes,
  showUploadDialog }) => {

  if (isLoading) {
    return <OutboundListSkeleton />
  } else if (!isLoading && outboundLength === 0) {
    if (search.trim() === '' && shipToParty.trim() === '' && csCodes.trim() === '' && date === null) {
      return <FusePlaceHolder className={classNames('flex flex-col', classes.nodata)}
        icon='insert_drive_file'
        title='Upload Outbounds'
        description={description}
        button={{
          text: 'IMPORT',
          onClick: showUploadDialog
        }} />
    } else if (search.trim() !== '' || shipToParty.trim() !== '' || csCodes.trim() !== '' || date !== null) {
      return <FusePlaceHolder
        className={classNames('flex flex-col', classes.nodata)}
        icon='insert_drive_file'
        title='No outbounds!'
        description={'Sorry! No outbounds found for the selected search query.'}
      />
    } else {
      return <React.Fragment />
    }
  } else {
    return <React.Fragment />
  }
}

const OutBoundTable = ({
  outbounds,
  airOutbounds,
  cbtOutbounds,
  railOutbounds,
  otherOutbounds,
  outboundsTotal,
  airOutboundsTotal,
  cbtOutboundsTotal,
  railOutboundsTotal,
  otherOutboundsTotal,
  onChange,
  showLoadMore,
  showAirLoadMore,
  showCbtLoadMore,
  showRailLoadMore,
  showOtherLoadMore,
  showUploadDialog,
  outboundsLoading,
  outboundsLoadingMore,
  airOutboundsLoading,
  airOutboundsLoadingMore,
  cbtOutboundsLoading,
  cbtOutboundsLoadingMore,
  railOutboundsLoading,
  railOutboundsLoadingMore,
  otherOutboundsLoading,
  otherOutboundsLoadingMore,
  onOutboundsClick,
  onLoadMore,
  onAirLoadMore,
  onCbtLoadMore,
  onRailLoadMore,
  onOtherLoadMore,
  submitSearch,
  classes,
  date,
  setDate,
  search,
  shipToParty,
  setShipToParty,
  csCodes,
  setCsCodes,
  setCurrentOutboundTab
}) => {
  const [tabValue, setTabValue] = useState(0)
  const [lastLoadingMore, setLastLoadingMore] = useState('')

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const changeDate = date => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    setDate(date)
    submitSearch(true, search, date, csCodes, shipToParty)
  }

  const changeCsCodes = value => {
    if (value === '') {
      setCsCodes(value)
      submitSearch(true, search, date, value, shipToParty)
    } else {
      value = value.replace(/ /g, ',')
      value = value.replace(/,+/g, ',')
      if (value[0] === ',') {
        value = value.substring(1)
      }
      value = uniq(value.split(',')).join(',')

      if (value !== csCodes) {
        setCsCodes(value)
        stopSearching()
        startSearching(500, () => {
          submitSearch(true, search, date, value, shipToParty)
        })
      }
    }
  }

  const changeShipToParty = value => {
    if (value === '') {
      setShipToParty(value)
      submitSearch(true, search, date, csCodes, value)
    } else {
      value = value.replace(/ /g, ',')
      value = value.replace(/,+/g, ',')
      if (value[0] === ',') {
        value = value.substring(1)
      }
      value = uniq(value.split(',')).join(',')

      if (value !== shipToParty) {
        setShipToParty(value)
        stopSearching()
        startSearching(500, () => {
          submitSearch(true, search, date, csCodes, value)
        })
      }
    }
  }

  const handleChange = (event, value) => {
    if (!lastLoadingMore) {
      console.log(tabs[value])
      setTabValue(value)
      setCurrentOutboundTab(tabs[value])
    }
  }

  useEffect(() => {
    if (!outboundsLoading && !airOutboundsLoading && !cbtOutboundsLoading && !railOutboundsLoading && !otherOutboundsLoading) {
      if (tabValue === indexOf(tabs, lastLoadingMore)) {
        setLastLoadingMore('')
      } else {
        if (outbounds.length > 0) handleChange(null, 0)
        else if (airOutbounds.length > 0) handleChange(null, 1)
        else if (cbtOutbounds.length > 0) handleChange(null, 2)
        else if (railOutbounds.length > 0) handleChange(null, 3)
        else if (otherOutbounds.length > 0) handleChange(null, 4)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outbounds, airOutbounds, cbtOutbounds, railOutbounds, otherOutbounds])

  const description =
    'Start importing all the CS or Outbounds using the file. Once all the CS and Outbounds are uploaded, you can start consolidating & proposing bookings'
  return (
    <div className={classes.outboundContainer}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <h3 className="uppercase bold">
            Open Outbounds</h3>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Grid container alignItems="center"
            className={classNames(
              'bg-white px-4 py-8',
              classes.bordered)}>
            <Grid item xs={2}>
              <Grid container
                alignItems="center"
                justify="center">
                <Icon className="text-16 fg-gray">
                  calendar_today</Icon>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <DateTime className={classNames(classes.datetimePicker)}
                    input
                    dateFormat='DD MMM YYYY'
                    timeFormat={false}
                    closeOnSelect={true}
                    inputProps={{
                      placeholder: 'Planned GI Date',
                      className: 'max-w-full text-12'
                    }}
                    onChange={changeDate}
                    value={date && new Date(date)}
                  />
                </Grid>
                <Grid item xs={2}>
                  {date && (
                    <Grid container
                      alignItems="center"
                      justify="center">
                      <Icon onClick={() => changeDate(null)}
                        className="text-12 cursor-pointer">close</Icon>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Grid container
            alignItems="center"
            className={classNames(
              'bg-white px-4 py-8',
              classes.bordered)}>
            <Grid item xs={2}>
              <Grid container
                alignItems="center"
                justify="center">
                <img src='assets/images/icons/Search-Organizations.png'
                  alt='search' />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <input type='text'
                    className='input w-full text-12'
                    placeholder='Search by CS Codes…'
                    value={csCodes}
                    onChange={e => changeCsCodes(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  {csCodes && (
                    <Grid container
                      alignItems="center"
                      justify="center">
                      <Icon onClick={() => changeCsCodes('')}
                        className="text-12 cursor-pointer">close</Icon>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <Grid container
            alignItems="center"
            className={classNames(
              'bg-white px-4 py-8',
              classes.bordered)}>
            <Grid item xs={2}>
              <Grid container
                alignItems="center"
                justify="center">
                <img src='assets/images/icons/Search-Organizations.png'
                  alt='search' />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <input type='text'
                    className='input w-full text-12'
                    placeholder='Search by ship to party…'
                    value={shipToParty}
                    onChange={e => changeShipToParty(e.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  {shipToParty && (
                    <Grid container
                      alignItems="center"
                      justify="center">
                      <Icon onClick={() => changeShipToParty('')}
                        className="text-12 cursor-pointer">close</Icon>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Grid container>
        <Tabs value={tabValue}
          onChange={handleChange}
          className="custom-tabs w-full"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Transport Method">
          {/* Ocean */}
          <Tab {...a11yProps(0)}
            label={<Grid container alignItems="center" spacing={1}>
              <Grid item className="leading-none"><DirectionsBoatIcon /></Grid>
              <Grid item className="leading-none">Ocean&nbsp;({outboundsTotal})</Grid>
            </Grid>}
            className={classNames({
              'custom-tabs-item': true,
              'selected': tabValue === 0,
              'hidden': outbounds.length === 0
            })} />

          {/* Air */}
          <Tab {...a11yProps(1)}
            label={<Grid container alignItems="center" spacing={1}>
              <Grid item className="leading-none"><FlightIcon /></Grid>
              <Grid item className="leading-none">Air&nbsp;({airOutboundsTotal})</Grid>
            </Grid>}
            className={classNames({
              'custom-tabs-item': true,
              'selected': tabValue === 1,
              'hidden': airOutbounds.length === 0
            })} />

          {/* CBT */}
          <Tab {...a11yProps(2)}
            label={<Grid container alignItems="center" spacing={1}>
              <Grid item className="leading-none"><LocalShippingIcon /></Grid>
              <Grid item className="leading-none">CBT&nbsp;({cbtOutboundsTotal})</Grid>
            </Grid>}
            className={classNames({
              'custom-tabs-item': true,
              'selected': tabValue === 2,
              'hidden': cbtOutbounds.length === 0
            })} />

          {/* Rail */}
          <Tab {...a11yProps(3)}
            label={<Grid container alignItems="center" spacing={1}>
              <Grid item className="leading-none"><TrainIcon /></Grid>
              <Grid item className="leading-none">Rail&nbsp;({railOutboundsTotal})</Grid>
            </Grid>}
            className={classNames({
              'custom-tabs-item': true,
              'selected': tabValue === 3,
              'hidden': railOutbounds.length === 0
            })} />

          {/* Other */}
          <Tab {...a11yProps(4)}
            label={<Grid container alignItems="center" spacing={1}>
              <Grid item className="leading-none"><InfoIcon /></Grid>
              <Grid item className="leading-none">Other&nbsp;({otherOutboundsTotal})</Grid>
            </Grid>}
            className={classNames({
              'custom-tabs-item': true,
              'selected': tabValue === 4,
              'hidden': otherOutbounds.length === 0
            })} />
        </Tabs>
      </Grid>

      {/* OCEAN */}
      <TabPanel value={tabValue} index={0}>
        <Grid container
          className={classes.outboundScrollBlock}>

          {(!outboundsLoading && outbounds.length > 0) &&
            <Grid item xs={12}>
              {outbounds.map((data, index) => (
                <OutBoundTableGroup key={index}
                  groupKey={`group_${index}`}
                  onOutboundsClick={onOutboundsClick}
                  data={data}
                  onChange={onChange}
                  transportMethod="ocean" />
              ))}
              {showLoadMore &&
                <LoadMore loading={outboundsLoadingMore}
                  onClick={() => {
                    onLoadMore()
                    setLastLoadingMore('ocean')
                  }} />
              }
            </Grid>
          }

          <PlaceHolderSection isLoading={outboundsLoading}
            outboundLength={outbounds.length}
            search={search}
            shipToParty={shipToParty}
            csCodes={csCodes}
            date={date}
            classes={classes}
            description={description}
            showUploadDialog={showUploadDialog} />

        </Grid>
      </TabPanel>

      {/* AIR */}
      <TabPanel value={tabValue} index={1}>
        <Grid container
          className={classes.outboundScrollBlock}>

          {(!airOutboundsLoading && airOutbounds.length > 0) &&
            <Grid item xs={12}>
              {airOutbounds.map((data, index) => (
                <OutBoundTableGroup key={index}
                  groupKey={`group_${index}`}
                  onOutboundsClick={onOutboundsClick}
                  data={data}
                  onChange={onChange}
                  transportMethod="air" />
              ))}
              {showAirLoadMore &&
                <LoadMore loading={airOutboundsLoadingMore}
                  onClick={() => {
                    onAirLoadMore()
                    setLastLoadingMore('air')
                  }} />
              }
            </Grid>
          }

          <PlaceHolderSection isLoading={airOutboundsLoading}
            outboundLength={airOutbounds.length}
            search={search}
            shipToParty={shipToParty}
            csCodes={csCodes}
            date={date}
            classes={classes}
            description={description}
            showUploadDialog={showUploadDialog} />

        </Grid>
      </TabPanel>

      {/* CBT */}
      <TabPanel value={tabValue} index={2}>
        <Grid container
          className={classes.outboundScrollBlock}>

          {(!cbtOutboundsLoading && cbtOutbounds.length > 0) &&
            <Grid item xs={12}>
              {cbtOutbounds.map((data, index) => (
                <OutBoundTableGroup key={index}
                  groupKey={`group_${index}`}
                  onOutboundsClick={onOutboundsClick}
                  data={data}
                  onChange={onChange}
                  transportMethod="CBT" />
              ))}
              {showCbtLoadMore &&
                <LoadMore loading={cbtOutboundsLoadingMore}
                  onClick={() => {
                    onCbtLoadMore()
                    setLastLoadingMore('CBT')
                  }} />
              }
            </Grid>
          }

          <PlaceHolderSection isLoading={cbtOutboundsLoading}
            outboundLength={cbtOutbounds.length}
            search={search}
            shipToParty={shipToParty}
            csCodes={csCodes}
            date={date}
            classes={classes}
            description={description}
            showUploadDialog={showUploadDialog} />

        </Grid>
      </TabPanel>

      {/* RAIL */}
      <TabPanel value={tabValue} index={3}>
        <Grid container
          className={classes.outboundScrollBlock}>

          {(!railOutboundsLoading && railOutbounds.length > 0) &&
            <Grid item xs={12}>
              {railOutbounds.map((data, index) => (
                <OutBoundTableGroup key={index}
                  groupKey={`group_${index}`}
                  onOutboundsClick={onOutboundsClick}
                  data={data}
                  onChange={onChange}
                  transportMethod="rail" />
              ))}
              {showRailLoadMore &&
                <LoadMore loading={railOutboundsLoadingMore}
                  onClick={() => {
                    onRailLoadMore()
                    setLastLoadingMore('rail')
                  }} />
              }
            </Grid>
          }

          <PlaceHolderSection isLoading={railOutboundsLoading}
            outboundLength={railOutbounds.length}
            search={search}
            shipToParty={shipToParty}
            csCodes={csCodes}
            date={date}
            classes={classes}
            description={description}
            showUploadDialog={showUploadDialog} />

        </Grid>
      </TabPanel>

      {/* OTHER */}
      <TabPanel value={tabValue} index={4}>
        <Grid container
          className={classes.outboundScrollBlock}>

          {(!otherOutboundsLoading && otherOutbounds.length > 0) &&
            <Grid item xs={12}>
              {otherOutbounds.map((data, index) => (
                <OutBoundTableGroup key={index}
                  groupKey={`group_${index}`}
                  onOutboundsClick={onOutboundsClick}
                  data={data}
                  onChange={onChange}
                  transportMethod="NA" />
              ))}
              {showOtherLoadMore &&
                <LoadMore loading={otherOutboundsLoadingMore}
                  onClick={() => {
                    onOtherLoadMore()
                    setLastLoadingMore('NA')
                  }} />
              }
            </Grid>
          }

          <PlaceHolderSection isLoading={otherOutboundsLoading}
            outboundLength={otherOutbounds.length}
            search={search}
            shipToParty={shipToParty}
            csCodes={csCodes}
            date={date}
            classes={classes}
            description={description}
            showUploadDialog={showUploadDialog} />

        </Grid>
      </TabPanel>
    </div>
  )
}

const styles = () => ({
  bordered: {
    border: '1px solid #e3e3e4',
    borderRadius: 4
  },
  outboundContainer: {},
  nodata: {
    height: 'calc(100vh - 310px)'
  },
  datetimePicker: {
    height: '100%',
    width: '100%',
    borderBottom: 'none',
    display: 'flex',
    fontSize: '12px',
  },
  outboundScrollBlock: {
    maxHeight: 'calc(100vh - 270px)',
    overflowY: 'auto',
    backgroundColor: '#F5F5F5',
    borderRadius: 4,
    padding: 20
  }
})

export default withStyles(styles)(OutBoundTable)
