import React, { useState, useEffect } from 'react'
import { FuseDialog, FuseDialogBody, FuseDialogHeader, FuseCheckBox } from 'core/components'
import {
  Grid,
  withStyles,
  Typography,
  MenuItem,
  InputBase,
  Select,
  FormControl,
  FormHelperText,
  Button,
  InputLabel,
  Chip,
  Icon
} from '@material-ui/core'
import classNames from 'classnames'
import { AutoComplete } from 'core/components/Inputs'
import DateTime from 'react-datetime'
import moment from 'moment'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { get, uniq } from 'lodash'
import DateRangeBlock from './DateRangeBlock'
import { prepareCustomFilterParams, addGaEvent } from '../../../helpers'

const INITIAL_STATE = {
  configType: 'user',
  settingsName: '',
  key: 'scheduleFilter',
  value: {
    //stage: ['system'],
    origin: [],
    destination: [],
    originPort: [],
    destinationPort: [],
    originPlant: [],
    destinationPlant: [],
    shipmentType: [],
    goodsType: [],
    specialLabels: [],
    incoterms: [],
    csCodes: [],
    addedEvents: [],
    pendingEvents: [],
    uploadedDocuments: [],
    pendingDocuments: [],
    groupage: {
      type: 'none',
      from: null,
      to: null
    },
    ranges: [
      //{event: null, type, :null, range_type: none, from: null,to: null}
    ],
    partners: {
      ofp: [],
      obl: [],
      obc: []
    },
    transportMethod: []
  }
}

const FilterDialog = ({
  open,
  filters,
  saveOrUpdateCustomFilter,
  deleteCustomFilter,
  classes,
  newFilter,
  applyCustomFilter,
  setDialogOpen,
  eventsList,
  documentsList,
  customFilters
}) => {
  const addRangeRow = (filter, setFilter) => {
    setFilter({
      ...filter,
      ranges: [...filter.ranges, { event: eventsList[0].name, type: 'estimated', range_type: 'today', from: null, to: null }]
    })
  }
  const [customFilter, setCustomFilter] = useState(INITIAL_STATE)
  const [error, setError] = useState(false)
  const [csCodes, setCsCodes] = useState('')
  const filter = { ...INITIAL_STATE.value, ...get(customFilter, 'value') }
  const incotermsOptions = [
    'CPT CONSIGNEE',
    'EXW',
    'FCA PORT',
    'FCA FACTORY',
    'CPT PORT',
    'DAP CONSIGNEE',
    'FOB PORT',
    'FCA PLATFORM',
    'DAP PORT',
    'FCA FORWARDER',
    'FCA WAREHOUSE',
    'CIF PORT'].map(value => {
      return {
        value,
        label: value
      }
    })
  const setFilter = filter => {
    setCustomFilter({ ...customFilter, value: filter })
  }

  useEffect(() => {
    if (!newFilter && customFilters.length > 0) {
      setCustomFilter({ ...INITIAL_STATE, ...get(customFilters, '[0]') })
    } else {
      setCustomFilter(INITIAL_STATE)
    }
    setError(false)
  }, [customFilters, newFilter])

  const saveFilter = async (apply = true) => {
    addGaEvent('shipment-createFilter')
    if (customFilter.settingsName === '') {
      setError(true)
      return
    }
    const response = await saveOrUpdateCustomFilter(customFilter, !apply)

    if (apply) {
      applyCustomFilter(response.data)
      setCustomFilter(INITIAL_STATE)
      setDialogOpen(false)
    }
  }

  const deleteFilter = async () => {
    await deleteCustomFilter(customFilter._id)
    setDialogOpen(false)
    setCustomFilter(INITIAL_STATE)
    applyCustomFilter(null)
  }

  const onChangeSelect = id => {
    let selected = customFilters.find(el => el._id === id)
    if (selected) {
      setCustomFilter({ ...INITIAL_STATE, ...selected })
    }
  }

  return (
    <FuseDialog open={open} onBackDropClick={() => { }}>
      <FuseDialogHeader
        text={newFilter ? 'Create filter' : 'Manage Filters'}
        handleClose={() => {
          setDialogOpen(false)
        }}
      />
      <FuseDialogBody>
        <Grid container alignItems='center' className={classes.container}>
          <Grid item sm={12} md={12} className={classes.header}>
            <Grid container>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Filter name</Typography>
              </Grid>
              <Grid item sm={3}>
                {!newFilter && customFilter && (
                  <FormControl className={classes.field}>
                    <Select
                      id='filter-select'
                      value={get(customFilter, '_id', '')}
                      onChange={e => {
                        onChangeSelect(e.target.value)
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        }
                      }}
                      input={<BootstrapInput />}
                    >
                      {customFilters.map((filter, index) => {
                        return (
                          <MenuItem key={index} value={filter._id}>
                            {filter.settingsName}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item sm={2}></Grid>
              <Grid item sm={3}>
                <FormControl className={classes.field}>
                  <input
                    className={classes.name}
                    value={get(customFilter, 'settingsName')}
                    onChange={e => {
                      setCustomFilter({ ...customFilter, settingsName: e.target.value })
                    }}
                    placeholder='Filter name'
                  />
                  {error && <FormHelperText error={true}>Enter filter name</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} md={12} className={classes.content}>
            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Shipment Type</Typography>
              </Grid>
              <Grid item sm={8} className='mt-16'>
                <Grid container alignItems="center">
                  <Grid item sm={4}>
                    <FuseCheckBox
                      checked={get(customFilter, 'value.shipmentType', []).indexOf('FCL') !== -1}
                      onChange={e => {
                        let fclIndex = get(customFilter, 'value.shipmentType', []).indexOf('FCL')
                        if (fclIndex === -1) {
                          setCustomFilter({
                            ...customFilter,
                            value: {
                              ...customFilter.value,
                              shipmentType: [...get(customFilter, 'value.shipmentType', []), 'FCL']
                            }
                          })
                        } else {
                          setCustomFilter({
                            ...customFilter,
                            value: {
                              ...customFilter.value,
                              shipmentType: [
                                ...get(customFilter, 'value.shipmentType', []).slice(0, fclIndex),
                                ...get(customFilter, 'value.shipmentType', []).slice(fclIndex + 1)
                              ]
                            }
                          })
                        }
                      }}
                    />
                    Full container load
                  </Grid>
                  <Grid item sm={4}>
                    <FuseCheckBox
                      checked={get(customFilter, 'value.shipmentType', []).indexOf('LCL') !== -1}
                      onChange={e => {
                        let lclIndex = get(customFilter, 'value.shipmentType', []).indexOf('LCL')
                        if (lclIndex === -1) {
                          setCustomFilter({
                            ...customFilter,
                            value: {
                              ...customFilter.value,
                              shipmentType: [...get(customFilter, 'value.shipmentType', []), 'LCL']
                            }
                          })
                        } else {
                          setCustomFilter({
                            ...customFilter,
                            value: {
                              ...customFilter.value,
                              shipmentType: [
                                ...get(customFilter, 'value.shipmentType', []).slice(0, lclIndex),
                                ...get(customFilter, 'value.shipmentType', []).slice(lclIndex + 1)
                              ]
                            }
                          })
                        }
                      }}
                    />
                    Less than container load
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Transport Methods</Typography>
              </Grid>
              <Grid item sm={8} className='mt-16'>
                <Grid container alignItems="center" spacing={2}>
                  {['ocean', 'air', 'CBT', 'rail', 'NA'].map((item, itemIndex) => {
                    const label = item === 'NA' ? 'OTHER' : item.toUpperCase()

                    return (
                      <Grid item key={itemIndex}>
                        <FuseCheckBox checked={get(customFilter, 'value.transportMethod', []).indexOf(item) !== -1}
                          onChange={e => {
                            let existingIndex = get(customFilter, 'value.transportMethod', []).indexOf(item)
                            if (existingIndex === -1) {
                              setCustomFilter({
                                ...customFilter,
                                value: {
                                  ...customFilter.value,
                                  transportMethod: [...get(customFilter, 'value.transportMethod', []), item]
                                }
                              })
                            } else {
                              setCustomFilter({
                                ...customFilter,
                                value: {
                                  ...customFilter.value,
                                  transportMethod: [
                                    ...get(customFilter, 'value.transportMethod', []).slice(0, existingIndex),
                                    ...get(customFilter, 'value.transportMethod', []).slice(existingIndex + 1)
                                  ]
                                }
                              })
                            }
                          }}
                        />{label}</Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Special Labels</Typography>
              </Grid>
              <Grid item sm={8} className='mt-16'>
                {getSpecialLabelBlock(filter, setFilter)}
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Goods Type</Typography>
              </Grid>
              <Grid item sm={8} className='mt-16'>
                <Grid container alignItems="center">
                  <Grid item sm={4}>
                    <FuseCheckBox
                      checked={get(filter, 'goodsType', []).indexOf('DG') !== -1}
                      onChange={e => {
                        let dgIndex = get(filter, 'goodsType', []).indexOf('DG')
                        if (dgIndex === -1) {
                          setFilter({
                            ...filter,
                            goodsType: [...get(filter, 'goodsType', []), 'DG']
                          })
                        } else {
                          setFilter({
                            ...filter,
                            goodsType: [
                              ...get(filter, 'goodsType', []).slice(0, dgIndex),
                              ...get(filter, 'goodsType', []).slice(dgIndex + 1)
                            ]
                          })
                        }
                      }}
                    />
                    DG
                  </Grid>
                  <Grid item sm={4}>
                    <FuseCheckBox
                      checked={get(filter, 'goodsType', []).indexOf('NORMAL') !== -1}
                      onChange={e => {
                        let normalIndex = get(filter, 'goodsType', []).indexOf('NORMAL')
                        if (normalIndex === -1) {
                          setFilter({
                            ...filter,
                            goodsType: [...get(filter, 'goodsType', []), 'NORMAL']
                          })
                        } else {
                          setFilter({
                            ...filter,
                            goodsType: [
                              ...get(filter, 'goodsType', []).slice(0, normalIndex),
                              ...get(filter, 'goodsType', []).slice(normalIndex + 1)
                            ]
                          })
                        }
                      }}
                    />
                    Normal
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Incoterms</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {getIncoTermsBlock(filter, setFilter, incotermsOptions)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>CS Codes</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    {getCsCodeBlock(filter, setFilter, csCodes, setCsCodes)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Groupage date</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <InputLabel className='form-label form-label_bg' style={{}}>
                      {`Range`}
                    </InputLabel>
                    <Select
                      style={{ width: '100%' }}
                      value={filter.groupage.type}
                      onChange={e => {
                        if (e.target.value !== 'custom') {
                          setFilter({ ...filter, groupage: { type: e.target.value, from: null, to: null } })
                        } else {
                          let today = moment()
                          let lastWeek = moment().subtract(7, 'd')
                          setFilter({
                            ...filter,
                            groupage: { type: e.target.value, from: lastWeek.toDate(), to: today.toDate() }
                          })
                        }
                      }}
                    >
                      <MenuItem value='none'> None</MenuItem>
                      <MenuItem value='today'> Today</MenuItem>
                      <MenuItem value='yesterday'> Yesterday</MenuItem>
                      <MenuItem value='lastWeek'> Last week</MenuItem>
                      <MenuItem value='last30'> Last 30 days</MenuItem>
                      <MenuItem value='last60'> Last 60 days</MenuItem>
                      <MenuItem value='custom'> Custom</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={4}>
                    {filter.groupage.type === 'custom' && (
                      <Grid className={classNames('', classes.bordered)}>
                        <Grid xs={12} item>
                          <InputLabel className='form-label form-label_bg' style={{}}>
                            {`From Date`}
                          </InputLabel>
                        </Grid>
                        <Grid xs={12} item>
                          <Grid container alignItems='center'>
                            <Grid xs={8} item>
                              <DateTime
                                className={classNames(classes.datetimePicker)}
                                input
                                dateFormat='DD MMM YYYY'
                                timeFormat={false}
                                closeOnSelect={true}
                                onChange={date => {
                                  if (new Date(date).toString() === 'Invalid Date') {
                                    date = new Date()
                                  }
                                  setFilter({ ...filter, groupage: { ...filter.groupage, from: date } })
                                }}
                                value={filter.groupage.from && new Date(filter.groupage.from)}
                              />
                            </Grid>
                            <Grid xs={4} item>
                              <Icon
                                color='disabled'
                                style={{
                                  fontSize: 18,
                                  marginRight: 8
                                }}
                              >
                                calendar_today
                              </Icon>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {filter.groupage.type === 'custom' && (
                      <Grid className={classNames('', classes.bordered)}>
                        <Grid xs={12} item>
                          <InputLabel className='form-label form-label_bg' style={{}}>
                            {`To Date`}
                          </InputLabel>
                        </Grid>
                        <Grid xs={12} item>
                          <Grid container alignItems='center'>
                            <DateTime
                              className={classNames(classes.datetimePicker)}
                              input
                              dateFormat='DD MMM YYYY'
                              timeFormat={false}
                              closeOnSelect={true}
                              onChange={date => {
                                if (new Date(date).toString() === 'Invalid Date') {
                                  date = new Date()
                                }
                                setFilter({ ...filter, groupage: { ...filter.groupage, to: date } })
                              }}
                              value={filter.groupage.to && new Date(filter.groupage.to)}
                            />
                            <Icon color='disabled' style={{ fontSize: 18, marginRight: 8 }}>
                              calendar_today
                            </Icon>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Partners</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    {getAutoCompleteBlockForPartner(
                      'obl',
                      get(filters, 'obl', []),
                      'Export logistics partner',
                      filter,
                      setFilter
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {getAutoCompleteBlockForPartner(
                      'ofp',
                      get(filters, 'ofp', []),
                      'Export freight partner',
                      filter,
                      setFilter
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    {getAutoCompleteBlockForPartner(
                      'obc',
                      get(filters, 'obc', []),
                      'Export customs partner',
                      filter,
                      setFilter
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Country</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock('origin', filters['originCountries'], 'Origin Countries', filter, setFilter)}
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container alignItems='center' justify='center' className='mt-16'>
                      <img src='assets/images/icons/filter/Routes-destination.png' alt='route' />
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock(
                      'destination',
                      filters['destinationCountries'],
                      'Destination Countries',
                      filter,
                      setFilter
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Ports</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock('originPort', filters['originPorts'], 'Origin Port', filter, setFilter)}
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container alignItems='center' justify='center' className='mt-16'>
                      <img src='assets/images/icons/filter/Routes-destination.png' alt='route' />
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock(
                      'destinationPort',
                      filters['destinationPorts'],
                      'Destination Port',
                      filter,
                      setFilter
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Shipment Parties</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock('originPlant', filters['originPlants'], 'Shipper', filter, setFilter)}
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container alignItems='center' justify='center' className='mt-16'>
                      <img src='assets/images/icons/filter/Routes-destination.png' alt='route' />
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock('destinationPlant', filters['destinationPlants'], 'Consignee', filter, setFilter)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}># Event</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock('addedEvents', eventsList, 'Added Events', filter, setFilter)}
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container alignItems='center' justify='center' className='mt-16'></Grid>
                  </Grid>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock('pendingEvents', eventsList, 'Pending Events', filter, setFilter)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Documents</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                <Grid container>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock('uploadedDocuments', documentsList, 'Uploaded Documents', filter, setFilter)}
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container alignItems='center' justify='center' className='mt-16'></Grid>
                  </Grid>
                  <Grid item xs={5}>
                    {getAutoCompleteBlock('pendingDocuments', documentsList, 'Pending Documents', filter, setFilter)}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container className='mb-16'>
              <Grid item sm={2} className={classes.titleContainer}>
                <Typography className={classes.title}>Date Range</Typography>
              </Grid>
              <Grid item sm={9} className='mt-16'>
                {(get(filter, 'ranges', [])).map((range, index) => {
                  return (
                    <DateRangeBlock
                      key={index}
                      index={index}
                      range={range}
                      filter={filter}
                      setFilter={setFilter}
                      classes={classes}
                      eventsList={eventsList}
                    />
                  )
                })}
                <Grid container alignItems='center' className='mt-8'>
                  <Grid item xs={12}>
                    <label className='fg-blue medium cursor-pointer'
                      onClick={() => {
                        addRangeRow(filter, setFilter)
                      }}
                    >
                      {get(filter, 'ranges', []).length > 0 ? '+  Add more' : '+  Add'}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container style={{ height: 30 }}></Grid>
          </Grid>

          <Grid item sm={12} md={12} className={classes.footer}>
            <Grid container
              alignItems='center'
              spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <button className={classes.reset}
                  onClick={() => {
                    setFilter(INITIAL_STATE)
                  }}>RESET</button>
                {!newFilter && customFilter && (
                  <span className={classes.deleteFilter}>
                    <FiberManualRecordIcon style={{ fontSize: 6 }} />
                    <button className={classes.deleteFilterButton}
                      onClick={() => deleteFilter()}>
                      DELETE THIS FILTER</button>
                  </span>
                )}
              </Grid>

              <Grid item xs={12} sm={12} md={6}>
                <Grid container
                  justify='flex-end'
                  spacing={2}>
                  <Grid item>
                    <Button className={classNames(
                      'btn btn_lg btn_light_green w-full',
                      classes.saveButton)}
                      onClick={() => saveFilter(false)}>
                      Save</Button>
                  </Grid>
                  {newFilter &&
                    <Grid item>
                      <Button className={classNames(
                        'btn btn_lg btn_light_green w-full',
                        classes.saveButton)}
                        onClick={() => {
                          const preparedFilters = {
                            ...customFilter,
                            settingsName: `${get(customFilter, 'settingsName')} [UNSAVED]`,
                            value: prepareCustomFilterParams(customFilter)
                          }
                          applyCustomFilter(preparedFilters)
                          setCustomFilter(INITIAL_STATE)
                          setDialogOpen(false)
                        }}>Apply</Button>
                    </Grid>
                  }
                  <Grid item>
                    <Button className={classNames(
                      'btn btn_lg btn_green',
                      classes.saveButton)}
                      onClick={() => saveFilter(true)}>
                      Save & Apply</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}
const getUniqueOptions = arr => {
  let uniqueNames = []
  let uniqueOptions = []
  arr.forEach(option => {
    if (uniqueNames.indexOf(option.value) === -1) {
      uniqueNames.push(option.value)
      uniqueOptions.push(option)
    }
  })
  return uniqueOptions
}

const getAutoCompleteBlock = (type, data, placeholder, filter, setFilter) => {
  let options = data
    .filter(val => get(filter, type, []).indexOf(val) === -1)
    .map(option => ({ value: option.name, label: option.name }))
  options = getUniqueOptions(options)
  return (
    <Grid container alignItems='center' justify='space-between' className='relative'>
      <Grid item xs={12}>
        <Grid container style={autoCompleteBlock}>
          <Grid item xs={12} className='h-40'>
            <AutoComplete
              options={options}
              onChange={value => {
                setFilter({ ...filter, [type]: value })
              }}
              value={get(filter, type, [])}
              customStyles={filterSelectStyles}
              placeholderStr={placeholder}
              placeholder={''}
              //showPlaceHolderOnFocus={true}
              isMulti
              hideChip
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='mt-8'>
        {(get(filter, type, [])).map((str, index) => {
          return (
            <Chip
              key={index}
              label={str}
              onDelete={() => {
                setFilter({ ...filter, [type]: removeFromArray(filter[type], str) })
              }}
              style={chipStyles}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

const getAutoCompleteBlockForPartner = (type, data = [], placeholder, filter, setFilter) => {
  let options = data.map(option => ({ value: option.name, label: option.name }))
  return (
    <Grid container alignItems='center' justify='space-between' className='relative'>
      <Grid item xs={12}>
        <Grid container style={autoCompleteBlock}>
          <Grid item xs={12} className='h-40'>
            <AutoComplete
              options={options}
              value={get(filter, 'partners.' + type, [])}
              onChange={value => {
                setFilter({ ...filter, partners: { ...filter.partners, [type]: value } })
              }}
              customStyles={filterSelectStyles}
              placeholderStr={placeholder}
              placeholder={''}
              //showPlaceHolderOnFocus={true}
              isMulti
              hideChip
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='mt-8'>
        {get(filter, 'partners.' + type, []).map((str, index) => {
          return (
            <Chip
              key={index}
              label={str}
              onDelete={() => {
                setFilter({
                  ...filter,
                  partners: {
                    ...get(filter, 'partners', {}),
                    [type]: removeFromArray(get(filter, 'partners.' + type, []), str)
                  }
                })
              }}
              style={chipStyles}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

const getIncoTermsBlock = (filter, setFilter, incotermsOptions) => {
  return (
    <Grid container
      alignItems='center'
      justify='space-between'
      className='relative'>
      <Grid item xs={12}>
        <Grid container style={autoCompleteBlock}>
          <Grid item xs={12} className='h-40'>
            <AutoComplete options={incotermsOptions}
              value={get(filter, 'incoterms', [])}
              onChange={value => {
                setFilter({ ...filter, incoterms: value })
              }}
              customStyles={filterSelectStyles}
              placeholderStr="Select incoterms..."
              placeholder={''}
              isMulti
              hideChip
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className='mt-8'>
        {get(filter, 'incoterms', []).map((term, index) => {
          return (
            <Chip key={index}
              label={term}
              onDelete={() => {
                setFilter({
                  ...filter,
                  incoterms: [...filter.incoterms.slice(0, index), ...filter.incoterms.slice(index + 1)]
                })
              }}
              style={chipStyles}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

const getCsCodeBlock = (filter, setFilter, csCodes, setCsCodes) => {
  return (
    <Grid container alignItems='center'
      justify='space-between'
      className='relative'>
      <Grid item xs={12}>
        <Grid container>
          <input value={csCodes}
            style={{
              width: '100%',
              height: 40,
              backgroundColor: '#FAFAFA',
              border: '1px solid rgba(159, 160, 164, 0.2)',
              borderRadius: 2,
              paddingLeft: 10
            }}
            onChange={e => {
              setCsCodes(e.target.value)
            }}
            onKeyDown={e => {
              if (e.keyCode === 13 && csCodes.trim() !== '') {
                let arr = csCodes.split(',').map(csCode => csCode.trim())
                let items = uniq([...get(filter, 'csCodes', []), ...arr])
                setFilter({ ...filter, csCodes: items })
                setCsCodes('')
              }
            }}
            placeholder='Enter the CS Code and press enter.'
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className='mt-8'>
        {get(filter, 'csCodes', []).map((csCode, index) => {
          return (
            <Chip key={index}
              label={csCode}
              onDelete={() => {
                setFilter({
                  ...filter,
                  csCodes: [...filter.csCodes.slice(0, index), ...filter.csCodes.slice(index + 1)]
                })
              }}
              style={chipStyles}
            />
          )
        })}
      </Grid>
    </Grid>
  )
}

const getSpecialLabelBlock = (filter, setFilter) => {
  return (
    <Grid container
      alignItems="center"
      spacing={2}>
      {[
        { name: 'FORMD', type: 'formd' },
        { name: 'FORMAK', type: 'formak' },
        { name: 'FORMA', type: 'forma' },
        { name: 'FORME', type: 'forme' },
      ].map((specialLabel, specialLabelIndex) => {
        const name = specialLabel.name
        const type = specialLabel.type

        return (
          <Grid item key={specialLabelIndex}>
            <FuseCheckBox
              checked={get(filter, 'specialLabels', []).indexOf(type) !== -1}
              onChange={e => {
                let formdIndex = get(filter, 'specialLabels', []).indexOf(type)
                if (formdIndex === -1) {
                  setFilter({
                    ...filter,
                    specialLabels: [...get(filter, 'specialLabels', []), type]
                  })
                } else {
                  setFilter({
                    ...filter,
                    specialLabels: [
                      ...get(filter, 'specialLabels', []).slice(0, formdIndex),
                      ...get(filter, 'specialLabels', []).slice(formdIndex + 1)
                    ]
                  })
                }
              }}
            />
            {name}
          </Grid>
        )
      })}
    </Grid>
  )
}

const removeFromArray = (arr, str) => {
  let index = arr.indexOf(str)
  if (index !== -1) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)]
  }
}
const autoCompleteBlock = {
  backgroundColor: '#FAFAFA',
  border: '1px solid rgba(159, 160, 164, 0.2)',
  borderRadius: 2
}
const chipStyles = {
  borderRadius: 3,
  fontSize: 10,
  height: 25,
  marginRight: 3,
  marginTop: 3
}
const filterSelectStyles = {
  container: () => ({
    position: 'absolute',
    width: '95%',
    height: '40px',
    margin: '0px'
  }),
  control: () => ({
    display: 'flex',
    // border: 'none',
    minHeight: 'unset',
    maxHeight: 'unset',
    marginTop: 'unset',
    height: '100%',
    width: '100%',
    paddingLeft: 10
  }),
  menu: styles => ({
    ...styles,
    minWidth: '180px',
    left: '0px',
    zIndex: 1900
  }),
  clearIndicator: () => ({
    display: 'none'
  }),
  placeholder: styles => ({
    ...styles,
    fontSize: 12
  })
}
const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #ced4da',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '12px 26px 12px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'IBMPlexSans-SemiBold',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

const styles = theme => ({
  delete: {
    padding: 30
  },
  header: {
    position: 'relative',
    top: 0,
    //height: 60,
    paddingBottom: 10,
    backgroundColor: '#F5F5F5'
  },
  content: {
    minHeight: 300,
    overflowY: 'scroll',
    maxHeight: 350
  },
  footer: {
    position: 'relative',
    bottom: 0,
    height: 60,
    backgroundColor: '#F5F5F5'
  },
  button: {
    color: '#fff',
    backgroundColor: '#cc1f1a'
  },
  titleContainer: {
    marginTop: 20,
    marginLeft: 20,
    textAlign: 'left'
  },
  title: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'left'
  },
  field: {
    width: 300,
    marginTop: 10
  },
  datetimePicker: {
    '& input': {
      maxWidth: '90%'
    }
  },
  name: {
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(159, 160, 164, 0.2)',
    borderRadius: 2,
    width: 290,
    height: 40,
    paddingLeft: 10
  },
  saveButton: {
    marginTop: 10,
    marginRight: 20,
    width: 200
  },
  reset: {
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left',
    marginTop: 10,
    marginLeft: 20,
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    borderRadius: 20,
    textTransform: 'uppercase',
    padding: '0px 13px',
    color: '#464242',
    backgroundColor: 'none'
  },
  deleteFilterButton: {
    marginTop: 10,
    marginLeft: 20,
    color: '#B10043',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'left'
  },
  icon: { fontSize: 75 }
})
export default withStyles(styles)(FilterDialog)
