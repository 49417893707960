import React from 'react'
import { ListSubheader, withStyles } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import FuseNavVerticalItem from './FuseNavVerticalItem'

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    children: PropTypes.array
  })
}

const defaultProps = {}

const styles = theme => ({
  item: {
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingRight: 12
  }
})

const FuseNavVerticalGroup = ({ classes, item, nestedLevel }) => {
  let paddingValue = 40 + nestedLevel * 16
  const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24'

  return (
    <React.Fragment>
      <ListSubheader
        disableSticky={true}
        className={classNames(classes.item, listItemPadding, {
          'list-subheader flex items-center': true,
          'h-auto': item.title === ''
        })}>
        <span className='list-subheader-text uppercase text-12'>{item.title}</span>
      </ListSubheader>

      {item.children && (
        <React.Fragment>
          {item.children.map(item => (
            <React.Fragment key={item.id}>
              {item.type === 'group' && <NavVerticalGroup item={item} nestedLevel={nestedLevel} />}
              {item.type === 'item' && <FuseNavVerticalItem item={item} nestedLevel={nestedLevel} />}
            </React.Fragment>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

FuseNavVerticalGroup.propTypes = propTypes
FuseNavVerticalGroup.defaultProps = defaultProps

const NavVerticalGroup = withStyles(styles, { withTheme: true })(withRouter(FuseNavVerticalGroup))

export default NavVerticalGroup
