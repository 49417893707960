import React from 'react'
import {
    Grid,
    Button,
    Icon
} from '@material-ui/core'
import {
    FuseDialog,
    FuseDialogHeader,
    FuseDialogBody
} from '../../core/components'
import { get } from 'lodash'

const PlantSettingConfirm = ({ open, currentPlant, existingTemplate, handleOkCreate, handleOkEdit, handleCancel }) => (
    <FuseDialog open={open} wSM>
        <FuseDialogHeader text="Action Confirmation"
            handleClose={handleCancel} />
        <FuseDialogBody>
            <Grid container alignItems="center" className="p-20">
                <Grid item xs={12}>
                    <Grid container justify="center">
                        <Icon className="text-52 mb-12 fg-orange">info</Icon>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="text-center">
                    {existingTemplate &&
                        <label className="regular">Any changes you make to this template will be changed for all the users available in plant <strong>{get(currentPlant, 'name')}</strong></label>
                    }
                    {!existingTemplate &&
                        <label className="regular">Added template can be seen and edit by all the users available in plant <strong>{get(currentPlant, 'name')}</strong></label>
                    }
                    <label className="regular block text-center">Are you sure you want to do this action?</label>
                </Grid>
                <Grid item xs={12}>
                    <Grid container
                        spacing={2}
                        className="pt-40">
                        <Grid item xs={6}>
                            <Button className="btn btn_sm btn_light_green"
                                onClick={() => existingTemplate ? handleOkEdit(existingTemplate) : handleOkCreate()}
                                fullWidth>Yes</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button className="btn btn_sm btn_light_red"
                                onClick={handleCancel}
                                fullWidth>Cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </FuseDialogBody>
    </FuseDialog>
)

export default PlantSettingConfirm
