import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { capitalize } from 'lodash'
import {
  LoadingButton,
  FuseFileUploader,
  CombinedDocsDialog,
  AttachOpenERPDialog,
  FusePlaceHolder,
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody
} from '../../core/components'
import { documentCodes } from '../../core/localization'
import { get } from "lodash";

class UploadDialog extends Component {
  initialCombinedDocsDialog = {
    open: false,
    allowedDocumentTypes: [],
    docType: '',
  }

  initialAttachOpenERPDialog = {
    open: false,
    allowedDocumentTypes: [],
  }

  state = {
    documents: {},
    combinedDocuments: {},
    arrayOfCall: [],
    response: {},
    loading: false,
    combinedDocsDialog: this.initialCombinedDocsDialog,
    attachOpenERPDialog: this.initialAttachOpenERPDialog
  }
  uploadInfo = {}
  uploaderRefs = []
  count = 0

  componentDidUpdate(oldProps) {
    const oldOpen = get(oldProps, 'open')
    const { shipment, open, selectedTask } = this.props
    const { rbac } = shipment

    if (rbac && get(rbac, 'write', []).length > 0) {
      this.uploadInfo = {}
      documentCodes.forEach(d => {
        if (get(rbac, 'write', []).includes(d.type)) {
          if (get(selectedTask, 'task.action.code') === d.code) {
            this.uploadInfo = {
              ...this.uploadInfo,
              [get(d, 'type')]: get(d, 'name')
            }
          }
        }
      })
    }

    if (oldOpen !== open) {
      this.setState({
        documents: {},
        combinedDocuments: {},
        arrayOfCall: [],
        response: {},
        loading: false,
        combinedDocsDialog: this.initialCombinedDocsDialog,
        attachOpenERPDialog: this.initialAttachOpenERPDialog
      })
      this.count = 0
    }
  }

  handleOpenAttachOpenERP = () => {
    const { shipment: { rbac } } = this.props
    if (rbac) {
      this.setState({
        attachOpenERPDialog: {
          open: true,
          allowedDocumentTypes: get(rbac, 'write', []),
        }
      })
    }
  }

  handleUpdateDocument = type => documents => {
    const { shipment: { rbac } } = this.props
    const combineTypes = []

    if (Object.keys(documents).length > 0 && combineTypes.includes(type)) {
      this.setState({
        combinedDocsDialog: {
          open: true,
          allowedDocumentTypes: get(rbac, 'write', []),
          docType: type,
        }
      })
    }

    this.setState({
      documents: {
        ...this.state.documents,
        [type]: documents
      }
    })
  }

  handleCombinedDocuments = (type, selectedTypes) => {
    this.setState({
      combinedDocsDialog: this.initialCombinedDocsDialog,
      combinedDocuments: {
        [type]: selectedTypes
      }
    })
  }

  uploadFile = () => {
    const { documents, combinedDocuments } = this.state
    const { shipment } = this.props
    const arrayOfCall = []
    const docTypes = Object.keys(documents)

    if (docTypes.length) {
      docTypes.forEach(docType => {
        Object.keys(documents[docType]).forEach(fileName => {
          const file = new FormData()
          file.append('document', documents[docType][fileName])

          const data = {
            file,
            params: [shipment._id, docType, undefined, get(combinedDocuments, docType)]
          }
          this.props.upload('shipment', data, this.handleResponse, this.handleResponse, true)

          arrayOfCall.push(`response${capitalize(docType)}`)
        })
      })

      this.setState({ arrayOfCall, loading: true })
    }
  }

  handleResponse = () => {
    const { arrayOfCall } = this.state
    const { handleClose, callback } = this.props
    this.count += 1

    if (this.count === arrayOfCall.length) {
      this.setState({ documents: {}, loading: false })
      this.uploaderRefs.forEach(ref => ref && ref.resetState())
      handleClose()
      callback()
    }
  }

  render() {
    const { handleClose, open, shipment, callback } = this.props
    const { loading, combinedDocsDialog, attachOpenERPDialog, documents } = this.state

    return (
      <React.Fragment>
        <FuseDialog open={open} wSM>
          <FuseDialogHeader text='Upload Document'
            handleClose={handleClose} />
          <FuseDialogBody>
            <Grid container className="p-20">
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {Object.keys(this.uploadInfo).length === 0 &&
                    <FusePlaceHolder icon="info"
                      title="Uploader not available"
                      description="This document uploader is not available yet" />
                  }
                  {Object.keys(this.uploadInfo).map((type, index) => (
                    <Grid item xs={12} key={index}>
                      <FuseFileUploader allowMultiple
                        ref={ref => this.uploaderRefs[index] = ref}
                        fileTitle={this.uploadInfo[type]}
                        type={type}
                        name={type}
                        image='assets/images/icons/Document-upload.png'
                        required={false}
                        subTitle='You can add multiple files'
                        onSuccess={this.handleUpdateDocument(type)}
                        allowedTypes={".pdf,.xls,.xlsx,.xlsm,.csv,.txt,.zip,.doc,.docx,.jpg,.jpeg,.png,.gif"}
                        maxFilesize={25} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12} className="pt-40">
                <LoadingButton className='btn btn_sm w-full'
                  disabled={Object.keys(documents).length === 0}
                  loading={loading}
                  onClick={this.uploadFile}>
                  upload</LoadingButton>
              </Grid>
            </Grid>
          </FuseDialogBody>
        </FuseDialog>

        <CombinedDocsDialog {...combinedDocsDialog}
          onConfirm={this.handleCombinedDocuments}
          handleClose={() => this.setState({
            combinedDocsDialog: this.initialCombinedDocsDialog
          })} />

        <AttachOpenERPDialog {...attachOpenERPDialog}
          shipmentId={shipment._id}
          onConfirm={() => {
            this.setState({
              attachOpenERPDialog: this.initialAttachOpenERPDialog
            })
            handleClose()
            callback()
          }}
          handleClose={() => this.setState({
            attachOpenERPDialog: this.initialAttachOpenERPDialog
          })} />
      </React.Fragment>
    )
  }
}

export default UploadDialog
