import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField, Select, MenuItem, FormControl, InputBase, withStyles } from '@material-ui/core'
import { filter, get, set, find, pick } from 'lodash'
import { FusePlaceHolder } from 'core/components'
import { GooglePlaceSearch } from 'core/components/Inputs'
import DateTime from 'react-datetime'
import { general } from 'core/localization'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.054)',
    border: '1px solid #ced4da',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '6px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'IBMPlexSans-SemiBold'
  }
}))(InputBase)

const Containers = (props) => {
  const {
    shipment,
    updateShipmentContainers,
    classes,
    addContainer,
    updateSingleContainer
  } = props
  const [containers, setContainers] = useState([])
  const s20Count = filter(containers, { type: 'C20GP' }).length
  const s40Count = filter(containers, { type: 'C40GP' }).length
  const hc40Count = filter(containers, { type: 'C40HC' }).length

  useEffect(() => {
    var containersArr = get(shipment, 'containers', []).map(item => {
      return pick(item, [
        '_id',
        'type',
        'status',
        'equipmentNo',
        'supplierSealNumber',
        'carrierSealNumber',
        'referenceEvents'
      ])
    })
    setContainers(containersArr)
  }, [shipment])

  const handleUpdateContainerType = (event, index) => {
    setContainers([
      ...containers.slice(0, index),
      { ...containers[index], type: event.target.value },
      ...containers.slice(index + 1)
    ])
  }

  const handleUpdateContainerPlace = (address, lat, lng, index) => {
    set(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {
      ...get(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {}),
      ...address,
      latitude: lat.toString(),
      longitude: lng.toString()
    })
    setContainers([...containers])
  }

  const handleUpdateContainerAdditionalAddress = (event, index) => {
    const { name, value } = event.target
    set(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {
      ...get(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {}),
      [name]: value
    })
    setContainers([...containers])
  }

  const handleUpdateContainerDateTime = (date, index) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    set(
      containers[index],
      'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt',
      new Date(date)
    )
    setContainers([...containers])
  }

  const handleChange = (event, index) => {
    const { name, value } = event.target
    set(containers[index], name, value.toUpperCase())
    setContainers([...containers])
  }

  const addNewContainer = () => {
    setContainers([
      ...containers,
      {
        isNew: true
      }
    ])
  }

  const removeContainer = (index) => {
    let arr = [
      ...containers.slice(0, index),
      ...containers.slice(index + 1)
    ]
    setContainers(arr)
  }

  const resetContainer = (_id, index) => {
    const shipmentContainer = find(shipment.containers, { _id })
    setContainers([
      ...containers.slice(0, index),
      { ...shipmentContainer },
      ...containers.slice(index + 1)
    ])
  }

  const submit = payload => {
    updateShipmentContainers(payload, shipment._id)
  }

  const typeOptions = ['C20GP', 'C40GP', 'C40HC']

  return (
    <Grid container direction='row' className={'field-control booking-wizard'}>
      <Grid item sm={12} md={6} className='flex items-center mb-12'></Grid>
      {containers.length > 0 && (
        <Grid item sm={12} md={6} className='flex items-center justify-end mt-16'>
          <label className={'container-field-instruction'}>
            <span className='medium'>20'ST - {s20Count}</span>
            <span className='px-6'>&bull;</span>
            <span className='medium'>40'ST - {s40Count}</span>
            <span className='px-6'>&bull;</span>
            <span className='medium'>40'HC - {hc40Count}</span>
          </label>
        </Grid>
      )}
      {containers.length === 0 && (
        <Grid container className="mb-20">
          <FusePlaceHolder
            className='flex flex-col'
            icon='error'
            title='No containers'
            button={{
              text: 'Add New',
              onClick: addNewContainer
            }}
          ></FusePlaceHolder>
        </Grid>
      )}
      {containers.length > 0 && (
        <Grid container direction='row' className='container-stepper'>
          {containers.map((container, index) => {
            const containerType = get(container, 'type')

            return (
              <Grid container direction='row'
                className='container-step pb-32'
                key={index}>
                <Grid container direction='row'
                  className='container-step-header'>
                  <span className='container-step-icon'>
                    <img src='assets/images/icons/timeline-step/storage-facility-blue.svg'
                      alt='container-booking' />
                  </span>
                  <Grid item sm={12} md={6}
                    className='flex items-center'>
                    {
                      <Grid container>
                        <Grid item xs={7}>
                          <FormControl fullWidth>
                            {typeOptions.length > 0 && (
                              <Select id='demo-customized-select'
                                value={containerType || 'default'}
                                onChange={event => {
                                  handleUpdateContainerType(event, index)
                                }}
                                placeholder='Select type...'
                                input={<BootstrapInput />}>
                                <MenuItem value='default' selected disabled>
                                  Select type...
                                </MenuItem>
                                {typeOptions.map(type => {
                                  return (
                                    <MenuItem key={type} value={type}>
                                      {general.container[type]}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={5} />
                      </Grid>
                    }
                  </Grid>
                  <Grid item sm={12} md={6}>
                    {container.isNew &&
                      <Grid container
                        justify="flex-end"
                        spacing={2}
                        className="text-14 medium">
                        <Grid item>
                          <label className={'fg-green cursor-pointer'}
                            onClick={() => addContainer(container, shipment._id)}>
                            Save</label>
                        </Grid>
                        <Grid item>
                          <label className={'fg-red cursor-pointer'}
                            onClick={() => removeContainer(index)}>
                            Remove</label>
                        </Grid>
                      </Grid>
                    }
                    {!container.isNew &&
                      <Grid container
                        justify="flex-end"
                        spacing={2}
                        className="text-14 medium">
                        <Grid item>
                          <label className={'fg-green cursor-pointer'}
                            onClick={() => updateSingleContainer(container, shipment._id)}>
                            Update</label>
                        </Grid>
                        <Grid item>
                          <label className={'fg-blue cursor-pointer'}
                            onClick={() => resetContainer(container._id, index)}>
                            Reset</label>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>

                {get(shipment, 'type') === 'LCL' &&
                  <Grid container spacing={2}
                    className="pt-24 pl-4">
                    <Grid item xs={4}>
                      <TextField name='equipmentNo'
                        className={classes.inputStyles}
                        value={get(container, 'equipmentNo', '')}
                        label='Container No'
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => handleChange(event, index)} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField name='supplierSealNumber'
                        className={classes.inputStyles}
                        value={get(container, 'supplierSealNumber', '')}
                        label='Supplier Seal Number'
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => handleChange(event, index)} />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField name='carrierSealNumber'
                        className={classes.inputStyles}
                        value={get(container, 'carrierSealNumber', '')}
                        label='Carrier Seal Number'
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => handleChange(event, index)} />
                    </Grid>
                  </Grid>
                }

                {get(shipment, 'type') === 'FCL' &&
                  <Grid container direction='row'
                    className='step-content'>
                    <Grid item sm={12} className='pb-16'>
                      <label className={'formsy-form-control__label'}>
                        Truck in with empty container place</label>
                      <GooglePlaceSearch
                        placeholder='Select street address...'
                        className='theme-text-field'
                        value={get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.location.street', '')}
                        updateCall={(address, lat, lng) => {
                          handleUpdateContainerPlace(address, lat, lng, index)
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={7} className='pr-8'>
                      <TextField
                        name='floorUnitNumber'
                        className={classes.inputStyles}
                        value={get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.location.floorUnitNumber', '')}
                        label='Additional address'
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={event => {
                          handleUpdateContainerAdditionalAddress(event, index)
                        }}
                      />
                    </Grid>
                    <Grid item sm={12} md={5} className='pl-6'>
                      <Grid container>
                        <Grid item xs={12}>
                          <label className='formsy-form-control__label'>
                            Date & time</label>
                        </Grid>
                        <Grid item xs={12} className='relative'>
                          <DateTime
                            className='container-theme-date-time-picker-field'
                            input
                            dateFormat='DD MMM YYYY,'
                            onChange={date => {
                              handleUpdateContainerDateTime(date, index)
                            }}
                            value={
                              get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt') &&
                              new Date(get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt'))
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            )
          })}
          {get(shipment, 'type') === 'FCL' &&
            <Grid container direction='row'
              justify="center"
              className="pt-40"
              spacing={2}>
              <Grid item xs={4}>
                <Button className="btn"
                  onClick={() => {
                    submit({ containers })
                  }}
                  fullWidth>Update Containers</Button>
              </Grid>
              <Grid item xs={2}>
                <Button className="btn"
                  onClick={() => setContainers(get(shipment, 'containers', []))}
                  fullWidth>reset</Button>
              </Grid>
            </Grid>
          }
        </Grid>
      )
      }
    </Grid>
  )
}

export default Containers
