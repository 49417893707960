import React from 'react'
import {
  withStyles,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Zoom
} from '@material-ui/core'
import WarningIcon from '@material-ui/icons/WarningTwoTone';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoToolTip from './InfoToolTip';

const NetworkDown = ({ classes }) => {
  return (
    <Dialog onClose={() => { }}
      aria-labelledby='newtworkdown-dialog-title'
      open={true}
      style={{ zIndex: 2000, height: '100%' }}>
      <DialogContent className="regular p-40">
        <Zoom in={true}>
          <Grid container alignItems="center" justify="center" spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom className="fg-red bold text-28 text-center uppercase">
                Service Down</Typography>
            </Grid>
            <Grid item>
              <WarningIcon className="text-60 fg-red" />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom className="fg-red semi-bold text-18 text-center">
                Sorry, TRAMÉS service is currently not available.</Typography>
              <Typography gutterBottom className="fg-red semi-bold text-18 text-center">
                Please check back soon!</Typography>
            </Grid>
            <Grid item>
              <Grid container justify="center">
                <InfoToolTip title="Refresh the page">
                  <Grid item className="cursor-pointer rounded-full bg-orange h-60 w-60 p-6"
                    onClick={() => window.location.reload()}>
                    <RefreshIcon className="text-48 text-white" />
                  </Grid>
                </InfoToolTip>

                <Grid item xs={12} className="pt-4">
                  <Typography gutterBottom className="fg-orange regular text-12 text-center">
                    Refresh the page</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Zoom>
      </DialogContent>
    </Dialog>

  )
}

const styles = theme => ({})

export default withStyles(styles)(NetworkDown)