import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { PersistGate } from 'redux-persist/integration/react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { StylesProvider } from '@material-ui/styles'
import { MuiThemeProvider } from '@material-ui/core'
import AppRoutes from 'core/Routes'
import * as serviceWorker from './serviceWorker'
import theme from 'core/theme'
import reduxStore from './store'
import ReactGA from 'react-ga';
import 'styles/index.scss'
import { getGATrackId } from './core/helpers'
import * as AuthActions from 'auth/store/actions';

const { store, persistor, history } = reduxStore
const GA_TRACKING_ID = getGATrackId()

const initializeReactGA = () => {
  setZendeskFormFields();
  setZendeskTitlePath();
  if (GA_TRACKING_ID) {
    ReactGA.initialize(GA_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

history.listen(function (location) {
  setZendeskTitlePath();
  if (GA_TRACKING_ID) {
    ReactGA.pageview(location.pathname + location.search);
  }
});

/**
 * Function to set the path and title for the Zendesk
 */
const setZendeskTitlePath = () => {
  if (typeof window.zE === 'function') {
    if (localStorage.getItem('Authorization')) {
      let { href, pathname } = window.location;

      if (pathname) {
        pathname = pathname.replace('/', '').replace('-', ' ').toUpperCase();
      } else {
        pathname = '';
      }
      window.zE('webWidget', 'updatePath', {
        title: pathname,
        url: href
      });
    }
  }
}

/**
 * Function to set the email and username for Zendesk
 */
const setZendeskFormFields = () => {
  if (typeof window.zE === 'function') {
    if (localStorage.getItem('Authorization')) {
      store.dispatch(AuthActions.getInfo())
        .then((result) => {
          if (result) {
            const { email, username, firstname, lastname } = result;
            window.zE('webWidget', 'prefill', {
              name: {
                value: `${firstname} ${lastname}` || username,
              },
              email: {
                value: email,
              }
            });
          }
        });
    }
  }
}

const App = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <MuiThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <PersistGate loading={null} persistor={persistor}>
            <AppRoutes />
          </PersistGate>
        </StylesProvider>
      </MuiThemeProvider>
    </ConnectedRouter>
  </Provider>
)

ReactDOM.render(App, document.getElementById('root'))
initializeReactGA();
serviceWorker.unregister()
