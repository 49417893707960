import { useState, useRef, useEffect as _useEffect } from 'react'

export const useMergeState = (initialState) => {
  const [state, setState] = useState(initialState)
  const setMergedState = (newState) => setState((prevState) => Object.assign({}, prevState, newState))
  return [state, setMergedState]
}

export const usePrevious = (value) => {
  const ref = useRef();
  _useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useEffect = (func, watchData) =>
  _useEffect(
    func,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    watchData
  )