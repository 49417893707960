import ActionTypes from './types'
import * as CoreActions from 'core/store/actions'
import * as API from 'core/api'

export const apiAttempt = () => ({ type: ActionTypes.API_ATTEMPT })
export const apiSuccess = () => ({ type: ActionTypes.API_SUCCESS })
export const apiFailed = payload => ({ type: ActionTypes.API_FAILED, payload })

export const setCurrentPlant = payload => ({ type: ActionTypes.SET_CURRENT_PLANT, payload })

export const updatePlantDetails = (payload, plantId) => async (dispatch, getState) => {
  try {
    const currentPlant = getState().welcome.currentPlant
    dispatch(apiAttempt())
    const { ok, status, data } = await API.updatePlantDetails(payload, plantId)
    if (!ok) {
      throw new Error(data.message)
    }
    data.data.communications && dispatch(setCurrentPlant({ ...currentPlant, communications: data.data.communications }))
    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    return true
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}
