import React from 'react'
import {
  Grid,
  Button,
  Icon
} from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody
} from '../../core/components'
import { get } from 'lodash'

const DeletePlaceConfirm = ({ open, deletePlace, handleDelete, handleDeleteCancel }) => (
  <FuseDialog open={open} wSM>
    <FuseDialogHeader text="Remove place"
      handleClose={handleDeleteCancel} />
    <FuseDialogBody>
      <Grid container alignItems="center" className="p-20">
        <Grid item xs={12}>
          <Grid container justify="center">
            <Icon className="text-52 mb-12 fg-red">delete</Icon>
          </Grid>
        </Grid>
        <Grid item xs={12} className="text-center">
          <label className="regular">Are you sure you want to remove&nbsp;</label>
          <label className="bold">{get(deletePlace, 'value.name')}?</label>
        </Grid>
        <Grid item xs={12}>
          <Grid container
            spacing={2}
            className="pt-40">
            <Grid item xs={6}>
              <Button className="btn btn_sm btn_light_red"
                onClick={() => handleDelete(deletePlace._id)}
                fullWidth>Yes, Remove</Button>
            </Grid>
            <Grid item xs={6}>
              <Button className="btn btn_sm btn_light_green"
                onClick={handleDeleteCancel}
                fullWidth>Cancel</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FuseDialogBody>
  </FuseDialog>
)

export default DeletePlaceConfirm
