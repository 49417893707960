import React, { useEffect, useState } from 'react'
import { Grid, TextField, Chip, Select, MenuItem } from '@material-ui/core'
import { get, find, uniqBy, pick, uniq } from 'lodash'
import { useMergeState, addGaEvent, getVariablesByType } from '../../core/helpers'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions,
  FuseIconButton,
  LoadingButton,
  InfoToolTip,
} from '../../core/components'
import 'react-quill/dist/quill.snow.css';
import { fileUploadInfo, general } from "../../core/localization";
import {
  AutoComplete,
  RichEditor
} from "../../core/components/Inputs";
import validator from 'validator'

const chipStyle = {
  fontFamily: 'IBMPlexSans-Medium',
  fontSize: 12,
  borderRadius: 15,
  height: 25,
}
const chipInvalidStyle = {
  ...chipStyle,
  backgroundColor: '#B10043',
  color: '#FFFFFF'
}

const selectStyle = {
  control: () => ({
    display: 'flex',
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid #949494',
    minHeight: '31px',
    maxHeight: '31px',
    marginTop: '0px'
  }),
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  }),
}

const PreAlertSidebar = ({
  shipment,
  onSubmit,
  plantEmailTemplates = [],
  getPlantEmailTemplates }) => {
  const [state, setState] = useMergeState({})
  const [open, setOpen] = useState(false)
  const [recipient, setRecipient] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState('')
  const [loading, setLoading] = useState(false)
  const [documentTypesOptions, setDocumentTypesOptions] = useState([])

  useEffect(() => {
    if (open) {
      if (plantEmailTemplates.length > 0) {
        getPlantEmailTemplates(true)
      } else {
        getPlantEmailTemplates()
      }
      addGaEvent('shipment-sendingPreAlert', get(shipment, '_id'))
    } else {
      setLoading(false)
      setSelectedTemplate('')
      setRecipient('')
      setState({
        recipients: {
          plants: [],
          emailAddresses: []
        },
        replayTo: '',
        subject: '',
        attachments: [],
        messageBody: ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    let documentTypes = []

    // Filling Document Types
    get(shipment, 'documents', []).forEach(item => {
      const documentUploads = get(item, 'documents', [])
      if (documentUploads.length > 0 &&
        find(documentUploads, docItem => (get(docItem, 'deleted.enabled') === false))) {
        documentTypes.push(get(item, 'type'))
      }
    })
    setDocumentTypesOptions(documentTypes)
    setState({ documentTypes })
    // End Filling Document Types

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  useEffect(() => {
    if (plantEmailTemplates.length === 1) {
      handleTemplateChange(plantEmailTemplates[0]._id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plantEmailTemplates])

  useEffect(() => {
    setState({ documentTypes: documentTypesOptions })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentTypesOptions])

  const removeFromArray = (arr, str) => {
    let index = arr.indexOf(str)
    if (index !== -1) {
      return [...arr.slice(0, index), ...arr.slice(index + 1)]
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setState({
      [name]: value
    })
  }

  const handleRecipientChange = (event) => {
    let { value } = event.target
    value = value.replace(/ +/g, ',')
    value = value.replace(/;+/g, ',')
    value = value.replace(/,+/g, ',')
    if (value[0] === ',') {
      value = value.substring(1)
    }
    setRecipient(value)

    if (value.search(',') >= 0) {
      handleRecipientAdd(event, true, value)
    }
  }

  const handleRecipientAdd = (event, force = false, recipient) => {
    event.stopPropagation()
    const appendKeyCodes = [9, 13, 32]
    const { keyCode } = event
    const { recipients } = state

    if (recipient && (force || appendKeyCodes.includes(keyCode))) {
      const recipientArray = recipient.split(',').map(email => {
        if (validator.isEmail(email)) {
          return {
            email
          }
        } else {
          return {
            email,
            invalid: true
          }
        }
      })
      setState({
        recipients: {
          ...recipients,
          emailAddresses: uniqBy([
            ...get(state, 'recipients.emailAddresses', []).map(i => ({ ...i, email: get(i, 'email', '').toLowerCase() })),
            ...recipientArray.map(i => ({ ...i, email: get(i, 'email', '').toLowerCase() }))
          ].filter(Boolean), 'email')
        }
      })
      setRecipient('')
    }
  }

  const handleTemplateChange = (_id) => {
    setSelectedTemplate(_id)
    const template = find(plantEmailTemplates, { _id })
    if (template) {
      const existingState = pick(get(template, 'value'), [
        'recipients.plants',
        'recipients.emailAddresses',
        'subject',
        'attachments',
        'replyTo',
        'messageBody'
      ])
      existingState.recipients = {
        ...get(existingState, 'recipients', {}),
        plants: [],
        emailAddresses: get(existingState, 'recipients.emailAddresses', [])
          .map(item => ({ email: item.toLowerCase() }))
      }
      setState(existingState)
    }
  }

  const handleEditorChange = (messageBody) => {
    setState({ messageBody })
  }

  const handleSubmit = () => {
    setLoading(true)
    const payload = pick(state, [
      'recipients',
      'subject',
      'replyTo',
      'messageBody'
    ])
    payload.recipients = {
      ...get(payload, 'recipients', {}),
      emailAddresses: get(payload, 'recipients.emailAddresses', [])
        .map(item => item.email)
    }
    payload.documentTypes = get(state, 'attachments', [])
    onSubmit(payload, shipment._id, () => setOpen(false))
  }

  return (
    <React.Fragment>
      <FuseIconButton
        style={{ background: '#3dcd58' }}
        className='text-uppercase text-12'
        img='assets/images/icons/mail.svg'
        onClick={setOpen}>
        <label className="bold cursor-pointer">Send Pre-Alert</label>
      </FuseIconButton>

      <FuseSideBar open={open} wSM>
        <FuseSideBarHeader
          text="Send Pre-Alert"
          handleClose={() => {
            addGaEvent('shipment-sendPreAlertCancelled', get(shipment, '_id'))
            setOpen(false)
          }}
        />
        <FuseSideBarBody>
          <Grid container className="regular text-14">
            <Grid item xs={12}>
              <Grid container>

                {/* Email Template */}
                <Grid item xs={12} className="pb-32 text-14">
                  <label className='text-16 medium'>
                    Email Template</label>
                  <AutoComplete value={selectedTemplate}
                    options={(open ? plantEmailTemplates : []).map(item => ({
                      value: get(item, '_id'),
                      label: get(item, 'value.name')
                    }))}
                    placeholder="Select email template..."
                    onChange={(templateId) => handleTemplateChange(templateId)}
                    customStyles={selectStyle}
                    showAllOptions />
                </Grid>
                {/* End of Subject */}

                {/* Subject */}
                <Grid item xs={12} className="pb-32">
                  <Grid container
                    alignItems="center"
                    spacing={1}>
                    <Grid item xs={8}>
                      <label className='text-16 medium'>
                        Subject</label>
                    </Grid>
                    <Grid item xs={4}
                      className="regular text-12 pb-4">
                      <AutoComplete value=""
                        options={getVariablesByType('subject').map(item =>
                          ({
                            value: item.name,
                            label: item.label
                          })
                        )}
                        placeholder="Append variable..."
                        onChange={(value) => {
                          const { subject } = state
                          setState({
                            subject: `${subject}${value}`
                          })
                        }}
                        customStyles={selectStyle}
                        showAllOptions />
                    </Grid>
                  </Grid>
                  <TextField placeholder="Enter subject (optional)"
                    name="subject"
                    value={get(state, 'subject', '')}
                    onChange={handleInputChange}
                    inputProps={{
                      style: {
                        fontSize: '14px',
                        padding: '7px 0px',
                        fontFamily: 'IBMPlexSans'
                      }
                    }}
                    fullWidth />
                </Grid>
                {/* End of Subject */}

                {/* Recipient Plants */}
                <Grid item xs={12} className="hidden">
                  <label className="text-16 medium">
                    Recipient Plants</label>
                  {get(state, 'recipients.plants', []).length === 0 &&
                    <label className="text-12 block mt-6 fg-orange">
                      You don't have any recipient plant selected</label>
                  }
                  {get(state, 'recipients.plants', []).length > 0 &&
                    <Grid container
                      className="mt-8"
                      spacing={1}>
                      {get(state, 'recipients.plants', [])
                        .map((item, itemIndex) => {
                          return (
                            <Grid item key={itemIndex}>
                              <InfoToolTip title={[...get(shipment, `actors.${item}`, [])
                                .map(item => [...get(item, 'communications.email')])]
                                .join(', ')
                              }>
                                <Chip label={get(general.partnerShortForms, item)}
                                  onDelete={() => {
                                    setState({
                                      recipients: {
                                        ...get(state, 'recipients', {}),
                                        plants: removeFromArray(get(state, 'recipients.plants', []), item)
                                      }
                                    })
                                  }}
                                  style={chipStyle}
                                />
                              </InfoToolTip>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  }
                </Grid>
                {/* End of Recipient Plants */}

                {/* Recipient Emails */}
                <Grid item xs={12} className="pb-32">
                  <label className='text-16 medium require-field'>
                    Recipient Emails</label>
                  <TextField placeholder="Enter additional recipients"
                    value={recipient}
                    onChange={handleRecipientChange}
                    onKeyDown={e => handleRecipientAdd(e, false, recipient)}
                    onBlur={e => handleRecipientAdd(e, true, recipient)}
                    inputProps={{
                      style: {
                        fontSize: '14px',
                        padding: '7px 0px',
                        fontFamily: 'IBMPlexSans'
                      }
                    }}
                    fullWidth />
                  {get(state, 'recipients.emailAddresses', []).length > 0 &&
                    <Grid container
                      className="my-8"
                      spacing={1}>
                      {get(state, 'recipients.emailAddresses', [])
                        .map((item, itemIndex) => {
                          return (
                            <Grid item key={itemIndex}>
                              <Chip label={item.email}
                                onDelete={() => {
                                  setState({
                                    recipients: {
                                      ...get(state, 'recipients', {}),
                                      emailAddresses: removeFromArray(get(state, 'recipients.emailAddresses', []), item)
                                    }
                                  })
                                }}
                                style={item.invalid ? chipInvalidStyle : chipStyle}
                              />
                            </Grid>
                          )
                        })
                      }
                      {find(get(state, 'recipients.emailAddresses', []), { invalid: true }) &&
                        <Grid item xs={12}
                          className="text-12 fg-red">
                          You have some invalid email addresses in recipient emails list, please remove them to proceed.</Grid>
                      }
                    </Grid>
                  }
                </Grid>
                {/* End of Recipient Emails */}

                {/* Reply To */}
                <Grid item xs={12} className="pb-32">
                  <label className='text-16 medium'>
                    Reply To Email</label>
                  <TextField placeholder="Enter reply to"
                    name="replyTo"
                    value={get(state, 'replyTo', '')}
                    onChange={handleInputChange}
                    inputProps={{
                      style: {
                        fontSize: '14px',
                        padding: '7px 0px',
                        fontFamily: 'IBMPlexSans'
                      }
                    }}
                    fullWidth
                    helperText={get(state, 'replyToInvalid', false)} />
                </Grid>
                {/* End of Replay To */}

                {/* Documents */}
                <Grid item xs={12} className="pb-32">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <label className='text-16 medium'>
                        Document Types</label>
                    </Grid>
                    <Grid item xs={6}>
                      <Select value=""
                        className="text-14"
                        onChange={e => {
                          setState({
                            attachments: uniq([...get(state, 'attachments', []), e.target.value])
                          })
                        }}
                        inputProps={{ 'aria-label': 'Without label' }}
                        displayEmpty
                        fullWidth>
                        <MenuItem value="" disabled>Select documents...</MenuItem>
                        {documentTypesOptions.map((item, itemIndex) =>
                          <MenuItem value={item} key={itemIndex}>{get(fileUploadInfo, item)}</MenuItem>
                        )}
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid container
                    className="mt-8"
                    spacing={1}>
                    {get(state, 'attachments', []).map((item, itemIndex) => {
                      return (
                        <Grid item key={itemIndex}>
                          <Chip label={get(fileUploadInfo, item)}
                            onDelete={() => {
                              setState({
                                attachments: removeFromArray(get(state, 'attachments', []), item)
                              })
                            }}
                            style={documentTypesOptions.includes(item) ? chipStyle : chipInvalidStyle}
                          />
                        </Grid>
                      )
                    })}
                    {find(get(state, 'attachments', []), item => !documentTypesOptions.includes(item)) &&
                      <Grid item xs={12}
                        className="text-12 fg-red">
                        You have some missing documents. Either upload them, or send without these documents</Grid>
                    }
                  </Grid>
                </Grid>
                {/* End of Documents */}

                {/* Message Body */}
                <Grid item xs={12} className="pb-28">
                  <label className='text-16 medium require-field'>
                    Message Body</label>
                  <Grid container className="mt-8">
                    <Grid item xs={12}>
                      <RichEditor value={get(state, 'messageBody', '')}
                        onChange={handleEditorChange}
                        placeholder="Enter your message here..." />
                    </Grid>
                  </Grid>
                </Grid>
                {/* End of Message Body */}

              </Grid>
            </Grid>
          </Grid>
        </FuseSideBarBody>

        <FuseSideBarActions>
          <LoadingButton className='btn btn_full-space'
            loading={loading}
            disabled={(get(state, 'recipients.emailAddresses', []).length === 0 ||
              find(get(state, 'recipients.emailAddresses', []), { invalid: true }) ||
              find(get(state, 'attachments', []), item => !documentTypesOptions.includes(item)) ||
              !get(state, 'messageBody'))}
            onClick={handleSubmit}>
            SEND</LoadingButton>
        </FuseSideBarActions>

      </FuseSideBar>
    </React.Fragment>
  )
}

export default PreAlertSidebar
