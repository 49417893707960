import React, { Component } from 'react'
import { Grid, Typography, Icon } from '@material-ui/core'
import { FuseSideBar, FuseSideBarBody, FuseSideBarHeader, LoadMore, FusePlaceHolder } from '../../core/components'
import { get } from 'lodash'
import { addGaEvent } from '../../core/helpers'
import Skeleton from 'react-loading-skeleton'

class ExistingShipmentListSidebar extends Component {
  render() {
    const {
      handleClose,
      open,
      onItemClick,
      shipmentsLoading,
      shipmentsLoadingMore,
      bookingSearch,
      setBookingSearch,
      showLoadMore,
      onLoadMore
    } = this.props

    return (
      <FuseSideBar open={open}>
        <FuseSideBarHeader text='Add to existing booking'
          handleClose={() => {
            addGaEvent('shipment-addToCancelled')
            handleClose()
          }} />
        <FuseSideBarBody>
          <Grid container>
            <Grid item xs={12}>
              <Grid container className='border-dashed border-b-1 border-gray mb-20'>
                <Grid item xs={12}>
                  <Grid container justify='space-between' className='py-8'>
                    <Grid item className='fg-gray text-12 medium'>
                      You have selected
                    </Grid>
                    <Grid item className='fg-gray text-12 medium'>
                      {get(this.props, 'outbounds', []).length} Outbound(s)
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center"
                className="p-8 border-1 border-gray rounded mb-20">
                <Grid item xs={10}>
                  <input type='text'
                    placeholder='Search bookings...'
                    className='input w-full text-14'
                    value={bookingSearch}
                    onChange={setBookingSearch}
                  />
                </Grid>
                <Grid item xs={2}>
                  {bookingSearch && (
                    <Grid container
                      alignItems="center"
                      justify="center">
                      <Icon onClick={() => setBookingSearch({ target: { value: '' } })}
                        className="text-14 cursor-pointer">close</Icon>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {shipmentsLoading &&
                <Grid container spacing={2}>
                  {[1, 2, 3].map(i => {
                    return <Grid item xs={12} key={i}><Skeleton height={90} /></Grid>
                  })}
                </Grid>
              }

              {(!shipmentsLoading && get(this.props, 'shipments', []).length > 0) &&
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item className='semi-bold fg-gray text-14 pb-12'>
                        Bookings</Grid>
                      {get(this.props, 'shipments', []).map((shipment, i) => (
                        <Grid container
                          key={`wrapper_${i}`}
                          className='p-12 border-solid bg-gray mb-20 border-1 border-gray cursor-pointer'
                          onClick={() => onItemClick(shipment._id)}>
                          <Grid item xs={12} className='pb-8'>
                            <Typography noWrap
                              className="text-16 bold uppercase"
                              title={get(shipment, 'name')}>
                              {get(shipment, 'name')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container className='pb-6' alignItems="center" spacing={1}>
                              <Grid item className='fg-gray text-14 semi-bold'>
                                {get(shipment, 'type')}
                              </Grid>
                              <Grid item className='fg-gray text-14 semi-bold'>&bull;</Grid>
                              <Grid item className='fg-gray text-14 semi-bold uppercase'>
                                {get(shipment, 'transportMethod')}
                              </Grid>
                            </Grid>
                            <Grid container justify='space-between'>
                              <Grid item className='fg-gray text-12 semi-bold'>
                                Total volume:{' '}
                                {get(shipment, 'totalVolume').toFixed(2) + ' ' + get(shipment, 'volumeUnit')}
                              </Grid>
                              <Grid item className='fg-gray text-12 semi-bold'>
                                Total wight: {get(shipment, 'totalWeight').toFixed(2) + ' ' + get(shipment, 'weightUnit')}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>

                  {showLoadMore &&
                    <Grid item xs={12}>
                      <Grid container justify="center">
                        <LoadMore onClick={onLoadMore}
                          loading={shipmentsLoadingMore} />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              }

              {!shipmentsLoading && get(this.props, 'shipments', []).length === 0 && (
                <FusePlaceHolder className="flex flex-col p-16"
                  icon='view_day'
                  title='No bookings found!'
                  description='Sorry, no bookings found!' />
              )}
            </Grid>
          </Grid>
        </FuseSideBarBody>
      </FuseSideBar>
    )
  }
}

export default ExistingShipmentListSidebar
