import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import connect from 'react-redux/es/connect/connect';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import SubToolBarLayout from '../../core/containers/Layout/SubToolBarLayout'
import * as Actions from '../store/actions';
import VesselInfoSidebar from '../components/VesselInfoSidebar';
import { Grid } from '@material-ui/core'
import AutoComplete from '../../core/components/Inputs/AutoComplete'

const Cesium = window.Cesium;

const styles = () => ({
  background: {
    backgroundImage: 'url("assets/images/globe/stars_background_blue_low.png")',
  },
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  expandPanelRoot: {
    width: '100%',
  },
});

class MapViewPage extends React.Component {
  state = {
    viewMode: 'map',
    markers: [],
    viewer: null,
    scene: null,
    selectedMarkerObj: {},
    vesselInfoSidebarOpen: false,
    vessle: {},
    shipments: [],
    clickedVessel: {}
  }

  loadMap = async () => {
    const { viewer } = this.state;
    if (viewer === null) {
      var west = 90.0;
      var south = -20.0;
      var east = 110.0;
      var north = 50.0;

      var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);

      Cesium.Camera.DEFAULT_VIEW_FACTOR = 0;
      Cesium.Camera.DEFAULT_VIEW_RECTANGLE = rectangle;

      // NOTE: Viewer constructed after default view is set.
      const cesiumViewer = new Cesium.Viewer('cesiumContainer', {
        imageryProvider: Cesium.createWorldImagery({
          style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS
        }),
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        baseLayerPicker: false,
        fullscreenElement: false,
        shadows: true
      });

      const scene = cesiumViewer.scene;
      await this.setState({
        viewer: cesiumViewer,
        scene
      });
      this.formMarkers();
    } else {
      this.formMarkers();
    }
  }

  formMarkers = () => {
    const { coordinates } = this.props;
    if (coordinates && coordinates.length > 0) {
      const { markers } = this.state;
      if (markers.length === 0) {
        var preparedMarkers = [];
        coordinates.forEach(coordinate => {
          if (_.get(coordinate, 'LON') && _.get(coordinate, 'LAT')) {
            preparedMarkers.push(coordinate)
          }
        })
        if (preparedMarkers.length > 0) {
          this.setState({
            markers: preparedMarkers
          })
          this.addMultiplePoints(this)
        }
      }
    }
  }

  addMultiplePoints(currentThis) {
    const { coordinates } = currentThis.props;
    const { viewer, scene } = currentThis.state;
    coordinates.forEach(coordinate => {
      var iconImage = '/assets/images/map/markers/ship-blue.png';

      if (coordinate.shipmentCount > 0)
        iconImage = '/assets/images/map/markers/ship-yellow.png';

      if (coordinate.LON && coordinate.LAT) {
        viewer.entities.add({
          id: coordinate.IMO,
          position: Cesium.Cartesian3.fromDegrees(
            coordinate.LON,
            coordinate.LAT
          ),
          billboard: {
            image: iconImage,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM
          },
          name: coordinate.SHIPNAME,
          label: {
            show: false,
            showBackground: true,
            horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
            verticalOrigin: Cesium.VerticalOrigin.TOP,
            pixelOffset: new Cesium.Cartesian2(15, 0),
            text: coordinate.IMO
          }
        });
      }
    })

    var nameOverlay = document.createElement('div');
    viewer.container.appendChild(nameOverlay);
    nameOverlay.className = 'backdrop';
    nameOverlay.style.display = 'none';
    nameOverlay.style.position = 'absolute';
    nameOverlay.style.bottom = '0';
    nameOverlay.style.left = '10px';
    nameOverlay.style['pointer-events'] = 'auto';
    nameOverlay.style.padding = '3px 8px';
    nameOverlay.style.cursor = 'pointer';
    nameOverlay.style.backgroundColor = '#3DCD58';
    nameOverlay.style.color = '#FFFFFF';
    nameOverlay.style.borderRadius = '3px';
    nameOverlay.style.fontFamily = 'IBMPlexSans';
    nameOverlay.style.fontSize = '10px';

    var handler = new Cesium.ScreenSpaceEventHandler(scene.canvas);
    handler.setInputAction(function (movement) {


      var cartesian = viewer.camera.pickEllipsoid(movement.position, scene.globe.ellipsoid);
      if (cartesian) {
        console.log("inside globe")

      } else {
        currentThis.setState({ selectedMarkerObj: {} })
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

    viewer.screenSpaceEventHandler.setInputAction(function onMouseMove(movement) {
      // Pick a new feature
      var pickedFeature = viewer.scene.pick(movement.endPosition);
      if (!Cesium.defined(pickedFeature)) {
        nameOverlay.style.display = 'none';
        return;
      }

      // A feature was picked, so show it's overlay content
      nameOverlay.style.display = 'block';
      nameOverlay.style.bottom = viewer.canvas.clientHeight - movement.endPosition.y + 'px';
      nameOverlay.style.left = movement.endPosition.x + 'px';
      var name = pickedFeature.id.name;
      if (!Cesium.defined(name)) {
        name = pickedFeature.id._id;
      }
      nameOverlay.textContent = name;

    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    viewer.selectedEntityChanged.addEventListener(function (clickedVessel) {
      if (clickedVessel) {
        currentThis.vesselSidebarOpen(clickedVessel);

        // @TODO Fix it
        viewer.camera.flyTo({
          destination: clickedVessel.position.getValue()
        });
      }
    });
  }

  async componentDidMount() {
    const { getCoordinates, getCesiumToken } = this.props;
    await getCesiumToken();
    const { cesiumToken } = this.props;
    Cesium.Ion.defaultAccessToken = cesiumToken;
    getCoordinates();
    this.loadMap();
  }

  componentDidUpdate(oldProps) {
    const oldCoordinates = oldProps.coordinates;
    const oldVessel = oldProps.vessel;
    const oldShipments = oldProps.shipments;

    const { coordinates, vessel, shipments } = this.props;


    if (oldCoordinates !== coordinates) {
      this.loadMap();
    }

    if (oldVessel !== vessel) {
      this.setState({
        vessel,
      })
    }

    if (oldShipments !== shipments) {
      this.setState({
        shipments,
      })
    }
  }

  vesselSidebarOpen = async (clickedVessel) => {
    const { getVesselInfo } = this.props;
    getVesselInfo(clickedVessel._id);
    this.setState({
      vesselInfoSidebarOpen: true,
      clickedVessel
    })
  }

  vesselSidebarClose = () => {
    this.setState({
      vesselInfoSidebarOpen: false,
      vesselSearch: ''
    })
    const { viewer } = this.state;
    var west = 90.0;
    var south = -20.0;
    var east = 110.0;
    var north = 50.0;

    var rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
    viewer.camera.flyTo({
      destination: rectangle
    });
  }

  onSearchVesselSelect = (value) => {
    const { viewer } = this.state

    if (viewer && viewer.entities) {
      const selectedVessel = viewer.entities.getById(value)
      console.log(selectedVessel)

      this.setState({ vesselSearch: value })
      if (selectedVessel) {
        this.vesselSidebarOpen(selectedVessel);
        viewer.camera.flyTo({
          destination: selectedVessel.position.getValue()
        });
      }
    }
  }

  render() {
    const { viewMode, vesselSearch } = this.state
    const { coordinates } = this.props

    return (
      <>
        <SubToolBarLayout>
          {coordinates && coordinates.length > 0 &&
            <Grid container justify="flex-end">
              <Grid item xs md={6}>
                <AutoComplete value={vesselSearch}
                  placeholder="Search your vessel..."
                  options={coordinates.map(item => ({
                    value: item.IMO,
                    label: item.SHIPNAME
                  }))}
                  onChange={this.onSearchVesselSelect} />
              </Grid>
            </Grid>
          }
        </SubToolBarLayout>
        <div className="map-view">
          <div className="flex flex-col">
            <div className="h-full w-full">
              {/* START Map View */}
              {
                viewMode === 'map' &&
                <div className="h-full w-full relative">
                  <div id="cesiumContainer"></div>
                </div>
              }
              {/* END Map View */}
            </div>
          </div>
        </div>
        <VesselInfoSidebar open={this.state.vesselInfoSidebarOpen}
          vessel={this.state.vessel}
          shipments={this.state.shipments}
          handleClose={this.vesselSidebarClose}></VesselInfoSidebar>
      </>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getCoordinates: Actions.getCoordinates,
    getCesiumToken: Actions.getCesiumToken,
    getVesselInfo: (imo) => Actions.getVesselInfo(imo)
  }, dispatch);
}

function mapStateToProps({ mapView }) {
  return {
    coordinates: mapView.coordinates,
    vessel: mapView.vessel,
    shipments: mapView.shipments,
    cesiumToken: mapView.cesiumToken
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(MapViewPage)));

