import ActionTypes from './types'

const initialState = {
  shipments: {}
}

const overviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_FILTER_SHIPMENTS: {
      return {
        shipments: {
          ...state.shipments,
          [action.filterId]: action.payload,
        }
      }
    }
    default:
      return state
  }
}

export default overviewReducer
