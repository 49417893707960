import React from 'react'
import classNames from 'classnames'

const SideBarBody = ({ children, className, ...other }) => (
  <div className={classNames('side-bar-body', { [className]: !!className })} {...other}>
    {children}
  </div>
)

export default SideBarBody
