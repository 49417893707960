import React, { Component } from 'react'
import {
  withStyles,
  Grid,
  Input,
  InputLabel,
  Icon,
  Slide,
  Fade,
  CircularProgress,
  Select,
  MenuItem
} from '@material-ui/core'
import { LoadingButton, InfoToolTip } from '../../core/components'
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout'
import { connect } from 'react-redux'
import DateTime from 'react-datetime'
import * as TrackerActions from '../store/actions'
import { get, pick } from 'lodash'
import DataTable from 'react-data-table-component'
import { formatTimestamp } from '../../core/helpers'
import history from '../../history'
import queryString from 'query-string'
import scacCodes from '../../core/helpers/json/scacCodes.json'

const styles = {
  input: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14,
  },
  select: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14,
  },
  datetimePicker: {
    height: 30,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    '& input': {
      fontFamily: 'IBMPlexSans',
      fontSize: 14,
      margin: 0,
      display: 'block',
      padding: '6px 0px 7px',
      width: '100%',
      background: 'none',
      boxSizing: 'content-box'
    }
  }
}

const customStyles = {
  header: {
    style: {
      backgroundColor: '#F2F2F2',
      fontFamily: 'IBMPlexSans-SemiBold'
    }
  },
  headRow: {
    style: {
      fontFamily: 'IBMPlexSans-Medium',
      borderBottomWidth: '2px'
    }
  },
  pagination: {
    style: {
      fontFamily: 'IBMPlexSans',
      padding: '10px 0px'
    },
    pageButtonsStyle: {
      margin: '0px 5px',
    }
  }
}

class TrackerPage extends Component {
  state = {
    infoType: 'equipmentNo',
    searching: false,
    isValid: false,
    equipmentNo: undefined,
    mblNo: undefined,
    scac: get(scacCodes, '[0].value'),
    lowerBoundDate: undefined,
    upperBoundDate: undefined,
  }

  eventColumns = [
    {
      name: 'Event Code',
      selector: (row) => get(row, 'code'),
      sortable: true,
    },
    {
      name: 'Event Name',
      selector: (row) => <InfoToolTip title={get(row, 'name')}>
        <label>{get(row, 'name')}</label>
      </InfoToolTip>,
      sortable: true,
    },
    {
      name: 'Source',
      selector: (row) => get(row, 'source.type'),
      sortable: true,
    },
    {
      name: 'Occurence Time',
      selector: (row) => formatTimestamp(get(row, 'occurenceAt', ''), 'date-time'),
      sortable: true,
    },
    {
      name: 'Reported Time',
      selector: (row) => formatTimestamp(get(row, 'reportedAt', ''), 'date-time'),
      sortable: true,
    },
    {
      name: 'Address',
      selector: (row) => this.getLocationString(get(row, 'location', {})),
      sortable: true,
    },
  ]

  pointColumns = [
    {
      name: 'Name',
      selector: (row) => get(row, 'point.name'),
      sortable: true,
    },
    {
      name: 'Country',
      selector: (row) => get(row, 'point.country'),
      sortable: true,
    },
    {
      name: 'Latitude',
      selector: (row) => parseFloat(get(row, 'point.lat') || get(row, 'point.latitude')),
    },
    {
      name: 'Longitude',
      selector: (row) => parseFloat(get(row, 'point.lng') || get(row, 'point.longitude')),
    }
  ]

  tableTitles = {
    estimated: {
      title: 'Estimated Events',
      columns: this.eventColumns,
    },
    actual: {
      title: 'Actual Events',
      columns: this.eventColumns,
    },
    landmark: {
      title: 'Landmark Points',
      columns: this.pointColumns,
    }
  }

  getLocationString = (location) => {
    let locationString = []

    if (get(location, 'name'))
      locationString.push(get(location, 'name'))
    if (get(location, 'address.city'))
      locationString.push(get(location, 'address.city'))
    if (get(location, 'address.country'))
      locationString.push(get(location, 'address.country'))

    return locationString.join(', ')
  }

  checkValidation = () => {
    const { scac, infoType } = this.state
    let isValid = true
    if (!scac) {
      isValid = false
    }
    if (scac !== undefined) {
      if (scac.length < 1 || scac.length > 10) {
        isValid = false
      }
    }
    if (!get(this.state, infoType)) {
      isValid = false
    }

    this.setState({ isValid })
  }

  handlerInputChange = (event) => {
    const { name, value } = event.target

    this.setState({
      [name]: value
    }, this.checkValidation)
  }

  handleDateChange = (date, name) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    this.setState({
      [name]: date
    }, this.checkValidation)
  }

  handleSearch = async () => {
    const { infoType } = this.state
    const params = {
      ...pick(this.state, [
        infoType,
        'autoload',
        'shipmentId',
        'scac',
        'lowerBoundDate',
        'upperBoundDate'
      ])
    }

    await history.push(`/tracker?${queryString.stringify(params)}`)
    this.startSearching()
  }

  startSearching = () => {
    const { getShipmentTrack, location } = this.props
    const parseQuery = queryString.parse(get(location, 'search'))

    this.setState({ searching: { status: 'loading' } })
    getShipmentTrack(parseQuery,
      () => {
        const { trackData } = this.props
        if (Object.keys(trackData).length === 0) {
          this.setState({ searching: { status: 'failed' } })
        } else {
          this.setState({ searching: { status: 'done' } })
        }
      },
      () => {
        this.setState({ searching: { status: 'failed' } })
      }
    )
  }

  componentDidMount() {
    const { location } = this.props
    const parseQuery = queryString.parse(get(location, 'search'))
    let infoType = 'equipmentNo'
    if (get(parseQuery, 'mblNo')) {
      infoType = 'mblNo'
    }
    if (get(parseQuery, 'equipmentNo')) {
      infoType = 'equipmentNo'
    }

    this.setState({
      ...parseQuery,
      infoType
    }, () => {
      this.checkValidation()
      if (get(parseQuery, 'autoload')) {
        this.handleSearch()
      }
    })
  }

  render() {
    const { classes, trackData } = this.props
    const { searching, isValid } = this.state

    return (
      <Grid container className="h-full overflow-auto">
        <SubToolBarLayout text='Shipment Tracker' />
        <Grid item xs={12}>
          <Grid container
            alignItems={searching ? 'flex-start' : 'center'}
            className={'p-20' + (!searching ? ' h-full overflow-auto' : '')}>
            {!searching &&
              <Slide direction="down"
                in={!searching}
                mountOnEnter
                unmountOnExit>
                <Grid item xs={12}
                  className="bg-white rounded-2 mt-auto mb-48 shadow">
                  <Grid container className="px-28 py-20">
                    <Grid item xs={12} className="text-center text-20 uppercase semi-bold">
                      You can Search your Shipments by following information</Grid>
                  </Grid>
                </Grid>
              </Slide>
            }

            <Slide direction="up"
              in={true}
              mountOnEnter
              unmountOnExit>
              <Grid item xs={12}
                className="bg-white rounded-2 mb-auto shadow">
                <Grid container
                  alignItems="center"
                  justify="center"
                  spacing={3}
                  className="p-28">
                  <Grid item xs={12} sm={12} md={10}>
                    <Grid container
                      alignItems="center"
                      spacing={3}>
                      <Grid item xs={12} sm={12} md>
                        <InputLabel className="medium text-12 mb-4 inline-block">
                          Info Type</InputLabel>
                        <Select name="infoType"
                          value={get(this.state, 'infoType', '')}
                          onChange={this.handlerInputChange}
                          className={classes.select}
                          placeholder="Select info type"
                          fullWidth>
                          <MenuItem value="equipmentNo">Equipment No</MenuItem>
                          <MenuItem value="mblNo">MBL No</MenuItem>
                        </Select>
                      </Grid>

                      {get(this.state, 'infoType') === 'equipmentNo' &&
                        <Grid item xs={12} sm={12} md>
                          <InputLabel className="medium text-12 mb-4 inline-block require-field">
                            Equipment No</InputLabel>
                          <Input name="equipmentNo"
                            value={get(this.state, 'equipmentNo', '')}
                            className={classes.input}
                            placeholder="Enter Equipment no"
                            onChange={this.handlerInputChange}
                            fullWidth />
                        </Grid>
                      }

                      {get(this.state, 'infoType') === 'mblNo' &&
                        <Grid item xs={12} sm={12} md>
                          <InputLabel className="medium text-12 mb-4 inline-block require-field">
                            MBL No</InputLabel>
                          <Input name="mblNo"
                            value={get(this.state, 'mblNo', '')}
                            className={classes.input}
                            placeholder="Enter MBL no"
                            onChange={this.handlerInputChange}
                            fullWidth />
                        </Grid>
                      }

                      <Grid item xs={12} sm={12} md>
                        <InputLabel className="medium text-12 mb-4 inline-block require-field">
                          SCAC/Line</InputLabel>
                        <Select name="scac"
                          value={get(this.state, 'scac', '')}
                          onChange={this.handlerInputChange}
                          placeholder="Select SCAC/Line"
                          MenuProps={{
                            style: {
                              maxHeight: 300
                            }
                          }}
                          className={classes.select}
                          fullWidth>
                          {scacCodes.map((scac, scacIndex) => {
                            return (
                              <MenuItem key={scacIndex}
                                value={scac.value}>
                                {scac.text}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </Grid>

                      <Grid item xs={12} sm={12} md>
                        <InputLabel className="medium text-12 mb-4 inline-block">
                          Time Lower Bound</InputLabel>
                        <DateTime name="lowerBoundDate"
                          inputProps={{
                            placeholder: 'Lower Bound date'
                          }}
                          className={classes.datetimePicker}
                          dateFormat="DD MMM YYYY"
                          timeFormat={false}
                          onChange={(date) => this.handleDateChange(date, 'lowerBoundDate')}
                          value={get(this.state, 'lowerBoundDate') && new Date(get(this.state, 'lowerBoundDate'))}
                          fullWidth />
                      </Grid>

                      <Grid item xs={12} sm={12} md>
                        <InputLabel className="medium text-12 mb-4 inline-block">
                          Time Upper OutBound</InputLabel>
                        <DateTime name="upperBoundDate"
                          inputProps={{
                            placeholder: 'Upper OutBound date'
                          }}
                          className={classes.datetimePicker}
                          dateFormat="DD MMM YYYY"
                          timeFormat={false}
                          onChange={(date) => this.handleDateChange(date, 'upperBoundDate')}
                          value={get(this.state, 'upperBoundDate') && new Date(get(this.state, 'upperBoundDate'))}
                          fullWidth />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <Grid container
                      alignItems="center"
                      spacing={3}>
                      <Grid item xs={12}>
                        <LoadingButton className="btn btn_sm w-full"
                          loading={get(searching, 'status') === 'loading'}
                          disabled={!isValid}
                          onClick={this.handleSearch}>
                          <Grid container
                            alignItems="center"
                            justify="center"
                            className="leading-none fg-white bold">
                            <Icon className="text-20">search</Icon>
                            <Grid item className="text-14 ml-8">Search</Grid>
                          </Grid>
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Slide>

            <Slide direction="up"
              in={searching !== false}
              mountOnEnter
              unmountOnExit>
              <Grid item xs={12}
                className="bg-white rounded-2 mt-28 mb-auto overflow-hidden shadow">

                {/* START: Searching Loading */}
                {get(searching, 'status') === 'loading' &&
                  <Fade in={get(searching, 'status') === 'loading'}
                    mountOnEnter
                    unmountOnExit>
                    <Grid container className="p-40"
                      alignItems="center"
                      justify="center">
                      <Grid item>
                        <CircularProgress />
                      </Grid>
                    </Grid>
                  </Fade>
                }
                {/* END: Searching Loading */}

                {/* START: Searching Failed */}
                {get(searching, 'status') === 'failed' &&
                  <Fade in={get(searching, 'status') === 'failed'}
                    mountOnEnter
                    unmountOnExit>
                    <Grid container className="p-40"
                      alignItems="center"
                      justify="center">
                      <Grid item className="text-16 fg-dark-gray">
                        <Grid container
                          alignItems="center"
                          justify="center"
                          spacing={2}>
                          <Icon>warning</Icon>
                          <Grid item>Tracking data for requested information not found, Try changing search information</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Fade>
                }
                {/* END: Searching Failed */}

                {/* START: Searching Done */}
                {get(searching, 'status') === 'done' &&
                  <Slide direction="down" in={get(searching, 'status') === 'done'}
                    mountOnEnter
                    unmountOnExit>
                    <Grid container className="p-28">
                      {Object.keys(trackData).map((type, typeIndex) => {
                        const data = trackData[type]
                        return (
                          <Grid item key={typeIndex} xs={12}
                            className={typeIndex > 0 ? 'pt-20' : ''}>
                            <label className="text-16 mb-16 semi-bold">

                            </label>
                            <DataTable title={get(this.tableTitles, `${type}.title`, 'MISC Data')}
                              columns={get(this.tableTitles, `${type}.columns`, [])}
                              data={data}
                              customStyles={customStyles}
                              pagination
                            />
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Slide>
                }
                {/* END: Searching Done */}

              </Grid>
            </Slide>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = ({
  trackerReducer: { trackData },
  core: { systemSettings }
}) => ({
  trackData,
})

const mapDispatchToProps = dispatch => ({
  getShipmentTrack: (...args) => dispatch(TrackerActions.getShipmentTrack(...args)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TrackerPage))
