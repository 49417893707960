import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Button,
  withStyles,
} from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useRouteMatch } from "react-router-dom";
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout';
import { connect } from 'react-redux';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import history from '../../history'

const { tableau } = window;
const styles = {
  actionButton: {
    minWidth: 143
  },
  formControl: {
    margin: 1,
    minWidth: 120,
  },
};
let tempVizObj = null;

const TableauDashboard = (props) => {
  let match = useRouteMatch();
  const tableauRef = useRef(null);
  const [stateTableauList, setStateTableauList] = useState([]);
  const [tableauCount] = useState(0);
  const [viz, setViz] = useState(null);
  const [workbook, setWorkbook] = useState(null);
  const [activeSheet, setActiveSheet] = useState(null);
  const [currentActiveSheet, setCurrentActiveSheet] = useState('');
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { classes, sheets } = props;

  /**
   * Initialize Tableau Viz
   */
  const initViz = () => {
    setLoading(true);
    let sheetName = _.get(match.params, 'sheet', '');
    let url = '';
    if (sheets.length && sheetName) {
      setCurrentActiveSheet(sheetName);
      let currentSheet = sheets.find((sheet) => {
        return sheet.name.toLowerCase() === sheetName.toLowerCase()
      });
      if (_.get(currentSheet, 'url', '')) {
        url = currentSheet.url;
      }
    }

    let vizDiv = tableauRef.current;
    let vizURL = url ? url : "https://analytics.trames.io/views/prod-Analytics-v1_0/TransportOverview-VolumeAnalysis";
    const options = {
      hideTabs: true,
      hideToolbar: true,
      onFirstInteractive: function () {
        if (tempVizObj) {
          let workbook = tempVizObj.getWorkbook();
          tempVizObj.getWorkbook().activateSheetAsync(sheetName);
          setWorkbook(workbook);
          querySheets();
          setActiveSheet(sheetName);
          setCurrentActiveSheet(sheetName);
        }

      }
    };
    if (viz) {
      viz.dispose();
      setViz(null);
    }
    tempVizObj = new tableau.Viz(vizDiv, vizURL, options);
    setViz(tempVizObj);
  };

  useEffect(initViz, [tableauCount]);

  /**
   *  Function to set the active sheet
   * @param sheetName string
   */
  const setView = (sheetName) => {
    if (sheetName) {
      let workbook = viz.getWorkbook();
      workbook.activateSheetAsync(sheetName);
    } else if (activeSheet) {
      let workbook = viz.getWorkbook();
      workbook.activateSheetAsync(activeSheet);
    }
    setCurrentActiveSheet(sheetName);
  }


  /**
   * Export as pdf
   */
  const exportAsPDF = () => {
    if (viz) {
      viz.showExportPDFDialog();
    }
  };

  /**
   * Get All related sheets
   */
  const querySheets = async () => {
    var sheets = null;
    if (workbook) {
      sheets = workbook.getPublishedSheetsInfo();
    } else if (tempVizObj) {
      sheets = tempVizObj.getWorkbook().getPublishedSheetsInfo();
    }
    if (sheets) {
      let tempSheetUrl = sheets.map((sheet) => {
        return {
          'url': sheet.getUrl(),
          'name': sheet.getName()
        }
      });
      setStateTableauList(tempSheetUrl);
    }
    setLoading(false);
  }

  /**
   * Set active sheet on change of Menu
   * @param sheetName
   */
  const handleChange = (sheetName) => {
    setView(sheetName);
  }

  /**
   * Menu open
   * @param event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Menu close
   */
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Grid container className="h-full overflow-auto">
        <SubToolBarLayout text='Analytics' section={'analytics'} />
        <Grid item xs={12}>
          <Grid container alignItems={'center'} className={'p-20'}>
            <Grid item xs={12} className="bg-white rounded-2 mt-auto mb-48 shadow">
              <Grid container className="p-20" justify="space-between" spacing={2}>
                <Grid item className="text-20 uppercase semi-bold">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Button variant="contained"
                      color="primary"
                      className={classes.actionButton + ' p-2 m-4'}
                      onClick={() => history.replace('/analytics')}>back</Button>
                  </FormControl>
                </Grid>
                {stateTableauList.length > 0 && (
                  <Grid item className="text-20 uppercase semi-bold">
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Button variant="contained"
                        color="secondary"
                        className={classes.actionButton + ' p-2 m-4'}
                        endIcon={<PictureAsPdfIcon />}
                        onClick={exportAsPDF}>Export Pdf</Button>
                    </FormControl>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Button variant="contained"
                        color="secondary"
                        endIcon={<ListAltIcon />}
                        className={classes.actionButton + ' p-2 m-4'}
                        onClick={handleClick}>Sheets</Button>
                      <Menu id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}>
                        {stateTableauList.map((sheet, index) => (
                          <MenuItem key={`${index}-menu`}
                            onClick={() => {
                              handleClose();
                              handleChange(sheet.name)
                            }}
                            value={sheet.name}>
                            {sheet.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
              <Grid container className="px-28 py-20 hidden">
                <Grid item xs={12} className="text-center text-20 uppercase semi-bold">
                  <Typography color='inherit' className='bold'>
                    {currentActiveSheet}
                  </Typography>
                </Grid>
              </Grid>
              {
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12}>
                    {loading &&
                      <Grid container spacing={2} className="p-20">
                        <Grid item xs={12}><Skeleton height={24} width={420} /></Grid>
                        <Grid item xs={12}><Skeleton height={80} /></Grid>
                        <Grid item xs={12}><Skeleton height={80} /></Grid>
                        <Grid item xs={12}><Skeleton height={80} /></Grid>
                        <Grid item xs={12}><Skeleton height={30} /></Grid>
                        <Grid item xs={12}><Skeleton height={30} /></Grid>
                        <Grid item xs={12}><Skeleton height={180} /></Grid>
                        <Grid item xs={12}><Skeleton height={180} /></Grid>
                      </Grid>
                    }
                    <Grid container className="p-16" ref={tableauRef} />
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = ({ analytics }) => {
  return {
    sheets: analytics.allSheets
  }
}

export default withStyles(styles)(connect(mapStateToProps)(TableauDashboard));
