import React, { Component } from 'react'
import { Grid, Button } from '@material-ui/core'
import { FuseSideBarActions, FuseSideBar, FuseSideBarBody, FuseSideBarHeader } from 'core/components'
import { isEmpty } from 'lodash'
import papaParse from 'papaparse'
import fileDownload from 'js-file-download'

class ShowLogDialog extends Component {
  interval = null

  componentDidMount() {
    this.interval = setInterval(() => {
      this.checkUploadStatus()
    }, 2000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleUpdateDocument = document => {
    this.setState(document)
  }

  uploadFile = () => {
    const { csv } = this.state
    const data = new FormData()
    data.append('csv', csv)
  }

  handleResponse = () => {
    this.props.handleClose()
    const { data } = this.props
    this.props.openErrorSide(data)
  }

  getCount = (data, type) => {
    let countSuccess = 0
    let countError = 0

    Object.keys(data).forEach(batchId => {
      data[batchId].data.forEach(info => {
        if (info.status === 'saved') {
          countSuccess = countSuccess + 1
        } else {
          countError = countError + 1
        }
      })
    })
    if (type === 'saved') {
      return countSuccess
    } else {
      return countError
    }
  }

  exportToCsv(filename, rows) {
    const csvFile = papaParse.unparse(rows)

    var blob = new Blob([csvFile], {
      type: 'text/csv;charset=utf-8;'
    })
    fileDownload(blob, filename)
  }

  download = () => {
    let data = []
    Object.keys(this.props.data.outbounds).forEach(key => {
      const item = this.props.data.outbounds[key]
      if (item && Array.isArray(item.data)) data = data.concat(item.data)
    })
    this.exportToCsv('log.csv', data)
  }

  checkUploadStatus = () => {
    const { data, getBatchStatus } = this.props
    const { outbounds } = data

    if (outbounds) {
      Object.keys(outbounds).forEach(batchId => {
        if (outbounds[batchId].status !== 'COMPLETED') {
          getBatchStatus(batchId, 'outbounds')
        }
      })
    }
  }

  getProgress = data => {
    const totalUploaded = Object.keys(data).reduce((acc, cur) => (acc += data[cur].data.length), 0)
    const totalAttempt = Object.keys(data).reduce((acc, cur) => (acc += data[cur].totalCount), 0)
    const completed = Object.keys(data).every(batchId => data[batchId].status === 'COMPLETED')
    const progress = Math.floor((totalUploaded / totalAttempt) * 100)

    return {
      value: progress ? progress.toString() : '0',
      completed
    }
  }

  render() {
    const { handleClose, open, data } = this.props
    const displayData = Object.keys(data)
      .map(element => {
        const uploadInfo = data[element]

        if (isEmpty(uploadInfo)) {
          return null
        }

        return {
          element,
          progress: this.getProgress(uploadInfo),
          savedCount: this.getCount(uploadInfo, 'saved'),
          errorCount: this.getCount(uploadInfo, 'error')
        }
      })
      .filter(v => !!v)

    return (
      <FuseSideBar open={open}>
        <FuseSideBarHeader text='Upload Status' handleClose={handleClose} />
        <FuseSideBarBody>
          <div className='py-4 px-20'>
            {displayData.map((item, index) => (
              <Grid container direction='row' key={index} className={'block py-20 documents'}>
                <div className='flex flex-row justify-between items-center'>
                  <div className='documents__title'>
                    <span className='uppercase'>{item.element}</span> file
                  </div>
                  {!item.progress.completed && (
                    <span className='documents__download'>In Progress ({item.progress.value}%)</span>
                  )}
                  {item.progress.completed && (
                    <span className='documents__download' id={item.element} onClick={this.download}>
                      Download log file
                    </span>
                  )}
                </div>
                <div className='flex flex-row justify-between items-center'>
                  <div className='success document__status'>Success</div>
                  <div className='documents__log document__status'>{item.savedCount} records saved successfully</div>
                </div>
                <div className='flex flex-row justify-between items-center'>
                  <div className='error document__status document__status_last'>Errors</div>
                  <div className='documents__log document__status'>{item.errorCount} records had error</div>
                </div>
              </Grid>
            ))}
          </div>
        </FuseSideBarBody>
        <FuseSideBarActions>
          <Button className='btn btn_full-space' onClick={this.download}>
            Download all logs
          </Button>
        </FuseSideBarActions>
      </FuseSideBar>
    )
  }
}

export default ShowLogDialog
