import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { set, get, omit } from 'lodash'
import { useMergeState, addGaEvent } from "../../core/helpers";
import { FusePlaceHolder } from "../../core/components";
import DateTime from 'react-datetime';

const INITIAL_STATE = {
  events: []
}

const ShipmentEvents = (props) => {
  const [state, setState] = useMergeState(INITIAL_STATE)

  useEffect(() => {
    if (!get(props, 'eventWriteList[0].invalid')) {
      setState({
        events: get(props, 'eventWriteList', []).map(event => {
          const eventData = {
            code: event.code,
            type: event.type,
            name: event.name,
            level: event.level,
            primaryInputs: event.primaryInputs.map(input => {
              input.oldValue = input.value
              return input
            })
          }
          return eventData
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.eventWriteList])

  const saveEvent = (eventIndex, inputIndex) => {
    const { shipment, addShipmentEvents } = props
    const { events } = state
    const currentEventInput = get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {})
    const payload = {
      primaryInputs: [
        omit(currentEventInput, ['oldValue', 'editing'])
      ]
    }

    addShipmentEvents(
      shipment._id,
      get(events[eventIndex], 'code'),
      get(events[eventIndex], 'type'),
      payload,
      () => cancelSave(eventIndex, inputIndex)
    )
  }

  const onDateChange = (date, eventIndex, inputIndex) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    const events = get(state, 'events', [])

    addGaEvent('shipment-addingEvent', get(props, 'shipment._id'), get(events, `[${eventIndex}].code`))
    set(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {
      ...get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {}),
      value: new Date(date),
      editing: true,
    })

    setState({ events })
  }

  const handleChange = (event, eventIndex, inputIndex) => {
    const { value } = event.target
    const events = get(state, 'events', [])

    addGaEvent('shipment-addingEvent', get(props, 'shipment._id'), get(events, `[${eventIndex}].code`))
    set(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {
      ...get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {}),
      value: value.toUpperCase(),
      editing: true,
    })

    setState({ events })
  }

  const setCurrentDateTime = (eventIndex, inputIndex) => {
    const events = get(state, 'events', [])

    addGaEvent('shipment-addingEvent', get(props, 'shipment._id'), get(events, `[${eventIndex}].code`))
    set(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {
      ...get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {}),
      value: new Date(),
      editing: true,
    })

    setState({ events })
  }

  const editEvent = (eventIndex, inputIndex) => {
    const events = get(state, 'events', [])

    set(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {
      ...get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {}),
      editing: true
    })

    setState({ events })
  }

  const cancelSave = (eventIndex, inputIndex) => {
    const events = get(state, 'events', [])

    set(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {
      ...get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {}),
      value: get(events[eventIndex], 'primaryInputs[' + inputIndex + '].oldValue', ''),
      editing: false
    })

    setState({ events })
  }

  const getEventFieldActions = (eventIndex, inputIndex, eventFieldValue, isEditing) => {
    return (
      <>
        {
          (!eventFieldValue || isEditing) &&
          <Grid item>
            <img src="assets/images/icons/events/save-active.svg"
              alt="save"
              className={
                'action-icons save'
                + ((isEditing && eventFieldValue) ? ' active' : '')
              }
              onClick={() => {
                if (isEditing) {
                  saveEvent(eventIndex, inputIndex)
                }
              }} />
          </Grid>
        }

        {
          (eventFieldValue && !isEditing) &&
          <Grid item>
            <img src="assets/images/icons/events/edit-active.svg"
              className={'action-icons edit'}
              alt="edit"
              onClick={() => editEvent(eventIndex, inputIndex)}
            />
          </Grid>
        }

        <Grid item>
          <img src="assets/images/icons/events/close-active.svg"
            alt="cancel"
            className={
              'cursor-pointer'
              + (isEditing ? '' : ' invisible')
            }
            onClick={() => {
              addGaEvent(
                'shipment-addingEventCancelled',
                get(props, 'shipment._id'),
                get(state, `events[${eventIndex}].code`)
              )
              cancelSave(eventIndex, inputIndex)
            }}
          />
        </Grid>
      </>
    )
  }

  const getEventField = (eventIndex, inputIndex) => {
    const event = get(state, 'events[' + eventIndex + ']', {})
    const eventDataType = get(event, 'primaryInputs[' + inputIndex + '].type')
    const eventFieldId = get(event, 'primaryInputs[' + inputIndex + '].id')
    const eventFieldValue = get(event, 'primaryInputs[' + inputIndex + '].value')
    const isEditing = get(event, 'primaryInputs[' + inputIndex + '].editing', false)

    if (eventDataType === 'date') {
      return (
        <Grid container
          justify='flex-end'
          alignItems='center'
          spacing={2}
          className="py-12 md:py-0">
          {
            (!eventFieldValue || isEditing) &&
            <Grid item xs={12} sm={12} md={4}
              onClick={() => setCurrentDateTime(eventIndex, inputIndex)}>
              <label className="today-picker">
                Pick Current date time</label>
            </Grid>
          }

          <Grid item xs={12} sm={12} md={7}
            className='value-field relative'>
            <Grid container
              alignItems="center"
              spacing={2}>
              <Grid item xs={8}>
                <Grid container
                  alignItems="center">
                  <Grid item xs={12} className="relative">
                    <DateTime className="theme-event-date-time-picker-field"
                      input
                      inputProps={{
                        placeholder: 'Add date & time',
                        disabled: (eventFieldValue && !isEditing)
                      }}
                      dateFormat="DD MMM YYYY,"
                      onChange={(date) => onDateChange(date, eventIndex, inputIndex)}
                      value={eventFieldValue ? new Date(eventFieldValue) : ''}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container
                  alignItems="center"
                  spacing={2}>
                  {getEventFieldActions(eventIndex, inputIndex, eventFieldValue, isEditing)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    } else if (eventDataType === 'string') {
      return (
        <Grid container
          justify='flex-end'
          alignItems='center'
          spacing={2}
          className="py-12 md:py-0">
          <Grid item xs={12} sm={12} md={7}
            className='value-field'>
            <Grid container
              alignItems='center'
              spacing={2}>
              <Grid item xs={8}>
                <Grid container alignItems="center">
                  <Grid item xs={12} className="relative">
                    <TextField name={eventFieldId}
                      className='value-field-input'
                      value={eventFieldValue}
                      placeholder={"Enter something..."}
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={(eventFieldValue && !isEditing)}
                      onChange={(e) => handleChange(e, eventIndex, inputIndex)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container
                  alignItems="center"
                  spacing={2}>
                  {getEventFieldActions(eventIndex, inputIndex, eventFieldValue, isEditing)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Grid container className='shipment-events'>
      <Grid item xs={12} className='pb-20'>
        <Typography className='section__title regular'>Add events</Typography>
      </Grid>
      {get(props, 'eventWriteList[0].invalid') &&
        <Grid item xs={12}>
          <FusePlaceHolder className='flex flex-col'
            icon='error'
            title='Events can be added only after accepting this shipment' />
        </Grid>
      }
      {!get(props, 'eventWriteList[0].invalid') && get(state, 'events', [])
        .map((event, eventIndex) => {
          const eventLevel = get(event, 'level')
          const primaryInputs = get(event, 'primaryInputs', [])

          if (primaryInputs.length === 0) {
            return <React.Fragment key={eventIndex} />
          } else {
            return (
              <Grid item xs={12}
                key={eventIndex}
                className={
                  'shipment-events-item'
                  + (false ? ' completed' : '')
                }>
                <Grid container className='shipment-events-item-container'>
                  <Grid item xs={12} sm={12} md={3}>
                    <Typography
                      className='shipment-events-name text-12'
                      title={event.name}>
                      {event.name}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}
                    className='shipment-events-value'>
                    {
                      (eventLevel === 'Shipment' && primaryInputs.length === 1) &&
                      getEventField(eventIndex, 0)
                    }
                  </Grid>
                  {
                    eventLevel === 'Container' &&
                    primaryInputs.map((input, inputIndex) => {
                      return (
                        <Grid key={`input_${inputIndex}`}
                          item xs={12}
                          className={
                            'pt-8'
                            + ((inputIndex === (primaryInputs.length - 1)) ? ' pb-20' : '')
                          }>
                          <Grid container alignItems="center">
                            <Grid item xs={12} sm={12} md={3}>
                              <Typography noWrap
                                title={get(input, 'name').toUpperCase()}
                                className='shipment-events-name text-12 uppercase regular'>
                                {input.name}</Typography>
                              {get(input, 'data') &&
                                <Typography className='shipment-events-name text-12 uppercase regular fg-gray'>
                                  {get(input, 'data')}</Typography>
                              }
                            </Grid>
                            <Grid item xs={12} sm={12} md={9} className='shipment-events-value'>
                              {getEventField(eventIndex, inputIndex)}
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            )
          }
        })
      }
    </Grid >
  )
}

export default ShipmentEvents
