import React, { Component } from 'react'
import FuseAnimate from '../Animate'
import { get, omit } from 'lodash'

class ListSearchBox extends Component {
  render() {
    const { containerProps } = this.props
    const inputProps = omit(this.props, ['containerProps', 'icon'])

    return (
      <FuseAnimate animation='transition.fadeIn' duration={200}>
        <div {...containerProps}
          className={
            'list-search-box flex items-center ' +
            get(containerProps, 'className', '')
          }>
          <img src='assets/images/icons/Search-Organizations.png'
            className='mr-6'
            alt='search' />
          <input type='text' {...inputProps}
            className={
              'input ' +
              get(inputProps, 'className', '')
            } />
          {this.props.icon}
        </div>
      </FuseAnimate>
    )
  }
}

export default ListSearchBox
