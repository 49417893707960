import React from 'react'
import {
  Grid,
  Button,
  Icon
} from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody,
  LoadingButton
} from '../../core/components'
import { get } from 'lodash'

const DeletePlantEmailTemplateConfirm = props => {
  const {
    open,
    deleteTemplate,
    submitting,
    handleDelete,
    handleDeleteCancel,
    currentPlant
  } = props

  return (
    <FuseDialog open={open} wSM>
      <FuseDialogHeader text="Remove template"
        handleClose={handleDeleteCancel} />
      <FuseDialogBody>
        <Grid container alignItems="center" className="p-20">
          <Grid item xs={12}>
            <Grid container justify="center">
              <Icon className="text-52 mb-12 fg-red">delete</Icon>
            </Grid>
          </Grid>
          <Grid item xs={12} className="text-center">
            <label className="regular block text-center">This will delete email template for all users available in plant <strong>{get(currentPlant, 'name')}</strong></label>
            <label className="regular">Are you sure you want to remove&nbsp;</label>
            <label className="bold">
              {get(deleteTemplate, 'value.name')}?</label>
          </Grid>
          <Grid item xs={12}>
            <Grid container
              spacing={2}
              className="pt-40">
              <Grid item xs={6}>
                <LoadingButton className="btn btn_sm btn_light_red"
                  loading={submitting === 'plantEmailTemplate'}
                  onClick={() => handleDelete(deleteTemplate)}
                  fullWidth>Yes, Remove</LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <Button className="btn btn_sm btn_light_green"
                  disabled={submitting === 'plantEmailTemplate'}
                  onClick={handleDeleteCancel}
                  fullWidth>Cancel</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default DeletePlantEmailTemplateConfirm
