import React from 'react'
import { Grid } from '@material-ui/core'
import { get } from 'lodash'
import { general } from '../../../core/localization'

const PartnerInfo = ({ actors = [], actorType }) => (
  <Grid container spacing={1}
    justify="space-between">
    <Grid item xs={6}
      className="fg-gray">
      {get(general, `partners.${actorType}`)}</Grid>
    <Grid item xs={6}>
      <Grid container
        direction="row"
        justify="flex-end">
        {actors.map((actor, actorIndex) => (
          <Grid item key={actorIndex}>
            {get(actor, 'name')}
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
)

const PartnersInfo = props => {
  const { shipment } = props
  const partners = ['ifp', 'ibc', 'ibl']

  return (
    <Grid container spacing={3}>
      {/* Partners */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}
                className="semi-bold text-16">
                Partners</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container
              spacing={1}
              className="medium text-14">
              {partners.map((partner, partnerIndex) => (
                <Grid item xs={12}
                  key={partnerIndex}>
                  <PartnerInfo actorType={partner}
                    actors={get(shipment, `actors.${partner}`, [])} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* End of Partners */}
      {/* Incoterms */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}
                className="semi-bold text-16">
                Incoterms</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container
              spacing={1}
              className="medium text-14">
              <Grid item xs={12}>
                <Grid container spacing={1}
                  justify="space-between">
                  <Grid item className="fg-gray">
                    Available incoterm</Grid>
                  <Grid item>{get(shipment, 'incoterm')}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* End of Incoterms */}
      {/* Itinerary id */}
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}
                className="semi-bold text-16">
                Itinerary id</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container
              spacing={1}
              className="medium text-14">
              <Grid item xs={12}>
                <Grid container spacing={1}
                  justify="space-between">
                  <Grid item xs={6}
                    className="fg-gray">
                    Available itinerary ids</Grid>
                  <Grid item xs={6}>
                    <Grid container
                      direction="row"
                      justify="flex-end">
                      {get(shipment, 'executableServiceCards', []).map((serviceCard, serviceCardIndex) => (
                        <Grid item key={serviceCardIndex}>
                          {get(serviceCard, 'meta.itineraryId')}
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* End of Itinerary id */}
    </Grid>
  )
}

export default PartnersInfo
