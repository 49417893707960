import ActionTypes from './types'

const initialState = {
  documents: [],
  chatDocuments: [],
  eventsTracking: {
    loading: true,
    data: []
  },
  outbounds: [],
  consolidations: [],
  events: [],
  eventWriteList: {
    loading: true,
    data: []
  },
  rbac: {
    read: [],
    write: []
  },
  loading: true,
  error: null,
  updatePreps: {},
  preAlertLogs: {
    loading: true,
    data: []
  },
  goodsReceived: {
    loading: true,
    data: []
  },
  topics: {
    loading: false,
    data: [],
    requestedTopicDetails: null
  }
}

const singleShipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_SHIPMENT_DETAILS: {
      return initialState
    }

    case ActionTypes.API_ATTEMPT: {
      return {
        ...state,
        loading: true
      }
    }

    case ActionTypes.API_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }

    case ActionTypes.API_FAILED: {
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_OUTBOUNDS_SUCCESS: {
      return {
        ...state,
        outbounds: action.payload,
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_CONSOLIDATIONS_SUCCESS: {
      return {
        ...state,
        consolidations: action.payload,
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documents: action.payload,
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_CHAT_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        chatDocuments: action.payload,
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_EVENTS_SUCCESS: {
      return {
        ...state,
        eventsTracking: {
          data: action.payload,
          loading: action.loading
        },
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_EVENT_WRITE_LIST_SUCCESS: {
      return {
        ...state,
        eventWriteList: {
          data: action.payload,
          loading: action.loading,
        },
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_META_SUCCESS: {
      return {
        ...state,
        rbac: action.payload,
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_UPDATE_PREPS_SUCCESS: {
      return {
        ...state,
        updatePreps: action.payload,
        loading: false,
      }
    }

    case ActionTypes.GET_SHIPMENT_PRE_ALERT_LOGS_SUCCESS: {
      return {
        ...state,
        preAlertLogs: {
          loading: false,
          data: action.payload
        },
      }
    }

    case ActionTypes.GET_SHIPMENT_GOODS_RECEIVED_SUCCESS: {
      return {
        ...state,
        goodsReceived: {
          loading: false,
          data: action.payload
        },
      }
    }

    case ActionTypes.GET_SHIPMENT_TOPICS: {
      return {
        ...state,
        topics: {
          ...state.topics,
          loading: action.loading,
          data: action.payload
        },
      }
    }

    case ActionTypes.GET_SHIPMENT_TOPICS_DETAILS: {
      return {
        ...state,
        topics: {
          ...state.topics,
          requestedTopicDetails: action.payload
        },
      }
    }

    default:
      return state
  }
}

export default singleShipmentReducer
