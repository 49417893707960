import React from 'react'
import { withStyles, Button, Icon } from '@material-ui/core'
import classNames from 'classnames'

const StatusIconButton = ({ classes, variant, icon, children, iconComponent, className, disabled = false, ...other }) => {
  return (
    <Button
      className={classNames(className, classes.root,
        {
          [classes.danger]: variant === 'danger',
          [classes.warning]: variant === 'warning',
          [classes.success]: variant === 'success'
        }
      )}
      variant='outlined'
      size='small'
      disabled={disabled}
      {...other}
    >
      {iconComponent}
      {icon && <Icon className='mr-8'>{icon}</Icon>}
      <span className={classes.text}>{children}</span>
    </Button >
  )
}

const styles = theme => ({
  root: {
    '&:hover': {
      background: '#ffffff !important',
      filter: 'brightness(110%)'
    }
  },
  danger: {
    background: '#F9EDF2 !important',
    color: '#B40B4C',
    border: '1px dashed currentColor'
  },
  warning: {
    background: '#FDF6ED !important',
    color: '#E89227',
    border: '1px dashed currentColor'
  },
  text: {
    fontFamily: 'IBMPlexSans',
    color: '#626469',
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'none',
    textAlign: 'left'
  }
})

export default withStyles(styles)(StatusIconButton)
