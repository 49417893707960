import React from 'react'
import { connect } from 'react-redux'
import { Grid, CircularProgress } from "@material-ui/core"
import { get } from "lodash"
import queryString from "query-string"
import * as API from "../../api";
import * as AuthActions from "../../../auth/store/actions"
import * as CoreActions from '../../store/actions'
import history from '../../../history'

const AuthenticateToken = props => {
  const { location, match, showMessage } = props
  const status = get(match, 'params.status')

  const authenticate = async () => {
    const { getInfo, loginSuccess } = props
    const { data } = await API.exchangeToken({ code: localStorage.getItem('providerAuthToken') })
    const authToken = get(data, 'data.token')

    localStorage.removeItem('providerAuthToken')
    await loginSuccess(authToken)
    await API.setTokenHeader(authToken)
    let info = await getInfo()

    if (get(info, 'status') === 'active') {
      showMessage({
        variant: 'success',
        message: 'Authenticated successfully'
      })
      history.replace(`/${get(location, 'search', '')}${get(location, 'hash', '')}`)
    } else {
      showMessage({
        variant: 'error',
        message: 'User account is inactive'
      })
      history.replace(`/login${get(location, 'search', '')}${get(location, 'hash', '')}`)
    }
  }

  if (status === 'success') {
    authenticate()
  } else {
    const parseQuery = queryString.parse(get(location, 'search'))
    const toast = {
      variant: 'error',
      message: 'Authentication failed'
    }
    if (get(parseQuery, 'reason')) {
      toast.message = get(parseQuery, 'reason')
    }
    showMessage(toast)
    history.replace(`/login${get(location, 'search', '')}${get(location, 'hash', '')}`)
  }

  return (
    <Grid container
      className="m-h-full m-w-full"
      justify="center"
      alignItems="center">
      <Grid item>
        <CircularProgress className="text-40 fg-green" />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  getInfo: () => dispatch(AuthActions.getInfo()),
  loginSuccess: (token) => dispatch(AuthActions.loginSuccess(token)),
  showMessage: (options) => dispatch(CoreActions.showMessage(options))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateToken)
