import React, { Component } from 'react'
import { Grid } from '@material-ui/core';
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout';
import Tableau from './Tableau';

class Analytics extends Component {
  state = {
  }

  render() {

    return (
      <Grid container className="h-full overflow-auto">
        <SubToolBarLayout text='Analytics' section={'analytics'} />
        <Grid item xs={12}>
          <Grid container alignItems={'center'} className={'p-20'}>
            <Tableau />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default (Analytics)
