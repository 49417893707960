import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody
} from '../../core/components'
import { get } from 'lodash'
import { getAllLocodes } from '../../core/helpers'
import { AutoComplete } from '../../core/components/Inputs'
import { getRouteConsignee } from '../../core/api'

const BookingInfoDialog = ({ open, onClose, currentPlant, setRequesting, setBookingTransportMethod, onNewBookingClick, showMessage }) => {
  const [state, setState] = useState({})
  const [consigneeOptions, setConsigneeOptions] = useState([])
  const [showNoConsignee, setShowNoConsignee] = useState(false)
  const portOptions = getAllLocodes('port').map(i => ({ value: i.locode, label: i.locode }))
  const transportMethodOptions = ['ocean', 'air', 'CBT', 'rail', 'NA'].map(i => ({ value: i, label: i === 'NA' ? 'OTHER' : i.toUpperCase() }))
  const shipmentModeOptions = ['direct', 'drop', 'local', 'express',].map(i => ({ value: i, label: i.toUpperCase() }))

  const handleStateUpdate = (name, value) => {
    setState({
      ...state,
      [name]: value
    })
  }

  const getConsigneeList = async () => {
    setShowNoConsignee(false)
    setConsigneeOptions([])
    const { originPlantId, originLocode, destinationLocode } = state
    const { ok, data } = await getRouteConsignee({ originPlantId, originLocode, destinationLocode })
    if (ok) {
      const consigneeList = get(data, 'data', [])
      if (consigneeList.length === 0) {
        setShowNoConsignee(true)
      } else {
        setConsigneeOptions(consigneeList.map(i => ({
          value: get(i, 'consignee.plantData._id'),
          label: get(i, 'consignee.plantData.name')
        })))
      }
    } else {
      setConsigneeOptions([])
    }
  }

  const handleGetBookingOptions = () => {
    const { transportMethod, originPlantId, destinationPlantId, shipmentMode } = state
    setBookingTransportMethod(transportMethod)
    setRequesting('create-booking')
    onNewBookingClick('new', null, { originPlantId, destinationPlantId, shipmentMode })
    onClose()
  }

  useEffect(() => {
    if (state.originLocode && state.destinationLocode) {
      getConsigneeList()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.originLocode, state.destinationLocode])

  useEffect(() => {
    if (open === true) {
      setState({
        originPlantId: get(currentPlant, '_id')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <FuseDialog open={open} wSM>
      <FuseDialogHeader text="Create Booking Info" handleClose={onClose} />
      <FuseDialogBody>
        <Grid container alignItems="center" className="p-20">
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {/* Form */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* Shipper */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <Typography className="semi-bold fg-gray">Shipper</Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <Typography className="regular">{get(currentPlant, 'name')}&nbsp;[{get(currentPlant, 'type', '').toUpperCase()}]</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* End of Shipper */}

                  {/* Shipper Port */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <Typography className="semi-bold fg-gray require-field"
                          display="inline">Shipper Port</Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <AutoComplete options={portOptions}
                          value={get(state, 'originLocode', '')}
                          placeholder="Select port..."
                          onChange={(value) => handleStateUpdate('originLocode', value)}
                          canCreate />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* End of Shipper Port */}

                  {/* Consignee Port */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <Typography className="semi-bold fg-gray require-field"
                          display="inline">Consignee Port</Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <AutoComplete options={portOptions}
                          value={get(state, 'destinationLocode', '')}
                          placeholder="Select port..."
                          onChange={(value) => handleStateUpdate('destinationLocode', value)}
                          canCreate />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* End of Consignee Port */}

                  {get(state, 'destinationLocode') &&
                    // Consignee
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={5}>
                          <Typography className={"semi-bold require-field " + (showNoConsignee ? 'fg-red' : 'fg-gray')}
                            display="inline">Consignee</Typography>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                          <AutoComplete options={consigneeOptions}
                            value={get(state, 'destinationPlantId', '')}
                            placeholder={showNoConsignee ? "No consignees available" : "Select consignee..."}
                            onChange={(value) => handleStateUpdate('destinationPlantId', value)}
                            disabled={showNoConsignee}
                            dynamic />
                        </Grid>
                      </Grid>
                    </Grid>
                    // End of Consignee
                  }

                  {/* Transport Method */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <Typography className="semi-bold fg-gray require-field"
                          display="inline">Transport Method</Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <AutoComplete options={transportMethodOptions}
                          value={get(state, 'transportMethod', '')}
                          placeholder="Select transport method..."
                          onChange={(value) => handleStateUpdate('transportMethod', value)} />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* End of Transport Method */}

                  {/* Shipment Mode */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={5}>
                        <Typography className="semi-bold fg-gray require-field"
                          display="inline">Shipment Mode</Typography>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <AutoComplete options={shipmentModeOptions}
                          value={get(state, 'shipmentMode', '')}
                          placeholder="Select shipment mode..."
                          onChange={(value) => handleStateUpdate('shipmentMode', value)} />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* End of Shipment Mode */}
                </Grid>
              </Grid>
              {/* Actions */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Button className="btn btn_sm w-full"
                      disabled={!state.destinationPlantId || !state.transportMethod || !state.shipmentMode}
                      onClick={handleGetBookingOptions}>
                      continue</Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button className="btn btn_sm btn_light_red w-full"
                      onClick={onClose}>
                      cancel</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default BookingInfoDialog
