import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  LoadingButton
} from '../../core/components'
import TransportTimeline from '../../core/components/Timelines/TransportTimeline'
import { get, filter, find } from 'lodash';
import { getAddressFromLocation } from '../../core/helpers'

const ShipperReviewBookingDialog = (props) => {
  const {
    open,
    shipment,
    handleClose,
    onAmend,
    onResolve,
    getAllShipments,
    requesting,
    setRequesting
  } = props
  const [isResolving, setIsResolving] = useState(false);
  const [note, setNote] = useState('');
  const originWH = find(get(shipment, 'journey', []), { location: { subType: 'origin', type: 'warehouse' } })
  const destinationWH = find(get(shipment, 'journey', []), { location: { subType: 'destination', type: 'warehouse' } })

  useEffect(() => {
    setIsResolving(false)
    setNote('')
  }, [open])

  const transportRoute = [
    {
      place: get(originWH, 'location.name'),
      address: getAddressFromLocation(get(originWH, 'location.address', {}))
    },
    {
      place: get(destinationWH, 'location.name'),
      address: getAddressFromLocation(get(destinationWH, 'location.address', {}))
    }
  ];

  const handleResolve = () => {
    setRequesting(true)
    onResolve({ note }, shipment._id,
      () => {
        getAllShipments()
        handleClose()
        setRequesting(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const sc20Count = filter(get(shipment, 'containers', []), { type: 'C20GP' }).length
  const sc40Count = filter(get(shipment, 'containers', []), { type: 'C40GP' }).length
  const hc40Count = filter(get(shipment, 'containers', []), { type: 'C40HC' }).length

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text={isResolving ? 'Resolve booking' : 'Review booking'}
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="text-14">
          <Grid item xs={8}
            className="bg-white p-20 min-h-full">
            <Grid container className="h-full">
              {
                !isResolving &&
                <Grid item xs={12}>

                  <Grid container direction='row' className='pb-28'>
                    <Grid item xs={12}
                      className="text-16 semi-bold pb-12">Rejected reason</Grid>
                    <Grid item xs={12}>
                      <label className="medium fg-gray">
                        {get(shipment, 'status.current.reason')}
                      </label>
                    </Grid>
                  </Grid>
                </Grid>
              }

              {
                isResolving &&
                <Grid item xs={12}>
                  <Grid container direction='row' className='pb-28'>
                    <Grid item xs={12}
                      className="text-16 semi-bold">Add note</Grid>
                    <Grid item xs={12}>
                      <TextField name='note'
                        placeholder="Specify a reason for not amending this booking…"
                        className='theme-text-field w-full'
                        value={note}
                        inputProps={{
                          style: {
                            fontSize: '14px'
                          }
                        }}
                        onChange={event => setNote(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              }

              <Grid item xs={12} sm={12} md={12}>
                <Grid container className="h-full" alignItems="flex-end">
                  {
                    !isResolving &&
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                          <LoadingButton className="btn btn_orange w-full"
                            loading={requesting}
                            onClick={() => onAmend(shipment)}>
                            Change Booking</LoadingButton>
                          <label className="regular text-10 fg-gray">
                            This will repropose the entire booking.</label>
                        </Grid>
                        <Grid item sm={6} md={6}>
                          <Button className="btn w-full btn_light_green"
                            disabled={requesting}
                            onClick={() => { setIsResolving(true) }}>
                            Resolve without changes</Button>
                          <label className="regular text-10 fg-gray">
                            This will resolve the booking without amending it.</label>
                        </Grid>
                      </Grid>
                    </Grid>
                  }

                  {
                    isResolving &&
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item sm={12} md={6}>
                          <LoadingButton className="btn w-full"
                            loading={requesting}
                            disabled={!note}
                            onClick={handleResolve}>Resolve booking</LoadingButton>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <Grid container alignItems="center" justify="flex-end">
                            <Button className="btn btn_transparent"
                              onClick={() => setIsResolving(false)}>Back</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={4}
            className="bg-gray p-20">
            <Grid container>
              {
                get(shipment, 'type') === 'FCL' &&
                <Grid item xs={12} className="pb-20 border-dashed border-b-1 border-gray">
                  <Grid item xs={12} className="text-16 pb-12">Containers</Grid>
                  {sc20Count > 0 &&
                    <div className='flex flex-row py-4 justify-between'>
                      <label className='flex items-center text-grey-dark'>
                        <span className='h-20 w-32 mr-8 text-center'>
                          <img src='assets/images/icons/20ft-standard.png' alt='20ft' />
                        </span>
                        20 FT Standard
                      </label>
                      <span className='text-right text-grey-darkest font-bold'>
                        {sc20Count}
                      </span>
                    </div>
                  }

                  {sc40Count > 0 && (
                    <div className='flex flex-row py-4 justify-between'>
                      <label className='flex items-center text-grey-dark'>
                        <span className='h-20 w-32 mr-8 text-center'>
                          <img src='assets/images/icons/40ft-standard.png' alt='40ft' />
                        </span>
                        40 FT Standard
                      </label>
                      <span className='text-right text-grey-darkest font-bold'>
                        {sc40Count}
                      </span>
                    </div>
                  )}

                  {hc40Count > 0 && (
                    <div className='flex flex-row py-4 justify-between'>
                      <label className='flex items-center text-grey-dark'>
                        <span className='h-20 w-32 mr-8 text-center'>
                          <img src='assets/images/icons/40ft-high-cube.png' alt='40fthc' />
                        </span>
                        40 FT High Cube
                      </label>
                      <span className='text-right text-grey-darkest font-bold'>
                        {hc40Count}
                      </span>
                    </div>
                  )}
                </Grid>
              }

              <Grid item xs={12} className="pt-20">
                <Grid container>
                  <Grid item xs={12}
                    className="text-16 pb-12">Transport route</Grid>
                  <TransportTimeline data={transportRoute} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default ShipperReviewBookingDialog
