import React, { useState } from 'react'
import { FuseCheckBox } from 'core/components'
import {
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  MenuItem,
  Select,
  withStyles,
  InputBase,
  FormControl,
  FormControlLabel,
  CircularProgress
} from '@material-ui/core'
import TermsDialog from './TermsDialog'
import { FuseTextField } from 'core/components'
import classNames from 'classnames'
import validator from 'validator'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    fontSize: '1.3em',
    minWidth: '30px',
    lineHeight: '16px',
    padding: '12px 26px 12px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'IBMPlexSans-SemiBold',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)
const countryPhoneCodes = require('../../core/helpers/json/countryPhoneCodes.json')

const labelStyles = {
  fontSize: '1.1em',
  marginBottom: '0.4em'
}

const inputStyles = {
  fontSize: '1.2em'
}

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const initialErrorState = {
  firstname: null,
  lastname: null,
  username: null,
  newPassword: null,
  confirmPassword: null,
  countryCode: null,
  phonenumber: null,
  firstname_helper_text: null,
  lastname_helper_text: null,
  username_helper_text: null,
  newPassword_helper_text: null,
  confirmPassword_helper_text: null,
  phonenumber_helper_text: null
}

const OnboardingForm = props => {
  const [onboarding, setOnboarding] = useState({
    acceptedTC: false,
    firstname: '',
    lastname: '',
    username: '',
    newPassword: '',
    confirmPassword: '',
    phone: {
      countryCode: '+65',
      phoneNumber: ''
    }
  })
  const [error, setError] = useState(initialErrorState)

  return (
    <div className='flex flex-col justify-start w-full'>
      {props.step === 1 && (
        <Step1
          setStep={props.setStep}
          setOnboarding={setOnboarding}
          onboarding={onboarding}
          setError={setError}
          error={error}
          {...props}
        />
      )}
      {props.step === 2 && (
        <Step2
          setStep={props.setStep}
          setOnboarding={setOnboarding}
          onboarding={onboarding}
          setError={setError}
          error={error}
          {...props}
        />
      )}
      {props.step === 3 && (
        <Step3
          setStep={props.setStep}
          setOnboarding={setOnboarding}
          onboarding={onboarding}
          setError={setError}
          error={error}
          {...props}
        />
      )}
    </div>
  )
}

const Step1 = ({ setStep, auth, setOnboarding, onboarding, loading, classes, toggleDrawer }) => {
  const onSubmit = () => {
    setStep(2)
  }
  let [termsDialogOpen, setTermsDialogOpen] = useState(false)

  return (
    <>
      <Typography variant='h4' className='md:w-full mb-32 semi-bold'>
        Review Account Details
      </Typography>
      <Typography className={classNames('text', classes.helperText)}>
        Review your email id and plants you have been invited to before setting up the account
      </Typography>
      <Table className={classes.table}>
        <TableBody>
          <TableRow className={classes.tableRow}>
            <TableCell align='left' className={classes.tableColumnLeft}>
              Email:{' '}
            </TableCell>
            <TableCell align='right' className={classes.tableColumnRight}>
              {auth.info.email}
            </TableCell>
          </TableRow>
          <TableRow className={classes.tableRow}>
            <TableCell align='left' className={classes.tableColumnLeft}>
              You are invited for:{' '}
            </TableCell>
            <TableCell align='right' className={classes.tableColumnRight}>
              <Button onClick={toggleDrawer}>{`${auth.info.plants.length} Plants`}</Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography>
        <FormControlLabel className={classes.terms}
          control={
            <FuseCheckBox checked={onboarding.acceptedTC}
              className='p-0 ml-8 pl-2'
              onChange={() => setOnboarding({
                ...onboarding,
                acceptedTC: !onboarding.acceptedTC
              })} />
          } />
        I have read and agree to{' '}
        <Button style={{ color: '#638DDE' }}
          onClick={() => setTermsDialogOpen(true)}>
          terms and conditions</Button>.</Typography>

      <Button type='submit'
        style={{ borderRadius: '50vh' }}
        variant='contained'
        color='primary'
        className='min-w-256 ml-auto mt-16 btn'
        aria-label='Continue'
        disabled={loading || !onboarding.acceptedTC}
        onClick={onSubmit}>
        {!loading ? 'Continue' : 'Loading...'}{' '}
        {loading && <CircularProgress color='primary' size={20} style={{ marginLeft: 20 }} />}
      </Button>
      <TermsDialog termsDialogOpen={termsDialogOpen} setTermsDialogOpen={setTermsDialogOpen} />
    </>
  )
}
const Step2 = ({ setStep, setOnboarding, error, setError, onboarding, loading, searchUser, classes }) => {
  const onSubmit = async e => {
    e.preventDefault()
    if (!onboarding.username) {
      setError({ ...initialErrorState, username: true, username_helper_text: 'Enter Username' })
      return
    }

    if (!onboarding.newPassword) {
      setError({ ...initialErrorState, newPassword: true, newPassword_helper_text: 'Enter Password' })
      return
    }
    var regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
    if (!regex.test(onboarding.newPassword)) {
      setError({
        ...initialErrorState,
        newPassword: true,
        newPassword_helper_text:
          ' Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
      })
      return
    }
    if (!onboarding.confirmPassword) {
      setError({ ...initialErrorState, confirmPassword: true, confirmPassword_helper_text: 'Confirm Password' })
      return
    }
    if (onboarding.newPassword !== onboarding.confirmPassword) {
      setError({ ...initialErrorState, confirmPassword: true, confirmPassword_helper_text: 'Passwords dont match' })
      return
    }
    if (/[^a-zA-Z0-9]/.test(onboarding.username)) {
      setError({
        ...initialErrorState,
        username: true,
        username_helper_text: 'Username should not have special characters'
      })
      return
    }
    const result = await searchUser({ username: onboarding.username })
    if (result.data && result.data.exists) {
      setError({ ...initialErrorState, username: true, username_helper_text: 'Username already taken' })
      return
    }
    setStep(3)
  }
  const onUsernameChange = e => setOnboarding({ ...onboarding, username: e.target.value })
  const onPasswordChange = e => setOnboarding({ ...onboarding, newPassword: e.target.value })
  const onConfirmPasswordChange = e => setOnboarding({ ...onboarding, confirmPassword: e.target.value })

  return (
    <>
      <Typography variant='h4' className='md:w-full mb-32 semi-bold'>
        Set username and password
      </Typography>
      <Typography className={classNames('text', classes.helperText)}>
        Set a unique username for your account. You can make password stronger by using atleast one upper case letter,
        atleast one lower case, atleast one number & min 8 characters long
      </Typography>
      <div className='flex flex-col justify-start w-full mt-32'>
        <form onSubmit={onSubmit} noValidate>
          <Grid container justify='center'>
            <Grid item md={12}>
              <FuseTextField
                className='mb-16'
                type='text'
                name='username'
                label='Username'
                labelStyles={labelStyles}
                inputStyles={inputStyles}
                error={error.username}
                helperText={error.username_helper_text}
                icon={{ ...baseTextSetting, name: 'person' }}
                value={onboarding.username}
                onChange={onUsernameChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6} style={{ paddingRight: 12 }}>
              <FuseTextField
                className='mb-16'
                type='password'
                name='password'
                label='Password'
                labelStyles={labelStyles}
                inputStyles={inputStyles}
                icon={{ ...baseTextSetting, name: 'vpn_key' }}
                error={error.newPassword}
                helperText={error.newPassword_helper_text}
                value={onboarding.newPassword}
                onChange={onPasswordChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item md={6}>
              <FuseTextField
                className='mb-16'
                type='password'
                name='confirmPassword'
                label='Confirm Password'
                labelStyles={labelStyles}
                inputStyles={inputStyles}
                error={error.confirmPassword}
                helperText={error.confirmPassword_helper_text}
                icon={{ ...baseTextSetting, name: 'vpn_key' }}
                value={onboarding.confirmPassword}
                onChange={onConfirmPasswordChange}
                required
                fullWidth
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            style={{ borderRadius: '50vh' }}
            variant='contained'
            color='primary'
            className='min-w-256 ml-auto mt-16 btn'
            aria-label='Continue'
            disabled={loading}
            onClick={onSubmit}
          >
            Continue
          </Button>
        </form>
      </div>
    </>
  )
}
const Step3 = ({ setStep, setError, error, loading, onboarding, setOnboarding, activateUser, classes }) => {
  const onSubmit = e => {
    e.preventDefault()
    if (!onboarding.firstname) {
      setError({ ...initialErrorState, firstname: true, firstname_helper_text: 'Enter Firstname' })
      return
    }
    if (!onboarding.lastname) {
      setError({ ...initialErrorState, lastname: true, lastname_helper_text: 'Enter Lastname' })
      return
    }
    if (!onboarding.phone.phoneNumber) {
      setError({ ...initialErrorState, phonenumber: true, phonenumber_helper_text: 'Enter Phone' })
      return
    }
    activateUser(onboarding)
  }
  const onFirstNameChange = e => setOnboarding({ ...onboarding, firstname: e.target.value })
  const onLastNameChange = e => setOnboarding({ ...onboarding, lastname: e.target.value })
  const onCountryCodeChange = e =>
    setOnboarding({ ...onboarding, phone: { ...onboarding.phone, countryCode: e.target.value } })
  const onPhoneNumberChange = e => {
    if (!validator.isNumeric(e.target.value) && e.target.value.trim() !== '') {
      return
    }
    setOnboarding({ ...onboarding, phone: { ...onboarding.phone, phoneNumber: e.target.value } })
  }
  return (
    <>
      <Typography variant='h4' className='md:w-full mb-32 semi-bold'>
        Add profile details
      </Typography>
      <Typography className={classNames('text', classes.helperText)}>
        Add your personal details, your name and phone number
      </Typography>

      <div className='flex flex-col justify-start w-full mt-32'>
        <form onSubmit={onSubmit} noValidate>
          <FuseTextField
            className='mb-16'
            type='text'
            name='firstname'
            label='Firstname'
            labelStyles={labelStyles}
            inputStyles={inputStyles}
            icon={{ ...baseTextSetting, name: 'person' }}
            error={error.firstname}
            helperText={error.firstname_helper_text}
            value={onboarding.firstName}
            onChange={onFirstNameChange}
            required
            fullWidth
          />

          <FuseTextField
            className='mb-8'
            type='text'
            name='lastname'
            label='Lastname'
            error={error.lastname}
            labelStyles={labelStyles}
            inputStyles={inputStyles}
            helperText={error.lastname_helper_text}
            icon={{ ...baseTextSetting, name: 'person' }}
            value={onboarding.lastName}
            onChange={onLastNameChange}
            required
            fullWidth
          />

          <Grid container>
            <Grid item xs={3}>
              <FormControl style={{ width: '100%', height: '100%' }}>
                <Select
                  className={classes.countryCode}
                  labelId='countryCode'
                  id='countryCode'
                  value={onboarding.phone.countryCode}
                  onChange={onCountryCodeChange}
                  input={<BootstrapInput />}
                >
                  {countryPhoneCodes.map(country => (
                    <MenuItem key={country.name} value={country.dial_code}>
                      {country.dial_code}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <FuseTextField
                type='text'
                name='phone'
                label='Phone'
                labelStyles={labelStyles}
                inputStyles={inputStyles}
                error={error.phonenumber}
                helperText={error.phonenumber_helper_text}
                icon={{ ...baseTextSetting, name: 'phone' }}
                value={onboarding.phone.phoneNumber}
                onChange={onPhoneNumberChange}
                required
                fullWidth
              />
            </Grid>
          </Grid>

          <Button
            type='submit'
            style={{ borderRadius: '50vh' }}
            variant='contained'
            color='primary'
            className='min-w-256 ml-auto mt-16 btn'
            aria-label='Continue'
            disabled={loading}
            onClick={onSubmit}
          >
            Continue
          </Button>
        </form>
      </div>
    </>
  )
}

const styles = theme => ({
  helperText: {
    color: 'rgba(0, 0, 0, 0.46)',
    fontSize: '17px',
    marginBottom: '0'
  },
  table: {
    marginTop: '20px'
  },
  terms: {
    paddingLeft: '0px'
  },
  tableRow: {
    margin: '0px',
    borderStyle: 'hidden'
  },
  tableColumnLeft: {
    color: 'rgba(0, 0, 0, 0.46)',
    paddingLeft: '0px',
    fontSize: '16px'
  },
  countryCode: {
    borderBottom: '1px solid grey',
    height: '100%'
  },
  tableColumnRight: {
    color: 'rgba(0, 0, 0, 0.46)',
    paddingRight: '0px',
    fontSize: '16px'
  }
})
export default withStyles(styles)(OnboardingForm)
