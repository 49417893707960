import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withStyles, Card, CardContent, Typography, Grid } from '@material-ui/core'
import classNames from 'classnames'
import { FuseAnimate, Unauthenticated } from 'core/components'
import * as AuthActions from '../store/actions'
import RefreshTokenForm from '../components/RefreshTokenForm'

const styles = theme => ({
  root: {
    background: 'url(/assets/images/backgrounds/login.png)',
    color: theme.palette.primary.contrastText
  },
  header: {
    marginBottom: '1em'
  },
  logoContainer: {
    top: '20%'
    //marginRight: 'auto'
  },
  leftSectionLogin: {
    maxWidth: '60%'
  },
  leftSectionOnboarding: {
    maxWidth: '50%'
  },
  rightSectionLogin: {
    minWidth: '40%'
  },
  rightSectionOnboarding: {
    minWidth: '50%'
  },
  indicatorContainer: {
    marginLeft: 'auto',
    minWidth: 210
  },
  loginForm: {
    position: 'relative',
    top: '20%',
    width: '100%'
  },
  indicator: {
    display: 'flex',
    minWidth: 210,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 10,
    float: 'right',

    '& .item': {
      width: 30,
      height: 8,
      background: '#ace8b8',
      marginRight: 4,
      cursor: 'pointer',

      '&.active': {
        background: '#3dcd58'
      }
    },

    '& div:first-child': {
      borderTopLeftRadius: '13vh',
      borderBottomLeftRadius: '13vh'
    },

    '& div:last-child': {
      borderTopRightRadius: '13vh',
      borderBottomRightRadius: '13vh'
    }
  }
})

const RefreshToken = ({ classes, refreshToken, auth, resetLoadingState }) => {
  const [password, setPassword] = useState('')

  useEffect(() => {
    resetLoadingState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className={classNames(classes.root, 'flex flex-col flex-1 flex-no-shrink p-24 md:flex-row md:p-0')}>
      <div
        className={classNames(
          auth.onboarding ? classes.leftSectionOnboarding : classes.leftSectionLogin,
          'flex flex-row flex-no-grow items-center text-white p-16 text-center md:p-72 md:flex-no-shrink md:flex-1 md:text-left'
        )}
      >
        <div className='flex flex-col'>
          <FuseAnimate animation='transition.slideUpIn' delay={300}>
            <Typography variant='h3' color='inherit' className='font-light'>
              Welcome to
            </Typography>
          </FuseAnimate>
          <FuseAnimate delay={400}>
            <Typography variant='h2' color='inherit' className='font-light mt-16 font-bold'>
              TRAMÉS ecosystem
            </Typography>
          </FuseAnimate>
        </div>
      </div>

      <FuseAnimate animation={{ translateX: [0, '100%'] }}>
        <Card
          className={classNames(
            auth.onboarding ? classes.rightSectionOnboarding : classes.rightSectionLogin,
            'w-full max-w-md mx-auto m-16 md:m-0 login-form-border'
          )}
          square
        >
          <CardContent className='flex flex-col items-center p-32 md:p-92 h-full'>
            <>
              <Grid container className={classes.header}>
                <Grid item className={classes.logoContainer}>
                  <img src='assets/images/logos/schneider.png' alt='logo' />
                </Grid>
                <Grid item className={classes.indicatorContainer}></Grid>
              </Grid>
              <div className={classes.loginForm}>
                <Typography variant='h4' className='md:w-full mb-32 semi-bold'>
                  Enter Password
                </Typography>
                <div className='flex flex-col items-center justify-center w-full'>
                  <RefreshTokenForm
                    loading={auth.loading}
                    refreshToken={refreshToken}
                    password={password}
                    setPassword={setPassword}
                  />
                </div>
              </div>
            </>
          </CardContent>
        </Card>
      </FuseAnimate>
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  refreshToken: payload => dispatch(AuthActions.refreshToken(payload)),
  resetLoadingState: () => dispatch(AuthActions.resetLoading())
})

export default compose(Unauthenticated, connect(mapStateToProps, mapDispatchToProps))(withStyles(styles)(RefreshToken))
