import React, { useState, useEffect, useRef } from "react";
import { Grid, withStyles } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoIcon from '@material-ui/icons/Info';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as AnalyticActions from '../store/actions';
import Skeleton from "react-loading-skeleton";
import _ from 'lodash';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

const { tableau } = window;

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  icon: {
    color: 'gray',
    fontSize: 20,
    cursor: 'pointer'
  },
  media: {
    height: 160,
  },
  rootCard: {
    margin: 4
  },
  cardContent: {
    padding: '18px 8px'
  }
};
let tempVizObj = null;
let allSheetUrl = [];

const Tableau = (props) => {
  const tableauRef = useRef(null);
  const [tableauCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const { classes, setAllAnalyticsSheets, setAnalyticsAuth } = props;

  const fetchData = async () => {
    let authUrl = '';
    let trusted = localStorage.getItem("trusted") === 'true';

    if (!trusted) {
      let data = await setAnalyticsAuth();
      let tableauToken = _.get(data, 'data.tableauToken', '');
      authUrl = `/trusted/${tableauToken}`;
      if (tableauToken === '') {
        localStorage.setItem("trusted", false);
      } else {
        localStorage.setItem("trusted", true);
      }
    }

    setLoading(true);
    let vizDiv = tableauRef.current;
    let vizURL = `https://analytics.trames.io${authUrl}/views/prod-Analytics-v1_0/TransportOverview-VolumeAnalysis`;

    const options = {
      hideTabs: true,
      hideToolbar: true,
      onFirstInteractive: function () {
        if (tempVizObj) {
          let sheets = tempVizObj.getWorkbook().getPublishedSheetsInfo();
          if (sheets) {
            let tempSheetUrl = sheets.map((sheet) => {
              return {
                'url': sheet.getUrl(),
                'name': sheet.getName()
              }
            });
            setAllAnalyticsSheets(tempSheetUrl);
            allSheetUrl = tempSheetUrl;
          }
          setLoading(false);
        }

      }
    };
    if (tableau) {
      tempVizObj = new tableau.Viz(vizDiv, vizURL, options);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableauCount, setAllAnalyticsSheets, setAnalyticsAuth, tableau]);

  useEffect(() => {
    if (allSheetUrl.length > 0) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid item xs={12}
        className="bg-white rounded-2 mt-auto mb-48 shadow">
        <Grid container className="p-20">
          <Grid item xs={12} className={classes.root} >
            {loading &&
              <Grid container spacing={4}>
                {[1, 2, 3, 4].map(item => {
                  return (
                    <Grid item xs={3} key={item}>
                      <Skeleton height={256} />
                    </Grid>
                  )
                })}
              </Grid>
            }
            <Grid container spacing={4}>
              {allSheetUrl.map((sheet, index) => {
                return (
                  <Grid item xs={3} key={`${index}-sheet`}>
                    <Card className={classes.rootCard}>
                      <CardActionArea className={classes.carActionArea}>
                        <CardMedia className={classes.media}
                          image={`${sheet.url}.png`}
                          title={sheet.name} />
                        <CardContent className={classes.cardContent}>
                          <Typography className="bold m-0" gutterBottom>
                            {sheet.name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                      <CardActions>
                        <Grid container justify="center" alignItems="center">
                          <Grid item>
                            <Link to={`/analytics/${sheet.name}`}>
                              <VisibilityIcon aria-label={sheet.name} className={classes.icon}>
                                <InfoIcon />
                              </VisibilityIcon>
                            </Link>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}


const mapStateToProps = ({ analytics }) => {
  return {
    sheets: analytics.allSheets,
    isAuthorised: analytics.isAuthorised,
  }
}

const mapDispatchToProps = dispatch => ({
  setAllAnalyticsSheets: (sheets) => dispatch(AnalyticActions.setAnalyticsSheets(sheets)),
  setAnalyticsAuth: () => dispatch(AnalyticActions.setAnalyticsAuth()),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Tableau))
