import React from 'react'
import { withStyles, Typography, Grid } from '@material-ui/core'
import { InfoToolTip } from './'
import { filter, get } from 'lodash'
import { general } from '../localization'

const styles = () => ({
})

const ContainerInfoLabel = ({ containers = [], className = '', style = {} }) => {
  const getContainersInfo = () => {
    const activeContainers = filter(containers, item =>
      (get(item, 'status.current.value') !== 'cancelled')
    )
    const cancelledContainers = filter(containers, item =>
      (get(item, 'status.current.value') === 'cancelled')
    )
    const C20GP = filter(containers, { type: 'C20GP' })
    const C40GP = filter(containers, { type: 'C40GP' })
    const C40HC = filter(containers, { type: 'C40HC' })

    return (
      <Grid container alignItems="center"
        spacing={1}
        className="max-w-160">

        <Grid item xs={12} className="border-dashed border-b-1">
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6}
              className="bold text-12 ">
              {activeContainers.length}&nbsp;Active</Grid>
            {cancelledContainers.length > 0 &&
              <Grid item xs={6}
                className="bold text-12 text-right">
                {cancelledContainers.length}&nbsp;Cancelled</Grid>
            }
          </Grid>
        </Grid>

        {C20GP.length > 0 &&
          <Grid item xs={12}>
            <Grid container
              alignItems="center"
              spacing={1}>
              <Grid item xs={8}>{general.container.C20GP}</Grid>
              <Grid item xs={4} className="text-right">{C20GP.length}</Grid>
            </Grid>
          </Grid>
        }

        {C40GP.length > 0 &&
          <Grid item xs={12}>
            <Grid container
              alignItems="center"
              spacing={1}>
              <Grid item xs={8}>{general.container.C40GP}</Grid>
              <Grid item xs={4} className="text-right">{C40GP.length}</Grid>
            </Grid>
          </Grid>
        }

        {C40HC.length > 0 &&
          <Grid item xs={12}>
            <Grid container
              alignItems="center"
              spacing={1}>
              <Grid item xs={8}>{general.container.C40HC}</Grid>
              <Grid item xs={4} className="text-right">{C40HC.length}</Grid>
            </Grid>
          </Grid>
        }
      </Grid>
    )
  }

  return (
    <InfoToolTip title={getContainersInfo()}>
      <Typography className={'medium text-12 max-w-96 ' + className}
        style={style}>
        {containers.length}&nbsp;Containers
      </Typography>
    </InfoToolTip>
  )
}

export default (withStyles(styles)(ContainerInfoLabel))
