import React, { useState } from 'react'
import { FuseSideBar, FuseSideBarHeader, FuseSideBarBody, FuseSideBarSubheader, InfoToolTip, LoadingButton, FuseDialog, FuseDialogHeader, FuseDialogBody } from 'core/components'
import { formatTimestamp } from 'core/helpers'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { Grid, Typography, Select, MenuItem } from '@material-ui/core'
import { filter, get } from 'lodash'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import fileDownload from 'js-file-download'
import PublishIcon from '@material-ui/icons/Publish'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { reUploadShipmentFile, transferDocOwnership, getTTAuthorization } from '../../core/api'
import CloseIcon from '@material-ui/icons/Close';
import SwapVerticalCircleIcon from '@material-ui/icons/SwapVerticalCircle';

const DocumentsListSidebar = props => {
  const {
    open,
    closeSideBar,
    sideBarTitle,
    deleteDocument,
    downloadDocument,
    viewDocument,
    documentsList,
    hasWriteAccess,
    showMessage,
    getDocuments,
    currentPlant,
    falconConfig,
    shipmentActors
  } = props
  const title = `Total ${filter(documentsList, x =>
    x.deleted.enabled === false
  ).length} document(s) uploaded`
  const [reUploadDocument, setReUploadDocument] = useState(null)
  const [reUploadedDocument, setReUploadedDocument] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [actorAnchorEl, setActorAnchorEl] = useState(null);
  const [isTransferringActor, setIsTransferringActor] = useState(false);
  const [requestingAuth, setRequestingAuth] = useState(false);
  const [ttAuthorization, setTtAuthorization] = useState(null);

  const actors = []
  Object.keys(shipmentActors).forEach(key => {
    if (key !== '_id') {
      get(shipmentActors, key, []).forEach(actor => {
        actors.push({
          value: get(actor, '_id'),
          label: `${get(actor, 'type', 'NO_TYPE').toUpperCase()}: ${get(actor, 'name', 'NO_NAME')}`
        })
      })
    }
  })

  const handleDownloadTT = (doc) => {
    let fileName = get(doc, 'originalName', 'NO_NAME')
    fileName = fileName.split('.').slice(0, -1).join('.')
    fileName = `${fileName}_${get(doc, 'type', 'NO_TYPE')}.tt`
    fileDownload(JSON.stringify({ ttSchema: get(doc, 'falcon.data.ttSchema', {}) }), fileName)
  }

  const handleFileUpload = (e) => {
    const { files } = e.target
    setReUploadedDocument(files[0])
  }

  const handleTransferOwnership = async (doc, plantId) => {
    setActorAnchorEl(null)
    const { shipment, docStoreId } = doc
    const formData = new FormData()
    formData.append('document', reUploadedDocument)
    setIsTransferringActor(true)
    try {
      const { ok, data, status } = await transferDocOwnership(shipment, docStoreId, { plantId })
      if (!ok) {
        throw new Error(data.message)
      }
      await getDocuments(shipment, true)
      showMessage({ data, status })
      setIsTransferringActor(false)
    } catch (e) {
      showMessage(e)
      setIsTransferringActor(false)
    }
  }

  const handleUploadFile = async () => {
    if (reUploadDocument) {
      const { shipment, type, docStoreId } = reUploadDocument
      const formData = new FormData()
      formData.append('document', reUploadedDocument)
      setIsUploading(true)
      try {
        const { ok, data, status } = await reUploadShipmentFile(shipment, type, docStoreId, formData)
        if (!ok) {
          throw new Error(data.message)
        }
        await getDocuments(shipment, true)
        showMessage({ data, status })
        setReUploadDocument(null)
        setIsUploading(false)
      } catch (e) {
        showMessage(e)
        setIsUploading(false)
      }
    }
  }

  const handleSubmitTTVerify = async (document) => {
    const { shipment, docStoreId } = document
    setRequestingAuth(true)
    setTtAuthorization(null)
    const { ok, data } = await getTTAuthorization(shipment, docStoreId)
    if (ok) {
      setTtAuthorization({
        ttSchema: get(document, 'falcon.data.ttSchema', ''),
        authKey: get(data, 'data.authorization', '')
      })
      setRequestingAuth(false)
    }
  }

  return (
    <React.Fragment>
      <FuseSideBar open={open}
        onBackDropClick={closeSideBar} wSM>
        <FuseSideBarHeader text={sideBarTitle}
          handleClose={closeSideBar} />
        <FuseSideBarSubheader text={title} />
        <FuseSideBarBody>
          {filter(documentsList, x => x.deleted.enabled === false)
            .map((data, index) => {
              return (
                <div key={`doc_item_${index}`}>
                  <Grid container alignItems="center" justify="space-between" spacing={1} className='documents-sidebar-row'>
                    <Grid item>
                      <img alt="file" className='file-icon' src='/assets/images/icons/document-upload.svg' />
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                              <Typography className="bold" noWrap>
                                {data.originalName || data.docStoreId}
                              </Typography>
                            </Grid>
                            {get(data, 'falcon.available') === true &&
                              <Grid item>
                                <Grid container alignItems="center">
                                  <InfoToolTip title="View TT verification">
                                    <CheckCircleIcon className="fg-blue text-16 cursor-pointer"
                                      onClick={() => !requestingAuth && handleSubmitTTVerify(data)} />
                                  </InfoToolTip>
                                </Grid>
                              </Grid>
                            }
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className='text-grey regular text-11'>
                            {formatTimestamp(data.uploadedAt, 'date-time')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid container justify="flex-end" spacing={2} alignItems="center">
                        {get(data, 'falcon.available') === true &&
                          <Grid item>
                            <Grid container alignItems="center">
                              <InfoToolTip title="Download TT document">
                                <SystemUpdateAltIcon className="fg-blue cursor-pointer text-20"
                                  onClick={() => handleDownloadTT(data)} />
                              </InfoToolTip>
                            </Grid>
                          </Grid>
                        }
                        <Grid item>
                          <Grid container alignItems="center">
                            <InfoToolTip title="Download this document">
                              <img alt="file"
                                className='file-icon'
                                src='/assets/images/icons/import-header-green.svg'
                                onClick={() => downloadDocument(data)} />
                            </InfoToolTip>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <InfoToolTip title="View this document">
                            <Grid container alignItems="center">
                              <VisibilityOutlinedIcon className="fg-green cursor-pointer text-20"
                                onClick={() => viewDocument(data)} />
                            </Grid>
                          </InfoToolTip>
                        </Grid>
                        {get(currentPlant, '_id') === get(data, 'uploadedBy.ownership._id', get(data, 'uploadedBy.plant._id')) &&
                          <Grid item>
                            <Grid container alignItems="center">
                              <InfoToolTip title="Re-upload this document">
                                <PublishIcon className="fg-orange cursor-pointer text-20"
                                  onClick={() => {
                                    if (!isUploading) {
                                      setReUploadedDocument(null)
                                      setReUploadDocument(data)
                                    }
                                  }} />
                              </InfoToolTip>
                            </Grid>
                          </Grid>
                        }
                        {/* Update condition for allowing transfer */}
                        {false &&
                          <Grid item>
                            <Grid container alignItems="center" className="relative">
                              <InfoToolTip title="Transfer ownership for this document">
                                <SwapVerticalCircleIcon className="fg-gray cursor-pointer text-20"
                                  aria-controls="actors-menu" aria-haspopup="true" onClick={() => !isTransferringActor && setActorAnchorEl(data)} />
                              </InfoToolTip>
                              {get(actorAnchorEl, '_id') === get(data, '_id') &&
                                <Select id="actors-menu"
                                  anchorEl={actorAnchorEl}
                                  keepMounted
                                  open={get(actorAnchorEl, '_id') === get(data, '_id')}
                                  className="absolute pin-t pin-r"
                                  onChange={(e) => handleTransferOwnership(data, e.target.value)}
                                  onClose={() => setActorAnchorEl(null)}
                                  value={get(data, 'uploadedBy.ownership._id', get(data, 'uploadedBy.plant._id'))}>
                                  {actors.map((act, actIndex) => {
                                    return (
                                      <MenuItem key={actIndex} value={get(act, 'value')}>
                                        {get(act, 'label', '')}
                                      </MenuItem>
                                    )
                                  })}
                                </Select>
                              }
                            </Grid>
                          </Grid>
                        }
                        {hasWriteAccess &&
                          <Grid item>
                            <Grid container alignItems="center">
                              <InfoToolTip title="Delete this document">
                                <img alt="file"
                                  className='file-icon'
                                  src='/assets/images/icons/Delete-consignee-route@3x.svg'
                                  onClick={() => deleteDocument(data)} />
                              </InfoToolTip>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                    {get(reUploadDocument, '_id') === get(data, '_id') &&
                      <Grid item xs={12}>
                        <Grid container justify="flex-end" alignItems="center" spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Grid container>
                              <Grid item xs={12}>
                                <Grid container className="border-1 border-blue relative p-4 bg-light-blue" alignItems="center" justify="center">
                                  <input type="file" className="h-full w-full opacity-0 absolute pin-t pin-l"
                                    disabled={isUploading}
                                    onChange={handleFileUpload} />
                                  <InsertDriveFileIcon className="fg-gray text-20 mr-12" />
                                  <Typography className="semi-bold fg-gray text-12 text-center">
                                    {!reUploadedDocument &&
                                      `Re-Upload this (${get(reUploadDocument, 'originalName')}) ${get(reUploadDocument, 'type')}`
                                    }
                                    {reUploadedDocument &&
                                      `Uploading ${get(reUploadedDocument, 'name')} for (${get(reUploadDocument, 'originalName')}) ${get(reUploadDocument, 'type')}`
                                    }
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={11} sm={2} md={2}>
                            <LoadingButton className="btn btn_sm btn_full-space"
                              loading={isUploading}
                              onClick={handleUploadFile}>
                              upload</LoadingButton>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center">
                              <CloseIcon className="fg-gray text-24 cursor-pointer"
                                onClick={() => !isUploading && setReUploadDocument(null)} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </div>
              )
            })
          }

          {filter(documentsList, x => x.deleted.enabled === true).length > 0 &&
            <Typography className='bold fg-gray mt-20 mb-16'>
              Deleted Documents</Typography>
          }

          {filter(documentsList, x => x.deleted.enabled === true)
            .map((data, index) => (
              <div key={`doc_item_${index}`}>
                <Grid container
                  alignItems="center"
                  spacing="1"
                  className='deleted-documents-sidebar-row'>
                  <Grid item>
                    <img alt="file"
                      className='file-icon'
                      src='/assets/images/icons/document-upload.svg' />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography className="bold fg-gray">
                          {data.originalName || data.docStoreId}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className='text-grey regular text-11'>
                          {formatTimestamp(data.uploadedAt, 'date-time')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ))
          }
        </FuseSideBarBody>
      </FuseSideBar>

      <FuseDialog open={ttAuthorization !== null} wSM>
        <FuseDialogHeader text="Confirmation" handleClose={() => setTtAuthorization(null)} />
        <FuseDialogBody>
          <Grid container className="px-20 pt-20" spacing={5}>
            <Grid item xs={12}>
              <Typography className="regular">Are you sure?</Typography>
              <Typography className="regular">You are about to leave this platform.</Typography>
            </Grid>
            <Grid item xs={12}>
              <form action={get(falconConfig, 'url', '')} method="post" className="m-0 p-0" target="_blank">
                <input name="ttSchema" defaultValue={JSON.stringify(get(ttAuthorization, 'ttSchema'))} hidden />
                <input name="Authorization" defaultValue={get(ttAuthorization, 'authKey', '')} hidden />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <input type="submit" value="Confirm"
                      className="btn btn_sm w-full cursor-pointer"
                      onClick={() => setTimeout(() => { setTtAuthorization(null) }, 2000)} />
                  </Grid>
                  <Grid item xs={6}>
                    <input type="reset" value="Cancel"
                      className="btn btn_sm btn_light_red w-full cursor-pointer"
                      onClick={() => setTtAuthorization(null)} />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </FuseDialogBody>
      </FuseDialog>

    </React.Fragment>
  )
}

export default DocumentsListSidebar;

