import React, { useState, useEffect } from 'react'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseCheckBox,
  FusePlaceHolder,
  LoadMore,
  FuseSideBarActions,
  LoadingButton
} from '../../core/components'
import { Grid, Typography } from '@material-ui/core'
import { get } from 'lodash'
import {
  shipmentNameBeautifier,
  stopSearching,
  startSearching
} from '../../core/helpers'
import Skeleton from 'react-loading-skeleton'

const AddToSidebar = ({ open, handleClose, selectedEmails, shipments, loading, loadingMore, hasMore, onSearch, onLoadMore, onAddToShipment, requesting }) => {
  const [search, setSearch] = useState('')
  const [selectedShipmentId, setSelectedShipmentId] = useState(null)

  const handleSearch = (e) => {
    const { value } = e.target

    setSearch(value)
    if (search !== value) {
      stopSearching()
      startSearching(500, () => {
        onSearch(value)
      })
    }
  }

  useEffect(() => {
    return () => {
      setSearch('')
      setSelectedShipmentId(null)
    }
  }, [])

  return (
    <FuseSideBar open={open}>
      <FuseSideBarHeader text='Add to existing shipment'
        handleClose={handleClose} />
      <FuseSideBarBody style={{ padding: 0 }}>
        <Grid container>

          <Grid item xs={12} className="bg-gray py-12 px-20">
            <Grid container justify="space-between" spacing={1} alignItems="center">
              <Grid item>
                <Typography className="text-14 medium fg-gray">
                  You have selected</Typography>
              </Grid>
              <Grid item>
                <Typography className="text-14 medium fg-gray">
                  {selectedEmails.length} email(s)</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className="py-12 px-20">

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <input placeholder="Search your shipment"
                  value={search}
                  onChange={handleSearch}
                  className="w-full px-8 py-12 border-1 border-gray rounded" />
              </Grid>

              {loading &&
                [1, 2, 3].map(i =>
                  <Grid item xs={12} key={i}><Skeleton height={40} /></Grid>
                )
              }

              {!loading && shipments.length > 0 && shipments.map((item, itemIndex) => {
                const shipmentId = get(item, '_id')
                const isSelected = shipmentId === selectedShipmentId

                return (
                  <Grid item xs={12} key={itemIndex}>
                    <Grid container className={'p-8 border-1 rounded cursor-pointer ' +
                      (isSelected ? 'border-green bg-transparent-green' : 'border-gray')}
                      onClick={() => setSelectedShipmentId(shipmentId)}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <FuseCheckBox checked={isSelected} />
                          </Grid>
                          <Grid item xs>
                            <Grid container>
                              <Grid item xs={12} className="pt-4">
                                <Typography className="text-14 semi-bold">
                                  {shipmentNameBeautifier(get(item, 'name', ''))}</Typography>
                              </Grid>
                              <Grid item xs={12} className="pt-6">
                                <Typography className="text-12 semi-bold text-grey-dark">
                                  {get(item, 'summary.latestActualEvent.name')}</Typography>
                              </Grid>
                              <Grid item xs={12} className="pt-4">
                                <Grid container spacing={1} className="fg-gray" alignItems="center">
                                  <Grid item>
                                    <Typography className="text-12 regular fg-gray uppercase">
                                      {get(item, 'type')}</Typography>
                                  </Grid>
                                  <Grid item>&bull;</Grid>
                                  <Grid item>
                                    <Typography className="text-12 regular fg-gray uppercase">
                                      {get(item, 'weight.value', 0).toFixed(3)}&nbsp;
                                      {get(item, 'weight.unit', 'KG')},&nbsp;
                                      {get(item, 'volume.value', 0).toFixed(3)}&nbsp;
                                      {get(item, 'volume.unit', 'M3')}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}

              {!loading && shipments.length === 0 &&
                <Grid item xs={12}>
                  <FusePlaceHolder title="No Shipments"
                    description="Looks like you don't have any shipments" />
                </Grid>
              }

              {!loading && hasMore &&
                <Grid item xs={12}>
                  <Grid container justify="center">
                    <LoadMore loading={loadingMore} onClick={() => onLoadMore(search)} />
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>

        </Grid>
      </FuseSideBarBody>
      <FuseSideBarActions>
        <LoadingButton className='btn btn_full-space'
          disabled={!selectedEmails || !selectedShipmentId || loading || loadingMore}
          loading={requesting}
          onClick={() => onAddToShipment(selectedShipmentId)}>
          add</LoadingButton>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

export default AddToSidebar
