import ActionTypes from './types'
import { uniqBy } from "lodash"

const initialState = {
  initialLoading: true,
  loading: true,
  shipments: [],
  shipmentsTotal: 0,
  showMore: false,
  outboundsByShipment: []
}

const cancelledReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ALL_CANCELLED_SHIPMENTS:
      return {
        ...state,
        shipments: action.payload.reset ?
          action.payload.data :
          uniqBy([...state.shipments, ...action.payload.data]),
        showMore: action.payload.showMore,
        loading: !!action.payload.loading,
        initialLoading: action.payload.reset ? state.initialLoading : false
      }
    case ActionTypes.SET_ALL_CANCELLED_SHIPMENTS_TOTAL:
      return {
        ...state,
        shipmentsTotal: action.payload
      }
    case ActionTypes.GET_OUTBOUNDS_BY_SHIPMENT_SUCCESS: {
      return {
        ...state,
        outboundsByShipment: action.payload
      }
    }
    default:
      return state
  }
}

export default cancelledReducer
