import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

const GreenSwitch = withStyles({
  switchBase: {
    color: '#FFFFFF',
    '&$checked': {
      color: '#3DCD58',
    },
    '&$checked + $track': {
      backgroundColor: '#3DCD58',
    },
  },
  checked: {},
  track: {},
})(Switch);

const CustomSwitch = (props) => {
  const { name, value, checked, onChange } = props
  const switchProps = {
    name,
    value,
    checked,
    onChange: () => onChange(name, value)
  }

  return <GreenSwitch {...switchProps} />
}

export default CustomSwitch
