import React, { useEffect } from 'react'
import { get, pick, find, set } from 'lodash'
import { useMergeState } from '../../core/helpers'
import ReviewExceptionDialog from './ReviewExceptionDialog'
import ResolveExceptionDialog from './ResolveExceptionDialog'

const ResolveException = (props) => {
  const [state, setState] = useMergeState({})
  const {
    plants,
    shipment,
    clearPropShipment,
    onResolveConflict,
    onMarkAsSpot,
    onResolveSpot,
    getAllShipments
  } = props

  useEffect(() => {
    if (shipment) {
      const bookingDraftWIP = find(
        get(shipment, 'exceptionMode.details', []),
        { type: "bookingDraftWIP" }
      )
      if (bookingDraftWIP) {
        var derivedShipmentData = {
          ...get(
            bookingDraftWIP,
            'data.bookingDraft.derivedShipmentData',
            {}
          ),
          ...pick(
            get(
              bookingDraftWIP,
              'data.bookingDraft.baseShipmentData',
              {}
            ),
            ['type', 'transportMethod', 'containers', 'referenceEvents']
          )
        }
        const goodsType = get(shipment, 'goodsType', [])
        derivedShipmentData.goodsType = [
          ...typeof goodsType === 'string' ? [goodsType] : goodsType,
          ...get(shipment, 'specialLabels', [])
        ]

        const dialogParams = {
          shipment: {
            id: shipment._id,
            ...derivedShipmentData
          },
          ...pick(
            get(bookingDraftWIP, 'data.bookingDraft', {}),
            ['exceptions', 'matchedServiceCards']
          )
        }

        // NOTE: Setting shipment.actors.shipper[0] as dialogParams.shipment.shipper
        if (!get(dialogParams, 'shipment.shipper._id')) {
          set(dialogParams, 'shipment.shipper', get(shipment, 'actors.shipper[0]'))
        }

        openDialog('reviewDialog', {
          ...dialogParams,
          handleResolve: () => {
            closeDialog('reviewDialog')
            openDialog('resolveDialog', {
              ...dialogParams,
              plants,
              onResolveConflict,
              onMarkAsSpot,
              onResolveSpot,
              getAllShipments
            })
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  const openDialog = (name, data) => {
    setState({
      [name]: {
        open: true,
        handleClose: () => closeDialog(name),
        ...data
      }
    })
  }

  const closeDialog = (name) => {
    setState({
      [name]: {
        open: false,
      }
    })
    clearPropShipment()
  }

  return (
    <>
      <ReviewExceptionDialog {...get(state, 'reviewDialog', { open: false })} />
      <ResolveExceptionDialog {...get(state, 'resolveDialog', { open: false })} />
    </>
  )
}

export default ResolveException
