import React from 'react'
import { withStyles } from '@material-ui/core'
import { FuseIconButton } from 'core/components'

const LoadMore = ({ classes, loading, text, className, onClick }) => (
  <div className='flex justify-center py-16'>
    {loading && (
      <FuseIconButton className={classes.root} disabled={true} variant='outline'>
        Loading...
      </FuseIconButton>
    )}
    {!loading && (
      <FuseIconButton className={classes.root} variant='outline' onClick={onClick}>
        {text || 'Load More...'}
      </FuseIconButton>
    )}
  </div>
)

const styles = theme => ({
  root: {
    cursor: 'pointer',
    textTransform: 'capitalize'
  }
})

export default withStyles(styles)(LoadMore)
