import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  LOAD_INCOMPLETE_TASKS
  LOADING_MORE_INCOMPLETE_TASKS
  LOAD_MORE_INCOMPLETE_TASKS

  LOAD_BLOCKED_TASKS
  LOADING_MORE_BLOCKED_TASKS
  LOAD_MORE_BLOCKED_TASKS

  LOAD_COMPLETED_TASKS
  LOADING_MORE_COMPLETED_TASKS
  LOAD_MORE_COMPLETED_TASKS

  LOAD_TEMPLATE_TASKS
  LOAD_MORE_TEMPLATE_TASKS

  LOAD_SHIPMENTS_TASKS
  LOAD_SHIPMENT_POSTS
  LOAD_ALL_SHIPMENTS
  LOAD_CONTROLLER_DICTIONARY
  LOAD_ALL_EVENTS
  LOAD_ALL_USERS
  LOAD_SHIPMENT_DETAILS
  LOAD_SHIPMENT_META
  LOAD_SHIPMENT_EVENT_WRITE_LIST
  LOAD_EXPAND_SHIPMENT_TASKS
  LOAD_EVENT_DOC_LIST
  LOADING_FILTER_SEARCH_SHIPMENTS
  LOAD_FILTER_SEARCH_SHIPMENTS
  `
)
