import React, { Component } from 'react'
import classNames from 'classnames'
import { ButtonGroup, withStyles } from '@material-ui/core'
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay'
import ReorderIcon from '@material-ui/icons/Reorder'
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout'
import { connect } from 'react-redux'
import * as ShipmentActions from '../store/actions'
import * as ShipmentDetailsActions from '../../shipment-details/store/actions'
import ShipmentCard from 'core/components/shipment-card/ShipmentCard'
import ReviewBookingDialog from '../components/ReviewBookingDialog'
import ShipperReviewBookingDialog from '../components/ShipperReviewBookingDialog'
import RejectBookingDialog from '../components/RejectBookingDialog'
import FPAcceptBookingDialog from '../components/FPAcceptBookingDialog'
import ELPAcknowledgeBookingDialog from '../components/ELPAcknowledgeBookingDialog'
import _, { get } from 'lodash'
import PartnersSidebar from 'core/components/PartnersSidebar'
import CSOutboundsSidebar from '../../core/components/CSOutboundsSidebar'
import {
  LoadMore,
  FuseIconButton,
  FusePlaceHolder,
  ManualNotification
} from '../../core/components'
import ShipmentListSkeleton from '../../core/components/Skeletons/ShipmentListSkeleton'
import ShipmentListFilterSkeleton from '../../core/components/Skeletons/ShipmentListFilterSkeleton'
import FilterRow from '../../core/components/Filters/shipment-filter/FilterRow'
import * as CoreActions from '../../core/store/actions'
import DocUploadSidebar from '../components/FileUploadDialog'
import NewBoookingDialog from '../../manage-booking/components/NewBoookingDialog'
import * as BookingActions from 'manage-booking/store/actions'
import {
  setShipmentListFilters,
  getShipmentListFilters,
  startSearching,
  stopSearching
} from '../../core/helpers'
import IOSSwitchComponent from '../../core/components/switches/IOSSwitchComponent';

class Schedule extends Component {
  state = {
    requesting: false,
    manualNotificationSidebar: {
      open: false,
      shipment: null,
      plantOptions: [],
      usersByPlantIds: [],
    },
    reviewDialog: {
      open: false,
      shipment: {}
    },
    shipperReviewDialog: {
      open: false,
      shipment: {}
    },
    rejectDialog: {
      open: false,
      shipment: {}
    },
    freightPartnerAcceptDialog: {
      open: false,
      shipment: {}
    },
    elpAcknowledgeBookingDialog: {
      open: false,
      shipment: {}
    },
    partnerSidebar: {
      open: false,
      actors: {}
    },
    outboundsSidebar: {
      open: false,
      outbounds: [],
      consolidations: []
    },
    customFilter: null,
    status: null,
    search: '',
    sort: null,
    minified: [],
    docUploadSidebar: {
      open: false,
      shipment: {}
    },
    amendBookingDialog: {
      open: false,
      selectedShipment: {}
    },
    bookingMode: 'edit'
  }

  pagination = {
    pageNo: 1
  }

  setRequesting = (requesting) => {
    this.setState({
      requesting
    })
  }

  applyCustomFilter = customFilter => {
    this.pagination.pageNo = 1
    setShipmentListFilters(
      _.get(this.props, 'userInfo._id'),
      _.get(this.props, 'currentPlant._id'),
      'scheduled',
      customFilter
    )
    this.setState({ customFilter }, this.getAllShipments)
  }

  getStatusArray = status => {
    switch (status) {
      case 'pending':
        return ['pending']
      case 'amended':
        return ['amended']
      case 'rejected':
        return ['freight-partner-rejected', 'obl-rejected']
      case 'confirmed_by_efp':
        return ['freight-partner-confirmed']
      case 'confirmed_by_elp':
        return ['obl-confirmed']
      default:
        return undefined
    }
  }

  handleStatusChange = status => {
    this.setState({ status }, this.getAllShipments)
  }

  handleSortChange = sort => {
    this.setState({ sort }, this.getAllShipments)
  }

  handleSearchChange = event => {
    const { value } = event.target
    const { search } = this.state

    this.setState({ search: value },
      () => {
        if (search !== value) {
          stopSearching()
          startSearching(1000, () => {
            this.pagination.pageNo = 1
            this.getAllShipments()
          })
        }
      }
    )
  }

  handleFilterChange = filters => {
    this.pagination.pageNo = 1
    this.setState({ filters }, this.getAllShipments)
  }

  onReviewOpen = shipment => {
    this.setState({
      reviewDialog: {
        open: true,
        shipment
      }
    })
  }

  onReviewClose = () => {
    this.setState({
      reviewDialog: {
        open: false,
        shipment: {}
      }
    })
  }

  onShipperReviewOpen = shipment => {
    this.setState({
      shipperReviewDialog: {
        open: true,
        shipment
      }
    })
  }

  onShipperReviewClose = () => {
    this.setState({
      shipperReviewDialog: {
        open: false,
        shipment: {}
      }
    })
  }

  onRejectOpen = shipment => {
    this.setState({
      reviewDialog: {
        open: false,
        shipment: {}
      },
      rejectDialog: {
        open: true,
        shipment
      },
      freightPartnerAcceptDialog: {
        open: false,
        shipment: {}
      }
    })
  }

  onRejectClose = () => {
    this.setState({
      rejectDialog: {
        open: false,
        shipment: {}
      }
    })
  }

  onRejectSubmit = (payload, shipmentId) => {
    this.setRequesting(true)
    const { freightPartnerRejectBooking } = this.props
    freightPartnerRejectBooking(payload, shipmentId,
      () => {
        this.onRejectClose()
        this.setRequesting(false)
      },
      () => {
        this.setRequesting(false)
      })
  }

  onFreightPartnerOpen = shipment => {
    this.setState({
      reviewDialog: {
        open: false,
        shipment: {}
      },
      rejectDialog: {
        open: false,
        shipment: {}
      },
      freightPartnerAcceptDialog: {
        open: true,
        shipment
      }
    })
  }

  onFreightPartnerAcceptSubmit = (shippingData, containerScheduleData, bookingData, shipmentContext) => {
    const documentCount = _.get(bookingData, 'documentUploads', []).length
    let uploadedDocumentCount = 0

    const { freightPartnerAcceptBooking, uploadShipmentFile } = this.props
    const payload = {
      referenceNos: {
        bookingReferenceNo: _.get(bookingData, 'bookingDetails.bookingRefNumber', ''),
        carrierBookingNo: _.get(bookingData, 'bookingDetails.carrierBookingNumber', ''),
        HBLNo: _.get(bookingData, 'bookingDetails.hblNumber', '').split(','),
        MBLNo: _.get(bookingData, 'bookingDetails.mblNumber', '').split(',')
      },
      freightOperatorInfo: {
        name: _.get(shippingData, 'carrierName', ''),
        identifier: {
          type: 'SCAC',
          value: _.get(shippingData, 'scacCode', '')
        }
      },
      referenceEvents: {
        documentDeadlineDate: _.get(bookingData, 'siCutOffDate', '')
      },
      vesselSchedule: _.get(shippingData, 'vesselSchedule', []),
      note: _.get(bookingData, 'note', ''),
      documentDeadlineDate: _.get(bookingData, 'siCutOffDate', '')
    }

    if (_.get(shipmentContext, 'type') === 'FCL') {
      payload.containers = _.get(containerScheduleData, 'containers', [])
    }

    if (_.get(shipmentContext, 'type') === 'LCL') {
      payload.referenceEvents = {
        ...payload.referenceEvents,
        cargoDropOffAtCFS: {
          expectedAt: _.get(bookingData, 'packageSubmissionSchedule.dateTime', ''),
          location: {
            ..._.get(bookingData, 'packageSubmissionSchedule.packageSubmissionAddress', {}),
            floorUnitNumber: _.get(bookingData, 'packageSubmissionSchedule.additionalAddress', '')
          }
        }
      }
    }

    if (_.get(shippingData, 'hasUnderlyingCarrier')) {
      _.set(payload, 'freightOperatorInfo.name', _.get(shippingData, 'underlyingCarrierName', ''))
      _.set(payload, 'freightOperatorInfo.identifier.value', _.get(shippingData, 'underlyingScacCode', ''))
      _.set(payload, 'freightOperatorInfo.secondaryIdentifier.type', 'SCAC')
      _.set(payload, 'freightOperatorInfo.secondaryIdentifier.value', _.get(shippingData, 'scacCode', ''))
    }

    _.get(bookingData, 'documentUploads', []).forEach(async doc => {
      this.setRequesting(true)
      const uploaded = await uploadShipmentFile(shipmentContext._id, doc.type, doc.payload)

      if (uploaded) {
        uploadedDocumentCount++
      }

      if (documentCount === uploadedDocumentCount) {
        freightPartnerAcceptBooking(payload, shipmentContext._id,
          () => {
            this.onFreightPartnerClose()
            this.setRequesting(false)
          },
          () => {
            this.setRequesting(false)
          }
        )
      } else {
        this.setRequesting(false)
      }
    })
  }

  onFreightPartnerClose = () => {
    this.setState({
      freightPartnerAcceptDialog: {
        open: false,
        shipment: {}
      }
    })
  }

  onLogisticsPartnerOpen = (shipment, note) => {
    this.setState({ note })
    this.onElpAcknowledgeOpen(shipment)
  }

  onLogisticsPartnerAcceptSubmit = (acknowledgeData, shipment) => {
    this.setRequesting(true)
    const { logisticsPartnerAcceptBooking } = this.props
    const { note } = this.state

    var payload = {
      note
    }

    if (_.get(shipment, 'type') === 'FCL') {
      payload.containers = _.get(acknowledgeData, 'containers', [])
    }

    if (_.get(shipment, 'type') === 'LCL') {
      payload.referenceEvents = _.get(acknowledgeData, 'referenceEvents', {})
    }

    logisticsPartnerAcceptBooking(shipment._id, payload,
      () => {
        this.onElpAcknowledgeClose()
        this.setRequesting(false)
      },
      () => {
        this.setRequesting(false)
      }
    )
  }

  onPartnerClick = actors => {
    this.setState({
      partnerSidebar: {
        open: true,
        actors
      }
    })
  }

  onPartnerSidebarClose = () => {
    this.setState({
      partnerSidebar: {
        open: false,
        actors: {}
      }
    })
  }

  onOutboundsClick = async shipment => {
    this.setState({
      outboundsSidebar: {
        open: true,
        outbounds: [],
        consolidations: shipment.consolidations
      }
    })
    const { getOutboundsByShipment } = this.props

    await getOutboundsByShipment(shipment._id)
    const { outboundsByShipment } = this.props
    this.setState({
      outboundsSidebar: {
        open: true,
        outbounds: outboundsByShipment,
        consolidations: shipment.consolidations
      }
    })
  }

  onOutboundsSidebarClose = () => {
    this.setState({
      outboundsSidebar: {
        open: false,
        outbounds: [],
        consolidations: []
      }
    })
  }

  getAllShipments = (isSync = false) => {
    const { getAllShipments } = this.props
    const { status, customFilter, search, sort } = this.state
    let filter = null
    if (customFilter) {
      filter = customFilter.value
      if (filter && filter.groupage && filter.groupage.type === 'none') {
        delete filter.groupage
      }
    }
    getAllShipments(this.getStatusArray(status), undefined, filter, this.pagination, search, sort, isSync)
  }

  onLoadMore = () => {
    this.pagination.pageNo += 1
    this.getAllShipments()
  }

  onElpAcknowledgeOpen = shipment => {
    this.setState({
      reviewDialog: {
        open: false,
        shipment: {}
      },
      elpAcknowledgeBookingDialog: {
        open: true,
        shipment
      }
    })
  }

  onElpAcknowledgeClose = () => {
    this.setState({
      elpAcknowledgeBookingDialog: {
        open: false,
        shipment: {}
      }
    })
  }

  setAllMinifiedState = val => {
    let minified = {}
    this.props.shipments.forEach(shipment => {
      minified[shipment._id] = val
    })
    this.setState({ minified })
  }

  expandAll = () => {
    this.setAllMinifiedState(false)
  }

  collapseAll = () => {
    this.setAllMinifiedState(true)
  }

  openDocUploadSidebar = shipment => {
    const { getShipmentMeta } = this.props

    this.setState({
      docUploadSidebar: {
        open: true,
        shipment
      }
    })
    getShipmentMeta(shipment._id, () => {
      const { shipments } = this.props

      this.setState({
        docUploadSidebar: {
          open: true,
          shipment: _.find(shipments, { _id: shipment._id })
        }
      })
    })
  }

  closeDocUploadSidebar = (...params) => {
    this.setState({
      docUploadSidebar: {
        open: false,
        shipment: {}
      }
    })
  }

  onUploadFiles = (...params) => {
    const { upload } = this.props

    upload(...params)
  }

  onAmendBookingOpen = (shipment, forChangeBooking = false) => {
    const { initialPrep } = this.props
    this.onShipperReviewClose()
    this.setState({
      amendBookingDialog: {
        open: true,
        selectedShipment: shipment,
        data: initialPrep,
        forChangeBooking,
        selectedTransportMethod: get(shipment, 'transportMethod', ''),
      }
    })
  }

  onAmendBookingClose = () => {
    this.setState({
      amendBookingDialog: {
        open: false,
        shipmentDetails: {},
        data: {}
      }
    })
  }

  bookingAmend = (shipment, mode = 'amend', callback = null) => {
    const { editShipment } = this.props
    const shipmentId = shipment._id

    editShipment(shipmentId, shipment, mode,
      () => {
        this.getAllShipments()
        this.onAmendBookingClose()
        if (callback) {
          callback()
        }
      }, callback)
  }

  resetTimer = () => {
    let timer = setTimeout(() => {
      this.getAllShipments(true)
      this.resetTimer()
    }, 30000)
    clearTimeout(this.timer)
    this.timer = timer
  }

  registerTimer = () => {
    document.addEventListener('mousemove', this.resetTimer)
    document.addEventListener('click', this.resetTimer)
    document.addEventListener('keypress', this.resetTimer)
  }

  getPlantFromActors = (shipment, actor) => {
    return _.get(shipment, `actors.${actor}`, []).map(item => ({
      ..._.pick(item, ['_id', 'name', 'type'])
    }))
  }

  onNotifyOpen = (shipment) => {
    this.setState({
      manualNotificationSidebar: {
        open: true,
        shipment,
        selectedPlant: 'ofp'
      }
    })
  }

  componentWillUnmount() {
    clearTimeout(this.timer)
    document.removeEventListener('mousemove', this.resetTimer)
    document.removeEventListener('click', this.resetTimer)
    document.removeEventListener('keypress', this.resetTimer)
  }

  componentDidMount() {
    const { userInfo, currentPlant, getVessels, autoRefreshStatus } = this.props
    const customFilter = getShipmentListFilters(
      _.get(userInfo, '_id'),
      _.get(currentPlant, '_id'),
      'scheduled'
    )

    if (customFilter) {
      this.setState({
        customFilter
      }, this.getAllShipments)
    } else {
      this.getAllShipments()
    }
    if (_.get(currentPlant, 'type') === 'ofp') {
      getVessels()
    }

    /* For checking that is the auto refresh or when component is mounted */
    if (autoRefreshStatus) {
      this.registerTimer();
      this.resetTimer();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.shipments !== prevProps.shipments) {
      let minified = {}
      this.props.shipments.forEach(shipment => {
        minified[shipment._id] = this.state.minified.hasOwnProperty(shipment._id) ? this.state.minified[shipment._id] : true
      })
      this.setState({ minified })
    }
    if (this.props.autoRefreshStatus !== prevProps.autoRefreshStatus) {
      this.setToggleAutoRefresh(this.props.autoRefreshStatus)
    }
  }

  /**
  * Function to set AutoRefreshStatus
  * @param status boolean
  */
  setToggleAutoRefresh = status => {
    this.props.setAutoRefreshStatus(status);

    if (status) {
      this.registerTimer();
      this.resetTimer();
    } else {
      if (this.timer) {
        clearTimeout(this.timer)
        document.removeEventListener('mousemove', this.resetTimer)
        document.removeEventListener('click', this.resetTimer)
        document.removeEventListener('keypress', this.resetTimer)
      }
    }
  }
  render() {
    const {
      initialLoading,
      loading,
      shipments,
      currentPlant,
      showShipmentsMore,
      getShipmentFilterOptions,
      classes,
      shipmentCardPrimaryId,
      amendShipmentWithoutChange,
      shipmentsTotal,
      getUsersByPlantIds,
      sendShipmentNotification,
      getVessels,
      autoRefreshStatus,
      getLimitedServiceCards,
      limitedServiceCards,
      resolveFpConflicts
    } = this.props
    const {
      requesting,
      reviewDialog,
      shipperReviewDialog,
      rejectDialog,
      freightPartnerAcceptDialog,
      elpAcknowledgeBookingDialog,
      partnerSidebar,
      outboundsSidebar,
      minified,
      search,
      docUploadSidebar,
      amendBookingDialog,
      manualNotificationSidebar
    } = this.state
    const atleastOneMinified = shipments.reduce((total, currentValue) => total || minified[currentValue._id], false)
    const allMinified = shipments.reduce((total, currentValue) => total && minified[currentValue._id], true)

    return (
      <>
        <SubToolBarLayout text='Scheduled Bookings'>
          <IOSSwitchComponent label={`AutoRefresh`} checked={autoRefreshStatus} handleToggle={this.setToggleAutoRefresh} />
          <ButtonGroup size='small' color='primary' aria-label='actions expand' className={classes.buttonGroup}>
            <FuseIconButton
              iconComponent={<CalendarViewDayIcon fontSize='large' className='mr-8' />}
              solid={true}
              className={classNames({ [classes.button]: true, [classes.buttonActive]: true })}
              variant='outline'
              onClick={this.expandAll}
              disabled={!atleastOneMinified}
            >
              Expand All
            </FuseIconButton>
            <FuseIconButton
              iconComponent={<ReorderIcon fontSize='large' className='mr-8' />}
              solid={true}
              className={classNames({ [classes.button]: true, [classes.buttonActive]: true })}
              variant='outline'
              onClick={this.collapseAll}
              disabled={allMinified}
            >
              Collapse All
            </FuseIconButton>
          </ButtonGroup>
        </SubToolBarLayout>
        <div className='p-20 h-full overflow-auto'>
          {initialLoading && <ShipmentListFilterSkeleton showSearch showFilter />}

          {!initialLoading && (
            <FilterRow shipmentsTotal={shipmentsTotal}
              search={search}
              handleSearchChange={this.handleSearchChange}
              sort={this.state.sort}
              handleSortChange={this.handleSortChange}
              handleStatusChange={this.handleStatusChange}
              shipments={shipments}
              getShipmentFilterOptions={getShipmentFilterOptions}
              applyCustomFilter={this.applyCustomFilter}
              customFilter={this.state.customFilter}
              allowCustomFilter
              allowStatusFilter />
          )}

          {loading && <ShipmentListSkeleton count={3} />}

          {!loading && shipments.length === 0 && (
            <FusePlaceHolder className='flex flex-col max-h-256'
              icon='calendar_today'
              title='No bookings'
              description={'Looks like there are no scheduled shipments at the moment. Please check back later.'} />
          )}

          {!loading && shipments.length > 0 && (
            <>
              <div className={classNames('mt-20 shipment-list')}>
                {shipments.map((shipment, index) => (
                  <ShipmentCard section='schedule'
                    key={index}
                    minified={this.state.minified[shipment._id]}
                    setMinified={value => {
                      this.setState({ minified: { ...minified, [shipment._id]: value } })
                    }}
                    shipment={shipment}
                    role={currentPlant.type}
                    onPartnerSidebarClick={this.onPartnerClick}
                    onOutboundsSidebarClick={this.onOutboundsClick}
                    statusType={'current-status'}
                    openDocUploader={this.openDocUploadSidebar}
                    onReviewBooking={this.onReviewOpen}
                    onResolveBooking={this.onShipperReviewOpen}
                    onChangeBooking={shipment => {
                      this.setRequesting(shipment._id)
                      this.props.getShipmentDerivatives(
                        {
                          outboundsDelta: { add: [], remove: [] },
                          shipmentId: shipment._id,
                        },
                        () => {
                          this.onAmendBookingOpen(shipment, false)
                          this.setRequesting(false)
                        },
                        () => {
                          this.setRequesting(false)
                        }
                      )
                    }}
                    shipmentCardPrimaryId={shipmentCardPrimaryId}
                    requesting={requesting}
                    onNotify={this.onNotifyOpen}
                  />
                ))}
              </div>
              {showShipmentsMore && <LoadMore onClick={this.onLoadMore} />}
            </>
          )}

          <PartnersSidebar
            open={partnerSidebar.open}
            closeSideBar={this.onPartnerSidebarClose}
            sideBarTitle={'Partners'}
            actors={partnerSidebar.actors}
          />

          <CSOutboundsSidebar
            open={outboundsSidebar.open}
            closeSideBar={this.onOutboundsSidebarClose}
            sideBarTitle={'CS & Outbounds'}
            outboundsList={outboundsSidebar.outbounds}
            consolidations={outboundsSidebar.consolidations}
            currentPlant={currentPlant}
          />

          <ReviewBookingDialog
            {...reviewDialog}
            handleClose={this.onReviewClose}
            currentPlant={currentPlant}
            onReject={this.onRejectOpen}
            onFreightPartnerAccept={this.onFreightPartnerOpen}
            onLogisticsPartnerAccept={this.onLogisticsPartnerOpen}
            getLimitedServiceCards={getLimitedServiceCards}
            limitedServiceCards={limitedServiceCards}
            resolveFpConflicts={resolveFpConflicts}
            resolveConflictCallback={() => this.getAllShipments(true)}
          />

          <ShipperReviewBookingDialog
            {...shipperReviewDialog}
            handleClose={this.onShipperReviewClose}
            onAmend={shipment => {
              const locationId = {
                originPlantId: get(shipment, 'actors.shipper[0]._id'),
                destinationPlantId: get(shipment, 'actors.consignee[0]._id')
              }

              this.setRequesting(true)
              this.props.getShipmentDerivatives(
                {
                  outboundsDelta: { add: [], remove: [] },
                  shipmentId: shipment._id,
                  locationId
                },
                () => {
                  this.onAmendBookingOpen(shipment, true, locationId)
                  this.setRequesting(false)
                },
                () => {
                  this.setRequesting(false)
                }
              )
            }}
            onResolve={amendShipmentWithoutChange}
            getAllShipments={this.getAllShipments}
            requesting={requesting}
            setRequesting={this.setRequesting}
          />

          <RejectBookingDialog {...rejectDialog}
            handleClose={this.onRejectClose}
            currentPlant={currentPlant}
            onSubmit={this.onRejectSubmit}
            onReviewBooking={(shipment) => {
              this.onRejectClose()
              this.onReviewOpen(shipment)
            }}
            requesting={requesting}
          />

          <FPAcceptBookingDialog {...freightPartnerAcceptDialog}
            handleClose={this.onFreightPartnerClose}
            vessels={_.get(this.props, 'vessels', [])}
            currentPlant={currentPlant}
            onSubmit={this.onFreightPartnerAcceptSubmit}
            requesting={requesting}
            getVessels={getVessels} />

          <ELPAcknowledgeBookingDialog {...elpAcknowledgeBookingDialog}
            handleClose={this.onElpAcknowledgeClose}
            currentPlant={currentPlant}
            onSubmit={this.onLogisticsPartnerAcceptSubmit}
            requesting={requesting} />

          <DocUploadSidebar {...docUploadSidebar}
            upload={this.onUploadFiles}
            handleClose={this.closeDocUploadSidebar}
            currentPlant={currentPlant}
            getAllShipments={this.getAllShipments} />

          <NewBoookingDialog {...amendBookingDialog}
            handleClose={this.onAmendBookingClose}
            mode='edit'
            outboundIds={[]}
            shipmentOutboundIds={[]}
            onSubmit={this.bookingAmend} />

          <ManualNotification {...manualNotificationSidebar}
            onClose={() => this.setState({
              manualNotificationSidebar: {
                open: false,
                shipment: null,
                plantOptions: [],
                userByPlantIds: [],
              }
            })}
            usersByPlantIds={_.get(this.props, 'usersByPlantIds', [])}
            onGetUsers={getUsersByPlantIds}
            onSubmit={sendShipmentNotification} />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  schedule: { initialLoading, loading, shipments, shipmentsTotal, outboundsByShipment, showShipmentsMore, vessels, limitedServiceCards },
  welcome: { currentPlant },
  shipmentDetails: { updatePreps },
  booking: { initialPrep },
  core: {
    userSettings: { shipmentCardPrimaryId },
    usersByPlantIds,
    autoRefreshStatus
  },
  auth: { info }
}) => ({
  initialPrep,
  vessels,
  limitedServiceCards,
  initialLoading,
  loading,
  shipments,
  shipmentsTotal,
  showShipmentsMore,
  outboundsByShipment,
  currentPlant,
  updatePreps,
  shipmentCardPrimaryId,
  userInfo: info,
  usersByPlantIds,
  autoRefreshStatus,
})

const mapDispatchToProps = dispatch => ({
  getAllShipments: (...params) => dispatch(ShipmentActions.getAllShipments(...params)),
  getOutboundsByShipment: shipmentId => dispatch(ShipmentActions.getOutboundsByShipment(shipmentId)),
  freightPartnerRejectBooking: (...args) => dispatch(ShipmentActions.freightPartnerRejectBooking(...args)),
  freightPartnerAcceptBooking: (...args) => dispatch(ShipmentActions.freightPartnerAcceptBooking(...args)),
  logisticsPartnerAcceptBooking: (...args) => dispatch(ShipmentActions.logisticsPartnerAcceptBooking(...args)),
  uploadShipmentFile: (shipmentId, type, payload) => dispatch(ShipmentActions.uploadShipmentFile(payload, shipmentId, type)),
  getVessels: (...args) => dispatch(ShipmentActions.getVessels(...args)),
  getShipmentFilterOptions: () => dispatch(CoreActions.getShipmentFilterOptions()),
  upload: (...params) => dispatch(CoreActions.uploadFile(...params)),
  getShipmentMeta: (...args) => dispatch(ShipmentActions.getShipmentMeta(...args)),
  editShipment: (...args) => dispatch(ShipmentDetailsActions.editShipment(...args)),
  getUpdatePreps: (shipmentId, callback) => dispatch(ShipmentDetailsActions.getUpdatePreps(shipmentId, callback)),
  getShipmentDerivatives: (...args) => dispatch(BookingActions.getShipmentDerivatives(...args)),
  amendShipmentWithoutChange: (...args) => dispatch(ShipmentActions.amendShipmentWithoutChange(...args)),
  sendShipmentNotification: (...args) => dispatch(ShipmentActions.sendShipmentNotification(...args)),
  getUsersByPlantIds: (...args) => dispatch(CoreActions.getUsersByPlantIds(...args)),
  setAutoRefreshStatus: (status) => dispatch(CoreActions.setAutoRefreshStatus(status)),
  getLimitedServiceCards: (...args) => dispatch(ShipmentActions.getLimitedServiceCards(...args)),
  resolveFpConflicts: (...args) => dispatch(ShipmentActions.resolveFpConflicts(...args)),
})
const styles = {
  button: { textTransform: 'none', fontWeight: 600, fontSize: 12 },
  buttonActive: {},
  buttonInActive: {}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Schedule))
