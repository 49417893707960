import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  buttonRoot: {
    overflow: 'hidden'
  },
  buttonEndIcon: {
    position: 'absolute',
    right: 0,
    margin: 0,
    padding: 10,
    backgroundColor: '#009530'
  },
  menuPaper: {
    marginTop: 3,
    marginBottom: 3,
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(159, 160, 164, 0.5)',
    borderRadius: 2,
    boxShadow: '0 1px 4px 0 rgba(5, 13, 52, 0.1)'
  },
  menuPadding: {
    padding: 0
  },
  menuListItemRoot: {
    fontFamily: 'IBMPlexSans-SemiBold !important',
    fontSize: '12px !important',
    backgroundColor: '#FFFFFF',
    color: '#3DCD58',
    padding: '12px 15px',
    '&:hover': {
      backgroundColor: '#E7F9EB'
    }
  },
  menuListItemRootSelected: {
    backgroundColor: '#E7F9EB'
  }
}));

export default function MenuButton(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Button {...props}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        classes={{
          root: classes.buttonRoot,
          endIcon: classes.buttonEndIcon
        }}
        onClick={handleToggle}>
        {get(props, 'title')}
      </Button>
      <Popper open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-end"
        transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}
            style={{ transformOrigin: placement === 'bottom-end' ? 'right top' : 'center bottom' }}>
            <Paper classes={{ root: classes.menuPaper }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  classes={{
                    padding: classes.menuPadding
                  }}>
                  {get(props, 'actions', []).map((actionItem, actionIndex) => {
                    return (
                      <MenuItem key={actionIndex}
                        classes={{
                          root: classes.menuListItemRoot,
                          selected: classes.menuListItemRootSelected
                        }}
                        onClick={actionItem.onClick}>
                        {actionItem.title}
                      </MenuItem>
                    )
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
