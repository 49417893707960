import React from 'react'
import { connect } from 'react-redux'
import { Snackbar, IconButton, withStyles, Icon, SnackbarContent } from '@material-ui/core'
import { green, amber, blue } from '@material-ui/core/colors'
import { omit } from 'lodash'
import classNames from 'classnames'
import * as Actions from 'core/store/actions'

const styles = theme => ({
  root: {},
  roundedRoot: {
    top: 0,
    '& > div': {
      borderRadius: 25,

      '& > div': {
        padding: 0
      }
    }
  },
  success: {
    backgroundColor: green[600],
    color: '#ffffff'
  },
  error: {
    backgroundColor: theme.palette.error.dark,
    color: theme.palette.getContrastText(theme.palette.error.dark)
  },
  info: {
    backgroundColor: blue[600],
    color: '#ffffff'
  },
  warning: {
    backgroundColor: amber[600],
    color: '#ffffff'
  },
  dark: {
    backgroundColor: amber[600],
    color: '#333333'
  }
})

const variantIcon = {
  success: 'check_circle',
  warning: 'warning',
  error: 'error_outline',
  info: 'info'
}

const FuseMessage = ({ classes, options, open, hideMessage }) => {
  const props = {
    content: {},
    root: {
      classes: {
        root: classes.root
      },
      onClose: hideMessage
    }
  }

  if (!options.noCloseButton) {
    props.content.action = [
      <IconButton key='close' aria-label='Close' color='inherit' onClick={hideMessage}>
        <Icon>close</Icon>
      </IconButton>
    ]
  }
  if (options.rounded) {
    props.root = {
      classes: {
        root: classes.roundedRoot
      }
    }
  }

  return (
    <Snackbar open={open} {...omit(options, ['noCloseButton', 'rounded'])} {...props.root}>
      <SnackbarContent
        className={classNames(classes[options.variant])}
        message={
          <div className='flex items-center medium'>
            {variantIcon[options.variant] && (
              <Icon className='mr-8' color='inherit'>
                {variantIcon[options.variant]}
              </Icon>
            )}
            {options.message}
          </div>
        }
        {...props.content}
      />
    </Snackbar>
  )
}

const mapStateToProps = ({ core: { messages } }) => messages

const mapDispatchToProps = dispatch => ({
  hideMessage: () => dispatch(Actions.hideMessage())
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FuseMessage))
