import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  withStyles,
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Link
} from '@material-ui/core'
import classNames from 'classnames'
import queryString from 'query-string'
import { groupBy } from 'lodash'
import {
  FuseAnimate,
  Unauthenticated,
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody
} from '../../core/components'
import LoginForm from '../components/LoginForm'
import OTPForm from '../components/OTPForm'
import OnboardingForm from '../components/OnboardingForm'
import * as AuthActions from '../store/actions'
import ForgotPasswordForm from '../components/ForgotPasswordForm'
import { get, filter } from "lodash";
import { getToken, getAPIBaseURL } from '../../core/helpers'
import LoadingBar from 'react-redux-loading-bar'

const plantTypes = require('../../core/helpers/json/plantTypes.json')

const styles = theme => ({
  root: {
    background: 'url(/assets/images/backgrounds/login.png)',
    color: theme.palette.primary.contrastText,

    [theme.breakpoints.down('md')]: {
      '& .MuiTypography-h4': {
        fontSize: '2.4rem'
      },
      '& .MuiTypography-h3': {
        fontSize: '2.8rem'
      },
      '& .MuiTypography-h2': {
        fontSize: '3.5rem'
      }
    }
  },
  header: {
    marginBottom: '1em'
  },
  leftSectionLogin: {
    maxWidth: '60%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    }
  },
  leftSectionOnboarding: {
    maxWidth: '50%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    }
  },
  rightSectionLogin: {
    minWidth: '40%'
  },
  rightSectionOnboarding: {
    minWidth: '50%'
  },
  indicatorContainer: {
    marginLeft: 'auto',
    minWidth: 210
  },
  loginForm: {
    marginTop: '20%',
  },
  autoLoginIndicator: {
    marginTop: '20%',
    textAlign: 'center'
  },
  indicator: {
    display: 'flex',
    minWidth: 210,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: 10,
    float: 'right',

    '& .item': {
      width: 30,
      height: 8,
      background: '#ace8b8',
      marginRight: 4,
      cursor: 'pointer',

      '&.active': {
        background: '#3dcd58'
      }
    },

    '& div:first-child': {
      borderTopLeftRadius: '13vh',
      borderBottomLeftRadius: '13vh'
    },

    '& div:last-child': {
      borderTopRightRadius: '13vh',
      borderBottomRightRadius: '13vh'
    }
  },
  plantSidebar: {
    top: '0 !important'
  },
  plantSidebarBody: {
    marginTop: 2,
    height: '100% !important',
    maxHeight: '100% !important'
  },
  plantSidebarTableTitle: {
    fontWeight: 700,
    marginTop: 20,
    color: '#000'
  },
  plantSidebarTableLeft: {
    color: 'rgba(0, 0, 0, 0.46)',
    paddingLeft: '0px',
    fontSize: '14px',
    padding: 3
  },
  plantSidebarTableRight: {
    color: 'rgba(0, 0, 0, 0.46)',
    paddingRight: '0px',
    fontSize: '14px',
    padding: 3
  },
  plantSidebarTableRow: {
    height: 30
  }
})

const LoginPage = ({
  classes,
  login,
  resendOTP,
  verifyChallenge,
  forgotPassword,
  auth,
  location: { search, hash },
  match,
  history,
  activateUser,
  searchUser,
  resetLoadingState,
  logout,
  getInfo,
  resetPassword
}) => {
  const [step, setStep] = useState(1)
  const [forgotPasswordStep, setForgotPasswordStep] = useState(1)
  const [showForgotPassword, setShowForgotPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [drawerVisibility, setDrawerVisibility] = useState(false)
  const [plantGroup, setPlantGroup] = useState([])
  const [authProviders, setAuthProviders] = useState([])
  const [defaultLogin, setDefaultLogin] = useState(false)
  const [loadingAuthInfo, setLoadingAuthInfo] = useState(false)
  const [policyLink, setPolicyLink] = useState('')
  const [userDetail, setUserDetail] = useState('');
  const toggleDrawer = () => setDrawerVisibility(!drawerVisibility)
  const hideDrawer = () => setDrawerVisibility(false)

  const getAuthInfo = async () => {
    setLoadingAuthInfo(true)
    setAuthProviders(JSON.parse(get(localStorage, 'authProviders', '[]')))
    setDefaultLogin(get(localStorage, 'defaultLogin', 'true') === 'true')
    localStorage['providerAuthToken'] = await getToken()
    setLoadingAuthInfo(false)
  }

  const getPrivacyPolicyLink = async () => {
    setPolicyLink(localStorage['policyLink'])
  }

  const notCorrectUser = () => {
    localStorage.removeItem("userDetail");
    history.push('/')
  }

  useEffect(() => {
    let plants = auth.info ? auth.info.plants : []
    if (plants && plants.length > 0) {
      let group = groupBy(plants, plant => plant.type)
      setPlantGroup(group)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.info])

  useEffect(() => {
    const values = queryString.parse(search)

    // if user status is not invited and token present, go back to home
    if (auth &&
      get(auth, 'token') &&
      get(auth, 'info.status') !== 'invited') {
      if (values.source && values.source !== 'email-invite') {
        history.push('/')
      }
    } else if (auth &&
      get(auth, 'token') &&
      get(auth, 'info') &&
      get(auth, 'info.status') === 'invited') {
      getInfo()
    } else {
      if (!auth.autoLoginLoading &&
        !auth.loading &&
        !get(auth, 'token')) {
        history.replace(`/${get(match, 'params.organizationId')}/login${search}${hash}`, { isLoginProcess: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, auth.token, auth.info])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const values = queryString.parse(search)

    // check params for auto login, important that it is only tried one time during page load.
    if (defaultLogin && (values.e && values.p)) {
      if (auth.info && values.source && values.source === 'email-invite') {
        logout(() => {
          login({ email: values.e, password: values.p, autoLogin: true })
        })
      } else {
        login({ email: values.e, password: values.p, autoLogin: true })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLogin])

  useEffect(() => {
    const values = queryString.parse(search)
    let userDetail = localStorage.getItem("userDetail");
    setUserDetail(userDetail);

    delete localStorage['providerAuthToken']
    setEmail(get(values, 'e', ''))
    resetLoadingState()
    getAuthInfo()
    getPrivacyPolicyLink()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showLoginForm = !auth.challengeId && !auth.onboarding && !auth.autoLoginLoading
  const showAutoLoginThrobber = !auth.challengeId && !auth.onboarding && auth.autoLoginLoading
  return (
    <div className={classNames(
      classes.root,
      'flex flex-col flex-1 flex-no-shrink sm:p-24 md:flex-row md:p-0')}>
      <div className={classNames(
        auth.onboarding ? classes.leftSectionOnboarding : classes.leftSectionLogin,
        'flex flex-row flex-no-grow items-center text-white p-16 text-center md:p-72 md:flex-no-shrink md:flex-1 md:text-left'
      )}>
        <Grid container className='md:text-left sm:text-center'>
          <Grid item xs={12}>
            <FuseAnimate animation='transition.slideUpIn' delay={300}>
              <Typography variant='h3'
                color='inherit'
                className='regular'>
                Welcome to</Typography>
            </FuseAnimate>
          </Grid>
          <Grid item xs={12}>
            <FuseAnimate delay={400}>
              <Typography variant='h2'
                color='inherit'
                className='mt-16 bold'>
                TRAMÉS ecosystem</Typography>
            </FuseAnimate>
          </Grid>
        </Grid>
      </div>

      <FuseAnimate animation={{ translateX: [0, '100%'] }}>
        <Card className={classNames(
          auth.onboarding ? classes.rightSectionOnboarding : classes.rightSectionLogin,
          'w-full max-w-md mx-auto m-16 md:m-0 login-form-border')} square>
          <CardContent className='flex flex-col items-center p-32 md:p-92 md:h-full'>
            <Grid container className={showLoginForm ? 'md:min-h-288' : ''}>
              <Grid item xs={12}>
                <Grid container className={classes.header}>
                  <Grid item className="pb-28">
                    {!loadingAuthInfo &&
                      <object data={`${getAPIBaseURL()}/organizations/${get(match, 'params.organizationId')}/logo`}
                        height={60}
                        type="image/png">
                        <img src="assets/images/logos/trames.png"
                          height={29}
                          alt='logo' />
                      </object>
                    }
                  </Grid>
                  <Grid item className={classes.indicatorContainer}>
                    {auth.onboarding &&
                      <OnboardingStepper step={step}
                        setStep={setStep}
                        classes={classes} />
                    }

                    {showForgotPassword &&
                      <ForgotPasswordStepper
                        forgotPasswordStep={forgotPasswordStep}
                        setForgotPasswordStep={setForgotPasswordStep}
                        classes={classes} />
                    }
                  </Grid>
                </Grid>
              </Grid>

              {(showAutoLoginThrobber || loadingAuthInfo) &&
                <Grid item xs={12}
                  className={classes.autoLoginIndicator}>
                  <CircularProgress color='secondary' />
                </Grid>
              }

              {(defaultLogin && showLoginForm) &&
                <Grid item xs={12}>
                  {!showForgotPassword &&
                    <FuseAnimate animation={{ translateY: [0, '100%'] }}>
                      <Grid container>
                        <Typography variant='h4'
                          className='md:w-full mb-32 semi-bold'>
                          Log in to your account</Typography>
                        <div className='flex flex-col items-center justify-center w-full'>
                          <LoginForm login={login}
                            loading={auth.loading}
                            setShowForgotPassword={setShowForgotPassword}
                            password={password}
                            setPassword={setPassword}
                            email={email}
                            setEmail={setEmail}
                            hash={hash} />
                        </div>
                      </Grid>
                    </FuseAnimate>
                  }

                  {showForgotPassword &&
                    <FuseAnimate animation={{ translateY: [0, '100%'] }}>
                      <Grid container>
                        <Typography variant='h4'
                          className='md:w-full mb-32 semi-bold'>
                          Forgot Password ?</Typography>
                        <div className='flex flex-col items-center justify-center w-full'>
                          <ForgotPasswordForm
                            auth={auth}
                            forgotPassword={forgotPassword}
                            setForgotPasswordStep={setForgotPasswordStep}
                            forgotPasswordStep={forgotPasswordStep}
                            loading={auth.loading}
                            setShowForgotPassword={setShowForgotPassword}
                            resetPassword={resetPassword}
                            sessionEmail={email}
                          />
                        </div>
                      </Grid>
                    </FuseAnimate>
                  }
                </Grid>
              }

              {((showLoginForm && !showForgotPassword) &&
                authProviders &&
                filter(authProviders, { status: { enabled: true } }).length > 0) &&
                <Grid item xs={12}>
                  <FuseAnimate animation={{ translateY: [0, '100%'] }}
                    delay={defaultLogin ? 250 : 50}>
                    <Grid container
                      className={defaultLogin ?
                        "py-20 mt-20 border-dashed border-t-2 border-gray" :
                        ""}>
                      <Grid item xs={12}
                        className={"bold " + (defaultLogin ? 'text-16 pb-20' : 'text-32 pb-32')}>
                        Login with Auth Providers</Grid>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={2}>
                          {filter(authProviders, { status: { enabled: true } }).map((provider, providerIndex) => {
                            const uri = get(provider, 'app.uri')
                            const identifier = get(provider, 'identifier')

                            return (
                              <Grid item xs={12} sm={12} md={6} key={providerIndex}>
                                <form method="post"
                                  action={`${uri}/api/v1/auth/${get(match, 'params.organizationId')}/${identifier}/initiate`}>
                                  <input type="hidden"
                                    id="code"
                                    name="code"
                                    value={localStorage['providerAuthToken']} />
                                  <button type="submit"
                                    id={`submit-${identifier}`}
                                    className="rounded-4 normal-case overflow-hidden semi-bold w-full p-0 h-36"
                                    style={{
                                      backgroundColor: get(provider, 'button.bgColor', 'rgba(9,138,9,.79)'),
                                      color: get(provider, 'button.color', 'white'),
                                      border: `1px solid ${get(provider, 'button.borderColor', 'black')}`,
                                      backgroundImage: `url("${get(provider, 'button.image', '')}")`,
                                      backgroundSize: '100%',
                                      backgroundPosition: 'center center',
                                      backgroundRepeat: 'no-repeat'
                                    }}>
                                    <Grid container
                                      justify={get(provider, 'button.icon') ? 'space-between' : 'center'}
                                      alignItems="center">
                                      {get(provider, 'button.icon') &&
                                        <Grid item float="left">
                                          <Grid container justify="flex-end">
                                            <img src={get(provider, 'button.icon')}
                                              height={36}
                                              className="max-w-36"
                                              alt="provider-login" />
                                          </Grid>
                                        </Grid>
                                      }
                                      <Grid item xs
                                        className="px-12">
                                        {get(provider, 'button.text')}
                                      </Grid>
                                    </Grid>
                                  </button>
                                </form>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  </FuseAnimate>
                </Grid>
              }

              {(defaultLogin && auth.challengeId) &&
                <FuseAnimate animation={{ translateY: [0, '100%'] }}>
                  <Grid item xs={12}
                    className={classes.loginForm}>
                    <Typography variant='h4'
                      className='md:w-full mb-32 semi-bold'>
                      Verify OTP</Typography>
                    <div className='flex flex-col items-center justify-center w-full'>
                      <OTPForm logout={logout}
                        verifyChallenge={verifyChallenge}
                        challengeId={auth.challengeId}
                        auth={auth}
                        password={password}
                        email={email}
                        resendOTP={resendOTP}
                        loading={auth.loading} />
                    </div>
                  </Grid>
                </FuseAnimate>
              }

              {(auth.onboarding && auth.info) &&
                <FuseAnimate animation={{ translateY: [0, '100%'] }}>
                  <Grid item xs={12}
                    className={classes.loginForm}>
                    <OnboardingForm step={step}
                      setStep={setStep}
                      auth={auth}
                      activateUser={activateUser}
                      searchUser={searchUser}
                      toggleDrawer={toggleDrawer}
                    />
                  </Grid>
                </FuseAnimate>
              }

              {userDetail &&
                <FuseAnimate animation={{ translateY: [0, '100%'] }}>
                  <Grid item xs={12} className="pt-12">
                    <Typography onClick={notCorrectUser}
                      display="inline"
                      gutterBottom
                      className="text-14 regular text-grey-darkest cursor-pointer hover:underline hover:text-blue">
                      Not&nbsp;{userDetail}?
                      </Typography>
                  </Grid>
                </FuseAnimate>
              }

              {!loadingAuthInfo &&
                <Grid item xs={12}
                  className="mt-20 text-right">
                  Read organization's&nbsp;<Link href={policyLink}
                    target="_blank"
                    className="fg-blue">
                    Privacy Policy</Link>
                </Grid>
              }
            </Grid>
          </CardContent>
        </Card>
      </FuseAnimate>

      <FuseSideBar open={drawerVisibility}
        onBackDropClick={hideDrawer}
        className={classes.plantSidebar}>
        <FuseSideBarHeader text={`Plants`}
          handleClose={hideDrawer} />
        <FuseSideBarBody className={classes.plantSidebarBody}>
          {Object.keys(plantGroup).map(type => (
            <React.Fragment key={type}>
              <Typography type='h5'
                className={classNames('text', classes.plantSidebarTableTitle)}>
                {get(plantTypes.find(t => t.key === type), 'name')}
              </Typography>
              <Table>
                <TableBody>
                  {plantGroup[type].map(item => (
                    <TableRow className={classes.plantSidebarTableRow}
                      key={item.name}>
                      <TableCell align='left'
                        className={classes.plantSidebarTableLeft}>
                        {item.name}
                      </TableCell>
                      <TableCell align='right'
                        className={classes.plantSidebarTableRight}>
                        {item.role}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </React.Fragment>
          ))}
        </FuseSideBarBody>
      </FuseSideBar>
      <LoadingBar className='page-loading-bar' />
    </div>
  )
}

const OnboardingStepper = ({ setStep, step, classes }) => (
  <Grid item sm={12} md={3} className={classes.indicator}>
    <div className={classNames('item', { active: step === 1 })} onClick={() => step > 1 && setStep(1)}></div>
    <div className={classNames('item', { active: step === 2 })} onClick={() => step > 2 && setStep(2)}></div>
    <div className={classNames('item', { active: step === 3 })}></div>
  </Grid>
)

const ForgotPasswordStepper = ({ setForgotPasswordStep, forgotPasswordStep, classes }) => (
  <Grid item sm={12} md={3} className={classes.indicator}>
    <div
      className={classNames('item', { active: forgotPasswordStep === 1 })}
      onClick={() => forgotPasswordStep > 1 && setForgotPasswordStep(1)}
    ></div>
    <div className={classNames('item', { active: forgotPasswordStep === 2 })}></div>
  </Grid>
)

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  login: payload => dispatch(AuthActions.login(payload)),
  getInfo: () => dispatch(AuthActions.getInfo()),
  verifyChallenge: payload => dispatch(AuthActions.verifyChallenge(payload)),
  activateUser: payload => dispatch(AuthActions.activateUser(payload)),
  searchUser: payload => dispatch(AuthActions.searchUser(payload)),
  forgotPassword: email => dispatch(AuthActions.forgotPassword(email)),
  logout: (...args) => dispatch(AuthActions.logout(...args)),
  resetLoadingState: () => dispatch(AuthActions.resetLoading()),
  resendOTP: payload => dispatch(AuthActions.resendOTP(payload)),
  resetPassword: payload => dispatch(AuthActions.resetPassword(payload)),
})

export default compose(Unauthenticated, connect(mapStateToProps, mapDispatchToProps))(withStyles(styles)(LoginPage))
