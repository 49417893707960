import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { get } from 'lodash'
import { FuseAnimate } from 'core/components'
import CustomBadge from './CustomBadge'

const PlatformCard = ({ data, onClick }) => (
  <FuseAnimate animation='transition.expandIn' delay={600}>
    <Grid item xs={12} md={3} className='p-36'>
      <Grid container
        className={`platform-card${data.unavailable ? ' unavailable' : ''}`}
        alignContent='center'
        justify='center'
        onClick={() => onClick(data)}
      >
        <Grid item xs={12}>
          <Grid container
            className='platform-card-top'
            style={{
              backgroundImage: `url(${data.background})`
            }}>
            <Grid item xs={12} className='invisible'>
              <Grid container justify='flex-end'>
                {get(data, 'events.total', 0) > 0 && (
                  <Grid item className={`tags event${(get(data, 'events.new') ? ' badge' : '')}`}>
                    <Grid container alignItems='center' className='h-full'>
                      <CustomBadge badgeContent={get(data, 'events.total')}>
                        <span className='tags-icon'></span>
                      </CustomBadge>
                    </Grid>
                  </Grid>
                )}
                {get(data, 'shipments.total', 0) > 0 && (
                  <Grid item className={'tags shipment' + (get(data, 'shipments.new') ? ' badge' : '')}>
                    <Grid container alignItems='center' className='h-full'>
                      <CustomBadge badgeContent={get(data, 'shipments.total')}>
                        <span className='tags-icon'></span>
                      </CustomBadge>
                    </Grid>
                  </Grid>
                )}
                {get(data, 'issues.total', 0) > 0 && (
                  <Grid item className={'tags issue' + (get(data, 'issues.new') ? ' badge' : '')}>
                    <Grid container alignItems='center' className='h-full'>
                      <CustomBadge badgeContent={get(data, 'issues.total')}>
                        <span className='tags-icon'></span>
                      </CustomBadge>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className='invisible'>
              {get(data, 'total', 0) > 0 && (
                <Grid container alignItems='flex-end' className='h-full onboard'>
                  <Grid item>
                    <Grid container alignItems='center'>
                      <img src='assets/images/icons/welcome/Network-Act.png' className='onboard-icon' alt='onboard' />
                      <Typography className='onboard-count' noWrap>
                        {get(data, 'total')}
                        &nbsp;{get(data, 'name')}
                        &nbsp;onboard
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container
            className='platform-card-bottom'
            alignItems='center'
            justify='space-between'>
            <Grid item>
              <Grid container
                justify='flex-start'
                alignItems='center'
                spacing={1}>
                <Grid item>
                  <Typography className='title text-14'>
                    {data.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center">
                    <img src='assets/images/icons/arrow-right.svg'
                      className='right-arrow-icon'
                      alt='right-arrow' />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {get(data, 'total', 0) > 1 && (
              <Grid item className='notification-count'>
                <Grid container alignItems='center' justify='center' className='h-full w-full'>
                  <Typography className='text-white semi-bold text-14'>{get(data, 'total')}</Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </FuseAnimate>
)

export default PlatformCard
