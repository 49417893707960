import React from 'react'
import { withStyles, Checkbox } from '@material-ui/core'
import classNames from 'classnames'

const CheckBox = ({ classes, className, ...other }) => (
  <Checkbox className={classNames(classes.checkbox, className)} {...other} />
)

const styles = theme => ({
  checkbox: {
    height: 24,
    padding: '0 4px 0 4px',

    '& svg': {
      width: '1.5em',
      height: '1.5em'
    }
  }
})

export default withStyles(styles)(CheckBox)
