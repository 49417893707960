import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMergeState, startSearching, stopSearching, shipmentNameBeautifier } from '../../helpers'
import {
  withStyles,
  AppBar,
  Icon,
  Typography,
  IconButton,
  Hidden,
  Badge,
  Grid,
  Box,
  CircularProgress
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import UserMenu from '../../components/UserMenu'
import * as authActions from '../../../auth/store/actions'
import * as coreActions from '../../store/actions'
import BaseToolBar from '../../components/BaseToolBar'
import NotificationSidebar from '../../components/Notification/NotificationSidebar'
import { pretiffyPlantType } from '../../helpers/prettify-plant-type'
import { get, find } from "lodash";
import { ListSearchBox } from '../../components/Inputs'
import { InfoToolTip } from '../../components'
import * as WelcomeActions from '../../../welcome/store/actions'


const styles = theme => ({
  separator: {
    width: 1,
    height: 52,
    backgroundColor: theme.palette.divider
  },
  badge: {
    fontSize: 10,
    right: 12,
    backgroundColor: '#E47F00'
  }
})

const INITIAL_STATE = {
  notificationSideBarOpen: false
}

const ToolBarLayout = ({
  user,
  logout,
  notifications,
  loadNotifications,
  markNotificationsAsRead,
  getUnreadNotificationCount,
  unreadNotifcationCount,
  currentPlant,
  setDrawerOpen,
  classes,
  getSettingByType,
  getSettingById,
  getSystemSearchShipments,
  systemSearchShipments,
  getAllShipmentsCounts,
  setCurrentPlant
}) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const [search, setSearch] = useState(get(systemSearchShipments, 'search', ''))
  const [searching, setSearching] = useState(false)

  useEffect(() => {
    if (currentPlant) {
      getSettingByType('pre-alert')
      getSettingById('efpAcceptanceThreshold')
      getUnreadNotificationCount()
      getAllShipmentsCounts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlant])
  if (!user) return null

  const openNotificationSidebar = () => {
    setState({
      notificationSideBarOpen: true
    })
  }

  const closeNotificationSidebar = () => {
    setState({
      notificationSideBarOpen: false
    })
  }

  const getShipmentLink = (shipment) => {
    const shipmentLinks = [
      { name: 'scheduled', title: 'Scheduled', url: '/schedule', icon: 'calendar_today' },
      { name: 'in-transit', title: 'In Transit', url: '/in-transit', icon: 'directions_boat' },
      { name: 'import', title: 'Inbound', url: '/inbound', icon: 'play_for_work' },
      { name: 'exceptions', title: 'Exceptions', url: '/exceptions', icon: 'warning_outline' },
      { name: 'completed', title: 'Completed', url: '/completed', icon: 'check_circle' },
      { name: 'cancelled', title: 'Cancelled', url: '/cancelled', icon: 'cancelled' },
    ]

    if (get(shipment, 'exceptionMode.enabled')) {
      return find(shipmentLinks, { name: 'exceptions' })
    } else {
      return find(shipmentLinks, { name: get(shipment, 'stage.current.value', 'scheduled') })
    }
  }

  const handleSearch = (event) => {
    const { value } = event.target
    setSearch(value)
    if (value !== search) {
      stopSearching()
      startSearching(500, () => {
        getSystemSearchShipments(value)
      })
    }
  }

  return (
    <>
      <AppBar id='fuse-toolbar' className='flex relative z-10' color='default'>
        <BaseToolBar
          className='h-52 min-h-52 px-8'
          leftIcon={
            <Hidden lgUp>
              <IconButton aria-label='delete'
                onClick={() => setDrawerOpen(true)}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          }
          leftComponent={
            <Hidden mdDown>
              <Typography align='center'
                color='textSecondary'
                variant='overline'
                className={`px-20 pl-12 medium`}>
                {currentPlant && `${get(currentPlant, 'name')} [${pretiffyPlantType(get(currentPlant, 'type'))}]`}
              </Typography>
            </Hidden>
          }>
          <Grid container
            justify="space-between"
            alignItems="center"
            spacing={2}>
            <Grid item xs={8}
              className="relative">
              {currentPlant &&
                <React.Fragment>
                  <ListSearchBox placeholder="Search for any shipments in system..."
                    value={search}
                    onChange={handleSearch}
                    onFocus={() => setSearching(true)}
                    onBlur={() => setTimeout(() => {
                      setSearching(false)
                    }, 300)}
                    containerProps={{
                      className: 'h-36'
                    }}
                    icon={
                      <Grid container
                        alignItems="center"
                        justify="flex-end"
                        spacing={1}
                        className="max-w-52 leading-none">
                        {search &&
                          <Grid item>
                            <InfoToolTip title="Clear">
                              <Icon className="fg-dark-gray text-16 cursor-pointer"
                                onClick={() => {
                                  handleSearch({ target: { value: '' } })
                                }}>close</Icon>
                            </InfoToolTip>
                          </Grid>
                        }
                        <Grid item>
                          <InfoToolTip title={
                            <Grid container>
                              <Grid item xs={12}
                                className="bold text-12 mb-4">
                                You can search shipments by:</Grid>
                              <Grid item xs={12}>
                                CBN no, Container no, Vessel name, CS code, Outbound no, HBL no, MBL no, Incoterm, Shipper, Drophub, Consignee & All partners.</Grid>
                            </Grid>
                          }>
                            <Icon className="fg-gray text-16 cursor-pointer">info</Icon>
                          </InfoToolTip>
                        </Grid>
                      </Grid>
                    } />
                  {(searching && search) &&
                    <Box className="absolute pin-l w-full px-8 pt-6">
                      <Grid container
                        className="max-h-256 overflow-auto rounded-2 border-solid border-1 border-gray bg-white shadow"
                        onClick={() => setSearching(true)}>
                        <Grid item xs={12}>
                          {get(systemSearchShipments, 'loading') &&
                            <Grid container
                              justify="center"
                              alignItems="center">
                              <Grid item className="p-12">
                                <CircularProgress size={24}
                                  className="fg-blue" />
                              </Grid>
                            </Grid>
                          }
                          {!get(systemSearchShipments, 'loading') &&
                            <Grid container>
                              {get(systemSearchShipments, 'data', []).length === 0 &&
                                <Grid item xs={12} className="p-12 text-center text-14 fg-gray">
                                  <Grid container
                                    justify="center"
                                    alignItems="center"
                                    className="leading-none">
                                    <Grid item><Icon>warning</Icon></Grid>
                                    <Grid item className="ml-6 regular">No shipments</Grid>
                                  </Grid>
                                </Grid>
                              }
                              {get(systemSearchShipments, 'data', []).map((shipment, shipmentIndex) => {
                                const shipmentLink = getShipmentLink(shipment)

                                return (
                                  <Grid item xs={12}
                                    key={shipmentIndex}
                                    className={'px-12 py-6 border-solid border-gray' +
                                      ((shipmentIndex + 1) !== get(systemSearchShipments, 'total', 0) ? ' border-b-1' : '')}>
                                    <Grid container>
                                      <Grid item xs={9}>
                                        <Grid container className="h-full">
                                          <Grid item xs={12} className="bold text-14">
                                            {shipmentNameBeautifier(get(shipment, 'name', ''))}
                                          </Grid>
                                          <Grid item xs={12} className="text-11 fg-gray medium uppercase">
                                            {`STATUS: ${get(shipment, 'status.current.value', '')}`}
                                          </Grid>
                                          <Grid item xs={12} className="text-11 fg-gray">
                                            {get(shipment, 'summary.latestActualEvent.name')}
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={3}>
                                        <Grid container
                                          className="h-full text-right">
                                          <Grid item xs={12}
                                            className="semi-bold fg-dark-gray">
                                            <Grid container alignItems="center" justify="flex-end">
                                              <Icon className="text-16" >{shipmentLink.icon}</Icon>
                                              <Grid item className="text-12 pl-6">{shipmentLink.title}</Grid>
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={12} className="text-11 mt-auto">
                                            <Link to={`${shipmentLink.url}/${shipment._id}`}
                                              className="fg-blue semi-bold uppercase">
                                              Go to shipment</Link>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                              })}
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  }
                </React.Fragment>
              }
            </Grid>
            <Grid item>
              {currentPlant &&
                <Badge badgeContent={unreadNotifcationCount}
                  color='secondary'
                  classes={{
                    anchorOriginTopRightRectangle: classes.badge
                  }}
                  className="mr-12">
                  <Icon color='action'
                    className='mr-16 text-20'
                    style={{ cursor: 'pointer' }}
                    onClick={() => openNotificationSidebar()}>
                    notifications</Icon>
                </Badge>
              }
              <UserMenu user={user} logout={logout}
                setCurrentPlant={setCurrentPlant} />
            </Grid>
          </Grid>
        </BaseToolBar>
      </AppBar>
      <NotificationSidebar
        open={state.notificationSideBarOpen}
        closeSideBar={() => closeNotificationSidebar()}
        notifications={notifications}
        loadNotifications={loadNotifications}
        markNotificationsAsRead={markNotificationsAsRead}
      />
    </>
  )
}

const mapStateToProps = ({
  core: { notifications, unreadNotifcationCount, systemSearchShipments },
  auth: { info },
  welcome: { currentPlant },
  schedule,
  inTransit,
  exceptions,
  shipmentDetails,
}) => ({
  user: info,
  currentPlant,
  notifications,
  unreadNotifcationCount,
  schedule: get(schedule, 'shipments'),
  manageBooking: get(schedule, 'manageShipments'),
  inTransit: get(inTransit, 'shipments'),
  exceptions: get(exceptions, 'shipments'),
  shipmentDetails,
  systemSearchShipments
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(authActions.logout()),
  loadNotifications: (...args) => dispatch(coreActions.loadNotifications(...args)),
  getUnreadNotificationCount: () => dispatch(coreActions.getUnreadNotificationsCount()),
  markNotificationsAsRead: params => dispatch(coreActions.markNotificationsAsRead(params)),
  getSettingByType: (...params) => dispatch(coreActions.getSettingByType(...params)),
  getSettingById: (...params) => dispatch(coreActions.getSettingById(...params)),
  getSystemSearchShipments: (...args) => dispatch(coreActions.getSystemSearchShipments(...args)),
  getAllShipmentsCounts: (...args) => dispatch(coreActions.getAllShipmentsCounts(...args)),
  setCurrentPlant: (...args) => dispatch(WelcomeActions.setCurrentPlant(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ToolBarLayout))
