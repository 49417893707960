import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Icon, withStyles, Box, CircularProgress } from '@material-ui/core'
import { ListSearchBox } from '../../core/components/Inputs'
import { getShipmentLink, startSearching, stopSearching, shipmentNameBeautifier } from '../../core/helpers'
import * as CoreActions from '../../core/store/actions'
import { InfoToolTip } from '../../core/components'
import { get } from 'lodash'

const ShipmentInfoIcon = ({ search, handleSearch, handleSelectShipment, filterSelectedShipment }) => {
  return (
    <Grid container alignItems="center" justify="flex-end" spacing={1} className="max-w-52 leading-none">
      {search &&
        <Grid item>
          <InfoToolTip title="Clear">
            <Icon className="fg-dark-gray text-16 cursor-pointer"
              onClick={() => {
                handleSearch({ target: { value: '' } })
                if (filterSelectedShipment) {
                  handleSelectShipment(undefined)
                }
              }}>close</Icon>
          </InfoToolTip>
        </Grid>
      }
      <Grid item>
        <InfoToolTip title={
          <Grid container>
            <Grid item xs={12} className="bold text-12 mb-4">
              You can search shipments by:</Grid>
            <Grid item xs={12}>
              CBN no, Container no, Vessel name, CS code, Outbound no, HBL no, MBL no, Incoterm, Shipper, DropHub, Consignee & All partners.</Grid>
          </Grid>
        }>
          <Icon className="fg-gray text-16 cursor-pointer">info</Icon>
        </InfoToolTip>
      </Grid>
    </Grid>
  )
}

const ShipmentSearch = ({ handleSearchShipments, searchedShipments, handleSelectShipment, filterSelectedShipment }) => {
  const [search, setSearch] = useState('')
  const [searching, setSearching] = useState(false)

  const handleSearch = (event) => {
    const { value } = event.target
    setSearch(value)
    if (value !== search) {
      stopSearching()
      startSearching(500, () => {
        handleSearchShipments(value)
      })
    }
  }

  useEffect(() => {
    if (filterSelectedShipment) {
      handleSearchShipments(filterSelectedShipment)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelectedShipment])

  return (
    <Grid container>
      <Grid item xs={12} className="relative">
        <ListSearchBox placeholder="Filter by shipment"
          value={search}
          onChange={handleSearch}
          onFocus={() => setSearching(true)}
          onBlur={() => setTimeout(() => {
            setSearching(false)
          }, 300)}
          containerProps={{
            className: 'h-32'
          }}
          icon={<ShipmentInfoIcon search={search}
            handleSearch={handleSearch}
            handleSelectShipment={handleSelectShipment}
            filterSelectedShipment={filterSelectedShipment} />} />
        {(searching && search) &&
          <Box className="absolute pin-l w-full pt-6 z-10">
            <Grid container
              className="max-h-256 overflow-auto rounded-2 border-solid border-1 border-gray bg-white shadow"
              onClick={() => setSearching(true)}>
              <Grid item xs={12}>
                {get(searchedShipments, 'loading') &&
                  <Grid container
                    justify="center"
                    alignItems="center">
                    <Grid item className="p-12">
                      <CircularProgress size={24}
                        className="fg-blue" />
                    </Grid>
                  </Grid>
                }
                {!get(searchedShipments, 'loading') &&
                  <Grid container>
                    {get(searchedShipments, 'data', []).length === 0 &&
                      <Grid item xs={12} className="p-12 text-center text-14 fg-gray">
                        <Grid container
                          justify="center"
                          alignItems="center"
                          className="leading-none">
                          <Grid item><Icon>warning</Icon></Grid>
                          <Grid item className="ml-6 regular">No shipments</Grid>
                        </Grid>
                      </Grid>
                    }
                    {get(searchedShipments, 'data', []).map((shipment, shipmentIndex) => {
                      const shipmentLink = getShipmentLink(shipment)

                      return (
                        <Grid item xs={12} key={shipmentIndex}
                          className={'px-12 py-6 border-solid border-gray cursor-pointer' +
                            ((shipmentIndex + 1) !== get(searchedShipments, 'total', 0) ? ' border-b-1' : '')}
                          onClick={() => handleSelectShipment(get(shipment, '_id'))}>
                          <Grid container>
                            <Grid item xs={12} className="bold text-14">
                              {shipmentNameBeautifier(get(shipment, 'name', ''))}
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container alignItems="center" justify="space-between">
                                <Grid item className="text-11 fg-gray medium uppercase">
                                  {`STATUS: ${get(shipment, 'status.current.value', '')}`}
                                </Grid>
                                <Grid item>
                                  <Grid container alignItems="center"
                                    className="fg-dark-gray semi-bold">
                                    <Icon className="text-16" >{shipmentLink.icon}</Icon>
                                    <Grid item className="text-12 pl-6">{shipmentLink.title}</Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                }
              </Grid>
            </Grid>
          </Box>
        }
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  separator: {
    width: 1,
    height: 52,
    backgroundColor: theme.palette.divider
  },
  badge: {
    fontSize: 10,
    right: 12,
    backgroundColor: '#E47F00'
  }
})

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  getSystemSearchShipments: (...args) => dispatch(CoreActions.getSystemSearchShipments(...args)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShipmentSearch))
