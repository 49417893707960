import React from 'react'
import { Grid } from '@material-ui/core'
import { FuseDialog, FuseDialogHeader, FuseDialogBody } from '../../core/components'
import { stringToXML } from '../../core/helpers'

const ViewXmlBody = ({ open, handleClose, xmlBody }) => {

  const formattedXML = stringToXML(xmlBody)

  return (
    <FuseDialog open={open} onBackDropClick={handleClose}>
      <FuseDialogHeader text="View XML Body" handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="p-20">
          <Grid item xs={12} className="border-1 border-gray rounded bg-gray p-12">
            <div dangerouslySetInnerHTML={{ __html: (`${formattedXML}`) }}></div>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}
export default ViewXmlBody