import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, Link, FormHelperText, CircularProgress } from '@material-ui/core'
import { FuseTextField } from 'core/components'

const labelStyles = {
  fontSize: '1.1em',
  marginBottom: '0.4em'
}

const inputStyles = {
  fontSize: '1.2em'
}

const OTPForm = ({
  verifyChallenge,
  challengeId,
  loading,
  logout,
  resendOTP,
  auth: { email, challengeIdCreatedAt }
}) => {
  let created = new Date(challengeIdCreatedAt)
  let diff = Math.ceil(Math.abs(created - new Date()) / 1000)
  diff = 5 * 60 - diff
  const [otp, setOTP] = useState('')
  const [seconds, setSeconds] = useState(diff)
  useEffect(() => {
    if (seconds < 1) {
      return
    }
    setTimeout(() => {
      setSeconds(seconds - 1)
    }, 1000)
  }, [seconds])
  const onOTPChange = e => setOTP(e.target.value)

  const onSubmit = () => verifyChallenge({ otp, challengeId })

  const baseTextSetting = {
    position: 'end',
    className: 'text-20',
    color: 'action'
  }

  return (
    <div className='flex flex-col justify-start w-full'>
      <form onSubmit={e => {
        onSubmit()
        e.preventDefault()
      }} noValidate>
        <FuseTextField
          className='mb-16'
          type='password'
          name='otp'
          label='OTP'
          labelStyles={labelStyles}
          inputStyles={inputStyles}
          icon={{ ...baseTextSetting, name: 'vpn_key' }}
          value={otp}
          onChange={onOTPChange}
          required
          fullWidth
        />
        <FormHelperText style={{ marginTop: 0 }}>
          <span>
            {seconds > 1
              ? `OTP is valid for next ${Math.floor(seconds / 60)}:${
              seconds % 60 < 10 ? `0${seconds % 60}` : `${seconds % 60}`
              } minutes. `
              : `OTP has timed out. `}{' '}
            {seconds < 2.5 * 60 && (
              <Link
                style={{ color: '#40a9ff' }}
                onClick={async () => {
                  await resendOTP({ email })
                  setSeconds(5 * 60)
                }}
              >
                Resend OTP
              </Link>
            )}
          </span>
        </FormHelperText>

        <Grid container alignItems='center' alignContent='flex-start'>
          <Grid item className='mt-8'>
            <Link onClick={logout}>Cancel</Link>
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button type='submit'
              variant='contained'
              color='primary'
              style={{ borderRadius: '50vh' }}
              className='min-w-160 ml-auto mt-8 btn'
              aria-label='LOG IN'
              disabled={loading || !otp}>
              {!loading ? 'Verify OTP' : 'Verifying...'}{' '}
              {loading && <CircularProgress color='primary' size={20} style={{ marginLeft: 20 }} />}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

OTPForm.propTypes = {
  verifyChallenge: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default OTPForm
