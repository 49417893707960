import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  SET_ALL_CLOSED_OUTBOUNDS
  SET_MORE_CLOSED_OUTBOUNDS
  SET_ALL_EXCLUDE_CLOSED_OUTBOUNDS
  SET_MORE_EXCLUDE_CLOSED_OUTBOUNDS

  SET_SELECTED_OUTBOUNDS
  SET_LOADING
  SET_LOADING_MORE
  SET_EXCLUDE_LOADING
  SET_EXCLUDE_LOADING_MORE
  `
)
