import React, { useState, useEffect } from 'react'
import {
  Grid,
  withStyles,
  Typography,
  Button,
  Slide
} from '@material-ui/core'
import { get, orderBy } from 'lodash'
import TopicList from './shipment-topics/TopicList'
import TopicDetails from './shipment-topics/TopicDetails'
import CreateTopic from './shipment-topics/CreateTopic'
import history from '../../history'

const ShipmentTopics = ({
  topics,
  shipmentId,
  shipmentActors,
  userInfo,
  getTopics,
  getTopicDetails,
  addTopic,
  addTopicsComment,
  openTopics,
  closeTopics,
  urlTabValue,
  urlTopicId,
  plants,
  updateTopics }) => {
  const requestedTopicDetails = get(topics, 'requestedTopicDetails', {})
  const [topicDetails, setTopicDetails] = useState(null)
  const [openCreateNew, setOpenCreateNew] = useState(false)

  const handleTopicClick = (topic) => {
    if (urlTabValue) {
      history.push(`?tab=${urlTabValue}&topicId=${topic._id}`)
    }
  }

  const handleBack = () => {
    setTopicDetails(null)
    getTopicDetails()
    getTopics(shipmentId, true)
    if (urlTabValue) {
      history.push(`?tab=${urlTabValue}`)
    }
  }

  useEffect(() => {
    if (topicDetails) {
      if (requestedTopicDetails) setTopicDetails(requestedTopicDetails)
      else setTopicDetails(topicDetails)
    }
  }, [requestedTopicDetails, topicDetails])

  useEffect(() => {
    if (urlTopicId) {
      setTopicDetails({ loading: true })
      getTopicDetails(urlTopicId, shipmentId, null, handleBack)
    } else {
      handleBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTopicId])

  return (
    <Grid container
      className="p-20 regular text-12">

      {topicDetails === null &&
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container
                justify="space-between"
                alignItems="center"
                spacing={1}>
                <Grid item>
                  <Typography className='fg-gray bold'>
                    Notices</Typography>
                </Grid>
                <Grid item>
                  <Button className="btn btn_sm"
                    onClick={() => setOpenCreateNew(true)}>
                    Create new Notice</Button>
                </Grid>
              </Grid>
            </Grid>
            <Slide in={topicDetails === null} direction="down">
              <Grid item xs={12}>
                <TopicList isLoading={get(topics, 'loading', true)}
                  topics={orderBy(get(topics, 'data', []), 'status', 'desc')}
                  onTopicClick={handleTopicClick}
                  shipmentId={shipmentId}
                  getTopics={getTopics}
                  openTopic={openTopics}
                  closeTopic={closeTopics} />
              </Grid>
            </Slide>
          </Grid>
        </Grid>
      }

      {topicDetails !== null &&
        <Grid item xs={12}
          className="overflow-hidden">
          <Slide in={topicDetails !== null} direction="left">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item>
                    <Typography className="fg-blue bg-light-blue uppercase bold px-12 py-4 rounded cursor-pointer"
                      onClick={handleBack}>
                      &lt; back</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TopicDetails topic={topicDetails}
                  plants={plants}
                  userInfo={userInfo}
                  getTopicDetails={getTopicDetails}
                  openTopic={openTopics}
                  closeTopic={closeTopics}
                  addTopicsComment={addTopicsComment}
                  updateTopics={updateTopics} />
              </Grid>
            </Grid>
          </Slide>
        </Grid>
      }

      <CreateTopic open={openCreateNew}
        plants={plants}
        shipmentId={shipmentId}
        shipmentActors={shipmentActors}
        addTopic={addTopic}
        getTopics={getTopics}
        handleClose={() => setOpenCreateNew(false)} />

    </Grid>
  )
}
const styles = {}

export default withStyles(styles)(ShipmentTopics)
