import React, { useEffect, useState } from 'react'
import {
  LoadingButton,
  FuseSideBarActions,
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody
} from '../../core/components'
import { withStyles, Grid, Input, TextField, Chip } from '@material-ui/core'
import { useMergeState, getVariablesByType } from '../../core/helpers'
import {
  AutoComplete,
  RichEditor
} from '../../core/components/Inputs'
import { get, pick, find, uniqBy } from 'lodash'
import 'react-quill/dist/quill.snow.css';
import { fileUploadInfo, general } from "../../core/localization";
import validator from 'validator'

const INITIAL_STATE = {
  name: '',
  type: '',
  subject: '',
  recipients: {
    plants: [],
    emailAddresses: []
  },
  replyTo: '',
  attachments: [],
  messageBody: ''
}

const styles = {
  input: {
    fontSize: 14,
    fontFamily: 'IBMPlexSans',
  },
  checkbox: {
    height: 'auto',
    padding: 0,
    marginLeft: '-3px',

    '& svg': {
      width: '1.8em',
      height: '1.8em'
    }
  },
}

const selectStyle = {
  control: () => ({
    display: 'flex',
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid #949494',
    minHeight: '31px',
    maxHeight: '31px',
    marginTop: '0px'
  }),
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  }),
}

const multiSelectStyle = {
  control: () => ({
    display: 'flex',
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid #949494',
    minHeight: '31px',
    maxHeight: 'auto',
    marginTop: '0px'
  }),
  placeholder: (styles) => ({
    ...styles,
    marginLeft: 0,
    marginRight: 0,
  }),
  dropdownIndicator: (styles) => ({
    ...styles
  }),
  multiValue: (styles) => ({
    ...styles,
    display: 'flex'
  })
}

const chipStyle = {
  fontFamily: 'IBMPlexSans-Medium',
  fontSize: 12,
  borderRadius: 15,
  height: 25,
}
const chipInvalidStyle = {
  ...chipStyle,
  backgroundColor: '#B10043',
  color: '#FFFFFF'
}

const ManagePlantEmailTemplateSidebar = props => {
  const {
    open,
    title,
    handleClose,
    existingTemplate,
    classes,
    plantEmailTemplateTypes,
    onSubmit,
    submitting
  } = props
  const [state, setState] = useMergeState(INITIAL_STATE)
  const [recipient, setRecipient] = useState('')

  useEffect(() => {
    if (open) {
      if (existingTemplate) {
        const existingState = pick(get(existingTemplate, 'value', {}), [
          'name',
          'type',
          'subject',
          'recipients.plants',
          'recipients.emailAddresses',
          'replyTo',
          'attachments',
          'messageBody'
        ])
        existingState.recipients = {
          ...get(existingState, 'recipients', {}),
          emailAddresses: get(existingState, 'recipients.emailAddresses', [])
            .map(item => ({ email: item }))
        }
        setState(existingState)
      } else {
        setState(INITIAL_STATE)
      }

    } else {
      setState(INITIAL_STATE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setState({
      [name]: value
    })
  }

  const handleEditorChange = (messageBody) => {
    setState({ messageBody })
  }

  const handleRecipientChange = (event) => {
    let { value } = event.target
    value = value.replace(/ +/g, ',')
    value = value.replace(/;+/g, ',')
    value = value.replace(/,+/g, ',')
    if (value[0] === ',') {
      value = value.substring(1)
    }
    setRecipient(value)

    if (value.search(',') >= 0) {
      handleRecipientAdd(event, true, value)
    }
  }

  const handleRecipientAdd = (event, force = false, recipient) => {
    event.stopPropagation()
    const appendKeyCodes = [9, 13, 32]
    const { keyCode } = event
    const { recipients } = state

    if (recipient && (force || appendKeyCodes.includes(keyCode))) {
      const recipientArray = recipient.split(',').map(email => {
        if (validator.isEmail(email)) {
          return {
            email
          }
        } else {
          return {
            email,
            invalid: true
          }
        }
      })
      setState({
        recipients: {
          ...recipients,
          emailAddresses: uniqBy([
            ...get(state, 'recipients.emailAddresses', []).map(i => ({...i, email: get(i,'email','').toLowerCase()})),
            ...recipientArray.map(i => ({...i, email: get(i,'email','').toLowerCase()}))
          ].filter(Boolean), 'email')
        }
      })
      setRecipient('')
    }
  }

  const removeFromArray = (arr, str) => {
    let index = arr.indexOf(str)
    if (index !== -1) {
      return [...arr.slice(0, index), ...arr.slice(index + 1)]
    }
  }

  const handleSubmit = () => {
    const payload = pick(state, [
      'name',
      'type',
      'recipients',
      'subject',
      'attachments',
      'replyTo',
      'messageBody'
    ])
    payload.recipients = {
      ...get(payload, 'recipients', {}),
      emailAddresses: get(payload, 'recipients.emailAddresses', [])
        .map(item => item.email)
    }
    onSubmit(payload, existingTemplate ? existingTemplate : 'adding')
  }

  return (
    <FuseSideBar open={open} wSM>
      <FuseSideBarHeader text={title}
        handleClose={handleClose} />
      <FuseSideBarBody>
        <Grid container>

          {/* Name */}
          <Grid item xs={12}>
            <Grid container
              className="mb-16 mt-8">
              <Grid item xs={12}
                className="semi-bold text-16 pb-4">
                Name</Grid>
              <Grid item xs={12} className="pb-12">
                <Input name="name"
                  placeholder="Enter name"
                  className={classes.input}
                  value={get(state, 'name', '')}
                  onChange={handleInputChange}
                  fullWidth />
              </Grid>
            </Grid>
          </Grid>
          {/* End of Name */}

          {/* Type */}
          <Grid item xs={12}>
            <Grid container
              className="mb-16">
              <Grid item xs={12}
                className="semi-bold text-16 pb-4">
                Type</Grid>
              <Grid item xs={12}>
                <AutoComplete value={get(state, 'type')}
                  className={classes.input}
                  options={plantEmailTemplateTypes}
                  placeholder="Select type..."
                  onChange={(type) => setState({ type })}
                  customStyles={selectStyle} />
              </Grid>
            </Grid>
          </Grid>
          {/* End of Type */}

          {/* Recipient Plants */}
          <Grid item xs={12} className="hidden">
            <Grid container
              className="mb-16">
              <Grid item xs={12}
                className="semi-bold text-16 pb-4">
                Recipient Plants</Grid>
              <Grid item xs={12} className="pb-12 text-14">
                <AutoComplete value={get(state, 'recipients.plants', [])}
                  options={Object.keys(general.partnerShortForms).map(value => {
                    return {
                      value,
                      label: get(general.partnerShortForms, value)
                    }
                  })}
                  onChange={plants => {
                    setState({
                      recipients: {
                        ...get(state, 'recipients', {}),
                        plants
                      }
                    })
                  }}
                  placeholder="Select recipient plants..."
                  customStyles={multiSelectStyle}
                  isMulti />
              </Grid>
            </Grid>
          </Grid>
          {/* End of Recipient Plants */}

          {/* Recipient Email Addresses */}
          <Grid item xs={12}>
            <Grid container
              className="mb-16">
              <Grid item xs={12}
                className="semi-bold text-16 pb-4">
                Recipient Emails</Grid>
              <Grid item xs={12} className="pb-12 text-14">
                <TextField placeholder="Enter recipient emails"
                  value={recipient}
                  onChange={handleRecipientChange}
                  onKeyDown={e => handleRecipientAdd(e, false, recipient)}
                  onBlur={e => handleRecipientAdd(e, true, recipient)}
                  inputProps={{
                    style: {
                      fontSize: '14px',
                      padding: '7px 0px',
                      fontFamily: 'IBMPlexSans'
                    }
                  }}
                  fullWidth />
                {get(state, 'recipients.emailAddresses', []).length > 0 &&
                  <Grid container
                    className="mt-8"
                    spacing={1}>
                    {get(state, 'recipients.emailAddresses', [])
                      .map((item, itemIndex) => {
                        return (
                          <Grid item key={itemIndex}>
                            <Chip label={item.email}
                              onDelete={() => {
                                setState({
                                  recipients: {
                                    ...get(state, 'recipients', {}),
                                    emailAddresses: removeFromArray(get(state, 'recipients.emailAddresses', []), item)
                                  }
                                })
                              }}
                              style={item.invalid ? chipInvalidStyle : chipStyle}
                            />
                          </Grid>
                        )
                      })
                    }
                    {find(get(state, 'recipients.emailAddresses', []), { invalid: true }) &&
                      <Grid item xs={12}
                        className="text-12 fg-red">
                        You have some invalid email addresses in recipient emails list, please remove them to proceed.</Grid>
                    }
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          {/* End of Recipient Email Addresses */}

          {/* Reply to Email Addresses */}
          <Grid item xs={12}>
            <Grid container
              className="mb-16">
              <Grid item xs={12}
                className="semi-bold text-16 pb-4">
                Reply to Emails</Grid>
              <Grid item xs={12} className="pb-12 text-14">
                <TextField placeholder="Enter reply to"
                  name="replyTo"
                  value={get(state, 'replyTo', '')}
                  onChange={handleInputChange}
                  inputProps={{
                    style: {
                      fontSize: '14px',
                      padding: '7px 0px',
                      fontFamily: 'IBMPlexSans'
                    }
                  }}
                  fullWidth />
              </Grid>
            </Grid>
          </Grid>
          {/* End of Recipient Email Addresses */}

          {/* Subject */}
          <Grid item xs={12}>
            <Grid container
              className="mb-16 mt-8">
              <Grid item xs={8}
                className="semi-bold text-16 pb-4">
                Subject</Grid>
              <Grid item xs={4}
                className="regular text-12 pb-4">
                <AutoComplete value=""
                  className={classes.input}
                  options={getVariablesByType('subject').map(item =>
                    ({
                      value: item.name,
                      label: item.label
                    })
                  )}
                  placeholder="Append variable..."
                  onChange={(value) => {
                    const { subject } = state
                    setState({
                      subject: `${subject}${value}`
                    })
                  }}
                  customStyles={selectStyle}
                  showAllOptions />
              </Grid>
              <Grid item xs={12} className="pb-12">
                <Input name="subject"
                  placeholder="Enter subject"
                  className={classes.input}
                  value={get(state, 'subject', '')}
                  onChange={handleInputChange}
                  fullWidth />
              </Grid>
            </Grid>
          </Grid>
          {/* End of Subject */}

          {/* Attachments */}
          <Grid item xs={12}>
            <Grid container
              className="mb-16">
              <Grid item xs={12}
                className="semi-bold text-16 pb-4">
                Attachments</Grid>
              <Grid item xs={12} className="pb-12 text-14">
                <AutoComplete value={get(state, 'attachments', [])}
                  options={Object.keys(fileUploadInfo).map(value => {
                    return {
                      value,
                      label: get(fileUploadInfo, value)
                    }
                  })}
                  onChange={attachments => {
                    setState({ attachments })
                  }}
                  placeholder="Select attachments..."
                  customStyles={multiSelectStyle}
                  isMulti />
              </Grid>
            </Grid>
          </Grid>
          {/* End of Attachments */}

          {/* Message Body */}
          <Grid item xs={12}>
            <Grid container
              className="mb-16">
              <Grid item xs={12}
                className="semi-bold text-16 pb-8">
                Message Body</Grid>
              <Grid item xs={12} className="pb-12">
                <RichEditor value={get(state, 'messageBody', '')}
                  onChange={handleEditorChange}
                  placeholder="Enter your message here..." />
              </Grid>
            </Grid>
          </Grid>
          {/* End of Message Body */}

        </Grid>
      </FuseSideBarBody>

      <FuseSideBarActions>
        <LoadingButton className='btn btn_full-space'
          loading={submitting === 'plantEmailTemplate'}
          onClick={handleSubmit}>
          SAVE</LoadingButton>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

export default withStyles(styles)(ManagePlantEmailTemplateSidebar)
