import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import * as API from '../../core/api'
import { get } from 'lodash'
import history from '../../history'
import { addGaEvent } from '../../core/helpers'

export const apiAttempt = (payload = {}) => ({
  type: ActionTypes.API_ATTEMPT,
  payload
})

export const getShipmentSuccess = payload => ({
  type: ActionTypes.GET_SHIPMENT_SUCCESS,
  payload
})

export const getShipmentOutboundsSuccess = payload => ({
  type: ActionTypes.GET_SHIPMENT_OUTBOUNDS_SUCCESS,
  payload
})

export const getShipmentConsolidationsSuccess = payload => ({
  type: ActionTypes.GET_SHIPMENT_CONSOLIDATIONS_SUCCESS,
  payload
})

export const getShipmentDocumentsSuccess = payload => ({
  type: ActionTypes.GET_SHIPMENT_DOCUMENTS_SUCCESS,
  payload
})

export const getShipmentEventsSuccess = (payload, loading = false) => ({
  type: ActionTypes.GET_SHIPMENT_EVENTS_SUCCESS,
  payload,
  loading
})

export const getShipmentEventWriteListSuccess = (payload, loading = false) => ({
  type: ActionTypes.GET_SHIPMENT_EVENT_WRITE_LIST_SUCCESS,
  payload,
  loading
})

export const getShipmentMetaSuccess = payload => ({
  type: ActionTypes.GET_SHIPMENT_META_SUCCESS,
  payload
})

export const getShipmentUpdatePrepsSuccess = payload => ({
  type: ActionTypes.GET_SHIPMENT_UPDATE_PREPS_SUCCESS,
  payload
})

export const getShipmentPreAlertLogs = payload => ({
  type: ActionTypes.GET_SHIPMENT_PRE_ALERT_LOGS_SUCCESS,
  payload
})

export const getShipmentGoodsReceived = payload => ({
  type: ActionTypes.GET_SHIPMENT_GOODS_RECEIVED_SUCCESS,
  payload
})

export const getShipmentTopicsSuccess = (payload, loading) => ({
  type: ActionTypes.GET_SHIPMENT_TOPICS,
  payload,
  loading
})

export const getSingleShipment = (shipmentId, isSync = false) => async (dispatch, getState) => {
  if (!isSync) {
    dispatch(apiAttempt())
    if (shipmentId !== get(getState(), 'shipmentDetails._id')) {
      dispatch({ type: ActionTypes.RESET_SHIPMENT_DETAILS })
    }
  }

  let responseCode = ''

  try {
    const { ok, data } = await API.getSingleShipment(shipmentId)
    var shipment

    if (!ok) {
      responseCode = get(data, 'code', '')
      throw new Error(data.message)
    }

    shipment = get(data, 'data', {})
    dispatch(getShipmentSuccess(shipment))
    dispatch(getShipmentOutbounds(shipmentId))
    dispatch(CoreActions.getAllShipmentsCounts())
  } catch (e) {
    if (responseCode === 'TM007') {
      e.message = `You don't have access to view this shipment, try switching plant`
      setTimeout(() => {
        dispatch(CoreActions.showMessage(e))
      }, 100)
    } else {
      dispatch(CoreActions.showMessage(e))
    }
  }
}

export const getShipmentDocuments = (shipmentId, isSync = false) => async dispatch => {
  if (!isSync) {
    dispatch(getShipmentDocumentsSuccess([]))
  }

  try {
    const { ok, data, status } = await API.getShipmentDocuments(shipmentId)

    if (status === 403) {
      dispatch(getShipmentDocumentsSuccess([{ invalid: true }]))
      return
    }

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getShipmentDocumentsSuccess(data.data))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getShipmentEvents = (shipmentId, isSync = false) => async dispatch => {
  if (!isSync) {
    dispatch(getShipmentEventsSuccess([], true))
  }

  try {
    const { ok, data, status } = await API.getShipmentEvents(shipmentId)

    if (status === 403) {
      dispatch(getShipmentEventsSuccess([{ invalid: true }]))
      return
    }

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getShipmentEventsSuccess(data.data))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getShipmentOutbounds = shipmentId => async dispatch => {
  try {
    const { ok, data } = await API.getOutboundsbyShipment(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getShipmentOutboundsSuccess(get(data, 'data', [])))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const markShipmentAsException = (payload, shipmentId) => async dispatch => {
  try {
    const { ok, status, data } = await API.markShipmentAsException(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(CoreActions.showMessage({ status, data }))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const updateShipment = (payload, shipmentId) => async dispatch => {
  try {
    const { ok, status, data } = await API.updateShipment(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    const shipment = get(data, 'data', {})

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getShipmentSuccess(shipment))
    dispatch(getShipmentOutbounds(get(shipment, 'outbounds', [])))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const updateShipmentCS = (payload, shipmentId) => async dispatch => {
  try {
    const { ok, status, data } = await API.updateShipmentCS(payload, shipmentId)

    if (!ok) {
      addGaEvent('shipment-updatingConsolidationFailed', shipmentId)
      throw new Error(data.message)
    }

    addGaEvent('shipment-updatedConsolidation', shipmentId)

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getSingleShipment(shipmentId))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const amendShipment = (payload, shipmentId) => async dispatch => {
  try {
    const { ok, status, data } = await API.amendShipment(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    const shipment = get(data, 'data', {})

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getShipmentSuccess(shipment))
    dispatch(getShipmentOutbounds(get(shipment, 'outbounds', [])))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getShipmentEventWriteList = (shipmentId, isSync = false) => async dispatch => {
  if (!isSync) {
    dispatch(getShipmentEventWriteListSuccess([], true))
  }

  try {
    const { ok, data, status } = await API.getShipmentEventWriteListV1(shipmentId)

    if (status === 403) {
      dispatch(getShipmentEventWriteListSuccess([{ invalid: true }]))
      return
    }

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getShipmentEventWriteListSuccess(get(data, 'data', [])))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const addShipmentEvents = (shipmentId, eventCode, eventType, payload, callback = null) => async dispatch => {
  try {

    const { ok, data, status } = await API.addShipmentEvents(shipmentId, eventCode, eventType, payload, true)

    if (!ok) {
      addGaEvent('shipment-addingEventFailed', shipmentId, eventCode)
      throw new Error(data.message)
    }

    addGaEvent('shipment-addedEvent', shipmentId, eventCode)

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getShipmentEventWriteList(shipmentId, true))
    dispatch(getShipmentEvents(shipmentId, true))
    dispatch(getSingleShipment(shipmentId, true))

    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getShipmentMeta = id => async dispatch => {
  try {
    const { ok, data } = await API.getShipmentMeta(id)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getShipmentMetaSuccess(data.data))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const editShipment = (id, payload, mode = 'update', success = null, fail = null) => async dispatch => {
  try {
    var request
    if (mode === 'amend') {
      request = await API.amendShipment(id, payload)
    } else {
      request = await API.updateShipment(id, payload)
    }

    let { ok, status, data } = request

    if (!ok) {
      addGaEvent(`shipment-${mode}Failed`, id)
      throw new Error(data.message)
    }
    addGaEvent(`shipment-${mode === 'amend' ? 'amended' : 'updated'}`, id)

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getSingleShipment(id))

    if (success) {
      success()
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    if (fail) {
      fail()
    }
  }
}

export const updateBLNumbers = (payload, shipmentId) => async dispatch => {
  try {
    const { ok, status, data } = await API.updateBLNumbers(payload, shipmentId)

    if (!ok) {
      addGaEvent('shipment-updatingBLFailed', shipmentId)
      throw new Error(data.message)
    }

    addGaEvent('shipment-updatedBL', shipmentId)

    const shipment = get(data, 'data', {})

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getShipmentSuccess(shipment))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const deleteShipmentDocument = (shipmentId, fileId) => async dispatch => {
  try {
    const { ok, status, data } = await API.deleteShipmentDocument(shipmentId, fileId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getShipmentDocuments(shipmentId))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getUpdatePreps = (shipmentId, callback = null) => async dispatch => {
  try {
    const payload = {
      outboundsDelta: {
        add: [],
        remove: []
      },
      shipmentId
    }

    let { ok, data } = await API.getUpdatePreps(payload)
    if (!ok) {
      throw new Error(data.message)
    }
    const updatePreps = get(data, 'data', {})
    dispatch(getShipmentUpdatePrepsSuccess(updatePreps))

    if (callback) {
      callback()
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
  }
}

export const updateCarrierInfo = (payload, shipmentId, callback) => async dispatch => {
  try {
    const { ok, status, data } = await API.updateCarrierInfo(payload, shipmentId)

    if (!ok) {
      addGaEvent('shipment-updatingCarrierFailed', shipmentId)
      throw new Error(data.message)
    }

    addGaEvent('shipment-updatedCarrier', shipmentId)

    const shipment = get(data, 'data', {})

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getShipmentSuccess(shipment))
    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const updateShipmentContainers = (payload, shipmentId, callback) => async dispatch => {
  try {
    const { ok, status, data } = await API.updateShipmentContainer(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    const shipment = get(data, 'data', {})

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getShipmentSuccess(shipment))
    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const updateEventTime = (shipment, event, datetime, type, rbac = true) => async dispatch => {
  var id = 'shipment'
  var name = 'shipment'
  const transhipmentPortId = get(event, 'transhipmentPortId')
  const transhipmentPortName = get(event, 'transhipmentPortName')

  if (event.level === 'Container') {
    id = get(event, 'containerId', id)
    name = get(event, 'containerName', name)
  }

  try {
    const { ok, data, status } = await API.addShipmentEvents(
      shipment._id,
      event.code,
      type,
      {
        transhipmentPortId,
        transhipmentPortName,
        primaryInputs: [
          {
            name,
            id,
            type: 'date',
            value: datetime
          }
        ]
      },
      rbac
    )

    if (!ok) {
      addGaEvent('shipment-updatingEventTrackingFailed', get(shipment, '_id'), get(event, 'code'))
      throw new Error(data.message)
    }

    addGaEvent('shipment-updatedEventTracking', get(shipment, '_id'), get(event, 'code'))

    await dispatch(getShipmentEvents(shipment._id, true))
    dispatch(getSingleShipment(shipment._id, true))
    dispatch(CoreActions.showMessage({ status, data }))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const cancelShipment = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.cancelShipment(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const cancelAndMoveToSpot = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.cancelAndMoveToSpot(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    const shipment = get(data, 'data', {})

    dispatch(CoreActions.showMessage({ status, data }))
    history.push(`/schedule/${shipment._id}`)
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const addShipmentContainers = (payload, shipmentId, callback) => async dispatch => {
  try {
    const { ok, status, data } = await API.addShipmentContainer(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getSingleShipment(shipmentId, true))
    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const updateShipmentSingleContainers = (payload, shipmentId, callback) => async dispatch => {
  try {
    const { ok, status, data } = await API.updateShipmentSingleContainer(payload, shipmentId, payload._id)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getSingleShipment(shipmentId, true))
    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const sendPreAlert = (payload, shipmentId, callback = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.sendPreAlert(payload, shipmentId)

    if (!ok) {
      addGaEvent('shipment-sendPreAlertFailed', shipmentId)
      throw new Error(data.message)
    }

    addGaEvent('shipment-sentPreAlert', shipmentId)

    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(getSingleShipment(shipmentId, true))
    dispatch(getPreAlertLogs(shipmentId))
    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getPreAlertLogs = (shipmentId) => async dispatch => {
  try {
    const { ok, data } = await API.getPreAlertLogs(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getShipmentPreAlertLogs(get(data, 'data', [])))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const addConsolidation = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.addConsolidation(shipmentId, payload)

    if (!ok) {
      addGaEvent('shipment-addingConsolidationFailed', shipmentId)
      throw new Error(data.message)
    }

    addGaEvent('shipment-addedConsolidation', shipmentId)

    dispatch(getSingleShipment(shipmentId, true))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getGoodsReceived = (shipmentId) => async dispatch => {
  try {
    const { ok, data } = await API.getShipmentGoodsReceived(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getShipmentGoodsReceived(get(data, 'data', [])))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const addShipmentTopic = (params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.addPosts(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getShipmentTopics = (shipment, isSync = false, success = null, fail = null) => async dispatch => {
  if (isSync === false) {
    dispatch(getShipmentTopicsSuccess([], true))
  }
  try {
    const { ok, data } = await API.getPosts({ shipment })

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getShipmentTopicsSuccess(get(data, 'data', []), false))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getShipmentTopicDetails = (topicId = null, shipment = null, success = null, fail = null) => async dispatch => {
  if (!topicId) {
    dispatch({
      type: ActionTypes.GET_SHIPMENT_TOPICS_DETAILS,
      payload: null
    })
  } else {
    try {
      const { ok, data } = await API.getPostById(topicId, { shipment })

      if (!ok) {
        throw new Error(data.message)
      }

      dispatch({
        type: ActionTypes.GET_SHIPMENT_TOPICS_DETAILS,
        payload: get(data, 'data', {})
      })
      if (success) {
        success()
      }
    } catch (e) {
      dispatch(CoreActions.showMessage(e))
      if (fail) {
        fail()
      }
    }
  }
}

export const openShipmentTopics = (topicId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.openPost(topicId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const closeShipmentTopics = (topicId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.closePost(topicId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const addShipmentTopicsComment = (topicId, params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.addPostsComment(topicId, params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const updateTopics = (topicId, params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.updatePosts(topicId, params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}
