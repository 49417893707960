import React from 'react'
import { IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

const DialogHeader = ({ text, handleClose }) => (
  <div className='dialog-header flex flex-row light-label justify-between mt-0 relative'>
    {text && <div className='bold flex items-center'>{text}</div>}
    {handleClose && (
      <IconButton color='inherit'
        className="p-6"
        onClick={handleClose} aria-label='Close'>
        <CloseIcon className="text-24 fg-gray" />
      </IconButton>
    )}
  </div>
)

export default DialogHeader
