import { get, findIndex, find } from 'lodash'

export function getShipmentFilterParams(params, filters) {
  if (filters === null || filters === '') {
    return params
  }
  if (filters && Object.keys(filters).length === 0) {
    return params
  }

  if (get(filters, 'country')) {
    if (get(filters, 'country.origin', []).length > 0) {
      params.origin = get(filters, 'country.origin', [])
    }
    if (get(filters, 'country.destination', []).length > 0) {
      params.destination = get(filters, 'country.destination', [])
    }
  }
  if (get(filters, 'port')) {
    if (get(filters, 'port.from', []).length > 0) {
      params.originPort = get(filters, 'port.from', [])
    }
    if (get(filters, 'port.to', []).length > 0) {
      params.destinationPort = get(filters, 'port.to', [])
    }
  }
  if (get(filters, 'plant')) {
    if (get(filters, 'plant.from', []).length > 0) {
      params.originPlant = get(filters, 'plant.from', [])
    }
    if (get(filters, 'plant.to', []).length > 0) {
      params.destinationPlant = get(filters, 'plant.to', [])
    }
  }

  return params
}

export function prepareCustomFilterParams(params) {
  var value = get(params, 'value', {})

  Object.keys(value).forEach(valueKey => {
    if (valueKey === 'partners') {
      var partners = get(value, valueKey, {})
      Object.keys(partners).forEach(partnerKey => {
        if (partners[partnerKey].length === 0) {
          delete partners[partnerKey]
        }
      })
      if (Object.keys(partners).length === 0) {
        delete value[valueKey]
      }
    } else {
      if (value[valueKey].length === 0) {
        delete value[valueKey]
      }
    }
  })

  return value
}

export function setShipmentListFilters(userId, currentPlantId, tab, customFilter) {
  let shipmentFilters = JSON.parse(localStorage.getItem('shipmentFilters')) || []
  const existingFilterIndex = findIndex(shipmentFilters, { userId, currentPlantId, tab })

  if (existingFilterIndex >= 0) {
    shipmentFilters[existingFilterIndex].customFilter = customFilter
  } else {
    shipmentFilters = [
      ...shipmentFilters,
      { userId, currentPlantId, tab, customFilter }
    ]
  }
  localStorage.setItem('shipmentFilters', JSON.stringify(shipmentFilters))
}

export function getShipmentListFilters(userId, currentPlantId, tab) {
  const shipmentFilters = JSON.parse(localStorage.getItem('shipmentFilters'))
  const tabFilter = find(shipmentFilters, { userId, currentPlantId, tab })
  return get(tabFilter, 'customFilter', null)
}

let searchTimer = null

export function startSearching(delay, callback = null) {
  searchTimer = setTimeout(() => {
    if (callback) {
      callback()
    }
  }, delay)
}

export function stopSearching() {
  clearTimeout(searchTimer)
}
