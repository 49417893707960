import React from 'react'
import { Grid } from '@material-ui/core'
import ChangeHistoryTwoTone from '@material-ui/icons/ChangeHistoryTwoTone'
import InfoToolTip from './InfoToolTip'
import { get } from 'lodash'

export default props => {
  const { details, iconClassName } = props

  if ((get(details, 'weight.delta', 0) !== 0 ||
    get(details, 'volume.delta', 0) !== 0)) {
    return (
      <InfoToolTip title={
        <Grid container
          spacing={1}
          justify="space-between">
          <Grid item xs={12}
            className="bold text-13">
            Delta</Grid>
          {get(details, 'weight.delta', 0) !== 0 &&
            <Grid item>
              {`Weight: ${get(details, 'weight.delta')} ${get(details, 'weight.unit', 'KG')}`}
            </Grid>
          }
          {get(details, 'volume.delta', 0) !== 0 &&
            <Grid item>
              {`Volume: ${get(details, 'volume.delta')} ${get(details, 'volume.unit', 'M3')}`}
            </Grid>
          }
        </Grid>
      }>
        <ChangeHistoryTwoTone className={iconClassName ?
          iconClassName :
          "fg-gray text-20 cursor-pointer"} />
      </InfoToolTip >
    )
  } else {
    return (
      <React.Fragment />
    )
  }
}
