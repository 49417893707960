import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid } from '@material-ui/core'
import { get, groupBy, capitalize, find } from 'lodash'
import queryString from 'query-string'
import { getNavigationConfig } from '../../core/components/Navigation/navItems'
import { useMergeState } from 'core/helpers'
import { FuseSideBar, FuseSideBarHeader, FuseSideBarBody } from 'core/components'
import { ListSearchBox } from 'core/components/Inputs'
import { WelcomeHeader, WelcomeFooter, PlantSelectionRow, PlatformCard } from '../components'
import * as WelcomeActions from '../store/actions'
import * as AuthActions from 'auth/store/actions'
import platformTemplates from '../configs/platforms'
import * as API from 'core/api'
import LoadingBar from 'react-redux-loading-bar'

const INITIAL_STATE = {
  sideBarOpen: false,
  platforms: [],
  hasDropHub: false,
  activePlatform: null,
  filteredPlatformData: null
}

const WelcomePage = ({ username, plants, networkAccess, setCurrentPlant, logout, getInfo, location: { hash } }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)

  useEffect(() => {
    const grouped = groupBy(plants, 'type')
    const platforms = platformTemplates.map(platform => {
      const data = grouped[platform.type] || []
      return {
        ...platform,
        total: data.length,
        unavailable: data.length === 0,
        data
      }
    })
    const hasDropHub = plants.some(v => v.type === 'dropHub')

    setState({ platforms, hasDropHub })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plants])

  useEffect(() => {
    getInfo()
  }, [getInfo])

  const getRedirectHref = (currentPlant, platform) => {
    const redirectHref = get(queryString.parse(hash), 'redirect_href')
    let isRedirectAllowed = false
    if (redirectHref) {
      getNavigationConfig(currentPlant.type, currentPlant.role).forEach(rootItem => {
        if (find(get(rootItem, 'children', []), item =>
          (redirectHref.split('/')[0] === get(item, 'url', '').slice(1)))) {
          isRedirectAllowed = true
        }
      })
    }
    return isRedirectAllowed ? redirectHref : platform.route
  }

  const onCardClick = async platform => {
    if (platform.unavailable || !platform.route) {
      return
    }

    if (platform.total > 1) {
      setState({
        activePlatform: platform,
        sideBarOpen: true,
        filteredPlatformData: platform.data
      })

      console.log("platform is updating..!")

    } else {
      if (platform.data && platform.data.length) {
        await setCurrentPlant(platform.data[0])
        await API.setContextPlantHeader(platform.data[0]._id)
      }
      window.location.href = getRedirectHref(platform.data[0], platform)
    }
  }

  const onPlantClick = async plant => {
    const platform = state.activePlatform
    await setCurrentPlant(plant)
    await API.setContextPlantHeader(plant._id)
    if (platform.unavailable || !platform.route) {
      return
    }
    window.location.href = getRedirectHref(plant, platform)
  }

  const onDropHubClick = () => {
    const platform = state.platforms.find(v => v.type === 'dropHub')
    if (platform) {
      onCardClick(platform)
    }
  }

  const onConfigureClick = () => {
    window.location.href = getAdminUrl()
  }

  const onAnalytics = () => {
    window.location.href = 'https://analytics.trames.io/';
  }

  const getAdminUrl = () => {
    const info = document.location.origin.split('://')

    if (!info[1].startsWith('localhost')) {
      info[1] = `${info[1]}/admin/`
    }

    return info.join('://')
  }

  const closeSideBar = () => setState({ sideBarOpen: false })

  const sideBarTitle = state.activePlatform ? `${capitalize(state.activePlatform.name)} plants` : ''

  const filterPlants = (e) => {
    const activePlants = state.activePlatform.data
    let updatedList = activePlants.filter(item => {
      return item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1
    });

    setState({
      filteredPlatformData: updatedList
    })
  }

  return (
    <Grid container alignContent='space-between' className='welcome h-full w-full'>
      <WelcomeHeader
        username={username}
        hasDropHub={state.hasDropHub}
        hasConfigure={networkAccess !== 'none'}
        onDropHubClick={onDropHubClick}
        onConfigureClick={onConfigureClick}
        logout={logout}
        onAnalytics={onAnalytics}
      />

      <Grid item xs={12}>
        <Grid container
          justify='space-between'
          className='platforms'>
          {state.platforms.map(
            (platform, index) => !platform.hide &&
              <PlatformCard key={index}
                data={platform}
                onClick={onCardClick} />
          )}
        </Grid>
      </Grid>

      <WelcomeFooter />

      <FuseSideBar open={state.sideBarOpen} onBackDropClick={closeSideBar}>
        <FuseSideBarHeader text={sideBarTitle} handleClose={closeSideBar} />
        <FuseSideBarBody>
          <Grid container className="plant-search-box">
            <ListSearchBox type="text" placeholder="Search your plant..." onChange={filterPlants} />
          </Grid>
          {state.activePlatform &&
            state.filteredPlatformData.map((plant, index) => (
              <PlantSelectionRow key={index} data={plant} onClick={onPlantClick} />
            ))}
        </FuseSideBarBody>
      </FuseSideBar>
      <LoadingBar className='page-loading-bar' />
    </Grid>
  )
}

const mapStateToProps = ({ auth: { info } }) => ({
  username: info.firstname,
  plants: info.plants,
  networkAccess: info.networkAccess
})

const mapDispatchToProps = dispatch => ({
  setCurrentPlant: plant => dispatch(WelcomeActions.setCurrentPlant(plant)),
  getInfo: () => dispatch(AuthActions.getInfo()),
  logout: () => dispatch(AuthActions.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePage)
