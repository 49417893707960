import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { get, filter } from 'lodash'
import { TransportTimeline } from '../../core/components'
import { bookingWizard, bookingInfoForm } from '../../core/localization'
import { formatTimestamp, getAddressFromLocation } from '../../core/helpers'

const ContainerCount = ({ imageSrc, label, count }) => (
  <Grid container
    justify="space-between"
    alignItems="center"
    spacing={1}
    className="text-12">
    <Grid item xs className="text-grey-dark">
      <Grid container
        alignItems="center"
        spacing={1}>
        <Grid item className="text-center">
          <img src={imageSrc} alt={label}
            className="flex h-12" />
        </Grid>
        <Grid item>
          {get(bookingInfoForm, `containers.options.${label}`)}
        </Grid>
      </Grid>
    </Grid>
    <Grid item className="text-grey-darkest semi-bold text-right">
      {count}
    </Grid>
  </Grid>
)

const ReviewBookingSummary = ({ shipment }) => {
  const [timeline, setTimeline] = useState([])

  useEffect(() => {
    const timelineData = []
    get(shipment, 'journey', []).forEach(item => {
      const type = get(item, 'location.type')
      const subType = get(item, 'location.subType')
      let connector = null

      if ((type === 'warehouse' && ['origin', 'destination'].includes(subType)) ||
        (type === 'port' && subType === 'destination')) {
        if (type === 'warehouse' && subType === 'origin') {
          connector = {
            icon: 'directions_boat',
            color: '#42b4e6',
            iconRounded: true
          }
        } else if (type === 'port' && subType === 'destination') {
          connector = {
            icon: 'local_shipping',
            color: '#3dcd58',
            iconRounded: true
          }
        }

        timelineData.push({
          place: get(item, 'location.name'),
          address: getAddressFromLocation(get(item, 'location.address', {})),
          color: subType === 'origin' ? '#42b4e6' : '#3dcd58',
          connector
        })
      }
    })
    setTimeline(timelineData)
  }, [shipment])

  return (
    <Grid container className="p-20">

      {get(shipment, 'type') === 'FCL' &&
        // Containers
        <Grid item xs={12}>
          <Grid container
            spacing={1}
            className='text-12 pb-20 mb-20 border-dashed border-b-1 border-gray'>

            <Grid item xs={12}>
              <h4 className='text-base text-grey-darker pb-12 m-0'>Containers</h4>
            </Grid>

            {filter(get(shipment, 'containers', []), { type: 'C20GP' }).length > 0 &&
              <Grid item xs={12}>
                <ContainerCount label="s20"
                  imageSrc="assets/images/icons/20ft-standard.png"
                  count={filter(get(shipment, 'containers', []), { type: 'C20GP' }).length} />
              </Grid>
            }

            {filter(get(shipment, 'containers', []), { type: 'C40GP' }).length > 0 &&
              <Grid item xs={12}>
                <ContainerCount label="s40"
                  imageSrc="assets/images/icons/40ft-standard.png"
                  count={filter(get(shipment, 'containers', []), { type: 'C40GP' }).length} />
              </Grid>
            }

            {filter(get(shipment, 'containers', []), { type: 'C40HC' }).length > 0 &&
              <Grid item xs={12}>
                <ContainerCount label="hc40"
                  imageSrc="assets/images/icons/40ft-high-cube.png"
                  count={filter(get(shipment, 'containers', []), { type: 'C40HC' }).length} />
              </Grid>
            }

          </Grid>
        </Grid>
      }

      {/* Vessel Schedule */}
      <Grid item xs={12}>
        <Grid container
          spacing={1}
          className="text-12 pb-20 mb-20 border-dashed border-b-1 border-gray">

          <Grid item xs={12}>
            <h4 className='text-base text-grey-darker pb-8 m-0'>
              Vessel Schedule</h4>
          </Grid>

          <Grid item xs={12}>
            <Grid container
              justify="space-between"
              spacing={1}>
              <Grid item xs
                className="fg-gray">ETA at Destination port</Grid>
              <Grid item xs
                className="medium text-right">
                {get(shipment, 'vesselSchedule.transhipmentPorts', []).length === 0 &&
                  formatTimestamp(get(shipment, 'vesselSchedule.FirstPOL.etaNextPort'), 'date-time')
                }
                {get(shipment, 'vesselSchedule.transhipmentPorts', []).length > 0 &&
                  formatTimestamp(get(shipment, `vesselSchedule.transhipmentPorts[${get(shipment, 'vesselSchedule.transhipmentPorts', []).length - 1}].etaNextPort`), 'date-time')
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container
              justify="space-between"
              spacing={1}>
              <Grid item xs
                className="fg-gray">ETD from Origin port</Grid>
              <Grid item xs
                className="medium text-right">
                {formatTimestamp(get(shipment, 'vesselSchedule.FirstPOL.etd'), 'date-time')}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Transport Route */}
      <Grid item xs={12}>
        <div className='info-container'>
          <h4 className='mb-20 text-base text-grey-darker'>
            {bookingWizard.summary.transportRoute}
          </h4>
          <TransportTimeline data={timeline} />
        </div>
      </Grid>

    </Grid>
  )
}

export default ReviewBookingSummary
