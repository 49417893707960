import React from 'react'
import {
  Grid,
  withStyles,
  Typography,
  Icon
} from '@material-ui/core'
import { get } from 'lodash'
import moment from 'moment'
import { FusePlaceHolder } from '../../core/components'

const GoodsReceived = props => {
  const { goodsReceived } = props

  return (
    <Grid container
      spacing={2}
      className="p-20 regular text-12">

      <Grid item xs={12}>
        <Typography className='fg-gray bold mb-12'>
          Goods Received Information</Typography>
      </Grid>

      {(get(goodsReceived, 'loading') === false && get(goodsReceived, 'data', []).length === 0) &&
        <FusePlaceHolder className="flex flex-col">
          <Grid container justify="center" spacing={2} className="text-14">
            <Icon className="fg-gray text-60">info</Icon>
            <Grid item xs={12} className="bold text-center">
              No Goods Received</Grid>
            <Grid item xs={12} className="regular text-center">
              Looks like there are no goods received for this shipment yet</Grid>
          </Grid>
        </FusePlaceHolder>
      }

      {get(goodsReceived, 'data', []).map((goodsReceivedItem, goodsReceivedIndex) => {
        return (
          <Grid item xs={12}
            key={goodsReceivedIndex}
            className={"border-gray" + (goodsReceivedIndex > 0 ? ' border-solid border-t-2' : '')}>
            <Grid container spacing={2} className="py-12">
              <Grid item xs={9}
                className="border-solid border-r-1 border-gray">
                {get(goodsReceivedItem, 'Item', []).map((item, itemIndex) => {
                  return (
                    <Grid container key={itemIndex}
                      className={'border-gray py-4' + (itemIndex > 0 ? ' border-dashed border-t-1' : '')}>
                      <Grid item cs={12} sm={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item
                            className="medium">GR Line No:</Grid>
                          <Grid item
                            className="fg-gray">{get(item, 'GRLineNum')}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item cs={12} sm={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item
                            className="medium">ShipTo PO No:</Grid>
                          <Grid item
                            className="fg-gray">{get(item, 'ShipToPONum')}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item cs={12} sm={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item
                            className="medium">ShipTo PO Line No:</Grid>
                          <Grid item
                            className="fg-gray">{get(item, 'ShipToPOLineNum')}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item cs={12} sm={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item
                            className="medium">PO Qty:</Grid>
                          <Grid item
                            className="fg-gray">{get(item, 'POQuantity')}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item cs={12} sm={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item
                            className="medium">GR Qty:</Grid>
                          <Grid item
                            className="fg-gray">{get(item, 'GRQuantity')}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item cs={12} sm={12} md={6}>
                        <Grid container spacing={1}>
                          <Grid item
                            className="medium">Material:</Grid>
                          <Grid item
                            className="fg-gray">{get(item, 'Material')}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
              <Grid item xs={3}>
                <Grid container>
                  <Grid item xs={12}
                    className="text-right semi-bold text-14 pb-8">Posting Date</Grid>
                  <Grid item xs={12}
                    className="text-right medium fg-gray">
                    {moment(`${get(goodsReceivedItem, 'Header.PostingDate')}T${get(goodsReceivedItem, 'Header.TimeofEntry')}`)
                      .format('DD MMM YYYY LT')
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
      })}

    </Grid>
  )
}
const styles = {}

export default withStyles(styles)(GoodsReceived)
