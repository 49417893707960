import React from 'react'
import classNames from 'classnames'
import { MenuItem, FormControl, Grid, Select, withStyles, InputBase, Icon } from '@material-ui/core'
import { Indicator } from '../../core/components'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #ced4da',
    fontSize: '12px',
    minWidth: '30px',
    lineHeight: '16px',
    padding: '12px 26px 12px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'IBMPlexSans-SemiBold',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}))(InputBase)

const ToolBar = ({ handleSearchChange, handleSearch, search, rowFilter, updateRowFilter }) => (
  <Grid container className='mt-20 mb-20' alignItems='center'>
    <Grid item xs md={4}>
      <div
        className={classNames('flex items-center bg-white h-32 p-20')}
        style={{ border: '1px solid #e3e3e4', borderRadius: 4 }}
      >
        <img src='assets/images/icons/Search-Organizations.png' className='mr-6' alt='search' onClick={handleSearch} />
        <input
          type='text'
          className='input'
          placeholder='Search by Username or Email Address'
          value={search}
          onChange={e => {
            handleSearchChange(e.target.value)
          }}
          style={{ width: '100%' }}
        />
        {search !== '' && (
          <Icon style={{ fontSize: 15, cursor: 'pointer' }} onClick={() => handleSearchChange('')}>
            close
          </Icon>
        )}
      </div>
    </Grid>
    <Grid item xs md={4}>
      <Indicator className="regular"
        indications={[
          { color: '#3DCD59', label: 'Active', checked: false },
          { color: '#49B7E6', label: 'Invited', checked: false },
          { color: '#E48006', label: 'Inactive', checked: false }
        ]}
      />
    </Grid>
    <Grid item xs md={4} className='flex justify-end'>
      <FormControl>
        <Select
          className='mb-8 min-w-128'
          labelId='countryCode'
          id='countryCode'
          value={rowFilter}
          onChange={e => updateRowFilter(e, e.target.value)}
          input={<BootstrapInput />}
        >
          <MenuItem value={'all'}>All</MenuItem>
          <MenuItem value={'active'}>Active</MenuItem>
          <MenuItem value={'inactive'}>In Active</MenuItem>
          <MenuItem value={'invited'}>Invited</MenuItem>
        </Select>
      </FormControl>
    </Grid>
  </Grid>
)

export default ToolBar
