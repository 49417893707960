export function shipmentNameBeautifier(name) {
  let parts = name.substr(0, 29).split('__');
  var beautifiedName = parts[0]
  if (parts[1]) {
    beautifiedName += ' (' + parts[1] + ')'
  }
  if(beautifiedName.indexOf('_') > -1){
    parts = beautifiedName.split('_');
    beautifiedName = parts[0] + ' (' + parts[1] + ')'
  }
  return beautifiedName.toUpperCase();
}
