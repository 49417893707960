import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Tabs, Tab, Typography, Box, Grid, Button, Collapse } from '@material-ui/core'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions,
  InfoToolTip
} from '../components'
import { get, round } from 'lodash'
import { FusePlaceHolder } from 'core/components'
import Skeleton from 'react-loading-skeleton'
import { copyToClipboard, showMessage } from '../store/actions'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import fileDownload from 'js-file-download'
import InfoTooltip from './InfoToolTip'

const formatValue = value => {
  return value ? value.toFixed(3).toString() : value
}

const CSOutboundsSidebar = ({
  open,
  closeSideBar,
  sideBarTitle,
  outboundsList,
  consolidations,
  onlyOutbounds,
  currentPlant,
  copyToClipboard,
  showMessage }) => {
  const [tabValue, setTabValue] = useState(onlyOutbounds ? 1 : 0)

  useEffect(() => {
    if (open) {
      setTabValue(onlyOutbounds ? 1 : 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const TabPanel = ({ children, value, index, ...other }) => (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box style={{ margin: 20 }}>{children}</Box>
    </Typography>
  )

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  return (
    <FuseSideBar open={open ? true : false}
      onBackDropClick={closeSideBar}>
      <FuseSideBarHeader text={sideBarTitle} handleClose={closeSideBar} />
      <FuseSideBarBody style={{ padding: 0 }}>
        {!onlyOutbounds && (
          <>
            <Tabs value={tabValue}
              onChange={(e, val) => setTabValue(val)}
              className='cs-tabs'>
              <Tab
                label='CS'
                {...a11yProps(0)}
                style={{ width: '50%' }}
                className={'cs-tabs-item ' + (tabValue === 0 ? 'selected' : '')}
              />
              <Tab
                label='Outbounds'
                style={{ width: '50%' }}
                {...a11yProps(1)}
                className={'cs-tabs-item ' + (tabValue === 1 ? 'selected' : '')}
              />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
              <CSList consolidations={consolidations}
                currentPlant={currentPlant}
                copyToClipboard={copyToClipboard} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <OutBounds outboundsList={outboundsList}
                currentPlant={currentPlant} />
            </TabPanel>
          </>
        )}
        {onlyOutbounds && (
          <Grid container className="p-20">
            <OutBounds outboundsList={outboundsList}
              noTab={true}
              isLoading={open === 'loading' ? true : false}
              currentPlant={currentPlant}
              showMessage={showMessage} />
          </Grid>
        )}
      </FuseSideBarBody>

      <FuseSideBarActions>
        <Button className='btn btn_full-space'
          onClick={closeSideBar}>CLOSE</Button>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

const CSList = ({ consolidations, currentPlant, copyToClipboard }) => {
  const [csSearchValue, setCsSearchValue] = useState('')
  let filteredConsolidations = []
  if (consolidations && consolidations.length > 0) {
    filteredConsolidations = consolidations.filter(
      consolidation =>
        get(consolidation, 'CSCode')
          .toLowerCase()
          .indexOf(csSearchValue) !== -1
    )
  }
  return (
    <React.Fragment>
      <div className={'flex items-center bg-white h-32 p-20 mb-16 search-cs'}>
        <img src='assets/images/icons/Search-Organizations.png' className='mr-6' alt='search' />
        <input type='text'
          className='input'
          placeholder='Search CS codes...'
          value={csSearchValue}
          onChange={e => {
            let val = e.target.value.toLowerCase()
            setCsSearchValue(val)
          }}
        />
      </div>
      {filteredConsolidations.length === 0 && (
        <FusePlaceHolder className='flex flex-col'
          icon='error'
          title='No CS code found'></FusePlaceHolder>
      )}
      {filteredConsolidations.length > 0 &&
        <Grid container>
          <Grid item xs={12}
            className="text-right pb-12">
            <label className="fg-blue text-12 semi-bold uppercase cursor-pointer"
              onClick={() => {
                const csCodes = filteredConsolidations
                  .map(item => get(item, 'CSCode'))
                  .filter(Boolean)
                  .join('\n')
                copyToClipboard(csCodes)
              }}>
              copy cs codes</label>
          </Grid>
          <Grid item xs={12}>
            {filteredConsolidations.map((consolidation, index) => (
              <div key={index} className='outbounds-sidebar-row'>
                <Grid container
                  justify="space-between"
                  alignItems="center"
                  className="regular">
                  <Grid item xs={6}
                    className='semi-bold text-14 pb-4'>
                    {get(consolidation, 'CSCode')}
                  </Grid>
                  <Grid item xs={6}
                    className="text-12 pb-4 text-right fg-gray">
                    {round(get(consolidation, 'packagedVolume.value', get(consolidation, 'volume.value', 0)), 3)}&nbsp;{get(consolidation, 'volume.unit', 'M3')}
                &nbsp;&bull;&nbsp;
                {round(get(consolidation, 'packagedWeight.value', get(consolidation, 'weight.value', 0)), 3)}&nbsp;{get(consolidation, 'weight.unit', 'KG')}
                  </Grid>

                  <Grid item xs={4}
                    className="text-12 fg-gray">
                    {get(consolidation, 'outbounds', 0)}&nbsp;outbound(s)
              </Grid>

                  <Grid item xs={6}
                    className="text-12 fg-gray uppercase semi-bold">
                    <Grid container spacing={1}
                      justify="flex-end">
                      {/* @todo: Remove this check in future */}
                      {get(currentPlant, 'type') === 'shipper' &&
                        <Grid item className={
                          (get(consolidation, 'goodsType', '').toLowerCase() === 'dg' ? ' fg-red' : '') ||
                          (get(consolidation, 'goodsType', '').toLowerCase() === 'normal' ? ' fg-blue' : '')
                        }>
                          {get(consolidation, 'goodsType')}
                        </Grid>
                      }
                      {get(consolidation, 'specialLabels', []).length > 0 &&
                        <Grid item className="fg-blue">
                          {get(consolidation, 'specialLabels', []).join(', ')}
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>

              </div>
            ))}
          </Grid>
        </Grid>
      }
    </React.Fragment>
  )
}

const OutBounds = ({ outboundsList, noTab, isLoading, currentPlant, showMessage }) => {
  const [obSearchValue, setObSearchValue] = useState('')
  const [openEvents, setOpenEvents] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);

  const handleExpandClick = (outbound, type) => {
    if (type === 'events') {
      setOpenDocuments(false)
      if (openEvents === get(outbound, 'meta.outboundId')) {
        setOpenEvents(false)
      } else {
        setOpenEvents(get(outbound, 'meta.outboundId'))
      }
    } else if (type === 'documents') {
      setOpenEvents(false)
      if (openDocuments === get(outbound, 'meta.outboundId')) {
        setOpenDocuments(false)
      } else {
        setOpenDocuments(get(outbound, 'meta.outboundId'))
      }
    }
  }

  const downloadSingleFile = async (document) => {
    if (!get(document, 'docStoreId')) {
      showMessage({
        variant: 'error',
        message: 'Can;t find document store identification'
      })
    }
    try {
      // @todo Add fetch document file call here
      const { data } = {}
      fileDownload(data, `${get(document, 'originalName', 'document')}.${get(document, 'identifier.type', 'pdf')}`)
    } catch (e) {
      showMessage(e)
    }
  }

  let filteredOutbounds = []
  if (outboundsList && outboundsList.length > 0) {
    filteredOutbounds = outboundsList.filter(data => get(data, 'meta.outboundId', '').toLowerCase().indexOf(obSearchValue) !== -1)
  }
  let widthStyle = {}
  if (noTab) {
    widthStyle = { width: '100%' }
  }
  return (
    <React.Fragment>
      <div className={'flex items-center bg-white h-32 p-20 mb-16 search-cs'} style={widthStyle}>
        <img src='assets/images/icons/Search-Organizations.png' className='mr-6' alt='search' />
        <input type='text'
          className='input'
          placeholder='Search outbounds…'
          value={obSearchValue}
          onChange={e => {
            let val = e.target.value.toLowerCase()
            setObSearchValue(val)
          }}
        />
      </div>

      {isLoading &&
        [1, 2, 3].map(rootItem => (
          <Grid container key={rootItem}
            className="mb-16">
            <Grid item xs={12}>
              <Skeleton height={70} />
            </Grid>
          </Grid>
        ))
      }

      {!isLoading && filteredOutbounds.length === 0 && (
        <FusePlaceHolder className='flex flex-col'
          icon='error'
          title='No outbounds'
          style={widthStyle} />
      )}

      {!isLoading && filteredOutbounds.length > 0 &&
        filteredOutbounds.map((data, index) => (
          <div key={index} className='outbounds-sidebar-row' style={widthStyle}>
            <Grid container
              alignItems="center"
              justify="space-between"
              className="mb-4 regular">
              <Grid item className="semi-bold text-14">{get(data, 'meta.outboundId')}</Grid>
              <Grid item className="text-12 fg-gray">
                <Grid container
                  alignItems="center"
                  spacing={1}>
                  <Grid item>
                    <InfoTooltip title="Volume">
                      <label>{formatValue(get(data, 'volume.value', 0))} {get(data, 'volume.unit', 'M3')}</label>
                    </InfoTooltip>
                  </Grid>
                  <Grid item>&bull;</Grid>
                  <Grid item>
                    <InfoTooltip title="Weight">
                      <label>{formatValue(get(data, 'weight.value', 0))} {get(data, 'weight.unit', 'KG')}</label>
                    </InfoTooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container
              alignItems="center"
              justify="space-between"
              spacing={1}
              className="text-12 fg-gray regular pb-4">
              <Grid item className="semi-bold">
                <Grid container spacing={1}>
                  {/* @todo: Remove this check in future */}
                  {get(currentPlant, 'type') === 'shipper' &&
                    <Grid item className={
                      (get(data, 'goodsType', '').toLowerCase() === 'dg' ? ' fg-red' : '') ||
                      (get(data, 'goodsType', '').toLowerCase() === 'normal' ? ' fg-blue' : '')
                    }>{get(data, 'goodsType', '').toUpperCase()}</Grid>
                  }
                  <Grid item className="fg-orange">
                    {get(data, 'shipmentMode', '').toUpperCase()}
                  </Grid>
                  {get(data, 'specialLabels', []).length > 0 &&
                    <Grid item className="fg-blue">
                      {get(data, 'specialLabels', []).join(' ').toUpperCase()}
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item>
                <InfoTooltip title="CSCode">
                  <label>{get(data, 'meta.CSCode')}</label>
                </InfoTooltip>
              </Grid>
            </Grid>

            <Grid container
              alignItems="center"
              justify="space-between"
              spacing={1}
              className="text-12 fg-gray regular pb-4">
              <Grid item>
                <InfoTooltip title="Header PO">
                  <label>{get(data, 'headerPONumber')}</label>
                </InfoTooltip>
              </Grid>
              <Grid item>
                <InfoTooltip title="Sales Order">
                  <label>{`${get(data, 'salesOrder.number', '')} (${get(data, 'salesOrder.itemNumber', '')})`}</label>
                </InfoTooltip>
              </Grid>
            </Grid>

            <Grid container
              alignItems="center"
              justify="space-between"
              spacing={1}
              className="text-12 fg-gray regular">
              <Grid item>
                <InfoTooltip title="Ship To PO">
                  <label>{`${get(data, 'shipToParty.poNumber', '')} (${get(data, 'shipToParty.poLineNumber', '')})`}</label>
                </InfoTooltip>
              </Grid>
              <Grid item>
                <InfoTooltip title="Sold To PO">
                  <label>{`${get(data, 'soldToParty.poNumber', '')} (${get(data, 'soldToParty.poLineNumber', '')})`}</label>
                </InfoTooltip>
              </Grid>
            </Grid>

            <Grid container className="text-14">
              <Grid item xs={6}
                className="mt-8 pr-8 cursor-pointer"
                onClick={() => handleExpandClick(data, 'events')}>
                <Grid container
                  alignItems="center"
                  justify="space-between"
                  className={openEvents === get(data, 'meta.outboundId') ? 'fg-green' : 'fg-gray'}>
                  <Grid item className="semi-bold">
                    {`Events (${get(data, 'events', []).length})`}
                  </Grid>
                  {openEvents === get(data, 'meta.outboundId') ?
                    <ExpandLess /> :
                    <ExpandMore />
                  }
                </Grid>
              </Grid>

              <Grid item xs={6}
                className="mt-8 pl-8 cursor-pointer"
                onClick={() => handleExpandClick(data, 'documents')}>
                <Grid container
                  alignItems="center"
                  justify="space-between"
                  className={openDocuments === get(data, 'meta.outboundId') ? 'fg-green' : 'fg-gray'}>
                  <Grid item className="semi-bold">
                    {`Documents (${get(data, 'docs', []).length})`}
                  </Grid>
                  {openDocuments === get(data, 'meta.outboundId') ?
                    <ExpandLess /> :
                    <ExpandMore />
                  }
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Collapse in={openEvents === get(data, 'meta.outboundId')}
                  timeout="auto"
                  unmountOnExit>
                  <Grid container spacing={1}
                    className="mt-4 pt-4 border-dashed border-t-1 border-grey">
                    {get(data, 'events', []).length === 0 &&
                      <Grid item xs={12}
                        className="text-center italic text-12 fg-gray">
                        No Events</Grid>
                    }
                    {get(data, 'events', []).map((outboundEvent, outboundEventIndex) => {
                      return (
                        <Grid item xs={12}
                          key={outboundEventIndex}
                          className="text-12 regular">
                          <Grid container
                            spacing={1}
                            justify="space-between">
                            <Grid item xs={3} className="medium">
                              {get(outboundEvent, 'eventCode')}
                            </Grid>
                            <Grid item xs={9}>
                              <Grid container
                                spacing={1}>
                                {get(outboundEvent, 'eventName') &&
                                  <Grid item xs={12}>
                                    {get(outboundEvent, 'eventName')}
                                  </Grid>
                                }
                                {get(outboundEvent, 'occurrenceAt') &&
                                  <Grid item xs={12} className="text-right">
                                    {moment(get(outboundEvent, 'occurrenceAt')).format('DD/MM/YYYY h:mm a')}
                                  </Grid>
                                }
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Collapse>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={openDocuments === get(data, 'meta.outboundId')}
                  timeout="auto"
                  unmountOnExit>
                  <Grid container spacing={1}
                    className="mt-4 pt-4 border-dashed border-t-1 border-grey">
                    {get(data, 'docs', []).length === 0 &&
                      <Grid item xs={12}
                        className="text-center italic text-12 fg-gray">
                        No Documents</Grid>
                    }
                    {get(data, 'docs', []).map((outboundDocument, outboundDocumentIndex) => {
                      return (
                        <Grid item xs={12}
                          key={outboundDocumentIndex}
                          className="text-12 regular">
                          <Grid container
                            spacing={1}
                            justify="space-between">
                            <Grid item xs={10}>
                              <Grid container spacing={1}>
                                <Grid item xs={12} className="medium">
                                  {`${get(outboundDocument, 'originalName')} (${get(outboundDocument, 'type')})`}
                                </Grid>
                                {get(outboundDocument, 'identifier.type') &&
                                  <Grid item xs={12}>
                                    {`${get(outboundDocument, 'identifier.type')}--${get(outboundDocument, 'identifier.value')}`}
                                  </Grid>
                                }
                              </Grid>
                            </Grid>
                            <Grid item xs={2} className="text-right">
                              <InfoToolTip title="Download this document">
                                {/* @todo remove hidden class when we figure out download function */}
                                <img src="/assets/images/icons/import-header-green.svg"
                                  alt="download"
                                  className="cursor-pointer hidden"
                                  height={14}
                                  onClick={() => downloadSingleFile(outboundDocument)} />
                              </InfoToolTip>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </div>
        ))}
    </React.Fragment>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  copyToClipboard: (...args) => dispatch(copyToClipboard(...args)),
  showMessage: (...args) => dispatch(showMessage(...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(CSOutboundsSidebar)
