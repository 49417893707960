import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
const TermsDialog = ({ setTermsDialogOpen, termsDialogOpen }) => {
  return (
    <Dialog
      open={termsDialogOpen}
      onClose={() => setTermsDialogOpen(false)}
      scroll={`paper`}
      maxWidth='xl'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
    >
      <DialogTitle id='scroll-dialog-title'>Terms and Conditions</DialogTitle>
      <DialogContent dividers={true}>
        <DialogContentText id='scroll-dialog-description' tabIndex={-1}>
          <p className='p1'>&nbsp;</p>
          <p className='p2'>
            <span className='s1'>
              <strong>Privacy Policy</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              By using our website and/or Trames products and services (&ldquo;Our Solutions&rdquo;), you consent to the
              collection and use of information as set forth in this Policy.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s1'>&ldquo;Trames&rdquo; refers to Trames Pte Ltd.</span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s1'>
              This document describes Trames&rsquo; policy for handling, processing, storing, and otherwise treating personal
              data, whether you are using Our Solutions on behalf of a company or organization (&ldquo;Your
              Organization&rdquo;) or merely browsing Our Solutions.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Personal Information Handling and Privacy</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              The information we gather from users enables us to personalize and improve Our Solutions. When our users set up
              user accounts and profiles, these can be used to personalize their experience on Our Solutions.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s1'>
              &ldquo;Personal Information&rdquo; refers to a person&rsquo;s name and information associated with his or her
              personal identity, including attributes designated as Personal Data under the data protection law applicable to
              Your Organization&rsquo;s use of Our Solutions. Personal Information, such as name, business address, and
              business email may be required for use of some features of Our Solutions. If you do not want to provide
              Personal Information to Trames or wish to have Trames remove your Personal Information from Our Solutions,
              please contact us to establish if there are any workarounds.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s1'>
              Trames will not collect &ldquo;Sensitive Personal Information&rdquo; which could include national
              identification numbers, financial account numbers associated with individual persons, personal credit card
              and/or banking account numbers, and medical records or health care claim information associated with
              individuals.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Use of Personal Information by Trames</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              Trames will treat Personal Information as confidential and will process the data according to the lawful and
              technically feasible instructions of Your Organization, as applicable within the scope of Our Solutions. This
              statement is considered to be part of such instructions.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s1'>
              Trames will use Personal Information for the following limited purposes, to: facilitate operation of Our
              Solutions and its related services; enhance the use of Our Solutions and its related web pages; enhance the
              functionalities of Our Solutions; perform internal tracking; enable Trames to contact you; process requested
              transactions through Our Solutions (including use of templates and document creation); and analyze the volume
              and history of a company&rsquo;s usage.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Use of Tracking Technologies Including Cookies</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              A cookie is a small file or a string of letters and numbers stored in your browser by websites that you visit.
              Cookies help to make the interaction between users and websites faster and easier. Cookies do not contain any
              Personal Information about you and therefore cannot be used to identify you personally. We also use cookies to
              help us understand how users utilize Our Solutions, for example the pages customers visit and the links they
              click on. This helps us make improvements and understand what visitors to our site are interested in. If you
              configure your browser to reject cookies from Our Solutions, you will not be able to access Our Solutions.
              Neither Trames nor its service providers link the information we gather automatically or that we store in
              cookies to Personal Information you submit while using Our Solutions except as necessary to perform web site
              security, service functionality and usage analytics. The web pages you access when using Our Solutions do not
              respond to &ldquo;do not track&rdquo; signals sent by your browser. Emails will be sent from or to Our
              Solutions, and Trames may track the open-rate, click-through rate and/or bounce rate of the messages at the
              individual level.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Visibility of Personal Information within Our Solutions</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              Trames may provide your business contact information to trading partners, potential trading partners, or to
              others if you (or your Organization&rsquo;s account administrator) are serving as a company contact for Your
              Organization.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Consent</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              By submitting Personal Information to Our Solutions, you are consenting to Trames&rsquo;s collection,
              processing, storage, and use of that information in accordance with this policy. Before providing Personal
              Information to Our Solutions, such as providing a companywide contact for your company or organization, obtain
              the legal entity&rsquo;s or individual&rsquo;s consent for the collection, transfer, processing, and use of
              that information in accordance with this policy and privacy law applicable to your organization. As a user of
              Our Solutions, and subject to the roles you hold and your notification settings, you may be required to receive
              certain administrative notices from Trames.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Correcting Account Information (Exercising Your Right to Access Personal Information)</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              You must have the ability to access, edit, and delete any of the Personal Information that you have provided to
              us. Trames encourages you to promptly update your Personal information if it changes. You may change any of
              your Personal Information in your Account online at any time by:
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <ul className='ul1'>
            <li className='li3'>
              <span className='s1'>Logging in in accordance with instructions posted elsewhere in Our Solutions.</span>
            </li>
            <li className='li3'>
              <span className='s1'>
                Contacting us with a request to update, review, or delete Personal Information at the support email found at
                the end of this Policy. We may decline requests that are unreasonably repetitive, require disproportionate
                technical effort, jeopardize the privacy of others, or are extremely impractical.
              </span>
            </li>
          </ul>
          <p className='p3'>
            <span className='s1'>
              In certain circumstances, deletion of your Personal Information may require approval by your employer (e.g.
              invoice control data) and may require Trames assistance. Some requests to delete data must be made to Trames
              through the administrative contact for your company.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s1'>
              Trames may refuse to give access to Our Solutions for legitimate reasons including delinquent payments on the
              account, a legal dispute, or security concerns. If you are unable to correct, update, or delete your personal
              information due to the fact that you are no longer an employee of the business that is the account holder, or
              your account has been terminated, you may contact the Trames at the details provided below. In each case,
              Trames will take reasonable measures to accommodate your request or respond in writing with the legal basis for
              denying the request within thirty (30) days.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Disclosure by Trames to Third Parties</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              Trames does not provide your Personal Information to third parties, except as described elsewhere in this
              policy and in our contracts with our customers, unless:
            </span>
          </p>
          <ol className='ol1'>
            <li className='li3'>
              <span className='s1'>
                you (or Your Organization&rsquo;s account administrator acting on your behalf) request or authorize it;{' '}
              </span>
            </li>
            <li className='li3'>
              <span className='s1'>
                such disclosure is necessary to process transactions or provide services which you have;{' '}
              </span>
            </li>
            <li className='li3'>
              <span className='s1'>
                Trames is compelled to do so by a governmental authority, regulatory body, or under subpoena or similar
                governmental request or to establish or defend a legal claim;{' '}
              </span>
            </li>
            <li className='li3'>
              <span className='s1'>
                the third party is acting as our agent or sub-contractor in performing services (e.g., Trames&rsquo;s use of
                a third party telecommunications provider); or{' '}
              </span>
            </li>
            <li className='li3'>
              <span className='s1'>
                you designate your Personal Information to be publicly viewable in Our Solutions. All companies that act on
                our behalf or in conjunction with Trames in this manner are required to safeguard Personal Information and
                respect Trames opt-out procedures.
              </span>
            </li>
          </ol>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Data Retention</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              Trames will retain Personal Information in active databases for varying lengths of time depending upon the
              specific Services, type of data, and applicable law in accordance with the agreement between Trames and your
              organization. The policy on data retention for each Services is set forth in the documentation or terms for
              each Services. Consistent with Trames&rsquo;s backup and storage procedures and due to the close integration of
              data with Our Solutions, Personal Information might be stored by Trames in backup logs and files for the
              duration necessary to comply with legal requirements, resolve disputes, enforce our agreements or for the
              purposes described in this policy. However, Trames makes no commitment to indefinitely store such data.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Changes to this Policy</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              From time to time Trames will need to make changes to this policy. Some of the changes will be in response to
              changes in applicable laws and regulations. As Trames adds new features and new services to Our Solutions, we
              will continue to handle Personal Information consistently with this policy, but some changes or clarifications
              may be required. If Trames seeks to make a material change to Trames&rsquo;s policy to allow use of Personal
              Information for a new, legitimate business purpose, Trames will document the change to this policy, note the
              date of the last update at the end of the policy. You are encouraged to check this policy occasionally to stay
              informed of any changes in our policies and procedures regarding Personal Information. For substantial and
              material changes to this policy, Trames will use reasonable efforts to provide notification to all affected
              users and suggest that such users review the updated policy.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Data Privacy Principles</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              Trames&rsquo; commitment is to ensure that our practices to collect and use Personal Information are fair and
              provide adequate privacy protection and safeguards. We take our own self-regulation very seriously and support
              your rights as a consumer to causes of action against disreputable and unprincipled data collectors and users.
              In the event of a data breach, we will do the following:
            </span>
          </p>
          <ul className='ul1'>
            <li className='li3'>
              <span className='s1'>Notify users by posting a notice on the Trames website within 5 business day(s).</span>
            </li>
          </ul>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Children&rsquo;s Online Privacy Protection</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>
              Our Services are not designed or intended for use by children under 18. If you are under 18, you should not use
              Our Solutions. Children under 18 may not submit any personally identifiable information to us, and if we
              discover that we have inadvertently gathered any such information from a child under 18, we will take
              appropriate steps to delete it. If you are the parent or guardian of a person under the age of 18 who has
              provided personally identifiable information to us, please inform us by contacting us{' '}
              <a href='mailto:enquiries@trames.sg'>
                <span className='s4'>enquiries@trames.sg</span>
              </a>{' '}
              and we will remove such information from our database. If you are concerned about your children&rsquo;s use of
              the Platform, you may use web filtering technology to supervise or limit access to Our Solutions.
            </span>
          </p>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s1'>
              We only collect your Personal Information for the purposes which we outlined in this Policy and we will do the
              following:
            </span>
          </p>
          <ul className='ul1'>
            <li className='li3'>
              <span className='s1'>NOT use false or misleading information in the subject line or body of our emails.</span>
            </li>
            <li className='li3'>
              <span className='s1'>
                Identify messages as advertisements by using the label &ldquo;advertisement&rdquo; in a conspicuous manner.
              </span>
            </li>
            <li className='li3'>
              <span className='s1'>Monitor third party email marketing services, if one is used.</span>
            </li>
            <li className='li3'>
              <span className='s1'>Provide accurate &ldquo;From&rdquo; fields so that users know who is sending the email.</span>
            </li>
            <li className='li3'>
              <span className='s1'>Provide visible and operable unsubscribe mechanisms in all of our emails.</span>
            </li>
            <li className='li3'>
              <span className='s1'>Include our physical address in the body of our emails.</span>
            </li>
            <li className='li3'>
              <span className='s1'>Honor any opt-out requests to our emails within 10 business days.</span>
            </li>
            <li className='li3'>
              <span className='s1'>Refrain from using harvested email addresses.</span>
            </li>
          </ul>
          <p className='p1'>&nbsp;</p>
          <p className='p3'>
            <span className='s2'>
              <strong>Questions</strong>
            </span>
          </p>
          <p className='p3'>
            <span className='s1'>If you have questions about this policy, please send an e-mail to:</span>
          </p>
          <p className='p4'>
            <span className='s2'>
              <a href='mailto:enquiries@trames.sg'>enquiries@trames.sg</a>
            </span>
          </p>

          <p className='p3'>
            <span className='s1'>Attn: Trames Data Privacy Team</span>
          </p>
          <br />
          <p className='p3'>
            <span className='s1'>
              If you have questions or concerns regarding the Personal Information Handling and Privacy section of this
              policy, you should first contact your company&rsquo;s administrator or the Trames Data Privacy Team listed
              above (
              <a href='mailto:enquiries@trames.sg'>
                <span className='s4'>enquiries@trames.sg</span>
              </a>
              ). If you do not receive acknowledgment of your inquiry or your inquiry has not been satisfactorily addressed,
              you should then raise your complaint to Trames&rsquo;s Data Privacy Team or your local data protection
              authority.
            </span>
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setTermsDialogOpen(false)} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default TermsDialog
