import { get } from 'lodash'

const filterArrayBasedOnProperty = (arr, restrictionValue, propertyName) => {
  let filtered = arr.filter(item => {
    return !item[propertyName] || item[propertyName].includes(restrictionValue)
  })
  let restrictedTree = filtered.map(item => {
    return {
      ...item,
      children: item.children.filter(child => !child[propertyName] || child[propertyName].includes(restrictionValue))
    }
  })
  return restrictedTree.filter(item => {
    return item.children && item.children.length > 0
  })
}
const filterNavigationForPlantType = (arr, plantType) => {
  return filterArrayBasedOnProperty(arr, plantType, 'plant_type_restriction')
}

const filterNavigationForUserRole = (arr, userRole) => {
  return filterArrayBasedOnProperty(arr, userRole, 'user_role_restriction')
}
export const getNavigationConfig = (plantType, userRole, shipmentCounts) => {
  let navigationConfig = [
    // SHIPPER NAVIGATION ITEMS
    {
      id: 'overview',
      title: '',
      type: 'group',
      children: [{
        id: 'overview',
        title: 'Overview',
        type: 'item',
        icon: 'assignment_turned_in',
        url: '/overview',
      }, {
        id: 'map-view',
        title: 'Map View',
        type: 'item',
        icon: 'public',
        url: '/map-view'
      }, {
        id: 'search',
        title: 'Tracker',
        type: 'item',
        icon: 'room',
        url: '/tracker'
      }, {
        id: 'notice-board',
        title: 'Notice Board',
        type: 'item',
        icon: 'forum',
        url: '/notice-board'
      }, {
        id: 'email-tracker',
        title: 'Email Tracker',
        type: 'item',
        icon: 'mail',
        url: '/email-tracker',
        plant_type_restriction: ['shipper']
      }, {
        id: 'erp-transmissions',
        title: 'ERP Transmissions',
        type: 'item',
        icon: 'view_list',
        url: '/erp-transmissions',
        plant_type_restriction: ['shipper']
      }, {
        id: 'tasks',
        title: 'Tasks',
        type: 'item',
        icon: 'playlist_add_check',
        url: '/tasks',
      }]
    },
    {
      id: 'plan',
      title: 'Plan',
      type: 'group',
      children: [{
        id: 'decoupled-inbound',
        title: 'Inbound',
        type: 'item',
        icon: 'event_note',
        url: '/decoupled-inbound',
        plant_type_restriction: ['consignee']
      }, {
        id: 'manage-booking',
        title: 'Manage Booking',
        type: 'item',
        icon: 'event_note',
        url: '/manage-booking',
        plant_type_restriction: ['shipper']
      },
      {
        id: 'manage-incoming',
        title: 'Manage Incoming',
        type: 'item',
        icon: 'event_note',
        url: '/manage-incoming',
        plant_type_restriction: ['consignee', 'ibl', 'ibc', 'ifp']
      },
      {
        id: 'inbound-schedule',
        title: 'Schedule',
        type: 'item',
        icon: 'calendar_today',
        url: '/inbound-schedule',
        plant_type_restriction: ['consignee', 'ibl', 'ibc', 'ifp']
      },
      {
        id: 'schedule',
        title: 'Schedule',
        type: 'item',
        icon: 'calendar_today',
        url: '/schedule',
        count: get(shipmentCounts, 'scheduled', 0),
        plant_type_restriction: ['shipper', 'obl', 'obc', 'ofp']
      }]
    },
    {
      id: 'transport',
      title: 'Transport',
      type: 'group',
      children: [{
        id: 'in-transit',
        title: 'In Transit',
        type: 'item',
        icon: 'directions_boat',
        url: '/in-transit',
        count: get(shipmentCounts, 'inTransit', 0),
        plant_type_restriction: ['shipper', 'obl', 'obc', 'ofp']
      },
      {
        id: 'inbound',
        title: 'Inbound',
        type: 'item',
        icon: 'play_for_work',
        url: '/inbound',
        count: get(shipmentCounts, 'import', 0),
        plant_type_restriction: ['shipper', 'obl', 'obc', 'ofp']
      },
      {
        id: 'delivering',
        title: 'Delivering',
        type: 'item',
        icon: 'directions_boat',
        url: '/delivering',
        plant_type_restriction: ['consignee', 'ibl', 'ibc', 'ifp']
      },
      {
        id: 'delivered',
        title: 'Delivered',
        type: 'item',
        icon: 'local_shipping',
        url: '/delivered',
        plant_type_restriction: ['consignee', 'ibl', 'ibc', 'ifp']
      }]
    },
    {
      id: 'plan-exceptions',
      title: 'Plan Exceptions',
      type: 'group',
      plant_type_restriction: ['shipper'],
      children: [{
        id: 'exceptions',
        title: 'Exceptions',
        type: 'item',
        icon: 'report_problem',
        url: '/exceptions',
        count: get(shipmentCounts, 'exceptions', 0)
      }]
    },
    {
      id: 'archive',
      title: 'Archive',
      type: 'group',
      children: [
        {
          id: 'completed',
          title: 'Completed',
          type: 'item',
          icon: 'check_circle',
          url: '/completed',
          count: get(shipmentCounts, 'completed', 0)
        },
        {
          id: 'cancelled',
          title: 'Cancelled',
          type: 'item',
          icon: 'cancelled',
          url: '/cancelled',
          count: get(shipmentCounts, 'cancelled', 0)
        },
        {
          id: 'closed',
          title: 'Closed',
          type: 'item',
          icon: 'not_interested',
          url: '/closed',
          plant_type_restriction: ['shipper']
        }
      ]
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'group',
      children: [
        {
          id: 'settings',
          title: 'Settings',
          type: 'item',
          icon: 'settings',
          url: '/settings'
        }
      ]
    }
  ]
  let filtered = filterNavigationForPlantType(navigationConfig, plantType)
  return filterNavigationForUserRole(filtered, userRole)
}
