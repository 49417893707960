import React, { useState, useEffect } from 'react'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody,
  LoadingButton
} from '../../core/components'
import {
  withStyles,
  Grid,
  Button,
  Input,
  Fade,
  Select,
  MenuItem
} from '@material-ui/core'
import { get } from 'lodash'

const countryPhoneCodes = require('../../core/helpers/json/countryPhoneCodes.json')

const ManageUserInfoDialog = props => {
  const {
    open,
    title,
    classes,
    existingEmail,
    existingPhone,
    updatedEmail,
    updatedPhone,
    infoType,
    onSubmit,
    handleClose,
    handleSetManageUserInfoDialog
  } = props
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState({})
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    setUpdating(false)

    if (updatedEmail) {
      setEmail(updatedEmail)
    } else if (existingEmail) {
      setEmail(existingEmail)
    } else {
      setEmail('')
    }

    if (updatedPhone) {
      setPhone(updatedPhone)
    } else if (existingPhone) {
      setPhone(existingPhone)
    } else {
      setPhone({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleEmailChange = (event) => {
    const { value } = event.target

    setEmail(value)
    handleSetManageUserInfoDialog({ updatedEmail: value })
  }

  const handlePhoneChange = (event) => {
    const { value, name } = event.target

    setPhone({
      ...phone,
      [name]: value
    })
    handleSetManageUserInfoDialog({
      updatedPhone: {
        ...phone,
        [name]: value
      }
    })
  }

  return (
    <FuseDialog open={open} wSM>
      <FuseDialogHeader text={title}
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container alignItems="center" className="p-20">
          {infoType === 'email' &&
            <Fade in={infoType === 'email'}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}
                    className="semi-bold text-14 pb-8">
                    Email</Grid>
                  <Grid item xs={12}>
                    <Input name="email"
                      className={classes.input}
                      value={email}
                      onChange={handleEmailChange}
                      fullWidth />
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          }
          {infoType === 'phone' &&
            <Fade in={infoType === 'phone'}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}
                    className="semi-bold text-14 pb-8">
                    Phone</Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="flex-end">
                      <Grid item xs={3}>
                        <Select name="countryCode"
                          value={get(phone, 'countryCode', '')}
                          onChange={handlePhoneChange}
                          placeholder="Select Code"
                          MenuProps={{
                            style: {
                              maxHeight: 250
                            }
                          }}
                          className={classes.select}
                          fullWidth>
                          {countryPhoneCodes.map((country, index) => (
                            <MenuItem key={index}
                              value={country.dial_code}>
                              {country.dial_code}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                      <Grid item xs={9}>
                        <Input name="phoneNumber"
                          className={classes.input}
                          value={get(phone, 'phoneNumber', '')}
                          onChange={handlePhoneChange}
                          fullWidth />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Fade>
          }
          <Grid item xs={12}>
            <Grid container
              spacing={2}
              className="pt-40">
              <Grid item xs={6}>
                <LoadingButton className="btn btn_sm btn_green"
                  loading={updating}
                  onClick={() => {
                    if (infoType === 'email') {
                      setUpdating(true)
                      onSubmit({ email }, () => {
                        setUpdating(false)
                        handleClose()
                      }, () => {
                        setUpdating(false)
                      })
                    }
                    else if (infoType === 'phone') {
                      setUpdating(true)
                      onSubmit({ phone }, () => {
                        setUpdating(false)
                        handleClose()
                      }, () => {
                        setUpdating(false)
                      })
                    }
                  }}
                  fullWidth>Confirm</LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <Button className="btn btn_sm btn_light_red"
                  onClick={handleClose}
                  disabled={updating}
                  fullWidth>Close</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

const styles = theme => ({
  input: {
    fontSize: 14,
    fontFamily: 'IBMPlexSans',
  },
  select: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14,
  },
  button: {
    color: '#fff',
    backgroundColor: '#cc1f1a'
  },
  icon: { fontSize: 75 }
})
export default withStyles(styles)(ManageUserInfoDialog)
