import React from 'react'
import {
  withStyles,
  withWidth,
  Button,
  Icon,
  CircularProgress,
  Grid
} from '@material-ui/core'
import classNames from 'classnames'
import { addGaEvent } from '../../core/helpers'

const ToolBar = ({ width, classes, requesting, isBookingDialogOpen, isBookingInfoDialogOpen, onCreateBookingInfo, ...other }) => {
  let sizeAdjust = 'text-12'

  if (width === 'xs' || width === 'sm' || width === 'md') {
    sizeAdjust = classes.smallSize
  }

  return (
    <Grid container
      justify="space-between"
      spacing={2}>
      <Grid item>
        <Button variant='outlined'
          className={classNames(
            classes.toolButtons,
            classes.btnPrimary,
            sizeAdjust
          )}
          onClick={() => {
            addGaEvent('shipment-creating')
            onCreateBookingInfo()
          }}
          disabled={other.outboundIds.length !== 0 ||
            requesting !== false ||
            isBookingInfoDialogOpen
          }>
          {requesting === 'create-booking' &&
            <React.Fragment>
              <Icon className='mr-8 text-16 text-white'>create</Icon>
              <label className="text-white">Create Booking</label>
              <CircularProgress size={24} className={classes.loadingIcon} />
            </React.Fragment>
          }
          {requesting !== 'create-booking' &&
            <React.Fragment>
              <Icon className='mr-8 text-16'>create</Icon>
              <label className="cursor-pointer">Create Booking</label>
            </React.Fragment>
          }
        </Button>
      </Grid>

      <Grid item>
        <Button variant='outlined'
          className={classNames(
            classes.toolButtons,
            classes.btnPrimary,
            sizeAdjust
          )}
          onClick={() => {
            addGaEvent('shipment-creating')
            other.onNewBookingClick()
          }}
          disabled={!other.outboundIds.length ||
            requesting !== false ||
            isBookingDialogOpen
          }>
          {requesting === 'new-booking' &&
            <React.Fragment>
              <Icon className='mr-8 text-16 text-white'>add</Icon>
              <label className="text-white">New Booking</label>
              <CircularProgress size={24} className={classes.loadingIcon} />
            </React.Fragment>
          }
          {requesting !== 'new-booking' &&
            <React.Fragment>
              <Icon className='mr-8 text-16'>add</Icon>
              <label className="cursor-pointer">New Booking</label>
            </React.Fragment>
          }
        </Button>
      </Grid>

      <Grid item>
        <Button variant='outlined'
          className={classNames(
            classes.toolButtons,
            classes.btnPrimary,
            sizeAdjust
          )}
          onClick={() => {
            addGaEvent('shipment-addTo')
            other.onAddToExistingClick()
          }}
          disabled={!other.outboundIds.length ||
            !other.shipments.length ||
            requesting !== false ||
            isBookingDialogOpen
          }>
          {requesting === 'add-to' &&
            <React.Fragment>
              <Icon className='mr-8 text-16 text-white'>playlist_add</Icon>
              <label className="text-white">Add To</label>
              <CircularProgress size={24} className={classes.loadingIcon} />
            </React.Fragment>
          }
          {requesting !== 'add-to' &&
            <React.Fragment>
              <Icon className='mr-8 text-16'>playlist_add</Icon>
              <label className="cursor-pointer">Add To</label>
            </React.Fragment>
          }
        </Button>
      </Grid>

      <Grid item>
        <Button variant='outlined'
          icon='close'
          className={classNames(
            classes.toolButtons,
            classes.btnDanger,
            sizeAdjust
          )}
          onClick={() => {
            addGaEvent('shipment-removeFrom')
            other.removeShipmentOutbounds()
          }}
          disabled={!other.shipmentOutboundIds.length ||
            requesting !== false ||
            isBookingDialogOpen
          }>
          {requesting === 'remove-from' &&
            <React.Fragment>
              <Icon className='mr-8 text-16 text-white'>close</Icon>
              <label className="text-white">Remove From</label>
              <CircularProgress size={24} className={classes.loadingIcon} />
            </React.Fragment>
          }
          {requesting !== 'remove-from' &&
            <React.Fragment>
              <Icon className='mr-8 text-16'>close</Icon>
              <label className="cursor-pointer">Remove From</label>
            </React.Fragment>
          }
        </Button>
      </Grid>

      <Grid item>
        <Button variant='outlined'
          className={classNames(
            classes.toolButtons,
            classes.btnDanger,
            sizeAdjust
          )}
          onClick={other.closeOutbounds}
          disabled={!other.outboundIds.length ||
            requesting !== false ||
            isBookingDialogOpen
          }>
          {requesting === 'close' &&
            <React.Fragment>
              <Icon className='mr-8 text-16 text-white'>block</Icon>
              <label className="text-white">Close</label>
              <CircularProgress size={24} className={classes.loadingIcon} />
            </React.Fragment>
          }
          {requesting !== 'close' &&
            <React.Fragment>
              <Icon className='mr-8 text-16'>block</Icon>
              <label className="cursor-pointer">Close</label>
            </React.Fragment>
          }
        </Button>
      </Grid>
    </Grid>
  )
}

const styles = () => ({
  bordered: {
    border: '1px solid #e3e3e4',
    borderRadius: 4
  },
  smallSize: {
    marginRight: `0.5rem`,
    fontSize: `0.9rem`
  },
  toolButtons: {
    position: 'relative',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF !important',
    borderRadius: '16px',
    height: '32px',
    fontFamily: 'IBMPlexSans-Medium',
    fontSize: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: '4px 14px',

    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: '#FFFFFF !important',
      borderColor: '#E1E5ED !important',
      color: '#9FA0A4 !important',
    }
  },
  btnPrimary: {
    border: '1px solid #3dcd58',
    color: '#3dcd58',
  },
  btnDanger: {
    border: '1px dashed #B10043',
    color: '#B10043',
  },
  loadingIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

export default withWidth()(withStyles(styles)(ToolBar))
