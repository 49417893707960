import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { FuseAnimate, FuseIconButton } from 'core/components'

const WelcomeHeader = ({ username, onDropHubClick, hasConfigure, hasDropHub, onConfigureClick, logout, onAnalytics }) => (
  <Grid item xs={12}>
    <div className='p-36 pt-56 pb-20 px platforms'>
      <FuseAnimate animation='transition.slideDownIn'>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant='h4' className='text-white'>
              Welcome, <span className='ml-2 bold capitalize'>{username}!</span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <FuseIconButton
                  variant='marked'
                  img='assets/images/icons/drophub.svg'
                  className='text-uppercase text-12'
                  style={{ background: '#FFC107' }}
                  onClick={onAnalytics}
                >Analytics</FuseIconButton>
              </Grid>
              {hasDropHub &&
                <Grid item>
                  <FuseIconButton
                    variant='marked'
                    img='assets/images/icons/drophub.svg'
                    className='text-uppercase text-12'
                    style={{ background: '#E47F00' }}
                    onClick={onDropHubClick}
                  >DROP HUB</FuseIconButton>
                </Grid>
              }
              {hasConfigure &&
                <Grid item>
                  <FuseIconButton
                    variant='marked'
                    img='assets/images/icons/network.svg'
                    className='text-uppercase text-12'
                    style={{ background: '#3DCD58' }}
                    onClick={onConfigureClick}
                  >CONFIGURE NETWORK</FuseIconButton>
                </Grid>
              }
              <Grid item>
                <FuseIconButton
                  variant='marked'
                  img='assets/images/icons/user-menu/logout-white.svg'
                  className='text-uppercase text-12'
                  style={{ background: '#B10043' }}
                  onClick={logout}
                >LOG OUT</FuseIconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseAnimate>
    </div>
  </Grid>
)

export default WelcomeHeader
