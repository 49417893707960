import { get, set, uniqBy } from 'lodash'
import ActionTypes from './types'

const initialState = {
  incomplete: {
    loading: true,
    loadingMore: false,
    hasMore: false,
    total: 0,
    tasks: []
  },
  blocked: {
    loading: true,
    loadingMore: false,
    hasMore: false,
    total: 0,
    tasks: []
  },
  completed: {
    loading: true,
    loadingMore: false,
    hasMore: false,
    total: 0,
    tasks: []
  },
  templates: {
    loading: true,
    hasMore: false,
    total: 0,
    tasks: []
  },
  shipmentTasks: [],
  shipments: [],
  controllerDictionary: [],
  events: [],
  users: [],
  eventDocs: {},
  searchedShipments: {
    loading: true,
    total: 0,
    data: []
  }
}

const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_INCOMPLETE_TASKS: {
      return {
        ...state,
        incomplete: {
          loading: get(action, 'loading', false),
          loadingMore: false,
          hasMore: get(action, 'payload.showMore', false),
          total: get(action, 'payload.total', 0),
          tasks: get(action, 'payload.data', []),
        }
      }
    }
    case ActionTypes.LOAD_BLOCKED_TASKS: {
      return {
        ...state,
        blocked: {
          loading: get(action, 'loading', false),
          loadingMore: false,
          hasMore: get(action, 'payload.showMore', false),
          total: get(action, 'payload.total', 0),
          tasks: get(action, 'payload.data', []),
        }
      }
    }
    case ActionTypes.LOAD_COMPLETED_TASKS: {
      return {
        ...state,
        completed: {
          loading: get(action, 'loading', false),
          loadingMore: false,
          hasMore: get(action, 'payload.showMore', false),
          total: get(action, 'payload.total', 0),
          tasks: get(action, 'payload.data', []),
        }
      }
    }
    case ActionTypes.LOADING_MORE_COMPLETED_TASKS: {
      return {
        ...state,
        completed: {
          ...state.completed,
          loadingMore: true,
        }
      }
    }
    case ActionTypes.LOADING_MORE_INCOMPLETE_TASKS: {
      return {
        ...state,
        incomplete: {
          ...state.incomplete,
          loadingMore: true,
        }
      }
    }
    case ActionTypes.LOADING_MORE_BLOCKED_TASKS: {
      return {
        ...state,
        blocked: {
          ...state.blocked,
          loadingMore: true,
        }
      }
    }
    case ActionTypes.LOAD_MORE_COMPLETED_TASKS: {
      return {
        ...state,
        completed: {
          loading: get(action, 'loading', false),
          loadingMore: false,
          hasMore: get(action, 'payload.showMore', false),
          total: get(action, 'payload.total', 0),
          tasks: uniqBy([...state.completed.tasks, ...get(action, 'payload.data', [])], '_id')
        }
      }
    }
    case ActionTypes.LOAD_MORE_INCOMPLETE_TASKS: {
      return {
        ...state,
        incomplete: {
          loading: get(action, 'loading', false),
          loadingMore: false,
          hasMore: get(action, 'payload.showMore', false),
          total: get(action, 'payload.total', 0),
          tasks: uniqBy([...state.incomplete.tasks, ...get(action, 'payload.data', [])], '_id')
        }
      }
    }
    case ActionTypes.LOAD_MORE_BLOCKED_TASKS: {
      return {
        ...state,
        blocked: {
          loading: get(action, 'loading', false),
          loadingMore: false,
          hasMore: get(action, 'payload.showMore', false),
          total: get(action, 'payload.total', 0),
          tasks: uniqBy([...state.blocked.tasks, ...get(action, 'payload.data', [])], '_id')
        }
      }
    }
    case ActionTypes.LOAD_TEMPLATE_TASKS: {
      return {
        ...state,
        templates: {
          loading: get(action, 'loading', false),
          hasMore: get(action, 'payload.showMore', false),
          total: get(action, 'payload.total', 0),
          tasks: get(action, 'payload.data', []),
        }
      }
    }
    case ActionTypes.LOAD_MORE_TEMPLATE_TASKS: {
      return {
        ...state,
        templates: {
          loading: get(action, 'loading', false),
          hasMore: get(action, 'payload.showMore', false),
          total: get(action, 'payload.total', 0),
          tasks: uniqBy([...state.templates.tasks, ...get(action, 'payload.data', [])], '_id')
        }
      }
    }
    case ActionTypes.LOAD_SHIPMENTS_TASKS: {
      return {
        ...state,
        shipmentTasks: get(action, 'payload', [])
      }
    }
    case ActionTypes.LOAD_ALL_SHIPMENTS: {
      return {
        ...state,
        shipments: get(action, 'payload', [])
      }
    }
    case ActionTypes.LOAD_SHIPMENT_DETAILS: {
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment._id === get(action, 'payload.shipmentId')) {
            shipment = {
              ...shipment,
              ...get(action, 'payload.data', {})
            }
          }
          return shipment
        })
      }
    }
    case ActionTypes.LOAD_SHIPMENT_META: {
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment._id === get(action, 'payload.shipmentId')) {
            shipment.rbac = get(action, 'payload.meta')
          }
          return shipment
        })
      }
    }
    case ActionTypes.LOAD_SHIPMENT_EVENT_WRITE_LIST: {
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment._id === get(action, 'payload.shipmentId')) {
            set(shipment, 'eventWriteList', get(action, 'payload.events', []))
          }
          return shipment
        })
      }
    }
    case ActionTypes.LOAD_EXPAND_SHIPMENT_TASKS: {
      return {
        ...state,
        incomplete: {
          ...state.incomplete,
          tasks: state.incomplete.tasks.map(incomplete => {
            if (incomplete.shipmentId === get(action, 'payload.shipmentId')) {
              set(incomplete, 'allTasks', get(action, 'payload.shipmentTasks', []))
            }
            return incomplete
          })
        }
      }
    }
    case ActionTypes.LOAD_CONTROLLER_DICTIONARY: {
      return {
        ...state,
        controllerDictionary: get(action, 'payload', [])
      }
    }
    case ActionTypes.LOAD_ALL_EVENTS: {
      return {
        ...state,
        events: get(action, 'payload', [])
      }
    }
    case ActionTypes.LOAD_ALL_USERS: {
      return {
        ...state,
        users: get(action, 'payload', [])
      }
    }
    case ActionTypes.LOAD_EVENT_DOC_LIST: {
      return {
        ...state,
        eventDocs: get(action, 'payload', {})
      }
    }
    case ActionTypes.LOADING_FILTER_SEARCH_SHIPMENTS: {
      return {
        ...state,
        searchedShipments: {
          loading: true,
          total: 0,
          data: []
        }
      }
    }
    case ActionTypes.LOAD_FILTER_SEARCH_SHIPMENTS: {
      return {
        ...state,
        searchedShipments: {
          loading: false,
          total: get(action, 'total', 0),
          data: get(action, 'payload', [])
        }
      }
    }
    case ActionTypes.LOAD_SHIPMENT_POSTS: {
      return {
        ...state,
        shipments: state.shipments.map(sItem => {
          if (sItem._id === get(action, 'shipmentId')) {
            set(sItem, 'posts', get(action, 'payload', []))
          }
          return sItem
        })
      }
    }
    default:
      return state
  }
}

export default tasksReducer
