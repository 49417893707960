import React from 'react'
import {
  Grid,
  Button,
  Icon
} from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody,
  LoadingButton
} from '../../core/components'

const CloseOutboundConfirm = ({ open, deleteOutbounds, handleClose, handleCloseCancel, isRequesting }) => (
  <FuseDialog open={open} wSM>
    <FuseDialogHeader text="Close Outbounds"
      handleClose={handleCloseCancel} />
    <FuseDialogBody>
      <Grid container alignItems="center" className="p-20">
        <Grid item xs={12}>
          <Grid container justify="center">
            <Icon className="text-52 mb-12 fg-red">warning</Icon>
          </Grid>
        </Grid>
        <Grid item xs={12} className="text-center">
          <Grid container spacing={2}>
            <Grid item xs={12} className="regular text-center">
              Are you sure you want to close <strong>{deleteOutbounds.length} outbounds</strong></Grid>
            <Grid item xs={12} className="regular italic fg-gray">
              Note: Once you close all will be moved to <strong>Closed Page</strong></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container
            spacing={2}
            className="pt-40">
            <Grid item xs={6}>
              <LoadingButton className="btn btn_sm btn_light_red"
                loading={isRequesting}
                onClick={() => handleClose(deleteOutbounds)}
                fullWidth>Yes, Close</LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button className="btn btn_sm btn_light_green"
                disabled={isRequesting}
                onClick={handleCloseCancel}
                fullWidth>Cancel</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FuseDialogBody>
  </FuseDialog>
)

export default CloseOutboundConfirm
