import React, { useEffect } from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import { get, set } from 'lodash'
import { FuseSideBar, FuseSideBarHeader, FuseSideBarBody, FuseSideBarActions } from '../../core/components'
import { useMergeState } from 'core/helpers'

const INITIAL_STATE = {
  containers: []
}

const ContainerEditSidebar = ({ open, closeSideBar, availableContainers, onSubmit, currentPlant }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)

  useEffect(() => {
    setState({
      containers: availableContainers || []
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, availableContainers])

  const handleContainerUpdate = (name, value, containerIndex) => {
    var updatedContainers = get(state, 'containers', [])
    set(updatedContainers[containerIndex], name, value.toUpperCase())
    setState({
      containers: updatedContainers
    })
  }

  return (
    <FuseSideBar open={open}>
      <FuseSideBarHeader text='Edit Containers' handleClose={closeSideBar} />
      <FuseSideBarBody>
        <Grid container>
          {get(state, 'containers', []).map((container, index) => {
            if (get(container, 'status.current.value') === 'cancelled') {
              return <React.Fragment key={index} />
            } else {
              return (
                <Grid item xs={12} key={index}>
                  <Grid container className='mb-32'>
                    <Grid item xs={12} className='semi-bold text-16 pb-8'>
                      {`${get(container, 'type')} (${(index+1)})`}
                    </Grid>
                    <Grid item xs={12}>
                      <label className='text-12 fg-gray regular'>Container no</label>
                      <TextField name={'container_' + index}
                        placeholder="Enter container number"
                        value={get(container, 'equipmentNo')}
                        onChange={e => {
                          handleContainerUpdate('equipmentNo', e.target.value, index)
                        }}
                        inputProps={{
                          style: {
                            fontSize: '14px'
                          }
                        }}
                        fullWidth />
                    </Grid>
                    <Grid item xs={12} className="pt-12">
                      <label className='text-12 fg-gray regular'>Supplier Seal no</label>
                      <TextField name={'container_' + index}
                        placeholder="Enter supplier seal number"
                        value={get(container, 'supplierSealNumber')}
                        onChange={e => {
                          handleContainerUpdate('supplierSealNumber', e.target.value, index)
                        }}
                        inputProps={{
                          style: {
                            fontSize: '14px'
                          }
                        }}
                        fullWidth />
                    </Grid>
                    <Grid item xs={12} className="pt-12">
                      <label className='text-12 fg-gray regular'>Carrier Seal no</label>
                      <TextField name={'container_' + index}
                        placeholder="Enter carrier seal number"
                        value={get(container, 'carrierSealNumber')}
                        onChange={e => {
                          handleContainerUpdate('carrierSealNumber', e.target.value, index)
                        }}
                        inputProps={{
                          style: {
                            fontSize: '14px'
                          }
                        }}
                        fullWidth />
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          })}
        </Grid>
      </FuseSideBarBody>
      {(['ofp', 'obl', 'shipper'].includes(get(currentPlant, 'type'))) && (
        <FuseSideBarActions>
          <Button className='btn btn_full-space'
            onClick={() => onSubmit(state)}>SAVE</Button>
        </FuseSideBarActions>
      )}
    </FuseSideBar>
  )
}

export default ContainerEditSidebar
