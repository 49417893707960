import React from 'react'
import { Grid } from '@material-ui/core'

const SideBarActions = ({ children }) => (
  <Grid container direction='row' className='side-bar-actions' alignItems='flex-end'>
    {children}
  </Grid>
)

export default SideBarActions
