import React from 'react'
import { Grid } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody
} from '../../core/components'
import { groupBy, orderBy, get } from 'lodash'
import classNames from 'classnames'

const eventColors = {
  processed: 'fg-orange',
  delivered: 'fg-green',
  open: 'fg-blue',
}

const EmailReport = ({ open, handleClose, emailReports }) => {
  const groupedReports = groupBy(emailReports, 'email')

  return (
    <FuseDialog open={open} wSM onBackDropClick={handleClose}>
      <FuseDialogHeader text="Email Reports" handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container alignItems="center" className="p-20" spacing={2}>
          {Object.keys(groupedReports).map((key, keyIndex) => {
            const orderedReports = orderBy(get(groupedReports, key, []), 'timestamp', 'desc')
            const latestEvent = get(orderedReports, '[0].event')

            return (
              <Grid item xs={12} key={keyIndex}
                className={keyIndex > 0 ? 'border-t-1 border-gray' : ''}>
                <Grid container justify="space-between"
                  alignItems="center"
                  spacing={1}>
                  <Grid item>{key}</Grid>
                  <Grid item className={classNames(
                    get(eventColors, latestEvent, 'fg-gray'),
                    'uppercase medium'
                  )}>{latestEvent}</Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default EmailReport
