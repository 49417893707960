import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { get } from "lodash"
import queryString from "query-string"

const SSORedirect = props => {
  const { location: { hash, search }, match } = props
  const parsedHash = queryString.parse(hash);
  const parsedQueryString = queryString.parse(search);

  const accessToken = get(parsedHash, 'access_token')

  let authCode = get(parsedHash, 'code')
  if (!authCode) {
    authCode = get(parsedQueryString, 'code');
  }

  const identifier = get(match, 'params.identifier', 'default');
  let redirectURL = "";
  if (accessToken) {
    redirectURL = `/api/v1/auth/${identifier}/redirect/access_token/${accessToken}`;
  } else if (authCode) {
    redirectURL = `/api/v1/auth/${identifier}/redirect/code/${authCode}`;
  } else {
    return <Redirect to='404' />
  }
  window.location.href = redirectURL;
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(SSORedirect)
