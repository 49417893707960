import React, { useEffect } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { FuseFileUploader } from '../../../core/components'
import { GooglePlaceSearch } from '../../../core/components/Inputs'
import { reviewBookingForm, bookingInfoForm } from 'core/localization'
import { useMergeState } from '../../../core/helpers'
import _ from 'lodash'
import DateTime from 'react-datetime'

const ReviewBookingForm = ({ onChange, onUpload, existingData, shipment, editShipping, documents }) => {
  const [state, setState] = useMergeState(existingData ? existingData : {})
  const { transportMethod } = shipment

  useEffect(() => {
    let updatedState = {
      ...state,
      packageSubmissionSchedule: {
        packageSubmissionAddress: _.get(
          state,
          'packageSubmissionSchedule.packageSubmissionAddress',
          _.get(shipment, 'referenceEvents.cargoDropOffAtCFS.location', '')
        ),
        additionalAddress: _.get(
          state,
          'packageSubmissionSchedule.additionalAddress',
          _.get(shipment, 'referenceEvents.cargoDropOffAtCFS.location.floorUnitNumber', '')
        ),
        dateTime: _.get(
          state,
          'packageSubmissionSchedule.dateTime',
          _.get(shipment, 'referenceEvents.cargoDropOffAtCFS.expectedAt', '')
        )
      },
      bookingDetails: {
        carrierBookingNumber: _.get(
          state,
          'bookingDetails.carrierBookingNumber',
          _.get(shipment, 'referenceNos.carrierBookingNo', '')
        ),
        bookingRefNumber: _.get(
          state,
          'bookingDetails.bookingRefNumber',
          _.get(shipment, 'referenceNos.bookingReferenceNo', '')
        ),
        hblNumber: _.get(state, 'bookingDetails.hblNumber', _.get(shipment, 'referenceNos.HBLNo', []).join(',')),
        mblNumber: _.get(state, 'bookingDetails.mblNumber', _.get(shipment, 'referenceNos.MBLNo', []).join(','))
      },
      siCutOffDate: _.get(state, 'siCutOffDate', _.get(shipment, 'documentDeadlineDate', ''))
    }
    if (editShipping) {
      if (shipment.history && shipment.history.length > 0) {
        let arr = shipment.history.filter(el => el.value === 'freight-partner-confirmed')
        if (arr.length > 0) {
          updatedState = { ...updatedState, note: arr[arr.length - 1].reason }
        }
      }
      if (documents && documents.length > 0) {
        let bookingConfirmation = documents.find(doc => doc.type === 'BookingConfirmation')
        if (bookingConfirmation && bookingConfirmation.documents.length > 0) {
          updatedState = { ...updatedState, bookingConfirmationDocs: bookingConfirmation.documents }
        }
        let shippingConfirmation = documents.find(doc => doc.type === 'ShippingNote')
        if (shippingConfirmation && shippingConfirmation.documents.length > 0) {
          updatedState = { ...updatedState, shippingConfirmationDocs: shippingConfirmation.documents }
        }
      }
    }
    setState(updatedState)
    onChange(updatedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  const handleChange = event => {
    let { value } = event.target
    let { name } = event.target
    name = name.split('.')
    // removing space and special char for carrier booking number
    if (['carrierBookingNumber', 'bookingRefNumber'].includes(name[1])) {
      value = value.replace(/[\W_]/g, '')
    }
    if (['hblNumber', 'mblNumber'].includes(name[1])) {
      value = value.replace(/ /g, '')
    }
    let updatedState

    if (name.length > 1) {
      updatedState = {
        ...state,
        [name[0]]: {
          ...state[name[0]],
          [name[1]]: value
        }
      }
    } else {
      updatedState = {
        ...state,
        [name[0]]: value
      }
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleDateChange = (date, name, section = null) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    var updatedState
    if (section) {
      updatedState = {
        ...state,
        [section]: {
          ...state[section],
          [name]: new Date(date)
        }
      }
    } else {
      updatedState = {
        ...state,
        [name]: new Date(date)
      }
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const updateAddress = (address, lat, lng) => {
    var updatedState
    if (_.get(shipment, 'type') === 'FCL') {
      updatedState = {
        ...state,
        emptyContainerPickupSchedule: {
          ...state.emptyContainerPickupSchedule,
          containerYardAddress: {
            ...address,
            latitude: lat.toString(),
            longitude: lng.toString()
          }
        }
      }
    } else {
      updatedState = {
        ...state,
        packageSubmissionSchedule: {
          ...state.packageSubmissionSchedule,
          packageSubmissionAddress: {
            ...address,
            latitude: lat.toString(),
            longitude: lng.toString()
          }
        }
      }
      if (_.get(address, 'floorUnitNumber')) {
        _.set(
          updatedState,
          `packageSubmissionSchedule.additionalAddress`,
          _.get(address, 'floorUnitNumber')
        )
      }
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleUploadChange = (data, name) => {
    const updatedState = {
      ...state,
      [`doc${name}`]: data
    }
    setState(updatedState)

    onUpload(data, name)
  }

  let uploadedShippingDocs = _.get(state, 'shippingConfirmationDocs', [])
  if (uploadedShippingDocs.length > 0) {
    uploadedShippingDocs = [uploadedShippingDocs[uploadedShippingDocs.length - 1]]
  } else {
    uploadedShippingDocs = []
  }
  let uploadedBookingDocs = _.get(state, 'bookingConfirmationDocs', {})
  if (uploadedShippingDocs.length > 0) {
    uploadedBookingDocs = [uploadedBookingDocs[uploadedShippingDocs.length - 1]]
  } else {
    uploadedBookingDocs = []
  }
  return (
    <>
      <Grid container direction='row' className='field-control'>
        {(_.get(shipment, 'type') === 'LCL' && transportMethod !== 'air') &&
          <Grid item xs={12}>
            <Grid container>
              <Grid item sm={12} md={6} className='flex items-center'>
                <label className={'field-label'}>Package submission schedule</label>
              </Grid>
              <Grid item sm={12} md={6} className='flex items-center justify-end'>
                <label className={'field-instruction'}>{reviewBookingForm.ecpSchedule.instruction}</label>
              </Grid>

              <Grid container direction='row' className='mt-16 mb-24'>
                <Grid item sm={12} className='pb-16'>
                  <label className={'formsy-form-control__label require-field'}>
                    CFS address for package submission</label>
                  <GooglePlaceSearch
                    placeholder='Select street address...'
                    className='theme-text-field'
                    value={_.get(state, 'packageSubmissionSchedule.packageSubmissionAddress.street', '')}
                    updateCall={updateAddress}
                    savedPlaceType="cfs"
                  />
                </Grid>
                <Grid item sm={12} md={7} className='pr-8'>
                  <TextField
                    name='packageSubmissionSchedule.additionalAddress'
                    className='theme-text-field'
                    value={_.get(state, 'packageSubmissionSchedule.additionalAddress', '')}
                    label={bookingInfoForm.schedules.field.additionalAddress}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item sm={12} md={5} className='pl-6'>
                  <Grid container>
                    <Grid item xs={12}>
                      <label className='formsy-form-control__label require-field'>
                        {bookingInfoForm.schedules.field.dateTime}
                      </label>
                    </Grid>
                    <Grid item xs={12} className='relative'>
                      <DateTime
                        className='theme-date-time-picker-field'
                        input
                        dateFormat='DD MMM YYYY,'
                        onChange={date => {
                          handleDateChange(date, 'dateTime', 'packageSubmissionSchedule')
                        }}
                        value={
                          _.get(state, 'packageSubmissionSchedule.dateTime') &&
                          new Date(state.packageSubmissionSchedule.dateTime)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid item sm={12} md={6} className='flex items-center'>
          <label className={'field-label'}>{reviewBookingForm.bookingDetails.label}</label>
        </Grid>
        <Grid item sm={12} md={6} className='flex items-center justify-end'>
          <label className={'field-instruction'}>{reviewBookingForm.bookingDetails.instruction}</label>
        </Grid>

        <Grid container direction='row'
          className='mt-16 mb-24'
          spacing={2}>
          <Grid item sm={12} md={6} className='pb-16'>
            <TextField
              name='bookingDetails.carrierBookingNumber'
              className={'theme-text-field ' + (_.get(shipment, 'transportMethod') === 'air' ? '' : 'required')}
              value={_.get(state, 'bookingDetails.carrierBookingNumber', '')}
              label={reviewBookingForm.field.carrierBookingNumber}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={12} md={6} className='pb-16'>
            <TextField
              name='bookingDetails.bookingRefNumber'
              className={'theme-text-field ' + (_.get(shipment, 'transportMethod') === 'air' ? '' : 'required')}
              value={_.get(state, 'bookingDetails.bookingRefNumber', '')}
              label={reviewBookingForm.field.bookingRefNumber}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
            />
          </Grid>
          <Grid item sm={12} md={12} className='pb-16'>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  name='bookingDetails.mblNumber'
                  className='theme-text-field w-full'
                  label={transportMethod === 'air' ? 'MAWB' : reviewBookingForm.field.mblNumber}
                  value={_.get(state, 'bookingDetails.mblNumber', '')}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <label className='medium fg-gray text-10 italic'>
                  {_.get(state, 'bookingDetails.mblNumber', '')
                    .split(',')
                    .filter(Boolean).length + `${transportMethod === 'air' ? ' MAWB(s) have been added' : ' MBL(s) have been added'}`}
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={12} className='pb-16'>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  name='bookingDetails.hblNumber'
                  className='theme-text-field w-full'
                  label={transportMethod === 'air' ? 'HAWB' : reviewBookingForm.field.hblNumber}
                  value={_.get(state, 'bookingDetails.hblNumber', '')}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <label className='medium fg-gray text-10 italic'>
                  {_.get(state, 'bookingDetails.hblNumber', '')
                    .split(',')
                    .filter(Boolean).length + `${transportMethod === 'air' ? ' HAWB(s) have been added' : ' HBL(s) have been added'}`}
                </label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} md={6} className='flex items-center'>
          <label className={'field-label'}>{reviewBookingForm.siCutOffDate.label}</label>
        </Grid>
        <Grid item sm={12} md={6} className='flex items-center justify-end'>
          <label className={'field-instruction'}>{reviewBookingForm.siCutOffDate.instruction}</label>
        </Grid>

        <Grid container direction='row' className='mt-16 mb-24'>
          <Grid item sm={12} md={5} className='pr-8'>
            <Grid container>
              <Grid item xs={12}>
                <label className='formsy-form-control__label require-field'>
                  Date & time</label>
              </Grid>
              <Grid item xs={12} className='relative'>
                <DateTime
                  className='theme-date-time-picker-field'
                  input
                  dateFormat='DD MMM YYYY,'
                  onChange={date => handleDateChange(date, 'siCutOffDate')}
                  value={_.get(state, 'siCutOffDate') && new Date(state.siCutOffDate)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} md={6} className='flex items-center'>
          <label className={'field-label require-field'}>
            {reviewBookingForm.uploadDocument.label}
          </label>
        </Grid>
        <Grid item sm={12} md={6} className='flex items-center justify-end'>
          <label className={'field-instruction'}>{reviewBookingForm.uploadDocument.instruction}</label>
        </Grid>

        <Grid container direction='row' className='mt-16 mb-24'>
          <Grid item sm={12} md={12} className='mb-20'>
            <FuseFileUploader
              fileTitle={reviewBookingForm.uploadDocument.bookingConfirmation}
              type='BookingConfirmation'
              name='BookingConfirmation'
              image='assets/images/images/mltrc.png'
              existingDocument={_.get(state, 'docBookingConfirmation', {})}
              uploadedFiles={uploadedBookingDocs}
              required={true}
              onSuccess={data => {
                handleUploadChange(data, 'BookingConfirmation')
              }}
              allowedTypes={".pdf,.xls,.xlsx,.xlsm,.csv,.txt,.zip,.doc,.docx,.jpg,.jpeg,.png,.gif"}
              maxFilesize={5}
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <FuseFileUploader
              fileTitle={reviewBookingForm.uploadDocument.shippingNote}
              type='ShippingNote'
              name='ShippingNote'
              image='assets/images/images/mltrc.png'
              existingDocument={_.get(state, 'docShippingNote', {})}
              uploadedFiles={uploadedShippingDocs}
              required={_.get(shipment, 'type') === 'LCL' && _.get(shipment, 'transportMethod') !== 'air' ? true : false}
              onSuccess={data => {
                handleUploadChange(data, 'ShippingNote')
              }}
              allowedTypes={".pdf,.xls,.xlsx,.xlsm,.csv,.txt,.zip,.doc,.docx,.jpg,.jpeg,.png,.gif"}
              maxFilesize={5}
            />
          </Grid>
        </Grid>

        <Grid container direction='row' className='mt-16 mb-24'>
          <Grid item xs={12}>
            <label className={'field-label'}>Add note</label>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name='note'
              placeholder='You can add any extra note for this booking'
              className='theme-text-field w-full'
              value={_.get(state, 'note', '')}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewBookingForm
