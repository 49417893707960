import React, { useState, useEffect } from 'react'
import { Grid, Icon } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  FuseCheckBox,
  LoadingButton,
  FuseFileUploader
} from '../../core/components'
import ReviewBookingSummary from './ReviewBookingSummary'
import { get } from 'lodash'
import classNames from 'classnames'
import EventForm from './ready-to-pickup-components/EventForm'


const UpdateShipmentStatus = props => {
  const { open, shipment, requesting, handleClose, handleSubmit } = props
  const [flag, setFlag] = useState(undefined)
  const [uploadDocuments, setUploadDocuments] = useState({})
  const [uploading, setUploading] = useState(false)
  const [review, setReview] = useState(true)
  const [referenceEvents, setReferenceEvents] = useState({})
  const [containers, setContainers] = useState([])
  const [isInvalid, setInvalid] = useState(true)

  const flagData = [{
    color: 'red',
    title: 'Red flag',
    description: 'Mark the shipment as red if there are any payment dues from consignee side to clear the customs. But you have to again it as green once it cleared customs.'
  }, {
    color: 'yellow',
    title: 'Yellow flag',
    description: 'Mark the shipment as yellow if there are any documents missing from consignee side to clear the customs. But you have to again it as green once it cleared customs.'
  }, {
    color: 'green',
    title: 'Green flag',
    description: 'Mark the shipment flag as green if its custom cleared and ready to pickup'
  }]

  const onSubmit = () => {
    if (flag === 'green') {
      if (get(shipment, 'type') === 'FCL') {
        handleSubmit(shipment, { flag, review, containers })
      } else if (get(shipment, 'type') === 'LCL') {
        handleSubmit(shipment, { flag, review, referenceEvents })
      }
    } else {
      handleSubmit(shipment, { flag, review })
    }
  }

  const checkValidation = () => {
    let validated = true
    if (flag === 'green') {
      if (get(shipment, 'type') === 'FCL') {
        containers.forEach(containers => {
          if (!get(containers, 'referenceEvents.containerReadyToPickup.location.street')) {
            validated = false
          }
          if (!get(containers, 'referenceEvents.containerReadyToPickup.expectedAt')) {
            validated = false
          }
        })
      } else {
        if (!get(referenceEvents, 'containerReadyToPickup.location.street')) {
          validated = false
        }
        if (!get(referenceEvents, 'containerReadyToPickup.expectedAt')) {
          validated = false
        }
      }
    }
    if (validated) {
      setInvalid(false)
    } else {
      setInvalid(true)
    }
  }

  const handleUpdateDocument = type => documents => {
    setUploadDocuments({
      ...uploadDocuments,
      [type]: documents
    })
  }

  const uploadFiles = async () => {
    const { uploadDocument } = props
    const docTypes = Object.keys(uploadDocuments)

    if (docTypes.length) {
      setUploading(true)
      await docTypes.forEach(async docType => {
        await Object.keys(uploadDocuments[docType]).forEach(async fileName => {
          const file = new FormData()
          file.append('document', uploadDocuments[docType][fileName])

          const data = {
            file,
            params: [shipment._id, docType]
          }
          await uploadDocument('shipment', data)
        })
      })
      setUploading(false)
    }
    onSubmit()
  }

  useEffect(() => {
    setFlag(get(shipment, 'customClearance.flag.status'))
    setReview(get(shipment, 'customClearance.reviewStatue') === 'not-needed' ? false : true)
    if (get(shipment, 'type') === 'FCL') {
      setContainers(get(shipment, 'containers', []))
    } else if (get(shipment, 'type') === 'LCL') {
      setReferenceEvents(get(shipment, 'referenceEvents.containerReadyToPickup'))
    }
  }, [shipment])

  useEffect(checkValidation, [flag, containers, referenceEvents])

  useEffect(() => {
    if (!open) {
      setReferenceEvents({})
      setContainers([])
      setInvalid(true)
      setFlag(undefined)
      setUploadDocuments({})
      setUploading(false)
    }
  }, [open])

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Update shipment status'
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="booking-wizard">
          <Grid item xs={8} className='booking-forms'>
            <Grid container className="h-full">

              <Grid item xs={12}>
                <Grid container spacing={4}>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Grid container spacing={1}
                          alignItems="center">
                          <Grid item xs={6}
                            className="semi-bold text-16">
                            Upload document</Grid>
                          <Grid item xs={6} className="regular fg-gray text-14 text-right">
                            Custom clearance doc & NOA</Grid>
                        </Grid>
                      </Grid>

                      {/* Remove false once we get the condition */}
                      {false &&
                        <Grid item xs={12}>
                          <Grid container spacing={1}
                            alignItems="center"
                            justify="space-between"
                            className="p-4 bg-light-orange">
                            <Grid item xs={9}
                              className="regular fg-gray italic text-12">
                              You have uploaded the custom doc & it is being reviewed by the consignee</Grid>
                            <Grid item xs={3} className="medium fg-blue text-12 text-right">
                              view doc</Grid>
                          </Grid>
                        </Grid>
                      }

                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={2}>
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid item onClick={() => setReview(true)}
                                className="cursor-pointer">
                                <FuseCheckBox checked={review === true} />
                                <label className="cursor-pointer">
                                  Let consignee review custom draft</label>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container>
                              <Grid item onClick={() => setReview(false)}
                                className="cursor-pointer">
                                <FuseCheckBox checked={review === false} />
                                <label className="cursor-pointer">
                                  No need to review</label>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <FuseFileUploader fileTitle="Custom clearance doc (PIB)"
                          type="ICP"
                          name="ICP"
                          image='assets/images/icons/Document-upload.png'
                          required={false}
                          onSuccess={handleUpdateDocument("ICP")}
                          allowedTypes={".pdf,.xls,.xlsx,.xlsm,.csv,.txt,.zip,.doc,.docx,.jpg,.jpeg,.png,.gif"}
                          maxFilesize={25} />
                      </Grid>

                      <Grid item xs={12}>
                        <FuseFileUploader fileTitle="Notice of arrival"
                          type="ArrivalNotice"
                          name="ArrivalNotice"
                          image='assets/images/icons/Document-upload.png'
                          required={false}
                          onSuccess={handleUpdateDocument("ArrivalNotice")}
                          allowedTypes={".pdf,.xls,.xlsx,.xlsm,.csv,.txt,.zip,.doc,.docx,.jpg,.jpeg,.png,.gif"}
                          maxFilesize={25} />
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Grid container spacing={1}
                          alignItems="center">
                          <Grid item xs={6}
                            className="semi-bold text-16">
                            Choose the shipment flag</Grid>
                        </Grid>
                      </Grid>

                      {flagData.map((flagItem, flagIndex) => {
                        return (
                          <Grid item xs={12} key={flagIndex}>
                            <Grid container
                              justify="space-between"
                              className={classNames({
                                'border-solid border-1 p-12 cursor-pointer': true,
                                'border-gray bg-gray': flag !== get(flagItem, 'color'),
                                'border-green bg-transparent-green': flag === get(flagItem, 'color')
                              })}
                              onClick={() => setFlag(flag === get(flagItem, 'color') ? undefined : get(flagItem, 'color'))}>
                              <Grid item xs={1}>
                                <FuseCheckBox checked={flag === get(flagItem, 'color')} />
                              </Grid>
                              <Grid item xs>
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Grid container
                                      alignItems="center"
                                      justify="space-between">
                                      <Grid item className="semi-bold text-14">
                                        {get(flagItem, 'title')}
                                      </Grid>
                                      <Grid item>
                                        <Icon className={`fas fa-flag fg-${get(flagItem, 'color')} text-18`} />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}
                                    className="regular text-12 fg-gray">
                                    {get(flagItem, 'description')}
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>

                  {flag === 'green' &&
                    <Grid item xs={12}>
                      <Grid container spacing={2}>

                        <Grid item xs={12}>
                          <Grid container spacing={1}
                            alignItems="center">
                            <Grid item className="semi-bold text-16 fg-gray">
                              When container is ready to pickup from port</Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12}>
                          <EventForm
                            shipment={shipment}
                            containers={containers}
                            setContainers={setContainers}
                            referenceEvents={referenceEvents}
                            setReferenceEvents={setReferenceEvents} />
                        </Grid>
                      </Grid>
                    </Grid>
                  }

                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container
                  alignItems="flex-end"
                  className="pt-40 h-full"
                  spacing={3}>
                  <Grid item xs={6}>
                    <LoadingButton className="btn btn_lg w-full btn_green"
                      disabled={flag && isInvalid}
                      loading={uploading || requesting}
                      onClick={uploadFiles}>Update</LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <ReviewBookingSummary shipment={shipment} />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default UpdateShipmentStatus
