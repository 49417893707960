import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { FuseDialog, FuseDialogBody, FuseDialogHeader, LoadingButton } from 'core/components'
import TransportTimeline from '../../core/components/Timelines/TransportTimeline'
import _, { filter, get } from 'lodash';
import { getAddressFromLocation, formatTimestamp } from '../../core/helpers'
import { bookingInfoForm, general } from '../../core/localization'
import FPConflictResolveForm from './FPConflictResolveForm';

const ReviewBookingDialog = ({
  open,
  shipment,
  handleClose,
  currentPlant,
  onReject,
  onFreightPartnerAccept,
  onLogisticsPartnerAccept,
  getLimitedServiceCards,
  limitedServiceCards,
  resolveFpConflicts,
  resolveConflictCallback
}) => {
  const [note, setNote] = useState('');
  const [fpAcceptLoading, setFpAcceptLoading] = useState(false);
  const [selectedLimitedServiceCard, setSelectedLimitedServiceCard] = useState([]);
  const [isFpNeedConfirm, setIsFpNeedConfirm] = useState(false);
  const [isFpConflictConfirm, setIsFpConflictConfirm] = useState(false);
  const { fpConflict, spotMode } = shipment

  const handleFpAccept = () => {
    if (isFpNeedConfirm) {
      setIsFpNeedConfirm(false)
      setIsFpConflictConfirm(true)
    } else if (!isFpNeedConfirm && isFpConflictConfirm) {
      const removed = filter(limitedServiceCards,
        i => !selectedLimitedServiceCard.includes(get(i, 'document.meta.itineraryId')))
        .map(i => get(i, 'document.meta.itineraryId'))
      const payload = {
        retained: selectedLimitedServiceCard,
        removed
      }

      setFpAcceptLoading(true)
      if ((_.get(spotMode, 'enabled', false) === false && fpConflict)) {
        resolveFpConflicts(shipment._id, payload, () => {
          onFreightPartnerAccept(shipment)
          setFpAcceptLoading(false)
          setIsFpNeedConfirm(false)
          setIsFpConflictConfirm(false)
          resolveConflictCallback()
        }, () => {
          setFpAcceptLoading(false)
          setIsFpConflictConfirm(false)
          setIsFpNeedConfirm(false)
        })
      } else {
        onFreightPartnerAccept(shipment)
        setFpAcceptLoading(false)
      }
    }
  }

  useEffect(() => {
    if (open) {
      if (_.get(spotMode, 'enabled', false) === false && fpConflict) {
        setIsFpNeedConfirm(true)
      } else {
        setIsFpNeedConfirm(false)
        setIsFpConflictConfirm(true)
      }
    } else {
      setNote('')
      setSelectedLimitedServiceCard([])
      setFpAcceptLoading(false)
      setIsFpNeedConfirm(false)
      setIsFpConflictConfirm(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  let transportRoute = [];

  if (currentPlant.type === 'ofp') {
    const originPort = _.find(_.get(shipment, 'journey', []), { location: { type: 'port', subType: 'origin' } })
    const destinationPort = _.find(_.get(shipment, 'journey', []), { location: { type: 'port', subType: 'destination' } })

    transportRoute = [{
      place: _.get(originPort, 'location.name'),
      address: getAddressFromLocation(_.get(originPort, 'location.address'))
    },
    {
      place: _.get(destinationPort, 'location.name'),
      address: getAddressFromLocation(_.get(destinationPort, 'location.address'))
    }];
  }
  else if (currentPlant.type === 'obl') {
    const origin = _.find(_.get(shipment, 'journey', []), { location: { type: 'warehouse', subType: 'origin' } })
    const destination = _.find(_.get(shipment, 'journey', []), { location: { type: 'warehouse', subType: 'destination' } })

    transportRoute = [{
      place: _.get(origin, 'location.name'),
      address: getAddressFromLocation(_.get(origin, 'location.address'))
    },
    {
      place: _.get(destination, 'location.name'),
      address: getAddressFromLocation(_.get(destination, 'location.address'))
    }];
  }

  const bookingHistory = _.get(shipment, 'history', []).map(item => {
    const type = _.get(bookingInfoForm, `bookingHistory.${_.get(item, 'value')}`, '')
    const actor = _.get(general, `partnerShortForms.${_.get(item, 'actor')}`, '')
    return {
      place: (
        <Grid container spacing={1}>
          <Grid item>{type}</Grid>
          <Grid item className="fg-gray regular">&bull;</Grid>
          <Grid item className="fg-gray regular">{actor}</Grid>
        </Grid>
      ),
      address: (
        <label className="italic">Note:&nbsp;{_.get(item, 'reason', 'N/A')}</label>
      )
    }
  })

  const activeContainers = _.filter(_.get(shipment, 'containers', []), item =>
    (_.get(item, 'status.current.value') !== 'cancelled')
  )
  const cancelledContainers = _.filter(_.get(shipment, 'containers', []), item =>
    (_.get(item, 'status.current.value') === 'cancelled')
  )

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Review booking'
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="text-14">
          <Grid item xs={8}
            className="bg-white p-20">

            <Grid container className="h-full">
              <Grid item xs={12}>
                {_.get(currentPlant, 'type') === 'ofp' &&
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container className="pb-24">
                        <Grid item xs={12}
                          className="text-16 pb-12 semi-bold">Transport plan</Grid>

                        {_.get(shipment, 'type') === 'FCL' &&
                          _.filter(_.get(shipment, 'containers', []), item =>
                            (_.get(item, 'status.current.value') !== 'cancelled')
                          ).map((containerItem, containerIndex) => {
                            const dateTime = formatTimestamp(
                              _.get(
                                containerItem,
                                'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt',
                                null
                              ),
                              'date-time'
                            )

                            return (
                              <Grid item key={containerIndex} xs={12}
                                className="pb-8">
                                <Grid container>
                                  <Grid item xs={12}
                                    className="pb-12">

                                  </Grid>
                                  <Grid item xs={6}
                                    className="medium fg-gray">
                                    <label>Truck In date&nbsp;-&nbsp;</label>
                                    <label>
                                      {_.get(containerItem, 'type') + ' (' + (containerIndex + 1) + ')'}
                                    </label>
                                  </Grid>
                                  <Grid item xs={6}
                                    className="medium text-right">
                                    {dateTime || 'N/A'}
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })
                        }

                        {_.get(shipment, 'type') === 'LCL' &&
                          <Grid item xs={12} className="pb-8">
                            <Grid container>
                              <Grid item xs={6}
                                className="medium fg-gray">Truck In date (Cargo Pickup)</Grid>
                              <Grid item xs={6}
                                className="medium text-right">
                                {formatTimestamp(
                                  _.get(
                                    shipment,
                                    'referenceEvents.truckInForCargoPickup.expectedAt',
                                    null
                                  ),
                                  'date-time'
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        }

                      </Grid>

                      {(_.get(spotMode, 'enabled', false) === false && fpConflict) &&
                        <Grid container className="pb-24">
                          <FPConflictResolveForm shipment={shipment}
                            limitedServiceCards={limitedServiceCards}
                            getLimitedServiceCards={getLimitedServiceCards}
                            selectedLimitedServiceCard={selectedLimitedServiceCard}
                            setSelectedLimitedServiceCard={setSelectedLimitedServiceCard} />
                        </Grid>
                      }

                      <Grid container className="pb-24">
                        <Grid item xs={12}
                          className="text-16 pb-12 semi-bold">Shipping details</Grid>

                        <Grid item xs={12} className="pb-8">
                          <Grid container>
                            <Grid item xs={6}
                              className="medium fg-gray">Shipping type</Grid>
                            <Grid item xs={6}
                              className="medium text-right">
                              {_.get(shipment, 'type')}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className="pb-8">
                          <Grid container>
                            <Grid item xs={6}
                              className="medium fg-gray">Total gross weight</Grid>
                            <Grid item xs={6}
                              className="medium text-right">
                              {_.sum(_.get(shipment, 'consolidations', [])
                                .map(item => _.get(item, 'weight.value', 0)))
                                .toFixed(2)}&nbsp;
                        {_.get(shipment, 'consolidations[0].weight.unit')}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} className="pb-8">
                          <Grid container>
                            <Grid item xs={6}
                              className="medium fg-gray">Total gross volume</Grid>
                            <Grid item xs={6}
                              className="medium text-right">
                              {_.sum(_.get(shipment, 'consolidations', [])
                                .map(item => _.get(item, 'volume.value', 0)))
                                .toFixed(2)}&nbsp;
                        {_.get(shipment, 'consolidations[0].volume.unit')}
                            </Grid>
                          </Grid>
                        </Grid>

                        {_.get(currentPlant, 'type') === 'ofp' &&
                          <Grid item xs={12} className="pb-8">
                            <Grid container>
                              <Grid item xs={6}
                                className="medium fg-gray">Planned GI</Grid>
                              <Grid item xs={6} className="medium text-right">
                                {_.get(shipment, 'summary.plannedGIDate', []).map(i => formatTimestamp(i)).join(', ')}
                              </Grid>
                            </Grid>
                          </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                }
                {_.get(currentPlant, 'type') === 'obl' &&
                  <>
                    {_.get(shipment, 'type') === 'FCL' &&
                      <>
                        <Grid container className="pb-24">
                          <Grid item xs={12}
                            className="text-16 pb-12 semi-bold">
                            Empty Container Pickup</Grid>

                          {_.filter(_.get(shipment, 'containers', []), item =>
                            (_.get(item, 'status.current.value') !== 'cancelled')
                          ).map((containerItem, containerIndex) => {
                            const place = getAddressFromLocation(
                              _.get(
                                containerItem,
                                'referenceEvents.emptyContainerPickUpFromYard.location',
                                {}
                              )
                            )
                            const dateTime = formatTimestamp(
                              _.get(
                                containerItem,
                                'referenceEvents.emptyContainerPickUpFromYard.expectedAt',
                                null
                              ),
                              'date-time'
                            )

                            return (
                              <Grid container key={containerIndex}>
                                <Grid item xs={12} className="pb-8">
                                  <label className="semi-bold">
                                    {_.get(containerItem, 'type') + ' (' + (containerIndex + 1) + ')'}
                                  </label>
                                </Grid>
                                <Grid item xs={12} className="pb-8">
                                  <Grid container>
                                    <Grid item xs={6}
                                      className="medium fg-gray">Place</Grid>
                                    <Grid item xs={6}
                                      className="medium text-right">
                                      {place || 'N/A'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} className="pb-16">
                                  <Grid container>
                                    <Grid item xs={6}
                                      className="medium fg-gray">Date</Grid>
                                    <Grid item xs={6}
                                      className="medium text-right">
                                      {dateTime || 'N/A'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )

                          })}
                        </Grid>

                        <Grid container className="pb-24">
                          <Grid item xs={12}
                            className="text-16 pb-12 semi-bold">
                            Truck In with empty container</Grid>

                          {_.filter(_.get(shipment, 'containers', []), item =>
                            (_.get(item, 'status.current.value') !== 'cancelled')
                          ).map((containerItem, containerIndex) => {
                            const place = getAddressFromLocation(
                              _.get(
                                containerItem,
                                'referenceEvents.truckInToSWHWithEmptyContainer.location',
                                {}
                              )
                            )
                            const dateTime = formatTimestamp(
                              _.get(
                                containerItem,
                                'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt',
                                null
                              ),
                              'date-time'
                            )

                            return (
                              <Grid container key={containerIndex}>
                                <Grid item xs={12} className="pb-8">
                                  <label className="semi-bold">
                                    {_.get(containerItem, 'type') + ' (' + (containerIndex + 1) + ')'}
                                  </label>
                                </Grid>
                                <Grid item xs={12} className="pb-8" >
                                  <Grid container>
                                    <Grid item xs={6}
                                      className="medium fg-gray">Place</Grid>
                                    <Grid item xs={6}
                                      className="medium text-right">
                                      {place || 'N/A'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} className="pb-16">
                                  <Grid container>
                                    <Grid item xs={6}
                                      className="medium fg-gray">Date</Grid>
                                    <Grid item xs={6}
                                      className="medium text-right">
                                      {dateTime || 'N/A'}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })}
                        </Grid>
                      </>
                    }

                    {_.get(shipment, 'type') === 'LCL' &&
                      <>
                        <Grid container className="pb-24">
                          <Grid item xs={12}
                            className="text-16 pb-12 semi-bold">Truck In date (Cargo Pickup)</Grid>

                          <Grid item xs={12} className="pb-8">
                            <Grid container>
                              <Grid item xs={6}
                                className="medium fg-gray">Place</Grid>
                              <Grid item xs={6}
                                className="medium text-right">
                                {getAddressFromLocation(
                                  _.get(
                                    shipment,
                                    'referenceEvents.truckInForCargoPickup.location',
                                    {}
                                  )
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} className="pb-8">
                            <Grid container>
                              <Grid item xs={6}
                                className="medium fg-gray">Date</Grid>
                              <Grid item xs={6}
                                className="medium text-right">
                                {formatTimestamp(
                                  _.get(
                                    shipment,
                                    'referenceEvents.truckInForCargoPickup.expectedAt',
                                    null
                                  ),
                                  'date-time'
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    }
                  </>
                }

                {_.get(shipment, 'type') === 'FCL' &&
                  <Grid container className="pb-28">
                    <Grid item xs={6}
                      className="text-16 pb-12 semi-bold">Containers</Grid>
                    <Grid item xs={6}
                      className="text-16 pb-12 semi-bold text-right">
                      {activeContainers.length > 0 &&
                        <label>{`${activeContainers.length} Active`}</label>
                      }
                      {cancelledContainers.length > 0 &&
                        <label className="ml-12">{`${cancelledContainers.length} Cancelled`}</label>
                      }
                    </Grid>
                    {_.get(shipment, 'containers', []).filter(x => x.type === 'C20GP').length > 0 &&
                      <Grid item xs={12} className="pb-14">
                        <Grid container alignItems="center">
                          <Grid item xs={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={2}>
                                <Grid container justify="center">
                                  <img src="assets/images/icons/review-booking/Container-booking-20@3x.svg"
                                    alt="container" />
                                </Grid>
                              </Grid>
                              <Grid item xs={10}
                                className="medium fg-gray">20 FT Standard</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}
                            className="medium text-right">
                            {_.get(shipment, 'containers', []).filter(x => x.type === 'C20GP').length}
                          </Grid>
                        </Grid>
                      </Grid>
                    }

                    {_.get(shipment, 'containers', []).filter(x => x.type === 'C40GP').length > 0 &&
                      <Grid item xs={12} className="pb-14">
                        <Grid container>
                          <Grid item xs={6}
                            className="medium fg-gray">
                            <Grid container alignItems="center">
                              <Grid item xs={2}>
                                <Grid container justify="center">
                                  <img src="assets/images/icons/review-booking/Container-booking-40@3x.svg"
                                    alt="container" />
                                </Grid>
                              </Grid>
                              <Grid item xs={10}
                                className="medium fg-gray">40 FT Standard</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}
                            className="medium text-right">
                            {_.get(shipment, 'containers', []).filter(x => x.type === 'C40GP').length}
                          </Grid>
                        </Grid>
                      </Grid>
                    }

                    {_.get(shipment, 'containers', []).filter(x => x.type === 'C40HC').length > 0 &&
                      <Grid item xs={12} className="pb-14">
                        <Grid container>
                          <Grid item xs={6}
                            className="medium fg-gray">
                            <Grid container alignItems="center">
                              <Grid item xs={2}>
                                <Grid container justify="center">
                                  <img src="assets/images/icons/review-booking/Container-booking-40hc@3x.svg"
                                    alt="container" />
                                </Grid>
                              </Grid>
                              <Grid item xs={10}
                                className="medium fg-gray">40 FT High Cube</Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}
                            className="medium text-right">
                            {_.get(shipment, 'containers', []).filter(x => x.type === 'C40HC').length}
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                }

                {(_.get(currentPlant, 'type') === 'ofp' &&
                  _.get(shipment, 'status.current.value') === 'amended'
                ) &&
                  <Grid container direction='row' className='mt-16 mb-24'>
                    <Grid item xs={12}
                      className="text-16 semi-bold pb-12">Amend reason</Grid>
                    <Grid item xs={12}>
                      <label className="medium fg-gray">
                        {_.get(shipment, 'status.current.reason')}
                      </label>
                    </Grid>
                  </Grid>
                }

                {(_.get(currentPlant, 'type') === 'shipper' &&
                  _.get(shipment, 'status.current.value') === 'rejected'
                ) &&
                  <Grid container direction='row' className='mt-16 mb-24'>
                    <Grid item xs={12}
                      className="text-16 semi-bold pb-12">Rejected reason</Grid>
                    <Grid item xs={12}>
                      <label className="medium fg-gray">
                        {_.get(shipment, 'status.current.reason')}
                      </label>
                    </Grid>
                  </Grid>
                }

                {_.get(currentPlant, 'type') === 'obl' &&
                  <Grid container direction='row' className='mt-16 mb-24'>
                    <Grid item xs={12}
                      className="text-16 semi-bold">Add note</Grid>
                    <Grid item xs={12}>
                      <TextField name='note'
                        placeholder="You can add any extra note for this booking"
                        className='theme-text-field w-full'
                        value={note}
                        inputProps={{
                          style: {
                            fontSize: '14px'
                          }
                        }}
                        onChange={event => setNote(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12} className="pt-40">
                <Grid container className="h-full" alignItems="flex-end">
                  {_.get(currentPlant, 'type') === 'obl' &&
                    <Grid item xs={6} className="pr-12">
                      <Button className="btn btn_lg w-full"
                        onClick={() => onLogisticsPartnerAccept(shipment, note)}>
                        Acknowledge Booking</Button>
                    </Grid>
                  }

                  {(_.get(currentPlant, 'type') === 'ofp' && !isFpNeedConfirm) &&
                    <>
                      <Grid item xs={6} className="pr-12">
                        <LoadingButton className="btn btn_lg w-full"
                          loading={fpAcceptLoading}
                          disabled={_.get(spotMode, 'enabled', false) === false && fpConflict && selectedLimitedServiceCard.length === 0}
                          onClick={handleFpAccept}>
                          Accept</LoadingButton>
                      </Grid>
                      <Grid item xs={6} className="pl-12">
                        <Button className="btn btn_lg w-full btn_light_red"
                          disabled={fpAcceptLoading}
                          onClick={() => onReject(shipment)}>
                          Reject</Button>
                      </Grid>
                    </>
                  }

                  {(_.get(currentPlant, 'type') === 'ofp' && isFpNeedConfirm) &&
                    <>
                      <Grid item xs={6} className="pr-12">
                        <Button className="btn btn_lg w-full"
                          disabled={fpConflict && selectedLimitedServiceCard.length === 0}
                          onClick={handleFpAccept}>
                          Confirm Selection</Button>
                      </Grid>
                      <Grid item xs={6} className="pl-12">
                        <Button className="btn btn_lg w-full btn_light_red"
                          disabled={fpConflict && selectedLimitedServiceCard.length === 0}
                          onClick={() => setSelectedLimitedServiceCard([])}>
                          Cancel</Button>
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
            </Grid>

          </Grid>

          <Grid item xs={4}
            className="bg-gray p-20">
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}
                    className="text-16 pb-12">
                    Transport route</Grid>
                  <Grid item xs={12}>
                    <TransportTimeline data={transportRoute} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container
                  className="border-t-1 border-dashed border-gray">
                  <Grid item xs={12} className="pt-12">
                    <Grid container>
                      <Grid item xs={12}
                        className="text-16 pb-12">Booking History</Grid>
                      <Grid item xs={12}>
                        <TransportTimeline data={bookingHistory} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default ReviewBookingDialog
