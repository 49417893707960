import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  API_ATTEMPT
  API_SUCCESS
  API_FAILED

  SET_NETWORK_DOWN
  SET_SHIPMENT_COUNTS
  SHOW_MESSAGE
  HIDE_MESSAGE

  LOAD_ORIGIN_COUNTRIES
  LOAD_DESTINATION_COUNTRIES
  LOAD_ORIGIN_PORTS
  LOAD_DESTINATION_PORTS
  LOAD_ORIGIN_PLANTS
  LOAD_DESTINATION_PLANTS
  LOAD_TRANSPORTATION
  LOAD_OFP
  LOAD_OBL
  LOAD_OBC
  SET_CUSTOM_FILTERS
  SET_DOCUMENTS
  SET_USER_NOTIFICATIONS
  MARK_USER_NOTIFICATIONS_READ
  SET_UNREAD_USER_NOTIFICATIONS

  SET_BAD_PERM_INFO
  HIGH_SECURITY_TOKEN_REDIRECT

  GET_VERSION_SUCCESS

  LOAD_USER_SETTINGS_EVENTS_LIST

  LOAD_PLANTS
  LOAD_SYSTEM_SETTINGS
  LOAD_AUTH_PROVIDERS
  LOAD_USERS_BY_PLANT_IDS

  LOADING_SYSTEM_SEARCH_SHIPMENTS
  LOAD_SYSTEM_SEARCH_SHIPMENTS
  SET_AUTO_REFRESH
  `
)
