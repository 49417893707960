import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import * as API from 'core/api'
import { get, groupBy, concat } from "lodash";

export const getShipmentTrack = (params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.getShipmentTrack(params)

    if (!ok) {
      throw new Error(data.message)
    }

    const events = concat(
      get(data, 'data.seaRatesData.events', []),
      get(data, 'data.yodaData.events', [])
    )
    const groupedEvents = groupBy(events, 'type', {})

    dispatch({
      type: ActionTypes.LOAD_SHIPMENT_TRACK,
      payload: groupedEvents
    })
    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}
