import React, { useEffect, useState } from 'react'
import { Tabs, Tab, Typography, Box, Grid, Icon } from '@material-ui/core'
import ShipmentEvents from './ShipmentEvents'
import EventsTracking from './EventsTracking'
import BookingDetails from './BookingDetails'
import ShipmentDocuments from './ShipmentDocuments'
import BookingHistory from './BookingHistory'
import ContainerDetails from './ContainerDetails'
import ShipmentRoute from './ShipmentRoute'
import history from '../../history'
import { get } from 'lodash'
import ListTable from "../../core/components/ListTable/ListTable";
import ViewMessageDialog from "./ViewMessageDialog";
import { fileUploadInfo } from "../../core/localization";
import { addGaEvent, getShipmentDetailsTabs } from "../../core/helpers";
import { AddEventSkeleton, EventTrackingSkeleton } from './skeletons'
import { FusePlaceHolder } from '../../core/components'
import PreAlertSidebar from './PreAlertSidebar'
import GoodsReceived from './GoodsReceived'
import ShipmentTopics from './ShipmentTopics'
import moment from 'moment'

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component='div'
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={'w-full overflow-hidden'}
    {...other}>
    <Box>{children}</Box>
  </Typography>
)

const SimpleTabs = ({
  shipment,
  documents,
  chatDocuments,
  eventsTracking,
  onUploadClick,
  eventWriteList,
  addShipmentEvents,
  onBLClick,
  onSingleFileDelete,
  onTrackingClick,
  currentPlant,
  onCarrierClick,
  onContainerClick,
  updateEventTime,
  updatePreps,
  updateShipmentContainers,
  updateShipmentCS,
  urlTabValue,
  addContainer,
  updateSingleContainer,
  showMessage,
  preAlertLogs,
  onBookingChange,
  onCSAdd,
  systemSettings,
  sendPreAlert,
  copyToClipboard,
  goodsReceived,
  topics,
  getShipmentTopics,
  getShipmentTopicDetails,
  addShipmentTopic,
  addShipmentTopicsComment,
  openShipmentTopics,
  closeShipmentTopics,
  userInfo,
  urlTopicId,
  plantEmailTemplates = [],
  getPlantEmailTemplates,
  plants,
  updateTopics,
  getShipmentDocuments,
  falconConfig
}) => {
  const [value, setValue] = React.useState(0)
  const [viewMessage, setViewMessage] = useState({})

  useEffect(() => {
    if (get(currentPlant, 'type') === 'shipper' &&
      get(systemSettings, 'preAlert.enabled')) {
      setValue(Number(urlTabValue <= 7 ? urlTabValue : 0))
    } else {
      setValue(Number(urlTabValue <= 6 ? urlTabValue : 0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlTabValue, systemSettings])

  useEffect(() => {
    setViewMessage({})
  }, [preAlertLogs])

  const handleChange = (event, newValue) => {
    addGaEvent('shipment-changeDetailsTab', get(shipment, '_id'), newValue)
    history.push(`?tab=${newValue}`)
  }

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  const preAlertLogFields = [
    {
      name: 'Subject',
      rendered: (data) => {
        return (
          <label>{get(data, 'subject')}</label>
        )
      }
    },
    {
      name: 'Recipients',
      rendered: (data) => {
        const recipients = get(data, 'recipients.emailAddresses', '').toString().split(',')
        if (recipients.length === 0) {
          return <React.Fragment />
        }
        else if (recipients.length > 1) {
          return (
            <React.Fragment>
              <label className="block">{get(recipients, '[0]')}</label>
              <label className="block text-11 fg-blue cursor-pointer"
                onClick={() => setViewMessage(data)}>
                +&nbsp;{recipients.length - 1}&nbsp;more&nbsp;recipients</label>
            </React.Fragment>
          )
        } else {
          return <label className="block">{get(recipients, '[0]')}</label>
        }
      }
    },
    {
      name: 'Documents',
      rendered: (data) => {
        const documentTypes = get(data, 'documentTypes', [])
        if (documentTypes.length === 0) {
          return <React.Fragment />
        }
        else if (documentTypes.length > 1) {
          return (
            <React.Fragment>
              <label className="block">
                {get(fileUploadInfo, get(documentTypes, '[0]'))}
              </label>
              <label className="block text-11 fg-blue cursor-pointer"
                onClick={() => setViewMessage(data)}>
                +&nbsp;{documentTypes.length - 1}&nbsp;more&nbsp;documents</label>
            </React.Fragment>
          )
        } else {
          return <label className="block">
            {get(fileUploadInfo, get(documentTypes, '[0]'))}
          </label>
        }
      }
    },
    {
      name: 'Message',
      rendered: (data) => {
        return (
          <label className="text-10 cursor-pointer uppercase fg-blue semi-bold"
            onClick={() => setViewMessage(data)}>View&nbsp;Message</label>
        )
      }
    },
    {
      name: 'Date Time',
      rendered: (data) => {
        let createdAt = get(data, 'createdAt')

        return (
          <label>{moment(createdAt).format("lll")}</label>
        )
      }
    },
  ]

  return (
    <Grid container>
      <Tabs value={value}
        onChange={handleChange}
        className='custom-tabs'
        variant="scrollable"
        scrollButtons="auto"
        aria-label="shipment detail tabs">
        {getShipmentDetailsTabs().map((item, itemIndex) => {
          if (itemIndex === 7) {
            if (get(currentPlant, 'type') === 'shipper' && get(systemSettings, 'preAlert.enabled')) {
              return (
                <Tab key={itemIndex} label={item} {...a11yProps(itemIndex)}
                  className={'custom-tabs-item ' + (value === itemIndex ? 'selected' : '')} />
              )
            } else {
              return false
            }
          } else {
            return (
              <Tab key={itemIndex} label={item} {...a11yProps(itemIndex)}
                className={'custom-tabs-item ' + (value === itemIndex ? 'selected' : '')} />
            )
          }
        })}
      </Tabs>

      <TabPanel value={value} index={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={8}>
            <Grid container className="shipment-details-container overflow-auto">
              {get(eventWriteList, 'loading') &&
                <AddEventSkeleton />
              }
              {(!get(eventWriteList, 'loading') && get(eventWriteList, 'data', []).length > 0) && (
                <Grid item xs={12} className='shipment-details-container-section'>
                  <ShipmentEvents shipment={shipment}
                    eventWriteList={get(eventWriteList, 'data', [])}
                    loading={get(eventWriteList, 'loading', false)}
                    addShipmentEvents={addShipmentEvents} />
                </Grid>
              )}

              <Grid item xs={12} className='shipment-details-container-section'>
                {get(eventsTracking, 'loading') &&
                  <EventTrackingSkeleton />
                }
                {!get(eventsTracking, 'loading') &&
                  <EventsTracking currentPlant={currentPlant}
                    events={get(eventsTracking, 'data', [])}
                    onTrackingClick={onTrackingClick}
                    updateEventTime={updateEventTime}
                    addShipmentEvents={addShipmentEvents}
                    shipment={shipment}
                  />
                }
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} md={4}>
            <ShipmentRoute shipment={shipment}
              currentJourneyLandMark={get(shipment, 'summary.currentJourneyLandMark')}
              plannedRoute={shipment.plannedRoute}
              latestEvent={shipment.summary && shipment.summary.latestActualEvent} />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={8}>
            <Grid container className='shipment-details-container'>
              <BookingDetails
                shipment={shipment}
                onBLClick={onBLClick}
                onCarrierClick={onCarrierClick}
                onContainerClick={onContainerClick}
                currentPlant={currentPlant}
                updateShipmentCS={updateShipmentCS}
                onBookingChange={onBookingChange}
                onCSAdd={onCSAdd}
                copyToClipboard={copyToClipboard}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4} md={4} className='sm:pt-0 px-8'>
            <ShipmentRoute shipment={shipment}
              currentJourneyLandMark={get(shipment, 'summary.currentJourneyLandMark')}
              plannedRoute={shipment.plannedRoute}
              latestEvent={shipment.summary && shipment.summary.latestActualEvent} />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={8}>
            <Grid container className='container-details-container'>
              <ContainerDetails
                updateShipmentContainers={updateShipmentContainers}
                shipment={shipment}
                onContainerClick={onContainerClick}
                updatePreps={updatePreps}
                addContainer={addContainer}
                updateSingleContainer={updateSingleContainer}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} md={4} className='sm:pt-0 px-8'>
            <ShipmentRoute shipment={shipment}
              currentJourneyLandMark={get(shipment, 'summary.currentJourneyLandMark')}
              plannedRoute={shipment.plannedRoute}
              latestEvent={shipment.summary && shipment.summary.latestActualEvent} />
          </Grid>

        </Grid>
      </TabPanel>

      <TabPanel value={value} index={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={8}>
            <Grid container className='shipment-details-container'>
              <ShipmentDocuments currentPlant={currentPlant}
                showMessage={showMessage}
                shipmentId={shipment._id}
                shipmentName={shipment.name}
                shipmentActors={get(shipment, 'actors', {})}
                shipmentCurrentStage={get(shipment, 'stage.current.value')}
                shipmentStatus={get(shipment, 'status')}
                documents={documents}
                chatDocuments={chatDocuments}
                onUploadClick={onUploadClick}
                onSingleFileDelete={onSingleFileDelete}
                hasWritableDocs={get(shipment, 'rbac.write', []).length > 0}
                getDocuments={getShipmentDocuments}
                falconConfig={falconConfig}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} md={4} className='sm:pt-0 px-8'>
            <ShipmentRoute shipment={shipment}
              currentJourneyLandMark={get(shipment, 'summary.currentJourneyLandMark')}
              plannedRoute={shipment.plannedRoute}
              latestEvent={shipment.summary && shipment.summary.latestActualEvent} />
          </Grid>

        </Grid>
      </TabPanel>

      <TabPanel value={value} index={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8} md={8} >
            <Grid container className='shipment-details-container'>
              <BookingHistory shipment={shipment} />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={4} md={4} className='sm:pt-0 px-8'>
            <ShipmentRoute shipment={shipment}
              currentJourneyLandMark={get(shipment, 'summary.currentJourneyLandMark')}
              plannedRoute={shipment.plannedRoute}
              latestEvent={shipment.summary && shipment.summary.latestActualEvent} />
          </Grid>

        </Grid>
      </TabPanel>

      <TabPanel value={value} index={7}>
        <Grid container className='shipment-details-container'>
          <Grid item xs={12}>
            <GoodsReceived goodsReceived={goodsReceived} />
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index={5}>
        <Grid container className='shipment-details-container'>
          <Grid item xs={12}>
            <ShipmentTopics topics={topics}
              userInfo={userInfo}
              shipmentId={get(shipment, '_id')}
              shipmentActors={get(shipment, 'actors')}
              getTopics={getShipmentTopics}
              getTopicDetails={getShipmentTopicDetails}
              addTopic={addShipmentTopic}
              addTopicsComment={addShipmentTopicsComment}
              openTopics={openShipmentTopics}
              closeTopics={closeShipmentTopics}
              urlTabValue={urlTabValue}
              urlTopicId={urlTopicId}
              plants={plants}
              updateTopics={updateTopics} />
          </Grid>
        </Grid>
      </TabPanel>

      {(get(currentPlant, 'type') === 'shipper' &&
        get(systemSettings, 'preAlert.enabled')) &&
        <TabPanel value={value} index={6}>
          <Grid container className='shipment-details-container'>
            <Grid item xs={12} className="p-20">
              {(get(preAlertLogs, 'loading') === false &&
                get(preAlertLogs, 'data', []).length === 0) &&
                <FusePlaceHolder className="flex flex-col">
                  <Grid container justify="center" spacing={2}>
                    <Icon className="fg-gray text-60">info</Icon>
                    <Grid item xs={12} className="bold text-center">
                      No Pre-Alerts</Grid>
                    <Grid item xs={12} className="regular text-center">
                      Uh oh! Looks like you haven't sent any pre-alert yet</Grid>
                    <Grid item className="max-w-192">
                      <PreAlertSidebar shipment={shipment}
                        currentPlant={currentPlant}
                        plantEmailTemplates={plantEmailTemplates}
                        getPlantEmailTemplates={getPlantEmailTemplates}
                        onSubmit={sendPreAlert} />
                    </Grid>
                  </Grid>
                </FusePlaceHolder>
              }
              {(get(preAlertLogs, 'loading') ||
                get(preAlertLogs, 'data', []).length > 0) &&
                <ListTable fields={preAlertLogFields}
                  loading={get(preAlertLogs, 'loading', false)}
                  logs={get(preAlertLogs, 'data', [])}
                  topAligned />
              }
            </Grid>
          </Grid>
          <ViewMessageDialog {...viewMessage}
            clearViewMessage={() => setViewMessage({})} />
        </TabPanel>
      }
    </Grid>
  )
}

export default SimpleTabs
