import React from 'react'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions,
  FuseTextField,
  FuseButtonToggle,
  LoadingButton
} from '../../core/components'
import { Typography, Box, Grid, CircularProgress } from '@material-ui/core'

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const CreateUser = ({
  sidebarOpen,
  email,
  closeSideBar,
  existingUser,
  checkingExistingUser,
  userAlreadyMember,
  createUser,
  role,
  setEmail,
  setRole,
  invalidEmail,
  submitting
}) => (
    <FuseSideBar open={sidebarOpen}>
      <FuseSideBarHeader text={`Add User`}
        handleClose={closeSideBar} />
      <FuseSideBarBody>
        <Typography color='inherit'
          className='text-16 semi-bold'>
          User Details</Typography>

        <Grid container className='my-12'>
          <Grid item xs={12} className="relative">
            <FuseTextField className='mb-12'
              type='email'
              name='email'
              label='Email Address'
              icon={{ ...baseTextSetting, name: 'email' }}
              value={email}
              onChange={e => setEmail(e.target.value)}
              inputStyles={{
                fontSize: 14,
                fontFamily: 'IBMPlexSans'
              }}
              required
              fullWidth
              error={userAlreadyMember || invalidEmail}
              helperText={
                userAlreadyMember ? 'User is already a member of plant' : invalidEmail ? 'Enter a valid email address' : ''
              }
            />
            {(email && checkingExistingUser) &&
              <CircularProgress size={18}
                className="absolute pin-t pin-r uppercase fg-blue regular mr-28 mt-16" />
            }
            {(email && !invalidEmail && !userAlreadyMember && !checkingExistingUser && !existingUser) &&
              <label className="absolute pin-t pin-r uppercase fg-blue regular text-12 mr-28 mt-20">
                new user</label>
            }
          </Grid>
          {existingUser && (
            <Grid item xs={12}>
              <Box className="rounded-2 bg-gray p-12">
                <Typography className='text-12 regular italic font-light fg-dark-gray mb-4'>
                  This is an existing user in this platform.</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        <Typography color='inherit'
          className='text-16 semi-bold my-12'>
          Role for this plant</Typography>
        <FuseButtonToggle value={role}
          data={[
            { label: 'Basic', value: 'basic' },
            { label: 'Admin', value: 'admin' }
          ]}
          buttonClass='text-10 h-28 px-20 leading-none'
          onChange={value => setRole(value)}
        />
      </FuseSideBarBody>
      <FuseSideBarActions>
        <LoadingButton className='btn btn_full-space'
          loading={submitting}
          onClick={createUser}
          disabled={!email || userAlreadyMember || invalidEmail || checkingExistingUser}>
          Create</LoadingButton>
      </FuseSideBarActions>
    </FuseSideBar>
  )
export default CreateUser
