import React from 'react'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions
} from '../../core/components'
import { withStyles, Button, Grid } from '@material-ui/core'
import classNames from 'classnames'
import { capitalize } from 'lodash'
import { formatTimestamp, getAPIBaseURL } from '../../core/helpers'
import { general } from '../../core/localization'
import { get } from 'lodash'

const EventTrackingSidebar = ({ classes, open, onClose, event }) => {
  if (!event) {
    return null
  }

  return (
    <FuseSideBar open={open} onBackDropClick={onClose}>
      <FuseSideBarHeader text={get(event, 'name')}
        handleClose={onClose} />

      <FuseSideBarBody>
        <div className={classNames(classes.dark, 'text-14 font-bold')}>
          {get(event, 'allEvents.planned', []).length > 0 && (
            <div className={classes.block}>
              <div className='mb-16 flex-none'>
                <span className={classes.eLetter}>P</span>
                <span>Planned event</span>
              </div>
              {get(event, 'allEvents.planned', []).map((v, i) => {
                const user = `${get(v, 'source.user.firstname')} ${get(v, 'source.user.lastname')}`
                const plant = `${get(v, 'source.plant.name')} [${get(general, `partnerShortForms.${get(v, 'source.plant.type')}`)}]`

                return (
                  <Grid container
                    justify="space-between"
                    key={`item_${i}`}
                    className="mb-8">
                    <Grid item xs={8} className="flex flex-col">
                      <span className={classes.dark}>
                        {formatTimestamp(get(v, 'occurenceAt'), 'date-time')}
                      </span>
                      <p className='text-12'>
                        {capitalize(get(v, 'source.type'))}
                      </p>
                      {user.search('undefined') === -1 &&
                        <label className='text-10 regular fg-gray'>
                          User: {user}
                        </label>
                      }
                      {plant.search('undefined') === -1 &&
                        <label className='text-10 regular fg-gray'>
                          Plant: {plant}
                        </label>
                      }
                      <label className='text-10 regular fg-gray'>
                        Reported at: {formatTimestamp(get(v, 'reportedAt'), 'date-time')}
                      </label>
                    </Grid>
                    <Grid item xs={4}
                      className='text-right'>
                      <img width={84} alt='logo'
                        src={
                          get(v, 'source.type') === 'tradeLens' ? `/assets/images/logos/tradeLens.png`
                            : get(v, 'source.plant')
                              ? `${getAPIBaseURL()}/plants/${get(v, 'source.plant._id')}/logo`
                              : `/assets/images/logos/trames.png`
                        } />
                    </Grid>
                  </Grid>
                )
              })}
            </div>
          )}
          {get(event, 'allEvents.estimated', []).length > 0 && (
            <div className={classes.block}>
              <div className='mb-16 flex-none'>
                <span className={classes.eLetter}>E</span>
                <span>Estimated event</span>
              </div>
              {get(event, 'allEvents.estimated', []).map((v, i) => {
                const user = `${get(v, 'source.user.firstname')} ${get(v, 'source.user.lastname')}`
                const plant = `${get(v, 'source.plant.name')} [${get(general, `partnerShortForms.${get(v, 'source.plant.type')}`)}]`

                return (
                  <Grid container
                    justify="space-between"
                    key={`item_${i}`}
                    className="mb-8">
                    <Grid item xs={8} className="flex flex-col">
                      <span className={classes.dark}>
                        {formatTimestamp(get(v, 'occurenceAt'), 'date-time')}
                      </span>
                      <p className='text-12'>
                        {capitalize(get(v, 'source.type'))}
                      </p>
                      {user.search('undefined') === -1 &&
                        <label className='text-10 regular fg-gray'>
                          User: {user}
                        </label>
                      }
                      {plant.search('undefined') === -1 &&
                        <label className='text-10 regular fg-gray'>
                          Plant: {plant}
                        </label>
                      }
                      <label className='text-10 regular fg-gray'>
                        Reported at: {formatTimestamp(get(v, 'reportedAt'), 'date-time')}
                      </label>
                    </Grid>
                    <Grid item xs={4}
                      className='text-right'>
                      <img width={84} alt='logo'
                        src={
                          get(v, 'source.type') === 'tradeLens' ? `/assets/images/logos/tradeLens.png`
                            : get(v, 'source.plant')
                              ? `${getAPIBaseURL()}/plants/${get(v, 'source.plant._id')}/logo`
                              : `/assets/images/logos/trames.png`
                        } />
                    </Grid>
                  </Grid>
                )
              })}
            </div>
          )}
          {get(event, 'allEvents.actual', []).length > 0 && (
            <div className={classes.block}>
              <div className='mb-16'>
                <span>Actual event</span>
              </div>
              {get(event, 'allEvents.actual', []).map((v, i) => {
                const user = `${get(v, 'source.user.firstname')} ${get(v, 'source.user.lastname')}`
                const plant = `${get(v, 'source.plant.name')} [${get(general, `partnerShortForms.${get(v, 'source.plant.type')}`)}]`

                return (
                  <Grid container
                    justify="space-between"
                    key={`item_${i}`}
                    className="mb-8">
                    <Grid item xs={8} className="flex flex-col">
                      <span className={classes.dark}>
                        {formatTimestamp(get(v, 'occurenceAt'), 'date-time')}
                      </span>
                      <p className='text-12'>
                        {capitalize(get(v, 'source.type'))}
                      </p>
                      {user.search('undefined') === -1 &&
                        <label className='text-10 regular fg-gray'>
                          User: {user}
                        </label>
                      }
                      {plant.search('undefined') === -1 &&
                        <label className='text-10 regular fg-gray'>
                          Plant: {plant}
                        </label>
                      }
                      <label className='text-10 regular fg-gray'>
                        Reported at: {formatTimestamp(get(v, 'reportedAt'), 'date-time')}
                      </label>
                    </Grid>
                    <Grid item xs={4}
                      className='text-right'>
                      <img width={84} alt='logo'
                        src={
                          get(v, 'source.type') === 'tradeLens' ? `/assets/images/logos/tradeLens.png`
                            : get(v, 'source.plant')
                              ? `${getAPIBaseURL()}/plants/${get(v, 'source.plant._id')}/logo`
                              : `/assets/images/logos/trames.png`
                        } />
                    </Grid>
                  </Grid>
                )
              })}
            </div>
          )}
        </div>
      </FuseSideBarBody>

      <FuseSideBarActions>
        <Button className='btn btn_full-space text-uppercase'
          onClick={onClose}>
          Close</Button>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

const styles = theme => ({
  dark: {
    color: '#333333'
  },
  eLetter: {
    marginRight: 8,
    padding: '0 4px',
    borderRadius: 4,
    width: 16,
    height: 16,
    background: '#D8D8D8',
    color: '#ffffff'
  },
  block: {
    color: '#9FA0A4',
    borderBottom: '1px dashed currentColor',
    paddingBottom: 18,
    marginBottom: 18,
    display: 'flex',
    flexDirection: 'column',

    '&:last-child': {
      borderBottom: 'none'
    },
    '& .block-body': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 8
    }
  }
})

export default withStyles(styles)(EventTrackingSidebar)
