import React, { Component } from 'react'
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, withStyles } from '@material-ui/core'

import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout'
import { connect } from 'react-redux'
import * as UserActions from '../store/actions'
import { FuseIconButton } from '../../core/components'
import CreateUser from '../components/CreateUser'
import DeleteUser from '../components/DeleteUser'
import ToolBar from '../components/ToolBar'
import Icon from '@material-ui/core/Icon'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import validator from 'validator'
import debounce from 'lodash/debounce'
import Skeleton from 'react-loading-skeleton'
import { startSearching, stopSearching } from '../../core/helpers'
import { get, find } from 'lodash'

class Users extends Component {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
      rowFilter: 'all',
      sidebarOpen: false,
      email: '',
      role: 'basic',
      existingUser: null,
      checkingExistingUser: false,
      userAlreadyMember: false,
      invalidEmail: false,
      deleteUser: null,
      submitting: false
    }
  }

  resetState = () => {
    this.setState({
      sidebarOpen: false,
      email: '',
      role: 'basic',
      existingUser: null,
      checkingExistingUser: false,
      invalidEmail: false,
      userAlreadyMember: false,
      deleteUser: null,
      submitting: false
    })
  }

  componentDidMount() {
    this.props.getPlantUsers(this.state.rowFilter)
  }

  handleItemClick = (event, id) => {
    event.stopPropagation()
  }

  promptDelete = (e, user) => {
    e.stopPropagation()
    this.setState({ deleteUser: user })
  }

  handleDelete = async user_id => {
    await this.props.handleDelete(user_id, this.props.currentPlant._id)
    this.resetState()
    this.props.getPlantUsers(this.state.rowFilter)
  }
  search = () => {
    this.props.getPlantUsers(this.state.rowFilter, this.state.search)
  }

  searchDebounced = debounce(this.search, 500)

  handleSearch = e => {
    if (e.key === 'Enter') {
      this.search()
    }
  }

  handleSearchChange = search => {
    this.setState({ search })
    this.searchDebounced()
  }

  updateRowFilter = (event, filter) => {
    this.setState({
      rowFilter: filter,
      search: ''
    })
    this.props.getPlantUsers(filter)
  }

  closeSideBar = () => {
    this.setState({ sidebarOpen: false })
    this.resetState()
  }

  openSideBar = () => {
    this.setState({ sidebarOpen: true })
  }

  resetEmailState = () => {
    this.setState({
      invalidEmail: false,
      userAlreadyMember: false,
      existingUser: null
    })
  }

  setEmail = (email) => {
    const { getUserByEmail, currentPlant } = this.props
    email = email.trim()

    this.setState({ email })
    stopSearching()
    this.resetEmailState()

    if (email) {
      if (!validator.isEmail(email)) {
        this.setState({ invalidEmail: true })
      } else {
        this.setState({ checkingExistingUser: true })
        startSearching(500, async () => {
          let user = await getUserByEmail(email)
          this.setState({ checkingExistingUser: false })
          if (user) {
            if (find(get(user, 'plants', []), { plant: get(currentPlant, '_id') })) {
              this.setState({ userAlreadyMember: true })
            } else {
              this.setState({ existingUser: user })
            }
          }
        })
      }
    }
  }

  createUser = async () => {
    const { email, role, existingUser } = this.state
    const { currentPlant } = this.props
    const payload = [
      existingUser ? null : email,
      get(existingUser, '_id', null),
      role,
      currentPlant._id
    ]

    this.setState({ submitting: true })
    await this.props.createUser(...payload)
    this.props.getPlantUsers(this.state.rowFilter)
    this.resetState()
  }

  render() {
    const { users, loading, currentPlant, classes, auth } = this.props
    const { search, rowFilter, sidebarOpen, deleteUser, existingUser, checkingExistingUser, userAlreadyMember, submitting } = this.state

    return (
      <>
        <SubToolBarLayout text='Users'>
          <Grid container
            alignItems='center'
            justify='flex-end'
            spacing={2}>
            {!loading &&
              <Grid item>
                <Grid container
                  alignItems="center">
                  <Icon color='disabled'>
                    people</Icon>
                  <Grid item
                    className="fg-gray text-14 medium mx-6">
                    {`${users.length} Users`}
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item>
              <FuseIconButton icon='add'
                iconClassName='text-20'
                className='mr-0 btn btn_sm'
                variant='fill'
                onClick={this.openSideBar}>
                Add New</FuseIconButton>
            </Grid>
          </Grid>
        </SubToolBarLayout>
        <div className='p-24'>
          <ToolBar rowFilter={rowFilter}
            updateRowFilter={this.updateRowFilter}
            openSideBar={this.openSideBar}
            closeOutbounds={() => { }}
            handleSearchChange={this.handleSearchChange}
            search={search}
          />
          <Table className='users-list custom-table'>
            <TableHead className='table-head'>
              <TableRow className='table-row'>
                <TableCell className='table-cell'></TableCell>
                <TableCell className='table-cell'>Name</TableCell>
                <TableCell className='table-cell'>Username</TableCell>
                <TableCell className='table-cell'>Role</TableCell>
                <TableCell className='table-cell'>Email and Phone Number</TableCell>
                <TableCell className='table-cell'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody className='table-body'>
              {loading &&
                [1, 2, 3].map(loadRow => {
                  return (
                    <TableRow key={loadRow}
                      className='table-row'>
                      {[1, 2, 3, 4, 5, 6].map(loadCol => {
                        return (
                          <TableCell key={loadCol}
                            className='table-cell'>
                            <Skeleton width={[1, 6].includes(loadCol) ? 14 : 60} />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })
              }

              {(!loading && (users && users.length === 0)) &&
                <TableRow className='table-row'>
                  <TableCell colSpan={6} className='no-records' />
                </TableRow>
              }

              {users && users.map(user => (
                <TableRow key={user._id}
                  className='table-row'
                  onClick={event => this.handleItemClick(event, user._id)}
                  hover>
                  <TableCell style={{ width: '10px' }}
                    className='table-cell'>
                    <div className={
                      user.status === 'active'
                        ? 'user-active'
                        : user.status === 'invited'
                          ? 'user-invited'
                          : 'user-inactive'
                    }></div>
                  </TableCell>
                  <TableCell className='table-cell'>
                    {user.firstname}&nbsp;{user.lastname}
                  </TableCell>
                  <TableCell className='table-cell'>
                    {user.username}
                  </TableCell>
                  <TableCell className='table-cell'>
                    {user.plants.find(x => x.plant === currentPlant._id).role}
                  </TableCell>
                  <TableCell className='table-cell'>
                    {user.email}&nbsp;
                    {user.phone && user.phone.phoneNumber && (
                      <span className='user-phone'>
                        <FiberManualRecordIcon style={{ fontSize: 6 }} />
                        {user.phone.areaCode ? ` ${user.phone.areaCode}` : ''}{' '}
                        {user.phone.phoneNumber}
                      </span>
                    )}
                  </TableCell>
                  <TableCell className='table-cell'>
                    <Grid container
                      alignItems='center'
                      justify='flex-end'>
                      {auth.info.email !== user.email && (
                        <img src='assets/images/icons/Remove-plant-red.png'
                          className={classes.action}
                          onClick={e => this.promptDelete(e, user)}
                          alt='remove-from-plant' />
                      )}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        <CreateUser closeSideBar={this.closeSideBar}
          sidebarOpen={sidebarOpen}
          createUser={this.createUser}
          existingUser={existingUser}
          checkingExistingUser={checkingExistingUser}
          userAlreadyMember={userAlreadyMember}
          email={this.state.email}
          role={this.state.role}
          invalidEmail={this.state.invalidEmail}
          setEmail={this.setEmail}
          setRole={role => this.setState({ role })}
          submitting={submitting} />

        {deleteUser &&
          <DeleteUser deleteUser={this.state.deleteUser}
            setDeleteUser={user => this.setState({ deleteUser: user })}
            handleDelete={this.handleDelete} />
        }
      </>
    )
  }
}

const styles = theme => ({
  usersIcon: {
    minWidth: '10px',
    marginRight: '5px'
  },
  usersCountText: {
    color: 'rgba(0, 0, 0, 0.46)',
    marginBottom: '0'
  },
  usersCount: {
    marginBottom: '0'
  },
  action: {
    cursor: 'pointer'
  }
})

const mapStateToProps = ({ users: { users, loading }, welcome: { currentPlant }, auth }) => ({
  auth,
  users,
  loading,
  currentPlant
})

const mapDispatchToProps = dispatch => ({
  getPlantUsers: (status, search) => dispatch(UserActions.getPlantUsers(status, search)),
  handleDelete: (user_id, plant_id) => dispatch(UserActions.deleteUserFromPlant(user_id, plant_id)),
  createUser: async (email, user_id, role, plant_id) => dispatch(UserActions.createUser(email, user_id, role, plant_id)),
  getUserByEmail: email => dispatch(UserActions.getUserByEmail(email))
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Users))
