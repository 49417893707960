import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody
} from './'
import {
  withStyles,
  Grid,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core'
import { fileUploadInfo } from "../localization"
import { get } from 'lodash'
import {
  getErpDocuments,
  attachErpDocuments
} from '../api'
import * as CoreActions from '../store/actions'
import AutoComplete from './Inputs/AutoComplete'

const AttachOpenERPDialog = (props) => {
  const {
    open,
    shipmentId,
    allowedDocumentTypes,
    onConfirm,
    handleClose,
    classes
  } = props
  const [documentTypesOptions, setDocumentTypesOptions] = useState([])
  const [erpDocumentTypesOptions, setErpDocumentTypesOptions] = useState([])
  const [documentType, setDocumentType] = useState('')
  const [erpDocumentType, setErpDocumentType] = useState('')

  useEffect(() => {
    setDocumentType('')
    getAllErpDocumentTypeOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setDocumentTypesOptions(allowedDocumentTypes
      .map(type => {
        return {
          value: type,
          label: get(fileUploadInfo, type),
        }
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedDocumentTypes])

  const getAllErpDocumentTypeOptions = async () => {
    const { data } = await getErpDocuments()
    setErpDocumentTypesOptions(get(data, 'data', []))
  }

  const handleAttach = async () => {
    const { showMessage } = props
    try {
      const { ok, status, data } = await attachErpDocuments(shipmentId, documentType, erpDocumentType)
      if (!ok) {
        throw new Error(data.message)
      }
      showMessage({ status, data })
      onConfirm()
    } catch (e) {
      showMessage(e)
    }
  }

  return (
    <FuseDialog open={open} wSM>
      <FuseDialogHeader text="Attach Open ERP Document"
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container
          alignItems="center"
          className="p-20"
          spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className="text-14 medium mb-4">
                Select Document Types</Grid>
              <Grid item xs={12}>
                <Select value={documentType}
                  onChange={e => {
                    setDocumentType(e.target.value)
                  }}
                  placeholder="Select document types..."
                  className={classes.input}
                  fullWidth>
                  {documentTypesOptions.map((option, optionIndex) =>
                    <MenuItem key={optionIndex} value={option.value}>
                      {option.label}
                    </MenuItem>
                  )}
                </Select>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className="text-14 medium mb-4">
                Select Open ERP Document</Grid>
              <Grid item xs={12} className="text-14">
                <AutoComplete value={erpDocumentType}
                  options={erpDocumentTypesOptions.map(item => {
                    return {
                      value: get(item, 'docStoreId'),
                      label: `${get(item, 'Document.identifier.type')} (${get(item, 'Document.identifier.value')})`
                    }
                  })}
                  onChange={(value) => setErpDocumentType(value)} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container
              spacing={2}>
              <Grid item xs={6}>
                <Button className="btn btn_sm btn_green"
                  onClick={handleAttach}
                  disabled={documentType === '' || erpDocumentType === ''}
                  fullWidth>Attach</Button>
              </Grid>
              <Grid item xs={6}>
                <Button className="btn btn_sm btn_light_red"
                  onClick={handleClose}
                  fullWidth>Close</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

const styles = theme => ({
  input: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14
  },
  autoCompleteOptions: {
    position: 'absolute',
    width: '100%',
    maxHeight: 200,
    zIndex: 1
  }
})

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  showMessage: (payload) => dispatch(CoreActions.showMessage(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AttachOpenERPDialog))
