import React, { useState, useEffect } from 'react'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  InfoToolTip
} from '../../core/components'
import { Grid, Icon, CircularProgress, Typography } from "@material-ui/core";
import { fileUploadInfo } from "../../core/localization";
import { zipFiles, formatTimestamp } from "../../core/helpers";
import { get, groupBy } from "lodash";
import { getShipmentDocument } from 'core/api'
import InfoIcon from '@material-ui/icons/Info';

const ReportInfo = ({ reports, email }) => {
  const groupedReports = groupBy(reports, 'email')
  const requestedReports = get(groupedReports, email, [])

  if (requestedReports.length > 0) {
    return (
      <InfoToolTip title={<Grid container>
        {requestedReports.map((i, iIndex) => {
          return (
            <Grid item xs={12} key={iIndex}>
              <Grid container spacing={2} alignItems="center" justify="space-between">
                <Grid item className="uppercase">{get(i, 'event')}</Grid>
                <Grid item>{formatTimestamp(get(i, 'timestamp'), 'date-time')}</Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>}>
        <InfoIcon className="text-18 fg-gray cursor-pointer ml-6" />
      </InfoToolTip>
    )
  } else {
    return <React.Fragment />
  }
}

const ViewMessageDialog = props => {
  const {
    subject,
    messageBody,
    recipients,
    documentTypes,
    clearViewMessage,
    reports
  } = props
  const [open, setOpen] = useState(false)
  const [downloading, setDownloading] = useState(false)

  useEffect(() => {
    if (messageBody) {
      setOpen(true)
      setDownloading(false)
    } else {
      setOpen(false)
    }
  }, [messageBody])

  const downloadFiles = async () => {
    setDownloading(true)
    const { documents } = props
    const files = {}
    if (documents) {
      for (let index = 0; index < documents.length; index++) {
        const document = documents[index];
        const shipmentId = get(document, 'shipment')
        const documentId = get(document, '_id')

        if (shipmentId && documentId) {
          const data = await getShipmentDocument(shipmentId, documentId)

          if (get(data, 'status') === 200) {
            files[`${document.type}_${document.originalName}`] = get(data, 'data')
          }
        }
      }
    }

    await zipFiles(files, null, subject.replace(' ', '_'))
    setDownloading(false)
  }

  return (
    <FuseDialog open={open} wMD>
      <FuseDialogHeader text={(subject || 'View Message')}
        handleClose={() => {
          setOpen(false)
          clearViewMessage()
        }} />
      <FuseDialogBody>
        <Grid container
          spacing={2}
          className="p-20 pre-alert-log">

          {/* Recipients */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} className="semi-bold">
                Recipients</Grid>
              <Grid item xs={12}>
                <Grid container className="overflow-auto">
                  {get(recipients, 'emailAddresses', []).map((item, itemIndex) => {
                    return (
                      <Grid item key={itemIndex}>
                        <Grid container alignItems="center" className="fg-gray">
                          {itemIndex > 0 && <label>,&nbsp;</label> }
                          <Typography className="medium fg-gray">{item}</Typography>
                          <ReportInfo reports={reports} email={item} />
                        </Grid>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End of Recipients */}

          {/* Message */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} className="semi-bold">Message</Grid>
              <Grid item xs={12} className="overflow-hidden">
                <div className="p-12 bg-gray rounded-4 message-container"
                  dangerouslySetInnerHTML={{ __html: (messageBody || '') }}></div>
              </Grid>
            </Grid>
          </Grid>
          {/* End of Message */}

          {/* Document Types */}
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}
                className="semi-bold">
                <Grid container
                  spacing={3}
                  alignItems="center">
                  <Grid item>Attached Documents</Grid>
                  {!downloading &&
                    <Icon className="fg-blue cursor-pointer text-28"
                      title="Download attachments"
                      onClick={downloadFiles}>cloud_download</Icon>
                  }
                  {downloading &&
                    <CircularProgress size={20} className="fg-blue" />
                  }
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {(documentTypes || [])
                    .map((item, itemIndex) => {
                      return (
                        <Grid item key={itemIndex}>
                          <Grid container alignItems="center"
                            className="bg-gray rounded-4 py-4 px-8">
                            <Grid item className="text-14 medium">
                              {get(fileUploadInfo, item)}
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* End of  Document Types */}

        </Grid>
      </FuseDialogBody>
    </FuseDialog >
  )
}

export default ViewMessageDialog
