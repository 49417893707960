import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { capitalize } from 'lodash'
import {
  LoadingButton,
  FuseFileUploader,
  FuseSideBarActions,
  FuseSideBar,
  FuseSideBarBody,
  FuseSideBarHeader
} from '../../core/components'

class UploadDialog extends Component {
  state = {
    documents: {},
    arrayOfCall: [],
    count: 0,
    loading: false
  }
  uploaderRefs = []

  handleUpdateDocument = type => documents => {
    this.setState({
      documents: {
        ...this.state.documents,
        [type]: documents
      }
    })
  }

  uploadFile = () => {
    const { documents } = this.state
    const arrayOfCall = []

    Object.keys(documents).forEach(type => {
      Object.keys(documents[type]).forEach(fileName => {
        const data = new FormData()
        data.append(type, documents[type][fileName])

        this.props.upload(type, { file: data }, this.handleResponse, () => this.handleResponse(true), true)
      })

      arrayOfCall.push(`response${capitalize(type)}`)
    })
    this.setState({
      arrayOfCall,
      loading: true
    })
  }

  handleResponse = (isFailed = false) => {
    if (isFailed) {
      this.setState({ loading: false })
    } else {
      const { arrayOfCall, count } = this.state
      const { handleClose, getOutbounds, handleImportScheduled } = this.props
      const updateCount = count + 1

      this.setState({ count: updateCount })
      if (updateCount === arrayOfCall.length) {
        this.setState({
          documents: {},
          count: 0,
          loading: false
        })
        if (this.uploaderRef) {
          this.uploaderRef.resetState()
        }
        handleClose()
        handleImportScheduled()
        getOutbounds()
      }
    }
  }

  render() {
    const { handleClose, open } = this.props
    const { loading } = this.state

    return (
      <FuseSideBar open={open}>
        <FuseSideBarHeader text='Upload Documents' handleClose={handleClose} />
        <FuseSideBarBody>
          <Grid container direction='row' className={'block'}>
            <FuseFileUploader
              allowMultiple
              ref={ref => (this.uploaderRef = ref)}
              fileTitle='Outbound file (csv)'
              type='csv'
              name='outbounds'
              image='assets/images/icons/Document-upload.png'
              required={false}
              subTitle='Upload prepared outbounds csv'
              onSuccess={this.handleUpdateDocument('csv')}
              sampleLink='/assets/files/import-examples/OUTBOUNDS_SAMPLE.csv'
              allowedTypes={".csv"}
            />
          </Grid>
          <br />
          <Grid container direction='row' className={'block'}>
            <FuseFileUploader
              ref={ref => (this.uploaderRef = ref)}
              fileTitle='Outbound file (excel)'
              type='xlsx'
              name='outbounds'
              image='assets/images/icons/Document-upload.png'
              required={false}
              subTitle='Upload outbounds excel exported from SAP'
              onSuccess={this.handleUpdateDocument('xlsx')}
              allowedTypes={`.xls,.xlsx,.xlsm`}
            />
          </Grid>
        </FuseSideBarBody>
        <FuseSideBarActions>
          <LoadingButton className='btn btn_full-space' loading={loading} onClick={this.uploadFile}>
            upload
          </LoadingButton>
        </FuseSideBarActions>
      </FuseSideBar>
    )
  }
}

export default UploadDialog
