import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  withStyles,
  Avatar,
  Slide,
  Fade,
  CircularProgress
} from '@material-ui/core'
import { get, filter } from 'lodash'
import { FuseTextField, LoadingButton } from '../../../core/components'
import { formatTimestamp } from '../../../core/helpers'
import Skeleton from 'react-loading-skeleton'
import AutoComplete from '../../../core/components/Inputs/AutoComplete'
import deepEqual from 'deep-equal'

const baseTextSetting = {
  position: 'end',
  className: 'hidden',
  color: '',
  name: ''
}

const inputStyle = {
  alignItems: 'flex-start',
  padding: 10,
  paddingLeft: 12,
  lineHeight: 'normal',
  minHeight: 80,
  fontFamily: 'IBMPlexSans',
  fontSize: 14,
}

const statusColors = {
  OPEN: 'bg-transparent-green fg-green',
  BLOCKED: 'bg-light-orange fg-orange',
  CLOSED: 'bg-light-red fg-red'
}

const typeColors = {
  NOTICE: 'bg-light-blue fg-blue',
  ISSUE: 'bg-light-orange fg-orange'
}

const multiSelectStyle = {
  control: () => ({
    display: 'flex',
    border: '1px solid #949494',
    borderRadius: '2px',
    padding: '10px',
    minHeight: '31px',
    maxHeight: 'auto',
  }),
  placeholder: (styles) => ({
    ...styles,
    marginLeft: 0,
    marginRight: 0,
  }),
  dropdownIndicator: (styles) => ({
    ...styles
  }),
  multiValue: (styles) => ({
    ...styles,
    display: 'flex'
  }),
  menuList: (styles) => ({
    ...styles,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 120,
  })
}

const TopicDetails = ({
  classes,
  topic,
  userInfo,
  openTopic,
  closeTopic,
  addTopicsComment,
  getTopicDetails,
  plants,
  updateTopics }) => {
  const [comment, setComment] = useState('')
  const [requesting, setRequesting] = useState(false)
  const [accessPlants, setAccessPlants] = useState([])

  const addComment = (isClose = false) => {
    const { _id } = topic
    const params = {
      content: {
        text: comment
      }
    }
    setRequesting(isClose ? 'close' : 'comment')
    addTopicsComment(_id, params, async () => {
      if (isClose) {
        await closeTopic(_id)
      }
      await getTopicDetails(_id)
      setComment('')
      setRequesting(false)
    }, () => {
      setRequesting(false)
    })
  }

  const handleAction = (e, action) => {
    const { _id } = topic
    e.preventDefault()
    setRequesting(action)
    if (action === 'open') {
      openTopic(_id, async () => {
        await getTopicDetails(_id)
        setRequesting(false)
      }, () => {
        setRequesting(false)
      })
    } else if (action === 'close') {
      closeTopic(_id, async () => {
        await getTopicDetails(_id)
        setRequesting(false)
      }, () => {
        setRequesting(false)
      })
    }
  }

  const handleUpdatePlants = () => {
    const { _id } = topic
    setRequesting('update-plant')
    updateTopics(_id, {
      ...topic,
      access: {
        ...get(topic, 'access', {}),
        plants: accessPlants
      },
      references: {
        ...get(topic, 'references', {}),
        shipments: get(topic, 'references.shipments', []).map(i => i._id)
      }
    }, async () => {
      await getTopicDetails(_id)
      setRequesting(false)
    }, () => {
      setRequesting(false)
    })
  }

  useEffect(() => setComment(""), [])

  useEffect(() => setAccessPlants(get(topic, 'access.plants', [])), [topic])

  return (
    <Grid container spacing={4}>

      <Grid item xs={12}>
        {get(topic, 'loading', false) === true &&
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}><Skeleton height={14} /></Grid>
                <Grid item xs={12}><Skeleton height={12} count={2} /></Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}><Skeleton width={60} height={18} /></Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} alignItems="center"
                className="fg-gray">
                <Grid item><Skeleton width={60} height={18} /></Grid>
                <Grid item><Skeleton width={200} /></Grid>
                <Grid item>&bull;</Grid>
                <Grid item><Skeleton width={80} /></Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        {get(topic, 'loading', false) === false &&
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className='bold'>
                {get(topic, 'title', 'No Title')}</Typography>
              <Typography className='fg-gray regular text-12'>
                {get(topic, 'description.text', 'No Description')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Typography className={'bold uppercase px-8 rounded text-12 ' +
                    get(typeColors, get(topic, 'type'), '')}>
                    {get(topic, 'type')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container
                spacing={1}
                alignItems="center">
                <Grid item>
                  <Typography className={'bold uppercase px-8 rounded text-12 ' +
                    get(statusColors, get(topic, 'status'), '')}>
                    {get(topic, 'status')}</Typography>
                </Grid>
                <Grid item>
                  <Grid container alignItems="center" spacing={1}
                    className="regular text-12 fg-gray">
                    <Grid item>
                      <Typography className="regular text-12 fg-gray">
                        <label className="medium">{get(topic, 'owner.user.firstname')}</label> opened this on {formatTimestamp(get(topic, 'createdAt'), 'date-time')}</Typography>
                    </Grid>
                    <Grid item>&bull;</Grid>
                    <Grid item>
                      {filter(get(topic, 'events', []), { type: 'COMMENT' }).length}&nbsp;Comments
                  </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      </Grid>

      <Grid item xs={9}>
        <Grid container spacing={3}
          className="text-14">
          {get(topic, 'events', []).map((event, eventIndex) => {
            const user = get(event, 'owner.user', {})

            return (
              <Grid item xs={12}
                key={eventIndex}>
                <Grid container spacing={2}>
                  <Grid item className="min-w-56">
                    {get(event, 'type') === 'COMMENT' &&
                      <Avatar className={classes.avatar}>
                        {`${get(user, 'firstname[0]', '')}${get(user, 'lastname[0]', '')}` || '!'}
                      </Avatar>
                    }
                  </Grid>
                  <Grid item xs>
                    <Grid container
                      className="relative">
                      {(get(event, 'type') === 'COMMENT' &&
                        eventIndex > 0) &&
                        <span className={classes.commentRowConnector} />
                      }
                      {get(event, 'type') === 'COMMENT' &&
                        <Grid item xs={12}
                          className="p-8 overflow-hidden border-solid border-1 border-gray rounded">
                          <Grid container spacing={2}>
                            <Grid item xs={12}
                              className="bg-gray">
                              <label className="medium">{get(user, 'firstname')}</label> commented on {formatTimestamp(get(event, 'createdAt'), 'date-time')}
                            </Grid>
                            <Grid item xs={12}>
                              {get(event, 'content.text')}
                            </Grid>
                          </Grid>
                        </Grid>
                      }

                      {get(event, 'type') === 'UPDATE' &&
                        <Grid item xs={12}>
                          <Grid container
                            alignItems="center"
                            spacing={1}
                            className="relative">
                            {eventIndex > 0 && <span className={classes.updateRowConnector} />}
                            <Grid item>
                              <Avatar className={classes.smallAvatar}>
                                {`${get(user, 'firstname[0]', '')}${get(user, 'lastname[0]', '')}` || '!'}
                              </Avatar>
                            </Grid>
                            <Grid item xs>
                              <label className="medium">{get(user, 'firstname', '')}</label> {get(event, 'content.text')} on {formatTimestamp(get(event, 'createdAt'), 'date-time')}
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <Grid container className="rounded border-1 border-gray">
          <Grid item xs={12} className="px-8 py-4 bg-gray medium">Assigned Plants</Grid>
          <Grid item xs={12} className="p-8">
            <AutoComplete className="text-14"
              options={plants.map(i => ({ value: i._id, label: i.name }))}
              value={accessPlants}
              onChange={plants => {
                setAccessPlants(plants)
              }}
              placeholder="Select plants..."
              customStyles={multiSelectStyle}
              isMulti
            />
          </Grid>
          {!deepEqual(get(topic, 'access.plants', []), accessPlants) &&
            <Grid item xs={12} className="px-8 py-4 bg-gray medium uppercase overflow-hidden">
              {!requesting &&
                <Slide in={!deepEqual(get(topic, 'access.plants'), accessPlants)} direction="down">
                  <Grid container spacing={1} alignItems="center" justify="flex-end" className="medium uppercase">
                    <Grid item className="fg-red cursor-pointer"
                      onClick={() => setAccessPlants(get(topic, 'access.plants', []))}>reset</Grid>
                    <Grid item className="fg-gray">&bull;</Grid>
                    <Grid item className="fg-green cursor-pointer"
                      onClick={handleUpdatePlants}>update</Grid>
                  </Grid>
                </Slide>
              }
              {requesting === 'update-plant' &&
                <Fade in={requesting === 'update-plant'}>
                  <Grid container justify="flex-end">
                    <CircularProgress size={18} className="fg-green" />
                  </Grid>
                </Fade>
              }
            </Grid>
          }
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {get(topic, 'status') === 'OPEN' &&
          <Grid container spacing={1}>
            <Grid item xs={12} className="fg-gray text-14 medium">
              Add your comment</Grid>
            <Grid item className="h-56 w-56">
              <Avatar className={classes.avatar}>
                {`${get(userInfo, 'firstname[0]', '')}${get(userInfo, 'lastname[0]', '')}` || '!'}
              </Avatar>
            </Grid>
            <Grid item xs>
              <Grid container spacing={1}>
                <Grid item xs={12}
                  className="p-6">
                  <FuseTextField type='text'
                    icon={baseTextSetting}
                    name='description'
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    inputStyles={inputStyle}
                    variant="outlined"
                    multiline
                    required
                    fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Grid container
                    justify="flex-end"
                    spacing={1}>
                    <Grid item>
                      {get(topic, 'status') === 'OPEN' &&
                        <LoadingButton className="btn btn_sm btn_light_red min-w-128"
                          disabled={!!requesting}
                          loading={requesting === 'close'}
                          onClick={(e) => {
                            if (comment) addComment(true)
                            else handleAction(e, 'close')
                          }}
                          fullWidth>{comment ? 'comment and close' : 'close'}</LoadingButton>
                      }
                    </Grid>
                    <Grid item>
                      <LoadingButton className="btn btn_sm min-w-128"
                        disabled={!comment || !!requesting}
                        loading={requesting === 'comment'}
                        onClick={() => addComment()}
                        fullWidth>comment</LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
        {get(topic, 'status') === 'CLOSED' &&
          <Grid container justify="flex-end">
            <Grid item>
              <LoadingButton className="btn btn_sm btn_light_green min-w-128"
                disabled={!!requesting}
                loading={requesting === 'open'}
                onClick={(e) => handleAction(e, 'open')}
                fullWidth>reopen</LoadingButton>
            </Grid>
          </Grid>
        }
      </Grid>

    </Grid>
  )
}

const styles = theme => ({
  avatar: {
    fontFamily: 'IBMPlexSans-SemiBold',
    fontSize: 16,
    textTransform: 'uppercase'
  },
  smallAvatar: {
    height: 24,
    width: 24,
    fontFamily: 'IBMPlexSans',
    fontSize: 10,
  },
  commentRowConnector: {
    position: 'absolute',
    top: '-22px',
    left: '11px',
    display: 'flex',
    height: 20,
    width: 2,
    backgroundColor: '#EEEEEE'
  },
  updateRowConnector: {
    position: 'absolute',
    top: '-18px',
    left: '15px',
    display: 'flex',
    height: 20,
    width: 2,
    backgroundColor: '#EEEEEE'
  }
})

export default withStyles(styles)(TopicDetails)
