import { withStyles, Tooltip } from '@material-ui/core'

const InfoTooltip = withStyles(theme => ({
  tooltip: {
    fontFamily: 'IBMPlexSans',
    fontSize: 11,
    maxWidth: 220,
  },
}))(Tooltip);

export default InfoTooltip
