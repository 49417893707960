import React from 'react'
import {
  Grid,
  Button,
  TextField,
} from '@material-ui/core'
import { filter, get } from 'lodash'
import classNames from 'classnames'
import { bookingInfoForm, general } from '../../core/localization'
import { formatTimestamp } from '../../core/helpers'
import BookingWizardSummary from '../../manage-booking/components/ProposeBookingSummary'

const DisabledBookingForm = (props) => {
  const { go, data: { shipment } } = props

  return (
    <div>
      <Grid container direction='row'>
        <Grid item sm={12} md={8} className={'booking-forms'}>

          {/* Shipment Type */}
          <Grid container direction='row'
            className={'field-control'}>
            <Grid item sm={12} md={12}
              className='flex items-center mb-12'>
              <label className={'field-label'}>
                {bookingInfoForm.shipmentType.label}
              </label>
            </Grid>
            <Grid item sm={12} md={12}
              className='flex items-center justify-between'>
              <Button className={classNames(
                'flex justify-around option-button',
                { active: get(shipment, 'type') === 'FCL' })}>
                <img src='assets/images/icons/fcl-icon.svg'
                  alt='fcl' />
                {bookingInfoForm.shipmentType.options.fcl}
              </Button>
              <Button className={classNames(
                'flex justify-around option-button',
                { active: get(shipment, 'type') === 'LCL' })}>
                <img src='assets/images/icons/lcl-icon.svg'
                  alt='LCL' />
                {bookingInfoForm.shipmentType.options.lcl}
              </Button>
            </Grid>
          </Grid>
          {/* End of Shipment Type */}

          {/* Containers and schedule */}
          {get(shipment, 'type') === 'FCL' && (
            <Grid container direction='row' className={'field-control'}>
              <Grid item sm={12} md={6} className='flex items-center mb-12'>
                <label className={'field-label'}>Containers and schedule</label>
              </Grid>
              <Grid item sm={12} md={6} className='flex items-center justify-end'>
                <label className={'field-instruction'}>
                  <span className="medium">20'ST - {filter(
                    get(
                      shipment,
                      'containers',
                      []
                    ),
                    { type: 'C20GP' }).length
                  }</span>
                  <span className="px-6">&bull;</span>
                  <span className="medium">40'ST - {filter(
                    get(
                      shipment,
                      'containers',
                      []
                    ),
                    { type: 'C40GP' }).length
                  }</span>
                  <span className="px-6">&bull;</span>
                  <span className="medium">40'HC - {filter(
                    get(
                      shipment,
                      'containers',
                      []
                    ),
                    { type: 'C40HC' }).length
                  }</span>
                </label>
              </Grid>

              <Grid container
                direction='row'
                className='stepper'>
                {
                  get(shipment, 'containers', []).map((container, containerIndex) => {
                    const containerType = get(container, 'type')
                    const equipmentNo = get(container, 'equipmentNo')

                    return (
                      <Grid container
                        key={containerIndex}
                        direction='row'
                        className='step pb-24'>
                        <Grid container
                          direction='row'
                          className='step-header'>
                          <span className='step-icon'>
                            <img src="assets/images/icons/timeline-step/storage-facility-blue.svg"
                              alt='container-booking' />
                          </span>
                          <Grid item sm={12} md={6}
                            className='flex items-center'>
                            <label className='field-sub-label'>
                              <span>{general.container[containerType]}</span>
                              {equipmentNo &&
                                <>
                                  <span className="px-6">&bull;</span>
                                  <span>{equipmentNo}</span>
                                </>
                              }
                            </label>
                          </Grid>
                          <Grid item sm={12} md={6}
                            className='flex items-center justify-end'></Grid>
                        </Grid>

                        <Grid container direction='row' className='step-content'>
                          <Grid item sm={12} className='pb-16'>
                            <TextField name='street'
                              className='theme-text-field'
                              label="Drop container at warehouse"
                              value={get(
                                container,
                                'referenceEvents.truckInToSWHWithEmptyContainer.location.street',
                                ''
                              )}
                              fullWidth={true}
                              InputLabelProps={{ shrink: true }}
                              disabled />
                          </Grid>
                          <Grid item sm={12} md={7} className='pr-8'>
                            <TextField name='floorUnitNumber'
                              className='theme-text-field'
                              value={get(
                                container,
                                'referenceEvents.truckInToSWHWithEmptyContainer.location.floorUnitNumber',
                                ''
                              )}
                              label="Additional address"
                              fullWidth={true}
                              InputLabelProps={{ shrink: true }}
                              disabled />
                          </Grid>
                          <Grid item sm={12} md={5} className='pl-6'>
                            <TextField name='floorUnitNumber'
                              className='theme-text-field'
                              value={formatTimestamp(
                                get(
                                  container,
                                  'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt',
                                  ''
                                ),
                                'date-time'
                              )}
                              label="Date & time"
                              fullWidth={true}
                              InputLabelProps={{ shrink: true }}
                              disabled />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          )}
          {/* End of Containers and schedule */}

          {/* Schedules */}
          {get(shipment, 'type') === 'LCL' &&
            <Grid container direction='row' className={'field-control'}>
              <Grid item sm={12} md={6} className='flex items-center'>
                <label className={'field-label'}>
                  Estimated schedule</label>
              </Grid>
              <Grid item sm={12} md={6} className='flex items-center justify-end'></Grid>
              <Grid container
                direction='row'
                className='stepper'>
                <Grid container
                  direction='row'
                  className='step pb-24'>
                  <Grid container
                    direction='row'
                    className='step-header'>
                    <span className='step-icon'>
                      <img src="assets/images/icons/wizard-arrow-down-circle.png"
                        alt='down' />
                    </span>
                    <Grid item sm={12} md={6}
                      className='flex items-center'>
                      <label className='field-sub-label'>
                        Truck in for cargo pickup</label>
                    </Grid>
                    <Grid item sm={12} md={6}
                      className='flex items-center justify-end'>
                      <label className="text-12 regular fg-gray">
                        Truck in to pickup the shipment</label>
                    </Grid>
                  </Grid>

                  <Grid container direction='row' className='step-content'>
                    <Grid item sm={12} className='pb-16'>
                      <TextField name='street'
                        className='theme-text-field'
                        label="Truck in with empty container place"
                        value={get(
                          shipment,
                          'referenceEvents.truckInForCargoPickup.location.street',
                          ''
                        )}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        disabled />
                    </Grid>
                    <Grid item sm={12} md={7} className='pr-8'>
                      <TextField name='floorUnitNumber'
                        className='theme-text-field'
                        value={get(
                          shipment,
                          'referenceEvents.truckInForCargoPickup.location.floorUnitNumber',
                          ''
                        )}
                        label="Additional address"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        disabled />
                    </Grid>
                    <Grid item sm={12} md={5} className='pl-6'>
                      <TextField name='floorUnitNumber'
                        className='theme-text-field'
                        value={formatTimestamp(
                          get(
                            shipment,
                            'referenceEvents.truckInForCargoPickup.expectedAt',
                            ''
                          ),
                          'date-time'
                        )}
                        label="Date & time"
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          }
          {/* End of Schedules */}

          {/* Groupage date */}
          <Grid container direction='row'
            className='field-control'
            alignItems='center' spacing={2}>
            <Grid item sm={12} md={6}>
              <label className='field-label'>Groupage date</label>
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField className='theme-text-field'
                name="groupageDate"
                value={formatTimestamp(get(shipment, 'groupageDate', ''))}
                fullWidth={true}
                InputLabelProps={{ shrink: true }}
                disabled />
            </Grid>
          </Grid>
          {/* END Groupage date */}

          {/* Booking Info actions */}
          <Grid container justify='space-between' className="pt-20">
            <Grid item sm={12} md={6}>
              <Button className='btn w-full'
                onClick={() => go(1)}>
                {general.next}
              </Button>
            </Grid>
          </Grid>
          {/* End of Booking Info actions */}
        </Grid>

        <Grid item sm={12} md={4}>
          <BookingWizardSummary shipment={shipment} />
        </Grid>
      </Grid>
    </div>
  )
}

export default DisabledBookingForm
