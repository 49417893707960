import ActionTypes from './types'

const initialState = {
  trackData: {}
}

const completedReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOAD_SHIPMENT_TRACK: {
      return {
        trackData: action.payload
      }
    }
    default:
      return state
  }
}

export default completedReducer
