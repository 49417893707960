import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { persistReducer } from 'redux-persist'
import { loadingBarReducer } from 'react-redux-loading-bar'
import storage from 'redux-persist/lib/storage'

import coreReducer from './store/reducers'
import authReducer from 'auth/store/reducers'
import welcomeReducer from 'welcome/store/reducers'
import scheduleReducer from 'schedule/store/reducers'
import inTransitReducer from 'in-transit/store/reducers'
import inboundReducer from 'inbound/store/reducers'
import exceptionsReducer from 'exceptions/store/reducers'
import bookingReducer from 'manage-booking/store/reducers'
import mapViewReducer from 'map-view/store/reducers'
import completedReducer from 'completed/store/reducers'
import cancelledReducer from 'cancelled/store/reducers'
import usersReducer from 'users/store/reducers'
import closedReducer from 'closed/store/reducers'
import shipmentDetailsReducer from '../shipment-details/store/reducers'
import trackerReducer from '../tracker/store/reducers'
import settingsReducer from '../settings/store/reducers'
import overviewReducer from '../overview/store/reducers'
import analyticsReducer from '../analytics/store/reducers';
import incomingReducer from '../manage-incoming/store/reducers';
import incomingScheduleReducer from '../inbound-schedule/store/reducers';
import noticeBoardReducer from '../notice-board/store/reducers';
import emailTrackerReducers from '../email-tracker/store/reducers';
import decoupledInboundReducers from '../decoupled-inbound/store/reducers';
import erpTransmissionsReducers from '../erp-transmissions/store/reducers';
import deliveringReducer from '../delivering/store/reducers';
import deliveredReducer from '../delivered/store/reducers';
import tasksReducers from '../tasks/store/reducers';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'welcome']
}

export const createRootReducer = history =>
  persistReducer(
    rootPersistConfig,
    combineReducers({
      core: coreReducer,
      auth: authReducer,
      welcome: welcomeReducer,
      schedule: scheduleReducer,
      inTransit: inTransitReducer,
      incoming: incomingReducer,
      incomingSchedule: incomingScheduleReducer,
      inbound: inboundReducer,
      exceptions: exceptionsReducer,
      booking: bookingReducer,
      shipmentDetails: shipmentDetailsReducer,
      trackerReducer: trackerReducer,
      settingsReducer: settingsReducer,
      overviewReducer: overviewReducer,
      loadingBar: loadingBarReducer,
      mapView: mapViewReducer,
      completed: completedReducer,
      cancelled: cancelledReducer,
      users: usersReducer,
      closed: closedReducer,
      analytics: analyticsReducer,
      noticeBoard: noticeBoardReducer,
      emailTracker: emailTrackerReducers,
      decoupledInbound: decoupledInboundReducers,
      erpTransmissions: erpTransmissionsReducers,
      delivering: deliveringReducer,
      delivered: deliveredReducer,
      tasks: tasksReducers,
      router: connectRouter(history)
    })
  )
