import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import { get, orderBy } from "lodash";
import * as API from '../../core/api'
import { checkPagination } from '../../core/helpers'

const loadTasks = (loading = false, status, payload) => {
  const actions = {
    incomplete: ActionTypes.LOAD_INCOMPLETE_TASKS,
    blocked: ActionTypes.LOAD_BLOCKED_TASKS,
    completed: ActionTypes.LOAD_COMPLETED_TASKS,
  }
  return { type: actions[status], loading, payload }
}

const loadingMoreTasks = (status) => {
  const actions = {
    incomplete: ActionTypes.LOADING_MORE_INCOMPLETE_TASKS,
    blocked: ActionTypes.LOADING_MORE_BLOCKED_TASKS,
    completed: ActionTypes.LOADING_MORE_COMPLETED_TASKS,
  }
  return { type: actions[status] }
}

const loadMoreTasks = (status, payload) => {
  const actions = {
    incomplete: ActionTypes.LOAD_MORE_INCOMPLETE_TASKS,
    blocked: ActionTypes.LOAD_MORE_BLOCKED_TASKS,
    completed: ActionTypes.LOAD_MORE_COMPLETED_TASKS,
  }
  return { type: actions[status], payload }
}

export const getTasks = (isSync = false, pagination, params, success = null, fail = null) => async dispatch => {
  const { status } = params
  const { pageNo } = pagination

  if (pageNo === 1 && !isSync) {
    dispatch(loadTasks(true, status))
  } else {
    dispatch(loadingMoreTasks(status))
  }

  try {
    const { ok, data } = await API.getShipmentTasks({ ...pagination, ...params })

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    if (pageNo === 1) {
      dispatch(loadTasks(false, status, data))
    } else {
      dispatch(loadMoreTasks(status, data))
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getTemplates = (pagination, params, success = null, fail = null) => async dispatch => {
  const { pageNo } = pagination

  try {
    const { ok, data } = await API.getTaskTemplates({ ...pagination, ...params })

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    if (pageNo === 1) {
      dispatch({ type: ActionTypes.LOAD_TEMPLATE_TASKS, payload: data })
    } else {
      dispatch({ type: ActionTypes.LOAD_MORE_TEMPLATE_TASKS, payload: data })
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const createTemplates = (params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.addTaskTemplate(params)

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getShipmentTasksById = (shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getShipmentTasks({ shipmentId })

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_SHIPMENTS_TASKS,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getAllShipments = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getAllShipmentsWithFilter()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_ALL_SHIPMENTS,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const getAllShipmentsLight = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getAllShipmentsLightWithFilter()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_ALL_SHIPMENTS,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const getActionsDictionary = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getControllerDictionary()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_CONTROLLER_DICTIONARY,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const getEventsList = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getEventsListUserSettings()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_ALL_EVENTS,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const getPlantUsers = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getAllUsers()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_ALL_USERS,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const createNewTask = (payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.createShipmentTask(payload)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const markTaskComplete = (taskId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.markShipmentTaskComplete(taskId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getShipmentDetails = (shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getSingleShipment(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_SHIPMENT_DETAILS,
      payload: {
        shipmentId,
        data: get(data, 'data', {})
      }
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getShipmentMeta = (shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getShipmentMeta(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_SHIPMENT_META,
      payload: {
        shipmentId,
        meta: get(data, 'data')
      }
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getExpandShipmentTasks = (shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getSingleShipmentTasks(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_EXPAND_SHIPMENT_TASKS,
      payload: {
        shipmentId,
        shipmentTasks: get(data, 'data', [])
      }
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getShipmentEventWriteList = (shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.getShipmentEventWriteListV1(shipmentId)

    if (status === 403) {
      dispatch({
        type: ActionTypes.LOAD_SHIPMENT_EVENT_WRITE_LIST,
        payload: {
          shipmentId,
          events: [{ invalid: true }]
        }
      })

      if (success) {
        success()
      }
      return
    }

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_SHIPMENT_EVENT_WRITE_LIST,
      payload: {
        shipmentId,
        events: get(data, 'data', [])
      }
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const addShipmentEvents = (shipmentId, eventCode, eventType, payload, success = null, fail = null) => async dispatch => {
  try {

    const { ok, data, status } = await API.addShipmentEvents(shipmentId, eventCode, eventType, payload, true)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const freightPartnerRejectBooking = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.exportFreightPartnerRejectBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const freightPartnerAcceptBooking = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.exportFreightPartnerAcceptBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const logisticsPartnerAcceptBooking = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.exportLogisticsPartnerAcceptBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const resolveFpConflicts = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.resolveFpConflict(shipmentId, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const consigneeMarkAsReceived = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.consigneeCompleteBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const uploadShipmentFile = (shipmentId, type, payload) => async dispatch => {
  try {
    const { ok, data } = await API.uploadShipmentFile(payload, shipmentId, type)

    if (!ok) {
      throw new Error(data.message)
    }

    return true
  } catch (e) {
    dispatch(CoreActions.showMessage(e))

    return false
  }
}

export const assignUser = (taskId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.assignShipmentTaskUser(taskId, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))

    if (fail) {
      fail()
    }
  }
}

export const addDueDate = (taskId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.addShipmentTaskDueDate(taskId, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))

    if (fail) {
      fail()
    }
  }
}

export const changePriority = (taskId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.changePriority(taskId, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))

    if (fail) {
      fail()
    }
  }
}

export const getEventDocList = (params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getEventDocList(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_EVENT_DOC_LIST,
      payload: get(data, 'data', {})
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const searchShipments = (search) => async dispatch => {
  dispatch({ type: ActionTypes.LOADING_FILTER_SEARCH_SHIPMENTS })

  if (search) {
    let multiSearch = search
    if ((multiSearch.search(/ \(/i) > -1) && (multiSearch.search(/\)/i) > -1)) {
      multiSearch = multiSearch.replace(/ \(/i, '__')
      multiSearch = multiSearch.replace(/\)/i, '')
    }
    const { data } = await API.getAllShipmentsLightWithFilter({ 'multi-search': multiSearch })
    const payload = orderBy(get(data, 'data', []), 'updatedAt', 'desc')

    dispatch({
      type: ActionTypes.LOAD_FILTER_SEARCH_SHIPMENTS,
      search,
      total: get(data, 'total', 0),
      payload
    })
  }
}

export const dismissTask = (taskId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.deleteTask(taskId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))

    if (fail) {
      fail()
    }
  }
}

export const updateTaskPostLink = (taskId, postId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.updateTaskPostLink(taskId, { postId })

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))

    if (fail) {
      fail()
    }
  }
}

export const reviewCustomDraft = (shipmentId, note, action, success = null, fail = null) => async dispatch => {
  try {
    let response
    if (action === 'accept') {
      response = await API.approveCustomReview(shipmentId, { note })
    } else {
      response = await API.rejectCustomReview(shipmentId, { note })
    }

    if (!get(response, 'ok')) {
      throw new Error(get(response, 'data.message'))
    }

    dispatch(CoreActions.showMessage({
      status: get(response, 'status'),
      data: get(response, 'data')
    }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const scheduleDelivery = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.scheduleDelivery(shipmentId, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const updateShipmentStatus = (shipmentId, params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.addCustomClearance(shipmentId, params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const resolveConflict = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.resolveShipmentConflict(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const markAsSpot = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.markShipmentAsSpot(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const resolveSpot = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.resolveShipmentSpot(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}
