import React, { useState } from 'react'
import { get, groupBy, filter, pick, omit } from 'lodash'
import {
  Grid,
  Button,
  TextField
} from '@material-ui/core'
import { useMergeState } from '../../core/helpers'
import { LoadingButton } from '../../core/components'
import { general } from '../../core/localization'
import BookingWizardSummary from '../../manage-booking/components/ProposeBookingSummary'

const BookingLaneConflictForm = (props) => {
  const {
    go,
    data: {
      shipment,
      matchedServiceCards
    },
    onChange,
    handleResolveConflict,
    handleMarkAsSpot,
    requesting
  } = props
  const [state, setState] = useMergeState({})
  const [markingAsSpot, setMarkingAsSpot] = useState(false)
  const [spotReason, setSpotReason] = useState('')
  const [hasWarning, setHasWarning] = useState(false)

  const handleSelectChange = (name, containerCard) => {
    const serviceCardSelection = {
      ...omit(state, 'note'),
      [name]: containerCard
    }
    const matchParams = [
      'incoterm',
      'ofp',
      'obl',
      'obc',
      'originPort',
      'destinationPort'
    ]
    const cardKeys = Object.keys(serviceCardSelection)
    var warningCount = 0

    cardKeys.forEach(cardKey => {
      const currentCard = pick(serviceCardSelection[cardKey], matchParams)
      const remainKeys = filter(cardKeys, item => item !== cardKey)

      if (!get(currentCard, 'incoterm') ||
        !get(currentCard, 'ofp.partner.plantData.name') ||
        !get(currentCard, 'obl[0].plantData.name') ||
        !get(currentCard, 'obc[0].plantData.name') ||
        !get(currentCard, 'originPort.locode') ||
        !get(currentCard, 'destinationPort.locode')) {
        warningCount++
      }

      remainKeys.forEach(remainKey => {
        const currentRemainCard = pick(serviceCardSelection[remainKey], matchParams)
        if (get(currentRemainCard, 'incoterm') !== get(currentCard, 'incoterm') ||
          get(currentRemainCard, 'ofp.partner.plantData.name') !== get(currentCard, 'ofp.partner.plantData.name') ||
          get(currentRemainCard, 'obl[0].plantData.name') !== get(currentCard, 'obl[0].plantData.name') ||
          get(currentRemainCard, 'obc[0].plantData.name') !== get(currentCard, 'obc[0].plantData.name') ||
          get(currentRemainCard, 'consignee.plantData.name') !== get(currentCard, 'consignee.plantData.name') ||
          get(currentRemainCard, 'originPort.locode') !== get(currentCard, 'originPort.locode') ||
          get(currentRemainCard, 'destinationPort.locode') !== get(currentCard, 'destinationPort.locode')) {
          warningCount++
        }
      })
    })

    if (warningCount === 0) {
      setHasWarning(false)
    } else {
      setHasWarning(true)
    }

    setState(serviceCardSelection)
    onChange('laneConflictForm', { serviceCardSelection })
  }

  const handleNoteChange = event => {
    const { value } = event.target
    setState({
      note: value
    })

    onChange('laneConflictForm.note', value)
  }

  return (
    <Grid container
      className="booking-wizard">
      <Grid item sm={12} md={8}>

        {!markingAsSpot &&
          /* Spot booking switch */
          <Grid container className={'px-20 py-12 bg-light-orange'}
            alignItems="center">
            <Grid item xs={12}>
              <label className={'text-16 semi-bold'}>
                Resolve conflicts</label>
            </Grid>
            <Grid item xs={12}>
              <label className={'text-10 fg-gray regular'}
                style={{
                  lineHeight: '12px'
                }}>
                You can resolve the conflicts by choosing the right service cards or you can move this booking to spot if needed</label>
            </Grid>
          </Grid>
          /* End of Spot booking switch  */
        }
        <Grid container
          className="booking-forms">

          {!markingAsSpot &&
            /* START containers */
            <Grid item xs={12} className="pb-28">
              {Object.keys(groupBy(matchedServiceCards, 'equipmentType')).map((containerKey, containerIndex) => {
                return (
                  <Grid container key={containerIndex}
                    className={'field-control'}>
                    <Grid item xs={12}>
                      <Grid container justify="space-between"
                        className="pb-6">
                        <label className={'text-16 semi-bold fg-gray'}>
                          {general.container[containerKey]}
                        </label>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {get(
                        groupBy(matchedServiceCards, 'equipmentType'),
                        containerKey,
                        []
                      ).map((containerCard, containerCardIndex) => {
                        const { serviceCardId } = containerCard
                        const isSelected = get(state, `${containerKey}.serviceCardId`) === serviceCardId ? true : false

                        return (
                          <Grid container
                            key={containerCardIndex}
                            style={{
                              borderRadius: '2px',
                              backgroundColor: isSelected ? '#ECFAEE' : '#FFFFFF'
                            }}
                            className={'my-12 p-12 border-solid border-1 cursor-pointer '
                              + (isSelected ? 'border-green' : 'border-gray')
                            }
                            spacing={1}
                            onClick={() => handleSelectChange(containerKey, containerCard)}>
                            <Grid item xs={1}>
                              <img src="assets/images/icons/form/check-box.svg"
                                className={isSelected ? 'hidden' : ''}
                                alt="check-box" />
                              <img src="assets/images/icons/form/check-box-checked.svg"
                                className={!isSelected ? 'hidden' : ''}
                                alt="check-box" />
                            </Grid>
                            <Grid item xs={11}>
                              <Grid container justify="space-between">
                                <Grid item className="semi-bold">
                                  {get(containerCard, 'meta.itineraryId')}
                                </Grid>

                                <Grid item
                                  className="text-12 fg-gray">
                                  <label>Incoterm:&nbsp;</label>
                                  <label>{get(containerCard, 'incoterm')}</label>
                                </Grid>

                                <Grid item xs={12}
                                  className="text-12 py-12 border-dashed border-b-1 border-gray fg-gray">
                                  {get(containerCard, 'ofp') &&
                                    <Grid container>
                                      <label>EFP:&nbsp;</label>
                                      <label>{get(containerCard, 'ofp.partner.plantData.name')}</label>
                                    </Grid>
                                  }
                                  {get(containerCard, 'obl') &&
                                    <Grid container>
                                      <label>ELP:&nbsp;</label>
                                      <label>{get(containerCard, 'obl[0].plantData.name')}</label>
                                    </Grid>

                                  }
                                  {get(containerCard, 'obc') &&
                                    <Grid container>
                                      <label>ECB:&nbsp;</label>
                                      <label>{get(containerCard, 'obc[0].plantData.name')}</label>
                                    </Grid>
                                  }
                                  {get(containerCard, 'consignee') &&
                                    <Grid item
                                      className="text-12 fg-gray">
                                      <label>Consignee:&nbsp;</label>
                                      <label>{get(containerCard, 'consignee.plantData.name')}</label>
                                    </Grid>
                                  }
                                </Grid>

                                <Grid item xs={12}
                                  className="text-12 pt-12 pb-6 fg-gray">
                                  <label>Origin port:&nbsp;</label>
                                  <label>
                                    {
                                      [
                                        get(containerCard, 'originPort.name'),
                                        get(containerCard, 'originPort.country')
                                      ].join(', ')
                                      + ' ('
                                      + get(containerCard, 'originPort.locode') + ')'
                                    }
                                  </label>
                                </Grid>

                                <Grid item xs={12}
                                  className="text-12 fg-gray">
                                  <label>Destination port:&nbsp;</label>
                                  <label>
                                    {
                                      [
                                        get(containerCard, 'destinationPort.name'),
                                        get(containerCard, 'destinationPort.country')
                                      ].join(', ')
                                      + ' ('
                                      + get(containerCard, 'destinationPort.locode') + ')'
                                    }
                                  </label>
                                </Grid>

                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                )
              })}

              {/* Add Note */}
              <Grid item xs={12} className='pt-20 field-control'>
                <Grid container alignItems='center'>
                  <label className='text-16 medium'>Add Note&nbsp;</label>
                  <label className='text-16 regular'>(For booking partners)</label>
                </Grid>
                <TextField
                  className='theme-text-field'
                  placeholder='You can add any extra note for this booking'
                  name='note'
                  value={get(state, 'note')}
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleNoteChange}
                />
              </Grid>
              {/* END Add Note */}
            </Grid>
            /* END Service providers */
          }

          {(!markingAsSpot && hasWarning) &&
            /* Spot booking switch */
            <Grid container className={'px-20 py-12 bg-light-orange mb-20'}
              alignItems="center">
              <Grid item xs={12}>
                <label className={'text-16 semi-bold fg-orange'}>Warning!</label>
              </Grid>
              <Grid item xs={12}>
                <label className={'text-10 fg-gray regular'}
                  style={{
                    lineHeight: '12px'
                  }}>
                  You have selected conflicting service cards or some service card have not all required information, please proceed with the spot booking</label>
              </Grid>
            </Grid>
            /* End of Spot booking switch */
          }

          {markingAsSpot &&
            <Grid container direction='row' className='pb-28'>
              <Grid item xs={12}>
                <label className="text-16 semi-bold require-field">
                  Reason</label>
              </Grid>
              <Grid item xs={12}>
                <TextField name='note'
                  placeholder="Specify a reason for marking this booking as spot…"
                  className='theme-text-field w-full'
                  value={spotReason}
                  inputProps={{
                    style: {
                      fontSize: '14px'
                    }
                  }}
                  onChange={event => setSpotReason(event.target.value)}
                />
              </Grid>
            </Grid>
          }

          {/* START Actions */}
          <Grid item xs={12}>
            <Grid container
              justify='space-between'
              alignItems="flex-end">
              <Grid item sm={12} md={10}>
                <Grid container
                  alignItems="center"
                  spacing={2}>
                  {!markingAsSpot &&
                    <Grid item sm={12} md={6}>
                      <LoadingButton className='btn w-full'
                        loading={requesting}
                        onClick={handleResolveConflict}
                        disabled={hasWarning}>
                        {general.createBooking}
                      </LoadingButton>
                    </Grid>
                  }
                  <Grid item sm={12} md={6}>
                    <LoadingButton className='btn btn_light_orange w-full'
                      loading={requesting}
                      onClick={() => {
                        if (markingAsSpot) {
                          handleMarkAsSpot(spotReason)
                        } else {
                          setSpotReason('')
                          setMarkingAsSpot(true)
                        }
                      }}
                      disabled={markingAsSpot && !spotReason}>
                      mark as spot</LoadingButton>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item sm={12} md={2}>
                <Grid container justify="flex-end">
                  <Button className={'btn btn_transparent'}
                    disabled={requesting}
                    onClick={() => {
                      if (markingAsSpot) {
                        setMarkingAsSpot(false)
                      } else {
                        go(-1)
                      }
                    }}>Back</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* END Actions */}

        </Grid>
      </Grid>
      <Grid item sm={12} md={4}>
        <BookingWizardSummary shipment={shipment} />
      </Grid>
    </Grid>
  )
}

export default BookingLaneConflictForm
