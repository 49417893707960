import ActionTypes from './types'
import * as CoreActions from 'core/store/actions'
import * as API from 'core/api'
import { checkPagination } from 'core/helpers'
import { get, set, uniq, filter } from 'lodash'

export const setAllClosedOutbounds = payload => ({
  type: ActionTypes.SET_ALL_CLOSED_OUTBOUNDS,
  payload
})

export const setMoreClosedOutbounds = payload => ({
  type: ActionTypes.SET_MORE_CLOSED_OUTBOUNDS,
  payload
})

export const setExcludeClosedOutbounds = payload => ({
  type: ActionTypes.SET_ALL_EXCLUDE_CLOSED_OUTBOUNDS,
  payload
})

export const setMoreExcludeClosedOutbounds = payload => ({
  type: ActionTypes.SET_MORE_EXCLUDE_CLOSED_OUTBOUNDS,
  payload
})


export const setSelectedOutbounds = selectedOutBoundIds => ({
  type: ActionTypes.SET_SELECTED_OUTBOUNDS,
  selectedOutBoundIds
})

export const setLoading = (loading, type) => (type === 'exclude' ?
  {
    type: ActionTypes.SET_EXCLUDE_LOADING,
    loading
  } : {
    type: ActionTypes.SET_LOADING,
    loading
  }
)

export const setLoadingMore = (type) => (type === 'exclude' ?
  { type: ActionTypes.SET_EXCLUDE_LOADING_MORE } :
  { type: ActionTypes.SET_LOADING_MORE }
)

export const getAllClosedOutbounds = (pagination, type, groupByCSCode = true) => async dispatch => {
  const query = {
    ...pagination,
    groupByCSCode,
    status: ['closed'],
  }

  if (type === 'exclude') {
    set(query, 'exclude', true)
  }

  try {
    if (pagination.pageNo === 1) {
      dispatch(setLoading(true, type))
    } else {
      dispatch(setLoadingMore(true, type))
    }
    const { ok, data } = await API.getClosedOutbounds(query)

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    if (type === 'exclude') {
      if (pagination.pageNo === 1) {
        dispatch(setExcludeClosedOutbounds(data))
      } else {
        dispatch(setMoreExcludeClosedOutbounds(data))
      }
    }
    else {
      if (pagination.pageNo === 1) {
        dispatch(setAllClosedOutbounds(data))
      } else {
        dispatch(setMoreClosedOutbounds(data))
      }
    }
    dispatch(setSelectedOutbounds([]))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const openSelectedOutbounds = (type) => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true, type))
    const { selectedOutBoundIds } = getState().closed
    const { ok, data, status } = await API.openClosedOutbounds(selectedOutBoundIds)
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(setLoading(false, type))
    return true
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    dispatch(setLoading(false, type))
  }
}

export const selectAll = (type) => async (dispatch, getState) => {
  const { closedOutbounds, excludeClosedOutbounds } = getState().closed
  const selected = []
  if (type === 'exclude') {
    excludeClosedOutbounds.forEach(item => {
      const key = get(Object.keys(item), '[0]')
      item[key].forEach(o => {
        selected.push(o._id)
      })
    })
  } else {
    closedOutbounds.forEach(item => {
      const key = get(Object.keys(item), '[0]')
      item[key].forEach(o => {
        selected.push(o._id)
      })
    })
  }
  dispatch(setSelectedOutbounds(selected))
}

export const selectNone = () => async (dispatch, getState) => {
  dispatch(setSelectedOutbounds([]))
}

export const selectOutbound = ids => async (dispatch, getState) => {
  const { selectedOutBoundIds } = getState().closed
  dispatch(setSelectedOutbounds(uniq([...selectedOutBoundIds, ...ids])))
}

export const unSelectOutbound = ids => async (dispatch, getState) => {
  const { selectedOutBoundIds } = getState().closed
  dispatch(setSelectedOutbounds(uniq(filter(selectedOutBoundIds, i => !ids.includes(i)))))
}
