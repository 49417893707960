import ActionTypes from './types'

const initialState = {
  eventsList: {
    loading: true,
    data: []
  },
  eventsNotification: {
    loading: true,
    data: []
  },
  places: {
    loading: true,
    data: []
  },
  plantEmailTemplates: {
    loading: true,
    data: []
  },
  appTheme: '',
  scheduleDefaultMode: '',
  shipmentCardPrimaryId: '',
  onGoingTask: {}
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_USER_SETTINGS_ON_GOING_TASK: {
      return {
        ...state,
        onGoingTask: action.payload
      }
    }
    case ActionTypes.LOADING_USER_SETTINGS_EVENT_LIST: {
      return {
        ...state,
        eventsList: initialState.eventsList
      }
    }
    case ActionTypes.LOAD_USER_SETTINGS_EVENT_LIST: {
      return {
        ...state,
        eventsList: {
          loading: false,
          data: action.payload
        }
      }
    }
    case ActionTypes.LOADING_USER_SETTINGS_EVENT_NOTIFICATIONS: {
      return {
        ...state,
        eventsNotification: initialState.eventsNotification
      }
    }
    case ActionTypes.LOAD_USER_SETTINGS_EVENT_NOTIFICATIONS: {
      return {
        ...state,
        eventsNotification: {
          loading: false,
          data: action.payload
        }
      }
    }
    case ActionTypes.LOADING_USER_SETTINGS_PLACES: {
      return {
        ...state,
        places: initialState.places
      }
    }
    case ActionTypes.LOAD_USER_SETTINGS_PLACES: {
      return {
        ...state,
        places: {
          loading: false,
          data: action.payload
        }
      }
    }
    case ActionTypes.LOAD_SET_UPDATE_USER_SETTINGS_BY_KEY: {
      return {
        ...state,
        [action.key]: action.value
      }
    }
    case ActionTypes.LOADING_PLANT_SETTINGS_EMAIL_TEMPLATES: {
      return {
        ...state,
        plantEmailTemplates: initialState.plantEmailTemplates
      }
    }
    case ActionTypes.LOAD_PLANT_SETTINGS_EMAIL_TEMPLATES: {
      return {
        ...state,
        plantEmailTemplates: {
          loading: false,
          data: action.payload
        }
      }
    }
    default:
      return state
  }
}

export default settingsReducer
