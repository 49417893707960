import React from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { FuseButtonToggle, FuseCheckBoxGroup } from '../../core/components'
import { routeInfoForm, general } from '../../core/localization'
import BookingWizardSummary from '../../manage-booking/components/ProposeBookingSummary'
import { get } from 'lodash'

const DisabledBookingRouteForm = props => {
  const {
    go,
    data: {
      shipment,
      shipment: { shipmentMode, goodsType, shipper, consignee, dropHub, transportMethod },
    },
  } = props

  const shipmentTypeData = [
    {
      label: routeInfoForm.shipmentMode.mode.direct,
      value: 'direct'
    },
    {
      label: routeInfoForm.shipmentMode.mode.drop,
      value: 'drop'
    }
  ]

  const goodsTypeData = [
    {
      label: routeInfoForm.goodsDeclaration.options.dg,
      value: 'DG',
      checked: false
    },
    {
      label: routeInfoForm.goodsDeclaration.options.formd,
      value: 'FORMD',
      checked: false
    },
    {
      label: routeInfoForm.goodsDeclaration.options.formak,
      value: 'FORMAK',
      checked: false
    },
    {
      label: routeInfoForm.goodsDeclaration.options.forma,
      value: 'FORMA',
      checked: false
    },
    {
      label: routeInfoForm.goodsDeclaration.options.forme,
      value: 'FORME',
      checked: false
    },
  ]

  const deliveryModeData = [
    {
      label: routeInfoForm.shipmentDeliveryMode.mode.ocean,
      value: 'ocean'
    },
    {
      label: routeInfoForm.shipmentDeliveryMode.mode.air,
      value: 'air'
    },
    {
      label: routeInfoForm.shipmentDeliveryMode.mode.cbt,
      value: 'CBT'
    }
  ]

  return (
    <Formik enableReinitialize
      validate={true}
      onSubmit={() => { }}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container direction='row'>
            <Grid item sm={12} md={8}
              className={'booking-forms'}>

              {/* Shipment Mode */}
              <Grid container
                direction='row'
                className='field-control'>
                <Grid item sm={12} md={6}
                  className='flex items-center'>
                  <label className='field-label'>
                    {routeInfoForm.shipmentMode.label}
                  </label>
                </Grid>
                <Grid item sm={12} md={6}
                  className='flex justify-end'>
                  <FuseButtonToggle name='shipmentMode'
                    data={shipmentTypeData}
                    value={shipmentMode}
                    buttonClass='text-12'
                    onChange={() => { }}
                    disabled />
                </Grid>
              </Grid>
              {/* END Shipment Mode */}

              {/* Shipment Route */}
              <Grid container direction='row' className={'field-control'}>
                <Grid item sm={12} md={6} className='flex items-center'>
                  <label className={'field-label'}>
                    {routeInfoForm.shipmentRoute.label}</label>
                </Grid>
                <Grid item sm={12} md={6} className='flex items-center justify-end'>
                  <label className={'field-instruction'}>
                    {routeInfoForm.shipmentRoute.instruction}</label>
                </Grid>
                <Grid container direction='row' className='stepper'>

                  {/* Shipment route shipper */}
                  <Grid container direction='row' className='step'>
                    <Grid container direction='row' className='step-header'>
                      <span className='step-icon'>
                        <img src="assets/images/icons/wizard-arrow-up-circle.png"
                          alt='shipper' />
                      </span>
                      <Grid item sm={12} md={6} className='flex items-center'>
                        <label className='field-sub-label'>
                          {routeInfoForm.shipmentRoute.shipper.label}</label>
                      </Grid>
                      <Grid item sm={12} md={6} className='flex items-center justify-end'>
                        <label className='field-instruction'>
                          {routeInfoForm.shipmentRoute.shipper.instruction}</label>
                      </Grid>
                    </Grid>

                    <Grid container direction='row' className='step-content'>
                      <Grid item sm={12} md={8} className='pr-16'>
                        <TextField className='theme-text-field'
                          name="name"
                          value={get(shipper, 'name', '')}
                          label={routeInfoForm.shipmentRoute.field.plantName}
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={12} md={4}>
                        <TextField className='theme-text-field'
                          name="location.country"
                          value={get(shipper, 'location.country', '')}
                          label={routeInfoForm.shipmentRoute.field.country}
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* END Shipment route shipper */}

                  {shipmentMode === 'drop' &&
                    /* Shipment route dropHub */
                    <Grid container direction='row' className='step'>
                      <Grid container direction='row' className='step-header'>
                        <span className='step-icon'>
                          <img src="assets/images/icons/wizard-drop-hub-circle.png"
                            alt='shipper' />
                        </span>
                        <Grid item sm={12} md={6} className='flex items-center'>
                          <label className='field-sub-label'>
                            {routeInfoForm.shipmentRoute.drophub.label}</label>
                        </Grid>
                        <Grid item sm={12} md={6} className='flex items-center justify-end'>
                          <label className='field-instruction'>
                            {routeInfoForm.shipmentRoute.drophub.instruction}</label>
                        </Grid>
                      </Grid>

                      <Grid container direction='row' className='step-content'>
                        <Grid item sm={12} md={8} className='pr-16'>
                          <TextField className='theme-text-field'
                            name="name"
                            value={get(dropHub, 'name', '')}
                            label={routeInfoForm.shipmentRoute.field.plantName}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <TextField className='theme-text-field'
                            name="location.country"
                            value={get(dropHub, 'location.country', '')}
                            label={routeInfoForm.shipmentRoute.field.country}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    /* END Shipment route dropHub */
                  }

                  {/* Shipment route consignee */}
                  <Grid container direction='row' className='step'>
                    <Grid container direction='row' className='step-header'>
                      <span className='step-icon'>
                        <img src="assets/images/icons/wizard-arrow-down-circle.png"
                          alt='shipper' />
                      </span>
                      <Grid item sm={12} md={6} className='flex items-center'>
                        <label className='field-sub-label'>
                          {routeInfoForm.shipmentRoute.consignee.label}</label>
                      </Grid>
                      <Grid item sm={12} md={6} className='flex items-center justify-end'>
                        <label className='field-instruction'>
                          {routeInfoForm.shipmentRoute.consignee.instruction}</label>
                      </Grid>
                    </Grid>
                    <Grid container direction='row' className='step-content'>
                      {consignee && consignee.map((consigneeItem, consigneeIndex) => {
                        return (
                          <React.Fragment key={consigneeIndex}>
                            <Grid key={consigneeIndex} item sm={12} md={8}
                              className='pr-16 pb-16'>
                              <TextField className='theme-text-field'
                                name="name"
                                value={get(consigneeItem, 'name', '')}
                                label={routeInfoForm.shipmentRoute.field.plantName + " " + (consigneeIndex + 1)}
                                fullWidth={true}
                                InputLabelProps={{ shrink: true }}
                                disabled
                              />
                            </Grid>
                            <Grid item sm={12} md={4} className='pb-16'>
                              <TextField className='theme-text-field'
                                name="location.country"
                                value={get(consigneeItem, 'location.country', '')}
                                label={routeInfoForm.shipmentRoute.field.country}
                                fullWidth={true}
                                InputLabelProps={{ shrink: true }}
                                disabled
                              />
                            </Grid>
                          </React.Fragment>
                        )
                      })}
                    </Grid>
                  </Grid>
                  {/* END Shipment route consignee */}

                </Grid>
              </Grid>
              {/* END Shipment Route */}

              {/* Goods Declaration */}
              <Grid container
                direction='row'
                className='field-control'>
                <Grid item sm={12} md={12}
                  className='flex items-center'>
                  <label className='field-label'>
                    {routeInfoForm.goodsDeclaration.label}
                  </label>
                </Grid>
                <Grid item sm={12} md={12}
                  className='flex items-center'>
                  <FuseCheckBoxGroup data={goodsTypeData}
                    value={goodsType ? goodsType.map(item => item.toUpperCase()) : []}
                    className='flex-3 flex-row pl-4 pt-8'
                    chkClassName='text-14 text-grey-darkest'
                    onChange={() => { }}
                    disabled
                  />
                </Grid>
              </Grid>
              {/* END Goods Declaration */}

              {/* Shipment Delivery Mode */}
              <Grid container
                direction='row'
                className='field-control'>
                <Grid item sm={12} md={6}
                  className='flex items-center'>
                  <label className='field-label'>
                    {routeInfoForm.shipmentDeliveryMode.label}
                  </label>
                </Grid>
                <Grid item sm={12} md={6}
                  className='flex items-center justify-end'>
                  <FuseButtonToggle name='transportMethod'
                    data={deliveryModeData}
                    value={transportMethod}
                    buttonClass='text-12'
                    onChange={() => { }}
                    disabled
                  />
                </Grid>
              </Grid>
              {/* END Shipment Delivery Mode */}

              {/* Actions */}
              <Grid container
                className="pt-20">
                <Grid item sm={12} md={6}>
                  <Button className='btn w-full'
                    onClick={() => go(1)}>
                    {general.next}
                  </Button>
                </Grid>

                <Grid item sm={12} md={6} className='flex justify-end'>
                  <Button className={'btn btn_transparent'}
                    onClick={() => go(-1)}>
                    {general.back}
                  </Button>
                </Grid>
              </Grid>
              {/* END Actions */}

            </Grid>
            <Grid item sm={12} md={4}>
              <BookingWizardSummary shipment={shipment} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default DisabledBookingRouteForm
