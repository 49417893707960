import ActionTypes from './types'

// TODO: redefine on auth module development
const initialState = {
  loading: false,
  autoLogin: false,
  autoLoginLoading: false,
  info: null,
  token: null,
  error: null,
  challengeId: null,
  challengeIdCreatedAt: null,
  onboarding: false,
  email: '',
  forgotPassword: {}
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_ATTEMPT:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.API_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case ActionTypes.API_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case ActionTypes.AUTO_LOGIN_ATTEMPT:
      return {
        ...state,
        autoLogin: true,
        autoLoginLoading: true
      }
    case ActionTypes.AUTO_LOGIN_FINISHED:
      return {
        ...state,
        autoLoginLoading: false
      }
    case ActionTypes.AUTH_LOADING_RESET:
      return {
        ...state,
        loading: false
      }
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload,
        challengeId: null
      }

    case ActionTypes.CHALLENGE_REQUIRED:
      return {
        ...state,
        challengeId: action.payload.challengeId,
        challengeIdCreatedAt: new Date(),
        email: action.payload.email
      }
    case ActionTypes.FORGOT_PASSWORD_CHALLENGE:
      return {
        ...state,
        forgotPassword: {
          challengeId: action.payload.challengeId,
          challengeIdCreatedAt: new Date(),
          email: action.payload.email
        }
      }
    case ActionTypes.ONBOARDING_REQUIRED:
      return {
        ...state,
        onboarding: true
      }
    case ActionTypes.ONBOARDING_COMPLETED:
      return {
        ...state,
        onboarding: false
      }
    case ActionTypes.GET_INFO_SUCCESS:
      return {
        ...state,
        info: action.payload
      }
    case ActionTypes.AUTH_PROFILE_UPDATE:
      return {
        ...state,
        info: action.payload
      }

    case ActionTypes.RESET_AUTH:
      return {
        ...state,
        ...initialState
      }
    case ActionTypes.LOGOUT:
      return {
        ...state,
        ...initialState
      }

    default:
      return state
  }
}

export default authReducer
