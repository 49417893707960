import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Button,
  withStyles,
  Grid,
  CircularProgress,
  Typography,
  Card,
  CardContent
} from '@material-ui/core'
import {
  FuseAnimate,
  FuseTextField,
  Unauthenticated
} from '../../core/components'
import classNames from 'classnames'
import { get } from 'lodash'
import queryString from 'query-string'
import * as AuthActions from '../store/actions'
import history from '../../history'
import LoadingBar from 'react-redux-loading-bar'

const styles = theme => ({
  root: {
    background: 'url(/assets/images/backgrounds/login.png)',
    color: theme.palette.primary.contrastText,

    [theme.breakpoints.down('md')]: {
      '& .MuiTypography-h4': {
        fontSize: '2.4rem'
      },
      '& .MuiTypography-h3': {
        fontSize: '2.8rem'
      },
      '& .MuiTypography-h2': {
        fontSize: '3.5rem'
      }
    }
  },
  leftSectionLogin: {
    maxWidth: '60%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    }
  },
  rightSectionLogin: {
    minWidth: '40%'
  },
})

const labelStyles = {
  fontSize: '1.1em',
  marginBottom: '0.4em'
}

const inputStyles = {
  fontSize: '1.2em'
}

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const LoginEmailPage = props => {
  const { location, classes } = props
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [autoFetching, setAutoFetching] = useState(false)

  const onSubmit = async (requestEmail = email) => {
    const { getOrganizationByEmail } = props
    let { search, hash } = location
    if (requestEmail) {
      setLoading(true)
      const organizationInfo = await getOrganizationByEmail(requestEmail)
      localStorage['defaultLogin'] = get(organizationInfo, 'defaultLogin', true);
      localStorage['authProviders'] = JSON.stringify(get(organizationInfo, 'authProviders', []));
      localStorage['policyLink'] = get(organizationInfo, 'privacyLink', '');
      localStorage.setItem("userDetail", requestEmail);
      search = queryString.parse(search)
      search.e = requestEmail
      history.push(`/${get(organizationInfo, 'organizationId')}/login?${queryString.stringify(search)}${hash}`,
        { isLoginProcess: true }
      )
      setLoading(false)
    }
  }

  const setUserFromLocalStorage = async () => {
    let userDetail = await localStorage.getItem("userDetail");
    if (userDetail) {
      onSubmit(userDetail)
      setAutoFetching(true)
    } else {
      setAutoFetching(false)
    }
  }

  useEffect(() => {
    const search = queryString.parse(get(location, 'search'));
    if (get(search, 'e')) {
      setEmail(get(search, 'e', ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useEffect(() => {
    const { logout, auth, getInfo } = props
    const search = queryString.parse(get(location, 'search'))

    delete localStorage['defaultLogin'];
    delete localStorage['authProviders'];
    delete localStorage['policyLink'];
    delete localStorage['trusted'];

    if (search.adminLogout) {
      logout()
    } else if (auth && get(auth, 'token') && get(auth, 'info')) {
      if (getInfo()) {
        history.push('/')
      }
    }
    setUserFromLocalStorage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames(
      classes.root,
      'flex flex-col flex-1 flex-no-shrink sm:p-24 md:flex-row md:p-0')}>
      <div className={classNames(
        classes.leftSectionLogin,
        'flex flex-row flex-no-grow items-center text-white p-16 text-center md:p-72 md:flex-no-shrink md:flex-1 md:text-left'
      )}>
        <Grid container className='md:text-left sm:text-center'>
          <Grid item xs={12}>
            <FuseAnimate animation='transition.slideUpIn' delay={300}>
              <Typography variant='h3'
                color='inherit'
                className='regular'>
                Welcome to</Typography>
            </FuseAnimate>
          </Grid>
          <Grid item xs={12}>
            <FuseAnimate delay={400}>
              <Typography variant='h2'
                color='inherit'
                className='mt-16 bold'>
                TRAMÉS ecosystem</Typography>
            </FuseAnimate>
          </Grid>
        </Grid>
      </div>
      <FuseAnimate animation={{ translateX: [0, '100%'] }}>
        <Card className={classNames(
          classes.rightSectionLogin,
          'w-full max-w-md mx-auto m-16 md:m-0 login-form-border')} square>
          <CardContent className='flex flex-col items-center p-32 md:p-92 md:h-full'>
            {!autoFetching &&
              <Grid container>

                <Grid item xs={12} className="pb-28">
                  <img src='assets/images/logos/trames.png'
                    alt='logo' />
                </Grid>

                <Grid item xs={12}>
                  <form style={{ marginTop: '20%' }}
                    onSubmit={e => {
                      onSubmit()
                      e.preventDefault()
                    }}
                    noValidate>
                    <FuseAnimate animation={{ translateY: [0, '100%'] }}
                      delay={300}>
                      <Grid container
                        alignItems="center"
                        className="h-full">

                        <Grid item xs={12}>
                          <Typography className='text-20 md:w-full mb-32 semi-bold'>
                            Enter your organization email address or username...</Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <FuseTextField className='mb-16 regular'
                            type='text'
                            name='email'
                            label='Email address or Username'
                            icon={{ ...baseTextSetting, name: 'email' }}
                            value={email}
                            labelStyles={labelStyles}
                            inputStyles={inputStyles}
                            onChange={e => setEmail(e.target.value.trim())}
                            required
                            fullWidth />
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container
                            alignItems='center'
                            justify='flex-end'>
                            <Grid item>
                              <Button type='submit'
                                variant='contained'
                                style={{ borderRadius: '50vh' }}
                                className='min-w-160 ml-auto mt-8 btn'
                                aria-label='LOG IN'
                                disabled={loading || email === ''}>
                                {!loading ? 'Next' : 'Checking...'}{' '}
                                {loading &&
                                  <CircularProgress color='primary'
                                    size={20}
                                    style={{ marginLeft: 20 }} />
                                }
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    </FuseAnimate>

                  </form>
                </Grid>

              </Grid>
            }

            {autoFetching &&
              <Grid container
                alignItems="center"
                justify="center"
                className="h-full">
                <CircularProgress color='secondary' />
              </Grid>
            }

          </CardContent>
        </Card>
      </FuseAnimate>
      <LoadingBar className='page-loading-bar' />
    </div>
  )
}

const mapStateToProps = ({ auth }) => ({ auth })

const mapDispatchToProps = dispatch => ({
  getOrganizationByEmail: (...args) => dispatch(AuthActions.getOrganizationByEmail(...args)),
  getInfo: () => dispatch(AuthActions.getInfo()),
  logout: (...args) => dispatch(AuthActions.logout(...args)),
})

export default compose(Unauthenticated, connect(mapStateToProps, mapDispatchToProps))(withStyles(styles)(LoginEmailPage))
