import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const Unauthenticated = WrappedComponent => {
  const UnauthenticatedComponent = ({ token, info, autoLogin, ...props }) =>
    !token || !info || autoLogin ? <WrappedComponent {...props} /> : <Redirect to='/' />

  const mapStateToProps = ({ auth }) => ({
    token: auth.token,
    autoLogin: auth.autoLogin
  })

  return connect(mapStateToProps, null)(UnauthenticatedComponent)
}

export default Unauthenticated
