import React, { useEffect, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { set, get, omit } from 'lodash'
import { useMergeState } from "../../core/helpers";
import { FusePlaceHolder, LoadingButton } from "../../core/components";
import DateTime from 'react-datetime';

const INITIAL_STATE = {
  events: []
}

const ShipmentEvents = (props) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const [savingEvent, setSavingEvent] = useState(false)
  const { eventWriteList, selectedCode, requesting, onDone } = props

  const saveEvent = (eventIndex, inputIndex, callback) => {
    const { shipment, addShipmentEvents, getShipmentEventWriteList } = props
    const { events } = state
    const currentEventInput = get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {})
    const payload = {
      primaryInputs: [
        omit(currentEventInput, ['oldValue', 'editing'])
      ]
    }
    setSavingEvent(true)
    addShipmentEvents(
      shipment._id,
      get(events[eventIndex], 'code'),
      get(events[eventIndex], 'type'),
      payload,
      () => {
        getShipmentEventWriteList(shipment._id, () => {
          setSavingEvent(false)
          if (callback) {
            callback()
          }
        })
      }
    )
  }

  const onDateChange = (date, eventIndex, inputIndex) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    const events = get(state, 'events', [])

    set(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {
      ...get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {}),
      value: new Date(date),
      editing: true,
    })

    setState({ events })
  }

  const handleChange = (event, eventIndex, inputIndex) => {
    const { value } = event.target
    const events = get(state, 'events', [])

    set(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {
      ...get(events[eventIndex], 'primaryInputs[' + inputIndex + ']', {}),
      value: value.toUpperCase(),
      editing: true,
    })

    setState({ events })
  }

  const getEventField = (eventIndex, inputIndex) => {
    const event = get(state, 'events[' + eventIndex + ']', {})
    const eventDataType = get(event, 'primaryInputs[' + inputIndex + '].type')
    const eventFieldId = get(event, 'primaryInputs[' + inputIndex + '].id')
    const eventFieldValue = get(event, 'primaryInputs[' + inputIndex + '].value')
    const isEditing = get(event, 'primaryInputs[' + inputIndex + '].editing', false)

    if (eventDataType === 'date') {
      return (
        <Grid container alignItems='center'>
          <Grid item xs={12} className='relative'>
            <DateTime input className="theme-event-date-time-picker-field"
              inputProps={{
                placeholder: 'Add date & time',
                disabled: (eventFieldValue && !isEditing)
              }}
              dateFormat="DD MMM YYYY,"
              onChange={(date) => onDateChange(date, eventIndex, inputIndex)}
              value={eventFieldValue ? new Date(eventFieldValue) : ''} />
          </Grid>
        </Grid>
      )
    } else if (eventDataType === 'string') {
      return (
        <Grid container alignItems='center' spacing={2}>
          <Grid item xs={12} className="relative">
            <TextField name={eventFieldId} className='value-field-input'
              value={eventFieldValue}
              placeholder={"Enter something..."}
              InputLabelProps={{
                shrink: true
              }}
              disabled={(eventFieldValue && !isEditing)}
              onChange={(e) => handleChange(e, eventIndex, inputIndex)} />
          </Grid>
        </Grid>
      )
    }
  }

  useEffect(() => {
    if (!get(eventWriteList, '[0].invalid')) {
      setState({
        events: eventWriteList.filter(x => selectedCode && x.code === selectedCode).map(event => {
          const eventData = {
            code: get(event, 'code'),
            type: get(event, 'type'),
            name: get(event, 'name'),
            level: get(event, 'level'),
            primaryInputs: get(event, 'primaryInputs', []).map(input => {
              input.oldValue = input.value
              return input
            })
          }
          return eventData
        })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventWriteList, selectedCode])

  return (
    <Grid container>
      {get(state, 'events', []).length === 0 &&
        <Grid item xs={12}>
          <FusePlaceHolder className='flex flex-col' icon='error'
            title='Requested event is not available for this task' />
        </Grid>
      }
      {get(props, 'eventWriteList[0].invalid') &&
        <Grid item xs={12}>
          <FusePlaceHolder className='flex flex-col' icon='error'
            title='Events can be added only after accepting this shipment' />
        </Grid>
      }
      {!get(props, 'eventWriteList[0].invalid') && get(state, 'events', [])
        .map((event, eventIndex) => {
          const eventLevel = get(event, 'level')
          const primaryInputs = get(event, 'primaryInputs', [])

          if (primaryInputs.length === 0) {
            return <React.Fragment key={eventIndex} />
          } else {
            return (
              <Grid item xs={12} key={eventIndex}>
                <Grid container>
                  <Grid item xs={12}>
                    {((eventLevel === 'Shipment' &&
                      primaryInputs.length === 1)) &&
                      getEventField(eventIndex, 0)
                    }
                  </Grid>
                  {eventLevel === 'Container' &&
                    primaryInputs.map((input, inputIndex) => {
                      return (
                        <Grid key={`input_${inputIndex}`} item xs={12}
                          className={'pt-8' + ((inputIndex === (primaryInputs.length - 1)) ? ' pb-20' : '')}>
                          <Grid container alignItems="center">
                            <Grid item xs={12}>
                              <Typography title={get(input, 'name').toUpperCase()}
                                className='text-12 uppercase regular'
                                noWrap>{input.name}</Typography>
                              {get(input, 'data') &&
                                <Typography className='text-12 uppercase regular fg-gray'>
                                  {get(input, 'data')}</Typography>
                              }
                            </Grid>
                            <Grid item xs={12}>
                              {getEventField(eventIndex, inputIndex)}
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            )
          }
        })
      }
      <Grid item xs={12} className="pt-40">
        <LoadingButton className='btn btn_sm w-full'
          disabled={!get(state, 'events[0].primaryInputs[0].editing')}
          loading={savingEvent || requesting}
          onClick={() => saveEvent(0, 0, onDone)}>
          save event</LoadingButton>
      </Grid>
    </Grid >
  )
}

export default ShipmentEvents
