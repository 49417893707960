import _ from 'lodash';

export function getAddressFromLocation(location) {
  var address = []
  address.push(_.get(location, 'floorUnitNumber'))
  address.push(_.get(location, 'street'))
  address.push(_.get(location, 'city'))
  address.push(_.get(location, 'state'))
  address.push(_.get(location, 'country'))
  address.push(_.get(location, 'zipcode'))
  return address.filter(Boolean).join(', ')
}
