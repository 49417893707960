import React from 'react'
import {
  Grid,
  Button,
  Icon
} from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody, LoadingButton
} from '../../core/components'
import { get } from 'lodash'

const AdHocConfirm = ({ open, adHocTask, handleConfirm, handleCancel, requesting }) => (
  <FuseDialog open={open} wSM>
    <FuseDialogHeader text="Dismiss Task"
      handleClose={handleCancel} />
    <FuseDialogBody>
      <Grid container alignItems="center" className="p-20">
        <Grid item xs={12}>
          <Grid container justify="center">
            <Icon className="text-52 mb-12 fg-green">done</Icon>
          </Grid>
        </Grid>
        <Grid item xs={12} className="text-center">
          <label className="regular">Are you sure you want to manually complete&nbsp;</label>
          <label className="bold">{get(adHocTask, 'task.name')}?</label>
        </Grid>
        <Grid item xs={12}>
          <Grid container
            spacing={2}
            className="pt-40">
            <Grid item xs={6}>
              <LoadingButton className="btn btn_sm btn_light_green"
                loading={requesting}
                onClick={handleConfirm}
                fullWidth>Yes, Confirm</LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button className="btn btn_sm btn_light_red"
                onClick={handleCancel}
                fullWidth>Cancel</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FuseDialogBody>
  </FuseDialog>
)

export default AdHocConfirm
