import React from 'react'
import { withStyles, Button, Grow, CircularProgress } from '@material-ui/core'
import classNames from 'classnames'
import DropzoneComponent from 'react-dropzone-component'
import { get } from 'lodash'

const styles = () => ({
  uploadContainer: {
    borderRadius: 2,
    minHeight: 62,
    padding: 8,
    backgroundColor: 'transparent'
  },
  documentIcon: {
    marginRight: 14
  },
  uploadFileTitle: {
    color: '#424D62',
    fontFamily: 'IBMPlexSans-Bold',
    lineHeight: '17px',
    fontSize: 14,
    marginBottom: 4,
    textAlign: 'left'
  },
  uploadFileSample: {
    color: '#424D62',
    fontFamily: 'IBMPlexSans',
    lineHeight: '17px',
    fontSize: 12,
    marginBottom: 4,
    marginLeft: 10,
    cursor: 'pointer !important'
  },
  uploadFile: {
    marginBottom: 0,
    color: '#8795a1',
    fontSize: 11,
    fontFamily: 'IBMPlexSans',
    lineHeight: '13px'
  },
  uploadRemove: {
    color: '#FF5353'
  },
  uploadActionButtons: {
    backgroundColor: 'rgba(16, 184, 123, 0.1)',
    color: '#10B87B',
    width: 117,
    height: 42,
    textTransform: 'uppercase'
  },
  addButton: {
    padding: 0,
    width: 24,
    height: 24,
    minWidth: 'auto',
    background: '#42B4E6',
    borderRadius: '100%',

    '& > span': {
      color: '#ffffff',
      cursor: 'pointer !important',
      fontSize: 22,
      fontWeight: 'lighter',
      height: 24
    }
  }
})

class FileUploader extends React.Component {
  state = {
    documents: {},
    uploadedFiles: [],
    uploadedFilesCopied: false
  }
  djsConfig = {
    addRemoveLinks: true,
    autoProcessQueue: false
  }

  componentWillMount() {
    if (!this.props.allowMultiple) {
      this.djsConfig.maxFiles = 1
    }

    if (this.props.allowedTypes) {
      this.djsConfig.acceptedFiles = this.props.allowedTypes;
    }

    if (this.props.maxFilesize) {
      this.djsConfig.maxFilesize = this.props.maxFilesize;
    }
  }

  fileDropped = (accepted, rejected) => {
    this.setState(
      {
        accepted,
        rejected
      },
      this.showDashSeperator
    )
  }

  onAddFile = file => {
    this.setState({ uploadedFiles: [] })
    if (this.state.documents[file.name]) {
      return
    }

    let documents = {}
    if (!this.props.allowMultiple) {
      documents = { [file.name]: file }
    } else {
      documents = {
        ...this.state.documents,
        [file.name]: file
      }
    }

    this.setState({ documents }, this.showDashSeperator)
    this.props.onSuccess(documents)
  }

  onRemoveFile = file => {
    const documents = { ...this.state.documents }
    delete documents[file.name]

    this.setState({ documents }, this.showDashSeperator)
    this.props.onSuccess(documents)
  }

  showFileDialog = () => {
    if (!this.dzRef) {
      return
    }

    this.dzRef.dropzone.element.click()
  }

  showDashSeperator = () => {
    if (!this.dzRef) {
      return
    }

    if (Object.keys(this.state.documents).length) {
      this.dzRef.dropzone.element.querySelector('.dz-message').classList.add('active')
    } else {
      this.dzRef.dropzone.element.querySelector('.dz-message').classList.remove('active')
    }
  }

  resetState = () => {
    this.setState({ documents: {} })
  }

  componentDidUpdate(prevProps) {
    const { existingDocument, uploadedFiles } = this.props
    if (existingDocument) {
      if (Object.keys(get(this.state, 'documents', {})).length === 0 && Object.keys(existingDocument).length !== 0) {
        this.setState(
          {
            documents: existingDocument
          },
          this.showDashSeperator
        )
      }
    }
    if (prevProps.uploadedFiles !== uploadedFiles && !this.state.uploadedFilesCopied) {
      this.setState({
        uploadedFilesCopied: true,
        uploadedFiles
      })
      if (uploadedFiles && uploadedFiles.length > 0) {
        this.setState({ documents: { [uploadedFiles[0].originalName]: uploadedFiles[0] } })
      }
    }
  }

  render() {
    const { classes, fileTitle, required, subTitle, name, image, sampleLink, hideUploadDetails, smallHeightBox, fileUploading } = this.props
    var { documents, uploadedFiles } = this.state

    const djsConfig = this.djsConfig

    const dropZoneEventHandlers = {
      init: dz => (this.dropzone = dz),
      addedfile: this.onAddFile,
      removedfile: this.onRemoveFile
    }

    const dropZoneConfig = {
      showFiletypeIcon: false,
      postUrl: '/file'
    }
    const documentCount = Object.keys(documents).length

    return (
      <div>
        <Grow in={true}>
          <DropzoneComponent
            onDrop={this.fileDropped}
            config={dropZoneConfig}
            eventHandlers={dropZoneEventHandlers}
            name={name}
            djsConfig={djsConfig}
            className={classNames(classes.dropComponentStyling, 'relative', {
              active: !hideUploadDetails && documentCount > 0,
              single: !this.props.allowMultiple,
              'h-40 overflow-hidden': smallHeightBox
            })}
            ref={ref => (this.dzRef = ref)}
          >
            <div className={classNames(classes.uploadContainer, {
              'flex justify-between': true,
              'h-40 min-h-40 py-24 mb-8': smallHeightBox
            })}>
              <div className={'flex items-center w-full'}>
                <div className={classNames(classes.documentIcon)}>
                  <img src={image} alt='Document' />
                </div>
                <div className='flex flex-col items-start w-full'>
                  <h4 className={classNames(classes.uploadFileTitle)}>
                    {fileTitle}
                    {sampleLink && (
                      <a href={sampleLink} className={classNames(classes.uploadFileSample)} download>
                        (sample)
                      </a>
                    )}
                  </h4>

                  {!hideUploadDetails &&
                    <React.Fragment>
                      {documentCount > 0 && (
                        <p style={{ overflow: 'hidden' }} className={classNames(classes.uploadFile)}>
                          {documentCount} files added
                        </p>
                      )}
                      {uploadedFiles &&
                        uploadedFiles.map((uploadedFile, index) => {
                          return (
                            <p style={{ overflow: 'hidden' }} className={classNames(classes.uploadFile)} key={index}>
                              {uploadedFile.originalName}
                              <Button size='small'
                                style={{ color: '#B10043', fontSize: 10, fontWeight: 500, marginLeft: 20 }}
                                onClick={() => this.setState({ documents: {}, uploadedFiles: [] })}>
                                Remove File</Button>
                            </p>
                          )
                        })
                      }
                    </React.Fragment>
                  }


                  {(required === undefined || required === true) && !documentCount && uploadedFiles.length < 1 && (
                    <p className={classNames(classes.uploadFile)}>This document is required</p>
                  )}

                  {subTitle !== undefined && !documentCount && <p className={classNames(classes.uploadFile)}>{subTitle}</p>}

                </div>
              </div>
              <div className='flex justify-end items-center mr-8'>
                {!fileUploading &&
                  <Button className={classes.addButton} onClick={this.showFileDialog}>
                    &#43;
                </Button>
                }
                {fileUploading &&
                  <CircularProgress className="fg-blue" size={20} />
                }
              </div>
            </div>
          </DropzoneComponent>
        </Grow>
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(FileUploader)
