import React, { useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { laneInfoForm, general } from 'core/localization'
import { get, set, find, filter } from 'lodash'
import BookingWizardSummary from '../ProposeBookingSummary'
import {
  CustomSwitch,
  LoadingButton
} from '../../../core/components'
import { useMergeState } from '../../../core/helpers'

const BookingLaneForm = (props) => {
  const {
    data,
    data: {
      shipment,
      shipment: { incoterm, itineraryIds },
      hasError,
      errors
    },
    go,
    onChange,
    existingFormData,
    onSubmit,
    submitAction,
    requesting
  } = props
  const [state, setState] = useMergeState({})
  const partners = ["obl", "obc", "ofp"]
  const ports = ["originPort", "destinationPort"]
  const knownErrors = [...partners, ...ports, 'incoterm', 'itineraryIds']

  useEffect(() => {
    var newState = existingFormData ? existingFormData : {}

    if (hasError === 'XRP003') {
      set(newState, 'isSpot', true)
    }

    setState(newState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [name]: value
    }

    onChange('laneForm', updatedState)
  }

  const getConflictingValue = (originProperty) => {
    const errorObject = find(errors, { originProperty })
    const conflictingValues = get(errorObject, 'conflictingValues', [])
    if (conflictingValues.length === 0) {
      return (
        <Grid item
          className="text-right medium">
          <label className="fg-red">Issues</label>
        </Grid>
      )
    } else {
      return conflictingValues.map((item, indexItem) => {
        return (
          <Grid item xs={12} key={indexItem}
            className="text-right medium">
            <label className="fg-red">{item.name}</label>
          </Grid>
        )
      })
    }
  }

  const getErrorMessage = (originProperty) => {
    const errorObject = find(errors, { originProperty })

    return (
      <Grid item sm={12} md={12}
        className="px-8 pt-4 pb-6 mt-6 mb-12"
        style={{
          backgroundColor: '#FBF0F4',
          borderRadius: '3px'
        }}>
        <label className="text-10 fg-red italic">
          {get(errorObject, 'message')}
        </label>
      </Grid>
    )
  }

  return (
    <div>
      <Grid container direction='row'>
        <Grid item sm={12} md={8}>
          {/* Spot booking switch  */}
          <Grid container className={'px-20 py-12 bg-light-orange'}
            alignItems="center">
            <Grid item xs={10}
              className='flex items-center'>
              <label className={'text-16 semi-bold'}>
                Enable spot for this booking</label>
            </Grid>
            <Grid item xs={2}>
              <Grid container justify="flex-end">
                <CustomSwitch name='isSpot'
                  onChange={(name) => {
                    handleInputChange({
                      target: {
                        name,
                        value: !get(state, 'isSpot', false),
                      }
                    })
                  }}
                  checked={get(state, 'isSpot', false)} />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <label className={'text-10 fg-gray regular'}>
                Once you enable the spot option, all shipment types will be available for this booking</label>
            </Grid>
          </Grid>
          {/* Spot booking switch  */}

          <Grid container className={'text-14 booking-forms'}>
            <Grid item xs={12}>

              {/* Partners */}
              <Grid container direction='row'>
                <Grid item sm={12}
                  className='flex items-center pb-8'>
                  <span className='text-base text-grey-darkest font-bold'>
                    {laneInfoForm.partners.label}
                  </span>
                </Grid>

                {partners.map((partnerKey, partnerIndex) => {
                  const currentPartner = get(shipment, partnerKey)

                  return (
                    <Grid key={`partner-${partnerIndex}`}
                      container alignItems="center"
                      className="pb-6">
                      <Grid item sm={12} md={5}
                        className='flex items-center pb-4'>
                        <span className='medium fg-gray'>
                          {general.partners[partnerKey]}
                        </span>
                      </Grid>
                      <Grid item sm={12} md={7}>
                        <Grid container direction="row"
                          alignItems="center"
                          justify="flex-end">
                          {currentPartner &&
                            <Grid item className="text-right medium">
                              <label>{get(currentPartner, 'name')}</label>
                            </Grid>
                          }
                          {!currentPartner && getConflictingValue(partnerKey)}
                        </Grid>
                      </Grid>
                      {!currentPartner && getErrorMessage(partnerKey)}
                    </Grid>
                  )
                })}
              </Grid>
              {/* End of Partners */}

              {/* Incoterms */}
              <Grid container direction='row'
                className='pt-24'>
                <Grid item sm={12}
                  className='flex items-center pb-8'>
                  <span className='text-base text-grey-darkest font-bold'>
                    {laneInfoForm.incoterms.label}
                  </span>
                </Grid>

                <Grid item sm={12} md={5}
                  className='flex items-center pb-4'>
                  <span className='medium fg-gray'>
                    Incoterm</span>
                </Grid>
                <Grid item sm={12} md={7}>
                  <Grid container direction="row"
                    alignItems="center"
                    justify="flex-end">
                    {incoterm &&
                      <Grid item xs={12}
                        className="text-right medium">
                        <label>{incoterm}</label>
                      </Grid>
                    }
                    {!incoterm && getConflictingValue('incoterm')}
                  </Grid>
                </Grid>

                {!incoterm && getErrorMessage('incoterm')}
              </Grid>
              {/* End of Incoterms */}

              {/* Port Information */}
              <Grid container direction='row'
                className='pt-24'>
                <Grid item sm={12}
                  className='flex items-center pb-8'>
                  <span className='text-base text-grey-darkest font-bold'>
                    {laneInfoForm.portInfo.label}
                  </span>
                </Grid>

                {ports.map((portKey, portIndex) => {
                  const currentPort = get(shipment, portKey)

                  return (
                    <Grid container
                      key={`port-${portIndex}`}
                      alignItems="center"
                      className="pb-6">
                      <Grid item sm={12} md={4}
                        className='flex items-center pb-4'>
                        <span className='medium fg-gray'>
                          {laneInfoForm.portInfo[portKey]}
                        </span>
                      </Grid>
                      <Grid item sm={12} md={8}
                        className='flex items-center justify-end'>
                        <Grid container direction="row"
                          alignItems="center"
                          justify="flex-end">
                          {currentPort &&
                            <Grid item className="text-right medium">
                              <label>
                                {
                                  [
                                    get(currentPort, 'name'),
                                    get(currentPort, 'country')
                                  ].join(', ')
                                  + ' ('
                                  + get(currentPort, 'locode') + ')'
                                }
                              </label>
                            </Grid>
                          }
                          {!currentPort && getConflictingValue(portKey)}
                        </Grid>
                      </Grid>

                      {!currentPort && getErrorMessage(portKey)}
                    </Grid>
                  )
                })}
              </Grid>
              {/* End of Port Information */}

              {/* Itinerary Id */}
              <Grid container direction='row'
                className='py-24'>
                <Grid item sm={12}
                  className='flex items-center pb-8'>
                  <span className='text-base text-grey-darkest font-bold'>
                    {laneInfoForm.itinerary.label}
                  </span>
                </Grid>

                <Grid item sm={12} md={5}
                  className='flex items-center pb-4'>
                  <span className='medium fg-gray'>
                    {laneInfoForm.itinerary.availableItineraryIds}
                  </span>
                </Grid>
                <Grid item sm={12} md={7}>
                  <Grid container direction="row"
                    alignItems="center"
                    justify="flex-end">
                    {itineraryIds &&
                      <Grid item className="text-right medium">
                        <label>{itineraryIds.join(', ')}</label>
                      </Grid>
                    }
                    {!itineraryIds && getConflictingValue('itineraryIds')}
                  </Grid>
                </Grid>

                {!itineraryIds && getErrorMessage('itineraryIds')}
              </Grid>
              {/* End of Itinerary Id */}

              {find(errors, item => !knownErrors.includes(item.originProperty)) &&
                /* Additional Errors */
                <Grid container className={'px-20 py-12 mt-20 bg-light-orange'} alignItems='center'>
                  <Grid item xs={12}
                    className="pb-12">
                    <label className={'text-16 semi-bold fg-orange'}>
                      Additional Errors</label>
                  </Grid>
                  {filter(errors, item => !knownErrors.includes(item.originProperty))
                    .map((errorItem, errorIndex) => {
                      return (
                        <Grid item xs={12}
                          key={errorIndex}
                          className="pb-4">
                          <label className={'text-10 fg-gray'}>
                            {errorItem.message}
                          </label>
                        </Grid>
                      )
                    })
                  }
                </Grid>
                /* END of Additional Errors */
              }

              {(hasError !== 'XRP003' && get(state, 'isSpot', false)) &&
                /* Spot reason */
                <Grid item xs={12}
                  className="field-control">
                  <Grid container alignItems="center">
                    <label className="text-16 semi-bold">
                      Specify your reason to move it to spot booking &nbsp;</label>
                    <label className="text-16 regular require-field">(For internal team)</label>
                  </Grid>
                  <TextField className='theme-text-field'
                    placeholder="Specify your reason…"
                    name="spotReason"
                    value={get(state, 'spotReason')}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleInputChange} />
                </Grid>
                /* End Spot reason */
              }

              {/* Actions */}
              <Grid container justify='space-between'
                className='pt-20'>
                <Grid item sm={12} md={6}>
                  {(!hasError && get(state, 'isSpot', false) === false) &&
                    <LoadingButton className="btn w-full"
                      loading={requesting}
                      onClick={onSubmit}>
                      {submitAction === 'create' && general.createBooking}
                      {submitAction === 'update' && general.updateBooking}
                      {submitAction === 'amend' && general.amendBooking}
                    </LoadingButton>
                  }
                  {(get(state, 'isSpot', false) === true || hasError) &&
                    <LoadingButton className="btn btn_orange w-full"
                      loading={requesting}
                      onClick={onSubmit}
                      disabled={hasError !== 'XRP003' && get(state, 'isSpot', false) && !get(state, 'spotReason')}>
                      {(hasError === 'XRP003' || get(state, 'isSpot', false)) ?
                        general.moveToSpot :
                        general.moveToExceptions
                      }
                    </LoadingButton>
                  }
                </Grid>
                <Grid item sm={12} md={6}
                  className='flex justify-end'>
                  <Button className={'btn btn_transparent'}
                    disabled={requesting}
                    onClick={() => go(-1)}>
                    {general.back}
                  </Button>
                </Grid>
                {(get(state, 'isSpot', false) === true || hasError) &&
                  <Grid item xs={12} className="pt-6">
                    <label className="text-10 fg-gray">
                      Once you {(hasError === 'XRP003' || get(state, 'isSpot', false)) ?
                        general.moveToSpot :
                        general.moveToExceptions
                      } you can find this shipment in <strong>Exceptions</strong> tab</label>
                  </Grid>
                }
              </Grid>
              {/* End of Actions */}

            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} md={4}>
          <BookingWizardSummary shipment={shipment} />
        </Grid>
      </Grid>
    </div>
  )
}
export default BookingLaneForm
