export function getDistinctContainers(containers) {
  const group = containers.reduce((acc, it) => {
    acc[it.type] = acc[it.type] + 1 || 1;
    return acc;
  }, {});
  let result = '';
  Object.keys(group).forEach(function (key, index) {
    if (index > 0) {
      result += ('\n')
    }
    result += (key + ' x ' + group[key]);
  });
  return result;
}
