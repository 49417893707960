import React, { useEffect, useState } from 'react'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions,
  FuseTextField
} from '../../core/components'
import {
  Grid,
  Button,
  withStyles,
  Typography,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel
} from '@material-ui/core'
import classNames from 'classnames'
import _, { get } from 'lodash'
import { addGaEvent } from '../../core/helpers'

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const CSInfoSideBar = ({ open, classes, shipment, csInfo, closeSideBar, updateCS }) => {
  const [state, setState] = useState([])
  const containerOptions = _.get(shipment, 'containers', []).map((option, index) => ({
    value: option._id,
    label: get(option, 'equipmentNo') ? get(option, 'equipmentNo') : `${option.type} (${index + 1})`
  }))

  useEffect(() => {
    const state = csInfo.map(cs => {
      return {
        volumeUnit: _.get(cs, 'volume.unit', ''),
        weightUnit: _.get(cs, 'weight.unit', ''),
        CSCode: cs.CSCode,
        volume: _.round(_.get(cs, 'volume.value', 0), 3),
        weight: _.round(_.get(cs, 'weight.value', 0), 3),
        packagedVolume: _.round(_.get(cs, 'packagedVolume.value', 0), 3),
        packagedWeight: _.round(_.get(cs, 'packagedWeight.value', 0), 3),
        packages: _.get(cs, 'packages', 0),
        container: _.get(cs, 'containerId', ''),
        invoice: _.get(cs, 'invoice', ''),
        volume_error: null,
        weight_error: null,
        packages_error: null,
        container_error: null
      }
    })
    setState(state)
  }, [csInfo, shipment])

  useEffect(() => {
    if (open) {
      addGaEvent('shipment-updatingConsolidation', get(shipment, '_id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const setInIndex = (obj, index) => {
    setState([...state.slice(0, index), obj, ...state.slice(index + 1)])
  }

  const updateConsolidations = () => {
    const data = state.map(el => {
      return {
        CSCode: el.CSCode,
        volume: {
          value: el.packagedVolume,
          unit: el.volumeUnit
        },
        weight: {
          value: el.packagedWeight,
          unit: el.weightUnit
        },
        containerId: el.container,
        packages: el.packages,
        invoice: el.invoice
      }
    })
    updateCS(data)
  }
  return (
    <FuseSideBar open={open}>
      <FuseSideBarHeader text={`CS Info`}
        handleClose={() => {
          addGaEvent('shipment-updatingConsolidationCancelled', get(shipment, '_id'))
          closeSideBar()
        }} />
      <FuseSideBarBody style={{
        padding: 0,
        overflowX: 'hidden'
      }}>
        <Grid container>
          <Grid xs={12} item className={classes.banner}>
            <Typography className={classes.bannerTitle}>
              Total: {csInfo ? csInfo.length : 0} CS</Typography>
          </Grid>
        </Grid>
        {state.map((cs, index) => {
          return (
            <Grid container
              className={classes.csBlock}
              spacing={2}
              key={index}>
              <Grid xs={12} item
                className={classes.csBlockTitle}>
                <Typography className={classes.csTitle}>
                  {cs.CSCode}</Typography>
              </Grid>

              {/* Estimated Weight */}
              <Grid sm={6} item>
                <Grid container>
                  <Grid item xs={12}
                    className="text-12 fg-gray regular">
                    Estimated Weight
                  </Grid>
                  <Grid item xs={12}
                    className="text-14 uppercase medium">
                    {`${cs.weight} ${cs.weightUnit}`}
                  </Grid>
                </Grid>
              </Grid>
              {/* End of Estimated Weight */}

              {/* Estimated Volume */}
              <Grid sm={6} item>
                <Grid container>
                  <Grid item xs={12}
                    className="text-12 fg-gray regular">
                    Estimated Volume
                  </Grid>
                  <Grid item xs={12}
                    className="text-14 uppercase medium">
                    {`${cs.volume} ${cs.volumeUnit}`}
                  </Grid>
                </Grid>
              </Grid>
              {/* End of Estimated Volume */}

              {/* Packaged Weight */}
              <Grid sm={6} item>
                <FuseTextField
                  className={classNames('mb-8', classes.csField)}
                  type='number'
                  name='weight'
                  label='Packaged Weight'
                  icon={{ ...baseTextSetting }}
                  value={cs.packagedWeight}
                  onChange={e => {
                    if (e.target.value >= 0) {
                      setInIndex({ ...cs, packagedWeight: e.target.value }, index)
                    }
                  }}
                  required
                  error={cs.weight_error !== null}
                  helperText={cs.weight_error}
                />
              </Grid>
              {/* End of Packaged Weight */}

              {/* Packaged Volume */}
              <Grid sm={6} item>
                <FuseTextField
                  className={classNames('mb-8', classes.csField)}
                  type='number'
                  name='volume'
                  label='Packaged Volume'
                  icon={{ ...baseTextSetting }}
                  value={cs.packagedVolume}
                  onChange={e => {
                    if (e.target.value >= 0) {
                      setInIndex({ ...cs, packagedVolume: e.target.value }, index)
                    }
                  }}
                  required
                  error={cs.volume_error !== null}
                  helperText={cs.volume_error}
                />
              </Grid>
              {/* End of Packaged Volume */}

              {/* No of packages */}
              <Grid sm={6} item>
                <FuseTextField className={classNames('mb-8', classes.csField)}
                  type='number'
                  name='packages'
                  label='No of packages'
                  icon={{ ...baseTextSetting }}
                  value={cs.packages}
                  onChange={e => {
                    if (e.target.value >= 0) {
                      setInIndex({ ...cs, packages: e.target.value }, index)
                    }
                  }}
                  required
                  error={cs.packages_error !== null}
                  helperText={cs.packages_error}
                />
              </Grid>
              {/* End of No of packages */}

              {/* Container */}
              <Grid sm={6} item>
                <FormControl className={classes.field}>
                  <InputLabel shrink className={classes.label}>
                    Container</InputLabel>
                  <Select id='customized-select'
                    placeholder={'Select container'}
                    onChange={e => {
                      setInIndex({ ...cs, container: e.target.value }, index)
                    }}
                    value={cs.container}
                    error={cs.container_error !== null}
                    className={classNames('mb-8', classes.select)}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      }
                    }}>
                    <MenuItem value='' disabled>
                      Select Container</MenuItem>
                    {containerOptions.map((option, index) => {
                      return (
                        <MenuItem key={index}
                          value={option.value}>
                          {option.label}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {cs.container_error !== null && (
                    <FormHelperText className={classes.selectErrorMessage}>
                      Required</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* End of Container */}

              {/* Invoice Number */}
              <Grid sm={12} item>
                <FuseTextField
                  className={classNames('mb-8', classes.csField)}
                  type='text'
                  name='invoice'
                  label='Invoice Number'
                  icon={{ ...baseTextSetting }}
                  value={cs.invoice}
                  onChange={e => {
                    setInIndex({ ...cs, invoice: e.target.value }, index)
                  }}
                />
              </Grid>
              {/* End of Packaged Volume */}

            </Grid>
          )
        })}
      </FuseSideBarBody>
      <FuseSideBarActions>
        <Button className='btn btn_full-space'
          onClick={updateConsolidations}>
          Save</Button>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

const styles = {
  banner: {
    backgroundColor: '#ECECED',
    height: 40,
    textAlign: 'left',
    paddingLeft: 20
  },
  bannerTitle: {
    color: '#9FA0A4',
    fontSize: 13,
    paddingTop: 10
  },
  csBlock: {
    padding: '10px 20px',
    '&:nth-child(2)': {
      paddingTop: '20px'
    },
    '&:last-child': {
      paddingBottom: '20px'
    }
  },
  csBlockTitle: {
    marginBottom: 5
  },
  csTitle: {
    fontFamily: 'IBMPlexSans-Medium',
    fontSize: 16,
    fontWeight: 600
  },
  csField: {
    width: '100%',
    '& input': {
      fontFamily: 'IBMPlexSans',
    }
  },
  field: {
    width: '100%',
    marginTop: 4
  },
  label: {
    color: '#AEAFB2',
    marginBottom: 15,
    fontWeight: 300,
    fontSize: 15
  },
  select: {
    backgroundColor: '#FFF',
    fontFamily: 'IBMPlexSans',
    '& > div ': {
      backgroundColor: '#FFF'
    }
  },
  selectErrorMessage: {
    color: '#F44335'
  }
}

export default withStyles(styles)(CSInfoSideBar)
