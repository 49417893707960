import React, { useEffect, useState } from 'react'
import {
  Grid,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputBase,
  withStyles
} from '@material-ui/core'
import { filter, get, set, find } from 'lodash'
import classNames from 'classnames'
import { useMergeState, copyFirstContainerEvents } from '../../core/helpers'
import { bookingInfoForm, general } from '../../core/localization'
import { InputTimeline } from '../../core/components'
import BookingWizardSummary from '../../manage-booking/components/ProposeBookingSummary'
import _ from 'lodash'
import { GooglePlaceSearch } from '../../core/components/Inputs'
import DateTime from 'react-datetime'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.054)',
    border: '1px solid #ced4da',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '6px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'IBMPlexSans-SemiBold'
  }
}))(InputBase)

const BookingForm = props => {
  const {
    data: {
      shipment
    },
    go,
    existingFormData,
    onChange,
    mode
  } = props
  const [state, setState] = useMergeState({})
  const [validated, setValidated] = useState(true)

  useEffect(() => {
    if (existingFormData) {
      setState(existingFormData)
      setValidated(checkValidation(existingFormData))
    } else {
      setValidated(false)
      if (shipment) {
        var newState = {
          type: get(shipment, 'type'),
          groupageDate: get(shipment, 'groupageDate'),
        }

        if (get(newState, 'type') === 'FCL') {
          set(newState, 'containers', get(shipment, 'containers', []))
        }

        if (get(newState, 'type') === 'LCL') {
          set(newState, 'truckInCargo',
            {
              ...get(shipment, 'referenceEvents.truckInForCargoPickup', {}),
              floorUnitNumber: get(
                shipment,
                'referenceEvents.truckInForCargoPickup.location.floorUnitNumber',
                ''
              ),
            }
          )
        }
        onChange('bookingForm', newState)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, existingFormData])

  const checkValidation = (updatedState) => {
    const type = get(updatedState, 'type')
    if (!type) {
      return false
    }
    if (!get(updatedState, 'groupageDate')) {
      return false
    }
    if (type === 'FCL') {
      var hasError = find(get(updatedState, 'containers', []), container => {
        return (!get(container, 'type') ||
          !get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.location.street') ||
          !get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt')
        )
      })

      if (get(updatedState, 'containers', []).length === 0) {
        hasError = true
      }
      return hasError ? false : true
    }
    if (type === 'LCL') {
      if (!get(updatedState, 'truckInCargo.location.street')) {
        return false
      }
      if (!get(updatedState, 'truckInCargo.expectedAt')) {
        return false
      }
    }
    return true
  }

  const handleChange = truckMode => event => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [truckMode]: {
        ...state[truckMode],
        [name]: value
      }
    }

    onChange('bookingForm', updatedState)
  }

  const handleChangeByClick = (name, value) => {
    const updatedState = {
      ...state,
      [name]: value
    }

    onChange('bookingForm', updatedState)
  }

  const handleDateChange = (date, name, section) => {
    date = new Date(date)
    if (date === 'Invalid Date') {
      date = undefined
    }

    const updatedState = {
      ...state,
      [section]: {
        ...state[section],
        [name]: date
      }
    }

    onChange('bookingForm', updatedState)
  }

  const updateAddress = (address, lat, lng, name) => {
    const updatedState = {
      ...state,
      [name]: {
        ...state[name],
        location: {
          ...address,
          latitude: lat.toString(),
          longitude: lng.toString()
        }
      }
    }

    onChange('bookingForm', updatedState)
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [name]: value
    }

    onChange('bookingForm', updatedState)
  }

  const handleManageContainer = (action, containerIndex = null) => {
    var updatedState = {
      ...state
    }

    if (action === 'add') {
      updatedState.containers = [...get(state, 'containers', []), { isNew: true }]
    } else if (action === 'remove') {
      var containers = get(state, 'containers', [])
      containers.splice(containerIndex, 1)
      updatedState.containers = containers
    }

    onChange('bookingForm', updatedState)
  }

  const handleUpdateContainerType = (event, index) => {
    const { value } = event.target
    var containers = get(state, 'containers', [])
    set(containers[index], 'type', value)
    const updatedState = {
      ...state,
      containers
    }

    onChange('bookingForm', updatedState)
  }

  const handleUpdateContainerPlace = (address, lat, lng, index) => {
    var containers = get(state, 'containers', [])
    set(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {
      ...get(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {}),
      ...address,
      latitude: lat.toString(),
      longitude: lng.toString()
    })

    const updatedState = {
      ...state,
      containers
    }

    onChange('bookingForm', updatedState)
  }

  const handleUpdateContainerAdditionalAddress = (event, index) => {
    const { name, value } = event.target
    var containers = get(state, 'containers', [])
    set(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {
      ...get(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {}),
      [name]: value
    })

    const updatedState = {
      ...state,
      containers
    }

    onChange('bookingForm', updatedState)
  }

  const handleUpdateContainerDateTime = (date, index) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    var containers = get(state, 'containers', [])
    set(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt', new Date(date))

    const updatedState = {
      ...state,
      containers
    }

    onChange('bookingForm', updatedState)
  }

  const inputData = {
    type: 'booking',
    label: bookingInfoForm.schedules.label,
    instruction: '',
    items: [
      {
        img: 'assets/images/icons/wizard-arrow-down-circle.png',
        label: bookingInfoForm.schedules.truckInCargo.label,
        instruction: bookingInfoForm.schedules.truckInCargo.instruction,
        section: 'truckInCargo',
        text1Name: 'location',
        text1Value: _.get(state, 'truckInCargo.location.street'),
        text1Label: bookingInfoForm.schedules.field.place,
        text1UpdateAddress: (address, lat, lng) => updateAddress(address, lat, lng, 'truckInCargo'),
        text2Name: 'floorUnitNumber',
        text2Value: _.get(state, 'truckInCargo.floorUnitNumber'),
        text2Label: bookingInfoForm.schedules.field.additionalAddress,
        text3Name: 'expectedAt',
        text3Value: _.get(state, 'truckInCargo.expectedAt'),
        text3Label: bookingInfoForm.schedules.field.dateTime
      }
    ]
  }

  const s20Count = filter(state.containers, { type: 'C20GP' }).length
  const s40Count = filter(state.containers, { type: 'C40GP' }).length
  const hc40Count = filter(state.containers, { type: 'C40HC' }).length

  return (
    <div>
      <Grid container direction='row'>
        <Grid item sm={12} md={8} className={'booking-forms'}>
          {/* Shipment Type */}
          <Grid container direction='row' className={'field-control'}>
            <Grid item sm={12} md={12} className='flex items-center mb-12'>
              <label className={'field-label require-field'}>
                {bookingInfoForm.shipmentType.label}
              </label>
            </Grid>
            <Grid item sm={12} md={12} className='flex items-center justify-between'>
              <Button
                onClick={() => handleChangeByClick('type', 'FCL')}
                className={classNames('flex justify-around option-button', { active: get(state, 'type') === 'FCL' })}
              >
                <img src='assets/images/icons/fcl-icon.svg' alt='FCL' />
                {bookingInfoForm.shipmentType.options.fcl}
              </Button>
              <Button
                onClick={() => handleChangeByClick('type', 'LCL')}
                className={classNames('flex justify-around option-button', { active: get(state, 'type') === 'LCL' })}
              >
                <img src='assets/images/icons/lcl-icon.svg' alt='LCL' />
                {bookingInfoForm.shipmentType.options.lcl}
              </Button>
            </Grid>
          </Grid>
          {/* End of Shipment Type */}

          {/* Containers and schedule */}
          {get(state, 'type') === 'FCL' && (
            <Grid container direction='row' alignItems='center' className={'field-control'}>
              <Grid item sm={12} md={6}>
                <label className={'field-label require-field'}>Containers and schedule</label>
              </Grid>
              <Grid item sm={12} md={6}>
                <Grid container alignItems='center' justify='flex-end'>
                  <label className={'field-instruction'}>
                    <span className='medium'>20'ST - {s20Count}</span>
                    <span className='px-6'>&bull;</span>
                    <span className='medium'>40'ST - {s40Count}</span>
                    <span className='px-6'>&bull;</span>
                    <span className='medium'>40'HC - {hc40Count}</span>
                  </label>
                </Grid>
              </Grid>

              <Grid container direction='row' className='stepper'>
                {get(state, 'containers', []).map((container, containerIndex) => {
                  const containerType = get(container, 'type')

                  return (
                    <Grid container key={`container-${containerIndex}`}
                      direction='row'
                      className='step pb-24'>
                      <Grid container direction='row'
                        className='step-header'>
                        <span className='step-icon'>
                          <img src='assets/images/icons/timeline-step/storage-facility-blue.svg'
                            alt='container-booking' />
                        </span>
                        <Grid item sm={12} md={6} className='flex items-center'>
                          <Grid container>
                            <Grid item xs={7}>
                              <FormControl fullWidth className="require-field">
                                <Select id='demo-customized-select'
                                  value={containerType || 'default'}
                                  onChange={event => {
                                    handleUpdateContainerType(event, containerIndex)
                                  }}
                                  placeholder='Select type...'
                                  input={<BootstrapInput />}>
                                  <MenuItem key={`type-default`}
                                    value='default' selected disabled>
                                    Select type...</MenuItem>
                                  {['C20GP', 'C40GP', 'C40HC'].map((type, typeIndex) => {
                                    return (
                                      <MenuItem key={`type-${typeIndex}`} value={type}>
                                        {general.container[type]}
                                      </MenuItem>
                                    )
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={5} />
                          </Grid>
                        </Grid>
                        <Grid item sm={12} md={6} className='flex items-center justify-end'>
                          {containerIndex > 0 && (
                            <label
                              className='fg-blue cursor-pointer mr-16'
                              onClick={() => {
                                const updatedState = {
                                  ...state,
                                  containers: copyFirstContainerEvents(
                                    get(state, 'containers', []),
                                    containerIndex,
                                    ['truckInToSWHWithEmptyContainer']
                                  )
                                }

                                onChange('bookingForm', updatedState)
                              }}
                            >
                              Copy from first
                            </label>
                          )}
                          <label className={'fg-red cursor-pointer'}
                            onClick={() => handleManageContainer('remove', containerIndex)}>
                            Remove
                          </label>
                        </Grid>
                      </Grid>

                      <Grid container direction='row' className='step-content'>
                        <Grid item sm={12} className='pb-16'>
                          <label className={'formsy-form-control__label require-field'}>
                            Drop container at warehouse</label>
                          <GooglePlaceSearch className='theme-text-field'
                            placeholder='Select street address...'
                            value={get(
                              container,
                              'referenceEvents.truckInToSWHWithEmptyContainer.location.street',
                              ''
                            )}
                            updateCall={(address, lat, lng) => {
                              handleUpdateContainerPlace(address, lat, lng, containerIndex)
                            }}
                          />
                        </Grid>
                        <Grid item sm={12} md={7} className='pr-8'>
                          <TextField name='floorUnitNumber'
                            className='theme-text-field'
                            value={get(
                              container,
                              'referenceEvents.truckInToSWHWithEmptyContainer.location.floorUnitNumber',
                              ''
                            )}
                            label='Additional address'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => {
                              handleUpdateContainerAdditionalAddress(event, containerIndex)
                            }}
                          />
                        </Grid>
                        <Grid item sm={12} md={5} className='pl-6'>
                          <Grid container>
                            <Grid item xs={12}>
                              <label className='formsy-form-control__label require-field'>
                                Date & time</label>
                            </Grid>
                            <Grid item xs={12} className='relative'>
                              <DateTime className="theme-date-time-picker-field"
                                input
                                dateFormat="DD MMM YYYY,"
                                onChange={(date) => {
                                  handleUpdateContainerDateTime(date, containerIndex)
                                }}
                                value={
                                  get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt') &&
                                  new Date(get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt'))
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}

                <Grid container direction='row' className='step pb-24'>
                  <Grid container direction='row' className='step-header' justify='space-between'>
                    <span className='step-icon'>
                      <img src='assets/images/icons/vessel-info/add-transhipment.png' alt='booking' />
                    </span>
                    <Grid item>
                      <Button className='btn btn_sm btn_light-blue border-solid border-1 border-blue normal-fonts'
                        onClick={() => handleManageContainer('add')}>Add container</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* End of Containers and schedule */}

          {/* Schedules */}
          {get(state, 'type') === 'LCL' && (
            <InputTimeline data={inputData}
              onChange={handleChange}
              onDateChange={handleDateChange} />
          )}
          {/* End of Schedules */}

          {/* Groupage date */}
          <Grid container direction='row' className='field-control' alignItems='center'>
            <Grid item sm={12} md={6}>
              <label className='field-label'>Groupage date</label>
            </Grid>
            <Grid item sm={12} md={6} className='relative'>
              <label className='text-10 regular fg-gray require-field'>
                Select date</label>
              <DateTime
                className='theme-date-time-picker-field'
                input
                dateFormat='DD MMM YYYY'
                timeFormat={false}
                onChange={groupageDate => {
                  if (new Date(groupageDate).toString() === 'Invalid Date') {
                    groupageDate = new Date()
                  }
                  onChange('bookingForm', { ...state, groupageDate })
                }}
                value={get(state, 'groupageDate') && new Date(get(state, 'groupageDate'))}
              />
            </Grid>
          </Grid>
          {/* END Groupage date */}

          {/* Add Note */}
          <Grid item xs={12} className='pt-20 field-control'>
            <Grid container alignItems='center'>
              <label className='text-16 medium'>Add Note&nbsp;</label>
              <label className='text-16 regular'>(For booking partners)</label>
            </Grid>
            <TextField
              className='theme-text-field'
              placeholder='You can add any extra note for this booking'
              name='note'
              value={get(state, 'note')}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
            />
          </Grid>
          {/* END Add Note */}

          {/* Booking Info actions */}
          <Grid container justify='space-between' className='pt-20'>
            <Grid item sm={12} md={6}>
              <Button className='btn w-full'
                disabled={!validated}
                onClick={() => go(1)}>{general.next}</Button>
            </Grid>
          </Grid>
          {/* End of Booking Info actions */}
        </Grid>

        <Grid item sm={12} md={4}>
          <BookingWizardSummary shipment={mode === 'edit' ?
            { ...shipment, ...state } :
            null
          } />
        </Grid>
      </Grid>
    </div>
  )
}

export default BookingForm
