import React, { useState, useEffect } from 'react'
import { Grid, withStyles, Typography, Slide } from '@material-ui/core'
import { connect } from 'react-redux'
import SubToolBarLayout from '../../core/containers/Layout/SubToolBarLayout'
import * as Actions from '../store/actions'
import { get, orderBy } from 'lodash'
import history from '../../history'
import NoticeList from '../components/NoticeList'
import NoticeDetails from '../components/NoticeDetails'
import * as CoreActions from '../../core/store/actions'

const NoticeBoard = ({
  noticeBoard,
  userInfo,
  getNotices,
  getNoticeDetails,
  addNoticeComment,
  openNotice,
  closeNotice,
  match,
  getPlants,
  plants,
  updateNotice
}) => {
  const requestedNoticeDetails = get(noticeBoard, 'details', {})
  const [noticeDetails, setNoticeDetails] = useState(null)
  const noticeId = get(match, 'params.id')

  const handleNoticeClick = (notice) => {
    history.push(`/notice-board/${notice._id}`)
  }

  const handleBack = () => {
    setNoticeDetails(null)
    getNoticeDetails()
    getNotices(true)
    history.push(`/notice-board`)
  }

  useEffect(() => {
    if (noticeDetails) {
      if (requestedNoticeDetails) setNoticeDetails(requestedNoticeDetails)
      else setNoticeDetails(noticeDetails)
    }
  }, [requestedNoticeDetails, noticeDetails])

  useEffect(() => {
    if (noticeId) {
      setNoticeDetails({ loading: true })
      getNoticeDetails(noticeId, null, handleBack)
    } else {
      handleBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticeId])

  useEffect(() => {
    getPlants()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <SubToolBarLayout text='Notice Board' />

      <div className="p-20 pb-72 h-full overflow-auto">
        <Grid container
          className="regular text-14">

          {noticeDetails === null &&
            <Slide in={noticeDetails === null} direction="down">
              <Grid item xs={12}>
                <NoticeList isLoading={get(noticeBoard, 'loading', true)}
                  notices={orderBy(get(noticeBoard, 'data', []), 'status', 'desc')}
                  total={get(noticeBoard, 'total', 0)}
                  showLoadMore={get(noticeBoard, 'showLoadMore', false)}
                  loadingMore={get(noticeBoard, 'loadingMore', false)}
                  onNoticeClick={handleNoticeClick}
                  getNotices={getNotices}
                  openNotice={openNotice}
                  closeNotice={closeNotice} />
              </Grid>
            </Slide>
          }

          {noticeDetails !== null &&
            <Grid item xs={12}
              className="bg-white shadow rounded p-20">
              <Slide in={noticeDetails !== null} direction="left">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item>
                        <Typography className="fg-blue bg-light-blue uppercase bold px-12 py-4 rounded cursor-pointer"
                          onClick={handleBack}>
                          &lt; back</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <NoticeDetails notice={noticeDetails}
                      userInfo={userInfo}
                      getNoticeDetails={getNoticeDetails}
                      openNotice={openNotice}
                      closeNotice={closeNotice}
                      addNoticeComment={addNoticeComment}
                      plants={plants}
                      updateNotice={updateNotice} />
                  </Grid>
                </Grid>
              </Slide>
            </Grid>
          }
        </Grid>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ noticeBoard, auth: { info }, core: { plants } }) => ({
  noticeBoard,
  userInfo: info,
  plants
})

const mapDispatchToProps = dispatch => ({
  getNotices: (...args) => dispatch(Actions.getNotices(...args)),
  getNoticeDetails: (...args) => dispatch(Actions.getNoticeDetails(...args)),
  openNotice: (...args) => dispatch(Actions.openNotice(...args)),
  closeNotice: (...args) => dispatch(Actions.closeNotice(...args)),
  addNoticeComment: (...args) => dispatch(Actions.addNoticeComment(...args)),
  getPlants: (...args) => dispatch(CoreActions.getPlants(...args)),
  updateNotice: (...args) => dispatch(Actions.updateNotice(...args)),
})

const styles = {}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NoticeBoard));
