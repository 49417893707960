import React, { useState } from 'react'
import { Grid, CircularProgress } from '@material-ui/core'
import TransportTimeline from '../../core/components/Timelines/TransportTimeline'
import _ from 'lodash'
import { formatTimestamp, getAddressFromLocation } from '../../core/helpers'
import { ShipmentRouteSkeleton } from "./skeletons";

const ShipmentRoute = ({ plannedRoute, latestEvent, shipment: { journey, journeyCurrentIdx } }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  if (journey) {
    const transportRoute = getTransportRoute(journey, journeyCurrentIdx, plannedRoute)
    const mapImageSrc = getMapImageSrc(journey)
    return (
      <Grid container className='shipment-details-container shipment-route'>
        <Grid item xs={12}>
          <Grid container alignItems="center" justify="center"
            style={{
              minHeight: 226,
              background: '#aad9fe',
              overflow: 'hidden',
              padding: 5
            }}>
            {!imageLoaded &&
              <CircularProgress />
            }
            <a target='_blank'
              rel='noopener noreferrer'
              href={mapImageSrc}
              className={!imageLoaded ? 'invisible h-0 w-0' : ''}>
              <img src={mapImageSrc} alt='map-view' onLoad={() => setImageLoaded(true)} />
            </a>
          </Grid>
        </Grid>
        <Grid item xs={12} className='p-16 shipment-route-status'>
          <Grid container>
            <Grid item xs={12} className='shipment-route-status-text'>
              {_.get(latestEvent, 'name') || _.get(latestEvent, 'code')}
            </Grid>
            <Grid item xs={12} className='shipment-route-status-date-time'>
              {formatTimestamp(_.get(latestEvent, 'occurenceAt'), 'date-time')}
            </Grid>
          </Grid>
        </Grid>
        {
          transportRoute.length !== 0 &&
          <Grid item xs={12} className='p-16 shipment-route-timeline'>
            <TransportTimeline data={transportRoute} />
          </Grid>
        }
      </Grid >
    )
  } else {
    return <ShipmentRouteSkeleton />
  }
}

const getMapImageSrc = (journey, plannedRoute) => {
  const pathCoordinates = []
  const portCoordinates = []
  let originWarehouse = journey.find(obj => obj.location.type === 'warehouse' && obj.location.subType === 'origin')
  let originPort = journey.find(obj => obj.location.type === 'port' && obj.location.subType === 'origin')
  let destinationPort = journey.find(obj => obj.location.type === 'port' && obj.location.subType === 'destination')
  let destinationWarehouse = journey.find(obj => obj.location.type === 'warehouse' && obj.location.subType === 'destination')
  if (_.get(originWarehouse, 'location.address.latitude') && _.get(originWarehouse, 'location.address.longitude')) {
    pathCoordinates.push(
      _.get(originWarehouse, 'location.address.latitude') + ',' + _.get(originWarehouse, 'location.address.longitude')
    )
  }
  if (_.get(originPort, 'location.address.latitude') && _.get(originPort, 'location.address.longitude')) {
    pathCoordinates.push(
      _.get(originPort, 'location.address.latitude') + ',' + _.get(originPort, 'location.address.longitude')
    )
    portCoordinates.push(
      _.get(originPort, 'location.address.latitude') + ',' + _.get(originPort, 'location.address.longitude')
    )
  }
  if (_.get(destinationPort, 'location.address.latitude') && _.get(destinationPort, 'location.address.longitude')) {
    pathCoordinates.push(
      _.get(destinationPort, 'location.address.latitude') + ',' + _.get(destinationPort, 'location.address.longitude')
    )
    portCoordinates.push(
      _.get(destinationPort, 'location.address.latitude') + ',' + _.get(destinationPort, 'location.address.longitude')
    )
  }
  if (
    _.get(destinationWarehouse, 'location.address.latitude') &&
    _.get(destinationWarehouse, 'location.address.longitude')
  ) {
    pathCoordinates.push(
      _.get(destinationWarehouse, 'location.address.latitude') +
      ',' +
      _.get(destinationWarehouse, 'location.address.longitude')
    )
  }
  const path = pathCoordinates.join('|')
  const ports = portCoordinates.join('|')
  const baseUrl = window.location.origin
  const benchmarkIcon = `&markers=anchor:center|icon:${baseUrl}/assets/images/icons/line-connector/Shipper-Benchmarks.png|color:blue|size:mid|`
  const portBenchmarkIcon = `&markers=anchor:center|icon:${baseUrl}/assets/images/icons/line-connector/Port-route-Benchmarks.png|color:blue|size:mid|`
  const mapImageSrc =
    'https://maps.googleapis.com/maps/api/staticmap?size=500x280&zoom=auto&path=color:0x0000ff|weight:2|' +
    path + portBenchmarkIcon + ports + benchmarkIcon + path +
    '&geodesic=true&key=AIzaSyAqFoIOLnKjerbivfsG19K7-Y0csP8mcLA'
  return mapImageSrc
}
const activeGreen = '#3dcd58'
const activeBlue = '#42b4e6'
const inactiveColor = '#9FA0A4'
const getColor = (location, isConnector, journeyCurrentIdx) => {
  if (location && journeyCurrentIdx) {
    if (location.index <= journeyCurrentIdx) {
      return isConnector ? activeBlue : activeGreen
    } else {
      return inactiveColor
    }
  } else {
    return ''
  }
}

const getTransportRoute = (journey, journeyCurrentIdx, plannedRoute) => {
  if (journey.length === 0) {
    return []
  }

  let originWarehouse = journey.find(obj => obj.location.type === 'warehouse' && obj.location.subType === 'origin')
  originWarehouse = { ...originWarehouse, index: journey.indexOf(originWarehouse) }

  let originPort = journey.find(obj => obj.location.type === 'port' && obj.location.subType === 'origin')
  originPort = { ...originPort, index: journey.indexOf(originPort) }

  let transhipmentPorts = journey.filter(obj => obj.location.type === 'port' && obj.location.subType === 'transhipment')
  transhipmentPorts = transhipmentPorts.map(tp => {
    return { ...tp, index: journey.indexOf(tp) }
  })

  let destinationPort = journey.find(obj => obj.location.type === 'port' && obj.location.subType === 'destination')
  destinationPort = { ...destinationPort, index: journey.indexOf(destinationPort) }

  let destinationWarehouse = journey.filter(obj => obj.location.type === 'warehouse' && obj.location.subType === 'destination')
  destinationWarehouse = destinationWarehouse.map(dw => {
    return { ...dw, index: journey.indexOf(dw) }
  })

  let route = [originWarehouse, originPort, ...transhipmentPorts, destinationPort, ...destinationWarehouse]
  const transportRoute = route.map((node, index) => {
    let address = ''
    if (node.location && node.location.address) {
      address = getAddressFromLocation(_.get(node, 'location.address', 'N/A') || 'N/A')
    }
    return {
      place: _.get(node, 'location.name', 'N/A') || 'N/A',
      address,
      color: getColor(node, false, journeyCurrentIdx),
      icon: _.get(node, 'location.type') === 'port' ? 'pin_drop' : 'home',
      iconRounded: true,
      connector:
        index === route.length - 1
          ? null
          : {
            icon: _.get(node, 'location.type') === 'port' ? 'directions_boat' : 'local_shipping',
            color: getColor(node, true, journeyCurrentIdx),
            events: _.get(node, 'keyEvents', [])
          }
    }
  })
  return transportRoute
}

export default ShipmentRoute
