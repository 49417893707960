import React, { useEffect, useState } from 'react'
import {
  withStyles,
  Grid,
  Icon,
  Fade,
} from '@material-ui/core'
import {
  LoadingButton,
  FuseCheckBoxGroup,
  InfoToolTip
} from '../../core/components'
import { useMergeState, getRandomInt } from '../../core/helpers'
import { get, filter, find, uniqBy } from 'lodash'
import AutoComplete from '../../core/components/Inputs/AutoComplete'
import classNames from 'classnames'
import ManageUserInfoDialog from './ManageUserInfoDialog'
import EventNotificationListSkeleton from '../../core/components/Skeletons/EventNotificationListSkeleton'

const styles = {
  notificationBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 105,
    height: 30,
    borderRadius: 15,
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: 'IBMPlexSans-Bold',
    cursor: 'pointer',
    lineHeight: 1.1
  },
  btnGreenBorder: {
    color: '#3DCD58 !important',
    border: '1px solid #3DCD58 !important',
    backgroundColor: 'transparent !important',

  },
  btnGreenBorderDashed: {
    color: '#3DCD58 !important',
    backgroundColor: 'rgba(61, 205, 88, 0.2) !important',
    border: '1px dashed #3DCD58 !important',
  }
}

const notificationOptions = [
  {
    label: 'Push',
    value: 'push',
    checked: false
  },
  {
    label: 'Email',
    value: 'email',
    checked: false
  },
  {
    label: 'Text',
    value: 'sms',
    checked: false,
    disabled: true
  },
]

const autoCompleteStyle = {
  menuList: (styles) => ({
    ...styles,
    height: 'auto',
    minHeight: 'auto',
    maxHeight: 120,
  })
}

const Notifications = props => {
  const [state, setState] = useMergeState({})
  const [manageUserInfoDialog, setManageUserInfoDialog] = useState(false)
  const {
    eventsNotification,
    eventsList,
    onEventChange,
    onEventReset,
    onEventSubmit,
    isEventSubmitting,
    disableEventSubmit,
    resetForm,
    classes,
    userInfo,
    updateProfile,
    onGoingTask,
    setOnGoingTask,
    defaultLogin
  } = props

  useEffect(() => {
    setManageUserInfoDialog(get(onGoingTask, 'manageUserInfoDialog', false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onGoingTask])

  useEffect(() => {
    prepareEventNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsList])

  useEffect(() => {
    prepareEventNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsNotification])

  useEffect(() => {
    if (resetForm === 'eventsNotification') {
      prepareEventNotification()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm])

  const prepareEventNotification = () => {
    const notifications = []
    get(eventsNotification, 'data', []).forEach(notification => {
      const settingsName = get(notification, 'settingsName', '').split('--')
      const code = get(settingsName, '[0]', '')
      const type = get(settingsName, '[1]', '')
      const event = find(get(eventsList, 'data', []), { code, type })

      notifications.push({
        _id: get(notification, '_id'),
        name: get(event, 'name', get(notification, 'settingsName', '')),
        settingsName,
        type,
        selection: Object.keys(get(notification, 'value', {})).map(key => {
          if (get(notification, 'value.' + key)) {
            return key
          } else {
            return false
          }
        }).filter(Boolean)
      })
    })

    setState({ notifications })
  }

  const getAvailableTypes = (id) => {
    const allNotifications = get(state, 'notifications', [])
    const currentNotification = find(allNotifications, { _id: id })
    const currentEvents = filter(get(eventsList, 'data', []), {
      code: get(currentNotification, 'code')
    })

    return currentEvents.map(event => {
      return {
        value: get(event, 'type'),
        label: get(event, 'type').charAt(0).toUpperCase() + get(event, 'type').slice(1)
      }
    })
  }

  const handleChange = (event, id) => {
    const { name, value } = event.target

    const notifications = get(state, 'notifications', []).map(notification => {
      if (notification._id === id) {
        if (!notification.isNew) {
          notification.isUpdated = true
        }
        notification[name] = value
      }
      return notification
    })

    setState({ notifications })
    onEventChange('eventsNotification', notifications)
  }

  const addEventNotification = () => {
    const notifications = [
      ...get(state, 'notifications', []),
      {
        _id: getRandomInt(11111111, 99999999),
        isNew: true
      }
    ]

    setState({ notifications })
    onEventChange('eventsNotification', notifications)
  }

  const removeEventNotification = (id) => {
    const notifications = get(state, 'notifications', []).map(notification => {
      if (notification._id === id) {
        if (notification.isNew) {
          return false
        } else {
          notification.isDeleted = true
        }
      }
      return notification
    }).filter(Boolean)

    setState({ notifications })
    onEventChange('eventsNotification', notifications)
  }

  const handleSetManageUserInfoDialog = (params) => {
    if (params === false) {
      setOnGoingTask({ manageUserInfoDialog: params })
    } else {
      setOnGoingTask({
        manageUserInfoDialog: {
          ...manageUserInfoDialog,
          ...params
        }
      })
    }
  }

  return (
    <Grid container className="p-20 regular">
      {/* We will notify you on */}
      <Grid item xs={12} className="py-8">
        <Grid container alignItems="center" className="pb-12">
          <Grid item xs={12}>
            <Grid container
              justify="space-between"
              alignItems="center"
              className="pb-12">
              <Grid item xs={12} sm={12} md={6}
                className="bold text-16">
                We will notify you on</Grid>
              <Grid item xs={12} sm={12} md={6}
                className="text-14 fg-gray text-right">
                We can notify you by...</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="rounded-6 shadow bg-white">
            <Grid container alignItems="center">
              {/* Email */}
              <Grid item xs={12} sm={12} md={6}
                className="p-16 border-dashed md:border-r-1 md:border-b-0 border-r-0 border-b-1 border-gray">
                <Grid container
                  alignItems="center"
                  justify="space-between">
                  <Grid item xs={8}>
                    <Grid container
                      alignItems="center">
                      <Grid item xs={2}>
                        <Grid container
                          alignItems="center"
                          justify="center"
                          className="bg-gray w-40 h-40 rounded-20 p-8 fg-gray">
                          <Icon className="fas fa-envelope text-20 w-auto h-auto" />
                        </Grid>
                      </Grid>
                      <Grid item xs={10} className="text-12 pl-8">
                        <Grid container>
                          {get(userInfo, 'email') &&
                            <Fade in={get(userInfo, 'email')}>
                              <Grid item xs={12} className="bold">
                                {get(userInfo, 'email')}</Grid>
                            </Fade>
                          }
                          {!get(userInfo, 'email') &&
                            <Fade in={!get(userInfo, 'email')}>
                              <Grid item xs={12} className="bold">
                                Add email address</Grid>
                            </Fade>
                          }
                          <Grid item xs={12} className="medium fg-gray">
                            Conversations sent to your mail</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container
                      alignItems="center"
                      justify="flex-end">
                      {(defaultLogin && !get(userInfo, 'email')) &&
                        <Fade in={!get(userInfo, 'email')}>
                          <LoadingButton className={classNames(classes.notificationBtn, classes.btnGreenBorderDashed)}
                            loading={false}
                            onClick={() => {
                              handleSetManageUserInfoDialog({
                                open: true,
                                infoType: "email",
                                title: 'Add Email'
                              })
                            }}>
                            add</LoadingButton>
                        </Fade>
                      }
                      {(defaultLogin && get(userInfo, 'email')) &&
                        <Fade in={get(userInfo, 'email')}>
                          <img src="assets/images/icons/Edit-Pen@2x.png"
                            height={20}
                            alt="edit"
                            className="gray-actions"
                            onClick={() => {
                              handleSetManageUserInfoDialog({
                                open: true,
                                infoType: "email",
                                email: get(userInfo, 'email'),
                                title: 'Update Email'
                              })
                            }} />
                        </Fade>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* End of Email */}

              {/* Phone no */}
              <Grid item xs={12} sm={12} md={6}
                className="p-16">
                <Grid container
                  alignItems="center"
                  justify="space-between">
                  <Grid item xs={8}>
                    <Grid container
                      alignItems="center">
                      <Grid item xs={2}>
                        <Grid container
                          alignItems="center"
                          justify="center"
                          className="bg-gray w-40 h-40 rounded-20 p-8 fg-gray">
                          <Icon className="fas fa-comments text-20 w-auto h-auto" />
                        </Grid>
                      </Grid>
                      <Grid item xs={10} className="text-12 pl-8">
                        <Grid container>
                          {get(userInfo, 'phone') &&
                            <Fade in={get(userInfo, 'phone.phoneNumber', '') !== ''}>
                              <Grid item xs={12} className="bold">
                                {get(userInfo, 'phone.countryCode')}&nbsp;{get(userInfo, 'phone.phoneNumber')}
                              </Grid>
                            </Fade>
                          }
                          {!get(userInfo, 'phone') &&
                            <Fade in={get(userInfo, 'phone.phoneNumber', '') === ''}>
                              <Grid item xs={12} className="bold">
                                Add phone no</Grid>
                            </Fade>
                          }
                          <Grid item xs={12} className="medium fg-gray">
                            Text message on your phone</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container
                      alignItems="center"
                      justify="flex-end">
                      {(defaultLogin && !get(userInfo, 'phone')) &&
                        <Fade in={!get(userInfo, 'phone')}>
                          <LoadingButton className={classNames(classes.notificationBtn, classes.btnGreenBorderDashed)}
                            loading={false}
                            onClick={() => {
                              handleSetManageUserInfoDialog({
                                open: true,
                                infoType: "phone",
                                title: 'Add Phone'
                              })
                            }}>
                            add</LoadingButton>
                        </Fade>
                      }
                      {(defaultLogin && get(userInfo, 'phone')) &&
                        <Fade in={get(userInfo, 'phone')}>
                          <img src="assets/images/icons/Edit-Pen@2x.png"
                            height={20}
                            alt="edit"
                            className="gray-actions"
                            onClick={() => {
                              handleSetManageUserInfoDialog({
                                open: true,
                                infoType: "phone",
                                phone: get(userInfo, 'phone'),
                                title: 'Update Phone'
                              })
                            }} />
                        </Fade>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* End of Phone no */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* End of We will notify you on */}

      {/* Receive notifications */}
      <Grid item xs={12} className="py-8">
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container
              justify="space-between"
              alignItems="center"
              className="pb-12">
              <Grid item xs={12} sm={12} md={6}
                className="bold text-16">
                Receive notifications</Grid>
              <Grid item xs={12} sm={12} md={6}
                className="text-14 fg-gray text-right">
                Get notified by email of phone when...</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="rounded-6 shadow bg-white">
            <Grid container alignItems="center">
              <Grid item xs={12} className="p-20 border-solid border-b-1 border-gray">
                {get(eventsNotification, 'loading') &&
                  <EventNotificationListSkeleton />
                }
                {!get(eventsNotification, 'loading') &&
                  <Grid container>
                    <Grid item xs={12}
                      className="pb-16 border-dashed border-gray border-b-1">
                      {get(state, 'notifications', []).map((notification, index) => {
                        const id = get(notification, '_id')
                        const name = get(notification, 'name', '')
                        const type = get(notification, 'type', '')
                        const selection = get(notification, 'selection', [])
                        const isNew = get(notification, 'isNew', false)
                        const isDeleted = get(notification, 'isDeleted', false)
                        const isUpdated = get(notification, 'isUpdated', false)

                        return (
                          (!isNew) &&
                          <Grid container
                            key={index}
                            className="py-6 relative"
                            alignItems="center"
                            spacing={1}>
                            {(isUpdated || isDeleted) &&
                              <InfoToolTip title={isDeleted ? 'Deleted' : 'Modified'}>
                                <Grid item className={classNames({
                                  'bg-orange': !isDeleted && isUpdated,
                                  'bg-red': isDeleted,
                                })} style={{
                                  position: 'absolute',
                                  height: 6,
                                  width: 6,
                                  left: -7,
                                  top: 19,
                                  borderRadius: 3,
                                  padding: 0
                                }}>&nbsp;</Grid>
                              </InfoToolTip>
                            }
                            <Grid item xs={12} sm={12} md={9}>
                              <Grid container
                                alignItems="center"
                                className="medium text-14">
                                <span>{name}</span>
                                {type &&
                                  <React.Fragment>
                                    <span className="px-8 fg-gray">&bull;</span>
                                    <span className="capitalize fg-gray">{type}</span>
                                  </React.Fragment>
                                }
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                              <Grid container
                                alignItems="center"
                                justify="space-between">
                                <Grid item xs={11}>
                                  <FuseCheckBoxGroup data={notificationOptions}
                                    value={selection}
                                    disabled={isDeleted}
                                    className='flex-1 flex-row justify-between pl-4'
                                    chkClassName='text-14 regular'
                                    onChange={(newSelected) => handleChange({
                                      target: {
                                        name: 'selection',
                                        value: newSelected
                                      }
                                    }, id)}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Grid container justify="flex-end">
                                    <img src="assets/images/icons/settings/delete-event-active.svg"
                                      alt="delete-notification"
                                      className={classNames({
                                        'gray-actions': !isDeleted,
                                        'invisible': isDeleted
                                      })}
                                      onClick={() => removeEventNotification(id)} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>

                    <Grid item xs={12}
                      className="pt-20">
                      {get(state, 'notifications', []).map((notification, index) => {
                        const id = get(notification, '_id')
                        const code = get(notification, 'code', '')
                        const type = get(notification, 'type', '')
                        const selection = get(notification, 'selection', [])
                        const isNew = get(notification, 'isNew', false)

                        return (
                          isNew &&
                          <Grid container
                            key={index}
                            className="mb-12"
                            alignItems="center"
                            spacing={1}>
                            <Grid item xs={12} sm={12} md={9}
                              className="pr-12">
                              <Grid container
                                alignItems="center"
                                className="form-control text-14"
                                spacing={2}>
                                <Grid item xs={12} sm={12} md={4}>
                                  <label className="text-10 regular fg-gray">Events and Documents</label>
                                  <AutoComplete value={code}
                                    options={uniqBy(get(eventsList, 'data', []), 'code').map(event => {
                                      return {
                                        value: get(event, 'code'),
                                        label: get(event, 'name')
                                      }
                                    })}
                                    placeholder="Search for Events and Documents..."
                                    onChange={(value) => {
                                      handleChange({
                                        target: {
                                          name: 'code',
                                          value
                                        }
                                      }, id)
                                    }}
                                    showAllOptions
                                    customStyles={autoCompleteStyle}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={2}>
                                  <label className="text-10 regular fg-gray">Type</label>
                                  <AutoComplete value={type}
                                    options={getAvailableTypes(id)}
                                    placeholder="Select type..."
                                    onChange={(value) => {
                                      handleChange({
                                        target: {
                                          name: 'type',
                                          value
                                        }
                                      }, id)
                                    }}
                                    defaultInitialSelect="actual"
                                    dynamic
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}
                              className="pl-12">
                              <Grid container
                                alignItems="center"
                                justify="space-between">
                                <Grid item xs={11}>
                                  <FuseCheckBoxGroup data={notificationOptions}
                                    value={selection}
                                    className='flex-1 flex-row justify-between pl-4'
                                    chkClassName='text-14 regular'
                                    onChange={(newSelected) => handleChange({
                                      target: {
                                        name: 'selection',
                                        value: newSelected
                                      }
                                    }, id)}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Grid container justify="flex-end">
                                    <img src="assets/images/icons/settings/delete-event-active.svg"
                                      alt="delete-notification"
                                      className="gray-actions"
                                      onClick={() => removeEventNotification(id)} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Grid container
                        className={find(
                          get(state, 'notifications', []),
                          { isNew: true }) ? "mt-20" : ""
                        }
                        alignItems="center">
                        <Grid item xs={12}>
                          <label className="fg-blue medium cursor-pointer text-14"
                            onClick={addEventNotification}>+&nbsp;&nbsp;Add more</label>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
              <Grid item xs={12}>
                <Grid container
                  justify="space-between"
                  alignItems="center"
                  className="px-20 py-16">

                  <Grid item>
                    <LoadingButton className="btn btn_transparent"
                      onClick={onEventReset}
                      style={{
                        padding: '0px',
                        minWidth: 'auto',
                      }}>RESET</LoadingButton>
                  </Grid>
                  <Grid item>
                    <LoadingButton className="btn btn_green min-w-192"
                      loading={isEventSubmitting}
                      disabled={disableEventSubmit}
                      onClick={onEventSubmit}>SAVE CHANGES</LoadingButton>
                  </Grid>

                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* End of Receive notifications */}

      {/* Dialogs */}
      <ManageUserInfoDialog open={get(manageUserInfoDialog, 'open', false)}
        title={get(manageUserInfoDialog, 'title', 'Manage User Info')}
        existingEmail={get(manageUserInfoDialog, 'email')}
        existingPhone={get(manageUserInfoDialog, 'phone')}
        updatedEmail={get(manageUserInfoDialog, 'updatedEmail')}
        updatedPhone={get(manageUserInfoDialog, 'updatedPhone')}
        infoType={get(manageUserInfoDialog, 'infoType')}
        onSubmit={updateProfile}
        handleClose={() => handleSetManageUserInfoDialog(false)}
        handleSetManageUserInfoDialog={handleSetManageUserInfoDialog} />
      {/* End of Dialogs */}
    </Grid>
  )
}

export default withStyles(styles)(Notifications)
