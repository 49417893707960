import React from 'react'
import { Grid } from '@material-ui/core'
import { FuseFileUploader } from '../../core/components'
import { get } from 'lodash'
import DateTime from 'react-datetime';

const ReceivedForm = (props) => {
  const {
    withLabels,
    handleDateChange,
    handleUpdateDocument,
    onPickCurrent
  } = props

  const uploadInfo = "Proof of delivery"

  return (
    <Grid container>
      <Grid item xs={12}>

        {/* Date Time */}
        <Grid container className="pb-12"
          alignItems="flex-start">
          {withLabels &&
            <Grid item xs={12}
              className="text-16 pb-12 semi-bold">
              Add the shipment receive date</Grid>
          }
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <label className='formsy-form-control__label'>
                  Date & time</label>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems="center">
                  <Grid item xs={5}
                    className="pb-8">
                    <DateTime className="theme-date-time-picker-field"
                      input
                      dateFormat="DD MMM YYYY,"
                      onChange={(date) => {
                        if (new Date(date).toString() === 'Invalid Date') {
                          date = new Date()
                        }
                        handleDateChange(date, 'deliveryDate')
                      }}
                      value={get(props, 'deliveryDate') && get(props, 'deliveryDate')}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container justify="flex-end">
                      <Grid item
                        className="cursor-pointer border-dashed border-b-1 border-green fg-green text-10 uppercase semi-bold"
                        onClick={onPickCurrent}>
                        Pick Current date time</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* END of Date Time */}

        {/* POD Document */}
        <Grid container className="pb-24"
          alignItems="flex-start">
          {withLabels &&
            <Grid item xs={12}>
              <Grid container
                justify="space-between"
                alignItems="center"
                className="pb-12">
                <Grid item
                  className="text-16 semi-bold">
                  Upload document</Grid>
                <Grid item
                  className="text-14 fg-gray">
                  POD document</Grid>
              </Grid>
            </Grid>
          }

          <Grid item xs={12}
            className="pb-8">
            <FuseFileUploader fileTitle={uploadInfo}
              type='POD'
              name='POD'
              image='assets/images/icons/Document-upload.png'
              required={false}
              onSuccess={(document) => handleUpdateDocument('POD', document)}
            />
          </Grid>
        </Grid>
        {/* END of POD Document */}

      </Grid>
    </Grid>
  )
}

export default ReceivedForm
