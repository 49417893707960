import { push } from 'react-router-redux'
import ActionTypes from './types'
import * as CoreActions from 'core/store/actions'
import * as WelcomeActions from '../../welcome/store/actions'
import * as API from '../../core/api'
import history from '../../history'
import { get } from 'lodash'

export const apiAttempt = () => ({ type: ActionTypes.API_ATTEMPT })
export const apiSuccess = () => ({ type: ActionTypes.API_SUCCESS })
export const authReset = () => ({ type: ActionTypes.RESET_AUTH })
export const resetLoading = () => ({ type: ActionTypes.AUTH_LOADING_RESET })
export const apiFailed = payload => ({ type: ActionTypes.API_FAILED, payload })
export const autoLoginAttempt = () => ({ type: ActionTypes.AUTO_LOGIN_ATTEMPT })
export const autoLoginFinished = () => ({ type: ActionTypes.AUTO_LOGIN_FINISHED })

export const loginSuccess = payload => ({ type: ActionTypes.LOGIN_SUCCESS, payload })
export const challengeRequired = payload => ({ type: ActionTypes.CHALLENGE_REQUIRED, payload })
export const forgotPasswordOTPReceived = payload => ({ type: ActionTypes.FORGOT_PASSWORD_CHALLENGE, payload })

export const onboardingRequired = () => ({ type: ActionTypes.ONBOARDING_REQUIRED })
export const onboardingCompleted = () => ({ type: ActionTypes.ONBOARDING_COMPLETED })
export const getInfoSuccess = payload => ({ type: ActionTypes.GET_INFO_SUCCESS, payload })

export const logoutSuccess = () => ({ type: ActionTypes.LOGOUT })

export const login = payload => async (dispatch, getState) => {
  const { email, password, hash } = payload
  try {
    dispatch(authReset())
    dispatch(apiAttempt())
    if (payload.autoLogin) {
      dispatch(autoLoginAttempt())
    }
    const { ok, status, data } = await API.login({ email, password })
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(apiSuccess())

    if (data.data.challenge.required) {
      dispatch(
        challengeRequired({
          challengeId: data.data.challenge.id,
          email: payload.email
        })
      )
      dispatch(CoreActions.showMessage({ status, data }))
    } else {
      dispatch(loginSuccess(data.data.token.value))
      API.setTokenHeader(data.data.token.value)

      let info = await getInfo()(dispatch)
      if (info.status === 'invited') {
        dispatch(onboardingRequired())
      } else {
        dispatch(CoreActions.showMessage({ status, data }))
        dispatch(push(hash ? `/${hash}` : '/'))
        window.zE('webWidget', 'identify', {
          name: `${get(info, 'firstname', '')} ${get(info, 'lastname', '')}` || get(info, 'username'),
          email: get(info, 'email'),
        });
      }
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  } finally {
    dispatch(autoLoginFinished())
  }
}
export const searchUser = payload => async (dispatch, getState) => {
  try {
    dispatch(apiAttempt())
    const { data } = await API.searchUsername(payload)
    return data
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}

export const verifyChallenge = payload => async (dispatch, getState) => {
  try {
    dispatch(apiAttempt())

    const { ok, status, data } = await API.verifyChallenge(payload)
    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(loginSuccess(data.data.token.value))
    API.setTokenHeader(data.data.token.value)

    let info = await getInfo()(dispatch)
    if (info.status === 'invited') {
      dispatch(onboardingRequired())
    } else {
      dispatch(CoreActions.showMessage({ status, data }))
      dispatch(push('/'))
      window.zE('webWidget', 'identify', {
        name: `${get(info, 'firstname', '')} ${get(info, 'lastname', '')}` || get(info, 'username'),
        email: get(info, 'email'),
      });
    }
    dispatch(apiSuccess())
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}

export const getInfo = () => async dispatch => {
  try {
    dispatch(apiAttempt)

    const {
      ok,
      data: { data }
    } = await API.getInfo({ populatePaths: 'plants.plant' })
    if (!ok) {
      throw new Error(data.message)
    }

    data.plants = data.plants.map(plant => ({
      ...plant.plant,
      role: plant.role
    }))

    dispatch(getInfoSuccess(data))
    dispatch(apiSuccess)
    return data
  } catch (error) {
    dispatch(apiFailed(error))
  }
}

export const activateUser = payload => async (dispatch, getState) => {
  try {
    dispatch(apiAttempt())
    const { ok, status, data } = await API.activateUser(payload)
    if (!ok && status === 403 && data.code === 'TM011') {
      API.setTokenHeader(null)
      API.setContextPlantHeader(null)
      dispatch(authReset())
      dispatch(
        CoreActions.showMessage({
          status,
          data: {
            message: 'Seems like you took more than 5 minutes to complete onboarding, Please click the invite link again.'
          }
        })
      )
      return
    } else if (!ok) {
      throw new Error(data.message)
    }
    await getInfo()(dispatch)
    dispatch(onboardingCompleted())
    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(push('/'))
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}

export const logout = (callback = null) => async dispatch => {
  try {
    const { ok } = await API.invalidateToken()
    const userDetail = await localStorage['userDetail']

    API.setTokenHeader(null)
    API.setContextPlantHeader(null)
    dispatch(loginSuccess(null))
    dispatch(getInfoSuccess(null))
    dispatch(WelcomeActions.setCurrentPlant(null))
    dispatch(apiAttempt)
    dispatch(logoutSuccess())
    window.zE('webWidget', 'logout');
    await localStorage.clear();
    localStorage['userDetail'] = userDetail
    if (!ok) {
      // throw new Error(data.message)
    }
    if (callback) {
      callback()
    } else {
      history.push('/login')
    }
  } catch (error) {
    dispatch(apiFailed(error))
  }
}
export const forgotPassword = email => async dispatch => {
  try {
    dispatch(apiAttempt())
    const { ok, status, data } = await API.forgotPassword({ email })
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    dispatch(
      forgotPasswordOTPReceived({
        email,
        challengeId: data.data.challenge.id
      })
    )
    return data
  } catch (error) {
    dispatch(apiFailed(error.message))
    dispatch(CoreActions.showMessage(error))
  }
}
export const resetPassword = payload => async dispatch => {
  try {
    dispatch(apiAttempt())
    const { ok, status, data } = await API.resetPassword(payload)
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    return status
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
    dispatch(
      forgotPasswordOTPReceived({
        email: payload.email,
        challengeId: payload.challengeId
      })
    )
  }
}

export const resendOTP = payload => async dispatch => {
  try {
    dispatch(apiAttempt())
    const { ok, status, data } = await API.resendOTP(payload)
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(
      challengeRequired({
        challengeId: data.data.challenge.id,
        email: payload.email
      })
    )
    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    return data
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}

export const updateProfile = (payload, success = null, fail = null) => async (dispatch, getState) => {
  try {
    dispatch(apiAttempt())
    const { ok, status, data } = await API.updateProfile(payload)
    if (!ok) {
      throw new Error(data.message)
    }
    const info = await getInfo()(dispatch)
    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
    return info
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
    if (fail) {
      fail()
    }
  }
}
export const updatePassword = payload => async (dispatch, getState) => {
  try {
    dispatch(apiAttempt())
    const { ok, status, data } = await API.updatePassword(payload)
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    return true
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}

export const refreshToken = password => async (dispatch, getState) => {
  try {
    let {
      core: { redirectBackToUrl }
    } = getState()
    dispatch(apiAttempt())
    const { ok, status, data } = await API.refreshToken({ password })
    if (!ok) {
      throw new Error(data.message)
    }
    await API.setTokenHeader(data.data.token.value)
    dispatch(loginSuccess(data.data.token.value))
    dispatch(CoreActions.showMessage({ status, data }))
    let redirectUrl = redirectBackToUrl ? redirectBackToUrl : '/'
    dispatch(push(redirectUrl))
    return data
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}

export const getOrganizationByEmail = (email, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.fetchUserOrganizations({ email })
    if (!ok) {
      throw new Error(data.message)
    }
    if (success) {
      success()
    }
    return get(data, 'data', {})
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    if (fail) {
      fail()
    }
  }
}
