import React from 'react'
import { Drawer, Hidden, withStyles } from '@material-ui/core'
import classNames from 'classnames'
import NavbarLayout from './NavbarLayout'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom';

const navbarWidth = 180

const styles = theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 4,
    [theme.breakpoints.up('lg')]: {
      width: navbarWidth,
      minWidth: navbarWidth
    }
  },
  wrapperFolded: {
    [theme.breakpoints.up('lg')]: {
      width: 64,
      minWidth: 64
    }
  },
  navbar: {
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    flex: '1 1 auto',
    width: navbarWidth,
    minWidth: navbarWidth,
    height: '100%',
    zIndex: 4,
    transition: theme.transitions.create(['width', 'min-width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shorter
    }),
    boxShadow: theme.shadows[1]
  },
  left: {
    left: 0
  },
  right: {
    right: 0
  },
  folded: {
    position: 'absolute',
    width: 64,
    minWidth: 64,
    top: 0,
    bottom: 0
  },
  foldedAndOpened: {
    width: navbarWidth,
    minWidth: navbarWidth
  },
  navbarContent: {
    flex: '1 1 auto'
  },
  foldedAndClosed: {
    '& $navbarContent': {
      '& .logo-icon': {
        width: 32,
        height: 32
      },
      '& .logo-text': {
        opacity: 0
      },
      '& .react-badge': {
        opacity: 0
      },
      '& .list-item-text, & .arrow-icon': {
        opacity: 0
      },
      '& .list-subheader .list-subheader-text': {
        opacity: 0
      },
      '& .list-subheader:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        minWidth: 16,
        borderTop: '2px solid',
        opacity: 0.2
      },
      '& .collapse-children': {
        display: 'none'
      },
      '& .user': {
        '& .username, & .email': {
          opacity: 0
        },
        '& .avatar': {
          width: 40,
          height: 40,
          top: 32,
          padding: 0
        }
      },
      '& .list-item.active': {
        marginLeft: 12,
        width: 40,
        padding: 12,
        borderRadius: 20,
        '&.square': {
          borderRadius: 0,
          marginLeft: 0,
          paddingLeft: 24,
          width: '100%'
        }
      }
    }
  }
})

const NavbarWrapperLayout = ({ classes, info, children, organizationId, drawerOpen, setDrawerOpen }) => {
  let location = useLocation();
  if (location.pathname) {
    let currentTab = location.pathname.split('/');
    if (currentTab.includes( 'analytics' )) {
      return null;
    }
  }

  if (!info) return null

  return (
    <div id='fuse-navbar' className={classNames(classes.wrapper)}>
      <Hidden mdDown>
        <div
          className={classNames(classes.navbar, classes.folded, classes.foldedAndOpened)}
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
        >
          <NavbarLayout className={classes.navbarContent} organizationId={organizationId} />
        </div>
      </Hidden>

      <Hidden lgUp>
        <Drawer
          anchor='left'
          variant='temporary'
          open={drawerOpen}
          classes={{
            paper: classes.navbar
          }}
          onClose={() => {
            setDrawerOpen(false)
          }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <NavbarLayout className={classes.navbarContent} organizationId={organizationId} />
        </Drawer>
      </Hidden>
    </div>
  )
}

const mapStateToProps = ({ auth: { info } }) => ({
  info
})

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(NavbarWrapperLayout))
