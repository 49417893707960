import React, { useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { useMergeState } from '../../../core/helpers'
import { GooglePlaceSearch } from '../../../core/components/Inputs'
import DateTime from 'react-datetime'
import { get, set, find } from 'lodash'

const INITIAL_STATE = {
  hasContainerStorage: false,
  hasWarehouseStorage: false,
}

const FCLAcknowledge = ({ journey, container, onChange, onCopyFromFirst, currentIndex }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const storageOneEvents = ['truckInToSFWithEmptyContainer', 'truckOutFromSFwithEmptyContainer']
  const storageTwoEvents = ['truckInToSFForCargoPickup', 'truckOutFromSFWithCargo']

  useEffect(() => {
    const warehouseLocation = get(
      find(journey, { location: { type: 'warehouse', subType: 'origin' } }),
      'location',
      {}
    )
    var updatedState = {
      ...state,
      container
    }

    set(updatedState, 'container.referenceEvents.truckInToSWHForCargoPickup.location', warehouseLocation)
    set(updatedState, 'container.referenceEvents.truckOutFromSWHWithCargo.location', warehouseLocation)

    if (get(container, 'referenceEvents.truckInToSFWithEmptyContainer') ||
      get(container, 'referenceEvents.truckOutFromSFwithEmptyContainer')) {
      set(updatedState, 'hasContainerStorage', true)
    } else {
      set(updatedState, 'hasContainerStorage', false)
    }

    if (get(container, 'referenceEvents.truckInToSFForCargoPickup') ||
      get(container, 'referenceEvents.truckOutFromSFWithCargo')) {
      set(updatedState, 'hasWarehouseStorage', true)
    } else {
      set(updatedState, 'hasWarehouseStorage', false)
    }

    setState(updatedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container])

  const updatedEvent = (sections, name, value) => {
    var container = get(state, 'container', {})

    if (value) {
      if (name === 'location') {
        sections.forEach(section => {
          set(container, `referenceEvents.${section}.${name}`, {
            ...get(container, `referenceEvents.${section}.${name}`, {}),
            ...storageOneEvents.includes(section) ? { name: 'Storage 1' } : {},
            ...storageTwoEvents.includes(section) ? { name: 'Storage 2' } : {},
            ...value
          })
        })
      } else {
        sections.forEach(section => {
          set(container, 'referenceEvents.' + [section], {
            ...get(container, 'referenceEvents.' + [section], {}),
            location: get(container, 'referenceEvents.' + [section] + '.location', {}),
            [name]: value
          })
        })
      }
    }

    return container;
  }

  const handleAddressChange = (address, lat, lng, sections) => {
    const updatedState = {
      ...state,
      container: updatedEvent(sections, 'location', {
        ...address,
        latitude: lat.toString(),
        longitude: lng.toString(),
      })
    }

    onChange(updatedState)
  }

  const handleAdditionalAddressChange = (event, sections) => {
    const { name, value } = event.target

    const updatedState = {
      ...state,
      container: updatedEvent(sections, 'location', {
        [name]: value
      })
    }

    onChange(updatedState)
  }

  const handleDateChange = (date, name, sections) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    const updatedState = {
      ...state,
      container: updatedEvent(sections, name, new Date(date))
    }

    onChange(updatedState)
  }

  const manageSF = (action, name) => {
    const value = action === 'add' ? {} : undefined
    var updatedState = {
      ...state,
      [name]: value ? true : false,
    }

    if (name === 'hasContainerStorage') {
      storageOneEvents.forEach(event => {
        set(updatedState, `container.referenceEvents.${event}`, value)
      })
    }

    if (name === 'hasWarehouseStorage') {
      storageTwoEvents.forEach(event => {
        set(updatedState, `container.referenceEvents.${event}`, value)
      })
    }

    onChange(updatedState)
  }

  return (
    <Grid container className="field-control">
      <Grid item sm={12} md={4} className='flex items-center'>
        <label className={'field-label'}>
          Haulage schedule</label>
      </Grid>
      <Grid item sm={12} md={8} className='flex items-center justify-end'>
        {
          currentIndex > 0 &&
          <label className="fg-blue cursor-pointer mr-16"
            onClick={() => {
              onCopyFromFirst([
                'emptyContainerPickUpFromYard',
                'truckInToSFWithEmptyContainer',
                'truckOutFromSFwithEmptyContainer',
                'truckInToSWHForCargoPickup',
                'truckOutFromSWHWithCargo',
                'truckInToSFForCargoPickup',
                'truckOutFromSFWithCargo',
              ])
            }}>Copy from first</label>
        }
        <label className={'field-instruction'}>
          Add container journey</label>
      </Grid>
      <Grid container
        direction='row'
        className='stepper'>

        <Grid container
          direction='row'
          className='step pb-24'>
          <Grid container
            direction='row'
            className='step-header'>
            <span className='step-icon'>
              <img src="assets/images/icons/timeline-step/container-yard-gray.svg"
                alt='container-yard' />
            </span>
            <Grid item sm={12} md={6} className='flex items-center'>
              <label className='field-sub-label'>
                Container Yard (CY)</label>
            </Grid>
          </Grid>

          <Grid container direction='row' className='step-content'>
            <Grid item sm={12} className='pb-16'>
              <label className={'formsy-form-control__label require-field'}>
                Container yard address</label>
              <GooglePlaceSearch placeholder='Select street address...'
                className='theme-text-field'
                value={get(
                  state,
                  'container.referenceEvents.emptyContainerPickUpFromYard.location.street',
                  ''
                )}
                updateCall={(address, lat, lng) => {
                  handleAddressChange(address, lat, lng, ['emptyContainerPickUpFromYard'])
                }}
                savedPlaceType="container-yard"
              />
            </Grid>
            <Grid item sm={12} md={7} className='pr-8'>
              <TextField name='floorUnitNumber'
                className='theme-text-field'
                value={get(
                  state,
                  'container.referenceEvents.emptyContainerPickUpFromYard.location.floorUnitNumber',
                  ''
                )}
                label="Additional address"
                fullWidth={true}
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  handleAdditionalAddressChange(
                    event,
                    ['emptyContainerPickUpFromYard']
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} md={5} className='pl-6'>
              <Grid container>
                <Grid item xs={12}>
                  <label className='formsy-form-control__label require-field'>
                    Empty container pickup</label>
                </Grid>
                <Grid item xs={12} className='relative'>
                  <DateTime className='theme-date-time-picker-field'
                    input
                    dateFormat='DD MMM YYYY,'
                    onChange={date => {
                      handleDateChange(
                        date,
                        'expectedAt',
                        ['emptyContainerPickUpFromYard']
                      )
                    }}
                    value={get(state, 'container.referenceEvents.emptyContainerPickUpFromYard.expectedAt') &&
                      new Date(get(state, 'container.referenceEvents.emptyContainerPickUpFromYard.expectedAt'))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {
          !state.hasContainerStorage &&
          <Grid container
            direction='row'
            className='step pb-24'>
            <Grid container
              direction='row'
              className='step-header'
              justify="space-between">
              <span className='step-icon'>
                <img src="assets/images/icons/vessel-info/add-transhipment.png"
                  alt='booking' />
              </span>
              <Grid item>
                <Button className="btn btn_sm btn_light-blue border-solid border-1 border-blue normal-fonts"
                  onClick={() => manageSF('add', 'hasContainerStorage')}>
                  Add storage facility</Button>
              </Grid>
              <Grid item>
                <label className={'field-instruction'}>
                  Is it going to SF before Warehouse?</label>
              </Grid>
            </Grid>
          </Grid>
        }

        {
          state.hasContainerStorage &&
          <Grid container
            direction='row'
            className='step pb-24'>
            <Grid container
              direction='row'
              className='step-header'>
              <span className='step-icon'>
                <img src="assets/images/icons/timeline-step/storage-facility-blue.svg"
                  alt='container-yard' />
              </span>
              <Grid item sm={12} md={9} className='flex items-center'>
                <label className='field-sub-label'>
                  Storage facility before going to warehouse</label>
              </Grid>
              <Grid item sm={12} md={3} className='flex items-center justify-end'>
                <label className={'cursor-pointer fg-red regular'}
                  onClick={() => manageSF('remove', 'hasContainerStorage')}>
                  Remove</label>
              </Grid>
            </Grid>

            <Grid container direction='row' className='step-content'>
              <Grid item sm={12} className='pb-16'>
                <label className={'formsy-form-control__label require-field'}>
                  Storage facility address</label>
                <GooglePlaceSearch placeholder='Select street address...'
                  className='theme-text-field'
                  value={get(
                    state,
                    'container.referenceEvents.truckInToSFWithEmptyContainer.location.street',
                    get(
                      state,
                      'container.referenceEvents.truckOutFromSFwithEmptyContainer.location.street',
                      ''
                    )
                  )}
                  updateCall={(address, lat, lng) => {
                    handleAddressChange(address, lat, lng, [
                      'truckInToSFWithEmptyContainer',
                      'truckOutFromSFwithEmptyContainer'
                    ])
                  }}
                  savedPlaceType="storage-before-warehouse"
                />
              </Grid>
              <Grid item sm={12} md={4} className='pr-8'>
                <TextField name='floorUnitNumber'
                  className='theme-text-field'
                  value={get(
                    state,
                    'container.referenceEvents.truckInToSFWithEmptyContainer.location.floorUnitNumber',
                    get(
                      state,
                      'container.referenceEvents.truckOutFromSFwithEmptyContainer.location.floorUnitNumber',
                      ''
                    )
                  )}
                  label="Additional address"
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    handleAdditionalAddressChange(event, [
                      'truckInToSFWithEmptyContainer',
                      'truckOutFromSFwithEmptyContainer'
                    ])
                  }}
                />
              </Grid>
              <Grid item sm={12} md={4} className='pl-6'>
                <Grid container>
                  <Grid item xs={12}>
                    <label className='formsy-form-control__label require-field'>
                      Truck In</label>
                  </Grid>
                  <Grid item xs={12} className='relative'>
                    <DateTime className='theme-date-time-picker-field'
                      input
                      dateFormat='DD MMM YYYY,'
                      onChange={date => {
                        handleDateChange(
                          date,
                          'expectedAt',
                          ['truckInToSFWithEmptyContainer'])
                      }}
                      value={get(state, 'container.referenceEvents.truckInToSFWithEmptyContainer.expectedAt') &&
                        new Date(get(state, 'container.referenceEvents.truckInToSFWithEmptyContainer.expectedAt'))
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={4} className='pl-6'>
                <Grid container>
                  <Grid item xs={12}>
                    <label className='formsy-form-control__label require-field'>
                      Truck Out</label>
                  </Grid>
                  <Grid item xs={12} className='relative'>
                    <DateTime className='theme-date-time-picker-field'
                      input
                      dateFormat='DD MMM YYYY,'
                      onChange={date => {
                        handleDateChange(
                          date,
                          'expectedAt',
                          ['truckOutFromSFwithEmptyContainer'])
                      }}
                      value={get(state, 'container.referenceEvents.truckOutFromSFwithEmptyContainer.expectedAt') &&
                        new Date(get(state, 'container.referenceEvents.truckOutFromSFwithEmptyContainer.expectedAt'))
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid container
          direction='row'
          className='step pb-24'>
          <Grid container
            direction='row'
            className='step-header'>
            <span className='step-icon'>
              <img src="assets/images/icons/timeline-step/container-yard-gray.svg"
                alt='container-yard' />
            </span>
            <Grid item sm={12} md={6} className='flex items-center'>
              <label className='field-sub-label'>
                Shipper warehouse</label>
            </Grid>
          </Grid>

          <Grid container direction='row' className='step-content'>
            <Grid item sm={12} className='pb-16'>
              {get(
                find(journey, { location: { type: 'warehouse', subType: 'origin' } }),
                'location.address.street'
              )}
            </Grid>
            <Grid item sm={12} md={6} className='pr-6'>
              <Grid container>
                <Grid item xs={12}>
                  <label className='formsy-form-control__label require-field'>
                    Planned truck in to pickup cargo</label>
                </Grid>
                <Grid item xs={12} className='relative'>
                  <DateTime className='theme-date-time-picker-field'
                    input
                    dateFormat='DD MMM YYYY,'
                    onChange={date => {
                      handleDateChange(
                        date,
                        'expectedAt',
                        ['truckInToSWHForCargoPickup'])
                    }}
                    value={get(state, 'container.referenceEvents.truckInToSWHForCargoPickup.expectedAt') &&
                      new Date(get(state, 'container.referenceEvents.truckInToSWHForCargoPickup.expectedAt'))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} md={6} className='pl-6'>
              <Grid container>
                <Grid item xs={12}>
                  <label className='formsy-form-control__label require-field'>
                    Planned truck out with cargo</label>
                </Grid>
                <Grid item xs={12} className='relative'>
                  <DateTime className='theme-date-time-picker-field'
                    input
                    dateFormat='DD MMM YYYY,'
                    onChange={date => {
                      handleDateChange(
                        date,
                        'expectedAt',
                        ['truckOutFromSWHWithCargo'])
                    }}
                    value={get(state, 'container.referenceEvents.truckOutFromSWHWithCargo.expectedAt') &&
                      new Date(get(state, 'container.referenceEvents.truckOutFromSWHWithCargo.expectedAt'))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {
          !state.hasWarehouseStorage &&
          <Grid container
            direction='row'
            className='step pb-24'>
            <Grid container
              direction='row'
              className='step-header'
              justify="space-between">
              <span className='step-icon'>
                <img src="assets/images/icons/vessel-info/add-transhipment.png"
                  alt='booking' />
              </span>
              <Grid item>
                <Button className="btn btn_sm btn_light-blue border-solid border-1 border-blue normal-fonts"
                  onClick={() => manageSF('add', 'hasWarehouseStorage')}>
                  Add storage facility</Button>
              </Grid>
              <Grid item>
                <label className={'field-instruction'}>
                  Is it going to SF before Origin port?</label>
              </Grid>
            </Grid>
          </Grid>
        }

        {
          state.hasWarehouseStorage &&
          <Grid container
            direction='row'
            className='step pb-24'>
            <Grid container
              direction='row'
              className='step-header'>
              <span className='step-icon'>
                <img src="assets/images/icons/timeline-step/storage-facility-blue.svg"
                  alt='container-yard' />
              </span>
              <Grid item sm={12} md={9} className='flex items-center'>
                <label className='field-sub-label'>
                  Storage facility before going to origin port</label>
              </Grid>
              <Grid item sm={12} md={3} className='flex items-center justify-end'>
                <label className={'cursor-pointer fg-red regular'}
                  onClick={() => manageSF('remove', 'hasWarehouseStorage')}>
                  Remove</label>
              </Grid>
            </Grid>

            <Grid container direction='row' className='step-content'>
              <Grid item sm={12} className='pb-16'>
                <label className={'formsy-form-control__label require-field'}>
                  Storage facility address</label>
                <GooglePlaceSearch placeholder='Select street address...'
                  className='theme-text-field'
                  value={get(
                    state,
                    'container.referenceEvents.truckInToSFForCargoPickup.location.street',
                    get(
                      state,
                      'container.referenceEvents.truckOutFromSFWithCargo.location.street',
                      ''
                    )
                  )}
                  updateCall={(address, lat, lng) => {
                    handleAddressChange(address, lat, lng, [
                      'truckInToSFForCargoPickup',
                      'truckOutFromSFWithCargo'
                    ])
                  }}
                  savedPlaceType="storage-before-port"
                />
              </Grid>
              <Grid item sm={12} md={4} className='pr-8'>
                <TextField name='floorUnitNumber'
                  className='theme-text-field'
                  value={get(
                    state,
                    'container.referenceEvents.truckInToSFForCargoPickup.location.floorUnitNumber',
                    get(
                      state,
                      'container.referenceEvents.truckOutFromSFWithCargo.location.floorUnitNumber',
                      ''
                    )
                  )}
                  label="Additional address"
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    handleAdditionalAddressChange(event, [
                      'truckInToSFForCargoPickup',
                      'truckOutFromSFWithCargo'
                    ])
                  }}
                />
              </Grid>
              <Grid item sm={12} md={4} className='pl-6'>
                <Grid container>
                  <Grid item xs={12}>
                    <label className='formsy-form-control__label require-field'>
                      Truck In</label>
                  </Grid>
                  <Grid item xs={12} className='relative'>
                    <DateTime className='theme-date-time-picker-field'
                      input
                      dateFormat='DD MMM YYYY,'
                      onChange={date => {
                        handleDateChange(
                          date,
                          'expectedAt',
                          ['truckInToSFForCargoPickup'])
                      }}
                      value={get(state, 'container.referenceEvents.truckInToSFForCargoPickup.expectedAt') &&
                        new Date(get(state, 'container.referenceEvents.truckInToSFForCargoPickup.expectedAt'))
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={4} className='pl-6'>
                <Grid container>
                  <Grid item xs={12}>
                    <label className='formsy-form-control__label require-field'>
                      Truck Out</label>
                  </Grid>
                  <Grid item xs={12} className='relative'>
                    <DateTime className='theme-date-time-picker-field'
                      input
                      dateFormat='DD MMM YYYY,'
                      onChange={date => {
                        handleDateChange(
                          date,
                          'expectedAt',
                          ['truckOutFromSFWithCargo'])
                      }}
                      value={get(state, 'container.referenceEvents.truckOutFromSFWithCargo.expectedAt') &&
                        new Date(get(state, 'container.referenceEvents.truckOutFromSFWithCargo.expectedAt'))
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid container
          direction='row'
          className='step'>
          <Grid container
            direction='row'
            className='step-header'>
            <span className='step-icon'>
              <img src="assets/images/icons/dock-filled.svg"
                alt='booking' />
            </span>
            <Grid item sm={12} md={6} className='flex items-center'>
              <label className='field-sub-label'>
                Origin port</label>
            </Grid>
          </Grid>
          <div className='flex items-center text-11 text-grey-dark'>
            {get(find(journey, { location: { type: 'port', subType: 'origin' } }), 'location.name')}
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FCLAcknowledge
