import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { get, pick, find, uniqBy, filter } from 'lodash'
import { FuseDialog, FuseDialogBody, FuseDialogHeader, FuseTabs, FusePlaceHolder } from 'core/components'
import { useMergeState, addGaEvent } from '../../core/helpers'
import BookingForm from './booking-form/BookingForm'
import BookingLaneForm from './booking-form/BookingLaneForm'
import BookingRouteForm from './booking-form/BookingRouteForm'

const tabs = {
  bookingInfo: 'Booking',
  routeInfo: 'Route (Read only)',
  laneInfo: 'Lane'
}

const formTitles = {
  create: 'Create New Booking',
  update: 'Update booking',
  amend: 'Amend booking'
}


const NewBookingDialog = props => {
  const [state, setState] = useMergeState({ currentTab: 0 })
  const [requesting, setRequesting] = useState(false)
  const [containersReferenceEvents, setContainersReferenceEvents] = useState([])

  const { currentTab } = state
  const {
    open,
    data,
    handleClose,
    onSubmit,
    mode,
    outboundIds,
    shipmentOutboundIds,
    selectedShipment,
    forChangeBooking,
    newShipmentObject,
    currentPlant = {},
    plants = [],
    selectedTransportMethod,
    bookingLocationId
  } = props
  const transportMethod = selectedTransportMethod ? selectedTransportMethod : 'ocean'
  const baseShipmentData = {
    transportMethod
  }
  const availableFCLOption = filter(data, item => {
    return (
      item.baseShipmentData.transportMethod === transportMethod &&
      item.baseShipmentData.type === 'FCL' &&
      item.exceptions.errors.length === 0
    )
  })
  const availableLCLOption = find(data, item => {
    return (
      item.baseShipmentData.transportMethod === transportMethod &&
      item.baseShipmentData.type === 'LCL' &&
      item.exceptions.errors.length === 0
    )
  })
  useEffect(() => {
    var allowed = []
    var available = {
      'LCL': filter(data, item => (
        item.baseShipmentData.transportMethod === transportMethod &&
        item.baseShipmentData.type === 'LCL'
      )).length,
      'FCL': filter(data, item => (
        item.baseShipmentData.transportMethod === transportMethod &&
        item.baseShipmentData.type === 'FCL'
      )).length
    }

    if (availableLCLOption) {
      allowed.push({
        type: 'LCL',
        equipmentType: 'N/A'
      })
    }
    if (availableFCLOption.length > 0) {
      availableFCLOption.forEach(optionItem => {
        get(optionItem, 'baseShipmentData.containers', []).forEach(containerItem => {
          allowed.push({
            type: 'FCL',
            equipmentType: containerItem.type
          })
        })
      })
    }

    setState({
      bookingOptions: {
        allowed: uniqBy(allowed, 'equipmentType'),
        available
      },
      formMode: mode === 'edit' ? 'update' : 'create',
      transportMethod
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, mode])

  useEffect(() => {
    if (open && !get(state, 'shipment')) {
      const goodsType = get(selectedShipment, 'goodsType', '')
      const specialLabels = get(selectedShipment, 'specialLabels', [])

      setContainersReferenceEvents(get(props, 'selectedShipment.containers', []).map(item => {
        return {
          street: get(item, 'referenceEvents.truckInToSWHWithEmptyContainer.location.street'),
          floorUnitNumber: get(item, 'referenceEvents.truckInToSWHWithEmptyContainer.location.floorUnitNumber'),
          expectedAt: get(item, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt'),
          cancelled: get(item, 'status.current.value') === 'cancelled',
        }
      }))
      setState({
        shipment: {
          ...selectedShipment,
          goodsType: typeof goodsType === 'string' ? [goodsType, ...specialLabels] : [...goodsType, ...specialLabels]
        },
        formMode: mode === 'edit' ? 'update' : 'create'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShipment, mode, open])

  useEffect(() => {
    if (newShipmentObject && mode !== 'edit' && get(state, 'formMode') !== 'create') {
      if (open) {
        onChange('bookingForm', newShipmentObject)
      }
      setState({
        formMode: 'create'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newShipmentObject, open])

  useEffect(() => {
    if (!open) {
      setState({
        currentTab: 0,
        bookingForm: null,
        routeForm: null,
        laneForm: null,
        shipment: null,
        errors: [],
        hasError: false
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const bookingAmended = (updatedState) => {
    let status = false
    if (forChangeBooking) {
      status = true
    }

    if (mode === 'edit' && get(updatedState, 'bookingForm')) {
      if (get(selectedShipment, 'type') === 'FCL') {
        get(updatedState, 'bookingForm.containers', []).forEach((item, itemIndex) => {
          const shipmentContainerStatus = get(containersReferenceEvents, `[${itemIndex}].cancelled`, false)

          if (get(item, 'isNew')) {
            status = true
          } else {
            if (get(item, `cancelled`, false) !== shipmentContainerStatus) {
              status = true
            }
          }
        })
      }
    }
    return status
  }

  const go = step => {
    const currentTab = state.currentTab + step

    if (0 <= currentTab && currentTab < 3) {
      setState({ currentTab })
    }
  }

  const onChange = (name, value) => {
    if (name === 'bookingForm') {
      const type = get(value, 'type')
      const containers = get(value, 'containers', [])
      var selectionCondition = {
        baseShipmentData: {
          ...baseShipmentData,
          type
        }
      }

      if (type === 'FCL') {
        selectionCondition.baseShipmentData = {
          ...selectionCondition.baseShipmentData,
          containers: uniqBy(
            containers.map(container => {
              return {
                type: container.type
              }
            }),
            'type'
          )
        }
      }
      const selectedOption = find(data, selectionCondition)
      if (selectedOption) {
        const goodsType = get(selectedOption, 'derivedShipmentData.goodsType')
        const specialLabels = get(selectedOption, 'derivedShipmentData.specialLabels')

        value.groupageDate = get(value, 'groupageDate', get(selectedOption, 'derivedShipmentData.groupageDate'))
        setState({
          shipment: {
            ...get(selectedOption, 'derivedShipmentData', {}),
            transportMethod,
            goodsType: typeof goodsType === 'string' ? [goodsType, ...specialLabels] : [...goodsType, ...specialLabels],
            ...value
          },
          hasError: get(selectedOption, 'exceptions.suggestNextResolutionProcess', false),
          errors: get(selectedOption, 'exceptions.errors')
        })
      } else {
        setState({
          shipment: {},
          errors: [],
          hasError: false
        })
      }
    }

    setState({
      [name]: value,
      formMode: bookingAmended({ [name]: value }) ? 'amend' : mode === 'edit' ? 'update' : 'create'
    })
  }

  const prepareRequest = () => {
    setRequesting(true)
    const type = get(state, 'bookingForm.type')
    var payload = {
      groupageDate: get(state, 'bookingForm.groupageDate'),
      note: get(state, 'bookingForm.note'),
      type,
      transportMethod
    }

    if (type === 'FCL') {
      payload.containers = get(state, 'bookingForm.containers')
    }

    if (type === 'LCL') {
      payload.referenceEvents = {
        truckInForCargoPickup: {
          ...get(state, 'bookingForm.truckInCargo', {}),
          expectedAt: new Date(get(state, 'bookingForm.truckInCargo.expectedAt')),
          location: {
            ...get(state, 'bookingForm.truckInCargo.location', {}),
            ...pick(get(state, 'bookingForm.truckInCargo', {}), ['floorUnitNumber'])
          }
        }
      }
    }

    if (transportMethod === 'air') {
      payload.referenceEvents = {
        ...get(payload, 'referenceEvents', {}),
        cargoDropOffAtCFS: get(state, 'bookingForm.cfs')
      }
      payload.referenceNos = {
        HBLNo: get(state, 'bookingForm.hblNumber', '').split(',').filter(Boolean),
        MBLNo: get(state, 'bookingForm.mblNumber', '').split(',').filter(Boolean)
      }
    }

    if (get(state, 'laneForm.isSpot') === true) {
      payload.raiseExceptionToSpot = {
        enabled: true,
        reason: get(state, 'laneForm.spotReason')
      }
    }

    if (mode === 'edit') {
      recordGAEvent()

      payload.outboundsDelta = {
        add: outboundIds,
        remove: shipmentOutboundIds
      }
      payload._id = selectedShipment._id

      onSubmit(payload, get(state, 'formMode'), () => setRequesting(false))
    } else {
      payload.outbounds = outboundIds
      if (bookingLocationId) {
        payload.noOutboundBooking = bookingLocationId
      }
      onSubmit(payload, 'new', () => setRequesting(false))
    }
  }

  const recordGAEvent = (type) => {
    const key = get(state, 'formMode')
    const id = get(selectedShipment, '_id')
    switch (key) {
      case 'update':
        if (type === 'cancel') {
          addGaEvent(`shipment-${key}Cancelled`, id)
        } else {
          addGaEvent(`shipment-updating`, id)
        }
        break;
      case 'amend':
        if (type === 'cancel') {
          addGaEvent(`shipment-${key}Cancelled`, id)
        } else {
          addGaEvent(`shipment-amending`, id)
        }
        break;

      default:
        if (type === 'cancel') {
          addGaEvent(`shipment-createCancelled`, id)
        } else {
          addGaEvent(`shipment-creating`, id)
        }
        break;
    }
  }

  const tabItems = [
    {
      label: tabs.bookingInfo,
      component: BookingForm,
      props: {
        data: state,
        booking: get(state, 'bookingOptions'),
        existingFormData: get(state, 'bookingForm'),
        go,
        mode,
        onChange,
        formMode: get(state, 'formMode')
      }
    },
    {
      label: tabs.routeInfo,
      component: BookingRouteForm,
      props: {
        data: state,
        existingFormData: get(state, 'routeForm'),
        go,
        onChange,
        currentPlant,
        plants
      }
    },
    {
      label: tabs.laneInfo,
      component: BookingLaneForm,
      props: {
        data: state,
        booking: get(state, 'bookingOptions', {}),
        existingFormData: get(state, 'laneForm'),
        go,
        onChange,
        onSubmit: prepareRequest,
        submitAction: get(state, 'formMode'),
        requesting
      }
    }
  ]

  if (get(state, 'bookingOptions.available.LCL', 0) === 0 &&
    get(state, 'bookingOptions.available.FCL', 0) === 0) {
    return (
      <FuseDialog open={open} wSM onBackDropClick={() => {
        recordGAEvent('cancel')
        handleClose()
      }}>
        <FuseDialogHeader text="Booking Not available"
          handleClose={() => {
            recordGAEvent('cancel')
            handleClose()
          }} />
        <FuseDialogBody>
          <Grid container direction='row'>
            <FusePlaceHolder icon="info"
              title="Booking not available"
              description="Looks like minimum required options for this shipment is not available yet. Please try again later" />
          </Grid>
        </FuseDialogBody>
      </FuseDialog>
    )
  } else {
    return (
      <FuseDialog open={open}>
        <FuseDialogHeader text={formTitles[get(state, 'formMode')]}
          handleClose={() => {
            recordGAEvent('cancel')
            handleClose()
          }} />
        <FuseDialogBody>
          <Grid container direction='row'>
            <FuseTabs showIndicator
              value={currentTab}
              data={tabItems}
              onChange={currentTab => { }} />
          </Grid>

          <div className='booking-wizard'>
            {tabItems.map((tab, index) => {
              if (index === currentTab) {
                return <tab.component {...tab.props}
                  key={index} />
              }
              return null
            })}
          </div>
        </FuseDialogBody>
      </FuseDialog>
    )
  }
}

export default NewBookingDialog
