import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'
import SubToolBarSkeleton from './SubToolBarSkeleton'

const ShipmentDetailsSkeleton = ({ showSearch, showFilter, count = 3 }) => (
  <Grid container>
    <Grid item xs={12}>
      <SubToolBarSkeleton actions={[1, 2]} />
    </Grid>
    <Grid item xs={12}
      className="p-20">
      <Grid container
        spacing={2}>
        {[1, 2, 3, 4, 5].map(gridItem => {
          return (
            <Grid key={gridItem} item>
              <Skeleton height={35} width={100} />
            </Grid>
          )
        })}
      </Grid>
      <Grid container
        spacing={2}>
        <Grid item xs md={8}>
          <Grid container>
            <Grid item xs={12}>
              <Skeleton height={250} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height={272} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs md={4}>
          <Grid container>
            <Grid item xs={12}>
              <Skeleton height={250} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height={70} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton height={200} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)

export default ShipmentDetailsSkeleton
