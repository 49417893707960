import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

const ShipmentListFilterSkeleton = ({ showStatus, showSearch, showFilter }) => (
  <Grid container alignItems='center'
    className='mb-20'
    spacing={2}>
    <Grid item xs={2}>
      {showSearch &&
        <Skeleton height={45} />
      }
    </Grid>

    <Grid item xs={2}>
      {showStatus &&
        <Skeleton height={45} />
      }
    </Grid>

    <Grid item xs={8}>
      {showFilter &&
        <Skeleton height={45} />
      }
    </Grid>
  </Grid>
)

export default ShipmentListFilterSkeleton
