import React from 'react'
import { IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

const SideBarHeader = ({ text, handleClose }) => (
  <div className='flex flex-row light-label justify-between side-bar-header mt-0 relative'>
    {text && <div className='light-label light-label_value flex items-center'>{text}</div>}
    {handleClose && (
      <IconButton color='inherit' onClick={handleClose} aria-label='Close'>
        <CloseIcon />
      </IconButton>
    )}
  </div>
)

export default SideBarHeader