import React from 'react'
import { withStyles, Grid, Tabs, Tab } from '@material-ui/core'
import classNames from 'classnames'

/**
 *
 * @param {Array} data  [{ label }]
 */

const FuseTabs = ({ classes, data, value, showIndicator, onChange }) => (
  <Grid container direction='row' className={classes.root}>
    <Grid item sm={12} md={9} className='flex items-center'>
      <Tabs value={value} onChange={onChange} className={classes.tabsRoot} scrollButtons='auto'>
      {data.map((tab, index) => {
        return (
          <Tab
            key={`tab_${index}`}
            label={tab.label}
            onClick={() => onChange(index)}
            className={classNames(classes.item, { active: value === index })}
          />
        )
      })}
      </Tabs>
    </Grid>
    {showIndicator &&
      <Grid item sm={12} md={3} className={classes.indicator}>
        {data.map((tab, index) => (
          <div key={`ind_${index}`} className={classNames('item', { active: index <= value })}></div>
        ))}
      </Grid>
    }
  </Grid>
)

const styles = theme => ({
  root: {
    backgroundColor: '#ececec'
  },
  tabsRoot: {
    '& > div > span': {
      display: 'none'
    }
  },
  item: {
    fontFamily: 'IBMPlexSans-Medium',
    fontSize: 14,
    textTransform: 'none',
    minWidth: 'auto',
    paddingLeft: '15px',
    paddingRight: '15px',
    color: '#9FA0A4',

    '&:first-child': {
      paddingLeft: '20px',
    },
    '&:hover': {
      color: '#333333'
    },
    '&.active': {
      fontFamily: 'IBMPlexSans-SemiBold',
      color: '#333333'
    }
  },
  indicator: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 32,

    '& .item': {
      width: 30,
      height: 5,
      background: '#ace8b8',
      marginRight: 4,

      '&.active': {
        background: '#3dcd58'
      }
    },

    '& div:first-child': {
      borderTopLeftRadius: '13vh',
      borderBottomLeftRadius: '13vh'
    },

    '& div:last-child': {
      borderTopRightRadius: '13vh',
      borderBottomRightRadius: '13vh'
    }
  }
})

export default withStyles(styles)(FuseTabs)
