import React from 'react'
import { connect } from 'react-redux'
import { Box, withWidth } from '@material-ui/core'
import { getNavigationConfig } from 'core/components/Navigation/navItems'
import BaseToolBar from '../../components/BaseToolBar'
import { get } from 'lodash'

const SubToolBarLayout = ({ location, children, text, withBack, currentPlant, doubleRightContent, width, section }) => {
  const navigationConfig = getNavigationConfig(get(currentPlant, 'type'), get(currentPlant, 'role'))
  const routeMapping = navigationConfig.reduce((acc, cur) => acc.concat(cur.children), [])
  const currentRoute = routeMapping.find(v => v.url === location.pathname)

  if (!currentRoute && !text) return null

  return (
    <Box
      className='bg-white min-h-48'
      style={{
        zIndex: 1001,
        boxShadow: '4px 1px 8px 0 rgba(235, 240, 247, 0.8), 1px 0 0 0 #EBF0F7',
        position: 'fixed',
        top: '54px',
        width: width === 'xs' || width === 'sm' || width === 'md' || section === 'analytics' ? '100%' : 'calc(100% - 180px)'
      }}
    >
      <BaseToolBar
        className='min-h-48 text-14'
        text={text || currentRoute.title}
        withBack={withBack}
        doubleRightContent={doubleRightContent}
      >
        {children}
      </BaseToolBar>
    </Box>
  )
}

const mapStateToProps = ({ router: { location }, welcome: { currentPlant } }) => ({
  location,
  currentPlant
})

export default withWidth()(connect(mapStateToProps)(SubToolBarLayout))
