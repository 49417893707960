import ActionTypes from './types'
import * as API from '../../core/api'
import * as CoreActions from '../../core/store/actions'
import { checkPagination } from 'core/helpers'

export const getEmailLogs = (params, pagination, success = null, fail = null) => async dispatch => {
  const { pageNo, pageSize } = pagination

  if (pageNo > 1) {
    dispatch({
      type: ActionTypes.LOADING_MORE_EMAIL_LOGS,
    })
  }

  try {
    const { ok, data } = await API.getEmailLogs(params, pageNo, pageSize)

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    if (pageNo === 1) {
      dispatch({
        type: ActionTypes.SET_EMAIL_LOGS_SUCCESS,
        payload: data
      })
    } else {
      dispatch({
        type: ActionTypes.LOAD_MORE_EMAIL_LOGS,
        payload: data
      })
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}
