import React, { useState, useEffect } from 'react'
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, withStyles, Typography, Checkbox } from '@material-ui/core'
import { FuseCheckBox, LoadMore } from '../../core/components'
import Skeleton from 'react-loading-skeleton'
import { get, filter, uniq } from 'lodash'

const OutboundList = ({ selectNone, selectAll, closedOutbounds, loading, selectedOutBoundIds, unSelectOutbound, selectOutbound, classes, hasMore, loadingMore, onLoadMore }) => {
  const [allSelected, setAllSelected] = useState(false)
  const [selectedCs, setSelectedCs] = useState([])
  const [expandedCs, setExpandedCs] = useState([])

  const handleCsExpandToggle = (csCode) => {
    if (expandedCs.includes(csCode)) {
      setExpandedCs(uniq(filter(expandedCs, i => i !== csCode)))
    } else {
      setExpandedCs(uniq([...expandedCs, csCode]))
    }
  }

  const handleCsSelect = (event) => {
    const { checked, value } = event.target
    let outboundIds = []
    closedOutbounds.forEach(item => {
      const key = get(Object.keys(item), '[0]')
      if (key === value)
        outboundIds = get(item, key, []).map(i => i._id)
    })

    if (checked) {
      setSelectedCs(uniq([...selectedCs, value]))
      setExpandedCs(uniq([...expandedCs, value]))
      selectOutbound(outboundIds)
    } else {
      setSelectedCs(uniq(filter(selectedCs, i => i !== value)))
      unSelectOutbound(outboundIds)
    }
  }

  useEffect(() => {
    let outboundCount = 0
    let selectedCsCodes = []

    closedOutbounds.forEach(item => {
      const key = get(Object.keys(item), '[0]')
      const currentOutbound = get(item, key, [])
      let selectedOutboundCount = 0

      currentOutbound.forEach(outbound => {
        outboundCount++
        if (selectedOutBoundIds.includes(outbound._id)) {
          selectedOutboundCount++
        }
      })
      if (currentOutbound.length === selectedOutboundCount) {
        selectedCsCodes = uniq([...selectedCsCodes, key])
      } else {
        selectedCsCodes = uniq(filter(selectedCsCodes, i => i !== key))
      }
    })
    if (outboundCount > 0) {
      setAllSelected(outboundCount === selectedOutBoundIds.length)
    }
    setSelectedCs(selectedCsCodes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOutBoundIds])

  useEffect(() => {
    if (expandedCs.length === 0) {
      const key = get(Object.keys(get(closedOutbounds, '[0]', {})), '[0]')
      key && setExpandedCs([key])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closedOutbounds])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Table className='closed-outbounds-list custom-table'>
          <TableHead className='table-head'>
            <TableRow className='table-row'>
              <TableCell className='table-cell' width={20}>
                <FuseCheckBox checked={allSelected}
                  onChange={() => {
                    allSelected ? selectNone() : selectAll()
                  }} />
              </TableCell>
              <TableCell className='table-cell'>CS</TableCell>
              <TableCell className='table-cell'>Outbound</TableCell>
              <TableCell className='table-cell'>Label</TableCell>
              <TableCell className='table-cell'>Weight</TableCell>
              <TableCell className='table-cell'>Volume</TableCell>
              <TableCell className='table-cell'>Consignee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='table-body'>
            {closedOutbounds.length === 0 && (
              <React.Fragment>
                {loading &&
                  [1, 2, 3].map(rowItem => {
                    return (
                      <TableRow key={`row-${rowItem}`}
                        className='table-row'>
                        {[1, 2, 3, 4, 5, 6, 7].map(colItem => {
                          return (
                            <TableCell key={`col-${colItem}`}
                              className='table-cell'>
                              <Skeleton width={colItem === 1 ? 14 : 100} />
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })
                }
                {!loading &&
                  <TableRow className='table-row'>
                    <TableCell colSpan={7} className='no-records' />
                  </TableRow>
                }
              </React.Fragment>
            )}
            {closedOutbounds.map((cItem, cItemIndex) => {
              const key = get(Object.keys(cItem), '[0]')

              return (
                <React.Fragment>
                  <TableRow className='table-row' hover key={cItemIndex}>
                    <TableCell colSpan={7} className='table-cell bold'>
                      <Grid container justify="space-between" alignItems="center" spacing={1}>
                        <Grid item>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <FuseCheckBox checked={selectedCs.includes(key)}
                                value={key}
                                onChange={handleCsSelect} />
                            </Grid>
                            <Grid item>
                              <Typography className="semi-bold text-grey-darker">{key}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid container justify="flex-end" alignItems="center" spacing={2}>
                            <Grid item>
                              <Typography className="regular fg-gray text-12">
                                {`${get(cItem, key, []).length} outbound(s)`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography className="semi-bold fg-blue text-14 cursor-pointer px-8 bg-light-blue rounded"
                                onClick={() => handleCsExpandToggle(key)}>
                                {expandedCs.includes(key) && 'Hide Outbounds'}
                                {!expandedCs.includes(key) && 'Show Outbounds'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  {get(cItem, key, []).map(outbound => {
                    let selected = selectedOutBoundIds.includes(outbound._id)

                    if (expandedCs.includes(key)) {
                      return (
                        <TableRow key={outbound._id} className='table-row' hover>
                          <TableCell className='table-cell' width={20}>
                            <Checkbox checked={selected}
                              onChange={() => {
                                selected ? unSelectOutbound([outbound._id]) : selectOutbound([outbound._id])
                              }} />
                          </TableCell>
                          <TableCell className='table-cell'>{get(outbound, 'meta.CSCode', '')}</TableCell>
                          <TableCell className='table-cell'>{get(outbound, 'meta.outboundId', '')}</TableCell>
                          <TableCell className='table-cell'>
                            <Grid container spacing={1}>
                              {get(outbound, 'goodsType', '') === 'normal' &&
                                <Grid item><span className={classes.normalGoodsType}>Normal</span></Grid>
                              }
                              {get(outbound, 'goodsType', '') === 'DG' &&
                                <Grid item><span className={classes.dgGoodsType}>DG</span></Grid>
                              }
                              {get(outbound, 'shipmentMode', '') === 'drop' &&
                                <Grid item><span className={classes.dropShipmentMode}>Drop</span></Grid>
                              }
                              {get(outbound, 'shipmentMode', '') === 'direct' &&
                                <Grid item><span className={classes.directShipmentMode}>Direct</span></Grid>
                              }
                            </Grid>
                          </TableCell>
                          <TableCell className='table-cell'>
                            {get(outbound, 'weight.value', 0)}&nbsp;{get(outbound, 'weight.unit', 'KG')}
                          </TableCell>
                          <TableCell className='table-cell'>
                            {get(outbound, 'volume.value', 0)} {get(outbound, 'volume.unit', 'M3')}
                          </TableCell>
                          <TableCell className='table-cell'>{get(outbound, 'shipToParty.name', '')}&nbsp;({get(outbound, 'shipToParty.code', '')})</TableCell>
                        </TableRow>
                      )
                    } else {
                      return <React.Fragment />
                    }
                  })}
                </React.Fragment>
              )
            })}
          </TableBody>
        </Table>
      </Grid>
      {hasMore &&
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <LoadMore loading={loadingMore} onClick={onLoadMore} />
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  )
}

const styles = {
  dgGoodsType: {
    color: '#cf2a2a'
  },
  normalGoodsType: {
    color: '#7db5e8'
  },
  directShipmentMode: {
    color: '#e47e00'
  },
  dropShipmentMode: {
    color: '#e47e00'
  }
}

export default withStyles(styles)(OutboundList)
