import React, { Component } from 'react'
import Steps from 'rc-steps'
import _ from 'lodash'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { withStyles } from '@material-ui/core/styles'
import 'rc-steps/assets/index.css'
import 'rc-steps/assets/iconfont.css'
import history from '../../history'
import moment from 'moment'
import { getRounded } from 'core/helpers'
import {
  formatTimestamp,
  getAddressFromLocation,
  shipmentNameBeautifier,
  getStatusHistory,
  getExceptionTypes,
  getDistinctContainers
} from '../helpers'

const styles = theme => ({
  root: {
    background: '#FFFFFF'
  }
})

class ShipmentCard extends Component {
  state = {
    reviewStatus: {
      ofp: ['pending', 'amended'],
      /** @todo remove pending from obl when we fix list */
      obl: ['freight-partner-confirmed'],
      consignee: ['in-transit']
    }
  }

  onClickShipItem = (event, id) => {
    history.push(`${window.location.pathname}/${id}`)
  }

  getTagClass = (statusType, shipment) => {
    switch (statusType) {
      case 'exception-type':
        return 'orange-tag-exception'
      case 'current-status': {
        const currentStatus = _.get(shipment, 'status.current.value').toLowerCase()
        if (currentStatus.includes('confirmed')) return 'green-tag'
        else if (currentStatus.includes('rejected')) return 'red-tag'
        else if (currentStatus.includes('amended')) return 'orange-tag'
        return ''
      }
      case 'time-delay': {
        return '' // @TODO: if 1-3 days delayed - yellow, if 4 to 7 organge, if 8 + red. ontime is green
      }
      default:
        return ''
    }
  }

  getStatusHoverText = (statusType, shipment) => {
    switch (statusType) {
      case 'exception-type':
        return getExceptionTypes(shipment.exceptionMode.details) // @TODO Show all exception types in a list
      case 'current-status': {
        return getStatusHistory(shipment.status)
      }
      case 'time-delay': {
        return '' // @TODO: if 1-3 days delayed - yellow, if 4 to 7 organge, if 8 + red. ontime is green
      }
      default:
        return ''
    }
  }

  getTagValue = (statusType, shipment) => {
    statusType === 'exception-type' ? _.get(shipment, 'exceptionMode.type') : _.get(shipment, 'status.current.value')
    switch (statusType) {
      case 'exception-type':
        return shipment.exceptionMode.details.map(x => x.type) //_.get(shipment, 'exceptionMode.type')
      case 'current-status':
        return [_.get(shipment, 'status.current.value')]
      case 'time-delay':
        return [_.get(shipment, 'status.current.value')] // @TODO: Need to change this to actual - planned
      default:
        return []
    }
  }

  getTimeBasedOnEvent = (code, entireObj) => {
    if (Object.keys(_.get(entireObj, 'events', {})).length > 0) {
      var eventMatched = _.find(entireObj.events, function(obj) {
        if (obj.hasOwnProperty('eventLabel')) {
          return obj.eventLabel.number === code && obj.source === 'tier1'
        }
      })
      if (eventMatched !== undefined && eventMatched.eventLabel.type === 'A' && eventMatched.eventLabel.number === '2540') {
        return this.getFormattedDate(eventMatched.occurenceDate)
      }
      if (eventMatched !== undefined && eventMatched.eventLabel.type === 'E' && eventMatched.eventLabel.number === '2540') {
        return this.getFormattedDate(eventMatched.occurenceDate)
      }
      if (eventMatched !== undefined && eventMatched.eventLabel.type === 'A' && eventMatched.eventLabel.number === '3600') {
        return this.getFormattedDate(eventMatched.occurenceDate)
      }
      if (eventMatched !== undefined && eventMatched.eventLabel.type === 'E' && eventMatched.eventLabel.number === '3600') {
        return this.getFormattedDate(eventMatched.occurenceDate)
      }
      if (eventMatched !== undefined && eventMatched.eventLabel.type === 'A' && eventMatched.eventLabel.number === '4200') {
        return this.getFormattedDate(eventMatched.occurenceDate)
      }
      if (eventMatched !== undefined && eventMatched.eventLabel.type === 'E' && eventMatched.eventLabel.number === '4200') {
        return this.getFormattedDate(eventMatched.occurenceDate)
      }
      if (eventMatched !== undefined && eventMatched.eventLabel.type === 'A' && eventMatched.eventLabel.number === '5200') {
        return this.getFormattedDate(eventMatched.occurenceDate)
      }
      if (eventMatched !== undefined && eventMatched.eventLabel.type === 'E' && eventMatched.eventLabel.number === '5200') {
        return this.getFormattedDate(eventMatched.occurenceDate)
      }
      if (eventMatched === undefined) {
        return '-'
      }
    }
  }

  getDescriptionName = (title, label, referenceEvent) => {
    let line1 = `Est. ${label}: -------`
    if (_.get(referenceEvent, 'actualAt')) {
      line1 = `${label}: ${moment(_.get(referenceEvent, 'actualAt')).format('DD/MM/YYYY h:mm a')}`
    } else if (_.get(referenceEvent, 'estimatedAt')) {
      line1 = `Est. ${label}:${moment(_.get(referenceEvent, 'estimatedAt')).format('DD/MM/YYYY h:mm a')}`
    }
    let line2 = `Plan. ${label}: -------`
    if (_.get(referenceEvent, 'expectedAt')) {
      line2 = `Plan ${label}: ${moment(_.get(referenceEvent, 'expectedAt')).format('DD/MM/YYYY h:mm a')}`
    }
    return (
      <>
        <Typography className='step__description' title={title} noWrap>
          {title}
        </Typography>
        <Typography
          className='step__description'
          style={{ fontWeight: '200', color: '#949494', fontStyle: 'italic' }}
          title={line1}
          noWrap
        >
          {line1}
        </Typography>
        <Typography
          className='step__description'
          style={{ fontWeight: '200', color: '#949494', fontStyle: 'italic' }}
          title={line2}
          noWrap
        >
          {line2}
        </Typography>
      </>
    )
  }

  countPartners = () => {
    const { shipment } = this.props
    const actors = _.get(shipment, 'actors', {})
    var count = 0
    Object.keys(actors).forEach(key => {
      if (Array.isArray(actors[key]) && actors[key].length > 0) {
        count++
      }
    })

    return count
  }

  render() {
    const {
      shipment,
      role,
      onReview,
      onMarkReceive,
      onPartnerSidebarClick,
      onOutboundsSidebarClick,
      statusType
    } = this.props
    const { reviewStatus } = this.state
    const tagValue = this.getTagValue(statusType, shipment)
    const tagClass = this.getTagClass(statusType, shipment)
    return (
      <Grid container className='shipment-list-item'>
        <Grid
          item
          xs={9}
          className='shipment-list-item-card cursor-pointer'
          onClick={event => this.onClickShipItem(event, shipment._id)}
        >
          <Grid container alignItems='stretch' className='h-full'>
            <Grid item xs={12}>
              <Grid container justify='space-between' alignItems='center' className='mb-20'>
                <Grid item>
                  <Typography className='shipment-list-item__sr-number'>
                    {shipmentNameBeautifier(_.get(shipment, 'chat.roomName', '') || _.get(shipment, 'name', ''))}
                    {_.get(shipment, 'stage.current.value') === 'in-transit' &&
                      _.get(shipment, 'summary.tracking.available') && (
                        <img
                          src='assets/images/icons/green-verified-tick.jpg'
                          height='25'
                          alt='Tracking available'
                          title='Tracking available'
                          className='ml-6'
                        />
                      )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container justify='flex-end' alignItems='center'>
                    <Grid item>
                      <Grid container alignItems='center'>
                        {_.get(shipment, 'type') === 'LCL' && (
                          <img src='assets/images/icons/shipments-list/LCL.svg' alt='type' className='mr-6' />
                        )}
                        {_.get(shipment, 'type') === 'FCL' && (
                          <img src='assets/images/icons/shipments-list/FCL.svg' alt='type' className='mr-6' />
                        )}
                        <Grid item className='mr-4 medium'>
                          {_.get(shipment, 'type', 'N/A')}
                        </Grid>
                        <Grid item className='mr-4'>
                          &#183;
                        </Grid>
                        <Grid
                          item
                          title={shipment.type === 'FCL' ? getDistinctContainers(_.get(shipment, 'containers', [])) : ''}
                        >
                          {shipment.type === 'FCL' && _.get(shipment, 'containers', []).length + ' Containers'}
                          {shipment.type === 'LCL' &&
                            getRounded(shipment, 'weight.value', 0, 3) + ' ' + _.get(shipment, 'weight.unit', 'kg')}
                        </Grid>
                      </Grid>
                    </Grid>

                    {_.get(shipment, 'specialLabels', []).length > 0 && (
                      <Grid item className='ml-24'>
                        <Grid container alignItems='center'>
                          <img
                            src='assets/images/icons/shipments-list/special-label.svg'
                            alt='special-label'
                            className='mr-6'
                          />
                          <Typography className='uppercase medium text-12'>
                            {_.get(shipment, 'specialLabels', []).join(', ')}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}

                    {_.get(shipment, 'shipmentMode') === 'drop' && (
                      <Grid item className='ml-24'>
                        <Grid container alignItems='center'>
                          <img src='assets/images/icons/shipments-list/drop-hub.svg' alt='dropHub' className='mr-6' />
                          <Typography className='uppercase medium text-12'>
                            {_.get(shipment, 'shipmentMode')}&nbsp;
                          </Typography>
                          (
                          <Typography
                            className='capitalize medium text-12 max-w-136'
                            title={_.get(shipment, 'actors.dropHub[0].name')}
                            noWrap
                          >
                            {_.get(shipment, 'actors.dropHub[0].name')}
                          </Typography>
                          )
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Steps className='shipment-list-item-progress'>
                  <Steps.Step
                    className={`shipment-list-item-progress-step warehouse${
                      ['origin', 'originPort', 'destinationPort', 'destination'].indexOf(
                        _.get(shipment, 'summary.currentJourneyLandMark', '')
                      ) > -1
                        ? '-current'
                        : ''
                    }`}
                    title={this.getTimeBasedOnEvent('2540', shipment)}
                    description={this.getDescriptionName(
                      _.get(shipment, 'plannedRoute.origin.name'),
                      'Truck Out',
                      _.get(shipment, 'referenceEvents.truckOutWithCargo')
                    )}
                  />
                  <Steps.Step
                    className={`shipment-list-item-progress-step port${
                      ['originPort', 'destinationPort', 'destination'].indexOf(
                        _.get(shipment, 'summary.currentJourneyLandMark', '')
                      ) > -1
                        ? '-current'
                        : ''
                    }`}
                    title={this.getTimeBasedOnEvent('3600', shipment)}
                    description={this.getDescriptionName(
                      `Port: ${_.get(shipment, 'plannedRoute.originPort.name')} (${_.get(
                        shipment,
                        'plannedRoute.originPort.locode'
                      )})`,
                      'Dep',
                      _.get(shipment, 'referenceEvents.departFromPOL')
                    )}
                  />
                  <Steps.Step
                    className={`shipment-list-item-progress-step port${
                      ['destinationPort', 'destination'].indexOf(_.get(shipment, 'summary.currentJourneyLandMark', '')) > -1
                        ? '-current'
                        : ''
                    }`}
                    title={this.getTimeBasedOnEvent('4200', shipment)}
                    description={this.getDescriptionName(
                      `Port: ${_.get(shipment, 'plannedRoute.destinationPort.name')} (${_.get(
                        shipment,
                        'plannedRoute.destinationPort.locode'
                      )})`,
                      'Arr',
                      _.get(shipment, 'referenceEvents.arriveAtPOD')
                    )}
                  />
                  <Steps.Step
                    className={`shipment-list-item-progress-step warehouse${
                      ['destination'].indexOf(_.get(shipment, 'summary.currentJourneyLandMark', '')) > -1 ? '-current' : ''
                    } last-step`}
                    title={this.getTimeBasedOnEvent('5200', shipment)}
                    description={this.getDescriptionName(
                      _.get(shipment, 'plannedRoute.destination.name'),
                      'Delivery',
                      _.get(shipment, 'referenceEvents.deliveryAtConsigneeWarehouse')
                    )}
                  />
                </Steps>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='space-between' alignItems='center' className='mt-20'>
                <Grid item>
                  {/* Invisible for now as w don't know how to show latest event */}
                  <Grid container alignItems='center'>
                    <Grid item className='shipment-list-item-current-event marker'></Grid>
                    <Grid
                      item
                      title={`Event Code: ${_.get(
                        shipment,
                        'summary.latestActualEvent.code'
                      )}, Location: ${getAddressFromLocation(_.get(shipment, 'summary.latestActualEvent.location')) ||
                        'Not confirmed'}`}
                    >
                      <Typography className='shipment-list-item-current-event'>
                        {_.get(shipment, 'summary.latestActualEvent.name') ||
                          _.get(shipment, 'summary.latestActualEvent.code')}
                      </Typography>
                    </Grid>
                    <Grid item className='mx-4'>
                      &#183;
                    </Grid>
                    <Grid item title={_.get(shipment, 'summary.latestActualEvent.type')}>
                      <Typography className='shipment-list-item-current-event'>
                        {formatTimestamp(_.get(shipment, 'summary.latestActualEvent.occurenceAt'), 'date-time')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {
                    <Grid container justify='flex-end' alignItems='center'>
                      <Grid item className='mr-8'>
                        <Grid container alignItems='center'>
                          <img src='assets/images/icons/shipments-list/ship-name.png' alt='ship-name' />
                        </Grid>
                      </Grid>
                      <Grid item>
                        {_.get(shipment, 'vesselSchedule.FirstPOL.vessel.name') ||
                          _.get(shipment, 'freightOperatorInfo.name') ||
                          'Not Decided'}
                        {/* {[shipment.vesselSchedule.FirstPOL.vessel.name, ...shipment.vesselSchedule.transhipmentPorts.map(x => x.vessel.name)].join(", ")} */}
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} className='shipment-list-item-card'>
          <Grid container alignItems='stretch' className='h-full'>
            <Grid item xs={2}>
              {_.get(shipment, 'stage.current.value') === 'in-transit' && _.get(shipment, 'summary.trackingLink') && (
                <Grid container justify='flex-start' alignItems='center' className='mb-20'>
                  <Grid
                    title={`Track shipment using global tracker`}
                    item
                    onClick={() => window.open(_.get(shipment, 'summary.trackingLink', ''), '_blank')}
                    className={'shipment-list-item-tag cursor-pointer green-tag'}
                  >
                    Track
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={10}>
              <Grid container justify='flex-end' alignItems='center' className='mb-20'>
                {tagValue.slice(0, 2).map(tag => {
                  return (
                    <Grid
                      title={this.getStatusHoverText(statusType, shipment)}
                      item
                      className={classNames('shipment-list-item-tag cursor-pointer', tagClass)}
                      key={tag}
                    >
                      {tag}
                    </Grid>
                  )
                })}
                {tagValue.length > 2 && (
                  <Grid
                    title={this.getStatusHoverText(statusType, shipment)}
                    item
                    className={classNames('shipment-list-item-tag cursor-pointer', tagClass)}
                  >
                    +{tagValue.length - 2}
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className='shipment-list-item-summery'>
                <Grid item xs={12} sm={5} className='shipment-list-item-summery__key'>
                  Partners
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid container justify='flex-end' onClick={() => onPartnerSidebarClick(_.get(shipment, 'actors'))}>
                    <Typography className='shipment-list-item-summery__value text-right'>
                      {this.countPartners()}&nbsp;partners involved
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className='shipment-list-item-summery'>
                <Grid item xs={12} sm={5} className='shipment-list-item-summery__key'>
                  Documents
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid container justify='flex-end'>
                    <Typography className='shipment-list-item-summery__value no-underline text-right'>
                      {_.get(shipment, 'summary.actions.docs.me.done', []).length}&nbsp;uploaded,{' '}
                      {_.get(shipment, 'summary.actions.docs.me.pending', []).length}&nbsp;pending
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className='shipment-list-item-summery'>
                <Grid item xs={12} sm={5} className='shipment-list-item-summery__key'>
                  CS/Outbounds
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid container justify='flex-end' onClick={() => onOutboundsSidebarClick(_.get(shipment, '_id'))}>
                    <Typography className='shipment-list-item-summery__value text-right'>
                      {_.get(shipment, 'summary.csCount')}&nbsp;CS, {_.get(shipment, 'outbounds', []).length}&nbsp;outbounds
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container className='shipment-list-item-actions'>
                {!_.get(reviewStatus, role, []).includes(_.get(shipment, 'status.current.value')) && (
                  <Button
                    className='btn btn_lg w-full btn_view'
                    style={{ borderRadius: '50vh' }}
                    onClick={event => this.onClickShipItem(event, shipment._id)}
                  >
                    VIEW DETAILS
                  </Button>
                )}
                {(role === 'ofp' || role === 'obl') &&
                  _.get(reviewStatus, role, []).includes(_.get(shipment, 'status.current.value')) && (
                    <Button className='btn btn_lg btn_light_green w-full' onClick={() => onReview(shipment)}>
                      REVIEW BOOKING
                    </Button>
                  )}
                {role === 'consignee' && _.get(reviewStatus, role, []).includes(_.get(shipment, 'status.current.value')) && (
                  <Button className='btn btn_lg btn_light_green w-full' onClick={() => onMarkReceive(shipment)}>
                    MARK AS RECEIVED
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles, { withTheme: true })(ShipmentCard)
