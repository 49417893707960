import React from 'react'
import { withStyles, Button, CircularProgress } from '@material-ui/core'

const LoadingButton = ({
  label,
  type,
  children,
  classes,
  className,
  loading,
  disabled,
  ...otherProps
}) => (
  <Button
    id={label}
    type={type || 'button'}
    disabled={loading || disabled}
    className={className}
    {...otherProps}
  >
    {!loading && children}
    {loading && <CircularProgress size={24} className={classes.loadingIcon} />}
  </Button>
)

const styles = theme => ({
  loadingIcon: {
    color: '#ffffff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
})

export default withStyles(styles)(LoadingButton)
