import React, { useState, useEffect } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { Timeline } from 'antd'
import moment from 'moment'
import { BookingHistorySkeleton } from "./skeletons";
import { get } from "lodash";

const config = {
  //'ready-to-propose': { icon: 'directions_boat', background: '#3DCD58', label: 'Booking Pending', actor: 'Consignee' },
  proposed: { svg: 'proposed.svg', label: 'Booking Proposed', actor: 'Shipper' },
  updated: { svg: 'updated.svg', label: 'Booking Updated', actor: 'Shipper' },
  'freight-partner-confirmed': { svg: 'approved.svg', label: 'Booking Accepted', actor: 'EFP' },
  'obl-confirmed': { svg: 'updated.svg', label: 'Booking Acknowledged', actor: 'ELP' },
  'freight-partner-rejected': { svg: 'rejected.svg', label: 'Booking Rejected', actor: 'EFP' },
  'obl-rejected': { svg: 'rejected.svg', label: 'ELP Rejected', actor: 'ELP' },
  cancelled: { svg: 'rejected.svg', label: 'Booking Cancelled', actor: 'Shipper' },
  'exception-raised': { svg: 'exception.svg', label: 'Booking marked as exception', actor: 'Shipper' },
  amended: { svg: 'amended.svg', label: 'Booking Amended', actor: 'Shipper' },
  'in-transit': { svg: 'event-ship.svg', label: 'Booking is In transit', actor: 'ELP' },
  import: { svg: 'event-ship.svg', label: 'Booking in Inbound', actor: 'ILP' },
  completed: { svg: 'completed.svg', label: 'Booking completed', actor: 'Consignee' }
}
const BookingHistory = ({ shipment, classes }) => {
  const [statusArray, setStatusArray] = useState([])
  useEffect(() => {
    if (shipment.history) {
      let arr = shipment.history
      arr = arr.map(a => ({
        movedToAt: moment(a.movedToAt).format('Do MMM YYYY, h:mm A'),
        _id: a._id,
        reason: a.reason,
        user: a.user,
        ...config[a.value]
      }))
      arr = arr.reverse()
      setStatusArray(arr)
    }
  }, [shipment])
  return (
    <Grid container className='booking-details'>
      <Grid item xs={12} className='shipment-details-container-section'>
        <Grid container>
          <Grid item xs={12} className={classes.title}>
            <Typography className='section__title bold mb-12'>Booking history with additional notes</Typography>
          </Grid>
          {!get(shipment, 'history') &&
            <Grid item xs={12} className="pl-8">
              <BookingHistorySkeleton />
            </Grid>
          }
          <Grid item xs={12} className="pl-8">
            <Grid container>
              <Timeline className={classes.timeline}>
                {statusArray.map((item, index) => {
                  return (
                    <Timeline.Item
                      dot={
                        <span className={classes.indicatorIcon}>
                          <img src={`/assets/images/icons/booking_history/${item.svg}`}
                            alt={item.label} />
                        </span>
                      }
                      className={classes.timelineItem}
                      key={index}>
                      <Grid container alignItems="center">
                        <Grid item xs={8}
                          className={classes.timelineItemLeft}>
                          <Grid container
                            alignItems="center"
                            spacing={1}>
                            <Grid item className="semi-bold">{item.label}</Grid>
                            <Grid item className="fg-gray">&bull;</Grid>
                            <Grid item className="regular fg-gray">
                              {get(item, 'actor')}
                            </Grid>
                            <Grid item className="fg-gray">&bull;</Grid>
                            <Grid item className="regular fg-gray">
                              {get(item, 'user.firstname')}&nbsp;{get(item, 'user.lastname')}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} className={classes.timelineItemRight}>
                          {item.movedToAt}
                        </Grid>
                        <Grid item xs={8} className={classes.timelineNote}>
                          Note: {item.reason}
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </Timeline.Item>
                  )
                })}
              </Timeline>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
const styles = {
  timeline: {
    width: '100%',
    padding: '20'
  },
  title: {
    marginBottom: 20
  },
  dot: {
    fontSize: 6,
    marginLeft: 10,
    marginRight: 10
  },
  timelineItemLeft: {
    textAlign: 'left',
    paddingLeft: 10,
  },
  timelineItemRight: {
    textAlign: 'right',
    color: 'grey'
  },
  timelineItem: {
    paddingBottom: '40px !important'
  },
  timelineNote: {
    color: 'grey',
    fontSize: 12,
    fontWeight: 300,
    fontStyle: 'italic',
    paddingLeft: 10
  },
  indicatorIcon: {
    width: 50,
    height: 50
  }
}
export default withStyles(styles)(BookingHistory)
