export function getAPIBaseURL() {
  // TODO: @thakurat44 do not merge the next line to production
  if(localStorage && localStorage.getItem("ALTERNATE_API_URL")) return localStorage.getItem("ALTERNATE_API_URL");
  return process.env.REACT_APP_API_BASE_URL
}

export function getGATrackId() {
  switch (window.location.hostname) {
    case 'se.ifo.trames.io':
      return process.env.REACT_APP_GOOGLE_TRACKING_ID
    case 'dev01.ifo.trames.io':
      return process.env.REACT_APP_GOOGLE_TRACKING_ID_DEV
    default:
      return null
  }
}
