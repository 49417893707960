import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  withStyles,
  Grid, Typography, Divider,
  ClickAwayListener, Card, Zoom,
  MenuItem, Button
} from '@material-ui/core'
import { FusePlaceHolder, LoadMore, InfoToolTip } from '../../core/components'
import { filter, get } from 'lodash'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatTimestamp, shipmentNameBeautifier, getShipmentLink } from '../../core/helpers'
import Skeleton from 'react-loading-skeleton';
import TimerIcon from '@material-ui/icons/Timer';
import moment from 'moment'
import DateTime from 'react-datetime';
import { AutoComplete } from 'core/components/Inputs';
import LayersIcon from '@material-ui/icons/Layers';
import InfoTooltip from 'core/components/InfoToolTip';

const priorityColors = {
  high: 'bg-red text-white',
  medium: 'bg-orange-dark text-white',
  low: 'bg-orange-light text-white'
}

var localTime = moment().format('YYYY-MM-DD'); // store localTime
var proposedDate = localTime + "T00:00:00.000Z"

const PopOverComponent = ({ anchorEl, setAnchorEl, height = 250, width = 200, right = 'auto', left = 'auto', children }) => {
  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Zoom direction="up" in={anchorEl !== null}>
        <Card className="border-solid border-1 border-gray shadow-none rounded-4 bg-white"
          style={{ position: 'absolute', height, width, zIndex: 1, top: 0, right, left }}>
          <Grid container className="py-4 px-6">
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Card>
      </Zoom>
    </ClickAwayListener>
  )
}

const TaskList = ({ getShipmentPosts, linkPost, loading, title, tasks, classes, selectedTask, setSelectedTask, requesting, markComplete, userInfo, shipments, loadingMore = false, hasMore = false, loadMore, users, onAssign, onDueDate, onPriorityChange, onDismiss }) => {
  const [assigneeAnchorEl, setAssigneeAnchorEl] = useState(null);
  const [dueAnchorEl, setDueAnchorEl] = useState(null);
  const [priorityAnchorEl, setPriorityAnchorEl] = React.useState(null);
  const [linkPostAnchorEl, setLinkPostAnchorEl] = React.useState(null);

  const handlePrioritySelect = (value) => {
    const priority = get(priorityAnchorEl, 'task.priority')
    if (value !== priority) {
      onPriorityChange(priorityAnchorEl, value)
      setPriorityAnchorEl(null)
    }
  }

  return (
    <Grid container className="max-w-full w-360">
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="fg-gray medium text-14">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {loading &&
                [1, 2, 3].map(i => {
                  return (
                    <Grid item xs={12} key={i}
                      className={classes.taskCard}>
                      <Grid container>
                        <Grid item xs={12} className="text-14"><Skeleton /></Grid>
                        <Grid item xs={10} className="text-12"><Skeleton /></Grid>
                        <Grid item xs={9} className="text-12"><Skeleton /></Grid>
                      </Grid>
                    </Grid>
                  )
                })
              }
              {(!loading && tasks.length === 0) &&
                <Grid item xs={12}>
                  <FusePlaceHolder icon="info"
                    title="No Tasks"
                    description="Looks like there are no tasks available in this list" />
                </Grid>
              }
              {tasks.map((task, taskIndex) => {
                const taskId = get(task, '_id', '')
                const name = get(task, 'task.name', '')
                const taskCategory = get(task, 'task.action.category', '')
                const taskType = get(task, 'task.type', 'Shipment')
                const priority = get(task, 'task.priority', '')
                const shipment = get(task, 'shipmentId', {})
                const type = get(task, 'task.identifier.shipmentType', [])
                const blockers = filter(get(task, 'task.dependency', []), i => i.status !== 'completed')
                const assignedUsers = get(task, 'task.assignee.user', [])
                const assignedUsersCount = assignedUsers.length
                const assignUsersOption = filter(users, i => !assignedUsers.includes(i._id))
                  .map(u => ({
                    value: u._id,
                    label: `${get(u, 'firstname')} ${get(u, 'lastname')}` || 'No Name'
                  }))
                const meAssigned = get(task, 'task.assignee.user', []).includes(get(userInfo, '_id', ''))
                const linkedPosts = get(task, 'linkedPosts[0]')
                const shipmentLink = getShipmentLink(shipment)
                const posts = get(task, 'shipmentPostInfo', [])

                return (
                  <Grid item xs={12} key={taskIndex}
                    className={classes.taskCard}>
                    <Grid container spacing={1}>
                      {/* Name and Priority */}
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs>
                            <Typography className="semi-bold regular text-14 leading-none" >
                              {name}
                            </Typography>
                          </Grid>
                          <Grid item className="relative">
                            <Typography className={"semi-bold px-6 leading-normal rounded text-11 uppercase cursor-pointer " + get(priorityColors, priority)}
                              onClick={() => onPriorityChange && setPriorityAnchorEl(task)} >
                              {priority}
                            </Typography>
                            {get(priorityAnchorEl, '_id') === taskId &&
                              <PopOverComponent anchorEl={priorityAnchorEl}
                                setAnchorEl={setPriorityAnchorEl}
                                width="auto" height="auto" right={0}>
                                <MenuItem onClick={() => handlePrioritySelect('high')}
                                  selected={priority === 'high'}>High</MenuItem>
                                <MenuItem onClick={() => handlePrioritySelect('medium')}
                                  selected={priority === 'medium'}>Medium</MenuItem>
                                <MenuItem onClick={() => handlePrioritySelect('low')}
                                  selected={priority === 'low'}>Low</MenuItem>
                              </PopOverComponent>
                            }
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Type and Category */}
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item>
                            <Typography className="semi-bold px-4 text-10 uppercase bg-grey-dark text-white" >
                              {taskType}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography className="semi-bold px-4 text-10 uppercase bg-grey text-white" >
                              {taskCategory}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Shipment and Shipment Type */}
                      <Grid item xs={12} >
                        <Grid container spacing={1} justify="space-between" alignItems="center">
                          <Grid item>
                            <InfoTooltip title="Go to the Shipment">
                              <Link to={`${get(shipmentLink, 'url')}/${get(task, 'shipmentId._id')}`}
                                className={classes.linkStyle}>
                                <Typography className={'semi-bold uppercase text-10 fg-gray cursor-pointer'}>
                                  {shipmentNameBeautifier(get(shipment, 'name', ''))}
                                </Typography>
                              </Link>
                            </InfoTooltip>
                          </Grid>
                          <Grid item>
                            <Typography className="fg-gray semi-bold uppercase text-10">
                              {type.join(', ')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}><Divider /></Grid>

                      {/* Due, Users, Blockers */}
                      <Grid item xs={12}>
                        <Grid container spacing={1} justify="space-between" alignItems="center">
                          <Grid item>
                            <Grid container spacing={2} alignItems="center">
                              <Grid item>
                                <Grid container alignItems="center" className="relative">
                                  <InfoToolTip title={onDueDate ? "Click to add due date" : "Due Date"}>
                                    <TimerIcon className="fg-gray mr-6 text-14 cursor-pointer"
                                      aria-controls="simple-popover"
                                      aria-haspopup="true"
                                      onClick={() => onDueDate && setDueAnchorEl(task)} />
                                  </InfoToolTip>
                                  <Grid item>
                                    {get(task, 'task.taskDueDate', '') >= proposedDate ?
                                      <Typography className="medium text-10 fg-gray">
                                        {formatTimestamp(get(task, 'task.taskDueDate', ''))} </Typography> :

                                      <Typography className="medium text-10 fg-red">
                                        {formatTimestamp(get(task, 'task.taskDueDate', ''))} </Typography>
                                    }
                                  </Grid>
                                  {get(dueAnchorEl, '_id') === taskId &&
                                    <PopOverComponent anchorEl={dueAnchorEl}
                                      setAnchorEl={setDueAnchorEl}
                                      width={265} height={285}>
                                      <DateTime className="theme-date-time-picker-field"
                                        input
                                        inputProps={{
                                          placeholder: 'Select Date'
                                        }}
                                        dateFormat="DD MMM YYYY"
                                        timeFormat=""
                                        onChange={(date) => {
                                          if (new Date(date).toString() === 'Invalid Date') {
                                            date = new Date()
                                          }
                                          onDueDate(task, date)
                                          setDueAnchorEl(null)
                                        }} />
                                    </PopOverComponent>
                                  }
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Grid container alignItems="center" className="relative">
                                  <InfoToolTip title={onAssign ? "Click to Assign" : "Assignee"}>
                                    <AccountCircleIcon className="fg-gray mr-6 text-14 cursor-pointer"
                                      aria-controls="simple-menu"
                                      aria-haspopup="true"
                                      onClick={(e) => onAssign && setAssigneeAnchorEl(task)} />
                                  </InfoToolTip>

                                  {assignedUsersCount > 0 &&
                                    <Grid item>
                                      <Typography className="regular text-10 fg-gray">
                                        {(meAssigned && assignedUsersCount === 1) &&
                                          `You`
                                        }
                                        {(meAssigned && assignedUsersCount > 1) &&
                                          `You & ${(assignedUsersCount - 1)} others`
                                        }
                                        {(!meAssigned && assignedUsersCount > 0) &&
                                          `${(assignedUsersCount)} users`
                                        }
                                      </Typography>
                                    </Grid>
                                  }
                                  {get(assigneeAnchorEl, '_id') === taskId &&
                                    <PopOverComponent anchorEl={assigneeAnchorEl}
                                      setAnchorEl={setAssigneeAnchorEl}
                                      height={360}>
                                      <AutoComplete options={assignUsersOption}
                                        className="text-14 regular"
                                        value=""
                                        placeholder="Select Users"
                                        onChange={(value) => {
                                          onAssign(task, value)
                                          setAssigneeAnchorEl(null)
                                        }} />
                                    </PopOverComponent>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {blockers.length > 0 &&
                            <Grid item>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Typography className="fg-red regular text-10">
                                    {`Blocked by ${blockers.length}`}
                                  </Typography>
                                </Grid>
                                <LayersIcon className="fg-red ml-6 text-14" />
                              </Grid>
                            </Grid>
                          }
                          {get(selectedTask, '_id') !== taskId &&
                            <Grid item xs={12}>
                              <Grid container justify="center" alignItems="center">
                                <InfoToolTip title="Expand Task">
                                  <ExpandMoreIcon className={'text-24 cursor-pointer ' + (shipments.length ? 'fg-blue' : 'fg-gray')}
                                    onClick={() => shipments.length > 0 && setSelectedTask(task)} />
                                </InfoToolTip>
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                      </Grid>

                      {get(selectedTask, '_id') === taskId &&
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography className="regular text-10 fg-gray">
                                {get(task, 'task.description', '')}
                              </Typography>
                            </Grid>
                            {blockers.length > 0 &&
                              <Grid item xs={12}>
                                <Grid container className="bg-light-orange p-8">
                                  <Grid item xs={12}>
                                    <Typography className="text-10 fg-orange semi-bold">
                                      {`${blockers.length} dependent tasks`}
                                    </Typography>
                                  </Grid>
                                  {blockers.map((bItem, bItemIndex) => {
                                    return (
                                      <Grid item xs={12} key={bItemIndex}>
                                        <Typography className="fg-red regular text-10 p-4 pl-8">
                                          {`${get(bItem, 'value')} is ${get(bItem, 'status', 'incomplete')}`}
                                        </Typography>
                                      </Grid>
                                    )
                                  })}
                                </Grid>
                              </Grid>
                            }

                            {(linkedPosts || posts.length > 0) &&
                              <Grid item xs={12} className="relative">
                                {linkedPosts &&
                                  <Grid container>
                                    <Link to={`/notice-board/${get(linkedPosts, '_id')}`}>
                                      <Typography className="text-12 semi-bold fg-orange bg-light-orange px-4">
                                        {get(linkedPosts, 'title', 'No Title')}
                                      </Typography>
                                    </Link>
                                  </Grid>
                                }
                                {(!linkedPosts && linkPost) &&
                                  <Grid container>
                                    <Typography className="text-12 medium fg-orange bg-light-orange rounded px-4 uppercase cursor-pointer"
                                      onClick={() => setLinkPostAnchorEl(task)}>
                                      link a post</Typography>
                                  </Grid>
                                }
                                {get(linkPostAnchorEl, '_id') === taskId &&
                                  <PopOverComponent anchorEl={linkPostAnchorEl}
                                    setAnchorEl={setLinkPostAnchorEl}
                                    width="auto" height="auto" left={0}>
                                    {posts.map((pItem, pItemIndex) => {
                                      return (
                                        <MenuItem key={pItemIndex}
                                          onClick={() => {
                                            linkPost(get(linkPostAnchorEl, '_id'), get(pItem, '_id'))
                                            setLinkPostAnchorEl(null)
                                          }}>
                                          {get(pItem, 'title')}
                                        </MenuItem>
                                      )
                                    })}
                                  </PopOverComponent>
                                }
                              </Grid>
                            }

                            {markComplete &&
                              <Grid item xs={12}>
                                <Button className="btn btn_sm btn_full-space"
                                  disabled={requesting}
                                  onClick={markComplete}
                                  fullWidth>mark as completed</Button>
                              </Grid>
                            }

                            {onDismiss &&
                              <Grid item xs={12}>
                                <Button className="btn btn_sm btn_light-blue btn_full-space"
                                  disabled={requesting}
                                  onClick={onDismiss}
                                  fullWidth>dismiss this task</Button>
                              </Grid>
                            }

                            <Grid item xs={12}>
                              <Grid container justify="center" alignItems="center">
                                <InfoToolTip title="Collapse Task">
                                  <ExpandLessIcon className="text-24 fg-blue cursor-pointer"
                                    onClick={() => setSelectedTask(null)} />
                                </InfoToolTip>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      }

                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          {hasMore &&
            <Grid item xs={12}>
              <LoadMore loading={loadingMore} onClick={loadMore} />
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = {
  taskCard: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #EBEBEB',
    borderRadius: '3px',
    boxShadow: '0 -1px 6px 0 rgba(0, 0, 0, 0.4)',
    padding: '8px 12px'
  },
  menuContainer: {
    maxHeight: 300,
  },
  linkStyle: {
    '&:hover': {
      textDecoration: 'none'
    }
  }
}

export default withStyles(styles)(TaskList)
