import React, { useState } from 'react'
import { FuseTextField } from '../../components'
import Grid from '@material-ui/core/Grid'

const baseTextIconSetting = {
  position: 'end',
  className: 'text-18 cursor-pointer',
  color: 'action'
}

const baseTextStyle = {
  fontFamily: 'IBMPlexSans',
  fontSize: 14,
}

const ChangePasswordForm = ({ passwordDetails, setPasswordDetails }) => {
  const [viewOldPassword, setViewOldPassword] = useState(false)
  const [viewNewPassword, setViewNewPassword] = useState(false)
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false)

  return (
    <Grid container>

      <Grid item sm={12}>
        <label className="medium text-16 mb-12">
          Old password</label>
        <FuseTextField className='mb-16 text-14 regular'
          type={viewOldPassword ? 'text' : 'password'}
          name='oldPassword'
          placeholder='Enter your old password'
          inputStyles={baseTextStyle}
          icon={{
            ...baseTextIconSetting,
            onClick: () => setViewOldPassword(!viewOldPassword),
            name: viewOldPassword ? 'visibility' : 'visibility_off',
          }}
          value={passwordDetails.oldPassword}
          onChange={e => setPasswordDetails({
            ...passwordDetails,
            oldPassword: e.target.value
          })}
          required
          fullWidth />
      </Grid>

      <Grid item sm={12} className="mt-16">
        <label className="medium text-16 mb-12">
          New password</label>
        <FuseTextField className='mb-16'
          type={viewNewPassword ? 'text' : 'password'}
          name='newPassword'
          placeholder='Enter new password'
          inputStyles={baseTextStyle}
          icon={{
            ...baseTextIconSetting,
            onClick: () => setViewNewPassword(!viewNewPassword),
            name: viewNewPassword ? 'visibility' : 'visibility_off',
          }}
          value={passwordDetails.newPassword}
          onChange={e => setPasswordDetails({
            ...passwordDetails,
            newPassword: e.target.value
          })}
          error={passwordDetails.error && passwordDetails.error.newPassword}
          helperText={passwordDetails.error && passwordDetails.error.newPassword}
          required
          fullWidth />
      </Grid>

      <Grid item sm={12}>
        <FuseTextField className='mb-16'
          type={viewConfirmPassword ? 'text' : 'password'}
          name='confirmPassword'
          placeholder='Confirm new password'
          inputStyles={baseTextStyle}
          icon={{
            ...baseTextIconSetting,
            onClick: () => setViewConfirmPassword(!viewConfirmPassword),
            name: viewConfirmPassword ? 'visibility' : 'visibility_off',
          }}
          value={passwordDetails.confirmNewPassword}
          onChange={e => setPasswordDetails({
            ...passwordDetails,
            confirmNewPassword: e.target.value
          })}
          error={passwordDetails.error && passwordDetails.error.confirmNewPassword}
          helperText={passwordDetails.error && passwordDetails.error.confirmNewPassword}
          required
          fullWidth />
      </Grid>

    </Grid>
  )
}

export default ChangePasswordForm
