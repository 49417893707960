import React, { useState, useEffect } from 'react'
import { Grid, Typography, Table, TableHead, TableRow, TableCell, TableBody, Box, Tabs, Tab } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody,
  LoadingButton,
  InfoToolTip,
} from '../../core/components'
import { AutoComplete } from 'core/components/Inputs'
import { get, set, filter, find, pick } from 'lodash'
import { fileUploadInfo, general } from '../../core/localization'
import { getFileById } from '../../core/api'
import FileType from 'file-type'
import EmailList from './EmailList'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CancelIcon from '@material-ui/icons/Cancel';
import Carriers from '../../core/helpers/json/carriers.json'

const selectInputStyle = {
  control: () => ({
    display: 'flex',
    border: 'none',
    padding: '1px 4px',
    minHeight: '29px',
    maxHeight: '29px',
    backgroundColor: '#f0f1f3',
  }),
  dropdownIndicator: (styles) => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  })
}

const selectSearchInputStyle = {
  control: () => ({
    display: 'flex',
    border: 'none',
    padding: '1px 4px',
    minHeight: '29px',
    maxHeight: '29px',
    backgroundColor: '#f0f1f3',
  })
}

const iFrameTabPanelStyle = {
  height: 'calc(100% - 48px)',
  width: '100%'
}
const transportMethods = ["ocean", "air", "rail", 'CBT']
const containerTypes = ["C20GP", "C40GP", "C40HC"]
const shipmentModes = ["direct", "drop", "local", "express"]

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component='div'
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={'overflow-scroll'}
    style={iFrameTabPanelStyle}
    {...other}>
    <Box className="w-full h-full">
      {children}
    </Box>
  </Typography>
)

const PartnerInfoBox = ({ partnerName, partnerKey, partnerInfo, handleInputChange, onClear, partnerOptions, handleSelectChange, required }) => {
  const [isCustom, setIsCustom] = useState(false)

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <Grid container spacing={1} justify="space-between">
          <Grid item>
            <Typography className={'semi-bold text-14 fg-gray ' + (required ? 'require-field' : '')}>
              {partnerName}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Typography className="regular text-12 fg-blue cursor-pointer"
                  onClick={() => {
                    setIsCustom(!isCustom)
                    handleSelectChange(partnerKey, {})
                  }}>
                  {isCustom ? 'Select' : 'Manual'}</Typography>
              </Grid>
              {onClear &&
                <Grid item>
                  <Typography className="regular text-12 fg-red cursor-pointer"
                    onClick={() => onClear(partnerKey)}>
                    Clear</Typography>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className="text-12 regular">
        {(!isCustom && partnerOptions) &&
          <AutoComplete options={partnerOptions.map(i => ({ value: i._id, label: i.name }))}
            value={get(partnerInfo, `_id`, '')}
            placeholder="Select plant..."
            onChange={(value) => handleSelectChange(partnerKey, value)}
            customStyles={selectSearchInputStyle}
            dynamic />
        }
        {(!partnerOptions || isCustom) &&
          <input name={`${partnerKey}.name`}
            placeholder="Name"
            className="gray-bg-input gray-bg-input_sm"
            value={get(partnerInfo, 'name', '')}
            onChange={handleInputChange} />
        }

      </Grid>
      <Grid item xs={12}>
        <input name={`${partnerKey}.office.address.street`}
          placeholder="Address"
          className={'gray-bg-input gray-bg-input_sm ' + (isCustom ? 'view-disabled' : '')}
          value={get(partnerInfo, 'office.address.street', '')}
          onChange={handleInputChange} />
      </Grid>
      <Grid item xs={6}>
        <input name={`${partnerKey}.office.address.city`}
          placeholder="City"
          className={'gray-bg-input gray-bg-input_sm ' + (isCustom ? 'view-disabled' : '')}
          value={get(partnerInfo, 'office.address.city', '')}
          onChange={handleInputChange} />
      </Grid>
      <Grid item xs={6}>
        <input name={`${partnerKey}.office.address.state`}
          placeholder="State"
          className={'gray-bg-input gray-bg-input_sm ' + (isCustom ? 'view-disabled' : '')}
          value={get(partnerInfo, 'office.address.state', '')}
          onChange={handleInputChange} />
      </Grid>
      <Grid item xs={6}>
        <input name={`${partnerKey}.office.address.country`}
          placeholder="Country"
          className={'gray-bg-input gray-bg-input_sm ' + (isCustom ? 'view-disabled' : '')}
          value={get(partnerInfo, 'office.address.country', '')}
          onChange={handleInputChange} />
      </Grid>
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12} sm={3} md={3}>
            <input name={`${partnerKey}.communications.phone[0].countryCode`}
              placeholder="Code"
              className={'gray-bg-input gray-bg-input_sm ' + (isCustom ? 'view-disabled' : '')}
              value={`${get(partnerInfo, 'communications.phone[0].countryCode', '')}`}
              onChange={handleInputChange} />
          </Grid>
          <Grid item xs={12} sm={9} dm={9}>
            <input name={`${partnerKey}.communications.phone[0]`}
              placeholder="Phone"
              className={'gray-bg-input gray-bg-input_sm ' + (isCustom ? 'view-disabled' : '')}
              value={`${get(partnerInfo, 'communications.phone[0]', '')}`}
              onChange={handleInputChange} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const DocPreview = ({ selectedDocuments, selectedEmails, setSelectedDocuments }) => {
  const [tabValue, setTabValue] = useState(0)
  const [fetchedDocs, setFetchedDocs] = useState([])
  const supported = ['pdf', 'jpeg', 'jpg', 'png', 'gif', 'txt']

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      style: {
        paddingLeft: 20,
        paddingRight: 20
      }
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const drawTabLabel = (doc, tabIndex, setTab) => {
    return (
      <Grid container alignItems="center">
        {`${tabIndex}. ${get(fileUploadInfo, get(doc, 'type'))}`}
        <InfoToolTip title="Remove this document">
          <CancelIcon className="fg-red text-20 cursor-pointer ml-12"
            onClick={async () => {
              await setSelectedDocuments(filter(selectedDocuments, i => (i._id !== doc._id)))
              setFetchedDocs(filter(fetchedDocs, (i, iIndex) => iIndex !== tabIndex - 1))
              if (selectedDocuments.length === tabIndex) {
                setTabValue(tabIndex - 1)
              }
            }} />
        </InfoToolTip>
      </Grid>
    )
  }

  useEffect(() => {
    selectedDocuments.forEach(async (doc, docIndex) => {
      if (!get(fetchedDocs, `[${docIndex}].url`)) {
        const { data } = await getFileById(get(doc, '_id'))
        const fileType = await FileType.fromBuffer(data)
        const blob = new Blob([data], { type: get(fileType, 'mime') })
        const srcBlob = URL.createObjectURL(blob);

        setFetchedDocs(selectedDocuments.map((item, index) => {
          if (index === docIndex) {
            set(item, 'url', srcBlob)
          }
          return item
        }))
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocuments])

  return (
    <Grid container className="h-full">
      <Grid item xs={12}>
        <Grid container spacing={1} className="h-full">
          <Grid item xs={12}>
            <Grid container className="h-full overflow-auto">
              <Grid item xs={12} className="h-full">
                <Tabs value={tabValue}
                  onChange={handleTabChange}
                  className='bg-white shadow'
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="email-logs tabs">
                  <Tab label="Emails" {...a11yProps(0)}
                    className={'custom-tabs-item ' + (tabValue === 0 ? 'selected' : '')} />
                  {fetchedDocs.map((doc, docIndex) => {
                    const tabIndex = docIndex + 1
                    return (
                      <Tab label={drawTabLabel(doc, tabIndex)}
                        className={'custom-tabs-item ' + (tabValue === tabIndex ? 'selected' : '')}
                        {...a11yProps(tabIndex)}
                        key={tabIndex} />
                    )
                  })}
                </Tabs>

                <TabPanel value={tabValue} index={0}>
                  <Grid container className="p-20">
                    <Grid item xs={12}>
                      <EmailList loading={false}
                        emails={selectedEmails} />
                    </Grid>
                  </Grid>
                </TabPanel>
                {fetchedDocs.map((doc, docIndex) => {
                  const tabIndex = docIndex + 1
                  const nameSplit = get(doc, 'name', '').split('.')
                  const format = get(nameSplit, `[${(nameSplit.length - 1)}]`, '').toLowerCase()

                  return (
                    <TabPanel value={tabValue} index={tabIndex} key={tabIndex}>
                      <iframe src={get(doc, 'url')}
                        title={get(doc, 'name', 'NoName')}
                        className="w-full h-full" />
                      {!supported.includes(format) &&
                        <Typography className="text-14 regular p-20">
                          This file type is not supported by the browser. Browser should download this file automatically</Typography>
                      }
                    </TabPanel>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const ShipmentInfoDialog = ({ open, handleClose, onSaveShipment, plants, requesting, selectedDocuments, selectedEmails, vessels, getServiceCardByItineraryId, setSelectedDocuments }) => {
  const [state, setState] = useState({})
  const [isValid, setIsValid] = useState(false)
  const [isAirShipment, setIsAirShipment] = useState(false)
  const [loadingServiceCards, setLoadingServiceCards] = useState(false)
  const shipper = filter(plants, { type: 'shipper' })
  const ifp = filter(plants, { type: 'ifp' })
  const ilp = filter(plants, { type: 'ibl' })
  const icb = filter(plants, { type: 'ibc' })
  const vesselOptions = vessels.map(vessel => {
    return {
      value: vessel.SHIPNAME,
      label: vessel.SHIPNAME
    }
  })
  const carrierOptions = Carriers.map(carrier => {
    return {
      value: carrier.name,
      label: carrier.name
    }
  })
  const shipmentTypes = isAirShipment ? ["LCL"] : ["FCL", "LCL"]

  const checkValidation = (newState, isAir = isAirShipment) => {
    setIsValid(true)
    if (!get(newState, 'ifp') ||
      !get(newState, 'ibl') ||
      !get(newState, 'ibc') ||
      !get(newState, 'type') ||
      !get(newState, 'transportMethod') ||
      !get(newState, 'billOfLadingNo') ||
      !get(newState, 'masterBillOfLadingNo') ||
      !get(newState, 'portOfLoading') ||
      !get(newState, 'portOfDischarge') ||
      !get(newState, 'shipmentMode')) {
      setIsValid(false)
    }
    if (!isAir) {
      if (!get(newState, 'vessel') ||
        !get(newState, 'imo') ||
        !get(newState, 'carrier') ||
        !get(newState, 'scac')) {
        setIsValid(false)
      }
    }

    if (get(newState, 'type') === 'FCL') {
      const containers = get(newState, 'containers', [])
      if (containers.length === 0) {
        setIsValid(false)
      }
      if (find(containers, i => !i.type)) {
        setIsValid(false)
      }
    }
  }

  const handlePartnerSelectChange = (name, value) => {
    const partner = find(plants, { _id: value })
    if (partner) {
      const newState = { ...state }
      set(newState, name, { ...partner })
      setState(newState)
    }
  }

  const handleInputChange = (event) => {
    const newState = { ...state }
    const { name, value } = event.target
    set(newState, name, value)
    setState(newState)
  }

  const handleSelectInputChange = (name, value) => {
    const newState = { ...state }
    set(newState, name, value)
    if (name === 'vessel') {
      const selectedVessel = find(vessels, { SHIPNAME: value })
      set(newState, 'imo', get(selectedVessel, 'IMO'))
    }
    setState(newState)
  }

  const handleClear = (partnerKey) => {
    const newState = { ...state }
    set(newState, partnerKey, undefined)
    setState(newState)
  }

  const handleManageContainer = (action = 'add', containerIndex = null) => {
    let containers = get(state, 'containers', [])
    if (action === 'add') {
      containers.push({})
    } else {
      containers = filter(containers, (i, index) => index !== containerIndex)
    }
    setState({ ...state, containers })
  }

  const handleFetchItineraryInfo = (value) => {
    setLoadingServiceCards(value)
    getServiceCardByItineraryId(value, (serviceCards) => {
      const partners = pick(get(serviceCards, '[0]'), ['ifp', 'ibl', 'ibc', 'shipper'])
      const incoterm = get(serviceCards, '[0].incoterm')
      const transportMethod = get(serviceCards, '[0].routeMethod')
      const type = get(serviceCards, '[0].shipmentType')
      const portOfLoading = get(serviceCards, '[0].originPort.locode')
      const portOfDischarge = get(serviceCards, '[0].destinationPort.locode')
      const ifp = find(plants, { _id: get(partners, 'ifp.plantId') })
      const ibl = find(plants, { _id: get(partners, 'ibl[0].plantId') })
      const ibc = find(plants, { _id: get(partners, 'ibc[0].plantId') })
      const shipper = find(plants, { _id: get(partners, 'shipper.plantId') })
      const newState = { ...state, ifp, ibl, ibc, shipper, incoterm, transportMethod, type, portOfLoading, portOfDischarge }

      setState(newState)
      setLoadingServiceCards(false)
    }, () => setLoadingServiceCards(false))
  }

  useEffect(() => {
    if (open === true) {
      setState({ containers: [{}] })
    }
    else {
      setState({})
      setIsValid(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.transportMethod])

  useEffect(() => {
    const isAir = get(state, 'transportMethod') === 'air'
    setIsAirShipment(isAir)
    checkValidation(state, isAir)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  return (
    <FuseDialog open={open} wLG>
      <Grid container>
        <Grid item xs={12} sm={12} md={6}
          className="bg-white border-r-1 border-gray">
          <DocPreview selectedDocuments={selectedDocuments}
            selectedEmails={selectedEmails}
            setSelectedDocuments={setSelectedDocuments} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FuseDialogHeader text="Add information to create a shipment" handleClose={handleClose} />
          <FuseDialogBody>
            <Grid container className="p-20">
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {/* Service providers */}
                  <Grid item xs={12}>
                    <Grid container spacing={1} justify="space-between" alignItems="center">
                      <Grid item xs={12}>
                        <Typography className="semi-bold text-16">Itinerary</Typography>
                      </Grid>
                      {get(state, 'itineraryId', []).map((iItem, iItemIndex) => {
                        return (
                          <Grid item xs={12} key={iItemIndex}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={5} md={4}>
                                <input name={`itineraryId[${iItemIndex}]`}
                                  placeholder="Enter Itinerary ID"
                                  className="gray-bg-input gray-bg-input_sm"
                                  value={iItem}
                                  onChange={handleInputChange} />
                              </Grid>
                              <Grid item xs={12} sm={3} md={3}>
                                <LoadingButton className="btn btn_sm w-full"
                                  disabled={!iItem || loadingServiceCards !== false}
                                  loading={loadingServiceCards === iItem}
                                  onClick={() => handleFetchItineraryInfo(iItem)}>
                                  fetch info</LoadingButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Grid item>
                        <Typography className="fg-blue text-14 semi-bold cursor-pointer"
                          onClick={() => setState({ ...state, itineraryId: [...get(state, 'itineraryId', []), ''] })}>
                          +&nbsp;Add more Itinerary</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Service providers */}
                  <Grid item xs={12}>
                    <Grid container spacing={1} justify="space-between" alignItems="center">

                      <Grid item xs={12}>
                        <Typography className="semi-bold text-16">Service providers</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={12} md={4}>
                            <label className="text-14 fg-gray semi-bold require-field">
                              Import freight partner</label>
                            <AutoComplete options={ifp.map(i => ({ value: i._id, label: i.name }))}
                              className="mt-6"
                              value={get(state, 'ifp._id', '')}
                              onChange={(value) => handlePartnerSelectChange('ifp', value)}
                              customStyles={selectSearchInputStyle}
                              noAutoSelect />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <label className="text-14 fg-gray semi-bold require-field">
                              Import logistics provider</label>
                            <AutoComplete options={ilp.map(i => ({ value: i._id, label: i.name }))}
                              className="mt-6"
                              value={get(state, 'ibl._id', '')}
                              onChange={(value) => handlePartnerSelectChange('ibl', value)}
                              customStyles={selectSearchInputStyle}
                              noAutoSelect />
                          </Grid>
                          <Grid item xs={12} sm={12} md={4}>
                            <label className="text-14 fg-gray semi-bold require-field">
                              Import custom broker</label>
                            <AutoComplete options={icb.map(i => ({ value: i._id, label: i.name }))}
                              className="mt-6"
                              value={get(state, 'ibc._id', '')}
                              onChange={(value) => handlePartnerSelectChange('ibc', value)}
                              customStyles={selectSearchInputStyle}
                              noAutoSelect />
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                  {/* Shipment information */}
                  <Grid item xs={12}>
                    <Grid container spacing={1} justify="space-between" alignItems="center">

                      <Grid item xs={12}>
                        <Typography className="semi-bold text-16">Shipment information</Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={4}>

                          <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={3}>
                              {/* Shipper */}
                              <Grid item xs={12}>
                                <PartnerInfoBox partnerName="Shipper"
                                  partnerKey="shipper"
                                  partnerInfo={get(state, 'shipper', {})}
                                  handleInputChange={handleInputChange}
                                  handleSelectChange={handlePartnerSelectChange}
                                  onClear={handleClear}
                                  partnerOptions={shipper} />
                              </Grid>

                              {/* Shipping Information */}
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={8} md={8}>
                                    <label className={"text-14 fg-gray semi-bold" + (isAirShipment ? '' : ' require-field')}>
                                      {isAirShipment ? 'Airline' : 'Carrier'}</label>
                                    <AutoComplete options={carrierOptions}
                                      value={get(state, 'carrier', '')}
                                      className="mt-12 text-12"
                                      placeholder={isAirShipment ? 'Search airline...' : 'Search carrier...'}
                                      onChange={value => handleSelectInputChange('carrier', value)}
                                      customStyles={selectSearchInputStyle}
                                      canCreate />
                                  </Grid>
                                  <Grid item xs={12} sm={4} md={4}>
                                    <label className={"text-14 fg-gray semi-bold" + (isAirShipment ? '' : ' require-field')}>
                                      {isAirShipment ? 'IATA' : 'SCAC'}</label>
                                    <AutoComplete value={get(state, 'scac', '')}
                                      options={get(find(Carriers, { name: get(state, 'carrier') }), 'SCAC', []).map(scac => {
                                        return {
                                          value: scac,
                                          label: scac
                                        }
                                      })}
                                      className="mt-12 text-12"
                                      onChange={value => handleSelectInputChange('scac', value)}
                                      customStyles={selectSearchInputStyle}
                                      canCreate
                                      dynamic />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={12}>
                                    <label className={"text-14 fg-gray semi-bold" + (isAirShipment ? '' : ' require-field')}>
                                      {isAirShipment ? 'Aircraft' : 'Vessel'}
                                    </label>
                                    <AutoComplete options={vesselOptions}
                                      value={get(state, 'vessel', '')}
                                      className="mt-12 text-12"
                                      placeholder={isAirShipment ? 'Search aircraft...' : 'Search vessel...'}
                                      onChange={value => handleSelectInputChange('vessel', value)}
                                      customStyles={selectSearchInputStyle}
                                      canCreate />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className={"text-14 fg-gray semi-bold" + (isAirShipment ? '' : ' require-field')}>
                                      {isAirShipment ? 'Tail' : 'IMO'}
                                    </label>
                                    <input name="imo"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'imo', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold">
                                      {isAirShipment ? 'Flight no' : 'Voyage no'}
                                    </label>
                                    <input name="voyageNo"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'voyageNo', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold require-field">
                                      Port of Loading</label>
                                    <input name="portOfLoading"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'portOfLoading', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold require-field">
                                      Port of Discharge</label>
                                    <input name="portOfDischarge"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'portOfDischarge', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={12} md={6}>
                            <Grid container spacing={3}>
                              {/* Shipment type and Transport Method */}
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold require-field">
                                      Transport Method</label>
                                    <AutoComplete options={transportMethods.map(i => ({ value: i, label: i.toUpperCase() }))}
                                      className="mt-12 text-12"
                                      value={get(state, 'transportMethod', '')}
                                      onChange={(value) => handleSelectInputChange('transportMethod', value)}
                                      customStyles={selectInputStyle} />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold require-field">
                                      Type of Shipment</label>
                                    <AutoComplete options={shipmentTypes.map(i => ({ value: i, label: i }))}
                                      className="mt-12 text-12"
                                      value={get(state, 'type', '')}
                                      onChange={(value) => handleSelectInputChange('type', value)}
                                      customStyles={selectInputStyle}
                                      dynamic />
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Shipment Mode and Incoterms */}
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold require-field">
                                      Shipment Mode</label>
                                    <AutoComplete options={shipmentModes.map(i => ({ value: i, label: i.toUpperCase() }))}
                                      className="mt-12 text-12"
                                      value={get(state, 'shipmentMode', '')}
                                      onChange={(value) => handleSelectInputChange('shipmentMode', value)}
                                      customStyles={selectInputStyle} />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold">
                                      Incoterm</label>
                                    <input name="incoterm"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'incoterm', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Bill of lading number and references */}
                              <Grid item xs={12}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold require-field">
                                      {isAirShipment ? 'House Airway Bill' : 'House Bill of lading'}</label>
                                    <input name="billOfLadingNo"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'billOfLadingNo', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold require-field">
                                      {isAirShipment ? 'Master Airway Bill' : 'Master Bill of lading'}</label>
                                    <input name="masterBillOfLadingNo"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'masterBillOfLadingNo', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold">
                                      Shipper’s reference</label>
                                    <input name="bookingReferenceNo"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'bookingReferenceNo', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6}>
                                    <label className="text-14 fg-gray semi-bold">
                                      Carrier’s reference</label>
                                    <input name="carrierBookingNo"
                                      className="gray-bg-input gray-bg-input_sm mt-12"
                                      value={get(state, 'carrierBookingNo', '')}
                                      onChange={handleInputChange} />
                                  </Grid>
                                </Grid>
                              </Grid>

                              {/* Additional information */}
                              <Grid item xs={12}>
                                <label className="text-14 fg-gray semi-bold">
                                  Additional information</label>
                                <textarea rows={8}
                                  name="note"
                                  placeholder="Enter notes or any bank details"
                                  className="gray-bg-input gray-bg-input_sm mt-16 resize-none"
                                  value={get(state, 'note', '')}
                                  onChange={handleInputChange} />
                              </Grid>
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>


                  {/* Containers */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {/* Container table */}
                      <Grid item xs={12} className="overflow-scroll">
                        <Table className="custom-table">
                          <TableHead className="table-head">
                            <TableRow className="table-row">
                              <TableCell></TableCell>
                              <TableCell className="text-center fg-gray">
                                Container&nbsp;Nos;&nbsp;Seal&nbsp;Nos;&nbsp;Marks&nbsp;and&nbsp;Nos</TableCell>
                              <TableCell className="text-center fg-gray">
                                No.&nbsp;of&nbsp;Container&nbsp;/&nbsp;Packages</TableCell>
                              <TableCell className="text-center fg-gray">
                                Gross&nbsp;Weight</TableCell>
                              <TableCell className="text-center fg-gray">
                                Gross&nbsp;Measurement</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="table-body">
                            {get(state, 'containers', []).map((cItem, cItemIndex) => {
                              const paramPrefix = `containers.[${cItemIndex}]`
                              const containerCount = get(state, 'containers', []).length

                              return (
                                <TableRow key={cItemIndex} className="table-row align-top">
                                  <TableCell className="max-w-64 align-bottom">
                                    <Grid container justify="center" className={containerCount > 1 ? 'cursor-pointer' : 'cursor-not-allowed'}
                                      onClick={() => containerCount > 1 && handleManageContainer('remove', cItemIndex)}>
                                      <DeleteForeverIcon className={'text-18 ' + (containerCount > 1 ? 'fg-red' : 'fg-gray')} />
                                      <Typography className={'uppercase text-12 semi-bold ' + (containerCount > 1 ? 'fg-red' : 'fg-gray')}>
                                        delete</Typography>
                                    </Grid>
                                  </TableCell>
                                  <TableCell className="align-bottom">
                                    <Grid container>
                                      <Grid item xs={12}>
                                        <Grid container>
                                          <Grid item xs={3}>
                                            <input className="gray-bg-input gray-bg-input_sm"
                                              value="CONT-NO."
                                              disabled />
                                          </Grid>
                                          <Grid item xs={9}>
                                            <input name={`${paramPrefix}.equipmentNo`}
                                              className="gray-bg-input gray-bg-input_sm"
                                              value={get(cItem, 'equipmentNo', '')}
                                              onChange={handleInputChange} />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Grid container>
                                          <Grid item xs={2}>
                                            <input className="gray-bg-input gray-bg-input_sm"
                                              value="SEAL:"
                                              disabled />
                                          </Grid>
                                          <Grid item xs={10}>
                                            <input name={`${paramPrefix}.carrierSealNumber`}
                                              className="gray-bg-input gray-bg-input_sm"
                                              value={get(cItem, 'carrierSealNumber', '')}
                                              onChange={handleInputChange} />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell className="align-bottom">
                                    <label className={'text-14 fg-gray semi-bold ' + (get(state, 'type') === 'FCL' ? 'require-field' : '')}>
                                      Type</label>
                                    <AutoComplete value={get(cItem, 'type', '')}
                                      className="regular"
                                      options={containerTypes.map(i => ({
                                        value: i,
                                        label: get(general, `container.${i}`)
                                      }))}
                                      placeholder="Select type"
                                      onChange={(value) => handleSelectInputChange(`${paramPrefix}.type`, value)}
                                      customStyles={selectInputStyle} />
                                  </TableCell>
                                  <TableCell className="align-bottom">
                                    <Grid container>
                                      <Grid item xs={8}>
                                        <input type="number"
                                          name={`${paramPrefix}.weight.value`}
                                          className="gray-bg-input gray-bg-input_sm"
                                          value={get(cItem, 'weight.value', '')}
                                          onChange={handleInputChange} />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <input name={`${paramPrefix}.weight.unit`}
                                          className="gray-bg-input gray-bg-input_sm"
                                          value={get(cItem, 'weight.unit', 'KG')}
                                          onChange={handleInputChange} />
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                  <TableCell className="align-bottom">
                                    <Grid container>
                                      <Grid item xs={9}>
                                        <input type="number"
                                          name={`${paramPrefix}.volume.value`}
                                          className="gray-bg-input gray-bg-input_sm"
                                          value={get(cItem, 'volume.value', '')}
                                          onChange={handleInputChange} />
                                      </Grid>
                                      <Grid item xs={3}>
                                        <input name={`${paramPrefix}.volume.unit`}
                                          className="gray-bg-input gray-bg-input_sm"
                                          value={get(cItem, 'volume.unit', 'M3')}
                                          onChange={handleInputChange} />
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </Grid>
                      {get(state, 'type') === 'FCL' &&
                        /* Add more containers */
                        <Grid item xs={12}>
                          <Grid container justify="center">
                            <Grid item>
                              <Typography className="fg-blue semi-bold text-14 cursor-pointer"
                                onClick={() => handleManageContainer('add')}>+&nbsp;Add more</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </Grid>

                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container className="pt-40">
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <LoadingButton className="btn w-full"
                          disabled={!isValid}
                          loading={requesting}
                          onClick={() => onSaveShipment(state)}>save shipment</LoadingButton>
                      </Grid>
                      <Grid item xs={12} sm={6} md={8}>
                        <Typography className="text-14 regular text-right fg-gray">
                          All the information will saved under “Shipment info” tab, you can view and update information from there</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid >
          </FuseDialogBody >
        </Grid >
      </Grid >
    </FuseDialog >
  )
}

export default ShipmentInfoDialog
