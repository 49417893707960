import React, { useState, useEffect } from 'react'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions,
  FuseTextField,
  LoadingButton,
  FuseCheckBoxGroup
} from '../../core/components'
import {
  Grid,
  withStyles,
  Typography,
} from '@material-ui/core'
import classNames from 'classnames'
import { get, set, pick } from "lodash";
import AutoComplete from '../../core/components/Inputs/AutoComplete'
import { addGaEvent } from '../../core/helpers'

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const selectStyle = {
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  }),
}

const CSAddSidebar = ({ open, classes, shipment, handleClose, onSubmit }) => {
  const [state, setState] = useState([])
  const [requesting, setRequesting] = useState(false)
  const [invalid, setInvalid] = useState(true)
  const specialLabelOptions = ['formd', 'formak', 'forma', 'forme'].map(value => {
    return {
      value,
      label: value.toUpperCase(),
      checked: false
    }
  })
  const { outbounds = [] } = shipment

  useEffect(() => {
    if (open) {
      addGaEvent('shipment-addingConsolidation', get(shipment, '_id'))
      handleAddCsCode()
    } else {
      setState([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const checkValidation = (updatedState) => {
    setInvalid(false)
    const requiredFields = ['meta.CSCode', 'weight', 'volume']
    updatedState.forEach(i => {
      requiredFields.forEach(field => {
        if (!get(i, field)) {
          setInvalid(true)
        }
      })
    })
    if (outbounds.length === 0) {
      if (!get(updatedState, '[0].shipToParty.code') ||
        !get(updatedState, '[0].shipToParty.name')) {
        setInvalid(true)
      }
    }
  }

  const handleChange = (event, index) => {
    const { name, value, type } = event.target
    let updatedState = [...state]
    updatedState = updatedState.map((item, itemIndex) => {
      if (itemIndex === index) {
        if (type === 'number') {
          if (value >= 0) {
            set(item, name, value)
          }
        } else {
          set(item, name, value)
        }
      }
      return item
    })

    checkValidation(updatedState)
    setState(updatedState)
  }

  const handleSubmit = () => {
    setRequesting(true)
    const payload = []
    state.forEach((item, iIndex) => {
      const payloadItem = {
        ...item,
        ...pick(shipment, ['groupageDate', 'shipmentMode']),
        ...pick(get(shipment, 'outbounds[0]', {}), [
          'shipToParty',
          'weight',
          'volume',
          'consolidatedWght',
          'consolidatedVolume',
        ]),
        specialLabels: get(item, 'specialLabels', []),
      }
      const wight = {
        value: get(item, 'weight.value', ''),
        unit: get(payloadItem, 'weight.unit', 'KG')
      }
      const volume = {
        value: get(item, 'volume.value', ''),
        unit: get(payloadItem, 'volume.unit', 'M3')
      }
      payloadItem.weight = wight
      payloadItem.volume = volume
      payloadItem.consolidatedWght = wight
      payloadItem.consolidatedVolume = volume

      if (outbounds.length === 0) {
        if (iIndex === 0) {
          payloadItem.shipToParty = item.shipToParty
        } else {
          payloadItem.shipToParty = get(state, '[0].shipToParty')
        }
      }

      payload.push(payloadItem)
    })

    onSubmit(shipment._id, payload,
      () => {
        setRequesting(false)
        setState([])
        setInvalid(true)
        handleClose()
      },
      () => setRequesting(false)
    )
  }

  const handleAddCsCode = () => {
    const updatedState = [...state]
    updatedState.push({})

    checkValidation(updatedState)
    setState(updatedState)
  }

  return (
    <FuseSideBar open={open}>
      <FuseSideBarHeader text={`Add New Consolidation`}
        handleClose={() => {
          addGaEvent('shipment-addingConsolidationCancelled', get(shipment, '_id'))
          setState({})
          setInvalid(true)
          handleClose()
        }} />
      <FuseSideBarBody style={{
        padding: 0,
        overflowX: 'hidden'
      }}>
        <Grid container className="p-20">
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {open && state.map((stateItem, itemIndex) => {
                return (
                  <Grid item xs={12} key={itemIndex}>
                    <Grid container className="rounded border-1 border-gray p-12">
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          {/* CS Code */}
                          <Grid sm={6} item>
                            <label className="text-12 fg-gray require-field">
                              CS Code</label>
                            <FuseTextField
                              className={classNames('mb-8', classes.csField)}
                              type='text'
                              name='meta.CSCode'
                              placeholder="Enter CS Code"
                              icon={{ ...baseTextSetting }}
                              value={get(stateItem, 'meta.CSCode', '')}
                              onChange={(e) => handleChange(e, itemIndex)}
                            />
                          </Grid>
                          {/* End of CS Code */}

                          {/* Outbound Number */}
                          <Grid sm={6} item>
                            <label className="text-12 fg-gray">
                              Outbound Number</label>
                            <FuseTextField
                              className={classNames('mb-8', classes.csField)}
                              type='text'
                              name='meta.outboundId'
                              placeholder="Enter outbound no."
                              icon={{ ...baseTextSetting }}
                              value={get(stateItem, 'meta.outboundId', '')}
                              onChange={(e) => handleChange(e, itemIndex)}
                            />
                          </Grid>
                          {/* End of Outbound Number */}

                          {/* Packaged Weight */}
                          <Grid sm={6} item>
                            <span className="text-12 fg-gray require-field">
                              <label>Packaged Weight&nbsp;</label>
                              <label>({get(shipment, 'outbounds[0].weight.unit', 'KG')})</label>
                            </span>
                            <FuseTextField
                              className={classNames('mb-8', classes.csField)}
                              type='number'
                              name='weight.value'
                              placeholder="Enter weight"
                              icon={{ ...baseTextSetting }}
                              value={get(stateItem, 'weight.value', '')}
                              onChange={(e) => handleChange(e, itemIndex)}
                            />
                          </Grid>
                          {/* End of Packaged Weight */}

                          {/* Packaged Volume */}
                          <Grid sm={6} item>
                            <span className="text-12 fg-gray require-field">
                              <label>Packaged Volume&nbsp;</label>
                              <label>({get(shipment, 'outbounds[0].volume.unit', 'M3')})</label>
                            </span>
                            <FuseTextField
                              className={classNames('mb-8', classes.csField)}
                              type='number'
                              name='volume.value'
                              placeholder="Enter volume"
                              icon={{ ...baseTextSetting }}
                              value={get(stateItem, 'volume.value', '')}
                              onChange={(e) => handleChange(e, itemIndex)}
                            />
                          </Grid>
                          {/* End of Packaged Volume */}

                          {/* Special Labels */}
                          <Grid sm={6} item>
                            <label className="text-12 fg-gray">
                              Special Labels</label>
                            <FuseCheckBoxGroup name="specialLabels"
                              data={specialLabelOptions}
                              value={get(stateItem, 'specialLabels', [])}
                              className='flex pl-6 pt-6'
                              chkClassName='text-14 text-grey-darkest'
                              onChange={(value, currentEvent) => handleChange({
                                target: {
                                  ...currentEvent,
                                  value
                                }
                              }, itemIndex)} />
                          </Grid>
                          {/* End of Special Labels */}

                          {/* Goods Type */}
                          <Grid sm={6} item>
                            <Grid container>
                              <Grid item className="text-12 fg-gray"
                                style={{
                                  margin: '1px 0px'
                                }}>Goods Type</Grid>
                              <Grid item xs={12}>
                                <AutoComplete value={get(stateItem, 'goodsType', '')}
                                  options={
                                    ['DG', 'normal'].map(value => {
                                      return { value, label: value.toUpperCase() }
                                    })
                                  }
                                  placeholder="Select..."
                                  customStyles={selectStyle}
                                  onChange={(value) => {
                                    handleChange({ target: { name: 'goodsType', value } }, itemIndex)
                                  }} />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* End of Goods Type */}

                          {(outbounds.length === 0 && itemIndex === 0) &&
                            // Ship to Party Code
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid item className="text-12 fg-gray require-field"
                                  style={{
                                    margin: '1px 0px'
                                  }}>Ship to Party Code</Grid>
                                <Grid item xs={12}>
                                  <FuseTextField
                                    className={classNames('mb-8', classes.csField)}
                                    name='shipToParty.code'
                                    placeholder="Enter code"
                                    icon={{ ...baseTextSetting }}
                                    value={get(stateItem, 'shipToParty.code', '')}
                                    onChange={(e) => handleChange(e, itemIndex)}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            // End of Ship to Party Code
                          }

                          {(outbounds.length === 0 && itemIndex === 0) &&
                            // Ship to Party Name
                            <Grid item xs={6}>
                              <Grid container>
                                <Grid item className="text-12 fg-gray require-field"
                                  style={{
                                    margin: '1px 0px'
                                  }}>Ship to Party Name</Grid>
                                <Grid item xs={12}>
                                  <FuseTextField
                                    className={classNames('mb-8', classes.csField)}
                                    name='shipToParty.name'
                                    placeholder="Enter name"
                                    icon={{ ...baseTextSetting }}
                                    value={get(stateItem, 'shipToParty.name', '')}
                                    onChange={(e) => handleChange(e, itemIndex)}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            // End of Ship to Party Name
                          }

                          {/* Note */}
                          <Grid sm={12} item>
                            <label className="text-12 fg-gray">
                              Note</label>
                            <FuseTextField
                              className={classNames('mb-8', classes.csField)}
                              type='text'
                              name='note'
                              placeholder="Enter Notes"
                              icon={{ ...baseTextSetting }}
                              value={get(stateItem, 'note', '')}
                              onChange={(e) => handleChange(e, itemIndex)}
                            />
                          </Grid>
                          {/* End of Note */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}

              <Grid item xs={12}>
                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <Typography className="fg-blue semi-bold cursor-pointer"
                      onClick={handleAddCsCode}>
                      +&nbsp;Add more</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseSideBarBody>
      <FuseSideBarActions>
        <LoadingButton className='btn btn_full-space'
          loading={requesting}
          disabled={invalid}
          onClick={handleSubmit}>
          Save</LoadingButton>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

const styles = {
  banner: {
    backgroundColor: '#ECECED',
    height: 40,
    textAlign: 'left',
    paddingLeft: 20
  },
  bannerTitle: {
    color: '#9FA0A4',
    fontSize: 13,
    paddingTop: 10
  },
  csBlock: {
    padding: '10px 20px',
    '&:nth-child(2)': {
      paddingTop: '20px'
    },
    '&:last-child': {
      paddingBottom: '20px'
    }
  },
  csBlockTitle: {
    marginBottom: 5
  },
  csTitle: {
    fontFamily: 'IBMPlexSans-Medium',
    fontSize: 16,
    fontWeight: 600
  },
  csField: {
    width: '100%',
    '& input': {
      fontFamily: 'IBMPlexSans',
      fontSize: 14,
    }
  },
  field: {
    width: '100%',
    marginTop: 4
  },
  label: {
    color: '#AEAFB2',
    marginBottom: 15,
    fontWeight: 300,
    fontSize: 15
  },
  select: {
    backgroundColor: '#FFF',
    fontFamily: 'IBMPlexSans',
    '& > div ': {
      backgroundColor: '#FFF'
    }
  },
  selectErrorMessage: {
    color: '#F44335'
  }
}

export default withStyles(styles)(CSAddSidebar)
