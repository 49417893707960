import ActionTypes from './types'
import { uniqBy } from 'lodash'

const initialState = {
  preAlertEmails: {
    loading: true,
    loadingMore: false,
    data: [],
    total: 0,
    hasMore: false
  },
  processedPreAlertEmails: {
    loading: true,
    loadingMore: false,
    data: [],
    total: 0,
    hasMore: false
  },
  shipments: {
    loading: true,
    loadingMore: false,
    data: [],
    total: 0,
    hasMore: false
  }
}

const decoupledInboundReducers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PRE_ALERT_EMAILS_SUCCESS: {
      return {
        ...state,
        preAlertEmails: {
          ...state.preAlertEmails,
          loading: false,
          data: action.payload.data,
          total: action.payload.total,
          hasMore: action.payload.showMore
        }
      }
    }
    case ActionTypes.LOADING_MORE_PRE_ALERT_EMAILS: {
      return {
        ...state,
        preAlertEmails: {
          ...state.preAlertEmails,
          loadingMore: true
        }
      }
    }
    case ActionTypes.LOAD_MORE_PRE_ALERT_EMAILS_SUCCESS: {
      return {
        ...state,
        preAlertEmails: {
          ...state.preAlertEmails,
          loadingMore: false,
          data: uniqBy([...state.preAlertEmails.data, ...action.payload.data], '_id'),
          total: action.payload.total,
          hasMore: action.payload.showMore
        }
      }
    }
    case ActionTypes.SET_PROCESSED_PRE_ALERT_EMAILS_SUCCESS: {
      return {
        ...state,
        processedPreAlertEmails: {
          ...state.processedPreAlertEmails,
          loading: false,
          data: action.payload.data,
          total: action.payload.total,
          hasMore: action.payload.showMore
        }
      }
    }
    case ActionTypes.LOADING_MORE_PROCESSED_PRE_ALERT_EMAILS: {
      return {
        ...state,
        processedPreAlertEmails: {
          ...state.processedPreAlertEmails,
          loadingMore: true
        }
      }
    }
    case ActionTypes.LOAD_MORE_PROCESSED_PRE_ALERT_EMAILS_SUCCESS: {
      return {
        ...state,
        processedPreAlertEmails: {
          ...state.processedPreAlertEmails,
          loadingMore: false,
          data: uniqBy([...state.processedPreAlertEmails.data, ...action.payload.data], '_id'),
          total: action.payload.total,
          hasMore: action.payload.showMore
        }
      }
    }
    case ActionTypes.LOADING_SHIPMENTS: {
      return {
        ...state,
        shipments: {
          ...state.shipments,
          loading: true
        }
      }
    }
    case ActionTypes.SET_SHIPMENTS_SUCCESS: {
      return {
        ...state,
        shipments: {
          ...state.shipments,
          loading: false,
          data: action.payload.data,
          total: action.payload.total,
          hasMore: action.payload.showMore
        }
      }
    }
    case ActionTypes.LOADING_MORE_SHIPMENTS: {
      return {
        ...state,
        shipments: {
          ...state.shipments,
          loadingMore: true
        }
      }
    }
    case ActionTypes.LOAD_MORE_SHIPMENTS_SUCCESS: {
      return {
        ...state,
        shipments: {
          ...state.shipments,
          loadingMore: false,
          data: [...state.shipments.data, ...action.payload.data],
          total: action.payload.total,
          hasMore: action.payload.showMore
        }
      }
    }

    default:
      return state
  }
}

export default decoupledInboundReducers
