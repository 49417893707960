import React, { useEffect, useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import { FuseDialog, FuseDialogBody, FuseDialogHeader, FuseTabs, LoadingButton } from '../../core/components'
import { useMergeState, hasNoEmptyParams } from '../../core/helpers'

import ReviewShippingForm from './review-booking/ReviewShippingForm'
import ReviewBookingForm from './review-booking/ReviewBookingForm'
import ReviewContainerScheduleForm from './review-booking/ReviewContainerScheduleForm'
import ReviewBookingSummary from './ReviewBookingSummary'
import _ from 'lodash'

let tabItems = [{ label: 'Shipping' }, { label: 'Container Schedule' }, { label: 'Booking' }]
const INITIAL_STATE = {
  tabValue: 0,
  bookingData: {},
  containerScheduleData: {},
  shippingData: {},
  validations: {
    bookingData: false,
    shippingData: false,
    containerScheduleData: false
  }
}

const ReviewBookingDialog = ({
  open,
  currentPlant,
  shipment,
  handleClose,
  onSubmit,
  vessels,
  editShipping,
  documents,
  requesting,
  getVessels }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const [vesselListStatus, setVesselListStatus] = useState('loading')

  useEffect(() => {
    if (!open) {
      setState({
        tabValue: 0,
        bookingData: {},
        shippingData: {},
        containerScheduleData: {}
      })
    } else {
      loadVessels()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const loadVessels = () => {
    setVesselListStatus('loaded')
  }

  let transportRoute = []

  if (_.get(shipment, 'type') === 'LCL') {
    tabItems = [{ label: 'Shipping' }, { label: 'Booking' }]
  } else {
    tabItems = [{ label: 'Shipping' }, { label: 'Container Schedule' }, { label: 'Booking' }]
  }

  if (currentPlant.type === 'ofp') {
    transportRoute = [
      {
        place: _.get(shipment, 'plannedRoute.originPort.name'),
        address: _.get(shipment, 'plannedRoute.originPort.street') || _.get(shipment, 'plannedRoute.originPort.country'),
        iconRounded: true,
        connector: {
          icon: 'directions_boat',
          iconRounded: true
        }
      },
      {
        place: _.get(shipment, 'plannedRoute.destinationPort.name'),
        address:
          _.get(shipment, 'plannedRoute.destinationPort.street') || _.get(shipment, 'plannedRoute.destinationPort.country')
      }
    ]
  } else if (currentPlant.type === 'obl') {
    transportRoute = [
      {
        place: _.get(shipment, 'plannedRoute.origin.name'),
        address: _.get(shipment, 'plannedRoute.origin.street') || _.get(shipment, 'plannedRoute.origin.country'),
        connector: {
          icon: 'local_shipping',
          iconRounded: true
        }
      },
      {
        place: _.get(shipment, 'plannedRoute.originPort.name'),
        address: _.get(shipment, 'plannedRoute.originPort.street') || _.get(shipment, 'plannedRoute.originPort.country')
      }
    ]
  }

  const updateValidations = (type, status) => {
    setState({
      validations: {
        ...state.validations,
        [type]: status
      }
    })
  }

  const handleShippingFormChanges = async data => {
    const shippingData = {
      ...state.shippingData,
      ...data
    }
    setState({ shippingData })
    // START Validation Checks
    updateValidations('shippingData', true)
    if (hasNoEmptyParams(shippingData, _.get(shippingData, 'hasUnderlyingCarrier') ? [] : ['underlyingScacCode', 'underlyingCarrierName']) === false) {
      updateValidations('shippingData', false)
    }
    if (hasNoEmptyParams(_.get(shippingData, 'vesselSchedule.FirstPOL', {}), ['port', 'vessel']) === false) {
      updateValidations('shippingData', false)
    }
    if (hasNoEmptyParams(_.get(shippingData, 'vesselSchedule.FirstPOL.port')) === false) {
      updateValidations('shippingData', false)
    }
    if (hasNoEmptyParams(_.get(shippingData, 'vesselSchedule.FirstPOL.vessel')) === false) {
      updateValidations('shippingData', false)
    }

    _.get(shippingData, 'vesselSchedule.transhipmentPorts', []).forEach(transhipmentPort => {
      if (hasNoEmptyParams(transhipmentPort, ['port', 'vessel']) === false) {
        updateValidations('shippingData', false)
      }
      if (hasNoEmptyParams(_.get(transhipmentPort, 'port')) === false) {
        updateValidations('shippingData', false)
      }
      if (hasNoEmptyParams(_.get(transhipmentPort, 'vessel')) === false) {
        updateValidations('shippingData', false)
      }
    })
    // END Validation Checks
  }

  const handleContainerScheduleFormChanges = async data => {
    const containerScheduleData = {
      ...state.containerScheduleData,
      ...data
    }
    setState({ containerScheduleData })

    // START Validation Checks
    updateValidations('containerScheduleData', true)
    _.get(data, 'containers', []).forEach(item => {
      if (_.get(item, 'status.current.value') !== 'cancelled') {
        if (!_.get(item, 'referenceEvents.emptyContainerPickUpFromYard.location.street')) {
          updateValidations('containerScheduleData', false)
        }
        if (!_.get(item, 'referenceEvents.emptyContainerPickUpFromYard.expectedAt')) {
          updateValidations('containerScheduleData', false)
        }
      }
    })
  }

  const handleBookingFormChanges = async data => {
    const bookingData = {
      ...state.bookingData,
      ...data
    }
    const uploads = _.get(bookingData, 'documentUploads', [])
    const bookingDetailsIgnore = ['mblNumber', 'hblNumber']
    if (_.get(shipment, 'transportMethod') === 'air') {
      bookingDetailsIgnore.push('carrierBookingNumber')
      bookingDetailsIgnore.push('bookingRefNumber')
    }

    setState({ bookingData })

    // START Validation Checks
    updateValidations('bookingData', true)
    if (hasNoEmptyParams(bookingData, ['note']) === false) {
      updateValidations('bookingData', false)
    }

    if (!_.find(uploads, { type: 'BookingConfirmation' }) && !editShipping) {
      updateValidations('bookingData', false)
    }

    if (hasNoEmptyParams(_.get(bookingData, 'bookingDetails', {}), bookingDetailsIgnore) === false) {
      updateValidations('bookingData', false)
    }

    if (_.get(shipment, 'type') === 'LCL' && _.get(shipment, 'transportMethod') !== 'air') {
      if (!_.find(uploads, { type: 'ShippingNote' }) && !editShipping) {
        updateValidations('bookingData', false)
      }
    }

    // END Validation Checks
  }

  const onUpload = (data, type) => {
    const payload = new FormData()
    const fileName = Object.keys(data)[0]
    var documentUploads = _.get(state, 'bookingData.documentUploads', [])
    const existing = _.findIndex(documentUploads, { type })
    payload.append('document', data[fileName])

    if (existing !== -1) {
      if (!fileName) {
        delete documentUploads[existing]
      } else {
        documentUploads[existing] = {
          type,
          payload
        }
      }
    } else {
      documentUploads.push({
        type,
        payload
      })
    }
    const bookingData = {
      ...state.bookingData,
      [`doc${type}`]: data,
      documentUploads
    }
    setState({ bookingData })

    handleBookingFormChanges(bookingData)
  }

  const changeTab = tabValue => {
    if (tabValue === 1 && _.get(state, 'validations.shippingData', false) === false) {
      setState({ tabValue: state.tabValue })
    } else if (tabValue === 2 && _.get(state, 'validations.containerScheduleData', false) === false) {
      setState({ tabValue: state.tabValue })
    } else {
      setState({ tabValue })
    }
  }

  const submit = () => {
    onSubmit(state.shippingData, state.containerScheduleData, state.bookingData, shipment)
  }

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text={editShipping ? 'Update shipment' : 'Accept booking'} handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container direction='row'>
          <FuseTabs showIndicator value={state.tabValue} data={tabItems} onChange={tabValue => changeTab(tabValue)} />
        </Grid>

        <Grid container className='booking-wizard'>
          <Grid item xs={8} className='booking-forms'>
            {state.tabValue === 0 && (
              <ReviewShippingForm
                onChange={handleShippingFormChanges}
                loadVessels={loadVessels}
                vesselListStatus={vesselListStatus}
                vessels={vessels}
                shipment={shipment}
                existingData={state.shippingData}
                isValid={_.get(state, 'validations.shippingData', false)}
              />
            )}
            {_.get(shipment, 'type') === 'FCL' && state.tabValue === 1 && (
              <ReviewContainerScheduleForm
                onChange={handleContainerScheduleFormChanges}
                shipment={shipment}
                existingData={state.containerScheduleData}
                isValid={_.get(state, 'validations.containerScheduleData', false)}
              />
            )}
            {((_.get(shipment, 'type') === 'FCL' && state.tabValue === 2) ||
              (_.get(shipment, 'type') === 'LCL' && state.tabValue === 1)) && (
                <ReviewBookingForm
                  onChange={handleBookingFormChanges}
                  onUpload={onUpload}
                  shipment={shipment}
                  existingData={state.bookingData}
                  editShipping={editShipping}
                  documents={documents}
                  isValid={_.get(state, 'validations.bookingData', false)}
                />
              )}

            {state.tabValue === 0 && (
              <Grid container className='pt-20'>
                <Grid item xs={6} className='pr-12'>
                  <Button
                    className='btn w-full'
                    disabled={_.get(state, 'validations.shippingData', false) === false}
                    onClick={() => changeTab(1)}
                  >
                    Next
                  </Button>
                </Grid>
              </Grid>
            )}
            {_.get(shipment, 'type') === 'FCL' && state.tabValue === 1 && (
              <Grid container className='pt-20'>
                <Grid item xs={6} className='pr-12'>
                  <Button
                    className='btn w-full'
                    disabled={_.get(state, 'validations.containerScheduleData', false) === false}
                    onClick={() => changeTab(2)}
                  >
                    Next
                  </Button>
                </Grid>
                <Grid item xs={6} className='pl-12'>
                  <Grid container justify='flex-end'>
                    <Button className='btn btn_transparent' onClick={() => changeTab(0)}>
                      back
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {((_.get(shipment, 'type') === 'FCL' && state.tabValue === 2) ||
              (_.get(shipment, 'type') === 'LCL' && state.tabValue === 1)) && (
                <Grid container justify='space-between' className='pt-20'>
                  <Grid item xs={6} className='pr-12'>
                    <LoadingButton className='btn w-full'
                      loading={requesting}
                      disabled={_.get(state, 'validations.bookingData', false) === false}
                      onClick={submit}
                    >
                      {editShipping ? 'Edit Booking' : 'Confirm Booking'}
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={6} className='pl-12'>
                    <Grid container justify='flex-end'>
                      <Button className='btn btn_transparent'
                        disabled={requesting}
                        onClick={() => changeTab(Number(_.get(state, 'tabValue', 0)) - 1)}>
                        back</Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>

          <Grid item xs={4}>
            <ReviewBookingSummary transportRoute={transportRoute} shipment={shipment} />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default ReviewBookingDialog
