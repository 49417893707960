import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'
import { get } from 'lodash'

const SubToolBarSkeleton = (props) => (
  <Grid container
    alignItems='center'
    style={{
      height: '48px',
      backgroundColor: '#FFFFFF',
      position: 'fixed',
      top: '54px',
      padding: '0px 20px',
      width: 'calc(100% - 180px)'
    }}>
    <Grid item xs={6}>
      <Skeleton width={150} />
    </Grid>
    <Grid item xs={6}>
      <Grid container
        justify="flex-end"
        alignItems="center"
        spacing={2}>
        {
          get(props, 'actions', []).map((action) => (
            <Grid item
              key={action}>
              <Skeleton height={30}
                width={100} />
            </Grid>
          ))
        }
      </Grid>
    </Grid>
  </Grid>
)

export default SubToolBarSkeleton
