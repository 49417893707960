import React from 'react'
import { connect } from 'react-redux'
import { Grid, Typography, withStyles } from '@material-ui/core'
import Steps from 'rc-steps'
import 'rc-steps/assets/index.css'
import 'rc-steps/assets/iconfont.css'
import _, { get } from 'lodash'
import classNames from 'classnames'
import { formatTimestamp, getAddressFromLocation } from 'core/helpers'
import EditIcon from '@material-ui/icons/Edit'
import { getLocationDetails } from './nodeDetails.js'

const LCLCargoTimelineRow = ({ shipment, index, classes, onContainerClick, currentPlant, noBackground }) => {
  let trackingAvailable = _.get(shipment, 'summary.tracking.available')
  return (
    <Grid container alignItems='stretch'
      className={classNames(
        classes.row,
        noBackground && classes.noBgRow
      )}>
      <Grid item xs={11}>
        <Grid item xs={12} className={classes.rowHead}>
          <Grid container>
            <Grid item xs={2} className={classes.rowHeadTitle}>
              <img src='/assets/images/icons/shipment-card/fcl-mini.svg' alt='FCL' className={classes.icon} />
              {`LCL shipment`}
            </Grid>

            <Grid item xs={10}>
              {onContainerClick && (
                <Grid container justify='flex-end' alignItems='center'>
                  <Grid item>
                    {/* Invisible for now as w don't know how to show latest event */}
                    <Grid container alignItems='center'>
                      <Grid item>
                        <Typography className='shipment-list-item-current-event'>
                          Confirm seal no: {`lcl equipmentNo`}
                        </Typography>
                      </Grid>
                      <Grid item className='mx-4'>
                        &#183;
                      </Grid>
                      <Grid item title={_.get(shipment, 'summary.latestActualEvent.type')} className='mr-8'>
                        <Typography className='shipment-list-item-current-event'>
                          Seal no: {`lcl carrierSealNumber`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    {(currentPlant.type === 'ofp' || currentPlant.type === 'shipper') && (
                      <Grid container justify='flex-end' alignItems='center'>
                        <Grid item className='mr-8'>
                          <Grid container alignItems='center'>
                            <EditIcon className={classes.editContainerSeal} onClick={onContainerClick} />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
              {!onContainerClick && (
                <Grid container justify='flex-end' alignItems='center'>
                  <Grid item>
                    {/* Invisible for now as w don't know how to show latest event */}
                    <Grid container alignItems='center'>
                      <Grid item className='shipment-list-item-current-event marker'></Grid>
                      <Grid
                        item
                        title={`Event Code: ${_.get(
                          shipment,
                          'summary.latestActualEvent.code'
                        )}, Location: ${getAddressFromLocation(_.get(shipment, 'summary.latestActualEvent.location')) ||
                        'Not confirmed'}`}
                      >
                        <Typography className='shipment-list-item-current-event'>
                          {_.get(shipment, 'summary.latestActualEvent.name') ||
                            _.get(shipment, 'summary.latestActualEvent.code')}
                        </Typography>
                      </Grid>
                      <Grid item className='mx-4'>
                        &#183;
                      </Grid>
                      <Grid item title={_.get(shipment, 'summary.latestActualEvent.type')} className='mr-8'>
                        <Typography className='shipment-list-item-current-event'>
                          {formatTimestamp(_.get(shipment, 'summary.latestActualEvent.occurenceAt'), 'date-time')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container justify='flex-end' alignItems='center'>
                      <Grid item className='mr-8'>
                        <Grid container alignItems='center'>
                          <img src='assets/images/icons/shipments-list/ship-name.png' alt='ship-name' />
                        </Grid>
                      </Grid>
                      <Grid item className={classes.rowHeadTitleRight}>
                        {_.get(shipment, 'vesselSchedule.FirstPOL.vessel.name') ||
                          _.get(shipment, 'freightOperatorInfo.name') ||
                          'Not Decided'}
                        {/* {[shipment.vesselSchedule.FirstPOL.vessel.name, ...shipment.vesselSchedule.transhipmentPorts.map(x => x.vessel.name)].join(", ")} */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Timeline shipment={shipment} classes={classes} />
        </Grid>
      </Grid>
      <Grid item style={{ marginLeft: 'auto' }}>
        <button
          className={classNames(classes.trackButton, { notracking: !trackingAvailable })}
          onClick={() => {
            if (trackingAvailable) {
              window.open(_.get(shipment, 'summary.tracking.trackingLink', ''), '_blank')
            }
          }}
          title={_.get(shipment, 'summary.tracking.error', '')}
        >
          T<br />R<br />A<br />C<br />K
        </button>
      </Grid>
    </Grid>
  )
}
const Timeline = ({ shipment, classes }) => {
  if (!shipment) {
    return null
  }
  let journeyCurrentIdx = shipment.journeyCurrentIdx
  let hasFirstConsignee = false;
  const filteredJourney = shipment.journey.filter(obj => {
    if (_.get(obj, 'location.visibilityType', '') === 'always' || obj.keyEvents) {
      if (get(obj, 'location.type') === 'warehouse' &&
        get(obj, 'location.subType') === 'destination') {
        if (hasFirstConsignee) {
          return false
        } else {
          hasFirstConsignee = true
          return true
        }
      }
      return true
    }
    return false
  })

  let completedIndex = -1
  filteredJourney.forEach((location, index) => {
    if (journeyCurrentIdx === index) {
      completedIndex = index
    }
  })

  const stepConfig = {
    CY: { className: 'container_yard' },
    CFS: { className: 'container_yard' },
    storage: { className: 'storage' },
    warehouse: { className: 'warehouse' },
    port: { className: 'port' }
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.rowLeft}>
        <Steps className='shipment-list-item-progress'>
          {filteredJourney.map((journey, index) => {
            let className = 'container_yard'
            if (journey.location && journey.location.type) {
              className = stepConfig[journey.location.type].className
            }

            if (index <= completedIndex) {
              className = `${className}-complete`
            } else if (index === completedIndex + 1) {
              className = `${className}-current`
            }
            if (index === filteredJourney.length - 1) {
              className = `${className} last-step`
            }
            let defaultNodeDetails = { title: '------', line1: '------', line2: '-------' }
            let nodeDetails = getLocationDetails(journey, shipment, 'cargo', index)
            let { title, address, line1, line2 } = nodeDetails ? nodeDetails : defaultNodeDetails
            return (
              <Steps.Step
                key={index}
                className={`shipment-list-item-progress-step ${className}`}
                description={
                  <>
                    <Typography className='step__description' title={title} noWrap>
                      {title}
                    </Typography>
                    <Typography
                      className='step__description'
                      noWrap>
                      {address}
                    </Typography>
                    <Typography
                      className='step__description'
                      style={{
                        fontWeight: '200',
                        color: ((!get(nodeDetails, 'consignees') &&
                          get(nodeDetails, 'allLine1', []).length > 1) ? '#FFAA3E' : '#949494'),
                        fontStyle: 'italic'
                      }}
                      title={get(nodeDetails, 'consignees') ?
                        get(nodeDetails, 'consignees', []).map((item, index) => {
                          return index === 0 ? false : item.location.name
                        }).filter(Boolean).join('\n') :
                        get(nodeDetails, 'allLine1', []).join('\n')
                      }
                      noWrap
                    >
                      {line1}
                    </Typography>
                    <Typography
                      className='step__description'
                      style={{
                        fontWeight: '200',
                        color: (get(nodeDetails, 'allLine2', []).length > 1 ? '#FFAA3E' : '#949494'),
                        fontStyle: 'italic'
                      }}
                      title={get(nodeDetails, 'allLine2', []).join('\n')}
                      noWrap
                    >
                      {line2}
                    </Typography>
                  </>
                }
              />
            )
          })}
        </Steps>
      </Grid>
    </Grid>
  )
}
const styles = theme => ({
  row: {
    backgroundColor: '#FAFAFA',
    borderTop: '.5px solid #ebebeb'
  },
  noBgRow: {
    backgroundColor: 'transparent',
  },
  rowPadded: {
    padding: 20
  },
  rowHead: {
    padding: 20
  },
  icon: {
    marginRight: 10,
    width: 20,
    height: 20
  },
  rowLeft: {
    padding: 20,
    overflowX: 'scroll'
  },
  rowHeadTitle: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'left'
  },
  rowHeadTitleRight: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'left'
  },
  editContainerSeal: {
    '&:hover': {
      color: '#41B4E6'
    },
    color: '#CFCFD1',
    cursor: 'pointer'
  },
  trackButton: {
    height: '100%',
    width: '20px',
    fontSize: '14px',
    backgroundColor: '#50bbee',
    color: '#fff',
    marginBottom: 2,
    fontWeight: 400,
    '&.notracking': {
      backgroundColor: '#e1e1e1',
      color: '#c7c7c7',
      border: '1px solid #c9c9c9',
      cursor: 'not-allowed'
    }
  }
})

const mapStateToProps = ({ welcome }) => ({
  currentPlant: welcome.currentPlant
})
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)(LCLCargoTimelineRow))
