import ActionTypes from './types'
import * as API from '../../core/api'
import {
  apiAttempt,
  apiSuccess,
  apiFailed,
} from '../../core/store/actions'

/* --- Map View --- */
export const getCoordinatesSuccess = payload => ({
  type: ActionTypes.GET_MAP_VIEW_VESSELS, payload
})

export const getVesselInfoSuccess = payload => ({
  type: ActionTypes.GET_MAP_VIEW_VESSEL_INFO, payload
})

export const getCesiumTokenSuccess = payload => ({
  type: ActionTypes.GET_CESIUM_TOKEN, payload
})

export const getCoordinates = (success = null, fail = null) => async dispatch => {
  try {
    dispatch(apiAttempt())
    const {ok, data} = await API.getVesselPositions();

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(apiSuccess())
    dispatch(getCoordinatesSuccess(data))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(apiFailed(e.message))

    if (fail) {
      fail()
    }
  }
}

export const getVesselInfo = (imo, success = null, fail = null) => async dispatch => {
  try {
    dispatch(apiAttempt())
    const {ok, data} = await API.getVesselInformation(imo);

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(apiSuccess())
    dispatch(getVesselInfoSuccess(data))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(apiFailed(e.message))

    if (fail) {
      fail()
    }
  }
}

export const getCesiumToken = (success = null, fail = null) => async dispatch => {
  try {
    dispatch(apiAttempt())
    const {ok, data} = await API.getCesiumToken();

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(apiSuccess())
    dispatch(getCesiumTokenSuccess(data))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(apiFailed(e.message))

    if (fail) {
      fail()
    }
  }
}
/* --------- */
