import React from 'react'
import { Grid, MenuItem, Select, InputLabel, Icon } from '@material-ui/core'
import DateTime from 'react-datetime'
import moment from 'moment'
import classNames from 'classnames'

const DateRangeBlock = ({ range, eventsList, filter, setFilter, classes, index }) => {
  const updateRow = obj => {
    setFilter({ ...filter, ranges: [...filter.ranges.slice(0, index), obj, ...filter.ranges.slice(index + 1)] })
  }
  const deleteRow = obj => {
    setFilter({ ...filter, ranges: [...filter.ranges.slice(0, index), ...filter.ranges.slice(index + 1)] })
  }
  const getUniqueNames = arr => {
    let uniqueNames = []
    arr.forEach(option => {
      if (uniqueNames.indexOf(option.name) === -1) {
        uniqueNames.push(option.name)
      }
    })
    return uniqueNames
  }
  return (
    <Grid container spacing={1}>
      <Grid item xs={2}>
        <InputLabel className='form-label form-label_bg' style={{}}>
          {`Event`}
        </InputLabel>
        <Select
          style={{ width: '100%' }}
          value={range.event}
          onChange={e => {
            updateRow({ ...range, event: e.target.value })
          }}
        >
          {getUniqueNames(eventsList).map((name, index) => {
            return (
              <MenuItem value={name} key={index}>
                {name}
              </MenuItem>
            )
          })}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <InputLabel className='form-label form-label_bg' style={{}}>
          {`Type`}
        </InputLabel>
        <Select
          style={{ width: '100%' }}
          value={range.type}
          onChange={e => {
            updateRow({ ...range, type: e.target.value })
          }}
        >
          <MenuItem value='estimated'> Estimated</MenuItem>
          <MenuItem value='actual'> Actual</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={2}>
        <InputLabel className='form-label form-label_bg' style={{}}>
          {`Range`}
        </InputLabel>
        <Select
          style={{ width: '100%' }}
          value={range.range_type}
          onChange={e => {
            if (e.target.value !== 'custom') {
              updateRow({ ...range, range_type: e.target.value, from: null, to: null })
            } else {
              let today = moment()
              let lastWeek = moment().subtract(7, 'd')
              updateRow({
                ...range,
                range_type: e.target.value,
                from: lastWeek.toDate(),
                to: today.toDate()
              })
            }
          }}
        >
          <MenuItem value='today'> Today</MenuItem>
          <MenuItem value='yesterday'> Yesterday</MenuItem>
          <MenuItem value='lastWeek'> Last week</MenuItem>
          <MenuItem value='last30'> Last 30 days</MenuItem>
          <MenuItem value='last60'> Last 60 days</MenuItem>
          <MenuItem value='custom'> Custom</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={2}>
        {range.range_type === 'custom' && (
          <Grid className={classNames('', classes.bordered)}>
            <Grid xs={12} item>
              <InputLabel className='form-label form-label_bg' style={{}}>
                {`From Date`}
              </InputLabel>
            </Grid>
            <Grid xs={12} item>
              <Grid container alignItems='center'>
                <Grid xs={10} item>
                  <DateTime
                    className={classNames(classes.datetimePicker)}
                    input
                    dateFormat='DD MMM YYYY'
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={date => {
                      if (new Date(date).toString() === 'Invalid Date') {
                        date = new Date()
                      }
                      updateRow({
                        ...range,
                        from: date
                      })
                    }}
                    value={range.from && new Date(range.from)}
                  />
                </Grid>
                <Grid xs={2} item>
                  <Icon
                    color='disabled'
                    style={{
                      fontSize: 15,
                      marginRight: 2
                    }}
                  >
                    calendar_today
                  </Icon>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={2}>
        {range.range_type === 'custom' && (
          <Grid className={classNames('', classes.bordered)}>
            <Grid xs={12} item>
              <InputLabel className='form-label form-label_bg' style={{}}>
                {`To Date`}
              </InputLabel>
            </Grid>
            <Grid xs={12} item>
              <Grid container alignItems='center'>
                <Grid xs={10} item>
                  <DateTime
                    className={classNames(classes.datetimePicker)}
                    input
                    dateFormat='DD MMM YYYY'
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={date => {
                      if (new Date(date).toString() === 'Invalid Date') {
                        date = new Date()
                      }
                      updateRow({
                        ...range,
                        to: date
                      })
                    }}
                    value={range.to && new Date(range.to)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Icon color='disabled' style={{ fontSize: 15, marginRight: 2 }}>
                    calendar_today
                  </Icon>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={1}>
        <Grid container justify='flex-end' className='mt-8'>
          <img
            src='assets/images/icons/settings/delete-event-active.svg'
            alt='delete-notification'
            className='cursor-pointer'
            onClick={() => {
              deleteRow()
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default DateRangeBlock
