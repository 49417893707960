import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { formatTimestamp } from 'core/helpers'
import { get } from 'lodash'

const ChatDocuments = ({ shipmentId, chatDocuments, onDownloadClick }) => {

  return (
    <Grid container>
      <Grid item xs={12}
        className='shipment-details-container-section'>
        <Grid container
          justify="space-between"
          className="mb-20">
          <Grid item>
            <Typography className='section__title bold'>Chat documents</Typography>
          </Grid>
          <Grid item className="hidden">
            <Grid container justify="flex-end">
              <Grid item>
                <Typography className='fg-green bold'>Download all</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <table className='documents-list' style={{ width: '100%' }}>
          <tbody>
            <tr>
              <td className="pb-12"></td>
              <td className="pb-12">
                <Typography className="text-12 regular fg-gray">
                  Document type</Typography>
              </td>
              <td className="pb-12">
                <Typography className="text-12 regular fg-gray">
                  Uploaded by</Typography>
              </td>
              <td className="pb-12">
                <Typography className="text-12 regular fg-gray">
                  Uploaded on</Typography>
              </td>
              <td className="pb-12"></td>
            </tr>
            {
              chatDocuments &&
              chatDocuments.map((doc, index) => (
                <tr key={`table_row_${index}`} className='table-row'>
                  <td className="pb-12">
                    <img src='/assets/images/icons/document-upload.svg'
                      alt='doc' />
                  </td>
                  <td className="pb-12">
                    <Grid container alignItems="center"
                      className="max-w-200">
                      <Grid item xs={12}>
                        <Typography noWrap
                          className="text-14 semi-bold"
                          title={get(doc, 'originalName')}>{get(doc, 'originalName')}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container
                      className="max-w-200">
                      <Grid item xs={1}>
                        <img src='/assets/images/icons/Message-doc-upload@3x.svg'
                          alt="doc" />
                      </Grid>
                      <Grid item xs={11}>
                        <Typography className="text-12 regular fg-gray"
                          title={get(doc, 'description')}>{get(doc, 'description')}</Typography>
                      </Grid>
                    </Grid>
                  </td>
                  <td className="pb-12">
                    <Typography noWrap
                      title={get(doc, 'user.firstname') + ' ' + get(doc, 'user.lastname')}
                      className="text-14 regular fg-gray">
                      {get(doc, 'user.firstname')}&nbsp;{get(doc, 'user.lastname')}</Typography>
                  </td>
                  <td className="pb-12">
                    <Typography noWrap
                      className="text-14 regular fg-gray"
                      title={formatTimestamp(get(doc, 'uploadedAt'), 'date-time')}>
                      {formatTimestamp(get(doc, 'uploadedAt'), 'date-time')}</Typography>
                  </td>
                  <td className="pb-12">
                    <Grid container alignItems='center' justify='flex-end'>
                      <Grid item className="pr-20 invisible">
                        <label className='fg-blue cursor-pointer regular'>
                          View all</label>
                      </Grid>
                      <Grid item>
                        <img src='/assets/images/icons/import-header-green.svg'
                          alt='import'
                          className='cursor-pointer'
                          onClick={() => onDownloadClick(doc, true)} />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </Grid>
    </Grid>
  )
}

export default ChatDocuments
