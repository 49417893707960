import React from 'react'
import { Toolbar, Grid } from '@material-ui/core'
import classNames from 'classnames'
import history from '../../history'
import FuseAnimate from './Animate'

const BaseToolBar = ({ className, text, children, withBack, leftComponent, doubleRightContent, leftIcon }) => (
  <Toolbar className={classNames(className, !className.includes('px-') ? 'px-20' : '')}>
    {window.innerWidth < 960 &&
      <Grid container alignItems="center">
        <Grid item xs={12}
          className="text-14 bold">
          {leftIcon}
          {withBack && (
            <img
              src='assets/images/icons/toolbar_back.png'
              alt='back'
              className='mr-12 cursor-pointer'
              onClick={() => history.push(`/${window.location.pathname.split('/')[1]}`)}
            />
          )}
          {text && <span>{text}</span>}
          {leftComponent}
        </Grid>
      </Grid>
    }
    {window.innerWidth >= 960 &&
      <React.Fragment>
        <FuseAnimate animation='transition.slideLeftIn'>
          <div className='flex flex-1 text-14 bold items_center'>
            {leftIcon}
            {withBack && (
              <img
                src='assets/images/icons/toolbar_back.png'
                alt='back'
                className='mr-12 cursor-pointer'
                onClick={() => history.push(`/${window.location.pathname.split('/')[1]}`)}
              />
            )}
            {text && <span>{text}</span>}
            {leftComponent}
          </div>
        </FuseAnimate>
        <FuseAnimate animation='transition.fadeIn'>
          <div className={
            classNames({
              'flex justify-end items-center': true,
              'flex-1': !doubleRightContent,
              'flex-2': doubleRightContent
            })}>
            {children}
          </div>
        </FuseAnimate>
      </React.Fragment>
    }
  </Toolbar>
)
export default BaseToolBar
