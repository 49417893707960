import React from 'react'
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/Check';
import Fab from '@material-ui/core/Fab';

const PlantSelectionRow = ({ data, onClick }) => (
  <Grid container className="plant-selection-row" onClick={() => onClick(data)}>
    <Grid item xs={10}>
      <h4 className='title'>{data.name}</h4>
      {
        data.meta && (
          <p className='sub-title'>
            {data.meta.referencePlantId}
            {/* <span style={{ fontSize: 16 }}>&#183;</span> 23 new notifications */}
      </p>
        )
      }
    </Grid>
    <Grid item xs={2}>
      <Fab color="primary" aria-label="add" className="plant-fab">
        <CheckIcon className="plant-check" />
      </Fab>
    </Grid>
  </Grid >
)

export default PlantSelectionRow
