import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

const OutboundListSkeleton = () => (
  <Grid container spacing={1}>
    {[1, 2, 3].map(item => {
      return (
        <Grid key={item} item xs={12}>
          <Skeleton height={80} />
        </Grid>
      )
    })}
  </Grid>
)

export default OutboundListSkeleton
