import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as API from 'core/api'
import BadPerm from '../../containers/BadPermPage'

// TODO: remove after plants api/store implementation (data will be fetched when we are on plant selection page)
const plantsData = [
  {
    _id: '5daeb9fda05bc70011d12b58',
    plantName: 'plant1',
    role: 'shipper',
    organization: 'org1',
    office: 'office1'
  },
  {
    _id: '5dbbf9186150b70011ac70ab',
    plantName: 'plant2',
    role: 'consignee',
    organization: 'org1',
    office: 'office1'
  }
]

const ProtectedRoute = ({
  component: Component,
  roles,
  ntwRoles,
  info,
  token,
  children,
  plantSelected,
  currentPlant,
  onboarding,
  ...rest
}) => {

  const requestedHref = `${window.location.pathname.slice(1)}${window.location.search}`

  if (!token || !info) {
    API.setTokenHeader(null)
    return <Redirect to={requestedHref ? `/login#redirect_href=${requestedHref}` : '/login'} />
  }
  if (!currentPlant && plantSelected) {
    return <Redirect to={requestedHref ? `/#redirect_href=${requestedHref}` : '/'} />
  }
  //onboarding case
  if (info.status === 'invited') {
    return <Redirect to={requestedHref ? `/login#redirect_href=${requestedHref}` : '/login'} />
  }
  let canAccess
  let _roles = info.roles
  let _ntwRoles = info.ntwRoles

  if (_roles) {
    if (typeof info.roles === 'string') {
      _roles = [info.roles]
    }
    if (typeof ntwRoles === 'string') {
      _ntwRoles = [info.ntwRoles]
    }

    canAccess = info.plants.some(v => {
      const plant = plantsData.find(d => d._id === v._id)

      if (!plant) {
        return false
      }

      const roleStr = plant.role + (v.role ? `_${v.role}` : v.role)
      const access = _roles.some(role => roleStr.startsWith(role))

      return access
    })

    if (!canAccess) {
      return <BadPerm code='403' message='Sorry you do not have permission to view this page' />
    }
  }
  if (_ntwRoles && !_ntwRoles.includes(info.role)) {
    return <BadPerm code='403' message='Sorry you do not have permission to view this page' />
  }

  return Component ? <Route {...rest} component={Component} /> : <Route {...rest}>{children}</Route>
}

const mapStateToProps = ({ auth: { info, token, onboarding }, welcome }) => ({
  info,
  token,
  currentPlant: welcome.currentPlant
})

const mapDispatchToProps = dispatch => ({})
ProtectedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  roles: PropTypes.any,
  children: PropTypes.any
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
