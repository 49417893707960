import React from 'react'
import { Grid, FormControlLabel } from '@material-ui/core'
import { FuseCheckBox } from 'core/components'
import classNames from 'classnames'

/**<div className='exception-reason-selection-row' onClick={() => onClick(data)}>
    <h4 className='title'>{data.name}</h4>
    {data.reason && (
      <p className='sub-title'>
        {data.reason}
      </p>
    )}
  </div> */

// const GreenCheckbox = withStyles({
//   root: {
//     color: green[400],
//     '&$checked': {
//       color: green[600]
//     }
//   },
//   checked: {}
// })(props => <Checkbox color='default' {...props} />)
const ExceptionReasonSelectionRow = ({ data, onClick }) => (
  <Grid
    container
    className={classNames({
      'exception-reason-selection-row': true,
      'exception-reason-selection-row-selected': data.checked
    })}
    onClick={() => onClick(data)}
  >
    <Grid item xs={1}>
      <FormControlLabel
        control={<FuseCheckBox checked={data.checked} className='p-0 pr-2 pl-2' 
        onChange={() => onClick(data)} />}
      />
    </Grid>
    <Grid item xs={11}>
      <h4 className='title'>{data.name}</h4>
      {data.reason && <p className='sub-title'>{data.reason}</p>}
    </Grid>
  </Grid>
)

export default ExceptionReasonSelectionRow
