import ActionTypes from './types'
import { uniqBy, get } from "lodash"

const initialState = {
  loading: true,
  initialLoading: true,
  showShipmentsMore: 0,
  shipments: [],
  shipmentsTotal: 0,
  outboundsByShipment: [],
  vessels: [],
  shipmentsLoading: false,
  shipmentsLoadingMore: false,
  limitedServiceCards: []
}

const scheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_SHIPMENT_SUCCESS: {
      return {
        ...state,
        shipments: action.payload.reset ?
          action.payload.data :
          uniqBy([...state.shipments, ...action.payload.data], '_id'),
        showShipmentsMore: action.payload.showMore,
        loading: !!action.payload.loading,
        initialLoading: action.payload.reset ? state.initialLoading : false,
        shipmentsTotal: get(action, 'payload.total', 0)
      }
    }
    case ActionTypes.SET_SHIPMENTS_LOADING:
      return {
        ...state,
        shipmentsLoading: action.value
      }
    case ActionTypes.SET_SHIPMENTS_LOADING_MORE:
      return {
        ...state,
        shipmentsLoadingMore: action.value
      }
    case ActionTypes.GET_OUTBOUNDS_BY_SHIPMENT_SUCCESS: {
      return {
        ...state,
        outboundsByShipment: action.payload
      }
    }
    case ActionTypes.GET_VESSELS_SUCCESS: {
      return {
        ...state,
        vessels: action.payload.data
      }
    }

    case ActionTypes.GET_SHIPMENT_META_SUCCESS: {
      return {
        ...state,
        shipments: state.shipments.map(shipment => {
          if (shipment._id === action.payload.shipmentId) {
            shipment.rbac = action.payload.meta
          }
          return shipment
        })
      }
    }

    case ActionTypes.GET_LIMITED_SERVICE_CARDS_SUCCESS: {
      return {
        ...state,
        limitedServiceCards: action.payload
      }
    }
    default:
      return state
  }
}

export default scheduleReducer
