import * as JSZip from 'jszip'
import fileDownload from 'js-file-download'

export const zipFiles = (data, ext, fileName = 'files') => {
  const jszip = new JSZip()

  if (Object.keys(data).length > 0) {
    Object.keys(data).forEach(key => {
      jszip.file(`${key}${ext ? '.' + ext : ''}`, data[key])
    })
    return jszip.generateAsync({ type: 'blob' }).then(function(content) {
      fileDownload(content, fileName + '.zip')
    })
  }
}
