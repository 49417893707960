import * as API from '../../core/api'
import ActionTypes from './types'
import { get, orderBy, filter } from 'lodash'
import { showMessage } from '../../core/store/actions'

export const setOnGoingTask = (payload, callback = null) => async dispatch => {
  dispatch({
    type: ActionTypes.SET_USER_SETTINGS_ON_GOING_TASK,
    payload
  })

  if (callback) {
    callback()
  }
}

export const getEventsList = (success = null, fail = null) => async dispatch => {
  dispatch({
    type: ActionTypes.LOADING_USER_SETTINGS_EVENT_LIST
  })
  try {
    const { ok, data } = await API.getEventsListUserSettings()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_USER_SETTINGS_EVENT_LIST,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getEventNotification = (isSync = false, success = null, fail = null) => async dispatch => {
  const params = {
    configType: 'user',
    key: 'eventsNotification'
  }
  if (!isSync) {
    dispatch({
      type: ActionTypes.LOADING_USER_SETTINGS_EVENT_NOTIFICATIONS
    })
  }

  try {
    const { ok, data } = await API.getUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_USER_SETTINGS_EVENT_NOTIFICATIONS,
      payload: orderBy(get(data, 'data', []), 'createdAt', 'asc')
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getPlaces = (isSync = false, success = null, fail = null) => async (dispatch, getState) => {
  const placeTypes = {
    shipper: ['truck-in'],
    obl: ['container-yard', 'storage-before-warehouse', 'storage-before-port', 'storage-before-cfs'],
    ofp: ['container-yard', 'cfs']
  }
  const params = {
    configType: 'user',
    key: 'places'
  }
  if (!isSync) {
    dispatch({
      type: ActionTypes.LOADING_USER_SETTINGS_PLACES
    })
  }

  try {
    const { ok, data } = await API.getUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_USER_SETTINGS_PLACES,
      payload: orderBy(filter(get(data, 'data', []), item => (
        get(placeTypes, `[${get(getState(), 'welcome.currentPlant.type')}]`, []).includes(get(item, 'value.type'))
      )), 'createdAt', 'asc')
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const updateUserSettingsById = (id, params, success = null, fail = null) => async dispatch => {
  params.configType = 'user'

  try {
    const { ok, status, data } = await API.updateUserSettings(id, params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const deleteUserSettingsById = (id, key, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.deleteUserSettings(id, key)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getUserSettingsByKey = (key = null, success = null, fail = null) => async dispatch => {
  const params = {
    configType: 'user',
  }
  if (key) {
    params.key = key
  }

  try {
    const { ok, data } = await API.getUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_SET_UPDATE_USER_SETTINGS_BY_KEY,
      key,
      value: get(data, 'data[0].value', '')
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const setUserSettingByKey = (key, params, success = null, fail = null) => async dispatch => {
  params.configType = 'user'

  try {
    const { ok, status, data } = await API.setUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const setUpdateUserSettingByKey = (key, params, success = null, fail = null) => async dispatch => {
  params.configType = 'user'

  try {
    const { ok, status, data } = await API.setUpdateUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_SET_UPDATE_USER_SETTINGS_BY_KEY,
      key,
      value: get(data, 'data', '')
    })
    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const setBulkUserSettings = (key, params, success = null, fail = null) => async dispatch => {
  params = params.map(param => {
    param.configType = 'user'
    return param
  })

  try {
    const { ok, status, data } = await API.setBulkUserSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getPlantEmailTemplates = (isSync = false, success = null, fail = null) => async (dispatch) => {
  const params = {
    key: 'plantEmailTemplate'
  }
  if (!isSync) {
    dispatch({
      type: ActionTypes.LOADING_PLANT_SETTINGS_EMAIL_TEMPLATES
    })
  }

  try {
    const { ok, data } = await API.getPlantSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.LOAD_PLANT_SETTINGS_EMAIL_TEMPLATES,
      payload: orderBy(get(data, 'data', []), 'createdAt', 'asc')
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const setPlantEmailTemplates = (params, success = null, fail = null) => async (dispatch) => {
  try {
    const { ok, status, data } = await API.setPlantSettings(params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const updatePlantEmailTemplates = (id, params, success = null, fail = null) => async (dispatch) => {
  try {
    const { ok, status, data } = await API.updatePlantSettings(id, params)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const deletePlantEmailTemplates = (id, success = null, fail = null) => async (dispatch) => {
  try {
    const { ok, status, data } = await API.deletePlantSettings(id)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(showMessage(e))
    if (fail) {
      fail()
    }
  }
}
