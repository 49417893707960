import React from 'react'
import PropTypes from 'prop-types'
import { Button, withStyles, Link, Grid, CircularProgress } from '@material-ui/core'
import { FuseTextField } from 'core/components'

const styles = theme => ({})

const labelStyles = {
  fontSize: '1.1em',
  marginBottom: '0.4em'
}

const inputStyles = {
  fontFamily: 'IBMPlexSans',
  fontSize: '1.2em'
}

const LoginForm = ({ login, loading, hash, setShowForgotPassword, email, setEmail, password, setPassword }) => {
  const onEmailChange = e => setEmail(e.target.value.trim())
  const onPasswordChange = e => setPassword(e.target.value)
  const onSubmit = () => login({ email, password, hash })

  const baseTextSetting = {
    position: 'end',
    className: 'text-20 cursor-pointer',
    color: 'action'
  }

  return (
    <div className='flex flex-col justify-start w-full'>
      <form onSubmit={e => {
        onSubmit()
        e.preventDefault()
      }} noValidate>
        <FuseTextField className='mb-16'
          type='text'
          name='email'
          label='Username or Email address'
          icon={{ ...baseTextSetting, name: 'email' }}
          value={email}
          labelStyles={labelStyles}
          inputStyles={inputStyles}
          onChange={onEmailChange}
          required
          fullWidth />

        <FuseTextField className='mb-16'
          type="password"
          name='password'
          label='Password'
          icon={{ ...baseTextSetting, name: 'vpn_key' }}
          value={password}
          labelStyles={labelStyles}
          inputStyles={inputStyles}
          onChange={onPasswordChange}
          required
          fullWidth />

        <Grid container
          alignItems='center'
          alignContent='flex-start'>
          <Grid item className='mt-8'>
            <Link onClick={() => setShowForgotPassword(true)}>
              Forgot password?</Link>
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button type='submit'
              variant='contained'
              color='primary'
              style={{ borderRadius: '50vh' }}
              className='min-w-160 ml-auto mt-8 btn'
              aria-label='LOG IN'
              disabled={loading || (!email || !password)}>
              {!loading ? 'Login' : 'Logging In...'}{' '}
              {loading &&
                <CircularProgress color='primary'
                  size={20}
                  style={{ marginLeft: 20 }} />
              }
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default withStyles(styles)(LoginForm)
