import React, { useEffect, useState } from 'react'
import { FuseSideBar, FuseSideBarHeader, FuseSideBarBody, FuseSideBarActions } from 'core/components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PersonalDetailsForm from './PersonalDetailsForm'
import ChangePasswordForm from './ChangePasswordForm'
import * as AuthActions from '../../../auth/store/actions'
import validator from 'validator'
import { get } from 'lodash'

const INITIAL_USERDETAILS_STATE = {
  firstname: '',
  lastname: '',
  email: '',
  username: '',
  phone: {
    countryCode: '+65',
    phoneNumber: ''
  },
  error: {}
}

const validateEmail = (payload, setUserDetails) => {
  const { email } = payload
  if (!validator.isEmail(email)) {
    setUserDetails({
      ...payload,
      error: {
        email: 'Enter a valid email'
      }
    })
    return false
  }
  return true
}

const validateUsername = (payload, setUserDetails) => {
  const { username } = payload
  if (username.trim() === '') {
    setUserDetails({
      ...payload,
      error: {
        username: 'Enter username'
      }
    })
    return false
  }
  return true
}

const validatePassword = (payload, setPasswordDetails) => {
  const { newPassword, confirmNewPassword, oldPassword } = payload
  if (oldPassword === newPassword) {
    setPasswordDetails({
      ...payload,
      error: {
        newPassword: 'New Password is same as old password'
      }
    })
    return false
  }
  var regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
  if (!regex.test(newPassword)) {
    setPasswordDetails({
      ...payload,
      error: {
        newPassword:
          'Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
      }
    })
    return false
  }
  if (confirmNewPassword !== newPassword) {
    setPasswordDetails({
      ...payload,
      error: {
        confirmNewPassword: 'Passwords not matching'
      }
    })
    return false
  }
  return true
}
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}
const INITIAL_PASSWORD_STATE = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
  error: {}
}
const ProfileDetailsSidebar = ({ open, closeSideBar, updateUserProfile, user, updatePassword, defaultLogin }) => {
  const [value, setValue] = useState(0)
  const [userDetails, setUserDetails] = useState(INITIAL_USERDETAILS_STATE)
  const [passwordDetails, setPasswordDetails] = useState(INITIAL_PASSWORD_STATE)

  useEffect(() => {
    if (open) {
      setUserDetails({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        username: user.username,
        phone: {
          countryCode: user.phone ? user.phone.countryCode : '',
          phoneNumber: user.phone ? user.phone.phoneNumber : ''
        }
      })
    }
  }, [open, user])

  useEffect(() => {
    if (!open) {
      setValue(0)
      setUserDetails(INITIAL_USERDETAILS_STATE)
      setPasswordDetails(INITIAL_PASSWORD_STATE)
    }
  }, [open])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <FuseSideBar open={open}>
      <FuseSideBarHeader text={'Profile Details'} handleClose={closeSideBar} />
      <FuseSideBarBody style={{ padding: 0 }}>
        <Tabs value={value}
          onChange={handleChange}
          className='bg-gray'>
          <Tab label='Personal Details'
            {...a11yProps(0)}
            className={'custom-tabs-item ' + (value === 0 ? 'selected' : '')}
          />
          {defaultLogin &&
            <Tab label='Change Password'
              {...a11yProps(1)}
              className={'custom-tabs-item ' + (value === 1 ? 'selected' : '')}
            />
          }
        </Tabs>
        <TabPanel value={value} index={0}>
          <Grid container className='profile-details-container'>
            <Grid item xs={12} className='profile-details-container-section'>
              <PersonalDetailsForm userDetails={userDetails}
                setUserDetails={setUserDetails}
                defaultLogin={defaultLogin} />
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container className='profile-details-container'>
            <Grid item xs={12}>
              <ChangePasswordForm passwordDetails={passwordDetails}
                setPasswordDetails={setPasswordDetails} />
            </Grid>
          </Grid>
        </TabPanel>
      </FuseSideBarBody>
      <FuseSideBarActions>
        {value === 0 && (
          <Button className='btn btn_full-space'
            onClick={() => updateUserProfile(userDetails, closeSideBar, setUserDetails)}
            disabled={!defaultLogin}>
            Update Profile
          </Button>
        )}
        {value === 1 && (
          <Button className='btn btn_full-space'
            onClick={() => updatePassword(passwordDetails, closeSideBar, setPasswordDetails)}
            disabled={!defaultLogin}>
            Change Password
          </Button>
        )}
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

const mapStateToProps = ({ auth }) => ({
  user: auth.info,
  defaultLogin: get(localStorage, 'defaultLogin') === 'true'
})

const mapDispatchToProps = dispatch => ({
  updateUserProfile: async (payload, closeSideBar, setUserDetails) => {
    if (!validateEmail(payload, setUserDetails) || !validateUsername(payload, setUserDetails)) {
      return
    }
    await dispatch(AuthActions.updateProfile(payload))
    closeSideBar()
  },
  updatePassword: async (payload, closeSideBar, setPasswordDetails) => {
    if (!validatePassword(payload, setPasswordDetails)) {
      return
    }
    await dispatch(AuthActions.updatePassword(payload))
    setPasswordDetails(INITIAL_PASSWORD_STATE)
    closeSideBar()
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailsSidebar)
