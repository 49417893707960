import ActionTypes from './types'

const initialState = {
  users: [],
  loading: true
}

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_PLANT_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload
      }

    default:
      return state
  }
}

export default usersReducer
