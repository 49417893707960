import React from 'react'
import { withStyles, Button, Icon } from '@material-ui/core'
import classNames from 'classnames'

const IconButton = ({
  classes,
  variant,
  icon,
  iconClassName,
  iconComponent,
  img,
  children,
  className,
  disabled,
  greyed,
  solid = false,
  ...other
}) => {
  let root = solid ? classes.rootSolid : classes.root
  return (
    <React.Fragment>
      {variant !== 'marked' &&
        <Button disabled={disabled}
          className={classNames(root, className, {
            [classes.outline]: variant === 'outline',
            [classes.flat]: variant === 'flat',
            [classes.greyed]: greyed
          })}
          {...other}>
          {iconComponent}
          {icon && <Icon className={'mr-8 ' + iconClassName}>{icon}</Icon>}
          {img && <img src={img} className='w-16 h-16 mr-8' alt='icon' />}
          {children}
        </Button>
      }
      {variant === 'marked' &&
        <Button disabled={disabled}
          className={classNames(classes.root, 'marked', className, {
            [classes.outline]: variant === 'outline',
            [classes.flat]: variant === 'flat',
            [classes.greyed]: greyed
          })}
          {...other}>
          {icon &&
            <div className='icon'>
              <Icon className='w-20 h-20'>{icon}</Icon>
            </div>
          }
          {img &&
            <div className='icon'>
              <img src={img} className='w-20 h-20' alt='icon' />
            </div>
          }
          <div className='px-16'>{children}</div>
        </Button>
      }
    </React.Fragment>
  )
}

let rootStyles = {
  display: 'flex',
  justifyContent: 'center',
  padding: '4px 16px',
  color: '#ffffff',
  backgroundColor: '#3dcd58',
  borderRadius: '50vh',
  height: 32,
  '&:hover': {
    backgroundColor: '#3dcd58'
  },
  '&:disabled': {
    color: '#bcbcbc !important',
    backgroundColor: '#ffffff !important',
    border: '1px dashed currentColor !important'
  },
  '&.marked': {
    borderRadius: 4,
    padding: 0,

    '& > span > .icon': {
      padding: '6px 6px 5px 6px',
      backgroundColor: '#00000021',

      '& > img': {
        marginTop: -3
      }
    }
  }
}

const styles = theme => ({
  root: rootStyles,
  rootSolid: {
    ...rootStyles,
    '&:disabled': {
      ...rootStyles['&:disabled'],
      border: '1px solid currentColor !important'
    }
  },
  childText: {},
  outline: {
    background: '#ffffff !important',
    color: '#3dcd58',
    border: '1px solid currentColor',

    '&:hover': {
      background: '#ffffff !important',
      filter: 'brightness(110%)'
    }
  },
  flat: {
    background: 'transparent !important',
    color: '#3dcd58',

    '&:hover': {
      filter: 'brightness(110%)'
    }
  },
  greyed: {
    color: '#bcbcbc',
    backgroundColor: '#ffffff !important',
    border: '1px dashed currentColor !important'
  }
})

export default withStyles(styles)(IconButton)
