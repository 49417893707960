import React, { useState } from 'react'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import LoadingBar from 'react-redux-loading-bar'
import { connect } from 'react-redux'
import NavbarWrapperLayout from './NavbarWrapperLayout'
import ToolBarLayout from './ToolBarLayout'
import { FuseScrollbars, NetworkDown } from '../../components'
import { get } from 'lodash'

const LayoutPage = ({ classes, children, currentPlant, networkDown }) => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  return (
    <div id='main-layout' className={classNames(classes.root, 'scroll-content')}>
      <div className='flex flex-1 flex-col overflow-hidden relative'>
        <div className={classes.wrapper}>
          <NavbarWrapperLayout
            organizationId={get(currentPlant, 'organization')}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
          />
          <div className={classes.contentWrapper}>
            <ToolBarLayout setDrawerOpen={setDrawerOpen} />
            <FuseScrollbars option={{ suppressScrollX: true }} className={classNames(classes.content, 'mt-48')}>
              {children}
            </FuseScrollbars>
          </div>
        </div>
      </div>
      <LoadingBar className='page-loading-bar' />
      {networkDown && <NetworkDown classes={classes} />}
    </div>
  )
}

const styles = theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '&.boxed': {
      maxWidth: 1200,
      margin: '0 auto',
      boxShadow: theme.shadows[3]
    },
    '&.scroll-body': {
      '& $wrapper': {
        height: 'auto',
        flex: '0 0 auto',
        overflow: 'auto'
      },
      '& $contentWrapper': {},
      '& $content': {}
    },
    '&.scroll-content': {
      '& $wrapper': {},
      '& $contentWrapper': {},
      '& $content': {}
    }
  },
  wrapper: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    flex: '1 1 auto'
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    zIndex: 3,
    overflow: 'hidden',
    flex: '1 1 auto',
    alignItems: 'center'
  },
  content: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    '-webkit-overflow-scrolling': 'touch',
    zIndex: 2
  }
})

const mapStateToProps = ({ router: { location }, welcome: { currentPlant }, core: { networkDown } }) => ({
  location,
  currentPlant,
  networkDown
})

export default connect(mapStateToProps)(withStyles(styles)(LayoutPage))
