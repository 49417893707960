import React from 'react'
import { Grid } from '@material-ui/core'
import { FuseDialog, FuseDialogHeader, FuseDialogBody } from '../../core/components'
import JSONPretty from 'react-json-pretty'

const ViewError = ({ open, handleClose, errors }) => {
  return (
    <FuseDialog open={open} onBackDropClick={handleClose}>
      <FuseDialogHeader text="View Error" handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="p-20">
          <Grid item xs={12} className="border-1 border-gray rounded bg-gray p-12">
            <JSONPretty id="json-pretty" data={errors}></JSONPretty>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}
export default ViewError