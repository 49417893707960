import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import * as API from 'core/api'
import { checkPagination } from '../../core/helpers'
import { get } from 'lodash'

const defaultStatus = [
  'custom-approved',
  'ready-to-pickup',
  'delivery-scheduled'
]

export const setAllShipments = payload => ({
  type: ActionTypes.SET_ALL_INCOMING_SCHEDULE_SHIPMENTS,
  payload
})
export const setAllShipmentsTotal = payload => ({
  type: ActionTypes.SET_ALL_INCOMING_SCHEDULE_SHIPMENTS_TOTAL,
  payload
})
export const getShipmentMetaSuccess = payload => ({
  type: ActionTypes.GET_SHIPMENT_META_SUCCESS,
  payload
})

export const getOutboundsByShipmentSuccess = payload => ({
  type: ActionTypes.GET_OUTBOUNDS_BY_SHIPMENT_SUCCESS,
  payload
})

const DEFAULT_PAGE_SIZE = 10

export const getAllShipments = (pagination, status = defaultStatus, search = null, sort, customFilter, isSync = false) => async dispatch => {
  try {
    var params = {
      stage: ['import'],
      status
    }
    let payload = {}
    if (search) {
      params = {
        ...params,
        'multi-search': search
      }
    }
    if (sort) {
      params = {
        ...params,
        ...sort
      }
    }
    if (customFilter) {
      payload = { ...customFilter, stage: ['import'] }
      delete payload.name
    }
    if (pagination) {
      pagination.pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE
      params = {
        ...params,
        ...pagination
      }
      if (pagination.pageNo === 1 && !isSync) {
        dispatch(setAllShipments({ reset: true, data: [], loading: true }))
        dispatch(setAllShipmentsTotal(0))
      }
    }

    const { ok, data } = await API.getAllShipmentsWithFilter(params, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    dispatch(setAllShipments(data))
    dispatch(setAllShipmentsTotal(get(data, 'total', 0)))
    dispatch(CoreActions.getAllShipmentsCounts())
  } catch (e) {
    dispatch(setAllShipments({ reset: true, data: [], loading: false }))
    dispatch(setAllShipmentsTotal(0))
    dispatch(CoreActions.showMessage(e))
  }
}

export const getShipmentMeta = (id, callback = null) => async dispatch => {
  try {
    const { ok, data } = await API.getShipmentMeta(id)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(
      getShipmentMetaSuccess({
        shipmentId: id,
        meta: data.data
      })
    )

    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const consigneeMarkAsReceived = (payload, shipmentId, callback = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.consigneeCompleteBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(
      CoreActions.showMessage({
        status,
        data
      })
    )
    dispatch(getAllShipments())

    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getOutboundsByShipment = shipmentId => async dispatch => {
  try {
    const { ok, data } = await API.getOutboundsbyShipment(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getOutboundsByShipmentSuccess(data.data))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const reviewCustomDraft = (shipmentId, note, action, success = null, fail = null) => async dispatch => {
  try {
    let response
    if (action === 'accept') {
      response = await API.approveCustomReview(shipmentId, { note })
    } else {
      response = await API.rejectCustomReview(shipmentId, { note })
    }

    if (!get(response, 'ok')) {
      throw new Error(get(response, 'data.message'))
    }

    dispatch(CoreActions.showMessage({
      status: get(response, 'status'),
      data: get(response, 'data')
    }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const scheduleDelivery = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.scheduleDelivery(shipmentId, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}
