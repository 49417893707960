import ActionTypes from './types';
import * as API from '../../core/api';
import * as CoreActions from '../../core/store/actions'

export const setAnalyticsSheets = (sheets) => async dispatch => {
  dispatch({
    type: ActionTypes.SAVE_SHEETS_DATA,
    payload: sheets
  })
}

export const setAnalyticsAuth = () => async dispatch => {
  try {
    const { ok, data } = await API.getAnalyticsToken();
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch({
      type: ActionTypes.SET_ANALYTIC_AUTH,
    });
    return data;
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}
