import React from 'react'
import { FuseDialog, FuseDialogBody, FuseDialogHeader } from 'core/components'
import { Grid, withStyles, Typography, Button } from '@material-ui/core'

const ConfirmDialog = ({
  open,
  close,
  onButtonClick,
  onCancelButtonClick,
  classes,
  icon,
  headerText,
  text,
  confirmButtonText,
  cancelButtonText,
  wSM
}) => (
  <FuseDialog open={open} onBackDropClick={() => close()} wSM={wSM}>
    <FuseDialogHeader text={headerText} handleClose={close} />
    <FuseDialogBody>
      <Grid container spacing={3} alignItems='center' className={classes.delete}>
        {icon && (
          <Grid item sm={12} md={12} className={classes.gridRow}>
            {icon}
          </Grid>
        )}
        <Grid item sm={12} md={12} className={classes.gridRow}>
          <Typography className='text font-light'>{text}</Typography>
        </Grid>
        <Grid item sm={12} md={12} className={classes.gridRow}>
          <Button variant='contained' className={classes.button} onClick={onButtonClick}>
            {confirmButtonText}
          </Button>
        </Grid>
        <Grid item sm={12} md={12} className={classes.gridRow}>
          <Button color='primary' onClick={onCancelButtonClick}>
            {cancelButtonText}
          </Button>
        </Grid>
      </Grid>
    </FuseDialogBody>
  </FuseDialog>
)

const styles = theme => ({
  delete: {
    padding: 30
  },
  gridRow: {
    textAlign: 'center',
    margin: 5
  },
  button: {
    color: '#fff',
    backgroundColor: '#cc1f1a'
  },
  icon: { fontSize: 75 }
})
export default withStyles(styles)(ConfirmDialog)
