import { find, filter } from 'lodash'
const locodes = require('./json/locode.min.json')

const getType = (type) => {
  switch (type) {
    case 'ocean':
      return 'port'
    case 'air':
      return 'airport'

    default:
      return 'port'
  }
}

export const getAllLocodes = (type) => {
  return filter(locodes, item => item.type.includes(getType(type)))
}

export const getLocodeDetails = (code, type) => {
  return find(locodes, item => item.type.includes(getType(type)) && item.locode === code)
}
