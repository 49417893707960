import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import * as API from 'core/api'
import { checkPagination } from '../../core/helpers'
import { get } from "lodash";

export const setAllShipments = payload => ({
  type: ActionTypes.SET_ALL_COMPLETED_SHIPMENTS,
  payload
})
export const setAllShipmentsTotal = payload => ({
  type: ActionTypes.SET_ALL_COMPLETED_SHIPMENTS_TOTAL,
  payload
})
export const getOutboundsByShipmentSuccess = payload => ({
  type: ActionTypes.GET_OUTBOUNDS_BY_SHIPMENT_SUCCESS,
  payload
})
const DEFAULT_PAGE_SIZE = 10

export const getAllShipments = (pagination, status, search = null, sort, customFilter, isSync = false) => async dispatch => {
  try {
    var params = {
      stage: ['completed']
    }
    let payload = {}
    if (search) {
      params = {
        ...params,
        'multi-search': search
      }
    }
    if (sort) {
      params = {
        ...params,
        ...sort
      }
    }
    if (customFilter) {
      payload = { ...customFilter, stage: ['completed'] }
      delete payload.name
    }
    if (pagination) {
      pagination.pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE
      params = {
        ...params,
        ...pagination
      }
      if (pagination.pageNo === 1 && !isSync) {
        dispatch(setAllShipments({ reset: true, data: [], loading: true }))
        dispatch(setAllShipmentsTotal(0))
      }
    }

    //const { ok, data } = await API.getAllShipments(params)
    const { ok, data } = await API.getAllShipmentsWithFilter(params, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    dispatch(setAllShipments(data))
    dispatch(setAllShipmentsTotal(get(data, 'total', 0)))
    dispatch(CoreActions.getAllShipmentsCounts())
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getOutboundsByShipment = shipmentId => async dispatch => {
  try {
    const { ok, data } = await API.getOutboundsbyShipment(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getOutboundsByShipmentSuccess(data.data))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}
