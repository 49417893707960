import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { get } from 'lodash'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { FusePlaceHolder, LoadingButton } from '../../../core/components'
import { formatTimestamp } from '../../../core/helpers'
import CommentIcon from '@material-ui/icons/Comment';

const typeColors = {
  NOTICE: 'bg-light-blue fg-blue',
  ISSUE: 'bg-light-orange fg-orange'
}

export default ({
  shipmentId,
  isLoading,
  topics,
  onTopicClick,
  getTopics,
  openTopic,
  closeTopic }) => {
  const [requesting, setRequesting] = useState(false)

  const handleQuickAction = (e, topicId, action) => {
    e.preventDefault()
    setRequesting(topicId)
    if (action === 'open') {
      openTopic(topicId, async () => {
        await getTopics(shipmentId, true)
        setRequesting(false)
      }, () => {
        setRequesting(false)
      })
    } else if (action === 'close') {
      closeTopic(topicId, async () => {
        await getTopics(shipmentId, true)
        setRequesting(false)
      }, () => {
        setRequesting(false)
      })
    }
  }

  return (
    <Grid container spacing={2}>

      {isLoading &&
        [1, 2].map((item, index) => {
          return (
            <Grid item xs={12}
              key={index}>
              <Skeleton height={60} />
            </Grid>
          )
        })
      }

      {(!isLoading && topics.length === 0) &&
        <FusePlaceHolder className='flex flex-col'
          icon='info'
          title='No Notices' />
      }

      {!isLoading && topics.map((topic, topicIndex) => {
        return (
          <Grid item xs={12}
            key={topicIndex}
            className="regular text-14">
            <Grid container
              className="rounded shadow overflow-hidden">
              <Grid item xs={12}
                className="px-8 py-4">
                <Grid container spacing={1}>
                  <Grid item xs={9}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="medium">
                        <Grid container>
                          <Grid item>
                            <Typography className="bold text-14 cursor-pointer hover:text-blue"
                              onClick={() => onTopicClick(topic)}>
                              {get(topic, 'title')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className="regular text-12 fg-gray max-w-full"
                              noWrap
                              onClick={() => onTopicClick(topic)}>
                              {get(topic, 'description.text')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <Typography className={'bold uppercase px-8 rounded text-12 ' +
                              get(typeColors, get(topic, 'type'), '')}>
                              {get(topic, 'type')}</Typography>
                          </Grid>
                          {get(topic, 'comments', 0) > 0 &&
                            <Grid item>
                              <Grid container alignItems="center">
                                <CommentIcon className="regular text-14 fg-gray" />
                                <Typography className={'regular text-12 fg-gray ml-4'}>
                                  {get(topic, 'comments')}
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} className="border-solid border-l-1 border-gray">
                    <Grid container
                      justify="center"
                      alignItems="center"
                      spacing={1}
                      className="p-6">
                      <Grid item
                        className={classNames({
                          'uppercase bold': true,
                          'fg-blue': get(topic, 'status') === 'OPEN',
                          'fg-red': get(topic, 'status') === 'CLOSED',
                        })}>
                        {get(topic, 'status')}
                      </Grid>
                      <Grid item xs={12}>
                        {get(topic, 'status') === 'OPEN' &&
                          <LoadingButton className="btn btn_sm btn_light_red"
                            loading={requesting === get(topic, '_id')}
                            onClick={(e) => handleQuickAction(e, get(topic, '_id'), 'close')}
                            fullWidth>close</LoadingButton>
                        }
                        {get(topic, 'status') === 'CLOSED' &&
                          <LoadingButton className="btn btn_sm btn_light_green"
                            loading={requesting === get(topic, '_id')}
                            onClick={(e) => handleQuickAction(e, get(topic, '_id'), 'open')}
                            fullWidth>reopen</LoadingButton>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}
                className="bg-gray px-8 py-4 text-12 text-grey-dark">
                <label className="medium">{get(topic, 'owner.user.firstname')}</label> opened this on {formatTimestamp(get(topic, 'createdAt'), 'date-time')}</Grid>
            </Grid>
          </Grid>
        )
      })}

    </Grid>
  )
}
