import React from 'react'
import { withStyles, Grid, Icon, Button } from '@material-ui/core'
import classNames from 'classnames'

const FusePlaceHolder = ({ classes, className, title, description, icon, button, children }) => (
  <Grid container
    justify='center'
    className={classNames(
      classes.root,
      className, {
      [classes.bordered]: !children,
      'justify-center': !children
    })}>
    {!children && (
      <div className='text-center'>
        {icon &&
          <div className='flex justify-center mb-12'>
            <Icon className={classes.icon}>{icon}</Icon>
          </div>
        }

        {title &&
          <h4 className='flex justify-center semi-bold text-grey-darkest mb-20'>
            {title}
          </h4>
        }

        <p className={classes.description}>
          {description}
        </p>

        {button &&
          <Button className={
            classNames(
              'btn',
              classes.button
            )}
            onClick={button.onClick}>
            {button.text}
          </Button>
        }
      </div>
    )}
    {children}
  </Grid>
)

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
    background: '#f5f5f5',
    borderRadius: 5,
    padding: 16,
    justifyContent: 'start'
  },
  bordered: {
    background: '#f2f2f2',
    border: '1px solid #e3e3e4',
    alignItems: 'center'
  },
  description: {
    fontFamily: 'IBMPlexSans',
    color: '#bdbdbd',
    fontStyle: 'italic',
    marginBottom: 24
  },
  button: {
    display: 'inline-flex !important',
    width: 96
  },
  icon: {
    color: '#bdbdbd',
    fontSize: '60px !important'
  }
})

export default withStyles(styles)(FusePlaceHolder)
