import React, { useState, useEffect } from 'react'
import { FuseDialog, FuseDialogHeader, FuseDialogBody } from './'
import { withStyles, Grid, Button } from '@material-ui/core'
import AutoComplete from './Inputs/AutoComplete'
import { fileUploadInfo } from "../localization"
import { get, compact } from 'lodash'

const CombinedDocsDialog = ({ open, allowedDocumentTypes, docType, onConfirm, handleClose }) => {
  const [documentTypesOptions, setDocumentTypesOptions] = useState([])
  const [documentTypes, setDocumentTypes] = useState([])
  const multiSelectStyle = {
    control: () => ({
      display: 'flex',
      border: '1px solid #949494',
      borderRadius: '2px',
      padding: '10px',
      minHeight: '31px',
      maxHeight: 'auto',
      marginTop: '15px',
    }),
    placeholder: (styles) => ({
      ...styles,
      marginLeft: 0,
      marginRight: 0,
    }),
    dropdownIndicator: (styles) => ({
      ...styles
    }),
    multiValue: (styles) => ({
      ...styles,
      display: 'flex'
    })
  }

  useEffect(() => {
    setDocumentTypes([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setDocumentTypesOptions(compact(allowedDocumentTypes
      .map(type => {
        if (type !== docType) {
          return {
            value: type,
            label: get(fileUploadInfo, type),
          }
        } else {
          return false
        }
      })
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedDocumentTypes])

  return (
    <FuseDialog open={open} wSM>
      <FuseDialogHeader text="Combined Documents in this file"
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container alignItems="center" className="p-20">
          <Grid item className="text-14 semi-bold mb-4">
            Select Document Types</Grid>
          <Grid item className="text-12 fg-gray regular mb-12">
            <label className="mr-4">Combined document types for</label>
            <label className="bg-gray fg-dark-gray px-4 rounded-2">
              {get(fileUploadInfo, docType)}
            </label>
          </Grid>
          <Grid item xs={12}>
            <AutoComplete
              options={documentTypesOptions}
              value={documentTypes}
              onChange={documentTypes => {
                setDocumentTypes(documentTypes)
              }}
              placeholder="Select document types..."
              customStyles={multiSelectStyle}
              isMulti
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container
              spacing={2}
              className="pt-20">
              <Grid item xs={6}>
                <Button className="btn btn_sm btn_green"
                  onClick={() => {
                    onConfirm(docType, documentTypes)
                  }}
                  disabled={documentTypes.length === 0}
                  fullWidth>Confirm</Button>
              </Grid>
              <Grid item xs={6}>
                <Button className="btn btn_sm btn_light_red"
                  onClick={handleClose}
                  fullWidth>Close</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

const styles = theme => ({
  delete: {
    padding: 30
  },
  deleteRow: {
    textAlign: 'center',
    margin: 5
  },
  button: {
    color: '#fff',
    backgroundColor: '#cc1f1a'
  },
  icon: { fontSize: 75 }
})
export default withStyles(styles)(CombinedDocsDialog)
