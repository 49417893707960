import React, { useState } from 'react'
import {
  withStyles,
  Grid,
  Icon,
  Fade
} from '@material-ui/core'
import { LoadingButton, InfoToolTip } from '../../core/components'
import { get, find } from 'lodash'
import classNames from 'classnames'
import ManagePlaceSidebar from './ManagePlaceSidebar'
import DeletePlaceConfirm from './DeletePlaceConfirm'

const styles = {
  notificationBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 105,
    height: 30,
    borderRadius: 15,
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: 'IBMPlexSans-Bold',
    cursor: 'pointer',
    lineHeight: 1.1
  },
  btnGreenBorder: {
    color: '#3DCD58 !important',
    border: '1px solid #3DCD58 !important',
    backgroundColor: 'transparent !important',

  },
  btnGreenBorderDashed: {
    color: '#3DCD58 !important',
    backgroundColor: 'rgba(61, 205, 88, 0.2) !important',
    border: '1px dashed #3DCD58 !important',
  }
}

const BookingInfo = props => {
  const {
    classes,
    bookingResponse,
    getPlaces,
    places,
    addPlaces,
    updatePlaces,
    deletePlaces,
    currentPlant
  } = props
  const [managePlaceSidebar, setManagePlaceSidebar] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [deletePlaceConfirm, setDeletePlaceConfirm] = useState(false)
  const placeTypeOptions = {
    shipper: [{
      value: 'truck-in',
      label: 'Truck in place'
    }],
    obl: [{
      value: 'container-yard',
      label: 'Container yard'
    }, {
      value: 'storage-before-warehouse',
      label: 'Storage before warehouse'
    }, {
      value: 'storage-before-port',
      label: 'Storage before port'
    }, {
      value: 'storage-before-cfs',
      label: 'Storage before CFS'
    }],
    ofp: [{
      value: 'container-yard',
      label: 'Container yard'
    }, {
      value: 'cfs',
      label: 'CFS'
    }]
  }

  const handleDefaultPlace = (type, id) => {
    const defaultPlace = find(get(places, 'data', []), item => (
      get(item, '_id') !== id &&
      get(item, 'value.type') === type &&
      get(item, 'value.isDefault') === true
    ))
    if (defaultPlace) {
      const defaultPlaceParams = {
        key: 'places',
        value: {
          ...get(defaultPlace, 'value', {}),
          isDefault: false
        }
      }
      updatePlaces(defaultPlace._id, defaultPlaceParams, () => {
        getPlaces(true)
      })
    }
  }

  const handleAddPlace = (payload) => {
    const params = {
      key: 'places',
      value: payload
    }

    setSubmitting('places')
    if (get(payload, 'isDefault') === true) {
      handleDefaultPlace(get(payload, 'type'))
    }
    addPlaces('places', params,
      () => {
        getPlaces(true)
        setSubmitting(false)
        setManagePlaceSidebar(false)
      },
      () => {
        setSubmitting(false)
      }
    )
  }

  const handleUpdatePlace = (id, payload) => {
    const params = {
      key: 'places',
      value: payload
    }

    setSubmitting('places')
    if (get(payload, 'isDefault') === true) {
      handleDefaultPlace(get(payload, 'type'), id)
    }
    updatePlaces(id, params,
      () => {
        getPlaces(true)
        setSubmitting(false)
        setManagePlaceSidebar(false)
      },
      () => {
        setSubmitting(false)
      }
    )
  }

  const handleDeletePlace = (id) => {
    deletePlaces(id, 'places', () => {
      getPlaces(true)
    })
  }

  return (
    <Grid container className="p-20 regular">
      {get(currentPlant, 'type') === 'shipper' &&
        /* Booking response */
        <Grid item xs={12} className="py-8 hidden">
          <Grid container alignItems="center" className="pb-12">
            <Grid item xs={12}>
              <Grid container
                justify="space-between"
                alignItems="center"
                className="pb-12">
                <Grid item xs={12} sm={12} md={6}
                  className="bold text-16">
                  Booking response</Grid>
                <Grid item xs={12} sm={12} md={6}
                  className="text-14 fg-gray text-right">
                  Set a booking response time for partners...</Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="rounded-6 shadow bg-white">
              <Grid container alignItems="center">
                {/* Freight partner */}
                <Grid item xs={12} sm={12} md={6}
                  className="p-16 border-dashed md:border-r-1 md:border-b-0 border-r-0 border-b-1 border-gray">
                  <Grid container
                    alignItems="center"
                    justify="space-between">
                    <Grid item xs={8}>
                      <Grid container
                        alignItems="center">
                        <Grid item xs={2}>
                          <Grid container
                            alignItems="center"
                            justify="center"
                            className="bg-gray w-40 h-40 rounded-20 p-8 fg-gray">
                            <Icon className="fas fa-ship text-20 w-auto h-auto" />
                          </Grid>
                        </Grid>
                        <Grid item xs={10} className="text-14">
                          <Grid container>
                            <Grid item xs={12} className="bold">
                              Freight partner</Grid>
                            {get(bookingResponse, 'ofp') &&
                              <Fade in={get(bookingResponse, 'ofp')}>
                                <Grid item xs={12}>
                                  <Grid container
                                    alignItems="center"
                                    spacing={1}>
                                    <Grid item className="medium fg-dark-gray">
                                      {get(bookingResponse, 'ofp')}
                                    </Grid>
                                    <Grid item>
                                      <InfoToolTip title="After this time we will highlight the shipment for partners & shipper can remind them again">
                                        <Icon className="fg-gray text-14">info</Icon>
                                      </InfoToolTip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Fade>
                            }
                            {!get(bookingResponse, 'ofp') &&
                              <Fade in={!get(bookingResponse, 'ofp')}>
                                <Grid item xs={12} className="medium fg-gray">
                                  Set a booking response time for freight partner...</Grid>
                              </Fade>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container
                        alignItems="center"
                        justify="flex-end">
                        {!get(bookingResponse, 'ofp') &&
                          <Fade in={!get(bookingResponse, 'ofp')}>
                            <LoadingButton className={classNames(classes.notificationBtn, classes.btnGreenBorderDashed)}
                              loading={false}
                              onClick={() => { }}>
                              add</LoadingButton>
                          </Fade>
                        }
                        {get(bookingResponse, 'ofp') &&
                          <Fade in={get(bookingResponse, 'ofp')}>
                            <Grid item>
                              <img src="assets/images/icons/Edit-Pen@2x.png"
                                height={20}
                                alt="edit" />
                              <img src="assets/images/icons/Delete-Bin@2x.png"
                                height={20}
                                className="ml-12"
                                alt="delete" />
                            </Grid>
                          </Fade>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* End of Freight partner */}

                {/* Logistic partner */}
                <Grid item xs={12} sm={12} md={6}
                  className="p-16">
                  <Grid container
                    alignItems="center"
                    justify="space-between">
                    <Grid item xs={8}>
                      <Grid container
                        alignItems="center">
                        <Grid item xs={2}>
                          <Grid container
                            alignItems="center"
                            justify="center"
                            className="bg-gray w-40 h-40 rounded-20 p-8 fg-gray">
                            <Icon className="fas fa-bus text-20 w-auto h-auto" />
                          </Grid>
                        </Grid>
                        <Grid item xs={10} className="text-14">
                          <Grid container>
                            <Grid item xs={12} className="bold">
                              Logistic partner</Grid>
                            {get(bookingResponse, 'obl') &&
                              <Fade in={get(bookingResponse, 'obl')}>
                                <Grid item xs={12}>
                                  <Grid container
                                    alignItems="center"
                                    spacing={1}>
                                    <Grid item className="medium fg-dark-gray">
                                      {get(bookingResponse, 'obl')}
                                    </Grid>
                                    <Grid item>
                                      <InfoToolTip title="After this time we will highlight the shipment for partners & shipper can remind them again">
                                        <Icon className="fg-gray text-14">info</Icon>
                                      </InfoToolTip>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Fade>
                            }
                            {!get(bookingResponse, 'obl') &&
                              <Fade in={!get(bookingResponse, 'obl')}>
                                <Grid item xs={12} className="medium fg-gray">
                                  Set a booking response time for logistic partner...</Grid>
                              </Fade>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container
                        alignItems="center"
                        justify="flex-end">
                        {!get(bookingResponse, 'obl') &&
                          <Fade in={!get(bookingResponse, 'obl')}>
                            <LoadingButton className={classNames(classes.notificationBtn, classes.btnGreenBorderDashed)}
                              loading={false}
                              onClick={() => { }}>
                              add</LoadingButton>
                          </Fade>
                        }
                        {get(bookingResponse, 'obl') &&
                          <Fade in={get(bookingResponse, 'obl')}>
                            <Grid item>
                              <img src="assets/images/icons/Edit-Pen@2x.png"
                                height={20}
                                alt="edit" />
                              <img src="assets/images/icons/Delete-Bin@2x.png"
                                height={20}
                                className="ml-12"
                                alt="delete" />
                            </Grid>
                          </Fade>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {/* End of Logistic partner */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        /* End of Booking response */
      }

      {/* Saved addresses */}
      <Grid item xs={12} className="py-8">
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container
              justify="space-between"
              alignItems="center"
              className="pb-12">
              <Grid item xs={12} sm={12} md={6}
                className="bold text-16">
                Saved addresses</Grid>
              <Grid item xs={12} sm={12} md={6}
                className="text-14 fg-gray text-right">
                Save address for filling up quickly in the booking form...</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}
            className="rounded-6 shadow bg-white px-20">
            {get(places, 'data', []).map((place, placeIndex) => {
              return (
                <Grid container key={placeIndex}
                  className="text-14">
                  <Grid item className="py-20 pr-16">
                    <img src="assets/images/icons/warehouse@2x.png"
                      height={20}
                      alt="home" />
                  </Grid>
                  <Grid item xs
                    className={'border-dashed border-gray py-20'
                      + (placeIndex > 0 ? ' border-t-1' : '')
                    }>
                    <Grid container>
                      <Grid item xs={12}
                        className="pb-6">
                        <Grid container
                          justify="space-between"
                          alignItems="center"
                          spacing={1}>
                          <Grid item>
                            <label className="medium">{get(place, 'value.name')}</label>
                            <label className="mx-8 fg-gray">&bull;</label>
                            <label className="fg-gray">{get(
                              find(
                                placeTypeOptions[currentPlant.type],
                                { value: get(place, 'value.type') }
                              ),
                              'label'
                            )}</label>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center">
                              {get(place, 'value.isDefault') &&
                                <Grid item className="pr-32">
                                  <Grid container alignItems="center">
                                    <Icon className="fg-green fas fa-check text-12" />
                                    <label className="fg-green ml-6 uppercase text-10 bold">
                                      {`default ${get(place, 'value.type').replace(/-+/g, ' ')}`}
                                    </label>
                                  </Grid>
                                </Grid>
                              }
                              <img src="assets/images/icons/Edit-Pen@2x.png"
                                height={20}
                                className="gray-actions"
                                onClick={() => setManagePlaceSidebar({
                                  open: true,
                                  title: 'Edit address',
                                  existingPlace: place
                                })}
                                alt="edit" />
                              <img src="assets/images/icons/remove-icon.svg"
                                height={20}
                                className="gray-actions ml-16"
                                onClick={() => setDeletePlaceConfirm({
                                  open: true,
                                  place
                                })}
                                alt="delete" />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={10}
                        className="medium">
                        {get(place, 'value.address.street')}
                      </Grid>
                      <Grid item xs={10}
                        className="regular italic fg-gray">
                        {get(place, 'value.additionalAddress')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}

            <Grid container
              className="text-14">
              <Grid item xs={12}
                className={'py-20 border-dashed border-gray'
                  + (get(places, 'data', []).length > 0 ? ' border-t-1' : '')
                }>
                <label className="fg-blue medium cursor-pointer"
                  onClick={() => setManagePlaceSidebar({
                    open: true,
                    title: 'Add new address',
                  })}>+&nbsp;&nbsp;Add{`${get(places, 'data', []).length > 0 ? ' more' : ' place'}`}</label>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* End of Saved addresses */}

      {/* Sidebars */}
      <ManagePlaceSidebar open={get(managePlaceSidebar, 'open', false)}
        placeTypeOptions={get(placeTypeOptions, get(currentPlant, 'type'), [])}
        title={get(managePlaceSidebar, 'title', '')}
        existingPlace={get(managePlaceSidebar, 'existingPlace')}
        submitting={submitting}
        onSubmit={(payload, mode) => {
          if (mode === 'adding') {
            handleAddPlace(payload)
          } else {
            handleUpdatePlace(mode._id, payload)
          }
        }}
        handleClose={() => setManagePlaceSidebar(false)} />
      {/* End of Sidebars */}

      {/* Dialogs */}
      <DeletePlaceConfirm open={get(deletePlaceConfirm, 'open', false)}
        deletePlace={get(deletePlaceConfirm, 'place')}
        handleDelete={place => handleDeletePlace(get(place, '_id'))}
        handleDeleteCancel={() => setDeletePlaceConfirm(false)} />
      {/* End of Dialogs */}
    </Grid>
  )
}

export default withStyles(styles)(BookingInfo)
