import React, { Fragment, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid } from '@material-ui/core'
import { union, get, pick, find, filter, uniq } from 'lodash'
import SubToolBarLayout from '../../core/containers/Layout/SubToolBarLayout'
import { FuseIconButton, ShowLogDialog, CSOutboundsSidebar } from '../../core/components'
import { useMergeState, useEffect, startSearching, stopSearching } from '../../core/helpers'
import { getGroupedOutbounds, getDisplayShipments } from '../store/selectors'
import BookingTable from '../components/booking-table/BookingTable'
import OutBoundTable from '../components/outbound-table/OutBoundTable'
import ToolBar from '../components/ToolBar'
import UploadDialog from '../components/FileUploadDialog'
import NewBoookingDialog from '../components/NewBoookingDialog'
import ExistingShipmentListSidebar from '../components/ExistingShipmentListSidebar'
import * as CoreActions from '../../core/store/actions'
import * as BookingActions from '../store/actions'
import CreateMultiShipToPartConfirm from '../components/CreateMultiShipToPartConfirm'
import CloseOutboundConfirm from '../components/CloseOutboundConfirm'
import BookingInfoDialog from '../components/BookingInfoDialog'

const INITIAL_STATE = {
  uploadOpen: false,
  logOpen: false,
  bookingOpen: false,
  addToExistingOpen: false,
  outboundsOpen: false,
  outboundIds: [],
  shipmentOutboundIds: [],
  outboundItems: {},
  outboundFullItems: [],
  outbounds: [],
  airOutbounds: [],
  cbtOutbounds: [],
  railOutbounds: [],
  otherOutbounds: [],
  outboundsSidebar: {
    open: false,
    outbounds: [],
    consolidations: []
  },
  shipments: [],
  airShipments: [],
  shipmentId: null,
  bookingMode: 'new',
  createMultiShipToParty: null
}

const ManageBookingPage = props => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const [requesting, setRequesting] = useState(false)
  const [search, setSearch] = useState('')
  const [csCodes, setCsCodes] = useState('')
  const [shipToParty, setShipToParty] = useState('')
  const [date, setDate] = useState(null)
  const [bookingSearch, setBookingSearch] = useState('')
  const [closeOutboundConfirm, setCloseOutboundConfirm] = useState(false)
  const [currentOutboundTab, setCurrentOutboundTab] = useState('')
  const [bookingInfoDialog, setBookingInfoDialog] = useState(false)
  const [bookingTransportMethod, setBookingTransportMethod] = useState('')
  const [bookingLocationId, setBookingLocationId] = useState(undefined)
  const [currentShipmentTab, setCurrentShipmentTab] = useState('')

  const outboundPagination = useRef({ pageNo: 1 })
  const airOutboundPagination = useRef({ pageNo: 1 })
  const cbtOutboundPagination = useRef({ pageNo: 1 })
  const railOutboundPagination = useRef({ pageNo: 1 })
  const otherOutboundPagination = useRef({ pageNo: 1 })
  const bookingPagination = useRef({ pageNo: 1 })
  const airBookingPagination = useRef({ pageNo: 1 })

  const onOutboundsLoadMore = () => {
    outboundPagination.current.pageNo += 1
    getOutbounds(false, search, date, csCodes, shipToParty)
  }
  const onAirOutboundsLoadMore = () => {
    airOutboundPagination.current.pageNo += 1
    getAirOutbounds(false, search, date, csCodes, shipToParty)
  }
  const onCbtOutboundsLoadMore = () => {
    cbtOutboundPagination.current.pageNo += 1
    getCbtOutbounds(false, search, date, csCodes, shipToParty)
  }
  const onRailOutboundsLoadMore = () => {
    railOutboundPagination.current.pageNo += 1
    getRailOutbounds(false, search, date, csCodes, shipToParty)
  }
  const onOtherOutboundsLoadMore = () => {
    otherOutboundPagination.current.pageNo += 1
    getOtherOutbounds(false, search, date, csCodes, shipToParty)
  }

  const onBookingLoadMore = () => {
    bookingPagination.current.pageNo += 1
    getShipments(false)
  }

  const onAirBookingLoadMore = () => {
    airBookingPagination.current.pageNo += 1
    getAirShipments(false)
  }

  useEffect(() => {
    const { getPlants } = props

    getShipments()
    getAirShipments()
    getOutbounds()
    getAirOutbounds()
    getCbtOutbounds()
    getRailOutbounds()
    getOtherOutbounds()
    getPlants()

    return () => {
      clearOutboundSelection()
      clearShipmentOutboundSelection()
      setCurrentOutboundTab('')
      setCurrentShipmentTab('')
      props.hideMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setState({
      outbounds: getGroupedOutbounds(props.outbounds)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.outbounds])

  useEffect(() => {
    setState({
      airOutbounds: getGroupedOutbounds(props.airOutbounds)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.airOutbounds])

  useEffect(() => {
    setState({
      cbtOutbounds: getGroupedOutbounds(props.cbtOutbounds)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cbtOutbounds])

  useEffect(() => {
    setState({
      railOutbounds: getGroupedOutbounds(props.railOutbounds)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.railOutbounds])

  useEffect(() => {
    setState({
      otherOutbounds: getGroupedOutbounds(props.otherOutbounds)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.otherOutbounds])

  useEffect(() => {
    setState({
      shipments: getDisplayShipments(props.shipments, get(props, 'shipmentCardPrimaryId'))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shipments])

  useEffect(() => {
    setState({
      airShipments: getDisplayShipments(props.airShipments, get(props, 'shipmentCardPrimaryId'))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.airShipments])

  const openDialog = (type, status) => () => {
    setState({ [`${type}Open`]: status })

    if (type === 'log' && !status) {
      props.resetUploadLog()
    }
  }

  const openBookingDialog = async mode => {
    await setState({ bookingMode: mode })
    openDialog('booking', true)()
  }

  const onNewBookingClick = (mode = 'new', shipmentId = null, noOutboundBooking = undefined) => {
    const outboundIds = get(state, 'outboundIds', [])
    const shipmentOutboundIds = get(state, 'shipmentOutboundIds', [])
    const outboundsDelta = {
      add: outboundIds,
      remove: shipmentOutboundIds
    }

    if (noOutboundBooking === undefined && outboundIds.length === 0 && shipmentOutboundIds.length === 0) {
      props.showMessage({
        variant: 'default',
        message: 'Please select outbounds'
      })
    } else {
      if (mode === 'edit') {
        props.getShipmentDerivatives(
          {
            outboundsDelta,
            shipmentId
          },
          () => {
            openBookingDialog(mode)
            setRequesting(false)
          },
          () => {
            setRequesting(false)
          }
        )
      } else {
        props.getShipmentDerivatives(
          {
            outboundsDelta,
            noOutboundBooking,
            shipmentMode: currentOutboundTab
          },
          () => {
            setBookingLocationId(noOutboundBooking)
            openBookingDialog(mode)
            setRequesting(false)
          },
          () => {
            setRequesting(false)
          }
        )
      }
    }
  }

  const onUploadCsv = (...params) => {
    props.uploadFile(...params, null, true)
  }

  const onSelectionChange = info => {
    const outboundItems = { ...state.outboundItems }
    outboundItems[info.key] = info
    const _info = getSelectionInfo(outboundItems)

    if (_info.selectedIds.length) {
      props.showMessage({
        variant: 'default',
        noCloseButton: true,
        rounded: true,
        message: `Selected weight: ${get(_info, 'totalWeight', 0).toFixed(3)} ${_info.weightUnit}, Selected volume: ${get(
          _info,
          'totalVolume',
          0
        ).toFixed(3)} ${_info.volumeUnit}, ${_info.totalSelected} CS, ${_info.selectedIds.length} Outbounds`
      })
    } else {
      props.hideMessage()
    }

    setState({ outboundIds: _info.selectedIds, outboundItems })
  }

  const getSelectionInfo = outboundItems => {
    const result = {
      selectedIds: [],
      totalSelected: 0,
      totalWeight: 0,
      totalVolume: 0
    }
    Object.values(outboundItems).forEach(v => {
      result.selectedIds = result.selectedIds.concat(v.selectedIds)
      result.totalSelected += v.totalSelected
      result.totalWeight += v.totalWeight
      result.totalVolume += v.totalVolume
      result.weightUnit = v.weightUnit ? v.weightUnit : 'KG'
      result.volumeUnit = v.volumeUnit ? v.volumeUnit : 'M3'
    })

    return result
  }

  const clearOutboundSelection = async () => {
    const { outboundIds } = state
    if (outboundIds.length > 0) {
      await setState({
        outboundIds: [],
        outboundItems: {}
      })
      props.hideMessage()
    }
  }

  const onShipmentSelectionChange = (data, shipmentId) => {
    if (currentShipmentTab === 'air') {
      const shipments = [...state.airShipments]
      const shipment = shipments.find(v => v._id === shipmentId)
      const outbound = shipment.outbounds.find(v => v.code === data.code)

      outbound.checked = !outbound.checked

      let shipmentOutboundIds = outbound.checked
        ? state.shipmentOutboundIds.concat(data.ids)
        : state.shipmentOutboundIds.filter(v => data.ids.indexOf(v) === -1)

      if (state.shipmentId && shipmentId !== state.shipmentId) {
        shipmentOutboundIds = data.ids
        const oldShipment = shipments.find(v => v._id === state.shipmentId)
        if (oldShipment) {
          oldShipment.outbounds.forEach(v => (v.checked = false))
        }
      }
      shipmentOutboundIds = union(shipmentOutboundIds)
      setState({ shipmentOutboundIds, airShipments: shipments, shipmentId })
    } else {
      const shipments = [...state.shipments]
      const shipment = shipments.find(v => v._id === shipmentId)
      const outbound = shipment.outbounds.find(v => v.code === data.code)

      outbound.checked = !outbound.checked

      let shipmentOutboundIds = outbound.checked
        ? state.shipmentOutboundIds.concat(data.ids)
        : state.shipmentOutboundIds.filter(v => data.ids.indexOf(v) === -1)

      if (state.shipmentId && shipmentId !== state.shipmentId) {
        shipmentOutboundIds = data.ids
        const oldShipment = shipments.find(v => v._id === state.shipmentId)
        if (oldShipment) {
          oldShipment.outbounds.forEach(v => (v.checked = false))
        }
      }
      shipmentOutboundIds = union(shipmentOutboundIds)
      setState({ shipmentOutboundIds, shipments, shipmentId })
    }
  }

  const clearShipmentOutboundSelection = async () => {
    const { shipmentOutboundIds } = state
    if (shipmentOutboundIds.length > 0) {
      await setState({ shipmentOutboundIds: [] })
      props.hideMessage()
    }
  }

  const submit = (shipment, mode = 'new', callback = null) => {
    if (mode === 'new') {
      props.createShipment(shipment,
        () => {
          onSubmitSuccess()
          if (callback) {
            callback()
          }
        }, callback)
    } else {
      props.editShipment(shipment._id, shipment, mode,
        () => {
          onSubmitSuccess()
          if (callback) {
            callback()
          }
        }, callback)
    }
  }

  const onSubmitSuccess = async () => {
    await setState({
      outboundIds: [],
      shipmentOutboundIds: [],
      selectedShipment: null
    })
    switch (currentOutboundTab) {
      case 'ocean':
        getShipments()
        getOutbounds()
        break;
      case 'air':
        getAirShipments()
        getAirOutbounds()
        break;
      case 'CBT':
        getCbtOutbounds()
        break;
      case 'rail':
        getRailOutbounds()
        break;

      default:
        getOtherOutbounds()
        break;
    }

    openDialog('booking', false)()
  }

  const closeOutbounds = () => {
    if (!state.outboundIds.length) {
      props.showMessage({
        variant: 'default',
        message: 'Please select one outbound at least'
      })
      return
    }
    setRequesting('close')
    props.closeOutbounds(state.outboundIds, () => {
      setState({
        outboundIds: [],
        shipmentOutboundIds: [],
        selectedShipment: null
      })
      switch (currentOutboundTab) {
        case 'ocean':
          getOutbounds()
          break;
        case 'air':
          getAirOutbounds()
          break;
        case 'CBT':
          getCbtOutbounds()
          break;
        case 'rail':
          getRailOutbounds()
          break;

        default:
          getOtherOutbounds()
          break;
      }
      setCloseOutboundConfirm(false)
      setRequesting(false)
    })
  }

  const existingShipmentClicked = shipmentId => {
    setState({
      addToExistingOpen: false,
      selectedShipment: find(get(props, 'shipments', []), { _id: shipmentId })
    })
    onNewBookingClick('edit', shipmentId)
  }

  const onOutboundsClick = ({ outboundIds, type, shipment, CSCode, transportMethod = 'NA' }) => async () => {
    const outboundFullItems = []
    let selectedOutbound = []
    switch (transportMethod) {
      case 'ocean':
        selectedOutbound = get(props, 'outbounds', [])
        break;
      case 'air':
        selectedOutbound = get(props, 'airOutbounds', [])
        break;
      case 'CBT':
        selectedOutbound = get(props, 'cbtOutbounds', [])
        break;
      case 'rail':
        selectedOutbound = get(props, 'railOutbounds', [])
        break;

      default:
        selectedOutbound = get(props, 'otherOutbounds', [])
        break;
    }

    if (type === 'outbounds') {
      selectedOutbound.forEach(group => {
        group.outbounds.forEach(item => {
          if (outboundIds.indexOf(item._id) !== -1) {
            outboundFullItems.push(item)
          }
        })
      })
      setState({
        outboundsSidebar: {
          open: true,
          outbounds: outboundFullItems,
          type,
          consolidations: []
        }
      })
    } else {
      setState({
        outboundsSidebar: {
          open: 'loading',
          outbounds: [],
          type,
          consolidations: shipment.consolidations
        }
      })
      let outboundsByShipment = await props.getOutboundsByShipment(shipment._id)
      setState({
        outboundsSidebar: {
          open: true,
          outbounds: filter(outboundsByShipment, { meta: { CSCode } }),
          type,
          consolidations: get(shipment, 'consolidations', [])
        }
      })
    }
  }

  const getOutbounds = async (reset = false, search, date, csCodes, shipToParty) => {
    if (reset) {
      outboundPagination.current.pageNo = 1
    }
    await props.getOutbounds(outboundPagination.current, search, date, csCodes, shipToParty, 'ocean')
    clearOutboundSelection()
  }
  const getAirOutbounds = async (reset = false, search, date, csCodes, shipToParty) => {
    if (reset) {
      airOutboundPagination.current.pageNo = 1
    }
    await props.getOutbounds(airOutboundPagination.current, search, date, csCodes, shipToParty, 'air')
    clearOutboundSelection()
  }
  const getCbtOutbounds = async (reset = false, search, date, csCodes, shipToParty) => {
    if (reset) {
      cbtOutboundPagination.current.pageNo = 1
    }
    await props.getOutbounds(cbtOutboundPagination.current, search, date, csCodes, shipToParty, 'CBT')
    clearOutboundSelection()
  }
  const getRailOutbounds = async (reset = false, search, date, csCodes, shipToParty) => {
    if (reset) {
      railOutboundPagination.current.pageNo = 1
    }
    await props.getOutbounds(railOutboundPagination.current, search, date, csCodes, shipToParty, 'rail')
    clearOutboundSelection()
  }
  const getOtherOutbounds = async (reset = false, search, date, csCodes, shipToParty) => {
    if (reset) {
      otherOutboundPagination.current.pageNo = 1
    }
    await props.getOutbounds(otherOutboundPagination.current, search, date, csCodes, shipToParty, 'NA')
    clearOutboundSelection()
  }

  const getShipments = async (reset = false, bookingSearch) => {
    if (reset) {
      bookingPagination.current.pageNo = 1
    }
    await props.getShipments(bookingPagination.current, bookingSearch)
    clearShipmentOutboundSelection()
  }

  const getAirShipments = async (reset = false, bookingSearch) => {
    if (reset) {
      airBookingPagination.current.pageNo = 1
    }
    await props.getShipments(airBookingPagination.current, bookingSearch, 'air')
    clearShipmentOutboundSelection()
  }

  const createMultiShipToPartyOpen = () => {
    const { outbounds, outboundIds } = state
    let shipToParties = []

    outbounds.forEach(outbound => {
      const stp = get(outbound, 'stpCode')

      outbound.items.forEach(outboundItems => {
        outboundItems.items.forEach(outboundItemsItems => {
          if (outboundIds.includes(outboundItemsItems.outboundIds[0])) {
            shipToParties.push(stp)
          }
        })
      })
    })

    shipToParties = uniq(shipToParties).filter(Boolean)
    if (shipToParties.length > 1) {
      setState({
        createMultiShipToParty: {
          open: true,
          shipToParties
        }
      })
    } else {
      onNewBookingClick()
    }
  }

  const createMultiShipToPartyClose = () => {
    setRequesting(false)
    setState({
      createMultiShipToParty: null
    })
  }

  const createMultiShipToPartyConfirm = () => {
    setState({
      createMultiShipToParty: null
    })
    onNewBookingClick()
  }

  const handleShipmentSearch = (e) => {
    const { value } = e.target
    setBookingSearch(value)
    if (value !== bookingSearch) {
      stopSearching()
      startSearching(500, () => {
        getShipments(true, value)
        getAirShipments(true, value)
      })
    }
  }

  const handleOutboundSearch = (...args) => {
    getOutbounds(...args)
    getAirOutbounds(...args)
    getCbtOutbounds(...args)
    getRailOutbounds(...args)
    getOtherOutbounds(...args)
  }

  return (
    <Fragment>
      <SubToolBarLayout doubleRightContent={true}>
        <Grid container spacing={3}>
          <Grid item>
            <ToolBar requesting={requesting}
              {...pick(state, 'outboundIds', 'shipments', 'shipmentOutboundIds', 'search', 'csCodes', 'shipToParty')}
              isBookingDialogOpen={state.bookingOpen}
              isBookingInfoDialogOpen={bookingInfoDialog}
              onNewBookingClick={() => {
                setRequesting('new-booking')
                createMultiShipToPartyOpen()
              }}
              onAddToExistingClick={() => {
                setRequesting('add-to')
                openDialog('addToExisting', true)()
              }}
              closeOutbounds={() => {
                setCloseOutboundConfirm(true)
              }}
              removeShipmentOutbounds={() => {
                setRequesting('remove-from')
                const _id = state.shipmentId
                setState({
                  selectedShipment: find(get(props, 'shipments', []), { _id })
                })
                onNewBookingClick('edit', _id)
              }}
              onCreateBookingInfo={() => setBookingInfoDialog(true)} />
          </Grid>
          {(!props.initialLoading && state.outbounds.length > 0) &&
            <Grid item>
              <FuseIconButton img='assets/images/icons/import-header.svg'
                className='text-uppercase medium text-12'
                onClick={openDialog('upload', true)}>
                IMPORT</FuseIconButton>
            </Grid>
          }
        </Grid>
      </SubToolBarLayout>

      <div className='manage-booking h-full p-20'>
        <Grid container spacing={2}
          className='h-full'>
          {/* Open Outbounds */}
          <Grid item xs={12} sm={12} md={6}>
            <OutBoundTable outbounds={state.outbounds}
              airOutbounds={state.airOutbounds}
              cbtOutbounds={state.cbtOutbounds}
              railOutbounds={state.railOutbounds}
              otherOutbounds={state.otherOutbounds}
              outboundsTotal={props.outboundsTotal}
              airOutboundsTotal={props.airOutboundsTotal}
              cbtOutboundsTotal={props.cbtOutboundsTotal}
              railOutboundsTotal={props.railOutboundsTotal}
              otherOutboundsTotal={props.otherOutboundsTotal}
              getInitialPreps={props.getInitialPreps}
              showUploadDialog={openDialog('upload', true)}
              showLoadMore={props.showOutboundsMore}
              showAirLoadMore={props.showAirOutboundsMore}
              showCbtLoadMore={props.showCbtOutboundsMore}
              showRailLoadMore={props.showRailOutboundsMore}
              showOtherLoadMore={props.showOtherOutboundsMore}
              onLoadMore={onOutboundsLoadMore}
              onAirLoadMore={onAirOutboundsLoadMore}
              onCbtLoadMore={onCbtOutboundsLoadMore}
              onRailLoadMore={onRailOutboundsLoadMore}
              onOtherLoadMore={onOtherOutboundsLoadMore}
              onChange={onSelectionChange}
              onOutboundsClick={onOutboundsClick}
              submitSearch={handleOutboundSearch}
              outboundsLoading={props.outboundsLoading}
              outboundsLoadingMore={props.outboundsLoadingMore}
              airOutboundsLoading={props.airOutboundsLoading}
              airOutboundsLoadingMore={props.airOutboundsLoadingMore}
              cbtOutboundsLoading={props.cbtOutboundsLoading}
              cbtOutboundsLoadingMore={props.cbtOutboundsLoadingMore}
              railOutboundsLoading={props.railOutboundsLoading}
              railOutboundsLoadingMore={props.railOutboundsLoadingMore}
              otherOutboundsLoading={props.otherOutboundsLoading}
              otherOutboundsLoadingMore={props.otherOutboundsLoadingMore}
              date={date}
              setDate={setDate}
              search={search}
              setSearch={setSearch}
              csCodes={csCodes}
              setCsCodes={setCsCodes}
              shipToParty={shipToParty}
              setShipToParty={setShipToParty}
              currentOutboundTab={currentOutboundTab}
              setCurrentOutboundTab={setCurrentOutboundTab} />
          </Grid>
          {/* End of Open Outbounds */}

          {/* Bookings */}
          <Grid item xs={12} sm={12} md={6}>
            <BookingTable shipments={state.shipments}
              shipmentsTotal={props.manageShipmentsTotal}
              onLoadMore={onBookingLoadMore}
              showLoadMore={props.showShipmentsMore}
              shipmentsLoading={props.shipmentsLoading}
              shipmentsLoadingMore={props.shipmentsLoadingMore}
              airShipments={state.airShipments}
              airShipmentsTotal={props.airManageShipmentsTotal}
              onAirLoadMore={onAirBookingLoadMore}
              showAirLoadMore={props.showAirShipmentsMore}
              airShipmentsLoading={props.airShipmentsLoading}
              airShipmentsLoadingMore={props.airShipmentsLoadingMore}
              bookingSearch={bookingSearch}
              setBookingSearch={handleShipmentSearch}
              onChkClick={onShipmentSelectionChange}
              onOutboundsClick={onOutboundsClick}
              currentShipmentTab={currentShipmentTab}
              setCurrentShipmentTab={setCurrentShipmentTab} />
          </Grid>
          {/* End of Bookings */}
        </Grid>
      </div>


      <UploadDialog open={state.uploadOpen}
        upload={onUploadCsv}
        getOutbounds={() => getOutbounds(true)}
        handleClose={openDialog('upload', false)}
        handleImportScheduled={() => setState({
          logOpen: true,
          uploadOpen: false
        })} />

      <ShowLogDialog open={state.logOpen}
        data={props.logs}
        handleClose={() => {
          openDialog('log', false)()
          getOutbounds(true)
        }}
        getBatchStatus={props.getBatchStatus} />

      <NewBoookingDialog open={state.bookingOpen}
        loading={props.loading}
        data={props.initialPrep}
        mode={state.bookingMode}
        outboundIds={state.outboundIds}
        shipmentOutboundIds={state.shipmentOutboundIds}
        handleClose={() => {
          setState({
            shipmentId: null,
            selectedShipment: null
          })
          openDialog('booking', false)()
        }}
        selectedShipment={get(state, 'selectedShipment')}
        currentPlant={get(props, 'currentPlant', {})}
        plants={get(props, 'plants', [])}
        onSubmit={submit}
        selectedTransportMethod={bookingTransportMethod || currentOutboundTab}
        bookingLocationId={bookingLocationId} />

      <ExistingShipmentListSidebar open={state.addToExistingOpen}
        outbounds={get(state, 'outboundIds', [])}
        shipments={currentOutboundTab === 'air' ? get(state, 'airShipments', []) : get(state, 'shipments', [])}
        handleClose={() => {
          openDialog('addToExisting', false)()
          setRequesting(false)
        }}
        onItemClick={shipmentId => existingShipmentClicked(shipmentId)}
        onLoadMore={currentOutboundTab === 'air' ? onAirBookingLoadMore : onBookingLoadMore}
        showLoadMore={currentOutboundTab === 'air' ? props.showAirShipmentsMore : props.showShipmentsMore}
        shipmentsLoading={currentOutboundTab === 'air' ? props.airShipmentsLoading : props.shipmentsLoading}
        shipmentsLoadingMore={currentOutboundTab === 'air' ? props.airShipmentsLoadingMore : props.shipmentsLoadingMore}
        bookingSearch={bookingSearch}
        setBookingSearch={handleShipmentSearch} />

      <CSOutboundsSidebar open={state.outboundsSidebar.open}
        closeSideBar={() => setState({ outboundsSidebar: { open: false } })}
        onlyOutbounds={true}
        sideBarTitle={get(state, 'outboundsSidebar.outbounds', []).length + ' Selected outbounds'}
        outboundsList={state.outboundsSidebar.outbounds}
        consolidations={state.outboundsSidebar.consolidations}
        currentPlant={props.currentPlant} />

      <CreateMultiShipToPartConfirm open={get(state, 'createMultiShipToParty.open', false)}
        shipToParties={get(state, 'createMultiShipToParty.shipToParties', [])}
        cancelCreate={createMultiShipToPartyClose}
        confirmCreate={createMultiShipToPartyConfirm} />

      <CloseOutboundConfirm open={closeOutboundConfirm}
        deleteOutbounds={get(state, 'outboundIds', [])}
        isRequesting={requesting === 'close'}
        handleClose={closeOutbounds}
        handleCloseCancel={() => setCloseOutboundConfirm(false)} />

      <BookingInfoDialog open={bookingInfoDialog}
        onClose={() => setBookingInfoDialog(false)}
        currentPlant={props.currentPlant}
        setRequesting={setRequesting}
        setBookingTransportMethod={setBookingTransportMethod}
        onNewBookingClick={onNewBookingClick} />
    </Fragment>
  )
}

const styles = theme => ({
  layoutRoot: {},
  layoutToolbar: {
    borderBottom: 0,
    height: 50,
    minHeight: 50
  },
  layoutHeader: {
    height: 85,
    minHeight: 85,
    background: '#fff',
    backgroundSize: 'cover',
    color: 'black',
    [theme.breakpoints.down('md')]: {
      height: 140,
      minHeight: 140
    }
  },
  layoutContent: {
    background: '#F1F3F3'
  }
})

const mapStateToProps = ({
  core,
  welcome: { currentPlant },
  booking: {
    outbounds,
    airOutbounds,
    cbtOutbounds,
    railOutbounds,
    otherOutbounds,
    outboundsTotal,
    airOutboundsTotal,
    cbtOutboundsTotal,
    railOutboundsTotal,
    otherOutboundsTotal,
    initialPrep,
    loading,
    showOutboundsMore,
    showAirOutboundsMore,
    showCbtOutboundsMore,
    showRailOutboundsMore,
    showOtherOutboundsMore,
    initialLoading,
    outboundsLoading,
    outboundsLoadingMore,
    airOutboundsLoading,
    airOutboundsLoadingMore,
    cbtOutboundsLoading,
    cbtOutboundsLoadingMore,
    railOutboundsLoading,
    railOutboundsLoadingMore,
    otherOutboundsLoading,
    otherOutboundsLoadingMore,
    manageShipments,
    manageShipmentsTotal,
    showShipmentsMore,
    shipmentsLoading,
    shipmentsLoadingMore,
    airManageShipments,
    airManageShipmentsTotal,
    showAirShipmentsMore,
    airShipmentsLoading,
    airShipmentsLoadingMore
  },
}) => ({
  loading,
  currentPlant,
  initialLoading,
  outbounds,
  airOutbounds,
  cbtOutbounds,
  railOutbounds,
  otherOutbounds,
  outboundsTotal,
  airOutboundsTotal,
  cbtOutboundsTotal,
  railOutboundsTotal,
  otherOutboundsTotal,
  shipments: manageShipments,
  showOutboundsMore,
  showAirOutboundsMore,
  showCbtOutboundsMore,
  showRailOutboundsMore,
  showOtherOutboundsMore,
  showShipmentsMore,
  initialPrep,
  logs: core.upload,
  outboundsLoading,
  outboundsLoadingMore,
  airOutboundsLoading,
  airOutboundsLoadingMore,
  cbtOutboundsLoading,
  cbtOutboundsLoadingMore,
  railOutboundsLoading,
  railOutboundsLoadingMore,
  otherOutboundsLoading,
  otherOutboundsLoadingMore,
  shipmentsLoading,
  shipmentsLoadingMore,
  shipmentCardPrimaryId: get(core, 'userSettings.shipmentCardPrimaryId'),
  plants: get(core, 'plants', []),
  airShipments: airManageShipments,
  showAirShipmentsMore,
  airShipmentsLoading,
  airShipmentsLoadingMore,
  manageShipmentsTotal,
  airManageShipmentsTotal
})
const mapDispatchToProps = dispatch => ({
  uploadFile: (...params) => dispatch(CoreActions.uploadFile(...params)),
  getBatchStatus: (...params) => dispatch(CoreActions.getBatchStatus(...params)),
  resetUploadLog: () => dispatch(CoreActions.resetUploadLog()),
  showMessage: options => dispatch(CoreActions.showMessage(options)),
  hideMessage: () => dispatch(CoreActions.hideMessage()),
  getOutboundsByShipment: shipmentId => dispatch(BookingActions.getOutboundsByShipment(shipmentId)),
  getOutbounds: (...args) => dispatch(BookingActions.getOutbounds(...args)),
  getInitialPreps: (ids, callback) => dispatch(BookingActions.getInitialPreps(ids, callback)),
  getUpdatePreps: (payload, callback) => dispatch(BookingActions.getUpdatePreps(payload, callback)),
  createShipment: (...args) => dispatch(BookingActions.createShipment(...args)),
  closeOutbounds: (...args) => dispatch(BookingActions.closeOutbounds(...args)),
  getShipments: (pagination, search, transportMethod) =>
    dispatch(BookingActions.getAllShipments(undefined, 'outbounds', undefined, pagination, search, undefined, false, transportMethod)),
  editShipment: (...args) => dispatch(BookingActions.editShipment(...args)),
  getShipmentDerivatives: (...args) => dispatch(BookingActions.getShipmentDerivatives(...args)),
  getPlants: (...args) => dispatch(CoreActions.getPlants(...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageBookingPage))
