import React from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core'
import { get } from "lodash";
import Skeleton from 'react-loading-skeleton'

const ListTable = props => {
  const { topAligned } = props

  return (
    <Table className="custom-table"
      style={{ border: 'none' }}>

      {/* List Table Header */}
      <TableHead className="table-head">
        <TableRow className="table-row">
          {get(props, 'fields', []).map((field, fieldIndex) => {
            return (
              <TableCell key={fieldIndex}
                className="table-cell">
                <label className="capitalize">{get(field, 'name')}</label>
              </TableCell>
            )
          })}
        </TableRow>
      </TableHead>
      {/* End of List Table Header */}

      <TableBody className="table-body">

        {get(props, 'loading', false) === true &&
          /* List Loading */
          [1, 2, 3].map(rowItem => {
            return (
              <TableRow key={`row-${rowItem}`}
                className="table-row">
                {get(props, 'fields', []).map((colItem, colItemIndex) => {
                  return (
                    <TableCell key={colItemIndex}
                      className="table-cell">
                      <Skeleton width={100} />
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })
          /* End of List Loading */
        }

        {(get(props, 'loading', false) === false &&
          get(props, 'logs', []).length === 0) &&
          /* List loaded with no records */
          <TableRow className='table-row'>
            <TableCell colSpan={get(props, 'fields', []).length}
              className="no-records" />
          </TableRow>
          /* End of List loaded with no records */
        }

        {get(props, 'logs', []).map((logRecord, logIndex) => {
          /* List loaded with records */
          return (
            <TableRow key={logIndex}
              className="table-row">
              {get(props, 'fields', []).map((field, fieldIndex) => {
                return (
                  <TableCell key={fieldIndex}
                    className={'table-cell' + (topAligned ? ' align-top' : '')}>
                    {field.rendered(logRecord)}
                  </TableCell>
                )
              })}
            </TableRow>
          )
          /* End of List loaded with records */
        })}
      </TableBody>

    </Table>
  )
}

export default ListTable
