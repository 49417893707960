import React from 'react'
import { FuseTextField } from '../'
import { AutoComplete } from '../Inputs'
import { Grid, withStyles, InputLabel } from '@material-ui/core'
import validator from 'validator'
import { get } from 'lodash'

const countryPhoneCodes = require('../../helpers/json/countryPhoneCodes.json')

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const inputStyle = {
  fontFamily: 'IBMPlexSans',
  fontSize: 14
}

const selectStyle = {
  control: () => ({
    display: 'flex',
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid #949494',
    minHeight: '31px',
    maxHeight: '31px',
    marginTop: '0px',
    minWidth: '80px'
  }),
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  }),
}

const PersonalDetailsForm = ({ userDetails, setUserDetails, defaultLogin, classes }) => {
  return (
    <Grid container>
      <Grid item sm={12}>
        <FuseTextField
          className='mb-16'
          type='text'
          icon={{ ...baseTextSetting, name: 'eye' }}
          name='firstname'
          label='First Name'
          value={userDetails.firstname}
          onChange={e => setUserDetails({ ...userDetails, firstname: e.target.value })}
          inputStyles={inputStyle}
          disabled={!defaultLogin}
          required
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <FuseTextField
          className='mb-16'
          type='text'
          icon={{ ...baseTextSetting, name: 'eye' }}
          name='lastname'
          label='Last Name'
          value={userDetails.lastname}
          onChange={e => setUserDetails({ ...userDetails, lastname: e.target.value })}
          inputStyles={inputStyle}
          disabled={!defaultLogin}
          required
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <FuseTextField
          className='mb-16'
          type='text'
          icon={{ ...baseTextSetting, name: 'eye' }}
          name='username'
          label='User Name'
          error={userDetails.error && userDetails.error.username}
          helperText={userDetails.error && userDetails.error.username}
          value={userDetails.username}
          onChange={e => setUserDetails({ ...userDetails, username: e.target.value })}
          inputStyles={inputStyle}
          disabled={!defaultLogin}
          required
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <FuseTextField
          className='mb-16'
          type='text'
          icon={{ ...baseTextSetting, name: 'eye' }}
          name='email'
          label='Email Address'
          value={userDetails.email}
          error={userDetails.error && userDetails.error.email}
          helperText={userDetails.error && userDetails.error.email}
          onChange={e => setUserDetails({ ...userDetails, email: e.target.value })}
          inputStyles={inputStyle}
          disabled={!defaultLogin}
          required
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <InputLabel className='form-label form-label_bg'>Phone</InputLabel>
        <Grid container spacing={2}
          alignItems="center"
          className='mb-16'>
          <Grid item>
            <AutoComplete value={get(userDetails, 'phone.countryCode', '+65')}
              className="text-14"
              options={countryPhoneCodes.map(item => ({
                value: item.dial_code,
                label: item.dial_code
              }))}
              onChange={(countryCode) => setUserDetails({
                ...userDetails,
                phone: {
                  ...userDetails.phone,
                  countryCode
                }
              })}
              customStyles={selectStyle}
              isDisabled={!defaultLogin}
              showAllOptions />
          </Grid>
          <Grid item xs>
            <FuseTextField style={{ fontSize: '14px' }}
              type='text'
              name='phone'
              placeholder="Phone number"
              icon={{ ...baseTextSetting, name: 'eye' }}
              value={userDetails.phone.phoneNumber}
              onChange={e => {
                if (!validator.isNumeric(e.target.value) &&
                  e.target.value.trim() !== '') {
                  return
                }
                setUserDetails({
                  ...userDetails,
                  phone: {
                    ...userDetails.phone,
                    phoneNumber: e.target.value
                  }
                })
              }}
              inputStyles={inputStyle}
              disabled={!defaultLogin}
              required
              fullWidth />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
const styles = {
  customSelect: {
    '& > div': {
      background: 'none',
      marginBottom: '-8px'
    },
    '& > div:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important'
    },
    '& > svg': {
      marginTop: '5px'
    }
  }
}
export default withStyles(styles)(PersonalDetailsForm)
