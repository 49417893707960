import React, { useEffect, useState } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { FuseButtonToggle, FuseCheckBoxGroup } from '../../core/components'
import { routeInfoForm, general } from '../../core/localization'
import { get, filter, set, find, pick } from 'lodash'
import { useMergeState } from '../../core/helpers'
import BookingWizardSummary from '../../manage-booking/components/ProposeBookingSummary'
import AutoComplete from '../../core/components/Inputs/AutoComplete'

const INITIAL_DELIVERY_DATA = [{
  label: routeInfoForm.shipmentDeliveryMode.mode.ocean,
  value: 'ocean'
}, {
  label: routeInfoForm.shipmentDeliveryMode.mode.air,
  value: 'air',
}, {
  label: routeInfoForm.shipmentDeliveryMode.mode.cbt,
  value: 'CBT'
}]

const BookingRouteForm = props => {
  const {
    go,
    data: {
      plants,
      shipment,
      bookingForm
    },
    existingFormData,
    onChange
  } = props

  const [state, setState] = useMergeState({})
  const [validated, setValidated] = useState(true)
  const [deliveryModeData, setDeliveryModeData] = useState(INITIAL_DELIVERY_DATA)

  useEffect(() => {
    if (existingFormData) {
      setState(existingFormData)
      setValidated(checkValidation(existingFormData))
    } else {
      if (shipment) {
        onChange('routeForm', pick(shipment, [
          'shipmentMode',
          'shipper',
          'dropHub',
          'consignee',
          'goodsType',
          'transportMethod'
        ]))
      }
    }
    setDeliveryModeData(INITIAL_DELIVERY_DATA.map(item => {
      if (item.value === 'air') {
        set(item, 'disabled', (get(bookingForm, 'type') === 'FCL'))
      }
      return item
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, existingFormData])

  const shipmentTypeData = [
    { label: routeInfoForm.shipmentMode.mode.direct, value: 'direct' },
    { label: routeInfoForm.shipmentMode.mode.drop, value: 'drop' }
  ]

  const goodsTypeData = [
    {
      label: routeInfoForm.goodsDeclaration.options.dg,
      value: 'DG',
      checked: false
    },
    {
      label: routeInfoForm.goodsDeclaration.options.formd,
      value: 'FORMD',
      checked: false
    },
    {
      label: routeInfoForm.goodsDeclaration.options.formak,
      value: 'FORMAK',
      checked: false
    },
    {
      label: routeInfoForm.goodsDeclaration.options.forma,
      value: 'FORMA',
      checked: false
    },
    {
      label: routeInfoForm.goodsDeclaration.options.forme,
      value: 'FORME',
      checked: false
    },
  ]

  const checkValidation = (updatedState) => {
    const consignee = get(updatedState, 'consignee', [])
    const hasConsigneeError = find(consignee, item => {
      return (!get(item, '_id'))
    })

    if (!get(updatedState, 'shipper._id')) {
      return false
    }
    if (consignee.length === 0) {
      return false
    }
    if (hasConsigneeError) {
      return false
    }
    if (get(updatedState, 'shipmentMode') === 'drop') {
      if (!get(updatedState, 'dropHub._id')) {
        return false
      }
    }
    return true
  }

  const handleChangePlant = (_id, section = null, handleIndex = -1) => {
    var updatedState = state
    var selectedPlant = find(plants, { _id })

    if (handleIndex >= 0) {
      set(updatedState, section + '[' + handleIndex + ']', selectedPlant)
    } else {
      set(updatedState, section, selectedPlant)
    }

    onChange('routeForm', updatedState)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [name]: value
    }

    onChange('routeForm', updatedState)
  }

  const handleConsignee = (action, handleIndex = null) => {
    var updatedState = state
    if (action === 'add') {
      updatedState.consignee = [
        ...get(state, 'consignee', []),
        { isNew: true }
      ]
    } else if (action === 'remove') {
      updatedState.consignee = filter(get(
        state,
        'consignee',
        []
      ), (consignee, consigneeIndex) => {
        return handleIndex !== consigneeIndex
      })
    }

    onChange('routeForm', updatedState)
  }

  return (
    <Formik enableReinitialize
      validate={true}
      onSubmit={() => { }}>
      {({ values, errors, touched, handleBlur, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container direction='row'>
            <Grid item sm={12} md={8}
              className={'booking-forms'}>

              {/* Shipment Mode */}
              <Grid container direction='row'
                className='field-control'>
                <Grid item sm={12} md={6}
                  className='flex items-center'>
                  <label className='field-label'>
                    {routeInfoForm.shipmentMode.label}
                  </label>
                </Grid>
                <Grid item sm={12} md={6} className='flex justify-end'>
                  <FuseButtonToggle name='shipmentMode'
                    data={shipmentTypeData}
                    value={get(state, 'shipmentMode', '')}
                    buttonClass='text-12 bold'
                    onChange={(value) => handleChange({
                      target: {
                        name: 'shipmentMode',
                        value
                      }
                    })} />
                </Grid>
              </Grid>
              {/* END Shipment Mode */}

              {/* Shipment Route */}
              <Grid container direction='row' className={'field-control'}>
                <Grid item sm={12} md={6} className='flex items-center'>
                  <label className={'field-label'}>
                    {routeInfoForm.shipmentRoute.label}</label>
                </Grid>
                <Grid item sm={12} md={6} className='flex items-center justify-end'>
                  <label className={'field-instruction'}>
                    {routeInfoForm.shipmentRoute.instruction}</label>
                </Grid>
                <Grid container direction='row' className='stepper'>

                  {/* Shipment route shipper */}
                  <Grid container direction='row' className='step'>
                    <Grid container direction='row' className='step-header'>
                      <span className='step-icon'>
                        <img src="assets/images/icons/wizard-arrow-up-circle.png"
                          alt='shipper' />
                      </span>
                      <Grid item sm={12} md={6} className='flex items-center'>
                        <label className='field-sub-label'>
                          {routeInfoForm.shipmentRoute.shipper.label}</label>
                      </Grid>
                      <Grid item sm={12} md={6} className='flex items-center justify-end'>
                        <label className='field-instruction'>
                          {routeInfoForm.shipmentRoute.shipper.instruction}</label>
                      </Grid>
                    </Grid>

                    <Grid container direction='row' className='step-content'>
                      <Grid item sm={12} md={8} className='pr-16'>
                        <TextField className='theme-text-field'
                          name="name"
                          value={get(shipment, 'shipper.name', '')}
                          label={routeInfoForm.shipmentRoute.field.plantName}
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Grid>
                      <Grid item sm={12} md={4}>
                        <TextField className='theme-text-field'
                          name="location.country"
                          value={get(
                            shipment,
                            'shipper.office.address.country',
                            get(state, 'shipper.office.address.country', '')
                          )}
                          label={routeInfoForm.shipmentRoute.field.country}
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* END Shipment route shipper */}

                  {
                    get(state, 'shipmentMode') === 'drop' &&
                    /* Shipment route dropHub */
                    < Grid container direction='row' className='step'>
                      <Grid container direction='row' className='step-header'>
                        <span className='step-icon'>
                          <img src="assets/images/icons/wizard-drop-hub-circle.png"
                            alt='shipper' />
                        </span>
                        <Grid item sm={12} md={6} className='flex items-center'>
                          <label className='field-sub-label'>
                            {routeInfoForm.shipmentRoute.drophub.label}</label>
                        </Grid>
                        <Grid item sm={12} md={6} className='flex items-center justify-end'>
                          <label className='field-instruction'>
                            {routeInfoForm.shipmentRoute.drophub.instruction}</label>
                        </Grid>
                      </Grid>

                      <Grid container direction='row' className='step-content'>
                        <Grid item sm={12} md={8} className='pr-16'>
                          <label className="text-11 regular fg-gray require-field">
                            {routeInfoForm.shipmentRoute.field.plantName}
                          </label>
                          <AutoComplete value={get(state, 'dropHub._id', '')}
                            options={filter(plants, { type: 'dropHub' }).map(plant => {
                              return {
                                value: plant._id,
                                label: plant.name
                              }
                            })}
                            placeholder="Search plant..."
                            onChange={(value) => handleChangePlant(value, 'dropHub')}
                            noAutoFirstSelect
                          />
                        </Grid>
                        <Grid item sm={12} md={4}>
                          <TextField className='theme-text-field'
                            name="dropHub.office.address.country"
                            value={get(state, 'dropHub.office.address.country', '')}
                            label={routeInfoForm.shipmentRoute.field.country}
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    /* END Shipment route dropHub */
                  }

                  {/* Shipment route consignee */}
                  <Grid container direction='row' className='step'>
                    <Grid container direction='row' className='step-header'>
                      <span className='step-icon'>
                        <img src="assets/images/icons/wizard-arrow-down-circle.png"
                          alt='shipper' />
                      </span>
                      <Grid item sm={12} md={6} className='flex items-center'>
                        <label className='field-sub-label require-field'>
                          {routeInfoForm.shipmentRoute.consignee.label}</label>
                      </Grid>
                      <Grid item sm={12} md={6} className='flex items-center justify-end'>
                        <label className='field-instruction'>
                          {routeInfoForm.shipmentRoute.consignee.instruction}</label>
                      </Grid>
                    </Grid>
                    <Grid container direction='row' className='step-content'>
                      {
                        get(state, 'consignee', []).map((consigneeItem, consigneeIndex) => {
                          return (
                            <React.Fragment key={consigneeIndex}>
                              <Grid item sm={12} md={8} className='pr-16 pb-16'>
                                <label className="text-11 regular fg-gray require-field">
                                  {routeInfoForm.shipmentRoute.field.plantName + " " + (consigneeIndex + 1)}
                                </label>
                                <AutoComplete value={get(consigneeItem, '_id', '')}
                                  options={filter(plants, { type: 'consignee' }).map(plant => {
                                    return {
                                      value: plant._id,
                                      label: plant.name
                                    }
                                  })}
                                  placeholder="Search plant..."
                                  onChange={(value) => handleChangePlant(value, 'consignee', consigneeIndex)}
                                  noAutoFirstSelect
                                />
                              </Grid>
                              <Grid item sm={12} md={4} className='pb-16'>
                                {
                                  get(state, 'consignee', []).length === 1 &&
                                  <TextField className='theme-text-field'
                                    name="consignee.office.address.country"
                                    value={get(consigneeItem, 'office.address.country', '')}
                                    label={routeInfoForm.shipmentRoute.field.country}
                                    fullWidth={true}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                  />
                                }

                                {
                                  get(state, 'consignee', []).length > 1 &&
                                  <Grid container
                                    alignItems="center"
                                    spacing={1}>
                                    <Grid item xs={10}>
                                      <TextField className='theme-text-field'
                                        name="country"
                                        value={get(consigneeItem, 'office.address.country', '')}
                                        label={routeInfoForm.shipmentRoute.field.country}
                                        fullWidth={true}
                                        InputLabelProps={{ shrink: true }}
                                        disabled
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Grid container
                                        alignItems="center"
                                        justify="flex-end">
                                        <img src="assets/images/icons/remove-icon.svg"
                                          className="cursor-pointer"
                                          onClick={() => handleConsignee('remove', consigneeIndex)}
                                          alt="delete" />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                }

                              </Grid>
                            </React.Fragment>
                          )
                        })
                      }
                      <Grid item xs={12}>
                        <label className="medium fg-blue cursor-pointer"
                          onClick={() => handleConsignee('add')}>
                          +&nbsp;&nbsp;Add more</label>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* END Shipment route consignee */}

                </Grid>
              </Grid>
              {/* END Shipment Route */}

              {/* Goods Declaration */}
              <Grid container direction='row'
                className='field-control'>
                <Grid item sm={12} md={12}
                  className='flex items-center'>
                  <label className='field-label medium'>
                    {routeInfoForm.goodsDeclaration.label}
                  </label>
                </Grid>
                <Grid item sm={12} md={12}
                  className='flex items-center'>
                  <FuseCheckBoxGroup name="goodsType"
                    data={goodsTypeData}
                    value={get(state, 'goodsType', []).map(item => item.toUpperCase())}
                    className='flex-3 flex-row pl-4 pt-8'
                    chkClassName='text-14 text-grey-darkest'
                    onChange={(value, currentEvent) => handleChange({
                      target: {
                        ...currentEvent,
                        value
                      }
                    })} />
                </Grid>
              </Grid>
              {/* END Goods Declaration */}

              {/* Shipment Delivery Mode */}
              <Grid container direction='row'
                className='field-control'>
                <Grid item sm={12} md={6}
                  className='flex items-center'>
                  <label className='field-label'>
                    {routeInfoForm.shipmentDeliveryMode.label}
                  </label>
                </Grid>
                <Grid item sm={12} md={6}
                  className='flex items-center justify-end'>
                  <FuseButtonToggle name='transportMethod'
                    data={deliveryModeData}
                    value={get(state, 'transportMethod', '')}
                    buttonClass='text-12 bold'
                    onChange={(value) => handleChange({
                      target: {
                        name: 'transportMethod',
                        value
                      }
                    })} />
                </Grid>
              </Grid>
              {/* END Shipment Delivery Mode */}

              {/* Form Actions */}
              <Grid container
                className="pt-20">
                <Grid item sm={12} md={6}>
                  <Button className='btn w-full'
                    disabled={!validated}
                    onClick={() => go(1)}>
                    {general.next}
                  </Button>
                </Grid>

                <Grid item sm={12} md={6}>
                  <Grid container justify="flex-end">
                    <Button className={'btn btn_transparent'}
                      onClick={() => go(-1)}>Back</Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* END Form Actions */}

            </Grid>
            <Grid item sm={12} md={4}>
              <BookingWizardSummary shipment={{ ...shipment, ...bookingForm, ...state }} />
            </Grid>
          </Grid>
        </Form>
      )
      }
    </Formik >
  )
}

export default BookingRouteForm
