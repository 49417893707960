import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Grid, CircularProgress } from '@material-ui/core'
import queryString from 'query-string'
import { Unauthenticated } from 'core/components'
import * as AuthActions from '../../auth/store/actions'
import * as WelcomeActions from '../../welcome/store/actions'
import * as CoreActions from '../../core/store/actions'
import * as API from '../api'
import { get, find } from 'lodash'
import history from '../../history'

const LoginPage = props => {
  const {
    auth,
    location: { search },
    logout,
    login,
    setCurrentPlant,
    showMessage
  } = props
  const searchValues = queryString.parse(search)
  const plants = get(auth, 'info.plants', [])

  useEffect(() => {
    if (searchValues) {
      const email = get(searchValues, 'u')
      const password = get(searchValues, 'p')

      if (auth.info) {
        if (auth.info.plants) {
          const plantId = get(searchValues, 'pl')
          const redirectURI = get(searchValues, 'redirect_uri')
          const requestedPlant = find(plants, { _id: plantId })

          if (requestedPlant) {
            setCurrentPlant(requestedPlant)
            API.setContextPlantHeader(requestedPlant._id)
            history.push(redirectURI)
          } else {
            showMessage({
              variant: 'error',
              message: `User don't have access to the plant`
            })
            logout(async () => {
              window.location.href = '/'
            })
          }
        }
      } else {
        if (email && password) {
          loginUser()
        } else {
          showMessage({
            variant: 'error',
            message: `URL doesn't have don't have access to the plant`
          })
          window.location.href = '/'
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [1])

  const loginUser = async () => {
    const plantId = get(searchValues, 'pl')
    const redirectURI = get(searchValues, 'redirect_uri')
    const email = get(searchValues, 'u')
    const password = get(searchValues, 'p')
    const autoLogin = true
    const loginObject = { email, password, autoLogin }

    if (auth.info) {
      await logout(async () => {
        await login(loginObject)
      })
    } else {
      await login(loginObject)
    }
    history.push(`/auto-login?pl=${plantId}&redirect_uri=${redirectURI}`)
  }

  return (
    <Grid container className="m-h-full m-w-full"
      justify="center"
      alignItems="center">
      <Grid item>
        <CircularProgress className="text-40 fg-green" />
      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({ auth }) => ({
  auth
})

const mapDispatchToProps = dispatch => ({
  login: (payload) => dispatch(AuthActions.login(payload)),
  logout: (...args) => dispatch(AuthActions.logout(...args)),
  setCurrentPlant: (plant) => dispatch(WelcomeActions.setCurrentPlant(plant)),
  showMessage: (payload) => dispatch(CoreActions.showMessage(payload)),
})

export default compose(Unauthenticated, connect(mapStateToProps, mapDispatchToProps))(LoginPage)
