import React, { useEffect } from 'react'
import { get, find } from 'lodash'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import {
  zipFiles,
  useMergeState,
  formatTimestamp,
  pretiffyPlantType
} from '../../core/helpers'
import {
  getShipmentDocument
} from '../../core/api'
import DocumentsListSidebar from './DocumentsListSidebar'
import {
  FusePlaceHolder,
  FuseIconButton,
  InfoToolTip
} from '../../core/components'
import ChatDocuments from './ChatDocuments'
import fileDownload from 'js-file-download'
import { fileUploadInfo } from '../../core/localization'

const INITIAL_STATE = {
  openDocumentsListSidebar: false,
  documentsList: [],
  documentSidebarTitle: '',
  hasWriteAccess: false,
  downloading: [],
  canUploadDocs: true
}
const ShipmentDocuments = ({
  shipmentId,
  shipmentName,
  shipmentStatus,
  shipmentCurrentStage,
  documents,
  chatDocuments,
  onUploadClick,
  onSingleFileDelete,
  currentPlant,
  hasWritableDocs,
  showMessage,
  getDocuments,
  shipmentActors,
  falconConfig }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const uploadStatus = {
    ofp: 'freight-partner-confirmed',
    obl: 'obl-confirmed',
  }

  useEffect(() => {
    const currentListType = get(state, 'documentsList[0].type')
    const currentListTypeDocs = find(documents, { type: currentListType })
    const currentStatus = get(shipmentStatus, 'current.value')
    const previousStatus = get(shipmentStatus, 'previous', []).map(item => item.value)
    var canUploadDocs = {
      enabled: hasWritableDocs,
      reason: !hasWritableDocs ? `You can not upload any documents to this shipment` : ''
    }
    const role = get(currentPlant, 'type')
    if (shipmentCurrentStage === 'scheduled') {
      if (Object.keys(uploadStatus).includes(role)) {
        if (currentStatus !== get(uploadStatus, role) &&
          !previousStatus.includes(get(uploadStatus, role))) {
          canUploadDocs = {
            enabled: false,
            reason: `Documents can be uploaded only after accepting this shipment`
          }
        }
      }
    }

    setState({
      documentsList: get(currentListTypeDocs, 'documents', []),
      canUploadDocs
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, currentPlant, shipmentStatus])

  const onViewDocumentsOpen = doc => {
    const documentsList = get(doc, 'documents', [])
    const sideBarTitle = get(doc, 'type', 'Documents')

    setState({
      openDocumentsListSidebar: true,
      documentsList: documentsList,
      documentSidebarTitle: sideBarTitle,
      hasWriteAccess: get(doc, 'writeAccess', false)
    })
  }

  const onViewDocumentsClose = () => {
    setState({
      openDocumentsListSidebar: false
    })
  }

  const downloadSingleFile = async (document, chat = false) => {
    let data = {}
    data = await getShipmentDocument(document.shipment, document._id)
    if (get(data, 'status') === 200) {
      fileDownload(data.data, `${!chat ? document.type : 'chat'}_${document.originalName}`)
    } else {
      showMessage({
        variant: 'error',
        message: get(data, 'message')
      })
    }
  }

  const viewSingleFile = async (document, chat = false) => {
    let data = {}
    data = await getShipmentDocument(document.shipment, document._id)
    if (get(data, 'status') === 200) {
      var file = new Blob([data.data], { type: data.headers["content-type"] })
      var fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } else {
      showMessage({
        variant: 'error',
        message: get(data, 'message')
      })
    }
  }

  const deleteSingleFile = async document => {
    onSingleFileDelete(shipmentId, document._id)
  }

  const downloadFiles = async (item, index) => {
    setState({ downloading: [...state.downloading, index] })
    const files = {}

    for (let i = 0; i < item.documents.length; i++) {
      const document = item.documents[i]
      const data = await getShipmentDocument(document.shipment, document._id)

      if (get(data, 'status') === 200) {
        files[`${document.type}_${i + 1}_${document.originalName}`] = get(data, 'data')
      }
    }

    await zipFiles(files, null, shipmentName)
    setState({ downloading: state.downloading.filter(i => i !== index) })
  }

  const getUploadedByHoverText = uploadedBy => {
    const user = get(uploadedBy, 'user.firstname', '') + ' ' + get(uploadedBy, 'user.lastname', '')
    const plant = get(uploadedBy, 'plant.name')

    return `Uploaded by ${user} from ${plant}`
  }
  return (
    <Grid container>
      {(get(state, 'canUploadDocs.enabled') &&
        (documents && documents.length > 0)) &&
        <Grid item xs={12} className='shipment-details-container-section'>
          <Grid container>
            <Grid item className='flex justify-between w-full'>
              <Typography className='section__title bold dark'>
                Upload new document</Typography>

              <FuseIconButton className='btn btn_sm text-uppercase'
                icon="publish"
                onClick={onUploadClick}>
                Upload</FuseIconButton>
            </Grid>
          </Grid>
        </Grid>
      }

      {!get(state, 'canUploadDocs.enabled') &&
        <Grid item xs={12}
          className="p-20">
          <FusePlaceHolder className='flex flex-col'
            icon='error'
            title={get(state, 'canUploadDocs.reason')} />
        </Grid>
      }

      {(get(state, 'canUploadDocs.enabled') &&
        (!documents || documents.length === 0)) &&
        <Grid item xs={12}
          className="p-20">
          <FusePlaceHolder className='flex flex-col'
            icon='insert_drive_file'
            title='Upload documents'
            description={'Looks like there are no documents available at the moment. Please check back later.'}
            button={{
              text: 'Upload',
              onClick: onUploadClick
            }} />
        </Grid>
      }

      {documents && documents.length > 0 && (
        <Grid item xs={12}
          className='shipment-details-container-section'>
          <Grid container
            justify='space-between'
            className='mb-20'>
            <Grid item>
              <Typography className='section__title bold'>
                Uploaded</Typography>
            </Grid>
            <Grid item className='hidden'>
              <Grid container justify='flex-end'>
                <Grid item>
                  <Typography className='fg-green bold'>
                    Download all</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className="overflow-auto">
            <table className='documents-list'
              style={{
                width: '99%',
                overflowX: 'auto'
              }}>
              <tbody>
                <tr>
                  <td className='pb-12'></td>
                  <td className='pb-12 pr-12'>
                    <Typography className='text-12 regular fg-gray'>
                      Document&nbsp;type</Typography>
                  </td>
                  <td className='pb-12 pr-12'>
                    <Typography className='text-12 regular fg-gray'>
                      Uploaded&nbsp;by</Typography>
                  </td>
                  <td className='pb-12 pr-12'>
                    <Typography className='text-12 regular fg-gray'>
                      Uploaded&nbsp;on</Typography>
                  </td>
                  <td className='pb-12'></td>
                </tr>
                {(!get(documents, '[0].invalid') && documents) && documents
                  .map((doc, index) => {
                    const typeDescription = fileUploadInfo[get(doc, 'type')]
                    const downloadInProgress = state.downloading.some(i => i === index)
                    return (
                      <tr key={`table_row_${index}`}>
                        <td className='pb-12'>
                          <img src='/assets/images/icons/document-upload.svg'
                            alt='doc' />
                        </td>
                        <td className='pb-12 pr-12'>
                          <Grid container alignItems='center'>
                            <Grid item xs={12}>
                              <InfoToolTip title={typeDescription}>
                                <Grid container className="max-w-224"
                                  alignItems="center">
                                  <Typography noWrap
                                    className='text-14 semi-bold max-w-200'>
                                    {typeDescription}
                                  </Typography>
                                  <span className='badge ml-8'>
                                    {get(doc, 'documents', [])
                                      .filter(x => x.deleted.enabled === false).length
                                    }
                                  </span>
                                </Grid>
                              </InfoToolTip>
                            </Grid>
                          </Grid>
                        </td>
                        <td className='pb-12 pr-12'>
                          <InfoToolTip title={getUploadedByHoverText(get(doc, 'documents[0].uploadedBy'))}>
                            <Typography noWrap
                              className='text-14 regular fg-gray'>
                              {pretiffyPlantType(get(doc, 'documents[0].uploadedBy.plant.type', ''))}
                            </Typography>
                          </InfoToolTip>
                        </td>
                        <td className='pb-12 pr-12'>
                          <InfoToolTip title={`Uploaded on ${formatTimestamp(
                            get(doc, 'summary.lastUploaded.at'), 'date-time')}`}>
                            <Typography noWrap
                              className='text-14 regular fg-gray'>
                              {formatTimestamp(get(doc, 'summary.lastUploaded.at'), 'date-time')}
                            </Typography>
                          </InfoToolTip>
                        </td>
                        <td className='pb-12'>
                          <Grid container
                            alignItems='center'
                            justify='center'
                            spacing={1}>
                            <Grid item>
                              <InfoToolTip title={`View all uploaded ${typeDescription}`}>
                                <label className='fg-blue cursor-pointer regular'
                                  onClick={() => onViewDocumentsOpen(doc)}>
                                  View&nbsp;all</label>
                              </InfoToolTip>
                            </Grid>
                            <Grid item>
                              {!downloadInProgress && (
                                <InfoToolTip title={`Download all ${typeDescription}`}>
                                  <img src='/assets/images/icons/import-header-green.svg'
                                    alt='import'
                                    className='cursor-pointer'
                                    onClick={() => downloadFiles(doc, index)} />
                                </InfoToolTip>
                              )}
                              {downloadInProgress &&
                                <InfoToolTip title={`Downloading all ${typeDescription}`}>
                                  <CircularProgress color='secondary'
                                    size={20} />
                                </InfoToolTip>
                              }
                            </Grid>
                          </Grid>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </Grid>
        </Grid>
      )}

      {chatDocuments && chatDocuments.length > 0 && (
        <ChatDocuments shipmentId={shipmentId}
          chatDocuments={chatDocuments}
          onDownloadClick={downloadSingleFile} />
      )}

      <DocumentsListSidebar open={state.openDocumentsListSidebar}
        sideBarTitle={state.documentSidebarTitle}
        documentsList={state.documentsList}
        hasWriteAccess={state.hasWriteAccess}
        downloadDocument={downloadSingleFile}
        viewDocument={viewSingleFile}
        deleteDocument={deleteSingleFile}
        closeSideBar={() => onViewDocumentsClose()}
        showMessage={showMessage}
        currentPlant={currentPlant}
        getDocuments={getDocuments}
        shipmentActors={shipmentActors}
        falconConfig={falconConfig} />
    </Grid>
  )
}

export default ShipmentDocuments
