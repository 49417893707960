import React, { useEffect } from 'react'
import { Grid, TextField, Button, CircularProgress, Icon } from '@material-ui/core'
import { reviewShippingForm } from '../../../core/localization'
import { useMergeState } from '../../../core/helpers'
import _, { set } from 'lodash'
import { InfoToolTip, FuseCheckBox } from '../../../core/components'
import DateTime from 'react-datetime'
import AutoComplete from '../../../core/components/Inputs/AutoComplete'
import Carriers from '../../../core/helpers/json/carriers.json'
import Airlines from '../../../core/helpers/json/airlines.json'
import { getAllLocodes, getLocodeDetails } from '../../../core/helpers'

const ReviewShippingForm = ({ shipment, onChange, existingData, loadVessels, vesselListStatus, vessels }) => {
  const [state, setState] = useMergeState(existingData ? { ...{}, ...existingData } : {})
  const { transportMethod } = shipment
  const locodes = getAllLocodes(transportMethod)
  const vesselOptions = vessels.map(vessel => {
    return {
      value: vessel.SHIPNAME,
      label: vessel.SHIPNAME
    }
  })

  const getCarrierOptions = () => {
    if (transportMethod === 'air') {
      return Airlines.map(airline => {
        return {
          value: airline.airline,
          label: airline.airline
        }
      })
    } else {
      return Carriers.map(carrier => {
        return {
          value: carrier.name,
          label: carrier.name
        }
      })
    }
  }

  const getScacOptions = () => {
    if (transportMethod === 'air') {
      const iata = _.get(_.find(Airlines, { airline: state.carrierName }), 'IATA', '')
      if (iata) {
        return [{
          value: iata,
          label: iata
        }]
      } else {
        return []
      }
    } else {
      return _.get(_.find(Carriers, { name: state.carrierName }), 'SCAC', []).map(scac => {
        return {
          value: scac,
          label: scac
        }
      })
    }
  }

  useEffect(() => {
    let carrierName = _.get(state, 'carrierName', _.get(shipment, 'freightOperatorInfo.name', ''))
    let scacCode = _.get(state, 'scacCode', _.get(shipment, 'freightOperatorInfo.identifier.value', ''))
    let underlyingScacCode = _.get(state, 'underlyingScacCode', _.get(shipment, 'freightOperatorInfo.secondaryIdentifier.value', ''))
    let underlyingCarrierName = _.get(_.find(Carriers, i => _.get(i, 'SCAC', []).includes(underlyingScacCode)), 'name', '')
    let hasUnderlyingCarrier = false
    if (underlyingScacCode) {
      hasUnderlyingCarrier = true
      const temp = { scacCode, underlyingScacCode, underlyingCarrierName, carrierName }
      carrierName = temp.underlyingCarrierName
      scacCode = temp.underlyingScacCode
      underlyingCarrierName = temp.carrierName
      underlyingScacCode = temp.scacCode
    }
    const updatedState = {
      ...state,
      carrierName,
      scacCode,
      underlyingCarrierName,
      underlyingScacCode,
      hasUnderlyingCarrier,
      vesselSchedule: {
        FirstPOL: {
          port: {
            name: _.get(
              state,
              'vesselSchedule.FirstPOL.port.name',
              _.get(shipment, 'vesselSchedule.FirstPOL.port.name', '')
            ),
            locode: _.get(
              state,
              'vesselSchedule.FirstPOL.port.locode',
              _.get(shipment, 'vesselSchedule.FirstPOL.port.locode', '')
            )
          },
          eta: _.get(state, 'vesselSchedule.FirstPOL.eta', _.get(shipment, 'vesselSchedule.FirstPOL.eta', '')),
          etd: _.get(state, 'vesselSchedule.FirstPOL.etd', _.get(shipment, 'vesselSchedule.FirstPOL.etd', '')),
          etaNextPort: _.get(
            state,
            'vesselSchedule.FirstPOL.etaNextPort',
            _.get(shipment, 'vesselSchedule.FirstPOL.etaNextPort', '')
          ),
          vessel: {
            name: _.get(
              state,
              'vesselSchedule.FirstPOL.vessel.name',
              _.get(shipment, 'vesselSchedule.FirstPOL.vessel.name', '')
            ),
            IMO: _.get(
              state,
              'vesselSchedule.FirstPOL.vessel.IMO',
              _.get(shipment, 'vesselSchedule.FirstPOL.vessel.IMO', '')
            )
          },
          voyageNumber: _.get(
            state,
            'vesselSchedule.FirstPOL.voyageNumber',
            _.get(shipment, 'vesselSchedule.FirstPOL.voyageNumber', '')
          )
        },
        transhipmentPorts: _.get(
          state,
          'vesselSchedule.transhipmentPorts',
          _.get(shipment, 'vesselSchedule.transhipmentPorts', [])
        ),
        LastPOD: _.get(state, 'vesselSchedule.LastPOD', _.get(shipment, 'vesselSchedule.LastPOD', {}))
      }
    }
    setState(updatedState)
    onChange(updatedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  const handleChange = event => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [name]: value
    }

    if (name === 'scacCode' && (['GLCO', 'NAQA'].includes(value))) {
      set(updatedState, 'hasUnderlyingCarrier', true)
    }
    setState(updatedState)
    onChange(updatedState)
  }

  const handleFirstPOLVesselChange = event => {
    const { value } = event.target
    var FirstPOL = _.get(state, 'vesselSchedule.FirstPOL', {})
    const selectedVessel = _.find(vessels, { SHIPNAME: value })
    var updatedState

    if (selectedVessel) {
      FirstPOL = {
        ...FirstPOL,
        vessel: {
          name: selectedVessel.SHIPNAME,
          IMO: selectedVessel.IMO,
          selected: true
        }
      }
    } else {
      FirstPOL = {
        ...FirstPOL,
        vessel: {
          name: value,
          IMO: '',
          selected: false
        }
      }
    }

    updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        FirstPOL
      }
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleFirstPOLChange = event => {
    const { value } = event.target
    var { name } = event.target
    var FirstPOL = _.get(state, 'vesselSchedule.FirstPOL', {})
    var updatedState

    name = name.split('.')
    if (name.length > 1) {
      FirstPOL = {
        ...FirstPOL,
        [name[0]]: {
          ...FirstPOL[name[0]],
          [name[1]]: value
        }
      }
    } else {
      FirstPOL = {
        ...FirstPOL,
        [name[0]]: value
      }
    }

    updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        FirstPOL
      }
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleFirstPOLDateChange = (date, name) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    var updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        FirstPOL: {
          ...state.vesselSchedule.FirstPOL,
          [name]: new Date(date)
        }
      }
    }

    if (name === 'etaNextPort') {
      updatedState = updateTranshipmentETA(updatedState)
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleTranshipmentPortVesselChange = (event, index) => {
    const { value } = event.target
    var transhipmentPorts = _.get(state, 'vesselSchedule.transhipmentPorts', [])
    var updatedState

    const selectedVessel = _.find(vessels, { SHIPNAME: value })
    if (selectedVessel) {
      transhipmentPorts[index] = {
        ...transhipmentPorts[index],
        vessel: {
          name: selectedVessel.SHIPNAME,
          IMO: selectedVessel.IMO,
          selected: true
        }
      }
    } else {
      transhipmentPorts[index] = {
        ...transhipmentPorts[index],
        vessel: {
          name: value,
          IMO: '',
          selected: false
        }
      }
    }

    updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        transhipmentPorts
      }
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleTranshipmentPortChange = (event, index) => {
    const { value } = event.target
    var { name } = event.target
    var transhipmentPorts = _.get(state, 'vesselSchedule.transhipmentPorts', [])
    var updatedState

    name = name.split('.')
    if (name.length > 1) {
      transhipmentPorts[index] = {
        ...transhipmentPorts[index],
        [name[0]]: {
          ...transhipmentPorts[index][name[0]],
          [name[1]]: value
        }
      }
    } else {
      transhipmentPorts[index] = {
        ...transhipmentPorts[index],
        [name[0]]: value
      }
    }

    updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        transhipmentPorts
      }
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleTranshipmentPortDateChange = (date, name, index) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    var transhipmentPorts = _.get(state, 'vesselSchedule.transhipmentPorts', [])
    transhipmentPorts[index] = {
      ...transhipmentPorts[index],
      [name]: new Date(date)
    }

    var updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        transhipmentPorts
      }
    }

    updatedState = updateTranshipmentETA(updatedState)

    setState(updatedState)
    onChange(updatedState)
  }

  const addTranshipmentPorts = () => {
    var updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        transhipmentPorts: [
          ...state.vesselSchedule.transhipmentPorts,
          {
            port: {
              name: '',
              locode: ''
            },
            etd: '',
            etaNextPort: '',
            vessel: {
              name: '',
              IMO: ''
            },
            voyageNumber: ''
          }
        ]
      }
    }

    updatedState = updateTranshipmentETA(updatedState)

    setState(updatedState)
    onChange(updatedState)
  }

  const removeTranshipmentPorts = index => {
    var updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        transhipmentPorts: [
          ...state.vesselSchedule.transhipmentPorts.filter((item, i) => {
            return i !== index
          })
        ]
      }
    }

    updatedState = updateTranshipmentETA(updatedState)

    setState(updatedState)
    onChange(updatedState)
  }

  const updateTranshipmentETA = (updatedStateData) => {
    var FirstPOL = _.get(updatedStateData, 'vesselSchedule.FirstPOL', {})
    var transhipmentPorts = _.get(updatedStateData, 'vesselSchedule.transhipmentPorts', [])
    transhipmentPorts = transhipmentPorts.map((item, itemIndex) => {
      _.set(item, 'eta', _.get(transhipmentPorts, `[${(itemIndex - 1)}].etaNextPort`, _.get(FirstPOL, 'etaNextPort')))
      return item
    })
    const updatedState = {
      ...state,
      vesselSchedule: {
        ...state.vesselSchedule,
        FirstPOL,
        transhipmentPorts
      }
    }

    return updatedState
  }

  const handleToggleUnderlyingCarrier = (e) => {
    const { checked } = e.target
    var updatedState = {
      ...state,
      hasUnderlyingCarrier: !checked
    }

    setState(updatedState)
    onChange(updatedState)
  }

  return (
    <>
      <Grid container
        direction='row'
        className='field-control'>
        <Grid item sm={12} md={6}
          className='flex items-center'>
          <label className={'field-label'}>
            {transportMethod === 'air' ? 'Shipping Airline' : reviewShippingForm.shippingCarrier.label}
          </label>
        </Grid>
        <Grid item sm={12} md={6}
          className='flex items-center justify-end'>
          <label className={'field-instruction'}>
            {transportMethod === 'air' ? 'Add shipping airline information' : reviewShippingForm.shippingCarrier.instruction}
          </label>
        </Grid>

        <Grid container direction='row'
          className='mt-16 mb-24'
          spacing={2}>
          <Grid item sm={12} md={8}>
            <label className='formsy-form-control__label require-field'>
              {transportMethod === 'air' ? 'Airline name' : reviewShippingForm.field.carrierName}
            </label>

            <AutoComplete
              value={state.carrierName}
              options={getCarrierOptions()}
              placeholder={transportMethod === 'air' ? 'Select airline...' : 'Select carrier...'}
              onChange={value =>
                handleChange({
                  target: {
                    name: 'carrierName',
                    value
                  }
                })
              }
              canCreate
            />
          </Grid>
          <Grid item sm={12} md={4}>
            <label className='formsy-form-control__label require-field'>
              {transportMethod === 'air' ? 'IATA' : reviewShippingForm.field.scacCode}
            </label>
            <AutoComplete
              value={state.scacCode}
              options={getScacOptions()}
              placeholder={transportMethod === 'air' ? 'Select IATA...' : 'Select SCAC...'}
              onChange={value =>
                handleChange({
                  target: {
                    name: 'scacCode',
                    value
                  }
                })
              }
              canCreate
              dynamic
            />
          </Grid>
        </Grid>
        {['GLCO', 'NAQA'].includes(_.get(state, 'scacCode')) &&
          <Grid container direction='row'
            className='mb-24'
            spacing={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <FuseCheckBox checked={_.get(state, 'hasUnderlyingCarrier') === false}
                  className="pl-0"
                  onChange={handleToggleUnderlyingCarrier} />
                <label className="medium fg-gray">{transportMethod === 'air' ? 'No underlying airline' : 'No underlying carrier'}</label>
              </Grid>
            </Grid>
            {_.get(state, 'hasUnderlyingCarrier') &&
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2}>
                  <Grid item sm={12} md={8}>
                    <label className='formsy-form-control__label require-field'>
                      Underlying&nbsp;{transportMethod === 'air' ? 'Airline name' : reviewShippingForm.field.carrierName}
                    </label>
                    <AutoComplete
                      value={state.underlyingCarrierName}
                      options={Carriers.map(carrier => {
                        return {
                          value: carrier.name,
                          label: carrier.name
                        }
                      })}
                      placeholder={transportMethod === 'air' ? 'Select airline...' : 'Select carrier...'}
                      onChange={value =>
                        handleChange({
                          target: {
                            name: 'underlyingCarrierName',
                            value
                          }
                        })
                      }
                      canCreate
                    />
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <label className='formsy-form-control__label require-field'>
                      Underlying&nbsp;{transportMethod === 'air' ? 'IATA' : reviewShippingForm.field.scacCode}
                    </label>
                    <AutoComplete
                      value={state.underlyingScacCode}
                      options={_.get(_.find(Carriers, { name: state.underlyingCarrierName }), 'SCAC', []).map(scac => {
                        return {
                          value: scac,
                          label: scac
                        }
                      })}
                      placeholder={transportMethod === 'air' ? 'Select IATA...' : 'Select SCAC...'}
                      onChange={value =>
                        handleChange({
                          target: {
                            name: 'underlyingScacCode',
                            value
                          }
                        })
                      }
                      canCreate
                      dynamic
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        }

        <Grid container>
          <Grid container direction='row' className='stepper'>
            <Grid container direction='row' className='step pb-20'>
              <Grid container direction='row' className='step-header'>
                <span className='step-icon'>
                  <img src='assets/images/icons/vessel-info/port-first.svg' alt='booking' />
                </span>
                <Grid item sm={12} md={6} className='flex items-center'>
                  <label className='field-sub-label'>{`First ${reviewShippingForm.field.portOfLoad}`}</label>
                </Grid>
              </Grid>

              <div className='flex items-center text-11 text-grey-dark'>
                <label className='mr-8'>{reviewShippingForm.field.portCode}:</label>
                <span>{_.get(state, 'vesselSchedule.FirstPOL.port.locode', '')}</span>
                <label className='ml-16 mr-8'>{reviewShippingForm.field.port}:</label>
                <span>{_.get(state, 'vesselSchedule.FirstPOL.port.name', '')}</span>
              </div>

              <Grid container
                direction='row'
                className='step-content'
                spacing={2}>
                <Grid item sm={12} md={8} className='pb-16 relative'>
                  {(vesselListStatus === 'loading' || vessels.length === 0) &&
                    <InfoToolTip title="Loading vessel list...">
                      <CircularProgress size={16}
                        className="absolute pin-b pin-r mb-16 mr-40 fg-blue" />
                    </InfoToolTip>
                  }
                  {vesselListStatus === 'failed' &&
                    <InfoToolTip title={
                      <React.Fragment>
                        <label className="mr-4">
                          Loading vessel list failed,</label>
                        <label className="fg-blue cursor-pointer"
                          onClick={loadVessels}>
                          Retry</label>
                      </React.Fragment>
                    }>
                      <Icon size={16}
                        className="absolute pin-b pin-r mb-16 mr-40 fg-reg">error</Icon>
                    </InfoToolTip>
                  }
                  <label className='formsy-form-control__label require-field'>
                    {transportMethod === 'air' ? 'Aircraft' : reviewShippingForm.field.vesselName}
                  </label>
                  <AutoComplete
                    value={_.get(state, 'vesselSchedule.FirstPOL.vessel.name', '')}
                    options={vesselOptions}
                    placeholder={transportMethod === 'air' ? 'Search aircraft...' : 'Search Vessel...'}
                    onChange={value =>
                      handleFirstPOLVesselChange({
                        target: {
                          name: 'vessel.name',
                          value
                        }
                      })
                    }
                    canCreate
                  />
                </Grid>

                <Grid item sm={12} md={4} className='pb-16'>
                  <TextField
                    className='theme-text-field required'
                    name='vessel.IMO'
                    value={_.get(state, 'vesselSchedule.FirstPOL.vessel.IMO', '')}
                    disabled={_.get(state, 'vesselSchedule.FirstPOL.vessel.selected', false)}
                    label={transportMethod === 'air' ? 'Tail no' : reviewShippingForm.field.imoNumber}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleFirstPOLChange}
                  />
                </Grid>

                <Grid item sm={12} md={6} className='pb-16'>
                  <TextField
                    className='theme-text-field required'
                    name='voyageNumber'
                    value={_.get(state, 'vesselSchedule.FirstPOL.voyageNumber', '')}
                    label={transportMethod === 'air' ? 'Flight no' : reviewShippingForm.field.voyageNo}
                    fullWidth={true}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleFirstPOLChange}
                  />
                </Grid>

                <Grid item sm={12} md={6} className='pb-16'>
                  <Grid container>
                    <Grid item xs={12}>
                      <label className='formsy-form-control__label require-field'>
                        {reviewShippingForm.field.etaAtOriginPort}
                      </label>
                    </Grid>
                    <Grid item xs={12} className='relative'>
                      <DateTime className='theme-date-time-picker-field'
                        input
                        dateFormat='DD MMM YYYY,'
                        value={_.get(state, 'vesselSchedule.FirstPOL.eta') &&
                          new Date(state.vesselSchedule.FirstPOL.eta)
                        }
                        onChange={date => {
                          handleFirstPOLDateChange(date, 'eta')
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={6} className='pb-16'>
                  <Grid container>
                    <Grid item xs={12}>
                      <label className='formsy-form-control__label require-field'>
                        {reviewShippingForm.field.etdFromOriginPort}
                      </label>
                    </Grid>
                    <Grid item xs={12} className='relative'>
                      <DateTime
                        className='theme-date-time-picker-field'
                        input
                        dateFormat='DD MMM YYYY,'
                        value={_.get(state, 'vesselSchedule.FirstPOL.etd') &&
                          new Date(state.vesselSchedule.FirstPOL.etd)
                        }
                        onChange={date => handleFirstPOLDateChange(date, 'etd')}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm={12} md={6} className='pb-16'>
                  <Grid container>
                    <Grid item xs={12}>
                      <label className='formsy-form-control__label require-field'>
                        {_.get(state, 'vesselSchedule.transhipmentPorts', []).length > 0 ?
                          reviewShippingForm.field.etaAtNextPort :
                          reviewShippingForm.field.etaToDestination
                        }
                      </label>
                    </Grid>
                    <Grid item xs={12} className='relative'>
                      <DateTime
                        className='theme-date-time-picker-field'
                        input
                        dateFormat='DD MMM YYYY,'
                        onChange={date => handleFirstPOLDateChange(date, 'etaNextPort')}
                        value={
                          _.get(state, 'vesselSchedule.FirstPOL.etaNextPort') &&
                          new Date(state.vesselSchedule.FirstPOL.etaNextPort)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {_.get(state, 'vesselSchedule.transhipmentPorts', []).map((port, index) => {
              return (
                <Grid container direction='row' className='step pb-20' key={index}>
                  <Grid container direction='row' className='step-header'>
                    <span className='step-icon'>
                      <img src='assets/images/icons/vessel-info/port-transhipment.svg' alt='booking' />
                    </span>
                    <Grid item sm={12} md={6} className='flex items-center'>
                      <label className='field-sub-label'>
                        {`Transhipment ${reviewShippingForm.field.portOfLoad} - ${(index + 1)}`}
                      </label>
                    </Grid>
                    <Grid item sm={12} md={6} className='flex items-center justify-end'>
                      <span className='field-action cursor-pointer semi-bold'
                        onClick={() => removeTranshipmentPorts(index)}>
                        Remove this port
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container
                    direction='row'
                    className='step-content'
                    spacing={2}>
                    <Grid item sm={12} md={4} className='pb-16'>
                      <Grid item xs={12}>
                        <label className='formsy-form-control__label require-field'>
                          {reviewShippingForm.field.portCode}
                        </label>
                      </Grid>
                      <AutoComplete value={_.get(port, 'port.locode', '')}
                        options={locodes.map(locode => {
                          const value = _.get(locode, 'locode', '')
                          return {
                            value,
                            label: value
                          }
                        })}
                        placeholder="Select code..."
                        onChange={value => {
                          const locodeInfo = getLocodeDetails(value)
                          handleTranshipmentPortChange({
                            target: { name: 'port.locode', value }
                          }, index)
                          handleTranshipmentPortChange({
                            target: {
                              name: 'port.name',
                              value: _.get(locodeInfo, 'portName', value)
                            }
                          }, index)
                        }}
                        style={{ marginTop: 18 }}
                        canCreate
                      />
                    </Grid>

                    <Grid item sm={12} md={8} className='pb-16'>
                      <TextField name='port.name'
                        className='theme-text-field required'
                        label={reviewShippingForm.field.port}
                        value={_.get(port, 'port.name', '')}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => handleTranshipmentPortChange(e, index)}
                      />
                    </Grid>

                    <Grid item sm={12} md={4} className='pb-16'>
                      <label className='formsy-form-control__label require-field'>
                        {reviewShippingForm.field.vesselName}
                      </label>
                      <AutoComplete
                        value={_.get(port, 'vessel.name', '')}
                        options={vesselOptions}
                        placeholder='Search Vessel...'
                        onChange={value =>
                          handleTranshipmentPortVesselChange(
                            {
                              target: {
                                name: 'vessel.name',
                                value
                              }
                            },
                            index
                          )
                        }
                        canCreate
                      />
                    </Grid>

                    <Grid item sm={12} md={4} className='pb-16'>
                      <TextField
                        className='theme-text-field required'
                        name='vessel.IMO'
                        value={_.get(port, 'vessel.IMO', '')}
                        disabled={_.get(port, 'vessel.selected')}
                        label={reviewShippingForm.field.imoNumber}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => handleTranshipmentPortChange(e, index)}
                      />
                    </Grid>

                    <Grid item sm={12} md={4} className='pb-16'>
                      <TextField
                        className='theme-text-field required'
                        name='voyageNumber'
                        value={_.get(port, 'voyageNumber', '')}
                        label={reviewShippingForm.field.voyageNo}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={e => handleTranshipmentPortChange(e, index)}
                      />
                    </Grid>

                    <Grid item sm={12} md={6} className='pb-16'>
                      <Grid container>
                        <Grid item xs={12}>
                          <label className='formsy-form-control__label require-field'>
                            {reviewShippingForm.field.etdFromThisPort}
                          </label>
                        </Grid>
                        <Grid item xs={12} className='relative'>
                          <DateTime
                            className='theme-date-time-picker-field'
                            input
                            dateFormat='DD MMM YYYY,'
                            onChange={date => handleTranshipmentPortDateChange(date, 'etd', index)}
                            value={_.get(port, 'etd') && new Date(port.etd)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item sm={12} md={6} className='pb-16'>
                      <Grid container>
                        <Grid item xs={12}>
                          <label className='formsy-form-control__label require-field'>
                            {(index === _.get(state, 'vesselSchedule.transhipmentPorts', []).length - 1) ?
                              reviewShippingForm.field.etaToDestination :
                              reviewShippingForm.field.etaAtNextPort
                            }
                          </label>
                        </Grid>
                        <Grid item xs={12} className='relative'>
                          <DateTime
                            className='theme-date-time-picker-field'
                            input
                            dateFormat='DD MMM YYYY,'
                            onChange={date => handleTranshipmentPortDateChange(date, 'etaNextPort', index)}
                            value={_.get(port, 'etaNextPort') && new Date(port.etaNextPort)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
            <Grid container direction='row' className='step pb-24'>
              <Grid container direction='row' className='step-header'>
                <span className='step-icon'>
                  <img src='assets/images/icons/vessel-info/add-transhipment.png' alt='booking' />
                </span>
                <Button className='btn btn_sm btn_light-blue border-solid border-1 border-blue normal-fonts'
                  onClick={addTranshipmentPorts}>Add transhipment port</Button>
              </Grid>
            </Grid>
            <Grid container direction='row' className='step'>
              <Grid container direction='row' className='step-header'>
                <span className='step-icon'>
                  <img src='assets/images/icons/dock-filled.svg' alt='booking' />
                </span>
                <Grid item sm={12} md={6} className='flex items-center'>
                  <label className='field-sub-label'>{`Last ${reviewShippingForm.field.portOfLoad}`}</label>
                </Grid>
              </Grid>

              <div className='flex items-center text-11 text-grey-dark'>
                <label className='mr-8'>{reviewShippingForm.field.portCode}:</label>
                <span>{_.get(state, 'vesselSchedule.LastPOD.port.locode', '')}</span>
                <label className='ml-16 mr-8'>{reviewShippingForm.field.port}:</label>
                <span>{_.get(state, 'vesselSchedule.LastPOD.port.name', '')}</span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewShippingForm
