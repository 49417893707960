import React, { useState, useEffect } from 'react'
import { Grid, Button } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody,
  FuseTextField,
  FuseCheckBox,
  LoadingButton
} from '../../../core/components'
import AutoComplete from '../../../core/components/Inputs/AutoComplete'
import { get } from 'lodash'

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const inputStyle = {
  fontFamily: 'IBMPlexSans',
  fontSize: 14
}

const multiSelectStyle = {
  control: () => ({
    display: 'flex',
    border: '1px solid #949494',
    borderRadius: '2px',
    padding: '0px 10px',
    minHeight: '31px',
    maxHeight: 'auto',
    marginTop: '15px',
  }),
  placeholder: (styles) => ({
    ...styles,
    marginLeft: 0,
    marginRight: 0,
  }),
  dropdownIndicator: (styles) => ({
    ...styles
  }),
  multiValue: (styles) => ({
    ...styles,
    display: 'flex'
  })
}

export default ({ open, handleClose, addTopic, shipmentId, shipmentActors, getTopics, plants }) => {
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [accessPlants, setAccessPlants] = useState([])
  const [type, setType] = useState('NOTICE')
  const [requesting, setRequesting] = useState(false)

  const resetForm = () => {
    setTitle('')
    setDescription('')
    setType('NOTICE')
    setAccessPlants([])
  }

  const onSubmit = () => {
    const params = {
      title,
      description: {
        text: description
      },
      type,
      references: {
        shipments: [shipmentId]
      },
      access: {
        plants: accessPlants
      }
    }
    setRequesting(true)
    addTopic(params, async () => {
      await getTopics(shipmentId, true)
      setRequesting(false)
      handleClose()
    }, () => {
      setRequesting(false)
    })
  }

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open])

  useEffect(() => {
    if (open) {
      setAccessPlants([
        ...get(shipmentActors, 'consignee', []).map(i => i._id),
        ...get(shipmentActors, 'dropHub', []).map(i => i._id),
        ...get(shipmentActors, 'ibl', []).map(i => i._id),
        ...get(shipmentActors, 'ibc', []).map(i => i._id),
        ...get(shipmentActors, 'ifp', []).map(i => i._id),
        ...get(shipmentActors, 'obc', []).map(i => i._id),
        ...get(shipmentActors, 'obl', []).map(i => i._id),
        ...get(shipmentActors, 'ofp', []).map(i => i._id),
        ...get(shipmentActors, 'shipper', []).map(i => i._id)
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, shipmentActors])

  return (
    <FuseDialog open={open} wSM>
      <FuseDialogHeader text="Create New Notice"
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container
          className="p-20">
          <Grid item xs={12}
            className="pb-20">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FuseTextField type='text'
                  icon={{ ...baseTextSetting, name: 'eye' }}
                  name='title'
                  label='Title'
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  inputStyles={inputStyle}
                  required
                  fullWidth />
              </Grid>
              <Grid item xs={12}>
                <FuseTextField type='text'
                  icon={{ ...baseTextSetting, name: 'eye' }}
                  name='description'
                  label='Description'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                  inputStyles={inputStyle}
                  multiline
                  required
                  fullWidth />
              </Grid>
              <Grid item xs={12}>
                <AutoComplete className="text-14"
                  options={plants.map(i => ({ value: i._id, label: i.name }))}
                  value={accessPlants}
                  onChange={plants => {
                    setAccessPlants(plants)
                  }}
                  placeholder="Select plants..."
                  customStyles={multiSelectStyle}
                  isMulti
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <FuseCheckBox name="typeNotice"
                          checked={type === 'NOTICE'}
                          onChange={e => {
                            if (e.target.checked) {
                              setType('NOTICE')
                            }
                          }} />
                      </Grid>
                      <Grid item className="medium">
                        NOTICE</Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <FuseCheckBox name="typeIssue"
                          checked={type === 'ISSUE'}
                          onChange={e => {
                            if (e.target.checked) {
                              setType('ISSUE')
                            }
                          }} />
                      </Grid>
                      <Grid item className="medium">
                        ISSUE</Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button className="btn btn_sm btn_light_red"
                  disabled={requesting}
                  onClick={resetForm}
                  fullWidth>Reset</Button>
              </Grid>
              <Grid item xs={6}>
                <LoadingButton className="btn btn_sm"
                  disabled={!title || !description || !type}
                  loading={requesting}
                  fullWidth
                  onClick={onSubmit}>Create</LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}
