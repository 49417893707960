import React, { Component } from 'react'
import { get, sum, find, round, ceil, uniq } from 'lodash'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import history from 'history.js'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import { shipmentNameBeautifier, addGaEvent, formatTimestamp } from '../../helpers'
import { StatusIconButton, InfoToolTip } from '../'
import FooterStrip from './FooterStrip'
import RightBlock from './RightBlock'
import ShipmentTimeline from './ShipmentTimeline'
import * as CoreActions from "../../store/actions";
import { connect } from 'react-redux'
import moment from "moment";
import { ContainerInfoLabel, ShipmentDeltaInfo } from '../'
import InfoTooltip from '../InfoToolTip'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import FlightIcon from '@material-ui/icons/Flight';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import TrainIcon from '@material-ui/icons/Train';
import InfoIcon from '@material-ui/icons/Info';

const countPartners = shipment => {
  const actors = get(shipment, 'actors', {})
  var count = 0
  Object.keys(actors).forEach(key => {
    if (Array.isArray(actors[key]) && actors[key].length > 0) {
      count++
    }
  })

  return count
}

class ShipmentCard extends Component {
  onClickShipItem = (event, id) => {
    history.push(`${window.location.pathname}/${id}`)
  }

  getTagClass = (statusType, shipment) => {
    switch (statusType) {
      case 'exception-type':
        return 'orange-tag-exception'
      case 'current-status': {
        const currentStatus = get(shipment, 'status.current.value').toLowerCase()
        if (currentStatus.includes('confirmed')) return 'green-tag'
        else if (currentStatus.includes('rejected')) return 'red-tag'
        else if (currentStatus.includes('amended')) return 'orange-tag'
        return ''
      }
      case 'time-delay': {
        /** @TODO
         * if 1-3 days delayed - yellow,
         * if 4 to 7 orange,
         * if 8 + red. ontime is green
         */
        return ''
      }
      case 'custom-clearance': {
        const currentStatus = get(shipment, 'customClearance.reviewStatus', 'pending').toLowerCase()
        if (currentStatus.includes('rejected')) return 'red-tag'
        else return ''
      }
      default:
        return ''
    }
  }

  getTagValue = (statusType, shipment) => {
    statusType === 'exception-type' ? get(shipment, 'exceptionMode.type') : get(shipment, 'status.current.value')
    switch (statusType) {
      case 'exception-type':
        /** @TODO get(shipment, 'exceptionMode.type') */
        return shipment.exceptionMode.details.map(x => x.type)
      case 'current-status':
        return [get(shipment, 'status.current.value')]
      case 'time-delay':
        /** @TODO Need to change this to actual - planned */
        return [get(shipment, 'status.current.value')]
      case 'custom-clearance': {
        return [get(shipment, 'customClearance.reviewStatus', 'pending')]
      }
      default:
        return []
    }
  }

  getTimeDifference = (shipment) => {
    const currentTime = moment()
    const proposeDate = moment(get(find(get(find(get(shipment, 'journey', []), {
      location: {
        type: 'warehouse',
        subType: 'origin'
      }
    }), 'keyEvents', []), {
      code: 'EV900'
    }), 'actual.occurenceAt'))
    proposeDate.set('second', 0)
    proposeDate.set('millisecond', 0)
    currentTime.set('second', 0)
    currentTime.set('millisecond', 0)
    const proposeDiffHours = currentTime.diff(proposeDate, 'hours')
    const proposeDiffMinutes = currentTime.diff(proposeDate, 'minutes')
    let stringMinutes = proposeDiffMinutes - (proposeDiffHours * 60)
    stringMinutes = ("0" + stringMinutes).slice(-2)

    return { proposeDiffHours, proposeDiffMinutes, stringMinutes, currentTime }
  }

  getActionButtonProps = () => {
    const {
      section,
      role,
      shipment,
      onChangeBooking,
      onResolveBooking,
      onReviewBooking,
      onResolveException,
      onMarkReceive,
      onUpdateShipmentStatus,
      onReviewCustomDraft,
      onScheduleDelivery,
      requesting,
      onNotify,
      systemSettings
    } = this.props
    const actionHours = get(systemSettings, 'efpAcceptanceThreshold', 2)
    const rejectStatus = {
      shipper: ['freight-partner-rejected', 'obl-rejected']
    }
    const reviewStatus = {
      ofp: get(shipment, 'transportMethod', 'ocean') === 'air' ? ['obl-confirmed'] : ['pending', 'amended'],
      obl: get(shipment, 'transportMethod', 'ocean') === 'air' ? ['pending', 'amended'] : ['freight-partner-confirmed'],
      consignee: ['in-transit']
    }
    const markReceiveStatus = {
      consignee: ['in-transit']
    }
    const currentStatus = get(shipment, 'status.current.value', '')
    const transportMethod = get(shipment, 'transportMethod', '')
    const className = 'btn btn_lg w-full'
    var buttonsProps = {
      className: className + ' btn_view',
      onClick: () => this.onClickShipItem({}, shipment._id),
      title: 'View details'
    }

    if (section === 'schedule') {
      if (role === 'shipper') {
        if (!get(rejectStatus, role, []).includes(currentStatus)) {
          if (['pending', 'amended'].includes(currentStatus)) {
            const { proposeDiffHours, stringMinutes } = this.getTimeDifference(shipment)
            if (proposeDiffHours >= actionHours) {
              buttonsProps.note = (
                <Grid container
                  alignItems="center"
                  justify="center"
                  spacing={1}>
                  <Grid item
                    className="fg-green semi-bold cursor-pointer"
                    onClick={() => {
                      addGaEvent('shipment-sendingNotification', get(shipment, '_id'))
                      onNotify(shipment)
                    }}>Notify to accept</Grid>
                  <Grid item>{actionHours}hrs exceeded... ({proposeDiffHours}h : {stringMinutes}m)</Grid>
                </Grid>
              )
              buttonsProps.notetitle = `Proposed ${proposeDiffHours} hours and ${stringMinutes} mins ago`
            }
          }
          buttonsProps.title = 'Change Booking'
          buttonsProps.loading = requesting === shipment._id
          buttonsProps.disabled = requesting
          buttonsProps.onClick = () => onChangeBooking(shipment)
        } else if (get(rejectStatus, role, []).includes(currentStatus)) {
          buttonsProps.className = className
          buttonsProps.onClick = () => onResolveBooking(shipment)
          buttonsProps.title = 'Resolve Booking'
        }
      } else if (role === 'ofp' || role === 'obl') {
        if (get(reviewStatus, role, []).includes(currentStatus)) {
          if (role === 'ofp') {
            const { currentTime, proposeDiffHours, proposeDiffMinutes, stringMinutes } = this.getTimeDifference(shipment)

            if (proposeDiffHours >= actionHours) {
              buttonsProps.exceeded = true
              buttonsProps.title = 'Review Booking'
              buttonsProps.note = `${actionHours}hrs exceeded... (${proposeDiffHours}h : ${stringMinutes}m)`
              buttonsProps.notetitle = `Proposed ${proposeDiffHours} hours and ${stringMinutes} mins ago`
              buttonsProps.className = className + ' btn_orange'
            } else {
              const remainMinutes = ((actionHours * 60) - proposeDiffMinutes)
              const remainHours = (actionHours - ceil(proposeDiffMinutes / 60))
              buttonsProps.className = className + ' progress-overlay'
              buttonsProps.progress = proposeDiffMinutes / ((actionHours * 60) / 100)
              buttonsProps.note = `${(remainHours) > 0 ?
                `${remainHours} hour(s) and `
                : ''}${(remainMinutes - (remainHours * 60))} mins remain`
              buttonsProps.notetitle = `This shipment should be reviewed before ${currentTime
                .add(remainMinutes, 'm')
                .format('DD/MM/YYYY LT')}`
              buttonsProps.title = `Review Booking`
            }
          } else {
            if (transportMethod === 'ocean') {
              buttonsProps.className = className
              buttonsProps.title = 'Review Booking'
            }
          }
          buttonsProps.onClick = () => onReviewBooking(shipment)
        }
      }
    } else if (section === 'exception') {
      const exceptionMode = get(shipment, 'exceptionMode', {})
      const type = 'bookingDraftWIP'

      if (role === 'shipper') {
        /** @TODO Remove exceptionMode.enabled once we start getting warning array correct from API response */
        if (get(exceptionMode, 'enabled') &&
          find(get(exceptionMode, 'details', []), { type })) {
          buttonsProps.className = className
          buttonsProps.onClick = () => onResolveException(shipment)
          buttonsProps.title = 'Resolve Exception'
        }
      }
    } else if (['completed', 'inTransit', 'inbound'].includes(section)) {
      if (role === 'consignee') {
        if (get(markReceiveStatus, role, []).includes(currentStatus)) {
          buttonsProps.className = className
          buttonsProps.onClick = () => onMarkReceive(shipment)
          buttonsProps.title = 'Mark as Received'
        }
      }
    } else if (section === 'manageIncoming') {
      if (role === 'consignee') {
        if (get(shipment, 'customClearance.reviewStatus') === 'to-be-approved') {
          buttonsProps.className = className
          buttonsProps.title = 'Review Custom Draft'
          buttonsProps.onClick = () => onReviewCustomDraft(shipment)
        }
      }
      else if (role === 'ibc') {
        if (get(shipment, 'customClearance.flag.status')) {
          buttonsProps.className = className + ' btn_orange'
          buttonsProps.title = 'Update Status'
        } else {
          buttonsProps.className = className
          buttonsProps.title = 'Start Scheduling'
        }
        buttonsProps.onClick = () => onUpdateShipmentStatus(shipment)
      }
    } else if (section === 'inboundSchedule') {
      if (role === 'consignee') {
        if (get(shipment, 'customClearance.reviewStatus') === 'to-be-approved') {
          buttonsProps.className = className
          buttonsProps.title = 'Review Custom Draft'
          buttonsProps.onClick = () => onReviewCustomDraft(shipment)
        } else if (get(shipment, 'customClearance.reviewStatus') !== 'rejected') {
          if (
            (
              get(shipment, 'type') === 'FCL' &&
              get(shipment, 'status.current.value') === 'ready-to-pickup') ||
            (
              get(shipment, 'type') === 'LCL' &&
              get(shipment, 'status.current.value') === 'delivery-scheduled'
            )
          ) {
            buttonsProps.className = className + ' btn_orange'
            buttonsProps.title = 'Re-schedule Delivery'
            buttonsProps.onClick = () => onScheduleDelivery(shipment)
            buttonsProps.note = `Last scheduled on ${formatTimestamp(get(shipment, 'status.current.movedToAt', ''), 'date-time')}`
            buttonsProps.notetitle = `This shipment was already scheduled on ${formatTimestamp(get(shipment, 'status.current.movedToAt', ''), 'date-time')}`
          } else {
            buttonsProps.className = className
            buttonsProps.title = 'Schedule Delivery'
            buttonsProps.onClick = () => onScheduleDelivery(shipment)
          }
        }
      }
    }

    return buttonsProps
  }

  onDocUpload = (shipment) => {
    const { role, openDocUploader, showMessage } = this.props
    const alwaysAllowed = ['shipper', 'consignee']
    const uploadStatus = {
      ofp: 'freight-partner-confirmed',
      obl: 'obl-confirmed'
    }
    if (alwaysAllowed.includes(role) ||
      get(shipment, 'status.current.value') === get(uploadStatus, role, '') ||
      find(get(shipment, 'status.previous', []), item => item.value === get(uploadStatus, role, ''))) {
      openDocUploader(shipment)
    } else {
      var toast = {
        variant: 'info',
        message: ''
      }
      if (role === 'ofp') {
        toast.message = 'Please review and accept the booking before you can start uploading documents.'
        showMessage(toast)
      } else if (role === 'obl') {
        toast.message = 'Please review and acknowledge booking before you can start uploading documents.'
        showMessage(toast)
      }
    }
  }

  getTitledToolTip = (title, body) => {
    return (
      <Grid container>
        <Grid item xs={12}
          className="bold">{title}</Grid>
        <Grid item xs={12}>{body}</Grid>
      </Grid>
    )
  }

  sortPlannedGI = (dates) => {
    dates.sort((aDate, bDate) => {
      aDate = new Date(aDate)
      bDate = new Date(bDate)
      if (aDate > bDate) return -1
      else if (aDate < bDate) return 1
      else return 0
    })

    return dates
  }

  render() {
    const {
      role,
      shipment,
      statusType,
      onPartnerSidebarClick,
      onOutboundsSidebarClick,
      classes,
      setMinified,
      minified,
      shipmentCardPrimaryId,
      vesselTrackDialog
    } = this.props

    const tagValue = this.getTagValue(statusType, shipment)
    const tagClass = this.getTagClass(statusType, shipment)
    const actionButtonProps = this.getActionButtonProps()

    return (
      <Grid container
        className="shipment-list-item"
        style={{
          borderColor: (role === 'ofp' && actionButtonProps.exceeded) && '#E47F00'
        }}>
        <Grid item xs={12} sm={12} md={9}
          className='shipment-list-item-card cursor-pointer'>
          <Grid container alignItems='stretch' className='h-full'>
            <Grid item xs={12}>
              <Grid container
                justify='space-between'
                alignItems='center'
                className='mb-20'>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item>
                      <Link to={`${window.location.pathname}/${shipment._id}`}
                        className="inline-block">
                        <Typography className='shipment-list-item__sr-number'>
                          {shipmentNameBeautifier(
                            get(shipment, shipmentCardPrimaryId) ||
                            get(shipment, 'name', '')
                          )}
                        </Typography>
                      </Link>
                    </Grid>

                    <Grid item>
                      <Grid container alignItems="center">
                        {get(shipment, 'transportMethod') === 'CBT' &&
                          <InfoToolTip title="CBT">
                            <LocalShippingIcon className="text-20 fg-gray" />
                          </InfoToolTip>
                        }
                        {get(shipment, 'transportMethod') === 'ocean' &&
                          <InfoToolTip title="OCEAN">
                            <DirectionsBoatIcon className="text-20 fg-gray" />
                          </InfoToolTip>
                        }
                        {get(shipment, 'transportMethod') === 'air' &&
                          <InfoToolTip title="AIR">
                            <FlightIcon className="text-20 fg-gray" />
                          </InfoToolTip>
                        }
                        {get(shipment, 'transportMethod') === 'rail' &&
                          <InfoToolTip title="RAIL">
                            <TrainIcon className="text-20 fg-gray" />
                          </InfoToolTip>
                        }
                        {get(shipment, 'transportMethod') === 'NA' &&
                          <InfoToolTip title="OTHER">
                            <InfoIcon className="text-20 fg-gray" />
                          </InfoToolTip>
                        }
                      </Grid>
                    </Grid>

                    <Grid item>
                      <ShipmentDeltaInfo details={shipment}
                        iconClassName="text-20 fg-gray cursor-pointer" />
                    </Grid>

                    {get(shipment, 'stage.current.value') === 'in-transit' &&
                      get(shipment, 'summary.tracking.available') && (
                        <Grid item>
                          <Grid container alignItems="center" >
                            <img src='assets/images/icons/green-verified-tick.jpg'
                              height='25'
                              alt='Tracking available'
                              title='Tracking available' />
                          </Grid>
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Grid container justify='flex-end' alignItems='center'>

                    {get(shipment, 'summary.plannedGIDate', []).length === 1 &&
                      <Grid item className="mr-16">
                        Planned GI:&nbsp;{formatTimestamp(get(shipment, 'summary.plannedGIDate[0]'))}</Grid>
                    }
                    {get(shipment, 'summary.plannedGIDate', []).length > 1 &&
                      <InfoTooltip title={this.sortPlannedGI(get(shipment, 'summary.plannedGIDate', [])).map((item, itemIndex) => {
                        return (
                          <label className="block" key={itemIndex}>{formatTimestamp(item)}</label>
                        )
                      })}>
                        <Grid item className={classes.plannedGIConflict}>
                          Planned GI:&nbsp;{formatTimestamp(get(this.sortPlannedGI(get(shipment, 'summary.plannedGIDate', [])), '[0]'))}</Grid>
                      </InfoTooltip>
                    }

                    <Grid item>
                      <Grid container alignItems='center' spacing={2}>
                        <Grid item className='medium'>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item>
                              <Grid container alignItems="center">
                                {get(shipment, 'type') === 'LCL' && (
                                  <img src='assets/images/icons/shipments-list/LCL.svg' alt='type' className='mr-6' />
                                )}
                                {get(shipment, 'type') === 'FCL' && (
                                  <img src='assets/images/icons/shipments-list/FCL.svg' alt='type' className='mr-6' />
                                )}
                                <Typography className="text-12 medium">{get(shipment, 'type', 'N/A')}</Typography>
                              </Grid>
                            </Grid>
                            <Grid item>&#183;</Grid>
                            <Grid item>
                              {shipment.type === 'FCL' &&
                                <ContainerInfoLabel containers={get(shipment, 'containers', [])}
                                  className="cursor-pointer" />
                              }

                              {shipment.type === 'LCL' &&
                                round(sum(get(shipment, 'consolidations').map(x => get(x, 'packagedWeight.value', get(x, 'weight.value', 0)))), 3) + ' ' + get(shipment, 'consolidations[0].weight.unit', 'KG') + ' | '}

                              {shipment.type === 'LCL' &&
                                round(sum(get(shipment, 'consolidations').map(x => get(x, 'packagedVolume.value', get(x, 'volume.value', 0)))), 3) + ' ' + get(shipment, 'consolidations[0].volume.unit', 'M3')}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          {`Ship-to-party: ${uniq(get(shipment, 'consolidations', []).map(i => get(i, 'shipToParty', ''))).join(', ')}`}
                        </Grid>
                      </Grid>
                    </Grid>

                    {get(shipment, 'specialLabels', []).length > 0 && (
                      <Grid item className='ml-24'>
                        <Grid container alignItems='center'>
                          <img
                            src='assets/images/icons/shipments-list/special-label.svg'
                            alt='special-label'
                            className='mr-6'
                          />
                          <Typography className='uppercase medium text-12'>
                            {get(shipment, 'specialLabels', []).join(', ')}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {/* @todo: Remove this check in future */}
                    {(role === 'shipper' &&
                      get(shipment, 'goodsType') === 'DG') && (
                        <Grid item className='ml-24'>
                          <Grid container alignItems='center'>
                            <img
                              width='20'
                              src='assets/images/icons/booking_history/exception.svg'
                              alt='special-label'
                              className='mr-6'
                            />
                            <Typography className='uppercase medium text-12 text-red'>
                              DG
                           </Typography>
                          </Grid>
                        </Grid>
                      )
                    }

                    {get(shipment, 'shipmentMode') === 'drop' && (
                      <Grid item className='ml-24'>
                        <Grid container alignItems='center'>
                          <img src='assets/images/icons/shipments-list/drop-hub.svg' alt='dropHub' className='mr-6' />
                          <Typography className='uppercase medium text-12'>{get(shipment, 'shipmentMode')}&nbsp;</Typography>
                          (
                          <Typography
                            className='capitalize medium text-12 max-w-136'
                            title={get(shipment, 'actors.dropHub[0].name')}
                            noWrap
                          >
                            {get(shipment, 'actors.dropHub[0].name')}
                          </Typography>
                          )
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <ShipmentTimeline shipment={shipment} />
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems='center'
                spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                  {get(shipment, 'summary.actions.docs.me.done', []).length > 0 && (
                    <InfoToolTip title={this.getTitledToolTip(
                      `Uploaded`,
                      get(shipment, 'summary.actions.docs.me.done', []).join(', ')
                    )}>
                      <Grid container
                        alignItems="center">
                        <img src='assets/images/icons/shipment-card/check-round.svg'
                          alt='check' />
                        <Typography className={classes.shipmentCardText} noWrap>
                          {get(shipment, 'summary.actions.docs.me.done', []).join(', ')}
                        </Typography>
                      </Grid>
                    </InfoToolTip>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Grid container justify='flex-end'
                    alignItems='center'
                    spacing={2}>
                    <Grid item>
                      {get(shipment, 'summary.actions.docs.me.pending', []).length > 0 && (
                        <StatusIconButton
                          className={classes.statusIcon}
                          iconComponent={<ErrorOutlineIcon fontSize='default' />}
                          variant='danger'
                          onClick={() => this.onDocUpload(shipment)}
                          disabled={false}>
                          <InfoToolTip title={this.getTitledToolTip(
                            `Please upload`,
                            get(shipment, 'summary.actions.docs.me.pending', [])
                              .join(', ')
                          )}>
                            <Typography className={classes.shipmentCardText} noWrap>
                              {get(shipment, 'summary.actions.docs.me.pending', []).join(', ')}
                            </Typography>
                          </InfoToolTip>
                        </StatusIconButton>
                      )}
                    </Grid>

                    <Grid item>
                      {get(shipment, 'summary.actions.docs.total.pending', []).length > 0 && (
                        <StatusIconButton
                          iconComponent={<ErrorOutlineIcon fontSize='default' />}
                          className={classes.statusIcon}
                          variant='warning'
                          onClick={() => { }}
                          disabled={false}>
                          <InfoToolTip title={this.getTitledToolTip(
                            `Pending from other partners`,
                            get(shipment, 'summary.actions.docs.total.pending', []).join(', ')
                          )}>
                            <Typography className={classes.shipmentCardText} noWrap>
                              {get(shipment, 'summary.actions.docs.total.pending', []).join(', ')}
                            </Typography>
                          </InfoToolTip>
                        </StatusIconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={3}
          className='shipment-list-item-card'>
          <RightBlock
            shipment={shipment}
            tagValue={tagValue}
            tagClass={tagClass}
            statusType={statusType}
            actionButtonProps={actionButtonProps}
            onPartnerSidebarClick={onPartnerSidebarClick}
            onOutboundsSidebarClick={onOutboundsSidebarClick}
            countPartners={countPartners}
            onDocSidebarClick={() => this.onDocUpload(shipment)}
            vesselTrackDialog={vesselTrackDialog}
            showMessage={this.props.showMessage}
          />
        </Grid>
        <FooterStrip shipment={shipment} setMinified={setMinified} minified={minified} />
      </Grid >
    )
  }
}

const styles = theme => ({
  root: {
    background: '#FFFFFF'
  },
  statusIcon: {
    marginRight: 0,
    padding: '2px 3px',
    borderWidth: '.2em'
  },
  shipmentCardText: {
    maxWidth: 220,
    fontFamily: 'IBMPlexSans-Medium',
    color: '#626469',
    marginLeft: 4,
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'left',
    lineHeight: 1.4
  },
  plannedGIConflict: {
    backgroundColor: '#FFAA3E',
    color: '#FFFFFF',
    padding: '2px 6px',
    borderRadius: 3,
    marginRight: 16
  }
})

const mapStateToProps = ({
  core: { systemSettings }
}) => ({
  systemSettings,
})

const mapDispatchToProps = dispatch => ({
  showMessage: (...params) => dispatch(CoreActions.showMessage(...params))
})

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(ShipmentCard))
