import React, { Component } from 'react'
import { TextField, InputAdornment, MenuItem, Paper } from '@material-ui/core'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { take } from 'lodash'

const renderInputComponent = inputProps => {
  const { inputRef = () => {}, ref, ...other } = inputProps
  return (
    <TextField
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        endAdornment: (
          <InputAdornment position='end'>
            <img src='assets/images/icons/field-search-icon.png' alt='field-search' />
          </InputAdornment>
        )
      }}
      {...other}
    />
  )
}

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion, query)
  const parts = parse(suggestion, matches)

  return (
    <MenuItem selected={isHighlighted} component='div'>
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  )
}

const getSuggestionValue = suggestion => {
  return suggestion
}

export default class AutoComplete extends Component {
  constructor() {
    super()

    this.state = {
      single: '',
      stateSuggestions: []
    }
  }

  setSuggestions = suggestions => {
    this.setState({
      stateSuggestions: suggestions
    })
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    var { suggestions } = this.props
    if (value.length < 3) {
      this.setSuggestions([])
    }
    this.setSuggestions(take(suggestions(), 3))
  }

  handleSuggestionsClearRequested = () => {
    this.setSuggestions([])
  }

  handleChange = name => (event, { newValue }) => {
    this.setState({
      [name]: newValue
    })
    this.props.onChange(newValue)
  }

  handleSelect = (event, { suggestion }) => {
    this.props.onSelect(suggestion)
  }

  render() {
    const { single } = this.state
    const autosuggestProps = {
      renderInputComponent,
      suggestions: this.state.stateSuggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      onSuggestionSelected: this.handleSelect,
      getSuggestionValue,
      renderSuggestion
    }
    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          value: single,
          ...this.props.textFieldProps,
          onChange: this.handleChange('single')
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square className={'absolute list-reset'}>
            {options.children}
          </Paper>
        )}
      />
    )
  }
}
