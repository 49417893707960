import React from 'react';
import { withStyles, Badge } from '@material-ui/core';
import PropTypes from 'prop-types';
import { InfoToolTip } from '../'

const propTypes = {
  className: PropTypes.string,
  count: PropTypes.number
};

const styles = theme => ({
  badge: {
    fontSize: 10,
    color: '#b8c2cc',
    fontFamily: 'IBMPlexSans',
    pointerEvents: 'initial',
    padding: 0
  }
});

const FuseNavBadge = ({ classes, className, count }) => (
  <InfoToolTip title={count}
    placement="right">
    <Badge badgeContent={count}
      classes={{
        anchorOriginTopRightRectangle: classes.badge
      }}
      className={className} />
  </InfoToolTip>
)

FuseNavBadge.propTypes = propTypes;

export default withStyles(styles, { withTheme: true })(FuseNavBadge);
