import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import Steps from 'rc-steps'
import { getLocationDetails, getWarehouseDestinationDetails } from './nodeDetails.js'
import { get } from "lodash";

const getNodeDetails = (shipment, index) => {
  let data = {
    title: '------',
    line1: '------',
    line2: '------'
  }
  if (!shipment || !shipment.journey) {
    return data
  }

  //node 1
  if (index === 0) {
    const locationIdx = shipment.journey.findIndex(
      journey => journey.location && journey.location.type === 'warehouse' && journey.location.subType === 'origin'
    )
    const location = shipment.journey[locationIdx];
    if (location) {
      data = getLocationDetails(location, shipment, 'shipment')
    }
  }

  //node 2
  if (index === 1) {
    const locationIdx = shipment.journey.findIndex(
      journey => journey.location && journey.location.type === 'port' && journey.location.subType === 'origin'
    )
    const location = shipment.journey[locationIdx];
    if (location) {
      data = getLocationDetails(location, shipment, 'shipment')
    }
  }

  //node 3
  if (index === 2) {
    const locationIdx = shipment.journey.findIndex(
      journey => journey.location && journey.location.type === 'port' && journey.location.subType === 'destination'
    )
    const location = shipment.journey[locationIdx];
    if (location) {
      data = getLocationDetails(location, shipment, 'shipment')
    }
  }

  //node 4
  if (index === 3) {
    // const locationIdx = shipment.journey.findIndex(
    //   journey => journey.location && journey.location.type === 'warehouse' && journey.location.subType === 'destination'
    // )
    data = getWarehouseDestinationDetails(shipment, 'shipment')
  }
  return data
}

const ShipmentTimeline = ({ shipment }) => {
  let node0 = getNodeDetails(shipment, 0)
  let node1 = getNodeDetails(shipment, 1)
  let node2 = getNodeDetails(shipment, 2)
  let node3 = getNodeDetails(shipment, 3)
  const locationIndices = shipment.journey.map((val, i) => {
    if ((val.location.type === 'warehouse' && val.location.subType === 'origin') ||
      (val.location.type === 'port' && val.location.subType === 'origin') ||
      (val.location.type === 'port' && val.location.subType === 'destination') ||
      (val.location.type === 'warehouse' && val.location.subType === 'destination')) {
      return i.toString();
    } else {
      return null;
    }
  }).filter(x => x);
  let journeyCurrentIdx = -1
  if (shipment.journeyCurrentIdx >= 0) {
    journeyCurrentIdx = shipment.journeyCurrentIdx
  }
  let classNames = [`warehouse`, `port`, `port`, `warehouse`]
  classNames = classNames.map((className, index) => {
    if (Number(locationIndices[index]) <= journeyCurrentIdx) {
      return `${className}-complete`
    } else if (Number(locationIndices[index]) === journeyCurrentIdx + 1) {
      return `${className}-current`
    } else {
      return className
    }
  })
  return (
    <Grid container justify='space-between' alignItems='center' className='mb-8'>
      <Steps className='shipment-list-item-progress'>
        <Steps.Step
          className={`shipment-list-item-progress-step ${classNames[0]}`}
          description={
            get(shipment, 'journey', []).length > 0 &&
            <>
              <Typography className='step__description' title={get(node0, 'title')} noWrap>
                {get(node0, 'title')}
              </Typography>
              <Typography
                className="step__description"
                style={{
                  fontWeight: '200',
                  color: '#949494',
                  fontStyle: 'italic'
                }}
                title={get(node0, 'allLine1', []).join('\n')}
                noWrap
              >
                {get(node0, 'line1')}
              </Typography>
              <Typography
                className='step__description'
                style={{
                  fontWeight: '200',
                  color: '#949494',
                  fontStyle: 'italic'
                }}
                title={get(node0, 'line2')}
                noWrap
              >
                {get(node0, 'line2')}
              </Typography>
            </>
          }
        />
        <Steps.Step
          className={`shipment-list-item-progress-step ${classNames[1]}`}
          description={
            get(shipment, 'journey', []).length > 0 &&
            <>
              <Typography className='step__description' title={get(node1, 'title')} noWrap>
                {get(node1, 'title')}
              </Typography>
              <Typography
                className='step__description'
                style={{
                  fontWeight: '200',
                  color: '#949494',
                  fontStyle: 'italic'
                }}
                title={get(node1, 'allLine1', []).join('\n')}
                noWrap
              >
                {get(node1, 'line1')}
              </Typography>
              <Typography
                className='step__description'
                style={{
                  fontWeight: '200',
                  color: '#949494',
                  fontStyle: 'italic'
                }}
                title={get(node1, 'allLine2', []).join('\n')}
                noWrap
              >
                {get(node1, 'line2')}
              </Typography>
            </>
          }
        />
        <Steps.Step
          className={`shipment-list-item-progress-step ${classNames[2]}`}
          description={
            get(shipment, 'journey', []).length > 0 &&
            <>
              <Typography className='step__description' title={get(node2, 'title')} noWrap>
                {get(node2, 'title')}
              </Typography>
              <Typography
                className='step__description'
                style={{
                  fontWeight: '200',
                  color: '#949494',
                  fontStyle: 'italic'
                }}
                title={get(node2, 'allLine1', []).join('\n')}
                noWrap
              >
                {get(node2, 'line1')}
              </Typography>
              <Typography
                className='step__description'
                style={{
                  fontWeight: '200',
                  color: '#949494',
                  fontStyle: 'italic'
                }}
                title={get(node2, 'allLine2', []).join('\n')}
                noWrap
              >
                {get(node2, 'line2')}
              </Typography>
            </>
          }
        />
        <Steps.Step
          className={`shipment-list-item-progress-step ${classNames[3]} last-step`}
          description={
            get(shipment, 'journey', []).length > 0 &&
            <>
              <Typography className='step__description'
                title={get(node3, 'title')}
                noWrap>
                {get(node3, 'title')}
              </Typography>

              <Typography className='step__description'
                style={{
                  fontWeight: '200',
                  color: '#949494',
                  fontStyle: 'italic'
                }}
                title={get(node3, 'consignees') ?
                  get(node3, 'consignees', []).map((item, index) => {
                    return index === 0 ? false : item.location.name
                  }).filter(Boolean).join('\n') :
                  get(node3, 'allLine1', []).join('\n')}
                noWrap>
                {get(node3, 'line1')}
              </Typography>

              <Typography className='step__description'
                style={{
                  fontWeight: '200',
                  color: '#949494',
                  fontStyle: 'italic'
                }}
                title={get(node3, 'line2')}
                noWrap>
                {get(node3, 'line2')}
              </Typography>
            </>
          }
        />
      </Steps>
    </Grid>
  )
}

export default ShipmentTimeline
