import ActionTypes from './types'

const initialState = {
  loading: true,
  loadingMore: false,
  hasMore: false,
  total: 0,
  data: []
}

const emailTrackerReducers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EMAIL_LOGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        hasMore: action.payload.showMore,
        total: action.payload.total,
      }
    }
    case ActionTypes.LOADING_MORE_EMAIL_LOGS: {
      return {
        ...state,
        loadingMore: true
      }
    }
    case ActionTypes.LOAD_MORE_EMAIL_LOGS: {
      return {
        ...state,
        loadingMore: false,
        hasMore: action.payload.showMore,
        total: action.payload.total,
        data: [...state.data, ...action.payload.data]
      }
    }

    default:
      return state
  }
}

export default emailTrackerReducers
