import ActionTypes from './types';

const initialState = {
  allSheets: [],
  isAuthorised:false
}

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SAVE_SHEETS_DATA: {
      return {
        ...state,
        allSheets: action.payload
      }
    }
    case ActionTypes.SET_ANALYTIC_AUTH: {
      return {
        ...state,
        isAuthorised: true
      }
    }
    default:
      return state
  }
}

export default analyticsReducer;
