import React from 'react'
import { Grid, Button } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader
} from '../../core/components'
import ReviewBookingSummary from './ReviewBookingSummary'

const UploadNDA = ({ open, shipment, handleClose }) => {

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Upload NDA'
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="booking-wizard">
          <Grid item xs={8} className='booking-forms h-full'>
            <Grid container className="h-full">

              <Grid item xs={12}>
                <Grid container spacing={4}>

                  <Grid item xs={12}>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Grid container spacing={1}
                          alignItems="center">
                          <Grid item xs={6}
                            className="semi-bold text-16">
                            Upload document</Grid>
                          <Grid item xs={6} className="regular fg-gray text-14 text-right">
                            Upload notice of arrival</Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container
                          justify="space-between"
                          alignItems="center"
                          className="border-dashed border-gray border-2 p-6 bg-gray">
                          <Grid item xs={10}>
                            <Grid container
                              alignItems="center"
                              spacing={2}>
                              <Grid item>
                                <img src="/assets/images/icons/document-upload.svg"
                                  alt="document"
                                  className="h-36 w-auto" />
                              </Grid>
                              <Grid item className="semi-bold">
                                Notice of arrival</Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <img src='/assets/images/icons/Plus@3x.svg'
                              alt="download"
                              className="h-24 w-auto" />
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container
                  alignItems="center"
                  className="pt-40"
                  spacing={3}>
                  <Grid item xs={6}>
                    <Button className="btn btn_lg w-full btn_green"
                      onClick={() => { }}
                      disabled={false}>
                      Upload NDA</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <ReviewBookingSummary shipment={shipment} />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default UploadNDA
