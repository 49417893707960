import ActionTypes from './types'

const initialState = {
  cesiumToken: '',
  coordinates: [],
  vessel: {},
  shipments: []
}

const mapViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_MAP_VIEW_VESSELS: {
      return {
        ...state,
        coordinates: action.payload.data,
      }
    }
    case ActionTypes.GET_MAP_VIEW_VESSEL_INFO: {
      return {
        ...state,
        vessel: action.payload.data.vessel,
        shipments: action.payload.data.shipments
      }
    }
    case ActionTypes.GET_CESIUM_TOKEN: {
      return {
        ...state,
        cesiumToken: action.payload.data.cesiumToken.value
      }
    }
    default:
      return state
  }
}

export default mapViewReducer
