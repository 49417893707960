import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody,
  FuseCheckBox,
  FuseFileUploader
} from '../../core/components'
import { get, find, filter } from 'lodash'
import GetAppIcon from '@material-ui/icons/GetApp'
import { getUploadableDocumentOptions } from '../../core/helpers'
import AutoComplete from '../../core/components/Inputs/AutoComplete'
import { getFileById, uploadFileById } from '../../core/api'
import fileDownload from 'js-file-download'
import InfoIcon from '@material-ui/icons/Info';
import FileType from 'file-type'

const selectStyle = {
  control: () => ({
    display: 'flex',
    border: 'none',
    padding: '0px 5px',
    minHeight: '24px',
    maxHeight: '24px',
    backgroundColor: '#f0f1f3',
  }),
  dropdownIndicator: (styles) => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  })
}

const DocumentItem = ({ doc, selectedDocuments, onSelectChange, downloadOff, usedDocuments, onTypeChange }) => {
  const docId = get(doc, '_id')
  const selectedDoc = find(selectedDocuments, i => i._id === docId)
  const docType = get(selectedDoc, 'type')
  const isSelected = selectedDoc ? true : false
  const isUsed = find(usedDocuments, i => i._id === docId) ? true : false
  const [fetchingFile, setFetchingFile] = useState(false)

  const handleDownloadFile = async () => {
    setFetchingFile(true)
    const { ok, data } = await getFileById(docId)
    setFetchingFile(false)
    if (ok) {
      fileDownload(data, get(doc, 'name', 'NoName'))
    }
  }

  const handlePreviewFile = async () => {
    setFetchingFile(true)
    const { ok, data } = await getFileById(docId)
    setFetchingFile(false)
    if (ok) {
      const fileType = await FileType.fromBuffer(data)
      const blob = new Blob([data], { type: get(fileType, 'mime') })
      const srcBlob = URL.createObjectURL(blob);
      window.open(srcBlob);
    }
  }

  return (
    <Grid container className="border-1 border-gray rounded p-6 pr-12">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <FuseCheckBox checked={isSelected}
              disabled={isUsed}
              onChange={(e) => !isUsed && onSelectChange(e, doc)} />
          </Grid>
          <Grid item xs>
            <Grid container justify="space-between" alignItems="center" spacing={1}>
              <Grid item xs={6}>
                <Typography className="semi-bold text-14" noWrap>
                  {get(doc, 'name', 'NoName')}
                </Typography>
              </Grid>
              {isSelected &&
                <Grid item xs={3}>
                  <AutoComplete options={getUploadableDocumentOptions()}
                    value={docType}
                    className="text-12"
                    placeholder="Select type..."
                    onChange={(value) => onTypeChange(docId, value)}
                    customStyles={selectStyle}
                    showAllOptions />
                </Grid>
              }
              <Grid item>
                <Grid container alignItems="center" spacing={3}>
                  {isUsed &&
                    <Grid item>
                      <Typography className="rounded bg-grey semi-bold text-10 uppercase text-white uppercase px-6 py-4">
                        been selected before</Typography>
                    </Grid>
                  }
                  {(!fetchingFile && !downloadOff) &&
                    <Grid item>
                      <Grid container alignItems="center">
                        <GetAppIcon className="text-20 cursor-pointer fg-green"
                          onClick={handleDownloadFile} />
                      </Grid>
                    </Grid>
                  }
                  {!fetchingFile &&
                    <Grid item>
                      <Grid container alignItems="center">
                        <InfoIcon className="text-20 cursor-pointer fg-blue"
                          onClick={handlePreviewFile} />
                      </Grid>
                    </Grid>
                  }
                  {fetchingFile &&
                    <Grid item>
                      <Grid container alignItems="center">
                        <CircularProgress className="fg-blue" size={20} />
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const SelectDocsDialog = ({ open, showMessage, handleClose, selectedEmails, onBack, selectedDocuments, setSelectedDocuments, onContinue, newShipmentCount, usedDocuments, onFinish }) => {
  const [newUploads, setNewUploads] = useState([])
  const [isValid, setIsValid] = useState(true)
  const [fileUploading, setFileUploading] = useState(false)

  const checkValidation = () => {
    setIsValid(true)
    selectedDocuments.forEach(i => {
      if (!get(i, 'type')) {
        setIsValid(false)
      }
    })
  }

  const handleDocUpload = async (document) => {
    const key = get(Object.keys(document), '[0]')

    if (!fileUploading && key) {
      setFileUploading(true)
      const uuid = document[key].upload.uuid
      const file = new FormData()
      file.append('file', document[key])
      try {
        const { ok, data } = await uploadFileById(uuid, file)

        if (!ok) {
          throw new Error(data.message)
        }

        setNewUploads([
          ...newUploads,
          { _id: uuid, name: key }
        ])
      } catch (e) {
        showMessage(e)
      }
      setFileUploading(false)
    }
  }

  const handleSelectionChange = (event, document) => {
    const { checked } = event.target
    if (checked) {
      setSelectedDocuments([
        ...selectedDocuments,
        document
      ])
    } else {
      setSelectedDocuments(filter(selectedDocuments, i => (i._id !== document._id)))
    }
  }

  const handleDocumentTypeChange = (docId, docType) => {
    setSelectedDocuments(selectedDocuments.map(i => {
      if (i._id === docId) i.type = docType
      return i
    }))
  }

  useEffect(() => {
    setSelectedDocuments([])
    setNewUploads([])
    setIsValid(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmails])

  useEffect(() => {
    checkValidation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocuments])

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text="Select document to create shipment" handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="p-20">
          <Grid item xs={12}>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <Grid container spacing={1} justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography className="semi-bold text-16">Use the following docs or upload new docs</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="regular fg-gray text-14">{selectedEmails.length} email selected</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {selectedEmails.map((emailItem, emailItemIndex) => {
                const attachments = get(emailItem, 'documents', []).map(i => ({
                  _id: get(i, 'docstoreId', i),
                  name: get(i, 'name')
                }))

                return (
                  <Grid item xs={12} key={emailItemIndex}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography className="regular text-14 fg-gray">
                          From:&nbsp;{get(emailItem, 'subject')}</Typography>
                      </Grid>
                      {attachments.length > 0 && attachments.map((aItem, aItemIndex) => {
                        return (
                          <Grid item xs={12} key={aItemIndex}>
                            <DocumentItem doc={aItem}
                              selectedDocuments={selectedDocuments}
                              usedDocuments={usedDocuments}
                              onSelectChange={handleSelectionChange}
                              onTypeChange={handleDocumentTypeChange} />
                          </Grid>
                        )
                      })}
                      {attachments.length === 0 &&
                        <Grid item xs={12}>
                          <Typography className="bg-gray p-6 text-center regular fg-gray">
                            This email has no attachments</Typography>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                )
              })}

              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography className="regular text-14 fg-gray">
                      New uploads</Typography>
                  </Grid>
                  {newUploads.length > 0 && newUploads.map((aItem, aItemIndex) => {
                    return (
                      <Grid item xs={12} key={aItemIndex}>
                        <DocumentItem doc={aItem}
                          selectedDocuments={selectedDocuments}
                          usedDocuments={usedDocuments}
                          onSelectChange={handleSelectionChange}
                          onTypeChange={handleDocumentTypeChange}
                          downloadOff />
                      </Grid>
                    )
                  })}
                  <Grid item xs={12}>
                    <FuseFileUploader fileTitle="Upload new document"
                      type="newUpload"
                      name="newUpload"
                      image='assets/images/icons/Document-upload.png'
                      required={false}
                      onSuccess={handleDocUpload}
                      allowedTypes={".pdf,.xls,.xlsx,.xlsm,.csv,.txt,.zip,.doc,.docx,.jpg,.jpeg,.png,.gif"}
                      maxFilesize={25}
                      fileUploading={fileUploading}
                      hideUploadDetails
                      smallHeightBox />
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container className="pt-40">
              <Grid container spacing={2}
                alignItems="center"
                justify={newShipmentCount === 0 ? 'space-between' : 'flex-start'}>
                {newShipmentCount > 0 &&
                  <Grid item xs={12} sm={12} md={12}>
                    <Typography className="regular text-14 fg-gray">
                      If you have created all the shipments you can click on “Finish” button</Typography>
                  </Grid>
                }
                <Grid item xs={12} sm={6} md={4}>
                  <Button className="btn w-full"
                    disabled={!isValid}
                    onClick={onContinue}>continue</Button>
                </Grid>
                {newShipmentCount > 0 &&
                  <Grid item xs={12} sm={6} md={4}>
                    <Button className="btn btn_light_green w-full"
                      onClick={onFinish}>finish</Button>
                  </Grid>
                }

                {(onBack && newShipmentCount === 0) &&
                  <Grid item>
                    <Button className="btn btn_transparent"
                      onClick={onBack}>back</Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default SelectDocsDialog
