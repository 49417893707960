import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { get, filter } from 'lodash'
import { bookingWizard, general } from '../../core/localization'
import { shipmentNameBeautifier } from '../../core/helpers'
import TransportTimeline from '../../core/components/Timelines/TransportTimeline'
import { formatTimestamp } from '../../core/helpers'

export default (props) => {
  const { shipment } = props
  const [plannedRoute, setPlannedRoute] = useState([])

  useEffect(() => {
    const route = [
      {
        place: [
          get(shipment, 'shipper.name'),
          get(shipment, 'shipper.office.address.country')
        ].filter(Boolean).join(', '),
        address: get(shipment, 'shipper.office.address.street', ''),
        color: '#3DCD58'
      },
      {
        place: [
          get(shipment, 'consignee[0].name'),
          get(shipment, 'consignee[0].office.address.country')
        ].filter(Boolean).join(', '),
        address: get(shipment, 'consignee[0].office.address.street', ''),
        color: '#3DCD58'
      }
    ]
    setPlannedRoute(route)
  }, [shipment])

  const activeContainers = filter(get(shipment, 'containers', []), item =>
    (get(item, 'type') && !get(item, 'cancelled'))
  )
  const cancelledContainers = filter(get(shipment, 'containers', []), item =>
    (get(item, 'cancelled') === true)
  )

  return (
    <Grid container
      className='booking-summary text-12'>
      <Grid item xs={12}>

        {/* Booking details */}
        <Grid container>

          {/* Title */}
          <Grid item xs={12}
            className="pb-20">
            <h4 className='text-base text-grey-darker'>
              {shipment && shipmentNameBeautifier(get(shipment, 'name', ''))}
              {!shipment && 'New shipment'}
            </h4>
          </Grid>
          {/* END of Title */}

          {!shipment &&
            /* New Booking description */
            <Grid item xs={12}
              className='fg-gray italic'>
              After adding booking details you can see your booking summary here…</Grid>
            /* END of New Booking description */
          }

          {shipment &&
            <>
              {/* Volume */}
              <Grid item xs={12} className="pb-6">
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {bookingWizard.summary.volume}
                  </Grid>
                  <Grid item className='text-right bold'>
                    {get(shipment, 'volume.value', 0).toFixed(3)}&nbsp;{get(shipment, 'volume.unit', 'M3')}
                  </Grid>
                </Grid>
              </Grid>
              {/* END of Volume */}

              {/* Weight */}
              <Grid item xs={12} className="pb-6">
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {bookingWizard.summary.weight}
                  </Grid>
                  <Grid item className='text-right bold'>
                    {get(shipment, 'weight.value', 0).toFixed(3)}&nbsp;{get(shipment, 'weight.unit', 'KG')}
                  </Grid>
                </Grid>
              </Grid>
              {/* END of Weight */}

              {/* Groupage Date */}
              <Grid item xs={12} className="pb-6">
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {bookingWizard.summary.groupageDate}
                  </Grid>
                  <Grid item className='text-right bold'>
                    {formatTimestamp(get(shipment, 'groupageDate')) || '-'}
                  </Grid>
                </Grid>
              </Grid>
              {/* END of Groupage Date */}

              {/* Shipment Type */}
              <Grid item xs={12} className="pb-6">
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {bookingWizard.summary.shipmentType}
                  </Grid>
                  <Grid item className='text-right bold uppercase'>
                    {get(shipment, 'shipmentMode')}
                  </Grid>
                </Grid>
              </Grid>
              {/* END of Shipment Type */}

              {/* Goods Declaration */}
              <Grid item xs={12} className="pb-6">
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {bookingWizard.summary.goodsDeclaration}
                  </Grid>
                  <Grid item className='text-right bold uppercase'>
                    {get(shipment, 'goodsType', []).join(', ')}
                  </Grid>
                </Grid>
              </Grid>
              {/* END of Goods Declaration */}

              {/* Transport Route */}
              <Grid item xs={12}>
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {bookingWizard.summary.transportRoute}
                  </Grid>
                  <Grid item className='text-right bold uppercase'>
                    {get(shipment, 'transportMethod')}
                  </Grid>
                </Grid>
              </Grid>
              {/* END of Transport Route */}
            </>
          }

        </Grid>
        {/* End of Booking details */}

        {get(shipment, 'type') === 'FCL' &&
          /* Containers */
          <Grid container
            className={'border-dashed border-t-1 border-gray pt-20 mt-20'}
            alignItems="center">

            {/* Title */}
            <Grid item xs={12} className="pb-20">
              <Grid container
                alignItems="center">
                <Grid item>
                  <h4 className='text-base text-grey-darker'>
                    Containers</h4>
                </Grid>
                <Grid item xs
                  className="text-grey-darker text-right text-12">
                  {activeContainers.length > 0 &&
                    <label>{`${activeContainers.length} Active`}</label>
                  }
                  {cancelledContainers.length > 0 &&
                    <label className="ml-12">{`${cancelledContainers.length} Cancelled`}</label>
                  }
                </Grid>
              </Grid>
            </Grid>
            {/* End of Title */}

            {filter(get(shipment, 'containers', []), { type: 'C20GP' }).length > 0 &&
              /* C20GP */
              <Grid item xs={12} className="pb-6">
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {general.container.C20GP}
                  </Grid>
                  <Grid item className='text-right bold'>
                    {filter(get(shipment, 'containers', []), { type: 'C20GP' }).length}
                  </Grid>
                </Grid>
              </Grid>
              /* END of C20GP */
            }

            {filter(get(shipment, 'containers', []), { type: 'C40GP' }).length > 0 &&
              /* C40GP */
              <Grid item xs={12} className="pb-6">
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {general.container.C40GP}
                  </Grid>
                  <Grid item className='text-right bold'>
                    {filter(get(shipment, 'containers', []), { type: 'C40GP' }).length}
                  </Grid>
                </Grid>
              </Grid>
              /* END of C40GP */
            }

            {filter(get(shipment, 'containers', []), { type: 'C40HC' }).length > 0 &&
              /* C40HC */
              <Grid item xs={12} className="pb-6">
                <Grid container
                  spacing={1}
                  justify="space-between">
                  <Grid item className='fg-gray'>
                    {general.container.C40HC}
                  </Grid>
                  <Grid item className='text-right bold'>
                    {filter(get(shipment, 'containers', []), { type: 'C40HC' }).length}
                  </Grid>
                </Grid>
              </Grid>
              /* END of C40HC */
            }

          </Grid>
          /* End of Containers */
        }

        {get(shipment, 'type') === 'LCL' &&
          /* Shipment type */
          <Grid container
            className={'border-dashed border-t-1 border-gray py-20 mt-20'}>

            {/* Title */}
            <Grid item xs={12}
              className="pb-20">
              <h4 className='text-base text-grey-darker'>
                Shipment type</h4>
            </Grid>
            {/* End of Title */}

            {/* LCL */}
            <Grid item xs={12} className="pb-6">
              <Grid container
                spacing={1}
                justify="space-between">
                <Grid item className='fg-gray'>
                  Less than container load</Grid>
                <Grid item className='text-right bold'>
                  LCL</Grid>
              </Grid>
            </Grid>
            {/* END of LCL */}

          </Grid>
          /* End of Shipment type */
        }

        {(shipment && plannedRoute.length > 0) &&
          /* Transport Timeline */
          <Grid container
            className={'info-container border-dashed border-t-1 border-gray py-20 mt-20'}>

            {/* Title */}
            <Grid item xs={12}
              className="pb-20">
              <h4 className='text-base text-grey-darker'>
                {bookingWizard.summary.transportRoute}
              </h4>
            </Grid>
            {/* End of Title */}

            {/* Route */}
            <Grid item xs={12}>
              <TransportTimeline data={plannedRoute} />
            </Grid>
            {/* End of Route */}

          </Grid>
          /* End of Transport Timeline */
        }

      </Grid>
    </Grid>
  )
}
