import React, { createRef } from 'react'
import FuseAnimate from '../Animate'
import classNames from 'classnames'

const SideBar = ({ open, children, onBackDropClick, className, wSM }) => {
  const sidebarRef = createRef()

  const backDropClick = onBackDropClick || (() => { })

  return open ? (
    <FuseAnimate animation='transition.fadeIn' duration={200}>
      <div ref={sidebarRef}
        className={classNames('side-bar', className)}
        onClick={backDropClick}>
        <FuseAnimate animation='transition.slideRightIn'
          duration={200}
          delay={200}>
          <div className={classNames({
            'side-bar-dialog': true,
            'side-bar-dialog_sm': wSM
          })}
            onClick={e => e.stopPropagation()}>
            {children}
          </div>
        </FuseAnimate>
      </div>
    </FuseAnimate>
  ) : null
}

export default SideBar
