import React from 'react'
import { Grid } from '@material-ui/core'
import { FuseDialog, FuseDialogHeader, FuseDialogBody } from '../../core/components'
import ShipmentEvent from './ShipmentEvents'
import { get } from 'lodash'

const AddEventSidebar = ({ handleClose, onDone, open, shipment, eventWriteList, addShipmentEvents, requesting, selectedTask, getShipmentEventWriteList }) => {
  return (
    <FuseDialog open={open} wSM>
      <FuseDialogHeader text={get(eventWriteList, '[0].name', 'Add Event')}
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="p-20">
          <Grid item xs={12} className="regular">
            <ShipmentEvent shipment={shipment}
              eventWriteList={eventWriteList}
              loading={false}
              addShipmentEvents={addShipmentEvents}
              getShipmentEventWriteList={getShipmentEventWriteList}
              selectedCode={get(selectedTask, 'task.action.code')}
              requesting={requesting}
              onDone={onDone} />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default AddEventSidebar
