import ReactGA from 'react-ga'
import { get, set, compact, join } from 'lodash'
import { getGATrackId } from './env-helpers'
import { getShipmentDetailsTabs } from './general-helpers'


const gaEventCategories = {
  outbound: "Outbound",
  shipment: "Shipment"
}
const gaEventActions = {}

// START: Base Actions
function getBaseActions(category) {
  return {
    creating: {
      action: `Creating ${category}`,
      label: `Creating new ${category}`
    },
    createCancelled: {
      action: `Create ${category} cancelled`,
      label: `Create new ${category} is cancelled`
    },
    createFailed: {
      action: `Create ${category} failed`,
      label: `Create new ${category} is failed`
    },
    created: {
      action: `${category} created`,
      label: `New ${category} created`
    },
    updating: {
      action: `Updating ${category}`,
      label: `Updating an existing ${category}`
    },
    updateCancelled: {
      action: `Update ${category} cancelled`,
      label: `Update an existing ${category} is cancelled`
    },
    updateFailed: {
      action: `Update ${category} failed`,
      label: `Update an existing ${category} is failed`
    },
    updated: {
      action: `${category} updated`,
      label: `Existing ${category} updated`
    },
    deleting: {
      action: `Deleting ${category}`,
      label: `Deleting a ${category}`
    },
    deleteCancelled: {
      action: `Delete ${category} cancelled`,
      label: `Delete ${category} is cancelled`
    },
    deleteFailed: {
      action: `Delete ${category} failed`,
      label: `Delete ${category} is failed`
    },
    deleted: {
      action: `${category} deleted`,
      label: `${category} is deleted`
    }
  }
}
// END: Base Actions

// START: Outbound
set(gaEventActions, 'outbound', {
  closing: {
    action: `Closing ${gaEventCategories.outbound}(s)`,
    label: `Closing some ${gaEventCategories.outbound}(s)`
  },
  closeFailed: {
    action: `Close ${gaEventCategories.outbound}(s) is failed`,
    label: `Close ${gaEventCategories.outbound}(s) is failed`
  },
  closed: {
    action: `${gaEventCategories.outbound}(s) closed`,
    label: `${gaEventCategories.outbound}(s) are closed`
  },
})
// END: Outbound

// START: Shipment
set(gaEventActions, 'shipment', {
  ...getBaseActions(gaEventCategories.shipment),
  addTo: {
    action: `Manage Booking -> Add outbounds`,
    label: `Adding outbounds to an exiting ${gaEventCategories.shipment}`
  },
  addToCancelled: {
    action: `Manage Booking -> Add outbounds cancelled`,
    label: `Adding outbounds to an exiting ${gaEventCategories.shipment} is cancelled`
  },
  removeFrom: {
    action: `Manage Booking -> Removing outbounds`,
    label: `Removing outbounds from an exiting ${gaEventCategories.shipment}`
  },
  removeFromFailed: {
    action: `Manage Booking -> Removing outbounds failed`,
    label: `Removing outbounds from an exiting ${gaEventCategories.shipment} is failed`
  },
  amending: {
    action: `Amending ${gaEventCategories.shipment}`,
    label: `Amending existing ${gaEventCategories.shipment}`
  },
  amendCancelled: {
    action: `Amend ${gaEventCategories.shipment} cancelled`,
    label: `Amend exiting ${gaEventCategories.shipment} is cancelled`
  },
  amended: {
    action: `${gaEventCategories.shipment} amended`,
    label: `Existing ${gaEventCategories.shipment} amended`
  },
  sendingPreAlert: {
    action: `Sending ${gaEventCategories.shipment} Pre Alert`,
    label: `Sending Pre Alert for ${gaEventCategories.shipment}`
  },
  sentPreAlert: {
    action: `${gaEventCategories.shipment} Pre Alert sent`,
    label: `Pre Alert for ${gaEventCategories.shipment} is sent`
  },
  sendPreAlertCancelled: {
    action: `${gaEventCategories.shipment} Pre Alert cancelled`,
    label: `Sending Pre Alert for ${gaEventCategories.shipment} is cancelled`
  },
  sendPreAlertFailed: {
    action: `${gaEventCategories.shipment} Pre Alert failed`,
    label: `Sending Pre Alert for ${gaEventCategories.shipment} is failed`
  },
  messageClick: {
    action: `${gaEventCategories.shipment} details -> Navigated to chat-room`,
    label: `Clicked on ${gaEventCategories.shipment}'s Messages button`
  },
  sendingNotification: {
    action: `Sending ${gaEventCategories.shipment} Notification`,
    label: `Sending Notification for ${gaEventCategories.shipment}`
  },
  sentNotification: {
    action: `${gaEventCategories.shipment} Notification sent`,
    label: `Notification for ${gaEventCategories.shipment} is sent`
  },
  sendingNotificationCancelled: {
    action: `${gaEventCategories.shipment} Notification cancelled`,
    label: `Sending Notification for ${gaEventCategories.shipment} is cancelled`
  },
  sendingNotificationFailed: {
    action: `${gaEventCategories.shipment} Notification failed`,
    label: `Sending Notification for ${gaEventCategories.shipment} is failed`
  },
  changeDetailsTab: {
    action: `Change ${gaEventCategories.shipment} details Tab`,
    label: `Changed ${gaEventCategories.shipment} details Tab`
  },
  addingEvent: {
    action: `Adding ${gaEventCategories.shipment} Event`,
    label: `Adding Event for ${gaEventCategories.shipment}`
  },
  addingEventCancelled: {
    action: `Adding ${gaEventCategories.shipment} Event cancelled`,
    label: `Adding Event for ${gaEventCategories.shipment} is cancelled`
  },
  addingEventFailed: {
    action: `Adding ${gaEventCategories.shipment} Event failed`,
    label: `Adding Event for ${gaEventCategories.shipment} is failed`
  },
  addedEvent: {
    action: `Added ${gaEventCategories.shipment} Event`,
    label: `Added Event for ${gaEventCategories.shipment}`
  },
  updatingEventTracking: {
    action: `Updating ${gaEventCategories.shipment} Event Tracking`,
    label: `Updating Event Tracking for ${gaEventCategories.shipment}`
  },
  updatingEventTrackingCancelled: {
    action: `Updating ${gaEventCategories.shipment} Event Tracking cancelled`,
    label: `Updating Event Tracking for ${gaEventCategories.shipment} is cancelled`
  },
  updatingEventTrackingFailed: {
    action: `Updating ${gaEventCategories.shipment} Event Tracking failed`,
    label: `Updating Event Tracking for ${gaEventCategories.shipment} is failed`
  },
  updatedEventTracking: {
    action: `Updated ${gaEventCategories.shipment} Event Tracking`,
    label: `Updated Event Tracking for ${gaEventCategories.shipment}`
  },
  addingConsolidation: {
    action: `Adding ${gaEventCategories.shipment} Consolidation/Outbound`,
    label: `Adding Consolidation/Outbound for ${gaEventCategories.shipment}`
  },
  addingConsolidationCancelled: {
    action: `Adding ${gaEventCategories.shipment} Consolidation/Outbound cancelled`,
    label: `Adding Consolidation/Outbound for ${gaEventCategories.shipment} is cancelled`
  },
  addingConsolidationFailed: {
    action: `Adding ${gaEventCategories.shipment} Consolidation/Outbound failed`,
    label: `Adding Consolidation/Outbound for ${gaEventCategories.shipment} is failed`
  },
  addedConsolidation: {
    action: `Added ${gaEventCategories.shipment} Consolidation/Outbound`,
    label: `Added Consolidation/Outbound for ${gaEventCategories.shipment}`
  },
  updatingConsolidation: {
    action: `Updating ${gaEventCategories.shipment} Consolidation/Outbound`,
    label: `Updating Consolidation/Outbound for ${gaEventCategories.shipment}`
  },
  updatingConsolidationCancelled: {
    action: `Updating ${gaEventCategories.shipment} Consolidation/Outbound cancelled`,
    label: `Updating Consolidation/Outbound for ${gaEventCategories.shipment} is cancelled`
  },
  updatingConsolidationFailed: {
    action: `Updating ${gaEventCategories.shipment} Consolidation/Outbound failed`,
    label: `Updating Consolidation/Outbound for ${gaEventCategories.shipment} is failed`
  },
  updatedConsolidation: {
    action: `Updated ${gaEventCategories.shipment} Consolidation/Outbound`,
    label: `Updated Consolidation/Outbound for ${gaEventCategories.shipment}`
  },
  removingConsolidation: {
    action: `Removing ${gaEventCategories.shipment} Consolidation/Outbound`,
    label: `Removing Consolidation/Outbound for ${gaEventCategories.shipment}`
  },
  removingConsolidationCancelled: {
    action: `Removing ${gaEventCategories.shipment} Consolidation/Outbound cancelled`,
    label: `Removing Consolidation/Outbound for ${gaEventCategories.shipment} is cancelled`
  },
  updatingBL: {
    action: `Updating ${gaEventCategories.shipment} BL number`,
    label: `Updating BL number for ${gaEventCategories.shipment}`
  },
  updatingBLCancelled: {
    action: `Updating ${gaEventCategories.shipment} BL number cancelled`,
    label: `Updating BL number for ${gaEventCategories.shipment} is cancelled`
  },
  updatingBLFailed: {
    action: `Updating ${gaEventCategories.shipment} BL number failed`,
    label: `Updating BL number for ${gaEventCategories.shipment} is failed`
  },
  updatedBL: {
    action: `Updated ${gaEventCategories.shipment} BL number`,
    label: `Updated BL number for ${gaEventCategories.shipment}`
  },
  updatingCarrier: {
    action: `Updating ${gaEventCategories.shipment} Carrier`,
    label: `Updating Carrier for ${gaEventCategories.shipment}`
  },
  updatingCarrierCancelled: {
    action: `Updating ${gaEventCategories.shipment} Carrier cancelled`,
    label: `Updating Carrier for ${gaEventCategories.shipment} is cancelled`
  },
  updatingCarrierFailed: {
    action: `Updating ${gaEventCategories.shipment} Carrier failed`,
    label: `Updating Carrier for ${gaEventCategories.shipment} is failed`
  },
  updatedCarrier: {
    action: `Updated ${gaEventCategories.shipment} Carrier`,
    label: `Updated Carrier for ${gaEventCategories.shipment}`
  },
  headerSearchShipments: {
    action: `Header ${gaEventCategories.shipment} Search`,
    label: `Searching ${gaEventCategories.shipment} from application Header`
  },
  pinFilter: {
    action: `Overview > Pin ${gaEventCategories.shipment} Filter`,
    label: `Overview page Pinned ${gaEventCategories.shipment} Filter`
  },
  unpinFilter: {
    action: `Overview > Unpin ${gaEventCategories.shipment} Filter`,
    label: `Overview page Unpinned ${gaEventCategories.shipment} Filter`
  },
  pinAllFilter: {
    action: `Overview > Pin All ${gaEventCategories.shipment} Filters`,
    label: `Overview page Pinned all ${gaEventCategories.shipment} Filters`
  },
  unpinAllFilter: {
    action: `Overview > Unpin all ${gaEventCategories.shipment} Filters`,
    label: `Overview page Unpinned all ${gaEventCategories.shipment} Filters`
  },
  uploadDocsFromList: {
    action: `${gaEventCategories.shipment} List > Upload Documents`,
    label: `Upload Documents form ${gaEventCategories.shipment} List Page`
  },
  uploadDocsFromDetails: {
    action: `${gaEventCategories.shipment} Details > Upload Documents`,
    label: `Upload Documents form ${gaEventCategories.shipment} Details Page`
  },
  createFilter: {
    action: `Create ${gaEventCategories.shipment} Filters`,
    label: `Create ${gaEventCategories.shipment} Filters`
  },
})
// END: Shipment

function addGaEvent(event, id, value) {
  event = event.split('-')
  const label = join(compact([
    get(gaEventActions, `${event[0]}.${event[1]}.label`),
    id !== undefined ? `[id: ${id}]` : false,
    value !== undefined ? `[value: ${value}]` : false
  ]), ' ')
  const eventObject = {
    category: get(gaEventCategories, event[0], 'MICS'),
    action: get(gaEventActions, `${event[0]}.${event[1]}.action`, 'MICS Action'),
    label,
  }

  if (event[1] === 'changeDetailsTab') {
    eventObject.action = `Shipment Details -> Moved to ${get(getShipmentDetailsTabs(), `[${value}]`)} tab`
  }

  if (getGATrackId()) {
    ReactGA.event(eventObject);
  } else {
    console.log(eventObject)
  }
}

export { gaEventCategories, gaEventActions, addGaEvent }
