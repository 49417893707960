import React from 'react'
import { Timeline } from 'antd'
import { withStyles, Icon, Grid } from '@material-ui/core'
import classNames from 'classnames'
import 'antd/dist/antd.css'
import { get } from "lodash";
import moment from 'moment'

const TransportTimeline = ({ data, classes, className }) => {
  const getAvailableDate = (event) => {
    const eventStyle = {
      display: 'inline-block',
      marginRight: '5px',
      padding: '0px 3px',
      borderRadius: '2px',
      backgroundColor: '#A8A8A8',
      color: '#FFFFFF',
      fontSize: '10px',
      lineHeight: 'initial',
    }

    if (get(event, 'actual')) {
      return <label>
        {moment(get(event, 'actual.occurenceAt', '')).format('D MMM YYYY, LT')}
      </label>
    }
    else if (get(event, 'estimated')) {
      return <label>
        <span className="semi-bold"
          style={eventStyle}>E</span>
        <span>
          {moment(get(event, 'estimated.occurenceAt', '')).format('D MMM YYYY, LT')}
        </span>
      </label>
    }
    else if (get(event, 'planned')) {
      return <label>
        <span className="semi-bold"
          style={eventStyle}>P</span>
        <span>
          {moment(get(event, 'planned.occurenceAt', '')).format('D MMM YYYY, LT')}
        </span>
      </label>
    }
    else {
      return 'N/A'
    }
  }

  return (
    <div>
      {data &&
        data.map((v, i) => {
          return v.events ? (
            <React.Fragment key={`item_${i}`}>
              <Timeline.Item
                className={classNames(className, classes.item, { rounded: v.iconRounded })}
                dot={v.icon ? <Icon className='text-base'>{v.icon}</Icon> : ''}
                style={{ color: v.color || '#9FA0A4', paddingBottom: '10px' }}
              >
                <div className='flex flex-col text-12 ml-24'>
                  {v.events.map((_v, _i) => (
                    <div key={`sub-item_${_i}`} className='flex flex-row text-12'>
                      <div className='text-grey-dark mr-8'>{_v.label}</div>
                      <div className='text-grey-darkest font-bold'>{_v.value}</div>
                    </div>
                  ))}
                </div>
              </Timeline.Item>
              {v.connector && (
                <Timeline.Item
                  className={classNames(className, classes.item, { rounded: v.connector.iconRounded })}
                  dot={v.connector.icon ? <Icon className='text-base'>{v.connector.icon}</Icon> : ''}
                  style={{ color: v.connector.color || '#9FA0A4', paddingBottom: '50px' }}
                />
              )}
            </React.Fragment>
          ) : (
              <React.Fragment key={`item_${i}`}>
                <Timeline.Item
                  className={classNames(className, classes.item, { rounded: v.iconRounded })}
                  dot={v.icon ? <Icon className='text-base'>{v.icon}</Icon> : ''}
                  style={{ color: v.color || '#9FA0A4', paddingBottom: '10px' }}>
                  <div className='flex flex-col ml-8 text-12'>
                    <div className='text-grey-darkest font-bold mb-4 pt-4'>{v.place}</div>
                    <div className='text-grey-dark text-10'>{v.address}</div>
                  </div>
                </Timeline.Item>
                {v.connector && (
                  <Timeline.Item
                    className={classNames(className, classes.item, { rounded: v.connector.iconRounded })}
                    dot={v.connector.icon ? <Icon className='text-base'>{v.connector.icon}</Icon> : ''}
                    style={{
                      color: v.connector.color || '#9FA0A4',
                      paddingBottom: v.connector.events && v.connector.events.length > 0 ? '10px' : '50px'
                    }}>
                    {(v.connector.events && v.connector.events.length > 0) &&
                      v.connector.events.map((event, eventIndex) => {
                        return (
                          <Grid key={eventIndex}
                            container
                            alignItems="center"
                            className="ml-16 text-12 fg-gray mb-4">
                            <Grid item xs={6}
                              className="pr-6">{get(event, 'name')}</Grid>
                            <Grid item xs={6}>
                              {getAvailableDate(event)}
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Timeline.Item>
                )}
              </React.Fragment>
            )
        })}
    </div>
  )
}

const styles = theme => ({
  item: {
    padding: '0 0 10px',
    '&:last-child .ant-timeline-item-tail': {
      display: 'none'
    },
    '& .ant-timeline-item-tail': {
      borderLeft: '1px dashed currentColor'
    },
    '& .ant-timeline-item-head.ant-timeline-item-head-blue': {
      borderColor: 'currentColor',
      padding: '0px',
      color: 'currentColor',
      backgroundColor: 'transparent'
    },
    '&.rounded .ant-timeline-item-head': {
      display: 'flex',
      padding: 5,
      border: '1px solid currentColor',
      borderRadius: '100%',
      backgroundColor: '#FFFFFF'
    }
  }
})

export default withStyles(styles)(TransportTimeline)
