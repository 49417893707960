import React, { Component } from 'react'
import {
  Avatar,
  Button,
  Icon,
  Grid,
  Popover,
  MenuItem,
  Typography,
  withStyles
} from '@material-ui/core'
import { get } from 'lodash'
import history from '../../history'
import ProfileDetailsSidebar from './User/ProfileDetailsSidebar'
import PlantDetailsSidebar from './Plant/PlantDetailsSidebar'
import UserSettingsDialog from './User/UserSettingsDialog'

class UserMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userMenu: null,
      openPersonalDetailsSidebar: false,
      openPlantDetailsSidebar: false,
      settingsDialog: {
        open: false
      }
    }
  }

  userMenuClick = event => {
    this.setState({ userMenu: event.currentTarget })
  }

  userMenuClose = () => {
    this.setState({ userMenu: null })
  }

  openPersonalDetails = () => {
    this.setState({
      openPersonalDetailsSidebar: true,
      openPlantDetailsSidebar: false,
      userMenu: null
    })
  }

  closePersonalDetails = () => {
    this.setState({
      openPersonalDetailsSidebar: false
    })
  }

  openPlantDetails = () => {
    this.setState({
      openPersonalDetailsSidebar: false,
      openPlantDetailsSidebar: true,
      userMenu: null
    })
  }

  closePlantDetails = () => {
    this.setState({
      openPlantDetailsSidebar: false
    })
  }

  updateUserProfile = () => { }

  openAction = (type, meta = {}) => {
    this.setState({
      userMenu: null,
      [type]: {
        open: true,
        handleClose: () => this.closeAction(type),
        ...meta
      }
    })
  }

  closeAction = (type) => {
    this.setState({
      [type]: {
        open: false,
      }
    })
  }

  handleSwitchPlant = () => {
    const requestedHref = `${window.location.pathname.slice(1)}${window.location.search}`
    const { setCurrentPlant } = this.props
    setCurrentPlant(null)
    history.replace(requestedHref ? `/#redirect_href=${requestedHref}` : '/')
  }

  render() {
    const { user, logout, classes } = this.props
    const { userMenu, settingsDialog } = this.state

    return (
      <React.Fragment>
        <Button className='h-52' onClick={this.userMenuClick}>
          <Avatar className={classes.avatar}>
            {`${get(user, 'firstname[0]', '')}${get(user, 'lastname[0]', '')}` || '!'}
          </Avatar>

          <Icon className='text-16 ml-8 hidden sm:flex' variant='action'>
            keyboard_arrow_down
          </Icon>
        </Button>

        <Popover open={Boolean(userMenu)}
          anchorEl={userMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          classes={{
            paper: 'page-user-menu'
          }}
          onClose={this.userMenuClose}>
          <React.Fragment>
            <MenuItem className='p-0'
              onClick={this.handleSwitchPlant}>
              <Grid container
                alignItems="center">
                <Grid item xs={3}
                  className="py-12 text-center">
                  <img src='assets/images/icons/user-menu/switch-plant.svg'
                    alt='switch-plant' />
                </Grid>
                <Grid item xs={9}
                  className="py-12 border-solid border-b-1 border-gray">
                  <Typography className='text-12 bold'>
                    Switch plants</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem className='p-0'
              onClick={() => {
                this.openPersonalDetails()
              }}>
              <Grid container
                alignItems="center">
                <Grid item xs={3}
                  className="py-12 text-center">
                  <img src='assets/images/icons/user-menu/profile-detail.svg'
                    alt='profile-detail' />
                </Grid>
                <Grid item xs={9}
                  className="py-12 border-solid border-b-1 border-gray">
                  <Typography className='text-12 bold'>
                    Profile details</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem className='p-0'
              onClick={() => {
                this.openPlantDetails()
              }}>
              <Grid container
                alignItems="center">
                <Grid item xs={3}
                  className="py-12 text-center">
                  <img src='assets/images/icons/user-menu/plant-change.svg'
                    alt='plant-details' />
                </Grid>
                <Grid item xs={9}
                  className="py-12 border-solid border-b-1 border-gray">
                  <Typography className='text-12 bold'>
                    Plant details</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem className='p-0'
              onClick={() => this.openAction('settingsDialog')}>
              <Grid container
                alignItems="center">
                <Grid item xs={3}
                  className="py-12 text-center">
                  <Icon className="fas fa-eye text-16 fg-gray w-auto align-top" />
                </Grid>
                <Grid item xs={9}
                  className="py-12 border-solid border-b-1 border-gray">
                  <Typography className='text-12 bold'>
                    Theme</Typography>
                </Grid>
              </Grid>
            </MenuItem>

            <MenuItem className='p-0'
              onClick={() => {
                logout()
                this.userMenuClose()
              }}>
              <Grid container
                alignItems="center">
                <Grid item xs={3}
                  className="py-12 text-center">
                  <img src='assets/images/icons/user-menu/logout.svg'
                    alt='logout' />
                </Grid>
                <Grid item xs={9}
                  className="py-12">
                  <Typography className='text-12 bold'>Logout</Typography>
                </Grid>
              </Grid>
            </MenuItem>
          </React.Fragment>
        </Popover>

        <PlantDetailsSidebar
          open={this.state.openPlantDetailsSidebar}
          closeSideBar={() => this.closePlantDetails()}
          plantInfo={{}}
        />

        <ProfileDetailsSidebar
          open={this.state.openPersonalDetailsSidebar}
          closeSideBar={() => this.closePersonalDetails()}
        ></ProfileDetailsSidebar>

        <UserSettingsDialog {...settingsDialog} />
      </React.Fragment >
    )
  }
}

const styles = () => ({
  avatar: {
    backgroundColor: '#3DCD58',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    border: '2px solid #009530',
    height: '35px',
    width: '35px',
    fontSize: 13,
    fontFamily: 'IBMPlexSans-Bold'
  }
})

export default withStyles(styles)(UserMenu)
