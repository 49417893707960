import React from 'react'
import { Route, Switch } from 'react-router-dom'

import NotFoundPage from './containers/NotFoundPage'
import LoginEmailPage from 'auth/containers/LoginEmailPage'
import LoginPage from 'auth/containers/LoginPage'
import RefreshToken from 'auth/containers/RefreshToken'
import MapViewPage from 'map-view/containers/MapViewPage'
import InTransitPage from 'in-transit/containers/InTransitPage'
import InboundPage from 'inbound/containers/InboundPage'
import ManageBookingPage from 'manage-booking/containers/ManageBookingPage'
import ManageIncomingPage from 'manage-incoming/containers/ManageIncomingPage'
import InboundSchedulePage from 'inbound-schedule/containers/InboundSchedulePage'
import SchedulePage from 'schedule/containers/SchedulePage'
import ExceptionPage from 'exceptions/containers/ExceptionsPage'
import WelcomePage from 'welcome/containers/WelcomePage'
import UsersPage from 'users/containers/UsersPage'
import ClosedPage from 'closed/containers/ClosedPage'
import CompletedPage from 'completed/containers/CompletedPage'
import CancelledPage from 'cancelled/containers/CancelledPage'
import TrackerPage from 'tracker/containers/TrackerPage'
import SettingsPage from 'settings/containers/SettingsPage'
import ProtectedRoute from './components/AuthGuards/ProtectedRoute'
import LayoutPage from './containers/Layout/LayoutPage'
import ShipmentDetailsPage from 'shipment-details/containers/ShipmentDetailsPage'
import OverviewPage from 'overview/containers/OverviewPage'
import BadPermPage from './containers/BadPermPage'
import Analytics from 'analytics/containers/Analytics';
import NoticeBoard from 'notice-board/containers/NoticeBoard';
import EmailTracker from 'email-tracker/containers/EmailTracker';
import DecoupledInbound from 'decoupled-inbound/containers/InboundEmails';
import ErpTransmissions from 'erp-transmissions/containers/ErpTransmissions';
import DeliveringPage from 'delivering/containers/DeliveringPage';
import DeliveredPage from 'delivered/containers/DeliveredPage';
import TasksPage from 'tasks/containers/TasksPage';
import TableauDashboard from 'analytics/containers/TableauDashboard';
import { AutoLoginRedirect } from "../core/components";
import AuthenticateToken from "../core/components/AuthGuards/AuthenticateToken";
import SSORedirect from "../core/components/AuthGuards/SSORedirect";
import packageInfo from '../../package.json';

import { FuseMessage } from './components';

const wrapWithLayout = Page => props => (
  <LayoutPage>
    <Page {...props} />
  </LayoutPage>
)

const Routes = () => (
  <>
    <Switch>
      <Route path='/login' component={LoginEmailPage} />
      <Route path='/:organizationId/login' component={LoginPage} />
      <Route exact path='/auto-login' component={AutoLoginRedirect} />
      <Route exact path='/authenticate/:status' component={AuthenticateToken} />
      <Route exact path='/oauth2/:identifier/redirect' component={SSORedirect} />
      <Route exact path='/client/info'>
        {JSON.stringify({ version: packageInfo.version })}
      </Route>
      <ProtectedRoute exact path='/' component={WelcomePage} />
      <ProtectedRoute exact path='/refresh-token' component={RefreshToken} />

      {/* Dashboard routes */}
      <ProtectedRoute exact path='/map-view' component={wrapWithLayout(MapViewPage)} plantSelected={true} />
      <ProtectedRoute exact path='/in-transit' component={wrapWithLayout(InTransitPage)} plantSelected={true} />
      <ProtectedRoute exact path='/in-transit/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/manage-incoming' component={wrapWithLayout(ManageIncomingPage)} plantSelected={true} />
      <ProtectedRoute exact path='/manage-incoming/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/inbound-schedule' component={wrapWithLayout(InboundSchedulePage)} plantSelected={true} />
      <ProtectedRoute exact path='/inbound-schedule/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/inbound' component={wrapWithLayout(InboundPage)} plantSelected={true} />
      <ProtectedRoute exact path='/inbound/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/manage-booking' component={wrapWithLayout(ManageBookingPage)} plantSelected={true} />
      <ProtectedRoute exact path='/schedule' component={wrapWithLayout(SchedulePage)} plantSelected={true} />
      <ProtectedRoute exact path='/schedule/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/exceptions' component={wrapWithLayout(ExceptionPage)} plantSelected={true} />
      <ProtectedRoute exact path='/exceptions/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/shipment-details' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/completed' component={wrapWithLayout(CompletedPage)} plantSelected={true} />
      <ProtectedRoute exact path='/completed/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/cancelled' component={wrapWithLayout(CancelledPage)} plantSelected={true} />
      <ProtectedRoute exact path='/cancelled/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/users' component={wrapWithLayout(UsersPage)} plantSelected={true} />
      <ProtectedRoute exact path='/closed' component={wrapWithLayout(ClosedPage)} plantSelected={true} />
      <ProtectedRoute exact path='/tracker' component={wrapWithLayout(TrackerPage)} plantSelected={true} />
      <ProtectedRoute exact path='/overview' component={wrapWithLayout(OverviewPage)} plantSelected={true} />
      <ProtectedRoute exact path='/settings' component={wrapWithLayout(SettingsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/analytics' component={wrapWithLayout(Analytics)} plantSelected={false} />
      <ProtectedRoute exact path='/analytics/:sheet' component={wrapWithLayout(TableauDashboard)} plantSelected={false} />
      <ProtectedRoute exact path='/notice-board' component={wrapWithLayout(NoticeBoard)} plantSelected={true} />
      <ProtectedRoute exact path='/notice-board/:id' component={wrapWithLayout(NoticeBoard)} plantSelected={true} />
      <ProtectedRoute exact path='/email-tracker' component={wrapWithLayout(EmailTracker)} plantSelected={true} />
      <ProtectedRoute exact path='/decoupled-inbound' component={wrapWithLayout(DecoupledInbound)} plantSelected={true} />
      <ProtectedRoute exact path='/erp-transmissions' component={wrapWithLayout(ErpTransmissions)} plantSelected={true} />
      <ProtectedRoute exact path='/delivering' component={wrapWithLayout(DeliveringPage)} plantSelected={true} />
      <ProtectedRoute exact path='/delivering/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/delivered' component={wrapWithLayout(DeliveredPage)} plantSelected={true} />
      <ProtectedRoute exact path='/delivered/:id' component={wrapWithLayout(ShipmentDetailsPage)} plantSelected={true} />
      <ProtectedRoute exact path='/tasks' component={wrapWithLayout(TasksPage)} plantSelected={true} />
      <Route path='/forbidden' component={BadPermPage} />
      <Route path='*' component={NotFoundPage} />
    </Switch>
    <FuseMessage />
  </>
)

export default Routes
