import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  eventsNotification
  appTheme
  scheduleDefaultMode
  shipmentCardPrimaryId
  `
)
