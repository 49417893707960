import moment from 'moment'

export function formatTimestamp(timestamp = null, format = null, hour12 = true, invalidateBlank = true) {
  if (!timestamp) {
    return ''
  }

  if (!invalidateBlank) {
    return ''
  }

  switch (format) {
    case 'date-time': {
      if (hour12) {
        return moment(timestamp).format('D MMM YYYY, LT')
      } else {
        return moment(timestamp).format('D MMM YYYY, HH:mm')
      }
    }
    case 'date-time-input': {
      var dateTime = new Date(timestamp)
      return dateTime.getFullYear()
        + '-' + dateTime.getMonth()
        + '-' + dateTime.getDay()
        + 'T' + dateTime.getHours()
        + ':' + dateTime.getMinutes()
    }
    case 'date-input': {
      var date = new Date(timestamp)
      return date.getFullYear()
        + '-' + date.getMonth()
        + '-' + date.getDay()
    }

    default:
      return new Date(timestamp).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
  }
}
