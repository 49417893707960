import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Link,
  withStyles,
  Button,
  Icon,
  Tooltip
} from '@material-ui/core'
import {
  FuseButtonToggle,
  FuseCheckBox
} from '../../core/components'
import { get, filter, find, uniq, sumBy, groupBy } from 'lodash'
import EditIcon from '@material-ui/icons/Edit'
import CSInfoSidebar from './CSInfoSideBar'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { formatTimestamp } from '../../core/helpers'
import RemoveOutboundDialog from './RemoveOutboundDialog'

const InfoTooltip = withStyles(theme => ({
  tooltip: {
    fontFamily: 'IBMPlexSans',
    fontSize: 11,
    maxWidth: 220,
  },
}))(Tooltip);

const BookingDetails = ({
  shipment,
  classes,
  onBLClick,
  currentPlant,
  onCarrierClick,
  updateShipmentCS,
  onBookingChange,
  onCSAdd,
  copyToClipboard }) => {
  const [shipmentView, setShipmentView] = useState('consolidation')
  const [hasConsolidations, setHasConsolidations] = useState(false)
  const [csInfo, setCsInfo] = useState([])
  const [selectedOutbounds, setSelectedOutbounds] = useState([])
  const [removeOutboundsDialog, setRemoveOutboundsDialog] = useState(false)
  const groupedCsCodes = groupBy(get(shipment, 'consolidations', []).map(i => ({ ...i, containerId: get(i, 'containerId', '') })), 'containerId')
  const { transportMethod } = shipment

  useEffect(() => {
    const consolidations = get(shipment, 'consolidations', [])
    setHasConsolidations(consolidations.length > 0 ? true : false)
  }, [shipment])

  const updateCS = async data => {
    await updateShipmentCS({ data }, shipment._id)
    setCsInfo([])
  }

  let voyage = []

  if (shipment.vesselSchedule) {
    if (shipment.vesselSchedule.FirstPOL) {
      voyage.push(shipment.vesselSchedule.FirstPOL)
    }

    if (shipment.vesselSchedule.transhipmentPorts && shipment.vesselSchedule.transhipmentPorts.length > 0) {
      voyage = voyage.concat(shipment.vesselSchedule.transhipmentPorts)
    }
  }

  const handleShipmentViewChange = view => {
    setShipmentView(view)
  }

  let billOfLadingCount =
    get(shipment, 'referenceNos.HBLNo', []).filter(v => !!v).length +
    get(shipment, 'referenceNos.MBLNo', []).filter(v => !!v).length

  const handleOutboundSelection = (event) => {
    const { name, value, checked } = event.target
    const outbounds = get(shipment, 'outbounds', [])

    if (name === 'csCodeCheckBox') {
      const existingOutbounds = selectedOutbounds.map(item => get(item, '_id'))
      const outboundsArr = filter(outbounds, item => {
        return (get(item, 'meta.CSCode') === value &&
          !existingOutbounds.includes(get(item, '_id'))
        )
      })
      if (checked) {
        setSelectedOutbounds([...selectedOutbounds, ...outboundsArr])
      } else {
        setSelectedOutbounds(filter(selectedOutbounds, item =>
          (get(item, 'meta.CSCode') !== value)
        ))
      }
    } else {
      const outboundsObj = find(outbounds, { _id: value })
      if (checked) {
        setSelectedOutbounds([...selectedOutbounds, outboundsObj])
      } else {
        setSelectedOutbounds(filter(selectedOutbounds, item =>
          (get(item, '_id') !== value)
        ))
      }
    }
  }

  return (
    <Grid container className='booking-details'>

      {/* Shipping details */}
      <Grid item xs={12}
        className='shipment-details-container-section'>
        <Grid container>
          <Grid item xs={12}>
            <Typography className='section__title bold mb-12'>
              Shipping details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    Shipment mode</Grid>
                  <Grid item xs={12}
                    className='booking-details-value capitalize'>
                    {get(shipment, 'transportMethod', <Skeleton width={80} />)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    Delivery type</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    {get(shipment, 'type', <Skeleton width={80} />)}
                  </Grid>
                </Grid>
              </Grid>
              {get(shipment, 'specialLabels', []).length > 0 && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Special label</Grid>
                    <Grid item xs={12}
                      className='booking-details-value uppercase'>
                      {get(shipment, 'specialLabels', []).join(', ')}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={3}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    Incoterm</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    {get(shipment, 'incoterm', <Skeleton width={80} />)}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    Planned GI</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    {get(shipment, 'summary.plannedGIDate') === undefined && <Skeleton width={80} />}
                    {get(shipment, 'summary.plannedGIDate', []).map(i => formatTimestamp(i)).join(', ')}
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* END of Shipping details */}

      {/* Partners */}
      <Grid item xs={12}
        className='shipment-details-container-section'>
        <Grid container>
          <Grid item xs={12}>
            <Typography className='section__title bold mb-12'>
              Partners</Typography>
          </Grid>
          <Grid item xs={12}>
            {!get(shipment, 'actors') &&
              <Grid container spacing={2}>
                {[1, 2, 3, 4].map(rootItem =>
                  <Grid item key={rootItem}
                    xs={12} sm={6} md={3}>
                    <Skeleton height={35} />
                  </Grid>
                )}
              </Grid>
            }
            <Grid container spacing={2}>
              {get(shipment, 'actors.shipper[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Shipper</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.shipper[0].name')}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {get(shipment, 'actors.obl[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Export logistic partner</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.obl[0].name')}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {get(shipment, 'actors.obc[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Export custom broker</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.obc[0].name')}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {get(shipment, 'actors.ofp[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Export freight partner</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.ofp[0].name')}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {get(shipment, 'actors.consignee[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Consignee</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.consignee', []).map((item, itemIndex) => {
                        return (
                          <label key={itemIndex}
                            className="block">{item.name}</label>
                        )
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {get(shipment, 'actors.ibl[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Import logistic partner</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.ibl[0].name')}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {get(shipment, 'actors.ibc[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Import custom broker</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.ibc[0].name')}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {get(shipment, 'actors.ifp[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Import freight partner</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.ifp[0].name')}
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {get(shipment, 'actors.dropHub[0]') && (
                <Grid item xs={12} sm={6} md={3}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Drop-Hub</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'actors.dropHub[0].name')}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={3}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    Ship to Party Codes</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    {!get(shipment, 'outbounds') &&
                      <Skeleton width={80} />}
                    {get(shipment, 'outbounds') &&
                      uniq(get(shipment, 'outbounds', []).map(item =>
                        get(item, 'shipToParty.code')))
                        .filter(Boolean)
                        .join(', ')
                    }
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* END of Partners */}

      {/* Booking details */}
      <Grid item xs={12}
        className='shipment-details-container-section'>
        <Grid container>
          <Grid item xs={12}>
            <Typography className='section__title bold mb-12'>
              Booking details</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={4}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    Booking ref no</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    {get(shipment, 'referenceNos') === undefined &&
                      <Skeleton width={80} />
                    }
                    {get(shipment, 'referenceNos') !== undefined &&
                      get(shipment, 'referenceNos.bookingReferenceNo')
                    }
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    Bill of lading no</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    <Grid container>
                      <Grid item className="pr-6">
                        <Grid container alignItems="center">
                          <label className="mr-6">
                            {`${get(shipment, 'referenceNos.MBLNo', []).filter(Boolean).length} ${transportMethod === 'air' ? 'MAWB' : 'MBL'}(s)`}
                          </label>
                          {get(shipment, 'referenceNos.MBLNo', []).filter(Boolean).length > 0 &&
                            <InfoTooltip title={
                              <span>
                                {get(shipment, 'referenceNos.MBLNo', []).filter(Boolean).map((item, itemIndex) =>
                                  <label className="block" key={itemIndex}>{item}</label>
                                )}
                              </span>}>
                              <Icon className="text-16 cursor-pointer fg-gray">info</Icon>
                            </InfoTooltip>
                          }
                        </Grid>
                      </Grid>
                      <Grid item className="pl-6">
                        <Grid container alignItems="center">
                          <label className="mr-6">
                            {`${get(shipment, 'referenceNos.HBLNo', []).filter(Boolean).length} ${transportMethod === 'air' ? 'HAWB' : 'HBL'}(s)`}
                          </label>
                          {get(shipment, 'referenceNos.HBLNo', []).filter(Boolean).length > 0 &&
                            <InfoTooltip title={
                              <span>
                                {get(shipment, 'referenceNos.HBLNo', []).filter(Boolean).map((item, itemIndex) =>
                                  <label className="block" key={itemIndex}>{item}</label>
                                )}
                              </span>}>
                              <Icon className="text-16 cursor-pointer fg-gray">info</Icon>
                            </InfoTooltip>
                          }
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="pt-4">
                        <label className='fg-green border-dashed border-green border-b-1 cursor-pointer'
                          onClick={onBLClick}>
                          {billOfLadingCount
                            ? `${billOfLadingCount} Bill of lading(s)`
                            : get(currentPlant, 'type') === 'shipper' || get(currentPlant, 'type') === 'ofp'
                              ? 'Add BL no'
                              : '0 Bill of lading(s)'}
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    Carrier Booking No.</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    {get(shipment, 'referenceNos') === undefined &&
                      <Skeleton width={80} />
                    }
                    {get(shipment, 'referenceNos') !== undefined &&
                      get(shipment, 'referenceNos.carrierBookingNo')
                    }
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    {transportMethod === 'air' ? 'Airline name' : 'Carrier name'}</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    <label className='fg-green border-dashed border-green border-b-1 cursor-pointer'
                      onClick={onCarrierClick}>
                      {get(shipment, 'freightOperatorInfo.name', `${transportMethod === 'air' ? 'Airline name' : 'Add Carrier name'}`) ||
                        `${transportMethod === 'air' ? 'Airline name' : 'Add Carrier name'}`}
                    </label>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Grid container>
                  <Grid item xs={12}
                    className='booking-details-name'>
                    {transportMethod === 'air' ? 'IATA' : 'SCAC code'}</Grid>
                  <Grid item xs={12}
                    className='booking-details-value'>
                    <label className='fg-green border-dashed border-green border-b-1 cursor-pointer'
                      onClick={onCarrierClick}>
                      {get(shipment, 'freightOperatorInfo.identifier.value', `${transportMethod === 'air' ? 'Add IATA' : 'Add SCAC code'}`) ||
                        `${transportMethod === 'air' ? 'Add IATA' : 'Add SCAC code'}`}
                    </label>
                  </Grid>
                </Grid>
              </Grid>

              {!get(shipment, 'spotMode.enabled') &&
                <Grid item xs={12} sm={6} md={4}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Itinerary IDs</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {!get(shipment, '_id') && <Skeleton width={80} />}
                      {get(shipment, 'executableServiceCards', []).map(item =>
                        get(item, 'meta.itineraryId'))
                        .filter(Boolean)
                        .join(', ')
                      }
                    </Grid>
                  </Grid>
                </Grid>
              }

              {get(shipment, 'spotMode.enabled') &&
                <Grid item xs={12} sm={6} md={4}>
                  <Grid container>
                    <Grid item xs={12}
                      className='booking-details-name'>
                      Quote IDs</Grid>
                    <Grid item xs={12}
                      className='booking-details-value'>
                      {get(shipment, 'spotMode.quoteId', <Skeleton width={80} />)}
                    </Grid>
                  </Grid>
                </Grid>
              }

            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* END of Booking details */}

      {voyage.length > 0 && (
        // Vessel details
        <Grid item xs={12}
          className='shipment-details-container-section'>
          <Grid container>
            <Grid item xs={12}>
              <Typography className='section__title bold mb-12'>
                {transportMethod === 'air' ? 'Aircraft details' : 'Vessel details'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container className="overflow-x-auto">
                <table className={classes.tableStyle}>
                  <tbody>
                    <tr>
                      <td className='booking-details-name pr-12'>{transportMethod === 'air' ? 'Aircraft name' : 'Vessel name'}</td>
                      <td className='booking-details-name pr-12'>{transportMethod === 'air' ? 'Tail no' : 'IMO'}</td>
                      <td className='booking-details-name pr-12'>{transportMethod === 'air' ? 'Flight no' : 'Voyage no'}</td>
                      <td className='booking-details-name pr-12'>Origin&nbsp;port</td>
                      <td className='booking-details-name pr-12'>Destination&nbsp;port</td>
                    </tr>
                    {voyage.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className='booking-details-value' valign="top">{get(item, 'vessel.name', '')}</td>
                          <td className='booking-details-value' valign="top">{get(item, 'vessel.IMO', '')}</td>
                          <td className='booking-details-value' valign="top">{get(item, 'voyageNumber', '')}</td>
                          <td className='booking-details-value' valign="top">
                            <Typography className="semi-bold text-12">
                              {`${get(item, 'port.name', '')} (${get(item, 'port.locode', '')})`}
                            </Typography>
                            <Typography className="semi-bold text-12 text-grey">
                              {`ETA: ${formatTimestamp(get(item, 'eta', ''), 'date-time')}`}
                            </Typography>
                            <Typography className="semi-bold text-12 text-grey">
                              {`ETD: ${formatTimestamp(get(item, 'etd', ''), 'date-time')}`}
                            </Typography>
                          </td>
                          {index === voyage.length - 1 && (
                            <td className='booking-details-value' valign="top">
                              <Typography className="semi-bold text-12">
                                {`${get(shipment, 'vesselSchedule.LastPOD.port.name', '')} (${get(shipment, 'vesselSchedule.LastPOD.port.locode', '')})`}
                              </Typography>
                              <Typography className="semi-bold text-12 text-grey">
                                {`ETA: ${formatTimestamp(get(item, 'etaNextPort', ''), 'date-time')}`}
                              </Typography>
                            </td>
                          )}
                          {index < voyage.length - 1 && voyage[index + 1] && (
                            <td className='booking-details-value' valign="top">
                              <Typography className="semi-bold text-12">
                                {`${voyage[index + 1].port.name} (${voyage[index + 1].port.locode})`}
                              </Typography>
                              <Typography className="semi-bold text-12 text-grey">
                                {`ETA: ${formatTimestamp(get(item, 'etaNextPort', ''), 'date-time')}`}
                              </Typography>
                            </td>
                          )}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        // END of Vessel details
      )}

      {/* Consolidation & Outbounds */}
      <Grid item xs={12} className='shipment-details-container-section'>
        <Grid container>
          <Grid item xs={12}>
            <Grid container
              alignItems='center'
              justify="space-between"
              className='mb-20'
              spacing={2}>
              <Grid item xs={12} sm={12} md={4}>
                <Typography className='section__title bold'>
                  {hasConsolidations ? 'Consolidation & Delivery' : 'Delivery'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <Grid container
                  justify='flex-end'
                  spacing={3}>
                  <Grid item>
                    {get(currentPlant, 'type') === 'shipper' &&
                      <Grid container
                        alignItems="center"
                        spacing={1}>
                        <Grid item>
                          <Button className={
                            classNames(
                              classes.csActionButtons,
                              classes.csActionGreen,
                            )}
                            disabled={selectedOutbounds.length !== 0}
                            onClick={onCSAdd}>Add</Button>
                        </Grid>
                        <Grid item>
                          <Button className={
                            classNames(
                              classes.csActionButtons,
                              classes.csActionRed,
                            )}
                            disabled={selectedOutbounds.length === 0 ||
                              selectedOutbounds.length === get(shipment, 'outbounds', []).length}
                            onClick={() => setRemoveOutboundsDialog(true)}>Remove</Button>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                  {hasConsolidations && (
                    <Grid item>
                      <FuseButtonToggle
                        data={[
                          { label: 'Consolidation', value: 'consolidation' },
                          { label: 'Delivery', value: 'outbounds' }
                        ]}
                        value={shipmentView}
                        buttonClass='text-10 py-4 px-20'
                        onChange={value => handleShipmentViewChange(value)}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              {shipmentView === 'consolidation' && (
                <Grid container spacing={4}>
                  {Object.keys(groupedCsCodes).map((key, index) => {
                    const currentCS = get(groupedCsCodes, key, [])
                    let containerName = ''
                    let containerType, containerEquipmentNo, containerCarrierSealNo, containerSupplierSealNo

                    if (key) {
                      let container = get(shipment, 'containers', [])
                        .find(el => el._id === key)
                      let containerIndex = get(shipment, 'containers', [])
                        .findIndex(el => el._id === key)
                      if (container) {
                        containerType = get(container, 'type')
                        containerEquipmentNo = get(container, 'equipmentNo')
                        containerCarrierSealNo = get(container, 'carrierSealNumber')
                        containerSupplierSealNo = get(container, 'supplierSealNumber')
                        if (get(container, 'equipmentNo')) {
                          containerName = get(container, 'equipmentNo', '')
                        } else {
                          containerName = `${get(container, 'type', '')} (${containerIndex + 1})`
                        }
                      }
                    }

                    return (
                      <Grid item xs={12} key={index} className="overflow-auto">
                        {containerName !== '' &&
                          <Typography className="mb-12 bold text-14 fg-gray">{`Container: ${containerName}`}</Typography>
                        }
                        <table className={classNames(classes.tableStyle, 'border-b-1 border-gray')}>
                          <tbody>
                            <tr>
                              {get(currentPlant, 'type') === 'shipper' &&
                                <td className='booking-details-name pb-8 pr-12'></td>
                              }
                              <td className='booking-details-name pb-8 pr-12'>
                                <Grid container
                                  alignItems="center">
                                  <Grid item className="pr-12">Number</Grid>
                                  {currentCS.length > 0 &&
                                    <InfoTooltip title="Copy CS Codes" placement="top">
                                      <Icon className="fas fa-copy text-14 fg-gray cursor-pointer"
                                        onClick={() => {
                                          const csCodes = currentCS
                                            .map(item => item.CSCode)
                                            .filter(Boolean)
                                            .join('\n')
                                          copyToClipboard(csCodes)
                                        }} />
                                    </InfoTooltip>
                                  }
                                </Grid>
                              </td>
                              {/* @todo: Remove this check in future */}
                              {get(currentPlant, 'type') === 'shipper' &&
                                <td className='booking-details-name pb-8 pr-12'>Goods&nbsp;type</td>
                              }
                              <td className='booking-details-name pb-8 pr-12'>Special&nbsp;label</td>
                              <td className='booking-details-name pb-8 pr-12'>Ship&nbsp;to&nbsp;party</td>
                              <td className='booking-details-name pb-8 pr-12'>Invoice</td>
                              <td className='booking-details-name pb-8 pr-12'>Delivery</td>
                              <td className='booking-details-name pb-8 pr-12'>Packages</td>
                              <td className='booking-details-name pb-8 pr-12'>Weight</td>
                              <td className='booking-details-name pb-8 pr-12'>Weight&nbsp;unit</td>
                              <td className='booking-details-name pb-8 pr-12'>Weight&nbsp;type</td>
                              <td className='booking-details-name pb-8 pr-12'>Volume</td>
                              <td className='booking-details-name pb-8 pr-12'>Volume&nbsp;unit</td>
                              <td className='booking-details-name pb-8 pr-12'>Volume&nbsp;type</td>
                              {get(currentPlant, 'type') === 'shipper' &&
                                <td className='booking-details-name pb-8'
                                  align="center">
                                  <Link className={classes.editAll}
                                    onClick={() => setCsInfo(currentCS)}>
                                    Edit&nbsp;all</Link>
                                </td>
                              }
                            </tr>

                            {currentCS.length === 0 && (
                              <tr>
                                <td colSpan={get(currentPlant, 'type') === 'shipper' ? 13 : 10}
                                  align='center'
                                  className='booking-details-name bold p-8'>
                                  No Consolidations</td>
                              </tr>
                            )}

                            {currentCS.map((consolidation, index) => {
                              return (
                                <tr key={index}>
                                  {get(currentPlant, 'type') === 'shipper' &&
                                    <td className='booking-details-value w-40'>
                                      <FuseCheckBox value={get(consolidation, 'CSCode')}
                                        name="csCodeCheckBox"
                                        checked={filter(selectedOutbounds, {
                                          meta: {
                                            CSCode: get(consolidation, 'CSCode')
                                          }
                                        }).length === get(consolidation, 'outbounds', 0)}
                                        className='p-0'
                                        onChange={handleOutboundSelection}
                                      />
                                    </td>
                                  }
                                  <td className='booking-details-value'>
                                    {get(consolidation, 'CSCode')}</td>
                                  {/* @todo: Remove this check in future */}
                                  {get(currentPlant, 'type') === 'shipper' &&
                                    <td className='booking-details-value'>
                                      <span className={'uppercase'
                                        + (get(consolidation, 'goodsType', '').toLowerCase() === 'dg' ? ' fg-red' : '')
                                        + (get(consolidation, 'goodsType', '').toLowerCase() === 'normal' ? ' fg-blue' : '')}>
                                        {get(consolidation, 'goodsType')}</span>
                                    </td>
                                  }
                                  <td className='booking-details-value uppercase'>
                                    <label className="fg-blue">
                                      {get(consolidation, 'specialLabels', []).join(', ')}
                                    </label>
                                  </td>
                                  <td className='booking-details-value'>
                                    {get(consolidation, 'shipToParty')}</td>
                                  <td className='booking-details-value'>{get(consolidation, 'invoice', '')}</td>
                                  <td className='booking-details-value'>
                                    {get(consolidation, 'outbounds', 0)}&nbsp;deliveries</td>
                                  <td className='booking-details-value'>
                                    {get(consolidation, 'packages', 0)}</td>
                                  <td className='booking-details-value'>
                                    {get(consolidation, 'packagedWeight.value') !== undefined &&
                                      <label>
                                        {get(consolidation, 'packagedWeight.value', 0).toFixed(3)}
                                      </label>
                                    }
                                    {get(consolidation, 'packagedWeight.value') === undefined &&
                                      <InfoTooltip title="Estimated Weight">
                                        <label className="fg-orange">
                                          {get(consolidation, 'weight.value').toFixed(3)}
                                        </label>
                                      </InfoTooltip>
                                    }
                                  </td>
                                  <td className='booking-details-value'>{get(consolidation, 'weight.unit', 'KG')}</td>
                                  <td className='booking-details-value'>{get(consolidation, 'weight.type')}</td>
                                  <td className='booking-details-value'>
                                    {get(consolidation, 'packagedVolume.value') !== undefined &&
                                      <label>
                                        {get(consolidation, 'packagedVolume.value').toFixed(3)}
                                      </label>
                                    }
                                    {get(consolidation, 'packagedVolume.value') === undefined &&
                                      <InfoTooltip title="Estimated Volume">
                                        <label className="fg-orange">
                                          {get(consolidation, 'volume.value').toFixed(3)}
                                        </label>
                                      </InfoTooltip>
                                    }
                                  </td>
                                  <td className='booking-details-value'>{get(consolidation, 'volume.unit', 'KG')}</td>
                                  <td className='booking-details-value'>{get(consolidation, 'volume.type')}</td>
                                  {get(currentPlant, 'type') === 'shipper' &&
                                    <td className='booking-details-value'
                                      align="center">
                                      <EditIcon className={classes.editCS}
                                        onClick={() => setCsInfo([consolidation])} />
                                    </td>
                                  }
                                </tr>
                              )
                            })}

                            {currentCS.length > 0 &&
                              <tr className="border-t-1 border-gray">
                                <td colSpan={get(currentPlant, 'type') === 'shipper' ? 6 : 4}
                                  align="right"
                                  className='booking-details-value py-4'>Total</td>
                                <td className='booking-details-value py-4'>
                                  {sumBy(currentCS, i => i.outbounds || 0)}&nbsp;deliveries</td>
                                <td className='booking-details-value py-4'>
                                  {sumBy(currentCS, i => i.packages || 0)}
                                </td>
                                <td className='booking-details-value py-4'>
                                  {!find(currentCS, i => get(i, 'packagedWeight.value') === undefined) &&
                                    <label>
                                      {sumBy(currentCS, (sumItem) => {
                                        if (get(sumItem, 'packagedWeight.value') !== undefined)
                                          return get(sumItem, 'packagedWeight.value')
                                        else
                                          return get(sumItem, 'weight.value')
                                      }).toFixed(3)}
                                    </label>
                                  }
                                  {find(currentCS, i => get(i, 'packagedWeight.value') === undefined) &&
                                    <InfoTooltip title="Estimated total weight">
                                      <label className="fg-orange">
                                        {sumBy(currentCS, (sumItem) => {
                                          if (get(sumItem, 'packagedWeight.value') !== undefined)
                                            return get(sumItem, 'packagedWeight.value', 0)
                                          else
                                            return get(sumItem, 'weight.value', 0)
                                        }).toFixed(3)}
                                      </label>
                                    </InfoTooltip>
                                  }
                                </td>
                                <td colSpan={2}
                                  className='booking-details-value py-4'>
                                  {get(currentCS, '[0].weight.unit', 'KG')}
                                </td>
                                <td className='booking-details-value py-4'>
                                  {!find(currentCS, i => get(i, 'packagedVolume.value') === undefined) &&
                                    <label>
                                      {sumBy(currentCS, (sumItem) => {
                                        if (get(sumItem, 'packagedVolume.value') !== undefined)
                                          return get(sumItem, 'packagedVolume.value', 0)
                                        else
                                          return get(sumItem, 'volume.value', 0)
                                      }).toFixed(3)}
                                    </label>
                                  }
                                  {find(currentCS, i => get(i, 'packagedVolume.value') === undefined) &&
                                    <InfoTooltip title="Estimated total volume">
                                      <label className="fg-orange">
                                        {sumBy(currentCS, (sumItem) => {
                                          if (get(sumItem, 'packagedVolume.value') !== undefined)
                                            return get(sumItem, 'packagedVolume.value', 0)
                                          else
                                            return get(sumItem, 'volume.value', 0)
                                        }).toFixed(3)}
                                      </label>
                                    </InfoTooltip>
                                  }
                                </td>
                                <td colSpan={get(currentPlant, 'type') === 'shipper' ? 5 : 4}
                                  className='booking-details-value py-4'>{get(currentCS, '[0].volume.unit', 'KG')}</td>
                              </tr>
                            }

                            {(currentCS.length > 0 && key !== '' && key !== undefined && key !== null) &&
                              <React.Fragment>
                                {containerType &&
                                  <tr className="border-t-1 border-gray">
                                    <td className="booking-details-value py-4"
                                      colSpan={get(currentPlant, 'type') === 'shipper' ? 6 : 4}
                                      align="right">
                                      Container Type</td>
                                    <td className="booking-details-value py-4"
                                      colSpan={get(currentPlant, 'type') === 'shipper' ? 9 : 8}>
                                      {containerType}</td>
                                  </tr>
                                }
                                {containerEquipmentNo &&
                                  <tr className="border-t-1 border-gray">
                                    <td className="booking-details-value py-4"
                                      colSpan={get(currentPlant, 'type') === 'shipper' ? 6 : 4}
                                      align="right">
                                      Container Equipment Number</td>
                                    <td className="booking-details-value py-4"
                                      colSpan={get(currentPlant, 'type') === 'shipper' ? 9 : 8}>
                                      {containerEquipmentNo}</td>
                                  </tr>
                                }
                                {containerCarrierSealNo &&
                                  <tr className="border-t-1 border-gray">
                                    <td className="booking-details-value py-4"
                                      colSpan={get(currentPlant, 'type') === 'shipper' ? 6 : 4}
                                      align="right">
                                      Container Carrier Seal Number</td>
                                    <td className="booking-details-value py-4"
                                      colSpan={get(currentPlant, 'type') === 'shipper' ? 9 : 8}>
                                      {containerCarrierSealNo}</td>
                                  </tr>
                                }
                                {containerSupplierSealNo &&
                                  <tr className="border-t-1 border-gray">
                                    <td className="booking-details-value py-4"
                                      colSpan={get(currentPlant, 'type') === 'shipper' ? 6 : 4}
                                      align="right">
                                      Container Supplier Seal Number</td>
                                    <td className="booking-details-value py-4"
                                      colSpan={get(currentPlant, 'type') === 'shipper' ? 9 : 8}>
                                      {containerSupplierSealNo}</td>
                                  </tr>
                                }
                              </React.Fragment>
                            }
                          </tbody>
                        </table>
                      </Grid>
                    )
                  })}
                </Grid>
              )}
              {shipmentView === 'outbounds' && (
                <Grid container>
                  <Grid item xs={12} className="overflow-auto">
                    <table className={classes.tableStyle}>
                      <tbody>
                        <tr>
                          {get(currentPlant, 'type') === 'shipper' &&
                            <td className='booking-details-name pb-8 pr-12'></td>
                          }
                          <td className='booking-details-name pb-8 pr-12'>Delivery</td>
                          <td className='booking-details-name pb-8 pr-12'>HU&nbsp;Number</td>
                          <td className='booking-details-name pb-8 pr-12'>CS&nbsp;no</td>
                          <td className='booking-details-name pb-8 pr-12'>Special&nbsp;label</td>
                          <td className='booking-details-name pb-8 pr-12'>Ship&nbsp;to&nbsp;party</td>
                          <td className='booking-details-name pb-8 pr-12'>Header&nbsp;PO</td>
                          <td className='booking-details-name pb-8 pr-12'>Sales&nbsp;Order</td>
                          <td className='booking-details-name pb-8 pr-12'>Ship&nbsp;To&nbsp;PO</td>
                          <td className='booking-details-name pb-8 pr-12'>Sold&nbsp;To&nbsp;PO</td>
                          <td className='booking-details-name pb-8 pr-12'>Weight</td>
                          <td className='booking-details-name pb-8 pr-12'>Weight&nbsp;unit</td>
                          <td className='booking-details-name pb-8 pr-12'>Volume</td>
                          <td className='booking-details-name pb-8 pr-12'>Volume&nbsp;unit</td>
                          <td className='booking-details-name pb-8'></td>
                        </tr>
                        {get(shipment, 'outbounds', []).length === 0 && (
                          <tr>
                            <td colSpan={get(currentPlant, 'type') === 'shipper' ? 14 : 13}
                              align='center'
                              className='booking-details-name bold p-8'>
                              No Outbounds
                        </td>
                          </tr>
                        )}
                        {get(shipment, 'outbounds', []).map((outbound, index) => {
                          return (
                            <tr key={index}>
                              {get(currentPlant, 'type') === 'shipper' &&
                                <td className='booking-details-value w-40'>
                                  <FuseCheckBox value={get(outbound, '_id')}
                                    name="outboundCheckBox"
                                    checked={find(selectedOutbounds, {
                                      _id: get(outbound, '_id')
                                    })}
                                    className='p-0'
                                    onChange={handleOutboundSelection}
                                  />
                                </td>
                              }
                              <td className='booking-details-value'>
                                {get(outbound, 'meta.outboundId')}</td>
                              <td className='booking-details-value'>
                                {get(outbound, 'packingDetail.handlingUnitNumber')}</td>
                              <td className='booking-details-value'>
                                {get(outbound, 'meta.CSCode', <Skeleton />)}</td>
                              <td className='booking-details-value uppercase'>
                                <label className="fg-blue">
                                  {get(outbound, 'specialLabels', []).join(', ')}
                                </label>
                              </td>
                              <td className='booking-details-value'>
                                {get(outbound, 'shipToParty.code')}</td>
                              <td className='booking-details-value'>
                                {get(outbound, 'headerPONumber')}</td>
                              <td className='booking-details-value'>
                                {`${get(outbound, 'salesOrder.number', '')} (${get(outbound, 'salesOrder.itemNumber', '')})`}</td>
                              <td className='booking-details-value'>
                                {`${get(outbound, 'shipToParty.poNumber', '')} (${get(outbound, 'shipToParty.poLineNumber', '')})`}</td>
                              <td className='booking-details-value'>
                                {`${get(outbound, 'soldToParty.poNumber', '')} (${get(outbound, 'soldToParty.poLineNumber', '')})`}</td>
                              <td className='booking-details-value'>{get(outbound, 'weight.value', 0).toFixed(3)}</td>
                              <td className='booking-details-value'>{get(outbound, 'weight.unit')}</td>
                              <td className='booking-details-value'>{get(outbound, 'volume.value', 0).toFixed(3)}</td>
                              <td className='booking-details-value'>{get(outbound, 'volume.unit')}</td>
                              <td className='booking-details-value'>
                                {get(outbound, 'note') &&
                                  <InfoTooltip title={get(outbound, 'note')}>
                                    <Icon className="text-16 fg-gray cursor-pointer align-middle">
                                      info</Icon>
                                  </InfoTooltip>
                                }
                              </td>
                            </tr>
                          )
                        })}
                        {get(shipment, 'outbounds', []).length > 0 &&
                          <tr className="border-solid border-t-1 border-gray">
                            <td colSpan={get(currentPlant, 'type') === 'shipper' ? 10 : 9}
                              align="right"
                              className='booking-details-value py-4'>Total</td>
                            <td className='booking-details-value py-4'>
                              {sumBy(get(shipment, 'outbounds', []), i => get(i, 'weight.value', 0)).toFixed(3)}
                            </td>
                            <td className='booking-details-value py-4'>
                              {get(get(shipment, 'outbounds', []), '[0].weight.unit', 'KG')}
                            </td>
                            <td className='booking-details-value py-4'>
                              {sumBy(get(shipment, 'outbounds', []), i => get(i, 'volume.value', 0)).toFixed(3)}
                            </td>
                            <td colSpan={2} className='booking-details-value py-4'>
                              {get(get(shipment, 'outbounds', []), '[0].volume.unit', 'M3')}
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                  </Grid>
                </Grid>
              )}

              <CSInfoSidebar
                open={csInfo.length > 0}
                closeSideBar={() => setCsInfo([])}
                csInfo={csInfo}
                shipment={shipment}
                updateCS={updateCS}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* END of Consolidation & Outbounds */}

      <RemoveOutboundDialog
        open={removeOutboundsDialog}
        selectedOutbounds={selectedOutbounds}
        shipment={shipment}
        setRemoveOutboundsDialog={setRemoveOutboundsDialog}
        onSubmit={onBookingChange}
      />
    </Grid>
  )
}
const styles = {
  editCS: {
    cursor: 'pointer',
    color: '#9FA0A4',
    '&:hover': {
      color: '#41B4E5'
    }
  },
  editAll: {
    color: '#41B4E5',
    borderBottom: '1px dashed #41B4E5',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  csActionButtons: {
    height: 32,
    padding: '4px 20px',
    fontSize: 10,
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 16,
  },
  csActionGreen: {
    backgroundColor: 'rgba(61, 205, 88, 0.2) !important',
    color: '#3DCD58 !important',
  },
  csActionRed: {
    backgroundColor: 'rgba(177, 0, 67, 0.07) !important',
    color: '#B10043 !important',
  },
  tableStyle: {
    width: '100%',
    overflowX: 'auto'
  }
}
export default withStyles(styles)(BookingDetails)
