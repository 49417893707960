import React, { useEffect } from 'react'
import {
  LoadingButton,
  FuseSideBarActions,
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseCheckBox
} from '../../core/components'
import { withStyles, Grid, Input } from '@material-ui/core'
import { useMergeState } from '../../core/helpers'
import AutoComplete from '../../core/components/Inputs/AutoComplete'
import { get, pick } from 'lodash'
import GooglePlaceSearch from '../../core/components/Inputs/GooglePlaceSearch'

const INITIAL_STATE = {
  type: '',
  name: '',
  address: {},
  additionalAddress: '',
  isDefault: false
}

const styles = {
  input: {
    fontSize: 14,
    fontFamily: 'IBMPlexSans',
  },
  checkbox: {
    height: 'auto',
    padding: 0,
    marginLeft: '-3px',

    '& svg': {
      width: '1.8em',
      height: '1.8em'
    }
  },
}

const selectStyle = {
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  }),
}

const ManagePlaceSidebar = props => {
  const {
    open,
    title,
    handleClose,
    existingPlace,
    classes,
    placeTypeOptions,
    onSubmit,
    submitting
  } = props
  const [state, setState] = useMergeState(INITIAL_STATE)

  useEffect(() => {
    if (open) {
      if (existingPlace) {
        setState(pick(get(existingPlace, 'value', {}), [
          'type',
          'name',
          'address',
          'additionalAddress',
          'isDefault'
        ]))
      } else {
        setState(INITIAL_STATE)
      }
    } else {
      setState(INITIAL_STATE)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setState({
      [name]: value
    })
  }

  const handleAddressChange = (address, lat, lng) => {
    setState({
      address: {
        ...address,
        latitude: lat.toString(),
        longitude: lng.toString()
      }
    })
  }

  return (
    <FuseSideBar open={open}>
      <FuseSideBarHeader text={title}
        handleClose={handleClose} />
      <FuseSideBarBody>
        <Grid container>
          <Grid item xs={12}>
            <Grid container
              className="mb-28">
              <Grid item xs={12}
                className="semi-bold text-16 pb-8">
                Address Type</Grid>
              <Grid item xs={12}>
                <label className="fg-gray medium text-12">
                  Place</label>
                <AutoComplete value={get(state, 'type')}
                  className={classes.input}
                  options={placeTypeOptions}
                  placeholder="Select place type..."
                  onChange={(type) => setState({ type })}
                  customStyles={selectStyle} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container
              className="mb-28">
              <Grid item xs={12}
                className="semi-bold text-16 pb-8">
                Address</Grid>
              <Grid item xs={12} className="pb-12">
                <label className="fg-gray medium text-12">
                  Name</label>
                <Input name="name"
                  className={classes.input}
                  value={get(state, 'name', '')}
                  onChange={handleInputChange}
                  fullWidth />
              </Grid>
              <Grid item xs={12} className="pb-12">
                <label className="fg-gray medium text-12">
                  Address</label>
                <GooglePlaceSearch placeholder="Select street address..."
                  className="theme-text-field text-14"
                  value={get(state, 'address.street', '')}
                  updateCall={handleAddressChange} />
              </Grid>
              <Grid item xs={12}>
                <label className="fg-gray medium text-12">
                  Additional address</label>
                <Input name="additionalAddress"
                  className={classes.input}
                  value={get(state, 'additionalAddress', '')}
                  onChange={handleInputChange}
                  fullWidth />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}
                className="semi-bold text-16 pb-8">
                Mark as default</Grid>
              <Grid item xs={12}>
                <Grid container
                  alignItems="center">
                  <Grid item xs={1}>
                    <FuseCheckBox classes={{ checkbox: classes.checkbox }}
                      onChange={(e) => setState({ isDefault: e.target.checked })}
                      checked={get(state, 'isDefault', false)} />
                  </Grid>
                  <Grid item xs={11} className="text-14">
                    Set as default {get(state, 'type', '').replace(/-+/g, ' ')} address</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </FuseSideBarBody>
      {
        <FuseSideBarActions>
          <LoadingButton className='btn btn_full-space'
            loading={submitting}
            onClick={() => onSubmit(state, existingPlace ? existingPlace : 'adding')}>
            SAVE</LoadingButton>
        </FuseSideBarActions>
      }

    </FuseSideBar>
  )
}

export default withStyles(styles)(ManagePlaceSidebar)
