import React from 'react'
import { withStyles, Icon, ListItem, ListItemText } from '@material-ui/core'
import { NavLink, withRouter } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import FuseNavBadge from './FuseNavBadge'

const propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string
  })
}

const defaultProps = {}

const styles = theme => ({
  item: {
    height: 40,
    width: 'calc(100% - 16px)',
    borderRadius: '0 20px 20px 0',
    paddingRight: 12,
    '&.active': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText + '!important',
      pointerEvents: 'none',
      transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
      '& .list-item-text-primary': {
        color: 'inherit'
      },
      '& .list-item-icon': {
        color: 'inherit',
        fontSize: '20px !important'
      }
    },
    '&.square, &.active.square': {
      width: '100%',
      borderRadius: '0'
    },
    '& .list-item-icon': {
      fontSize: '20px !important'
    },
    '& .list-item-text': {
      padding: '0 16px 0 8px'
    },
    color: 'inherit!important',
    textDecoration: 'none!important'
  }
})

const FuseNavVerticalItem = ({ item, classes, nestedLevel }) => {
  let paddingValue = 16 + nestedLevel * 16
  const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24'
  return (
    <ListItem button
      component={NavLink}
      to={item.url}
      activeClassName='active'
      className={classNames(classes.item, listItemPadding, 'list-item')}
      onClick={() => { }}
      exact={item.exact}>

      {item.icon && (
        <Icon className='list-item-icon text-16 flex-no-shrink'
          fontSize='large'
          color='action'>
          {item.icon}
        </Icon>
      )}

      <ListItemText className='list-item-text'
        primary={item.title}
        classes={{
          primary: 'text-14 medium list-item-text-primary'
        }} />

      {item.count !== undefined &&
        <FuseNavBadge count={item.count}
          className={item.badgeClassName} />
      }
    </ListItem>
  )
}
FuseNavVerticalItem.propTypes = propTypes
FuseNavVerticalItem.defaultProps = defaultProps

const NavVerticalItem = withStyles(styles, { withTheme: true })(withRouter(FuseNavVerticalItem))

export default NavVerticalItem
