import React, { useState, useEffect } from 'react'
import { Grid, Typography, Icon } from '@material-ui/core'
import { get, find } from 'lodash'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { FusePlaceHolder, LoadingButton, LoadMore, InfoToolTip } from '../../core/components'
import { formatTimestamp, shipmentNameBeautifier } from '../../core/helpers'
import CommentIcon from '@material-ui/icons/Comment'
import { Link } from 'react-router-dom'

const typeColors = {
  NOTICE: 'bg-light-blue fg-blue',
  ISSUE: 'bg-light-orange fg-orange'
}

export default ({
  isLoading,
  notices,
  total,
  onNoticeClick,
  getNotices,
  openNotice,
  closeNotice,
  showLoadMore,
  loadingMore }) => {
  const [requesting, setRequesting] = useState(false)
  const [pageNo, setPageNo] = useState(1)

  const handleQuickAction = (e, topicId, action) => {
    e.preventDefault()
    setRequesting(topicId)
    if (action === 'open') {
      openNotice(topicId, async () => {
        await getNotices(true, pageNo)
        setRequesting(false)
      }, () => {
        setRequesting(false)
      })
    } else if (action === 'close') {
      closeNotice(topicId, async () => {
        await getNotices(true, pageNo)
        setRequesting(false)
      }, () => {
        setRequesting(false)
      })
    }
  }

  const getShipmentLink = (shipment) => {
    const shipmentLinks = [
      { name: 'scheduled', title: 'Scheduled', url: '/schedule', icon: 'calendar_today' },
      { name: 'in-transit', title: 'In Transit', url: '/in-transit', icon: 'directions_boat' },
      { name: 'import', title: 'Inbound', url: '/inbound', icon: 'play_for_work' },
      { name: 'exceptions', title: 'Exceptions', url: '/exceptions', icon: 'warning_outline' },
      { name: 'completed', title: 'Completed', url: '/completed', icon: 'check_circle' },
      { name: 'cancelled', title: 'Cancelled', url: '/cancelled', icon: 'cancelled' },
    ]

    if (get(shipment, 'exceptionMode.enabled')) {
      return find(shipmentLinks, { name: 'exceptions' })
    } else {
      return find(shipmentLinks, { name: get(shipment, 'stage.current.value', 'scheduled') })
    }
  }

  useEffect(() => {
    setPageNo(1)
  }, [])

  useEffect(() => {
    getNotices(true, pageNo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo])

  return (
    <Grid container spacing={2} justify="center">

      {total > 0 &&
        <Grid item xs={12}>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <Typography className="bold text-14 fg-gray">
                {total}&nbsp;Total
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      }


      {isLoading &&
        [1, 2].map((item, index) => {
          return (
            <Grid item xs={12}
              key={index}>
              <Skeleton height={60} />
            </Grid>
          )
        })
      }

      {(!isLoading && notices.length === 0) &&
        <Grid item xs={12}>
          <FusePlaceHolder className='flex flex-col'
            icon='info'
            title='No Notices'
            description="Looks like there are no notices posted to any shipment." />
        </Grid>
      }

      {!isLoading && notices.map((notice, noticeIndex) => {
        const shipments = get(notice, 'references.shipments', [])
        const commentsCount = get(notice, 'comments', 0)

        return (
          <Grid item xs={12}
            key={noticeIndex}
            className="regular text-14">
            <Grid container
              className="rounded shadow overflow-hidden bg-white">
              <Grid item xs={12}
                className="px-8 py-4">
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={10} md={10}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className="medium">
                        <Grid container>
                          <Grid item>
                            <Typography className="bold text-14 cursor-pointer hover:text-blue"
                              onClick={() => onNoticeClick(notice)}>
                              {get(notice, 'title')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography className="regular text-12 fg-gray max-w-full"
                              noWrap
                              onClick={() => onNoticeClick(notice)}>
                              {get(notice, 'description.text')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={2}>
                          <Grid item>
                            <Typography className={'bold uppercase px-8 rounded text-12 ' +
                              get(typeColors, get(notice, 'type'), '')}>
                              {get(notice, 'type')}</Typography>
                          </Grid>
                          {commentsCount > 0 &&
                            <Grid item>
                              <InfoToolTip title={`${commentsCount} comment${commentsCount > 1 ? 's' : ''}`}>
                                <Grid container alignItems="center">
                                  <CommentIcon className="text-16 fg-gray" />
                                  <Typography className={'regular text-12 fg-gray ml-4'}>
                                    {commentsCount}
                                  </Typography>
                                </Grid>
                              </InfoToolTip>
                            </Grid>
                          }
                          {shipments.length > 0 &&
                            shipments.map((shipment, shipmentIndex) => {
                              const shipmentLink = getShipmentLink(shipment)
                              return (
                                <Grid item key={shipmentIndex}>
                                  <Link to={`${shipmentLink.url}/${shipment._id}?tab=6`}>
                                    <Grid container alignItems="center" spacing={1}
                                      className="fg-gray hover:text-blue p-6 cursor-pointer text-12">
                                      <Grid item>
                                        <InfoToolTip title={shipmentLink.title}>
                                          <Icon className="text-16">{shipmentLink.icon}</Icon>
                                        </InfoToolTip>
                                      </Grid>
                                      <Grid item xs className="medium">
                                        {shipmentNameBeautifier(get(shipment, 'name', ''))}
                                      </Grid>
                                    </Grid>
                                  </Link>
                                </Grid>
                              )
                            })
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={2} md={2}
                    className="border-solid border-l-1 border-gray">
                    <Grid container
                      justify="center"
                      alignItems="center"
                      spacing={1}
                      className="p-6">
                      <Grid item
                        className={classNames({
                          'uppercase bold': true,
                          'fg-blue': get(notice, 'status') === 'OPEN',
                          'fg-red': get(notice, 'status') === 'CLOSED',
                        })}>
                        {get(notice, 'status')}
                      </Grid>
                      <Grid item xs={12}>
                        {get(notice, 'status') === 'OPEN' &&
                          <LoadingButton className="btn btn_sm btn_light_red"
                            loading={requesting === get(notice, '_id')}
                            onClick={(e) => handleQuickAction(e, get(notice, '_id'), 'close')}
                            fullWidth>close</LoadingButton>
                        }
                        {get(notice, 'status') === 'CLOSED' &&
                          <LoadingButton className="btn btn_sm btn_light_green"
                            loading={requesting === get(notice, '_id')}
                            onClick={(e) => handleQuickAction(e, get(notice, '_id'), 'open')}
                            fullWidth>reopen</LoadingButton>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}
                className="bg-gray px-8 py-4 text-12 text-grey-dark">
                <label className="medium">{get(notice, 'owner.user.firstname')}</label> opened this on {formatTimestamp(get(notice, 'createdAt'), 'date-time')}</Grid>
            </Grid>
          </Grid>
        )
      })}

      {showLoadMore &&
        <Grid item xs={12}>
          <LoadMore loading={loadingMore}
            onClick={() => setPageNo(pageNo + 1)} />
        </Grid>
      }

    </Grid>
  )
}
