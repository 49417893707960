import React, { Component } from 'react'
import classNames from 'classnames'
import { ButtonGroup, withStyles } from '@material-ui/core'
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay'
import ReorderIcon from '@material-ui/icons/Reorder'
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout'
import { connect } from 'react-redux'
import { LoadMore, FuseIconButton } from 'core/components'
import * as ShipmentActions from '../store/actions'
import PartnersSidebar from 'core/components/PartnersSidebar'
import CSOutboundsSidebar from '../../core/components/CSOutboundsSidebar'
import { FusePlaceHolder } from 'core/components'
import ShipmentListSkeleton from '../../core/components/Skeletons/ShipmentListSkeleton'
import ShipmentListFilterSkeleton from '../../core/components/Skeletons/ShipmentListFilterSkeleton'
import ShipmentCard from 'core/components/shipment-card/ShipmentCard'
import FilterRow from '../../core/components/Filters/shipment-filter/FilterRow'
import * as CoreActions from 'core/store/actions'
import ResolveException from '../components/ResolveException'
import IOSSwitchComponent from '../../core/components/switches/IOSSwitchComponent';
import { get } from 'lodash'
import {
  setShipmentListFilters,
  getShipmentListFilters,
  startSearching,
  stopSearching
} from '../../core/helpers'

class Exceptions extends Component {
  state = {
    partnerSidebar: {
      open: false,
      actors: {}
    },
    outboundsSidebar: {
      open: false,
      outbounds: [],
      consolidations: []
    },
    customFilter: null,
    status: null,
    search: '',
    sort: null,
    minified: [],
    resolvingShipment: null
  }

  pagination = {
    pageNo: 1
  }
  filters = null
  search = null

  applyCustomFilter = customFilter => {
    this.pagination.pageNo = 1
    setShipmentListFilters(
      get(this.props, 'userInfo._id'),
      get(this.props, 'currentPlant._id'),
      'exception',
      customFilter
    )
    this.setState({ customFilter }, this.getAllShipments)
  }

  resetTimer = () => {
    let timer = setTimeout(() => {
      this.getAllShipments(true)
      this.resetTimer()
    }, 30000)
    clearTimeout(this.timer)
    this.timer = timer
  }
  registerTimer = () => {
    document.addEventListener('mousemove', this.resetTimer)
    document.addEventListener('click', this.resetTimer)
    document.addEventListener('keypress', this.resetTimer)
  }
  componentWillUnmount() {
    clearTimeout(this.timer)
    document.removeEventListener('mousemove', this.resetTimer)
    document.removeEventListener('click', this.resetTimer)
    document.removeEventListener('keypress', this.resetTimer)
  }

  componentDidMount() {
    const { userInfo, currentPlant, autoRefreshStatus } = this.props
    const customFilter = getShipmentListFilters(
      get(userInfo, '_id'),
      get(currentPlant, '_id'),
      'exception'
    )
    if (customFilter) {
      this.setState({
        customFilter
      }, this.getAllShipments)
    } else {
      this.getAllShipments()
    }

    /* For checking that is the auto refresh or when component is mounted */
    if (autoRefreshStatus) {
      this.registerTimer();
      this.resetTimer();
    }
  }

  onPartnerClick = actors => {
    this.setState({
      partnerSidebar: {
        open: true,
        actors
      }
    })
  }

  onPartnerSidebarClose = () => {
    this.setState({
      partnerSidebar: {
        open: false,
        actors: {}
      }
    })
  }

  onOutboundsClick = async shipment => {
    this.setState({
      outboundsSidebar: {
        open: true,
        outbounds: [],
        consolidations: shipment.consolidations
      }
    })
    const { getOutboundsByShipment } = this.props

    await getOutboundsByShipment(shipment._id)
    const { outboundsByShipment } = this.props
    this.setState({
      outboundsSidebar: {
        open: true,
        outbounds: outboundsByShipment,
        consolidations: shipment.consolidations
      }
    })
  }

  onOutboundsSidebarClose = () => {
    this.setState({
      outboundsSidebar: {
        open: false,
        outbounds: [],
        consolidations: []
      }
    })
  }

  getAllShipments = (isSync = false) => {
    const { getPlants, getAllShipments } = this.props
    const { status, customFilter, search, sort } = this.state
    let filter = null
    if (customFilter) {
      filter = customFilter.value
      if (filter && filter.groupage && filter.groupage.type === 'none') {
        delete filter.groupage
      }
    }

    getPlants()
    getAllShipments(this.pagination, status, search, sort, filter, [
      'actors.shipper.office',
      'actors.consignee.office'
    ], isSync)
  }
  getStatusArray = status => {
    switch (status) {
      case 'pending':
        return ['pending']
      case 'amended':
        return ['amended']
      case 'rejected':
        return ['freight-partner-rejected', 'obl-rejected']
      case 'confirmed_by_efp':
        return ['freight-partner-confirmed']
      case 'confirmed_by_elp':
        return ['obl-confirmed']
      default:
        return undefined
    }
  }

  onLoadMore = () => {
    this.pagination.pageNo += 1
    this.getAllShipments()
  }

  componentDidUpdate(prevProps) {
    if (this.props.shipments !== prevProps.shipments) {
      let minified = {}
      this.props.shipments.forEach(shipment => {
        minified[shipment._id] = this.state.minified.hasOwnProperty(shipment._id) ? this.state.minified[shipment._id] : true
      })
      this.setState({ minified })
    }
    if (this.props.autoRefreshStatus !== prevProps.autoRefreshStatus) {
      this.setToggleAutoRefresh(this.props.autoRefreshStatus)
    }
  }

  setAllMinifiedState = val => {
    let minified = {}
    this.props.shipments.forEach(shipment => {
      minified[shipment._id] = val
    })
    this.setState({ minified })
  }

  expandAll = () => {
    this.setAllMinifiedState(false)
  }

  collapseAll = () => {
    this.setAllMinifiedState(true)
  }

  onFilterChange = filters => {
    this.filters = filters
    this.pagination.pageNo = 1
    this.getAllShipments()
  }

  handleSearchChange = event => {
    const { value } = event.target
    const { search } = this.state

    this.setState({ search: value },
      () => {
        if (search !== value) {
          stopSearching()
          startSearching(1000, () => {
            this.pagination.pageNo = 1
            this.getAllShipments()
          })
        }
      }
    )
  }

  handleStatusChange = status => {
    this.setState({ status }, this.getAllShipments)
  }
  handleSortChange = sort => {
    this.setState({ sort }, this.getAllShipments)
  }

  /**
  * Function to set AutoRefreshStatus
  * @param status boolean
  */
  setToggleAutoRefresh = status => {
    this.props.setAutoRefreshStatus(status);

    if (status) {
      this.registerTimer();
      this.resetTimer();
    }else{
      if (this.timer) {
        clearTimeout(this.timer)
        document.removeEventListener('mousemove', this.resetTimer)
        document.removeEventListener('click', this.resetTimer)
        document.removeEventListener('keypress', this.resetTimer)
      }
    }
  }

  render() {
    const {
      loading,
      shipments,
      currentPlant,
      showMore,
      initialLoading,
      classes,
      getShipmentFilterOptions,
      resolveConflict,
      markAsSpot,
      resolveSpot,
      plants,
      shipmentsTotal,
      autoRefreshStatus
    } = this.props
    const { partnerSidebar, outboundsSidebar, minified, resolvingShipment } = this.state
    const atleastOneMinified = shipments.reduce((total, currentValue) => total || minified[currentValue._id], false)
    const allMinified = shipments.reduce((total, currentValue) => total && minified[currentValue._id], true)

    return (
      <>
        <SubToolBarLayout text='Exceptional Bookings'>
          <IOSSwitchComponent label={`AutoRefresh`} checked={autoRefreshStatus} handleToggle={this.setToggleAutoRefresh}/>
          <ButtonGroup size='small' color='primary' aria-label='actions expand' className={classes.buttonGroup}>
            <FuseIconButton
              iconComponent={<CalendarViewDayIcon fontSize='large' className='mr-8' />}
              solid={true}
              className={classNames({ [classes.button]: true, [classes.buttonActive]: true })}
              variant='outline'
              onClick={this.expandAll}
              disabled={!atleastOneMinified}
            >
              Expand All
            </FuseIconButton>
            <FuseIconButton
              iconComponent={<ReorderIcon fontSize='large' className='mr-8' />}
              solid={true}
              className={classNames({ [classes.button]: true, [classes.buttonActive]: true })}
              variant='outline'
              onClick={this.collapseAll}
              disabled={allMinified}
            >
              Collapse All
            </FuseIconButton>
          </ButtonGroup>
        </SubToolBarLayout>
        <div className='p-20 h-full overflow-auto'>
          {initialLoading && <ShipmentListFilterSkeleton showFilter showSearch />}
          {!initialLoading && (
            <FilterRow shipmentsTotal={shipmentsTotal}
              search={this.state.search}
              handleSearchChange={this.handleSearchChange}
              sort={this.state.sort}
              handleSortChange={this.handleSortChange}
              handleStatusChange={this.handleStatusChange}
              shipments={shipments}
              getShipmentFilterOptions={getShipmentFilterOptions}
              applyCustomFilter={this.applyCustomFilter}
              customFilter={this.state.customFilter}
              allowCustomFilter
            />
          )}

          {loading && <ShipmentListSkeleton count={3} />}

          {!loading && shipments.length === 0 && (
            <FusePlaceHolder
              className='flex flex-col max-h-256'
              icon='warning_outline'
              title='No bookings'
              description={'Looks like there are no exceptional bookings at the moment. Please check back later.'}
            ></FusePlaceHolder>
          )}

          {!loading && shipments.length > 0 && (
            <>
              <div className={classNames('mt-20 shipment-list')}>
                {shipments.map((shipment, index) => (
                  <ShipmentCard
                    section='exception'
                    key={index}
                    minified={this.state.minified[shipment._id]}
                    setMinified={value => {
                      this.setState({ minified: { ...minified, [shipment._id]: value } })
                    }}
                    shipment={shipment}
                    role={currentPlant.type}
                    onPartnerSidebarClick={this.onPartnerClick}
                    onOutboundsSidebarClick={this.onOutboundsClick}
                    onResolveException={shipment => {
                      this.setState({
                        resolvingShipment: shipment
                      })
                    }}
                    openDocUploader={() => { }}
                    statusType={'exception-type'}
                  />
                ))}
                {showMore && <LoadMore onClick={this.onLoadMore} />}
              </div>
            </>
          )}

          <PartnersSidebar
            open={partnerSidebar.open}
            closeSideBar={this.onPartnerSidebarClose}
            sideBarTitle={'Partners'}
            actors={partnerSidebar.actors}
          />

          <CSOutboundsSidebar
            open={outboundsSidebar.open}
            closeSideBar={this.onOutboundsSidebarClose}
            sideBarTitle={'CS & Outbounds'}
            outboundsList={outboundsSidebar.outbounds}
            consolidations={outboundsSidebar.consolidations}
            currentPlant={currentPlant}
          />

          <ResolveException
            shipment={resolvingShipment}
            clearPropShipment={() => {
              this.setState({
                resolvingShipment: null
              })
            }}
            plants={plants}
            onResolveConflict={resolveConflict}
            onMarkAsSpot={markAsSpot}
            onResolveSpot={resolveSpot}
            getAllShipments={this.getAllShipments}
          />
        </div>
      </>
    )
  }
}

const mapStateToProps = ({
  exceptions: { initialLoading, loading, shipments, shipmentsTotal, outboundsByShipment, showMore },
  welcome: { currentPlant },
  core: { plants , autoRefreshStatus },
  auth: { info }
}) => ({
  plants,
  initialLoading,
  loading,
  shipments,
  shipmentsTotal,
  outboundsByShipment,
  showMore,
  currentPlant,
  userInfo: info,
  autoRefreshStatus: autoRefreshStatus,
})

const mapDispatchToProps = dispatch => ({
  getAllShipments: (...args) => dispatch(ShipmentActions.getAllShipments(...args)),
  getOutboundsByShipment: shipmentId => dispatch(ShipmentActions.getOutboundsByShipment(shipmentId)),
  getShipmentFilterOptions: () => dispatch(CoreActions.getShipmentFilterOptions()),
  getPlants: (...args) => dispatch(CoreActions.getPlants(...args)),
  resolveConflict: (...args) => dispatch(ShipmentActions.resolveConflict(...args)),
  markAsSpot: (...args) => dispatch(ShipmentActions.markAsSpot(...args)),
  resolveSpot: (...args) => dispatch(ShipmentActions.resolveSpot(...args)),
  setAutoRefreshStatus: (status) => dispatch(CoreActions.setAutoRefreshStatus(status)),
})
const styles = {
  button: { textTransform: 'none', fontWeight: 600, fontSize: 12 },
  buttonActive: {},
  buttonInActive: {}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Exceptions))
