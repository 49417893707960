import React from 'react'
import { connect } from 'react-redux'
import { Grid, Typography, withStyles } from '@material-ui/core'
import Steps from 'rc-steps'
import 'rc-steps/assets/index.css'
import 'rc-steps/assets/iconfont.css'
import { get, find } from 'lodash'
import classNames from 'classnames'
import { formatTimestamp, getAddressFromLocation } from 'core/helpers'
import EditIcon from '@material-ui/icons/Edit'
import { getLocationDetails } from './nodeDetails.js'

const FCLContainerTimelineRow = ({ shipment, container, index, classes, onContainerClick, currentPlant, noBackground, direction="horizontal" }) => {
  let canEdit = false
  let statusArray = [...get(shipment, 'status.previous', []), get(shipment, 'status.current', {})]
  let role = get(currentPlant, 'type')
  if (get(container, 'status.current.value') !== 'cancelled') {
    if (role === 'shipper') {
      canEdit = true
    }
    if (role === 'ofp') {
      canEdit = statusArray.some(status => status.value === 'freight-partner-confirmed')
    }
    if (role === 'obl') {
      canEdit = statusArray.some(status => status.value === 'obl-confirmed')
    }
  }

  let trackingAvailable = get(container, 'tracking.available')

  const getStatusClass = () => {
    switch (get(container, 'status.current.value')) {
      case "cancelled": return 'red-tag';
      case "booked": return 'green-tag';
      case "delivered": return 'green-tag';
      case "moving": return 'green-tag';
      case "free": return 'orange-tag';
      default: return ''
    }
  }

  return (
    <Grid 
      container
      alignItems='stretch'
      xs={ direction === "horizontal" ? 12 : 6}

      className={classNames(
        classes.row,
        noBackground && classes.noBgRow
      )}>
      <Grid item xs={ direction === "horizontal" ? 11 : 12}>
        {shipment.type !== 'LCL' && (
          <Grid item xs={12} className={classes.rowHead}>
            <Grid container alignItems='center' spacing={2}>
              <Grid item className={classes.rowHeadTitle}>
                <Grid container alignItems='center'>
                  <img src='/assets/images/icons/shipment-card/fcl-mini.svg' alt='FCL' className={classes.icon} />
                  <label className="uppercase">
                    {get(container, 'equipmentNo')
                      ? get(container, 'equipmentNo')
                      : get(container, 'type') + ' (' + (index + 1) + ')'}
                  </label>
                </Grid>
              </Grid>
              <Grid
                item
                title={`Container ${get(container, 'status.current.value', 'status unknown')}`}
                className={classNames('shipment-list-item-tag cursor-pointer p-4', getStatusClass())}
              >
                {get(container, 'status.current.value', 'untraceable')}
              </Grid>
              <Grid item>
                {onContainerClick && (
                  <Grid container justify='flex-end' alignItems='center'>
                    <Grid item>
                      {/* Invisible for now as w don't know how to show latest event */}
                      <Grid container alignItems='center'>
                        <Grid item>
                          <Typography className='shipment-list-item-current-event'>
                            Carrier seal no:&nbsp;
                            <label className="uppercase">
                              {get(container, 'carrierSealNumber', 'N/A')}
                            </label>
                          </Typography>
                        </Grid>
                        <Grid item className='mx-4'>
                          &#183;
                        </Grid>
                        <Grid item className='mr-8'>
                          <Typography className='shipment-list-item-current-event'>
                            Supplier Seal no:&nbsp;
                            <label className="uppercase">
                              {get(container, 'supplierSealNumber', 'N/A')}
                            </label>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      {canEdit && (
                        <Grid container justify='flex-end' alignItems='center'>
                          <Grid item className='mr-8'>
                            <Grid container alignItems='center'>
                              <EditIcon className={classes.editContainerSeal} onClick={onContainerClick} />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                {!onContainerClick && (
                  <Grid container justify='flex-end' alignItems='center'>
                    {
                      get(container, 'latestActualEvent.name') && (
                        <Grid item>
                          {/* Invisible for now as w don't know how to show latest event */}
                          <Grid container alignItems='center'>
                            <Grid item className='shipment-list-item-current-event marker'></Grid>
                            <Grid
                              item
                              title={`Event Code: ${get(
                                container,
                                'latestActualEvent.code'
                              )}, Location: ${getAddressFromLocation(get(container, 'latestActualEvent.location')) ||
                              'Not confirmed'}`}
                            >
                              <Typography className='shipment-list-item-current-event'>
                                {get(container, 'latestActualEvent.name') ||
                                  get(container, 'latestActualEvent.code')}
                              </Typography>
                            </Grid>
                            <Grid item className='mx-4'>
                              &#183;
                        </Grid>
                            <Grid item title={get(container, 'latestActualEvent.type')} className='mr-8'>
                              <Typography className='shipment-list-item-current-event'>
                                {formatTimestamp(get(container, 'latestActualEvent.occurenceAt'), 'date-time')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }

                    <Grid item>
                      <Grid container justify='flex-end' alignItems='center'>
                        <Grid item className='mr-8'>
                          <Grid container alignItems='center'>
                            <img src='assets/images/icons/shipments-list/ship-name.png' alt='ship-name' />
                          </Grid>
                        </Grid>
                        <Grid item className={classes.rowHeadTitleRight}>
                          {get(shipment, 'vesselSchedule.FirstPOL.vessel.name') ||
                            get(shipment, 'freightOperatorInfo.name') ||
                            'Not Decided'}
                          {/* {[shipment.vesselSchedule.FirstPOL.vessel.name, ...shipment.vesselSchedule.transhipmentPorts.map(x => x.vessel.name)].join(", ")} */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Timeline shipment={shipment} direction={direction} classes={classes} container={container} />
        </Grid>
      </Grid>
      {
      <Grid item style={ direction === "horizontal" ? { marginLeft: 'auto' } : { width: '100%' }}>
        <button
          className={classNames(classes.trackButton, { border: 'border' }, { notracking: !trackingAvailable }, direction)}
          onClick={() => {
            if (trackingAvailable) {
              window.open(get(container, 'tracking.trackingLink', ''), '_blank')
            }
          }}
          title={get(container, 'tracking.error', '')}
        >

          {direction === "horizontal" && (
            <Typography
              className={classNames(classes.trackButton, { notracking: !trackingAvailable })}>
              T<br/>R<br/>A<br/>C<br/>K
            </Typography>
          )}

          {direction === "vertical" && (
            <Typography
              className={classNames(classes.trackButton, { notracking: !trackingAvailable })}>
              TRACK
            </Typography>
          )}

        </button>
      </Grid>
    }
    </Grid>
  )
}
const Timeline = ({ shipment, classes, container, direction }) => {
  if (!container || !shipment) {
    return null
  }
  let journeyCurrentIdx = shipment.journeyCurrentIdx
  if (shipment.type !== 'LCL') {
    journeyCurrentIdx = container.journeyCurrentIdx
  }

  let filteredJourney = shipment.journey.filter(obj => {
    if (get(obj, 'location.visibilityType', '') === 'always') {
      return true
    } else if (!obj.keyEvents) {
      return false
    }
    for (let keyEvent of obj.keyEvents) {
      if (keyEvent.cargo && keyEvent.cargo.id) {
        if (keyEvent.cargo.id.indexOf(container._id) !== -1) {
          return true
        }
      }
    }
    return false
  })

  let completedIndex = -1
  let containerJourney = []
  filteredJourney.forEach((location, index) => {
    if (journeyCurrentIdx === index) {
      completedIndex = index
    }
    if (get(location, 'location.visibilityType', '') === 'always') {
      if (get(location, 'location.type') === 'warehouse' &&
        get(location, 'location.subType') === 'destination') {
        if (!find(containerJourney, { location: { type: 'warehouse', subType: 'destination' } })) {
          containerJourney = [...containerJourney, { ...location }]
        }
      } else {
        containerJourney = [...containerJourney, { ...location }]
      }
    } else {
      let keyEvents = []
      if (location.keyEvents) {
        keyEvents = location.keyEvents.filter(event => event.cargo.id.indexOf(container._id) !== -1)
      }
      if (get(location, 'location.type') === 'warehouse' && get(location, 'location.subType') === 'destination') {
        if (!find(containerJourney, { location: { type: 'warehouse', subType: 'destination' } })) {
          containerJourney = [...containerJourney, { keyEvents, location: location.location }]
        }
      } else {
        containerJourney = [...containerJourney, { keyEvents, location: location.location }]
      }
    }
  })

  const stepConfig = {
    CY: { className: 'container_yard' },
    storage: { className: 'storage' },
    warehouse: { className: 'warehouse' },
    port: { className: 'port' }
  }

  return (
    <Grid container>
      <Grid 
        item 
        xs={12} 
        className={classes.rowLeft}>
        <Steps 
          className='shipment-list-item-progress'
          direction={direction}>
          {containerJourney.map((journey, index) => {
            let className = 'container_yard'
            if (journey.location && journey.location.type) {
              className = stepConfig[journey.location.type].className
            }

            if (index <= completedIndex) {
              className = `${className}-complete`
            } else if (index === completedIndex + 1) {
              className = `${className}-current`
            }
            if (index === filteredJourney.length - 1) {
              className = `${className} last-step`
            }
            let defaultNodeDetails = { title: '------', line1: '------', line2: '-------', address: '------' }
            let nodeDetails = getLocationDetails(journey, shipment, 'container', index, container)
            let { title, line1, line2, address } = nodeDetails ? nodeDetails : defaultNodeDetails
            return (
              <Steps.Step
                key={index}
                className={`shipment-list-item-progress-step ${className}`}
                description={
                  <>
                    <Typography className='step__description' title={title} noWrap>
                      {title}
                    </Typography>
                    <Typography 
                      className='step__description'                      
                      noWrap>
                      {address}
                    </Typography>
                    <Typography
                      className='step__description'
                      style={{
                        fontWeight: '200',
                        color: ((!get(nodeDetails, 'consignees') &&
                          get(nodeDetails, 'allLine1', []).length > 1) ? '#FFAA3E' : '#949494'),
                        fontStyle: 'italic'
                      }}
                      title={get(nodeDetails, 'consignees') ?
                        get(nodeDetails, 'consignees', []).map((item, index) => {
                          return index === 0 ? false : item.location.name
                        }).filter(Boolean).join('\n') :
                        get(nodeDetails, 'allLine1', []).join('\n')
                      }
                      noWrap
                    >
                      {line1}
                    </Typography>
                    <Typography
                      className='step__description'
                      style={{
                        fontWeight: '200',
                        color: (get(nodeDetails, 'allLine2', []).length > 1 ? '#FFAA3E' : '#949494'),
                        fontStyle: 'italic'
                      }}
                      title={get(nodeDetails, 'allLine2', []).join('\n')}
                      noWrap
                    >
                      {line2}
                    </Typography>
                  </>
                }
              />
            )
          })}
        </Steps>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  row: {
    backgroundColor: '#FAFAFA',
    borderTop: '.5px solid #ebebeb'
  },
  noBgRow: {
    backgroundColor: 'transparent',
  },
  rowPadded: {
    padding: 20
  },
  rowHead: {
    padding: 12,
    paddingTop: 20
  },
  icon: {
    marginRight: 10,
    width: 20,
    height: 20
  },
  rowLeft: {
    padding: 12,
    overflowX: 'scroll'
  },
  rowHeadTitle: {
    color: '#333333',
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'left'
  },
  rowHeadTitleRight: {
    color: '#333333',
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'left'
  },
  editContainerSeal: {
    '&:hover': {
      color: '#41B4E6'
    },
    color: '#CFCFD1',
    cursor: 'pointer'
  },
  trackButton: {  
    fontSize: '14px',
    backgroundColor: '#50bbee',
    color: '#fff',
    // marginBottom: 2,
    fontWeight: 400,
    '&.border': {
      border: '1px solid #c9c9c9'
    },
    '&.notracking': {
      backgroundColor: '#e1e1e1',
      color: '#c7c7c7',      
      cursor: 'not-allowed'
    },
    '&.horizontal': {
      height: '100%',
      width: '20px'
    },
    '&.vertical': {
      width: '98%',
      padding: '6px',
      margin: 'auto',
      display: 'table'
    },
  }
})

const mapStateToProps = ({ welcome }) => ({
  currentPlant: welcome.currentPlant
})
export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, null)(FCLContainerTimelineRow))
