import React from 'react'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarSubheader
} from '../components'
import { Grid } from '@material-ui/core'
import { pretiffyPlantType, getAPIBaseURL } from '../helpers'
import { get } from 'lodash'

const PartnersSidebar = ({ open, closeSideBar, sideBarTitle, actors }) => {
  var partners = []
  if (actors) {
    partners = Object.keys(actors)
      .filter(x => (Array.isArray(get(actors, x)) && get(actors, x).length > 0));
  }

  return (
    <FuseSideBar open={open} onBackDropClick={closeSideBar}>
      <FuseSideBarHeader text={sideBarTitle} handleClose={closeSideBar} />
      <FuseSideBarSubheader text={`Total ${partners.length} partners are included for this shipment`} />
      <FuseSideBarBody>
        {
          actors &&
          Object.keys(actors).map((key, index) => {
            if (Array.isArray(actors[key]) && actors[key].length > 0) {
              return (
                <Grid key={index} container className='partners-sidebar-row'>
                  <Grid item xs={9}>
                    <h4 className='title'>{actors[key][0].name}</h4>
                    <p className='sub-title'>{pretiffyPlantType(actors[key][0].type)}</p>
                  </Grid>
                  <Grid item xs={3}>
                    <img
                      src={`${getAPIBaseURL()}/organizations/${actors[key][0].organization}/logo`}
                      alt='org-logo'
                    />

                  </Grid>
                </Grid>
              )
            } else {
              return <React.Fragment key={index} />
            }
          })
        }
      </FuseSideBarBody>
    </FuseSideBar>
  )
}

export default PartnersSidebar;
