import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import IconButton from './IconButton'

/**
 *
 * @param {data} Array  { name, value }
 */

const ButtonToggle = ({ data, name, value, onChange, className, buttonClass, disabled }) => {
  const [state, setState] = useState('')

  useEffect(() => {
    setState(value)
  }, [value])

  const handleClick = v => {
    setState(v)
    if (onChange) {
      onChange(v)
    }
  }

  return (
    <div className={classNames('flex', className)}>
      {data.map((v, i) => (
        <IconButton
          key={`btn_${i}`}
          name={name}
          variant='outline'
          onClick={() => {
            if (disabled) return
            else handleClick(v.value)
          }}
          disabled={v.disabled}
          greyed={state !== v.value}
          style={{ paddingTop: 4, paddingBottom: 4 }}
          className={classNames(buttonClass, { 'mr-16': i < data.length - 1 })}
        >
          {v.label}
        </IconButton>
      ))}
    </div>
  )
}

export default ButtonToggle
