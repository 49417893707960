import React, { useState, useEffect } from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  LoadingButton,
  FuseTabs
} from '../../components'
import ReviewBookingSummary from './ReviewBookingSummary'
import { BookingForm, PartnersInfo } from './schedule-delivery-components'
import { get } from 'lodash'

const tabs = {
  bookingInfo: 'Booking',
  partnerInfo: 'Partners',
}

const tabItems = [
  {
    label: tabs.bookingInfo,
    component: BookingForm
  },
  {
    label: tabs.partnerInfo,
    component: PartnersInfo
  },
]

const ScheduleDelivery = props => {
  const { open, shipment, handleClose, handleSubmit, requesting } = props
  const [tabValue, setTabValue] = useState(0)
  const [note, setNote] = useState('')
  const [referenceEvents, setReferenceEvents] = useState({})
  const [containers, setContainers] = useState([])
  const [isInvalid, setInvalid] = useState(true)

  const onChangeTab = (value) => {
    setTabValue(value)
  }

  const checkValidation = () => {
    let validated = true
    if (get(shipment, 'type') === 'FCL') {
      containers.forEach(containers => {
        if (!get(containers, 'referenceEvents.truckInWithContainerPlace.location.street')) {
          validated = false
        }
        if (!get(containers, 'referenceEvents.truckInWithContainerPlace.expectedAt')) {
          validated = false
        }
      })
    } else {
      if (!get(referenceEvents, 'truckInForCargoDelivery.location.street')) {
        validated = false
      }
      if (!get(referenceEvents, 'truckInForCargoDelivery.expectedAt')) {
        validated = false
      }
    }
    if (validated) {
      setInvalid(false)
    } else {
      setInvalid(true)
    }
  }

  const onSubmit = () => {
    if (get(shipment, 'type') === 'FCL') {
      handleSubmit(shipment, {
        containers,
        note
      })
    } else {
      handleSubmit(shipment, {
        referenceEvents,
        note
      })
    }
  }

  useEffect(() => {
    if (get(shipment, 'type') === 'FCL') {
      setContainers(get(shipment, 'containers', []))
    }
  }, [shipment])

  useEffect(checkValidation, [containers, referenceEvents])

  useEffect(() => {
    if (!open) {
      setTabValue(0)
      setNote('')
      setReferenceEvents({})
      setContainers([])
      setInvalid(true)
    }
  }, [open])

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Schedule Delivery'
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="booking-wizard">
          <Grid item xs={12}>
            <FuseTabs showIndicator
              value={tabValue}
              data={tabItems}
              onChange={tabValue => { }} />
          </Grid>
          <Grid item xs={8} className='booking-forms'>
            <Grid container className="h-full">

              <Grid item xs={12}>
                {tabItems.map((tabItem, tabItemIndex) => {
                  if (tabItemIndex === tabValue) {
                    return <tabItem.component key={tabItemIndex}
                      shipment={shipment}
                      containers={containers}
                      setContainers={setContainers}
                      referenceEvents={referenceEvents}
                      setReferenceEvents={setReferenceEvents}
                      onChangeTab={onChangeTab} />
                  } else {
                    return <React.Fragment key={tabItemIndex} />
                  }
                })}
              </Grid>

              <Grid item xs={12}>
                <Grid container
                  alignItems="flex-end"
                  className="pt-40 h-full">
                  <Grid item xs={12}>
                    {tabValue === 0 &&
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Button className="btn btn_lg w-full btn_green"
                            disabled={isInvalid}
                            onClick={() => onChangeTab(1)}>
                            Next</Button>
                        </Grid>
                      </Grid>
                    }
                    {tabValue === 1 &&
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={12}
                              className="semi-bold text-16">
                              Add Note</Grid>
                            <Grid item xs={12}>
                              <TextField type="text"
                                name="note"
                                value={note}
                                onChange={e => setNote(e.target.value)}
                                inputProps={{
                                  className: 'text-14 regular'
                                }}
                                placeholder="You can add any extra note for this booking"
                                fullWidth />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={6}>
                          <LoadingButton className="btn btn_lg w-full btn_green"
                            loading={requesting}
                            onClick={onSubmit}>
                            Schedule Delivery</LoadingButton>
                        </Grid>

                        <Grid item xs={6}>
                          <Grid container justify="flex-end">
                            <Button className="btn btn_lg btn_transparent"
                              disabled={requesting}
                              onClick={() => onChangeTab(0)}>
                              Back</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} className="bg-gray">
            <ReviewBookingSummary shipment={shipment} />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default ScheduleDelivery
