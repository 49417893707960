import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

export default () => {
  return (
    <Grid container className='shipment-events'>
      {[1, 2, 3].map((rootItem => {
        return (
          <Grid item xs={12} key={rootItem}
            className="shipment-events-item big_dots">
            <Grid container
              className='shipment-events-item-container pl-20'
              alignItems="center">
              <Grid item xs={12} className="py-4">
                <Skeleton width={100} height={20} />
              </Grid>
              <Grid item xs={12} className="py-16">
                {[1, 2, 3].map(child1Item => {
                  return (
                    <Grid container alignItems="center"
                      key={child1Item}
                      spacing={2}>
                      <Grid item xs={3}><Skeleton height={15} /></Grid>
                      <Grid item xs={9}>
                        <Grid container
                          justify="flex-end"
                          alignItems="center"
                          spacing={2}>
                          <Grid item xs={3}><Skeleton height={15} /></Grid>
                          <Grid item xs={3}><Skeleton height={15} /></Grid>
                          <Grid item xs={3}><Skeleton height={15} /></Grid>
                          <Grid item ><Skeleton width={60} height={15} /></Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        )
      }))}
    </Grid>
  )
}

