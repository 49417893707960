import React, { useState } from 'react'
import { Grid, Typography, Icon, Button } from '@material-ui/core'
import ListSkeleton from './ListSkeleton'
import { get, find, filter } from 'lodash'
import { formatTimestamp, shipmentNameBeautifier } from '../../core/helpers'
import { FusePlaceHolder, FuseCheckBox, InfoToolTip } from '../../core/components'

const EmailList = ({ loading, emails, selectedEmails, setSelectedEmails }) => {
  const [expandEmail, setExpandEmail] = useState(null)

  const toggleExpand = (emailId) => {
    if (emailId === expandEmail) {
      setExpandEmail(null)
    } else {
      setExpandEmail(emailId)
    }
  }

  const handleSelectionChange = (event, email) => {
    const { checked } = event.target
    if (checked) {
      setSelectedEmails([
        ...selectedEmails,
        email
      ])
    } else {
      setSelectedEmails(filter(selectedEmails, i => (i._id !== email._id)))
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading &&
          <ListSkeleton />
        }
        {!loading && emails.length > 0 &&
          <Grid container className="p-8 regular" spacing={4}>
            {emails.map((email, emailIndex) => {
              const emailRecordId = get(email, '_id')
              const emailStatus = get(email, 'status')
              const isExpanded = expandEmail === emailRecordId
              const isSelected = find(selectedEmails, { _id: emailRecordId }) ? true : false
              const attachments = get(email, 'documents', []).map(i => ({
                _id: get(i, 'docstoreId', i),
                name: get(i, 'name')
              }))
              const shipments = get(email, 'shipments', [])

              return (
                <Grid item xs={12} key={emailIndex}>
                  <Grid container spacing={2}
                    className="bg-white rounded shadow overflow-hidden">
                    <Grid item xs={12}>
                      <Grid container alignItems="center" className={setSelectedEmails && "pr-6"}>
                        {selectedEmails && setSelectedEmails &&
                          <Grid item className="w-40">
                            <Grid container alignItems="center">
                              <FuseCheckBox checked={isSelected}
                                onChange={(e) => handleSelectionChange(e, email)} />
                            </Grid>
                          </Grid>
                        }

                        <Grid item xs>
                          <Typography className="text-14 semi-bold pr-12">
                            {get(email, 'subject')}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Grid container spacing={3} justify="flex-end">
                            <Grid item>
                              <Grid container alignItems="center">
                                <Icon className="mr-6 text-grey-dark text-14">email</Icon>
                                <InfoToolTip title={get(email, 'from')}>
                                  <Typography className="text-12 medium text-grey-dark max-w-200" noWrap>
                                    {get(email, 'from')}
                                  </Typography>
                                </InfoToolTip>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container alignItems="center">
                                <Icon className="mr-6 text-grey-dark text-14">description</Icon>
                                <Typography className="text-12 medium text-grey-dark">
                                  {attachments.length}&nbsp;docs
                              </Typography>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Grid container alignItems="center">
                                <Icon className="fg-gray text-14">none</Icon>
                                <Typography className="text-12 medium fg-gray">
                                  {formatTimestamp(get(email, 'createdAt'))}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} className={setSelectedEmails && "pl-40"}>
                          <Grid container>
                            <Grid item xs={12} sm={12} md={11}
                              className={isExpanded ? 'h-auto' : 'h-20 overflow-hidden'}>
                              <div className={'transition-all text-12 overflow-hidden fg-gray'}
                                dangerouslySetInnerHTML={{ __html: (get(email, 'messageBody.html') || '') }} />
                              <Grid container spacing={1} className="pt-12">
                                <Grid item xs={12}>
                                  <Grid container spacing={2}>
                                    {attachments.map((aItem, aItemIndex) => {
                                      return (
                                        <Grid item key={aItemIndex}>
                                          <Button className="btn btn_sm btn_light-blue">
                                            {get(aItem, 'name', 'NoName.file')}
                                          </Button>
                                        </Grid>
                                      )
                                    })}
                                  </Grid>
                                </Grid>
                                {emailStatus === 'processed' && shipments.length > 0 &&
                                  <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                      {shipments.map((sItem, sItemIndex) => {
                                        return (
                                          <Grid item key={sItemIndex}>
                                            <Typography className="text-12 bg-transparent-green fg-green semi-bold rounded px-8 py-4">
                                              {shipmentNameBeautifier(get(sItem, 'name', ''))}
                                            </Typography>
                                          </Grid>
                                        )
                                      })}
                                    </Grid>
                                  </Grid>
                                }

                              </Grid>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1}>
                              <Grid container justify="flex-end">
                                <Grid item>
                                  <Typography className="text-12 semi-bold fg-blue cursor-pointer"
                                    onClick={() => toggleExpand(emailRecordId)} noWrap>
                                    {isExpanded ? 'view less' : 'view more'}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        }
        {!loading && emails.length === 0 &&
          <FusePlaceHolder icon="info"
            title="No Inbound Emails"
            description="Looks like there are no inbound emails" />
        }
      </Grid>
    </Grid >
  )
}

export default EmailList
