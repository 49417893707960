import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import * as API from '../../core/api'
import { find } from 'lodash'

export const setPlantUsers = payload => ({
  type: ActionTypes.SET_PLANT_USERS,
  payload
})

export const getPlantUsers = (status, search) => async (dispatch, getState) => {
  try {
    const state = getState()

    const { ok, data } = await API.getPlantUsers(
      state.welcome.currentPlant._id,
      status === 'all' ? undefined : status,
      search
    )

    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(setPlantUsers(data.data))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const deleteUserFromPlant = (user_id, plant_id) => async (dispatch, getState) => {
  try {
    const { ok, data, status } = await API.deleteUserFromPlant(user_id, plant_id)

    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(CoreActions.showMessage({ status, data }))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getUserByEmail = email => async (dispatch, getState) => {
  try {
    const { data } = await API.getUserByEmail({ email })
    if (data.data.length > 0) {
      return find(data.data, { email }, null)
    } else {
      return null
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const createUser = (inviteEmail, userId, role, plantId) => async (dispatch, getState) => {
  try {
    let payload = {
      userId,
      inviteEmail,
      role
    }
    const { ok, status, data } = await API.addUserForPlant(payload, plantId)
    if (!ok) {
      throw new Error(data.message)
    }
    dispatch(CoreActions.showMessage({ status, data }))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}
