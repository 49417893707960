import React, { useState, useEffect } from 'react'
import { withStyles, Grid, Button, Typography, Divider } from '@material-ui/core'
import { FuseSideBar, FuseSideBarHeader, FuseSideBarBody, FuseSideBarActions, InfoToolTip, LoadMore } from '../../core/components'
import { get } from 'lodash'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import FlightIcon from '@material-ui/icons/Flight';
import TrainIcon from '@material-ui/icons/Train';
import InfoIcon from '@material-ui/icons/Info';

const priorityColors = {
  high: 'bg-red text-white',
  medium: 'bg-orange-dark text-white',
  low: 'bg-orange-light text-white'
}

const TaskTemplates = ({ classes, open, handleClose, templates, getTemplates, onAddNew }) => {
  const [pageNo, setPageNo] = useState(1)
  const [loadingMore, setLoadingMore] = useState(false)
  const [expandedTemplate, setExpandedTemplate] = useState(null)

  const loadTemplates = (currentPage = null) => {
    setLoadingMore(true)
    const pageSize = 10
    if (!currentPage) {
      currentPage = pageNo
      setPageNo(pageNo + 1)
    }
    getTemplates(
      { pageNo: currentPage, pageSize },
      null,
      () => setLoadingMore(false),
      () => setLoadingMore(false)
    )
  }

  useEffect(() => {
    loadTemplates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <FuseSideBar open={open}>
      <FuseSideBarHeader text="Task Templates" handleClose={handleClose} />
      <FuseSideBarBody>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Button className="btn btn_sm"
                  onClick={onAddNew}
                  fullWidth>+ add new</Button>
              </Grid>
              <Grid item xs={6}>
                <Grid container alignItems="center" justify="flex-end">
                  <Typography className="semi-bold fg-gray text-14">
                    {get(templates, 'total', 0)}&nbsp;Templates
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {get(templates, 'tasks', []).map((template, templateIndex) => {
            const templateId = get(template, '_id')
            const taskType = get(template, 'type', 'Shipment')
            const taskCategory = get(template, 'action.category', '')
            const priority = get(template, 'priority', '')
            const dependencies = get(template, 'dependency', [])
            const isExpanded = expandedTemplate === templateId

            return (
              <Grid item xs={12} key={templateIndex}>
                <Grid container className={classes.cardContainer}>
                  <Grid item xs={12} className={classes.cardHeader}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs>
                        <Grid container>
                          <Typography className="semi-bold text-14">
                            {get(template, 'name')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item>
                        {get(template, 'identifier.shipmentMode') === 'CBT' &&
                          <InfoToolTip title="CBT">
                            <LocalShippingIcon className="fg-gray text-14" />
                          </InfoToolTip>
                        }
                        {get(template, 'identifier.shipmentMode') === 'ocean' &&
                          <InfoToolTip title="OCEAN">
                            <DirectionsBoatIcon className="fg-gray text-14" />
                          </InfoToolTip>
                        }
                        {get(template, 'identifier.shipmentMode') === 'air' &&
                          <InfoToolTip title="AIR">
                            <FlightIcon className="fg-gray text-14" />
                          </InfoToolTip>
                        }
                        {get(template, 'identifier.shipmentMode') === 'rail' &&
                          <InfoToolTip title="RAIL">
                            <TrainIcon className="fg-gray text-14" />
                          </InfoToolTip>
                        }
                        {get(template, 'identifier.shipmentMode') === 'NA' &&
                          <InfoToolTip title="OTHER">
                            <InfoIcon className="fg-gray text-14" />
                          </InfoToolTip>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={isExpanded ? classes.cardBodyExpanded : classes.cardBody}>
                    <Grid container spacing={1} justify="space-between">
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <InfoToolTip title="Task Type">
                              <Typography className="semi-bold px-4 text-10 uppercase bg-grey-dark text-white" >
                                {taskType}
                              </Typography>
                            </InfoToolTip>
                          </Grid>
                          <Grid item>
                            <InfoToolTip title="Task Category">
                              <Typography className="semi-bold px-4 text-10 uppercase bg-grey text-white" >
                                {taskCategory}
                              </Typography>
                            </InfoToolTip>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <InfoToolTip title="Task Priority">
                          <Typography className={"semi-bold px-6 leading-normal rounded text-11 uppercase " + get(priorityColors, priority)}>
                            {priority}
                          </Typography>
                        </InfoToolTip>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className="regular fg-gray text-12">
                          {get(template, 'description')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <Typography className="semi-bold text-12 bg-grey-lighter px-4">
                                  Identifiers</Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography className="medium text-12 fg-gray">
                                  Shipment Types</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography className="medium text-12 ">
                                  {get(template, 'identifier.shipmentType', []).join(', ')}
                                </Typography>
                              </Grid>

                              <Grid item xs={6}>
                                <Typography className="medium text-12 fg-gray">
                                  Special Labels</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography className="medium text-12 ">
                                  {get(template, 'identifier.specialLabel', []).join(', ')}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          {dependencies.length > 0 &&
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography className="semi-bold text-12 bg-grey-lighter px-4">
                                    Dependencies</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={6}>
                                      <Typography className="medium text-12">
                                        Type</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography className="medium text-12 ">
                                        Value</Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                {dependencies.map((dependency, dependencyIndex) => {
                                  return (
                                    <Grid item xs={12} key={dependencyIndex}>
                                      <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                          <Typography className="medium text-12 fg-gray">
                                            {get(dependency, 'type')}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography className="medium text-12 fg-gray">
                                            {get(dependency, 'value')}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  )
                                })}
                              </Grid>
                            </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classes.cardAction}>
                    <Typography className="semi-bold text-12 uppercase fg-blue text-center cursor-pointer"
                      onClick={() => setExpandedTemplate(isExpanded ? null : templateId)}>
                      {isExpanded ? 'collapse' : 'expand'}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

          {get(templates, 'hasMore', false) &&
            <Grid item xs={12}>
              <LoadMore loading={loadingMore} onClick={() => loadTemplates()} />
            </Grid>
          }
        </Grid>
      </FuseSideBarBody>
      <FuseSideBarActions>
        <Button className='btn btn_full-space' onClick={handleClose}>close</Button>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

const styles = {
  cardContainer: {
    borderRadius: 3,
    overflow: 'hidden',
    border: '1px solid #EEE'
  },
  cardHeader: {
    backgroundColor: '#EEE',
    padding: '5px 10px'
  },
  cardBodyExpanded: {
    padding: 10
  },
  cardBody: {
    maxHeight: 56,
    overflow: 'hidden',
    padding: 10
  },
  cardAction: {
    padding: '5px 10px',
    backgroundColor: 'rgba(66, 180, 230, 0.1)'
  }
}

export default withStyles(styles)(TaskTemplates)
