import React, { useEffect } from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader
} from '../../core/components'
import { useMergeState, formatTimestamp } from '../../core/helpers'
import ReviewBookingSummary from '../../schedule/components/ReviewBookingSummary'
import { get, set, pick } from 'lodash'
import ReceiveForm from './ReceiveForm'
import { general } from '../../core/localization'

const INITIAL_STATE = {
  deliveryDate: '',
  documents: {},
  arrayOfCall: [],
  response: {},
  count: 0
}

const MarkAsReceivedDialog = ({ open, shipment, handleClose, onSubmit, onUpload }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)

  useEffect(() => {
    if (open) {
      if (get(shipment, 'type') === 'FCL') {
        setState({
          ...INITIAL_STATE,
          containers: get(shipment, 'containers', []).map(item => {
            if (item.deliveryDate) {
              set(item, 'isReceived', true)
            } else {
              set(item, 'isReceived', false)
            }
            return pick(item, ['_id', 'type', 'equipmentNo', 'deliveryDate', 'isReceived'])
          })
        })
      } else {
        setState(INITIAL_STATE)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const handleUpdateDocument = (type, documents, containerId = null) => {
    if (containerId) {
      setState({
        containers: get(state, 'containers', []).map(item => {
          if (item._id === containerId) {
            item.documents = {
              [type]: documents
            }
          }
          return item
        })
      })
    } else {
      setState({
        documents: {
          [type]: documents
        }
      })
    }
  }

  const handleDateChange = (date, name, containerId = null) => {
    const requestDateTime = new Date(date)
    if (containerId) {
      setState({
        containers: get(state, 'containers', []).map(item => {
          if (item._id === containerId) {
            item[name] = requestDateTime
          }
          return item
        })
      })
    } else {
      setState({
        [name]: requestDateTime
      })
    }
  }

  const onPickCurrent = (containerId = null) => {
    const currentDateTime = new Date()

    if (containerId) {
      setState({
        containers: get(state, 'containers', []).map(item => {
          if (item._id === containerId) {
            item.deliveryDate = currentDateTime
          }
          return item
        })
      })
    } else {
      setState({
        deliveryDate: currentDateTime
      })
    }
  }

  const handleSubmit = () => {
    const { deliveryDate, documents } = state
    if (get(shipment, 'type') === 'FCL') {
      onSubmit({
        containerDeliveryDates: get(state, 'containers', []).map(container => {
          if (container.deliveryDate) {
            return {
              containerId: container._id,
              deliveryDate: container.deliveryDate
            }
          } else {
            return false
          }
        }).filter(Boolean)
      }, shipment._id, uploadFile);

    } else {
      onSubmit({ deliveryDate }, shipment._id, () => {
        if (Object.keys(documents).length > 0) {
          uploadFile()
        } else {
          handleClose()
        }
      });
    }
  }

  const uploadFile = () => {
    const { documents, containers } = state
    if (get(shipment, 'type') === 'FCL') {
      containers.forEach((container, index) => {
        const containerDocuments = get(container, 'documents')
        if (containerDocuments) {
          Object.keys(containerDocuments).forEach(docType => {
            Object.keys(containerDocuments[docType]).forEach(fileName => {
              const file = new FormData()
              file.append('document', containerDocuments[docType][fileName])
              const data = {
                file,
                params: [shipment._id, docType, container._id]
              }
              onUpload('shipment', data)
            })
          })
        }
        if (index === (containers.length - 1)) {
          handleClose()
        }
      })
    } else {
      Object.keys(documents).forEach(docType => {
        Object.keys(documents[docType]).forEach(fileName => {
          const CSCodes = get(shipment, 'consolidations', []).map(c => c.CSCode).join(",")
          const file = new FormData()
          file.append('document', documents[docType][fileName])
          const data = {
            file,
            params: [shipment._id, docType, CSCodes]
          }
          onUpload('shipment', data, () => {
            handleClose()
            setState({ documents: {} })
          })
        })
      })
    }
  }

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Shipment receive confirmation'
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="booking-wizard">
          <Grid item xs={8} className='booking-forms'>

            {get(shipment, 'type') === 'LCL' &&
              <ReceiveForm deliveryDate={get(state, 'deliveryDate', '')}
                onPickCurrent={() => onPickCurrent()}
                handleDateChange={handleDateChange}
                handleUpdateDocument={handleUpdateDocument}
                withLabels
              />
            }

            {get(shipment, 'type') === 'FCL' &&
              <Grid container>
                {get(state, 'containers', [])
                  .map((containerItem, containerIndex) => {
                    const containerId = get(containerItem, '_id')
                    const deliveryDate = get(containerItem, 'deliveryDate', '')
                    const containerType = get(containerItem, 'type')
                    const equipmentNo = get(containerItem, 'equipmentNo')
                    const isReceived = get(containerItem, 'isReceived')
                    if (get(containerItem, 'status.current.value') === 'cancelled') {
                      return <React.Fragment key={containerIndex} />
                    } else {
                      return (
                        <Grid key={containerIndex}
                          item xs={12}>
                          <Grid container>
                            <Grid item xs={1}>
                              <img src="assets/images/icons/timeline-step/storage-facility-blue.svg"
                                alt="container" />
                            </Grid>
                            <Grid item xs={11}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Grid container
                                    style={{ height: '30px' }}
                                    alignItems="center">
                                    <label className='semi-bold fg-gray'>
                                      <span>{general.container[containerType]}</span>
                                      {equipmentNo && (
                                        <>
                                          <span className='px-6'>&bull;</span>
                                          <span>{equipmentNo}</span>
                                        </>
                                      )}
                                    </label>
                                  </Grid>
                                </Grid>
                                <Grid item xs={12} className="pt-4">
                                  {isReceived &&
                                    <Grid container
                                      className="mt-6 mb-12 medium">
                                      Received on&nbsp;
                                    <span className="bold">{formatTimestamp(deliveryDate, 'date-time')}</span>
                                    </Grid>
                                  }
                                  {!isReceived &&
                                    <ReceiveForm deliveryDate={deliveryDate}
                                      onPickCurrent={() => {
                                        onPickCurrent(containerId)
                                      }}
                                      handleDateChange={(...args) => {
                                        handleDateChange(...args, containerId)
                                      }}
                                      handleUpdateDocument={(...args) => {
                                        handleUpdateDocument(...args, containerId)
                                      }}
                                    />
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    }
                  })
                }
              </Grid>
            }

            <Grid container
              alignItems="center"
              className="pt-40">
              <Grid item xs={6} className="pr-12">
                <Button className="btn btn_lg w-full btn_light_green"
                  onClick={handleSubmit}
                  disabled={get(state, 'deliveryDate.invalid') || get(state, 'documents.invalid')}>
                  Yes, I received</Button>
              </Grid>
              <Grid item xs={6} className="pl-64">
                <Grid container
                  justify="flex-end"
                  alignItems="center"></Grid>
                <Typography className="text-10 fg-gray text-right">
                  When you confirm this shipment as received, it will move to the Completed</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <ReviewBookingSummary shipment={shipment} hideEvents />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default MarkAsReceivedDialog
