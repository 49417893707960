import React, { useState } from 'react'
import {
  withStyles,
  Grid,
  Fade,
  Icon
} from '@material-ui/core'
import { LoadingButton } from '../../core/components'
import classNames from 'classnames'
import ShipmentCardViewDialog from './ShipmentCardViewDialog'
import ShipmentPrimaryNameDialog from './ShipmentPrimaryNameDialog'
import { get, find } from 'lodash'

const styles = {
  notificationBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 105,
    height: 30,
    borderRadius: 15,
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: 'IBMPlexSans-Bold',
    cursor: 'pointer',
    lineHeight: 1.1
  },
  btnGreenBorder: {
    color: '#3DCD58 !important',
    border: '1px solid #3DCD58 !important',
    backgroundColor: 'transparent !important',

  },
  btnGreenBorderDashed: {
    color: '#3DCD58 !important',
    backgroundColor: 'rgba(61, 205, 88, 0.2) !important',
    border: '1px dashed #3DCD58 !important',
  }
}

const PlatformView = props => {
  const [shipmentCardViewDialog, setShipmentCardViewDialog] = useState(false)
  const [shipmentPrimaryNameDialog, setShipmentPrimaryNameDialog] = useState(false)
  const shipmentCardPrimaryIdOptions = [
    { value: 'name', label: 'Shipment Name Beautified' },
    { value: 'portName', label: 'Port pairs name' },
    { value: 'bookingReferenceNumber', label: 'Booking reference number' },
  ]
  const {
    scheduleDefaultMode,
    shipmentCardPrimaryId,
    classes,
    getUserSettingsByKey,
    setUserSettingByKey
  } = props

  return (
    <Grid container className="p-20 regular">
      {/* Shipment setting  */}
      <Grid item xs={12} className="py-8">
        <Grid container alignItems="center" className="pb-12">
          <Grid item xs={12}>
            <Grid container
              justify="space-between"
              alignItems="center"
              className="pb-12">
              <Grid item xs={12} sm={12} md={6}
                className="bold text-16">
                Shipment setting</Grid>
              <Grid item xs={12} sm={12} md={6}
                className="text-14 fg-gray text-right">
                Set shipment properties...</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="rounded-6 shadow bg-white">
            <Grid container alignItems="center">
              {/* Shipment card view */}
              <Grid item xs={12} sm={12} md={6}
                className="p-16 border-dashed md:border-r-1 md:border-b-0 border-r-0 border-b-1 border-gray">
                <Grid container
                  alignItems="center"
                  justify="space-between">
                  <Grid item xs={8}>
                    <Grid container
                      alignItems="center">
                      <Grid item xs={2}>
                        <Grid container
                          alignItems="center"
                          justify="center"
                          className="bg-gray w-40 h-40 rounded-20 p-8 fg-gray">
                          <Icon className="fas fa-window-maximize text-20 w-auto h-auto" />
                        </Grid>
                      </Grid>
                      <Grid item xs={10} className="text-14">
                        <Grid container>
                          <Grid item xs={12} className="bold">
                            Shipment card view</Grid>
                          {scheduleDefaultMode &&
                            <Fade in={scheduleDefaultMode}>
                              <Grid item xs={12} className="medium fg-dark-gray uppercase">
                                {scheduleDefaultMode}
                              </Grid>
                            </Fade>
                          }
                          {!scheduleDefaultMode &&
                            <Fade in={!scheduleDefaultMode}>
                              <Grid item xs={12} className="medium fg-gray">
                                Set shipment card view...</Grid>
                            </Fade>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container
                      alignItems="center"
                      justify="flex-end">
                      {!scheduleDefaultMode &&
                        <Fade in={!scheduleDefaultMode}>
                          <LoadingButton className={classNames(
                            classes.notificationBtn,
                            classes.btnGreenBorderDashed)}
                            loading={false}
                            onClick={() => setShipmentCardViewDialog(true)}>
                            add</LoadingButton>
                        </Fade>
                      }
                      {scheduleDefaultMode &&
                        <Fade in={scheduleDefaultMode}>
                          <Grid item>
                            <img src="assets/images/icons/Edit-Pen@2x.png"
                              height={20}
                              className="gray-actions"
                              onClick={() => setShipmentCardViewDialog(true)}
                              alt="edit" />
                          </Grid>
                        </Fade>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* End of Shipment card view */}

              {/* Shipment primary name */}
              <Grid item xs={12} sm={12} md={6}
                className="p-16">
                <Grid container
                  alignItems="center"
                  justify="space-between">
                  <Grid item xs={8}>
                    <Grid container
                      alignItems="center">
                      <Grid item xs={2}>
                        <Grid container
                          alignItems="center"
                          justify="center"
                          className="bg-gray w-40 h-40 rounded-20 p-8 fg-gray">
                          <Icon className="fas fa-heading text-20 w-auto h-auto" />
                        </Grid>
                      </Grid>
                      <Grid item xs={10} className="text-14">
                        <Grid container>
                          <Grid item xs={12} className="bold">
                            Shipment primary name</Grid>
                          {shipmentCardPrimaryId &&
                            <Fade in={shipmentCardPrimaryId}>
                              <Grid item xs={12}
                                className="medium fg-dark-gray">
                                {get(
                                  find(shipmentCardPrimaryIdOptions,
                                    { value: shipmentCardPrimaryId }
                                  ),
                                  'label',
                                  shipmentCardPrimaryId
                                )}
                              </Grid>
                            </Fade>
                          }
                          {!shipmentCardPrimaryId &&
                            <Fade in={!shipmentCardPrimaryId}>
                              <Grid item xs={12} className="medium fg-gray">
                                Set shipment primary name...</Grid>
                            </Fade>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container
                      alignItems="center"
                      justify="flex-end">
                      {!shipmentCardPrimaryId &&
                        <Fade in={!shipmentCardPrimaryId}>
                          <LoadingButton className={classNames(
                            classes.notificationBtn,
                            classes.btnGreenBorderDashed)}
                            loading={false}
                            onClick={() => setShipmentPrimaryNameDialog(true)}>
                            add</LoadingButton>
                        </Fade>
                      }
                      {shipmentCardPrimaryId &&
                        <Fade in={shipmentCardPrimaryId}>
                          <Grid item>
                            <img src="assets/images/icons/Edit-Pen@2x.png"
                              height={20}
                              className="gray-actions"
                              onClick={() => setShipmentPrimaryNameDialog(true)}
                              alt="edit" />
                          </Grid>
                        </Fade>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* End of Shipment primary name */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* End of We will notify you on */}

      {/* Dialogs */}
      <ShipmentCardViewDialog open={shipmentCardViewDialog}
        scheduleDefaultMode={scheduleDefaultMode}
        onSubmit={setUserSettingByKey}
        getUserSettingsByKey={getUserSettingsByKey}
        handleClose={() => setShipmentCardViewDialog(false)} />
      <ShipmentPrimaryNameDialog open={shipmentPrimaryNameDialog}
        shipmentCardPrimaryId={shipmentCardPrimaryId}
        shipmentCardPrimaryIdOptions={shipmentCardPrimaryIdOptions}
        onSubmit={setUserSettingByKey}
        getUserSettingsByKey={getUserSettingsByKey}
        handleClose={() => setShipmentPrimaryNameDialog(false)} />
      {/* End of Dialogs */}
    </Grid>
  )
}

export default withStyles(styles)(PlatformView)
