import ActionTypes from './types'
import { uniqBy } from 'lodash'

const initialState = {
  loading: true,
  loadingMore: false,
  data: [],
  total: 0,
  showLoadMore: false,
  details: null
}

const noticeBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_NOTICES: {
      return {
        ...state,
        data: action.payload.reset ?
          action.payload.data :
          uniqBy([...state.data, ...action.payload.data], '_id'),
        total: action.payload.total,
        showLoadMore: action.payload.showMore,
        loading: action.loading,
        loadingMore: false,
      }
    }
    case ActionTypes.GET_LOAD_MORE_NOTICES: {
      return {
        ...state,
        loadingMore: true
      }
    }
    case ActionTypes.GET_NOTICES_DETAILS: {
      return {
        ...state,
        details: action.payload
      }
    }

    default:
      return state
  }
}

export default noticeBoardReducer
