import React, { useEffect, useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  FuseTabs,
  LoadingButton
} from '../../core/components'
import { useMergeState, copyFirstContainerEvents } from '../../core/helpers'
import ReviewBookingSummary from './ReviewBookingSummary'
import { get, filter, findIndex } from 'lodash'
import FCLAcknowledge from "./acknowledge-booking/FCLAcknowledge";
import LCLAcknowledge from "./acknowledge-booking/LCLAcknowledge";

const INITIAL_STATE = {
  tabValue: 0,
}

const ELPAcknowledgeBookingDialog = ({ open, currentPlant, shipment, handleClose, onSubmit, requesting }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const [invalid, setInvalid] = useState(true)

  useEffect(() => {
    setState({
      containers: get(shipment, 'containers', [])
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  useEffect(() => {
    const type = get(shipment, 'type')
    if (type === 'FCL') {
      const tabValue = 0
      checkValidation(get(shipment, `containers[${tabValue}]`), type)
      setState({ tabValue: 0 })
    } else if (type === 'LCL') {
      checkValidation(get(shipment, `referenceEvents`), type)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment._id, open])

  let transportRoute = [];

  if (currentPlant.type === 'ofp') {
    transportRoute = [
      {
        place: get(shipment, 'plannedRoute.originPort.name'),
        address: get(shipment, 'plannedRoute.originPort.street') || get(shipment, 'plannedRoute.originPort.country'),
        iconRounded: true,
        connector: {
          icon: 'directions_boat',
          iconRounded: true
        }
      },
      {
        place: get(shipment, 'plannedRoute.destinationPort.name'),
        address: get(shipment, 'plannedRoute.destinationPort.street') || get(shipment, 'plannedRoute.destinationPort.country'),
      }];
  }
  else if (currentPlant.type === 'obl') {
    transportRoute = [{
      place: get(shipment, 'plannedRoute.origin.name'),
      address: get(shipment, 'plannedRoute.origin.street') || get(shipment, 'plannedRoute.origin.country'),
      connector: {
        icon: 'local_shipping',
        iconRounded: true
      }
    },
    {
      place: get(shipment, 'plannedRoute.originPort.name'),
      address: get(shipment, 'plannedRoute.originPort.street') || get(shipment, 'plannedRoute.originPort.country'),
    }
    ];
  }

  const availableContainerIndex = get(state, 'containers', []).map((item, index) => {
    if (get(item, 'status.current.value') === 'cancelled') {
      return false
    } else {
      return index.toString()
    }
  }).filter(Boolean)

  const changeTab = (tabValue) => {
    setState({ tabValue })
    checkValidation(get(state, `containers[${Number(availableContainerIndex[tabValue])}]`), 'FCL')
  }

  const checkValidation = (data, type) => {
    setInvalid(false)

    if (type === 'LCL') {
      // Shipper warehouse
      if (!get(data, 'truckOutWithCargo.expectedAt')) {
        setInvalid(true)
      }
      // END Shipper warehouse

      // Storage 1
      if (get(data, 'truckInToSFWithCargo')) {
        if (!get(data, 'truckInToSFWithCargo.location.street')) {
          setInvalid(true)
        }
        if (!get(data, 'truckInToSFWithCargo.expectedAt')) {
          setInvalid(true)
        }
      }
      if (get(data, 'truckOutFromSFWithCargo')) {
        if (!get(data, 'truckOutFromSFWithCargo.location.street')) {
          setInvalid(true)
        }
        if (!get(data, 'truckOutFromSFWithCargo.expectedAt')) {
          setInvalid(true)
        }
      }
      // END Storage 1
    } else if (type === 'FCL') {
      if (get(data, 'status.current.value') !== 'cancelled') {
        const referenceEvents = get(data, 'referenceEvents', {})
        // Container Yard (CY)
        if (!get(referenceEvents, 'emptyContainerPickUpFromYard.location.street')) {
          setInvalid(true)
        }
        if (!get(referenceEvents, 'emptyContainerPickUpFromYard.expectedAt')) {
          setInvalid(true)
        }
        // END Container Yard (CY)

        // Storage 1
        if (get(referenceEvents, 'truckInToSFWithEmptyContainer')) {
          if (!get(referenceEvents, 'truckInToSFWithEmptyContainer.location.street')) {
            setInvalid(true)
          }
          if (!get(referenceEvents, 'truckInToSFWithEmptyContainer.expectedAt')) {
            setInvalid(true)
          }
        }
        if (get(referenceEvents, 'truckOutFromSFwithEmptyContainer')) {
          if (!get(referenceEvents, 'truckOutFromSFwithEmptyContainer.location.street')) {
            setInvalid(true)
          }
          if (!get(referenceEvents, 'truckOutFromSFwithEmptyContainer.expectedAt')) {
            setInvalid(true)
          }
        }
        // END Storage 1

        // Shipper warehouse
        if (!get(referenceEvents, 'truckInToSWHForCargoPickup.expectedAt')) {
          setInvalid(true)
        }
        if (!get(referenceEvents, 'truckOutFromSWHWithCargo.expectedAt')) {
          setInvalid(true)
        }
        // END Shipper warehouse

        // Storage 2
        if (get(referenceEvents, 'truckInToSFForCargoPickup')) {
          if (!get(referenceEvents, 'truckInToSFForCargoPickup.location.street')) {
            setInvalid(true)
          }
          if (!get(referenceEvents, 'truckInToSFForCargoPickup.expectedAt')) {
            setInvalid(true)
          }
        }
        if (get(referenceEvents, 'truckOutFromSFWithCargo')) {
          if (!get(referenceEvents, 'truckOutFromSFWithCargo.location.street')) {
            setInvalid(true)
          }
          if (!get(referenceEvents, 'truckOutFromSFWithCargo.expectedAt')) {
            setInvalid(true)
          }
        }
        // END Storage 2
      }
    }
  }

  const handleFCLEventChange = (_id, events) => {
    var containers = get(state, 'containers', [])
    const index = findIndex(containers, { _id })
    containers[index] = {
      ...containers[index],
      ...get(events, 'container', {})
    }

    checkValidation(containers[index], 'FCL')
    setState({ containers })
  }

  const handleLCLEventChange = (events) => {
    const referenceEvents = {
      ...get(shipment, 'referenceEvents', {}),
      ...get(events, 'referenceEvents', {})
    }

    checkValidation(referenceEvents, 'LCL')
    setState({ referenceEvents })
  }

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Acknowledge booking'
        handleClose={handleClose} />
      <FuseDialogBody>
        {get(shipment, 'type') === 'FCL' &&
          <>
            <Grid container direction='row'>
              <FuseTabs value={state.tabValue}
                data={filter(get(state, 'containers', []), item =>
                  (get(item, 'status.current.value') !== 'cancelled')
                ).map((container, index) => {
                  return {
                    label: get(container, 'type') + ' (' + (index + 1) + ')'
                  }
                })
                }
                onChange={tabValue => { }}
              />
            </Grid>
            {filter(get(state, 'containers', []), item =>
              (get(item, 'status.current.value') !== 'cancelled')
            ).map((container, index) => {
              const containerLength = filter(get(state, 'containers', []), item =>
                (get(item, 'status.current.value') !== 'cancelled')
              ).length

              return (
                state.tabValue === index &&
                <Grid container key={index}
                  className='booking-wizard'>
                  <Grid item xs={8} className='booking-forms'>
                    <FCLAcknowledge shipment={shipment}
                      onCopyFromFirst={async (events) => {
                        await setState({
                          containers: copyFirstContainerEvents(
                            get(state, 'containers', []),
                            index,
                            events,
                            true
                          )
                        })
                        // switching tabs for updating state
                        changeTab(0)
                        changeTab(index)
                      }}
                      currentIndex={index}
                      container={container}
                      journey={get(shipment, 'journey', [])}
                      onChange={(data) => handleFCLEventChange(container._id, data)} />
                    <Grid container
                      justify="space-between"
                      className="pt-20">
                      {
                        index < (containerLength - 1) &&
                        <Grid item xs={6}
                          className='pr-12'>
                          <Button className='btn w-full'
                            disabled={invalid}
                            onClick={() => changeTab(index + 1)}>next</Button>
                        </Grid>
                      }

                      {
                        index === (containerLength - 1) &&
                        <Grid item xs={6}
                          className='pr-12'>
                          <LoadingButton className='btn w-full'
                            loading={requesting}
                            disabled={invalid}
                            onClick={() => onSubmit(state, shipment)}>
                            save</LoadingButton>
                        </Grid>
                      }

                      {
                        index > 0 &&
                        <Grid item xs={6}
                          className='pl-12'>
                          <Grid container
                            justify="flex-end">
                            <Button className='btn btn_transparent'
                              disabled={requesting}
                              onClick={() => changeTab(index - 1)}>back</Button>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <ReviewBookingSummary transportRoute={transportRoute}
                      container={container}
                      shipment={shipment}
                      truckInWithEmptyContainer
                      vesselSchedules
                      hideEvents />
                  </Grid>
                </Grid>
              )
            })}
          </>
        }

        {
          get(shipment, 'type') === 'LCL' &&
          <>
            <Grid container className='booking-wizard'>
              <Grid item xs={8} className='booking-forms'>
                <LCLAcknowledge shipment={shipment}
                  onChange={handleLCLEventChange} />
                <Grid container
                  justify="space-between"
                  className="pt-20">
                  <Grid item xs={6}
                    className='pr-12'>
                    <LoadingButton className='btn w-full'
                      loading={requesting}
                      disabled={invalid}
                      onClick={() => onSubmit(state, shipment)}>
                      save</LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <ReviewBookingSummary transportRoute={transportRoute}
                  shipment={shipment}
                  vesselSchedules
                  hideEvents />
              </Grid>
            </Grid>
          </>
        }
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default ELPAcknowledgeBookingDialog
