import ActionTypes from './types'
import * as API from '../../core/api'
import * as CoreActions from '../../core/store/actions'
import { checkPagination } from '../../core/helpers'

export const getErpTransmissions = (params, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getErpTransmissions(params)
    const { pageNo, pageSize } = params

    if (!ok) {
      throw new Error(data.message)
    }
    
    checkPagination({ pageNo, pageSize }, data)
    if (pageNo === 1) {
      dispatch({
        type: ActionTypes.SET_ERP_TRANSMISSIONS_SUCCESS,
        payload: data
      })
    } else {
      dispatch({
        type: ActionTypes.SET_ERP_TRANSMISSIONS_LOAD_MORE_SUCCESS,
        payload: data
      })
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}