import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Menu, Button, Typography, withStyles, Card, CardHeader, CardContent, CardActions, Icon } from '@material-ui/core'
import ListSearchBox from 'core/components/Inputs/ListSearchBox'
import StatusFilter from './StatusFilter'
import FilterDialog from './FilterDialog'
import SortBy from './SortBy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Tooltip from '@material-ui/core/Tooltip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import * as CoreActions from 'core/store/actions'
import { get } from "lodash";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
    style={{
      marginTop: 8
    }}
    {...props}
  />
))

const FilterRow = ({
  allowCustomFilter,
  allowStatusFilter,
  shipments,
  shipmentsTotal,
  search,
  sort,
  handleSortChange,
  handleSearchChange,
  handleStatusChange,
  getShipmentFilterOptions,
  loadCustomFilters,
  loadDocuments,
  getEventsListUserSettings,
  setCustomFilter,
  deleteCustomFilter,
  customFilters,
  applyCustomFilter,
  customFilter,
  filters,
  documentsList,
  eventsList,
  classes
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [newFilter, setNewFilter] = useState(false)
  const [tooltipVisible, setTooltipVisibility] = useState(false)
  useEffect(() => {
    getShipmentFilterOptions()
    loadCustomFilters()
    loadDocuments()
    getEventsListUserSettings()
  }, [getShipmentFilterOptions, loadCustomFilters, getEventsListUserSettings, loadDocuments])
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleTooltipClose = () => {
    setTooltipVisibility(false)
  }
  const handleTooltipOpen = () => {
    setTooltipVisibility(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      color: '#717171',
      maxWidth: 220
    }
  }))(Tooltip)
  return (
    <>
      <Grid container alignItems='center' className='mb-8' spacing={2}>

        <Grid item xs={12} sm={12} md={4}>
          <Grid container
            spacing={2}
            alignItems="center">
            {allowStatusFilter &&
              <Grid item xs={6} sm={6} md={6}>
                <StatusFilter onSelectionChange={handleStatusChange} />
              </Grid>
            }

            {shipmentsTotal > 0 &&
              <Grid item xs={6} sm={6} md={6}>
                <Typography className={classes.shipmentsNo}>
                  {shipmentsTotal} shipments</Typography>
              </Grid>
            }
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Grid container
            justify="flex-end"
            spacing={2}
            alignItems="center">
            <Grid item xs={12} sm={12} md={5}>
              <ListSearchBox
                value={search}
                placeholder='Search...'
                onChange={handleSearchChange}
                icon={
                  <ClickAwayListener onClickAway={handleTooltipClose}>
                    <HtmlTooltip
                      PopperProps={{
                        disablePortal: true
                      }}
                      onClose={handleTooltipClose}
                      open={tooltipVisible}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <div style={{ backgroundColor: '#717171', color: '#FFFFFF' }}>
                          <Typography style={{ fontSize: 10 }}
                            color='inherit'
                            className="bold">
                            You can search shipments by:
                      </Typography>
                          <p style={{ fontSize: 10, lineHeight: 1.1 }}
                            className="regular">
                            CBN no, Container no, Vessel name, CS code, Outbound no, HBL no, MBL no, Incoterm, Shipper, Drophub,
                            Consignee & All partners.
                      </p>
                        </div>
                      }
                    >
                      <InfoOutlinedIcon
                        onClick={handleTooltipOpen}
                        style={{ color: '#CFCFD1', cursor: 'pointer' }}
                        fontSize='default'
                      />
                    </HtmlTooltip>
                  </ClickAwayListener>
                }
              />
            </Grid>

            {allowCustomFilter &&
              <Grid item xs={6} sm={6} md={3}>
                <div>
                  <Button aria-controls='customized-menu'
                    aria-haspopup='true'
                    variant='outlined'
                    color='primary'
                    disableRipple={true}
                    onClick={handleClick}
                    className={classes.filterBox}>
                    <Grid container alignItems="center">
                      <Grid item xs={10}>
                        <Grid container alignItems="center">
                          <img src='assets/images/icons/shipments-list/filter.svg'
                            className="mr-8"
                            alt='filter' />
                          <span className={classes.filtersTypography}>Filters</span>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <Grid container justify='flex-end'>
                          <img src='assets/images/icons/shipments-list/down.svg'
                            className="mr-6"
                            alt='down' />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Button>
                  <StyledMenu id='customized-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}>
                    <Card className={classes.card}>
                      <CardHeader className={classes.cardHeader}
                        title={
                          <Grid container
                            alignItems="center">
                            <Icon className="cursor-pointer text-12 fg-green fas fa-plus mr-6" />
                            <Typography className={classes.cardHeaderOption + ' fg-green'}>
                              Create new filter</Typography>
                          </Grid>
                        }
                        onClick={() => {
                          setNewFilter(true)
                          setDialogOpen(true)
                          handleClose()
                        }} />
                      <CardContent className={classes.cardContent}>
                        {customFilters && customFilters.map((filter, index) => {
                          return (
                            <Typography className={classes.menuOption}
                              key={index}
                              onClick={() => {
                                applyCustomFilter(filter)
                                handleClose()
                              }}>
                              {filter.settingsName}
                            </Typography>
                          )
                        })}
                      </CardContent>
                      {customFilters && customFilters.length > 0 && (
                        <CardActions className={classes.footerMenu}
                          onClick={() => {
                            setNewFilter(false)
                            setDialogOpen(true)
                            handleClose()
                          }}>
                          <Typography>Open filter options</Typography>
                        </CardActions>
                      )}
                    </Card>
                  </StyledMenu>
                </div>
              </Grid>
            }

            <Grid item xs={6} sm={6} md={3}>
              <SortBy handleSortChange={handleSortChange}
                sort={sort} />
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      {customFilter && (
        <Grid container alignItems='flex-start' className='mb-8'>
          <Grid item className="semi-bold">
            <label>Applied Filter:&nbsp;</label>
            <label className="fg-orange mr-12 underline">
              {get(customFilter, 'settingsName')}
            </label>
            <button className={classes.clearFilter}
              onClick={() => {
                applyCustomFilter(null)
              }}>clear</button>
          </Grid>
        </Grid>
      )}
      <FilterDialog
        open={dialogOpen}
        saveOrUpdateCustomFilter={setCustomFilter}
        deleteCustomFilter={deleteCustomFilter}
        filters={filters}
        setDialogOpen={setDialogOpen}
        applyCustomFilter={applyCustomFilter}
        newFilter={newFilter}
        documentsList={documentsList}
        customFilters={customFilters}
        eventsList={eventsList}
      />
    </>
  )
}

const styles = () => ({
  bordered: {
    border: '1px solid #e3e3e4',
    borderRadius: 4
  },
  filterBox: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #ced4da',
    borderRadius: 4,
    width: '100%',
    height: 42,
    paddingLeft: 10,
    paddingRight: 0
  },
  filtersTypography: {
    color: '#333333',
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: 12,
    textTransform: 'none',
    textAlign: 'left'
  },
  createFilterText: {
    fontWeight: 600,
    fontSize: 12,
    textAlign: 'left',
    color: '#333333'
  },
  shipmentsNo: {
    color: '#7F7F7F',
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: 14,
    textAlign: 'left'
  },
  clearFilter: {
    backgroundColor: '#B10043',
    border: 'none',
    borderRadius: 4,
    width: 46,
    height: 20,
    fontFamily: 'IBMPlexSans-SemiBold',
    fontSize: 10,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: '#FFFFFF'
  },
  card: {
    minWidth: 205,
    border: 'none',
    marginTop: -8,
    marginBottom: -8
  },
  cardHeader: {
    backgroundColor: '#3dcd5729',
    padding: '10px 16px',
    cursor: 'pointer',
  },
  cardHeaderOption: {
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: 12,
    textAlign: 'left',
    cursor: 'pointer',
  },
  cardContent: {
    maxHeight: 300,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '4px 16px'
  },
  menuOption: {
    color: '#7F7F7F',
    fontFamily: 'IBMPlexSans-Bold',
    fontSize: 12,
    textAlign: 'left',
    paddingTop: 8,
    paddingBottom: 8,
    cursor: 'pointer'
  },
  footerMenu: {
    backgroundColor: 'rgba(66, 180, 230, 0.1)',
    width: '100%',
    padding: '10px 16px',
    cursor: 'pointer',
    '& > p': {
      color: '#42B4E6',
      fontFamily: 'IBMPlexSans-Bold',
      fontSize: 12,
      height: '100%',
      width: '100%',
      cursor: 'pointer'
    }
  }
})

const mapStateToProps = ({
  core: {
    filters,
    customFilters,
    documents,
    userSettings: { eventsList }
  }
}) => ({
  filters,
  customFilters,
  eventsList,
  documentsList: documents.map(doc => {
    return { name: doc.type }
  })
})
const mapDispatchToProps = dispatch => ({
  loadCustomFilters: () => dispatch(CoreActions.loadCustomFilters()),
  loadDocuments: () => dispatch(CoreActions.loadDocuments()),
  setCustomFilter: (payload, showStatus) => dispatch(CoreActions.setCustomFilter(payload, showStatus)),
  deleteCustomFilter: id => dispatch(CoreActions.deleteCustomFilter(id)),
  // createCustomFilter: payload => dispatch(ShipmentActions.createCustomFilter(payload)),
  getEventsListUserSettings: () => dispatch(CoreActions.getEventsListUserSettings())
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FilterRow))
