import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  API_ATTEMPT
  API_SUCCESS
  API_FAILED

  GET_OUTBOUNDS_SUCCESS
  SET_OUTBOUND_LOADING
  SET_OUTBOUND_LOADING_MORE

  GET_AIR_OUTBOUNDS_SUCCESS
  SET_AIR_OUTBOUND_LOADING
  SET_AIR_OUTBOUND_LOADING_MORE

  GET_CBT_OUTBOUNDS_SUCCESS
  SET_CBT_OUTBOUND_LOADING
  SET_CBT_OUTBOUND_LOADING_MORE

  GET_RAIL_OUTBOUNDS_SUCCESS
  SET_RAIL_OUTBOUND_LOADING
  SET_RAIL_OUTBOUND_LOADING_MORE

  GET_OTHER_OUTBOUNDS_SUCCESS
  SET_OTHER_OUTBOUND_LOADING
  SET_OTHER_OUTBOUND_LOADING_MORE

  GET_INITIAL_PREP_SUCCESS
  CREATE_SHIPMENT_SUCCESS

  SET_MANAGE_SHIPMENTS_LOADING
  SET_MANAGE_SHIPMENTS_LOADING_MORE
  GET_ALL_MANAGE_SHIPMENT_SUCCESS
  GET_ALL_MANAGE_SHIPMENT_TOTAL_SUCCESS

  SET_AIR_MANAGE_SHIPMENTS_LOADING
  SET_AIR_MANAGE_SHIPMENTS_LOADING_MORE
  GET_AIR_MANAGE_SHIPMENT_SUCCESS
  GET_AIR_MANAGE_SHIPMENT_TOTAL_SUCCESS
  `
)
