
export function pretiffyPlantType(type) {
  switch (type) {
    case 'shipper': return 'Shipper';
    case 'dropHub': return 'Drop Hub';
    case 'obl': return 'Export Logistics Partner';
    case 'ofp': return 'Export Freight Partner';
    case 'obc': return 'Export Customs Broker';
    case 'ibc': return 'Import Customs Broker';
    case 'ifp': return 'Import Freight Partner';
    case 'ibl': return 'Import Logistics Partner';
    case 'consignee': return 'Consignee';
    default: return ''
  }
}
