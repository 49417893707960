import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, Link, Grid, FormHelperText, CircularProgress } from '@material-ui/core'
import { FuseTextField, FuseAnimate } from '../../core/components'
import validator from 'validator'

const labelStyles = {
  fontSize: '1.1em',
  marginBottom: '0.4em'
}

const inputStyles = {
  fontSize: '1.2em'
}

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const ForgotPasswordForm = ({
  auth,
  forgotPassword,
  loading,
  setShowForgotPassword,
  forgotPasswordStep,
  setForgotPasswordStep,
  resetPassword,
  sessionEmail = ''
}) => {
  const [email, setEmail] = useState('')

  useEffect(() => {
    setEmail(sessionEmail)
  }, [sessionEmail])

  return (
    <div className='flex flex-col justify-start w-full'>
      {forgotPasswordStep === 1 && (
        <FuseAnimate>
          <Step1
            setShowForgotPassword={setShowForgotPassword}
            email={email}
            setEmail={setEmail}
            loading={loading}
            forgotPassword={forgotPassword}
            setForgotPasswordStep={setForgotPasswordStep}
          />
        </FuseAnimate>
      )}
      {forgotPasswordStep === 2 && (
        <FuseAnimate>
          <Step2
            setShowForgotPassword={setShowForgotPassword}
            auth={auth}
            loading={loading}
            forgotPassword={forgotPassword}
            resetPassword={resetPassword}
          />
        </FuseAnimate>
      )}
    </div>
  )
}

const Step1 = ({ setShowForgotPassword, setEmail, email, loading, forgotPassword, setForgotPasswordStep }) => {
  const initialErrorState = {
    email: null,
    email_helper_text: null
  }
  const [error, setError] = useState(initialErrorState)
  return (
    <form
      onSubmit={async e => {
        e.preventDefault()
        if (!validator.isEmail(email)) {
          setError({ email: true, email_helper_text: 'Email not valid' })
        } else {
          let data = await forgotPassword(email)
          if (data) {
            setForgotPasswordStep(2)
          }
        }
      }}
      noValidate
    >
      <FuseTextField
        className='mb-16'
        type='text'
        name='email'
        label='Email'
        labelStyles={labelStyles}
        inputStyles={inputStyles}
        icon={{ ...baseTextSetting, name: 'email' }}
        value={email}
        onChange={e => setEmail(e.target.value.trim())}
        error={error.email}
        helperText={error.email_helper_text}
        required
        fullWidth
      />
      <Grid container alignItems='center' alignContent='flex-start'>
        <Grid item className='mt-8'>
          <Link onClick={() => setShowForgotPassword(false)}>Back to login</Link>
        </Grid>
        <Grid item style={{ marginLeft: 'auto' }}>
          <Button type='submit'
            variant='contained'
            style={{ borderRadius: '50vh' }}
            color='primary'
            className='min-w-160 ml-auto btn mt-8'
            aria-label='forgot password'
            disabled={loading || email === ''}>
            {!loading ? 'Forgot Password' : 'Checking...'}{' '}
            {loading && <CircularProgress color='primary' size={20} style={{ marginLeft: 20 }} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

const Step2 = ({ setShowForgotPassword, auth, forgotPassword, resetPassword, loading }) => {
  const initialErrorState = {
    otp: null,
    newPassword: null,
    confirmPassword: null,
    otp_helper_text: null,
    newPassword_helper_text: null,
    confirmPassword_helper_text: null
  }
  const [error, setError] = useState(initialErrorState)
  // const resetError = () => setError(initialErrorState)
  let created = new Date(auth.forgotPassword.challengeIdCreatedAt)
  let diff = Math.ceil(Math.abs(created - new Date()) / 1000)
  diff = 5 * 60 - diff
  const [otp, setOTP] = useState('')
  const [seconds, setSeconds] = useState(diff)
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const onOTPChange = e => setOTP(e.target.value)
  useEffect(() => {
    if (seconds < 1) {
      return
    }
    let timer = setTimeout(() => {
      setSeconds(seconds - 1)
    }, 1000)
    return () => clearTimeout(timer)
  }, [seconds])

  const resetPasswordSubmit = async e => {
    e.preventDefault()
    if (!otp || otp.trim() === '') {
      setError({ ...initialErrorState, otp: true, otp_helper_text: 'Enter the OTP received in your email.' })
      return
    }
    var regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
    if (!regex.test(newPassword)) {
      setError({
        ...initialErrorState,
        newPassword: true,
        newPassword_helper_text:
          ' Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character'
      })
      return
    }
    if (!confirmPassword || confirmPassword.trim() === '') {
      setError({ ...initialErrorState, confirmPassword: true, confirmPassword_helper_text: 'Confirm Password' })
      return
    }
    if (newPassword !== confirmPassword) {
      setError({ ...initialErrorState, confirmPassword: true, confirmPassword_helper_text: 'Passwords dont match' })
      return
    }
    const resetPasswordStatus = await resetPassword({
      challengeId: auth.forgotPassword.challengeId,
      email: auth.forgotPassword.email,
      newPassword,
      otp
    })

    if (resetPasswordStatus === 200) {
      setShowForgotPassword(false)
    }
  }
  return (
    <form onSubmit={resetPasswordSubmit} noValidate>
      <FuseTextField
        className='mb-16'
        type='password'
        name='otp'
        label='OTP'
        labelStyles={labelStyles}
        inputStyles={inputStyles}
        icon={{ ...baseTextSetting, name: 'vpn_key' }}
        value={otp}
        onChange={onOTPChange}
        error={error.otp}
        helperText={error.otp_helper_text}
        required
        fullWidth
      />
      <FormHelperText style={{ marginTop: 0, marginBottom: 30 }}>
        <span>
          {seconds > 1
            ? `OTP is valid for next ${Math.floor(seconds / 60)}:${
            seconds % 60 < 10 ? `0${seconds % 60}` : `${seconds % 60}`
            } minutes. `
            : `OTP has timed out. `}{' '}
          {seconds < 2.5 * 60 && (
            <Link
              style={{ color: '#40a9ff' }}
              onClick={async () => {
                // let data = await forgotPassword(auth.forgotPassword.email)
                setSeconds(5 * 60)
              }}
            >
              Resend OTP
            </Link>
          )}
        </span>
      </FormHelperText>
      <FuseTextField
        className='mb-16'
        type='password'
        name='newpassword'
        label='New Password'
        labelStyles={labelStyles}
        inputStyles={inputStyles}
        icon={{ ...baseTextSetting, name: 'vpn_key' }}
        value={newPassword}
        onChange={e => {
          setNewPassword(e.target.value)
        }}
        error={error.newPassword}
        helperText={error.newPassword_helper_text}
        required
        fullWidth
      />
      <FuseTextField
        className='mb-16'
        type='password'
        name='confirmNewpassword'
        label='Confirm Password'
        labelStyles={labelStyles}
        inputStyles={inputStyles}
        icon={{ ...baseTextSetting, name: 'vpn_key' }}
        value={confirmPassword}
        onChange={e => {
          setConfirmPassword(e.target.value)
        }}
        error={error.confirmPassword}
        helperText={error.confirmPassword_helper_text}
        required
        fullWidth
      />
      <Grid container alignItems='center' alignContent='flex-start'>
        <Grid item className='mt-8'>
          <Link onClick={() => setShowForgotPassword(false)}>Back to login</Link>
        </Grid>
        <Grid item style={{ marginLeft: 'auto' }}>
          <Button type='submit'
            variant='contained'
            style={{ borderRadius: '50vh' }}
            color='primary'
            className='min-w-160 ml-auto btn mt-8'
            aria-label='forgot password'
            disabled={loading || (!otp || !newPassword || !confirmPassword)}>
            {!loading ? 'Reset Password' : 'Checking...'}{' '}
            {loading && <CircularProgress color='primary' size={20} style={{ marginLeft: 20 }} />}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}
ForgotPasswordForm.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default ForgotPasswordForm
