import React from 'react'
import BookingGroupHeader from './BookingGroupHeader'
import OutBoundTableRow from '../outbound-table/OutBoundTableRow'

const BookingTableGroup = ({ data, onChkClick, onOutboundsClick }) => {
  return (
  <div className='rounded bg-white p-16 mb-16'>
    <BookingGroupHeader data={data} />
    {data.outbounds.length > 0 &&
      data.outbounds.map((outbound, index) => (
        <OutBoundTableRow
          key={index}
          className='booking__confirmed_row'
          data={outbound}
          onChkClick={v => onChkClick(v, data._id)}
          onOutboundsClick={onOutboundsClick({ type: 'booking', shipment: data, CSCode: outbound.code })}
        />
      ))}
  </div>
)}

export default BookingTableGroup
