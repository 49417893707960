import { get, groupBy, sumBy, uniq } from 'lodash'
import { getCountryCode, shipmentNameBeautifier, formatTimestamp } from 'core/helpers'

export const getGroupedOutbounds = (outbounds) => {
  const result = []

  try {
    outbounds.forEach(v => {
      const item = {
        stpCode: get(v, 'shipToParty.code', ''),
        warning: get(v, 'warning.enabled', false),
        warningReason: get(v, 'warning.reason', 'There are one or more issues with outbounds in this group. Please contact admin for more info.'),
        items: []
      }

      if (v.laneType === 'preAwarded') {
        item.country = getCountryCode(get(v, 'consignee.office.address.country', ''))
        item.plantType = 'Consignee'
        item.plantName = get(v, 'consignee.name')
      }
      if (v.laneType === 'spot') {
        item.plantType = 'Spot'
        item.plantName = get(v, 'shipToParty.name')
      }

      const csCodeGrouped = groupBy(v.outbounds, _v => (_v.meta.CSCode || _v.meta.outboundId || 'No Code'));
      const csCodeItems = []

      Object.keys(csCodeGrouped).forEach(code => {
        const outboundCount = csCodeGrouped[code].length
        const outboundIds = csCodeGrouped[code].map(__v => __v._id)
        const outboundTransportMethods = csCodeGrouped[code].map(__v => __v.transportMethod)
        const outboundSources = [...csCodeGrouped[code].map(__v => __v.source)]
        const groupageDate = []
        const groupageDay = []

        if (!outboundCount) {
          return
        }

        const sampleItem = csCodeGrouped[code][0]
        const _item = {
          code,
          isCSItem: !!get(sampleItem, 'meta.CSCode'),
          outboundCount,
          outboundIds,
          outboundTransportMethods,
          outboundSources: uniq(...outboundSources),
          totalWeight: get(sampleItem, 'consolidatedWght.value', get(sampleItem, 'weight.value', 0)),
          totalVolume: get(sampleItem, 'consolidatedVolume.value', get(sampleItem, 'volume.value', 0)),
          weightUnit: get(sampleItem, 'consolidatedWght.unit', get(sampleItem, 'weight.unit', 'KG')),
          volumeUnit: get(sampleItem, 'consolidatedVolume.unit', get(sampleItem, 'volume.unit', 'M3')),
          specialLabel: get(sampleItem, 'specialLabels[0]', '').toUpperCase(),
          goodsType: get(sampleItem, 'goodsType', '').toUpperCase(),
          shipmentMode: get(sampleItem, 'shipmentMode', '').toUpperCase(),
        }

        csCodeGrouped[code].forEach(oItem => {
          groupageDate.push(formatTimestamp(oItem.groupageDate))
          groupageDay.push(get(oItem, 'groupageDay'))
        })

        csCodeItems.push({
          itemInfo: _item,
          groupageDate: uniq(groupageDate),
          groupageDay: uniq(groupageDay).filter(Boolean)
        })
      })

      const groupageDateGrouped = groupBy(csCodeItems, csItem => csItem.groupageDate.join(','))

      Object.keys(groupageDateGrouped).forEach(grDate => {
        let groupageDate = grDate.split(',')
        groupageDate.sort((aDate, bDate) => {
          aDate = new Date(aDate)
          bDate = new Date(bDate)
          if (aDate > bDate) return -1
          else if (aDate < bDate) return 1
          else return 0
        })

        const subItem = {
          items: [],
          groupageDay: [],
          groupageDate
        }
        groupageDateGrouped[grDate].forEach(grDateItem => {
          subItem.groupageDay.push(...grDateItem.groupageDay)
          subItem.groupageDay = uniq(subItem.groupageDay).sort()
          subItem.items.push(grDateItem.itemInfo)
        })
        item.items.push(subItem)
      })

      result.push(item)
    })

    return result
  } catch (err) {
    console.log(err)
    return []
  }
}

export const getDisplayShipments = (shipments, shipmentCardPrimaryId) => {
  try {
    const result = shipments.map(shipment => {
      const shipmentItem = {
        outbounds: []
      }
      const codeGrouped = groupBy(shipment.outbounds, v => (v.meta.CSCode || v.meta.outboundId || 'No Code'))

      Object.keys(codeGrouped).forEach(code => {
        const current = codeGrouped[code]
        const outboundSpacialLabels = [...current.map(__v => __v.specialLabels)]
        const sample = current[0]

        const outboundItem = {
          code,
          totalVolume: get(sample, 'consolidatedVolume.value', get(sample, 'volume.value', 0)),
          totalWeight: get(sample, 'consolidatedWght.value', get(sample, 'weight.value', 0)),
          volumeUnit: get(sample, 'consolidatedVolume.unit', get(sample, 'volume.unit', 'M3')),
          weightUnit: get(sample, 'consolidatedWght.unit', get(sample, 'weight.unit', 'KG')),
          specialLabel: uniq(...outboundSpacialLabels).join(', ').toUpperCase(),
          goodsType: get(sample, 'goodsType', '').toUpperCase(),
          shipmentMode: get(sample, 'shipmentMode', '').toUpperCase(),
          outboundCount: current.length,
          ids: current.map(_v => _v._id)
        }

        shipmentItem.outbounds.push(outboundItem)
      })

      shipmentItem.totalWeight = sumBy(get(shipmentItem, 'outbounds', []), v => v.totalWeight)
      shipmentItem.totalVolume = sumBy(get(shipmentItem, 'outbounds', []), v => v.totalVolume)
      shipmentItem.volumeUnit = get(shipmentItem, 'outbounds[0].volumeUnit', 'M3')
      shipmentItem.weightUnit = get(shipmentItem, 'outbounds[0].weightUnit', 'KG')
      shipmentItem.truckOutDate = get(shipment, 'referenceEvents.truckInForCargoPickup.expectedAt')
      shipmentItem.status = get(shipment, 'status')
      shipmentItem.summary = get(shipment, 'summary')
      shipmentItem.name = shipmentNameBeautifier(get(shipment, shipmentCardPrimaryId) || get(shipment, 'name', ''))
      shipmentItem.type = get(shipment, 'type')
      shipmentItem.containers = get(shipment, 'containers', [])
      shipmentItem.consolidations = get(shipment, 'consolidations', [])
      shipmentItem._id = get(shipment, '_id')
      shipmentItem.transportMethod = get(shipment, 'transportMethod')

      return shipmentItem
    })

    return result
  } catch (err) {
    return []
  }
}
