import React, { useState, useEffect } from 'react'
import { FusePlaceHolder, LoadMore } from 'core/components'
import BookingTableGroup from './BookingTableGroup'
import {
  Grid, withStyles, Icon, Tabs,
  Tab,
  Typography,
  Box
} from '@material-ui/core'
import classNames from 'classnames'
import BookingListSkeleton from 'core/components/Skeletons/BookingListSkeleton'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import FlightIcon from '@material-ui/icons/Flight';
import { indexOf } from 'lodash'

const tabs = ['ocean', 'air', 'CBT', 'rail', 'NA']

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component='div'
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={'w-full overflow-hidden'}
    {...other}>
    <Box>{children}</Box>
  </Typography>
)

const BookingTable = ({
  shipments,
  shipmentsTotal,
  showLoadMore,
  shipmentsLoading,
  shipmentsLoadingMore,
  onLoadMore,
  airShipments,
  airShipmentsTotal,
  onAirLoadMore,
  showAirLoadMore,
  airShipmentsLoading,
  airShipmentsLoadingMore,
  onChkClick,
  onOutboundsClick,
  classes,
  bookingSearch,
  setBookingSearch,
  setCurrentShipmentTab
}) => {
  const [tabValue, setTabValue] = useState(0)
  const [lastLoadingMore, setLastLoadingMore] = useState('')

  const a11yProps = index => {
    return {
      id: `simple-shipment-tab-${index}`,
      'aria-controls': `simple-shipment-tabpanel-${index}`
    }
  }

  const handleChange = (event, value) => {
    setTabValue(value)
    setCurrentShipmentTab(tabs[value])
  }

  useEffect(() => {
    if (!shipmentsLoading && !airShipmentsLoading) {
      if (tabValue === indexOf(tabs, lastLoadingMore)) {
        setLastLoadingMore('')
      } else {
        if (shipments.length > 0) handleChange(null, 0)
        else if (airShipments.length > 0) handleChange(null, 1)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments, airShipments])

  return (
    <div className={classes.bookingContainer}>
      <Grid container spacing={1}>

        <Grid item xs={12} sm={6} md={6}>
          <h3 className="uppercase bold">
            Bookings</h3>
        </Grid>

        <Grid item xs={6}>
          <Grid container
            alignItems="center"
            className={classNames(
              'bg-white px-4 py-8',
              classes.bordered)}>
            <Grid item xs={2}>
              <Grid container
                alignItems="center"
                justify="center">
                <img src='assets/images/icons/Search-Organizations.png'
                  alt='search' />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <input type='text'
                    placeholder='Search bookings...'
                    className='input w-full text-12'
                    value={bookingSearch}
                    onChange={setBookingSearch}
                  />
                </Grid>
                <Grid item xs={2}>
                  {bookingSearch && (
                    <Grid container
                      alignItems="center"
                      justify="center">
                      <Icon onClick={() => setBookingSearch({ target: { value: '' } })}
                        className="text-12 cursor-pointer">close</Icon>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Tabs value={tabValue}
          onChange={handleChange}
          className="custom-tabs w-full"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="Transport Method">
          {/* Ocean */}
          <Tab {...a11yProps(0)}
            label={<Grid container alignItems="center" spacing={1}>
              <Grid item className="leading-none"><DirectionsBoatIcon /></Grid>
              <Grid item className="leading-none">Ocean&nbsp;({shipmentsTotal})</Grid>
            </Grid>}
            className={classNames({
              'custom-tabs-item': true,
              'selected': tabValue === 0,
              'hidden': shipments.length === 0
            })} />

          {/* Air */}
          <Tab {...a11yProps(1)}
            label={<Grid container alignItems="center" spacing={1}>
              <Grid item className="leading-none"><FlightIcon /></Grid>
              <Grid item className="leading-none">Air&nbsp;({airShipmentsTotal})</Grid>
            </Grid>}
            className={classNames({
              'custom-tabs-item': true,
              'selected': tabValue === 1,
              'hidden': airShipments.length === 0
            })} />
        </Tabs>

        {/* OCEAN */}
        <TabPanel value={tabValue} index={0}>
          <Grid container className={classes.bookingScrollBlock}>
            {!shipmentsLoading && shipments.length > 0 && (
              <Grid item xs={12}>

                {shipments.map((shipment, index) => (
                  <BookingTableGroup key={index}
                    data={shipment}
                    onChkClick={onChkClick}
                    onOutboundsClick={onOutboundsClick} />
                ))}

                {showLoadMore &&
                  <LoadMore onClick={onLoadMore}
                    loading={() => {
                      shipmentsLoadingMore()
                      setLastLoadingMore('ocean')
                    }} />
                }
              </Grid>
            )}

            {!shipmentsLoading && shipments.length === 0 && (
              <FusePlaceHolder className={classNames('flex flex-col p-16', classes.nodata)}
                icon='view_day'
                title='No bookings found!'
                description='Sorry, no bookings found!' />
            )}

            {shipmentsLoading && <BookingListSkeleton />}
          </Grid>
        </TabPanel>

        {/* AIR */}
        <TabPanel value={tabValue} index={1}>
          <Grid container className={classes.bookingScrollBlock}>
            {!airShipmentsLoading && airShipments.length > 0 && (
              <Grid item xs={12}>

                {airShipments.map((shipment, index) => (
                  <BookingTableGroup key={index}
                    data={shipment}
                    onChkClick={onChkClick}
                    onOutboundsClick={onOutboundsClick} />
                ))}

                {showAirLoadMore &&
                  <LoadMore onClick={onAirLoadMore}
                    loading={() => {
                      airShipmentsLoadingMore()
                      setLastLoadingMore('air')
                    }} />
                }
              </Grid>
            )}

            {!airShipmentsLoading && airShipments.length === 0 && (
              <FusePlaceHolder className={classNames('flex flex-col p-16', classes.nodata)}
                icon='view_day'
                title='No bookings found!'
                description='Sorry, no bookings found!' />
            )}

            {airShipmentsLoading && <BookingListSkeleton />}
          </Grid>
        </TabPanel>
      </Grid>
    </div>
  )
}
const styles = {
  bordered: {
    border: '1px solid #e3e3e4',
    borderRadius: 4
  },
  bookingContainer: {},
  nodata: {
    height: 'calc(100vh - 228px)'
  },
  title: {
    fontWeight: 800,
    color: '#212121'
  },
  bookingScrollBlock: {
    maxHeight: 'calc(100vh - 228px)',
    overflowY: 'auto',
    backgroundColor: 'rgba(235, 235, 235, 0.5)',
    border: '1px solid rgba(159, 160, 164, 0.2)',
    borderRadius: 4,
    padding: 15,
  }
}

export default withStyles(styles)(BookingTable)
