import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { get } from 'lodash'
import { getCountryName, formatTimestamp, shipmentNameBeautifier } from 'core/helpers'
import { FuseSideBar, FuseSideBarHeader, FuseSideBarBody, FuseSideBarActions } from '../../core/components'
import Typography from '@material-ui/core/Typography'
import { FusePlaceHolder } from 'core/components'
import history from '../../history'

const VesselInfoSidebar = ({ open, vessel, shipments, handleClose }) => {

  const gotoShipmentDetails = (shipment) => {
    let stage = get(shipment, 'stage.current.value');
    stage = stage === 'scheduled' ? 'schedule' : stage;
    history.push(`/${stage}/${get(shipment, '_id')}`)
  }

  return (
    <FuseSideBar className='vessel-info-sidebar' open={open} onBackDropClick={handleClose}>
      <FuseSideBarHeader
        text={get(vessel, 'SHIPNAME', 'Loading...')}
        handleClose={handleClose}
      />
      <FuseSideBarBody>
        <Grid container className='pd-20 sidebar-header'>
          <Grid item xs={4}>
            <b>{getCountryName(get(vessel, 'LAST_PORT_COUNTRY', 'Last Port'))}</b>
            <p className='f-10'>{formatTimestamp(get(vessel, 'LAST_PORT_TIME'))}</p>
          </Grid>
          <Grid item xs={4} className='text-center'>
            <img src='assets/images/icons/Ship-route-map-popup@3x.svg' alt='route-map-popup' />
          </Grid>
          <Grid item xs={4} className='text-right'>
            <b>{getCountryName(get(vessel, 'NEXT_PORT_COUNTRY', 'Next Port'))}</b>
            <p className='f-10'>{formatTimestamp(get(vessel, 'ETA'))}</p>
          </Grid>
        </Grid>
        <Grid container>
          <img src={`https://photos.marinetraffic.com/ais/showphoto.aspx?shipid=${get(vessel, 'SHIP_ID')}`}
            alt='vessel' />
        </Grid>
        <div className='side-bar-subheader mt-0 relative'>
          <Grid container>
            <Grid item xs={6}><Typography className='subheader-left'>All shipments</Typography></Grid>
            <Grid item xs={6}><Typography className='subheader-right'>{`${(shipments ? shipments.length : 'loading ')} shipments`}</Typography></Grid>
          </Grid>
        </div>
        {
          shipments &&
          shipments.map((shipment, shipmentIndex) =>
            (
              <div key={shipmentIndex}
                className='vessel-shipments-sidebar-row'>
                <Grid container className='p-16'>
                  <Grid item xs={1}>
                    <img src='assets/images/icons/Shipment-box-mapview-gray@3x.svg' alt='box-mapview' />
                  </Grid>
                  <Grid item xs={5} className="bold">
                    {shipmentNameBeautifier(get(shipment, 'chat.roomName', '') || get(shipment, 'name', ''))}
                  </Grid>
                  <Grid item xs={6} className='text-right'>
                    <span className='light'>{get(shipment, 'vesselSchedule.FirstPOL.port.name')}</span>
                    <img className='ml-2 mr-2' src='assets/images/icons/Arrow-bet-country@3x.svg' alt='bet-country' />
                    <span className='light'>{get(shipment, 'vesselSchedule.LastPOD.port.name')}</span>
                  </Grid>
                </Grid>
                <Grid container className='pb-16'>
                  <Grid item xs={1}>
                  </Grid>
                  <Grid item xs={9} className='light'>
                    <span className='separator'>{get(shipment, 'type')}</span>•
                    {
                      get(shipment, 'type') === 'FCL' &&
                      <>
                        <span className='separator'>{get(shipment, 'containers', []).length} containers</span>•
                    </>
                    }
                    {
                      <span className='separator'>
                        {
                          get(shipment, 'specialLabels', []).length > 0 && (
                            <>
                              {get(shipment, 'specialLabels', []).join(', ')}
                            </>
                          )
                        }
                        &nbsp;
                        {
                          get(shipment, 'goodsType')
                        }
                      </span>
                    }
                  </Grid>
                  <Grid item xs={2}>
                    <span className='details-btn' onClick={() => gotoShipmentDetails(shipment)}>DETAILS</span>
                  </Grid>
                </Grid>
              </div>
            ))
        }
        {
          (!shipments || shipments.length === 0) &&
          <FusePlaceHolder
            className='flex flex-col height-unset'
            icon='calendar_today'
            title='No Shipments'
            description={'Looks like we are currently not able to track shipments for selected vessel. Please check back later.'}
          ></FusePlaceHolder>
        }

      </FuseSideBarBody>

      <FuseSideBarActions>
        <Button className='btn btn_full-space'
          onClick={handleClose}>CLOSE</Button>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

export default VesselInfoSidebar
