import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { FuseDialog, FuseDialogHeader, FuseDialogBody } from '.'
import { get } from 'lodash'
import { withStyles, Grid, Zoom } from '@material-ui/core'
import * as CoreActions from '../store/actions'
import Skeleton from 'react-loading-skeleton'
import moment from 'moment'

const VesselTrackDialog = props => {
  const { open, vessel, handleClose, classes } = props
  const [loading, setLoading] = useState(false)
  const [vesselInfo, setVesselInfo] = useState({})

  const splitTime = (numberOfHours) => {
    var Days = Math.floor(numberOfHours / 24);
    var Remainder = numberOfHours % 24;
    var Hours = Math.floor(Remainder);
    var Minutes = Math.floor(60 * (Remainder - Hours));
    return Days + " days " + Hours + " hours and " + Minutes + " minutes";
  }

  useEffect(() => {
    const { getVesselInfo } = props

    if (get(vessel, 'IMO')) {
      setLoading(true)
      getVesselInfo(get(vessel, 'IMO'),
        (response) => {
          setVesselInfo(get(response, 'vessel', {}))
          setLoading(false)
        },
        () => {
          setVesselInfo({})
          setLoading(false)
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vessel])

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text={`${get(vessel, 'name', 'NO NAME')} (${get(vessel, 'IMO', 'NO IMO')})`}
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className={classes.root}>

          <Grid xs={12}>
            <Grid container
              alignItems="center"
              spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container
                  alignItem="center"
                  justify="center"
                  className={classes.vesselImageContainer}>
                  <Zoom in={!loading}>
                    <img src={`https://photos.marinetraffic.com/ais/showphoto.aspx?shipid=${get(vesselInfo, 'SHIP_ID')}`}
                      alt="Vessel"
                      className={classes.vesselImage} />
                  </Zoom>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2} className="py-20">
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={1}>
                          <Grid item className={classes.infoLabel}>IMO:</Grid>
                          <Grid item className={classes.infoValue} xs>
                            {loading && <Skeleton width={60} />}
                            {!loading && (get(vesselInfo, 'IMO') || '--')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={1}>
                          <Grid item className={classes.infoLabel}>Ship Id:</Grid>
                          <Grid item className={classes.infoValue} xs>
                            {loading && <Skeleton width={60} />}
                            {!loading && (get(vesselInfo, 'SHIP_ID') || '--')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={1}>
                          <Grid item className={classes.infoLabel}>Ship Name:</Grid>
                          <Grid item className={classes.infoValue} xs>
                            {loading && <Skeleton width={60} />}
                            {!loading && (get(vesselInfo, 'SHIPNAME') || '--')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={1}>
                          <Grid item className={classes.infoLabel}>Ship Dimensions:</Grid>
                          <Grid item className={classes.infoValue} xs>
                            {loading && <Skeleton width={60} />}
                            {!loading && `${get(vesselInfo, 'LENGTH') || '--'} x ${get(vesselInfo, 'WIDTH') || '--'}`}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={1}>
                          <Grid item className={classes.infoLabel}>Year Build:</Grid>
                          <Grid item className={classes.infoValue} xs>
                            {loading && <Skeleton width={60} />}
                            {!loading && (get(vesselInfo, 'YEAR_BUILT') || '--')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={1}>
                          <Grid item className={classes.infoLabel}>Ship Type:</Grid>
                          <Grid item className={classes.infoValue} xs>
                            {loading && <Skeleton width={60} />}
                            {!loading && (get(vesselInfo, 'SHIPTYPE') || '--')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={1}>
                          <Grid item className={classes.infoLabel}>AIS Type:</Grid>
                          <Grid item className={classes.infoValue} xs>
                            {loading && <Skeleton width={60} />}
                            {!loading && (get(vesselInfo, 'AIS_TYPE_SUMMARY') || '--')}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container
                          alignItems="center"
                          spacing={1}>
                          <Grid item className={classes.infoLabel}>Call Sign:</Grid>
                          <Grid item className={classes.infoValue} xs>
                            {loading && <Skeleton width={60} />}
                            {!loading && (get(vesselInfo, 'CALLSIGN') || '--')}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12} className="overflow-hidden">
            <Grid container
              alignItems="center"
              justify="space-between"
              spacing={1}
              className={classes.infoTimelineContainer}>
              <Grid item xs>
                <Grid container
                  alignItems="center">
                  <Grid item xs={12}
                    className="text-right semi-bold">
                    {loading && <Skeleton width={100} />}
                    {!loading &&
                      `${get(vesselInfo, 'LAST_PORT_COUNTRY') || '--'} (${get(vesselInfo, 'LAST_PORT_UNLOCODE') || '--'})`
                    }
                  </Grid>
                  <Grid item xs={12}
                    className="text-right">
                    {loading && <Skeleton width={140} />}
                    {(!loading && get(vesselInfo, 'LAST_PORT_TIME')) &&
                      moment(get(vesselInfo, 'LAST_PORT_TIME')).utc().format('L LT z').toString()
                    }
                    {(!loading && !get(vesselInfo, 'LAST_PORT_TIME')) && '--'}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Grid container
                  alignItems="center"
                  justify="center">
                  {loading && <Skeleton height={30} width={120} />}
                  {!loading &&
                    <Zoom in={!loading}>
                      <img src='assets/images/icons/Ship-route-map-popup@3x.svg'
                        alt='route-map-popup' />
                    </Zoom>
                  }
                </Grid>
              </Grid>
              <Grid item xs>
                <Grid container
                  alignItems="center">
                  <Grid item xs={12} className="semi-bold">
                    {loading && <Skeleton width={100} />}
                    {!loading &&
                      `${get(vesselInfo, 'NEXT_PORT_COUNTRY') || '--'} (${get(vesselInfo, 'NEXT_PORT_UNLOCODE') || '--'})`
                    }
                  </Grid>
                  <Grid item xs={12}>
                    {loading && <Skeleton width={140} />}
                    {(!loading && get(vesselInfo, 'ETA_UPDATED')) &&
                      moment(get(vesselInfo, 'ETA_UPDATED')).utc().format('L LT z').toString()
                    }
                    {(!loading && !get(vesselInfo, 'ETA_UPDATED')) && '--'}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid xs={12}>
            <Grid container
              spacing={2}
              className="p-20">
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Last Port:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading && (get(vesselInfo, 'LAST_PORT') || '--')}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Reported ETA:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {(!loading && get(vesselInfo, 'ETA_UPDATED')) &&
                          moment(get(vesselInfo, 'ETA_UPDATED')).utc().format('L LT z').toString()
                        }
                        {(!loading && !get(vesselInfo, 'ETA_UPDATED')) && '--'}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Calculated ETA:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {(!loading && get(vesselInfo, 'ETA_CALC')) &&
                          moment(get(vesselInfo, 'ETA_CALC')).utc().format('L LT z').toString()
                        }
                        {(!loading && !get(vesselInfo, 'ETA_CALC')) && '--'}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Calculated ETA at:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading && (get(vesselInfo, 'NEXT_PORT_NAME') || '--')}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Predictive ETD:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading && (get(vesselInfo, 'ETD') || '--')}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Reported Destination:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading && (get(vesselInfo, 'DESTINATION') || '--')}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Course / Heading:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading &&
                          `${get(vesselInfo, 'COURSE') || '--'} / ${get(vesselInfo, 'HEADING') || '--'}`
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Distance Travelled:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading && (get(vesselInfo, 'DISTANCE_TRAVELLED') || '--')}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Distance to Go:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading && (get(vesselInfo, 'DISTANCE_TO_GO') || '--')}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Total Voyage Distance:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading &&
                          (
                            Number(+get(vesselInfo, 'DISTANCE_TRAVELLED', 0))
                            + Number(+get(vesselInfo, 'DISTANCE_TO_GO', 0))
                          )
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Time to Destination:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading && (
                          splitTime(
                            (
                              Number(+get(vesselInfo, 'DISTANCE_TO_GO', 0))
                              / (Number(+get(vesselInfo, 'AVG_SPEED')) * 1.15078)
                            ).toFixed(2)
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Draught (Reported / Max):</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading &&
                          `${get(vesselInfo, 'DRAUGHT') || '--'} / ${get(vesselInfo, 'MAX_DRAUGHT') || '--'}`
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Speed recorded (Average / Max):</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading &&
                          `${get(vesselInfo, 'AVG_SPEED') || '--'} KNOTS / ${get(vesselInfo, 'MAX_SPEED') || '--'} KNOTS`
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container
                      alignItems="center"
                      spacing={1}>
                      <Grid item className={classes.infoLabel}>Load Condition:</Grid>
                      <Grid item className={classes.infoValue} xs>
                        {loading && <Skeleton width={60} />}
                        {!loading && (get(vesselInfo, 'LOAD_CONDITION') || '--')}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

const styles = theme => ({
  root: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14
  },
  infoLabel: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14
  },
  infoValue: {
    fontFamily: 'IBMPlexSans-SemiBold',
    fontSize: 14
  },
  infoTimelineContainer: {
    backgroundColor: '#484848',
    padding: 20,
    color: '#FFFFFF'
  },
  vesselImageContainer: {
    backgroundColor: '#EEEEEE'
  },
  vesselImage: {
    height: 200,
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center'
  }
})

const mapStateToProps = () => ({})

const mapDispatchToProps = dispatch => ({
  getVesselInfo: (...args) => dispatch(CoreActions.getVesselInfo(...args))
})


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VesselTrackDialog))
