import React, { useState, useEffect } from 'react'
import { Grid, Radio, Typography, withStyles, FormControlLabel } from '@material-ui/core'
import {
  FuseCheckBox,
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  FuseTextField,
  FuseButtonToggle, LoadingButton
} from '../../core/components'
import { get, uniqBy, filter, pick, find, set } from 'lodash'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EventIcon from '@material-ui/icons/Event';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import MenuIcon from '@material-ui/icons/Menu';
import LayersIcon from '@material-ui/icons/Layers';
import { AutoComplete } from 'core/components/Inputs';
import { formatTimestamp, shipmentNameBeautifier } from '../../core/helpers'
import DateTime from 'react-datetime';

const availableBookingActions = ['I001', 'I002', 'I003', 'D007', 'D008', 'D013', 'D015', 'D021', 'D024', 'D027']

const taskNameInput = {
  fontFamily: 'IBMPlexSans-SemiBold',
  fontSize: '20px'
}

const baseTextSetting = {
  position: 'end',
  className: 'text-20 cursor-pointer',
  color: 'action'
}

const inputStyles = {
  fontFamily: 'IBMPlexSans',
  fontSize: '14px'
}

const priorityOptions = [{
  label: 'High',
  value: 'high'
}, {
  label: 'Medium',
  value: 'medium'
}, {
  label: 'Low',
  value: 'low'
}]

const CreateTaskDialog = ({ currentPlant, getEventDocList, eventDocs, open, handleClose, shipments, events, userInfo, users, controllerDictionary, getShipmentTasks, shipmentTasks, onSave, requesting }) => {
  const [state, setState] = useState()
  const [isValid, setIsValid] = useState(false)
  const shipmentOptions = shipments.map(s => ({
    value: get(s, '_id'),
    label: shipmentNameBeautifier(get(s, 'name', ''))
  }))
  const eventOptions = uniqBy(events, 'code').map(e => ({
    value: get(e, 'code'),
    label: get(e, 'name')
  }))
  const assigneeOptions = filter(users, i => get(i, '_id') !== get(userInfo, '_id')).map(e => ({
    value: get(e, '_id'),
    label: [get(e, 'firstname'), get(e, 'lastname')].filter(Boolean).join(' ') || get(e, 'email')
  }))
  const actionOptions = filter(controllerDictionary, i => availableBookingActions.includes(i.code)).map(a => ({
    value: get(a, 'code'),
    label: get(a, 'name')
  }))
  actionOptions.push({
    value: 'ad-hoc',
    label: 'Ad-Hoc'
  })
  const taskOptions = filter(shipmentTasks, i => get(i, 'task.action.category') !== 'ad-hoc').map(a => ({
    value: get(a, '_id'),
    label: get(a, 'task.name')
  }))

  const handleChange = (event) => {
    const { name, value } = event.target

    if (name === 'eventDays' && value <= 0) {
      return
    }

    setState({
      ...state,
      [name]: value
    })
  }

  const handleSelectChange = (name, value) => {
    const shipment = find(shipments, { _id: value })
    setState({
      ...state,
      [name]: value
    })
    if (name === 'shipmentId') {
      getShipmentTasks(value)
    }
    if (shipment) {
      const { transportMethod } = shipment
      getEventDocList({ transportMethod })
    }
  }

  const handleDependChange = (value) => {
    let dependency = get(state, 'dependency', [])
    if (dependency.includes(value)) {
      dependency = filter(dependency, i => i !== value)
    } else {
      dependency = [...dependency, value]
    }
    setState({
      ...state,
      dependency
    })
  }

  const handleAssigneeChange = (value) => {
    let assignedUsers = get(state, 'assignee.user', [])
    if (assignedUsers.includes(value)) {
      assignedUsers = filter(assignedUsers, i => i !== value)
    } else {
      assignedUsers = [...assignedUsers, value]
    }
    setState({
      ...state,
      assignee: {
        user: assignedUsers,
        role: [],
        team: [],
      }
    })
  }

  const handleSaveTask = () => {
    const shipment = find(shipments, { _id: get(state, 'shipmentId') })
    const payload = {
      shipmentId: get(shipment, '_id'),
      task: {
        ...pick(state, ['name', 'description', 'priority']),
        assignee: {
          ...get(state, 'assignee', {}),
          team: [get(currentPlant, 'type', 'shipper')]
        },
        dependency: get(state, 'dependency', []),
        identifier: {
          shipmentMode: get(shipment, 'transportMethod'),
          shipmentType: [get(shipment, 'type')],
          specialLabel: get(shipment, 'specialLabels')
        },
        due: {
          type: get(state, 'dueType'),
          event: get(state, 'event'),
          time: get(state, 'eventDays')
        },
        taskDueDate: get(state, 'taskDueDate'),
        action: {
          code: get(state, 'actionCode')
        }
      }
    }

    if (get(state, 'actionCode') === 'D013') {
      set(payload, 'task.action.eventCode', get(state, 'actionEvent'))
    }
    if (get(state, 'actionCode') === 'D015') {
      set(payload, 'task.action.docCode', get(state, 'actionDoc'))
    }
    if (get(state, 'actionCode') === 'ad-hoc') {
      set(payload, 'task.action.category', get(state, 'actionCode'))
      set(payload, 'task.action.code', get(state, 'actionAdHoc'))
    }

    onSave(payload)
  }

  useEffect(() => {
    setIsValid(true)
    if (!get(state, 'priority')) {
      setState({ ...state, priority: 'medium' })
    }
    if (!get(state, 'name') ||
      !get(state, 'description') ||
      !get(state, 'shipmentId') ||
      !get(state, 'dueType') ||
      !get(state, 'event') ||
      !get(state, 'eventDays') ||
      !get(state, 'actionCode')) {
      setIsValid(false)
    }
    if (get(state, 'actionCode') === 'D013') {
      !get(state, 'actionEvent') && setIsValid(false)
    }
    if (get(state, 'actionCode') === 'D015') {
      !get(state, 'actionDoc') && setIsValid(false)
    }
    if (get(state, 'actionCode') === 'ad-hoc') {
      !get(state, 'actionAdHoc') && setIsValid(false)
    }
  }, [state])

  useEffect(() => {
    if (open === false) {
      setState()
    }
  }, [open])

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Create new task'
        handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="p-20">
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Task Name */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <FuseTextField name='name'
                      placeholder='Add the task name'
                      icon={{ ...baseTextSetting, name: 'none' }}
                      value={get(state, 'name', '')}
                      inputStyles={taskNameInput}
                      onChange={handleChange}
                      required
                      fullWidth />
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container justify="flex-end">
                      <FuseButtonToggle name='priority'
                        data={priorityOptions}
                        value={get(state, 'priority', 'medium')}
                        buttonClass='text-12'
                        onChange={(value) => handleSelectChange('priority', value)} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <MenuIcon className="text-grey text-36" />
                  </Grid>
                  <Grid item xs={11}>
                    <FuseTextField name='description'
                      label='Description'
                      icon={{ ...baseTextSetting, name: 'ship' }}
                      value={get(state, 'description', '')}
                      inputStyles={inputStyles}
                      onChange={handleChange}
                      required
                      fullWidth
                      multiline />
                  </Grid>
                </Grid>
              </Grid>

              {/* Shipment */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <Grid container alignItems="center">
                      <DirectionsBoatIcon className="text-grey text-36" />
                    </Grid>
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <label className="text-12 fg-gray require-field">Shipment</label>
                        <AutoComplete className="text-14"
                          options={shipmentOptions}
                          value={get(state, 'shipmentId', '')}
                          placeholder="Search shipment"
                          onChange={(value) => handleSelectChange('shipmentId', value)}
                          dynamic />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Due Date */}
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={1}>
                    <EventIcon className="text-grey text-36" />
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <FormControlLabel name="dueType"
                              value="previous"
                              checked={get(state, 'dueType', '') === 'previous'}
                              control={<Radio />}
                              label="Previous"
                              onChange={handleChange} />
                          </Grid>
                          <Grid item>
                            <FormControlLabel name="dueType"
                              value="next"
                              checked={get(state, 'dueType', '') === 'next'}
                              control={<Radio />}
                              label="Next"
                              onChange={handleChange} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <label className="text-12 fg-gray require-field">Event name</label>
                            <AutoComplete options={eventOptions}
                              className="text-14 regular"
                              value={get(state, 'event', '')}
                              placeholder="Select event"
                              onChange={(value) => handleSelectChange('event', value)}
                              showAllOptions />
                          </Grid>
                          <Grid item xs={4}>
                            <FuseTextField type="number" name='eventDays'
                              label="Days"
                              placeholder='Enter Days'
                              icon={{ ...baseTextSetting, name: 'none' }}
                              value={get(state, 'eventDays', '')}
                              inputStyles={inputStyles}
                              onChange={handleChange}
                              required
                              fullWidth />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography className="uppercase fg-gray semi-bold">or</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <DateTime className="theme-date-time-picker-field"
                      input
                      inputProps={{
                        placeholder: 'Select Due Date'
                      }}
                      dateFormat="DD MMM YYYY"
                      timeFormat=""
                      value={get(state, 'taskDueDate') && formatTimestamp(get(state, 'taskDueDate', '', 'date'))}
                      onChange={(date) => {
                        if (new Date(date).toString() === 'Invalid Date') {
                          date = new Date()
                        }
                        handleSelectChange('taskDueDate', date)
                      }} />
                  </Grid>
                </Grid>
              </Grid>

              {/* Dependents */}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={1}>
                    <LayersIcon className="text-grey text-36" />
                  </Grid>
                  <Grid item xs={11}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <FormControlLabel name="dependType"
                              value="dependent"
                              checked={get(state, 'dependType', '') === 'dependent'}
                              control={<Radio />}
                              label="Dependent on something"
                              onChange={handleChange} />
                          </Grid>
                          <Grid item>
                            <FormControlLabel name="dependType"
                              value="open"
                              checked={get(state, 'dependType', '') === 'open'}
                              control={<Radio />}
                              label="Open for anytime"
                              onChange={handleChange} />
                          </Grid>
                        </Grid>
                      </Grid>
                      {get(state, 'dependType', '') === 'dependent' &&
                        <Grid item xs={12}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <AutoComplete options={filter(taskOptions, i => !get(state, 'dependency', []).includes(i.value))}
                                className="text-14 regular"
                                value=""
                                placeholder="Depend on task"
                                onChange={(value) => handleDependChange(value)}
                                showAllOptions
                                dynamic />
                              {get(state, 'dependency', []).length > 0 &&
                                <label className="fg-blue text-10">
                                  Depend on&nbsp;{get(state, 'dependency', []).length}&nbsp;tasks</label>
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* Assignee */}
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={1}>
                    <Grid container alignItems="center">
                      <AccountCircleIcon className="text-grey text-36" />
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <label className="text-12 fg-gray">Assignee</label>
                    <AutoComplete options={filter(assigneeOptions, i => !get(state, 'assignee.user', []).includes(i.value))}
                      className="text-14 regular"
                      value=""
                      placeholder="Search by name"
                      onChange={(value) => handleAssigneeChange(value)}
                      dynamic />
                    {get(state, 'assignee.user', []).length > 0 &&
                      <label className="text-12 fg-blue">
                        Assigned to&nbsp;{get(state, 'assignee.user', []).length}&nbsp;users
                    </label>
                    }
                  </Grid>
                  <Grid item>
                    <Typography className="uppercase fg-gray semi-bold">or</Typography>
                  </Grid>
                  <Grid item xs>
                    <Grid container alignItems="center">
                      <Grid item>
                        <FuseCheckBox checked={get(state, 'assignee.user', []).includes(get(userInfo, '_id', ''))}
                          value={get(userInfo, '_id', '')}
                          onChange={(e) => handleAssigneeChange(e.target.value)} />
                      </Grid>
                      <Grid item>
                        <Typography className="text-14 regular">Assign to me</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} className="pt-40">
            <Grid container spacing={2} justify="space-between" alignItems="center">
              {/* Action */}
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <label className="text-12 fg-gray require-field">Action</label>
                    <AutoComplete options={actionOptions}
                      className="text-14 regular"
                      value={get(state, 'actionCode', '')}
                      placeholder="Select action"
                      onChange={(value) => handleSelectChange('actionCode', value)}
                      showAllOptions />
                  </Grid>
                  {get(state, 'actionCode') === 'D013' &&
                    // Events
                    <Grid item xs={12} sm={6} md={6}>
                      <label className="text-12 fg-gray require-field">Action Event</label>
                      <AutoComplete options={get(eventDocs, 'events', []).map(i => ({ value: get(i, 'code'), label: get(i, 'name') }))}
                        className="text-14 regular"
                        value={get(state, 'actionEvent', '')}
                        placeholder="Select event"
                        onChange={(value) => handleSelectChange('actionEvent', value)}
                        showAllOptions />
                    </Grid>
                  }
                  {get(state, 'actionCode') === 'D015' &&
                    // Documents
                    <Grid item xs={12} sm={6} md={6}>
                      <label className="text-12 fg-gray require-field">Action Document</label>
                      <AutoComplete className="text-14 regular"
                        options={get(eventDocs, 'documents', []).map(i => ({ value: get(i, 'targetEvent.code'), label: get(i, 'targetEvent.name') }))}
                        value={get(state, 'actionDoc', '')}
                        placeholder="Select document"
                        onChange={(value) => handleSelectChange('actionDoc', value)}
                        showAllOptions />
                    </Grid>
                  }
                  {get(state, 'actionCode') === 'ad-hoc' &&
                    // Documents
                    <Grid item xs={12} sm={6} md={6}>
                      <FuseTextField type="text" name='actionAdHoc'
                        label="Action Code"
                        icon={{ ...baseTextSetting, name: 'none' }}
                        value={get(state, 'actionAdHoc', '')}
                        inputStyles={inputStyles}
                        onChange={handleChange}
                        required
                        fullWidth />
                    </Grid>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <LoadingButton className="btn"
                  disabled={!isValid}
                  loading={requesting}
                  onClick={handleSaveTask}
                  fullWidth>save</LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody >
    </FuseDialog >
  )
}

const styles = {}

export default withStyles(styles)(CreateTaskDialog)
