import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import * as API from 'core/api'
import { get } from "lodash";

export const getFilterShipments = (filter, isSync = false, success = null, fail = null) => async dispatch => {
  const payload = {
    ...get(filter, 'value', {})
  }
  if (get(filter, 'value.groupage.type') === 'none') {
    delete payload.groupage
  }

  if (!isSync) {
    dispatch({
      type: ActionTypes.LOAD_FILTER_SHIPMENTS,
      filterId: get(filter, '_id'),
      payload: 'loading'
    })
  }

  try {
    const { ok, data } = await API.getAllShipmentsWithFilter(undefined, payload)

    if (!ok) {
      throw new Error(`${data.message} for Filter ${get(filter, 'settingsName')}`)
    }

    dispatch({
      type: ActionTypes.LOAD_FILTER_SHIPMENTS,
      filterId: get(filter, '_id'),
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}
