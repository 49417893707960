import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  SET_USER_SETTINGS_ON_GOING_TASK

  LOADING_USER_SETTINGS_EVENT_LIST
  LOAD_USER_SETTINGS_EVENT_LIST

  LOADING_USER_SETTINGS_EVENT_NOTIFICATIONS
  LOAD_USER_SETTINGS_EVENT_NOTIFICATIONS

  LOADING_PLANT_SETTINGS_EMAIL_TEMPLATES
  LOAD_PLANT_SETTINGS_EMAIL_TEMPLATES

  LOADING_USER_SETTINGS_PLACES
  LOAD_USER_SETTINGS_PLACES

  LOAD_SET_UPDATE_USER_SETTINGS_BY_KEY
  `
)
