import React from 'react'
import classNames from 'classnames'
import { Grid, Typography } from '@material-ui/core'
import { getStatusHistory, formatTimestamp, getAddressFromLocation } from '../../../core/helpers'
import history from '../../../history'
import { get, uniq } from 'lodash'
import { ContainerInfoLabel, InfoToolTip } from '../../../core/components'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import FlightIcon from '@material-ui/icons/Flight';
import TrainIcon from '@material-ui/icons/Train';
import InfoIcon from '@material-ui/icons/Info';

const BookingGroupHeader = ({ data }) => {
  const getTagClass = (statusType, shipment) => {
    if (shipment) {

      switch (statusType) {
        case 'exception-type': {
          return 'orange-tag-exception'
        }
        case 'current-status': {
          const currentStatus = shipment.status.current.value.toLowerCase()
          if (currentStatus.includes('confirmed')) return 'green-tag'
          else if (currentStatus.includes('rejected')) return 'red-tag'
          else if (currentStatus.includes('amended')) return 'orange-tag'
          return ''
        }
        case 'time-delay': {
          return '' // @TODO: if 1-3 days delayed - yellow, if 4 to 7 orange, if 8 + red. ontime is green
        }
        default:
          return ''
      }
    }
  }

  const getTagValue = (statusType, shipment) => {
    if (shipment) {
      //statusType === 'exception-type' ? shipment.exceptionMode.type : shipment.status.current.value
      switch (statusType) {
        case 'exception-type':
          return shipment.exceptionMode.details.map(x => x.type) //_.get(shipment, 'exceptionMode.type')
        case 'current-status':
          return shipment.status.current.value
        case 'time-delay':
          return shipment.status.current.value // @TODO: Need to change this to actual - planned
        default:
          return ''
      }
    }
  }

  return (
    <Grid container
      spacing={1}
      className='text-14'>

      <Grid item xs={12}>
        <Grid container
          alignItems="center"
          justify="space-between"
          spacing={1}>
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item className='booking-proposed__header bold'
                onClick={() => history.push(`/schedule/${data._id}`)}>
                {get(data, 'name')}
              </Grid>
              <Grid item>
                <Grid container alignItems="center">
                  {get(data, 'transportMethod') === 'CBT' &&
                    <InfoToolTip title="CBT">
                      <LocalShippingIcon className="fg-gray text-16" />
                    </InfoToolTip>
                  }
                  {get(data, 'transportMethod') === 'ocean' &&
                    <InfoToolTip title="OCEAN">
                      <DirectionsBoatIcon className="fg-gray text-16" />
                    </InfoToolTip>
                  }
                  {get(data, 'transportMethod') === 'air' &&
                    <InfoToolTip title="AIR">
                      <FlightIcon className="fg-gray text-16" />
                    </InfoToolTip>
                  }
                  {get(data, 'transportMethod') === 'rail' &&
                    <InfoToolTip title="RAIL">
                      <TrainIcon className="fg-gray text-16" />
                    </InfoToolTip>
                  }
                  {get(data, 'transportMethod') === 'NA' &&
                    <InfoToolTip title="OTHER">
                      <InfoIcon className="fg-gray text-16" />
                    </InfoToolTip>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <InfoToolTip title={<div dangerouslySetInnerHTML={{
              __html: getStatusHistory(get(data, 'status', {})).replace(/\n/g, '<br />')
            }}></div>}>
              <span className={classNames('shipment-list-tag', getTagClass('current-status', data))}>
                {getTagValue('current-status', data)}
              </span>
            </InfoToolTip>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className='text-12 text-grey-dark medium'>
        <Grid container
          justify="space-between"
          alignItems="center"
          spacing={1}>
          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <Typography className="medium text-12 fg-gray">
                  {`Ship-to-party: ${uniq(get(data, 'consolidations', []).map(i => get(i, 'shipToParty', ''))).join(', ')}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <img src='assets/images/icons/20ft-standard.png'
                      alt='fcl'
                      className='h-14' />
                  </Grid>
                  <Grid item>{get(data, 'type')}</Grid>
                  {get(data, 'containers', []).length > 0 &&
                    <Grid item>&#183;</Grid>
                  }
                  {get(data, 'containers', []).length > 0 &&
                    <Grid item>
                      <ContainerInfoLabel containers={get(data, 'containers', [])}
                        className="text-grey-dark cursor-pointer" />
                    </Grid>
                  }
                </Grid>
              </Grid>

              <Grid item>
                {`Total: ${get(data, 'totalWeight', 0).toFixed(3)} ${get(data, 'weightUnit')}, ${get(data, 'totalVolume', 0).toFixed(3)} ${get(data, 'volumeUnit')}`}
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <InfoToolTip title={<Grid container>
              <Grid item xs={12}>
                {`Date: ${formatTimestamp(get(data, 'summary.latestActualEvent.occurenceAt'))}`}
              </Grid>
              <Grid item xs={12}>
                {`Event Code: ${get(data, 'summary.latestActualEvent.code')}`}
              </Grid>
              <Grid item xs={12}>
                {`Location: ${getAddressFromLocation(get(data, 'summary.latestActualEvent.location', {})) || 'Not Available'}`}
              </Grid>
            </Grid>}>
              <span className='cursor-pointer'>
                {get(data, 'summary.latestActualEvent.name') || get(data, 'summary.latestActualEvent.code')}
              </span>
            </InfoToolTip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BookingGroupHeader
