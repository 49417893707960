import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, CircularProgress } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { FuseTextField } from 'core/components'

const labelStyles = {
  fontSize: '1.1em',
  marginBottom: '0.4em'
}

const inputStyles = {
  fontSize: '1.2em'
}

const RefreshTokenForm = ({ refreshToken, loading, password, setPassword }) => {
  const onSubmit = e => {
    e.preventDefault()
    refreshToken(password)
  }

  const baseTextSetting = {
    position: 'end',
    className: 'text-20',
    color: 'action'
  }

  return (
    <div className='flex flex-col justify-start w-full'>
      <form onSubmit={onSubmit} noValidate>
        <FuseTextField
          className='mb-16'
          type='password'
          name='password'
          label='Password'
          labelStyles={labelStyles}
          inputStyles={inputStyles}
          icon={{ ...baseTextSetting, name: 'vpn_key' }}
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
          fullWidth
        />
        <Grid container alignItems='center' alignContent='flex-start'>
          <Grid item className='mt-8'>
            <RouterLink to='/login'>Back to login</RouterLink>
          </Grid>
          <Grid item style={{ marginLeft: 'auto' }}>
            <Button type='submit'
              variant='contained'
              style={{ borderRadius: '50vh' }}
              color='primary'
              className='min-w-160 ml-auto btn mt-8'
              aria-label='VERIFY PASSWORD'
              disabled={loading || !password}>
              {!loading ? 'Verify' : 'Verifying...'}{' '}
              {loading && <CircularProgress color='primary' size={20} style={{ marginLeft: 20 }} />}
              Verify
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  )
}

RefreshTokenForm.propTypes = {
  refreshToken: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired
}

export default RefreshTokenForm
