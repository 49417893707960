import React from 'react'
import Icon from '@material-ui/core/Icon'
import { ConfirmDialog } from 'core/components'
import { withStyles } from '@material-ui/core'

const DeleteUser = ({ deleteUser, setDeleteUser, classes, handleDelete }) => (
  <ConfirmDialog
    open={deleteUser}
    close={() => setDeleteUser(null)}
    onButtonClick={() => handleDelete(deleteUser._id)}
    onCancelButtonClick={() => setDeleteUser(null)}
    icon={
      <Icon color='disabled' style={{ fontSize: 75 }}>
        delete
      </Icon>
    }
    headerText={'Remove user from this plant'}
    text={['Are you sure you want to remove ', <b>{deleteUser.email}</b>, ' from this plant.']}
    confirmButtonText={'Yes, Remove'}
    cancelButtonText={'Cancel'}
  />
)

const styles = theme => ({
  delete: {
    padding: 30
  },
  deleteRow: {
    textAlign: 'center',
    margin: 5
  },
  button: {
    color: '#fff',
    backgroundColor: '#cc1f1a'
  },
  icon: { fontSize: 75 }
})
export default withStyles(styles)(DeleteUser)
