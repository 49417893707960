import React from 'react'
import { Grid } from '@material-ui/core'

const WelcomeFooter = () => (
  <Grid item xs={12} className='custom-footer'>
    <Grid container alignItems='center' className='item-container'>
      <Grid item xs={6}>
        <Grid container alignItems='center'>
          <Grid item className='powered-by'>
            Powered by TRAMÉS
          </Grid>
          <img src='assets/images/logos/trames_white.png' className='ml-24' alt='poweredBy' />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container alignItems='center' justify='flex-end'>
          <img src='assets/images/logos/life_is_schneider.png' alt='lineIdSchneider' />
        </Grid>
      </Grid>
    </Grid>
    <Grid container className='bottom-border'></Grid>
  </Grid>
)

export default WelcomeFooter