import React, { useEffect, useState } from 'react'
import { get, set, find, filter, pick } from 'lodash'
import {
  Grid,
  Button,
  TextField
} from '@material-ui/core'
import { useMergeState, getAllLocodes, getLocodeDetails } from '../../core/helpers'
import { LoadingButton } from '../../core/components'
import { general } from '../../core/localization'
import BookingWizardSummary from '../../manage-booking/components/ProposeBookingSummary'
import AutoComplete from '../../core/components/Inputs/AutoComplete'

const BookingLaneSpotForm = (props) => {
  const [state, setState] = useMergeState({})
  const [validated, setValidated] = useState(true)
  const serviceProviders = ['obl', 'obc', 'ofp']
  const ports = ['origin', 'destination']
  const {
    go,
    data: {
      plants,
      shipment,
      bookingForm,
      routeForm
    },
    existingFormData,
    onChange,
    handleResolveSpot,
    requesting
  } = props

  useEffect(() => {
    if (existingFormData) {
      setState(existingFormData)
      setValidated(checkValidation(existingFormData))
    } else {
      if (shipment) {
        onChange('laneSpotForm', pick(shipment, [
          'fpType',
          'obl',
          'obc',
          'ofp',
          'originPort',
          'destinationPort',
          'incoterm'
        ]))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, existingFormData])

  const checkValidation = (updatedState) => {
    if (!get(updatedState, 'quoteId')) {
      return false
    }
    if (!get(updatedState, 'obl._id')) {
      return false
    }
    if (!get(updatedState, 'obc._id')) {
      return false
    }
    if (!get(updatedState, 'ofp._id')) {
      return false
    }
    if (!get(updatedState, 'fpType')) {
      return false
    }
    if (!get(updatedState, 'originPort.locode')) {
      return false
    }
    if (!get(updatedState, 'destinationPort.locode')) {
      return false
    }
    if (!get(updatedState, 'incoterm')) {
      return false
    }
    return true
  }


  const handleInputChange = (event) => {
    const { name } = event.target
    var { value } = event.target
    var updatedState = state

    if (name === 'quoteId') {
      value = value.replace(/ /g, '')
    }
    set(updatedState, name, value)
    onChange('laneSpotForm', updatedState)
  }

  const handleChangePort = (name, value) => {
    var updatedState = state
    var portDetails = getLocodeDetails(value)

    if (portDetails) {
      set(updatedState, name, {
        ...portDetails,
        name: get(portDetails, 'portName', value)
      })
    } else {
      set(updatedState, name, {
        locode: value,
        portName: value,
        name: value,
        city: value,
        country: value,
      })
    }
    onChange('laneSpotForm', updatedState)
  }

  const handleChangePlant = (_id, section = null) => {
    var updatedState = state
    var selectedPlant = find(plants, { _id })

    set(updatedState, section, selectedPlant)
    onChange('laneSpotForm', updatedState)
  }

  return (
    <Grid container
      className="booking-wizard">
      <Grid item sm={12} md={8}
        className="min-h-full">
        <Grid container
          className="h-full booking-forms">

          {/* START Quote Id */}
          <Grid item xs={12} className="pb-28">
            <Grid container className={'field-control'}>
              <Grid item xs={12}>
                <Grid container
                  alignItems="center"
                  justify="space-between"
                  className="pb-6">
                  <label className={'field-label require-field'}>
                    Quote Id</label>
                  <label className={'field-instruction'}>
                    Add Quote Ids for this shipment</label>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container
                  justify="space-between"
                  spacing={2}
                  className="pt-12">
                  <Grid item xs={12} md={7}>
                    <TextField className='theme-text-field'
                      name="quoteId"
                      label="Quote Ids"
                      placeholder="Enter comma-separated Quote Ids"
                      value={get(state, 'quoteId')}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleInputChange}
                    />
                    <label className="fg-gray text-10">
                      {get(state, 'quoteId', '')
                        .split(',')
                        .filter(Boolean)
                        .length + ' Quote Id(s) have been added'}
                    </label>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* END Quote Id */}

          {/* START Service providers */}
          <Grid item xs={12} className="pb-28">
            <Grid container className={'field-control'}>
              <Grid item xs={12}>
                <Grid container
                  alignItems="center"
                  justify="space-between"
                  className="pb-6">
                  <label className={'field-label'}>
                    Service providers</label>
                  <label className={'field-instruction'}>
                    Select service provider</label>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {
                  serviceProviders.map((serviceProvider, serviceProviderIndex) => {
                    return (
                      <Grid container key={serviceProviderIndex}
                        justify="space-between"
                        spacing={2}
                        className="pt-12">
                        <Grid item xs={12} md={7}>
                          <label className="text-11 regular fg-gray require-field">
                            {general.partners[serviceProvider]}
                          </label>
                          <AutoComplete value={get(state, serviceProvider + '._id', '')}
                            options={filter(plants, { type: serviceProvider }).map(plant => {
                              return {
                                value: plant._id,
                                label: plant.name
                              }
                            })}
                            placeholder="Select service provider..."
                            onChange={(value) => handleChangePlant(value, serviceProvider)}
                            noAutoFirstSelect
                          />
                        </Grid>
                        {serviceProvider === 'ofp' &&
                          <Grid item xs={12} md={5}>
                            <label className="text-11 regular fg-gray require-field">
                              {general.partners[serviceProvider] + ' Type'}
                            </label>
                            <AutoComplete value={get(state, 'fpType', '')}
                              options={['D2C', 'NVOCC', 'D2CBA'].map(item => {
                                return {
                                  value: item,
                                  label: item
                                }
                              })}
                              placeholder="Select service provider type..."
                              onChange={(value) => {
                                handleInputChange({
                                  target: {
                                    name: 'fpType',
                                    value
                                  }
                                })
                              }}
                              noAutoFirstSelect
                            />
                          </Grid>
                        }
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
          {/* END Service providers */}

          {/* START Incoterm */}
          <Grid item xs={12} className="pb-28">
            <Grid container className={'field-control'}>
              <Grid item xs={12}>
                <Grid container
                  alignItems="center"
                  justify="space-between">
                  <label className={'field-label require-field'}>
                    Incoterm</label>
                  <label className={'field-instruction'}>
                    Add incoterm for this shipment</label>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container
                  justify="space-between"
                  spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField className='theme-text-field'
                      name="incoterm"
                      placeholder="Enter Incoterm"
                      value={get(state, 'incoterm')}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleInputChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* END Incoterm */}

          {/* START Port information */}
          <Grid item xs={12} className="pb-28">
            <Grid container className={'field-control'}>
              <Grid item xs={12}>
                <Grid container
                  alignItems="center"
                  justify="space-between"
                  className="pb-6">
                  <label className={'field-label'}>
                    Port information</label>
                  <label className={'field-instruction'}>
                    Add sea port information</label>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container
                  justify="space-between"
                  spacing={2}
                  className="pt-12">
                  {ports.map((port, index) => {
                    return (
                      <Grid key={index} item xs={12} md={6}
                        className="relative">
                        <label className="text-11 regular fg-gray require-field">
                          {general.port[port]}
                        </label>
                        <AutoComplete value={get(state, port + 'Port.locode', '')}
                          className="mb-16"
                          options={getAllLocodes().map(item => {
                            const value = get(item, 'locode', '')
                            return {
                              value,
                              label: value
                            }
                          })}
                          placeholder={'Select ' + port + ' port...'}
                          onChange={(value) => handleChangePort(port + 'Port', value)}
                          canCreate
                        />
                        <label className="text-12 medium absolute pin-b fg-gray">
                          {`Port Name: ${get(state, port + 'Port.name', '')}`}
                        </label>
                      </Grid>
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* END Port information */}

          {/* START Actions */}
          <Grid item xs={12}>
            <Grid container
              justify='space-between'
              alignItems="flex-end">
              <Grid item sm={12} md={6}>
                <LoadingButton className='btn w-full'
                  loading={requesting}
                  disabled={!validated}
                  onClick={handleResolveSpot}>
                  {general.createBooking}
                </LoadingButton>
              </Grid>

              <Grid item sm={12} md={6}>
                <Grid container justify="flex-end">
                  <Button className={'btn btn_transparent'}
                    disabled={requesting}
                    onClick={() => go(-1)}>Back</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* END Actions */}

        </Grid>
      </Grid>
      <Grid item sm={12} md={4}>
        <BookingWizardSummary shipment={{
          ...shipment,
          ...bookingForm,
          ...routeForm,
          ...state
        }} />
      </Grid>
    </Grid>
  )
}

export default BookingLaneSpotForm
