import React from 'react'
import Select from 'react-select'
import { get, find, filter } from 'lodash'
import deepEqual from 'deep-equal'
import { withStyles } from '@material-ui/core'
import classNames from 'classnames'

class AutoComplete extends React.Component {

  state = {
    selected: null,
    hasFocus: false,
    manualCreate: false,
    customStyles: {
      control: () => ({
        display: 'flex',
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid #D6E0E6',
        minHeight: '31px',
        maxHeight: '31px',
        marginTop: '0px'
      }),
      valueContainer: base => ({
        ...base,
        padding: '0px',
        paddingRight: '20px'
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isDisabled ? '#FFFFFF' : isSelected ? 'rgba(0, 0, 0, 0.14)' : isFocused ? 'rgba(0, 0, 0, 0.05)' : '#FFFFFF',
        color: isDisabled ? '#CCCCCC' : '#333333',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? '#FFFFFF' : 'rgba(0, 0, 0, 0.14)'),
        }
      }),
      indicatorSeparator: () => ({
        display: 'none'
      }),
      dropdownIndicator: () => ({
        height: '20px',
        width: '20px',
        content: 'url(/assets/images/icons/shipments-list/search.svg)'
      }),
      placeholder: styles => ({
        ...styles,
        color: '#9FA0A4'
      }),
      multiValue: () => ({
        display: this.props.hideChip ? 'none' : 'block'
      })
    }
  }

  handleChange = selection => {
    const { isMulti, onChange } = this.props
    if (!selection) {
      return
    }
    if (isMulti) {
      onChange(
        selection.map(item => {
          return item.value
        })
      )
    } else {
      onChange(selection.value)
    }
  }

  onFocus = () => {
    this.setState({ hasFocus: true })
  }

  onBlur = () => {
    this.setState({ hasFocus: false })
  }

  setSelected = () => {
    const { options, value, isMulti, onChange, defaultInitialSelect, noAutoSelect } = this.props
    const { manualCreate } = this.state
    var selected

    if (isMulti) {
      selected = filter(options, item => value.includes(item.value))
    } else {
      if (manualCreate) {
        selected = find(options, { value })
      } else {
        if (find(options, { value })) {
          selected = find(options, { value })
        } else if (find(options, { value: defaultInitialSelect })) {
          selected = find(options, { value: defaultInitialSelect })
          onChange(selected.value)
        } else if (options.length === 1) {
          if (!noAutoSelect) {
            onChange(options[0].value)
          }
        } else {
          selected = null
        }
      }
    }

    this.setState({ selected })
  }

  setOptions = () => {
    const { options, value, showAllOptions } = this.props
    const selectedOption = find(options, { value })
    var newOptions
    const optionLimit = showAllOptions ? options.length : 10

    if (selectedOption) {
      newOptions = [
        selectedOption,
        ...filter(options.slice(0, optionLimit), item => item.value !== value)
      ]
    } else {
      newOptions = options.slice(0, optionLimit)
    }
    this.setState({
      options: newOptions.filter(Boolean),
    }, this.setSelected)
  }

  componentDidMount() {
    const { customStyles, isMulti } = this.props
    if (customStyles) {
      this.setState({
        customStyles: {
          ...this.state.customStyles,
          ...customStyles
        }
      })
    }
    isMulti ? this.setSelected() : this.setOptions()
  }

  componentDidUpdate(oldProps) {
    const oldOptions = oldProps.options
    const oldValue = oldProps.value
    const { options, dynamic, value, isMulti } = this.props

    if (dynamic && !deepEqual(oldOptions, options)) {
      this.setState({
        manualCreate: false
      })
      isMulti ? this.setSelected() : this.setOptions()
    }
    if (value !== oldValue) {
      isMulti ? this.setSelected() : this.setOptions()
    }
  }

  render() {
    const {
      selected,
      customStyles,
      hasFocus,
    } = this.state
    const {
      isMulti,
      placeholderStr,
      canCreate,
      classes,
      showPlaceHolderOnFocus = false,
      options,
    } = this.props

    return (
      <>
        <Select {...this.props}
          options={isMulti ? options : get(this.state, 'options', [])}
          styles={customStyles}
          value={selected}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onChange={this.handleChange}
          onInputChange={search => {
            if (!isMulti) {
              const filteredOptions = filter(options, item =>
                item.label.toLowerCase().indexOf(search.toLowerCase()) > -1
              )
              if (search) {
                if (filteredOptions.length === 0) {
                  if (canCreate) {
                    const manualCreate = { value: search, label: search }
                    this.setState({
                      manualCreate,
                      options: [
                        manualCreate
                      ]
                    })
                  }
                } else {
                  this.setState({
                    manualCreate: false,
                    options: [
                      ...filteredOptions.slice(0, 10),
                    ].filter(Boolean)
                  })
                }
              } else {
                this.setOptions()
              }
            }
          }}
        />
        {(placeholderStr && (!hasFocus || showPlaceHolderOnFocus)) && (
          <span className={classNames(classes.placeholder, { active: selected && selected.length > 0 })}>
            {placeholderStr}
          </span>
        )}
      </>
    )
  }
}

const styles = () => ({
  placeholder: {
    color: '#9FA0A4',
    marginLeft: 12,
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    width: '100%',
    height: '100%',

    '&.active': {
      color: '#333333'
    }
  }
})

export default withStyles(styles)(AutoComplete)
