import React from 'react'
import { TextField, InputLabel, InputAdornment, Icon } from '@material-ui/core'

const TextFieldWithIcon = ({
  icon: { position, name, ...otherIconProps },
  label,
  classes,
  style,
  labelStyles,
  inputStyles,
  ...other }) => (
    <div style={style}>
      {label &&
        <InputLabel className={'regular text-12 fg-gray inline-block' + (other.required ? ' require-field' : '')}
          style={labelStyles}>
          {label}
        </InputLabel>
      }
      <TextField
        InputProps={{
          [!!name && 'endAdornment']: (
            <InputAdornment position={position}>
              <Icon {...otherIconProps}>{name}</Icon>
            </InputAdornment>
          ),
          style: { fontSize: '1.6rem', ...inputStyles }
        }}
        {...other}
      />
    </div>
  )

export default TextFieldWithIcon
