import ActionTypes from './types'
import { uniqBy } from 'lodash'

const initialState = {
  loading: true,
  loadingMore: false,
  data: [],
  hasMore: false,
}

const erpTransmissionsReducers = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_ERP_TRANSMISSIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        hasMore: action.payload.showMore,
      }
    }
    case ActionTypes.SET_ERP_TRANSMISSIONS_LOAD_MORE_SUCCESS: {
      return {
        ...state,
        loadingMore: true,
        data: uniqBy([...state.data, ...action.payload.data], '_id'),
        hasMore: action.payload.showMore,
      }
    }

    default:
      return state
  }
}

export default erpTransmissionsReducers
