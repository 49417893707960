import React, { useEffect, useState } from 'react'
import { Grid, Button, TextField, Select, MenuItem, FormControl, InputBase, withStyles } from '@material-ui/core'
import { filter, get, set, find, findIndex } from 'lodash'
import classNames from 'classnames'
import { useMergeState, copyFirstContainerEvents } from '../../../core/helpers'
import { bookingInfoForm, general } from 'core/localization'
import { InputTimeline } from '../../../core/components'
import BookingWizardSummary from '../ProposeBookingSummary'
import _ from 'lodash'
import { GooglePlaceSearch } from '../../../core/components/Inputs'
import DateTime from 'react-datetime'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.054)',
    border: '1px solid #ced4da',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '6px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'IBMPlexSans-SemiBold'
  }
}))(InputBase)
const styles = {
  disabledContainer: {
    background: `#dddddd !important`,
    cursor: `not-allowed !important`,
    marginTop: 10,
    marginBottom: 10,
    paddingRight: 10,
    '& input': {
      background: `#dddddd !important`,
      pointerEvents: 'none'
    },
    '& input:disabled': {
      marginTop: 10
    }
  }
}
const BookingForm = props => {
  const {
    booking,
    data: { hasError, shipment, transportMethod },
    go,
    existingFormData,
    onChange,
    mode,
    formMode,
    classes
  } = props
  const [state, setState] = useMergeState({})
  const [validated, setValidated] = useState(true)
  const [firstContainerIndex, setFirstContainerIndex] = useState(0)

  useEffect(() => {
    if (existingFormData) {
      setState(existingFormData)
      setValidated(checkValidation(existingFormData))
      setFirstContainerIndex(findIndex(get(existingFormData, 'containers', []), item =>
        ((get(item, 'status.current.value') !== 'cancelled') && !get(item, 'cancelled'))
      ))
    } else {
      setValidated(false)
      if (shipment) {
        var newState = {
          type: get(shipment, 'type'),
          groupageDate: get(shipment, 'groupageDate')
        }

        if (get(newState, 'type') === 'FCL') {
          let containers = get(shipment, 'containers', [])
          containers = containers.map(container => {
            return { ...container, cancelled: get(container, 'status.current.value') === 'cancelled' }
          })
          set(newState, 'containers', containers)
        }

        if (get(newState, 'type') === 'LCL') {
          set(newState, 'truckInCargo', {
            ...get(shipment, 'referenceEvents.truckInForCargoPickup', {}),
            floorUnitNumber: get(shipment, 'referenceEvents.truckInForCargoPickup.location.floorUnitNumber', '')
          })
        }

        if (transportMethod === 'air') {
          set(newState, 'hblNumber', get(shipment, 'referenceNos.HBLNo', []).join(','))
          set(newState, 'mblNumber', get(shipment, 'referenceNos.MBLNo', []).join(','))
          set(newState, 'cfs', get(shipment, 'referenceEvents.cargoDropOffAtCFS', {}))
        }

        setFirstContainerIndex(findIndex(get(shipment, 'containers', []), item =>
          ((get(item, 'status.current.value') !== 'cancelled') && !get(item, 'cancelled'))
        ))
        onChange('bookingForm', newState)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment, existingFormData])

  const availableContainerIndex = get(state, 'containers', []).map((item, index) => {
    if (get(item, 'status.current.value') === 'cancelled' ||
      get(item, 'cancelled')) {
      return false
    } else {
      return index.toString()
    }
  }).filter(Boolean)

  const checkValidation = updatedState => {
    const type = get(updatedState, 'type')
    if (!type) {
      return false
    }
    if (!get(updatedState, 'groupageDate')) {
      return false
    }
    if (type === 'FCL') {
      var hasError = find(get(updatedState, 'containers', []), container => {
        return (
          !get(container, 'type') ||
          !get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.location.street') ||
          !get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt')
        )
      })

      if (get(updatedState, 'containers', []).length === 0) {
        hasError = true
      }
      return hasError ? false : true
    }
    if (type === 'LCL') {
      if (!get(updatedState, 'truckInCargo.location.street')) {
        return false
      }
      if (!get(updatedState, 'truckInCargo.expectedAt')) {
        return false
      }
    }
    if (transportMethod === 'air') {
      if (!get(updatedState, 'cfs.location.street')) {
        return false
      }
      if (!get(updatedState, 'cfs.expectedAt')) {
        return false
      }
    }
    return true
  }

  const handleBlChange = event => {
    let { name, value } = event.target
    value = value.replace(/ /g, '')
    const updatedState = {
      ...state,
      [name]: value
    }

    onChange('bookingForm', updatedState)
  }

  const handleChange = truckMode => event => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [truckMode]: {
        ...state[truckMode],
        [name]: value
      }
    }

    onChange('bookingForm', updatedState)
  }

  const handleChangeByClick = (name, value) => {
    if (formMode === 'amend' || formMode === 'update') {
      return
    }
    const updatedState = {
      ...state,
      [name]: value
    }

    onChange('bookingForm', updatedState)
  }

  const handleDateChange = (date, name, section) => {
    const updatedState = {
      ...state,
      [section]: {
        ...state[section],
        [name]: new Date(date)
      }
    }

    onChange('bookingForm', updatedState)
  }

  const updateAddress = (address, lat, lng, name) => {
    let updatedState = {
      ...state,
      [name]: {
        ...state[name],
        location: {
          ...address,
          latitude: lat.toString(),
          longitude: lng.toString()
        }
      }
    }
    if (name === 'truckInCargo' && get(address, 'floorUnitNumber')) {
      set(updatedState, `${name}.floorUnitNumber`, get(address, 'floorUnitNumber', ''))
    }

    onChange('bookingForm', updatedState)
  }

  const handleInputChange = event => {
    const { name, value } = event.target
    const updatedState = {
      ...state,
      [name]: value
    }

    onChange('bookingForm', updatedState)
  }

  const handleManageContainer = (action, containerIndex = null) => {
    var updatedState = {
      ...state
    }
    let containers = get(state, 'containers', [])
    if (action === 'add') {
      updatedState.containers = [...get(state, 'containers', []), { isNew: true }]
    } else if (action === 'remove') {
      containers.splice(containerIndex, 1)
      updatedState.containers = containers
    } else if (action === 'cancel') {
      updatedState.containers = [
        ...containers.slice(0, containerIndex),
        { ...containers[containerIndex], cancelled: true },
        ...containers.slice(containerIndex + 1)
      ]
    } else if (action === 'back') {
      updatedState.containers = [
        ...containers.slice(0, containerIndex),
        { ...containers[containerIndex], cancelled: false },
        ...containers.slice(containerIndex + 1)
      ]
    }
    onChange('bookingForm', updatedState)
  }

  const handleUpdateContainerType = (event, index) => {
    const { value } = event.target
    var containers = get(state, 'containers', [])
    set(containers[index], 'type', value)
    const updatedState = {
      ...state,
      containers
    }

    onChange('bookingForm', updatedState)
  }

  const handleUpdateContainerPlace = (address, lat, lng, index) => {
    var containers = get(state, 'containers', [])
    set(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {
      ...get(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {}),
      ...address,
      latitude: lat.toString(),
      longitude: lng.toString()
    })

    const updatedState = {
      ...state,
      containers
    }

    onChange('bookingForm', updatedState)
  }

  const handleUpdateContainerAdditionalAddress = (event, index) => {
    const { name, value } = event.target
    var containers = get(state, 'containers', [])
    set(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {
      ...get(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.location', {}),
      [name]: value
    })

    const updatedState = {
      ...state,
      containers
    }

    onChange('bookingForm', updatedState)
  }

  const handleUpdateContainerDateTime = (date, index) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    var containers = get(state, 'containers', [])
    set(containers[index], 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt', new Date(date))

    const updatedState = {
      ...state,
      containers
    }

    onChange('bookingForm', updatedState)
  }

  const handleCfsAddress = (address, lat, lng) => {
    let updatedState = {
      ...state,
      cfs: {
        ...get(state, 'cfs', {}),
        location: {
          ...address,
          latitude: lat.toString(),
          longitude: lng.toString()
        }
      }
    }
    onChange('bookingForm', updatedState)
  }

  const handleCfsAdditionalAddress = (floorUnitNumber) => {
    let updatedState = {
      ...state,
      cfs: {
        ...get(state, 'cfs', {}),
        location: {
          ...state.cfs.location,
          floorUnitNumber
        }
      }
    }
    onChange('bookingForm', updatedState)
  }

  const handleCfsDateTime = (date) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    let updatedState = {
      ...state,
      cfs: {
        ...get(state, 'cfs', {}),
        expectedAt: new Date(date)
      }
    }
    onChange('bookingForm', updatedState)
  }

  const inputData = {
    type: 'booking',
    label: bookingInfoForm.schedules.label,
    instruction: '',
    items: [
      {
        img: 'assets/images/icons/wizard-arrow-down-circle.png',
        label: bookingInfoForm.schedules.truckInCargo.label,
        instruction: bookingInfoForm.schedules.truckInCargo.instruction,
        section: 'truckInCargo',
        text1Name: 'location',
        text1Value: _.get(state, 'truckInCargo.location.street'),
        text1Label: bookingInfoForm.schedules.field.place,
        text1UpdateAddress: (address, lat, lng) => updateAddress(address, lat, lng, 'truckInCargo'),
        text2Name: 'floorUnitNumber',
        text2Value: _.get(state, 'truckInCargo.floorUnitNumber'),
        text2Label: bookingInfoForm.schedules.field.additionalAddress,
        text3Name: 'expectedAt',
        text3Value: _.get(state, 'truckInCargo.expectedAt'),
        text3Label: bookingInfoForm.schedules.field.dateTime
      }
    ]
  }

  const s20Count = filter(state.containers, { type: 'C20GP' }).length
  const s40Count = filter(state.containers, { type: 'C40GP' }).length
  const hc40Count = filter(state.containers, { type: 'C40HC' }).length

  return (
    <div>
      <Grid container direction='row'>
        <Grid item sm={12} md={8} className={'booking-forms'}>
          {/* Available Shipment Type */}
          <Grid container direction='row'>
            <Grid item sm={12} className='flex items-center pb-8'>
              <span className='text-base text-grey-darkest font-bold'>Available shipment type</span>
            </Grid>

            {get(booking, 'available.FCL', 0) > 0 &&
              /* Available FCL */
              <React.Fragment>
                <Grid item sm={12} md={7} className='flex items-center pb-4'>
                  <span className='medium fg-gray'>Full container load</span>
                </Grid>
                <Grid item sm={12} md={5} className='flex items-center justify-end'>
                  <span className='medium'>
                    {_.get(booking, 'allowed', [])
                      .filter(x => x.type === 'FCL')
                      .map(x => x.equipmentType)
                      .join(', ') ||
                      <label className="fg-red">Not available</label>
                    }
                  </span>
                </Grid>
              </React.Fragment>
              /* End of Available FCL */
            }
            {get(booking, 'available.LCL', 0) > 0 &&
              /* Available LCL */
              <React.Fragment>
                <Grid item sm={12} md={7} className='flex items-center pb-4'>
                  <span className='medium fg-gray'>Less than container load</span>
                </Grid>
                <Grid item sm={12} md={5} className='flex items-center justify-end'>
                  <span className='medium'>
                    {_.get(booking, 'allowed', [])
                      .filter(x => x.type === 'LCL').length > 0 ?
                      'LCL' :
                      <label className="fg-red">Not available</label>
                    }
                  </span>
                </Grid>
              </React.Fragment>
              /* End of Available LCL */
            }

          </Grid>
          {/* End of Available Shipment Type */}

          {/* Shipment Type */}
          <Grid container direction='row' className={'field-control'}>
            <Grid item sm={12} md={12} className='flex items-center mb-12'>
              <label className={'field-label require-field'}>{bookingInfoForm.shipmentType.label}</label>
            </Grid>
            <Grid item sm={12} md={12}>
              <Grid container spacing={2}>
                {get(booking, 'available.FCL', 0) > 0 &&
                  <Grid item xs={12} sm={6} md={6}>
                    <Button disabled={formMode === 'amend' || formMode === 'update'}
                      onClick={() => handleChangeByClick('type', 'FCL')}
                      className={classNames({
                        'option-button': true,
                        active: get(state, 'type') === 'FCL'
                      })} fullWidth>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item><img src='assets/images/icons/fcl-icon.svg' alt='FCL' /></Grid>
                        <Grid item>{bookingInfoForm.shipmentType.options.fcl}</Grid>
                      </Grid>
                    </Button>
                  </Grid>
                }
                {get(booking, 'available.LCL', 0) > 0 &&
                  <Grid item xs={12} sm={6} md={6}>
                    <Button disabled={formMode === 'amend' || formMode === 'update'}
                      onClick={() => handleChangeByClick('type', 'LCL')}
                      className={classNames({
                        'flex justify-around option-button': true,
                        active: get(state, 'type') === 'LCL'
                      })} fullWidth>
                      <Grid container alignItems="center" spacing={2}>
                        <Grid item><img src='assets/images/icons/lcl-icon.svg' alt='LCL' /></Grid>
                        <Grid item>{bookingInfoForm.shipmentType.options.lcl}</Grid>
                      </Grid>
                    </Button>
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
          {/* End of Shipment Type */}

          {/* Containers and schedule */}
          {get(state, 'type') === 'FCL' && (
            <Grid container direction='row' alignItems='center' className={'field-control'}>
              <Grid item sm={12} md={6}>
                <label className={'field-label require-field'}>Containers and schedule</label>
              </Grid>
              <Grid item sm={12} md={6}>
                <Grid container alignItems='center' justify='flex-end'>
                  <label className={'field-instruction'}>
                    <span className='medium'>20'ST - {s20Count}</span>
                    <span className='px-6'>&bull;</span>
                    <span className='medium'>40'ST - {s40Count}</span>
                    <span className='px-6'>&bull;</span>
                    <span className='medium'>40'HC - {hc40Count}</span>
                  </label>
                </Grid>
              </Grid>

              <Grid container direction='row' className='stepper'>
                {get(state, 'containers', []).map((container, index) => {
                  const containerType = get(container, 'type')
                  const equipmentNo = get(container, 'equipmentNo')
                  let containerRemovalType = 'remove'
                  if (get(state, 'type') === 'FCL' &&
                    (formMode === 'amend' || formMode === 'update') &&
                    !get(container, 'isNew')) {
                    containerRemovalType = 'cancel'
                    if (container.cancelled) {
                      containerRemovalType = 'back'
                    }
                  }
                  return (
                    <Grid container
                      direction='row'
                      key={index}
                      className={classNames(
                        'step pb-24',
                        {
                          [classes.disabledContainer]: container.cancelled
                        }
                      )}>
                      <Grid container direction='row' className='step-header'>
                        <span className='step-icon'>
                          <img src='assets/images/icons/timeline-step/storage-facility-blue.svg'
                            alt='container-booking' />
                        </span>
                        <Grid item sm={12} md={6} className='flex items-center'>
                          {get(container, 'isNew') && (
                            <Grid container>
                              <Grid item xs={7}>
                                <FormControl fullWidth className="require-field">
                                  <Select id='demo-customized-select'
                                    value={containerType || 'default'}
                                    onChange={event => {
                                      handleUpdateContainerType(event, index)
                                    }}
                                    placeholder='Select type...'
                                    input={<BootstrapInput />}>
                                    <MenuItem value='default'
                                      selected
                                      disabled>Select type...</MenuItem>
                                    {['C20GP', 'C40GP', 'C40HC'].map(type => {
                                      return <MenuItem key={`type-key-${type}`}
                                        value={type}>{general.container[type]}
                                      </MenuItem>
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={5} />
                            </Grid>
                          )}
                          {!get(container, 'isNew') && (
                            <label className='field-sub-label'>
                              <span>{general.container[containerType]}</span>
                              {equipmentNo && (
                                <>
                                  <span className='px-6'>&bull;</span>
                                  <span>{equipmentNo}</span>
                                </>
                              )}
                            </label>
                          )}
                        </Grid>
                        <Grid item sm={12} md={6} className='flex items-center justify-end'>
                          {index > firstContainerIndex && !container.cancelled && (
                            <label className='fg-blue cursor-pointer mr-16'
                              onClick={() => {
                                const updatedState = {
                                  ...state,
                                  containers: copyFirstContainerEvents(
                                    get(state, 'containers', []),
                                    availableContainerIndex.indexOf(index.toString()),
                                    ['truckInToSWHWithEmptyContainer']
                                  )
                                }

                                onChange('bookingForm', updatedState)
                              }}>
                              Copy from first
                            </label>
                          )}
                          <label className={'fg-red cursor-pointer'}
                            onClick={() => handleManageContainer(containerRemovalType, index)}>
                            {containerRemovalType === 'remove'
                              ? 'Remove'
                              : containerRemovalType === 'back'
                                ? 'Add Back'
                                : 'Cancel'}
                          </label>
                        </Grid>
                      </Grid>

                      <Grid container direction='row' className='step-content'>
                        <Grid item sm={12} className='pb-16'>
                          <label className={'formsy-form-control__label require-field'}>
                            Drop container at warehouse</label>
                          <GooglePlaceSearch placeholder='Select street address...'
                            className='theme-text-field'
                            disabled={container.cancelled}
                            value={get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.location.street', '')}
                            updateCall={(address, lat, lng) => {
                              handleUpdateContainerPlace(address, lat, lng, index)
                            }}
                            savedPlaceType="truck-in"
                          />
                        </Grid>
                        <Grid item sm={12} md={7} className='pr-8'>
                          <TextField name='floorUnitNumber'
                            className='theme-text-field'
                            disabled={container.cancelled}
                            value={get(
                              container,
                              'referenceEvents.truckInToSWHWithEmptyContainer.location.floorUnitNumber',
                              ''
                            )}
                            label='Additional address'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => {
                              handleUpdateContainerAdditionalAddress(event, index)
                            }}
                          />
                        </Grid>
                        <Grid item sm={12} md={5} className='pl-6'>
                          <Grid container>
                            <Grid item xs={12}>
                              <label className='formsy-form-control__label require-field'>
                                Date & time</label>
                            </Grid>
                            <Grid item xs={12} className='relative'>
                              <DateTime
                                className='theme-date-time-picker-field'
                                input
                                inputProps={{
                                  disabled: container.cancelled
                                }}
                                dateFormat='DD MMM YYYY,'
                                onChange={date => {
                                  if (container.cancelled) return
                                  handleUpdateContainerDateTime(date, index)
                                }}
                                value={
                                  get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt') &&
                                  new Date(get(container, 'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt'))
                                }
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}

                <Grid container direction='row' className='step pb-24'>
                  <Grid container direction='row' className='step-header' justify='space-between'>
                    <span className='step-icon'>
                      <img src='assets/images/icons/vessel-info/add-transhipment.png' alt='booking' />
                    </span>
                    <Grid item>
                      <Button className='btn btn_sm btn_light-blue border-solid border-1 border-blue normal-fonts'
                        onClick={() => handleManageContainer('add')}>
                        Add container</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* End of Containers and schedule */}

          {/* Schedules */}
          {get(state, 'type') === 'LCL' && (
            <InputTimeline data={inputData} onChange={handleChange} onDateChange={handleDateChange} />
          )}
          {/* End of Schedules */}

          {transportMethod === 'air' &&
            // CFS address for package submission
            <Grid container direction='row' className='field-control' alignItems='center'>
              <Grid item sm={12} md={12} className="pb-12">
                <label className='field-label'>Package submission schedule</label>
              </Grid>
              <Grid item sm={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <label className={'formsy-form-control__label require-field'}>
                      CFS address for package submission</label>
                    <GooglePlaceSearch placeholder='Select street address...'
                      className='theme-text-field'
                      value={_.get(state, 'cfs.location.street', '')}
                      updateCall={handleCfsAddress}
                      savedPlaceType="cfs" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <TextField name='packageSubmissionSchedule.additionalAddress'
                      className='theme-text-field'
                      value={_.get(state, 'cfs.location.floorUnitNumber', '')}
                      label={get(bookingInfoForm, 'schedules.field.additionalAddress')}
                      fullWidth={true}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => handleCfsAdditionalAddress(e.target.value)} />
                  </Grid>
                  <Grid item xs={12} sm={12} md={5}>
                    <Grid container>
                      <Grid item xs={12}>
                        <label className='formsy-form-control__label require-field'>
                          {get(bookingInfoForm, 'schedules.field.dateTime')}
                        </label>
                      </Grid>
                      <Grid item xs={12} className='relative'>
                        <DateTime className='theme-date-time-picker-field'
                          input
                          dateFormat='DD MMM YYYY,'
                          onChange={date => handleCfsDateTime(date)}
                          value={_.get(state, 'cfs.expectedAt') && new Date(get(state, 'cfs.expectedAt'))} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            // End of CFS address for package submission
          }

          {/* Groupage date */}
          <Grid container direction='row' className='field-control' alignItems='center'>
            <Grid item sm={12} md={6}>
              <label className='field-label'>Groupage date</label>
            </Grid>
            <Grid item sm={12} md={6} className='relative'>
              <label className='text-10 regular fg-gray require-field'>
                Select date</label>
              <DateTime className='theme-date-time-picker-field'
                input
                dateFormat='DD MMM YYYY'
                timeFormat={false}
                onChange={(date) => {
                  if (new Date(date).toString() === 'Invalid Date') {
                    date = new Date()
                  }
                  handleInputChange({ target: { name: 'groupageDate', value: date } })
                }}
                value={get(state, 'groupageDate') && new Date(get(state, 'groupageDate'))}
              />
            </Grid>
          </Grid>
          {/* END Groupage date */}

          {transportMethod === 'air' &&
            /* Pre-Assigned Airway Bill */
            <Grid container direction='row' className='field-control' alignItems='center'>
              <Grid item sm={12} md={12} className="pb-12">
                <label className='field-label'>Pre-Assigned Airway Bill</label>
              </Grid>
              <Grid item sm={12} md={12}>
                <Grid container spacing={2}>
                  {/* MBL */}
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name='mblNumber'
                          className='theme-text-field w-full'
                          label="MAWB Number"
                          value={_.get(state, 'mblNumber', '')}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleBlChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className='medium fg-gray text-10 italic'>
                          {_.get(state, 'mblNumber', '')
                            .split(',')
                            .filter(Boolean).length + ' MAWB(s) have been added'}
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* HBL */}
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name='hblNumber'
                          className='theme-text-field w-full'
                          label="HAWB Number"
                          value={_.get(state, 'hblNumber', '')}
                          InputLabelProps={{ shrink: true }}
                          onChange={handleBlChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <label className='medium fg-gray text-10 italic'>
                          {_.get(state, 'hblNumber', '')
                            .split(',')
                            .filter(Boolean).length + ' HAWB(s) have been added'}
                        </label>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            /* END Pre-Assigned Airway Bill */
          }

          {/* Add Note */}
          <Grid item xs={12} className='pt-20 field-control'>
            <Grid container alignItems='center'>
              <label className='text-16 medium'>Add Note&nbsp;</label>
              <label className='text-16 regular'>(For booking partners)</label>
            </Grid>
            <TextField
              className='theme-text-field'
              placeholder='You can add any extra note for this booking'
              name='note'
              value={get(state, 'note')}
              fullWidth={true}
              InputLabelProps={{ shrink: true }}
              onChange={handleInputChange}
            />
          </Grid>
          {/* END Add Note */}

          {hasError && (
            /* Shipment Warning */
            <Grid container className={'px-20 py-12 mt-40 bg-light-orange'} alignItems='center'>
              <Grid item xs={12}>
                <label className={'text-16 semi-bold fg-orange'}>Warning!</label>
              </Grid>
              <Grid item xs={12}>
                <label
                  className={'text-10 fg-gray regular'}
                  style={{
                    lineHeight: '12px'
                  }}
                >
                  You don’t seem to have a service card linked to the current selection or there are possible conflicts in
                  service cards of selected cargo type. But you can still continue to create a booking. Shipment will mark as
                  spot.
                </label>
              </Grid>
            </Grid>
          )
            /* End of Shipment Warning */
          }

          {/* Booking Info actions */}
          <Grid container justify='space-between' className='pt-20'>
            <Grid item sm={12} md={6}>
              <Button className='btn w-full' disabled={!validated} onClick={() => go(1)}>
                {general.next}
              </Button>
            </Grid>
          </Grid>
          {/* End of Booking Info actions */}
        </Grid>

        <Grid item sm={12} md={4}>
          <BookingWizardSummary shipment={mode === 'edit' ? { ...shipment, ...existingFormData } : null} />
        </Grid>
      </Grid>
    </div >
  )
}

export default withStyles(styles)(BookingForm)
