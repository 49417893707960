import React from 'react'
import { Grid, Button } from '@material-ui/core'

const SettingsFormFooter = (props) => {
  const { onReset, onSubmit, disableSubmit } = props
  return (
    <Grid container
      justify="space-between"
      alignItems="center"
      className="px-20 py-16 bg-gray">

      <Grid item>
        <Button className="btn btn_transparent"
          onClick={onReset}
          style={{
            padding: '0px',
            minWidth: 'auto',
          }}>RESET</Button>
      </Grid>
      <Grid item>
        <Button className="btn btn_green min-w-192"
          onClick={onSubmit}
          disabled={disableSubmit}>SAVE</Button>
      </Grid>

    </Grid>
  )
}

export default SettingsFormFooter
