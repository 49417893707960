import React, { useMemo, useRef, useEffect } from 'react'
import { withStyles, Grid } from '@material-ui/core'
import { unionBy } from 'lodash'
import { useMergeState } from 'core/helpers'
import OutboundGroupHeader from './OutboundGroupHeader'
import OutBoundTableRow from './OutBoundTableRow'
import { InfoToolTip } from '../../../core/components'

const INITIAL_STATE = {
  group: {
    items: []
  }
}

const OutBoundTableGroup = ({ classes, data, onChange, groupKey, onOutboundsClick, transportMethod }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const selectedItems = useRef([])

  useMemo(() => {
    setState({ group: data })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onFilterChange = index => filters => {
    const newGroup = { ...state.group }

    newGroup.items[index].items = newGroup.items[index].items.map(v => {
      v.checked =
        filters.indexOf('ALL') !== -1 ||
        filters.indexOf(v.specialLabel) !== -1 ||
        filters.indexOf(v.goodsType) !== -1 ||
        filters.indexOf(v.shipmentMode) !== -1
      if (v.checked) {
        selectedItems.current.push(v)
      } else {
        const index = selectedItems.current.findIndex(_v => _v.code === v.code)
        selectedItems.current.splice(index, 1)
      }

      return v
    })

    selectedItems.current = unionBy(selectedItems.current, v => v.code)

    setState({ group: newGroup })
    onChange(getSelectionInfo())
  }

  const onChkClick = index => value => {
    const newGroup = { ...state.group }

    newGroup.items[index].items = newGroup.items[index].items.map(v => {
      if (v.code === value.code) {
        v.checked = !v.checked

        if (v.checked) {
          selectedItems.current.push(v)
        } else {
          const _index = selectedItems.current.findIndex(_v => _v.code === value.code)
          if (_index !== -1) {
            selectedItems.current.splice(_index, 1)
          }
        }
      }
      return v
    })

    setState({ group: newGroup })
    onChange(getSelectionInfo())
  }

  const getSelectionInfo = () => {
    const result = {
      selectedIds: [],
      totalSelected: 0,
      totalWeight: 0,
      totalVolume: 0,
      key: groupKey
    }
    selectedItems.current.forEach(v => {
      if (v.isCSItem) {
        result.totalSelected++
      }
      result.selectedIds = result.selectedIds.concat(v.outboundIds)
      result.totalWeight += v.totalWeight
      result.totalVolume += v.totalVolume
      result.weightUnit = v.weightUnit
      result.volumeUnit = v.volumeUnit
    })

    return result
  }

  useEffect(() => {
    selectedItems.current = []
    onChange({
      selectedIds: [],
      totalSelected: 0,
      totalWeight: 0,
      totalVolume: 0,
      key: groupKey
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <div className={classes.root}>
      <Grid container
        justify="space-between"
        alignItems="center"
        className='text-14 semi-bold'>
        <Grid item>
          <Grid container
            alignItems="center">
            <Grid item className="text-grey-dark">
              {`${data.country ? `(${data.country}) ` : ''}`}
              {`${data.plantType}:`}
            </Grid>
            <Grid item className="text-grey-darkest ml-4">
              {data.plantName}
            </Grid>
            {data.warning &&
              <InfoToolTip title={data.warningReason}>
                <Grid item className='ml-4 cursor-pointer'>
                  <img src='assets/images/icons/outbound-exception.svg'
                    alt='tab-inact' />
                </Grid>
              </InfoToolTip>
            }
          </Grid>
        </Grid>
        <Grid item className="text-grey-dark">
          {`Ship-to-party: ${data.stpCode}`}
        </Grid>
      </Grid>

      {state.group.items.map((item, i) => (
        <div key={`group-header-${i}`}>
          <OutboundGroupHeader data={item} onChange={onFilterChange(i)} />
          {item.items.map((subItem, _i) => (
            <OutBoundTableRow
              key={`row-${i}-${_i}`}
              data={subItem}
              onChkClick={onChkClick(i)}
              onOutboundsClick={onOutboundsClick({ outboundIds: subItem.outboundIds, type: 'outbounds', transportMethod })}
            />
          ))}
        </div>
      ))}
    </div>
  )
}

const styles = theme => ({
  root: {
    borderBottom: '1px dashed #c0c3c5',
    marginBottom: 20,
    paddingBottom: 20,

    '&:last-child': {
      borderBottom: 'none',
      marginBottom: 0,
      paddingBottom: 0,
    }
  }
})

export default withStyles(styles)(OutBoundTableGroup)
