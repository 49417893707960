import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, Tabs, Tab, Typography, Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import history from '../../history'
import queryString from 'query-string'
import { get } from 'lodash'
import EmailList from '../components/EmailList'
import * as Actions from '../store/actions'
import EmailReport from '../components/EmailReport'
import { LoadMore } from 'core/components'

const tabStyle = {
  position: 'fixed',
  width: '100%',
  top: '54px'
}

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component='div'
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={'w-full overflow-hidden'}
    {...other}>
    <Box>{children}</Box>
  </Typography>
)

const EmailTracker = props => {
  const {
    location: { search },
    getEmailLogs,
    emailTracker
  } = props
  const [tabValue, setSabValue] = useState(0)
  const [emailReportDialog, setEmailReportDialog] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const pageSize = 10

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      style: {
        paddingLeft: 20,
        paddingRight: 20
      }
    }
  }

  const handleTabChange = (event, newValue) => {
    history.push(`?tab=${newValue}`)
  }

  const handleViewEmailReport = (reports) => {
    setEmailReportDialog({
      open: true,
      reports
    })
  }

  const onLoadMore = () => {
    const newPageNo = pageNo + 1
    setPageNo(newPageNo)
    getEmailLogs({ type: 'preAlert' }, { pageNo: newPageNo, pageSize })
  }

  useEffect(() => {
    const parseQuery = queryString.parse(search)
    const { tab } = parseQuery
    setSabValue(Number(tab || 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    getEmailLogs({ type: 'preAlert' }, { pageNo, pageSize })

    return () => {
      setPageNo(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container className="h-full overflow-auto">
      <Grid item xs={12}>
        <Tabs value={tabValue} style={tabStyle}
          onChange={handleTabChange}
          className='bg-white shadow'
          variant="scrollable"
          scrollButtons="auto"
          aria-label="email-logs tabs">
          <Tab label='Pre Alerts' {...a11yProps(0)}
            className={'custom-tabs-item ' + (tabValue === 0 ? 'selected' : '')} />
        </Tabs>

        {/* Pre Alert Emails */}
        <TabPanel value={tabValue} index={0}>
          <Grid container>
            <Grid item xs={12} className="p-20">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <Typography className="fg-gray semi-bold text-14">
                        Total&nbsp;{get(emailTracker, 'total', 0)}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <EmailList loading={get(emailTracker, 'loading', false)}
                    emails={get(emailTracker, 'data', [])}
                    onInfoClick={handleViewEmailReport} />
                </Grid>
                {get(emailTracker, 'hasMore', false) &&
                  <Grid item xs={12}>
                    <LoadMore loading={get(emailTracker, 'loadingMore', false)}
                      onClick={onLoadMore} />
                  </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </TabPanel>
        {/* End of Pre Alert Emails */}

      </Grid>

      {/* Info Dialog */}
      <EmailReport open={get(emailReportDialog, 'open', false)}
        emailReports={get(emailReportDialog, 'reports', [])}
        handleClose={() => setEmailReportDialog(false)} />
      {/* End of Info Dialog */}

    </Grid>
  )
}

const styles = {
  dgGoodsType: {
    color: '#cf2a2a'
  },
  normalGoodsType: {
    color: '#7db5e8'
  },
  directShipmentMode: {
    color: '#e47e00'
  },
  dropShipmentMode: {
    color: '#e47e00'
  }
}

const mapStateToProps = ({ emailTracker }) => ({
  emailTracker,
})

const mapDispatchToProps = dispatch => ({
  getEmailLogs: (...args) => dispatch(Actions.getEmailLogs(...args)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EmailTracker))
