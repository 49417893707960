import React, { useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { FuseDialog, FuseDialogBody, FuseDialogHeader, LoadingButton } from '../../core/components'
import { get } from 'lodash';
import { useMergeState } from '../../core/helpers'
import ReviewBookingSummary from './ReviewBookingSummary'

const INITIAL_STATE = {
  rejectReason: ''
}

const RejectBookingDialog = ({ open, currentPlant, shipment, handleClose, onSubmit, onReviewBooking, requesting }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)

  useEffect(() => {
    setState(INITIAL_STATE)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  let transportRoute = [];

  if (currentPlant.type === 'ofp') {
    transportRoute = [
      {
        place: get(shipment, 'plannedRoute.originPort.name'),
        address: get(shipment, 'plannedRoute.originPort.street') || get(shipment, 'plannedRoute.originPort.country'),
        iconRounded: true,
        connector: {
          icon: 'directions_boat',
          iconRounded: true
        }
      },
      {
        place: get(shipment, 'plannedRoute.destinationPort.name'),
        address: get(shipment, 'plannedRoute.destinationPort.street') || get(shipment, 'plannedRoute.destinationPort.country'),
      }];
  }
  else if (currentPlant.type === 'obl') {
    transportRoute = [{
      place: get(shipment, 'plannedRoute.origin.name'),
      address: get(shipment, 'plannedRoute.origin.street') || get(shipment, 'plannedRoute.origin.country'),
      connector: {
        icon: 'local_shipping',
        iconRounded: true
      }
    },
    {
      place: get(shipment, 'plannedRoute.originPort.name'),
      address: get(shipment, 'plannedRoute.originPort.street') || get(shipment, 'plannedRoute.originPort.country'),
    }
    ];
  }

  const handleChange = (event) => {
    setState({
      rejectReason: event.target.value
    })
  }

  const submit = () => {
    //Call api here
    const payload = {
      reason: state.rejectReason
    }
    onSubmit(payload, shipment._id);
  }

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text='Reject booking' handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="booking-wizard">
          <Grid item xs={8} className='booking-forms'>
            <Grid container className="pb-40" alignItems="flex-start">
              <Grid item xs={12}>
                <label className="text-16 semi-bold require-field">
                  Add your reason for rejecting the booking</label>
              </Grid>

              <Grid item xs={12} className="field-control">
                <TextField name='reason'
                  placeholder='Add note'
                  className="theme-text-field"
                  value={state.rejectReason}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth={true} />
              </Grid>
            </Grid>
            <Grid container className="pt-40" alignItems="flex-end">
              <Grid item xs={6} className="pr-12">
                <LoadingButton className="btn btn_lg w-full btn_light_red"
                  loading={requesting}
                  disabled={!state.rejectReason}
                  onClick={submit}>Reject Booking</LoadingButton>
              </Grid>
              <Grid item xs={6} className="pl-12">
                <Grid container justify="flex-end">
                  <Button className="btn btn_transparent"
                    disabled={requesting}
                    onClick={() => onReviewBooking(shipment)}>back</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <ReviewBookingSummary transportRoute={transportRoute} shipment={shipment} />
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default RejectBookingDialog
