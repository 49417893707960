import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { FuseCheckBoxGroup, InfoToolTip } from '../../../core/components'
import { get } from 'lodash'

const weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const checkboxData = {
  ALL: 'ALL',
  NORMAL: 'NON DG',
  FORMD: 'FORMD',
  FORMAK: 'FORMAK',
  FORMA: 'FORMA',
  FORME: 'FORME',
  DG: 'DG',
  DIRECT: 'DIRECT',
  DROP: 'DROP'
}
const FULL_CHECKED = Object.keys(checkboxData)

const OutboundGroupHeader = ({ data, onChange }) => {
  const [value, setValue] = useState([])
  const [availableOptions, setAvailableOptions] = useState([])

  useEffect(() => {
    const availableOptions = []
    get(data, 'items', []).forEach(item => {
      if (!availableOptions.find(v => v.value === item.goodsType)) {
        availableOptions.push({
          label: checkboxData[item.goodsType],
          value: item.goodsType
        })
      }

      if (!availableOptions.find(v => v.value === item.shipmentMode)) {
        availableOptions.push({
          label: checkboxData[item.shipmentMode],
          value: item.shipmentMode
        })
      }

      if (item.specialLabel && !availableOptions.find(v => v.value === item.specialLabel)) {
        availableOptions.push({
          label: checkboxData[item.specialLabel],
          value: item.specialLabel
        })
      }
    })

    if (availableOptions.length > 1) {
      availableOptions.unshift({
        label: 'ALL',
        value: 'ALL'
      })
    }
    setAvailableOptions(availableOptions)
    setValue([])
    onChange([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const onBoxChange = (selected, cur) => {
    if (cur.value === 'ALL') {
      selected = cur.checked ? FULL_CHECKED : []
    } else {
      const allIndex = selected.indexOf('ALL')
      if (cur.checked && selected.length === FULL_CHECKED.length - 1 && allIndex === -1) {
        selected = FULL_CHECKED
      }
      if (!cur.checked && allIndex !== -1) {
        selected.splice(allIndex, 1)
      }
    }

    setValue(selected)
    onChange(selected)
  }

  return (
    <Grid container
      alignItems="center"
      justify="space-between"
      className='text-12 my-12 regular'>
      <Grid item>
        <FuseCheckBoxGroup data={availableOptions}
          value={value}
          className='flex-3 flex-row pl-8'
          chkClassName='text-12 medium text-grey-darkest'
          onChange={onBoxChange} />
      </Grid>

      {data.groupageDay.length > 0 &&
        <Grid item
          className='text-12 text-grey-darkest text-right w-172'>
          {data.groupageDay.map((d) => weekDays[d - 1]).join(', ')}
        </Grid>
      }

      {data.groupageDate.length === 1 &&
        <Grid item
          className='text-12 text-grey-dark text-right w-172'>
          {`Planned GI: ${data.groupageDate[0]}`}
        </Grid>
      }

      {data.groupageDate.length > 1 &&
        <InfoToolTip title={data.groupageDate.map((i, iIndex) =>
          <label className="block" key={iIndex}>{i}</label>)}>
          <Grid item className='text-12 text-white text-right bg-orange px-6 rounded-2 leading-normal'>
            {`Planned GI: ${data.groupageDate[0]}`}
          </Grid>
        </InfoToolTip>
      }

    </Grid>
  )
}

export default OutboundGroupHeader
