import React, { useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import ListSkeleton from './ListSkeleton'
import { get, groupBy, filter } from 'lodash'
import { formatTimestamp } from '../../core/helpers'
import InfoIcon from '@material-ui/icons/Info'
import { InfoToolTip } from 'core/components'
import ScheduleIcon from '@material-ui/icons/Schedule'
import DoneIcon from '@material-ui/icons/Done'
import DoneAllIcon from '@material-ui/icons/DoneAll'

const EmailList = ({ loading, emails, onInfoClick }) => {
  const [expandEmail, setExpandEmail] = useState(null)

  const getCounts = (reports) => {
    const groupedReports = groupBy(reports, 'email')
    const recipients = Object.keys(groupedReports).length
    const processed = filter(reports, i => i.event === 'processed').length
    const delivered = filter(reports, i => i.event === 'delivered').length
    const open = filter(reports, i => i.event === 'open').length

    return {
      recipients,
      processed: processed <= recipients ? processed : recipients,
      delivered: delivered <= recipients ? delivered : recipients,
      open: open <= recipients ? open : recipients,
    }
  }

  const getDeliveryStatus = (reports) => {
    const { recipients, processed, delivered, open } = getCounts(reports)

    if (delivered >= recipients && open >= recipients) {
      return <InfoToolTip title={`${open}/${recipients} open`}>
        <DoneAllIcon className="fg-blue mr-12 text-18" />
      </InfoToolTip>
    } else if (processed <= recipients && delivered >= recipients) {
      return <InfoToolTip title={`${delivered}/${recipients} delivered`}>
        <DoneAllIcon className="fg-gray mr-12 text-18" />
      </InfoToolTip>
    } else if (processed > 0 && processed <= recipients) {
      return <InfoToolTip title={`${processed}/${recipients} processed`}>
        <DoneIcon className="fg-gray mr-12 text-18" />
      </InfoToolTip>
    }
    else if (processed === 0) {
      return <InfoToolTip title="Waiting...">
        <ScheduleIcon className="fg-gray mr-12 text-18" />
      </InfoToolTip>
    }
  }

  const toggleExpand = (emailId) => {
    if (emailId === expandEmail) {
      setExpandEmail(null)
    } else {
      setExpandEmail(emailId)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {loading &&
          <ListSkeleton />
        }
        {!loading && emails.length > 0 &&
          <Grid container className="p-12 regular" spacing={4}>
            {emails.map((email, emailIndex) => {
              const reports = get(email, 'reports', [])
              const emailId = get(email, '_id')
              const isExpanded = expandEmail === emailId
              const { recipients, delivered } = getCounts(reports)

              return (
                <Grid item xs={12} key={emailIndex}>
                  <Grid container spacing={2} className="bg-white rounded shadow overflow-hidden">
                    <Grid item xs={12}>
                      <Typography className="text-16 semi-bold mb-8">
                        {get(email, 'subject')}
                      </Typography>
                      <InfoToolTip title={isExpanded ? 'Click to collapse' : 'Click to expand'}>
                        <div className={'transition-all text-12 overflow-hidden cursor-pointer '
                          + (isExpanded ? 'h-auto' : 'h-56')}
                          onClick={() => toggleExpand(emailId)}
                          dangerouslySetInnerHTML={{ __html: (get(email, 'text') || '') }}></div>
                      </InfoToolTip>
                    </Grid>
                    <Grid item xs={12} className="bg-gray overflow-hidden">
                      <Grid container alignItems="center" justify="space-between" spacing={1}
                        className="bg-gray text-12 fg-gray">
                        <Grid item>Sent on: {formatTimestamp(get(email, 'createdAt'), 'date-time')}</Grid>
                        <Grid item>
                          <Grid container justify="flex-end" alignItems="center">
                            <Grid item className="pr-12">
                              {`${delivered} delivered / ${recipients} email addresses`}
                            </Grid>
                            {getDeliveryStatus(reports)}
                            <InfoToolTip title={`Click to view full information`}>
                              <InfoIcon className="fg-gray cursor-pointer text-18"
                                onClick={() => onInfoClick(reports)} />
                            </InfoToolTip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        }
      </Grid>
    </Grid>
  )
}

export default EmailList
