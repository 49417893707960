import React, { useState, useEffect } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { get, findKey } from 'lodash'
import moment from 'moment'
import DateTime from 'react-datetime'
import { FusePlaceHolder, InfoToolTip } from '../../core/components'
import { general } from "../../core/localization";
import { addGaEvent } from "../../core/helpers";

const EventsTracking = ({ shipment, events, onTrackingClick, updateEventTime, classes, currentPlant }) => {
  const icons = {
    'check-list': ['scheduling', 'complete', 'misc'],
    transporter: ['exportLocalTransport', 'importLocalTransport'],
    customs: ['exportCustomClearance', 'importCustomClearance'],
    ship: ['exportFreightPartner', 'importFreightPartner']
  }

  const StyledDateTime = ({ datetime, type, allEvents, event, inputProps }) => {
    let userName = ''
    let plantName = ''
    const [newDate, setNewDate] = useState(null)
    const [isDateUpdated, setIsDateUpdated] = useState(false)
    const acceptStatus = {
      ofp: ['freight-partner-confirmed', 'obl-confirmed'],
      obl: ['obl-confirmed']
    }
    var canEdit = false

    if (type === 'E') {
      const currentPlantType = get(currentPlant, 'type')

      if (currentPlantType === 'shipper' || get(shipment, 'stage.current.value') === 'in-transit') {
        canEdit = true
      }
      else if (Object.keys(acceptStatus).includes(currentPlantType) &&
        acceptStatus[currentPlantType].includes(get(shipment, 'status.current.value'))
      ) {
        canEdit = true
      }
    }

    useEffect(() => {
      if (!newDate && datetime) {
        setNewDate(new Date(datetime))
      }
      if (!newDate && !datetime) {
        setNewDate(null)
      }
    }, [datetime, newDate])

    if (!datetime && type !== 'E') {
      return null
    }

    let itemBg = ''
    switch (type) {
      case 'E':
        itemBg = get(allEvents, 'estimated', []).length > 1 ? '#e0d4a8' : ''
        const eSource = get(allEvents, 'estimated[0].source', {})
        userName = `${get(eSource, 'user.firstname')} ${get(eSource, 'user.lastname')}`
        plantName = `${get(eSource, 'plant.name')} [${get(general, `partnerShortForms.${get(eSource, 'plant.type')}`)}]`
        break

      case 'P':
        itemBg = get(allEvents, 'planned', []).length > 1 ? '#e0d4a8' : ''
        const pSource = get(allEvents, 'planned[0].source', {})
        userName = `${get(pSource, 'user.firstname')} ${get(pSource, 'user.lastname')}`
        plantName = `${get(pSource, 'plant.name')} [${get(general, `partnerShortForms.${get(pSource, 'plant.type')}`)}]`
        break

      case '':
      default:
        itemBg = get(allEvents, 'actual', []).length > 1 ? '#e0d4a8' : ''
        const aSource = get(allEvents, 'actual[0].source', {})
        userName = `${get(aSource, 'user.firstname')} ${get(aSource, 'user.lastname')}`
        plantName = `${get(aSource, 'plant.name')} [${get(general, `partnerShortForms.${get(aSource, 'plant.type')}`)}]`
    }

    let rootStyle = {
      borderRadius: '2px',
    }
    let typeStyle = {
      borderRadius: '2px',
      marginRight: '4px',
      backgroundColor: '#D8D8D8',
      color: '#FFFFFF',
      lineHeight: '22px',
      padding: '1px 3px',
      fontSize: '8px',
      fontFamily: 'IBMPlexSans-Bold',
    }
    let dateStyle = {
      borderBottom: '1px dashed #80808070',
      padding: '1px 0px',
    }

    if (!canEdit) {
      rootStyle = {
        ...rootStyle,
        padding: '4px 3px',
        display: 'flex',
        justifyContent: type === '' ? 'center' : 'flex-start',
        backgroundColor: itemBg,
        minWidth: '100%',
        lineHeight: 'initial',
      }
      return (
        <Grid container
          alignItems="center"
          justify='flex-end'>
          <Grid xs={2} item>
            {(datetime && type) && <label style={typeStyle}>{type}</label>}
          </Grid>
          <Grid item xs={10}>
            <InfoToolTip title={`Last update by ${userName} from ${plantName}`}
              disableFocusListener={(userName.search('undefined') > -1 || plantName.search('undefined') > -1)}
              disableHoverListener={(userName.search('undefined') > -1 || plantName.search('undefined') > -1)}
              disableTouchListener={(userName.search('undefined') > -1 || plantName.search('undefined') > -1)}>
              <label style={rootStyle}>
                {datetime && moment(datetime).format('DD MMM YYYY, HH:mm')}
              </label>
            </InfoToolTip>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid container
          alignItems='center'
          justify='flex-end'
          className={classes.estimatedDateTime}
          style={rootStyle}>
          <Grid xs={2} item>
            {(type && !isDateUpdated) &&
              <label style={typeStyle}>{type}</label>
            }
            <span className={'cursor-pointer'
              + (isDateUpdated ? '' : ' hidden')}
              style={{ paddingRight: '1px' }}>
              <img src="assets/images/icons/events/save-active.svg"
                alt="save"
                onClick={() => {
                  updateEventTime(shipment, event, newDate, 'estimated', false)
                }} />
            </span>
          </Grid>
          <InfoToolTip title={`Last update by ${userName} from ${plantName}`}
            disableFocusListener={(userName.search('undefined') > -1 || plantName.search('undefined') > -1)}
            disableHoverListener={(userName.search('undefined') > -1 || plantName.search('undefined') > -1)}
            disableTouchListener={(userName.search('undefined') > -1 || plantName.search('undefined') > -1)}
            placement="top">
            <Grid item xs={10}
              style={dateStyle}
              className={'relative ' + (itemBg !== '' ? 'conflict' : '')}>
              <DateTime input
                dateFormat='DD MMM YYYY,'
                timeFormat='HH:mm'
                onChange={date => {
                  addGaEvent(
                    'shipment-updatingEventTracking',
                    get(shipment, '_id'),
                    get(event, 'code')
                  )
                  if (new Date(date).toString() === 'Invalid Date') {
                    date = new Date()
                  }
                  setNewDate(date)
                  if (newDate !== new Date(date)) {
                    setIsDateUpdated(true)
                  } else {
                    setIsDateUpdated(false)
                  }
                }}
                inputProps={inputProps}
                value={newDate} />
              <span className={'bg-white absolute cursor-pointer '
                + (isDateUpdated ? '' : 'hidden')}
                style={{
                  top: '1px',
                  right: '0px',
                  height: '100%'
                }}>
                <img src="assets/images/icons/events/close-active.svg"
                  alt="save"
                  onClick={() => {
                    addGaEvent(
                      'shipment-updatingEventTrackingCancelled',
                      get(shipment, '_id'),
                      get(event, 'code')
                    )
                    setNewDate(null)
                    setIsDateUpdated(false)
                  }} />
              </span>
            </Grid>
          </InfoToolTip>
        </Grid>
      )
    }
  }

  const calculateDelay = (planned, actual, estimated) => {
    if (!planned) {
      planned = estimated
    }
    if (planned && actual) {
      var date1 = new Date(planned)
      var date2 = new Date(actual)
      date2.setSeconds(0)
      date1.setSeconds(0)
      date2.setUTCMilliseconds(0)
      date1.setUTCMilliseconds(0)
      const diffTime = date2.getTime() - date1.getTime()
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (diffDays > 0 && diffDays <= 3) {
        return (
          <InfoToolTip title={`${diffDays} day${diffDays > 1 ? 's' : ''} delayed`}>
            <label style={{ color: '#dcd500' }}
              className="semi-bold text-12">Delayed</label>
          </InfoToolTip>
        )
      } else if (diffDays > 3 && diffDays <= 7) {
        return (
          <InfoToolTip title={`${diffDays} days delayed`}>
            <label style={{ color: '#dca200' }}
              className="semi-bold text-12">Delayed</label>
          </InfoToolTip>
        )
      } else if (diffDays >= 8) {
        return (
          <InfoToolTip title={`${diffDays} days delayed`}>
            <label style={{ color: '#dc0000' }}
              className="semi-bold text-12">Delayed</label>
          </InfoToolTip>
        )
      } else {
        return (
          <label style={{ color: '#2db700' }}
            className="semi-bold text-12">On&nbsp;time</label>
        )
      }
    }
  }

  return (
    <Grid container className='event-tracking'>
      <Grid item xs={12} className='pb-16'>
        <Grid container>
          <Grid item xs={6}>
            <Typography className='section__title'>
              Event tracking</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify='flex-end'>
              <Typography className='section__info'>
                Here you can see events added by all the actors</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {get(events, '[0].invalid') &&
        <Grid item xs={12}>
          <FusePlaceHolder className='flex flex-col'
            icon='error'
            title='Events tracking will be visible to you once you accepting this shipment' />
        </Grid>
      }

      {(!get(events, '[0].invalid') && events) && events
        .map((event, sectionIndex) => {
          return (
            <Grid container key={sectionIndex} className='pl-12'>
              <Grid
                item
                xs={12}
                className={
                  'pb-12 event-tracking-item ' +
                  findKey(icons, icon => {
                    return icon.includes(event.section)
                  }) +
                  (events.length - 1 === sectionIndex ? ' last' : '')
                }
              >
                <Grid container>
                  <Grid item xs={6} className='scheduling-title capitalize pl-12'>
                    {get(event, 'section', '').replace(/([A-Z])/g, ' $1')}
                  </Grid>
                </Grid>
              </Grid>
              {get(event, 'events', []).map((e, eventIndex) => {
                var eventName = get(e, 'name', '')
                eventName += get(e, 'containerName') ? ` [${e.containerName}]` : ''

                return (
                  <Grid item xs={12} className='scheduling-title' key={eventIndex}>
                    <Grid container alignItems="center"
                      className={'scheduling-container py-12 md:py-0' +
                        (events.length - 1 === sectionIndex ? ' last' : '')
                      }>
                      <Grid item xs={12} sm={12} md={3}
                        className={
                          'scheduling-name capitalize min-h-24 pl-20 pr-12 py-6' +
                          (get(event, 'events', []).length - 1 === eventIndex ? ' pb-20' : '') +
                          (get(e, 'containerStatus') === 'cancelled' ? ' cancelled' : '')
                        }
                        onClick={onTrackingClick(e)}
                      >
                        {e.trigger === 'document' && (
                          <img className='mr-4 w-16 h-16'
                            src='assets/images/icons/Document-upload.png'
                            alt='document' />
                        )}
                        <label className="cursor-pointer">{eventName}</label>
                      </Grid>

                      <Grid item xs={12} sm={12} md={9}>
                        <Grid container
                          alignItems="center"
                          justify='flex-end'>
                          <Grid item style={{ width: '88%' }}>
                            <Grid container
                              justify='flex-end'
                              alignItems="center"
                              spacing={1}>
                              <Grid item xs={12} sm={12} md={4}
                                className='scheduling-value text-right'>
                                <StyledDateTime
                                  datetime={get(e, 'highlightEvents.planned.occurenceAt', '')}
                                  type={'P'}
                                  allEvents={get(e, 'allEvents', [])}
                                  event={e}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4}
                                className='scheduling-value text-right'>
                                <StyledDateTime
                                  datetime={get(e, 'highlightEvents.estimated.occurenceAt', '')}
                                  type={'E'}
                                  inputProps={{
                                    placeholder: 'Click to add',
                                    style: {
                                      display: 'flex',
                                      width: '100%',
                                      cursor: 'pointer',
                                      padding: '4px 3px',
                                      borderRadius: '2px'
                                    }
                                  }}
                                  allEvents={get(e, 'allEvents', [])}
                                  event={e}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4}
                                className='scheduling-value text-right'>
                                <StyledDateTime
                                  datetime={get(e, 'highlightEvents.actual.occurenceAt', '')}
                                  type={''}
                                  allEvents={get(e, 'allEvents', [])}
                                  event={e}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item style={{ width: '12%' }} className="pl-12">
                            <Grid container justify='flex-end'>
                              <Grid item xs={12} className='scheduling-value text-right'>
                                {calculateDelay(
                                  get(e, 'highlightEvents.planned.occurenceAt', null),
                                  get(e, 'highlightEvents.actual.occurenceAt', null),
                                  get(e, 'highlightEvents.estimated.occurenceAt', null)
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          )
        })}
    </Grid>
  )
}

const styles = {
  estimatedDateTime: {
    maxWidth: '100%',
    '& .conflict .rdt > input': {
      backgroundColor: '#e0d4a8'
    }
  }
}
export default withStyles(styles)(EventsTracking)
