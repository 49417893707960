import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, Button, Typography, Box } from '@material-ui/core'
import { get, pick } from 'lodash'
import _ from 'lodash'
import queryString from 'query-string'
import { useMergeState, shipmentNameBeautifier } from '../../core/helpers'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions,
  LoadingButton,
  ShipmentDeltaInfo
} from '../../core/components'
import SubToolBarLayout from '../../core/containers/Layout/SubToolBarLayout'
import * as CoreActions from '../../core/store/actions'
import * as BookingActions from 'manage-booking/store/actions'
import * as SettingsActions from '../../settings/store/actions'
import TabContent from '../components/TabContent'
import ShipmentDetailsSkeleton from '../../core/components/Skeletons/ShipmentDetailsSkeleton'
import ExceptionReasonSelectionRow from '../components/ExceptionReasonSelectionRow'
import NewBoookingDialog from '../../manage-booking/components/NewBoookingDialog'
import UploadDialog from '../components/FileUploadDialog'
import FPAcceptBookingDialog from 'schedule/components/FPAcceptBookingDialog'
import * as ScheduleActions from 'schedule/store/actions'
import * as ShipmentActions from '../store/actions'
import BillLadinSidebar from '../components/BillLadinSidebar'
import CarrierEditSidebar from '../components/CarrierEditSidebar'
import ContainerEditSidebar from '../components/ContainerEditSidebar'
import EventTrackingSidebar from '../components/EventTrackingSidebar'
import CancelAndRestartReasonDialog from '../components/CancelAndRestartReasonDialog'
import history from '../../history'
import ReviewBookingDialog from 'schedule/components/ReviewBookingDialog'
import ELPAcknowledgeBookingDialog from 'schedule/components/ELPAcknowledgeBookingDialog'
import RejectBookingDialog from 'schedule/components/RejectBookingDialog'
import PreAlertSidebar from '../components/PreAlertSidebar'
import CSAddSidebar from '../components/CSAddSidebar'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import { Link } from 'react-router-dom'
import { getSettingById } from '../../core/api'

const INITIAL_STATE = {
  csAddSidebarOpen: false,
  carrierEditSidebarOpen: false,
  containerEditSidebarOpen: false,
  exceptionSideBarOpen: false,
  trackingSideBarOpen: false,
  billOfLadinSidebarOpen: false,
  billOfLadinSidebarMode: 'add',
  MBL: [''],
  HBL: [''],
  uploadOpen: false,
  id: '',
  exceptionReasons: [],
  amendBooking: {
    open: false
  },
  documents: [],
  chatDocuments: [],
  eventsTracking: [],
  bookingMode: 'edit',
  activeTracking: null,
  outboundIds: []
}
const DEFAULT_EXCEPTION_REASONS = [
  {
    type: 'spot',
    name: 'Spot',
    reason: 'Spot Booking',
    checked: false
  },
  {
    type: 'accident',
    name: 'Accident',
    reason: 'Accident',
    checked: false
  },
  {
    type: 'changeToAir',
    name: 'Air',
    reason: 'Changed shipment mode to air.',
    checked: false
  },
  {
    type: 'changedPartner',
    name: 'Partner Change',
    reason: 'Changed partners',
    checked: false
  },
  {
    type: 'changedSailingDate',
    name: 'Sail Date Change',
    reason: 'Changed sailing date.',
    checked: false
  },
  {
    type: 'changedToExpress',
    name: 'Express',
    reason: 'Changed to express booking',
    checked: false
  },
  {
    type: 'others',
    name: 'Other',
    reason: 'Others',
    checked: false
  }
]
const ShipmentDetailsPage = props => {
  const {
    getSingleShipment,
    getShipmentEvents,
    getShipmentEventWriteList,
    getShipmentDocuments,
    getShipmentEventsTracking,
    getShipmentMeta,
    deleteShipmentDocument,
    upload,
    match,
    details,
    markAsException,
    loading,
    currentPlant,
    eventWriteList,
    addShipmentEvents,
    editShipment,
    updateBLNumbers,
    updatePreps,
    classes,
    vessels,
    updateCarrierInfo,
    updateShipmentContainers,
    updateEventTime,
    getVessels,
    freightPartnerUpdateBooking,
    uploadShipmentFile,
    getShipmentDerivatives,
    updateShipmentCS,
    initialPrep,
    shipmentCardPrimaryId,
    cancelAndMoveToSpot,
    freightPartnerRejectBooking,
    freightPartnerAcceptBooking,
    logisticsPartnerAcceptBooking,
    logisticsPartnerUpdateBooking,
    cancelShipment,
    addContainer,
    updateSingleContainer,
    showMessage,
    sendPreAlert,
    getPreAlertLogs,
    systemSettings,
    copyToClipboard,
    getPlantEmailTemplates,
    getGoodsReceived,
    getShipmentTopics,
    limitedServiceCards,
    getLimitedServiceCards,
    resolveFpConflicts,
    getPlants,
    updateTopics
  } = props
  const [state, setState] = useMergeState(INITIAL_STATE)
  const [updateShippingDialogVisible, setUpdateShippingDialogVisible] = useState(false)
  const [reviewShippingDialogVisible, setReviewShippingDialogVisible] = useState(false)
  const [rejectBookingDialogVisible, setRejectBookingDialogVisible] = useState(false)
  const [freightPartnerAcceptDialogVisible, setFreightPartnerAcceptDialogVisible] = useState(false)
  const [elpAcknowledgeBookingDialogVisible, setElpAcknowledgeBookingDialogVisible] = useState(false)
  const [updateShippingELPDialogVisible, setUpdateShippingELPDialogVisible] = useState(false)
  const [elpNote, setElpNote] = useState('')
  const [requesting, setRequesting] = useState(false)
  const [falconConfig, setFalconConfig] = useState(null)

  const id = get(match, 'params.id')
  const loadAll = async () => {
    await getSingleShipment(id)
    await getShipmentMeta(id)
    getShipmentEvents(id)
    getShipmentEventWriteList(id)
    getShipmentEventsTracking(id)
    getShipmentDocuments(id)
  }

  const getFalconSettings = async () => {
    const { ok, data } = await getSettingById('falconKeyAPI')
    if (ok) {
      setFalconConfig(get(data, 'data', {}))
    } else {
      setFalconConfig(null)
    }
  }

  useEffect(() => {
    if (id) {
      loadAll()
      getPlants()
      getGoodsReceived(id)
      getShipmentTopics(id)
      getFalconSettings()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (id && get(systemSettings, 'preAlert.enabled')) {
      getPreAlertLogs(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, get(systemSettings, 'preAlert')])

  useEffect(() => {
    if (_.get(currentPlant, 'type') === 'ofp') {
      getVessels()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlant])

  useEffect(() => {
    const documents = get(details, 'documents', []).filter(v => v.documents && v.documents.length > 0)
    setState({ documents })

    const chatDocuments = get(details, 'chatDocuments', [])
    setState({ chatDocuments })

    const eventsTracking = get(details, 'eventsTracking', [])
    setState({ eventsTracking })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details])

  const onExceptionClick = () => {
    setState({
      exceptionSideBarOpen: true,
      exceptionReasons: DEFAULT_EXCEPTION_REASONS
    })
  }

  const onExceptionReasonClick = exceptionReason => {
    setState({
      exceptionReasons: state.exceptionReasons.map(reason => {
        if (exceptionReason.type === reason.type) {
          return { ...reason, checked: !reason.checked }
        }
        return reason
      })
    })
  }

  const onExceptionSubmit = () => {
    // Call api here with exceptionReason.type
    let payload = state.exceptionReasons
      .filter(reason => {
        return reason.checked
      })
      .map(reason => {
        return {
          type: reason.type,
          reason: reason.reason
        }
      })
    markAsException({ details: payload }, id)

    setState({
      exceptionSideBarOpen: false
    })
    history.push(`/exceptions/${details._id}`)
  }

  const onAmendBookingClick = () => {
    setRequesting(true)
    getShipmentDerivatives(
      {
        outboundsDelta: { add: [], remove: [] },
        shipmentId: details._id,
      },
      () => {
        setState({
          amendBooking: {
            open: true
          }
        })
        setRequesting(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const onAmendBookingClose = () => {
    setState({
      amendBooking: {
        open: false
      }
    })
  }

  const onUploadFiles = (...params) => {
    upload(...params)
  }

  const open = (type, status) => () => {
    setState({ [`${type}Open`]: status })
  }

  const closeSideBar = () => setState({ exceptionSideBarOpen: false })
  const sideBarTitle = 'Mark as exception'

  const openBillOfLadinSidebar = mode => {
    setState({
      billOfLadinSidebarOpen: true,
      billOfLadinSidebarMode: mode,
      MBL: (details.referenceNos && details.referenceNos.MBLNo) || [''],
      HBL: (details.referenceNos && details.referenceNos.HBLNo) || ['']
    })
  }

  const closeBillOfLadinSidebar = () => {
    setState({
      billOfLadinSidebarOpen: false
    })
  }

  const openCarrierEditSidebar = () => {
    setState({
      carrierEditSidebarOpen: true
    })
  }

  const closeCarrierEditSidebar = () => {
    setState({
      carrierEditSidebarOpen: false
    })
  }

  const openContainerEditSidebar = () => {
    setState({
      containerEditSidebarOpen: true
    })
  }

  const closeContainerEditSidebar = () => {
    setState({
      containerEditSidebarOpen: false
    })
  }

  const submitBillOfLadin = (HBL, MBL) => {
    const payload = {
      MBLNo: MBL,
      HBLNo: HBL
    }
    updateBLNumbers(payload, details._id)
    setState({
      billOfLadinSidebarOpen: false
    })
  }

  const bookingAmend = (shipment, mode = 'amend', callback = null) => {
    if (get(state, 'cancelAndRestartReason.reason')) {
      const payload = {
        ...shipment,
        reason: get(state, 'cancelAndRestartReason.reason')
      }
      cancelAndMoveToSpot(payload, details._id, onAmendBookingClose)
    } else {
      editShipment(shipment._id, shipment, mode,
        () => {
          onAmendBookingClose()
          if (callback) {
            callback()
          }
        }, callback)
    }
  }

  const bookingUpdate = (shipment, success = null, fail = null) => {
    editShipment(shipment._id, shipment, 'update', success, fail)
  }

  const onTrackingClick = data => () => {
    setState({ activeTracking: data })
    open('trackingSideBar', true)()
  }

  const onFreightPartnerUpdate = (shippingData, containerScheduleData, bookingData, shipmentContext) => {
    const documentCount = _.get(bookingData, 'documentUploads', []).length
    let uploadedDocumentCount = 0

    const payload = {
      referenceNos: {
        bookingReferenceNo: _.get(bookingData, 'bookingDetails.bookingRefNumber', ''),
        carrierBookingNo: _.get(bookingData, 'bookingDetails.carrierBookingNumber', ''),
        HBLNo: _.get(bookingData, 'bookingDetails.hblNumber', '').split(','),
        MBLNo: _.get(bookingData, 'bookingDetails.mblNumber', '').split(',')
      },
      freightOperatorInfo: {
        name: _.get(shippingData, 'carrierName', ''),
        identifier: {
          type: 'SCAC',
          value: _.get(shippingData, 'scacCode', '')
        }
      },
      referenceEvents: {
        documentDeadlineDate: _.get(bookingData, 'siCutOffDate', '')
      },
      vesselSchedule: _.get(shippingData, 'vesselSchedule', []),
      note: _.get(bookingData, 'note', '')
    }

    if (_.get(shipmentContext, 'type') === 'FCL') {
      payload.containers = _.get(containerScheduleData, 'containers', [])
    }

    if (_.get(shipmentContext, 'type') === 'LCL') {
      payload.referenceEvents = {
        ...payload.referenceEvents,
        cargoDropOffAtCFS: {
          expectedAt: _.get(bookingData, 'packageSubmissionSchedule.dateTime', ''),
          location: {
            ..._.get(bookingData, 'packageSubmissionSchedule.packageSubmissionAddress', {}),
            floorUnitNumber: _.get(bookingData, 'packageSubmissionSchedule.additionalAddress', '')
          }
        }
      }
    }

    if (_.get(shippingData, 'hasUnderlyingCarrier')) {
      _.set(payload, 'freightOperatorInfo.name', _.get(shippingData, 'underlyingCarrierName', ''))
      _.set(payload, 'freightOperatorInfo.identifier.value', _.get(shippingData, 'underlyingScacCode', ''))
      _.set(payload, 'freightOperatorInfo.secondaryIdentifier.type', 'SCAC')
      _.set(payload, 'freightOperatorInfo.secondaryIdentifier.value', _.get(shippingData, 'scacCode', ''))
    }

    if (documentCount > 0) {
      _.get(bookingData, 'documentUploads', []).forEach(async doc => {
        setRequesting(true)
        const uploaded = await uploadShipmentFile(shipmentContext._id, doc.type, doc.payload)

        if (uploaded) {
          uploadedDocumentCount++
        }

        if (documentCount === uploadedDocumentCount) {
          freightPartnerUpdateBooking(payload, shipmentContext._id,
            () => {
              setUpdateShippingDialogVisible(false)
              setRequesting(false)
            },
            () => {
              setRequesting(false)
            }
          )
        } else {
          setRequesting(false)
        }
      })
    } else {
      setRequesting(true)
      freightPartnerUpdateBooking(payload, shipmentContext._id,
        () => {
          setUpdateShippingDialogVisible(false)
          setRequesting(false)
        },
        () => {
          setRequesting(false)
        }
      )
    }

  }

  const onFreightPartnerAcceptSubmit = (shippingData, containerScheduleData, bookingData, shipmentContext) => {
    const documentCount = _.get(bookingData, 'documentUploads', []).length
    let uploadedDocumentCount = 0

    const payload = {
      referenceNos: {
        bookingReferenceNo: _.get(bookingData, 'bookingDetails.bookingRefNumber', ''),
        carrierBookingNo: _.get(bookingData, 'bookingDetails.carrierBookingNumber', ''),
        HBLNo: _.get(bookingData, 'bookingDetails.hblNumber', '').split(','),
        MBLNo: _.get(bookingData, 'bookingDetails.mblNumber', '').split(',')
      },
      freightOperatorInfo: {
        name: _.get(shippingData, 'carrierName', ''),
        identifier: {
          type: 'SCAC',
          value: _.get(shippingData, 'scacCode', '')
        }
      },
      referenceEvents: {
        documentDeadlineDate: _.get(bookingData, 'siCutOffDate', '')
      },
      vesselSchedule: _.get(shippingData, 'vesselSchedule', []),
      note: _.get(bookingData, 'note', ''),
      documentDeadlineDate: _.get(bookingData, 'siCutOffDate', '')
    }

    if (_.get(shipmentContext, 'type') === 'FCL') {
      payload.containers = _.get(containerScheduleData, 'containers', [])
    }

    if (_.get(shipmentContext, 'type') === 'LCL') {
      payload.referenceEvents = {
        ...payload.referenceEvents,
        cargoDropOffAtCFS: {
          expectedAt: _.get(bookingData, 'packageSubmissionSchedule.dateTime', ''),
          location: {
            ..._.get(bookingData, 'packageSubmissionSchedule.packageSubmissionAddress', {}),
            floorUnitNumber: _.get(bookingData, 'packageSubmissionSchedule.additionalAddress', '')
          }
        }
      }
    }

    if (_.get(shippingData, 'hasUnderlyingCarrier')) {
      _.set(payload, 'freightOperatorInfo.name', _.get(shippingData, 'underlyingCarrierName', ''))
      _.set(payload, 'freightOperatorInfo.identifier.value', _.get(shippingData, 'underlyingScacCode', ''))
      _.set(payload, 'freightOperatorInfo.secondaryIdentifier.type', 'SCAC')
      _.set(payload, 'freightOperatorInfo.secondaryIdentifier.value', _.get(shippingData, 'scacCode', ''))
    }

    _.get(bookingData, 'documentUploads', []).forEach(async doc => {
      setRequesting(true)
      const uploaded = await uploadShipmentFile(shipmentContext._id, doc.type, doc.payload)

      if (uploaded) {
        uploadedDocumentCount++
      }

      if (documentCount === uploadedDocumentCount) {
        freightPartnerAcceptBooking(payload, shipmentContext._id,
          () => {
            loadAll()
            setFreightPartnerAcceptDialogVisible(false)
            setRequesting(false)
          },
          () => {
            setRequesting(false)
          }
        )
      } else {
        setRequesting(false)
      }
    })
  }

  const onCancelAndMoveToSpotSubmit = (reason, cancelOnly = false) => {
    setRequesting(true)
    cancelShipment({ reason }, details._id,
      () => {
        if (cancelOnly) {
          setState({
            cancelAndRestartReason: {
              open: false
            }
          })
          setRequesting(false)
          history.push('/schedule')
        } else {
          const outboundsDelta = {
            add: get(details, 'outbounds', []).map(outbound => get(outbound, '_id')).filter(Boolean),
            remove: []
          }
          getShipmentDerivatives(
            {
              outboundsDelta,
              shipmentId: details._id
            },
            () => {
              setState({
                amendBooking: {
                  open: true
                },
                cancelAndRestartReason: {
                  open: false,
                  reason
                }
              })
              setRequesting(false)
            },
            () => {
              setRequesting(false)
            }
          )
        }
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const onRejectOpen = () => {
    setRejectBookingDialogVisible(true)
    setReviewShippingDialogVisible(false)
    setUpdateShippingDialogVisible(false)
  }

  const onRejectSubmit = payload => {
    setRequesting(true)
    freightPartnerRejectBooking(payload, details._id,
      () => {
        setRejectBookingDialogVisible(false)
        loadAll()
        setRequesting(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const onFreightPartnerOpen = shipment => {
    setFreightPartnerAcceptDialogVisible(true)
    setRejectBookingDialogVisible(false)
    setReviewShippingDialogVisible(false)
  }

  const onLogisticsPartnerOpen = (shipment, note) => {
    setElpNote(note)
    onElpAcknowledgeOpen()
  }

  const onElpAcknowledgeOpen = () => {
    setReviewShippingDialogVisible(false)
    setElpAcknowledgeBookingDialogVisible(true)
  }

  const onLogisticsPartnerAcceptSubmit = (acknowledgeData, shipment) => {
    setRequesting(true)
    let payload = { note: elpNote }
    if (_.get(shipment, 'type') === 'FCL') {
      payload.containers = _.get(acknowledgeData, 'containers', [])
    }
    if (_.get(shipment, 'type') === 'LCL') {
      payload.referenceEvents = _.get(acknowledgeData, 'referenceEvents', {})
    }
    logisticsPartnerAcceptBooking(shipment._id, payload,
      () => {
        loadAll()
        setElpAcknowledgeBookingDialogVisible(false)
        setRequesting(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }

  const onLogisticsPartnerUpdateSubmit = (acknowledgeData, shipment) => {
    setRequesting(true)
    let payload = { note: elpNote }
    if (_.get(shipment, 'type') === 'FCL') {
      payload.containers = _.get(acknowledgeData, 'containers', [])
    }
    if (_.get(shipment, 'type') === 'LCL') {
      payload.referenceEvents = _.get(acknowledgeData, 'referenceEvents', {})
    }
    logisticsPartnerUpdateBooking(shipment._id, payload,
      () => {
        setUpdateShippingELPDialogVisible(false)
        loadAll()
        setRequesting(false)
      },
      () => {
        setRequesting(false)
      }
    )
  }

  let status = get(details, 'status.current.value', '')
  let stage = get(details, 'stage.current.value', '')
  let transportMethod = get(details, 'transportMethod', 'ocean')
  const isOBL = get(currentPlant, 'type') === 'obl'
  const isOFP = get(currentPlant, 'type') === 'ofp'
  let showReview = false
  let showUpdateOFP = false
  let showUpdateOBL = false
  if (transportMethod === 'ocean') {
    if (isOFP && (status === 'pending' || status === 'amended')) {
      showReview = true
    }
    if (isOFP && status !== 'pending' && status !== 'amended') {
      showUpdateOFP = true
    }
    if (isOBL) {
      //get(currentPlant, 'type') === 'obl' && status !== 'pending' && status !== 'amended'
      // if status history don't contain `obl-confirmed` , but contains ` freight-partner-confirmed` : show Review Button
      //if status history has obl-confirmed and there is no amend / pending after it.Then it should be update button
      let previous = get(details, 'status.previous', [])
      let hasOblConfirmed = false
      let hasFPConfirmed = false
      let history = [...previous, get(details, 'status.current', [])]
      history.forEach(status => {
        if (status.value === `obl-confirmed`) {
          hasOblConfirmed = true
          if (stage !== 'in-transit' && stage !== 'import') {
            showUpdateOBL = true
          }
        }
        if (hasOblConfirmed && (status.value === `pending` || status.value === `amended`)) {
          showUpdateOBL = false
        }
        if (status.value === `freight-partner-confirmed`) {
          hasFPConfirmed = true
        }
      })

      if (hasFPConfirmed && !hasOblConfirmed) {
        showReview = true
      }
    }
  } else if (transportMethod === 'air') {
    if (isOBL && (status === 'pending' || status === 'amended')) {
      showReview = true
    }
    if (isOBL && stage !== 'in-transit' && stage !== 'import') {
      showUpdateOBL = true
    }
    if (isOFP) {
      //get(currentPlant, 'type') === 'obl' && status !== 'pending' && status !== 'amended'
      // if status history don't contain `obl-confirmed` , but contains ` freight-partner-confirmed` : show Review Button
      //if status history has obl-confirmed and there is no amend / pending after it.Then it should be update button
      let previous = get(details, 'status.previous', [])
      let hasOblConfirmed = false
      let hasFPConfirmed = false
      let history = [...previous, get(details, 'status.current', [])]
      history.forEach(status => {
        if (status.value === `obl-confirmed`) {
          hasOblConfirmed = true
        }
        if (hasOblConfirmed && (status.value === `pending` || status.value === `amended`)) {
          showUpdateOBL = false
        }
        if (status.value === `freight-partner-confirmed`) {
          hasFPConfirmed = true
        }
      })

      if (hasOblConfirmed && !hasFPConfirmed) {
        showReview = true
      }
    }
  }
  return (
    <>
      {loading && <ShipmentDetailsSkeleton />}
      {!loading && (
        <SubToolBarLayout withBack doubleRightContent
          text={shipmentNameBeautifier(get(details, shipmentCardPrimaryId) || get(details, 'name', '') || get(details, '_id', ''))}>
          <ShipmentDeltaInfo details={details}
            iconClassName="text-28 fg-gray cursor-pointer mr-20" />

          {get(details, 'spotMode.enabled', false) && (
            <Grid item className='mr-24 float-left'>
              <Grid container alignItems='flex-start'>
                <img
                  src='assets/images/icons/shipments-list/Exception-Orange@3x.svg'
                  alt='spot'
                  className='mr-6'
                />
                <Typography className='uppercase medium text-12'>
                  <span className={classes.spot}>SPOT</span>
                </Typography>
              </Grid>
            </Grid>
          )}
          {get(details, 'specialLabels', []).length > 0 && (
            <Grid item className='mr-24 float-left'>
              <Grid container alignItems='flex-start'>
                <img
                  src='assets/images/icons/shipments-list/special-label.svg'
                  alt='special-label'
                  className='mr-6'
                />
                <Typography className='uppercase medium text-12'>
                  {get(details, 'specialLabels', []).join(', ')}
                </Typography>
              </Grid>
            </Grid>
          )}
          {/* @todo: Remove this check in future */}
          {(get(currentPlant, 'type') === 'shipper' &&
            get(details, 'goodsType') === 'DG') && (
              <Grid item className='mr-24 float-left'>
                <Grid container alignItems='flex-start'>
                  <img
                    width='20'
                    src='assets/images/icons/booking_history/exception.svg'
                    alt='special-label'
                    className='mr-6'
                  />
                  <Typography className='uppercase medium text-12 text-red'>
                    DG
                  </Typography>
                </Grid>
              </Grid>
            )
          }

          <Grid item className="mr-24">
            <Link to={`/tasks?shipmentId=${get(details, '_id')}`}>
              <Grid container alignItems="center"
                className="bg-transparent-green fg-green px-8 py-4 rounded">
                <PlaylistAddCheckIcon className="fg-green text-14 mr-4" />
                <Typography className="fg-green text-10 uppercase bold">tasks</Typography>
              </Grid>
            </Link>
          </Grid>

          {!get(details, 'exceptionMode.enabled') &&
            get(currentPlant, 'type') === 'shipper' &&
            get(details, 'stage.current.value') === 'scheduled' && (
              <Button className='btn btn_sm btn_light_orange' onClick={() => onExceptionClick()}>
                <label className='text-12 cursor-pointer'>Raise Exception</label>
              </Button>
            )}
          {(get(currentPlant, 'type') === 'ofp' || get(currentPlant, 'type') === 'shipper') &&
            get(details, 'referenceNos.HBLNo', []).length === 0 &&
            get(details, 'referenceNos.MBLNo', []).length === 0 && (
              <Button className='btn btn_sm btn_light_green ml-12' onClick={() => openBillOfLadinSidebar('add')}>
                <label className='text-12 cursor-pointer'>Add BL Number</label>
              </Button>
            )}
          {showUpdateOFP && (
            <Button className='btn btn_sm btn_light_green ml-12' onClick={() => setUpdateShippingDialogVisible(true)}>
              <label className='text-12 cursor-pointer'>Update Shipment</label>
            </Button>
          )}
          {showUpdateOBL && (
            <Button className='btn btn_sm btn_light_green ml-12' onClick={() => setUpdateShippingELPDialogVisible(true)}>
              <label className='text-12 cursor-pointer'>Update Shipment</label>
            </Button>
          )}
          {showReview && (
            <Button className='btn btn_sm btn_light_green ml-12' onClick={() => setReviewShippingDialogVisible(true)}>
              <label className='text-12 cursor-pointer'>Review Shipment</label>
            </Button>
          )}
          {get(currentPlant, 'type') === 'shipper' && get(details, 'stage.current.value') === 'scheduled' && (
            <>
              <LoadingButton
                className='btn btn_sm btn_light_green ml-12'
                loading={requesting}
                style={{
                  background: 'rgba(61, 205, 88, 0.2)',
                  color: '#3DCD58',
                  minWidth: '132px'
                }}
                onClick={() => onAmendBookingClick()}>
                <label className='text-12 cursor-pointer'>Change Booking</label>
              </LoadingButton>
              <Button
                className='btn btn_sm btn_light_red ml-12'
                onClick={() =>
                  setState({
                    cancelAndRestartReason: {
                      open: true
                    }
                  })
                }>
                <label className='text-12 cursor-pointer'>Cancel & Restart</label>
              </Button>
            </>
          )}
          {(get(details, 'stage.current.value') === 'in-transit' || get(details, 'stage.current.value') === 'import') && get(details, 'summary.tracking.trackingLink') && (
            <Button className='btn btn_sm ml-12'
              onClick={() => window.open(get(details, 'summary.tracking.trackingLink', ''), '_blank')}>
              <label className='text-12 cursor-pointer'>Track</label>
            </Button>
          )}
        </SubToolBarLayout>
      )}

      {!loading && (
        <Grid container className='shipment-details h-full w-full px-20'>
          <Grid item xs={12} sm={12} md={12}>
            <TabContent shipment={details}
              showMessage={showMessage}
              urlTabValue={get(queryString.parse(get(props, 'location.search', '?tab=0')), 'tab', 0)}
              urlTopicId={get(queryString.parse(get(props, 'location.search', '')), 'topicId', '')}
              documents={state.documents}
              chatDocuments={state.chatDocuments}
              onUploadClick={open('upload', true)}
              eventWriteList={eventWriteList}
              addShipmentEvents={addShipmentEvents}
              eventsTracking={state.eventsTracking}
              activeTracking={state.activeTracking}
              onBLClick={() => openBillOfLadinSidebar('edit')}
              onSingleFileDelete={deleteShipmentDocument}
              onTrackingClick={onTrackingClick}
              currentPlant={currentPlant}
              onCarrierClick={openCarrierEditSidebar}
              onContainerClick={openContainerEditSidebar}
              updateEventTime={updateEventTime}
              updatePreps={updatePreps}
              updateShipmentContainers={updateShipmentContainers}
              updateShipmentCS={updateShipmentCS}
              addContainer={addContainer}
              updateSingleContainer={updateSingleContainer}
              preAlertLogs={props.preAlertLogs}
              onBookingChange={bookingUpdate}
              onCSAdd={open('csAddSidebar', true)}
              systemSettings={systemSettings}
              sendPreAlert={sendPreAlert}
              copyToClipboard={copyToClipboard}
              goodsReceived={props.goodsReceived}
              topics={props.topics}
              getShipmentTopics={props.getShipmentTopics}
              getShipmentTopicDetails={props.getShipmentTopicDetails}
              addShipmentTopic={props.addShipmentTopic}
              addShipmentTopicsComment={props.addShipmentTopicsComment}
              openShipmentTopics={props.openShipmentTopics}
              closeShipmentTopics={props.closeShipmentTopics}
              userInfo={props.info}
              plantEmailTemplates={get(props, 'plantEmailTemplates', [])}
              getPlantEmailTemplates={getPlantEmailTemplates}
              updateTopics={updateTopics}
              plants={get(props, 'plants', [])}
              getShipmentDocuments={getShipmentDocuments}
              falconConfig={falconConfig} />
          </Grid>


          <FuseSideBar open={state.exceptionSideBarOpen}>
            <FuseSideBarHeader text={sideBarTitle} handleClose={closeSideBar} />
            <FuseSideBarBody>
              <Box component='span'>
                <Typography color='inherit' className='exception-sidebar-header'>
                  Select the type of exception from the list.
                </Typography>
              </Box>
              <Grid container>
                <Grid md={12} item>
                  {state.exceptionReasons &&
                    state.exceptionReasons.map(types => (
                      <ExceptionReasonSelectionRow key={types.name} data={types} onClick={onExceptionReasonClick} />
                    ))}
                </Grid>
              </Grid>
            </FuseSideBarBody>
            <FuseSideBarActions>
              <Button className='btn btn_full-space mark-exception-button' onClick={onExceptionSubmit}>
                MARK THIS SHIPMENT AS EXCEPTION
              </Button>
            </FuseSideBarActions>
          </FuseSideBar>
        </Grid>
      )}

      <CancelAndRestartReasonDialog
        shipment={details}
        open={get(state, 'cancelAndRestartReason.open', false)}
        handleClose={() =>
          setState({
            cancelAndRestartReason: {
              open: false
            }
          })
        }
        onSubmit={onCancelAndMoveToSpotSubmit}
        requesting={requesting}
      />

      <NewBoookingDialog
        open={state.amendBooking.open}
        data={initialPrep}
        handleClose={onAmendBookingClose}
        mode={get(state, 'cancelAndRestartReason.reason') ? 'create' : 'edit'}
        outboundIds={
          get(state, 'cancelAndRestartReason.reason')
            ? get(details, 'outbounds', []).map(outbound => get(outbound, '_id'))
            : []
        }
        shipmentOutboundIds={[]}
        selectedShipment={get(props, 'details')}
        newShipmentObject={pick(details, ['type', 'containers', 'groupageDate'])}
        selectedTransportMethod={get(details, 'transportMethod', '')}
        onSubmit={bookingAmend}
      />

      <UploadDialog
        upload={onUploadFiles}
        open={state.uploadOpen}
        shipmentId={details._id}
        rbac={details.rbac}
        handleClose={open('upload', false)}
        currentPlant={currentPlant}
        getShipmentDocuments={id => getShipmentDocuments(id)}
      />

      <BillLadinSidebar
        open={state.billOfLadinSidebarOpen}
        closeSideBar={() => closeBillOfLadinSidebar()}
        onSubmit={submitBillOfLadin}
        mode={state.billOfLadinSidebarMode}
        existingMBL={state.MBL}
        existingHBL={state.HBL}
        currentPlant={currentPlant}
        details={details}
      />

      <CarrierEditSidebar
        open={state.carrierEditSidebarOpen}
        closeSideBar={() => closeCarrierEditSidebar()}
        carrierInfo={get(details, 'freightOperatorInfo', {})}
        currentPlant={currentPlant}
        shipment={details}
        onSubmit={payload => {
          updateCarrierInfo(payload, details._id, closeCarrierEditSidebar)
        }}
      />

      <ContainerEditSidebar
        open={state.containerEditSidebarOpen}
        closeSideBar={() => closeContainerEditSidebar()}
        availableContainers={get(details, 'containers', [])}
        currentPlant={currentPlant}
        onSubmit={payload => {
          updateShipmentContainers(payload, details._id, closeContainerEditSidebar)
        }}
      />

      <EventTrackingSidebar
        open={state.trackingSideBarOpen}
        onClose={open('trackingSideBar', false)}
        event={state.activeTracking}
      />

      <ReviewBookingDialog
        open={reviewShippingDialogVisible}
        handleClose={() => setReviewShippingDialogVisible(false)}
        shipment={details}
        currentPlant={currentPlant}
        onReject={onRejectOpen}
        onFreightPartnerAccept={onFreightPartnerOpen}
        onLogisticsPartnerAccept={onLogisticsPartnerOpen}
        getLimitedServiceCards={getLimitedServiceCards}
        limitedServiceCards={limitedServiceCards}
        resolveFpConflicts={resolveFpConflicts}
        resolveConflictCallback={loadAll}
      />

      <RejectBookingDialog
        open={rejectBookingDialogVisible}
        shipment={details}
        handleClose={() => setRejectBookingDialogVisible(false)}
        currentPlant={currentPlant}
        onSubmit={onRejectSubmit}
        onReviewBooking={() => {
          setRejectBookingDialogVisible(false)
          setReviewShippingDialogVisible(true)
        }}
        requesting={requesting}
      />

      <FPAcceptBookingDialog
        open={freightPartnerAcceptDialogVisible}
        shipment={details}
        handleClose={() => setFreightPartnerAcceptDialogVisible(false)}
        vessels={vessels}
        currentPlant={currentPlant}
        onSubmit={onFreightPartnerAcceptSubmit}
        requesting={requesting}
        getVessels={getVessels}
      />

      <FPAcceptBookingDialog
        open={updateShippingDialogVisible}
        shipment={details}
        handleClose={() => setUpdateShippingDialogVisible(false)}
        vessels={vessels}
        currentPlant={currentPlant}
        editShipping={true}
        documents={state.documents}
        onSubmit={onFreightPartnerUpdate}
        requesting={requesting}
        getVessels={getVessels}
      />

      <ELPAcknowledgeBookingDialog
        open={updateShippingELPDialogVisible}
        shipment={details}
        handleClose={() => {
          setUpdateShippingELPDialogVisible(false)
        }}
        currentPlant={currentPlant}
        onSubmit={onLogisticsPartnerUpdateSubmit}
        requesting={requesting}
      />

      <ELPAcknowledgeBookingDialog
        open={elpAcknowledgeBookingDialogVisible}
        shipment={details}
        handleClose={() => {
          setElpAcknowledgeBookingDialogVisible(false)
        }}
        currentPlant={currentPlant}
        onSubmit={onLogisticsPartnerAcceptSubmit}
        requesting={requesting}
      />

      <CSAddSidebar open={state.csAddSidebarOpen}
        shipment={details}
        handleClose={open('csAddSidebar', false)}
        onSubmit={props.addConsolidation}
      />

      {(get(currentPlant, 'type') === 'shipper' &&
        get(systemSettings, 'preAlert.enabled')) &&
        <div className={classes.preAlertBtnWrapper}>
          <PreAlertSidebar shipment={details}
            currentPlant={currentPlant}
            onSubmit={sendPreAlert}
            plantEmailTemplates={get(props, 'plantEmailTemplates', [])}
            getPlantEmailTemplates={getPlantEmailTemplates} />
        </div>
      }
    </>
  )
}

const styles = theme => ({
  preAlertBtnWrapper: {
    position: 'fixed',
    right: 150,
    bottom: 20
  },
  spot: {
    color: '#E47F00',
    fontSize: 11,
    fontWeight: 900,
    paddingTop: 4
  }
})

const mapStateToProps = ({
  shipmentDetails: { loading, events, eventWriteList, updatePreps, preAlertLogs, goodsReceived, topics, ...details },
  booking: { initialPrep },
  schedule: { vessels, limitedServiceCards },
  welcome: { currentPlant },
  auth: {
    info,
    info: { networkAccess }
  },
  core: {
    userSettings: { shipmentCardPrimaryId },
    systemSettings,
    plants
  },
  settingsReducer
}) => ({
  initialPrep,
  vessels,
  limitedServiceCards,
  details,
  updatePreps,
  loading,
  events,
  eventWriteList,
  currentPlant,
  info,
  networkAccess,
  shipmentCardPrimaryId,
  preAlertLogs,
  systemSettings,
  plantEmailTemplates: get(settingsReducer, 'plantEmailTemplates.data', []),
  goodsReceived,
  topics,
  plants
})
const mapDispatchToProps = dispatch => ({
  getSingleShipment: shipmentId => dispatch(ShipmentActions.getSingleShipment(shipmentId)),
  markAsException: (payload, shipmentId) => dispatch(ShipmentActions.markShipmentAsException(payload, shipmentId)),
  getShipmentDocuments: (...args) => dispatch(ShipmentActions.getShipmentDocuments(...args)),
  getShipmentEvents: shipmentId => dispatch(ShipmentActions.getShipmentEvents(shipmentId)),
  getShipmentEventWriteList: shipmentId => dispatch(ShipmentActions.getShipmentEventWriteList(shipmentId)),
  addShipmentEvents: (shipmentId, eventCode, eventType, payload, callback) =>
    dispatch(ShipmentActions.addShipmentEvents(shipmentId, eventCode, eventType, payload, callback)),
  getShipmentEventsTracking: shipmentId => dispatch(ShipmentActions.getShipmentEvents(shipmentId)),
  upload: (...params) => dispatch(CoreActions.uploadFile(...params)),
  getShipmentMeta: shipmentId => dispatch(ShipmentActions.getShipmentMeta(shipmentId)),
  editShipment: (...args) => dispatch(ShipmentActions.editShipment(...args)),
  updateBLNumbers: (payload, shipmentId) => dispatch(ShipmentActions.updateBLNumbers(payload, shipmentId)),
  updateCarrierInfo: (...args) => dispatch(ShipmentActions.updateCarrierInfo(...args)),
  updateShipmentContainers: (...args) => dispatch(ShipmentActions.updateShipmentContainers(...args)),
  deleteShipmentDocument: (shipmentId, fileId) => dispatch(ShipmentActions.deleteShipmentDocument(shipmentId, fileId)),
  getUpdatePreps: (shipmentId, callback) => dispatch(ShipmentActions.getUpdatePreps(shipmentId, callback)),
  updateEventTime: (...args) => dispatch(ShipmentActions.updateEventTime(...args)),
  getVessels: (...args) => dispatch(ScheduleActions.getVessels(...args)),
  freightPartnerUpdateBooking: (...args) => dispatch(ScheduleActions.freightPartnerUpdateBooking(...args)),
  freightPartnerAcceptBooking: (...args) => dispatch(ScheduleActions.freightPartnerAcceptBooking(...args)),
  freightPartnerRejectBooking: (...args) => dispatch(ScheduleActions.freightPartnerRejectBooking(...args)),
  logisticsPartnerAcceptBooking: (...args) => dispatch(ScheduleActions.logisticsPartnerAcceptBooking(...args)),
  logisticsPartnerUpdateBooking: (...args) => dispatch(ScheduleActions.logisticsPartnerUpdateBooking(...args)),
  uploadShipmentFile: (shipmentId, type, payload) => dispatch(ScheduleActions.uploadShipmentFile(payload, shipmentId, type)),
  getInitialPreps: (ids, callback) => dispatch(BookingActions.getInitialPreps(ids, callback)),
  getShipmentDerivatives: (...args) => dispatch(BookingActions.getShipmentDerivatives(...args)),
  updateShipmentCS: (...args) => dispatch(ShipmentActions.updateShipmentCS(...args)),
  cancelShipment: (...args) => dispatch(ShipmentActions.cancelShipment(...args)),
  cancelAndMoveToSpot: (...args) => dispatch(ShipmentActions.cancelAndMoveToSpot(...args)),
  addContainer: (...args) => dispatch(ShipmentActions.addShipmentContainers(...args)),
  updateSingleContainer: (...args) => dispatch(ShipmentActions.updateShipmentSingleContainers(...args)),
  showMessage: (...args) => dispatch(CoreActions.showMessage(...args)),
  sendPreAlert: (...args) => dispatch(ShipmentActions.sendPreAlert(...args)),
  getPreAlertLogs: (...args) => dispatch(ShipmentActions.getPreAlertLogs(...args)),
  addConsolidation: (...args) => dispatch(ShipmentActions.addConsolidation(...args)),
  copyToClipboard: (...args) => dispatch(CoreActions.copyToClipboard(...args)),
  getPlantEmailTemplates: (...args) => dispatch(SettingsActions.getPlantEmailTemplates(...args)),
  getGoodsReceived: (...args) => dispatch(ShipmentActions.getGoodsReceived(...args)),
  getShipmentTopics: (...args) => dispatch(ShipmentActions.getShipmentTopics(...args)),
  getShipmentTopicDetails: (...args) => dispatch(ShipmentActions.getShipmentTopicDetails(...args)),
  addShipmentTopic: (...args) => dispatch(ShipmentActions.addShipmentTopic(...args)),
  addShipmentTopicsComment: (...args) => dispatch(ShipmentActions.addShipmentTopicsComment(...args)),
  openShipmentTopics: (...args) => dispatch(ShipmentActions.openShipmentTopics(...args)),
  updateTopics: (...args) => dispatch(ShipmentActions.updateTopics(...args)),
  closeShipmentTopics: (...args) => dispatch(ShipmentActions.closeShipmentTopics(...args)),
  getLimitedServiceCards: (...args) => dispatch(ScheduleActions.getLimitedServiceCards(...args)),
  resolveFpConflicts: (...args) => dispatch(ScheduleActions.resolveFpConflicts(...args)),
  getPlants: (...args) => dispatch(CoreActions.getPlants(...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShipmentDetailsPage))
