import React from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import GooglePlaceSearch from '../Inputs/GooglePlaceSearch';
import DateTime from 'react-datetime';

const InputTimeline = ({ data, onChange, onDateChange, ...other }) => (
  <Grid container direction='row' className={'field-control'}>
    <Grid item sm={12} md={6} className='flex items-center'>
      <label className={'field-label'}>{data.label}</label>
    </Grid>
    <Grid item sm={12} md={6} className='flex items-center justify-end'>
      <label className={'field-instruction'}>{data.instruction}</label>
    </Grid>
    {data.items && (
      <Grid container direction='row' className='stepper'>
        {data.items.map((v, i) => {
          if (v.hide) return null

          switch (data.type) {
            case 'route':
              return (
                <Grid key={i} container direction='row' className='step'>
                  <Grid container direction='row' className='step-header'>
                    <span className='step-icon'>
                      <img src={v.img} alt='shipper' />
                    </span>
                    <Grid item sm={12} md={6} className='flex items-center'>
                      <label className='field-sub-label'>{v.label}</label>
                    </Grid>
                    <Grid item sm={12} md={6} className='flex items-center justify-end'>
                      <label className='field-instruction'>{v.instruction}</label>
                    </Grid>
                  </Grid>

                  <Grid container direction='row' className='step-content'>
                    <Grid item sm={12} md={8} className='pr-16'>
                      <TextField
                        className='theme-text-field'
                        name={v.text1Name}
                        value={v.text1Value || ''}
                        label={v.text1Label}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={onChange}
                      />
                    </Grid>
                    <Grid item sm={12} md={4}>
                      <TextField
                        className='theme-text-field'
                        name={v.text2Name}
                        value={v.text2Value || ''}
                        label={v.text2Label}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={onChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )

            case 'booking':
              return (
                <Grid key={i} container direction='row' className='step'>
                  <Grid container direction='row' className='step-header'>
                    <span className='step-icon'>
                      <img src={v.img} alt='booking' />
                    </span>
                    <Grid item sm={12} md={6} className='flex items-center'>
                      <label className='field-sub-label'>{v.label}</label>
                    </Grid>
                    <Grid item sm={12} md={6} className='flex items-center justify-end'>
                      <label className='field-instruction'>{v.instruction}</label>
                    </Grid>
                  </Grid>

                  <Grid container direction='row' className='step-content'>
                    <Grid item sm={12} md={12} className='pb-16'>
                      <label className='formsy-form-control__label require-field'>
                        {v.text1Label}
                      </label>
                      <GooglePlaceSearch placeholder="Select street address..."
                        className="theme-text-field"
                        value={v.text1Value}
                        updateCall={v.text1UpdateAddress}
                        savedPlaceType="truck-in" />
                    </Grid>

                    <Grid item sm={12} md={7} className='pr-16'>
                      <TextField className='theme-text-field'
                        name={v.text2Name}
                        value={v.text2Value || ''}
                        label={v.text2Label}
                        fullWidth={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={onChange(v.section)}
                      />
                    </Grid>

                    <Grid item sm={12} md={5} className='flex items-end'>
                      <Grid container>
                        <Grid item xs={12}>
                          <label className='formsy-form-control__label require-field'>
                            {v.text3Label}
                          </label>
                        </Grid>
                        <Grid item xs={12} className="relative">
                          <DateTime className="theme-date-time-picker-field"
                            input
                            dateFormat="DD MMM YYYY,"
                            onChange={(date) => {
                              if (new Date(date).toString() === 'Invalid Date') {
                                date = new Date()
                              }
                              onDateChange(date, v.text3Name, v.section)
                            }}
                            value={v.text3Value && new Date(v.text3Value)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )

            case 'vessel':
              return (
                <Grid key={i} container direction='row' className='step'>
                  <Grid container direction='row' className='step-header'>
                    <span className='step-icon'>
                      <img src={v.img} alt='booking' />
                    </span>
                    {
                      v.button &&
                      <Button className={'btn ' + v.button.className}
                        onClick={v.button.onClick}>{v.button.text}</Button>
                    }
                    <Grid item sm={12} md={6} className='flex items-center'>
                      <label className='field-sub-label'>{v.label}</label>
                    </Grid>
                    <Grid item sm={12} md={6} className='flex items-center justify-end'>
                      {v.instruction && <label className='field-instruction'>{v.actionName}</label>}
                      {v.onActionClick && (
                        <span className='field-action' onClick={v.onActionClick}>
                          {v.actionName}
                        </span>
                      )}
                    </Grid>
                  </Grid>

                  {v.subHeader &&
                    <div className='flex items-center text-11 text-grey-dark'>
                      <label className='mr-8'>{v.subHeader.label1}:</label><span>{v.subHeader.value1}</span>
                      <label className='ml-16 mr-8'>{v.subHeader.label2}:</label><span>{v.subHeader.value2}</span>
                    </div>
                  }

                  <Grid container direction='row' className='step-content'>
                    {v.text1 && v.text2 &&
                      <>
                        <Grid item sm={12} md={4} className='pb-16 pr-6'>
                          <TextField
                            {...v.text1}
                            className='theme-text-field'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange(v.section)}
                          />
                        </Grid>

                        <Grid item sm={12} md={8} className='pb-16 pl-6'>
                          <TextField
                            {...v.text2}
                            className='theme-text-field'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange(v.section)}
                          />
                        </Grid>
                      </>
                    }
                    {v.text3 && v.text4 && v.text5 && v.text6 && v.text7 &&
                      <>
                        <Grid item sm={12} md={8} className='pb-16 pr-6'>
                          <TextField {...v.text3}
                            className='theme-text-field'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange(v.section)}
                          />
                        </Grid>

                        <Grid item sm={12} md={4} className='pb-16 pl-6'>
                          <TextField {...v.text4}
                            className='theme-text-field'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange(v.section)}
                          />
                        </Grid>

                        <Grid item sm={12} md={4} className='pb-16 pr-6'>
                          <TextField {...v.text5}
                            className='theme-text-field'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange(v.section)}
                          />
                        </Grid>

                        <Grid item sm={12} md={4} className='pb-16 pl-6 pr-6'>
                          <TextField {...v.text6}
                            className='theme-text-field'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange(v.section)}
                          />
                        </Grid>

                        <Grid item sm={12} md={4} className='pb-16 pl-6'>
                          <TextField {...v.text7}
                            className='theme-text-field'
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={onChange(v.section)}
                          />
                        </Grid>
                      </>
                    }
                  </Grid>
                </Grid>
              )

            default:
              return null
          }
        })}
      </Grid>
    )}
  </Grid>
)

export default InputTimeline
