import { get } from 'lodash'
import * as CoreActions from '../../core/store/actions'
import { checkPagination, addGaEvent } from '../../core/helpers'
import ActionTypes from './types'
import * as API from 'core/api'

const defaultStatus = [
  'pending',
  'freight-partner-confirmed',
  'obl-confirmed',
  'freight-partner-rejected',
  'obl-rejected',
  'cancelled',
  'frozen',
  'amended'
]
const DEFAULT_SHIPMENT_PAGE_SIZE = 10

export const apiAttempt = () => ({ type: ActionTypes.API_ATTEMPT })
export const apiSuccess = () => ({ type: ActionTypes.API_SUCCESS })
export const apiFailed = payload => ({ type: ActionTypes.API_FAILED, payload })
export const getInitialPrepSuccess = payload => ({ type: ActionTypes.GET_INITIAL_PREP_SUCCESS, payload })
export const createShipmentSuccess = payload => ({ type: ActionTypes.CREATE_SHIPMENT_SUCCESS, payload })

export const outboundLoading = (value, type) => {
  switch (type) {
    case 'ocean':
      return { type: ActionTypes.SET_OUTBOUND_LOADING, value }
    case 'air':
      return { type: ActionTypes.SET_AIR_OUTBOUND_LOADING, value }
    case 'CBT':
      return { type: ActionTypes.SET_CBT_OUTBOUND_LOADING, value }
    case 'rail':
      return { type: ActionTypes.SET_RAIL_OUTBOUND_LOADING, value }

    default:
      return { type: ActionTypes.SET_OTHER_OUTBOUND_LOADING, value }
  }
}
export const outboundLoadingMore = (value, type) => {
  switch (type) {
    case 'ocean':
      return { type: ActionTypes.SET_OUTBOUND_LOADING_MORE, value }
    case 'air':
      return { type: ActionTypes.SET_AIR_OUTBOUND_LOADING_MORE, value }
    case 'CBT':
      return { type: ActionTypes.SET_CBT_OUTBOUND_LOADING_MORE, value }
    case 'rail':
      return { type: ActionTypes.SET_RAIL_OUTBOUND_LOADING_MORE, value }

    default:
      return { type: ActionTypes.SET_OTHER_OUTBOUND_LOADING_MORE, value }
  }
}
export const getOutboundsSuccess = (type, payload) => {
  switch (type) {
    case 'ocean':
      return { type: ActionTypes.GET_OUTBOUNDS_SUCCESS, payload }
    case 'air':
      return { type: ActionTypes.GET_AIR_OUTBOUNDS_SUCCESS, payload }
    case 'CBT':
      return { type: ActionTypes.GET_CBT_OUTBOUNDS_SUCCESS, payload }
    case 'rail':
      return { type: ActionTypes.GET_RAIL_OUTBOUNDS_SUCCESS, payload }

    default:
      return { type: ActionTypes.GET_OTHER_OUTBOUNDS_SUCCESS, payload }
  }
}

export const shipmentsLoading = (value, type) => {
  switch (type) {
    case 'air':
      return { type: ActionTypes.SET_AIR_MANAGE_SHIPMENTS_LOADING, value }
    default:
      return ({ type: ActionTypes.SET_MANAGE_SHIPMENTS_LOADING, value })
  }
}
export const shipmentsLoadingMore = (value, type) => {
  switch (type) {
    case 'air':
      return { type: ActionTypes.SET_AIR_MANAGE_SHIPMENTS_LOADING_MORE, value }
    default:
      return ({ type: ActionTypes.SET_MANAGE_SHIPMENTS_LOADING_MORE, value })
  }
}
export const getAllManageShipmentSuccess = (payload, type) => {
  switch (type) {
    case 'air':
      return ({
        type: ActionTypes.GET_AIR_MANAGE_SHIPMENT_SUCCESS,
        payload
      })
    default:
      return ({
        type: ActionTypes.GET_ALL_MANAGE_SHIPMENT_SUCCESS,
        payload
      })
  }
}

const DEFAULT_PAGE_SIZE = 5
function formatDate(d) {
  d = new Date(d)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  let year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}

export const getOutbounds = (pagination, search, date, csCodes = null, shipToParty = null, transportMethod = 'ocean') => async dispatch => {
  try {
    if (get(pagination, 'pageNo', 1) > 1) {
      dispatch(outboundLoadingMore(true, transportMethod))
    } else {
      dispatch(apiAttempt())
      dispatch(outboundLoading(true, transportMethod))
    }

    let params = { transportMethod }
    if (pagination) {
      pagination.pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE
      params = { ...params, ...pagination }

      if (pagination.pageNo === 1) {
        dispatch(getOutboundsSuccess(transportMethod, { reset: true, data: [], loading: true }))
      }
    }
    if (search) {
      params['multi-search'] = search
    }
    if (date) {
      params['groupageDate'] = `${formatDate(date)}T01:00:00.000Z`
    }
    if (csCodes) {
      params['csCodes'] = csCodes
    }
    if (shipToParty) {
      params['shipToParty'] = shipToParty
    }

    let { ok, data } = await API.getOpenOutbounds(params)
    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    dispatch(getOutboundsSuccess(transportMethod, data))
    dispatch(outboundLoading(false, transportMethod))
    dispatch(outboundLoadingMore(false, transportMethod))
  } catch (error) {
    console.error(error)
    dispatch(apiFailed(error.message))
    dispatch(outboundLoading(false, transportMethod))
    dispatch(outboundLoadingMore(false, transportMethod))
  }
}

export const getOutboundsByShipment = shipmentId => async dispatch => {
  try {
    const { ok, data } = await API.getOutboundsbyShipment(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    return data.data;
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const getInitialPreps = (payload, callback = null) => async dispatch => {
  try {
    dispatch(apiAttempt())

    let { ok, data } = await API.getInitialPreps(payload)
    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getInitialPrepSuccess(get(data, 'data')))
    if (callback) {
      callback()
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}

export const getUpdatePreps = (payload, callback = null) => async dispatch => {
  try {
    dispatch(apiAttempt())

    let { ok, data } = await API.getUpdatePreps(payload)
    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getInitialPrepSuccess(get(data, 'data')))
    if (callback) {
      callback()
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
  }
}

export const createShipment = (payload, success = null, fail = null) => async dispatch => {
  try {
    dispatch(apiAttempt())

    let { ok, status, data } = await API.createShipment(payload)
    if (!ok) {
      addGaEvent('shipment-createFailed')
      throw new Error(data.message)
    }

    addGaEvent('shipment-created', get(data, 'data._id'))

    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
    if (fail) {
      fail()
    }
  }
}

export const closeOutbounds = (payload, callback) => async dispatch => {
  try {
    dispatch(apiAttempt())
    addGaEvent('outbound-closing')

    let { ok, status, data } = await API.closeOutbounds(payload)
    if (!ok) {
      addGaEvent('outbound-closeFailed')
      throw new Error(data.message)
    }

    addGaEvent('outbound-closed')

    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))
    if (callback) {
      callback()
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
    if (callback) {
      callback()
    }
  }
}

export const editShipment = (id, payload, mode = 'update', success = null, fail = null) => async dispatch => {
  try {
    dispatch(apiAttempt())
    var request
    if (mode === 'amend') {
      request = await API.amendShipment(id, payload)
    } else {
      request = await API.updateShipment(id, payload)
    }

    let { ok, status, data } = request

    if (!ok) {
      addGaEvent(`shipment-${mode}Failed`, id)
      throw new Error(data.message)
    }

    addGaEvent(`shipment-${mode === 'amend' ? 'amended' : 'updated'}`, id)

    dispatch(apiSuccess())
    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    dispatch(apiFailed(error.message))
    if (fail) {
      fail()
    }
  }
}

export const getShipmentDerivatives = (payload, success = null, fail = null) => async dispatch => {
  try {
    let { ok, data } = await API.getShipmentBookingOptions(payload)
    if (!ok) {
      if (data.code === 'TM062') {
        addGaEvent('shipment-removeFromFailed')
      }
      throw new Error(data.message)
    }

    dispatch(getInitialPrepSuccess(get(data, 'data')))
    if (success) {
      success()
    }
  } catch (error) {
    dispatch(CoreActions.showMessage(error))
    if (fail) {
      fail()
    }
  }
}

export const getAllShipments = (status = defaultStatus, populatePaths, customFilter, pagination, search = null, sort, isSync = false, transportMethod = 'ocean') => async (dispatch) => {
  console.log(transportMethod)
  try {
    if (get(pagination, 'pageNo', 1) > 1) {
      dispatch(shipmentsLoadingMore(true, transportMethod))
    } else {
      if (!isSync) {
        dispatch(shipmentsLoading(true, transportMethod))
      }
    }

    let params = { stage: ['scheduled'], status, transportMethod }
    let payload = {}
    if (populatePaths) {
      params.populatePaths = populatePaths
    }
    if (search) {
      params = { ...params, 'multi-search': search }
    }
    if (sort) {
      params = { ...params, ...sort }
    }
    if (customFilter) {
      payload = { ...customFilter, stage: ['scheduled'] }
      delete payload.name
    }
    if (pagination) {
      pagination.pageSize = pagination.pageSize || DEFAULT_SHIPMENT_PAGE_SIZE
      params = { ...params, ...pagination }
      if (get(pagination, 'pageNo', 1) === 1 && !isSync) {
        dispatch(getAllManageShipmentSuccess({ reset: true, data: [], loading: true }, transportMethod))
      }
    }

    const { ok, data } = await API.getAllShipmentsWithFilter(params, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    dispatch(getAllManageShipmentSuccess(data, transportMethod))
    dispatch(shipmentsLoading(false, transportMethod))
    dispatch(shipmentsLoadingMore(false, transportMethod))
  } catch (e) {
    dispatch(shipmentsLoading(false, transportMethod))
    dispatch(shipmentsLoadingMore(false, transportMethod))
    dispatch(CoreActions.showMessage(e))
  }
}
