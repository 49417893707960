import ActionTypes from './types'
import * as CoreActions from '../../core/store/actions'
import * as API from '../../core/api'
import { checkPagination, addGaEvent } from '../../core/helpers'
import { get } from 'lodash'

const defaultStatus = [
  'pending',
  'freight-partner-confirmed',
  'obl-confirmed',
  'freight-partner-rejected',
  'obl-rejected',
  'cancelled',
  'frozen',
  'amended'
]
const DEFAULT_PAGE_SIZE = 10

export const shipmentsLoading = (value) => ({ type: ActionTypes.SET_SHIPMENTS_LOADING, value })
export const shipmentsLoadingMore = (value) => ({ type: ActionTypes.SET_SHIPMENTS_LOADING_MORE, value })
export const getAllShipmentSuccess = (payload) => ({ type: ActionTypes.GET_ALL_SHIPMENT_SUCCESS, payload })

export const getOutboundsByShipmentSuccess = payload => ({
  type: ActionTypes.GET_OUTBOUNDS_BY_SHIPMENT_SUCCESS,
  payload
})

export const getVesselsSuccess = payload => ({
  type: ActionTypes.GET_VESSELS_SUCCESS,
  payload
})

export const getShipmentMetaSuccess = payload => ({
  type: ActionTypes.GET_SHIPMENT_META_SUCCESS,
  payload
})

export const getAllShipments = (status = defaultStatus, populatePaths, customFilter, pagination, search = null, sort, isSync = false) => async (dispatch) => {
  try {
    if (get(pagination, 'pageNo', 1) > 1) {
      dispatch(shipmentsLoadingMore(true))
    } else {
      if (!isSync) {
        dispatch(shipmentsLoading(true))
      }
    }

    let params = { stage: ['scheduled'], status }
    let payload = {}
    if (populatePaths) {
      params.populatePaths = populatePaths
    }
    if (search) {
      params = { ...params, 'multi-search': search }
    }
    if (sort) {
      params = { ...params, ...sort }
    }
    if (customFilter) {
      payload = { ...customFilter, stage: ['scheduled'] }
      delete payload.name
    }
    if (pagination) {
      pagination.pageSize = pagination.pageSize || DEFAULT_PAGE_SIZE
      params = { ...params, ...pagination }
      if (get(pagination, 'pageNo', 1) === 1 && !isSync) {
        dispatch(getAllShipmentSuccess({ reset: true, data: [], loading: true }))
      }
    }

    const { ok, data } = await API.getAllShipmentsWithFilter(params, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    checkPagination(pagination, data)
    dispatch(getAllShipmentSuccess(data))
    dispatch(shipmentsLoading(false))
    dispatch(shipmentsLoadingMore(false))
    dispatch(CoreActions.getAllShipmentsCounts())
  } catch (e) {
    dispatch(shipmentsLoading(false))
    dispatch(shipmentsLoadingMore(false))
    dispatch(CoreActions.showMessage(e))
  }
}

export const freightPartnerRejectBooking = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.exportFreightPartnerRejectBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(
      CoreActions.showMessage({
        status,
        data
      })
    )
    dispatch(getAllShipments())

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const freightPartnerAcceptBooking = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.exportFreightPartnerAcceptBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(
      CoreActions.showMessage({
        status,
        data
      })
    )
    dispatch(getAllShipments())

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const freightPartnerUpdateBooking = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.exportFreightPartnerUpdateBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(
      CoreActions.showMessage({
        status,
        data
      })
    )
    dispatch(getAllShipments())

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const logisticsPartnerAcceptBooking = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.exportLogisticsPartnerAcceptBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(
      CoreActions.showMessage({
        status,
        data
      })
    )
    dispatch(getAllShipments())

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const logisticsPartnerUpdateBooking = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data, status } = await API.exportLogisticsPartnerUpdateBooking(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(
      CoreActions.showMessage({
        status,
        data
      })
    )
    dispatch(getAllShipments())

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getOutboundsByShipment = shipmentId => async dispatch => {
  try {
    const { ok, data } = await API.getOutboundsbyShipment(shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getOutboundsByShipmentSuccess(data.data))
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const uploadShipmentFile = (shipmentId, type, payload) => async dispatch => {
  try {
    const { ok, data } = await API.uploadShipmentFile(shipmentId, type, payload)

    if (!ok) {
      throw new Error(data.message)
    }
    return true
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    return false
  }
}

export const getVessels = (success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.getVesselPositions()

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(getVesselsSuccess(data))

    if (success) {
      success()
    }
  } catch (e) {
    if (fail) {
      fail()
    }
  }
}

export const getShipmentMeta = (id, callback = null) => async dispatch => {
  try {
    const { ok, data } = await API.getShipmentMeta(id)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(
      getShipmentMetaSuccess({
        shipmentId: id,
        meta: data.data
      })
    )

    if (callback) {
      callback()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
  }
}

export const amendShipmentWithoutChange = (payload, shipmentId, success = null, fail = null) => async dispatch => {
  try {
    const { ok, data } = await API.amendShipmentWithNoChange(payload, shipmentId)

    if (!ok) {
      throw new Error(data.message)
    }

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const sendShipmentNotification = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.sendShipmentManualNotification(shipmentId, payload)

    if (!ok) {
      addGaEvent('shipment-sendingNotificationFailed', shipmentId)
      throw new Error(data.message)
    }

    addGaEvent('shipment-sentNotification', shipmentId)

    dispatch(CoreActions.showMessage({ status, data }))
    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const getLimitedServiceCards = (itineraryId, success = null, fail = null) => async dispatch => {
  dispatch({
    type: ActionTypes.GET_LIMITED_SERVICE_CARDS_SUCCESS,
    payload: []
  })

  try {
    const { ok, data } = await API.getLimitedServiceCards({ itineraryId })

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch({
      type: ActionTypes.GET_LIMITED_SERVICE_CARDS_SUCCESS,
      payload: get(data, 'data', [])
    })

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}

export const resolveFpConflicts = (shipmentId, payload, success = null, fail = null) => async dispatch => {
  try {
    const { ok, status, data } = await API.resolveFpConflict(shipmentId, payload)

    if (!ok) {
      throw new Error(data.message)
    }

    dispatch(CoreActions.showMessage({ status, data }))

    if (success) {
      success()
    }
  } catch (e) {
    dispatch(CoreActions.showMessage(e))
    if (fail) {
      fail()
    }
  }
}
