import React from 'react'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const getStyle = color => {
  return {
    color,
    fontSize: 15,
    fontWeight: 500,
    fontFamily: 'IBMPlexSans',
    '&$checked': {
      color
    },
    '&:hover': {
      background: 'transparent !important'
    }
  }
}

const getStyles = indications => {
  let styles = {}
  indications.forEach((data, i) => {
    styles[i] = getStyle(data.color)
  })
  return styles
}

const Indicator = ({ label, classes, style, indications, ...other }) => (
  <div style={{ marginLeft: 20, ...style }}>
    {indications.map((data, i) => {
      return (
        <FormControl component='fieldset' key={data.label}>
          <RadioGroup aria-label='position' name='position' row>
            <FormControlLabel
              value='end'
              control={<Radio color='primary' disableRipple={true} checked={false} className={classes[i]} />}
              label={data.label}
              labelPlacement='end'
              classes={{ label: classes[i] }}
            />
          </RadioGroup>
        </FormControl>
      )
    })}
  </div>
)
let wrapper = props => {
  let Wrapped = withStyles(getStyles(props.indications))(Indicator)
  return <Wrapped {...props} />
}
export default wrapper
