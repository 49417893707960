import React, { useEffect } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogHeader,
  FuseDialogBody,
  FuseCheckBox,
} from '../../core/components'
import { get } from 'lodash'

const availableSelections = [{
  type: "only-one-shipment",
  title: "Only one shipment",
  description: "If you choose to create only one shipment out of the selected emails, you will go through the process only once. And then we will create a shipment in behalf of you. Later if you want to update the shipment details you can go to shipment info tab and can edit from there."
}, {
  type: "multiple-shipment",
  title: "Multiple shipment",
  description: "If you want to create more than one shipments out of the selected emails, the next step “Document selection” will be repetitive until you create all the shipments and then you may have to select finish option in the next step to finish the process."
}]

const StartCreateDialog = ({ open, handleClose, selectedEmails, selectedType, setSelectedType, onContinue }) => {

  useEffect(() => {
    setSelectedType(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmails])

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader text="Start creating shipment" handleClose={handleClose} />
      <FuseDialogBody>
        <Grid container className="p-20">
          <Grid item xs={12}>
            <Grid container spacing={3}>

              <Grid item xs={12}>
                <Grid container spacing={1} justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography className="semi-bold text-16">How many shipment do you want to create from selected emails</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className="regular fg-gray text-14">{selectedEmails.length} email selected</Typography>
                  </Grid>
                </Grid>
              </Grid>

              {availableSelections.map((item, itemIndex) => {
                const title = get(item, 'title', '')
                const type = get(item, 'type', '')
                const description = get(item, 'description', '')
                const isSelected = selectedType === type

                return (
                  <Grid item xs={12} key={itemIndex}>
                    <Grid container>
                      <Grid item xs={12} className={'border-1 p-12 rounded cursor-pointer '
                        + (isSelected ? 'border-green bg-transparent-green' : 'border-gray')}
                        onClick={() => setSelectedType(type)}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <FuseCheckBox checked={isSelected} />
                          </Grid>
                          <Grid item xs>
                            <Grid container>
                              <Grid item xs={12} className="mt-4">
                                <Typography className="semi-bold text-14">{title}</Typography>
                              </Grid>
                              <Grid item xs={12} className="mt-6">
                                <Typography className="regular text-14 text-grey">{description}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container className="pt-40">
              <Grid item xs={12} sm={6} md={4}>
                <Button className="btn w-full"
                  disabled={!selectedType}
                  onClick={onContinue}>continue</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

export default StartCreateDialog
