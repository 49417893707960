import React, { useState } from 'react'
import {
  withStyles,
  Grid,
  Tooltip
} from '@material-ui/core'
import { get, find } from 'lodash'
import ManagePlantEmailTemplateSidebar from './ManagePlantEmailTemplateSidebar'
import DeletePlantEmailTemplateConfirm from './DeletePlantEmailTemplateConfirm'
import PlantSettingConfirm from './PlantSettingConfirm'

const MessageToolTip = withStyles(theme => ({
  tooltip: {
    fontSize: 12,
    maxWidth: '60vw',
    backgroundColor: '#FFFFFF',
    color: '#333333',
    border: '1px solid #CCCCCC',
    borderRadius: 3
  },
}))(Tooltip);

const styles = {
  notificationBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 105,
    height: 30,
    borderRadius: 15,
    textTransform: 'uppercase',
    fontSize: 12,
    fontFamily: 'IBMPlexSans-Bold',
    cursor: 'pointer',
    lineHeight: 1.1
  },
  btnGreenBorder: {
    color: '#3DCD58 !important',
    border: '1px solid #3DCD58 !important',
    backgroundColor: 'transparent !important',

  },
  btnGreenBorderDashed: {
    color: '#3DCD58 !important',
    backgroundColor: 'rgba(61, 205, 88, 0.2) !important',
    border: '1px dashed #3DCD58 !important',
  }
}

const Notifications = props => {
  const [submitting, setSubmitting] = useState(false)
  const [managePlantEmailTemplateSidebar, setManagePlantEmailTemplateSidebar] = useState(false)
  const [deletePlantEmailTemplateConfirm, setDeletePlantEmailTemplateConfirm] = useState(false)
  const [plantConfirm, setPlantConfirm] = useState(false)

  const {
    plantEmailTemplates,
    getTemplates,
    addTemplates,
    updateTemplates,
    deleteTemplates,
    currentPlant
  } = props
  const plantEmailTemplateTypes = [{
    value: 'preAlert',
    label: 'Pre Alert'
  }]

  const handleAddTemplate = (payload) => {
    const params = {
      key: 'plantEmailTemplate',
      value: payload
    }

    setSubmitting('plantEmailTemplate')
    addTemplates(params,
      () => {
        getTemplates(true)
        setSubmitting(false)
        setManagePlantEmailTemplateSidebar(false)
      },
      () => {
        setSubmitting(false)
      }
    )
  }

  const handleUpdateTemplate = (id, payload) => {
    const params = {
      key: 'plantEmailTemplate',
      value: payload
    }

    setSubmitting('plantEmailTemplate')
    updateTemplates(id, params,
      () => {
        getTemplates(true)
        setSubmitting(false)
        setManagePlantEmailTemplateSidebar(false)
      },
      () => {
        setSubmitting(false)
      }
    )
  }

  const handleCreateConfirm = () => {
    setPlantConfirm(false)
    setManagePlantEmailTemplateSidebar({
      open: true,
      title: 'Add new template',
    })
  }

  const handleEditConfirm = (existingTemplate) => {
    setPlantConfirm(false)
    setManagePlantEmailTemplateSidebar({
      open: true,
      title: 'Edit template',
      existingTemplate
    })
  }

  const handleDeleteTemplate = (id) => {
    setSubmitting('plantEmailTemplate')
    deleteTemplates(id,
      () => {
        getTemplates(true)
        setSubmitting(false)
        setDeletePlantEmailTemplateConfirm(false)
      },
      () => {
        setSubmitting(false)
      }
    )
  }

  return (
    <Grid container className="p-20 regular">
      {/* Email template */}
      <Grid item xs={12} className="py-8">
        <Grid container alignItems="center">
          <Grid item xs={12}>
            <Grid container
              justify="space-between"
              alignItems="center"
              className="pb-12">
              <Grid item xs={12} sm={12} md={6}
                className="bold text-16">
                Email template</Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="rounded-6 shadow bg-white px-20">
            {get(plantEmailTemplates, 'data', []).map((template, templateIndex) => {
              return (
                <Grid container key={templateIndex}
                  className="text-14">
                  <Grid item xs
                    className={'border-dashed border-gray py-20'
                      + (templateIndex > 0 ? ' border-t-1' : '')
                    }>
                    <Grid container>
                      <Grid item xs={12}
                        className="pb-6">
                        <Grid container
                          justify="space-between"
                          spacing={1}>
                          <Grid item xs>
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <label className="medium">
                                  {get(template, 'value.name')}
                                </label>
                                <label className="mx-8 fg-gray">&bull;</label>
                                <label className="fg-gray">
                                  {get(
                                    find(
                                      plantEmailTemplateTypes,
                                      { value: get(template, 'value.type') }
                                    ),
                                    'label'
                                  )}
                                </label>
                              </Grid>
                              <Grid item xs={12}
                                className="fg-gray medium">
                                {get(template, 'value.subject')}
                              </Grid>
                              <MessageToolTip title={<div dangerouslySetInnerHTML={{ __html: (get(template, 'value.messageBody') || '') }}></div>}>
                                <Grid item xs={12} className="max-h-128 overflow-auto">
                                  <div dangerouslySetInnerHTML={{ __html: (get(template, 'value.messageBody') || '') }}></div>
                                </Grid>
                              </MessageToolTip>

                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container alignItems="center">
                              <img src="assets/images/icons/Edit-Pen@2x.png"
                                height={20}
                                className="gray-actions"
                                onClick={() => setPlantConfirm({
                                  open: true,
                                  existingTemplate: template
                                })}
                                alt="edit" />
                              <img src="assets/images/icons/remove-icon.svg"
                                height={20}
                                className="gray-actions ml-16"
                                onClick={() => setDeletePlantEmailTemplateConfirm({
                                  open: true,
                                  template
                                })}
                                alt="delete" />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}

            {/* Add more */}
            <Grid container
              className="text-14">
              <Grid item xs={12}
                className={'py-20 border-dashed border-gray'
                  + (get(plantEmailTemplates, 'data', []).length > 0 ? ' border-t-1' : '')
                }>
                <label className="fg-blue medium cursor-pointer"
                  onClick={() => setPlantConfirm({ open: true })}>
                  +&nbsp;&nbsp;Add{`${get(plantEmailTemplates, 'data', []).length > 0 ?
                    ' more' :
                    ' new template'}`
                  }</label>
              </Grid>
            </Grid>
            {/* End of Add more */}

          </Grid>
        </Grid>
      </Grid>
      {/* End of Email template */}

      {/* Sidebars */}
      <ManagePlantEmailTemplateSidebar open={get(managePlantEmailTemplateSidebar, 'open', false)}
        plantEmailTemplateTypes={plantEmailTemplateTypes}
        title={get(managePlantEmailTemplateSidebar, 'title', '')}
        existingTemplate={get(managePlantEmailTemplateSidebar, 'existingTemplate')}
        submitting={submitting}
        onSubmit={(payload, mode) => {
          if (mode === 'adding') {
            handleAddTemplate(payload)
          } else {
            handleUpdateTemplate(mode._id, payload)
          }
        }}
        handleClose={() => setManagePlantEmailTemplateSidebar(false)} />
      {/* End of Sidebars */}

      {/* Dialogs */}
      {/* Delete Confirmation */}
      <DeletePlantEmailTemplateConfirm open={get(deletePlantEmailTemplateConfirm, 'open', false)}
        deleteTemplate={get(deletePlantEmailTemplateConfirm, 'template')}
        plantEmailTemplateTypes={plantEmailTemplateTypes}
        submitting={submitting}
        handleDelete={template => handleDeleteTemplate(get(template, '_id'))}
        handleDeleteCancel={() => setDeletePlantEmailTemplateConfirm(false)}
        currentPlant={currentPlant} />
      {/* End of Delete Confirmation */}

      {/* Plant Manage Confirmation */}
      <PlantSettingConfirm open={get(plantConfirm, 'open', false)}
        existingTemplate={get(plantConfirm, 'existingTemplate')}
        currentPlant={currentPlant}
        handleOkCreate={handleCreateConfirm}
        handleOkEdit={handleEditConfirm}
        handleCancel={() => setPlantConfirm(false)} />
      {/* End of Plant Manage Confirmation */}
      {/* End of Dialogs */}
    </Grid>
  )
}

export default withStyles(styles)(Notifications)
