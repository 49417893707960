import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

const EventNotificationListSkeleton = ({ count = 3 }) => (
  <Grid container alignItems='center'>
    {Array(count).fill().map((_, i) => i + 1).map(item => (
      <Grid item xs={12} key={item}
        className='py-6 text-14 fg-gray'>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container spacing={1} alignItems="center">
              <Grid item><Skeleton width={120} /></Grid>
              <Grid item>&bull;</Grid>
              <Grid item><Skeleton width={70} /></Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item><Skeleton height={16} width={16} /></Grid>
                  <Grid item><Skeleton width={50} /></Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item><Skeleton height={16} width={16} /></Grid>
                  <Grid item><Skeleton width={50} /></Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item><Skeleton height={16} width={16} /></Grid>
                  <Grid item><Skeleton width={50} /></Grid>
                </Grid>
              </Grid>
              <Grid item><Skeleton height={16} width={16} /></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ))}
  </Grid>
)

export default EventNotificationListSkeleton
