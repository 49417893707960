import { withStyles, Badge } from '@material-ui/core'

const CustomBadge = withStyles(() => ({
  badge: {
    position: 'unset',
    transform: 'unset',
    height: 'auto',
    width: 'auto',
    minWidth: 'unset',
    marginLeft: '7px',
    justifyContent: 'unset',
    border: 'none',
    padding: '0',
    backgroundColor: 'transparent',
    fontFamily: 'IBMPlexSans-SemiBold',
    fontSize: '9px'
  }
}))(Badge)

export default CustomBadge
