import React from 'react'
import { Grid } from '@material-ui/core'
import { bookingWizard } from 'core/localization'
import { TransportTimeline } from 'core/components'
import { bookingInfoForm } from 'core/localization'
import _ from 'lodash'
import { getAddressFromLocation, formatTimestamp } from '../../core/helpers'

const ReviewBookingSummary = ({
  transportRoute,
  shipment,
  hideEvents,
  truckInWithEmptyContainer,
  vesselSchedules,
  container,
}) => (<div className='booking-summary'>
  {false &&
    <div className='text-12 pb-20'>
      <h4 className='text-base text-grey-darker pb-16 m-0'>Itinerary ids</h4>
      <div className='flex flex-row py-4'>
        <Grid item sm={12} md={12} className='flex items-center justify-end'>
          <span className='text-grey-darkest'>
            {_.get(shipment, 'executableServiceCards', [])
              .map(x => x.meta.itineraryId)
              .join(', ') || 'N/A'}
          </span>
        </Grid>
      </div>
    </div>
  }

  {false && (
    <div className='text-12 pb-20'>
      <h4 className='text-base text-grey-darker pb-16 m-0'>Truck in schedule</h4>
      {_.get(shipment, 'type') === 'LCL' && (
        <div className='flex flex-row py-4'>
          <Grid item sm={12} md={6} className='mr-16 text-grey-dark'>
            {getAddressFromLocation(_.get(shipment, 'referenceEvents.truckInForCargoPickup.location', {}))}
          </Grid>
          <Grid item sm={12} md={6} className='text-right text-grey-darkest font-bold'>
            {formatTimestamp(_.get(shipment, 'referenceEvents.truckInForCargoPickup.expectedAt', null))}
          </Grid>
        </div>
      )}
      {_.get(shipment, 'type') === 'FCL' && (
        <div className='flex flex-row py-4'>
          <Grid item sm={12} md={6} className='mr-16 text-grey-dark'>
            {getAddressFromLocation(_.get(shipment, 'referenceEvents.truckInWithEmptyContainer.location', {}))}
          </Grid>
          <Grid item sm={12} md={6} className='text-right text-grey-darkest font-bold'>
            {formatTimestamp(_.get(shipment, 'referenceEvents.truckInWithEmptyContainer.expectedAt', null))}
          </Grid>
        </div>
      )}
    </div>
  )}

  {truckInWithEmptyContainer &&
    <Grid container className="text-12 pb-16">
      <Grid item xs={12}>
        <h4 className='text-base text-grey-darker pb-16 m-0'>
          Truck in with empty container</h4>
      </Grid>
      <Grid item xs={12}>
        <Grid container
          spacing={1}>
          <Grid item sm={12} md={6}
            className="fg-gray">
            {getAddressFromLocation(
              _.get(
                container,
                'referenceEvents.truckInToSWHWithEmptyContainer.location',
                {}
              )
            )}
          </Grid>
          <Grid item sm={12} md={6}
            className="medium text-right">
            {formatTimestamp(
              _.get(
                container,
                'referenceEvents.truckInToSWHWithEmptyContainer.expectedAt',
                null
              ),
              'date-time'
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  {vesselSchedules &&
    <Grid container className="text-12 pb-16">
      <Grid item xs={12}>
        <h4 className='text-base text-grey-darker pb-16 m-0'>
          Vessel Schedule</h4>
      </Grid>
      <Grid item xs={12}>
        <Grid container
          className="pb-6"
          spacing={1}>
          <Grid item sm={12} md={6}
            className="fg-gray">ETA at origin port</Grid>
          <Grid item sm={12} md={6}
            className="medium text-right">
            {formatTimestamp(
              _.get(
                shipment,
                'vesselSchedule.FirstPOL.eta'
              ),
              'date-time'
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container
          spacing={1}>
          <Grid item sm={12} md={6}
            className="fg-gray">ETD from origin port</Grid>
          <Grid item sm={12} md={6}
            className="medium text-right">
            {formatTimestamp(
              _.get(
                shipment,
                'vesselSchedule.FirstPOL.etd'
              ),
              'date-time'
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  }

  {false && (
    <>
      <div className='text-12 pb-20'>
        <h4 className='text-base text-grey-darker pb-16 m-0'>Containers</h4>

        {_.get(shipment, 'containers').filter(x => x.type === 'C20GP').length > 0 && (
          <div className='flex flex-row py-4 justify-between'>
            <label className='flex items-center text-grey-dark'>
              <span className='h-20 w-32 mr-8 text-center'>
                <img src='assets/images/icons/20ft-standard.png' alt='20ft' />
              </span>
              {bookingInfoForm.containers.options.s20}
            </label>
            <span className='text-right text-grey-darkest font-bold'>
              {_.get(shipment, 'containers').filter(x => x.type === 'C20GP').length}
            </span>
          </div>
        )}

        {_.get(shipment, 'containers').filter(x => x.type === 'C40GP').length > 0 && (
          <div className='flex flex-row py-4 justify-between'>
            <label className='flex items-center text-grey-dark'>
              <span className='h-20 w-32 mr-8 text-center'>
                <img src='assets/images/icons/40ft-standard.png' alt='20ft' />
              </span>
              {bookingInfoForm.containers.options.s40}
            </label>
            <span className='text-right text-grey-darkest font-bold'>
              {_.get(shipment, 'containers').filter(x => x.type === 'C40GP').length}
            </span>
          </div>
        )}

        {_.get(shipment, 'containers').filter(x => x.type === 'C40HC').length > 0 && (
          <div className='flex flex-row py-4 justify-between'>
            <label className='flex items-center text-grey-dark'>
              <span className='h-20 w-32 mr-8 text-center'>
                <img src='assets/images/icons/40ft-high-cube.png' alt='20ft' />
              </span>
              {bookingInfoForm.containers.options.hc40}
            </label>
            <span className='text-right text-grey-darkest font-bold'>
              {_.get(shipment, 'containers').filter(x => x.type === 'C40HC').length}
            </span>
          </div>
        )}
      </div>
    </>
  )}

  {transportRoute && transportRoute.length > 0 && (
    <div className='info-container'>
      <h4 className='mb-20 text-base text-grey-darker'>{bookingWizard.summary.transportRoute}</h4>
      <TransportTimeline data={transportRoute} />
    </div>
  )}
</div>
  )

export default ReviewBookingSummary
