import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { getAPIBaseURL } from '../helpers'

const styles = theme => ({
  root: {
    height: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    '& .logo-icon': {
      width: 'auto',
      height: '100%',
      transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shortest,
        easing: theme.transitions.easing.easeInOut
      })
    }
  },
  reactBadge: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    color: '#61dafb'
  }
});

function Logo({ classes, organizationId }) {
  return (
    <Grid container
      justify="center"
      className={classNames(classes.root)}>
      <object data={`${getAPIBaseURL()}/organizations/${organizationId}/logo`}
        className="logo-icon"
        type="image/png">
        <img src="assets/images/logos/trames.png"
          height={29}
          alt='logo' />
      </object>
    </Grid>
  );
}

export default withStyles(styles, { withTheme: true })(Logo);
