import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  SET_DELIVERING_SHIPMENTS
  SET_DELIVERING_SHIPMENTS_TOTAL
  GET_OUTBOUNDS_BY_SHIPMENT_SUCCESS
  GET_SHIPMENT_META_SUCCESS
  `
)
