import React, { Component } from 'react'
import {
  withStyles,
  Grid,
  Button,
  Icon,
  Typography,
  Zoom,
  Chip,
  CircularProgress,
  Badge
} from '@material-ui/core'
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout'
import { connect } from 'react-redux'
import {
  InfoToolTip,
  FusePlaceHolder,
  ContainerInfoLabel
} from '../../core/components'
import * as CoreActions from '../../core/store/actions'
import * as OverviewActions from '../store/actions'
import { get, filter, orderBy, round, sum, find } from 'lodash'
import { shipmentNameBeautifier, addGaEvent } from '../../core/helpers'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const styles = {
  listCard: {
    transition: 'all 0.3s',
    height: 320,
    backgroundColor: '#FFFFFF',
    border: '1px solid #E1E5ED',
    overflowY: 'auto',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 2,
    '&:hover': {
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)'
    }
  },
  badge: {
    fontSize: 11,
    right: -20,
    top: 12,
  },
  pinIconContainer: {
    '& .button-icon': {
      transition: 'all 0.3s',
    },
    '&:hover': {
      '& .button-icon': {
        transform: 'translateY(-10px) translateX(10px) rotate3d(0, 0, 1, 45deg)',
      }
    }
  },
  unpinEffect: {
    transform: 'translateY(-10px) translateX(10px) rotate3d(0, 0, 1, 45deg)',
  }
}

class OverviewPage extends Component {
  state = {
    requesting: false
  }

  getFilterShipments = (filter) => {
    const { getFilterShipments, shipments } = this.props
    const existingShipments = get(shipments, get(filter, '_id'))
    let isSync = (Array.isArray(existingShipments) && existingShipments.length > 0)

    getFilterShipments(filter, isSync)
  }

  manageFilterPin = async (filter, isPinned = true) => {
    const { setCustomFilter } = this.props
    filter = {
      ...filter,
      value: {
        ...get(filter, 'value', {}),
        isPinned
      }
    }

    this.setState({ requesting: get(filter, '_id') })
    await setCustomFilter(filter, false, () => {
      const { showMessage } = this.props
      const toast = {
        variant: 'success',
        message: (
          <span>{isPinned ? 'Pinned' : 'Unpinned'}&nbsp;
            <label className="bold">{get(filter, 'settingsName')}</label>
          </span>
        )
      }

      this.setState({ requesting: false })
      showMessage(toast)
    })
    addGaEvent(`shipment-${isPinned ? 'pinFilter' : 'unpinFilter'}`, undefined)
    if (isPinned) {
      this.getFilterShipments(filter)
    }
  }

  manageAllPin = (isPinned = true) => {
    const { customFilters, setCustomFilter } = this.props

    if (isPinned) {
      const unpinned = orderBy(filter(customFilters, item => (get(item, 'value.isPinned', false) === false)), 'createdAt', 'asc')
      unpinned.forEach(async filter => {
        this.setState({ requesting: 'pin-all' })
        filter = {
          ...filter,
          value: {
            ...get(filter, 'value', {}),
            isPinned
          }
        }
        await setCustomFilter(filter, false, () => {
          this.setState({ requesting: false })
          this.getFilterShipments(filter)
        })
      })
    } else {
      const pinned = filter(customFilters, item => (get(item, 'value.isPinned', false) === true))
      pinned.forEach(async filter => {
        this.setState({ requesting: 'unpin-all' })
        filter = {
          ...filter,
          value: {
            ...get(filter, 'value', {}),
            isPinned
          }
        }
        await setCustomFilter(filter, false, () => this.setState({ requesting: false }))
      })
    }
    addGaEvent(`shipment-${isPinned ? 'pinAllFilter' : 'unpinAllFilter'}`)
  }

  getShipmentLink = (shipment) => {
    const shipmentLinks = [
      { name: 'scheduled', title: 'Scheduled', url: '/schedule', icon: 'calendar_today' },
      { name: 'in-transit', title: 'In Transit', url: '/in-transit', icon: 'directions_boat' },
      { name: 'import', title: 'Inbound', url: '/inbound', icon: 'play_for_work' },
      { name: 'exceptions', title: 'Exceptions', url: '/exceptions', icon: 'warning_outline' },
      { name: 'completed', title: 'Completed', url: '/completed', icon: 'check_circle' },
      { name: 'cancelled', title: 'Cancelled', url: '/cancelled', icon: 'cancelled' },
    ]

    if (get(shipment, 'exceptionMode.enabled')) {
      return find(shipmentLinks, { name: 'exceptions' })
    } else {
      return find(shipmentLinks, { name: get(shipment, 'stage.current.value', 'scheduled') })
    }
  }

  componentDidMount() {
    const { loadCustomFilters } = this.props

    this.setState({ requesting: 'filters' })
    loadCustomFilters(
      () => {
        const { customFilters } = this.props
        filter(customFilters, item => (get(item, 'value.isPinned', false) === true))
          .forEach(filter => this.getFilterShipments(filter))
        this.setState({ requesting: false })
      }
    )
  }

  render() {
    const { requesting } = this.state
    const { classes, customFilters, shipments } = this.props

    return (
      <Grid container className="max-h-full overflow-auto">
        <SubToolBarLayout text='Summary Page' />

        <Grid item xs={12} className="p-20">
          {(!requesting && customFilters.length === 0) &&
            <FusePlaceHolder icon="info"
              title="No Filters available"
              description="Looks Like you don't have any Custom Filters available" />
          }
          {filter(customFilters, item => (get(item, 'value.isPinned', false) === false)).length > 0 &&
            <Grid container className="mb-8">
              <Grid item xs={12}
                className="">
                <Grid container alignItems="center"
                  spacing={2}>
                  <Grid item className="text-14 bold">
                    Available Filters</Grid>
                  <InfoToolTip title="Click to pin all Available Filters">
                    <Grid item
                      className="fg-blue text-12 cursor-pointer semi-bold"
                      onClick={() => this.manageAllPin()}>
                      Pin all
                  </Grid>
                  </InfoToolTip>
                </Grid>
              </Grid>
            </Grid>
          }
          {filter(customFilters, item => (get(item, 'value.isPinned', false) === false)).length > 0 &&
            <Grid container spacing={1}
              wrap="nowrap"
              className="overflow-x-auto mb-12">
              {orderBy(filter(customFilters, item => (get(item, 'value.isPinned', false) === false)), 'createdAt', 'asc')
                .map((filter, filterIndex) => {
                  const name = get(filter, 'settingsName')

                  return (
                    <Grid item key={filterIndex}>
                      <InfoToolTip title={<span className="regular">
                        Click to pin <label className="bold">{name}</label>
                      </span>}>
                        <Chip variant="outlined"
                          disabled={requesting !== false}
                          label={name}
                          onClick={() => this.manageFilterPin(filter)}
                          className="text-12 medium cursor-pointer" />
                      </InfoToolTip>
                    </Grid>
                  )
                })
              }
            </Grid>
          }
        </Grid>

        <Grid item xs={12} className="px-20">
          <Grid container spacing={3}>
            {(requesting !== 'filters' &&
              customFilters.length > 0 &&
              filter(customFilters, item => (get(item, 'value.isPinned', false) === true)).length === 0) &&
              <Grid item xs={12}>
                <FusePlaceHolder icon="info"
                  className="bg-transparent border-0"
                  title="No Pinned Filters"
                  description="Looks like you don't have any Pined Filters. Pin from Available filters" />
              </Grid>
            }
            {filter(customFilters, item => (get(item, 'value.isPinned', false) === true)).length > 0 &&
              <Grid item xs={12}
                className="text-12 bold fg-blue text-right">
                <InfoToolTip title="Click to unpin all Pinned Filters">
                  <label className="cursor-pointer"
                    onClick={() => this.manageAllPin(false)}>Unpin all</label>
                </InfoToolTip>
              </Grid>
            }
            {orderBy(filter(customFilters, item => (get(item, 'value.isPinned', false) === true)), 'updatedAt', 'asc')
              .map((filter, filterIndex) => {
                const name = get(filter, 'settingsName')
                const shipmentsCount = Array.isArray(get(shipments, get(filter, '_id'))) ?
                  get(shipments, get(filter, '_id'), []).length : 0

                return (
                  <Grid item key={filterIndex} xs={12} sm={6} md={4}>
                    <Grid container spacing={2}>

                      <Grid item xs={12}>
                        <Grid container
                          justify="space-between"
                          alignItems="center"
                          spacing={1}>
                          <Grid item xs={10}>
                            <Badge badgeContent={shipmentsCount}
                              color="secondary"
                              classes={{
                                anchorOriginTopRightRectangle: classes.badge
                              }}
                              className="max-w-full">
                              <InfoToolTip title={`${name} (${shipmentsCount})`}>
                                <Typography className="text-16 bold max-w-full"
                                  noWrap>{name}</Typography>
                              </InfoToolTip>
                            </Badge>
                          </Grid>
                          <Grid item xs={1} className="text-right">
                            <InfoToolTip title="UNPIN">
                              <span>
                                <Zoom in={true}>
                                  <Button disabled={requesting !== false}
                                    onClick={() => this.manageFilterPin(filter, false)}
                                    style={{
                                      minWidth: 'auto',
                                      padding: 0
                                    }}>
                                    <Grid container
                                      className={classes.pinIconContainer}
                                      justify="flex-end">
                                      <Icon className={classNames(
                                        'button-icon text-20 fg-gray fas fa-thumbtack',
                                        ((requesting === get(filter, '_id') || requesting === 'unpin-all') ? classes.unpinEffect : '')
                                      )} />
                                    </Grid>
                                  </Button>
                                </Zoom>
                              </span>
                            </InfoToolTip>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container
                          className={classes.listCard}
                          alignContent="flex-start">
                          {get(shipments, get(filter, '_id')) === 'loading' &&
                            <Grid item xs={12} className="h-full">
                              <Grid container className="h-full"
                                alignItems="center"
                                justify="center">
                                <InfoToolTip title="Loading shipments">
                                  <CircularProgress />
                                </InfoToolTip>
                              </Grid>
                            </Grid>
                          }
                          {(get(shipments, get(filter, '_id')) !== 'loading' &&
                            get(shipments, get(filter, '_id'), []).length === 0) &&
                            <Grid item xs={12} className="h-full">
                              <Grid container className="h-full"
                                alignItems="center"
                                justify="center">
                                <Grid item xs={12} className="p-8">
                                  <FusePlaceHolder icon="info"
                                    className="bg-white border-0"
                                    title="No Shipments" />
                                </Grid>
                              </Grid>
                            </Grid>
                          }
                          {get(shipments, get(filter, '_id')) !== 'loading' &&
                            orderBy(
                              get(shipments, get(filter, '_id'), []),
                              ['stage.current.value', 'createdAt'],
                              ['desc', 'asc']
                            ).map(
                              (shipment, shipmentIndex) => {
                                const shipmentLink = this.getShipmentLink(shipment)

                                return (
                                  <Grid item key={shipmentIndex} xs={12}>
                                    <Grid container>
                                      <Grid item className="pr-12 pt-12">
                                        {get(shipment, 'type') === 'LCL' && (
                                          <img src='assets/images/icons/shipments-list/LCL.svg'
                                            alt='type' />
                                        )}
                                        {get(shipment, 'type') === 'FCL' && (
                                          <img src='assets/images/icons/shipments-list/FCL.svg'
                                            alt='type' />
                                        )}
                                      </Grid>
                                      <Grid item xs
                                        className={
                                          'py-12' +
                                          (shipmentIndex > 0 ? ' border-dashed border-t-1 border-gray' : '')
                                        }>
                                        <Grid container
                                          justify="space-between"
                                          alignItems="center"
                                          spacing={1}>
                                          <Grid item xs>
                                            <Grid container>
                                              <Grid item xs={12} className="pb-8">
                                                <Link to={`${shipmentLink.url}/${shipment._id}`}
                                                  className="fg-primary semi-bold uppercase text-14">
                                                  {shipmentNameBeautifier(get(shipment, 'name', ''))}
                                                </Link>
                                              </Grid>
                                              <Grid item xs={12}
                                                className="regular fg-gray text-12">
                                                <Grid container spacing={1}>
                                                  <Grid item>
                                                    <Grid container
                                                      alignItems="center"
                                                      className="w-92">
                                                      <Icon className="text-14">{shipmentLink.icon}</Icon>
                                                      <Grid item className="pl-6">
                                                        <Typography className="text-12 semi-bold fg-gray">
                                                          {shipmentLink.title}
                                                        </Typography>
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                  <Grid item xs>
                                                    <Grid container
                                                      spacing={1}
                                                      alignItems="center">
                                                      <Grid item className="uppercase">
                                                        <Typography className="uppercase text-12 regular fg-gray">
                                                          {get(shipment, 'type')}
                                                        </Typography>
                                                      </Grid>
                                                      <Grid item>&bull;</Grid>
                                                      {get(shipment, 'type') === 'FCL' &&
                                                        <Grid item>
                                                          <ContainerInfoLabel containers={get(shipment, 'containers', [])}
                                                            className="fg-gray text-12 cursor-pointer"
                                                            style={{
                                                              fontFamily: 'IBMPlexSans'
                                                            }} />
                                                        </Grid>
                                                      }
                                                      {get(shipment, 'type') === 'LCL' &&
                                                        <Grid item>
                                                          {round(sum(get(shipment, 'consolidations')
                                                            .map(x => get(x, 'packagedWeight.value', get(x, 'weight.value', 0)))), 3)
                                                            + ' '
                                                            + get(shipment, 'consolidations[0].weight.unit', 'KG')
                                                            + ' | '
                                                            + round(sum(get(shipment, 'consolidations')
                                                              .map(x => get(x, 'packagedVolume.value', get(x, 'volume.value', 0)))), 3)
                                                            + ' '
                                                            + get(shipment, 'consolidations[0].volume.unit', 'M3')
                                                          }
                                                        </Grid>
                                                      }
                                                    </Grid>
                                                  </Grid>
                                                  {get(shipment, 'specialLabels', []).length > 0 &&
                                                    <Grid item>
                                                      <Grid container
                                                        className="ml-16"
                                                        alignItems="center">
                                                        <img src='assets/images/icons/shipments-list/special-label.svg'
                                                          alt='special-label'
                                                          className='mr-6' />
                                                        <InfoToolTip title={get(shipment, 'specialLabels', []).join(', ').toUpperCase()}>
                                                          <Typography item className="uppercase semi-bold fg-gray max-w-136 text-12"
                                                            noWrap>
                                                            {get(shipment, 'specialLabels', []).join(', ')}
                                                          </Typography>
                                                        </InfoToolTip>
                                                      </Grid>
                                                    </Grid>
                                                  }
                                                  {get(shipment, 'shipmentMode') === 'drop' && (
                                                    <Grid item xs={12} className="pt-6">
                                                      <Grid container alignItems='center'>
                                                        <img src='assets/images/icons/shipments-list/drop-hub.svg'
                                                          alt='dropHub'
                                                          className='mr-6' />
                                                        <Typography className='uppercase medium text-12 fg-gray'>
                                                          {get(shipment, 'shipmentMode')}&nbsp;</Typography>
                                                        <label>(</label>
                                                        <InfoToolTip title={get(shipment, 'actors.dropHub[0].name')}>
                                                          <Typography className='capitalize medium text-12 max-w-136 fg-gray'
                                                            noWrap>
                                                            {get(shipment, 'actors.dropHub[0].name')}
                                                          </Typography>
                                                        </InfoToolTip>
                                                        <label>)</label>
                                                      </Grid>
                                                    </Grid>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>

                                          <Grid item>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                              }
                            )
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })
            }
          </Grid>
        </Grid>
      </Grid >
    )
  }
}

const mapStateToProps = ({
  overviewReducer: { shipments },
  core: { customFilters }
}) => ({
  shipments,
  customFilters
})

const mapDispatchToProps = dispatch => ({
  loadCustomFilters: (...args) => dispatch(CoreActions.loadCustomFilters(...args)),
  setCustomFilter: (...args) => dispatch(CoreActions.setCustomFilter(...args)),
  showMessage: (...args) => dispatch(CoreActions.showMessage(...args)),
  getFilterShipments: (...args) => dispatch(OverviewActions.getFilterShipments(...args)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OverviewPage))
