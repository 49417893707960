import ActionTypes from './types'

const initialState = {
  currentPlant: null,
  loading: false,
  error: null
}

const welcomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_ATTEMPT:
      return {
        ...state,
        loading: true
      }
    case ActionTypes.API_SUCCESS:
      return {
        ...state,
        loading: false
      }

    case ActionTypes.API_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case ActionTypes.SET_CURRENT_PLANT:
      return {
        ...state,
        currentPlant: action.payload
      }

    default:
      return state
  }
}

export default welcomeReducer
