import React, { useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { useMergeState } from '../../../core/helpers'
import { GooglePlaceSearch } from '../../../core/components/Inputs'
import DateTime from 'react-datetime'
import { get, find, set } from 'lodash';

const INITIAL_STATE = {
  hasWarehouseStorage: false,
}

const LCLAcknowledge = ({ shipment, onChange }) => {
  const [state, setState] = useMergeState(INITIAL_STATE)
  const storageOneEvents = ['truckInToSFWithCargo', 'truckOutFromSFWithCargo']
  const { transportMethod } = shipment

  useEffect(() => {
    const warehouseLocation = get(
      find(get(shipment, 'journey', []), { location: { type: 'warehouse', subType: 'origin' } }),
      'location',
      {}
    )
    var updatedState = {
      ...state,
      referenceEvents: get(shipment, 'referenceEvents', {})
    }

    set(updatedState, 'referenceEvents.truckOutWithCargo.location', warehouseLocation)
    if (get(shipment, 'referenceEvents.truckInToSFWithCargo') ||
      get(shipment, 'referenceEvents.truckOutFromSFWithCargo')) {
      set(updatedState, 'hasWarehouseStorage', true)
    } else {
      set(updatedState, 'hasWarehouseStorage', false)
    }

    setState(updatedState)
    onChange(updatedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  const updatedEvent = (sections, name, value) => {
    var referenceEvents = get(state, 'referenceEvents', {})

    if (name === 'location') {
      referenceEvents = {
        ...referenceEvents,
      }
      sections.forEach(section => {
        referenceEvents[section] = {
          ...get(referenceEvents, section, {}),
          location: {
            ...storageOneEvents.includes(section) ? { name: 'Storage 1' } : {},
            ...get(referenceEvents, [section] + '.location', {}),
            ...value
          }
        }
      })
    } else {
      referenceEvents = {
        ...referenceEvents
      }
      sections.forEach(section => {
        referenceEvents[section] = {
          ...get(referenceEvents, section, {}),
          location: get(referenceEvents, [section] + '.location', {}),
          [name]: value
        }
      })
    }

    return referenceEvents;
  }

  const handleAddressChange = (address, lat, lng, sections) => {
    const updatedState = {
      ...state,
      referenceEvents: updatedEvent(sections, 'location', {
        ...address,
        latitude: lat.toString(),
        longitude: lng.toString(),
      })
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleAdditionalAddressChange = (event, sections) => {
    const { name, value } = event.target

    const updatedState = {
      ...state,
      referenceEvents: updatedEvent(sections, 'location', {
        [name]: value
      })
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleDateChange = (date, name, sections) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    const updatedState = {
      ...state,
      referenceEvents: updatedEvent(sections, name, new Date(date))
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const manageSF = (action) => {
    const value = action === 'add' ? {} : undefined
    var updatedState = {
      ...state,
      hasWarehouseStorage: value ? true : false,
    }

    storageOneEvents.forEach(event => {
      set(updatedState, `referenceEvents.${event}`, value)
    })

    setState(updatedState)
    onChange(updatedState)
  }

  return (
    <Grid container className="field-control">
      <Grid item sm={12} md={6} className='flex items-center'>
        <label className={'field-label'}>
          Haulage schedule</label>
      </Grid>
      <Grid item sm={12} md={6} className='flex items-center justify-end'>
        <label className={'field-instruction'}>
          Add container journey</label>
      </Grid>

      <Grid container
        direction='row'
        className='stepper'>

        <Grid container
          direction='row'
          className='step pb-24'>
          <Grid container
            direction='row'
            className='step-header'>
            <span className='step-icon'>
              <img src="assets/images/icons/timeline-step/container-yard-gray.svg"
                alt='container-yard' />
            </span>
            <Grid item sm={12} md={6} className='flex items-center'>
              <label className='field-sub-label'>
                Shipper warehouse</label>
            </Grid>
          </Grid>

          <Grid container direction='row' className='step-content'>
            <Grid item sm={12} className='pb-16'>
              <label className="text-12 fg-gray">
                {get(find(
                  get(shipment, 'journey', []),
                  {
                    location: {
                      type: 'warehouse',
                      subType: 'origin'
                    }
                  }),
                  'location.name')
                }
              </label>
            </Grid>
            <Grid item sm={12} md={6} className='pr-6'>
              <Grid container>
                <Grid item xs={12}>
                  <label className='formsy-form-control__label require-field'>
                    Planned truck out with cargo</label>
                </Grid>
                <Grid item xs={12} className='relative'>
                  <DateTime
                    className='theme-date-time-picker-field'
                    input
                    dateFormat='DD MMM YYYY,'
                    onChange={date => handleDateChange(
                      date,
                      'expectedAt',
                      ['truckOutWithCargo']
                    )}
                    value={get(state, 'referenceEvents.truckOutWithCargo.expectedAt') &&
                      new Date(get(state, 'referenceEvents.truckOutWithCargo.expectedAt'))
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {
          !state.hasWarehouseStorage &&
          <Grid container
            direction='row'
            className='step pb-24'>
            <Grid container
              direction='row'
              className='step-header'
              justify="space-between">
              <span className='step-icon'>
                <img src="assets/images/icons/vessel-info/add-transhipment.png"
                  alt='booking' />
              </span>
              <Grid item>
                <Button className="btn btn_sm btn_light-blue border-solid border-1 border-blue normal-fonts"
                  onClick={() => manageSF('add')}>
                  Add storage facility</Button>
              </Grid>
              <Grid item>
                <label className={'field-instruction'}>
                  Is it going to SF before&nbsp;{transportMethod === 'air' ? 'Cargo Warehouse' : 'CFS'}?</label>
              </Grid>
            </Grid>
          </Grid>
        }

        {
          state.hasWarehouseStorage &&
          <Grid container
            direction='row'
            className='step pb-24'>
            <Grid container
              direction='row'
              className='step-header'>
              <span className='step-icon'>
                <img src="assets/images/icons/timeline-step/storage-facility-blue.svg"
                  alt='container-yard' />
              </span>
              <Grid item sm={12} md={9} className='flex items-center'>
                <label className='field-sub-label'>
                  Storage facility before going to&nbsp;{transportMethod === 'air' ? 'Cargo Warehouse' : 'CFS'}</label>
              </Grid>
              <Grid item sm={12} md={3} className='flex items-center justify-end'>
                <label className={'cursor-pointer fg-red regular'}
                  onClick={() => manageSF('remove')}>
                  Remove</label>
              </Grid>
            </Grid>

            <Grid container direction='row' className='step-content'>
              <Grid item sm={12} className='pb-16'>
                <label className={'formsy-form-control__label require-field'}>
                  Storage facility address</label>
                <GooglePlaceSearch
                  placeholder='Select street address...'
                  className='theme-text-field'
                  value={get(
                    state,
                    'referenceEvents.truckInToSFWithCargo.location.street',
                    get(state, 'referenceEvents.truckOutFromSFWithCargo.location.street'))
                  }
                  updateCall={(address, lat, lng) => {
                    handleAddressChange(address, lat, lng, storageOneEvents)
                  }}
                  savedPlaceType="storage-before-cfs"
                />
              </Grid>
              <Grid item sm={12} md={4} className='pr-8'>
                <TextField name='floorUnitNumber'
                  className='theme-text-field'
                  value={get(
                    state,
                    'referenceEvents.truckInToSFWithCargo.location.floorUnitNumber',
                    get(state, 'referenceEvents.truckOutFromSFWithCargo.location.floorUnitNumber'))
                  }
                  label="Additional address"
                  fullWidth={true}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => {
                    handleAdditionalAddressChange(event, storageOneEvents)
                  }}
                />
              </Grid>
              <Grid item sm={12} md={4} className='pl-6'>
                <Grid container>
                  <Grid item xs={12}>
                    <label className='formsy-form-control__label require-field'>
                      Truck In</label>
                  </Grid>
                  <Grid item xs={12} className='relative'>
                    <DateTime
                      className='theme-date-time-picker-field'
                      input
                      dateFormat='DD MMM YYYY,'
                      onChange={date => handleDateChange(
                        date,
                        'expectedAt',
                        ['truckInToSFWithCargo']
                      )}
                      value={get(state, 'referenceEvents.truckInToSFWithCargo.expectedAt') &&
                        new Date(get(state, 'referenceEvents.truckInToSFWithCargo.expectedAt'))
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={4} className='pl-6'>
                <Grid container>
                  <Grid item xs={12}>
                    <label className='formsy-form-control__label require-field'>
                      Truck Out</label>
                  </Grid>
                  <Grid item xs={12} className='relative'>
                    <DateTime
                      className='theme-date-time-picker-field'
                      input
                      dateFormat='DD MMM YYYY,'
                      onChange={date => handleDateChange(
                        date,
                        'expectedAt',
                        ['truckOutFromSFWithCargo']
                      )}
                      value={get(state, 'referenceEvents.truckOutFromSFWithCargo.expectedAt') &&
                        new Date(get(state, 'referenceEvents.truckOutFromSFWithCargo.expectedAt'))
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid container
          direction='row'
          className='step'>
          <Grid container
            direction='row'
            className='step-header'>
            <span className='step-icon'>
              <img src="assets/images/icons/dock-filled.svg"
                alt='booking' />
            </span>
            <Grid item sm={12} md={6} className='flex items-center'>
              <label className='field-sub-label'>
                {transportMethod === 'air' ? 'Cargo Warehouse' : 'CFS'} hub</label>
            </Grid>
          </Grid>
          <div className='flex items-center text-11 text-grey-dark'>
            {get(find(
              get(shipment, 'journey', []),
              {
                location: {
                  type: 'CFS',
                  subType: 'origin'
                }
              }),
              'location.name')
            }
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LCLAcknowledge
