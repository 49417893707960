import React from 'react'
import { withStyles } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #ced4da',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '12px 26px 12px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'IBMPlexSans-SemiBold',
    '&:focus': {
      borderRadius: 4,
      border: '1px solid #ced4da',
      backgroundColor: '#FFFFFF',
      boxShadow: 'none'
    }
  }
}))(InputBase)

const getPropName = (sortBy) => {
  switch (sortBy) {
    case 'booking': return 'createdAt';
    default:
      return null;
  }
}

const SortBy = ({ handleSortChange }) => {
  const [sort, setSort] = React.useState('relevance')

  const handleChange = event => {
    const { value } = event.target
    setSort(value)
    if (value === 'relevance') {
      handleSortChange(null)
    } else {
      let sort = value.split('_')
      handleSortChange({ sortBy: getPropName(sort[0]), sortMode: sort[1] })
    }
  }

  return (
    <FormControl fullWidth>
      <Select
        id='demo-customized-select'
        value={sort}
        onChange={handleChange}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        input={<BootstrapInput />}
      >
        <MenuItem value='relevance' selected>
          Relevance
        </MenuItem>
        <MenuItem value='booking_asc'>Booking: Early to Late</MenuItem>
        <MenuItem value='booking_desc'>Booking: Late to Early</MenuItem>
      </Select>
    </FormControl>
  )
}

const styles = () => ({
  bordered: {
    border: '1px solid #e3e3e4',
    borderRadius: 4
  }
})

export default withStyles(styles)(SortBy)
