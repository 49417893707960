import React, { useEffect } from 'react'
import { Grid, TextField } from '@material-ui/core'
import { reviewContainerScheduleForm } from '../../../core/localization'
import { useMergeState, copyFirstContainerEvents } from '../../../core/helpers'
import _, { get, set, findIndex } from 'lodash'
import DateTime from 'react-datetime'
import { GooglePlaceSearch } from '../../../core/components/Inputs'

const ReviewContainerScheduleForm = ({ shipment, onChange, existingData }) => {
  const [state, setState] = useMergeState(existingData ? { ...{}, ...existingData } : {})

  useEffect(() => {
    const updatedState = {
      ...state,
      containers: [...get(state, 'containers', get(shipment, 'containers', []))]
    }
    setState(updatedState)
    onChange(updatedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipment])

  const updatedContainerEvent = (index, name, value) => {
    var containers = get(shipment, 'containers', [])
    var paramPath = 'referenceEvents.emptyContainerPickUpFromYard'

    if (name === 'location') {
      set(containers[index], paramPath + '.' + [name], {
        ...get(containers[index], paramPath + '.' + [name], {}),
        ...value
      })
    } else {
      set(containers[index], paramPath, {
        ...get(containers[index], paramPath, {}),
        [name]: value
      })
    }

    return containers
  }

  const handleContainerLocationChange = (_id, address, lat, lng) => {
    const containerIndex = findIndex(get(state, 'containers', []), { _id })
    const updatedState = {
      ...state,
      containers: updatedContainerEvent(containerIndex, 'location', {
        ...address,
        latitude: lat.toString(),
        longitude: lng.toString()
      })
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleAdditionalAddressChange = (_id, event) => {
    const containerIndex = findIndex(get(state, 'containers', []), { _id })
    const { name, value } = event.target
    const updatedState = {
      ...state,
      containers: updatedContainerEvent(containerIndex, 'location', {
        [name]: value
      })
    }

    setState(updatedState)
    onChange(updatedState)
  }

  const handleDateChange = (_id, date, name) => {
    if (new Date(date).toString() === 'Invalid Date') {
      date = new Date()
    }
    const containerIndex = findIndex(get(state, 'containers', []), { _id })
    const updatedState = {
      ...state,
      containers: updatedContainerEvent(containerIndex, name, new Date(date))
    }

    setState(updatedState)
    onChange(updatedState)
  }

  return (
    <>
      <Grid container direction='row' className='field-control'>
        <Grid item sm={12} md={6} className='flex items-center'>
          <label className={'field-label'}>
            {reviewContainerScheduleForm.emptyCotainerPickupSchedule.label}
          </label>
        </Grid>
        <Grid item sm={12} md={6} className='flex items-center justify-end'>
          <label className={'field-instruction'}>
            {reviewContainerScheduleForm.emptyCotainerPickupSchedule.instruction}
          </label>
        </Grid>

        <Grid container>
          <Grid container direction='row' className='stepper'>
            {_.filter(_.get(state, 'containers', []), item =>
              (_.get(item, 'status.current.value') !== 'cancelled')
            ).map((container, index) => {
              return (
                <Grid container direction='row' className='step' key={index}>
                  <Grid container direction='row' className='step-header'>
                    <span className='step-icon'>
                      <img src='assets/images/icons/Storage-facility-blue@3x.svg' alt='container' />
                    </span>
                    <Grid item sm={12} md={6} className='flex items-center'>
                      <label className='field-sub-label'>{`${_.get(container, 'type')} (${index + 1})`}</label>
                    </Grid>
                    <Grid item sm={12} md={6} className='flex items-center justify-end'>
                      {index > 0 && (
                        <label className='fg-blue cursor-pointer'
                          onClick={() => {
                            const updatedState = {
                              containers: copyFirstContainerEvents(
                                get(state, 'containers', []),
                                index,
                                ['emptyContainerPickUpFromYard']
                              )
                            }
                            setState(updatedState)
                            onChange(updatedState)
                          }}>Copy from first</label>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container direction='row' className='step-content'>
                    <Grid container direction='row' className='mt-16 mb-24'>
                      <Grid item sm={12} className='pb-16'>
                        <label className={'formsy-form-control__label require-field'}>
                          {reviewContainerScheduleForm.field.containerYardAddress}
                        </label>
                        <GooglePlaceSearch
                          placeholder='Select street address...'
                          className='theme-text-field'
                          value={_.get(container, 'referenceEvents.emptyContainerPickUpFromYard.location.street')}
                          updateCall={(address, lat, lng) => handleContainerLocationChange(container._id, address, lat, lng)}
                          savedPlaceType="container-yard"
                        />
                      </Grid>
                      <Grid item sm={12} md={7} className='pr-8'>
                        <TextField
                          name='floorUnitNumber'
                          className='theme-text-field'
                          value={_.get(container, 'referenceEvents.emptyContainerPickUpFromYard.location.floorUnitNumber')}
                          label={reviewContainerScheduleForm.field.additionalAddress}
                          fullWidth={true}
                          InputLabelProps={{ shrink: true }}
                          onChange={event => handleAdditionalAddressChange(container._id, event)}
                        />
                      </Grid>
                      <Grid item sm={12} md={5} className='pl-6'>
                        <Grid container>
                          <Grid item xs={12}>
                            <label className='formsy-form-control__label require-field'>
                              {reviewContainerScheduleForm.field.dateTime}
                            </label>
                          </Grid>
                          <Grid item xs={12} className='relative'>
                            <DateTime
                              className='theme-date-time-picker-field'
                              input
                              dateFormat='DD MMM YYYY,'
                              onChange={date => handleDateChange(container._id, date, 'expectedAt')}
                              value={
                                _.get(container, 'referenceEvents.emptyContainerPickUpFromYard.expectedAt') &&
                                new Date(_.get(container, 'referenceEvents.emptyContainerPickUpFromYard.expectedAt'))
                              }
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })
            }
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ReviewContainerScheduleForm
