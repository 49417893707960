import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  Tooltip,
  withStyles
} from '@material-ui/core'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FusePlaceHolder,
  FuseSideBarActions
} from '../'
import Truncate from 'react-truncate'
import classNames from 'classnames'
import { filter, groupBy, get, orderBy } from "lodash";
import { shipmentNameBeautifier } from '../../helpers'

const InfoTooltip = withStyles(theme => ({
  tooltip: {
    fontFamily: 'IBMPlexSans',
    fontSize: 11,
    maxWidth: 220,
  },
}))(Tooltip);

const NotificationSidebar = ({ open, closeSideBar, notifications, markNotificationsAsRead, loadNotifications }) => {
  const [openNotification, setOpenNotification] = useState(null)
  const [notificationType, setNotificationType] = useState('all')
  const [selectedNotifications, setSelectedNotifications] = useState(null)
  const [loading, setLoading] = useState(false)
  const unreadNotificationCount = filter(notifications, item => !item.isRead, []).length
  const groupedNotifications = groupBy(notifications, 'shipmentId._id')

  const pageSize = 10
  const pageNo = 0

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      await loadNotifications(pageSize, notificationType, pageNo)
      setLoading(false)
    }
    if (open) {
      setSelectedNotifications(null)
      if (notifications.length === 0) {
        fetchData()
      } else {
        loadNotifications(pageSize, notificationType, pageNo)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    if (selectedNotifications) {
      const currentNotifications = get(
        groupedNotifications,
        `${get(selectedNotifications, 'shipmentId')}`,
        []
      )
      setSelectedNotifications({
        ...selectedNotifications,
        currentNotifications
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  const openNotificationDialog = async notification => {
    setOpenNotification(notification)
    markNotificationsAsRead({ ids: [notification._id], notificationType: 'read' })
  }

  const markMultipleRead = async (notificationGroup) => {
    markNotificationsAsRead({
      ids: filter(notificationGroup, item => !item.isRead, [])
        .map(item => item._id),
      notificationType: 'read'
    })
  }

  const markAllRead = async () => {
    setLoading(true)
    await markNotificationsAsRead({
      ids: filter(notifications, item => !item.isRead, [])
        .map(item => item._id),
      notificationType: 'read'
    })
    setLoading(false)
  }

  const checkForEmptyNotifications = () => {
    if (loading) {
      return false
    } else {
      switch (notificationType) {
        case 'all':
          if (notifications.length > 0) {
            return false
          }
          return true
        case 'unread':
          if (filter(notifications, { isRead: false }).length > 0) {
            return false
          }
          return true
        default:
          return true
      }
    }
  }

  return (
    <FuseSideBar open={open ? true : false}
      onBackDropClick={closeSideBar}>
      <FuseSideBarHeader text={'Notifications'}
        handleClose={closeSideBar} />
      <FuseSideBarBody style={{ padding: 0 }}>
        <Grid container>
          {!selectedNotifications &&
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container justify="space-between"
                    alignItems="center"
                    className="px-20 bg-gray">
                    <Grid item>
                      <Button className={
                        classNames({
                          'border-b-2 regular normal-case border-solid rounded-none': true,
                          'border-transparent': notificationType === 'unread',
                          'border-green': notificationType === 'all',
                          'fg-gray': notificationType !== 'all'
                        })}
                        onClick={() => setNotificationType('all')}>All</Button>
                      <Button className={
                        classNames({
                          'border-b-2 regular normal-case border-solid rounded-none': true,
                          'border-transparent': notificationType === 'all',
                          'border-green': notificationType === 'unread',
                          'fg-gray': notificationType !== 'unread'
                        })}
                        onClick={() => setNotificationType('unread')}>Unread</Button>
                    </Grid>
                    {unreadNotificationCount > 0 &&
                      <Grid item>
                        <Grid container alignItems="center"
                          className="text-10 bold uppercase fg-orange cursor-pointer semi-bold"
                          onClick={() => {
                            if (unreadNotificationCount > 0) {
                              markAllRead()
                            }
                          }}>
                          <Icon className="mr-4 text-14">done</Icon>mark all read</Grid>
                      </Grid>
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} className="p-20">
                  {!loading &&
                    Object.keys(groupedNotifications).map((shipmentId, groupIndex) => {
                      const currentNotifications = orderBy(
                        get(groupedNotifications, shipmentId, []),
                        'createdAt',
                        'desc'
                      )
                      const newNotificationCount = filter(currentNotifications, item => !item.isRead, []).length
                      if (notificationType === 'unread' && newNotificationCount === 0) {
                        return <React.Fragment key={groupIndex} />
                      } else {
                        return (
                          <Grid container
                            key={groupIndex}
                            className="bg-gray rounded-4 mb-16">
                            <Grid item xs={12} className="px-12 py-8">
                              <Grid container spacing={1}
                                className="cursor-pointer"
                                onClick={() => {
                                  setSelectedNotifications({
                                    shipmentId: get(currentNotifications, '[0]shipmentId._id'),
                                    currentNotifications
                                  })
                                }}>
                                <Grid item xs={12} className="text-14 uppercase bold">
                                  {shipmentNameBeautifier(get(currentNotifications, '[0]shipmentId.name', ''))}
                                </Grid>
                                <Grid item xs={12}>
                                  <Grid container spacing={1}>
                                    <Grid item xs={7} className={'text-11 regular'
                                      + (get(currentNotifications, '[0].isRead') ? ' fg-gray' : '')}>
                                      <Truncate line={2}
                                        ellipsis="...">{get(currentNotifications, '[0].body')}</Truncate>
                                    </Grid>
                                    <Grid item xs={5} className="text-10 fg-gray regular italic text-right">
                                      {moment(get(currentNotifications, '[0]createdAt')).format('Do MMM YYYY, HH:mm')}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            {(currentNotifications.length > 1 || newNotificationCount > 0) &&
                              <Grid item xs={12} className="border-solid border-t-1 border-gray px-12 py-8">
                                <Grid container justify="space-between"
                                  alignItems="center"
                                  className="text-11 medium">
                                  <Grid item className="fg-green">
                                    {currentNotifications.length > 1 &&
                                      <label className="cursor-pointer"
                                        onClick={() => {
                                          setSelectedNotifications({
                                            shipmentId: get(currentNotifications, '[0]shipmentId._id'),
                                            currentNotifications
                                          })
                                        }}
                                      >{currentNotifications.length - 1} more notifications</label>
                                    }
                                  </Grid>
                                  <Grid item className="fg-orange">
                                    {newNotificationCount > 0 &&
                                      <Grid container
                                        alignItems="center">
                                        <Grid item style={{
                                          backgroundColor: '#E47F00',
                                          borderRadius: 2.5,
                                          width: 5,
                                          height: 5,
                                        }}></Grid>
                                        <Grid item className="pl-4">
                                          <InfoTooltip title="This will mark all unread notifications as read for this shipment">
                                            <label className="cursor-pointer"
                                              onClick={() => markMultipleRead(currentNotifications)}>
                                              {newNotificationCount} unread</label>
                                          </InfoTooltip>
                                        </Grid>
                                      </Grid>
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                            }
                          </Grid>
                        )
                      }
                    })
                  }
                  {loading && <NotificationSkeleton />}
                </Grid>
              </Grid>
            </Grid>
          }

          {selectedNotifications &&
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container
                    alignItems="center"
                    className="px-20 py-8 bg-gray">
                    <Grid item className="cursor-pointer pr-6"
                      onClick={() => setSelectedNotifications(null)}>
                      <img src="assets/images/icons/Back-Organizations@2x.png"
                        alt="back"
                        height={21} />
                    </Grid>
                    <Grid item className="uppercase medium text-14">
                      {shipmentNameBeautifier(get(
                        selectedNotifications,
                        'currentNotifications[0].shipmentId.name',
                        ''
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className="p-20">
                  {get(selectedNotifications, 'currentNotifications', [])
                    .map((notification, notificationIndex) => {
                      return (
                        <Grid container
                          key={notificationIndex}
                          className="bg-gray p-12 rounded-4 mb-20 cursor-pointer"
                          onClick={() => openNotificationDialog(notification)}
                          spacing={2}>
                          <Grid item xs={12}
                            className={classNames({
                              'text-12': true,
                              'fg-gray': get(notification, 'isRead')
                            })}>
                            <Truncate line={2}
                              ellipsis="...">{notification.body}</Truncate>
                          </Grid>
                          <Grid item xs={12} className="text-10 fg-gray regular italic">
                            {moment(notification.createdAt).format('Do MMM YYYY, HH:mm')}
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
          }

          {checkForEmptyNotifications() &&
            <Grid item xs={12} className="px-20">
              <FusePlaceHolder
                className='w-full py-32 px-12 regular'
                icon='error'
                title='No notifications' />
            </Grid>
          }

        </Grid>

        <ViewNotificationDialog openNotification={openNotification}
          setOpenNotification={setOpenNotification} />
      </FuseSideBarBody>

      <FuseSideBarActions>
        <Button className='btn btn_full-space'
          onClick={closeSideBar}>CLOSE</Button>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

const NotificationSkeleton = () => {
  return (
    <Grid container
      justify='flex-end'
      alignItems='center'>
      {[1, 2, 3].map(action => (
        <Grid item xs={12}
          key={action}
          className="notifications-sidebar-row"
          style={{ marginBottom: 20 }}>
          <Grid container
            justify="space-between"
            alignItems="center">
            <Grid item xs={6}><Skeleton height={16} /></Grid>
            <Grid item xs={4}><Skeleton height={12} /></Grid>
            <Grid item xs={12} className="pt-12">
              <Skeleton height={14} count={2} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

const ViewNotificationDialog = ({ openNotification, setOpenNotification }) => {
  if (!openNotification) {
    return null
  }
  return (
    <Dialog open={openNotification}
      onClose={() => setOpenNotification(null)}
      scroll={`paper`}
      maxWidth='sm'
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'>
      <DialogTitle id='scroll-dialog-title'>
        <Grid container justify="space-between"
          alignItems="center"
          spacing={2}>
          <Grid item xs={8} className="bold">
            {openNotification.title}
          </Grid>
          <Grid item xs={4} className="text-12 regular fg-gray text-right">
            {moment(openNotification.createdAt).format('Do MMM YYYY, HH:mm')}
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div className="regular"
          dangerouslySetInnerHTML={{ __html: (openNotification.body || '') }}></div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenNotification(null)} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default NotificationSidebar
