import ActionTypes from './types'

const initialState = {
  closedOutbounds: [],
  excludeClosedOutbounds: [],
  selectedOutBoundIds: [],
  loading: false,
  hasMore: false,
  loadingMore: false,
  excludeLoading: false,
  hasExcludeMore: false,
  excludeLoadingMore: false
}

const closedReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    case ActionTypes.SET_EXCLUDE_LOADING:
      return {
        ...state,
        excludeLoading: action.loading
      }
    case ActionTypes.SET_LOADING_MORE:
      return {
        ...state,
        loadingMore: true
      }
    case ActionTypes.SET_EXCLUDE_LOADING_MORE:
      return {
        ...state,
        excludeLoadingMore: true
      }
    case ActionTypes.SET_ALL_CLOSED_OUTBOUNDS:
      return {
        ...state,
        closedOutbounds: action.payload.data,
        hasMore: action.payload.showMore,
        loading: false
      }
    case ActionTypes.SET_MORE_CLOSED_OUTBOUNDS:
      return {
        ...state,
        closedOutbounds: [...state.closedOutbounds, ...action.payload.data],
        hasMore: action.payload.showMore,
        loadingMore: false
      }
    case ActionTypes.SET_ALL_EXCLUDE_CLOSED_OUTBOUNDS:
      return {
        ...state,
        excludeClosedOutbounds: action.payload.data,
        hasMore: action.payload.showMore,
        excludeLoading: false
      }
    case ActionTypes.SET_MORE_EXCLUDE_CLOSED_OUTBOUNDS:
      return {
        ...state,
        excludeClosedOutbounds: [...state.excludeClosedOutbounds, ...action.payload.data],
        hasMore: action.payload.showMore,
        excludeLoadingMore: false
      }
    case ActionTypes.SET_SELECTED_OUTBOUNDS:
      return {
        ...state,
        selectedOutBoundIds: action.selectedOutBoundIds
      }

    default:
      return state
  }
}

export default closedReducer
