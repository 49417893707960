import React, { Component } from 'react'
import { Grid, Button } from '@material-ui/core'
import { get, capitalize, pick } from 'lodash'
import {
  LoadingButton,
  FuseFileUploader,
  FuseSideBarActions,
  FuseSideBar,
  FuseSideBarBody,
  FuseSideBarHeader,
  CombinedDocsDialog,
  AttachOpenERPDialog
} from '../../core/components'
import { fileUploadInfo } from '../../core/localization'
import { addGaEvent } from '../../core/helpers'

class UploadDialog extends Component {
  initialCombinedDocsDialog = {
    open: false,
    allowedDocumentTypes: [],
    docType: '',
  }

  initialAttachOpenERPDialog = {
    open: false,
    allowedDocumentTypes: [],
  }

  state = {
    documents: {},
    combinedDocuments: {},
    arrayOfCall: [],
    response: {},
    loading: false,
    combinedDocsDialog: this.initialCombinedDocsDialog,
    attachOpenERPDialog: this.initialAttachOpenERPDialog
  }
  uploadInfo = []
  uploaderRefs = []
  count = 0

  componentDidUpdate(nextProps) {
    const { shipment } = nextProps
    const { rbac } = shipment

    if (rbac && get(rbac, 'write', []).length) {
      this.uploadInfo = pick(fileUploadInfo, rbac.write)
    }
  }

  handleOpenAttachOpenERP = () => {
    const { shipment: { rbac } } = this.props
    if (rbac) {
      this.setState({
        attachOpenERPDialog: {
          open: true,
          allowedDocumentTypes: get(rbac, 'write', []),
        }
      })
    }
  }

  handleUpdateDocument = type => documents => {
    const { shipment: { rbac } } = this.props
    const combineTypes = ['PL', 'FAN', 'ShipperInvoice']

    if (Object.keys(documents).length > 0 && combineTypes.includes(type)) {
      this.setState({
        combinedDocsDialog: {
          open: true,
          allowedDocumentTypes: get(rbac, 'write', []),
          docType: type,
        }
      })
    }

    this.setState({
      documents: {
        ...this.state.documents,
        [type]: documents
      }
    })
  }

  handleCombinedDocuments = (type, selectedTypes) => {
    this.setState({
      combinedDocsDialog: this.initialCombinedDocsDialog,
      combinedDocuments: {
        [type]: selectedTypes
      }
    })
  }

  uploadFile = () => {
    const { documents, combinedDocuments } = this.state
    const { shipment } = this.props
    const arrayOfCall = []
    const docTypes = Object.keys(documents)

    addGaEvent('shipment-uploadDocsFromList')
    if (docTypes.length) {
      docTypes.forEach(docType => {
        Object.keys(documents[docType]).forEach(fileName => {
          const file = new FormData()
          file.append('document', documents[docType][fileName])

          const data = {
            file,
            params: [shipment._id, docType, undefined, get(combinedDocuments, docType)]
          }
          this.props.upload('shipment', data, this.handleResponse, this.handleResponse, true)

          arrayOfCall.push(`response${capitalize(docType)}`)
        })
      })

      this.setState({ arrayOfCall, loading: true })
    }
  }

  handleResponse = () => {
    const { arrayOfCall } = this.state
    const { handleClose, getAllShipments } = this.props
    this.count += 1

    if (this.count === arrayOfCall.length) {
      this.setState({ documents: {}, loading: false })
      this.uploaderRefs.forEach(ref => ref && ref.resetState())
      getAllShipments()
      handleClose()
    }
  }

  render() {
    const { handleClose, open, shipment, getAllShipments, currentPlant } = this.props
    const { loading, combinedDocsDialog, attachOpenERPDialog } = this.state

    return (
      <React.Fragment>
        <FuseSideBar open={open}>
          <FuseSideBarHeader text='Upload Documents' handleClose={handleClose} />
          <FuseSideBarBody>
            <Grid container spacing={2}>
              {get(currentPlant, 'type') === 'dropHub' &&
                <Grid item xs={12}
                  className="mb-8">
                  <Grid container spacing={1}
                    className="py-12 bg-gray rounded-4 border-dashed border-2 border-grey">
                    <Grid item xs={12}
                      className="text-16 text-center bold">
                      OR</Grid>
                    <Grid item xs={12}
                      className="text-14 text-center medium">
                      Attach any open ERP documents</Grid>
                    <Grid item xs={12}>
                      <Button className="btn btn_sm btn_green m-auto"
                        onClick={this.handleOpenAttachOpenERP}
                        disabled={!shipment.rbac}>
                        Attach Open ERP</Button>
                    </Grid>
                  </Grid>
                </Grid>
              }
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {Object.keys(this.uploadInfo).map((type, index) => (
                    <Grid item xs={12} key={index}>
                      <FuseFileUploader
                        allowMultiple
                        ref={ref => this.uploaderRefs[index] = ref}
                        fileTitle={this.uploadInfo[type]}
                        type={type}
                        name={type}
                        image='assets/images/icons/Document-upload.png'
                        required={false}
                        subTitle='You can add multiple files'
                        onSuccess={this.handleUpdateDocument(type)}
                        allowedTypes={".pdf,.xls,.xlsx,.xlsm,.csv,.txt,.zip,.doc,.docx,.jpg,.jpeg,.png,.gif"}
                        maxFilesize={25}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </FuseSideBarBody>
          <FuseSideBarActions>
            <LoadingButton className='btn btn_full-space' loading={loading} onClick={this.uploadFile}>
              upload
          </LoadingButton>
          </FuseSideBarActions>
        </FuseSideBar>

        <CombinedDocsDialog {...combinedDocsDialog}
          onConfirm={this.handleCombinedDocuments}
          handleClose={() => this.setState({
            combinedDocsDialog: this.initialCombinedDocsDialog
          })} />

        <AttachOpenERPDialog {...attachOpenERPDialog}
          shipmentId={shipment._id}
          onConfirm={() => {
            this.setState({
              attachOpenERPDialog: this.initialAttachOpenERPDialog
            })
            handleClose()
            getAllShipments()
          }}
          handleClose={() => this.setState({
            attachOpenERPDialog: this.initialAttachOpenERPDialog
          })} />
      </React.Fragment>
    )
  }
}

export default UploadDialog
