import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { get, find } from 'lodash'
import GooglePlaceSearch from '../../Inputs/GooglePlaceSearch'
import DateTime from 'react-datetime'
import { general } from '../../../localization'

const BookingForm = props => {
  const {
    shipment,
    containers,
    setContainers,
    referenceEvents,
    setReferenceEvents
  } = props

  const updateReferenceEvent = (value) => {
    setReferenceEvents({
      truckInForCargoDelivery: {
        ...get(referenceEvents, 'truckInForCargoDelivery', {}),
        ...value
      }
    })
  }

  const updateContainerReferenceEvent = (valueIndex, value, copy = false) => {
    if (copy) {
      const firstActive = find(containers, i => get(i, 'status.current') !== 'cancelled')
      value = get(firstActive, 'referenceEvents.truckInWithContainerPlace', {})
    }

    const updatedContainer = containers.map((item, index) => {
      if (index === valueIndex) {
        item = {
          ...item,
          referenceEvents: {
            ...get(item, 'referenceEvents', {}),
            truckInWithContainerPlace: {
              ...get(item, 'referenceEvents.truckInWithContainerPlace', {}),
              ...value
            }
          }
        }
      }
      return item
    })
    setContainers(updatedContainer)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}
                className="semi-bold text-16">
                Delivery Schedule</Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction='row' className='stepper'>
              {get(shipment, 'type') === 'FCL' &&
                containers.map((container, containerIndex) => {
                  const type = get(container, 'type')

                  if (get(container, 'status.current') === 'cancelled') {
                    return <React.Fragment key={containerIndex} />
                  } else {
                    return (
                      // Step
                      <Grid item xs={12} key={containerIndex}
                        className='step'>
                        <Grid container>
                          {/* Step Header */}
                          <Grid xs={12} className='step-header'>
                            <Grid container direction="row">
                              <Grid item className='step-icon'>
                                <img src='assets/images/icons/Storage-facility-blue@3x.svg'
                                  alt='container' />
                              </Grid>
                              <Grid item xs>
                                <Grid container spacing={1}
                                  className="fg-dark-gray medium">
                                  <Grid item>{get(general, `container.${type}`, type)}</Grid>
                                  <Grid item>&bull;</Grid>
                                  <Grid item>{get(container, 'equipmentNo')}</Grid>
                                </Grid>
                              </Grid>
                              {containerIndex > 0 &&
                                <Grid item xs>
                                  <Grid container justify="flex-end"
                                    className="fg-dark-gray medium">
                                    <label className='fg-blue cursor-pointer'
                                      onClick={async () => updateContainerReferenceEvent(containerIndex, undefined, true)}>
                                      Copy from first</label>
                                  </Grid>
                                </Grid>
                              }
                            </Grid>
                          </Grid>
                          {/* Step Content */}
                          <Grid item xs={12} className="step-content">
                            <Grid container
                              direction='row'
                              spacing={2}>
                              <Grid item sm={12} md={12}>
                                <label className={'formsy-form-control__label require-field'}>
                                  Truck in with container</label>
                                <GooglePlaceSearch placeholder='Select street address...'
                                  className='theme-text-field'
                                  value={get(container, 'referenceEvents.truckInWithContainerPlace.location.street', '')}
                                  updateCall={(address, lat, lng) => updateContainerReferenceEvent(containerIndex, {
                                    location: {
                                      ...get(container, 'referenceEvents.truckInWithContainerPlace.location', {}),
                                      ...address,
                                      latitude: lat.toString(),
                                      longitude: lng.toString()
                                    }
                                  })} />
                              </Grid>
                              <Grid item sm={12} md={7}>
                                <TextField name='floorUnitNumber'
                                  className='theme-text-field'
                                  value={get(container, 'referenceEvents.truckInWithContainerPlace.location.floorUnitNumber', '')}
                                  label="Truck in place"
                                  fullWidth={true}
                                  InputLabelProps={{ shrink: true }}
                                  onChange={event => updateContainerReferenceEvent(containerIndex, {
                                    location: {
                                      ...get(container, 'referenceEvents.truckInWithContainerPlace.location', {}),
                                      floorUnitNumber: event.target.value
                                    }
                                  })} />
                              </Grid>
                              <Grid item sm={12} md={5}>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <label className='formsy-form-control__label require-field'>
                                      Date & Time</label>
                                  </Grid>
                                  <Grid item xs={12} className='relative'>
                                    <DateTime input
                                      className='theme-date-time-picker-field'
                                      dateFormat='DD MMM YYYY,'
                                      value={get(container, 'referenceEvents.truckInWithContainerPlace.expectedAt') &&
                                        new Date(get(container, 'referenceEvents.truckInWithContainerPlace.expectedAt'))}
                                      onChange={date => {
                                        if (new Date(date).toString() === 'Invalid Date') {
                                          date = new Date()
                                        }
                                        updateContainerReferenceEvent(containerIndex, {
                                          expectedAt: date
                                        })
                                      }} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  }
                })
              }
              {get(shipment, 'type') === 'LCL' &&
                // Step
                <Grid item xs={12}
                  className='step'>
                  <Grid container>
                    {/* Step Header */}
                    <Grid xs={12} className='step-header'>
                      <Grid container direction="row">
                        <Grid item className='step-icon'>
                          <img src='assets/images/icons/Consignee-Route-down.svg'
                            alt='container' />
                        </Grid>
                        <Grid item xs>
                          <Grid container justify="space-between" spacing={1}>
                            <Grid item className="fg-dark-gray medium">
                              Truck in for cargo delivery</Grid>
                            <Grid item className="fg-gray text-12">
                              Truck in to delivery the shipment</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Step Content */}
                    <Grid item xs={12} className="step-content">
                      <Grid container
                        direction='row'
                        spacing={2}>
                        <Grid item sm={12} md={12}>
                          <label className={'formsy-form-control__label require-field'}>
                            Truck in place</label>
                          <GooglePlaceSearch placeholder='Select street address...'
                            className='theme-text-field'
                            value={get(referenceEvents, 'truckInForCargoDelivery.location.street', '')}
                            updateCall={(address, lat, lng) => updateReferenceEvent({
                              location: {
                                ...get(referenceEvents, 'truckInForCargoDelivery.location', {}),
                                ...address,
                                latitude: lat.toString(),
                                longitude: lng.toString()
                              }
                            })} />
                        </Grid>
                        <Grid item sm={12} md={7}>
                          <TextField name='floorUnitNumber'
                            className='theme-text-field'
                            value={get(referenceEvents, 'truckInForCargoDelivery.location.floorUnitNumber', '')}
                            label="Truck in place"
                            fullWidth={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => updateReferenceEvent({
                              location: {
                                ...get(referenceEvents, 'truckInForCargoDelivery.location', {}),
                                floorUnitNumber: event.target.value
                              }
                            })} />
                        </Grid>
                        <Grid item sm={12} md={5}>
                          <Grid container>
                            <Grid item xs={12}>
                              <label className='formsy-form-control__label require-field'>
                                Date & Time</label>
                            </Grid>
                            <Grid item xs={12} className='relative'>
                              <DateTime input
                                className='theme-date-time-picker-field'
                                dateFormat='DD MMM YYYY,'
                                value={get(referenceEvents, 'truckInForCargoDelivery.expectedAt') &&
                                  new Date(get(referenceEvents, 'truckInForCargoDelivery.expectedAt'))}
                                onChange={(date) => {
                                  if (new Date(date).toString() === 'Invalid Date') {
                                    date = new Date()
                                  }
                                  updateReferenceEvent({
                                    expectedAt: date
                                  })
                                }} />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  )
}

export default BookingForm
