import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'

export default () => {
  return (
    <Grid item xs={12}
      className='shipment-details-container-section'>
      <Grid container className='shipment-events'>
        {[1, 2, 3].map((rootItem => {
          const isEvent = (rootItem % 2) === 0 ? true : false

          return (
            <Grid item xs={12} key={rootItem}
              className="shipment-events-item">
              {!isEvent &&
                <Grid container className='shipment-events-item-container'>
                  <Grid item xs={8}><Skeleton width={150} /></Grid>
                  <Grid item xs={4}>
                    <Grid container
                      justify="flex-end"
                      alignItems="center"
                      spacing={2}>
                      <Grid item xs={8}><Skeleton height={25} /></Grid>
                      <Grid item xs={2}><Skeleton width={25} height={25} /></Grid>
                      <Grid item xs={2}><Skeleton width={25} height={25} /></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
              {isEvent &&
                <Grid container className='shipment-events-item-container'
                  alignItems="center">
                  <Grid item xs={12}><Skeleton width={150} /></Grid>
                  <Grid item xs={12} className="py-12">
                    {[1, 2].map(child1Item => {
                      return (
                        <Grid container alignItems="center"
                          key={child1Item}>
                          <Grid item xs={8}><Skeleton width={150} /></Grid>
                          <Grid item xs={4}>
                            <Grid container
                              justify="flex-end"
                              alignItems="center"
                              spacing={2}>
                              <Grid item xs={8}><Skeleton height={25} /></Grid>
                              <Grid item xs={2}><Skeleton width={25} height={25} /></Grid>
                              <Grid item xs={2}><Skeleton width={25} height={25} /></Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
              }
            </Grid>
          )
        }))}
      </Grid>
    </Grid>
  )
}

