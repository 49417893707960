import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, Grid, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography } from '@material-ui/core'
import * as Actions from '../store/actions'
import SubToolBarLayout from '../../core/containers/Layout/SubToolBarLayout'
import { FusePlaceHolder, LoadMore } from '../../core/components'
import { formatTimestamp } from '../../core/helpers'
import Skeleton from 'react-loading-skeleton'
import { get, find } from 'lodash'
import ViewXmlBody from '../components/ViewXmlBody'
import ViewError from '../components/ViewError'

const ErpTransmissions = props => {
  const {
    erpTransmissions,
    getErpTransmissions
  } = props

  const { loading, data, hasMore } = erpTransmissions
  const [viewXmlBodyDialog, setViewXmlBodyDialog] = useState(false)
  const [pageNo, setPageNo] = useState(1)
  const [viewErrorDialog, setViewErrorDialog] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const pageSize = 10

  const handleLoadMore = () => {
    const increasedPageNo = pageNo + 1

    setLoadingMore(true)
    getErpTransmissions(
      { pageNo: increasedPageNo, pageSize },
      () => setLoadingMore(false),
      () => setLoadingMore(false)
    )
    setPageNo(increasedPageNo)
  }

  useEffect(() => {
    getErpTransmissions({ pageNo, pageSize })

    return () => {
      setPageNo(1)
      setViewXmlBodyDialog(false)
      setViewErrorDialog(false)
      setLoadingMore(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <SubToolBarLayout text='ERP Transmissions' />
      <Grid container className="p-20 pb-72">
        <Grid item xs={12}>

          {loading &&
            <Grid container spacing={2}>
              {[1, 2, 3].map(i => {
                return (
                  <Grid item xs={12} key={i}>
                    <Skeleton height={70} />
                  </Grid>
                )
              })}
            </Grid>
          }

          {!loading &&
            <Table className='custom-table'>
              <TableHead className='table-head'>
                <TableRow className='table-row'>
                  <TableCell className='table-cell'>Entry&nbsp;Time</TableCell>
                  <TableCell className='table-cell'>ERP&nbsp;ID</TableCell>
                  <TableCell className='table-cell'>Planning&nbsp;Point</TableCell>
                  <TableCell className='table-cell'>Shipment&nbsp;Number</TableCell>
                  <TableCell className='table-cell'>Status</TableCell>
                  <TableCell className='table-cell'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className='table-body'>
                {data.map((item, itemIndex) => {
                  const updateSituations = get(item, 'updates.situation', [])
                  const isProcessed = find(updateSituations, { stage: 'processing', status: 'completed' }) ? true : false

                  return (
                    <TableRow className='table-row' key={itemIndex}>
                      <TableCell className='table-cell'>{formatTimestamp(get(item, 'meta.entryTime'), 'date-time')}</TableCell>
                      <TableCell className='table-cell'>{get(item, 'meta.ERPID')}</TableCell>
                      <TableCell className='table-cell'>{get(item, 'meta.TransportationPlanningPoint')}</TableCell>
                      <TableCell className='table-cell'>{get(item, 'meta.ShipmentNum')}</TableCell>
                      <TableCell className='table-cell'>
                        <Typography className={'text-14 regular ' + (isProcessed ? 'fg-green' : 'fg-red')}>
                          {isProcessed ? 'Processed' : 'Error'}
                        </Typography>
                      </TableCell>
                      <TableCell className='table-cell'>
                        <Grid container spacing={2} justify="flex-end" alignItems="center">
                          {!isProcessed &&
                            <Grid item>
                              <Button className="btn btn_sm btn_light_orange"
                                onClick={() => setViewErrorDialog({
                                  open: true,
                                  errors: updateSituations
                                })}>
                                view error</Button>
                            </Grid>
                          }
                          <Grid item>
                            <Button className="btn btn_sm btn_light-blue"
                              onClick={() => setViewXmlBodyDialog({
                                open: true,
                                xmlBody: get(item, 'meta.xmlBody')
                              })}>
                              view xml</Button>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          }

          {!loading && data.length === 0 &&
            <FusePlaceHolder icon="info"
              title="No ERP Transmissions"
              description="Looks like you don't have nay ERP Transmissions available yet." />
          }

        </Grid>

        {hasMore &&
          <Grid item xs={12}>
            <Grid container justify="center">
              <LoadMore loading={loadingMore}
                onClick={handleLoadMore} />
            </Grid>
          </Grid>
        }
      </Grid>

      {/* Dialogs */}
      <ViewXmlBody open={get(viewXmlBodyDialog, 'open', false)}
        xmlBody={get(viewXmlBodyDialog, 'xmlBody', '')}
        handleClose={() => setViewXmlBodyDialog(false)} />

      <ViewError open={get(viewErrorDialog, 'open', false)}
        errors={get(viewErrorDialog, 'errors', [])}
        handleClose={() => setViewErrorDialog(false)} />
      {/* End of Dialogs */}
    </React.Fragment>
  )
}

const styles = {}

const mapStateToProps = ({ erpTransmissions }) => ({
  erpTransmissions,
})

const mapDispatchToProps = dispatch => ({
  getErpTransmissions: (...args) => dispatch(Actions.getErpTransmissions(...args)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ErpTransmissions))
