import { create } from 'apisauce'
import history from '../history'
import appStore from '../store'
import * as CoreActions from './store/actions'
import * as AuthActions from 'auth/store/actions'
import { showLoading, hideLoading } from "react-redux-loading-bar";
import queryString from 'query-string'
import { get } from 'lodash'
import { getAPIBaseURL } from './helpers'

const { store } = appStore
const securityHeaders = {
  "Content-Security-Policy": "default-src=self",
  "Strict-Transport-Security": "max-age=31536000",
  "X-XSS-Protection": "X-XSS-Protection=1; mode=block",
  "X-Content-Type-Options": "nosniff",
}
let apiFailCountTimer = null

const startApiFailCountResetter = () => {
  apiFailCountTimer = setTimeout(() => {
    localStorage.setItem('apiCallFailCount', 0)
  }, 1000)
}
const stopApiFailCountResetter = () => {
  clearTimeout(apiFailCountTimer)
}

const API = create({
  baseURL: getAPIBaseURL(),
  headers: securityHeaders
})
const APIBin = create({
  baseURL: getAPIBaseURL(),
  responseType: 'arraybuffer',
  timeout: 45000,
  headers: securityHeaders
})

const setNetworkOffline = async () => {
  const apiCallFailCount = Number(localStorage.getItem('apiCallFailCount') || 0)
  stopApiFailCountResetter()
  startApiFailCountResetter()
  if (apiCallFailCount > 1) {
    store.dispatch(CoreActions.setNetworkDownStatus(true))
  } else {
    store.dispatch(CoreActions.setNetworkDownStatus(false))
  }
  await localStorage.setItem('apiCallFailCount', apiCallFailCount + 1)
}

API.addMonitor(response => {
  const { ok, problem } = response

  if (!ok && problem === 'NETWORK_ERROR') {
    setNetworkOffline()
  } else {
    store.dispatch(CoreActions.setNetworkDownStatus(false))
  }
})

API.addResponseTransform(response => {
  const { ok, data, problem } = response

  if (!ok) {
    if (response.status === 403 && response.data && response.data.code === 'TM011') {
      let { auth } = store.getState()
      if (auth.info.status !== 'invited') {
        store.dispatch(CoreActions.setTokenRedirect(history.location.pathname))
        history.push('/refresh-token')
      }
    } else if (response.status === 401 && response.data && response.data.code === 'TM006') {
      store.dispatch(AuthActions.authReset())
      console.log(history)
      if (get(history, 'location.state.isLoginProcess', false)) {
        const { pathname, search, hash } = get(history, 'location', {})
        history.replace(`${pathname}${search}${hash}`, { isLoginProcess: true })
      } else {
        history.push('/login')
      }
    }

    switch (problem) {
      case 'CLIENT_ERROR':
        response.data = {
          status: 'error',
          ...data
        }
        break

      case 'TIMEOUT_ERROR':
        response.status = 408
        response.data = {
          status: 'error',
          message: 'Network timeout. Please try again.',
          ...data
        }
        break

      case 'CONNECTION_ERROR':
        response.status = 503
        response.data = {
          status: 'error',
          message: 'Server not available.',
          ...data
        }
        break

      case 'NETWORK_ERROR':
        response.status = 511
        response.data = {
          status: 'error',
          message: 'Network unavailable.',
          ...data
        }
        break

      case 'CANCEL_ERROR':
        response.status = 500
        response.data = {
          status: 'error',
          message: 'Request has been cancelled.',
          ...data
        }
        break

      default:
        response.status = 500
        response.data = {
          status: 'error',
          message: 'System error.',
          ...data
        }
    }
  }
})

export const setTokenHeader = token => {
  if (token) {
    localStorage['Authorization'] = token
    API.setHeader('Authorization', token)
    APIBin.setHeader('Authorization', token)
  } else {
    delete localStorage['Authorization']
    delete API.headers['Authorization']
    delete APIBin.headers['Authorization']
  }
}
export const setContextPlantHeader = currentPlantId => {
  if (currentPlantId) {
    API.setHeader('context-plant', currentPlantId)
    APIBin.setHeader('context-plant', currentPlantId)
  } else {
    delete API.headers['context-plant']
  }
}

/* --------- APIs ---------- */

export const getVersion = () => makeCall('GET', `info`)

/* --------- Auth ---------- */
export const login = payload => makeCall('POST', `auth/token/bearer`, payload)
export const exchangeToken = payload => makeCall('POST', `auth/exchange-token/bearer`, payload)
export const verifyChallenge = payload => makeCall('POST', `auth/challenge/verify`, payload)
export const getInfo = params => makeCall('GET', `auth/userinfo`, params)
export const invalidateToken = () => new Promise(resolve => {
  store.dispatch(showLoading())
  setTimeout(async () => {
    await API['post']('auth/token/bearer/invalidate').then(resolve)
    store.dispatch(hideLoading())
  }, 0)
})
export const activateUser = payload => makeCall('PUT', `auth/activate`, payload)
export const searchUsername = params => makeCall('GET', `users/exists`, params)
export const updateProfile = payload => makeCall('PUT', `auth/userinfo`, payload)
export const updatePassword = payload => makeCall('PUT', `auth/password/update`, payload)
export const resendOTP = payload => makeCall('POST', `auth/send/otp`, payload)
export const refreshToken = payload => makeCall('POST', `auth/token/bearer/refresh`, payload)
export const forgotPassword = payload => makeCall('POST', `auth/forgot-password/send/otp`, payload)
export const resetPassword = payload => makeCall('PUT', `auth/forgot-password/update`, payload)

export const fetchUserOrganizations = payload => makeCall('POST', `users/fetch-organization`, payload)
/* ------------------------- */

/* --------- File Upload ---------- */
export const uploadCsvFile = payload => makeCall('POST', `outbounds/upload/erp-export-v1/csv`, payload)
export const uploadXlsxFile = payload => makeCall('POST', `outbounds/upload/erp-export-v1/xlsx`, payload)
export const uploadMltrcFile = payload => makeCall('POST', `service-cards/upload/mltrc-v1/no-wait`, payload)
export const uploadRouteFile = payload => makeCall('POST', `routes/upload/route-master-v1/no-wait`, payload)
export const uploadBenchmarkFile = payload => makeCall('POST', `service-cards/upload/kpi-v1/no-wait`, payload)
export const uploadShipmentFile = (payload, id, docType, equipmentId, others) => {
  const queryParams = {}
  if (equipmentId) {
    queryParams.equipmentId = equipmentId
  }
  if (others) {
    queryParams.others = others.join(',')
  }
  const stringifyQuery = queryString.stringify(queryParams)

  return makeCall('POST', `/shipments-v1.1/${id}/documents/${docType}?${stringifyQuery}`, payload)
}
export const reUploadShipmentFile = (shipmentId, docType, docStoreId, payload) => {
  return makeCall('POST', `shipments-v1.1/${shipmentId}/documents/${docType}/re-upload/${docStoreId}`, payload)
}
export const transferDocOwnership = (shipmentId, docStoreId, payload) => {
  return makeCall('POST', `shipments-v1.1/${shipmentId}/documents/${docStoreId}/falcon-transfer`, payload)
}

export const getUploadStatus = batchId => makeCall('GET', `batch-job/${batchId}`)
export const getErpDocuments = (params) => makeCall('GET', `erp-documents`, params)

export const uploadFileById = (fileId, payload) => makeCall('POST', `files/${fileId}`, payload)
export const getFileById = (fileId) => makeCallBinary('GET', `files/${fileId}`)
/* ------------------------- */

/* --------- Service Cards ---------- */
export const getAllServiceCards = (params = {}) => makeCall('GET', `service-cards`, params)
export const getLimitedServiceCards = (params = {}) => makeCall('GET', `service-cards/limited-service-card-info/{itineraryId}`, params)
export const getConsigneeServiceCards = (params = {}) => makeCall('GET', `service-cards/list/consignee`, params)
export const getRouteConsignee = (params = {}) => makeCall('GET', `service-cards/list/consignee/shipper`, params)
/* ------------------------- */

/* ---------- Outbounds ---------- */
export const getAllOutbounds = (params = {}) => makeCall('GET', `outbounds`, params)
export const getOutbounds = () => makeCall('GET', `outbounds/groupBy/shipToParty/groupage`)
export const getOpenOutbounds = (params = {}) =>
  makeCall('GET', `outbounds/groupBy/shipToParty/groupage?status[]=open`, params)
export const getClosedOutbounds = (params = {}) => makeCall('GET', `outbounds`, params)
export const closeOutbounds = ids => makeCall('DELETE', `outbounds/close`, {}, { data: { ids } })
export const openClosedOutbounds = ids => makeCall('PUT', `outbounds/open`, { ids })
export const getOutboundsbyShipment = shipmentId => makeCall('GET', `shipments/${shipmentId}/outbounds`)
export const getUserNotifications = params => makeCall('GET', `notifications`, params)
export const markNotificationsAsRead = params => makeCall('POST', `notifications/update/notifications`, params)
/* -----------User Settings-------------- */
export const setUserSettings = payload => makeCall('POST', `user-settings/set`, payload)
export const deleteCustomFilter = (key, id) => makeCall('DELETE', `user-settings/remove/${key}/${id}`)
export const getUserSettings = (params = {}) => makeCall('GET', `user-settings`, params)
export const loadDocuments = () => makeCall('GET', `user-settings/documents-list`)
export const setUpdateUserSettings = (params = {}) => makeCall('POST', `user-settings/set-update`, params)
export const setBulkUserSettings = (params = {}) => makeCall('POST', `user-settings/set-bulk`, params)
export const getEventsListUserSettings = (params = {}) => makeCall('GET', `user-settings/events-list`, params)
export const updateUserSettings = (id, params = {}) => makeCall('PUT', `user-settings/update/${id}`, params)
export const deleteUserSettings = (id, key, params = {}) => makeCall('DELETE', `user-settings/remove/${key}/${id}`, params)

/* ---------- Shipments ---------- */
export const getAllShipments = (params = {}) => makeCall('GET', `shipments`, params)
export const getAllShipmentsWithFilter = (params = {}, payload) => makeCall('POST', `shipments/list`, payload, { params })
export const getAllShipmentsLightWithFilter = (params = {}, payload) => makeCall('POST', `shipments/list/light`, payload, { params })
export const getSingleShipment = (id, params = {}) => makeCall('GET', `shipments/${id}`, params)
export const createShipment = payload => makeCall('POST', `shipments-v1.1/booking/create`, payload)
export const getInitialPreps = outboundIds =>
  makeCall('POST', `shipments/prep/initial-state`, { outboundIds, status: 'open' })
export const getUpdatePreps = payload => makeCall('POST', `shipments/prep/update-state`, payload)
export const getAllShipmentsFilters = () => makeCall('GET', `/shipments/list/filters`)
export const markShipmentAsException = (payload, shipmentId) =>
  makeCall('PUT', `/shipments/${shipmentId}/raise-exception`, payload)
export const exportFreightPartnerAcceptBooking = (payload, shipmentId) =>
  makeCall('PUT', `/shipments-v1.1/${shipmentId}/booking-accept/export-freight-partner`, payload)
export const exportFreightPartnerUpdateBooking = (payload, shipmentId) =>
  makeCall('PUT', `/shipments/${shipmentId}/booking-update/export-freight-partner`, payload)
export const exportFreightPartnerRejectBooking = (payload, shipmentId) =>
  makeCall('PUT', `/shipments/${shipmentId}/booking-reject/export-freight-partner`, payload)
export const exportLogisticsPartnerAcceptBooking = (payload, shipmentId) =>
  makeCall('PUT', `/shipments-v1.1/${shipmentId}/booking-accept/export-logistics-partner`, payload)
export const exportLogisticsPartnerUpdateBooking = (payload, shipmentId) =>
  makeCall('PUT', `/shipments/${shipmentId}/booking-update/export-logistics-partner`, payload)
export const exportLogisticsPartnerRejectBooking = (payload, shipmentId) =>
  makeCall('PUT', `/shipments/${shipmentId}/booking-reject/export-logistics-partner`, payload)
export const consigneeCompleteBooking = (payload, shipmentId) =>
  makeCall('PUT', `/shipments/${shipmentId}/booking-complete/consignee`, payload)
export const uploadShipmentDocuments = (id, docType, payload) =>
  makeCall('POST', `/shipments/${id}/document/${docType}`, payload)
export const updateShipment = (id, payload) => makeCall('POST', `/shipments/${id}/booking/update/no-amend`, payload)
export const updateShipmentCS = (payload, id) =>
  makeCall('PUT', `/shipments/${id}/consolidation/outbounds/bulk-edit`, payload)
export const amendShipment = (id, payload) => makeCall('POST', `/shipments/${id}/booking/update/amend`, payload)
export const getShipmentDocuments = (id, params = {}) => makeCall('GET', `shipments/${id}/documents`, params)
export const getShipmentEventWriteList = id => makeCall('GET', `shipments/${id}/events/write-list`)
export const getShipmentEventWriteListV1 = id => makeCall('GET', `shipments/${id}/events/write-list/v1.1`)
export const addShipmentEvents = (id, eventCode, eventType, payload = {}, rbac) =>
  makeCall('POST', `shipments-v1.1/${id}/events/${eventCode}/${eventType}?rbac=${rbac}`, payload)
export const getShipmentDocument = (id, docId, params = {}) =>
  makeCallBinary('GET', `shipments/${id}/documents/${docId}`, params)
export const getShipmentDocumentByType = (shipmentId, docKey, params = {}) =>
  makeCallBinary('GET', `shipments/${shipmentId}/documents/by-type/${docKey}`, params)

export const deleteShipmentDocument = (id, docId, params = {}) =>
  makeCall('DELETE', `shipments/${id}/documents/${docId}`, params)
export const getShipmentMeta = id => makeCall('GET', `shipments/${id}/documents/meta/rbac`)
export const getShipmentEvents = (id, params = {}) => makeCall('GET', `shipments/${id}/events`, params)

export const updateBLNumbers = (payload, id) => makeCall('PUT', `/shipments/${id}/bl`, payload)
export const updateCarrierInfo = (payload, id) => makeCall('PUT', `/shipments/${id}/booking-edit/carrier-info`, payload)
export const updateShipmentContainer = (payload, id) => makeCall('PUT', `/shipments/${id}/booking-edit/containers`, payload)
export const cancelShipmentContainer = (payload, id, containerId) => makeCall('PUT', `/shipments/${id}/cancel/container/${containerId}`, payload)
export const getShipmentBookingOptions = payload => makeCall('POST', `/shipments/booking/options`, payload)
export const resolveShipmentConflict = (payload, id) => makeCall('PUT', `/shipments-v1.1/${id}/exception/resolve/XRP002`, payload)
export const markShipmentAsSpot = (payload, id) =>
  makeCall('PUT', `/shipments-v1.1/${id}/exception/resolve/XRP002/fallback-to/XRP003`, payload)
export const resolveShipmentSpot = (payload, id) => makeCall('PUT', `/shipments-v1.1/${id}/exception/resolve/XRP003`, payload)
export const amendShipmentWithNoChange = (payload, id) =>
  makeCall('POST', `/shipments/${id}/booking/amend/no-change`, payload)
export const cancelAndMoveToSpot = (payload, id) => makeCall('POST', `/shipments-v1.1/booking/restart/${id}`, payload)
export const cancelShipment = (payload, id) => makeCall('PUT', `/shipments-v1.1/${id}/booking/cancel`, payload)
export const addShipmentContainer = (payload, id) => makeCall('PUT', `/shipments/${id}/add/container`, payload)
export const updateShipmentSingleContainer = (payload, id, containerId) =>
  makeCall('PUT', `/shipments/${id}/update/container/${containerId}`, payload)
export const getShipmentCounts = (payload = {}) => makeCall('GET', `/shipments/booking/info/list`, payload)
export const sendPreAlert = (payload = {}, id) => makeCall('POST', `shipments/${id}/send-pre-alert`, payload)
export const getPreAlertLogs = (id, payload = {}) => makeCall('GET', `shipments/${id}/pre-alert-logs`, payload)
export const addConsolidation = (id, payload = {}) => makeCall('POST', `shipments/${id}/outbounds/create/new`, payload)
export const sendShipmentManualNotification = (id, payload = {}) => makeCall('POST', `shipments/${id}/notify`, payload)
export const getShipmentGoodsReceived = (id, payload = {}) => makeCall('GET', `shipments/${id}/goods-received`, payload)
export const attachErpDocuments = (id, docType, erpDocId) => makeCall('POST', `shipments/${id}/erp-documents/${docType}/${erpDocId}`)
export const resolveFpConflict = (id, params) => makeCall('PUT', `shipments/${id}/freight-partner-conflict/resolve`, params)

export const saveInboundShipment = (params) => makeCall('POST', `shipments-inbound/decouple-shipment`, params)
export const addInboundToShipment = (shipmentId, params) => makeCall('PUT', `shipments-inbound/${shipmentId}/add-to-existing`, params)
/* ------------------------- */

/* ---------- Inbound Shipments ---------- */
export const addCustomClearance = (id, params = {}) => makeCall('PUT', `shipments-inbound/${id}/custom-clearance`, params)
export const approveCustomReview = (id, params = {}) => makeCall('PUT', `shipments-inbound/${id}/custom/review/approve`, params)
export const rejectCustomReview = (id, params = {}) => makeCall('PUT', `shipments-inbound/${id}/custom/review/reject`, params)
export const scheduleDelivery = (id, params = {}) => makeCall('PUT', `shipments-inbound/${id}/schedule`, params)
export const shipmentWriteEventByCode = (shipmentId, eventCode) => makeCall('GET', `shipments-v1.1/${shipmentId}/events-list/${eventCode}/actual`)
export const getTTAuthorization = (id, docStoreId) => makeCall('GET', `shipments-v1.1/${id}/documents/${docStoreId}/redirect-auth`)
/* ------------------------- */

/* ---------- Users ---------- */
export const getAllUsers = (params = {}) => makeCall('GET', `users`, params)
export const getUserByEmail = (params = {}) => makeCall('GET', `users/search`, params)
export const getPlantUsers = (plantId, status, multiSearch) =>
  makeCall('GET', `plants/${plantId}/users`, { status, 'multi-search': multiSearch })

export const deleteUserFromPlant = (user_id, plant_id) => makeCall('DELETE', `plants/${plant_id}/users/${user_id}/remove`)
export const addUserForPlant = (payload, plant_id) => makeCall('POST', `plants/${plant_id}/users`, payload)
export const addUserByPlantIds = (payload = {}) => makeCall('GET', `users/search-email`, payload)
/* ------------------------- */

/* ---------- Plant ---------- */
export const getPlants = (payload = {}) => makeCall('GET', `plants`, payload)
export const updatePlantDetails = (payload, plant_id) => makeCall('PUT', `plants/${plant_id}`, payload)
/* ------------------------- */

/* ---------- Vessels ---------- */
export const getVesselPositions = () => makeCall('GET', `vessels`)
export const getVesselInformation = imo => makeCall('GET', `vessels/${imo}`)
/* ------------------------- */

/* ---------- Settings ---------- */
export const getCesiumToken = () => makeCall('GET', `settings/getToken/cesium`)
/* ------------------------- */

/* ---------- System Settings ---------- */
export const getSystemSettings = () => makeCall('GET', `settings`)
export const getSettingByType = (type) => makeCall('GET', `settings/${type}/check`)
export const getSettingById = (key, id = 'system') => makeCall('GET', `settings/${id}/get/${key}`)
export const getSettingAppId = () => makeCall('GET', `settings/appId`)
export const getSettingApiKey = () => makeCall('GET', `settings/api-key`)
/* ------------------------- */

/* ---------- Plant Settings ---------- */
export const getPlantSettings = (params = {}) => makeCall('GET', `settings/get/plant`, params)
export const setPlantSettings = (params = {}) => makeCall('POST', `settings/set/plant`, params)
export const updatePlantSettings = (id, params = {}) => makeCall('PUT', `settings/set/plant/${id}`, params)
export const deletePlantSettings = (id, params = {}) => makeCall('DELETE', `settings/delete/plant/${id}`, params)
/* ------------------------- */

/* ---------- Auth Providers ---------- */
export const getAuthProvidersByAppId = (appid) => makeCall('GET', `auth-provider/${appid}/list`)
/* ------------------------- */

/* ---------- Shipment Tracker ---------- */
export const getShipmentTrack = (params = {}) => makeCall('GET', `tracker/internal`, params)
/* ------------------------- */

/* ---------- Analytics ---------- */
export const getAnalyticsToken = () => makeCall('GET', `settings/token/tableau`)
/* ------------------------- */

/* ---------- Email Tracker ---------- */
export const getEmailLogs = (params = {}, pageNo, pageSize) => makeCall('POST', `email-logs?pageNo=${pageNo}&pageSize=${pageSize}`, params)
/* ------------------------- */

/* ---------- Posts ---------- */
export const addPosts = (params = {}) => makeCall('POST', `posts`, params)
export const addPostsComment = (postId, param) => makeCall('POST', `posts/${postId}/comment`, param)
export const getPosts = (params = {}) => makeCall('GET', `posts`, params)
export const updatePosts = (postId, params = {}) => makeCall('PUT', `posts/${postId}`, params)
export const getPostById = (postId, params = {}) => makeCall('GET', `posts/${postId}`, params)
export const openPost = (postId) => makeCall('PUT', `posts/${postId}/open`)
export const closePost = (postId) => makeCall('PUT', `posts/${postId}/close`)
/* ------------------------- */

/* ---------- Pre Alert Emails ---------- */
export const getPreAlertEmails = (params = {}) => makeCall('GET', `pre-alerts/inbound`, params)

/* ---------- ERP Transmissions ---------- */
export const getErpTransmissions = (params = {}) => makeCall('GET', `erp-documents/processor-records`, params)
/* ------------------------- */

/* ---------- Tasks ---------- */
export const getTasksTemplates = (params = {}) => makeCall('GET', `task-template`, params)
export const getShipmentTasks = (params = {}) => makeCall('GET', `shipment-task`, params)
export const getControllerDictionary = (params = {}) => makeCall('GET', `shipment-task/controller-dictionary`, params)
export const createShipmentTask = (params = {}) => makeCall('POST', `shipment-task`, params)
export const markShipmentTaskComplete = (taskId, params = {}) => makeCall('PUT', `shipment-task/${taskId}/complete`, params)
export const getSingleShipmentTasks = (shipmentId, params = {}) => makeCall('GET', `shipments-v1.1/${shipmentId}/tasks`, params)
export const assignShipmentTaskUser = (taskId, params = {}) => makeCall('PUT', `shipment-task/${taskId}/assign`, params)
export const addShipmentTaskDueDate = (taskId, params = {}) => makeCall('PUT', `shipment-task/${taskId}/due-date`, params)
export const getEventDocList = (params = {}) => makeCall('GET', `shipment-task/event-doc-list`, params)
export const changePriority = (taskId, params = {}) => makeCall('PUT', `shipment-task/${taskId}/change/priority`, params)
export const deleteTask = (taskId, params = {}) => makeCall('DELETE', `shipment-task/${taskId}`, params)
export const updateTaskPostLink = (taskId, params = {}) => makeCall('PUT', `shipment-task/${taskId}/link/posts`, params)
/* ------------------------- */

/* ---------- Task Templates ---------- */
export const addTaskTemplate = (params = {}) => makeCall('POST', `task-template`, params)
export const getTaskTemplates = (params = {}) => makeCall('GET', `task-template`, params)
export const getTaskTemplateDetails = (taskId, params = {}) => makeCall('GET', `task-template/${taskId}`, params)
/* ------------------------- */

const makeCall = (method, ...args) =>
  new Promise(resolve => {
    store.dispatch(showLoading())
    setTimeout(async () => {
      await API[method.toLowerCase()](...args).then(resolve)
      store.dispatch(hideLoading())
    }, 0)
  })

const makeCallBinary = (method, ...args) =>
  new Promise(resolve => {
    store.dispatch(showLoading())
    setTimeout(async () => {
      await APIBin[method.toLowerCase()](...args).then(resolve)
      store.dispatch(hideLoading())
    }, 0)
  })
