import React from 'react'
import { withStyles } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import InputBase from '@material-ui/core/InputBase'
import MenuItem from '@material-ui/core/MenuItem'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFFFFF',
    border: '1px solid #ced4da',
    fontSize: '12px',
    lineHeight: '16px',
    padding: '12px 26px 12px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: 'IBMPlexSans-SemiBold',
    '&:focus': {
      borderRadius: 4,
      border: '1px solid #ced4da',
      backgroundColor: '#FFFFFF',
      boxShadow: 'none'
    }
  }
}))(InputBase)

const StatusFilter = ({ onSelectionChange }) => {
  const [status, setStatus] = React.useState('all')

  const handleChange = event => {
    const { value } = event.target
    setStatus(value)
    onSelectionChange(value)
  }

  return (
    <FormControl fullWidth>
      <Select
        id='demo-customized-select'
        value={status}
        onChange={handleChange}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          }
        }}
        input={<BootstrapInput />}
      >
        <MenuItem value='all' selected>
          All
        </MenuItem>
        <MenuItem value='pending'>Pending</MenuItem>
        <MenuItem value='amended'>Amended</MenuItem>
        <MenuItem value='rejected'>Rejected</MenuItem>
        <MenuItem value='confirmed_by_efp'>Confirmed by EFP</MenuItem>
        <MenuItem value='confirmed_by_elp'>Confirmed by ELP</MenuItem>
      </Select>
    </FormControl>
  )
}

const styles = () => ({
  bordered: {
    border: '1px solid #e3e3e4',
    borderRadius: 4
  }
})

export default withStyles(styles)(StatusFilter)
