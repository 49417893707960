import React, { useEffect } from 'react'
import { List, Grid } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import FuseNavVerticalGroup from './FuseNavVerticalGroup'
import FuseNavVerticalItem from './FuseNavVerticalItem'
import { getNavigationConfig } from './navItems'
import packageInfo from '../../../../package.json'
import * as CoreActions from 'core/store/actions'

const FuseNavigation = ({ className, currentPlant, getVersion, apiVersion, shipmentCounts }) => {
  useEffect(() => {
    getVersion()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <List className={classNames('navigation whitespace-no-wrap', className)}
        style={{
          maxHeight: 'calc(100% - 60px)',
          overflow: 'auto'
        }}>
        {getNavigationConfig(currentPlant.type, currentPlant.role, shipmentCounts).map(item => (
          <React.Fragment key={item.id}>
            {item.type === 'group' && <FuseNavVerticalGroup item={item} nestedLevel={0} />}
            {item.type === 'item' && <FuseNavVerticalItem item={item} nestedLevel={0} />}
          </React.Fragment>
        ))}
      </List>
      <Grid container
        className='fg-gray px-16 py-12 absolute pin-b text-12'>
        <Grid item xs={12} className='mb-4'>
          <Grid container justify="center">
            <Grid item>
              <span>v{packageInfo.version}</span>
              <span className='ml-4'>(Client)</span>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item>
              <span>v{apiVersion}</span>
              <span className='ml-4'>(API)</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

const mapStateToProps = ({ welcome, core: { apiVersion, shipmentCounts } }) => ({
  currentPlant: welcome.currentPlant,
  apiVersion,
  shipmentCounts
})

const mapDispatchToProps = dispatch => ({
  getVersion: () => dispatch(CoreActions.getVersion())
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FuseNavigation))
