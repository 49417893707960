import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  SET_PRE_ALERT_EMAILS_SUCCESS
  LOADING_MORE_PRE_ALERT_EMAILS
  LOAD_MORE_PRE_ALERT_EMAILS_SUCCESS
  SET_PROCESSED_PRE_ALERT_EMAILS_SUCCESS
  LOADING_MORE_PROCESSED_PRE_ALERT_EMAILS
  LOAD_MORE_PROCESSED_PRE_ALERT_EMAILS_SUCCESS

  LOADING_SHIPMENTS
  SET_SHIPMENTS_SUCCESS
  LOADING_MORE_SHIPMENTS
  LOAD_MORE_SHIPMENTS_SUCCESS
  `
)
