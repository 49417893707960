import React from 'react'
import { Grid } from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'

const ListSkeleton = () => {
  return (
    <Grid container spacing={2}>
      {[1, 2, 4].map(item =>
        <Grid item key={item} xs={12}>
          <Skeleton height={90} />
        </Grid>
      )}
    </Grid>
  )
}

export default ListSkeleton
