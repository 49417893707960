import React, { useEffect } from 'react'
import {
  withStyles,
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { useMergeState } from '../../helpers'
import { get } from "lodash";

const ThemeSettingsForm = (props) => {
  const [state, setState] = useMergeState({})
  const {
    classes,
    appTheme,
    onChange,
    resetForm
  } = props

  useEffect(() => {
    setState({
      appTheme
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appTheme])

  useEffect(() => {
    if (resetForm === 'appTheme') {
      setState({
        appTheme
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm])

  const handleChange = (event) => {
    const { name, value } = event.target

    setState({
      [name]: value
    })
    onChange(name, value)
  }

  return (
    <Grid container className="p-20">

      <Grid item xs={12}>
        <Grid container className="mb-24">
          <Grid item xs={12}>
            <label className="bold text-16">
              Change the appearance</label>
          </Grid>
          <Grid item xs={12}>
            <label className="fg-gray text-12">
              Customize the look, Only you will see this…</label>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container
          className="mb-28"
          alignItems="center">
          <Grid item xs={12}>
            <RadioGroup name="appTheme"
              value={get(state, 'appTheme', appTheme)}
              onChange={handleChange}>
              <Grid container
                alignItems="center"
                spacing={5}>
                <Grid item>
                  <FormControlLabel value="light"
                    classes={classes}
                    control={<Radio />} label="Light mode" />
                </Grid>
                <Grid item>
                  <FormControlLabel value="dark"
                    classes={classes}
                    control={<Radio />} label="Dark mode" />
                </Grid>
              </Grid>
            </RadioGroup>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  )
}

const styles = theme => ({
  root: {
    marginRight: '0px'
  },
  label: {
    color: '#333333',
    fontFamily: 'IBMPlexSans-Medium',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',
    textAlign: 'left',
  }
})

export default withStyles(styles)(ThemeSettingsForm)
