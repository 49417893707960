import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab, Typography, Box } from '@material-ui/core'
import SubToolBarLayout from 'core/containers/Layout/SubToolBarLayout'
import Grid from '@material-ui/core/Grid'
import { FuseIconButton } from 'core/components'
import * as ClosedActions from '../store/actions'
import { CSVLink } from 'react-csv'
import OutboundList from '../components/OutboundList'
import { getClosedOutbounds } from '../../core/api'
import { get } from 'lodash'

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component='div'
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={'w-full overflow-hidden'}
    {...other}>
    <Box>{children}</Box>
  </Typography>
)

class Closed extends Component {
  state = {
    openOutboundDialog: false,
    requesting: false,
    pageSize: 10,
    pageNo: 1,
    excludePageNo: 1,
    tabValue: 0,
    allClosed: [],
    allExcluded: []
  }

  openSelectedOutbounds = async () => {
    this.setState({ requesting: true })
    await this.props.openSelectedOutbounds()
    this.props.getAllClosedOutbounds()
    this.setState({
      openOutboundDialog: false,
      requesting: false
    })
  }

  getExportableOutbounds = async () => {
    const closed = await getClosedOutbounds({ status: ['closed'] })
    const exclude = await getClosedOutbounds({ status: ['closed'], exclude: true })

    this.setState({
      allClosed: get(closed, 'data.data', []).map(outbound => ({
        cs: outbound.meta.CSCode,
        outbound: outbound.meta.outboundId,
        label: [
          outbound.shipmentMode,
          outbound.goodsType]
          .filter(Boolean)
          .join(','),
        weight: `${outbound.weight.value} ${outbound.weight.unit}`,
        volume: `${outbound.volume.value} ${outbound.volume.unit}`,
        consignee: outbound.shipToParty.name
      })),
      allExcluded: get(exclude, 'data.data', []).map(outbound => ({
        cs: outbound.meta.CSCode,
        outbound: outbound.meta.outboundId,
        label: [
          outbound.shipmentMode,
          outbound.goodsType]
          .filter(Boolean)
          .join(','),
        weight: `${outbound.weight.value} ${outbound.weight.unit}`,
        volume: `${outbound.volume.value} ${outbound.volume.unit}`,
        consignee: outbound.shipToParty.name
      }))
    })
  }

  a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  handleTabChange = (event, tabValue) => {
    const { selectNone } = this.props
    this.setState({ tabValue })
    selectNone()
  }

  handleLoadMore = () => {
    const { pageNo, excludePageNo, pageSize, tabValue } = this.state
    const { getAllClosedOutbounds } = this.props
    let newPageNo = 1

    if (tabValue === 0) {
      newPageNo = pageNo + 1
      getAllClosedOutbounds({ pageSize, pageNo: newPageNo })
    } else {
      newPageNo = excludePageNo + 1
      getAllClosedOutbounds({ pageSize, pageNo: newPageNo }, 'exclude')
    }
    this.setState({ pageNo: newPageNo })
  }

  componentDidMount() {
    const { pageSize, pageNo, excludePageNo } = this.state
    const { getAllClosedOutbounds } = this.props

    getAllClosedOutbounds({ pageSize, pageNo })
    getAllClosedOutbounds({ pageSize, pageNo: excludePageNo }, 'exclude')
    this.getExportableOutbounds()
  }

  render() {
    const {
      closedOutbounds,
      selectedOutBoundIds,
      loading,
      selectNone,
      unSelectOutbound,
      selectOutbound,
      selectAll,
      loadingMore,
      hasMore,
      excludeClosedOutbounds,
      excludeLoading,
      hasExcludeMore,
      excludeLoadingMore
    } = this.props
    const { openOutboundDialog, requesting, tabValue, allClosed, allExcluded } = this.state
    const headers = [
      { label: 'CS', key: 'cs' },
      { label: 'Outbound', key: 'outbound' },
      { label: 'Label', key: 'label' },
      { label: 'Weight', key: 'weight' },
      { label: 'Volume', key: 'volume' },
      { label: 'Consignee', key: 'consignee' }
    ]

    return (
      <React.Fragment>
        <SubToolBarLayout text='Closed CS and Outbounds'>
          <Grid container alignItems='center' justify='flex-end' spacing={2}>
            <Grid item>
              <FuseIconButton icon='block'
                className='text-uppercase text-12'
                variant='outline'
                onClick={() => this.setState({ openOutboundDialog: true })}
                disabled={selectedOutBoundIds.length === 0 || loading}>
                Open</FuseIconButton>
            </Grid>
            <Grid item>
              {tabValue === 0 &&
                <CSVLink headers={headers} data={allClosed} filename={'closed-outbounds.csv'}>
                  <FuseIconButton icon='import_export'
                    className='text-uppercase'
                    variant='fill'>
                    EXPORT</FuseIconButton>
                </CSVLink>
              }
              {tabValue === 1 &&
                <CSVLink headers={headers} data={allExcluded} filename={'exclude-closed-outbounds.csv'}>
                  <FuseIconButton icon='import_export'
                    className='text-uppercase'
                    variant='fill'>
                    EXPORT</FuseIconButton>
                </CSVLink>
              }
            </Grid>
          </Grid>
        </SubToolBarLayout>
        <div className='p-20 h-full overflow-auto'>
          <Tabs value={tabValue}
            onChange={this.handleTabChange}
            className='custom-tabs'
            variant="scrollable"
            scrollButtons="auto"
            aria-label="closed outbounds tabs">
            <Tab label="Closed" {...this.a11yProps(0)}
              className={'custom-tabs-item ' + (tabValue === 0 ? 'selected' : '')} />
            <Tab label="Excluded" {...this.a11yProps(1)}
              className={'custom-tabs-item ' + (tabValue === 1 ? 'selected' : '')} />
          </Tabs>

          {/* Closed */}
          <TabPanel value={tabValue} index={0}>
            <OutboundList selectNone={selectNone}
              selectAll={selectAll}
              loading={loading}
              selectedOutBoundIds={selectedOutBoundIds}
              unSelectOutbound={unSelectOutbound}
              selectOutbound={selectOutbound}
              closedOutbounds={closedOutbounds}
              loadingMore={loadingMore}
              hasMore={hasMore}
              onLoadMore={this.handleLoadMore} />
          </TabPanel>
          {/* Excluded */}
          <TabPanel value={tabValue} index={1}>
            <OutboundList selectNone={selectNone}
              selectAll={selectAll}
              loading={excludeLoading}
              selectedOutBoundIds={selectedOutBoundIds}
              unSelectOutbound={unSelectOutbound}
              selectOutbound={selectOutbound}
              closedOutbounds={excludeClosedOutbounds}
              loadingMore={excludeLoadingMore}
              hasMore={hasExcludeMore}
              onLoadMore={this.handleLoadMore} />
          </TabPanel>
        </div>

        {/* Open Outbound Confirmation */}
        <Dialog open={openOutboundDialog}
          onClose={() => this.setState({ openOutboundDialog: false })}
          scroll={`paper`}
          maxWidth='sm'
          aria-labelledby='scroll-dialog-title'
          aria-describedby='scroll-dialog-description'>
          <DialogTitle id='scroll-dialog-title'>
            <label className="medium text-16">Confirm Open Outbounds</label>
          </DialogTitle>
          <DialogContent dividers={true}>
            <label className="regular italic text-14">
              Are you sure you want to open these outbounds? These will become visible under Manage Booking tab.</label>
          </DialogContent>
          <DialogActions>
            <Grid container justify="space-between"
              className="px-8">
              <Grid item>
                <Button onClick={() => this.setState({ openOutboundDialog: false })}
                  color='primary'
                  disabled={requesting}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button onClick={this.openSelectedOutbounds}
                  color='secondary'
                  disabled={requesting}>Yes,&nbsp;open</Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        {/* Open Outbound Confirmation */}

      </React.Fragment>
    )
  }
}

const styles = {
  dgGoodsType: {
    color: '#cf2a2a'
  },
  normalGoodsType: {
    color: '#7db5e8'
  },
  directShippmentMode: {
    color: '#e47e00'
  },
  dropShippmentMode: {
    color: '#e47e00'
  }
}

const mapStateToProps = ({ closed: {
  closedOutbounds,
  selectedOutBoundIds,
  loading,
  loadingMore,
  hasMore,
  excludeClosedOutbounds,
  excludeLoading,
  hasExcludeMore,
  excludeLoadingMore
} }) => ({
  closedOutbounds,
  selectedOutBoundIds,
  loading,
  loadingMore,
  hasMore,
  excludeClosedOutbounds,
  excludeLoading,
  hasExcludeMore,
  excludeLoadingMore
})

const mapDispatchToProps = dispatch => ({
  getAllClosedOutbounds: (...args) => dispatch(ClosedActions.getAllClosedOutbounds(...args)),
  selectOutbound: id => dispatch(ClosedActions.selectOutbound(id)),
  selectAll: () => dispatch(ClosedActions.selectAll()),
  selectNone: () => dispatch(ClosedActions.selectNone()),
  unSelectOutbound: id => dispatch(ClosedActions.unSelectOutbound(id)),
  openSelectedOutbounds: () => dispatch(ClosedActions.openSelectedOutbounds())
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Closed))
