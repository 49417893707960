import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import {
  FuseDialog,
  FuseDialogBody,
  FuseDialogHeader,
  FuseTabs
} from '../../components'
import { useMergeState } from '../../helpers'
import ThemeSettingsForm from './ThemeSettingsForm'
import SettingsFormFooter from "./SettingsFormFooter";
import { connect } from 'react-redux'
import * as Actions from '../../store/actions'
import { get } from 'lodash'

const tabItems = [
  {
    label: "Appearance",
    formName: 'appTheme'
  }
]

const INITIAL_STATE = {
  tabValue: 0
}

const UserSettingsDialog = (props) => {
  const {
    open,
    handleClose,
    appTheme,
    setUpdateUserSettings,
  } = props

  const [state, setState] = useMergeState(INITIAL_STATE)
  const { tabValue } = state

  useEffect(() => {
    if (open) {
      const { getEventsListUserSettings, getUserSettings } = props
      getEventsListUserSettings(() => {
        getUserSettings()
        setState(INITIAL_STATE)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const onChange = (type, params) => {
    setState({
      [type]: params
    })
  }

  const submitAppTheme = () => {
    const formName = 'appTheme'
    setUpdateUserSettings(formName, {
      key: formName,
      value: get(state, formName)
    }, () => resetForm(formName))
  }

  const resetForm = async (type) => {
    await setState({
      resetFormName: type
    })
    setState({
      [type]: undefined,
      resetFormName: ''
    })
  }

  const onTabChange = (tabValue) => {
    if (typeof tabValue === 'number') {
      resetForm(tabItems[tabValue].formName)
      setState({ tabValue })
    }
  }

  return (
    <FuseDialog open={open}>
      <FuseDialogHeader handleClose={handleClose}
        text="Theme" />
      <FuseDialogBody>
        <Grid container
          direction='row'>
          <FuseTabs value={tabValue}
            data={tabItems}
            onChange={onTabChange} />
        </Grid>

        <Grid container>
          {tabValue === 0 &&
            <Grid item xs={12}>
              <ThemeSettingsForm {
                ...{
                  appTheme,
                  onChange
                }}
                resetForm={get(state, 'resetFormName')}
              />
              <SettingsFormFooter onSubmit={submitAppTheme}
                disableSubmit={!get(state, 'appTheme')}
                onReset={() => resetForm('appTheme')}
              />
            </Grid>
          }
        </Grid>
      </FuseDialogBody>
    </FuseDialog>
  )
}

const mapStateToProps = ({ core: { userSettings } }) => ({
  eventsList: userSettings.eventsList,
  eventsNotification: userSettings.eventsNotification,
  appTheme: userSettings.appTheme,
  scheduleDefaultMode: userSettings.scheduleDefaultMode,
  shipmentCardPrimaryId: userSettings.shipmentCardPrimaryId
})

const mapDispatchToProps = dispatch => ({
  getUserSettings: (...args) => dispatch(Actions.getUserSettings(...args)),
  getEventsListUserSettings: (...args) => dispatch(Actions.getEventsListUserSettings(...args)),
  setUpdateUserSettings: (...args) => dispatch(Actions.setUpdateUserSettings(...args)),
  updateUserSettings: (...args) => dispatch(Actions.updateUserSettings(...args)),
  deleteUserSettings: (...args) => dispatch(Actions.deleteUserSettings(...args)),
  setBulkUserSettings: (...args) => dispatch(Actions.setBulkUserSettings(...args)),
})

export default (connect(mapStateToProps, mapDispatchToProps)(UserSettingsDialog))
