import React, { useEffect, useState } from 'react'
import {
  withStyles,
  Grid,
  Tabs,
  Tab,
  Typography,
  Box
} from '@material-ui/core'
import { connect } from 'react-redux'
import queryString from 'query-string'
import history from '../../history'
import * as SettingActions from '../store/actions'
import * as AuthActions from '../../auth/store/actions'
import { get } from 'lodash'
import Notifications from '../components/Notifications'
import BookingInfo from '../components/BookingInfo'
import PlatformView from '../components/PlatformView'
import PlantSettings from '../components/PlantSettings'
import { useMergeState } from '../../core/helpers'

const styles = {
  input: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14,
  },
  select: {
    fontFamily: 'IBMPlexSans',
    fontSize: 14,
  },
  datetimePicker: {
    height: 30,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    '& input': {
      fontFamily: 'IBMPlexSans',
      fontSize: 14,
      margin: 0,
      display: 'block',
      padding: '6px 0px 7px',
      width: '100%',
      background: 'none',
      boxSizing: 'content-box'
    }
  }
}

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component='div'
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={'w-full overflow-hidden'}
    {...other}>
    <Box>{children}</Box>
  </Typography>
)

const SettingsPage = props => {
  const [value, setValue] = useState(0)
  const [state, setState] = useMergeState({})
  const [invalidEvents, setInvalidEvents] = useState(true)
  const [submitting, setSubmitting] = useState(false)

  const {
    location: { search },
    getEventsList,
    getEventNotification,
    getPlaces,
    getUserSettingsByKey,
    setUserSettingByKey,
    updateUserSettingsById,
    deleteUserSettingsById,
    userInfo,
    updateProfile,
    setBulkUserSettings,
    setOnGoingTask,
    currentPlant,
    getPlantEmailTemplates,
    setPlantEmailTemplates,
    updatePlantEmailTemplates,
    deletePlantEmailTemplates
  } = props

  const handleChange = (event, newValue) => {
    history.push(`?tab=${newValue}`)
  }

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      style: {
        paddingLeft: 20,
        paddingRight: 20
      }
    }
  }

  useEffect(() => {
    const parseQuery = queryString.parse(search)
    const { tab } = parseQuery
    setValue(Number(tab || 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    getEventsList()

    if (get(props, 'eventsNotification.data', []).length > 0) {
      getEventNotification(true)
    } else {
      getEventNotification()
    }

    if (get(props, 'places.data', []).length > 0) {
      getPlaces(true)
    } else {
      getPlaces()
    }

    if (get(props, 'plantEmailTemplates.data', []).length > 0) {
      getPlantEmailTemplates(true)
    } else {
      getPlantEmailTemplates()
    }

    getUserSettingsByKey('scheduleDefaultMode')
    getUserSettingsByKey('shipmentCardPrimaryId')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo])

  const onChange = (type, params) => {
    if (type === 'eventsNotification') {
      let hasChanges = false
      setInvalidEvents(false)
      params.forEach(notification => {
        if (notification.isNew) {
          hasChanges = true
          if (!get(notification, 'code')) {
            setInvalidEvents(true)
          }
          if (!get(notification, 'type')) {
            setInvalidEvents(true)
          }
          if (get(notification, 'selection', []).length === 0) {
            setInvalidEvents(true)
          }
        }
        if (notification.isUpdated) {
          hasChanges = true
          if (get(notification, 'selection', []).length === 0) {
            setInvalidEvents(true)
          }
        }
        if (notification.isDeleted) {
          hasChanges = true
        }
      })
      if (!hasChanges) {
        setInvalidEvents(true)
      }
    }
    setState({
      [type]: params
    })
  }

  const submitEventsNotification = () => {
    const formName = 'eventsNotification'
    const allNotifications = get(state, formName, [])

    setSubmitting(formName)

    var createPayload = []
    allNotifications.forEach(async (notification, index) => {
      const selection = get(notification, 'selection', [])
      const value = {
        push: selection.includes('push') ? true : false,
        email: selection.includes('email') ? true : false,
        sms: selection.includes('sms') ? true : false
      }

      if (notification.isDeleted) {
        // Deleting Event Notification
        setSubmitting(formName)
        deleteUserSettingsById(
          notification._id,
          formName,
          () => {
            getEventNotification(true, () => {
              setSubmitting(false)
            })
          }
        )
      }
      else if (notification.isUpdated) {
        // Updating Event Notification
        setSubmitting(formName)
        updateUserSettingsById(
          notification._id,
          {
            settingsName: get(notification, 'settingsName', []).join('--'),
            key: formName,
            value
          },
          () => {
            getEventNotification(true, () => {
              setSubmitting(false)
            })
          }
        )
      }
      else if (notification.isNew) {
        // Adding Event Notification to Create List
        createPayload.push({
          settingsName: get(notification, 'code', '')
            + '--'
            + get(notification, 'type', ''),
          key: formName,
          value
        })
      }

      if (index === (allNotifications.length - 1) && createPayload.length === 0) {
        resetForm(formName)
      }
    })

    if (createPayload.length > 0) {
      // Adding New Event Notifications
      setSubmitting(formName)
      setBulkUserSettings(formName, createPayload, () => {
        getEventNotification(true, async () => {
          await resetForm(formName)
          setSubmitting(false)
        })
      })
    } else {
      resetForm(formName)
      setSubmitting(false)
    }
  }

  const resetForm = async (type) => {
    await setState({
      resetFormName: type
    })
    setState({
      [type]: undefined,
      resetFormName: ''
    })
    setInvalidEvents(true)
  }

  return (
    <Grid container className="h-full overflow-auto">
      <Grid item xs={12}>
        <Tabs value={value}
          style={{
            position: 'fixed',
            width: '100%',
            top: '54px'
          }}
          onChange={handleChange}
          className='bg-white shadow'
          variant="scrollable"
          scrollButtons="auto"
          aria-label="settings tabs">
          <Tab label='Notifications' {...a11yProps(0)}
            className={'custom-tabs-item ' + (value === 0 ? 'selected' : '')} />
          <Tab label='Booking info' {...a11yProps(1)}
            className={'custom-tabs-item ' + (value === 1 ? 'selected' : '')} />
          <Tab label='Platform view' {...a11yProps(2)}
            className={'custom-tabs-item ' + (value === 2 ? 'selected' : '')} />
          {get(currentPlant, 'role') === 'admin' &&
            <Tab label='Plant Settings' {...a11yProps(3)}
              className={'custom-tabs-item ' + (value === 3 ? 'selected' : '')} />
          }
        </Tabs>

        <TabPanel value={value} index={0}>
          <Notifications userInfo={get(props, 'userInfo', {})}
            eventsList={get(props, 'eventsList', {})}
            eventsNotification={get(props, 'eventsNotification', {})}
            disableEventSubmit={invalidEvents}
            resetForm={get(state, 'resetFormName')}
            onEventChange={onChange}
            onEventSubmit={submitEventsNotification}
            isEventSubmitting={submitting === 'eventsNotification'}
            onEventReset={() => resetForm('eventsNotification')}
            updateProfile={updateProfile}
            onGoingTask={get(props, 'onGoingTask', {})}
            setOnGoingTask={setOnGoingTask}
            defaultLogin={get(props, 'defaultLogin', false)} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <BookingInfo places={get(props, 'places', {})}
            currentPlant={get(props, 'currentPlant', {})}
            addPlaces={setUserSettingByKey}
            updatePlaces={updateUserSettingsById}
            deletePlaces={deleteUserSettingsById}
            getPlaces={getPlaces} />
        </TabPanel>

        <TabPanel value={value} index={2}>
          <PlatformView scheduleDefaultMode={get(props, 'scheduleDefaultMode', '')}
            shipmentCardPrimaryId={get(props, 'shipmentCardPrimaryId', '')}
            getUserSettingsByKey={getUserSettingsByKey}
            setUserSettingByKey={setUserSettingByKey}
            currentPlant={currentPlant} />
        </TabPanel>

        {get(currentPlant, 'role') === 'admin' &&
          <TabPanel value={value} index={3}>
            <PlantSettings plantEmailTemplates={get(props, 'plantEmailTemplates', {})}
              getTemplates={getPlantEmailTemplates}
              addTemplates={setPlantEmailTemplates}
              updateTemplates={updatePlantEmailTemplates}
              deleteTemplates={deletePlantEmailTemplates}
              currentPlant={currentPlant} />
          </TabPanel>
        }

      </Grid>
    </Grid>
  )
}

const mapStateToProps = ({ settingsReducer, auth, welcome }) => ({
  userInfo: get(auth, 'info', {}),
  eventsList: get(settingsReducer, 'eventsList', {}),
  eventsNotification: get(settingsReducer, 'eventsNotification', {}),
  places: get(settingsReducer, 'places', {}),
  onGoingTask: get(settingsReducer, 'onGoingTask', {}),
  scheduleDefaultMode: get(settingsReducer, 'scheduleDefaultMode', ''),
  shipmentCardPrimaryId: get(settingsReducer, 'shipmentCardPrimaryId', ''),
  plantEmailTemplates: get(settingsReducer, 'plantEmailTemplates', {}),
  currentPlant: get(welcome, 'currentPlant', {}),
  defaultLogin: get(localStorage, 'defaultLogin') === 'true'
})

const mapDispatchToProps = dispatch => ({
  getEventsList: (...args) => dispatch(SettingActions.getEventsList(...args)),
  getEventNotification: (...args) => dispatch(SettingActions.getEventNotification(...args)),
  getPlaces: (...args) => dispatch(SettingActions.getPlaces(...args)),
  updateUserSettingsById: (...args) => dispatch(SettingActions.updateUserSettingsById(...args)),
  deleteUserSettingsById: (...args) => dispatch(SettingActions.deleteUserSettingsById(...args)),
  getUserSettingsByKey: (...args) => dispatch(SettingActions.getUserSettingsByKey(...args)),
  setUserSettingByKey: (...args) => dispatch(SettingActions.setUserSettingByKey(...args)),
  setUpdateUserSettingByKey: (...args) => dispatch(SettingActions.setUpdateUserSettingByKey(...args)),
  setBulkUserSettings: (...args) => dispatch(SettingActions.setBulkUserSettings(...args)),
  updateProfile: (...args) => dispatch(AuthActions.updateProfile(...args)),
  setOnGoingTask: (...args) => dispatch(SettingActions.setOnGoingTask(...args)),
  getPlantEmailTemplates: (...args) => dispatch(SettingActions.getPlantEmailTemplates(...args)),
  setPlantEmailTemplates: (...args) => dispatch(SettingActions.setPlantEmailTemplates(...args)),
  updatePlantEmailTemplates: (...args) => dispatch(SettingActions.updatePlantEmailTemplates(...args)),
  deletePlantEmailTemplates: (...args) => dispatch(SettingActions.deletePlantEmailTemplates(...args)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SettingsPage))
