const platforms = [
  {
    title: 'Shipper view',
    unavailable: false,
    background: 'assets/images/backgrounds/welcome/shipper_view.png',
    name: 'shipper',
    type: 'shipper',
    route: '/manage-booking',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  },
  {
    title: 'Export transport',
    unavailable: false,
    background: 'assets/images/backgrounds/welcome/export_transport.png',
    name: 'export logistic partner',
    type: 'obl',
    route: '/schedule',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  },
  {
    title: 'Export custom',
    unavailable: false,
    background: 'assets/images/backgrounds/welcome/export_custom.png',
    name: 'export custom provider',
    type: 'obc',
    route: '/schedule',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  },
  {
    title: 'Export freight',
    unavailable: false,
    background: 'assets/images/backgrounds/welcome/export_freight.png',
    name: 'export freight partner',
    type: 'ofp',
    route: '/schedule',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  },
  {
    title: 'Consignee view',
    unavailable: false,
    background: 'assets/images/backgrounds/welcome/consignee_view.png',
    name: 'consignee',
    type: 'consignee',
    route: '/manage-incoming',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  },
  {
    title: 'Import transport',
    unavailable: false,
    background: 'assets/images/backgrounds/welcome/import_transport.png',
    name: 'import logistics provider',
    type: 'ibl',
    route: '/manage-incoming',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  },
  {
    title: 'Import custom',
    unavailable: false,
    background: 'assets/images/backgrounds/welcome/import_custom.png',
    name: 'import custom provider',
    type: 'ibc',
    route: '/manage-incoming',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  },
  {
    title: 'Import freight',
    unavailable: false,
    background: 'assets/images/backgrounds/welcome/import_freight.png',
    name: 'import freight partner',
    type: 'ifp',
    route: '/manage-incoming',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  },
  {
    title: 'Drophub',
    unavailable: false,
    hide: true,
    background: 'assets/images/backgrounds/welcome/drophub.png',
    name: 'drop hub',
    type: 'dropHub',
    route: '/map-view',
    events: {
      total: 0,
      new: 0
    },
    shipments: {
      total: 0,
      new: 0
    },
    issues: {
      total: 0,
      new: 0
    },
    notification: {
      total: 0
    },
    total: 0
  }
]

export default platforms
