import React, { useState, useEffect } from 'react'
import { Grid, LinearProgress } from '@material-ui/core'
import { InfoToolTip } from 'core/components'
import { get, filter } from 'lodash'

const FPConflictResolveForm = ({
  shipment,
  limitedServiceCards,
  getLimitedServiceCards,
  selectedLimitedServiceCard,
  setSelectedLimitedServiceCard
}) => {
  const [isLoading, setLoading] = useState(true)

  const handleSelectItineraries = (itineraryId) => {
    if (selectedLimitedServiceCard.includes(itineraryId)) {
      setSelectedLimitedServiceCard(filter(selectedLimitedServiceCard, i => i !== itineraryId))
    } else {
      setSelectedLimitedServiceCard([
        ...selectedLimitedServiceCard,
        itineraryId
      ])
    }
  }

  useEffect(() => {
    const itineraryId = get(shipment, 'executableServiceCards', []).map(i => get(i, 'meta.itineraryId'))
    getLimitedServiceCards(itineraryId,
      () => setLoading(false),
      () => setLoading(false)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container>
      {isLoading &&
        <InfoToolTip title="Loading limited Service Cards">
          <Grid item xs={12}><LinearProgress /></Grid>
        </InfoToolTip>
      }
      {!isLoading &&
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}
              className="text-16 pb-12 semi-bold">Conflicting Itineraries</Grid>

            <Grid item xs={12} className="p-6">
              <Grid container spacing={3}>
                {(limitedServiceCards || []).map((card, cardIndex) => {
                  const meta = get(card, 'document.meta')
                  const ofp = get(card, 'document.ofp')
                  const destinationPort = get(card, 'document.destinationPort')
                  const carrierInfo = get(card, 'carrierInfo')
                  const itineraryId = get(meta, 'itineraryId')
                  const isSelected = selectedLimitedServiceCard.includes(itineraryId)

                  return (
                    <Grid item xs={6} key={cardIndex}>
                      <Grid container spacing={1}
                        className={'p-6 rounded border-solid border-1 cursor-pointer '
                          + (isSelected ? 'bg-transparent-green border-green' : 'border-gray')
                        }
                        onClick={() => handleSelectItineraries(itineraryId)}>
                        <Grid item>
                          <img src="assets/images/icons/form/check-box.svg"
                            className={isSelected ? 'hidden' : ''}
                            alt="check-box" />
                          <img src="assets/images/icons/form/check-box-checked.svg"
                            className={!isSelected ? 'hidden' : ''}
                            alt="check-box" />
                        </Grid>
                        <Grid item xs>
                          <Grid container className="fg-gray medium">
                            <InfoToolTip title="Itinerary Id">
                              <Grid item xs={12} className="fg-primary semi-bold text-15">
                                {get(meta, 'itineraryId')}
                              </Grid>
                            </InfoToolTip>
                            <InfoToolTip title="Plant name">
                              <Grid item xs={12}>{get(ofp, 'partner.plantData.name')}</Grid>
                            </InfoToolTip>
                            <InfoToolTip title="Type">
                              <Grid item xs={12}>{get(ofp, 'type')}</Grid>
                            </InfoToolTip>
                            {destinationPort &&
                              <InfoToolTip title="Destination Port">
                                <Grid item xs={12}>{`${get(destinationPort, 'country', '')} (${get(destinationPort, 'locode', '')})`}</Grid>
                              </InfoToolTip>
                            }
                            {carrierInfo &&
                              <InfoToolTip title="Carrier name">
                                <Grid item xs={12}>{get(carrierInfo, 'name')}</Grid>
                              </InfoToolTip>
                            }
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </Grid>



          </Grid>
        </Grid>
      }
    </Grid>
  )
}

export default FPConflictResolveForm

