import React from 'react';
import FuseNavigation from './FuseNavigation';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';

const Navigation = ({dense, className}) => {
  return (
    <FuseNavigation className={classNames("navigation", className)} dense={dense}/>
  );
};

Navigation.defaultProps = {
  layout: "vertical"
};

export default withRouter(Navigation);
