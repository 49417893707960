import React from 'react'
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { withStyles } from '@material-ui/core'
import { ConfirmDialog } from '../../core/components'

const CreateMultiShipToPartConfirm = ({ open, shipToParties = [], cancelCreate, confirmCreate }) => {
  return (
    <ConfirmDialog open={open}
      close={cancelCreate}
      onButtonClick={confirmCreate}
      onCancelButtonClick={cancelCreate}
      icon={<AccountTreeIcon className='fg-gray text-52' />}
      headerText={`Warning you are selecting ${shipToParties.length} ship to party codes`}
      text={[
        ...shipToParties.map((code, index) =>
          <p className="regular text-14" key={index}>Ship to Party Code: {code}</p>
        ),
        <p className="medium text-16 mt-6" key="confirm-paragraph">Please confirm to proceed with booking</p>
      ]}
      confirmButtonText={'Confirm'}
      cancelButtonText={'Cancel'}
      wSM
    />
  )
}

const styles = theme => ({
  delete: {
    padding: 30
  },
  deleteRow: {
    textAlign: 'center',
    margin: 5
  },
  button: {
    color: '#fff',
    backgroundColor: '#cc1f1a'
  },
  icon: { fontSize: 75 }
})
export default withStyles(styles)(CreateMultiShipToPartConfirm)
