import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import _, { get } from 'lodash'
import { FuseTextField } from 'core/components'
import { useMergeState } from 'core/helpers'
import { FuseSideBar, FuseSideBarHeader, FuseSideBarBody, FuseSideBarActions } from 'core/components'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { addGaEvent } from '../../core/helpers'

const INITIAL_STATE = {
  MBL: [''],
  HBL: ['']
}
const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const inputStyle = {
  fontSize: 12,
  fontFamily: 'IBMPlexSans'
}

const BillLadinSidebar = ({ open, closeSideBar, onSubmit, mode, existingMBL, existingHBL, currentPlant, details }) => {
  const [state, setState] = useMergeState(INITIAL_STATE);
  const [emptyHBL, setfilteredEmptyHBL] = useState([]);
  const [emptyMBL, setfilteredEmptyMBL] = useState([]);
  const { transportMethod } = details


  const addRow = (type) => {
    if (!state[type].includes('')) {
      setState({ [type]: [...state[type], ''] })
    }
  }

  const deleteRow = (type, index) => {
    setState({ [type]: state[type].filter((x, i) => i !== index) })
  }

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    state[name][index] = value;

    setState({ ...state })
  }

  useEffect(() => {
    setState({
      MBL: existingMBL || [''],
      HBL: existingHBL || ['']
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingMBL, existingHBL])

  useEffect(() => {
    if (open) {
      addGaEvent('shipment-updatingBL', get(details, '_id'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  /**
   * Validate and check that the mbl fields are not empty
   */

  const onSubmitdata = (HBL_data, MBL_data) => {
    setfilteredEmptyHBL([]);
    setfilteredEmptyMBL([]);
    let filteredEmptyHBL = [];
    HBL_data.forEach(function (el, index) {
      if (el === '') {
        filteredEmptyHBL.push(index);
      }
    });

    let filteredEmptyMBL = []
    MBL_data.forEach(function (el, index) {
      if (el === '') {
        filteredEmptyMBL.push(index);
      }
    });

    if (filteredEmptyHBL.length || filteredEmptyMBL.length) {
      setfilteredEmptyHBL(filteredEmptyHBL);
      setfilteredEmptyMBL(filteredEmptyMBL);
    } else {
      onSubmit(HBL_data, MBL_data)
    }
  }

  return (
    <FuseSideBar open={open} onBackDropClick={closeSideBar}>
      <FuseSideBarHeader
        text={mode === 'add' ? 'Add BL number(s)' : ((_.get(currentPlant, 'type') === 'ofp' || _.get(currentPlant, 'type') === 'shipper') ? 'Edit bill of lading no' : 'BL Numbers')}
        handleClose={() => {
          addGaEvent('shipment-updatingBLCancelled', get(details, '_id'))
          closeSideBar()
        }}
      />
      <FuseSideBarBody>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className="bg-gray px-12 py-8 rounded">
                <Typography variant='h6' color='inherit' className='bold font-dark mb-12'>
                  {`${transportMethod === 'air' ? 'MAWB' : 'MBL'} Numbers`}
                </Typography>
                {state.MBL &&
                  state.MBL.map((mbl, index) => (
                    <Grid container key={index} alignItems="center">
                      <Grid item xs={11}>
                        <FuseTextField disabled={_.get(currentPlant, 'type') !== 'ofp' && _.get(currentPlant, 'type') !== 'shipper'}
                          className='mb-16'
                          type='text'
                          name="MBL"
                          placeholder={`Enter ${transportMethod === 'air' ? 'MAWB' : 'MBL'} number`}
                          label={(transportMethod === 'air' ? 'MAWB ' : 'MBL ') + (index + 1)}
                          icon={{ ...baseTextSetting, name: '' }}
                          value={mbl}
                          onChange={(e) => handleChange(e, index)}
                          inputStyles={inputStyle}
                          required
                          fullWidth
                          autoFocus
                          error={emptyMBL.includes(index) ? true : false}
                          helperText={emptyMBL.includes(index) ? `Please provide ${transportMethod === 'air' ? 'MAWB' : 'MBL'}` : ""}
                        />
                      </Grid>
                      {
                        (_.get(currentPlant, 'type') === 'ofp' || _.get(currentPlant, 'type') === 'shipper') &&
                        <Grid item xs={1} className='cursor-pointer'
                          key={'close_' + index}
                          onClick={() => deleteRow('MBL', index)}>
                          <Grid container justify="flex-end" alignItems="center">
                            <img src="assets/images/icons/remove-icon.svg"
                              alt="remove" />
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  ))}
                {
                  (_.get(currentPlant, 'type') === 'ofp' || _.get(currentPlant, 'type') === 'shipper') &&
                  <Typography onClick={() => addRow('MBL')}
                    className='fg-green text-14 semi-bold cursor-pointer'
                    display="inline">
                    {`+ Add ${transportMethod === 'air' ? 'MAWB' : 'MBL'}`}
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} className="bg-gray px-12 py-8 rounded">
                <Typography variant='h6' color='inherit' className='bold font-dark mb-12'>
                  {`${transportMethod === 'air' ? 'HAWB' : 'HBL'} Numbers`}
                </Typography>
                {state.HBL &&
                  state.HBL.map((hbl, index) => (
                    <Grid key={`hbl_${index}`} container alignItems="center">
                      <Grid item xs={11} key={index}>
                        <FuseTextField disabled={_.get(currentPlant, 'type') !== 'ofp' && _.get(currentPlant, 'type') !== 'shipper'}
                          className='mb-16'
                          type='text'
                          name="HBL"
                          placeholder={`Enter ${transportMethod === 'air' ? 'HAWB' : 'HBL'} number`}
                          label={(transportMethod === 'air' ? 'HAWB ' : 'HBL ') + (index + 1)}
                          icon={{ ...baseTextSetting, name: '' }}
                          value={hbl}
                          onChange={(e) => handleChange(e, index)}
                          inputStyles={inputStyle}
                          required
                          fullWidth
                          autoFocus
                          error={emptyHBL.includes(index) ? true : false}
                          helperText={emptyHBL.includes(index) ? `Please provide ${transportMethod === 'air' ? 'HAWB' : 'HBL'}` : ""}
                        />
                      </Grid>
                      {
                        (_.get(currentPlant, 'type') === 'ofp' || _.get(currentPlant, 'type') === 'shipper') &&
                        <Grid item xs={1} className='cursor-pointer'
                          key={'close_' + index}
                          onClick={() => deleteRow('HBL', index)}>
                          <Grid container justify="flex-end" alignItems="center">
                            <img src="assets/images/icons/remove-icon.svg"
                              alt="remove" />
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  ))
                }
                {
                  (_.get(currentPlant, 'type') === 'ofp' || _.get(currentPlant, 'type') === 'shipper') &&
                  <Typography onClick={() => addRow('HBL')}
                    className='fg-green text-14 semi-bold cursor-pointer'
                    display="inline">
                    {`+ Add ${transportMethod === 'air' ? 'HAWB' : 'HBL'}`}
                  </Typography>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseSideBarBody>
      {
        (_.get(currentPlant, 'type') === 'ofp' || _.get(currentPlant, 'type') === 'shipper') &&
        <FuseSideBarActions>
          <Button className='btn btn_full-space'
            onClick={() => onSubmitdata(state.HBL, state.MBL)}>
            SAVE</Button>
        </FuseSideBarActions>
      }

    </FuseSideBar>
  )
}

export default BillLadinSidebar
