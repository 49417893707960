import React, { useEffect } from 'react'
import {
  FuseSideBar,
  FuseSideBarHeader,
  FuseSideBarBody,
  FuseSideBarActions,
  FuseTextField
} from '../'
import {
  Grid,
  Typography,
  Button,
  withStyles,
  InputLabel
} from '@material-ui/core'
import { connect } from 'react-redux'
import validator from 'validator'
import { AutoComplete } from '../Inputs'
import * as WelcomeActions from '../../../welcome/store/actions'
import { get } from 'lodash'

const countryPhoneCodes = require('../../helpers/json/countryPhoneCodes.json')

const baseTextSetting = {
  position: 'end',
  className: 'text-20',
  color: 'action'
}

const inputStyle = {
  fontFamily: 'IBMPlexSans'
}

const selectStyle = {
  control: () => ({
    display: 'flex',
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid #949494',
    minHeight: '31px',
    maxHeight: '31px',
    marginTop: '0px',
    minWidth: '80px'
  }),
  dropdownIndicator: () => ({
    height: '20px',
    width: '20px',
    content: 'url(/assets/images/icons/down-arrow-text.svg)'
  }),
}

const validatePlantDetails = (payload, setPlantDetails) => {
  if (!validator.isEmail(payload.email)) {
    setPlantDetails({
      ...payload,
      error: {
        email: 'Please enter a valid email'
      }
    })
    return false
  }
  return true
}

const INITIAL_STATE = {
  email: '',
  phone: {
    countryCode: '+65',
    phoneNumber: ''
  },
  error: {}
}

const PlantDetailsSidebar = ({
  open,
  closeSideBar,
  sideBarTitle,
  plantInfo,
  updatePlantDetails,
  classes
}) => {
  const [plantDetails, setPlantDetails] = React.useState(INITIAL_STATE)
  const communications = get(plantInfo, 'communications')

  useEffect(() => {
    setPlantDetails({
      email: get(communications, 'email[0]', ''),
      phone: {
        countryCode: get(communications, 'phone[0].countryCode', ''),
        phoneNumber: get(communications, 'phone[0].phoneNumber', '')
      }
    })
  }, [communications])

  return (
    <FuseSideBar open={open} onBackDropClick={closeSideBar}>
      <FuseSideBarHeader text={sideBarTitle || 'Plant Details'}
        handleClose={closeSideBar} />
      <FuseSideBarBody>
        <Grid container>
          <Typography variant='h6'
            color='inherit'
            className='font-dark mt-10'>
            Plant / Team details</Typography>
          <Grid item sm={12} className='mt-20'>
            <FuseTextField
              className='mb-16'
              type='text'
              icon={{ ...baseTextSetting, name: 'eye' }}
              name='email'
              value={plantDetails.email}
              error={plantDetails.error && plantDetails.error.email}
              helperText={plantDetails.error && plantDetails.error.email}
              onChange={e => setPlantDetails({ ...plantDetails, email: e.target.value })}
              label='Communication email address'
              inputStyles={inputStyle}
              disabled={get(plantInfo, 'role') !== 'admin'}
              required
              fullWidth />
          </Grid>
          <Grid item sm={12}>
            <InputLabel className='form-label form-label_bg'>
              Communication phone</InputLabel>
            <Grid container spacing={2}
              alignItems="center"
              className='mb-16'>
              <Grid item>
                <AutoComplete value={get(plantDetails, 'phone.countryCode', '+65')}
                  className="text-14"
                  options={countryPhoneCodes.map(item => ({
                    value: item.dial_code,
                    label: item.dial_code
                  }))}
                  placeholder="Code"
                  onChange={(countryCode) => setPlantDetails({
                    ...plantDetails,
                    phone: {
                      ...plantDetails.phone,
                      countryCode
                    }
                  })}
                  customStyles={selectStyle}
                  isDisabled={get(plantInfo, 'role') !== 'admin'}
                  showAllOptions />
              </Grid>
              <Grid item xs>
                <FuseTextField style={{ fontSize: '14px' }}
                  type='text'
                  name='phone'
                  placeholder="Phone number"
                  icon={{ ...baseTextSetting, name: 'eye' }}
                  value={get(plantDetails, 'phone.phoneNumber', '')}
                  onChange={e => {
                    if (!validator.isNumeric(e.target.value) &&
                      e.target.value.trim() !== '') {
                      return
                    }
                    setPlantDetails({
                      ...plantDetails,
                      phone: {
                        ...plantDetails.phone,
                        phoneNumber: e.target.value
                      }
                    })
                  }}
                  inputStyles={inputStyle}
                  disabled={get(plantInfo, 'role') !== 'admin'}
                  required
                  fullWidth />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FuseSideBarBody>
      <FuseSideBarActions>
        <Button className='btn btn_full-space'
          onClick={() => updatePlantDetails(plantDetails, closeSideBar, setPlantDetails, plantInfo)}>
          Update Plant Details</Button>
      </FuseSideBarActions>
    </FuseSideBar>
  )
}

const mapStateToProps = ({ welcome }) => ({
  plantInfo: welcome.currentPlant
})

const mapDispatchToProps = dispatch => ({
  updatePlantDetails: async (plantDetails, closeSideBar, setPlantDetails, plantInfo) => {
    if (!validatePlantDetails(plantDetails, setPlantDetails)) {
      return
    }
    let payload = {
      ...plantInfo,
      communications: {
        email: [plantDetails.email],
        phone: [{ countryCode: plantDetails.phone.countryCode, phoneNumber: plantDetails.phone.phoneNumber }]
      }
    }
    await dispatch(WelcomeActions.updatePlantDetails(payload, plantInfo._id))
    closeSideBar()
  }
})
const styles = {
  customSelect: {
    '& > div': {
      background: 'none',
      marginBottom: '-8px'
    },
    '& > div:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important',
    },
    '& > svg': {
      marginTop: '5px'
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlantDetailsSidebar))
