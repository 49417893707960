import { createTypes } from 'reduxsauce'

export default createTypes(
  `
  API_ATTEMPT
  API_SUCCESS
  API_FAILED

  GET_SHIPMENT_SUCCESS
  GET_SHIPMENT_OUTBOUNDS_SUCCESS
  GET_SHIPMENT_CONSOLIDATIONS_SUCCESS
  GET_SHIPMENT_DOCUMENTS_SUCCESS
  GET_SHIPMENT_CHAT_DOCUMENTS_SUCCESS
  GET_SHIPMENT_EVENTS_SUCCESS
  GET_SHIPMENT_EVENT_WRITE_LIST_SUCCESS
  GET_SHIPMENT_META_SUCCESS
  GET_SHIPMENT_UPDATE_PREPS_SUCCESS
  GET_SHIPMENT_PRE_ALERT_LOGS_SUCCESS
  GET_SHIPMENT_GOODS_RECEIVED_SUCCESS

  RESET_SHIPMENT_DETAILS

  GET_SHIPMENT_TOPICS
  GET_SHIPMENT_TOPICS_DETAILS
  `
)
