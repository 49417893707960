import { createTypes } from 'reduxsauce'

export default createTypes(
  `
    API_ATTEMPT
    API_SUCCESS
    API_FAILED

    LOGIN_ATTEMPT
    LOGIN_SUCCESS
    CHALLENGE_REQUIRED
    RESET_AUTH
    AUTH_LOGOUT

    GET_INFO_ATTEMPT
    GET_INFO_SUCCESS
    AUTO_LOGIN_ATTEMPT
    AUTO_LOGIN_FINISHED
    ONBOARDING_REQUIRED
    ONBOARDING_COMPLETED

    AUTH_PROFILE_UPDATE
    AUTH_LOADING_RESET
    FORGOT_PASSWORD_CHALLENGE

    LOGOUT
  `
)
