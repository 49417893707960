import React from 'react'
import { FuseAnimate } from 'core/components'
import classNames from 'classnames';

const Dialog = ({ open, children, onBackDropClick, wSM, wMD, wLG }) =>
  open && (
    <FuseAnimate animation='transition.fadeIn' duration={200}>
      <div className={'dialog'} onClick={onBackDropClick}>
        <FuseAnimate animation='transition.slideDownIn' duration={200} delay={200}>
          <div onClick={e => e.stopPropagation()}
            className={classNames({
              'dialog-content': true,
              'dialog-content_md': wMD,
              'dialog-content_sm': wSM,
              'dialog-content_lg': wLG,
            })
            }>
            {children}
          </div>
        </FuseAnimate>
      </div>
    </FuseAnimate>
  )

export default Dialog
