import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withStyles, Tabs, Tab, Typography, Box, Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import history from '../../history'
import queryString from 'query-string'
import { get, pick, set, sumBy, concat } from 'lodash'
import EmailList from '../components/EmailList'
import * as Actions from '../store/actions'
import { LoadingButton, LoadMore } from '../../core/components'
import StartCreateDialog from '../components/StartCreateDialog'
import SelectDocsDialog from '../components/SelectDocsDialog'
import ShipmentInfoDialog from '../components/ShipmentInfoDialog'
import AddToSidebar from '../components/AddToSidebar'
import * as CoreActions from '../../core/store/actions'
import * as ScheduleActions from '../../schedule/store/actions'

const tabStyle = {
  position: 'fixed',
  width: '100%',
  top: '54px'
}

const TabPanel = ({ children, value, index, ...other }) => (
  <Typography component='div'
    role='tabpanel'
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    className={'w-full overflow-hidden'}
    {...other}>
    <Box>{children}</Box>
  </Typography>
)

const InboundEmails = props => {
  const {
    location: { search },
    getPreAlertEmails,
    getShipments,
    preAlertEmails,
    processedPreAlertEmails,
    shipments,
    getPlants,
    plants,
    saveShipment,
    addToShipment,
    currentPlant,
    showMessage,
    getVessels,
    getServiceCardByItineraryId
  } = props
  const [tabValue, setSabValue] = useState(0)
  const [selectedEmails, setSelectedEmails] = useState([])
  const [startCreateDialogOpen, setStartCreateDialogOpen] = useState(false)
  const [selectDocsDialogOpen, setSelectDocsDialogOpen] = useState(false)
  const [addToSidebarOpen, setAddToSidebarOpen] = useState(false)
  const [shipmentInfoDialogOpen, setShipmentInfoDialogOpen] = useState(false)
  const [selectedType, setSelectedType] = useState(null)
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [usedDocuments, setUsedDocuments] = useState([])
  const [unscheduledPageNo, setUnscheduledPageNo] = useState(1)
  const [scheduledPageNo, setScheduledPageNo] = useState(1)
  const [shipmentsPageNo, setShipmentsPageNo] = useState(1)
  const [newShipmentCount, setNewShipmentCount] = useState(0)
  const [documentAction, setDocumentAction] = useState(null)
  const [requesting, setRequesting] = useState(false)
  const pageSize = 10

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      style: {
        paddingLeft: 20,
        paddingRight: 20
      }
    }
  }

  const loadEmails = () => {
    const pageNo = 1
    getPreAlertEmails({ pageNo, pageSize, status: 'raw' })
    getPreAlertEmails({ pageNo, pageSize, status: 'processed' })
    setUnscheduledPageNo(pageNo)
    setScheduledPageNo(pageNo)
  }

  const handleTabChange = (event, newValue) => {
    history.push(`?tab=${newValue}`)
  }

  const handleUnscheduleLoadMore = () => {
    const pageNo = unscheduledPageNo + 1
    getPreAlertEmails({ pageNo, pageSize, status: 'raw' })
    setUnscheduledPageNo(pageNo)
  }

  const handleScheduleLoadMore = () => {
    const pageNo = scheduledPageNo + 1
    getPreAlertEmails({ pageNo, pageSize, status: 'processed' })
    setScheduledPageNo(pageNo)
  }

  const handleShipmentSearch = (search) => {
    const pageNo = 1
    getShipments({ pageNo, pageSize, search })
    setShipmentsPageNo(pageNo)
  }

  const handleShipmentLoadMore = (search) => {
    const pageNo = shipmentsPageNo + 1
    getShipments({ pageNo, pageSize, search })
    setShipmentsPageNo(pageNo)
  }

  const handleEmailAction = (action) => {
    setDocumentAction(action)
    if (action === 'new-shipment') {
      setStartCreateDialogOpen(true)
    } else if (action === 'add-to') {
      setSelectDocsDialogOpen(true)
    }
  }

  const handleDocumentDialogContinue = () => {
    if (documentAction === 'new-shipment') {
      setSelectDocsDialogOpen(false)
      setShipmentInfoDialogOpen(true)
    } else if (documentAction === 'add-to') {
      setSelectDocsDialogOpen(false)
      setAddToSidebarOpen(true)
    }
  }

  const handleDocumentDialogBack = () => {
    if (documentAction === 'new-shipment') {
      return () => {
        setSelectDocsDialogOpen(false)
        setStartCreateDialogOpen(true)
      }
    } else if (documentAction === 'add-to') {
      return undefined
    }
  }

  const handleSubmitShipmentInfo = (shipmentInfo) => {
    setRequesting(true)
    const shipper = get(shipmentInfo, 'shipper')
    const ifp = get(shipmentInfo, 'ifp')
    const ibc = get(shipmentInfo, 'ibc')
    const ibl = get(shipmentInfo, 'ibl')
    const type = get(shipmentInfo, 'type')
    const transportMethod = get(shipmentInfo, 'transportMethod')

    const payload = {
      shipment: {
        ...pick(shipmentInfo, ['type', 'transportMethod', 'shipmentMode', 'note', 'incoterm']),
        actors: {
          shipper: shipper ? [{
            ...shipper,
            office: get(shipper, 'office._id')
          }] : undefined,
          ifp: [{
            ...ifp,
            office: get(ifp, 'office._id')
          }],
          ibc: [{
            ...ibc,
            office: get(ibc, 'office._id')
          }],
          ibl: [{
            ...ibl,
            office: get(ibl, 'office._id')
          }]
        },
        referenceNos: {
          ...pick(shipmentInfo, ['bookingReferenceNo', 'carrierBookingNo']),
          HBLNo: concat(get(shipmentInfo, 'billOfLadingNo', '').split(',')),
          MBLNo: concat(get(shipmentInfo, 'masterBillOfLadingNo', '').split(','))
        },
        freightOperatorInfo: {
          name: get(shipmentInfo, 'carrier'),
          identifier: { type: 'SCAC', value: get(shipmentInfo, 'scac', '') }
        },
        vesselSchedule: {
          FirstPOL: {
            port: {
              name: get(shipmentInfo, 'portOfLoading'),
              locode: get(shipmentInfo, 'portOfLoading')
            },
            vessel: {
              name: get(shipmentInfo, 'vessel'),
              IMO: get(shipmentInfo, 'imo'),
            },
            voyageNumber: get(shipmentInfo, 'voyageNo')
          },
          transhipmentPorts: [],
          LastPOD: {
            port: {
              name: get(shipmentInfo, 'portOfDischarge'),
              locode: get(shipmentInfo, 'portOfDischarge')
            }
          }
        }
      },
      documents: selectedDocuments.map(doc => ({
        docType: get(doc, 'type'),
        docKey: get(doc, 'name', 'NoName'),
        docStoreId: get(doc, '_id')
      })),
      incomingPreAlertIds: selectedEmails.map(email => email._id)
    }

    if (transportMethod === 'air' && !get(shipmentInfo, 'carrier')) {
      set(payload, 'shipment.freightOperatorInfo', undefined)
    }

    if (transportMethod === 'air' && !get(shipmentInfo, 'vessel')) {
      set(payload, 'shipment.vesselSchedule.FirstPOL.vessel', undefined)
    }

    if (type === 'FCL' || (type === 'LCL' && get(shipmentInfo, 'containers[0].type'))) {
      set(payload, 'shipment.containers', get(shipmentInfo, 'containers', []).map(i => pick(i, [
        'carrierSealNumber',
        'equipmentNo',
        'type'
      ])))
      set(payload, 'shipment.weight', {
        value: sumBy(get(shipmentInfo, 'containers', []), i => Number(get(i, 'weight.value') || 0)),
        unit: get(shipmentInfo, 'containers[0].weight.unit', 'KG')
      })
      set(payload, 'shipment.volume', {
        value: sumBy(get(shipmentInfo, 'containers', []), i => Number(get(i, 'volume.value') || 0)),
        unit: get(shipmentInfo, 'containers[0].volume.unit', 'M3')
      })
    }

    saveShipment(payload, () => {
      setRequesting(false)
      if (selectedType === 'multiple-shipment') {
        setUsedDocuments([
          ...usedDocuments,
          ...selectedDocuments
        ])
        setShipmentInfoDialogOpen(false)
        setSelectDocsDialogOpen(true)
        setSelectedDocuments([])
        setNewShipmentCount(newShipmentCount + 1)
        loadEmails()
      } else {
        handleFinish()
        loadEmails()
      }
    }, () => {
      setRequesting(false)
    })
  }

  const handleSubmitAddToShipment = (shipmentId) => {
    setRequesting(true)
    const payload = {
      documents: selectedDocuments.map(doc => ({
        docType: get(doc, 'type'),
        docKey: get(doc, 'name', 'NoName'),
        docStoreId: get(doc, '_id')
      })),
      incomingPreAlertIds: selectedEmails.map(email => email._id)
    }

    addToShipment(shipmentId, payload, () => {
      loadEmails()
      handleFinish()
      setRequesting(false)
    }, () => setRequesting(false))
  }

  const handleFinish = () => {
    setStartCreateDialogOpen(false)
    setAddToSidebarOpen(false)
    setSelectDocsDialogOpen(false)
    setShipmentInfoDialogOpen(false)
    setSelectedEmails([])
    setNewShipmentCount(0)
    setDocumentAction(null)
  }

  const handleCancel = () => {
    setStartCreateDialogOpen(false)
    setAddToSidebarOpen(false)
    setSelectDocsDialogOpen(false)
    setShipmentInfoDialogOpen(false)
    setNewShipmentCount(0)
    setDocumentAction(null)
  }

  useEffect(() => {
    const parseQuery = queryString.parse(search)
    const { tab } = parseQuery
    setSabValue(Number(tab || 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    loadEmails()
    getShipments({ pageNo: shipmentsPageNo, pageSize })
    getPlants()

    if (get(currentPlant, 'type') === 'consignee') {
      getVessels()
    }

    return () => {
      setSabValue(0)
      setSelectedEmails([])
      setStartCreateDialogOpen(false)
      setSelectDocsDialogOpen(false)
      setAddToSidebarOpen(false)
      setShipmentInfoDialogOpen(false)
      setSelectedType(null)
      setSelectedDocuments([])
      setUsedDocuments([])
      setUnscheduledPageNo(1)
      setScheduledPageNo(1)
      setShipmentsPageNo(1)
      setNewShipmentCount(0)
      setDocumentAction(null)
      setRequesting(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container className="h-full overflow-auto">
      <Grid item xs={12}>
        <Tabs value={tabValue} style={tabStyle}
          onChange={handleTabChange}
          className='bg-white shadow'
          variant="scrollable"
          scrollButtons="auto"
          aria-label="email-logs tabs">
          <Tab label='Unscheduled' {...a11yProps(0)}
            className={'custom-tabs-item ' + (tabValue === 0 ? 'selected' : '')} />
          <Tab label='Scheduled' {...a11yProps(1)}
            className={'custom-tabs-item ' + (tabValue === 1 ? 'selected' : '')} />
        </Tabs>

        {/* Unscheduled Emails */}
        <TabPanel value={tabValue} index={0}>
          <Grid container className="p-20 regular">

            {get(preAlertEmails, 'data', []).length > 0 &&
              <Grid item xs={12} className="pb-20">
                <Grid container spacing={2} justify="flex-end" alignItems="center">
                  <Grid item xs={6} sm={2} md={2}>
                    <Button className="btn btn_sm btn_light_green w-full"
                      disabled={selectedEmails.length === 0}
                      onClick={() => handleEmailAction('new-shipment')}>
                      new shipment</Button>
                  </Grid>
                  <Grid item xs={6} sm={2} md={2}>
                    <Button className="btn btn_sm btn_light_green w-full"
                      disabled={selectedEmails.length === 0}
                      onClick={() => handleEmailAction('add-to')}>
                      add to</Button>
                  </Grid>
                </Grid>
              </Grid>
            }

            {get(preAlertEmails, 'data', []).length > 0 &&
              <Grid item xs={12} className="pb-20">
                <Grid container alignItems="center" justify="space-between">
                  <Grid item className="semi-bold fg-gray">{get(preAlertEmails, 'total', 0)}&nbsp;emails</Grid>
                  <Grid item className="italic fg-gray text-12">You can select multiple emails to create shipment</Grid>
                </Grid>
              </Grid>
            }

            <Grid item xs={12}>
              <EmailList loading={get(preAlertEmails, 'loading', false)}
                emails={get(preAlertEmails, 'data', [])}
                selectedEmails={selectedEmails}
                setSelectedEmails={setSelectedEmails} />
            </Grid>

            {get(preAlertEmails, 'hasMore', false) === true &&
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item>
                    <LoadMore loading={get(preAlertEmails, 'loadingMore', false)}
                      onClick={handleUnscheduleLoadMore} />
                  </Grid>
                </Grid>
              </Grid>
            }

          </Grid>

        </TabPanel>
        {/* End of Unscheduled Emails */}

        {/* Scheduled Emails */}
        <TabPanel value={tabValue} index={1}>
          <Grid container className="p-20 regular">
            {get(processedPreAlertEmails, 'data', []).length > 0 &&
              <Grid item xs={12} className="pb-20">
                <Grid container alignItems="center" justify="space-between">
                  <Grid item className="semi-bold fg-gray">{get(processedPreAlertEmails, 'total', 0)}&nbsp;emails</Grid>
                </Grid>
              </Grid>
            }

            <Grid item xs={12}>
              <EmailList loading={get(preAlertEmails, 'loading', false)}
                emails={get(processedPreAlertEmails, 'data', [])} />
            </Grid>

            {get(processedPreAlertEmails, 'hasMore', false) === true &&
              <Grid item xs={12}>
                <Grid container justify="center">
                  <Grid item>
                    <LoadMore loading={get(processedPreAlertEmails, 'loadingMore', false)}
                      onClick={handleScheduleLoadMore} />
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </TabPanel>
        {/* End of Scheduled Emails */}

        {/* Archive Emails */}
        <TabPanel value={tabValue} index={2} className="hidden">
          <Grid container className="p-20 regular">
            {[].length > 0 &&
              <Grid item xs={12} className="pb-20">
                <Grid container spacing={2} justify="flex-end" alignItems="center">
                  <Grid item>
                    <LoadingButton className="btn"
                      disabled={selectedEmails.length === 0}>unarchive</LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            }
            {[].length > 0 &&
              <Grid item xs={12} className="pb-20">
                <Grid container alignItems="center" justify="space-between">
                  <Grid item className="semi-bold fg-gray">0&nbsp;emails</Grid>
                  <Grid item className="italic fg-gray text-12">You can select multiple emails to unarchive</Grid>
                </Grid>
              </Grid>
            }
            <Grid item xs={12}>
              <EmailList loading={get(preAlertEmails, 'loading', false)}
                emails={[]} />
            </Grid>
          </Grid>
        </TabPanel>
        {/* End of Archive Emails */}

      </Grid>

      {/* Dialogs */}
      <StartCreateDialog open={startCreateDialogOpen}
        handleClose={handleCancel}
        selectedEmails={selectedEmails}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        onContinue={() => {
          setStartCreateDialogOpen(false)
          setSelectDocsDialogOpen(true)
        }} />

      <SelectDocsDialog open={selectDocsDialogOpen}
        handleClose={handleCancel}
        selectedEmails={selectedEmails}
        selectedType={selectedType}
        selectedDocuments={selectedDocuments}
        newShipmentCount={newShipmentCount}
        usedDocuments={usedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        onBack={handleDocumentDialogBack()}
        onContinue={handleDocumentDialogContinue}
        onFinish={handleFinish}
        showMessage={showMessage} />

      <ShipmentInfoDialog open={shipmentInfoDialogOpen}
        plants={plants}
        selectedEmails={selectedEmails}
        handleClose={handleCancel}
        onSaveShipment={handleSubmitShipmentInfo}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        requesting={requesting}
        currentPlant={currentPlant}
        vessels={get(props, 'vessels', [])}
        getServiceCardByItineraryId={getServiceCardByItineraryId} />

      <AddToSidebar open={addToSidebarOpen}
        handleClose={handleCancel}
        selectedEmails={selectedEmails}
        loading={get(shipments, 'loading', false)}
        hasMore={get(shipments, 'hasMore', false)}
        loadingMore={get(shipments, 'loadingMore', false)}
        shipments={get(shipments, 'data', [])}
        onSearch={handleShipmentSearch}
        onLoadMore={handleShipmentLoadMore}
        onAddToShipment={handleSubmitAddToShipment}
        requesting={requesting} />
      {/* End of Dialogs */}

    </Grid>
  )
}

const styles = {
  dgGoodsType: {
    color: '#cf2a2a'
  },
  normalGoodsType: {
    color: '#7db5e8'
  },
  directShipmentMode: {
    color: '#e47e00'
  },
  dropShipmentMode: {
    color: '#e47e00'
  }
}

const mapStateToProps = ({
  decoupledInbound: { preAlertEmails, processedPreAlertEmails, shipments },
  core: { plants },
  welcome: { currentPlant },
  schedule: { vessels }
}) => ({
  preAlertEmails,
  processedPreAlertEmails,
  shipments,
  plants,
  currentPlant,
  vessels
})

const mapDispatchToProps = dispatch => ({
  getPreAlertEmails: (...args) => dispatch(Actions.getPreAlertEmails(...args)),
  getShipments: (...args) => dispatch(Actions.getShipments(...args)),
  saveShipment: (...args) => dispatch(Actions.saveShipment(...args)),
  addToShipment: (...args) => dispatch(Actions.addToShipment(...args)),
  getServiceCardByItineraryId: (...args) => dispatch(Actions.getServiceCardByItineraryId(...args)),
  getPlants: (...args) => dispatch(CoreActions.getPlants(...args)),
  showMessage: (...args) => dispatch(CoreActions.showMessage(...args)),
  getVessels: (...args) => dispatch(ScheduleActions.getVessels(...args)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InboundEmails))
