import React from 'react'
import Skeleton from 'react-loading-skeleton'
import Grid from '@material-ui/core/Grid'
import TransportTimeline from '../../../core/components/Timelines/TransportTimeline'

const timeLineIcons = [
  'home-local_shipping',
  'pin_drop-directions_boat',
  'pin_drop-local_shipping',
  'home',
]

const getTimeLine = (icon) => {
  return {
    place: <Skeleton width={150} />,
    address: <Skeleton height={12} count={2} />,
    icon: icon.split('-')[0],
    iconRounded: true,
    connector: icon.split('-')[1] ? {
      icon: icon.split('-')[1]
    } : null
  }
}

export default () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Skeleton height={226} />
      </Grid>
      <Grid item xs={12}>
        <Grid container className="p-16"
          style={{
            backgroundColor: 'rgba(61, 205, 88, 0.06)'
          }}>
          <Grid item xs={1}>
            <Skeleton height={15} width={15} circle />
          </Grid>
          <Grid item xs={11}>
            <Skeleton width={200} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            <Skeleton width={150} height={12} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="p-16 bg-white">
        <TransportTimeline data={timeLineIcons.map(item => getTimeLine(item))} /></Grid>
    </Grid>
  )
}

