import React from 'react'
import _, { get, filter, find, set, has } from 'lodash'
import moment from 'moment'
import { getAddressFromLocation } from 'core/helpers'

export const getWarehouseDestinationDetails = (shipment, timelineType) => {
  let data = {
    title: `-----`,
    line1: `Planned Arr: -----`,
    line2: timelineType === 'shipping' ? `0/${shipment.containers.length} Delivered` : null
  }

  let locations = shipment.journey
    .filter(
      journey => journey.location && journey.location.type === 'warehouse' && journey.location.subType === 'destination'
    )
    .sort(journey => (journey.keyEvents ? 1 : -1))
  if (locations.length > 0) {
    let title = _.get(locations[0], 'location.name', []).trim()
    data.title = title === '' ? `warehouse(destination)` : title

    if (locations.length === 1 && locations[0].keyEvents) {
      const filteredEventEV6000Actual = locations[0].keyEvents.filter(event => event.code === 'EV6000' && event.actual)
      const filteredEventEV6000Estimated = locations[0].keyEvents.filter(event => event.code === 'EV6000' && event.estimated)
      const filteredEventEV6000Planned = locations[0].keyEvents.filter(event => event.code === 'EV6000' && event.planned)
      if (filteredEventEV6000Actual[0] && filteredEventEV6000Actual[0].actual.occurenceAt) {
        data.line1 = `Arr: ${moment(filteredEventEV6000Actual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
        data.allLine1 = filteredEventEV6000Actual.map(item =>
          moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
        )
      } else if (filteredEventEV6000Estimated[0] && filteredEventEV6000Estimated[0].estimated.occurenceAt) {
        data.line1 = `Est. Arr: ${moment(filteredEventEV6000Estimated[0].estimated.occurenceAt).format('DD/MM/YYYY h:mm a')}`
        data.allLine1 = filteredEventEV6000Estimated.map(item =>
          moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
        )
      } else if (filteredEventEV6000Planned[0] && filteredEventEV6000Planned[0].planned.occurenceAt) {
        data.line1 = `Planned Arr: ${moment(filteredEventEV6000Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
        data.allLine1 = filteredEventEV6000Planned.map(item =>
          moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
        )
      }
    } else if (locations.length > 1) {
      data.line1 = getmultipleConsigneeLink(locations, shipment)
      set(data, 'consignees', locations)
    }

    let allKeyEvents = []
    locations.forEach(location => {
      if (location.keyEvents) {
        allKeyEvents = [...allKeyEvents, ...location.keyEvents]
      }
    })
    const filteredEventEV6000Actual = allKeyEvents.filter(event => event.code === 'EV6000' && event.actual)
    const containers = filteredEventEV6000Actual
      .map(event => (event.cargo ? event.cargo.id : 0))
      .filter(containerId => containerId !== 0)
    if (timelineType === 'shipping' && containers.length > 0) {
      let x = _.uniq(containers).length
      data.line2 = `${x}/${shipment.containers.length} Delivered`
    }
  }
  return data
}

//location details can be requested for  timelineType 'shipment', 'container', 'cargo'
export const getLocationDetails = (obj, shipment, timelineType = 'shipment', locationIndex, locationContainer) => {
  let { location, keyEvents } = obj
  let dataToReturn, address

  if (location.type === 'warehouse' && location.subType === 'origin') {
    dataToReturn = getWarehouseOriginDetails(location, keyEvents, shipment, timelineType, locationContainer)
  }
  if (location.type === 'port' && location.subType === 'origin') {
    dataToReturn = getPortOriginDetails(location, keyEvents, shipment)
  }
  if (location.type === 'port' && location.subType === 'destination') {
    dataToReturn = getPortDestinationDetails(location, keyEvents, shipment, timelineType)
  }
  if (location.type === 'CY' && location.subType === 'origin') {
    dataToReturn = getCYDetails(location, keyEvents, shipment)
  }
  if (location.type === 'storage' && location.subType === 'origin') {
    dataToReturn = getStorageOriginDetails(location, keyEvents, shipment, timelineType, locationIndex)
  }
  if (location.type === 'port' && location.subType === 'transhipment') {
    dataToReturn = getPortTranshipmentDetails(location, keyEvents, shipment, timelineType)
  }
  if (location.type === 'warehouse' && location.subType === 'destination') {
    dataToReturn = getWarehouseDestinationDetails(shipment, timelineType)
  }
  if (location.type === 'CFS' && location.subType === 'origin') {
    dataToReturn = getCFSOriginDetails(location, keyEvents, shipment, timelineType)
  }

  if(has(location, 'address')){
    address = getAddressFromLocation(get(location, 'address'))
  }

  dataToReturn = {...dataToReturn, ...{'address':address}}

  return dataToReturn
}

const getCFSOriginDetails = (location, keyEvents, shipment, timelineType) => {
  let title = _.get(location, 'name', '').trim()
  let data = {
    title: title === '' ? `port(transhipment)` : title,
    line1: `Est.Drop: -------`,
    line2: `Planned Drop: -------`
  }
  if (keyEvents && keyEvents.length > 0) {
    const filteredEventEV3030Actual = keyEvents.filter(event => event.code === 'EV3030' && event.actual)
    const filteredEventEV3030Estimated = keyEvents.filter(event => event.code === 'EV3030' && event.estimated)
    const filteredEventEV3030Planned = keyEvents.filter(event => event.code === 'EV3030' && event.planned)
    if (filteredEventEV3030Actual[0] && filteredEventEV3030Actual[0].actual.occurenceAt) {
      data.line1 = `Drop: ${moment(filteredEventEV3030Actual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3030Actual.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3030Estimated[0] && filteredEventEV3030Estimated[0].estimated.occurenceAt) {
      data.line1 = `Est. Drop: ${moment(filteredEventEV3030Estimated[0].estimated.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3030Estimated.map(item =>
        moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3030Planned[0] && filteredEventEV3030Planned[0].planned.occurenceAt) {
      data.line1 = `Est. Drop: ${moment(filteredEventEV3030Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3030Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
    if (filteredEventEV3030Planned[0] && filteredEventEV3030Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Drop: ${moment(filteredEventEV3030Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV3030Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
  }
  return data
}

const getPortTranshipmentDetails = (location, keyEvents, shipment, timelineType) => {
  let title = _.get(location, 'name', '').trim()
  let data = {
    title: title === '' ? `port(transhipment)` : title,
    line1: `Est. Arr: -------`,
    line2: `Planned Arr: -------`
  }
  if (keyEvents && keyEvents.length > 0) {
    //4100
    //3800
    const filteredEventEV4100Actual = keyEvents.filter(event => event.code === 'EV4100' && event.actual)
    const filteredEventEV4100Estimated = keyEvents.filter(event => event.code === 'EV4100' && event.estimated)
    const filteredEventEV4100Planned = keyEvents.filter(event => event.code === 'EV4100' && event.planned)

    const filteredEventEV3800Actual = keyEvents.filter(event => event.code === 'EV3800' && event.actual)
    const filteredEventEV3800Estimated = keyEvents.filter(event => event.code === 'EV3800' && event.estimated)
    const filteredEventEV3800Planned = keyEvents.filter(event => event.code === 'EV3800' && event.planned)

    let showDep = false;
    if (filteredEventEV3800Actual[0] && filteredEventEV3800Actual[0].actual.occurenceAt) {
      data.line1 = `Dep: ${moment(filteredEventEV3800Actual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      showDep = true;
      data.allLine1 = filteredEventEV3800Actual.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV4100Actual[0] && filteredEventEV4100Actual[0].actual.occurenceAt) {
      data.line1 = `Arr: ${moment(filteredEventEV4100Actual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      showDep = false;
      data.allLine1 = filteredEventEV4100Actual.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3800Estimated[0] && filteredEventEV3800Estimated[0].estimated.occurenceAt) {
      data.line1 = `Est. Dep: ${moment(filteredEventEV3800Estimated[0].estimated.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      showDep = true;
      data.allLine1 = filteredEventEV3800Estimated.map(item =>
        moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV4100Estimated[0] && filteredEventEV4100Estimated[0].estimated.occurenceAt) {
      data.line1 = `Est. Arr: ${moment(filteredEventEV4100Estimated[0].estimated.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      showDep = false;
      data.allLine1 = filteredEventEV4100Estimated.map(item =>
        moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }

    if (showDep && filteredEventEV3800Planned[0] && filteredEventEV3800Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Dep: ${moment(filteredEventEV3800Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV3800Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (showDep && filteredEventEV4100Planned[0] && filteredEventEV4100Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Arr: ${moment(filteredEventEV4100Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV4100Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (!showDep && filteredEventEV3800Planned[0] && filteredEventEV3800Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Dep: ${moment(filteredEventEV3800Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV3800Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (!showDep && filteredEventEV4100Planned[0] && filteredEventEV4100Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Arr: ${moment(filteredEventEV4100Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV4100Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }

  }
  return data
}

const getStorageOriginDetails = (location, keyEvents, shipment, timelineType, locationIndex) => {
  // FCL or LCL ?
  let eventCode = '';
  if (_.get(shipment, 'type') === 'LCL' && locationIndex === 1) {
    // if type = LCL, and index = 1
    eventCode = "EV2575";
  } else if (_.get(shipment, 'type') === 'FCL') {
    // if type = FCL, and index = 1 (means storage 1)
    // if type = FCL, and index = 2 or 3 (means storage 2)
    if (locationIndex === 1) {
      eventCode = "EV2555";
    } else if (locationIndex === 2 || locationIndex === 3) {
      eventCode = "EV2565";
    }
  }

  let title = _.get(location, 'name', '').trim()
  let data = {
    title: title === '' ? `storage(origin)` : title,
    line1: `Est. Truck Out: -------`,
    line2: `Planned Truck Out: -------`
  }
  if (keyEvents && keyEvents.length > 0) {
    const filteredEventActual = keyEvents.filter(event => event.code === eventCode && event.actual)
    const filteredEventEstimated = keyEvents.filter(event => event.code === eventCode && event.estimated)
    const filteredEventPlanned = keyEvents.filter(event => event.code === eventCode && event.planned)
    if (filteredEventActual[0] && filteredEventActual[0].actual.occurenceAt) {
      data.line1 = `Truck Out: ${moment(filteredEventActual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventActual.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEstimated[0] && filteredEventEstimated[0].estimated.occurenceAt) {
      data.line1 = `Est. Truck Out: ${moment(filteredEventEstimated[0].estimated.occurenceAt).format(
        'DD/MM/YYYY h:mm a'
      )}`
      data.allLine1 = filteredEventEstimated.map(item =>
        moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventPlanned[0] && filteredEventPlanned[0].planned.occurenceAt) {
      data.line1 = `Est. Truck Out: ${moment(filteredEventPlanned[0].planned.occurenceAt).format(
        'DD/MM/YYYY h:mm a'
      )}`
      data.allLine1 = filteredEventPlanned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
    if (filteredEventPlanned[0] && filteredEventPlanned[0].planned.occurenceAt) {
      data.line2 = `Planned Truck Out: ${moment(filteredEventPlanned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventPlanned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
  }
  return data
}

const getCYDetails = (location, keyEvents, shipment, timelineType) => {
  let title = _.get(location, 'name', '').trim()
  let data = {
    title: title === '' ? `CY(origin)` : title,
    line1: `Est.Pick Up: -------`,
    line2: `Planned Pick Up: -------`
  }
  if (keyEvents && keyEvents.length > 0) {
    const filteredEventEV2100Actual = keyEvents.filter(event => event.code === 'EV2100' && event.actual)
    const filteredEventEV2100Estimated = keyEvents.filter(event => event.code === 'EV2100' && event.estimated)
    const filteredEventEV2100Planned = keyEvents.filter(event => event.code === 'EV2100' && event.planned)
    if (filteredEventEV2100Actual[0] && filteredEventEV2100Actual[0].actual.occurenceAt) {
      data.line1 = `Pick Up: ${moment(filteredEventEV2100Actual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV2100Actual.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV2100Estimated[0] && filteredEventEV2100Estimated[0].estimated.occurenceAt) {
      data.line1 = `Est. Pick Up: ${moment(filteredEventEV2100Estimated[0].estimated.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV2100Estimated.map(item =>
        moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV2100Planned[0] && filteredEventEV2100Planned[0].planned.occurenceAt) {
      data.line1 = `Est. Pick Up: ${moment(filteredEventEV2100Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV2100Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
    if (filteredEventEV2100Planned[0] && filteredEventEV2100Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Pick Up: ${moment(filteredEventEV2100Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV2100Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
  }
  return data
}

const getPortDestinationDetails = (location, keyEvents, shipment, timelineType) => {
  let title = _.get(location, 'name', '').trim()
  let data = {
    title: title === '' ? `port(destination)` : title,
    line1: `Est.Arr: -------`,
    line2: `Planned Arr: -------`
  }
  if (keyEvents && keyEvents.length > 0) {
    const filteredEventEV4200Actual = keyEvents.filter(event => event.code === 'EV4200' && event.actual)
    const filteredEventEV4200Estimated = keyEvents.filter(event => event.code === 'EV4200' && event.estimated)
    const filteredEventEV4200Planned = keyEvents.filter(event => event.code === 'EV4200' && event.planned)
    if (filteredEventEV4200Actual[0] && filteredEventEV4200Actual[0].actual.occurenceAt) {
      data.line1 = `Arr: ${moment(filteredEventEV4200Actual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV4200Actual.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV4200Estimated[0] && filteredEventEV4200Estimated[0].estimated.occurenceAt) {
      data.line1 = `Est. Arr: ${moment(filteredEventEV4200Estimated[0].estimated.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV4200Estimated.map(item =>
        moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV4200Planned[0] && filteredEventEV4200Planned[0].planned.occurenceAt) {
      data.line1 = `Est. Arr: ${moment(filteredEventEV4200Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV4200Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
    if (filteredEventEV4200Planned[0] && filteredEventEV4200Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Arr: ${moment(filteredEventEV4200Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV4200Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
  }
  return data
}

const getPortOriginDetails = (location, keyEvents, shipment, timelineType) => {
  let title = _.get(location, 'name', '').trim()
  let data = {
    title: title === '' ? `port(origin)` : title,
    line1: `Est.Arr: -------`,
    line2: `Planned Arr: -------`
  }

  if (keyEvents && keyEvents.length > 0) {
    const filteredEventEV3600Actual = keyEvents.filter(event => event.code === 'EV3600' && event.actual)
    const filteredEventEV3600Estimated = keyEvents.filter(event => event.code === 'EV3600' && event.estimated)
    const filteredEventEV3600Planned = keyEvents.filter(event => event.code === 'EV3600' && event.planned)
    const filteredEventEV3550Actual = keyEvents.filter(event => event.code === 'EV3550' && event.actual)
    const filteredEventEV3550Estimated = keyEvents.filter(event => event.code === 'EV3550' && event.estimated)
    const filteredEventEV3550Planned = keyEvents.filter(event => event.code === 'EV3550' && event.planned)
    if (filteredEventEV3600Actual[0] && filteredEventEV3600Actual[0].actual.occurenceAt) {
      data.line1 = `Dep: ${moment(filteredEventEV3600Actual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3600Actual.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3600Estimated[0] && filteredEventEV3600Estimated[0].estimated.occurenceAt) {
      data.line1 = `Est. Dep: ${moment(filteredEventEV3600Estimated[0].estimated.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3600Estimated.map(item =>
        moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3550Actual[0] && filteredEventEV3550Actual[0].actual.occurenceAt) {
      data.line1 = `Arr: ${moment(filteredEventEV3550Actual[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3550Actual.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3550Estimated[0] && filteredEventEV3550Estimated[0].estimated.occurenceAt) {
      data.line1 = `Est.Arr: ${moment(filteredEventEV3550Estimated[0].estimated.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3550Estimated.map(item =>
        moment(item.estimated.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3600Planned[0] && filteredEventEV3600Planned[0].planned.occurenceAt) {
      data.line1 = `Est. Dep: ${moment(filteredEventEV3600Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3600Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3550Planned[0] && filteredEventEV3550Planned[0].planned.occurenceAt) {
      data.line1 = `Est.Arr: ${moment(filteredEventEV3550Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV3550Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }

    if (filteredEventEV3600Planned[0] && filteredEventEV3600Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Dep: ${moment(filteredEventEV3600Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV3600Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    } else if (filteredEventEV3550Planned[0] && filteredEventEV3550Planned[0].planned.occurenceAt) {
      data.line2 = `Planned Arr: ${moment(filteredEventEV3550Planned[0].planned.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine2 = filteredEventEV3550Planned.map(item =>
        moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
  }
  return data
}

const getWarehouseOriginDetails = (location, keyEvents, shipment, timelineType, locationContainer) => {
  const shipmentContainers = filter(get(shipment, 'containers', []), item =>
    (get(item, 'status.current.value') !== 'cancelled'))
  let title = _.get(location, 'name', '')
  let data = {
    title: title === '' ? `warehouse(origin)` : title,
    line1:
      timelineType === 'cargo'
        ? `Truck out: -----`
        : timelineType === 'container'
          ? `Truck out: -----`
          : `Proposed: -------`,
    line2:
      timelineType === 'cargo'
        ? null
        : timelineType === 'container'
          ? `Planned Cargo Pickup: -----`
          : (_.get(shipment, 'type') === 'FCL' ? `0/${shipmentContainers.length} Trucked Out` : null)
  }
  if (keyEvents && keyEvents.length > 0) {
    data.title = title === '' ? `port(origin)` : title
    const filteredEventEV900 = keyEvents.filter(event => event.code === 'EV900' && event.actual)
    if (filteredEventEV900[0] && filteredEventEV900[0].actual.occurenceAt) {
      data.line1 = `Proposed: ${moment(filteredEventEV900[0].actual.occurenceAt).format('DD/MM/YYYY h:mm a')}`
      data.allLine1 = filteredEventEV900.map(item =>
        moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
      )
    }
    if (timelineType === 'cargo') {
      //LCL
      const filteredEventEV2537Planned = keyEvents.filter(event => event.code === 'EV2537' && event.planned)
      if (filteredEventEV2537Planned[0] && filteredEventEV2537Planned[0].planned.occurenceAt) {
        data.line2 = `Planned Cargo Pickup: ${moment(filteredEventEV2537Planned[0].planned.occurenceAt).format(
          'DD/MM/YYYY h:mm a'
        )}`
        data.allLine2 = filteredEventEV2537Planned.map(item =>
          moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
        )
      }
    } else if (timelineType === 'container') {
      const filteredEventEV2540Actual = keyEvents.filter(event =>
        (event.code === 'EV2540' &&
          get(event, 'cargo.id', []).includes(get(locationContainer, '_id')) &&
          event.actual))
      if (filteredEventEV2540Actual[0] && filteredEventEV2540Actual[0].actual.occurenceAt) {
        data.line1 = `Truck Out: ${moment(filteredEventEV2540Actual[0].actual.occurenceAt).format(
          'DD/MM/YYYY h:mm a'
        )}`
        data.allLine1 = filteredEventEV2540Actual.map(item =>
          moment(item.actual.occurenceAt).format('DD/MM/YYYY h:mm a')
        )
      }

      const filteredEventEV2540Planned = keyEvents.filter(event =>
        (event.code === 'EV2540' &&
          get(event, 'cargo.id', []).includes(get(locationContainer, '_id')) &&
          event.planned))
      if (filteredEventEV2540Planned[0] && filteredEventEV2540Planned[0].planned.occurenceAt) {
        data.line2 = `Planned Truck Out: ${moment(filteredEventEV2540Planned[0].planned.occurenceAt).format(
          'DD/MM/YYYY h:mm a'
        )}`
        data.allLine2 = filteredEventEV2540Planned.map(item =>
          moment(item.planned.occurenceAt).format('DD/MM/YYYY h:mm a')
        )
      }
    } else {
      const filteredEventEV2540Actual = keyEvents.filter(event => event.code === 'EV2540' && event.actual)
      const containers = filteredEventEV2540Actual
        .map(event => (event.cargo ? event.cargo.id : 0))
        .filter(containerId => (containerId !== 0 &&
          !find(shipmentContainers, { _id: containerId }))
        )
      if (containers.length > 0) {
        let x = _.uniq(containers).length
        data.line2 = `${x}/${shipmentContainers.length} Trucked Out`
      }
    }
  }
  return data
}

const getmultipleConsigneeLink = locations => {
  return (
    <span
      style={{
        backgroundColor: 'transparent',
        color: `#1890ff`,
        textDecoration: `none`,
        outline: `none`,
        cursor: `pointer`,
        transition: `color 0.3s`
      }}
    >{`+ ${locations.length - 1} consignees`}</span>
  )
}
