import React from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { get, set, omit, filter, pick, orderBy, find } from 'lodash'
import Grid from '@material-ui/core/Grid'
import { connect } from 'react-redux'
import * as SettingsActions from '../../../settings/store/actions'
import { CircularProgress } from '@material-ui/core'

class GooglePlaceSearch extends React.Component {
  state = {
    addressComponents: {},
  }

  handleChange = street => {
    const { updateCall } = this.props
    this.setState({ street })
    updateCall({ street }, '', '')
  }

  handleSelect = street => {
    this.setState({
      street
    })

    geocodeByAddress(street)
      .then(async results => {
        const firstResult = results[0]
        const { updateCall } = this.props
        const addressComponents = {
          street,
          country: '',
          zipcode: '',
          state: '',
          city: ''
        }
        var lat, lng

        await getLatLng(firstResult)
          .then(latLng => {
            lat = latLng.lat
            lng = latLng.lng
          })
          .catch(error => {
            console.error('Error', error)
          })

        get(firstResult, 'address_components', []).forEach(addressComponent => {
          if (addressComponent.types.includes('country')) {
            set(addressComponents, 'country', addressComponent.long_name)
            set(addressComponents, 'countryCode', addressComponent.short_name)
          }
          if (addressComponent.types.includes('postal_code')) {
            set(addressComponents, 'zipcode', addressComponent.long_name)
          }
        })
        await this.setState({ addressComponents })
        updateCall(addressComponents, lat, lng)
      })
      .catch(error => {
        console.error('Error', error)
      })
  }

  handleShowSavedPlace = (status = null) => {
    const { savedPlaceType } = this.props
    const { street } = this.state

    if (status !== null) {
      setTimeout(() => {
        this.setState({
          showSavedPlaces: status
        })
      }, 300)
    } else {
      if (savedPlaceType && !street) {
        this.setState({
          showSavedPlaces: true
        })
      } else {
        this.setState({
          showSavedPlaces: false
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    const oldValue = prevProps.value
    const { value } = this.props
    if (oldValue !== value) {
      this.setState({ street: value }, this.handleShowSavedPlace)
    }
  }

  componentDidMount() {
    const { getPlaces, savedPlaceType, updateCall } = this.props
    getPlaces(true, () => {
      const { places, value } = this.props
      const { street } = this.state
      if (savedPlaceType && !street && !value) {
        const defaultPlace = find(
          filter(places, { value: { type: savedPlaceType } }, []),
          { value: { isDefault: true } }
        )
        if (defaultPlace) {
          updateCall(
            {
              ...pick(get(defaultPlace, 'value.address', {}), [
                'street',
                'country',
                'zipcode',
                'state',
                'city',
                'countryCode'
              ]),
              floorUnitNumber: get(defaultPlace, 'value.additionalAddress', '')
            },
            get(defaultPlace, 'value.address.latitude'),
            get(defaultPlace, 'value.address.longitude')
          )
        }
      }
    })
  }

  render() {
    const { street, showSavedPlaces } = this.state
    const { savedPlaceType, places, loadingPlaces } = this.props

    return (
      <PlacesAutocomplete value={street === undefined ? this.props.value : street}
        onChange={this.handleChange}
        onSelect={this.handleSelect}>
        {({ getInputProps, suggestions, loading, getSuggestionItemProps }) => (
          <Grid container direction='row' className='relative'>
            <Grid item xs={12} className='theme-text-field theme-text-field-border relative'>
              <Grid container justify='space-between'>
                <Grid item className='flex-1'>
                  <input name="placeAutoComplete"
                    {...getInputProps()}
                    className={'location-search-input'}
                    {...omit(this.props, ['updateCall'])}
                    value={street === undefined ? this.props.value : street}
                    onFocus={() => this.handleShowSavedPlace()}
                    onBlur={() => this.handleShowSavedPlace(false)}
                  />
                </Grid>
                <Grid item className='w-56'>
                  <Grid container justify='space-between' alignItems='center' className='h-full'>
                    <Grid item className='pl-6'>
                      <Grid container alignItems='center'>
                        <img src='assets/images/icons/google-logo.png' alt='google' />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems='center'>
                        <img src='assets/images/icons/Search-In-text.png' alt='search-in-text' />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {suggestions.length > 0 && (
              <Grid item xs={12}
                className='autocomplete-dropdown-container absolute'
                style={{
                  top: '55px',
                  borderRadius: '2px',
                  backgroundColor: '#FFFFFF',
                  zIndex: '1',
                  overflow: 'hidden',
                  boxShadow: '0 1px 4px 0 rgba(5, 13, 52, 0.1)',
                  border: '1px solid rgba(159, 160, 164, 0.6)'
                }}
              >
                <Grid container>
                  {loading && (
                    <Grid
                      item
                      xs={12}
                      className={'suggestion-item'}
                      style={{
                        fontSize: '12px',
                        padding: '10px',
                        border: '1px solid #fafafa',
                        backgroundColor: '#ffffff',
                        textAlign: 'center'
                      }}
                    >
                      Loading...
                    </Grid>
                  )}
                  {suggestions.map((suggestion, index) => (
                    <Grid
                      {...getSuggestionItemProps(suggestion)}
                      key={index}
                      item
                      xs={12}
                      className={'cursor-pointer ' + (suggestion.active ? 'suggestion-item--active' : 'suggestion-item')}
                      style={{
                        fontSize: '12px',
                        padding: '5px',
                        border: '1px solid #fafafa',
                        ...(suggestion.active ? { backgroundColor: '#fafafa' } : { backgroundColor: '#ffffff' })
                      }}
                    >
                      {suggestion.description}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {showSavedPlaces &&
              <Grid item xs={12}
                className={
                  'w-full absolute p-12'
                  + ((!loadingPlaces && filter(places, { value: { type: savedPlaceType } }, []).length === 0) ?
                    ' hidden' :
                    '')
                }
                style={{
                  top: '55px',
                  borderRadius: '2px',
                  backgroundColor: '#FFFFFF',
                  zIndex: '1',
                  overflow: 'hidden',
                  boxShadow: '0 1px 4px 0 rgba(5, 13, 52, 0.1)',
                  border: '1px solid rgba(159, 160, 164, 0.6)'
                }}>
                <Grid container>
                  <Grid item xs={12} className="pb-12">
                    <Grid container justify="space-between" alignItems="center">
                      <Grid item className="fg-gray regular uppercase">
                        saved places</Grid>
                      {loadingPlaces &&
                        <Grid item><CircularProgress size={20} /></Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className="cursor-pointer">
                    {orderBy(filter(places, { value: { type: savedPlaceType } }, []), 'value.isDefault', 'desc')
                      .map((place, placeIndex) => {
                        return (
                          <Grid container key={placeIndex} alignItems="center"
                            onClick={() => {
                              const { updateCall } = this.props

                              updateCall(
                                {
                                  ...pick(get(place, 'value.address', {}), [
                                    'street',
                                    'country',
                                    'zipcode',
                                    'state',
                                    'city',
                                    'countryCode'
                                  ]),
                                  floorUnitNumber: get(place, 'value.additionalAddress', '')
                                },
                                get(place, 'value.address.latitude'),
                                get(place, 'value.address.longitude')
                              )
                            }}>
                            <Grid item className="pr-12">
                              <Grid container alignItems="center">
                                <img src="/assets/images/icons/Office-Org@2x.png"
                                  height={20}
                                  alt="place" />
                              </Grid>
                            </Grid>
                            <Grid item xs className={
                              'text-14 py-8' +
                              (placeIndex > 0 ? ' border-solid border-t-1 border-gray' : '')
                            }>
                              <Grid container justify="space-between" spacing={2}>
                                <Grid item xs className="regular">
                                  <Grid container>
                                    <Grid item xs={12}>
                                      {get(place, 'value.address.street')}
                                    </Grid>
                                    <Grid item xs={12} className="fg-gray">
                                      {get(place, 'value.additionalAddress')}
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid item xs className="semi-bold text-right">
                                  {get(place, 'value.name')}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        )}
      </PlacesAutocomplete>
    )
  }
}

const mapStateToProps = ({ settingsReducer: { places } }) => ({
  loadingPlaces: get(places, 'loading'),
  places: get(places, 'data', [])
})

const mapDispatchToProps = dispatch => ({
  getPlaces: (...args) => dispatch(SettingsActions.getPlaces(...args))
})

export default connect(mapStateToProps, mapDispatchToProps)(GooglePlaceSearch)
